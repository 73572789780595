import React, { FC } from "react";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import ClockIcon from "../../../components/icons/ClockIcon";
import CloseIcon from "../../../components/icons/CloseIcon";
import CopyIcon from "../../../components/icons/CopyIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { copyToClipboard } from "../../../helpers/copyToClipboard";
import {
  ConfigurationPreview,
  HistoryLogCell,
  printConfiguration,
} from "../domain";

import styles from "./ConfigurationPreviewDialog.module.scss";
import { useUserContext } from "../../../contexts/UserContext";

type Props = {
  onClose: () => void;
  configuration: ConfigurationPreview;
  isLoading?: boolean;
  event?: HistoryLogCell;
};

const ConfigurationPreviewDialog: FC<Props> = ({
  configuration,
  onClose,
  isLoading,
  event,
}) => {
  const { isDemo } = useUserContext();
  const config = isDemo ? DEMO_CONFIG : printConfiguration(configuration);
  const copyConfig = () => {
    copyToClipboard(config);
  };

  return (
    <DialogTemplate
      containerClassName={styles.dialog}
      leftColumn={
        <SummaryInfoBlock
          icon={<ClockIcon width={12} color={GREY_FONT} />}
          title={"Configuration"}
          className={styles.previewTitle}
        >
          <UnifiedTextPair subtitle="Connection" text={event?.name || "-"} />
        </SummaryInfoBlock>
      }
      onClose={onClose}
    >
      <CloseIcon
        className={styles.closeBtn}
        color={GREY_FONT}
        onClick={onClose}
      />
      <div className={styles.content}>
        {isLoading ? (
          <LoaderIcon />
        ) : (
          <div className={styles.previewFrame}>
            <CopyIcon
              className={styles.copyBtn}
              onClick={copyConfig}
              color={GREY_FONT}
            />
            {config}
          </div>
        )}
      </div>
    </DialogTemplate>
  );
};

export default ConfigurationPreviewDialog;

const DEMO_CONFIG = `
  [10.125.4.76]
configure private

set interfaces xe-100/0/36 unit 501 apply-groups-except uRPF_interface
set interfaces xe-100/0/36 unit 501 apply-groups-except ARP_interface
set interfaces xe-100/0/36 unit 501 description "Inside Packet POC"
set interfaces xe-100/0/36 unit 501 encapsulation vlan-ccc
set interfaces xe-100/0/36 unit 501 vlan-id 501
set interfaces xe-100/0/36 unit 501 input-vlan-map pop
set interfaces xe-100/0/36 unit 501 output-vlan-map push
set interfaces xe-100/0/36 unit 501 family ccc mtu 9100
set interfaces xe-100/0/36 unit 501 family ccc filter input eth_ccc_edge_100m
set protocols l2circuit neighbor 10.125.4.86 interface xe-100/0/36.501 virtual-circuit-id 130999988
set protocols l2circuit neighbor 10.125.4.86 interface xe-100/0/36.501 description "Inside Packet POC"
set protocols l2circuit neighbor 10.125.4.86 interface xe-100/0/36.501 ignore-mtu-mismatch

commit
exit

exit


[10.125.4.86]
configure private

set interfaces ae60 unit 501 apply-groups-except uRPF_interface
set interfaces ae60 unit 501 apply-groups-except ARP_interface
set interfaces ae60 unit 501 description "Inside Packet POC"
set interfaces ae60 unit 501 encapsulation vlan-ccc
set interfaces ae60 unit 501 vlan-id 501
set interfaces ae60 unit 501 input-vlan-map pop
set interfaces ae60 unit 501 output-vlan-map push
set interfaces ae60 unit 501 family ccc mtu 9100
set interfaces ae60 unit 501 family ccc filter input eth_ccc_edge_100m
set protocols l2circuit neighbor 10.125.4.76 interface ae60.501 virtual-circuit-id 130999988
set protocols l2circuit neighbor 10.125.4.76 interface ae60.501 description "Inside Packet POC"
set protocols l2circuit neighbor 10.125.4.76 interface ae60.501 ignore-mtu-mismatch

commit
exit

exit
`;
