import { CellProps } from "react-table";

export const routesTableHeader = (): Array<any> => {
  return [
    {
      Header: "Destination",
      accessor: "destination",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value || "-";
      },
    },
    {
      Header: "Gateway",
      accessor: "gateway",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value || "-";
      },
    },
    {
      Header: "Distance",
      accessor: "distance",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value || "-";
      },
    },
    {
      Header: "Type",
      accessor: "route_type",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value || "-";
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof any,
  }));
};
