import React, { useMemo, useState } from "react";
import { VIMapType } from "../../../types";
import CenterBackground from "./parts/CenterBackgronud";
import LocationCircle from "./parts/LocationCircle";
import InterfaceCircle from "./parts/InterfaceCircle";
import InterfaceConnector from "./parts/InterfaceConnector";
import {
  createStarLayout,
  InterfacesGroup,
  PosProps,
  STAR_HEIGHT,
  STAR_WIDTH,
} from "./utils";
import HoverTooltip from "./parts/HoverTooltip";
import OnboardingContext from "../../../OnboardingContext";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import { LocationType } from "../../ConnectionsContext";

type Props = {
  viMap?: VIMapType;
  activeLocation?: LocationType;
  isLocationLoading?: boolean;
  onClick?: (connection: any, type?: string, loc?: string) => void;
  selectedInterface?: any;
  isLoading?: boolean;
};
function Star({
  viMap,
  activeLocation,
  isLocationLoading,
  onClick = () => undefined,
  selectedInterface,
  isLoading,
}: Props): React.ReactElement {
  const layout = useMemo(() => createStarLayout(viMap, false), [viMap]);
  const [tooltipData, setTooltipData] = useState<
    (PosProps & { data: InterfacesGroup }) | undefined
  >(undefined);
  return (
    <>
      {tooltipData && (
        <HoverTooltip groupData={tooltipData.data} {...tooltipData} />
      )}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        viewBox={`0 0 ${STAR_WIDTH} ${STAR_HEIGHT}`}
        style={{ margin: "0.5rem", height: "100%", width: "100%" }}
      >
        <CenterBackground isLoading={isLoading} />
        {layout.map((l) => {
          return (
            <>
              {l.interfacesGroups.map((vi) => {
                return (
                  <InterfaceConnector
                    from={l.position}
                    to={vi.position}
                    key={l.location + vi.connection_type}
                  />
                );
              })}
              <LocationCircle
                {...l.position}
                key={l.location}
                location={l.location}
                active={activeLocation?.location === l.location}
                isLoading={
                  activeLocation?.location === l.location && isLocationLoading
                }
                onClick={() => onClick(l, "loc")}
              />
            </>
          );
        })}

        {/* render interfaces*/}

        {layout.map((l) => (
          <>
            {l.interfacesGroups.map((viGroup, idx) => {
              const firstVi = viGroup.data[0];
              const isActive = selectedInterface?.name === firstVi.name;
              return (
                <InterfaceCircle
                  groupData={viGroup}
                  {...viGroup.position}
                  active={isActive}
                  onClick={() => onClick(firstVi, "vi", l.location)}
                  onMouseOver={(e) => {
                    setTooltipData({
                      data: viGroup,
                      x: e.clientX,
                      y: e.clientY,
                    });
                  }}
                  onMouseLeave={() => {
                    setTooltipData(undefined);
                  }}
                  key={idx + viGroup.data[0].name + viGroup.position.y}
                />
              );
            })}
          </>
        ))}
      </svg>
    </>
  );
}

export default withContexts<Props>(Star, [OnboardingContext]);
