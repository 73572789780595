import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function EssentialsIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 2L18.6604 17H1.33984L10.0001 2Z"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EssentialsIcon;
