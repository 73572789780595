import moment from "moment";
import { ElasticHistoryLogHit, ElasticHit } from "../../../helpers/api/types";

export enum HistoryLogActivity {
  CREATE = "Create",
  BGP_CONFIG = "Bgp config",
  DELETE = "Delete",
  UPDATE = "Update",
}

export type HistoryLogCell = {
  timestamp: string;
  action: string;
  name: string;
  connectionType: string;
  endpointA: Array<string>;
  endpointZ: Array<string>;
  connectionBW: string;
  remoteAsn?: string;
  routerIp?: string;
  logs?: string;
};

export type ConfigurationPreview = Record<string, Array<Array<string>>>;

const convertTimestamp = (timestamp: string): string => {
  return moment(timestamp).format("DD/MM/YY HH:mm:ss");
};

export const convertElasticToTable = (
  data: Array<ElasticHit<ElasticHistoryLogHit>>
): Array<HistoryLogCell> => {
  return data.map(({ fields }) => {
    const connectionType = fields["z_endpoint_conf.Connection_type.keyword"]
      ? fields["z_endpoint_conf.Connection_type.keyword"][0]
      : "not stated";

    const name = fields.name ? fields.name[0] : fields.entity?.[0] || "";

    const connectionBW = fields["a_endpoint_conf.BW"]
      ? fields["a_endpoint_conf.BW"][0] + " Mbps"
      : "";

    const action = fields.action ? fields.action[0] : "";

    const timestamp = fields["@timestamp"]
      ? convertTimestamp(fields["@timestamp"][0])
      : "no date";

    const remoteAsn = fields["remote_asn"] ? fields["remote_asn"][0] : "";
    const routerIp = fields["remote_router_ip"]
      ? fields["remote_router_ip"][0]
      : "";

    return {
      action,
      timestamp,
      name,
      connectionType,
      endpointA: fields.a_endpoint_name,
      endpointZ: fields.z_endpoint_name,
      connectionBW,
      remoteAsn,
      routerIp,
    };
  });
};

export const printConfiguration = (
  config: ConfigurationPreview | Array<string>
): string => {
  if (Array.isArray(config)) {
    return config.join("\n");
  }
  return config
    ? Object.entries(config)
        .map(([ip, content]) => {
          const logsData = content
            .filter((c) => c.length)
            .reduce((acc, cur) => acc + cur.join("\n") + "\n\n", "");

          return `[${ip}]\n${logsData}`;
        })
        .join("\n")
    : "no data";
};
