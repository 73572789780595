import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

export const elasticTenantEventsBody = (
  tenant: string,
  size: string,
  timeRange?: GTimeRange,
  timeRangeMS?: { from: number; to: number }
): { [key: string]: any } => {
  let gte, lte;
  if (timeRange) {
    gte = parseGTime(timeRange.from);
    lte = parseGTime(timeRange.to);
  }
  if (timeRangeMS) {
    gte = new Date(timeRangeMS.from).toISOString();
    lte = new Date(timeRangeMS.to).toISOString();
  }
  return {
    track_total_hits: false,
    sort: [
      {
        "@timestamp": {
          order: "desc",
          unmapped_type: "boolean",
        },
      },
    ],
    fields: [
      {
        field: "*",
        include_unmapped: "true",
      },
      {
        field: "@timestamp",
        format: "strict_date_optional_time",
      },
      {
        field: "First sampled",
        format: "strict_date_optional_time",
      },
      {
        field: "Last sampled",
        format: "strict_date_optional_time",
      },
      {
        field: "Timestamp",
        format: "strict_date_optional_time",
      },
      {
        field: "created_at",
        format: "strict_date_optional_time",
      },
      {
        field: "syslog5424_ts",
        format: "strict_date_optional_time",
      },
      {
        field: "updated_at",
        format: "strict_date_optional_time",
      },
    ],
    size: size,
    version: true,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    _source: false,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              filter: [
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "event_type.keyword": "log",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        exists: {
                          field: "entity.keyword",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match_phrase: {
                          "tenant_name.keyword": tenant,
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte,
                lte,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
    highlight: {
      pre_tags: ["@kibana-highlighted-field@"],
      post_tags: ["@/kibana-highlighted-field@"],
      fields: {
        "*": {},
      },
      fragment_size: 2147483647,
    },
  };
};
