export type QueryParams = {
  [key: string]: string | number;
};

export const getQueryParams = (params: QueryParams): string => {
  const stringifyParams = Object.keys(params).map(
    (key) => `${key}=${params[key]}`
  );
  return stringifyParams.join("&");
};
