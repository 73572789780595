import React, { FC, ReactElement } from "react";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import styles from "./EventsSizeDropdown.module.scss";

type Props = {
  onChange: (field: string) => void;
  disabled?: boolean;
  selected: string;
  label?: string;
};

export const EventsSizeDropdown: FC<Props> = ({
  onChange,
  disabled,
  selected,
  label,
}): ReactElement => {
  const list = ["20", "100", "500"];
  return (
    <div className={styles.wrapper}>
      <span className={styles.label}>{label}</span>
      <DropdownBasic
        id={"eventSize"}
        onChange={(val) => {
          onChange(val.key);
        }}
        selected={mapStringToItem(selected)}
        itemsList={list.map((item) => mapStringToItem(item))}
        isMedium
        error={""}
        disabled={disabled}
      />
    </div>
  );
};

export default EventsSizeDropdown;
