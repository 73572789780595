import { UserRoles } from "./rolesHelpers";

export const filteredDataByGetawayIp = (
  list: Array<any>,
  field: string,
  role: string
): Array<any> => {
  return role === UserRoles.TENANT
    ? list.filter((el) => {
        return (
          el[field] === null ||
          !(el[field] as string).match(/192\.88\.99\.\d+/gm)
        );
      })
    : list;
};
