import { SystemNodeEdit } from "../../../types";

export const getEditNodesParams = (
  current: any,
  initial: any
): Array<{ node: string; params: SystemNodeEdit }> => {
  const result = [];

  for (let i = 0; i < current.nodes.length; i++) {
    const currentNode = current.nodes[i];
    const initialNode = initial.nodes[i];
    if (
      currentNode.location !== initialNode.location ||
      currentNode.description !== initialNode.description
    ) {
      result.push({
        node: currentNode.name,
        params: {
          location:
            currentNode.location !== initialNode.location
              ? currentNode.location
              : initialNode.location,
          description:
            currentNode.description !== initialNode.description
              ? currentNode.description
              : initialNode.description,
        },
      });
    }
  }
  return result;
};
