import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CaretOutline({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M.2 1.106c-.628-.593.367-1.533.995-.89L5.804 4.52a.66.66 0 0 1 0 .94L1.195 9.81c-.628.593-1.623-.346-.995-.94l4.085-3.858L.2 1.106Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default CaretOutline;
