import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function CyberIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="9"
        width="14"
        height="9"
        rx="1"
        stroke={color || FONT}
        strokeWidth="2.4"
      />
      <path
        d="M7 9V5a3 3 0 1 1 6 0v4"
        stroke={color || FONT}
        strokeWidth="2.2"
      />
      <circle cx="10" cy="13.5" r="1.5" fill={color || FONT} />
    </svg>
  );
}

export default CyberIcon;
