import { StaticRouteType } from "./../api/apiTypes";
import Validator from "./Validator";

export default function validateStaticRoutes(
  data: StaticRouteType & { vrf: string },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.destination, "destination");
  validator.checkIPWithPrefix(data.destination, "destination");

  validator.checkEmpty(data.gateway_ip_address, "gateway");
  validator.checkIP(data.gateway_ip_address, "gateway");

  validator.checkEmpty(data.distance, "distance");
  validator.checkIsNumeric(+data.distance, "distance");

  validator.checkEmpty(data.description, "description");
  validator.checkEmpty(data.vrf, "vrf");

  return validator;
}
