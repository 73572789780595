import React, { CSSProperties, FC } from "react";
import styles from "./DashboardControls.module.scss";

type Props = {
  style?: CSSProperties;
};

const DashboardControls: FC<Props> = ({ style, children }) => {
  return (
    <div className={styles.wrapper} style={style}>
      {children}
    </div>
  );
};
export default DashboardControls;
