import { FC } from "react";
import styles from "./EventLogs.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import { useEventsContext } from "../EventsContext";
import { PageLoader } from "../../../components/common/loadStates/LoaderIcon";
import Timestamp from "../common/Timestamp";
import EventLabel from "../common/EventLabel/EventLabel";
import EventStatus from "../common/EventStatus";

type Props = {
  className: string;
};

const EventLogs: FC<Props> = ({ className }) => {
  const { selectedEvent } = useEventsContext();

  if (!selectedEvent) return <PageLoader />;

  const { timestamp, activity, type, status, logs } = selectedEvent;

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.header}>
        <Timestamp value={timestamp} />
        <span className={styles.lables}>
          <EventLabel activity={activity} />
          <EventLabel activity={activity} entity={type} />
        </span>
      </div>
      <ul className={styles.content}>
        {logs?.map((l: string | { [key: string]: string }, i: number) => {
          if (typeof l === "string") return null;
          const key = Object.keys(l)[0];
          const value = l[key];
          return (
            <li className={styles.row} key={key + i}>
              <span className={styles.key}>{key}</span>
              <span className={styles.value}>{value}</span>
            </li>
          );
        })}
        <li className={styles.row}>
          <span className={styles.key}>status</span>
          <span className={styles.value}>
            <EventStatus status={status} />
          </span>
        </li>
      </ul>
    </div>
  );
};
export default EventLogs;
