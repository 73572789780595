import { FC } from "react";
import { PageProps } from "../../helpers/types";
import styles from "./ServiceBGPContent.module.scss";
import ServicesBGPPage from "./components/ServiceBGPPage/ServicesBGPPage";
import { withLayout } from "../../helpers/hocs/withLayout";

const BGPContent: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <div className={styles.container}>
      <Tabs showTimeRange />
      <ServicesBGPPage />
    </div>
  );
};

export default withLayout<PageProps>(BGPContent);
