import { FC, useEffect, useMemo } from "react";
import { useRemoteUsersStatusContext } from "../../contexts/RemoteUsersStatusContext";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StatusPageTable.module.scss";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { remoteUserStatusTableColumns } from "./TableColumns";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../contexts/TimerangeContext";
import { useUserListContext } from "../../contexts/UserListContext";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { CellHoverTooltip } from "./CellHoverTooltip";
import { RemoteUserStatus } from "../../helpers/types";
import useRequestInterval from "../../helpers/hooks/useRequestInterval";

export const StatusPageTable: FC = () => {
  const {
    userStatusList,
    userRequest,
    fetchUserStatusList,
  } = useRemoteUsersStatusContext();
  const {
    fetchUserList,
    fetchCurrSystem,
    currSystem,
    listFetchRequest,
  } = useUserListContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchUserStatusList(selectedTenant, timeRange.general);
    }
  }, [selectedTenant, timeRange.general]);

  useRequestInterval(() => {
    if (selectedTenant) fetchUserStatusList(selectedTenant);
  }, 30000);

  useEffect(() => {
    if (selectedTenant) {
      fetchCurrSystem(selectedTenant);
      fetchUserList(0, 500, selectedTenant, currSystem);
    }
  }, [selectedTenant]);

  const rowActions: ActionFuncs<RemoteUserStatus> = useMemo(
    () => ({
      hoverTooltip: (row: any) => <CellHoverTooltip rowOriginal={row} />,
    }),
    [selectedTenant]
  );

  const isEmpty = !userStatusList?.length;
  return (
    <TableWrapper
      isEmpty={isEmpty}
      dataStatus={userRequest}
      className={styles.tableWrapper}
      titleProps={{
        title: !isEmpty
          ? `Remote User Status (${userStatusList?.length || 0})`
          : "No users",
      }}
    >
      {userRequest.state === "pending" ||
      listFetchRequest?.state === "pending" ? (
        <PageLoader />
      ) : (
        <div className={styles.wrapper}>
          <div className={styles.table}>
            <Table
              header={TableHeader}
              columns={remoteUserStatusTableColumns()}
              totalCount={userStatusList.length}
              withFullData
              count={20}
              data={userStatusList}
              gridColumnTemplate={`1fr 200px 1fr 1fr 1fr 1fr`}
              rowActions={rowActions}
            />
          </div>
        </div>
      )}
    </TableWrapper>
  );
};
