import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

const fromApplicationsFiltersBodyBody = (
  type: FiltersFieldType,
  timeRange: GTimeRange,
  tenantName?: string
): { [key: string]: any } => {
  const { from, to } = timeRange;
  const gte = parseGTime(from);
  const lte = parseGTime(to);
  const field = FILTERS_FIELDS_MAP[type];
  return {
    aggs: {
      "2": {
        terms: {
          field: field,
          order: {
            _count: "desc",
          },
          size: 1000,
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match: {
                    "entity.keyword": "DPI",
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte,
                lte,
              },
            },
          },
          {
            match_phrase: { "tenant_name.keyword": tenantName },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default fromApplicationsFiltersBodyBody;

export type FiltersFieldType = "destination" | "hostname" | "source";
export const FILTERS_FIELDS_MAP: { [key: string]: string } = {
  destination: "Destination_IP.keyword",
  hostname: "Hostname.keyword",
  source: "Source_IP.keyword",
};
