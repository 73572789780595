import { TRAFFIC_FIELD_VALUE } from "./../consts";
import { getFiltersParams } from "../../ApplicationsDashboard/common/appStatisticsHelper";
import { FiltersFields } from "../types";
import {
  GRAFANA_ROOT_PATH,
  GTimeRange,
} from "../../../components/common/charts/GraphanaLinksFactory";
import { getMsFromDate } from "../../../components/common/charts/GraphanaTableLinks";

export const getTrafficLink = (
  panelId: number,
  timeRange: GTimeRange,
  tenant?: string,
  filters?: FiltersFields
): string => {
  const address = GRAFANA_ROOT_PATH + "/d-solo/iI2ltFJVz/sdr";
  const orgId = "orgId=1";
  const theme = "theme=light";

  const { from, to } = getMsFromDate(timeRange);
  const dateRange = `from=${from}&to=${to}`;

  const tenantFilter = `var-Filters=tenant_name.keyword%7C%3D%7C${tenant}`;
  const refresh = "refresh=30s";

  const filtersParams = filters
    ? `&${getFiltersParams(TRAFFIC_FIELD_VALUE, filters)}`
    : "";
  return `${address}?${orgId}&${tenantFilter}${filtersParams}&${dateRange}&panelId=${panelId}&${theme}&${refresh}`;
};
