import React from "react";
import styles from "./TableHeader.module.scss";
import { classNames } from "../../../../../helpers/common/classNames";
import { TableColumnHeadersProps } from "../Table";
function TableHeader<D extends { [key: string]: any }>({
  columns,
  grid,
  className,
}: TableColumnHeadersProps<D>): React.ReactElement {
  const tableHeader = classNames(styles.tableHeader, className);

  return (
    <div style={grid} className={tableHeader}>
      {columns.map((column, key) => (
        <div key={key} className={styles.th}>
          {column.render("Header")}
        </div>
      ))}
    </div>
  );
}

export default TableHeader;
