import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { natRulesTableColumns } from "../../NATRulesPage/components/NATRulesOverview/table/natRulesTableColumns";
import { formPathFromArray } from "../../../helpers/navigation";
import { SERVICES, SERVICES_NAT } from "../../../helpers/navigation/entries";

const NatRulesTable: FC = () => {
  const { data, statuses } = useSearchContext();
  return (
    <ResultTable
      title="NAT Rules"
      isCollapsible
      columns={natRulesTableColumns}
      data={data?.natRules}
      grid={"100px 150px 150px repeat(3, 1fr) 0px"}
      status={statuses?.natRulesStatus}
      count={data?.natRules.length}
      goto={formPathFromArray([SERVICES(), SERVICES_NAT()])}
    />
  );
};
export default NatRulesTable;
