import React, { ReactElement } from "react";
import ReactTooltip from "react-tooltip";
import { classNames } from "../../../helpers/common/classNames";
import RadioBtnIcon from "../../icons/RadioBtnIcon";
import styles from "./RadioBtn.module.scss";

type RadioBtnProps = {
  label?: string | React.ReactElement;
  isChecked: boolean;
  toggleRadioBtn: () => void;
  disabled?: boolean;
  value: string;
  className?: string;
  isNotEditable?: boolean;
  onlyAdmin?: boolean;
};

export function RadioBtn({
  label,
  isChecked,
  toggleRadioBtn,
  disabled,
  value,
  className,
  isNotEditable,
  onlyAdmin,
}: RadioBtnProps): ReactElement {
  const radioStyles = classNames(
    styles.radio,
    disabled && styles.radioDisabled,
    isNotEditable && styles.notEditable,
    className
  );
  const labelStyles = classNames(
    styles.container,
    disabled && styles.labelDisabled
  );
  return (
    <>
      <label className={labelStyles} data-tip data-for={value}>
        <input
          type="radio"
          onChange={toggleRadioBtn}
          checked={isChecked}
          value={value}
          disabled={disabled || isNotEditable}
        />
        <span className={radioStyles} aria-hidden="true">
          {isChecked && (
            <RadioBtnIcon
              color={
                disabled ? "var(--disabledChecked)" : "var(--button-primary)"
              }
            />
          )}
        </span>
        {label}
      </label>
      {onlyAdmin && (
        <ReactTooltip
          id={value}
          aria-haspopup="true"
          place="bottom"
          type="light"
          className={styles.tooltip}
        >
          Service disabled. Please contact your operator to activate it
        </ReactTooltip>
      )}
    </>
  );
}
