import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function ZayoLogo({ x, y }: IconProps): ReactElement {
  return (
    <svg
      width="53"
      height="18"
      viewBox="0 0 53 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x ? x - 53 / 2 : "none"}
      y={y ? y - 7 : "none"}
    >
      <path
        d="M35.137 14.5473C34.8956 15.2641 34.5369 15.9177 34.0068 16.4473C32.4467 18.0058 29.8748 17.5797 27.8738 17.3701C27.8738 16.16 27.8738 14.9713 27.8738 13.8254C28.5106 13.8254 29.1397 13.869 29.7594 13.8115C30.249 13.7661 30.3843 13.4791 30.2257 13.0109C28.8551 8.96579 27.431 4.93769 26.0382 0.900037C26.0237 0.857861 26.0364 0.806292 26.0364 0.738747C27.7598 0.738747 29.4795 0.738747 31.2166 0.738747C31.8034 3.50217 32.5975 6.23341 32.939 9.06938C33.2914 6.23517 34.1198 3.50554 34.7327 0.726562C36.4549 0.726562 38.1505 0.726562 39.9346 0.726562C38.3417 5.33508 36.6931 9.9266 35.137 14.5473Z"
        fill="#F5831F"
      />
      <path
        d="M22.1329 13.1262C22.0959 12.8176 22.0813 12.5218 22.0813 12.2109C21.7447 12.4014 21.4419 12.5946 21.1207 12.7502C20.3134 13.1415 19.4586 13.3558 18.5587 13.3626C17.7871 13.3685 17.0193 13.3906 16.2636 13.1432C15.2947 12.8261 14.527 12.2717 14.0319 11.3818C13.6104 10.6241 13.4727 9.78483 13.565 8.93416C13.698 7.70814 14.3295 6.76187 15.3682 6.10278C16.0217 5.68818 16.7567 5.47927 17.5146 5.35308C18.9423 5.11534 20.3706 4.88801 21.7962 4.63628C21.8293 3.63557 21.3478 3.34152 20.5547 3.29825C20.1981 3.2788 19.8354 3.31055 19.4797 3.35354C19.0956 3.39996 18.8467 3.6499 18.7701 4.01671C18.7059 4.32429 18.5175 4.41037 18.2531 4.43558C16.8491 4.56942 15.4265 4.39103 14.0166 4.55027C14.01 4.09417 14.132 3.67877 14.285 3.27184C14.8248 1.83618 15.9679 1.11838 17.3642 0.720231C18.1587 0.493671 18.9692 0.356747 19.8019 0.370171C20.5471 0.382185 21.299 0.311111 22.0365 0.387446C23.1596 0.503703 24.261 0.734842 25.2012 1.44661C25.8611 1.94611 26.2572 2.59711 26.4253 3.39031C26.4967 3.72724 26.539 4.07695 26.5403 4.42106C26.5499 6.87204 26.5444 9.32307 26.5464 11.7741C26.5467 12.1638 26.6219 12.5325 26.859 12.8599C26.9037 12.9216 26.8294 13.1183 26.8294 13.1183L22.1329 13.1262ZM19.8451 10.0765C21.1783 9.74534 21.9048 8.71737 21.7716 7.4345C21.4027 7.54848 21.0341 7.66721 20.6626 7.77572C20.2891 7.8848 19.916 7.99858 19.5366 8.0832C19.0946 8.18182 18.7167 8.3706 18.4061 8.70819C18.0936 9.04793 18.1356 9.49952 18.4252 9.78844C18.8144 10.1769 19.2917 10.2203 19.8451 10.0765Z"
        fill="#F5831F"
      />
      <path
        d="M43.6979 0.411942C44.4579 0.332728 45.1986 0.203617 45.937 0.215626C47.6015 0.242695 49.1708 0.643157 50.5435 1.64143C51.138 2.07379 51.6169 2.62585 51.9367 3.27532C52.2588 3.92928 52.5548 4.61563 52.7231 5.32098C52.9558 6.29562 52.948 7.30106 52.7733 8.29852C52.6352 9.08678 52.382 9.82889 51.9545 10.5074C51.4549 11.3004 50.8016 11.942 50.0154 12.4485C49.3353 12.8868 48.5804 13.1638 47.8028 13.342C46.8656 13.5567 45.9029 13.6633 44.9408 13.5873C43.9075 13.5055 42.8882 13.3274 41.9395 12.8682C40.8206 12.3265 39.9243 11.5444 39.3117 10.4547C38.8435 9.62187 38.6107 8.72104 38.4985 7.77446C38.3973 6.92012 38.4871 6.09369 38.6616 5.25852C38.8887 4.17105 39.3621 3.22831 40.1047 2.40223C41.0714 1.32696 42.2893 0.727939 43.6979 0.411942ZM44.2004 4.32361C43.6356 4.92504 43.4801 5.68584 43.4407 6.46168C43.4132 7.00241 43.4715 7.5541 43.5512 8.09203C43.656 8.79889 43.9724 9.40001 44.5928 9.81116C44.98 10.0677 45.4089 10.0455 45.841 10.0478C46.7058 10.0524 47.2642 9.48422 47.5372 8.84007C47.8169 8.18039 47.8661 7.46236 47.8836 6.75316C47.892 6.40967 47.8361 6.06018 47.7723 5.72035C47.67 5.17548 47.4932 4.65214 47.0942 4.2446C46.394 3.52941 44.8848 3.59137 44.2004 4.32361Z"
        fill="#F5831F"
      />
      <path
        d="M1.63672 13.0809C5.56457 9.10057 9.43424 5.17919 13.3039 1.25781C13.3294 1.27376 13.3548 1.28971 13.3803 1.30566C13.3803 2.02734 13.4093 2.75099 13.3602 3.46932C13.3477 3.6528 13.0995 3.83098 12.9398 3.99186C10.2982 6.65185 7.60073 9.26663 5.02753 11.9931C5.39316 11.9931 5.72769 12.0292 6.04812 11.9777C6.20716 11.9522 6.35402 11.7791 6.48259 11.6492C7.20508 10.9192 7.89639 10.1611 8.63655 9.44812C8.71006 9.37732 8.82574 9.31011 8.92237 9.30953C10.3529 9.30099 11.7834 9.3039 13.214 9.30485C13.2409 9.30486 13.2679 9.31397 13.3098 9.32155C13.3098 10.5593 13.3098 11.7948 13.3098 13.0809C9.41879 13.0809 5.52776 13.0809 1.63672 13.0809Z"
        fill="#F5831F"
      />
      <path
        d="M1.65234 0.726849C5.22717 0.680673 8.80408 0.716133 12.3792 0.716126C12.4794 0.716126 12.5796 0.716126 12.7345 0.716126C8.78033 4.72729 4.86942 8.69457 0.925755 12.6951C0.917569 12.5761 0.907591 12.4972 0.907426 12.4183C0.906104 11.789 0.915581 11.1594 0.901593 10.5304C0.896914 10.3199 0.964791 10.1759 1.11266 10.0325C3.89544 7.33499 6.5837 4.53198 9.27067 1.73998C8.75498 1.80566 8.27669 1.59119 7.84646 2.0793C7.16688 2.85031 6.40675 3.55091 5.66916 4.26945C5.57485 4.36134 5.41177 4.42755 5.27979 4.42885C4.08792 4.44053 2.89588 4.43509 1.67737 4.40438C1.65135 3.15835 1.65185 1.9426 1.65234 0.726849Z"
        fill="#F5831F"
      />
    </svg>
  );
}

export default ZayoLogo;
