import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function PlusIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.2002 5.43652H0.702148V3.87207H4.2002V0.286133H5.78223V3.87207H9.28906V5.43652H5.78223V9.00488H4.2002V5.43652Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default PlusIcon;
