import { DropdownItem } from "./types";

export function mapStringToItemSubitem(
  val: string,
  subtitle?: string
): DropdownItem {
  if (subtitle) {
    return { key: val, subtitle: subtitle };
  } else return { key: val };
}

export function mapObjToItem(val: {
  value: string;
  disabled: boolean;
}): DropdownItem {
  return { key: val.value, disabled: Boolean(val.disabled) };
}
