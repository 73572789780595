import React, { CSSProperties, FC } from "react";
import { ConnectionPortDetails } from "../../../../types";
import styles from "./ConfigFields.module.scss";

import { CONFIG_FIELDS_MAP, FIELD_LABELS_MAP } from "../../../../consts";
import { renderFieldValue } from "../../../../helpers/renderFieldValue";
import { classNames } from "../../../../../../../helpers/common/classNames";
import DeleteButton from "../../../../../../../components/common/buttons/DeleteButton";
import { GREY_DISABLED } from "../../../../../../../helpers/common/colorAliases";

type Props = {
  data: ConnectionPortDetails;
  className?: string;
  onDelete?: (row: any) => void;
  allFields: Array<string>;
  isVPCType: boolean;
  isShortcuted: boolean;
};
const ConfigFields: FC<Props> = ({
  data,
  className,
  onDelete = () => undefined,
  allFields,
  isVPCType,
  isShortcuted,
}) => {
  const config = { ...data.config };
  const portFields = Object.keys(config);

  const wrapperStyles: CSSProperties = {
    gridTemplateColumns: isShortcuted
      ? `1fr 80px 1fr 20px`
      : `repeat(${allFields.length}, 1fr) 20px`,
  };

  return (
    <div
      style={wrapperStyles}
      className={classNames(styles.paramsWrapper, className)}
    >
      {CONFIG_FIELDS_MAP.map((el, i) => {
        if (!allFields.includes(el) || (isShortcuted && i > 2)) return null;
        const field = portFields.find((field) => field === el);
        return (
          <div className={styles.field}>
            {!field ? (
              <div className={styles.fieldPlaceholder}>. . .</div>
            ) : (
              <>
                <span className={styles.fieldTitle}>
                  {FIELD_LABELS_MAP[el]}
                </span>
                <span className={styles.fieldValue}>
                  {renderFieldValue(el, config[field], data.endpoint_type)}
                </span>
              </>
            )}
          </div>
        );
      })}
      {isVPCType && (
        <DeleteButton
          id={"delete"}
          color={GREY_DISABLED}
          onClick={() => onDelete(data)}
          className={styles.deleteBtn}
        />
      )}
    </div>
  );
};

export default ConfigFields;
