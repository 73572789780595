import React, { FC, useState } from "react";
import IconBlankButton from "../../../common/buttons/IconBlankButton";
import ThreeDotsIconNew from "../../../icons/ThreeDotsIconNew";
import styles from "./HeaderMenu.module.scss";
import { useClickOutside } from "../../../../helpers/hooks/useClickOutside";
import HeaderMenuComponent from "./components/HeaderMenuContent";
import { CONTROLS_MAP, ControlsType, ZAYO_CONTROLS_MAP } from "./consts";
import { useUserContext } from "../../../../contexts/UserContext";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";

const HeaderMenu: FC = () => {
  const { isTenant, isZayo } = useUserContext();
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const dropDownRef = useClickOutside(() => setShowMenu(false));

  const controls: Array<ControlsType> = isZayo
    ? ZAYO_CONTROLS_MAP
    : CONTROLS_MAP;

  return (
    <div
      className={styles.wrapper}
      ref={dropDownRef as React.RefObject<HTMLDivElement>}
    >
      <IconBlankButton
        id={"headerMenuThreeDots"}
        className={styles.button}
        icon={ThreeDotsIconNew}
        onClick={() => setShowMenu((prev) => !prev)}
        isOutlined
        color={GREY_FONT}
      />
      {showMenu && (
        <div className={styles.content}>
          {controls.map((control) => {
            if (
              (isTenant && control.onlyAdmin) ||
              (!isTenant && control.onlyTenant)
            )
              return null;

            return <HeaderMenuComponent control={control} />;
          })}
        </div>
      )}
    </div>
  );
};

export default HeaderMenu;
