export enum DDoSAttackStatus {
  ATTACKED = "attacked",
  UPDATED = "updated",
  WATERMARK_REACHED = "watermark_reached",
  THRESHOLD_BREACHED = "threshold_breached",
  CLOSED = "closed",
}

export type AttackEvent = {
  id: number;
  timestamp: number | string;
  event: DDoSAttackStatus;
  target: Array<string>;
  mitigation?: string | number | null;
  protectedScope: Array<string>;
  rule: string; // todo: replace with enum
  thresholdRatio:
    | {
        max: number;
        current: number;
      }
    | string;
  mitigationPattern: Array<string>;
};

export type DDoSTableRow = {
  id: number;
  subRows: Array<AttackEvent>;
};

export type Attack = {
  id: number;
  type?: AttackType;
  subRows: Array<AttackEvent>;
};

export type AttackGraph = {
  path_1: SVGAElement;
  path_2: SVGAElement;
};

export enum AttackType {
  ACTIVE,
  CLOSED,
}
