import { configApi } from "../../helpers/api/ConfigApi";

import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { PortInterface } from "./types";
import { RequestStatus } from "../../helpers/types";
import { PortType } from "../PortsPage/types";

type RequestsState = CrudRequestStatuses & {
  portListStatus: RequestStatus;
};

type ValueState = CrudValueState<PortInterface> & {
  portList?: Array<PortType>;
};

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<PortInterface> & {
  fetchPortList: () => Promise<void>;
};

export type IPortInterfaceContext = IState & IFunc;
const [
  Context,
  usePortInterfaceContext,
  withPortInterfaceContextProps,
] = createContextUtils<IState, IFunc>();

export { usePortInterfaceContext, withPortInterfaceContextProps };

type Props = IOneSystemContext;

class VLANContextContainer extends NewAbstractCrudContext<
  PortInterface,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;

    await this._fetchListWrap(() =>
      configApi.getPortInterfaceList(system.name)
    );
  };

  remove = async (portInterface: PortInterface): Promise<boolean> => {
    const { system } = this.props;
    const { physical_port_name, alias } = portInterface;
    if (!system) return false;
    const { ok } = await this._removeWrap(() =>
      configApi.deletePortInterface(system.name, { physical_port_name, alias })
    );

    return ok;
  };

  // todo not implemented
  edit = async (vlan: any): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());

    return ok;
  };

  fetchPortList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;
    const { ok, result } = await this._fetchWithStatus(
      () => configApi.getPortsList(system.name),
      "portListStatus"
    );
    this.setState({ portList: ok ? result : undefined });
  };

  funcs = {
    fetchPortList: this.fetchPortList,
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
  };
}

export default withOneSystemContextProps(VLANContextContainer);
