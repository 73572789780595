import React, { ReactElement } from "react";
import { ACCENT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ArrowBack(props: IconProps): ReactElement {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 4.11614V5.88386H3.38342L6.24978 8.75022L5 10L0 5L5 0L6.24978 1.24978L3.38342 4.11614H14Z"
        fill={props.color || ACCENT}
      />
    </svg>
  );
}

export default ArrowBack;
