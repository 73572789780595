import React, { FC } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  HISTORY_LOG,
  ZAYO_EDIT_CONNECTION,
  NEW_CONNECTION,
} from "../../../helpers/navigation/entries";
import ConnectionsPage from "./ConnectionsPage";
import { ConnectionTab } from "./ConnectionTab/ConnectionTab";
import ConnectionConfiguratorAdd from "./ConnectionConfigurator/ConnectionConfiguratorAdd";
import { withContexts } from "../../../helpers/hocs/withContexts";
import ConnectionsContextContainer from "./ConnectionContext";
import ConnectionConfiguratorEdit from "./ConnectionConfigurator/ConnectionConfiguratorEdit";
import HistoryLogPage from "../../HistoryLogPage/HistoryLogPage";

const Connections: FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <ConnectionsPage tabs={ConnectionTab} />
      </Route>
      <Route path={path + "/" + NEW_CONNECTION().path}>
        <ConnectionConfiguratorAdd />
      </Route>
      <Route path={path + "/" + HISTORY_LOG().path}>
        <HistoryLogPage />
      </Route>
      <Route path={path + "/" + ZAYO_EDIT_CONNECTION().path + ":connection"}>
        <ConnectionConfiguratorEdit />
      </Route>
      <Redirect to={path} />
    </Switch>
  );
};

export default withContexts(Connections, [ConnectionsContextContainer]);
