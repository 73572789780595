import { FC, useEffect } from "react";
import { LocationsList } from "../../../WizardToolPage/types";
import { useSystemProvisioningContext } from "../ProvisioningContextContainer";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import SystemIcon from "../../../../components/icons/SystemIcon";
import StatusIcon from "../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";

import styles from "./SystemTooltip.module.scss";
import BlankButton from "../../../../components/common/buttons/BlankButton";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import { capitalizeFirstLetter } from "../../../../helpers/common/stringHelpers";
import { useHistory } from "react-router-dom";
import { REPORTS, SYSTEM } from "../../../../helpers/navigation/entries";

type Props = {
  location?: LocationsList;
};

const SystemTooltip: FC<Props> = ({ location }) => {
  const history = useHistory();
  const { system, getSystem } = useSystemProvisioningContext();

  useEffect(() => {
    location && getSystem(location.name);
  }, [location]);

  if (!system) return <PageLoader />;
  return (
    <div className={styles.wrapper}>
      <TextWithIcon
        icon={<SystemIcon color={GREY_FONT} />}
        text={system.name}
        className={styles.header}
      />
      <RowPair title={"Location"} value={system.location} />
      <RowPair title={"Status"} value={system.status} />
      <RowPair title={"Type"} value={system.type} />
      {/* <BlankButton
        className={styles.btn}
        id={system.name + system.id}
        onClick={() => {
          history.push(SYSTEM(system.name).path + "/" + REPORTS().path);
        }}
      >
        Go to details
      </BlankButton> */}
    </div>
  );
};

export default SystemTooltip;

const RowPair: FC<{ title: string; value: string }> = ({ title, value }) => {
  return (
    <div className={styles.row}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>
        {title === "Status" && (
          <StatusIcon color={getColorFromStatus(value.toLowerCase())} />
        )}
        {capitalizeFirstLetter(value)}
      </span>
    </div>
  );
};
