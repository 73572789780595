import React, { FC, ReactElement } from "react";
import { classNames } from "../../helpers/common/classNames";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./UnifiedTextPair.module.scss";

type Props = {
  subtitle: string;
  text: string | number | ReactElement | undefined;
  className?: string;
  textClassName?: string;
  align?: "center";
  textSize?: "small" | "medium" | "large";
  separator?: "slash" | "colon";
};

const SEPARATORS_MAP = {
  slash: "/",
  colon: ":",
};

const UnifiedTextPair: FC<Props> = ({
  subtitle,
  text,
  className,
  align,
  textSize,
  separator,
  textClassName,
}) => {
  const wrapperClasses = classNames(
    styles.wrapper,
    align && styles[align],
    separator && styles.withSeparator,
    className
  );
  const textClasses = classNames(
    styles.text,
    textSize && styles[textSize],
    textClassName
  );
  const separatorClasses = classNames(
    styles.separator,
    separator && styles[separator]
  );

  return (
    <div className={wrapperClasses}>
      <div className={styles.subtitle}>{subtitle}</div>
      {separator && (
        <span className={separatorClasses}>{SEPARATORS_MAP[separator]}</span>
      )}
      <div className={textClasses}>{text !== undefined ? text : "—"}</div>
    </div>
  );
};
export default UnifiedTextPair;
