import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { systemApi } from "../../../helpers/api/SystemApi";
import { DropdownItem } from "../formComponents/DropDownInput";

type RequestStatuses = {};
type State = { list?: Array<DropdownItem> };

type IState = State & RequestStatuses;
type IFunc = {};

const [LocationDropdownContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useLocationDropdownContext = useContext;

export default class LocationDropdownContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = LocationDropdownContext;

  componentDidMount() {
    this.fetchList();
  }

  fetchList = async (): Promise<void> => {
    const { ok, result } = await systemApi.getSystemsList();
    if (ok && result) {
      const list = result.map((system) => ({
        key: system.name,
        value: system.location,
      }));
      this.setState({ list });
    }
  };

  funcs = {};
}
