import React, { FC, useEffect, useState } from "react";
import { classNames } from "../../../../helpers/common/classNames";
import ViewTabs from "../common/ViewTabs";
import { SystemsViewMode } from "../types";

import styles from "./MapViewContainer.module.scss";
import { useSystemProvisioningContext } from "../ProvisioningContextContainer";
import MapView from "../../../WizardToolPage/LocationStep/MapView";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { LocationsList } from "../../../WizardToolPage/types";
import SystemTooltip from "../common/SystemTooltip";
import { useHistory } from "react-router-dom";
import { SYSTEM, REPORTS } from "../../../../helpers/navigation/entries";

type Props = {
  className: string;
  viewMode: SystemsViewMode;
  onChange: (mode: SystemsViewMode) => void;
};

const MapViewContainer: FC<Props> = ({ className, viewMode, onChange }) => {
  const {
    fetchLocations,
    locations,
    selectedLocations,
    toggleLocationForMap,
  } = useSystemProvisioningContext();

  const [tooltip, setTooltip] = useState<LocationsList | undefined>(undefined);
  const history = useHistory();

  const clb = (name: string) => {
    history.push(SYSTEM(name).path + "/" + REPORTS().path);
  };

  const toggleLocation = (location: LocationsList) => {
    toggleLocationForMap(location, clb);
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <ViewTabs
        className={styles.viewTabs}
        viewMode={viewMode}
        onChange={onChange}
      />
      {locations ? (
        <MapView
          locations={locations}
          toggleLocation={toggleLocation}
          selectedLocations={selectedLocations || []}
          setTooltip={setTooltip}
          tooltipContent={tooltip && <SystemTooltip location={tooltip} />}
          tooltipClassName={styles.tooltip}
        />
      ) : (
        <PageLoader />
      )}
    </div>
  );
};
export default MapViewContainer;
