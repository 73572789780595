import { FC } from "react";
import ArrowDown from "../../../../components/icons/ArrowDown";
import ArrowUp from "../../../../components/icons/ArrowUp";
import { CRITICAL, GREEN } from "../../../../helpers/common/colorAliases";
import { StateStatus } from "../../types/connections";

import styles from "./StateCell.module.scss";

type Props = {
  administrative_state: StateStatus;
  operational_state: StateStatus;
};

export const getStatus = (v: StateStatus | string) =>
  v?.toLocaleLowerCase() === "up" || !v ? "Up" : "Down";

export const StateCell: FC<Props> = ({
  administrative_state,
  operational_state,
}) => {
  return (
    <div className={styles.cell}>
      <div className={styles.arrow}>
        <ArrowSymbol direction={administrative_state} />
        {getStatus(administrative_state)}
      </div>
      <div className={styles.arrow}>
        <ArrowSymbol direction={operational_state} />
        {getStatus(operational_state)}
      </div>
    </div>
  );
};

type ArrowSymbolProps = {
  direction: StateStatus;
};

const ArrowSymbol: FC<ArrowSymbolProps> = ({ direction }) => {
  if (direction === "up" || !direction) return <ArrowUp color={GREEN} />;

  return <ArrowDown color={CRITICAL} />;
};
