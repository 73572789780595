import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { PortType } from "../types";
import PortIcon from "../../../components/icons/PortIcon";
import { convertPortSpeed } from "../helpers/convertPortSpeed";
import { capitalizeFirstLetter } from "../../../helpers/common/stringHelpers";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";
import ArrowUp from "../../../components/icons/ArrowUp";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";
import ArrowDown from "../../../components/icons/ArrowDown";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import LagIcon from "../../../components/icons/LagIcon";

type SumProps = {
  title: string;
  data: PortType;
};

const SummaryBlock: FC<SumProps> = ({ title, data }) => {
  const isPort = data && data?.name.startsWith("hge");
  const isLag = data?.name.startsWith("lag") ? true : false;
  let opState = <LoaderIcon />;
  let rowIcon = <LoaderIcon />;
  const iconPort = isPort ? <PortIcon /> : <LagIcon />;
  if (data.operational_state?.toLowerCase() === "up") {
    rowIcon = <ArrowUp color={getColorFromStatus("up")} />;
    opState = (
      <TextWithIcon
        icon={rowIcon}
        text={capitalizeFirstLetter(data.operational_state)}
      />
    );
  } else {
    rowIcon = <ArrowDown color={getColorFromStatus("idle")} />;
    opState = (
      <TextWithIcon
        icon={rowIcon}
        text={capitalizeFirstLetter(data.operational_state)}
      />
    );
  }
  return (
    <SummaryInfoBlock icon={iconPort} title={title}>
      <UnifiedTextPair subtitle="Name" text={data.name} />
      {!isLag && <UnifiedTextPair subtitle="LAG" text={data.lag_name} />}
      {data.speed && (
        <UnifiedTextPair subtitle="Speed" text={convertPortSpeed(data.speed)} />
      )}
      <UnifiedTextPair subtitle="Op-state" text={opState} />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
