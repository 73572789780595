import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";

function DeleteIcon({ color, onClick }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        x="5"
        y="8"
        width="10"
        height="10"
        rx="1"
        fill={color || BUTTON_PRIMARY}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C7.44772 2 7 2.44772 7 3V4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6H16C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H13V3C13 2.44772 12.5523 2 12 2H8Z"
        fill={color || BUTTON_PRIMARY}
      />
    </svg>
  );
}

export default DeleteIcon;
