import { ASType, NeighborType } from "./../api/apiTypes";
import Validator from "./Validator";

export function validateAS(data: ASType, initValidator?: Validator): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.asn, "asn");
  data.asn && validator.checkIsNumeric(+data.asn, "asn");
  return validator;
}

export function validateNeighbor(
  data: Partial<NeighborType>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.remote_asn, "remote_asn");
  data.remote_asn && validator.checkIsNumeric(+data.remote_asn, "remote_asn");

  validator.checkEmpty(data.remote_router_ip, "remote_router_ip");
  validator.checkIP(data.remote_router_ip, "remote_router_ip");

  return validator;
}
