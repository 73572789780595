import React, { FC, useEffect } from "react";
import { useVRFSelectorContext } from "../../../contexts/systemsContext/VRFSelectorContext";
import { VRFType } from "../../../helpers/api/apiTypes";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import DropdownWithSearch from "../Dropdown/DropdownWithSearch";
type Props = {
  disabled?: boolean;
};
const ActiveVrfSelector: FC<Props> = ({ disabled }) => {
  const {
    list,
    fetchList,
    selectedVRF = "",
    setSelectedVRF,
  } = useVRFSelectorContext();

  useEffect(() => {
    if (!selectedVRF) {
      fetchList();
    }
  }, [selectedVRF]);

  useEffect(() => {
    if (list?.length && !selectedVRF) {
      setSelectedVRF(list[0]?.name);
    }
  }, [list]);

  return (
    <DropdownWithSearch
      id={"ActiveVRF"}
      onChange={(val) => setSelectedVRF(val.key)}
      selected={mapStringToItem(selectedVRF)}
      placeholder={selectedVRF}
      error={""}
      valuesList={list?.map((vrf: VRFType) => vrf.name)}
      isMedium
      disabled={disabled}
    />
  );
};

export default ActiveVrfSelector;
