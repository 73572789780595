import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { GREY_DISABLED } from "../../helpers/common/colorAliases";

function InfoIcon({
  color,
  className,
  height = 20,
  width = 20,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M0.7 9.99998C0.7 4.86161 4.86161 0.7 9.99998 0.7C15.1384 0.7 19.3 4.86157 19.3 9.99998C19.3 15.1384 15.1383 19.2999 9.99998 19.2999C4.86161 19.2999 0.7 15.1383 0.7 9.99998Z"
          stroke={color || GREY_DISABLED}
          strokeWidth="1.4"
        />
        <path
          d="M11 15H9C9 12.6569 9 11.3432 9 9.00003H11V15ZM11 7.00001H9V5H11V7.00001Z"
          fill={color || GREY_DISABLED}
        />
      </g>
    </svg>
  );
}

export default InfoIcon;
