import React, { FC } from "react";
import { useConnectionsContext } from "../../../../ConnectionContext";

type Props = { x: number; y: number; value: string };

const IPLabel: FC<Props> = ({ x, y, value }) => {
  const { setTooltip } = useConnectionsContext();
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x - 23 / 2}
      y={y - 20 / 2}
      onMouseEnter={() => setTooltip({ id: x + y + value, value })}
      onMouseLeave={() => setTooltip(undefined)}
      style={{ cursor: "help", userSelect: "none" }}
    >
      <rect
        x="1"
        y="1"
        width="21"
        height="18"
        rx="9"
        fill="white"
        stroke="#BEB8EA"
      />
      <text
        x={23 / 2}
        y={14}
        textAnchor="middle"
        fill="#BEB8EA"
        fontSize={12}
        fontWeight={600}
      >
        IP
      </text>
    </svg>
  );
};
export default IPLabel;
