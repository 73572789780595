import React, { FC } from "react";
import { classNames } from "../../helpers/common/classNames";
import styles from "./ElementWithTitle.module.scss";

type PropsType = {
  title: string;
  isTextElement?: boolean;
  className?: string;
};

const ElementWithTitle: FC<PropsType> = ({
  title,
  isTextElement,
  className,
  children,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={classNames(className, isTextElement && styles.text)}>
        {children}
      </div>
    </div>
  );
};

export default ElementWithTitle;
