import React, { FC, ReactNode } from "react";
import { classNames } from "../../../helpers/common/classNames";

import styles from "./PageHeader.module.scss";

type Props = {
  title?: string | ReactNode;
  right?: ReactNode;
  className?: string;
};

export const PageHeader: FC<Props> = ({ title, right, className }) => {
  return (
    <div className={classNames(styles.header, className)}>
      <span className={styles.title}>{title}</span>
      <div className={styles.right}>{right}</div>
    </div>
  );
};
