import React, { FC, ReactElement } from "react";
import styles from "./TabsComponent.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import BlankButton from "../buttons/BlankButton";

type TabsProps = {
  className?: string;
  tabs: Array<string>;
  activeTab: string;
  setTabActive: (tab: string) => void;
  isBtnMode?: boolean;
  rightComponent?: ReactElement;
};

const TabsComponent: FC<TabsProps> = (props) => {
  const { tabs, activeTab, setTabActive, className, isBtnMode } = props;

  return (
    <div className={classNames(styles.tabsContainer, className)}>
      {tabs.map((tab) => {
        return (
          <BlankButton
            id={tab}
            key={tab}
            className={classNames(
              styles.tab,
              activeTab === tab && styles.active,
              isBtnMode && styles.btnMode,
              isBtnMode && activeTab === tab && styles.btnModeActive
            )}
            onClick={() => setTabActive(tab)}
          >
            {isBtnMode ? tab : tab.toUpperCase()}
          </BlankButton>
        );
      })}
    </div>
  );
};

export default TabsComponent;
