import { ReactElement } from "react";

function OktaAuthIcon(): ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9961 25C20.9654 25 24.9939 20.9706 24.9939 16C24.9939 11.0294 20.9654 7 15.9961 7C11.0267 7 6.99829 11.0294 6.99829 16C6.99829 20.9706 11.0267 25 15.9961 25ZM15.9961 21C18.7568 21 20.9949 18.7614 20.9949 16C20.9949 13.2386 18.7568 11 15.9961 11C13.2353 11 10.9973 13.2386 10.9973 16C10.9973 18.7614 13.2353 21 15.9961 21Z"
        fill="#007DC1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.9989 2.80078C3.5081 2.80078 2.29956 4.04768 2.29956 5.5858V13.322C2.29956 14.3474 1.49387 15.1786 0.5 15.1786V15.179V16.6227V16.6231C1.49387 16.6231 2.29956 17.4544 2.29956 18.4798V26.216C2.29956 27.7541 3.5081 29.001 4.9989 29.001H6.49853V27.5569H4.9989C4.28111 27.5569 3.69922 26.9565 3.69922 26.216V18.4798C3.69922 17.4362 3.22981 16.5057 2.49696 15.9009C3.22981 15.296 3.69922 14.3655 3.69922 13.322V5.5858C3.69922 4.84522 4.28111 4.24486 4.9989 4.24486H6.49853V2.80078H4.9989Z"
        fill="#C2C3C3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9933 2.80078C28.4841 2.80078 29.6926 4.04768 29.6926 5.5858V13.322C29.6926 14.3474 30.4983 15.1786 31.4922 15.1786V15.179V16.6227V16.6231C30.4983 16.6231 29.6926 17.4544 29.6926 18.4798V26.216C29.6926 27.7541 28.4841 29.001 26.9933 29.001H25.4937V27.5569H26.9933C27.7111 27.5569 28.293 26.9565 28.293 26.216V18.4798C28.293 17.4362 28.7624 16.5057 29.4952 15.9009C28.7624 15.296 28.293 14.3655 28.293 13.322V5.5858C28.293 4.84522 27.7111 4.24486 26.9933 4.24486H25.4937V2.80078H26.9933Z"
        fill="#C2C3C3"
      />
    </svg>
  );
}

export default OktaAuthIcon;
