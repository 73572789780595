import * as React from "react";

import {
  LOGIN,
  WIZARDS,
  SERVICES,
  TENANTS,
  NETWORK,
  INSIGHTS,
  ZAYO_CONNECTIONS,
  ZAYO_REPORT_USAGE_BILLING,
  ENTER_LICENSE_KEY,
  EVENTS,
  SERVICES_PREFERENCES,
  NETWORK_RESOURCES,
  SEARCH,
  IPAM,
  REMOTE_USER,
  CONNECTIONS,
} from "../helpers/navigation/entries";
import { Redirect, Route, Switch } from "react-router-dom";

import AuthRoute from "./navigation/AuthRoute";
import LoginPage from "../pages/LoginPage/LoginPage";

import TenantsContextContainer from "../contexts/tenantsContext/TenantsContext";
import WizardToolPage from "../pages/WizardToolPage";
import { useUserContext } from "../contexts/UserContext";
import { UserRoles } from "../helpers/rolesHelpers";
import TimerangeContextContainer from "../contexts/TimerangeContext";
import { useTutorialContext } from "../contexts/TutorialContext";
import Tutorial from "../pages/TutorialPage/Tutorial";
import Connections from "../pages/ZayoPages/Connections";
import ReportUsageBilling from "../pages/ZayoPages/ReportUsageBilling";
import { PageLoader } from "./common/loadStates/LoaderIcon";
import NotificationPopup from "./NotificationPopup/NotificationPopup";
import { useNotificationsContext } from "../contexts/NotificationsContext";
import EnterLicenseKeyPage from "../pages/EnterLicenseKeyPage/EnterLicenseKeyPage";
import { EnterLicenseKeyContextContainer } from "../pages/EnterLicenseKeyPage/context/EnterLicenseKeyPageContext";
import EventsPage from "../pages/EventsPage";
import ServicesPreferencesPage from "../pages/ServicesPreferencesPage";
import Services from "../routing/Services";
import Systems from "../routing/Systems";
import Network from "../routing/Network";
import Tenants from "../routing/Tenants";
import SearchPage from "../pages/SearchPage";
import InsightsPage from "../pages/InsightsPage";
import IpamRoutes from "../pages/IPAMPage/IpamRoutes";
import RemoteUserPage from "../pages/RemoteUserPage/RemoteUserPage";
import WireGuardApp from "../pages/WireGuardAppPage/WireGuardApp";
import { CheckSSOPage } from "../pages/CheckSSOPage/CheckSSOPage";

export const Router = (): React.ReactElement => {
  const { isZayo, isDemo } = useUserContext();

  if (isZayo === undefined || isDemo === undefined) return <PageLoader />;
  return <PagePicker />;
};

function PagePicker() {
  const { user, isZayo, isDemo } = useUserContext();
  const { showNotification } = useNotificationsContext();
  const isTenant = React.useMemo(() => user?.role === UserRoles.TENANT, [user]);

  return (
    <Switch>
      <Route path={"/" + LOGIN().path} exact>
        <LoginPage />
      </Route>
      <Route path={"/sso"} exact>
        <CheckSSOPage />
      </Route>
      <Route path={"/mobile/app/login"} exact>
        <WireGuardApp />
      </Route>
      <AuthRoute>
        {getRoutes(isTenant, isZayo, isDemo)}
        {showNotification && <NotificationPopup />}
      </AuthRoute>
    </Switch>
  );
}

const getRoutes = (isTenant: boolean, isZayo?: boolean, isDemo?: boolean) => {
  let routes;
  if ((isZayo || isDemo) && isTenant) {
    routes = <ZayoRoutes />;
  } else if (isTenant) {
    routes = <TenantRoutes />;
  } else {
    routes = <AdminRoutes />;
  }
  return (
    <TimerangeContextContainer>
      <TenantsContextContainer>{routes}</TenantsContextContainer>
    </TimerangeContextContainer>
  );
};

const AdminRoutes = () => {
  const { isVisible: tutorialIsVisible } = useTutorialContext();
  const { isZayo } = useUserContext();
  return (
    <Tutorial visible={tutorialIsVisible}>
      <Switch>
        <Route path="/" exact>
          <Redirect
            to={"/" + isZayo ? NETWORK().path : NETWORK_RESOURCES().path}
          />
        </Route>
        <Route path={"/" + CONNECTIONS().path}>
          <Connections />
        </Route>
        <Route path={"/" + EVENTS().path}>
          <EventsPage type="tenant" tabs={(<></>) as any} />
        </Route>
        <Route path={"/" + IPAM().path}>
          <IpamRoutes />
        </Route>
        <Route path={"/" + NETWORK_RESOURCES().path}>
          <Systems />
        </Route>
        <Route path={"/" + TENANTS().path}>
          <Tenants />
        </Route>
        <Route path={"/" + INSIGHTS().path}>
          <InsightsPage />
        </Route>
        <Route path={"/" + NETWORK().path}>
          <Network />
        </Route>
        <Route path={"/" + SERVICES().path}>
          <Services />
        </Route>
        <Route path={"/" + ENTER_LICENSE_KEY().path}>
          <EnterLicenseKeyContextContainer>
            <EnterLicenseKeyPage />
          </EnterLicenseKeyContextContainer>
        </Route>
        <Route path={"/" + SERVICES_PREFERENCES().path}>
          <ServicesPreferencesPage />
        </Route>
        <Route path={"/" + SEARCH().path + "*"}>
          <SearchPage tabs={(<></>) as any} />
        </Route>
        <Route path={"/" + REMOTE_USER().path}>
          <RemoteUserPage />
        </Route>
        <Route>
          <Redirect
            to={"/" + isZayo ? NETWORK().path : NETWORK_RESOURCES().path}
          />
        </Route>
      </Switch>
    </Tutorial>
  );
};

const TenantRoutes = () => {
  const { isTenantEmpty } = useUserContext();
  const { isVisible: tutorialIsVisible } = useTutorialContext();

  if (isTenantEmpty) {
    return (
      <>
        <Route path="/" exact>
          <Redirect to={WIZARDS().path} />
        </Route>
        <Route path={"/" + INSIGHTS().path}>
          <Redirect to={"/" + WIZARDS().path} />
        </Route>
        <Route path={"/" + WIZARDS().path}>
          <WizardToolPage />
        </Route>
        <Route path={"/" + SEARCH().path + "*"}>
          <SearchPage tabs={(<></>) as any} />
        </Route>
        <Route path={"/" + REMOTE_USER().path}>
          <RemoteUserPage />
        </Route>
      </>
    );
  }

  return (
    <Tutorial visible={tutorialIsVisible}>
      <Route path="/" exact>
        <Redirect to={INSIGHTS().path} />
      </Route>
      <Route path={"/" + INSIGHTS().path}>
        <InsightsPage />
      </Route>
      <Route path={"/" + EVENTS().path}>
        <EventsPage type="tenant" tabs={(<></>) as any} />
      </Route>
      <Route path={"/" + SERVICES().path}>
        <Services />
      </Route>
      <Route path={"/" + WIZARDS().path}>
        <WizardToolPage />
      </Route>
      <Route path={"/" + NETWORK().path}>
        <Network />
      </Route>
      <Route path={"/" + SERVICES_PREFERENCES().path}>
        <ServicesPreferencesPage />
      </Route>
      <Route path={"/" + SEARCH().path + "*"}>
        <SearchPage tabs={(<></>) as any} />
      </Route>
      <Route path={"/" + REMOTE_USER().path}>
        <RemoteUserPage />
      </Route>
    </Tutorial>
  );
};

const ZayoRoutes = () => {
  const { isVisible: tutorialIsVisible } = useTutorialContext();

  return (
    <Tutorial visible={tutorialIsVisible}>
      <Switch>
        <Route exact path={"/"}>
          <Redirect to={"/" + ZAYO_CONNECTIONS().path} />
        </Route>
        <Route path={"/" + ZAYO_CONNECTIONS().path}>
          <Connections />
        </Route>
        <Route path={"/" + EVENTS().path}>
          <EventsPage type="tenant" tabs={(<></>) as any} />
        </Route>
        <Route path={"/" + INSIGHTS().path}>
          <InsightsPage />
        </Route>
        <Route path={"/" + ZAYO_REPORT_USAGE_BILLING().path}>
          <ReportUsageBilling />
        </Route>
        <Route path={"/" + IPAM().path}>
          <IpamRoutes />
        </Route>
        <Route path={"/" + SERVICES().path}>
          <Services />
        </Route>
        <Route path={"/" + NETWORK().path}>
          <Network />
        </Route>
        <Route path={"/" + TENANTS().path}>
          <Tenants />
        </Route>
        <Route path={"/" + WIZARDS().path}>
          <WizardToolPage />
        </Route>
        <Route path={"/" + SERVICES_PREFERENCES().path}>
          <ServicesPreferencesPage />
        </Route>
        <Route path={"/" + SEARCH().path + "*"}>
          <SearchPage tabs={(<></>) as any} />
        </Route>
        <Route path={"/" + REMOTE_USER().path}>
          <RemoteUserPage />
        </Route>
        <Route>
          <Redirect to={"/" + ZAYO_CONNECTIONS().path} />
        </Route>
      </Switch>
    </Tutorial>
  );
};
