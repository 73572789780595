import React, { ReactElement, useEffect, useMemo } from "react";
import styles from "./EventsIcon.module.scss";
import BellIcon from "../../../icons/BellIcon";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { useUserContext } from "../../../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  EVENTS,
  NETWORK_RESOURCES,
} from "../../../../helpers/navigation/entries";
import IconBlankButton from "../../../common/buttons/IconBlankButton";
import { GREY_FONT, PINK } from "../../../../helpers/common/colorAliases";
import EventsIconContextContainer, {
  useEventsIconContext,
} from "./EventsIconContext";
import { setEventsStartTime } from "../../../../helpers/setEventsStartTime";

const EventsIcon = (): ReactElement => {
  const history = useHistory();
  const { user } = useUserContext();
  const { fetchList, eventsAmount } = useEventsIconContext();

  const isAdmin = useMemo(() => user?.role === "admin", [user]);

  useEffect(() => {
    if (user) {
      fetchList(!isAdmin ? "tenant" : undefined);
    }
  }, [user]);

  const handleClick = () => {
    const path = isAdmin ? ADMIN_EVENT_PATH : TENANT_EVENT_PATH;
    history.replace(path);
  };

  const handleHover = () => {
    setEventsStartTime();
  };

  const isUpdated = useMemo(() => eventsAmount > 0, [eventsAmount]);
  return (
    <div
      className={styles.wrapper}
      onClick={handleClick}
      onMouseEnter={handleHover}
    >
      <IconBlankButton
        id="eventIcon"
        icon={BellIcon}
        className={styles.icon}
        color={isUpdated ? PINK : GREY_FONT}
        hint={"Events"}
      />
      {isUpdated && <div className={styles.notification}>{eventsAmount}</div>}
    </div>
  );
};

export default withContexts(EventsIcon, [EventsIconContextContainer]);

const ADMIN_EVENT_PATH =
  formPathFromArray([NETWORK_RESOURCES(), EVENTS()]) + "?fromIcon";
const TENANT_EVENT_PATH = "/" + EVENTS().path + "?fromIcon";
