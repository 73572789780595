import { ReactElement } from "react";

function SamlIcon(): ReactElement {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.508 0L14.434 0.82C15.12 1.428 15.808 2.034 16.486 2.65C20.88 6.64 24.6 11.13 27.4 16.298C27.544 16.562 27.674 16.834 27.804 17.104L28 17.51L27.784 17.624L27.622 17.394L27.202 16.796L26.908 16.368C24.87 13.41 22.828 10.448 20.024 8.078C19.1756 7.3598 18.2395 6.75227 17.238 6.27C15.21 5.3 13.406 5.688 11.858 7.28C10.386 8.794 9.622 10.674 8.932 12.586C8.842 12.832 8.748 13.076 8.654 13.32L8.49 13.75L8.146 13.64C8.346 12.944 8.53 12.244 8.714 11.542C9.134 9.952 9.554 8.362 10.138 6.83C10.758 5.198 11.542 3.624 12.324 2.05C12.662 1.368 13 0.686 13.324 0H13.508Z"
        fill="#c22e33"
      />
      <path
        d="M14.896 19.6443C11.644 21.1843 3.77605 22.7723 0.0440473 22.3643C-0.565953 19.1523 5.42005 4.10031 8.36005 1.82031L8.17005 2.25631C8.08533 2.46155 7.99327 2.66368 7.89405 2.86231L7.76205 3.12031C6.24205 6.04431 4.72405 8.97231 3.94605 12.1803C3.66588 13.2853 3.51289 14.4186 3.49005 15.5583C3.47005 18.2383 5.13805 20.0623 7.92405 20.1763C9.26405 20.2303 10.618 20.0903 11.97 19.9483C12.558 19.8883 13.144 19.8263 13.73 19.7803C13.946 19.7643 14.158 19.7383 14.434 19.7023L14.896 19.6463V19.6443Z"
        fill="#c22e33"
      />
      <path
        d="M5.65013 25.5613C4.76013 25.6293 3.87013 25.6973 2.98413 25.7773C11.1781 26.5573 19.0441 25.2233 26.8041 22.4893C24.2741 18.0293 20.8641 14.5073 16.9001 11.4453C16.9521 11.6413 17.0681 11.7833 17.1821 11.9233L17.2781 12.0433C17.6181 12.4953 17.9741 12.9393 18.3321 13.3833C19.1501 14.4013 19.9681 15.4193 20.5841 16.5393C22.1401 19.3793 21.2961 21.8353 18.2481 23.1313C16.2441 23.9853 14.0541 24.5673 11.8881 24.9153C9.82813 25.2433 7.73813 25.4013 5.65013 25.5613Z"
        fill="#c22e33"
      />
    </svg>
  );
}

export default SamlIcon;
