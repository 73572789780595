import { FC } from "react";
import DashboardLeft from "./components/leftPart/DashboardLeft";
import DashboardRight from "./components/rightPart/DashboardRight";
import styles from "./TopologyPage.module.scss";

const TopologyPage: FC<{ tabs: any }> = ({ tabs: Tabs }) => {
  return (
    <>
      <Tabs />
      <div className={styles.twoColumns}>
        <DashboardLeft />
        <DashboardRight />
      </div>
    </>
  );
};

export default TopologyPage;
