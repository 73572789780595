import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function CaretDown({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="8"
      height="3"
      viewBox="0 0 8 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m4 3 3.464-3H.536L4 3Z" fill={color || FONT} />
    </svg>
  );
}

export default CaretDown;
