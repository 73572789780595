import { EventLabelType } from "../types";

export const getTypeByActivity = (activity: string): EventLabelType => {
  switch ((activity || "").toLowerCase()) {
    case "create":
      return EventLabelType.GREEN;
    case "update":
      return EventLabelType.ORANGE;
    case "delete":
      return EventLabelType.RED;
    default:
      return EventLabelType.DEFAULT;
  }
};
