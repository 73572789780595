import React from "react";
import Header from "../../../components/Header/Header";
import NavigationBar from "../../../components/NavigationBar";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./WizardLayoutComponent.module.scss";
import WizardNavigationBar from "./WizardNavigationBar";

type Props = {};

const WizardLayoutComponent: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={classNames(styles.content)}>{children}</div>
      <WizardNavigationBar />
      <NavigationBar />
    </div>
  );
};

export default WizardLayoutComponent;
