import { FC } from "react";
import Input from "../../../../../../components/common/formComponents/Input";
import SegmentsComponent from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/common/SegmentsComponent";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import DefaultConnectionFields from "../DefaultConnectionFields/DefaultConnectionFields";

type Props = {
  className: string;
};
const LoopbackConnection: FC<Props> = ({ className }) => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  return (
    <DefaultConnectionFields className={className}>
      <Input
        label="IP"
        name="ip"
        placeholder=""
        value={fields.ip}
        medium
        error={""}
        onChange={(e) => onChange("ip", e.target.value)}
      />
      <SegmentsComponent
        list={fields.network_segments || []}
        onChange={onChange}
        error={""}
      />
    </DefaultConnectionFields>
  );
};

export default LoopbackConnection;
