import React, { FC, useEffect, useState } from "react";
import { ADD, SAVE } from "../../../../helpers/common/constantsAlias";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import Footer from "./common/Footer";
import GeneralSection from "./components/GeneralSection";
import { useNATRuleConfigurationContext } from "./NATRuleConfigurationContext";

import styles from "./ConfigurationContent.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import OutsideSection from "./components/OutsideSection";
import FilterSection from "./components/FilterSection";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import usePrevious from "../../../../helpers/hooks/usePrevious";
import { SOURCE_NAT } from "../../const";
import { FieldsType } from "../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { useValidation } from "../../../../helpers/validators/Validator";
import { validateNATConfig } from "../../../../helpers/validators/NATConfigValidator";

const DEFAULT_FIELDS: { [key: string]: any } = {};

type Props = {
  className: string;
  onClose: () => void;
  fetchData: (tenant: string) => void;
  data?: FieldsType;
};

const ConfigurationContent: FC<Props> = ({
  className,
  onClose,
  fetchData,
  data,
}) => {
  const isEdit = !!data;
  const { selectedTenant } = useGlobalFilterContext();
  const { addStatus, add, edit, editStatus } = useNATRuleConfigurationContext();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

  const [fields, handleFieldChange, resetFields] = useFormField({
    ...(data || DEFAULT_FIELDS),
  });
  const [errors, validate] = useValidation<any>(validateNATConfig, [fields]);

  const prevType = usePrevious(fields.rule_type, undefined);

  useEffect(() => {
    if (prevType !== fields.rule_type && !data) {
      resetFields({
        name: fields.name,
        inside: "",
        rule_type: fields.rule_type,
      });
    }
  }, [fields.rule_type]);

  const handleAdd = async () => {
    if (selectedTenant) {
      const { isOk } = validate();
      if (isOk) {
        const res = await add(fields, selectedTenant);
        if (res) {
          fetchData(selectedTenant);
          onClose();
        }
      }
    }
  };

  const handleEdit = async () => {
    const { isOk } = validate();
    if (selectedTenant && data && isOk) {
      const res = await edit(fields, selectedTenant, data?.name);
      if (res) {
        fetchData(selectedTenant);
        onClose();
      }
    }
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.content}>
        <GeneralSection
          fields={fields}
          onChange={handleFieldChange}
          className={styles.sectionWrapper}
          isEdit={isEdit}
          errors={errors}
          validate={validate}
        />
        {fields?.rule_type === SOURCE_NAT && (
          <FilterSection
            fields={fields}
            onChange={handleFieldChange}
            className={styles.sectionWrapper}
            isEdit={isEdit}
          />
        )}
        {isAdvanced && (
          <OutsideSection
            fields={fields}
            onChange={handleFieldChange}
            className={styles.sectionWrapper}
            isEdit={isEdit}
          />
        )}
      </div>
      <Footer
        isAdvanced={isAdvanced}
        disableAdvanced={true}
        onToggle={() => setIsAdvanced((prev) => !prev)}
        onAction={isEdit ? handleEdit : handleAdd}
        action={isEdit ? SAVE : ADD}
        onClose={onClose}
        status={addStatus || editStatus}
      />
    </div>
  );
};

export default ConfigurationContent;
