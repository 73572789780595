import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../../../components/common/buttons/Button";
import NavigationTabs from "../../../../components/common/navigation/NavigationTabs";
import {
  USAGE_REPORT,
  ZAYO_SERVICES,
} from "../../../../helpers/navigation/entries";

import styles from "./ReportUsageBillingTab.module.scss";

export const ReportUsageBillingTab: React.FC<{}> = () => {
  const { location } = useHistory();
  const isReportUsage = useMemo(
    () => location.pathname.includes(USAGE_REPORT().path),
    [location]
  );

  return (
    <div className={styles.tabsWrapper}>
      <TabsComponent />
      <div className={styles.controls}>
        {isReportUsage && (
          <Button onClick={() => undefined} className={styles.btn}>
            Send Mail
          </Button>
        )}
        <Button onClick={() => undefined} className={styles.btn}>
          Download PDF
        </Button>
      </div>
    </div>
  );
};
export const TabsComponent: React.FC = () => {
  return <NavigationTabs entries={servicesArray} disabledTabKeys={[]} />;
};

const servicesArray = [USAGE_REPORT(), ZAYO_SERVICES()];
