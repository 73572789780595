export enum APPLICATION_FILTER_FIELD {
  LOCATION = "locations",
  USER = "users",
  SRC_SEGMENTS = "srcSegments",
  SRC_CONNECTIONS = "srcConnections",
  DST_CONNECTIONS = "dstConnections",
  SRC_IP = "srcIPs",
  DST_IP = "dstIP",
  HOSTNAMES = "hostnames",
}

export const APPLICATION_FIELDS_MAP: Array<string> = [
  APPLICATION_FILTER_FIELD.LOCATION,
  APPLICATION_FILTER_FIELD.SRC_SEGMENTS,
  APPLICATION_FILTER_FIELD.SRC_CONNECTIONS,
  APPLICATION_FILTER_FIELD.DST_CONNECTIONS,
  APPLICATION_FILTER_FIELD.SRC_IP,
  APPLICATION_FILTER_FIELD.DST_IP,
  APPLICATION_FILTER_FIELD.HOSTNAMES,
];

export const APPLICATION_FIELD_VALUE: { [key: string]: string } = {
  [APPLICATION_FILTER_FIELD.LOCATION]: "Location",
  [APPLICATION_FILTER_FIELD.SRC_SEGMENTS]: "Incoming Segments",
  [APPLICATION_FILTER_FIELD.SRC_CONNECTIONS]: "Incoming Connection",
  [APPLICATION_FILTER_FIELD.DST_CONNECTIONS]: "Outgoing Connections",
  [APPLICATION_FILTER_FIELD.SRC_IP]: "Source_IP",
  [APPLICATION_FILTER_FIELD.DST_IP]: "Destination_IP",
  [APPLICATION_FILTER_FIELD.HOSTNAMES]: "Hostname",
};

export const APPLICATION_FIELD_LABEL: { [key: string]: string } = {
  [APPLICATION_FILTER_FIELD.LOCATION]: "Location",
  [APPLICATION_FILTER_FIELD.SRC_SEGMENTS]: "Source Segments",
  [APPLICATION_FILTER_FIELD.SRC_CONNECTIONS]: "Source Connection",
  [APPLICATION_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connection",
  [APPLICATION_FILTER_FIELD.SRC_IP]: "Source IPs",
  [APPLICATION_FILTER_FIELD.DST_IP]: "Destination IPs",
  [APPLICATION_FILTER_FIELD.HOSTNAMES]: "Hostnames",
};

export const LOCALSTORAGE_APPLICATION_KEY = "LOCALSTORAGE_APPLICATION_KEY";
