import React from "react";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { IconProps } from "../../../helpers/types";
import { GREY_FONT } from "../../../helpers/common/colorAliases";

function SDWANIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...formIconAttrs(24, 24, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.5788C13.464 4.35277 14.1818 3.48748 14.1818 2.45454C14.1818 1.24955 13.205 0.27272 12 0.27272C10.795 0.27272 9.81818 1.24955 9.81818 2.45454C9.81818 3.48748 10.536 4.35277 11.5 4.5788V9.30516C10.201 9.47506 9.1074 10.2979 8.55937 11.4334L4.35147 10.05C4.35951 9.97382 4.36364 9.89648 4.36364 9.81818C4.36364 8.61319 3.3868 7.63636 2.18182 7.63636C0.976833 7.63636 0 8.61319 0 9.81818C0 11.0232 0.976833 12 2.18182 12C2.95394 12 3.63239 11.5989 4.02018 10.9937L8.24723 12.3834C8.20428 12.6127 8.18182 12.8492 8.18182 13.0909C8.18182 14.1669 8.6269 15.1389 9.34299 15.8329L6.5915 19.5415C6.32649 19.427 6.03429 19.3636 5.72727 19.3636C4.52229 19.3636 3.54545 20.3405 3.54545 21.5454C3.54545 22.7504 4.52229 23.7273 5.72727 23.7273C6.93226 23.7273 7.90909 22.7504 7.90909 21.5454C7.90909 21.009 7.71546 20.5177 7.39428 20.1377L10.1457 16.4294C10.6947 16.735 11.327 16.9091 12 16.9091C12.6587 16.9091 13.2784 16.7423 13.8193 16.4486L16.5483 20.2086C16.2616 20.5779 16.0909 21.0417 16.0909 21.5454C16.0909 22.7504 17.0677 23.7273 18.2727 23.7273C19.4777 23.7273 20.4545 22.7504 20.4545 21.5454C20.4545 20.3405 19.4777 19.3636 18.2727 19.3636C17.9338 19.3636 17.6129 19.4409 17.3268 19.5788L14.6281 15.8607C15.3611 15.1649 15.8182 14.1813 15.8182 13.0909C15.8182 12.882 15.8014 12.677 15.7691 12.4772L19.9493 11.2174C20.3314 11.8499 21.0254 12.2727 21.8182 12.2727C23.0232 12.2727 24 11.2959 24 10.0909C24 8.88592 23.0232 7.90908 21.8182 7.90908C20.6132 7.90908 19.6364 8.88592 19.6364 10.0909C19.6364 10.1496 19.6387 10.2077 19.6432 10.2653L15.4809 11.5196C14.9475 10.3397 13.8316 9.47933 12.5 9.30516V4.5788Z"
        fill={GREY_FONT}
      />
    </svg>
  );
}

export default SDWANIcon;
