import React, { FC, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSystemProvisioningContext } from "../ProvisioningContextContainer";
import { System, SystemsViewMode } from "../types";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import { ActionFuncs } from "../../../../components/common/table/newTable/RowControls";
import { REPORTS, SYSTEM } from "../../../../helpers/navigation/entries";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import AddButton from "../../../../components/common/buttons/AddButton";
import Table from "../../../../components/common/table/newTable/Table";
import { provisioningTableHeader } from "../table/provisioningTableHeader";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { checkIsActive } from "../../../../helpers/getNodeFromSystem";
import ViewTabs from "../common/ViewTabs";

import styles from "./TableViewContainer.module.scss";
import SwitchNodeDialog from "./components/SwitchNodeDialog";

type Props = {
  className: string;
  viewMode: SystemsViewMode;
  onChange: (mode: SystemsViewMode) => void;
  expandCharts: () => void;
  showAdd: any;
  setShowAdd: any;
  status: any;
  withoutPagination?: boolean;
  fetchAction: (offset?: number, limit?: number) => void;
};

const TableViewContainer: FC<Props> = ({
  className,
  viewMode,
  onChange,
  expandCharts,
  setShowAdd,
  status,
  fetchAction,
}) => {
  const history = useHistory();
  const {
    list = [],
    count,
    remove,
    removeStatus,
    removeSystem,
    selectRow,
    selectedRow,
    selectNode,
  } = useSystemProvisioningContext();

  const removeSystemItem = (data: System) => {
    if (data.subRows) {
      removeSystem(data.name);
    } else {
      remove(data);
    }
  };

  const [, setViewItem] = useState<System | undefined>();
  const [switchConfirm, setSwitchConfirm] = useState<boolean>(false);

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<System>(removeSystemItem, removeStatus, () => {
    setViewItem(undefined);
  });

  const rowActions: ActionFuncs<System> = useMemo(() => {
    return {
      onDelete: tryDelete,
      // hideFor: { onDelete: "parentRow" },
    };
  }, [tryDelete]);

  const handleClickRow = (row: { [key: string]: any; name: string }): void => {
    if (!row.subRows) {
      selectNode(row as any);
      setShowAdd(true);
      return;
    }
    history.push(SYSTEM(row.name).path + "/" + REPORTS().path);
  };

  const handleSelectRow = (row: any) => {
    selectRow(row);
    expandCharts();
  };

  const handleAddNode = (row: any) => {
    selectRow(row);
    setShowAdd(true);
  };

  const handleSwitchNode = (row: any): void => {
    selectNode(row as any);
    setSwitchConfirm(true);
  };

  const tableControls = (
    <div className={styles.tableControls}>
      <AddButton onClick={() => setShowAdd(true)} />
      <ViewTabs viewMode={viewMode} onChange={onChange} />
    </div>
  );

  return (
    <>
      {switchConfirm && (
        <SwitchNodeDialog onClose={() => setSwitchConfirm(false)} />
      )}
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Node"}
        name={selectedToDelete?.name}
      />
      <TableWrapper
        className={className}
        titleProps={{
          title: `Nodes(${(list || []).length})`,
          children: tableControls,
        }}
        dataStatus={status}
        tablePlaceholder={["Nodes", "Node"]}
        isEmpty={list && list.length === 0}
      >
        <Table
          fetchAction={fetchAction}
          columns={provisioningTableHeader(
            handleAddNode,
            handleSelectRow,
            handleSwitchNode,
            selectedRow || "all"
          )}
          header={TableHeader}
          count={count}
          data={list || []}
          gridColumnTemplate={`30px repeat(7, 1fr) 72px 24px`}
          rowActions={rowActions}
          onClick={handleClickRow}
          isRowHighlighted={(row) => checkIsActive(row, list)}
        />
      </TableWrapper>
    </>
  );
};
export default TableViewContainer;
