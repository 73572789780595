import React from "react";
import styles from "./DashboardRight.module.scss";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getApplicationLink } from "../../../../pages/ApplicationObservabilityPage/components/ApplicationObservabilityStat/components/Statistics/components/StatisticsBody";
import { useDashboardFilterContext } from "../../../../contexts/dashboardContext/DashboardFilterContext";
import InterfaceInfoCart from "./InterfaceInfoCart";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import BranchTraffic from "./components/charts/BranchTraffic";
import NewTenantTraffic from "./components/charts/NewTenantTraffic";
import ApplicationObservability from "./components/charts/ApplicationObservability";

const DashboardRight: React.FC<{}> = ({}) => {
  const { timeRange } = useTimerangeContext();
  const { selectedInterface } = useDashboardFilterContext();
  const { selectedTenant } = useGlobalFilterContext();

  if (selectedInterface) {
    return (
      <GrafanaContainer type={GRAFANA_TYPES.DPI} className={styles.rightPart}>
        <div className={styles.onePart}>
          <BranchTraffic interfaceInfo={selectedInterface} />
          <InterfaceInfoCart
            interfaceInfo={selectedInterface}
            inBand={20}
            outBand={40}
          />
        </div>
      </GrafanaContainer>
    );
  }

  return (
    <GrafanaContainer
      type={GRAFANA_TYPES.DPI}
      className={styles.rightPart}
      showInsteadPlaceholder={<NewTenantTraffic className={styles.traffic} />}
    >
      <div className={styles.demoTopPart}>
        <ApplicationObservability />
      </div>
      <div className={styles.demoBottomPart}>
        <NewTenantTraffic className={styles.left} />
        <GrafanaGraph
          link={getApplicationLink(10, timeRange.general, selectedTenant)}
        />
      </div>
    </GrafanaContainer>
  );
};
export default DashboardRight;
