export const CLOUDS_TYPES_MAP = ["aws", "azure", "gcp", "oracle", "alibaba"];

const getGroupsFromTypes = (
  list: Array<string>
): { [key: string]: Array<any> } => {
  const groups: { [key: string]: Array<any> } = {};
  list.forEach((element) => {
    groups[element] = [];
  });
  return groups;
};

export const CLOUDS_TYPES_GROUPS = getGroupsFromTypes(CLOUDS_TYPES_MAP);

export const azureLocations = {
  "East Asia": "eastasia",
  "Southeast Asia": "southeastasia",
  "Central US": "centralus",
  "East US": "eastus",
  "East US 2": "eastus2",
  "West US": "westus",
  "North Central US": "northcentralus",
  "South Central US": "southcentralus",
  "North Europe": "northeurope",
  "West Europe": "westeurope",
  "Japan West": "japanwest",
  "Japan East": "japaneast",
  "Brazil South": "brazilsouth",
  "Australia East": "australiaeast",
  "Australia Southeast": "australiasoutheast",
  "South India": "southindia",
  "Central India": "centralindia",
  "West India": "westindia",
  "Jio India West": "jioindiawest",
  "Jio India Central": "jioindiacentral",
  "Canada Central": "canadacentral",
  "Canada East": "canadaeast",
  "UK South": "uksouth",
  "UK West": "ukwest",
  "West Central US": "westcentralus",
  "West US 2": "westus2",
  "Korea Central": "koreacentral",
  "Korea South": "koreasouth",
  "France Central": "francecentral",
  "France South": "francesouth",
  "Australia Central": "australiacentral",
  "Australia Central 2": "australiacentral2",
  "UAE Central": "uaecentral",
  "UAE North": "uaenorth",
  "South Africa North": "southafricanorth",
  "South Africa West": "southafricawest",
  "Switzerland North": "switzerlandnorth",
  "Switzerland West": "switzerlandwest",
  "Germany North": "germanynorth",
  "Germany West Central": "germanywestcentral",
  "Norway West": "norwaywest",
  "Norway East": "norwayeast",
  "Brazil Southeast": "brazilsoutheast",
  "West US 3": "westus3",
  "Sweden Central": "swedencentral",
  "Qatar Central": "qatarcentral",
};
