import React, { FC } from "react";
import { isPending } from "../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../helpers/types";
import FieldError from "../formComponents/FieldError";
import FieldLoader from "../formComponents/FieldLoader";

type Props = {
  status: RequestStatus;
  pendingMessage: string;
};
const InputWithStatus: FC<Props> = ({ status, pendingMessage, children }) => {
  if (isPending(status)) {
    return <FieldLoader message={pendingMessage} />;
  }
  if (status?.state === "error") {
    return <FieldError message={status.message} />;
  }
  return <>{children}</>;
};

export default InputWithStatus;
