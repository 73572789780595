import React, { ReactElement } from "react";

function GradientalBackground(props: { className?: string }): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="720"
      height="800"
      viewBox="0 0 720 800"
      fill="none"
      className={props.className}
    >
      <g filter="url(#a)">
        <path
          fill="#ff5f01"
          d="M323.156-205.922c48.858-133.949 182.069-32.343 242.568 35.204 12.305 13.739 31.826 45.509-19.784 74.703-31.431 17.78-122.144 31.77-222.784 38.639-100.64 6.87-51.61 167.436-203 270.474-151.39 103.037-55.051-90.158-19.784-218.955 35.267-128.797 161.712-32.63 222.784-200.065z"
        />
        <path
          fill="#ff5f01"
          d="M419.347 511.045c75.512-179.608 281.395-43.367 374.897 47.205 19.018 18.421 49.189 61.021-30.576 100.166-48.579 23.84-188.778 42.599-344.321 51.81-155.542 9.211-79.765 224.51-313.743 362.674-233.979 138.16-85.083-120.894-30.577-293.594s249.931-43.751 344.32-268.261z"
          opacity="0.8"
        />
        <path
          fill="#FF5EDB"
          d="M259.197-37.876c118.926-104.492 374.997-46.32 444.466 25.598 14.129 14.627 40 166.816-19.262 197.899-36.091 18.93-67.915 107.804-183.477 115.117-115.561 7.314-129.462 0-342.09 0-205.463 0-226.544 22.012-186.048-115.117C13.28 48.491 103.124 99.254 259.197-37.876z"
        />
        <path
          fill="#6e39df"
          d="M222.971 152.753c86.211-41.006 289.044-38.721 330.503 3.132 83.548 84.343-548.02 187.641-589.238 403.931-24.16 126.773-16.374-244.845 19.78-309.069 36.153-64.225 125.816-44.181 238.955-97.994z"
          opacity="0.5"
        />
        <path
          fill="#FFE793"
          d="M467.838-130.41c-61.28 114.555 181.6 199.264 244.429 167.346 62.828-31.917 0-167.346-62.829-199.263-62.828-31.916-120.321-82.638-181.6 31.917z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="1198"
          height="1695.58"
          x="-226"
          y="-427.585"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur
            result="effect1_foregroundBlur_575:15859"
            stdDeviation="78"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default GradientalBackground;
