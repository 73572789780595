import { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { GREY_FONT } from "../../helpers/common/colorAliases";

function BranchesIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" fill="white" />
      <path
        d="M17 9H1.5C1.22386 9 1 9.22386 1 9.5V22.5C1 22.7761 1.22386 23 1.5 23H17M11 23L9.12572 18.3143C9.04979 18.1245 8.86594 18 8.66148 18H2.73852C2.38478 18 2.14291 18.3573 2.27428 18.6857L4 23H11ZM11 23H13.6743C14.3386 23 14.8183 22.3641 14.6358 21.7253L13.5181 17.8132C13.2114 16.7399 12.2304 16 11.1142 16H9.75M6.7 16.5H6.5C5.67157 16.5 5 17.1716 5 18M12.5 20.5L10.5 21.5M17.5 23H26.5C26.7761 23 27 22.7761 27 22.5V4.5C27 4.22386 26.7761 4 26.5 4H17.5C17.2239 4 17 4.22386 17 4.5V22.5C17 22.7761 17.2239 23 17.5 23ZM19 7H21V10H19V7ZM19 12H21V15H19V12ZM21 19H23V23H21V19ZM23 7H25V10H23V7ZM23 12H25V15H23V12ZM10 14.5C10 15.8807 9.10457 17 8 17C6.89543 17 6 15.8807 6 14.5C6 13.1193 6.89543 12 8 12C9.10457 12 10 13.1193 10 14.5Z"
        stroke={color || GREY_FONT}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default BranchesIcon;
