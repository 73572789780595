import React, { ReactElement } from "react";

import { ACCENT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ExpandArrowIcon({
  color = ACCENT,
  className,
  height = 14,
  width = 9,
  opacity = 0.5,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        opacity={opacity}
        d="M7 0L13.9282 8.25H0.0717969L7 0Z"
        fill={color}
      />
    </svg>
  );
}

export default ExpandArrowIcon;
