import { VRFType } from "../../../../../helpers/api/apiTypes";
import VRFIcon from "../../../../icons/VRFIcon";
import { CellFactory } from "../../newTable/cells/CellsFactory";
import { EditableCellFactory } from "../../newTable/cells/EditableCellsFactory";

const cellFactory = new CellFactory<VRFType>({
  icon: VRFIcon,
});

const editCellFactory = new EditableCellFactory<VRFType>({
  icon: VRFIcon,
});

export const vrfTableHeader = [
  {
    Header: "Name",
    accessor: "name",
    Cell: cellFactory.idCell(),
    EditCell: cellFactory.idCell(),
  },
  {
    Header: "Description",
    accessor: "description",
    EditCell: editCellFactory.input(),
  },
  // {
  //   Header: "Type",
  //   accessor: "vrf_type",
  //   EditCell: editCellFactory.input(),
  // },
].map((el) => ({ ...el, accessor: el.accessor as keyof VRFType }));
