import React, { FC } from "react";

import { useConnectionsContext } from "../../../../ConnectionsContext";
import EditConnectionForm from "./EditForm";
import AddConnectionForm from "./AddForm";

const ConnectionForm: FC = () => {
  const { activeConnection } = useConnectionsContext();
  return (
    <>{activeConnection ? <EditConnectionForm /> : <AddConnectionForm />}</>
  );
};
export default ConnectionForm;
