import React, { FC, useCallback, useEffect } from "react";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import { useCyberThreatsContext } from "../../../../CyberThreatsContext";
import styles from "./StatisticsBody.module.scss";
import { useGlobalFilterContext } from "../../../../../../contexts/GlobalFilterContext";
import { classNames } from "../../../../../../helpers/common/classNames";
import { getFiltersParams } from "../../../../../ApplicationsDashboard/common/appStatisticsHelper";
import { CYBER_THREATS_FIELD_VALUE } from "../../../../consts";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useUserContext } from "../../../../../../contexts/UserContext";
import grafanaLinksFactory, {
  LinkFn,
} from "../../../../../../components/common/charts/GraphanaLinksFactory";
import { useTenantContext } from "../../../../../../contexts/tenantsContext/TenantsContext";

type Props = {
  className?: string;
};

const StatisticsBody: FC<Props> = () => {
  const { isDemo } = useUserContext();
  const { filters } = useCyberThreatsContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchServicesStatus, servicesStatusRequest } = useTenantContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  // if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  const linkWithData = useCallback(
    (func: LinkFn) => {
      const data = {
        timeRange: timeRange.general,
        filters: undefined,
        tenantName: selectedTenant,
        filtersList: "&" + getFiltersParams(CYBER_THREATS_FIELD_VALUE, filters),
      };
      return func(data);
    },
    [timeRange, filters, selectedTenant]
  );

  return (
    <GrafanaContainer
      type={GRAFANA_TYPES.IDS}
      className={styles.wrapper}
      ignorePlaceholder={isDemo}
    >
      <div className={styles.iframeWrapper}>
        <ChartBlock
          className={styles.stat1}
          link={linkWithData(grafanaLinksFactory.cyber_threats_multiGraph)}
        />
        <ChartBlock
          className={styles.stat2}
          link={linkWithData(grafanaLinksFactory.cyber_threats_graph)}
        />
        <ChartBlock
          className={styles.stat3}
          link={linkWithData(grafanaLinksFactory.cyber_threats_pie)}
        />
        <ChartBlock
          className={styles.stat4}
          link={linkWithData(grafanaLinksFactory.cyber_threats_table)}
        />
      </div>
    </GrafanaContainer>
  );
};

export default StatisticsBody;

const ChartBlock: FC<{ link: string; className: string }> = ({
  link,
  className,
}) => {
  const wrapperClasses = classNames(styles.chartBlock, className);
  return (
    <div className={wrapperClasses}>
      <GrafanaGraph link={link} />
    </div>
  );
};
