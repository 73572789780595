import React, { ReactElement } from "react";
import { PURPLE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CaretBig({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="m7 3 7 8H0l7-8Z" fill={color || PURPLE} />
    </svg>
  );
}

export default CaretBig;
