import { ReactElement } from "react";

function AzureIcon(): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.2911 22.0366C16.5532 21.4411 19.2475 20.9484 19.2784 20.9416L19.3347 20.9293L16.2549 17.144C14.5611 15.062 13.1752 13.3505 13.1752 13.3407C13.1752 13.322 16.3553 4.27285 16.3732 4.24065C16.3792 4.22989 18.5433 8.09076 21.6192 13.5997C24.4989 18.7572 26.8731 23.0096 26.8951 23.0494L26.9353 23.1219L17.1477 23.1206L7.36004 23.1193L13.2911 22.0366ZM1.54486 20.8823C1.54486 20.8769 2.99601 18.2739 4.76964 15.0977L7.99442 9.32282L11.7525 6.06388C13.8195 4.27147 15.5162 2.80276 15.523 2.80008C15.5298 2.7974 15.5026 2.86811 15.4626 2.95722C15.4225 3.04633 13.5862 7.1164 11.3818 12.0018L7.37382 20.8844L4.45934 20.8882C2.85638 20.8903 1.54486 20.8876 1.54486 20.8823Z"
        fill="#0089D6"
      />
    </svg>
  );
}

export default AzureIcon;
