import { FC } from "react";
import Input from "../../../../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../../../../components/common/formComponents/RadioGroup";
import Section from "../../../../../../../components/common/Section";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";

type Props = {
  className: string;
};
const RoutingSection: FC<Props> = ({ className }) => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  return (
    <Section title={"Routing"} columnsCount={2} className={className}>
      <RadioGroup
        label="Default Route"
        isOneRow
        options={RouteTypes}
        value={fields.defaultRoute}
        setActiveValue={(val) => onChange("defaultRoute", val)}
      />
      <Input
        label="Administrative Distance:"
        name="admDistance"
        placeholder=""
        value={fields.admDistance}
        medium
        error={""}
        onChange={(e) => onChange("admDistance", e.target.value)}
        type={"number"}
      />
    </Section>
  );
};

export default RoutingSection;

const RouteTypes = [
  { value: "global", label: "global" },
  { value: "location", label: "local" },
  { value: "none", label: "none" },
];
