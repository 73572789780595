import React, { ReactElement } from "react";
import { ACCENT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CopyIcon({
  color = ACCENT,
  height = 18,
  width = 18,
  className,
  onClick,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect x="1.80078" y="7.2002" width="9" height="9" rx="1" fill={color} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.20312 1.7998C7.65084 1.7998 7.20312 2.24752 7.20312 2.7998V5.39975H10.6049C11.7095 5.39975 12.6049 6.29518 12.6049 7.39975V10.7998H15.2031C15.7554 10.7998 16.2031 10.3521 16.2031 9.7998V2.7998C16.2031 2.24752 15.7554 1.7998 15.2031 1.7998H8.20312Z"
        fill={color}
      />
    </svg>
  );
}

export default CopyIcon;
