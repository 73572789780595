import { CellProps } from "react-table";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";
import { NatpoolType } from "../../../../../helpers/api/apiTypes";
import LoopIcon from "../../../../icons/LoopIcon";
import LabelArray from "../../LabelArray";
import TextWithIcon from "../../TextWithIcon";

export const natpoolTableHeader = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row, cell: { value } }: CellProps<NatpoolType>) => (
      <TextWithIcon
        icon={
          <LoopIcon indicatorColor={getColorFromStatus(row.original.status)} />
        }
        text={value}
      />
    ),
  },
  {
    Header: "Interface Description",
    accessor: "interfaceDescription",
  },
  {
    Header: "VRF Mapping",
    accessor: "vrfMapping",
  },
  {
    Header: "IPv4",
    accessor: "ipv4",
    Cell: ({ cell: { value } }: CellProps<NatpoolType>) => (
      <LabelArray values={value} title={"IPv4"} />
    ),
  },
  {
    Header: "IPv6",
    accessor: "ipv6",
    Cell: ({ cell: { value } }: CellProps<NatpoolType>) => (
      <LabelArray values={value} title={"IPv6"} />
    ),
  },
].map((el) => ({ ...el, accessor: el.accessor as keyof NatpoolType }));
