import { FC, useEffect, useMemo, useState } from "react";
import Button from "../../../components/common/buttons/Button";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";
import TableWrapper from "../../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../components/common/table/newTable/Table";
import { OK_STATUS } from "../../../components/const/api";
import SearchIcon from "../../../components/icons/SearchIcon";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { GREY_DISABLED, WHITE } from "../../../helpers/common/colorAliases";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { REGIONS } from "../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/regions";
import { useCloudsConnectivityContext } from "../CloudsConnectivityContext";
import { vpcsTableHeader } from "../common/vpcsTableHeader";
import styles from "./CloudDiscovery.module.scss";
import CloudVPNForm from "./CloudVPNForm";
import { vnetTableHeader } from "../common/vnetTableHeader";
import LocationIcon from "../../../components/icons/LocationIcon";

type Props = { type: string };

const fieldsData = {
  azure: {
    columns: vnetTableHeader,
    tableGrid: `1fr 1fr 1.5fr 1fr 1fr 1fr`,
  },
  aws: {
    columns: vpcsTableHeader,
    tableGrid: `30px 1fr 1fr 1fr 1.5fr 1fr 1fr`,
  },
};

const getDataByType = (type: string) => {
  switch (type) {
    case "azure":
      return fieldsData.azure;
    default:
      return fieldsData.aws;
  }
};

const CloudDiscovery: FC<Props> = ({ type }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { vpcs, getVPSList, vpcStatus } = useCloudsConnectivityContext();

  const specificData = getDataByType(type);

  const [fields] = useFormField<any>({});
  const [showList, setShowList] = useState<boolean>(false);
  const [region, setRegion] = useState<string>(REGIONS[type]?.[0]);

  const [selectedRow, setSelectedRow] = useState<
    | {
        body: any;
        onClose: any;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (vpcs[type]) {
      setShowList(vpcs[type]?.length > 0);
    }
  }, [vpcs[type]]);

  const handleDiscovery = () => {
    if (region && selectedTenant) {
      getVPSList(fields, region, selectedTenant, type);
    }
  };

  const handleAdd = (row: any) => {
    setSelectedRow({ body: row.original, onClose: row.toggleRowExpanded });
  };

  const regionsList = useMemo(() => REGIONS[type], [type]);

  const getVPNFormByType = () => {
    const tag = selectedRow?.body.Tags?.filter(
      (tag: any) => tag.Key === "Name"
    );
    const name = tag?.[0].Value || "";
    let vpc_id;
    switch (type) {
      case "azure":
        vpc_id = selectedRow?.body?.name;
        break;
      default:
        vpc_id = selectedRow?.body?.VpcId;
        break;
    }
    return (
      <CloudVPNForm
        onClose={() => selectedRow?.onClose()}
        type={type}
        vpc_id={vpc_id}
        name={name}
        region={selectedRow?.body?.region}
        resource_group_name={selectedRow?.body?.resource_group_name}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.discoverLabel}>Discover to Add Connections</div>
        <DropdownBasic
          id={"cloudDiscoveryConnectionBandwidth"}
          onChange={(val) => setRegion(val.key)}
          selected={mapStringToItem(region)}
          itemsList={regionsList?.map(mapStringToItem)}
          placeholder="Cloud region"
          isPlaceholderAlwaysVisible
          isMedium
          icon={<LocationIcon color={GREY_DISABLED} />}
        />
        <Button onClick={handleDiscovery} isPrimaryBtn className={styles.btn}>
          {vpcStatus[type]?.state === "pending" ? (
            <LoaderIcon color={WHITE} className={styles.icon} />
          ) : (
            <SearchIcon color={WHITE} className={styles.icon} />
          )}
          Discover
        </Button>
      </div>
      {showList && (
        <TableWrapper
          dataStatus={OK_STATUS}
          tablePlaceholder={["Clouds", "Cloud"]}
          isEmpty={false}
          className={styles.listTable}
        >
          <Table
            columns={specificData.columns(handleAdd)}
            data={vpcs[type]}
            header={TableHeader}
            gridColumnTemplate={specificData.tableGrid}
            renderRowSubComponent={getVPNFormByType}
            noInitialRowExpand
            withoutPagination
          />
        </TableWrapper>
      )}
    </div>
  );
};
export default CloudDiscovery;
