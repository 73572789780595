import React, { PropsWithChildren } from "react";

import { AbstractTimeoutHandler } from "../helpers/common/AbstractTimeoutHandler";
import { createContextUtils } from "./common/utils";

export type NotificationMessage = {
  title: string;
  details: string;
  state: "ok" | "error" | "pending";
};

type IState = { showNotification?: boolean; message?: NotificationMessage };
type IFunc = {
  handleShowNotification: (
    response?: NotificationMessage,
    showedAlways?: boolean
  ) => void;
};

export type INotificationsContext = IState & IFunc;
const [
  UserContext,
  useNotificationsContext,
  withNotificationsContextProps,
] = createContextUtils<IState, IFunc>();

export { useNotificationsContext, withNotificationsContextProps };

export default class NotificationsContextContainer extends AbstractTimeoutHandler<
  IState,
  PropsWithChildren<any>
> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {};

    this.funcs = {
      handleShowNotification: this.handleShowNotification,
    };
  }

  removeNotification = (): void => {
    this.setState({ showNotification: false, message: undefined });
  };

  handleShowNotification = (
    response?: NotificationMessage,
    showedAlways?: boolean
  ): void => {
    if (!response) {
      this.removeNotification();
      return;
    }
    // Show the notification after 1 second
    setTimeout(() => {
      this.setState({ showNotification: true, message: response });

      if (!showedAlways) {
        // Hide the notification after 5 seconds
        setTimeout(() => {
          this.removeNotification();
        }, 8000);
      }
    }, 1000);
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
