import React, { useState } from "react";
import { createContextAndUse } from "./common/AbstractCrudContext";

type IState = {
  isVisible: boolean;
  link?: string;
};

type IFunc = {
  hideTutorial: () => void;
  showTutorial: (link: string) => void;
};

const [TutorialContext, useContext] = createContextAndUse<IState, IFunc>();
export const useTutorialContext = useContext;

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

export default function TutorialContextContainer(
  props: Props
): React.ReactElement {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [link, setLink] = useState<string>("");

  const hideTutorial = () => {
    setIsVisible(false);
  };

  const showTutorial = (link: string) => {
    setLink(link);
    setIsVisible(true);
  };

  return (
    <TutorialContext.Provider
      value={{ isVisible, hideTutorial, showTutorial, link }}
    >
      {props.children}
    </TutorialContext.Provider>
  );
}
