import { FC } from "react";
import styles from "./ContentBlock.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import DialogBtmButtons from "../../common/DialogBtmButtons";
import {
  ChangeField,
  FieldsType,
} from "../../../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import { RequestStatus } from "../../../../helpers/types";

import GeneralSection from "./GeneralSection";
import SystemSection from "./SystemSection";
import ServiceSection from "./ServiceSection";

type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  onClose?: () => void;
  onAction: () => Promise<void>;
  actionStatus?: RequestStatus;
  isEdit?: boolean;
  errors?: { [key: string]: string };
  isNode?: boolean;
};

const ContentBlock: FC<Props> = ({
  className,
  fields,
  onChange,
  onClose,
  onAction,
  actionStatus,
  isEdit,
  errors,
  isNode,
}) => {
  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.content}>
        <>
          <GeneralSection
            className={styles.section}
            fields={fields}
            onChange={onChange}
            isEdit={isEdit}
            errors={errors}
          />
          <SystemSection
            className={styles.section}
            fields={fields}
            onChange={onChange}
            errors={errors}
          />
          <ServiceSection
            className={styles.section}
            classnameServices={styles.subsection}
            fields={fields}
            onChange={onChange}
            errors={errors}
          />
        </>
      </div>
      <DialogBtmButtons
        errorDisplay={actionStatus}
        className={styles.footer}
        controls={{
          okText: isEdit ? "Save" : "Add",
          onOk: () => onAction(),
          cancelText: "Cancel",
          onCancel: onClose,
        }}
      />
    </div>
  );
};
export default ContentBlock;
