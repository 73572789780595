import React, { FC } from "react";
import SummaryInfoBlock from "../../leftInfoBlock/SummaryInfoBlock";
import TenantsIcon from "../../icons/tabs/TenantsIcon";
import UnifiedTextPair from "../../common/UnifiedTextPair";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";

type Props = {};

const TenantInfo: FC<Props> = () => {
  const { selected } = useTenantContext();
  return (
    <>
      <SummaryInfoBlock
        icon={<TenantsIcon />}
        title={selected?.name || ""}
        isSmallerIcon
      >
        <UnifiedTextPair subtitle="Company" text={selected?.full_name} />
        <UnifiedTextPair
          subtitle="Contact Name"
          text={selected?.contact_info.name}
        />
        <UnifiedTextPair subtitle="Email" text={selected?.contact_info.email} />
      </SummaryInfoBlock>
    </>
  );
};
export default TenantInfo;
