import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { OverlayedRightBlockType } from "../../../helpers/types";
import styles from "./OverlayedRightBlock.module.scss";

type OverlayedRightBlockProps = {
  blockProps: OverlayedRightBlockType;
  className?: string;
};

const OverlayedRightBlock: FC<OverlayedRightBlockProps> = ({
  blockProps: { title, body, footer, isVisible },
  className,
}) => {
  const overlayFilterClasses = classNames(
    styles.animatedWrapper,
    !isVisible && styles.collapsed
  );
  return (
    <div className={overlayFilterClasses}>
      <div className={styles.title}>{title}</div>
      <div className={classNames(styles.body, className)}>{body}</div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
};

export default OverlayedRightBlock;
