import { FC, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { arubaApi } from "../../../helpers/api/ArubaApi";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  className: string;
  list: Array<any>;
};

export const _data = {
  labels: ["Default Overlay", "Critical Apps", "Real Time", "Bulk Apps"],
  datasets: [
    {
      data: [0, 0, 0, 0],
      backgroundColor: ["#6A82FF", "#00BA77", "#3000BA", "#FF5F01"],
      borderWidth: 0,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right" as const,
      labels: {
        boxWidth: 8,
        boxHeight: 8,
        borderRadius: 8,
      },
    },
    tooltip: {
      callbacks: {
        label: (tooltip: any) => {
          return `${tooltip.label}: ${tooltip.formattedValue}%`;
        },
      },
    },
  },
};

const Bandwidth: FC<Props> = ({ className, list }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Array<any>>([]);
  const devices = list.map((item) => item.id).join();

  useEffect(() => {
    if (!list.length) return;
    if (!isLoading) {
      setLoading(true);
    }
    const payload = list.map((item) => {
      return { nePk: item.id };
    });

    arubaApi.getBandwidth(payload).then((res) => {
      const json = res.result || [];
      json.pop();

      let total = 0;
      let defaultOverlay = 0;
      let crititalApps = 0;
      let realTime = 0;
      let bulkApps = 0;

      json.forEach((item) => {
        const [value] = Object.values(item) as Array<Array<Array<number>>>;

        value.forEach((bytes) => {
          const [id, number] = bytes;
          total += number;

          if (id == 1) realTime += number;
          if (id == 2) crititalApps += number;
          if (id == 3) bulkApps += number;
          if (id == 4) defaultOverlay += number;
        });
      });

      const percentag = [
        (defaultOverlay / total) * 100,
        (crititalApps / total) * 100,
        (realTime / total) * 100,
        (bulkApps / total) * 100,
      ].map((num) => num.toFixed(2));

      setData(percentag);
      setLoading(false);
    });
  }, [devices]);

  if (!data.length) return null;
  _data.datasets[0].data = data;

  return (
    <div className={className} data-loading={isLoading}>
      <h4>Overlay Bandwidth</h4>
      <Doughnut data={_data} options={options} />
    </div>
  );
};

export default Bandwidth;
