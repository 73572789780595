import React, { ChangeEvent, FC } from "react";
import styles from "./FieldsGroup.module.scss";
import Input from "./formComponents/Input";

type FieldsGroupProps = {
  name: string;
  placeholder: string;
  fields: Array<string>;
  title: string;
  onChange: (i: number, name: string, value: string | number) => void;
  errors?: { [key: string]: string };
  isOptional?: boolean;
  medium?: boolean;
  isNotEditable?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const FieldsGroup: FC<FieldsGroupProps> = ({
  name,
  placeholder,
  fields,
  title,
  onChange,
  errors,
  isOptional,
  medium,
  isNotEditable,
  onBlur,
}) => {
  const renderInputsWithExtraField = () => {
    return [
      ...fields.filter((field) => field.length > 0),
      "",
    ].map((field: string, i: number) => (
      <Input
        key={i}
        onChange={(e) => onChange(i, name, e.target.value)}
        name={name}
        placeholder={placeholder}
        value={field}
        label={i === 0 ? title : ""}
        error={errors && errors[`${i}_${name}`]}
        isOptional={isOptional}
        medium={medium}
        isNotEditable={isNotEditable}
        onBlur={onBlur}
      />
    ));
  };

  return (
    <div className={styles.inputGroups}>{renderInputsWithExtraField()}</div>
  );
};

export default FieldsGroup;
