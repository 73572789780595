import React, { FC } from "react";
import UserListContextContainer, {
  GLOBAL_TENANT,
} from "../../contexts/UserListContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PathEntry } from "../../helpers/types";
import { TenantsTab } from "../../components/common/navigation/Tenants/TenantsTab";

export type Props = {
  customRoot?: Array<PathEntry>;
};
const IPGroupsPage: FC<Props> = ({ customRoot }) => {
  return (
    <>
      <TenantsTab
        defaultTenants={[{ name: GLOBAL_TENANT }]}
        remoteUsersRoot={customRoot}
      />
      <div>IP GroupsPage</div>
    </>
  );
};

export default withContexts<Props>(withLayout<any>(IPGroupsPage), [
  UserListContextContainer,
]);
