import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { AQUA } from "../../helpers/common/colorAliases";

function CheckMarkIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        stroke={color || AQUA}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M3.857 9l4.5 4.5 7.072-9.643"
      />
    </svg>
  );
}

export default CheckMarkIcon;
