import Api, { Res } from "./Api";
import {
  NodeUpdate,
  NodeVersion,
} from "../../pages/Systems/HADomainsPage/types";
import { NODES } from "./apiPaths";

export class HADomainsgApi extends Api {
  constructor() {
    super();
  }

  async getNodeVersion(node_name: string): Promise<Res<NodeVersion>> {
    return await this.fetchBroker<NodeVersion>(NODES.GET_VERSION(node_name));
  }

  async editNode(node_name: string, params: NodeUpdate): Promise<Res<string>> {
    return await this.fetchBroker<string>(NODES.EDIT(node_name), {
      body: JSON.stringify(params),
    });
  }

  async editNodeDescription(
    node_name: string,
    description: string
  ): Promise<Res<string>> {
    return await this.fetchBroker<string>(NODES.EDIT_DESCRIPTION(node_name), {
      body: JSON.stringify({ description }),
    });
  }

  async editNodeLocation(
    node_name: string,
    location: string
  ): Promise<Res<string>> {
    return await this.fetchBroker<string>(NODES.EDIT_LOCATION(node_name), {
      body: JSON.stringify({ location }),
    });
  }

  async resetNode(node_name: string): Promise<Res<string>> {
    return await this.fetchBroker<string>(NODES.RESEET(node_name));
  }

  async factoryResetNode(node_name: string): Promise<Res<string>> {
    return await this.fetchBroker<string>(NODES.FACTORY_RESEET(node_name));
  }

  async reconfigNode(node_name: string, address: string): Promise<Res<string>> {
    return await this.fetchBroker<string>(NODES.RECONFIG_NODE(node_name), {
      body: JSON.stringify({ address }),
    });
  }
}

export const haDomainApi = new HADomainsgApi();
