import { CellProps } from "react-table";
import ReactTooltip from "react-tooltip";
import { FWFilters, FWRuleType } from "../../../../../helpers/api/apiTypes";
import { getProtocolType } from "../../../../../helpers/getFirewallsFilterValues";
import getLabelTypeFromValue from "../../../../../helpers/getLabelTypeFromValue";
import { Toggle } from "../../../Toggle";
import UnifiedTextPair from "../../../UnifiedTextPair";
import Label from "../../Label";
import LabelOutline from "../../LabelOutline";
import styles from "./firewallTableHeader.module.scss";

const ACTIONS: { [key: string]: string } = {
  ALLOW: "Allow",
  DENY: "Drop",
  ALLOW_WITH_BLOCKED_SNI: "Allow",
  ALLOW_ONLY_SELECTED_SNIS: "Allow",
};
const FQDNActions = ["ALLOW_WITH_BLOCKED_SNI", "ALLOW_ONLY_SELECTED_SNIS"];
const MAX_FW_PRIORITY_FOR_MANUAL_CREATION = 10000;

export const firewallTableHeader = (): Array<any> => {
  return [
    {
      Header: "Enabled",
      accessor: (row: FWRuleType) => [row.id, row.enable],
      Cell: ({ cell: { value } }: CellProps<FWRuleType>) => {
        const [id, enable] = value;
        return (
          <div className={styles.labels}>
            <div data-tip data-for={value} className={styles.id}>
              ID
            </div>
            <Toggle
              isChecked={enable}
              onChange={() => {
                return;
              }}
            />
            <ReactTooltip
              id={id + ""}
              aria-haspopup="true"
              place="bottom"
              type="light"
              className={styles.tooltip}
            >
              {id}
            </ReactTooltip>
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Action",
      accessor: "forwarding_policy",
      Cell: ({ cell: { value } }: CellProps<FWRuleType>) => {
        const hasFQDN = FQDNActions.includes(value);
        return (
          <div className={styles.actionWrapper}>
            <LabelOutline
              type={getLabelTypeFromValue(hasFQDN ? "ALLOW" : value)}
            >
              {ACTIONS[value as string]}
            </LabelOutline>
            {hasFQDN && (
              <LabelOutline type="accent">{"Web Filtering"}</LabelOutline>
            )}
          </div>
        );
      },
    },
    {
      Header: (
        <UnifiedTextPair
          subtitle={"Source"}
          text={"IP, Protocol, Port, Segment"}
          separator={"slash"}
        />
      ),
      accessor: "source",
      Cell: ({ row: { original } }: CellProps<FWRuleType>) => {
        return (
          <div className={styles.labels}>
            <Label>
              {getFilterValueOrUndefined(original.filters, "src_network") ||
                getFilterValue(original.filters, "src_group")}
            </Label>
            <LabelOutline type="font">
              {getProtocolType(original.filters)}:{" "}
              {getFilterValue(original.filters, "src_l4_port")}
            </LabelOutline>
            {original.priority >= MAX_FW_PRIORITY_FOR_MANUAL_CREATION && (
              <LabelOutline type="font" className={styles.fwSegmenBlock}>
                {getFilterValue(original.filters, "src_segment")}
              </LabelOutline>
            )}
          </div>
        );
      },
    },
    {
      Header: (
        <UnifiedTextPair
          subtitle={"Destination"}
          text={"IP, Protocol, Port, Segment"}
          separator={"slash"}
        />
      ),
      accessor: "destination",
      Cell: ({ row: { original } }: CellProps<FWRuleType>) => {
        return (
          <div className={styles.labels}>
            <Label>
              {getFilterValueOrUndefined(original.filters, "dst_network") ||
                getFilterValue(original.filters, "dst_group")}
            </Label>
            <LabelOutline type="font">
              {getProtocolType(original.filters)}:{" "}
              {getFilterValue(original.filters, "dst_l4_port")}
            </LabelOutline>
            {original.priority >= MAX_FW_PRIORITY_FOR_MANUAL_CREATION && (
              <LabelOutline type="font" className={styles.fwSegmenBlock}>
                {getFilterValue(original.filters, "dst_segment")}
              </LabelOutline>
            )}
          </div>
        );
      },
    },
    {
      Header: "Affected Segments",
      Cell: ({ row: { original } }: CellProps<FWRuleType>) => {
        return (
          <div className={styles.labels}>
            <LabelOutline type="font">
              {getFilterValue(original.filters, "dst_segment")}
            </LabelOutline>
          </div>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
    },
    {
      Header: "Priority",
      accessor: "priority",
      Cell: ({ row: { original } }: CellProps<FWRuleType>) => {
        return (
          original.priority < MAX_FW_PRIORITY_FOR_MANUAL_CREATION && (
            <div className={styles.labels}>{original.priority}</div>
          )
        );
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof FWRuleType,
  }));
};

const getFilterValue = (filters: Array<FWFilters>, field: string): string => {
  return (
    filters.find((filter) => filter.filter_type === field)?.values.toString() ||
    "*"
  );
};

const getFilterValueOrUndefined = (
  filters: Array<FWFilters>,
  field: string
): string | undefined => {
  return filters
    .find((filter) => filter.filter_type === field)
    ?.values.toString();
};
