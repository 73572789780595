import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";

function DuplicateIcon({ color, onClick }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <rect
        x="2"
        y="8"
        width="10"
        height="10"
        rx="1"
        fill={color || BUTTON_PRIMARY}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2a1 1 0 0 0-1 1v3h4a2 2 0 0 1 2 2v4h3a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H9Z"
        fill={color || BUTTON_PRIMARY}
      />
    </svg>
  );
}

export default DuplicateIcon;
