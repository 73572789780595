import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { systemApi } from "../../../helpers/api/SystemApi";
import { tenantApi } from "../../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../helpers/types";
import { LocationsList } from "../../WizardToolPage/types";
import { System, SystemNode } from "./types";

type RequestsState = CrudRequestStatuses & {
  locationsStatus?: RequestStatus;
  switchNodeStatus?: RequestStatus;
};

type ValueState = CrudValueState<any>;

type IState = RequestsState &
  ValueState & {
    locations?: Array<LocationsList>;
    selectedLocations: Array<LocationsList> | undefined;
    system?: System;
    selectedRow?: string;
    selectedNode?: SystemNode;
  };

type IFunc = CrudFunc<any> & {
  removeSystem: (name: string) => Promise<boolean>;
  fetchLocations: () => Promise<any>;
  toggleLocation: (location: LocationsList) => void;
  toggleLocationForMap: (
    location: LocationsList,
    clb: (name: string) => void
  ) => void;
  getSystem: (name: string) => void;
  selectRow: (name?: string) => void;
  selectNode: (node?: SystemNode) => void;
  switchNodeToActive: (node?: any) => Promise<boolean>;
};

export type ISystemProvisioningContext = IState & IFunc;
const [
  Context,
  useSystemProvisioningContext,
  withSystemProvisioningContextProps,
] = createContextUtils<IState, IFunc>();

export { useSystemProvisioningContext, withSystemProvisioningContextProps };

type Props = any;

let FetchTimeout: NodeJS.Timeout | undefined;

class SystemProvisioningContextContainer extends NewAbstractCrudContext<
  any,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  clearTimeout = (): void => {
    if (FetchTimeout) {
      clearTimeout(FetchTimeout);
    }
    FetchTimeout = undefined;
  };

  fetchList = async (
    specificator?: string,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    await this._fetchListWrap(() =>
      systemApi.getSystemsList(undefined, offset, limit)
    );
  };

  remove = async (node: any): Promise<boolean> => {
    const { ok } = await this._removeWrap(() =>
      systemApi.updateSystem(node.system, { remove_nodes: [node.name] } as any)
    );
    return ok;
  };

  removeSystem = async (systemName: string): Promise<boolean> => {
    const { ok } = await this._removeWrap(() =>
      systemApi.deleteSystem(systemName)
    );
    return ok;
  };

  edit = async (): Promise<boolean> => {
    return await true;
  };

  fetchLocations = async (): Promise<void> => {
    this.selectRow("all");
    this.fetchList(undefined, undefined);
    const { ok, result } = await this._fetchWithStatus(
      () => tenantApi.getLocations(),
      "locationsStatus"
    );
    this.setState({ locations: ok ? result : undefined });
  };

  toggleLocation = async (
    location: LocationsList | undefined
  ): Promise<void> => {
    this.setState({ selectedLocations: location ? [location] : undefined });
    location && this.getSystem(location.name);
  };

  toggleLocationForMap = async (
    location: LocationsList | undefined,
    clb: (name: string) => void
  ): Promise<void> => {
    await this.toggleLocation(location);
    this.state.system && clb(this.state.system.name);
  };

  getSystem = (name: string): void => {
    const system = this.state.list?.find(
      (sys: System) => sys.name === name || sys.location === name
    );
    this.setState({ system, selectedRow: system.name });
  };

  selectRow = (selectedRow?: string): void => {
    if (selectedRow == "all") {
      this.toggleLocation(undefined);
    } else if (selectedRow) {
      this.getSystem(selectedRow);
    }
    this.setState({ selectedRow });
  };

  selectNode = (selectedNode?: any): void => {
    this.setState({ selectedNode });
  };

  switchNodeToActive = async (node: any): Promise<boolean> => {
    this.setState({ switchNodeStatus: setPending() });
    const res = await systemApi.systemFailover(node.system_name, {
      active_node: node.name,
    });
    this.setState({ switchNodeStatus: resToState(res) });
    if (res.ok) {
      this.fetchList();
    }
    return res.ok;
  };

  funcs = {
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
    removeSystem: this.removeSystem,
    fetchLocations: this.fetchLocations,
    toggleLocation: this.toggleLocation,
    getSystem: this.getSystem,
    selectRow: this.selectRow,
    selectNode: this.selectNode,
    switchNodeToActive: this.switchNodeToActive,
    toggleLocationForMap: this.toggleLocationForMap,
  };
}

export default SystemProvisioningContextContainer;
