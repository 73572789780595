import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/utils";
import { IUserContext, withUserContextProps } from "../../contexts/UserContext";
import { networkApi } from "../../helpers/api/networkApi";
import { systemApi } from "../../helpers/api/SystemApi";
import { tenantApi } from "../../helpers/api/TenantApi";
import { tenantVirtualInterfacesApi } from "../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { DropdownItem, RequestStatus } from "../../helpers/types";
import { Layer3InterfaceNew } from "../Layer3Interface/types";
import { REGIONS } from "../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/regions";
import {
  AWSAccessKey,
  AWSVPNBody,
  AzureAccessKey,
  AzureVPNBody,
  VNET,
  VPC,
} from "../WizardToolPage/types";
import { CloudCredentialsFields } from "./components/CloudCredentials";
import { CLOUDS_TYPES_GROUPS } from "./const";

type RequestStatuses = {
  vpcStatus: { [key: string]: RequestStatus | undefined };
  removeStatus?: RequestStatus;
  countStatus?: RequestStatus;
  addConnectionStatus?: RequestStatus;
};

type State = {
  grouppedList: { [key: string]: Array<Layer3InterfaceNew> };
  vpcs: { [key: string]: Array<VPC | VNET> };
  isToken: boolean;
  tokens?: { [key: string]: { [key: string]: string } | undefined };
  correctTokens?: { [key: string]: boolean };
  locations?: Array<DropdownItem>;
  locationsAWS?: Array<DropdownItem>;
  requestToRemove: string | undefined;
  requestToUpdate: string | undefined;
  usedTenant: string;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (tenant: string) => Promise<void>;
  getAccessKeys: (tenant: string, type: string) => Promise<void>;
  remove: (item: Layer3InterfaceNew) => Promise<boolean>;
  useTenant: (tenant: string) => Promise<void>;
  getVPSList: (
    token: { [key: string]: string },
    region: string,
    tenant: string,
    type: string
  ) => Promise<void>;
  createAwsVpn: (params: AWSVPNBody, tenant?: string) => Promise<boolean>;
  addVPNByType: (
    type: string,
    fields: AWSVPNBody | AzureVPNBody,
    tenant: string
  ) => Promise<any>;
  fetchLocations: () => Promise<void>;
  getLocationsForAws: (tenant: string) => Promise<any>;
  createAccessKey: (
    fields: CloudCredentialsFields,
    tenant: string,
    type: string
  ) => Promise<any>;
  deleteAccessKey: (
    tenant: string,
    type: string,
    withCreate?: boolean,
    fields?: any
  ) => Promise<any>;
  resetTokens: () => Promise<void>;
  setRequestToRemove: (type?: string) => Promise<void>;
  setRequestToUpdate: (type?: string) => Promise<void>;
};

const [
  CloudsConnectivityContext,
  useCloudsConnectivityContext,
] = createContextAndUse<IState, IFunc>();
export { useCloudsConnectivityContext };

class CloudsConnectivityContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = CloudsConnectivityContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      vpcs: {},
      isToken: false,
      grouppedList: CLOUDS_TYPES_GROUPS,
      vpcStatus: {},
      requestToRemove: undefined,
      requestToUpdate: undefined,
      usedTenant: "",
    };
  }

  useTenant = async (tenant: string): Promise<void> => {
    this.setState({ usedTenant: tenant });
  };

  setRequestToRemove = async (type?: string): Promise<void> => {
    this.setState({ requestToRemove: type });
  };

  setRequestToUpdate = async (type?: string): Promise<void> => {
    this.setState({ requestToUpdate: type });
  };

  fetchList = async (tenant: string) => {
    this.setState({ countStatus: setPending() });
    const res = await tenantApi.getVirtualInterfacesAllTypes(tenant);
    const grouppedList = { ...CLOUDS_TYPES_GROUPS };
    const types = Object.keys(grouppedList);
    types.forEach((type) => {
      const connections = (res.result || [])?.filter(
        (el) => el.labels?.remote_type === type
      );
      grouppedList[type] = [...connections];
    });
    this.setState({ grouppedList, countStatus: resToState(res) });
  };

  fetchVPCList = async (
    region: string,
    tenant: string,
    type: string
  ): Promise<void> => {
    const tenantName = tenant || this.props.user.name;
    this.setState({
      vpcStatus: { ...this.state.vpcStatus, [type]: setPending() },
    });
    let query;
    switch (type) {
      case "aws":
        query = tenantApi.getAWSVPCListApi(tenantName, region);
        break;
      case "azure":
        query = tenantApi.getAzureVNETListApi(tenantName, region);
        break;
      default:
        return;
    }
    const res = await query;
    const data = (res?.result || []).map((vpc) => ({ ...vpc, region }));
    if (res) {
      this.setState({
        vpcStatus: { ...this.state.vpcStatus, [type]: resToState(res) },
        vpcs: { ...this.state.vpcs, [type]: data },
      });
    }
  };

  testKey = async (tenant: string, type: string): Promise<void> => {
    const region = REGIONS[type][0];
    let res;
    switch (type) {
      case "aws":
        res = await tenantApi.getAWSVPCListApi(tenant, region);
        break;
      case "azure":
        res = await tenantApi.getAzureVNETListApi(tenant, region);
        break;
      default:
        return;
    }

    const newTokens = { ...this.state.correctTokens };
    if (res?.result) {
      newTokens[type] = true;
    } else {
      newTokens[type] = false;
    }
    this.setState({ correctTokens: newTokens });
  };

  getAccessKeys = async (tenant: string, type: string): Promise<void> => {
    let query;
    switch (type) {
      case "aws":
        query = tenantApi.getAWSAccessKey(tenant);
        break;
      case "azure":
        query = tenantApi.getAzureAccessKey(tenant);
        break;
      default:
        return;
    }
    const { ok, result } = query && (await query);
    if (ok && result) {
      const newTokens = { ...this.state.tokens };
      newTokens[type] = result;
      this.testKey(tenant, type);
      this.setState({ tokens: newTokens, isToken: ok });
    }
  };

  resetTokens = async (): Promise<void> => {
    this.setState({ tokens: undefined, correctTokens: undefined });
  };

  createAccessKey = async (
    fields: any,
    tenant: string,
    type: string
  ): Promise<boolean> => {
    let res;
    switch (type) {
      case "aws":
        res = await this.createAWSAccessKey(fields, tenant, type);
        return res;
      case "azure":
        res = await this.createAzureAccessKey(fields, tenant, type);
        return res;
      default:
        return false;
    }
  };

  deleteAccessKey = async (
    tenant: string,
    type: string,
    withCreate?: boolean,
    fields?: any
  ): Promise<any> => {
    let query;
    switch (type) {
      case "aws":
        query = tenantApi.deleteAWSAccessKey(tenant);
        break;
      case "azure":
        query = tenantApi.deleteAzureAccessKey(tenant);
        break;
      default:
        return;
    }
    const { ok } = await query;
    if (ok) {
      const correctTokens = this.state.correctTokens;
      if (correctTokens) delete correctTokens[type];

      this.setState({ correctTokens });

      if (withCreate && fields) {
        this.createAccessKey(fields, tenant, type);
      }
    }
  };

  createAWSAccessKey = async (
    fields: AWSAccessKey,
    tenant: string,
    type: string
  ): Promise<boolean> => {
    const res = await tenantApi.createAWSAccessKey(fields, tenant);
    this.getAccessKeys(tenant, type);
    return res.ok;
  };

  createAzureAccessKey = async (
    fields: AzureAccessKey,
    tenant: string,
    type: string
  ): Promise<boolean> => {
    const res = await tenantApi.createAzureAccessKey(fields, tenant);
    this.getAccessKeys(tenant, type);
    return res.ok;
  };

  remove = async (item: Layer3InterfaceNew): Promise<boolean> => {
    this.setState({ removeStatus: setPending() });
    const res = await networkApi.deleteIpsecInterface(
      this.state.usedTenant,
      item.name
    );
    this.setState({ removeStatus: resToState(res) });

    if (res.ok) {
      this.fetchList(this.state.usedTenant);
    }

    return res.ok;
  };

  getVPSList = async (
    token: any,
    region: string,
    tenant: string,
    type: string
  ): Promise<void> => {
    this.setState({ vpcs: { ...this.state.vpcs, [type]: [] } });
    if (this.state.isToken) {
      return this.fetchVPCList(region, tenant, type);
    }
    const res = await this.createAccessKey(token, tenant, type);
    if (res) return this.fetchVPCList(region, tenant, type);
  };

  getGateVIName = async (tenantName: string): Promise<any> => {
    const {
      ok,
      result,
    } = await tenantVirtualInterfacesApi.getVirtualInterfacesGate(tenantName);
    if (ok && result) {
      return result.items[0];
    }
    return undefined;
  };

  createAwsVpn = async (
    params: AWSVPNBody,
    tenant?: string
  ): Promise<boolean> => {
    const tenantName = tenant || this.props.user.name;
    const gateVI = await this.getGateVIName(tenantName);
    if (!gateVI) return false;
    const res = await tenantVirtualInterfacesApi.createAWSVPN(
      { ...params, gate_name: gateVI.name },
      tenantName
    );
    this.fetchList(tenantName);
    return res.ok;
  };

  addAwsService = async (fields: AWSVPNBody, tenant: string): Promise<any> => {
    this.setState({ addConnectionStatus: setPending() });
    //check if we have a VI gate ../virtual_interface/gate
    const gateRes = await tenantVirtualInterfacesApi.getVirtualInterfacesGate(
      tenant
    );
    if (gateRes.result && gateRes.result.count > 0) {
      //  Check if you have a gate VI for the system we need
      for (let i = 0; i < gateRes.result.items.length; i++) {
        if (
          gateRes.result.items[i].system_name === fields.system_name &&
          gateRes.result.items[i].is_ipsec_service
        ) {
          const res = await tenantVirtualInterfacesApi.createAWSVPN(
            {
              ...fields,
              system_name: fields.system_name,
              gate_name: gateRes.result.items[i].name,
            },
            tenant
          );
          this.fetchList(tenant);
          return res.ok;
        }
      }
    }
    // else if we have not gate -> not implemented logic on the BE
    return false;
  };

  addVPNByType = async (
    type: string,
    fields: AWSVPNBody | AzureVPNBody,
    tenant: string
  ) => {
    switch (type) {
      case "aws":
        return await this.addAwsService(fields as AWSVPNBody, tenant);
      case "azure":
        return await this.addAzureService(fields as AzureVPNBody, tenant);
      default:
        return false;
    }
  };

  addAzureService = async (
    fields: AzureVPNBody,
    tenant: string
  ): Promise<any> => {
    this.setState({ addConnectionStatus: setPending() });
    const gateRes = await tenantVirtualInterfacesApi.getVirtualInterfacesGate(
      tenant
    );
    const gateItems = gateRes.result?.items.filter(
      (item) => item.system_name === fields.system_name && item.is_ipsec_service
    );
    delete fields.system_name;

    gateItems?.forEach(async (gateItem) => {
      const res = await tenantVirtualInterfacesApi.createAzureVPN(
        {
          ...fields,
          gate_name: gateItem.name,
        },
        tenant
      );
      this.fetchList(tenant);
      return res.ok;
    });
    return false;
  };

  fetchLocations = async (): Promise<void> => {
    const { ok, result } = await systemApi.getSystemsList();
    if (ok && result) {
      const locations = result.map((system) => ({
        key: system.name,
        value: system.location,
      }));
      this.setState({ locations });
    }
  };

  getLocationsForAws = async (tenantName: string): Promise<any> => {
    const locationsAWSRes = await tenantApi.getTenantLocations(tenantName);
    if (locationsAWSRes) {
      const locationsAWS = locationsAWSRes.map((system) => ({
        key: system.system,
        value: system.location,
      }));
      this.setState({ locationsAWS });
    }
  };

  funcs = {
    fetchList: this.fetchList,
    getAccessKeys: this.getAccessKeys,
    remove: this.remove,
    getVPSList: this.getVPSList,
    createAwsVpn: this.createAwsVpn,
    fetchLocations: this.fetchLocations,
    getLocationsForAws: this.getLocationsForAws,
    createAccessKey: this.createAccessKey,
    deleteAccessKey: this.deleteAccessKey,
    resetTokens: this.resetTokens,
    setRequestToRemove: this.setRequestToRemove,
    setRequestToUpdate: this.setRequestToUpdate,
    addVPNByType: this.addVPNByType,
    useTenant: this.useTenant,
  };
}

export default withUserContextProps<any>(CloudsConnectivityContextContainer);
