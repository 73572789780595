import React, { useEffect } from "react";
import styles from "./DashboardLeft.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import { useDashboardFilterContext } from "../../../../contexts/dashboardContext/DashboardFilterContext";
import StarComponent from "../starComponent/StarComponent";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import TopologyButtons from "./TopologyButtons/TopologyButtons";
import EventsTable from "../../../../pages/EventsPage/EventsTable";

const DashboardLeft: React.FC<{}> = ({}) => {
  const { setSelectedInterface } = useDashboardFilterContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    if (selectedTenant) {
      setSelectedInterface(undefined);
    }
  }, [selectedTenant]);

  return (
    <div className={classNames(styles.leftPart)}>
      <TopologyButtons className={styles.buttonsContainer} />
      <div className={styles.starWrapper}>
        <div className={styles.starHeader}>{selectedTenant} network</div>
        <StarComponent />
      </div>
      <EventsTable isCollapsible type="tenant" />
    </div>
  );
};

export default DashboardLeft;
