import { VtepVni, VtepVniCreate } from "./../types";
export const getCreateParams = (vni: VtepVni): VtepVniCreate => {
  const {
    administrative_state,
    mtu,
    virtual_interface_id,
    virtual_interface_name,
    vrf_name,
    alias,
    vni_id,
    vtep_id,
    vtep_name,
    evpn_type,
  } = vni;
  return {
    administrative_state,
    mtu,
    virtual_interface_id,
    virtual_interface_name,
    vrf_name,
    alias,
    vni_id,
    vtep_id,
    vtep_name,
    evpn_type,
  };
};
