import { FC, useEffect } from "react";
import LayoutComponent from "../../components/common/LayoutComponent";
import { PageProps } from "../../helpers/types";
import CloudComponent from "./components/CloudComponent";
import styles from "./CloudsConnectivityContent.module.scss";
import { withContexts } from "../../helpers/hocs/withContexts";
import CloudsConnectivityContext, {
  useCloudsConnectivityContext,
} from "./CloudsConnectivityContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useUserContext } from "../../contexts/UserContext";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";

const CloudsConnectivityContent: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    fetchList,
    grouppedList,
    resetTokens,
    tokens,
    requestToRemove,
    deleteAccessKey,
    setRequestToRemove,
  } = useCloudsConnectivityContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { isDemo } = useUserContext();

  const isDisabled = (type: string) => {
    if (["aws", "azure"].includes(type)) return false;
    else if (isDemo) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (selectedTenant) {
      fetchList(selectedTenant);
      if (tokens) resetTokens();
    }
  }, [selectedTenant]);

  const handleDeleteKey = () => {
    if (selectedTenant && requestToRemove) {
      deleteAccessKey(selectedTenant, requestToRemove);
      setRequestToRemove(undefined);
    }
  };

  const handleCancel = () => {
    setRequestToRemove(undefined);
  };

  return (
    <LayoutComponent>
      <Tabs hideControls />
      <ConfirmDeleteDialog
        deviceType={"Credentials"}
        name={`${requestToRemove?.toUpperCase()} cloud credentials`}
        isShown={!!requestToRemove}
        onOk={handleDeleteKey}
        onCancel={handleCancel}
      />
      <div className={styles.wrapper}>
        {Object.keys(grouppedList).map((type) => (
          <CloudComponent
            type={type}
            vpc={grouppedList[type]}
            disabled={isDisabled(type)}
          />
        ))}
      </div>
    </LayoutComponent>
  );
};
export default withContexts<PageProps>(CloudsConnectivityContent, [
  CloudsConnectivityContext,
]);
