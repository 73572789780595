import { FilteredPortsLists, PortTypeScheme } from "../types";

export const getFilteredLists = (
  list: Array<PortTypeScheme>
): FilteredPortsLists => {
  return list.reduce(
    (acc: FilteredPortsLists, el: PortTypeScheme) => {
      if (el.role === "backplane") {
        acc.backplane.push(el);
        return acc;
      } else if (el.role === "data") {
        acc.data.push(el);
        //sorting the array of ports, "lag" ports are shown above the others
        acc.data.sort((a, b) => {
          if (a.name.startsWith("lag") && !b.name.startsWith("lag")) {
            return -1;
          }
          if (!a.name.startsWith("lag") && b.name.startsWith("lag")) {
            return 1;
          }
          return 0;
        });
        return acc;
      } else if (el.role === "lag") {
        acc.lag.push(el);
        return acc;
      } else {
        acc.basic.push(el);
        return acc;
      }
    },
    {
      basic: [],
      backplane: [],
      data: [],
      lag: [],
    }
  );
};
