import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { GREY_FONT } from "../../helpers/common/colorAliases";

function PieChartIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18a7 7 0 0 0 7-7H9V4a7 7 0 0 0 0 14Z"
        fill={color || GREY_FONT}
      />
      <path d="M18 9h-7V2a7 7 0 0 1 7 7Z" fill={color || GREY_FONT} />
    </svg>
  );
}

export default PieChartIcon;
