import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function UsersIcon({}: IconProps): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-2 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20514_265334)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5.33301C4 3.12387 5.79086 1.33301 8 1.33301C10.2091 1.33301 12 3.12387 12 5.33301C12 7.54214 10.2091 9.33301 8 9.33301C5.79086 9.33301 4 7.54214 4 5.33301Z"
          fill="#6B55F0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6216 11.2687C4.70489 10.8145 6.14984 10.667 8.00097 10.667C9.84831 10.667 11.291 10.8139 12.3734 11.2657C13.5351 11.7507 14.2482 12.5745 14.6283 13.7728C14.7695 14.2181 14.4355 14.667 13.9738 14.667H2.02403C1.56419 14.667 1.23217 14.2201 1.37224 13.7773C1.75122 12.5791 2.46177 11.7549 3.6216 11.2687Z"
          fill="#6B55F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_20514_265334">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

// function UserIcon({ color }: IconProps): ReactElement {
//   return (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clipPath="url(#clip0_20514_265334)">
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M4 5.33301C4 3.12387 5.79086 1.33301 8 1.33301C10.2091 1.33301 12 3.12387 12 5.33301C12 7.54214 10.2091 9.33301 8 9.33301C5.79086 9.33301 4 7.54214 4 5.33301Z"
//           fill={color || GREY_FONT}
//         />
//         <path
//           fillRule="evenodd"
//           clipRule="evenodd"
//           d="M3.6216 11.2687C4.70489 10.8145 6.14984 10.667 8.00097 10.667C9.84831 10.667 11.291 10.8139 12.3734 11.2657C13.5351 11.7507 14.2482 12.5745 14.6283 13.7728C14.7695 14.2181 14.4355 14.667 13.9738 14.667H2.02403C1.56419 14.667 1.23217 14.2201 1.37224 13.7773C1.75122 12.5791 2.46177 11.7549 3.6216 11.2687Z"
//           fill={color || GREY_FONT}
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_20514_265334">
//           <rect width="16" height="16" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   );
// }

export default UsersIcon;
