import { ReactElement } from "react";
import { IconProps } from "../../../../../../helpers/types";
import NetworkObservabilityIcon from "../../../../../../components/icons/NetworkObservabilityIcon";
import ApplicationsIcon from "../../../../../../components/icons/topologyButtons/ApplicationsIcon";
import ShieldIcon from "../../../../../../components/icons/ShieldIcon";
import NATIcon from "../../../../../../components/icons/NATIcon";
import SegmentsIcon from "../../../../../../components/icons/topologyButtons/SegmentsIcon";
import FirewallIcon from "../../../../../../components/icons/tabs/FirewallIcon";
import DNSIcon from "../../../../../../components/icons/topologyButtons/DNSIcon";

export const getServiceDetails = (
  type: string
): { icon: (props: IconProps) => ReactElement; title: string } | undefined => {
  const details = SERVICES_MAP[type];
  const icon = details?.icon;
  const title = details?.title;
  if (title) {
    return { icon, title };
  }
};

export const SERVICES_MAP: {
  [key: string]: {
    title: string;
    description: string;
    icon: (props: IconProps) => ReactElement;
    group: string;
  };
} = {
  sdr: {
    title: "Network Observability",
    description:
      "With Network Observability you will be able to understand traffic inside and coming from or going to your network on session level with location, segment, protocol and other information.",
    icon: NetworkObservabilityIcon,
    group: "insights",
  },
  dpi: {
    icon: ApplicationsIcon,
    title: "Application  Observability",
    description:
      "Enabling Application Observability will inspect all web traffic for application and domain name information to be able to give insights about network usage and communication per segment.",
    group: "insights",
  },
  ids: {
    icon: ShieldIcon,
    title: "Cyber Threats",
    description:
      "Threat Protection will inspect all traffic for attempts to exploit known vulnerabilities and report on malicious targets in the internet. The threat database is updated on a regular basis to keep it up to date with latest threats.",
    group: "insights",
  },

  // fqdn: {
  //   icon: CloudRouterIcon,
  //   title: "Cloud Router",
  //   description:
  //     "Connect different branches, clouds and other connections via a virtual Router with BGP support.",
  //   group: "network",
  // },
  nat: {
    icon: NATIcon,
    title: "Address Translation (NAT)",
    description:
      "With NAT it is possible to translate IP addresses on the way to hide multiple hosts behind one shared (public) IP or to redirect users to a different server. Supported modes are SNAT, Port Forwarding and 1:1 NAT.",
    group: "network",
  },
  segments: {
    icon: SegmentsIcon,
    title: "Network Segmentation",
    description:
      "When defining connections, adding a segment name to this connection will allow to control communication between segments and Network Observability data will be enriched with segment information.",
    group: "services",
  },
  firewall: {
    icon: FirewallIcon,
    title: "Stateful Firewall",
    description:
      "A Stateful Firewall allows you to control all traffic flows inside your network and those entering or leaving it. A default deny rule prevents all traffic not explicitly allowed by you and because it is stateful, only the server ports need to be opened for desired communications.",
    group: "services",
  },
  dns: {
    title: "DNS Threats",
    description: "",
    icon: DNSIcon,
    group: "insights",
  },
};
