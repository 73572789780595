import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function VirtInterfaceIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5563 3.88558C12.5298 3.82044 12.4678 3.77794 12.399 3.77794L9.92594 3.77795V0.840224C9.92594 0.744073 9.84967 0.666032 10.3633 0.666016L8.24431 0.665999C8.15034 0.665999 8.07407 0.74404 8.07407 0.840191V3.77794L5.59671 3.77795C5.52794 3.77795 5.46597 3.82046 5.43941 3.88526C5.41319 3.9504 5.42749 4.0253 5.47618 4.07513L8.87062 6.9641C8.90262 6.99685 8.94587 7.01531 8.99115 7.01531C9.03643 7.01531 9.07968 6.99685 9.11168 6.96445L12.5196 4.07547C12.5683 4.02563 12.5829 3.95074 12.5563 3.88558Z"
        fill={color || FONT}
      />
      <path
        d="M5.44366 14.0534C5.47022 14.1173 5.53219 14.1589 5.60096 14.1589H8.07406V17.1632C8.07406 17.2575 8.15033 17.334 8.2443 17.334L9.75569 17.334C9.84966 17.334 9.92593 17.2575 9.92593 17.1632V14.1589L12.4033 14.1589C12.4721 14.1589 12.534 14.1172 12.5606 14.0537C12.5868 13.9898 12.5725 13.9164 12.5238 13.8675L9.11568 11.0349C9.08369 11.0028 9.04044 10.9847 8.99516 10.9847C8.94988 10.9847 8.90663 11.0028 8.87463 11.0346L5.48043 13.8672C5.43174 13.9161 5.41711 13.9895 5.44366 14.0534Z"
        fill={color || FONT}
      />
      <path
        d="M3.76586 12.5576C3.82929 12.531 3.87068 12.4691 3.87068 12.4003L3.87069 9.95049H6.84833C6.94196 9.95049 7.01794 9.87422 7.01794 9.78025L7.01794 8.24309C7.01794 8.14912 6.94196 8.07285 6.84833 8.07285H3.87068L3.87066 5.59798C3.87066 5.5292 3.82927 5.46723 3.76618 5.44068C3.70275 5.41445 3.62981 5.42875 3.5813 5.47745L0.718495 8.75743C0.686615 8.78943 0.66863 8.83268 0.66863 8.87796C0.66863 8.92324 0.686615 8.96649 0.718162 8.99849L3.58097 12.5208C3.6295 12.5695 3.70242 12.5842 3.76586 12.5576Z"
        fill={color || FONT}
      />
      <path
        d="M14.2316 5.44044C14.1682 5.467 14.1268 5.52897 14.1268 5.59774V8.07288H11.1518C11.0581 8.07288 10.9821 8.14915 10.9821 8.24312L10.9821 9.75451C10.9821 9.84848 11.0581 9.92475 11.1518 9.92475H14.1268L14.1268 12.4001C14.1268 12.4688 14.1682 12.5308 14.2313 12.5574C14.2948 12.5836 14.3678 12.5693 14.4163 12.5206L17.2815 9.23919C17.3134 9.20719 17.3314 9.16394 17.3314 9.11866C17.3314 9.07339 17.3134 9.03013 17.2818 8.99813L14.4166 5.47721C14.3681 5.42852 14.2951 5.41389 14.2316 5.44044Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default VirtInterfaceIcon;
