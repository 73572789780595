import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT, PURPLE, WHITE } from "../../helpers/common/colorAliases";

type FilterIconProps = IconProps & {
  hasIndicator?: boolean;
};

function FilterIcon({
  color,
  className,
  onClick,
  indicatorColor,
  hasIndicator,
  height = 20,
  width = 24,
}: FilterIconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M0 2h20v2H0V2Zm3 7h14v2H3V9Zm11 7H6v2h8v-2Z"
        fill={color || FONT}
      />
      {hasIndicator && (
        <g clipPath="url(#a)">
          <circle cx="20" cy="4" r="4" fill={indicatorColor || PURPLE} />
        </g>
      )}
      <defs>
        <clipPath id="a">
          <path fill={WHITE} d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FilterIcon;
