import { FC, useEffect, useState } from "react";
import Input from "../../../components/common/formComponents/Input";
import SearchIcon from "../../../components/icons/SearchIcon";
import { useSearchContext } from "../SearchContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../contexts/TimerangeContext";
import { useParams } from "react-router-dom";

type Props = { className: string };

const SearchField: FC<Props> = ({ className }) => {
  const params = useParams<any>();
  const seacrhValueFromPath = params?.[0].slice(1);

  const { fetchData } = useSearchContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();

  const [searchValue, setSeacrhValue] = useState<string>("");

  useEffect(() => {
    if (seacrhValueFromPath && selectedTenant) {
      setSeacrhValue(seacrhValueFromPath);
      fetchData(seacrhValueFromPath, selectedTenant, timeRange.general);
    }
  }, [seacrhValueFromPath, selectedTenant]);

  useEffect(() => {
    if (timeRange && searchValue && selectedTenant) {
      fetchData(searchValue, selectedTenant, timeRange.general);
    }
  }, [timeRange]);

  const handleOnSearch = (key?: any) => {
    if ((key && key !== "Enter") || !selectedTenant) return;
    fetchData(searchValue, selectedTenant, timeRange.general);
  };

  return (
    <Input
      value={searchValue}
      onChange={(e) => {
        setSeacrhValue(e.target.value);
      }}
      medium
      className={className}
      rigthButtonIcon={SearchIcon}
      placeholder="IP (10.1.1.1) / hostname (www.insidepacket.com)"
      rigthButtonClick={() => handleOnSearch()}
      onKeyDown={({ key }) => handleOnSearch(key)}
      error={undefined}
    />
  );
};

export default SearchField;
