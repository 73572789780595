import { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { ORANGE } from "../../../helpers/common/colorAliases";
import ErrorIcon from "../../icons/ErrorIcon";
import TextWithIcon from "../table/TextWithIcon";
import styles from "./FieldError.module.scss";

type Props = {
  className?: string;
  message?: string;
};

const FieldError: FC<Props> = ({ className, message }) => {
  return (
    <TextWithIcon
      icon={<ErrorIcon color={ORANGE} />}
      text={message}
      className={classNames(styles.loader, className)}
    />
  );
};

export default FieldError;
