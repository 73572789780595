import React, { FC, useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { classNames } from "../../../../helpers/common/classNames";
import { FONT, GREY_DISABLED } from "../../../../helpers/common/colorAliases";
import { CONFIGURATION, REPORTS } from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";
import PieChartIcon from "../../../icons/PieChartIcon";
import SettingsIcon from "../../../icons/SettingsIcon";
import BlankButton from "../../buttons/BlankButton";
import TimerangOverlayComponent from "../../timerange/TimerangeOverlayComponent";
import ActiveTenantSelector from "../tenantSelector/ActiveTenantSelector";

import styles from "./BasicTabs.module.scss";
import Tabs from "./Tabs";

type Props = {
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  tabs?: Array<PathEntry>;
  root?: Array<PathEntry>;
  defaultTenants?: Array<any>;
};

const BasicTabs: FC<Props> = ({
  showTimeRange,
  noConfig,
  noReports,
  tabs,
  root,
  defaultTenants,
}) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const isReportingActive = useMemo(() => path.includes(REPORTS().path), [
    path,
  ]);

  return (
    <div className={styles.tabsWrapper}>
      <Tabs tabs={tabs} root={root} />
      <div className={styles.controlsWrapper}>
        <ActiveTenantSelector defaultTenants={defaultTenants} />
        {showTimeRange && (
          <TimerangOverlayComponent
            id={"timerangeComp"}
            className={styles.timerangeWrapper}
          />
        )}
        <div className={styles.selectView}>
          <BlankButton
            id={"configBtn"}
            className={classNames(noConfig && styles.disabled)}
            disabled={noConfig}
            onClick={() => {
              history.push(CONFIGURATION().path);
            }}
          >
            <SettingsIcon
              color={!noConfig && !isReportingActive ? FONT : GREY_DISABLED}
            />
          </BlankButton>
          <BlankButton
            id={"reportBtn"}
            className={classNames(noReports && styles.disabled)}
            disabled={noReports}
            onClick={() => {
              history.push(REPORTS().path);
            }}
          >
            <PieChartIcon
              color={!noReports && isReportingActive ? FONT : GREY_DISABLED}
            />
          </BlankButton>
        </div>
      </div>
    </div>
  );
};

export default BasicTabs;
