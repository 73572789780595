export const REGIONS: { [key: string]: Array<string> } = {
  aws: [
    "us-east-2",
    "us-east-1",
    "us-west-1",
    "us-west-2",
    "af-south-1",
    "ap-east-1",
    "ap-south-2",
    "ap-southeast-3",
    "ap-south-1",
    "ap-northeast-3",
    "ap-northeast-2",
    "ap-southeast-1",
    "ap-southeast-2",
    "ap-northeast-1",
    "ca-central-1",
    "eu-central-1",
    "eu-west-1",
    "eu-west-2",
    "eu-south-1",
    "eu-west-3",
    "eu-south-2",
    "eu-north-1",
    "eu-central-2",
    "me-south-1",
    "me-central-1",
    "sa-east-1",
  ],
  azure: [
    "East US",
    "East US 2",
    "South Central US",
    "West US 2",
    "West US 3",
    "Australia East",
    "Southeast Asia",
    "North Europe",
    "Sweden Central",
    "UK South",
    "West Europe",
    "Central US",
    "South Africa North",
    "Central India",
    "East Asia",
    "Japan East",
    "Korea Central",
    "Canada Central",
    "France Central",
    "Germany West Central",
    "Norway East",
    "Switzerland North",
    "UAE North",
    "Brazil South",
    "East US 2 EUAP",
    "Qatar Central",
    "Central US (Stage)",
    "East US (Stage)",
    "East US 2 (Stage)",
    "North Central US (Stage)",
    "South Central US (Stage)",
    "West US (Stage)",
    "West US 2 (Stage)",
    "Asia",
    "Asia Pacific",
    "Australia",
    "Brazil",
    "Canada",
    "Europe",
    "France",
    "Germany",
    "Global",
    "India",
    "Japan",
    "Korea",
    "Norway",
    "Singapore",
    "South Africa",
    "Switzerland",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States EUAP",
    "East Asia (Stage)",
    "Southeast Asia (Stage)",
    "East US STG",
    "South Central US STG",
    "North Central US",
    "West US",
    "Jio India West",
    "Central US EUAP",
    "West Central US",
    "South Africa West",
    "Australia Central",
    "Australia Central 2",
    "Australia Southeast",
    "Japan West",
    "Jio India Central",
    "Korea South",
    "South India",
    "West India",
    "Canada East",
    "France South",
    "Germany North",
    "Norway West",
    "Switzerland West",
    "UK West",
    "UAE Central",
    "Brazil Southeast",
  ],
  gcp: [
    "asia-east1",
    "asia-east2",
    "asia-northeast1",
    "asia-northeast2",
    "asia-northeast3",
    "asia-south1",
    "asia-south2",
    "asia-southeast1",
    "asia-southeast2",
    "australia-southeast1",
    "australia-southeast2",
    "europe-central2",
    "europe-north1",
    "europe-southwest1",
    "europe-west2",
    "europe-west3",
    "europe-west4",
    "europe-west6",
    "europe-west8",
    "europe-west9",
    "me-west1",
    "northamerica-northeast1",
    "northamerica-northeast2",
    "southamerica-east1",
    "southamerica-west1",
    "us-central1",
    "us-east4",
    "us-east5",
    "us-south1",
    "us-west1",
    "us-west2",
    "us-west3",
    "us-west4",
  ],
};
