import { FC, useEffect } from "react";
import { AWSAccessKey } from "../../../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { useGlobalFilterContext } from "../../../../../../../contexts/GlobalFilterContext";
import VPCContextContainer, { useVPCContext } from "./VPCListContext";
import { withContexts } from "../../../../../../../helpers/hocs/withContexts";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import Input from "../../../../../../../components/common/formComponents/Input";
import InputWithStatus from "../../../../../../../components/common/InputWithStatus/InputWithStatus";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import styles from "./VPCList.module.scss";
import { ChangeField } from "../../../types";

type Props = {
  onChange: ChangeField;
  externalFields: any;
  error?: string;
};

const DEFAULT_FIELDS: AWSAccessKey = {
  access_key_id: "",
  secret_access_key: "",
  session_token: "",
  description: "",
};

const VPCList: FC<Props> = ({ externalFields, onChange, error }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    list = [],
    fetchData,
    listStatus,
    token,
    changeAWSAccessKey,
  } = useVPCContext();

  const [fields, handleFieldChange, resetFields] = useFormField(DEFAULT_FIELDS);

  useEffect(() => {
    return () => {
      resetFields({});
    };
  }, []);

  useEffect(() => {
    if (externalFields?.location?.location_name && selectedTenant) {
      fetchData(externalFields?.location?.location_name, selectedTenant);
    }
  }, [externalFields?.location?.location_name, selectedTenant]);

  useEffect(() => {
    if (token) {
      resetFields(token);
    }
  }, [token]);

  const handleCheckBlur = async () => {
    if (fields.access_key_id && fields.secret_access_key && selectedTenant) {
      await changeAWSAccessKey(
        fields,
        selectedTenant,
        externalFields?.location?.location_name
      );
    }
  };

  return (
    <>
      <div className={styles.tokenFields}>
        <div className={styles.title}>Token</div>
        <div className={styles.field}>
          <Input
            value={fields.access_key_id}
            onChange={(e) => {
              onChange("Token_part1", e.target.value);
              handleFieldChange("access_key_id", e.target.value);
            }}
            isFullValue
            medium
            error={""}
            placeholder="Access key"
            onBlur={handleCheckBlur}
            type={"password"}
          />
          <Input
            value={fields.secret_access_key}
            onChange={(e) => {
              onChange("Token_part2", e.target.value);
              handleFieldChange("secret_access_key", e.target.value);
            }}
            isFullValue
            medium
            error={""}
            placeholder="Secret key"
            onBlur={handleCheckBlur}
            type={"password"}
          />
        </div>
      </div>
      <InputWithStatus status={listStatus} pendingMessage={"Discovering VPCs"}>
        <DropdownBasic
          id={"VPCListVPC"}
          onChange={(val) => onChange("VPC_ID", val.key)}
          selected={mapStringToItem(externalFields["VPC_ID"])}
          label="VPC"
          itemsList={list.map((key) => mapStringToItem(key))}
          isMedium
          error={listStatus?.state === "error" ? listStatus?.message : error}
        />
      </InputWithStatus>
    </>
  );
};

export default withContexts<Props>(VPCList, [VPCContextContainer]);
