/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { FilterAndSort, PBMType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUseWithExtra,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import { EMPTY_RESULT } from "../../helpers/common/constantsAlias";
import withTimerangeContext from "../../helpers/hocs/withTimerangeContext";
import { RequestStatus, TimerangeContextType } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";

type IState = CrudState<PBMType, PBMType> & {
  pbmList: Array<PBMType>;
  pbmListStatus: RequestStatus;
  lastFetchedTenant: string;
  selectedPBM: PBMType | undefined;
};

type IFunc = {
  fetchList: (tenant?: string, offset?: number, limit?: number) => void;
  selectPBM: (pbm: PBMType | undefined) => void;
  changePBMEnable: (pbm?: any | undefined) => void;
};

export type PBMDeleteArgs = PBMType & {
  tenant: string;
};

const [Context, useContext] = createContextAndUseWithExtra<
  IState,
  IFunc & CrudFunc<PBMType, PBMType>,
  TimerangeContextType
>();

export const usePBMContext = useContext;

type Props = React.PropsWithChildren<{
  timerangeContext: TimerangeContextType;
}>;

class PBMContextContainer extends AbstractCrudContextContainer<
  PBMType,
  PBMType,
  IState,
  PBMType
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      fetchList: this.fetchList,
      selectPBM: this.selectPBM,
      changePBMEnable: this.changePBMEnable,
    };
  }

  changePBMEnable = async (pbm?: PBMType | undefined) => {
    if (pbm) {
      await this.editReq(
        { enable: !pbm.enable, priority: pbm.priority },
        pbm.name,
        this.state.lastFetchedTenant
      );
    }
  };

  selectPBM = async (pbm: PBMType | undefined) => {
    this.setState({ selectedPBM: pbm });
  };

  listReq = async (params?: FilterAndSort, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.getPBMRules(tenant);
    // TEMP SOLUTION FOR SORTING
    if (res.ok) {
      const sortedResult = res.result?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      );
      return { ...res, result: sortedResult };
    }
    return res;
  };

  fetchList = async (
    tenant?: string,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    if (!tenant) return;
    this.setState({ pbmListStatus: setPending("Fetching") });
    const res = await this.listReq(undefined, tenant);

    if (res.ok && res.result) {
      this.setState({ pbmList: res.result, lastFetchedTenant: tenant });
    }
    this.setState({
      pbmListStatus: resToState(res),
      lastFetchedTenant: tenant,
    });
  };

  selectedReq = async (rule_name: string | number, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    return await configApi.getPBM(tenant, rule_name as string);
  };

  addReq = async (params: any, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.addPBM(tenant, params as any);
    if (res.ok) {
      await this.fetchList(this.state.lastFetchedTenant);
    }
    return res;
  };

  removeReq = async (rule: PBMType, tenant?: string) => {
    const selectedTenant = tenant || this.state.lastFetchedTenant;
    if (!selectedTenant) return EMPTY_RESULT;
    const res = await configApi.deletePBM(selectedTenant, rule.name.toString());
    if (res.ok) {
      await this.fetchList(this.state.lastFetchedTenant);
    }
    return res;
  };

  editReq = async (
    params: Partial<PBMType>,
    rule_name: string | number,
    tenant?: string
  ) => {
    if (!tenant || !params.priority) return EMPTY_RESULT;
    const res = await configApi.editPBM(tenant, rule_name as string, params);
    if (res && res.ok) {
      await this.fetchList(this.state.lastFetchedTenant);
    }
    return res;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.baseFuncs,
          ...this.funcs,
          ...this.props.timerangeContext,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default withTimerangeContext(PBMContextContainer);
