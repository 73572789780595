import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { TableColumnHeadersProps } from "../../components/common/table/newTable/Table";
import GlobeIcon from "../../components/icons/GlobeIcon";
import LocationIcon from "../../components/icons/LocationIcon";

import styles from "./TableGroupHeader.module.scss";
import { IpamRow } from "./types";

export const TableGroupHeader: FC<TableColumnHeadersProps<IpamRow>> = ({
  onCollapse,
  row,
}) => {
  const history = useHistory();
  const name = row?.original.name;

  const openAddDialog = () => {
    history.push("/ipam/add-ipam");
  };

  const Icon = () => {
    switch (name) {
      case "global":
        return <GlobeIcon />;
      case "location":
      default:
        return <LocationIcon />;
    }
  };

  return (
    <div className={styles.groupWrapper} onClick={onCollapse}>
      <div className={styles.groupTitle}>
        {Icon()}
        {name}
      </div>
      {/* <AddButton onClick={openAddDialog} isIconOnly /> */}
    </div>
  );
};
