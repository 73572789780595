import React, { ReactElement } from "react";
import { classNames } from "../../helpers/common/classNames";
import styles from "./Toggle.module.scss";

type ToggleProps = {
  isChecked: boolean;
  onChange: () => void;
  label?: string | ReactElement;
  disabled?: boolean;
  resolvedRef?: React.ForwardedRef<HTMLInputElement>;
  size?: "small" | "large";
  id?: string;
  className?: string;
  notEditable?: boolean;
};

export function Toggle({
  isChecked,
  onChange,
  label,
  disabled,
  resolvedRef,
  size,
  id,
  className,
  notEditable,
}: ToggleProps): ReactElement {
  const toggleStyles = classNames(
    styles.toggle,
    isChecked && styles.toggleActive,
    notEditable && styles.notEditable,
    disabled && styles.toggleDisabled,
    size === "large" && styles.largeSize,
    className
  );
  return (
    <div className={toggleStyles}>
      <input
        className={styles.toggleCheckbox}
        id={id || "switchNew"}
        type="checkbox"
        ref={resolvedRef}
        onChange={onChange}
        disabled={disabled}
        checked={isChecked}
      />
      <label className={styles.toggleLabel} htmlFor={id || "switchNew"}>
        <span className={styles.toggleButton} />
      </label>
      <span className={styles.toggleText}>{label}</span>
    </div>
  );
}
