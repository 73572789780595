import React, { FC } from "react";

import styles from "./PasswordChangeDialog.module.scss";
import DialogTemplateOneColumn from "../../../../components/dialogs/common/DialogTemplateOneColumn";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import ShieldFieldIcon from "../../../../components/icons/ShieldFillIcon";
import Input from "../../../../components/common/formComponents/Input";

type Props = {
  onCancel: () => void;
  onSave: () => void;
};

export const PasswordChangeDialog: FC<Props> = ({ onCancel, onSave }) => {
  return (
    <DialogTemplateOneColumn
      header={
        <TextWithIcon
          icon={<ShieldFieldIcon width={16} height={18} />}
          text="Change Password"
        />
      }
      controls={{
        okText: "Save",
        onOk: onSave,
        cancelText: "Cancel",
        onCancel,
      }}
    >
      <div className={styles.form}>
        <Input
          name="password"
          label="Current Password"
          placeholder="current_password"
          handleFieldChange={() => undefined}
          medium
          togglePasswordVisibility
        />
        <Input
          name="password"
          label="New Password"
          placeholder="current_password"
          handleFieldChange={() => undefined}
          medium
          togglePasswordVisibility
        />
        <Input
          name="password"
          label="Confirm Password"
          placeholder="current_password"
          handleFieldChange={() => undefined}
          medium
          togglePasswordVisibility
        />
      </div>
    </DialogTemplateOneColumn>
  );
};
