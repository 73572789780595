import { FC } from "react";
import styles from "./MetricCard.module.scss";
import { UniqueMetric } from "../../types";
import { RequestStatus } from "../../../../helpers/types";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { formatNumbers } from "../../../../helpers/formatNumbers";

type Props = {
  card: UniqueMetric;
  status?: RequestStatus;
  className: string;
};
const MetricCard: FC<Props> = ({ card, status, className }) => {
  const isPending = status?.state === "pending";
  const wrapperClassnames = classNames(styles.wrapper, className);
  return (
    <div className={wrapperClassnames}>
      {isPending ? (
        <PageLoader />
      ) : (
        <>
          <span className={styles.title}>{card.metric}</span>
          <span className={styles.value}>{formatNumbers(card.value)}</span>
        </>
      )}
    </div>
  );
};
export default MetricCard;
