import React, { ReactElement } from "react";
import styles from "./PageTitle.module.scss";
import { ComponentProps } from "../../helpers/types";

interface Props {
  title: ReactElement;
}

const PageTitle: React.FC<ComponentProps & Props> = (props) => {
  const { title, ...restProps } = props;

  return (
    <div className={styles.container} {...restProps}>
      {title}
    </div>
  );
};

export default PageTitle;
