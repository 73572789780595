import React, { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import styles from "./DIAPage.module.scss";
import TenantInfo from "../../components/tenants/OneTenant/TenantInfo";
import { CollapsableTwoColumnLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { OneTenantTabs } from "../../components/common/navigation/OneTenantTabs";
import DIATable from "./DIATable";

const DIAPage: FC = () => {
  return (
    <CollapsableTwoColumnLayout InfoBlock={() => <TenantInfo />}>
      <div className={styles.container}>
        <OneTenantTabs />
        <DIATable />
      </div>
    </CollapsableTwoColumnLayout>
  );
};
export default withLayout<any>(DIAPage);
