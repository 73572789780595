import React, { FC } from "react";
import { SERVICES } from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";
import BasicTabs from "../BasicTabs/BasicTabs";

type Props = {
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  tabs?: Array<PathEntry>;
};

export const ServicesTab: FC<Props> = ({
  showTimeRange,
  noConfig,
  noReports,
  tabs,
}) => {
  return (
    <BasicTabs
      showTimeRange={showTimeRange}
      noConfig={noConfig}
      noReports={noReports}
      tabs={tabs}
      root={[SERVICES()]}
    />
  );
};
