import React from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { Checkbox } from "../../../../../../components/common/formComponents/Checkbox";
import SegmentsComponent from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/common/SegmentsComponent";
import CloudOperatorField from "../../common/CloudOperatorField";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import { CONNECTION_END_POINT_MAP } from "../../helpers/consts";

import styles from "./IpsecConnectionExtraCloudFields.module.scss";

const IpsecConnectionExtraCloudFields = () => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  const {
    showBranchAgent,
    setBranchAgentVisibility,
  } = useConnectionConfiguratorContext();

  const toggleBranchAgent = (status: boolean) => {
    setBranchAgentVisibility(status);
  };

  return (
    <>
      <DropdownBasic
        id={"endPoint"}
        onChange={(val) => {
          onChange("endPoint", val.key);
        }}
        selected={CONNECTION_END_POINT_MAP.find(
          (idx) => idx?.key === fields.endPoint
        )}
        label="End Point"
        placeholder="Choose End Point"
        itemsList={CONNECTION_END_POINT_MAP}
        isMedium
      />
      <SegmentsComponent
        list={fields.network_segments || []}
        onChange={onChange}
        error={""}
      />
      {fields?.endPoint === "branch" && (
        <div className={styles.branchAgent}>
          <Checkbox
            isChecked={showBranchAgent}
            label={"Agent"}
            onChange={() => toggleBranchAgent(!showBranchAgent)}
          />
        </div>
      )}
      {fields?.endPoint === "cloud" && <CloudOperatorField />}
    </>
  );
};

export default IpsecConnectionExtraCloudFields;
