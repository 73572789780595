import React, { ReactElement } from "react";
import { FONT } from "../../../../helpers/common/colorAliases";
import { IconProps } from "../../../../helpers/types";

function IPAMIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      className={className}
    >
      <path
        fill={color || FONT}
        fillRule="evenodd"
        d="M11.401 17.4H4.287v1.2h15.429v-1.2H12.6V7.716h-1.2V17.4Z"
        clipRule="evenodd"
      />
      <circle cx="3.286" cy="18" r="2.571" fill={color || FONT} />
      <circle cx="12.001" cy="18" r="2.571" fill={color || FONT} />
      <circle cx="12.001" cy="7.714" r="4.286" fill={color || FONT} />
      <circle cx="20.716" cy="18" r="2.571" fill={color || FONT} />
    </svg>
  );
}

export default IPAMIcon;
