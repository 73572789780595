import React, { FC } from "react";
import { FONT, GREY_FONT } from "../../../helpers/common/colorAliases";
import CaretOutline from "../../icons/CaretOutline";
import BlankButton from "../buttons/BlankButton";
import styles from "./Paginator.module.scss";

type PaginatorProps = {
  page?: any;
  pageStartRowIndex?: number;
  pageEndRowIndex?: number;

  rowsAmount?: number;
  canPreviousPage: any;
  canNextPage: any;
  nextPage: any;
  previousPage: any;
};

const Paginator: FC<PaginatorProps> = ({
  canPreviousPage,
  page,
  pageStartRowIndex,
  pageEndRowIndex,
  canNextPage,
  nextPage,
  previousPage,
  rowsAmount,
}) => {
  return (
    <div className={styles.container}>
      <div>
        {pageStartRowIndex} - {pageEndRowIndex} of {rowsAmount}
      </div>
      <div className={styles.btnContainer}>
        <BlankButton
          id={"paginatorPrev"}
          onClick={previousPage}
          className={styles.btn}
          disabled={!canPreviousPage}
        >
          {
            <CaretOutline
              color={canPreviousPage ? FONT : GREY_FONT}
              className={styles.prevBtn}
            />
          }
        </BlankButton>
        <BlankButton
          id={"paginatorNext"}
          onClick={nextPage}
          className={styles.btn}
          disabled={!Boolean(canNextPage)}
        >
          {<CaretOutline color={canNextPage ? FONT : GREY_FONT} />}
        </BlankButton>
      </div>
    </div>
  );
};

export default Paginator;
