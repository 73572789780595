import { Segment, SegmentsRules } from "../SegmentsRulesContext";
import { ALLOW, CUSTOM, DENY } from "./consts";
import { isAllowAllExist, isCustomExist } from "./isAllowAllExist";

export const getCellStatus = (
  segments: Array<Segment>,
  rules?: SegmentsRules,
  currId?: number,
  crossId?: number
): string => {
  if (currId === undefined || crossId === undefined) return "ERROR";

  const src = segments.find((el) => el.id === currId)?.name;
  const dst = segments.find((el) => el.id === crossId)?.name;

  if (!src || !dst) return "ERROR";

  const isAllowAll = isAllowAllExist(src, dst, rules?.allowAll);
  const isCustom = isCustomExist(src, dst, rules?.custom);

  // Allow all rule
  if (isAllowAll) return ALLOW;

  // Deny rule
  if (!isAllowAll && src !== dst && !isCustom) return DENY;

  // One rule but without specific name or more then one rule - Custom.
  return CUSTOM;
};
