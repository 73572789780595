import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus } from "../../helpers/types";
import { isLocalStorageSupported } from "../../helpers/isLocalStorageSupported";
import { tenantApi } from "../../helpers/api/TenantApi";
import { FiltersFields } from "./types";
import { Service } from "../WizardToolPage/ServicesStep/types";
import { CYBER_THREATS_FIELDS_MAP, CYBER_THREATS_FIELD_VALUE } from "./consts";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";

const LOCALSTORAGE_CYBERTHREATS_KEY = "LOCALSTORAGE_CYBERTHREATS_KEY";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = {
  filters?: FiltersFields | undefined;
  filtersData: { [key: string]: Array<any> };
  ids: boolean;
  dpi: boolean;
};

type IState = State & RequestStatuses;
type IFunc = {
  setFilters: (fields?: FiltersFields) => void;
  fetchData: (tenant: string, timeRange: GTimeRange) => Promise<void>;
  toggleServices: (
    value: boolean,
    id: Service,
    tenant?: string
  ) => Promise<void>;
  fetchServicesData: (tenant: string) => Promise<void>;
};

const [CyberThreatsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useCyberThreatsContext = useContext;

export default class CyberThreatsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = CyberThreatsContext;

  componentWillMount() {
    const savedFilters = window.localStorage.getItem(
      LOCALSTORAGE_CYBERTHREATS_KEY
    );
    if (savedFilters) {
      this.setFilters(JSON.parse(savedFilters));
    }
  }

  updateLoacalstorageByFilters(fields?: FiltersFields): void {
    if (!isLocalStorageSupported()) {
      return;
    }
    window.localStorage.setItem(
      LOCALSTORAGE_CYBERTHREATS_KEY,
      JSON.stringify(fields)
    );
  }

  setFilters = (fields?: FiltersFields): void => {
    this.setState({ filters: fields });
  };

  fetchData = async (tenant: string, timeRange: GTimeRange): Promise<void> => {
    const filtersData: { [key: string]: Array<any> } = {};
    await Promise.all(
      CYBER_THREATS_FIELDS_MAP.map(
        async (name: string): Promise<any> => {
          filtersData[name] = await this.fetchElasticFields(
            CYBER_THREATS_FIELD_VALUE[name],
            tenant,
            timeRange
          );
        }
      )
    );
    this.setState({ filtersData });
  };

  fetchElasticFields = async (
    fieldName: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> => {
    const { ok, result } = await elasticApi.getElasticFiltersValues(
      fieldName,
      tenant,
      "IDS",
      timeRange
    );
    if (ok && result) {
      return result;
    }
    return [];
  };

  getIds = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getServiceIds(tenant);
    if (ok && result) {
      this.setState({ ids: result.all });
    }
  };

  getDpi = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getServiceDpi(tenant);
    if (ok && result) {
      this.setState({ dpi: result.enabled });
    }
  };

  toggleServices = async (
    value: boolean,
    id: Service,
    tenant?: string
  ): Promise<void> => {
    if (!tenant) return;
    let res;
    if (id === Service.DPI) {
      res = await tenantApi.editServiceDpi(tenant, value);
      if (res.ok) this.getDpi(tenant);
    }
    if (id === Service.IDS) {
      res = await tenantApi.editServiceIds(tenant, value);
      if (res.ok) this.getIds(tenant);
    }
  };

  fetchServicesData = async (tenant: string): Promise<void> => {
    this.getIds(tenant);
    this.getDpi(tenant);
  };

  funcs = {
    setFilters: this.setFilters,
    fetchData: this.fetchData,
    toggleServices: this.toggleServices,
    fetchServicesData: this.fetchServicesData,
  };
}
