import React, { FC } from "react";
import Input from "../../../../../../components/common/formComponents/Input";
import { AddRuleButton } from "../../AddRuleButton";
import { RuleLayout } from "../../WidgetLayout/RuleLayout";
import { SubmitButton } from "../Submit";
import styles from "./styles.module.scss";

export const NatService: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <RuleLayout>
          <div className={styles.form}>
            <Input
              placeholder="NAT name"
              name="nat_name"
              onChange={() => undefined}
              label="Name"
              className={styles.input}
            />

            <Input
              placeholder="11.11.11.11"
              name="outside_ip"
              onChange={() => undefined}
              label="Outside IP"
            />
            <Input
              placeholder="Description"
              name="description"
              onChange={() => undefined}
              label="Description"
              isOptional
            />
            <Input
              placeholder="192.168.1.1"
              name="inside_ip"
              onChange={() => undefined}
              label="Inside IP"
            />
          </div>
        </RuleLayout>
        <AddRuleButton />
      </div>
      <div className={styles.submit}>
        <SubmitButton onClick={() => undefined} />
      </div>
    </div>
  );
};
