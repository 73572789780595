import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./Button.module.scss";

type ButtonProps = {
  isPrimaryBtn?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: (arg: any) => void;
  children: React.ReactNode;
  isBtnSmall?: boolean;
};

const Button: FC<ButtonProps> = ({
  isPrimaryBtn,
  className,
  onClick,
  children,
  disabled,
  isBtnSmall,
}) => {
  const btnContainer = classNames(
    styles.btn,
    isPrimaryBtn && styles.primaryBtn,
    disabled && styles.disabledBtn,
    isBtnSmall && styles.smallBtn,
    className
  );

  return (
    <button className={btnContainer} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
