import { AbstractContextProvider } from "../../../../../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../../../../../contexts/common/utils";
import { tenantApi } from "../../../../../../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../../../../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../../../../../helpers/types";
import { AWSAccessKey } from "../../../../../types";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = {
  list?: Array<string>;
  isToken: boolean;
  token?: AWSAccessKey;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (region: string, tenant: string) => Promise<void>;
  getAWSAccessKey: (tenant: string) => Promise<void>;
  createAWSAccessKey: (fields: AWSAccessKey, tenant: string) => Promise<void>;
};

const [VPCContext, useVPCContext] = createContextAndUse<IState, IFunc>();
export { useVPCContext };

export default class VPCContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = VPCContext;
  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchList = async (region: string, tenant: string): Promise<void> => {
    this.setState({ listStatus: setPending() });
    const res = await tenantApi.getAWSVPCListApi(tenant, region);

    if (res) {
      this.setState({
        listStatus: resToState(res),
        list: res?.result?.map((el) => el.VpcId) || [],
      });
    }
  };

  getAWSAccessKey = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getAWSAccessKey(tenant);
    this.setState({ token: result, isToken: ok });
  };

  createAWSAccessKey = async (
    fields: AWSAccessKey,
    tenant: string
  ): Promise<void> => {
    if (this.state.isToken) {
      await tenantApi.deleteAWSAccessKey(tenant);
    }
    await tenantApi.createAWSAccessKey(fields, tenant);
    this.getAWSAccessKey(tenant);
  };

  funcs = {
    fetchList: this.fetchList,
    getAWSAccessKey: this.getAWSAccessKey,
    createAWSAccessKey: this.createAWSAccessKey,
  };
}
