export const maskedStr = (value: string | number): string => {
  if (!value) return "-";
  const str = value?.toString();

  if (str.length <= 2) {
    return str;
  }

  const maskLength = Math.ceil((str.length - 2) / 2);
  const firstChars = str.substring(0, maskLength);
  const lastChars = str.substring(str.length - maskLength);
  const maskedChars = "*".repeat(str.length - maskLength * 2);

  return firstChars + maskedChars + lastChars;
};
