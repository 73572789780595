import { FC, useEffect, useMemo } from "react";
import Button from "../../../../components/common/buttons/Button";
import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import AddButton from "../../../../components/common/buttons/AddButton";
import styles from "./RouteFiltersOverview.module.scss";
import Table from "../../../../components/common/table/newTable/Table";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { useHistory } from "react-router-dom";
import {
  ADD,
  BGP,
  CLOUD_ROUTER,
  EDIT,
  NETWORK,
  ROUTE_FILTERS,
} from "../../../../helpers/navigation/entries";
import { useRouteFiltersContext } from "../../RouteFiltersContext";
import { routeFiltersTableColumns } from "./table/routeFiltersTableColumns";
import { formPathFromArray } from "../../../../helpers/navigation";
import { UserRouteFilters } from "../../types";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import { ActionFuncs } from "../../../../components/common/table/newTable/RowControls";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";

const RouteFiltersOverview: FC = () => {
  const history = useHistory();
  const {
    fetchList,
    list,
    listStatus,
    amount,
    remove,
    removeStatus,
  } = useRouteFiltersContext();

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<string>(remove, removeStatus);

  useEffect(() => {
    const tenant = localStorage.getItem("selectedTenant");
    if (tenant) {
      fetchList(tenant);
    }
  }, []);

  const handleAdd = () => {
    history.push(ROUTE_FILTERS().path + "/" + ADD().path);
  };

  const handleBack = () => {
    history.replace(formPathFromArray([NETWORK(), CLOUD_ROUTER(), BGP()]));
  };

  const handleDelete = (row: UserRouteFilters) => {
    const tenant = localStorage.getItem("selectedTenant");
    tryDelete(row.name, tenant);
  };

  const handleEdit = (row: UserRouteFilters) => {
    history.push(ROUTE_FILTERS().path + "/" + EDIT(row.name).path);
  };

  const rowActions: ActionFuncs<UserRouteFilters> = useMemo(
    () => ({
      onEdit: handleEdit,
      onDelete: handleDelete,
    }),
    []
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Route Filter"}
        name={selectedToDelete}
      />
      <div className={styles.wrapper}>
        <TableWrapper
          titleProps={{
            title: `Route Filters (${amount})`,
            children: <AddButton onClick={handleAdd} />,
          }}
          dataStatus={listStatus}
          tablePlaceholder={["Route Filters", "Route Filter"]}
          className={styles.tableWrapper}
        >
          <div className={styles.tableContent}>
            <div className={styles.row}>
              <Table
                extraTitle={"Global"}
                columns={routeFiltersTableColumns()}
                header={TableHeader}
                data={list.global}
                gridColumnTemplate={`1fr 0`}
                withoutPagination
              />
            </div>
            <div className={styles.row}>
              <Table
                extraTitle={"User"}
                columns={routeFiltersTableColumns()}
                header={undefined}
                data={list.user}
                gridColumnTemplate={`1fr 0`}
                withoutPagination
                rowActions={rowActions}
              />
            </div>
          </div>
        </TableWrapper>
        <div className={styles.backBtn}>
          <Button onClick={handleBack}>Back to BGP</Button>
        </div>
      </div>
    </>
  );
};
export default withLayout(RouteFiltersOverview);
