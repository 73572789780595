import { FC, useEffect } from "react";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { withLayout } from "../../../helpers/hocs/withLayout";
import { PageProps } from "../../../helpers/types";
import {
  NetworkContextContainer,
  useNetworkContext,
} from "../context/NetworkContext";
import styles from "./ConnectionsStatistics.module.scss";
import InterfaceStatistics from "./InterfaceStatistics";

const ConnectionsStatistics: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchConnections, fetchSystems } = useNetworkContext();

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  return (
    <>
      <div className={styles.pageWrapper}>
        <Tabs showTimeRange />
        <InterfaceStatistics />
      </div>
    </>
  );
};

export default withContexts<PageProps>(
  withLayout<PageProps>(ConnectionsStatistics),
  [NetworkContextContainer]
);
