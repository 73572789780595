import React, { FC, useEffect, useState } from "react";
import { PageProps } from "../../../../helpers/types";
import { WEB_FILTERING } from "../../../../helpers/navigation/entries";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import Footer from "./components/Footer";
import BlockSelectedContainer from "./components/BlockSelectedContainer";
import AllowSelectedContainer from "./components/AllowSelectedContainer";
import styles from "./WebFilteringConfig.module.scss";
import {
  WEB_FILTERING_ENTITIES,
  WEB_FILTERING_TABS,
} from "../../helpers/consts";
import TabsComponent from "./components/TabsComponent";
import { useWebFilteringsContext } from "../../WebFilteringContext";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { WebFilteringTab } from "../../types";
import { classNames } from "../../../../helpers/common/classNames";

const WebFilteringConfig: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    servicesStatus,
    fetchServicesStatus,
    fetchData,
    defaultAction,
    dataRequest,
  } = useWebFilteringsContext();
  const [activeTab, setActiveTab] = useState<WebFilteringTab>(
    WEB_FILTERING_TABS[0]
  );

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (selectedTenant && servicesStatus?.fqdn.overall) {
      fetchData(selectedTenant);
    }
  }, [selectedTenant, servicesStatus]);

  useEffect(() => {
    if (defaultAction) {
      const activeTab =
        WEB_FILTERING_TABS.find((el) => el.id === defaultAction) ||
        WEB_FILTERING_TABS[0];
      setActiveTab(activeTab);
    }
  }, [defaultAction]);

  const isLoading = dataRequest?.state === "pending";

  return (
    <div className={styles.pageWrapper}>
      <Tabs tabs={[WEB_FILTERING()]} />
      {servicesStatus && !servicesStatus.fqdn.overall ? (
        <ServicesRedirectPlaceholder
          type={"fqdn"}
          adminMode={servicesStatus.firewall.adminSide}
          fetchData={fetchServicesStatus}
        />
      ) : (
        <div
          className={classNames(styles.wrapper, isLoading && styles.loading)}
        >
          {!dataRequest || isLoading ? (
            <PageLoader />
          ) : (
            <>
              <TabsComponent activeTab={activeTab} onClick={setActiveTab} />
              {activeTab.id === WEB_FILTERING_ENTITIES.BLOCK_SELECTED ? (
                <BlockSelectedContainer />
              ) : (
                <AllowSelectedContainer />
              )}
              <Footer activeTab={activeTab} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default withLayout<PageProps>(WebFilteringConfig);
