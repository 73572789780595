import { CellProps } from "react-table";
import {
  VirtualInterfaceType,
  VRouter_ARP,
} from "../../../../../helpers/api/apiTypes";
import { getBooleanIcon } from "../../../../../helpers/getIconFromValue";
import { getNameById } from "../../../../../helpers/getNameById";

export const arpsTableHeader = (
  viList?: Array<VirtualInterfaceType>
): Array<any> => {
  return [
    {
      Header: "IP",
      accessor: "ip",
      Cell: ({ cell: { value } }: CellProps<VRouter_ARP>) => {
        return value || "";
      },
    },
    {
      Header: "MAC",
      accessor: "mac",
      Cell: ({ cell: { value } }: CellProps<VRouter_ARP>) => {
        return value || "";
      },
    },
    {
      Header: "Virtual Interface",
      accessor: "bridge_domain_id",
      Cell: ({ cell: { value } }: CellProps<VRouter_ARP>) => {
        return getNameById(value, viList) || value || "";
      },
    },
    {
      Header: "Age(sec)",
      accessor: "age_sec",
      Cell: ({ cell: { value } }: CellProps<VRouter_ARP>) => {
        return value || "";
      },
    },
    {
      Header: "Static",
      accessor: "is_static",
      Cell: ({ cell: { value } }: CellProps<VRouter_ARP>) => {
        return getBooleanIcon(value);
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VRouter_ARP,
  }));
};
