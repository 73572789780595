import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";

function ReloadSquareIcon({
  color,
  className,
  onClick,
}: IconProps): ReactElement {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect x="1" y="1" width="28" height="28" rx="10" stroke="#6B55F0" />
      <path d="M9.5 17L12 14H7L9.5 17Z" fill={color || BUTTON_PRIMARY} />
      <path
        d="M9.40039 15.0004C9.40039 11.9076 11.9076 9.40039 15.0004 9.40039C18.0932 9.40039 20.6004 11.9076 20.6004 15.0004C20.6004 18.0932 18.0932 20.6004 15.0004 20.6004C14.1415 20.6004 13.3278 20.407 12.6004 20.0615"
        stroke="#6B55F0"
        stroke-width="2"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default ReloadSquareIcon;
