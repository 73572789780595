import React, { FC } from "react";
import EditButton from "../buttons/EditButton";
import LabelOutline from "../table/LabelOutline";
import ValueWithPlaceholder from "../ValueWithPlaceholder";

import styles from "./BGPAutonomousSystem.module.scss";

type Props = {
  onClick?: () => void;
  data?: { asn?: string | number; router_id?: string | number };
  isIconShown?: boolean;
  isIconDisabled?: boolean;
};

const BGPAutonomousSystem: FC<Props> = ({
  onClick = (f: any) => f,
  data,
  isIconShown,
  isIconDisabled,
}) => {
  return (
    <div className={styles.complexHeader}>
      <LabelOutline type="font" isMedium className={styles.oneLine}>
        <span className={styles.label}>ASN: </span>
        <ValueWithPlaceholder value={data?.asn} />
      </LabelOutline>
      {isIconShown && (
        <EditButton
          id={"BJPAuto"}
          disabled={isIconDisabled}
          onClick={onClick}
          isOnlyOpacity
        />
      )}
    </div>
  );
};

export default BGPAutonomousSystem;
