import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

const fromApplicationsCountBody = (
  timeRange: GTimeRange,
  tenantName?: string
): { [key: string]: any } => {
  return {
    aggs: {
      "1": {
        cardinality: {
          field: "Incoming Segments.keyword",
        },
      },
      "2": {
        cardinality: {
          field: "Source Group.keyword",
        },
      },
      "3": {
        cardinality: {
          field: "Source IP.keyword",
        },
      },
      "4": {
        cardinality: {
          field: "Username.keyword",
        },
      },
      "5": {
        cardinality: {
          field: "Hostname.keyword",
        },
      },
      "6": {
        cardinality: {
          field: "Location.keyword",
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  match_phrase: { "event_type.keyword": "DPI" },
                },
              ],
              minimum_should_match: 1,
            },
          },
          { match_phrase: { "tenant_name.keyword": tenantName } },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: parseGTime(timeRange.from),
                lte: parseGTime(timeRange.to),
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default fromApplicationsCountBody;
