import React from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { DropdownItem } from "../../../../../helpers/types";
import { useConnectionConfiguratorContext } from "../ConnectionConfiguratorContext";
import { CONNECTION_END_POINT_MAP } from "../helpers/consts";

const DEFAULT_ENDPOINT = CONNECTION_END_POINT_MAP.find(
  (c) => c.key === "branch"
);

const EndpointDropdown = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  const selectedEndpoint =
    CONNECTION_END_POINT_MAP.find((idx) => idx?.key === fields.endPoint) ||
    DEFAULT_ENDPOINT;

  const onChange = (value: DropdownItem) => {
    fieldsOnChange("endPoint", value.key);
  };

  return (
    <DropdownBasic
      id={"endPoint"}
      onChange={onChange}
      selected={selectedEndpoint}
      label="End Point"
      placeholder="Choose End Point"
      itemsList={CONNECTION_END_POINT_MAP}
      isMedium
    />
  );
};

export default EndpointDropdown;
