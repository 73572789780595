/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const sendRequest = async (
  isOk: boolean,
  request: any,
  onClose: () => void
) => {
  if (isOk) {
    const successAdd = await request;
    if (successAdd) onClose();
  }
};
export default sendRequest;
