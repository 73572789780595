import { ConnectionApi } from "../types";

export const getBGPStatus = (
  connection: ConnectionApi,
  bgpNeighbors?: Array<any>,
  viList?: Array<any>
): Array<any> | undefined => {
  const connectedVI = connection.z_endpoint_conf?.ip_addresses?.[0];
  if (
    !bgpNeighbors ||
    bgpNeighbors.length === 0 ||
    !viList ||
    viList.length === 0 ||
    !connectedVI
  ) {
    return undefined;
  }
  const viName = viList.find((vi) => vi?.ip_addresses?.[0] === connectedVI)
    ?.name;
  return bgpNeighbors?.filter((el) => el?.connected_vi === viName);
};
