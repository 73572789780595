import React, { FC } from "react";
import PortFields from "./PortFields";
import VlanFields from "./VlanFields";
import VniFields from "./VniFields";

type Props = {
  system: string;
  currentL2Type: string;
  changeCurrentL2Type: (val: string) => void;
  isEdit: boolean;
  fieldProps: any;
  fields: { [key: string]: any };
  onChange: (fields: string, value: string | number) => void;
};

const L2TypeFields: FC<Props> = ({
  system,
  currentL2Type,
  isEdit,
  fieldProps,
  fields,
  onChange,
}) => {
  switch (currentL2Type) {
    case "port_vlan":
      return (
        <VlanFields
          system={system}
          isEdit={isEdit}
          fieldProps={fieldProps}
          fields={fields}
          onChange={onChange}
        />
      );
    case "vtep_vni":
      return (
        <VniFields
          system={system}
          isEdit={isEdit}
          fieldProps={fieldProps}
          fields={fields}
          onChange={onChange}
        />
      );
    case "port_interface":
    default:
      return (
        <PortFields
          system={system}
          isEdit={isEdit}
          fields={fields}
          onChange={onChange}
        />
      );
  }
};
export default L2TypeFields;
