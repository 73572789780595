import { FC, useState } from "react";
import styles from "./AllowSelectedContainer.module.scss";
import AddFormComponent from "../common/AddFormComponent";
import AddButton from "../../../../../components/common/buttons/AddButton";
import { useWebFilteringsContext } from "../../../WebFilteringContext";
import { WEB_FILTERING_ENTITIES } from "../../../helpers/consts";
import { generateListItemId } from "../../../helpers/generateListItemId";
import { FDQNDomain, FilterType } from "../../../types";

type Props = {};

const AllowSelectedContainer: FC<Props> = () => {
  const {
    denyDomains,
    addGenerslWebFilterSettings,
    removeGenerslWebFilterSettings,
  } = useWebFilteringsContext();

  const [url, setUrl] = useState<string>("");
  const id = WEB_FILTERING_ENTITIES.ALLOW_SELECTED;

  const handleRemoveItem = (item: FDQNDomain) => {
    removeGenerslWebFilterSettings(id, item);
  };

  const handleAddItem = () => {
    const type: FilterType = "allow";
    const itemId = generateListItemId(denyDomains);
    const newItem = { type, domain: url, id: itemId };
    addGenerslWebFilterSettings(id, newItem);
    setUrl("");
  };

  return (
    <AddFormComponent
      removeItem={handleRemoveItem}
      urlValue={url}
      onChange={setUrl}
      btns={[<AddButton onClick={handleAddItem} className={styles.addBtn} />]}
      list={denyDomains}
      className={styles.formContainer}
    />
  );
};

export default AllowSelectedContainer;
