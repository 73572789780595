import React, { FC } from "react";
import ReactTooltip from "react-tooltip";
import { FONT } from "../../../../helpers/common/colorAliases";
import QuestionIcon from "../../../icons/QuestionIcon";
import styles from "./SectionTitle.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import { EXISTED_VALUES_MAP, TOOLTIPS_MAP } from "./constAlias";

type Props = {
  fieldKey: string;
  className?: string;
};

const SectionTitle: FC<Props> = ({ fieldKey, className }) => {
  return (
    <div className={classNames(styles.titleRow, className)}>
      {EXISTED_VALUES_MAP[fieldKey] || fieldKey}
      <div data-tip data-for={fieldKey} className={styles.tooltipIcon}>
        <QuestionIcon color={FONT} />
      </div>
      <ReactTooltip
        id={fieldKey}
        aria-haspopup="true"
        place="right"
        type="light"
        className={styles.tooltipWrapper}
      >
        <div>{TOOLTIPS_MAP[fieldKey]}</div>
      </ReactTooltip>
    </div>
  );
};
export default SectionTitle;
