import React, { FC } from "react";
import styles from "./BGPConfig.module.scss";
import IconBlankButton from "../../../../../../../components/common/buttons/IconBlankButton";
import SettingsIcon from "../../../../../../../components/icons/SettingsIcon";
import { useHistory } from "react-router";
import { formPathFromArray } from "../../../../../../../helpers/navigation";
import {
  BGP,
  CLOUD_ROUTER,
  NETWORK,
} from "../../../../../../../helpers/navigation/entries";

const BGPConfig: FC = () => {
  const history = useHistory();

  return (
    <div className={styles.wrapper}>
      <IconBlankButton
        id={""}
        icon={SettingsIcon}
        label="Configure BGP"
        onClick={() =>
          history.push(formPathFromArray([NETWORK(), CLOUD_ROUTER(), BGP()]))
        }
        className={styles.btn}
      />
    </div>
  );
};
export default BGPConfig;
