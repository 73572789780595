import { ASType, StatusNeighborsType, StatusTypeType } from "./api/apiTypes";

const extractFieldsFromBGPStatus = (bgpStatus: {
  [key: string]: StatusTypeType;
}): {
  bgpAS?: Partial<ASType>;
  bgpNeighbors?: Array<StatusNeighborsType>;
} => {
  if (!bgpStatus) {
    return { bgpAS: undefined, bgpNeighbors: [] };
  }

  const types = Object.keys(bgpStatus);
  const bgpAS: Partial<ASType> = {
    asn: bgpStatus[types[0]].as,
    router_id: bgpStatus[types[0]].routerId,
  };
  const bgpNeighbors: Array<StatusNeighborsType> = [];

  types.map((type) => {
    const peers = Object.keys(bgpStatus[type].peers);
    peers.map((peer) =>
      bgpNeighbors.push({
        ...bgpStatus[type].peers[peer],
        remote_router_ip: peer,
        remoteAs: bgpStatus[type].as,
        idType: convertBgpPeerStatus(type),
      })
    );
  });
  return { bgpAS, bgpNeighbors };
};

const convertBgpPeerStatus = (type: string) => {
  if (type === "ipv4Unicast") {
    return "ipv4";
  }

  return "L2evpn";
};

export default extractFieldsFromBGPStatus;
