import { FC } from "react";
import PortStatus from "./PortStatus";
import { getPortBodyColor } from "../helpers/getPortBodyColors";
import { PortTypeScheme } from "../../../../types";
import { usePortsContext } from "../../../../PortsContext";

type Props = { x: number; y: number; subPorts: Array<PortTypeScheme> };

const AsideConnections: FC<Props> = ({ x, y, subPorts }) => {
  return (
    <svg
      x={x}
      y={y}
      width="19"
      height="29"
      viewBox="0 0 19 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* container */}
      <line x1="1" y1="1" x2="1" y2="28" stroke="#D8DAEB" />
      <line x1="9.5" y1="1" x2="9.5" y2="28" stroke="#D8DAEB" />
      <line x1="1" y1="14.5" x2="18" y2="14.5" stroke="#D8DAEB" />
      {/* statuses */}
      <ConnectionStatus
        x="1"
        y="1"
        status={subPorts[0]?.operational_state}
        name={subPorts[0]?.name}
      />
      <ConnectionStatus
        x="1"
        y="15"
        status={subPorts[1]?.operational_state}
        name={subPorts[1]?.name}
      />
      <ConnectionStatus
        x="10"
        y="1"
        status={subPorts[2]?.operational_state}
        name={subPorts[2]?.name}
      />
      <ConnectionStatus
        x="10"
        y="15"
        status={subPorts[3]?.operational_state}
        name={subPorts[3]?.name}
      />
    </svg>
  );
};

export default AsideConnections;

type ConnectionStatusProps = {
  x: number | string;
  y: number | string;
  status: string;
  name: string;
};
const ConnectionStatus: FC<ConnectionStatusProps> = ({
  x,
  y,
  status,
  name,
}) => {
  const { setTooltip } = usePortsContext();
  return (
    <svg
      x={x}
      y={y}
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() =>
        setTooltip({
          id: name,
          value: name,
        })
      }
      onMouseLeave={() => setTooltip(undefined)}
    >
      <rect width="8" height="13" fill={getPortBodyColor(status)[0]} />
      <PortStatus cx="4" cy="7" r="3" status={status} />
    </svg>
  );
};
