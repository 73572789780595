import { useHistory } from "react-router-dom";
import { PathEntry } from "../../../../helpers/types";
import NavigationTabs from "../NavigationTabs";
import { formPathFromArray } from "../../../../helpers/navigation";
import { FC } from "react";

type Props = {
  tabs?: Array<PathEntry>;
  root?: Array<PathEntry>;
};

export const Tabs: FC<Props> = ({ tabs = [], root = [] }) => {
  const history = useHistory();
  const handleClick = (path: PathEntry) => {
    history.push(formPathFromArray([...root, path]));
  };

  return (
    <NavigationTabs
      entries={tabs || []}
      disabledTabKeys={[]}
      onClick={handleClick}
    />
  );
};

export default Tabs;
