export type ActivatedRules = { [key: string]: boolean } & {
  source: boolean;
  destination: boolean;
};

export type SubnetFirewallRule = {
  subnet: string;
  rule_actions: ActivatedRules;
};

export enum FirewallRuleDirection {
  DESTINATION = "destination",
  SOURCE = "source",
}
