import { CardElement } from "../../../../components/common/Card/cardTypes";
import LocationIcon from "../../../../components/icons/LocationIcon";
import DestinationIcon from "../../../../components/icons/topologyButtons/DestinationIcon";
import SegmentsIcon from "../../../../components/icons/topologyButtons/SegmentsIcon";
import SourceIcon from "../../../../components/icons/topologyButtons/SourceIcon";
import { PURPLE } from "../../../../helpers/common/colorAliases";

export const LOCATIONS_BTN = "Locations";
export const SEGMENTS_BTN = "Unique Group and Segments";
export const SOURCES_BTN = "Users and Source IP";
export const DESTINATIONS_BTN = "Applications";

export const APP_BTN_MAP: Array<CardElement> = [
  {
    icon: <LocationIcon color={PURPLE} />,
    title: LOCATIONS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SegmentsIcon />,
    title: SEGMENTS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SourceIcon />,
    title: SOURCES_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <DestinationIcon />,
    title: DESTINATIONS_BTN,
    disabled: false,
    data: undefined,
  },
];

export const APP_BUTTONS_DATA = {
  [LOCATIONS_BTN]: [{ title: "Locations", number: 0 }],
  [SEGMENTS_BTN]: [
    { title: "Segments", number: 0 },
    { title: "Unique Groups", number: 0 },
  ],
  [SOURCES_BTN]: [
    { title: "Source IP", number: 0 },
    { title: "Users", number: 0 },
  ],
  [DESTINATIONS_BTN]: [
    { title: "Applications", number: 0 },
    { title: "Blocked Applications", number: 0 },
  ],
};
