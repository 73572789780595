import {
  CreateVirtualInterfaceType,
  EditVirtualInterfaceType,
} from "./../api/apiTypes";
import Validator from "./Validator";

export default function validateVirtInterface(
  data: CreateVirtualInterfaceType & { mtu: any },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.name, "name");

  validator.checkEmpty(data.mtu, "mtu");
  validator.checkNumber(data.mtu, [64, 20000], "mtu");

  data.ipv4_addresses &&
    data.ipv4_addresses.map((el) => {
      el && validator.checkIPWithPrefix(el, "ipv4_addresses");
    });

  data.ipv6_addresses &&
    data.ipv6_addresses.map((el) => {
      el && validator.checkIPWithPrefixIPv6(el, "ipv6_addresses");
    });

  return validator;
}

export function validateVirtInterfaceIPs(
  data: EditVirtualInterfaceType,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  data.add_ip_addresses &&
    data.add_ip_addresses.map((el) => {
      el && validator.checkIPWithPrefixAll(el, "add_ip_addresses");
    });

  return validator;
}
