import React, { FC } from "react";
import { useOnboardingContext } from "../OnboardingContext";
import { STEPS_TITLES_MAP } from "../utils/onboardingConsts";
import styles from "./StepCounter.module.scss";

const StepCounter: FC = () => {
  const { stepNumber } = useOnboardingContext();
  return (
    <div className={styles.wrapper}>
      <div className={styles.step}>Step {stepNumber + 1}:</div>
      <div className={styles.title}>{STEPS_TITLES_MAP[stepNumber]}</div>
    </div>
  );
};

export default StepCounter;
