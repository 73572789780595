import { ConfigField } from "./types";

export const CONFIG_FIELDS_PHASE1: Array<ConfigField> = [
  {
    name: "Local IP",
    key: "remote_ip",
  },
  {
    name: "Remote IP",
    key: "gateway_ip",
  },
  {
    name: "Type",
    key: "ike_version",
  },
  {
    name: "Auth",
    value: "Preshared Key",
  },
  {
    name: "PSK",
    key: "psk",
  },
  {
    name: "Local ID-Type",
    key: "remote_id_type",
  },
  {
    name: "Local ID",
    key: "remote_id_data",
  },
  {
    name: "Remote ID-Type",
    key: "local_id_type",
  },
  {
    name: "Remote ID",
    key: "local_id_data",
  },
  {
    name: "Identity/PRF",
    key: "auth",
  },
  {
    name: "DH",
    key: "dh_groups",
  },
  {
    name: "Algorithm",
    key: "encryption",
  },
  {
    name: "Key-Size",
    key: "encryption",
  },
  {
    name: "Key-Lifetime",
    value: "86400",
  },
  {
    name: "Mode",
    key: "passive",
  },
];

export const CONFIG_FIELDS_PHASE2: Array<ConfigField> = [
  {
    name: "Mode",
    value: "Tunnel",
  },
  {
    name: "Transport Mode",
    key: "udp_encap",
  },
  {
    name: "NAT-Traversal",
    key: "nat_traversal",
  },
  {
    name: "Local Network",
    key: "remote_networks",
  },
  {
    name: "Remote Network",
    key: "local_networks",
  },
  {
    name: "Algorithm",
    key: "encryption",
  },
  {
    name: "Key lifetime",
    value: "3600",
  },
  {
    name: "DH",
    key: "dh_groups",
  },
  {
    name: "Detection (DPD)",
    value: "on",
  },
  {
    name: "DPD lifetime",
    value: "30",
  },
];

export const DH_TABLE: Record<
  number,
  { type: string; key: number; pfSense: string | number }
> = {
  0: {
    type: "none",
    key: 0,
    pfSense: "not supported",
  },
  18: {
    type: "modp",
    key: 8192,
    pfSense: 18,
  },
};
