import { useCallback, useEffect, useState } from "react";
import { VirtualInterfaceApi } from "../../../pages/VirtualInterfacePage/types";
import { Res } from "../Api";
import { VRFType } from "../apiTypes";
import { configApi } from "../ConfigApi";
import { tenantApi } from "../TenantApi";
import { ERROR, OK, PENDING } from "../../common/RequestStateHelpers";

import { RequestState } from "../../types";

type Type = [Array<VirtualInterfaceApi> | null, RequestState];

export const useSystemVIList = (system?: string): Type => {
  const [requestStatus, setRequestStatus] = useState<RequestState>(PENDING);
  const [list, setList] = useState<Array<VirtualInterfaceApi> | null>(null);

  const fetchList = useCallback(async () => {
    if (!system) return;
    const resVRF = await configApi.getVRFList(system);
    const vrf = getVRF(resVRF);
    if (resVRF.ok && resVRF.result && vrf) {
      const { ok, result } = await configApi.getVirtualInterfacesApiList(
        system,
        vrf
      );
      const isError = !ok || !result;
      setList(isError ? null : result);
      setRequestStatus(isError ? ERROR : OK);
    }
    setRequestStatus(ERROR);
  }, [system]);

  useEffect(() => {
    fetchList();
  }, [system]);

  return [list, requestStatus];
};

const getVRF = (res: Res<Array<VRFType>>) => {
  return res.result?.find((el) => el.vrf_type === "internet")?.name;
};
