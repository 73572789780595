import React from "react";
import { classNames } from "../../../helpers/common/classNames";
import BlankButton from "../buttons/BlankButton";

import styles from "./style.module.scss";

export type SelectorTabType = {
  name: string;
  id: string | number;
};

type Props<T extends SelectorTabType> = {
  activeTabId: string | number;
  onTabSelect: (value: T) => void;
  tabs: Array<T>;
  isLongTab?: boolean;
  className?: string;
  toUpperCase?: boolean;
};

const TabSelector = <T extends SelectorTabType>({
  activeTabId,
  onTabSelect,
  tabs,
  isLongTab,
  className,
  toUpperCase,
}: Props<T>): JSX.Element => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {tabs.map((tab) => {
        return (
          <BlankButton
            id={String(tab.id)}
            key={tab.id}
            className={classNames(
              styles.tab,
              tab.id === activeTabId && styles.active,
              isLongTab && styles.longTab
            )}
            onClick={() => onTabSelect(tab)}
          >
            {isLongTab
              ? tab.name
              : toUpperCase
              ? tab.name.toUpperCase()
              : tab.name}
          </BlankButton>
        );
      })}
    </div>
  );
};

export default TabSelector;
