import React, { FC, ReactElement, useCallback } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./style.module.scss";

export type MatrixCellObject = {
  id?: number;
  value?: string;
};

export type MatrixCell = {
  data: MatrixCellObject;
  render?: (data: MatrixCellObject, options?: any) => ReactElement;
};

type Props = {
  dataSource: Array<Array<MatrixCell>>;
  grid?: string;
  onClick?: (data: MatrixCellObject) => void;
};

export const Matrix: FC<Props> = ({
  dataSource,
  grid = "repeat(4, 1fr)",
  onClick,
}) => {
  const renderCell = (x: number, y: number, row: MatrixCell) => {
    const value = row.render ? row.render(row.data) : row.data.value;

    return (
      <div
        className={classNames(styles.matrixCell)}
        onClick={() => onClick && onClick(row.data)}
      >
        {value}
      </div>
    );
  };

  const renderMatrix = useCallback(() => {
    return dataSource.map((boards, y) => {
      return (
        <div className={styles.matrixRow} key={y}>
          {boards.map((row, x) => renderCell(x, y, row))}
        </div>
      );
    });
  }, [dataSource]);

  return (
    <div className={styles.matrix} style={{ gridTemplateColumns: grid }}>
      {renderMatrix()}
    </div>
  );
};
