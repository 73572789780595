import { FC, useEffect, useMemo } from "react";
import ConfirmDeleteDialog from "../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import useDeleteDialog from "../../../helpers/hooks/useDeleteDialog";
import { Layer3InterfaceNew } from "../../Layer3Interface/types";
import { useSDWANProvidersContext } from "../SDWANProvidersContext";
import styles from "./ProviderDetails.module.scss";
import ProviderCredentials from "./ProviderCredentials";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import ProviderDiscovery from "./ProviderDiscovery";
import TableWrapper from "../../../components/common/table/newTable/layout/TableWrapper";
import { OK_STATUS } from "../../../components/const/api";
import Table from "../../../components/common/table/newTable/Table";
import TableHeader from "../../../components/common/table/newTable/rows/TableHeader";
import { ActionFuncs } from "../../../components/common/table/newTable/RowControls";
import { providerTableHeader } from "../common/providerTableHeader";

type Props = { type: string; connectedGW?: Array<Layer3InterfaceNew> };

const ProviderDetails: FC<Props> = ({ type, connectedGW }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    requestToUpdate,
    tokens,
    getAccessKeys,
    useTenant,
  } = useSDWANProvidersContext();

  useEffect(() => {
    if (selectedTenant) {
      getAccessKeys(selectedTenant, type);
      useTenant(selectedTenant);
    }
  }, [selectedTenant]);

  const { remove, removeStatus } = useSDWANProvidersContext();
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<Layer3InterfaceNew>(remove, removeStatus);

  const rowActions: ActionFuncs<Layer3InterfaceNew> = useMemo(
    () => ({
      onEdit: () => undefined,
      onDelete: (rowOriginal) => tryDelete(rowOriginal, selectedTenant),
    }),
    [tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Gateway"}
        name={selectedToDelete?.name}
      />
      <div className={styles.wrapper}>
        {connectedGW && connectedGW?.length > 0 && (
          <TableWrapper
            dataStatus={OK_STATUS}
            tablePlaceholder={["Gateways", "Gateway"]}
            isEmpty={false}
          >
            <Table
              columns={providerTableHeader(rowActions, type)}
              header={TableHeader}
              data={connectedGW}
              gridColumnTemplate={`30px 1fr 1fr 1.5fr 1fr`}
            />
          </TableWrapper>
        )}
        {(!tokens?.[type] || requestToUpdate === type) && (
          <ProviderCredentials type={type} />
        )}
        {tokens?.[type] && <ProviderDiscovery type={type} />}
      </div>
    </>
  );
};
export default ProviderDetails;
