import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function ConfigSaveIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1019_2349)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7349 8.77248L22.4028 9.4241C22.6968 9.49059 22.9106 9.75656 22.9106 10.0624V11.2376C22.0265 10.9543 21.0838 10.8013 20.1051 10.8013C19.0362 10.8013 18.0101 10.9838 17.0566 11.3192C17.0079 8.34501 14.5702 5.94889 11.5702 5.94889C8.5397 5.94889 6.08302 8.39391 6.08302 11.41C6.08302 14.396 8.49099 16.8223 11.4798 16.8704C11.143 17.819 10.9598 18.8398 10.9598 19.9031C10.9598 20.8774 11.1136 21.8159 11.3983 22.6959H10.1278C9.82048 22.6959 9.55324 22.4831 9.47307 22.1905L8.8317 19.6239C8.36404 19.4777 7.90974 19.2915 7.4688 19.0654L5.19729 20.4218C4.93006 20.5814 4.59601 20.5415 4.38222 20.3287L2.48485 18.4404C2.27106 18.2276 2.23097 17.8952 2.39131 17.6292L3.75422 15.3685C3.52707 14.9296 3.34 14.4775 3.19302 14.012L0.614194 13.3604C0.320234 13.2939 0.106445 13.028 0.106445 12.7221V10.0624C0.106445 9.75656 0.320234 9.49059 0.614194 9.4241L3.19302 8.77248C3.34 8.30704 3.52707 7.85489 3.75422 7.41605L2.39131 5.15533C2.23097 4.88936 2.27106 4.5569 2.48485 4.34413L4.38222 2.45576C4.59601 2.24299 4.93006 2.20309 5.19729 2.36267L7.4688 3.71911C7.90974 3.49303 8.36404 3.30686 8.8317 3.16058L9.47307 0.505337C9.55324 0.212774 9.82048 0 10.1278 0H12.8002C13.1075 0 13.3747 0.212774 13.4549 0.505337L14.0963 3.16058C14.5639 3.30686 15.0182 3.49303 15.4592 3.71911L17.7307 2.36267C17.9979 2.20309 18.3319 2.24299 18.5457 2.45576L20.4431 4.34413C20.6569 4.5569 20.697 4.88936 20.5366 5.15533L19.1737 7.41605C19.4009 7.85489 19.588 8.30704 19.7349 8.77248ZM11.5712 13.8375C12.9181 13.8375 14.01 12.7508 14.01 11.4103C14.01 10.0698 12.9181 8.98315 11.5712 8.98315C10.2244 8.98315 9.1325 10.0698 9.1325 11.4103C9.1325 12.7508 10.2244 13.8375 11.5712 13.8375Z"
          fill={color || FONT}
        />
        <g clipPath="url(#clip1_1019_2349)">
          <path
            d="M17.3545 14.9617C17.3545 14.299 17.8918 13.7617 18.5545 13.7617V13.7617C19.2172 13.7617 19.7545 14.299 19.7545 14.9617L19.7545 19.1617C19.7545 19.8245 19.2172 20.3617 18.5545 20.3617V20.3617C17.8918 20.3617 17.3545 19.8245 17.3545 19.1617L17.3545 14.9617Z"
            fill={color || FONT}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.033 17.7951C14.4573 17.286 15.214 17.2172 15.7231 17.6414L18.5549 20.0013L21.3867 17.6414C21.8958 17.2172 22.6525 17.286 23.0768 17.7951C23.5011 18.3042 23.4323 19.0609 22.9231 19.4852L18.5549 23.1254L14.1867 19.4852C13.6776 19.0609 13.6088 18.3042 14.033 17.7951Z"
            fill={color || FONT}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1019_2349">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_1019_2349">
          <rect
            width="11.7551"
            height="11.7553"
            fill="white"
            transform="translate(24.7549 12.2422) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ConfigSaveIcon;
