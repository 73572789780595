import { PortVlanType } from "../../../helpers/api/apiTypes";
import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import { IVlanContext, withVlanContextProps } from "../VLANContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { PortVlan } from "../types";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<PortVlanType>;

type IState = State & RequestStatuses;
type IFunc = DialogFunc<PortVlanType>;

const [VLANDialogContext, useVLANDialogContext] = createContextUtils<
  IState,
  IFunc
>();
export { useVLANDialogContext };

class VLANDialogContextContainer extends AbstractDialogContextContainer<
  PortVlan,
  IState,
  RequestStatuses,
  IFunc,
  IVlanContext & IOneSystemContext
> {
  Context = VLANDialogContext;

  _updateSystems = (ok: boolean) => {
    if (ok) {
      this.props.fetchList();
    }
  };
  add = async (vlan: Partial<PortVlan>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._addWrap(() =>
      configApi.addPortVLAN(system.name, vlan)
    );
    this._updateSystems(ok);

    return ok;
  };
  // todo not implemented
  edit = async (vlan: Partial<PortVlan>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());
    this._updateSystems(ok);
    return ok;
  };

  funcs = {
    edit: this.edit,
    add: this.add,
  };
}

export default withVlanContextProps(
  withOneSystemContextProps(VLANDialogContextContainer)
);
