import React, { useEffect, useMemo, useState } from "react";
import styles from "./InfoMessages.module.scss";
import {
  InfoEvent,
  InfoMessage,
  getMessages,
  requestMiddleware,
} from "../../helpers/api/RequestMiddleware";
import ErrorIcon from "../icons/ErrorIcon";
import { CRITICAL, GREY_DISABLED } from "../../helpers/common/colorAliases";
import OkCircleIcon from "../icons/OkCircleIcon";
import CloseIcon from "../icons/CloseIcon";

const InfoMessages: React.FC = () => {
  const [messages, setMessages] = useState<Array<InfoMessage>>(getMessages());
  const [succes, failed]: Array<Array<InfoMessage>> = useMemo(() => {
    const succes = messages.filter((ms) => ms.status);
    const failed = messages.filter((ms) => ms.status === false);
    return [succes, failed];
  }, [messages]);

  useEffect(() => {
    const handleChange = () => {
      setTimeout(() => {
        setMessages(getMessages());
      }, 500);
    };
    window.addEventListener(InfoEvent, handleChange);
  }, []);

  return messages.length ? (
    <div className={styles.messages}>
      {succes.length > 0 && (
        <div className={styles.messagesBlock}>
          {succes.map((msg) => {
            return (
              <div className={styles.message}>
                <div className={styles.detail}>
                  <OkCircleIcon />
                  <div className={styles.info}>Successfully:</div>
                  <div className={styles.name}>{msg.message}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {failed.length > 0 && (
        <div className={styles.messagesBlockError}>
          {failed.map((msg) => {
            return (
              <div className={styles.message}>
                <div className={styles.detail}>
                  <ErrorIcon color={CRITICAL} />
                  <div className={styles.error}>Server Error:</div>
                  <div className={styles.name}>{msg.message}</div>
                  {msg.e && <div className={styles.desc}>- {msg.e}</div>}
                </div>
                <CloseIcon
                  height={12}
                  width={12}
                  color={GREY_DISABLED}
                  className={styles.close}
                  onClick={() => requestMiddleware.removeMessage(msg.createdAt)}
                />
              </div>
            );
          })}
          <div
            onClick={requestMiddleware.removeAll}
            className={styles.clearAll}
          >
            Clear All
          </div>
        </div>
      )}
    </div>
  ) : null;
};

export default InfoMessages;
