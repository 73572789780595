import React, { FC, useEffect, useMemo, useState } from "react";
import { vlanTableHeader } from "../../components/common/table/TableHeaders/Systems/vlanTableHeader";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import VLANDialog from "./itemDialog/VLANDialog";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { withContexts } from "../../helpers/hocs/withContexts";
import VLANContextContainer, { useVLANContext } from "./VLANContext";
import { PortVlan } from "./types";

import styles from "./VLANPageContent.module.scss";

const VLANPageContent: FC = () => {
  const {
    list,
    fetchList,
    listStatus,
    remove,
    removeStatus,
    groupedList,
  } = useVLANContext();

  const [, setViewItem] = useState<PortVlan | undefined>();
  const [showAdd, setShowAdd] = useState(false);
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<PortVlan>(remove, removeStatus, () => {
    setViewItem(undefined);
  });

  useEffect(() => {
    fetchList();
  }, []);

  const rowActions: ActionFuncs<PortVlan> = useMemo(
    () => ({
      onDelete: tryDelete,
    }),
    [tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"VLAN"}
        name={selectedToDelete?.name}
      />
      {showAdd && <VLANDialog onClose={() => setShowAdd(false)} type={"add"} />}
      <TableWrapper
        titleProps={{
          title: `${(list || []).length} Interfaces`,
          children: <AddButton onClick={() => setShowAdd(true)} />,
        }}
        dataStatus={listStatus}
        tablePlaceholder={["Interfaces", "Interface"]}
        isEmpty={list && list.length === 0}
        className={styles.tableWrapper}
      >
        <div className={styles.tableContent}>
          {groupedList &&
            Object.keys(groupedList).map((physicalPort, i) => {
              return (
                <div className={styles.vlanRow}>
                  <Table
                    extraTitle={physicalPort}
                    columns={vlanTableHeader(physicalPort)}
                    header={i === 0 ? TableHeader : undefined}
                    data={groupedList[physicalPort] || []}
                    gridColumnTemplate={`1fr 1fr 1fr 140px 1fr 12px 0`}
                    rowActions={rowActions}
                  />
                </div>
              );
            })}
        </div>
      </TableWrapper>
    </>
  );
};

export default withContexts(VLANPageContent, [VLANContextContainer]);
