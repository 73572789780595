import { calcCoordinates } from "../pages/WizardToolPage/LocationStep/mapUtils";
import { LocationsList, LocationsListApi } from "../pages/WizardToolPage/types";
import { systemApi } from "./api/SystemApi";

export const getFormattedLocations = async (
  arr: Array<LocationsListApi>
): Promise<Array<LocationsList>> => {
  const copy: Array<LocationsList> = [];
  const { ok, result } = await systemApi.getSystemsList();
  if (ok && result) {
    arr.map(({ name, latitude, longitude }) => {
      // due to api: lat -> long, long -> lat
      const coords =
        latitude && longitude && calcCoordinates(latitude, longitude);

      const system = result?.find((sys) => sys.location === name);

      if (system && coords)
        copy.push({
          name,
          system: system.name,
          x: coords[0],
          y: coords[1],
          status: system.status,
        });
    });
  }

  return copy;
};
