export type WireGuardParams = {
  tunnel_ip: string; // Address
  tunnel_private_key: string; // PrivateKey
  tunnel_DNS: string; // DNS
  public_key: string; // PublicKey
  psk: string; // PresharedKey
  remote_ip: string;
  remote_port: number; // remote_ip+remote_port -> Endpoint
  allowed_ips: string; // AllowedIPs
};

export const generateWireGuardConfig = (params: WireGuardParams): string => {
  const {
    tunnel_ip,
    tunnel_private_key,
    tunnel_DNS,
    public_key,
    psk,
    remote_ip,
    remote_port,
    allowed_ips,
  } = params;

  return `
  [Interface]
  Address=${tunnel_ip}
  PrivateKey=${tunnel_private_key}
  DNS=${tunnel_DNS}
  
  [Peer]
  PublicKey=${public_key}
  PresharedKey=${psk}
  Endpoint=${remote_ip}:${remote_port}
  PersistentKeepalive=25
  AllowedIPs=${allowed_ips}`;
};
