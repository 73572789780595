import React, { ChangeEvent, FC, useRef, useState } from "react";
import SearchIcon from "../icons/SearchIcon";
import styles from "./SearchInput.module.scss";
import { classNames } from "../../helpers/common/classNames";
import isEmpty from "../../helpers/common/isEmpty";

type Props = {
  value?: string;
  onChange: (value: string, e: ChangeEvent<HTMLInputElement>) => void;
};
const SearchInput: FC<Props> = ({ value, onChange }) => {
  const [visible, setVisible] = useState(false);
  const inputClassName = classNames(styles.input, !visible && styles.collapsed);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <div
      onMouseEnter={() => {
        setVisible(true);
        if (!visible) inputRef.current?.focus();
      }}
      onMouseLeave={() => {
        if (isEmpty(value)) {
          setVisible(false);
          inputRef.current?.blur();
        }
      }}
      className={classNames(styles.wrapper, !visible && styles.collapsed)}
    >
      <SearchIcon />
      <input
        ref={inputRef}
        onChange={(e) => onChange(e.target.value, e)}
        value={value}
        className={inputClassName}
      />
    </div>
  );
};

export default SearchInput;
