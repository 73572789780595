import { ConnectionPortDetails } from "../types";

const UNUSED_FIELDS = [
  "network_segments",
  "virtual_interface_name",
  "endpoint_name",
  "Tagged",
];

export const getAllPortsFields = (
  arr: Array<ConnectionPortDetails>
): Array<string> => {
  const result: Array<string> = [];
  arr.forEach((port) => {
    const configFields = Object.keys(port.config);
    configFields.forEach((field) => {
      if (!result.includes(field) && !UNUSED_FIELDS.includes(field)) {
        result.push(field);
      }
    });
  });
  return result;
};
