import React, { ReactElement } from "react";
import { GREY_DISABLED } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function BooleanFalseIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.8994 12H2.10042"
        stroke={color || GREY_DISABLED}
        strokeWidth="2.4"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default BooleanFalseIcon;
