import { FC } from "react";
import Section from "../../../../../components/common/Section";
import Input from "../../../../../components/common/formComponents/Input";
import { Checkbox } from "../../../../../components/common/formComponents/Checkbox";
import {
  ChangeField,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";

type Props = {
  isEdit?: boolean;
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
};

const AdvancedSection: FC<Props> = ({
  className,
  isEdit,
  fields,
  onChange,
}) => {
  return (
    <Section title={"Advanced"} className={className} columnsCount={2} btmAlign>
      <Input
        label="Multihop TTL"
        type={"number"}
        value={fields.multihopTTL}
        onChange={(e) => {
          onChange("multihopTTL", e.target.value);
        }}
        isNotEditable={isEdit}
        medium
        error={""}
      />
      <Checkbox
        isChecked={fields.passive}
        onChange={() => {
          onChange("passive", !fields.passive);
        }}
        label={"Passive Mode"}
      />
      <Input
        label="Keepalive timer (sec)"
        type={"number"}
        value={fields.keepalive}
        onChange={(e) => {
          onChange("keepalive", e.target.value);
        }}
        isNotEditable={isEdit}
        medium
        error={""}
      />
      <Input
        label="Hold timer (sec)"
        type={"number"}
        value={fields.hold}
        onChange={(e) => {
          onChange("hold", e.target.value);
        }}
        isNotEditable={isEdit}
        medium
        error={""}
      />
      <Input
        label="Open Delay (sec)"
        type={"number"}
        value={fields.open_delay}
        onChange={(e) => {
          onChange("open_delay", e.target.value);
        }}
        isNotEditable={isEdit}
        medium
        error={""}
      />
    </Section>
  );
};
export default AdvancedSection;
