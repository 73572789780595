import React, { FC, useEffect } from "react";
import { useTenantVirtualInterfaceContext } from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import { ADMIN_STATE } from "../../../helpers/common/constantsAlias";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import DropdownBasic from "../../common/Dropdown/DropdownBasic";
import Input from "../../common/formComponents/Input";

type Props = {
  systemsList?: Array<string>;
  system: string;
  changeSystem: (value: string) => void;
  isEdit: boolean;
  fieldProps: any;
  fields: { [key: string]: any };
  onChange: (fields: string, value: string | number) => void;
};
const CommonFields: FC<Props> = ({
  systemsList,
  system,
  changeSystem,
  isEdit,
  fieldProps,
  fields,
  onChange,
}) => {
  const { list, fetchList } = useTenantVirtualInterfaceContext();

  useEffect(() => {
    fetchList();
  }, []);

  return (
    <>
      <Input {...fieldProps("alias")} label="Alias" isNotEditable={isEdit} />
      <DropdownBasic
        id={"systemBasic"}
        onChange={(val) => changeSystem(val.key)}
        selected={mapStringToItem(system)}
        label="System"
        itemsList={systemsList?.map(mapStringToItem)}
        isMedium
        isNotEditable={isEdit}
      />
      <DropdownBasic
        id={"adminStateBasic"}
        onChange={(val) => onChange("administrative_state", val.key)}
        selected={mapStringToItem(fields.administrative_state + "")}
        label="Admin State"
        itemsList={ADMIN_STATE.map(mapStringToItem)}
        isMedium
        isNotEditable={isEdit}
      />
      <DropdownBasic
        id={"virtualInterfaceBasic"}
        onChange={(val) => onChange("virtual_interface_name", val.value)}
        selected={mapStringToItem(fields.virtual_interface_name)}
        label="Virtual Interface"
        itemsList={list?.map((item) => ({
          key: item.id.toString(),
          value: item.name,
        }))}
        isMedium
        isNotEditable={isEdit}
      />
    </>
  );
};
export default CommonFields;
