import {
  ConnectionAdd,
  ConnectionEdit,
} from "./../../pages/ZayoPages/Connections/types";
import { NATRuleAdd } from "./../../pages/NATRulesPage/types";
import { fetchAndAppendVIIp } from "./../fetchAndAppendVIIp";
import { CloudFields } from "./../../pages/WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/formConsts";
import { VtepVniCreate } from "./../../pages/VNIPage/types";
import {
  PortInterface,
  PortInterfaceCreate,
  PortInterfaceDelete,
} from "./../../pages/PortInterfacePage/types";
import { EMPTY_RESULT } from "../common/constantsAlias";
import getManualSortedData from "../getManualSortedData";

import {
  ASType,
  BGPUnderlay,
  CreateVirtualInterfaceType,
  DeleteStaticRouteType,
  EditVirtualInterfaceType,
  FilterAndSort,
  FWRuleType,
  IdxResponse,
  MemberType,
  NatRulesList,
  NatRuleType,
  NeighborType,
  PaginateListResponse,
  PBMType,
  PBRType,
  PortVlanType,
  ServiceBGPNeighbor,
  ServiceBGPStatus,
  SortType,
  StaticRouteType,
  StatusTypeType,
  StatusVRFType,
  VirtualInterfaceType,
  VRFType,
  VRouter_ARP,
  VRouter_MAC,
} from "./apiTypes";

import Api, { Method, Res, ResultStatus } from "./Api";
import {
  FW_RULE,
  NAT_RULE,
  PORT_INTERFACE,
  PORT_VLAN,
  PORTS,
  STATIC_ROUTE,
  VIRTUAL_INTERFACE,
  VRF,
  VTEP,
  VTEP_VNI,
  BGP_SERVICE_AS,
  BGP_SERVICE_NEIGHBOR,
  VROUTER_SYSTEM,
  VROUTER_SERVICE,
  BGP_SYSTEM,
  SERVICE_INTERNET,
  SERVICE_AWS,
  CONFIG_REQUEST,
  SERVICE_ENDPOINT,
  SERVICE_CONNECTION,
  IPAM,
  CONFIG_CONNECTION,
  CONFIG_ENDPOINT,
  LOCATIONS,
  BGP_PFX_SYSTEM,
  SERVICE_BGP_PFX,
  BGP_SERVICE,
  FQDN,
  SERVICES_PREFERENCES,
  PBR_CRUD,
  PBM_CRUD,
  IPAM_CONF,
} from "./apiPaths";
import { PortVlan, PortVlanDelete } from "../../pages/VLANPage/types";
import {
  ApiVTEPType,
  CreateVTEPType,
  EditVTEPParams,
  VTEPType,
} from "../../pages/VTEPPage/types";
import { fetchAndAppendVRF } from "../fetchAndAppendVRF";
import { VtepVni, VtepVniDelete } from "../../pages/VNIPage/types";
import { fetchAndAppendVtep } from "../fetchAndAppendVtep";
import { flatMembersList } from "../flatMembersList";
import {
  VirtualInterface,
  VirtualInterfaceApi,
} from "../../pages/VirtualInterfacePage/types";
import {
  InternetIpsecAdd,
  InternetNatAdd,
  LocationsListApi,
} from "../../pages/WizardToolPage/types";
import {
  ConnectionApi,
  Endpoint,
} from "../../pages/ZayoPages/Connections/types";
import { ConfigurationPreview } from "../../pages/HistoryLogPage/domain";
import { PortType } from "../../pages/PortsPage/types";
import {
  GlobalRouteFilters,
  UserRouteFilters,
  UserRouteFiltersAdd,
} from "../../pages/RouteFiltersPage/types";
import { ALLOW_ALL_FILTER } from "../../pages/RouteFiltersPage/consts";
import { BGPNeighborAdd } from "../../pages/ServiceBGP/types";
import {
  CreateIpam,
  PublicIpManagementRecord,
} from "../../pages/IPAMPage/types";
import {
  EditFQDNService,
  FQDNCategory,
  FQDNCategoryAdd,
  FQDNCategoryEdit,
} from "../../pages/WebFilteringPage/types";
import { WIRE_GUARD, WIRE_GUARD_STATUS } from "./apiPaths";

export class ConfigApi extends Api {
  private _configPath = "config";
  constructor() {
    super();
  }

  private configPath(path: string): string {
    return this.brokerApi(`${this._configPath}/${path}`);
  }

  // PORTS
  async getPortsList(
    system: string,
    sortBy?: SortType
  ): Promise<Res<Array<PortType>>> {
    const res = await this.fetchBroker<PaginateListResponse<PortType>>(
      PORTS.LIST(system)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getPort(system: string, id: string): Promise<Res<PortType>> {
    return await this.fetchBroker<PortType>(PORTS.GET(system, id));
  }

  async editAlias(
    system: string,
    id: string,
    param: Partial<PortType>
  ): Promise<any> {
    return await this.fetchBroker<PortType>(PORTS.EDIT(system, id), {
      body: JSON.stringify(param),
    });
  }

  async createLAG(system: string, params: any): Promise<Res<any>> {
    const res = await this.fetchBroker<PortType>(PORTS.CREATE_LAG(system), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async assignPortToLag(
    system: string,
    port_name: string,
    lag_name: string
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<PortType>(
      PORTS.ADD_PORT(system, port_name),
      {
        body: JSON.stringify({ lag_name: lag_name }),
      }
    );
    return res;
  }

  async removePortFromLag(
    system: string,
    port_name: string
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<PortType>(
      PORTS.DEL_PORT(system, port_name)
    );
    return res;
  }

  // VTEP
  async getVTEPList(
    system_name: string,
    sortBy?: SortType
  ): Promise<Res<Array<VTEPType>>> {
    const res = await this.fetchBroker<PaginateListResponse<ApiVTEPType>>(
      VTEP.LIST(system_name)
    );
    const fullData = await fetchAndAppendVRF(
      system_name,
      res.result?.items || []
    );
    return { ...res, result: getManualSortedData(fullData, sortBy) };
  }

  async getVTEP(
    system_name: string,
    vtep_name: string
  ): Promise<Res<ApiVTEPType>> {
    return await this.fetchBroker<ApiVTEPType>(
      VTEP.GET(system_name, vtep_name)
    );
  }

  async addVTEP(
    system_name: string,
    params: CreateVTEPType
  ): Promise<Res<void>> {
    return await this.fetchBroker(VTEP.ADD(system_name), {
      body: JSON.stringify(params),
    });
  }

  async editVTEP(
    system_name: string,
    vtep_name: string,
    params: EditVTEPParams
  ): Promise<Res<void>> {
    return await this.fetchBroker(VTEP.EDIT(system_name, vtep_name), {
      body: JSON.stringify(params),
    });
  }

  async deleteVTEP(system_name: string, vtep_name: string): Promise<Res<any>> {
    return await this.fetchBroker(VTEP.DELETE(system_name, vtep_name));
  }

  async setVTEPDescription(
    system_name: string,
    vtep_name: string,
    params: { description: string }
  ): Promise<Res<any>> {
    return await this.fetchBroker(VTEP.SET_DESCR(system_name, vtep_name), {
      body: JSON.stringify(params),
    });
  }

  async setVTEPIPS(
    system_name: string,
    vtep_name: string,
    params: { remote_ips: Array<string> }
  ): Promise<Res<any>> {
    return await this.fetchBroker(VTEP.SET_IPS(system_name, vtep_name), {
      body: JSON.stringify(params),
    });
  }

  // L2

  // PORT_INTERFACE
  async getPortInterfaceList(
    system: string,
    sortBy?: SortType
  ): Promise<Res<Array<PortInterface>>> {
    const res = await this.fetchBroker<PaginateListResponse<PortInterface>>(
      PORT_INTERFACE.LIST(system)
    );

    return {
      ...res,
      result: getManualSortedData(res.result?.items || [], sortBy),
    };
  }

  async addPortInterface(
    system: string,
    params: PortInterfaceCreate
  ): Promise<Res<any>> {
    return await this.fetchBroker(PORT_INTERFACE.ADD(system), {
      body: JSON.stringify(params),
    });
  }

  async getPortInterface(
    system: string,
    alias: string
  ): Promise<Res<PortInterface>> {
    return await this.fetchBroker<PortInterface>(
      PORT_INTERFACE.GET(system, alias)
    );
  }

  async deletePortInterface(
    system: string,
    portInterface: PortInterfaceDelete
  ): Promise<any> {
    return await this.fetchBroker(PORT_INTERFACE.DELETE(system, ""), {
      body: JSON.stringify(portInterface),
    });
  }

  async editPortInterfaceThreshold(
    system: string,
    alias: string,
    params: { max_bandwidth: number }
  ): Promise<any> {
    return await this.fetchBroker(
      PORT_INTERFACE.EDIT_THRESHOLD(system, alias),
      {
        body: JSON.stringify(params),
      }
    );
  }

  // NOT implemented
  async editPortInterface(): Promise<any> {
    return await EMPTY_RESULT;
  }

  // VNI
  async getVNIList(
    system: string,
    sortBy?: SortType
  ): Promise<{
    result: Array<VtepVni>;
    ok: boolean;
    error?: string | undefined;
  }> {
    const res = await this.fetchBroker<PaginateListResponse<VtepVni>>(
      VTEP_VNI.LIST(system)
    );
    const fullData = await fetchAndAppendVtep(system, res.result?.items || []);
    return { ...res, result: getManualSortedData(fullData, sortBy) };
  }

  async addVNI(system: string, params: VtepVniCreate): Promise<Res<void>> {
    return await this.fetchBroker(VTEP_VNI.ADD(system), {
      body: JSON.stringify(params),
    });
  }

  async getVNI(system: string, vniId: number): Promise<Res<VtepVni>> {
    return await this.fetchBroker<VtepVni>(
      VTEP_VNI.GET(system, vniId.toString())
    );
  }

  async deleteVNI(system: string, params: VtepVniDelete): Promise<Res<void>> {
    const reqBody = { vtep_id: params.vtep_id, vni_id: params.vni_id };
    return await this.fetchBroker(VTEP_VNI.DELETE(system, ""), {
      body: JSON.stringify(reqBody),
    });
  }

  async editVNIThreshold(
    system: string,
    alias: string,
    params: { max_bandwidth: number }
  ): Promise<any> {
    return await this.fetchBroker(VTEP_VNI.EDIT_THRESHOLD(system, alias), {
      body: JSON.stringify(params),
    });
  }

  // NOT implemented
  async editVNI(): Promise<Res<any>> {
    return await EMPTY_RESULT;
  }

  // PORT_VLAN
  async getPortVLANList(
    system: string,
    sortBy?: SortType
  ): Promise<Res<Array<PortVlan>>> {
    const res = await this.fetchBroker<PaginateListResponse<PortVlan>>(
      PORT_VLAN.LIST(system)
    );
    return {
      ...res,
      result: getManualSortedData(res.result?.items || [], sortBy),
    };
  }

  async addPortVLAN(
    system: string,
    params: Partial<PortVlanType>
  ): Promise<Res<void>> {
    return await this.fetchBroker(PORT_VLAN.ADD(system), {
      body: JSON.stringify(params),
    });
  }

  async getPortVLAN(system: string, alias: string): Promise<Res<PortVlanType>> {
    return await this.fetchBroker<PortVlanType>(PORT_VLAN.GET(system, alias));
  }

  async deletePortVLAN(system: string, data: PortVlanDelete): Promise<any> {
    return await this.fetchBroker(PORT_VLAN.DELETE_PARAMS(system), {
      body: JSON.stringify(data),
    });
  }

  async editPortVLANThreshold(
    system: string,
    alias: string,
    params: { max_bandwidth: number }
  ): Promise<any> {
    return await this.fetchBroker(PORT_VLAN.EDIT_THRESHOLD(system, alias), {
      body: JSON.stringify(params),
    });
  }

  // NOT implemented yet
  async editPortVLAN(): Promise<any> {
    return await EMPTY_RESULT;
  }

  // VRF
  async getVRFList(
    system: string,
    sortBy?: SortType
  ): Promise<Res<Array<VRFType>>> {
    const res = await this.fetchBroker<PaginateListResponse<VRFType>>(
      VRF.LIST(system)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async addVRF(system: string, params: Partial<VRFType>): Promise<Res<any>> {
    return await this.fetchBroker(VRF.ADD(system), {
      body: JSON.stringify({
        name: params.name,
        description: params.description,
        vrf_type: params.vrf_type,
      }),
    });
  }

  async getVRF(system: string, vrf: string): Promise<Res<VRFType>> {
    return await this.fetchBroker<VRFType>(VRF.GET(system, vrf));
  }

  async editVRF(
    system: string,
    vrf: string,
    params: Partial<VRFType>
  ): Promise<any> {
    return await this.fetchBroker(VRF.EDIT(system, vrf), {
      body: JSON.stringify(params),
    });
  }

  async deleteVRF(system: string, vrf: string): Promise<any> {
    return await this.fetchBroker(VRF.DELETE(system, vrf));
  }

  // Virtual Interfaces
  async getVirtualInterfacesList(
    system: string,
    vrf: string,
    sortBy?: SortType
  ): Promise<Res<Array<VirtualInterface>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<VirtualInterfaceApi>
    >(VIRTUAL_INTERFACE.LIST(system, vrf));
    const data = flatMembersList(res.result?.items || []);
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getVirtualInterfacesApiList(
    system: string,
    vrf: string,
    sortBy?: SortType
  ): Promise<Res<Array<VirtualInterfaceApi>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<VirtualInterfaceApi>
    >(VIRTUAL_INTERFACE.LIST(system, vrf));
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getAllVirtualInterfacesByTenant(
    tenant: string,
    sortBy?: SortType
  ): Promise<Res<Array<VirtualInterface>>> {
    const res = await this.fetchBroker<PaginateListResponse<VirtualInterface>>(
      VIRTUAL_INTERFACE.ALL_TYPES(tenant)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getVirtualInterface(
    system: string,
    vi_name: string | number,
    vrf: string
  ): Promise<Res<VirtualInterfaceType>> {
    return await this.fetchBroker<VirtualInterfaceType>(
      VIRTUAL_INTERFACE.GET(system, vi_name as string, vrf)
    );
  }

  async addVirtualInterface(
    system: string,
    vrf: string,
    params: CreateVirtualInterfaceType
  ): Promise<Res<any>> {
    return await this.fetchBroker(VIRTUAL_INTERFACE.ADD(system, vrf), {
      body: JSON.stringify(params),
    });
  }

  async editVirtualInterface(
    system: string,
    vi_name: string,
    vrf: string,
    params: EditVirtualInterfaceType
  ): Promise<any> {
    return await this.fetchBroker(
      VIRTUAL_INTERFACE.EDIT(system, vi_name, vrf),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async deleteVirtualInterface(
    system: string,
    vi_name: string,
    vrf: string
  ): Promise<any> {
    return await this.fetchBroker(
      VIRTUAL_INTERFACE.DELETE(system, vi_name, vrf)
    );
  }

  async getMembers(
    system: string,
    id: string,
    vrf: string
  ): Promise<Res<Array<MemberType>>> {
    return await this.fetchBroker(
      VIRTUAL_INTERFACE.LIST_MEMBERS(system, id, vrf)
    );
  }

  // Static Routes
  async getStaticRoutesList(
    system: string,
    vrf: string,
    sortBy: SortType
  ): Promise<Res<Array<StaticRouteType>>> {
    const res = await this.fetchBroker<PaginateListResponse<StaticRouteType>>(
      STATIC_ROUTE.LIST(system, vrf)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async addStaticRoute(
    system: string,
    vrf: string,
    params: StaticRouteType
  ): Promise<Res<string>> {
    return await this.fetchBroker(STATIC_ROUTE.ADD(system, vrf), {
      body: JSON.stringify(params),
    });
  }

  async deleteStaticRoute(
    system: string,
    vrf: string,
    params: DeleteStaticRouteType
  ): Promise<any> {
    return await this.fetchBroker(STATIC_ROUTE.DELETE(system, vrf, ""), {
      body: JSON.stringify(params),
    });
  }

  // not implemented
  getStaticRoute(): Res<StaticRouteType> {
    return EMPTY_RESULT;
  }
  async editStaticRoute(): Promise<any> {
    return EMPTY_RESULT;
  }

  // FW
  async getFWRules(
    tenant: string,
    params?: FilterAndSort
  ): Promise<Res<Array<FWRuleType>>> {
    const res = await this.fetchBroker<PaginateListResponse<FWRuleType>>(
      FW_RULE.LIST(tenant)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, params?.sort) };
  }

  async addFWRule(
    tenant: string,
    params: Partial<FWRuleType>
  ): Promise<Res<any>> {
    return await this.fetchBroker(
      FW_RULE.ADD(tenant),
      {
        body: JSON.stringify(params),
      },
      undefined
    );
  }

  async getFWRule(tenant: string, rule_name: string): Promise<Res<FWRuleType>> {
    return await this.fetchBroker<FWRuleType>(FW_RULE.GET(tenant, rule_name));
  }

  async editFWRule(
    tenant: string,
    rule_name: string,
    params: Partial<FWRuleType>
  ): Promise<Res<FWRuleType>> {
    return await this.fetchBroker<FWRuleType>(FW_RULE.EDIT(tenant, rule_name), {
      body: JSON.stringify(params),
    });
  }

  async deleteFWRule(tenant: string, rule_name: string): Promise<any> {
    return await this.fetchBroker(FW_RULE.DELETE(tenant, rule_name));
  }

  // PBR
  async getPBRRules(
    tenant: string,
    params?: FilterAndSort
  ): Promise<Res<Array<PBRType>>> {
    const res = await this.fetchBroker<PaginateListResponse<PBRType>>(
      PBR_CRUD.LIST(tenant)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, params?.sort) };
  }

  async getPBRGlobalRules(
    params?: FilterAndSort
  ): Promise<Res<Array<PBRType>>> {
    const res = await this.fetchBroker<PaginateListResponse<PBRType>>(
      PBR_CRUD.GLOBAL_LIST()
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, params?.sort) };
  }

  async addPBR(tenant: string, params: Partial<PBRType>): Promise<Res<any>> {
    return await this.fetchBroker(
      PBR_CRUD.ADD(tenant),
      {
        body: JSON.stringify(params),
      },
      undefined
    );
  }

  async addPBRGlobal(params: Partial<PBRType>): Promise<Res<any>> {
    return await this.fetchBroker(
      PBR_CRUD.GLOBAL_ADD(),
      {
        body: JSON.stringify(params),
      },
      undefined
    );
  }

  async getPBR(tenant: string, rule_name: string): Promise<Res<PBRType>> {
    return await this.fetchBroker<PBRType>(PBR_CRUD.GET(tenant, rule_name));
  }

  async editPBR(
    tenant: string,
    rule_name: string,
    params: Partial<PBRType>,
    isGlobal?: boolean
  ): Promise<Res<PBRType>> {
    const query = isGlobal ? PBR_CRUD.GLOBAL_EDIT : PBR_CRUD.EDIT;
    return await this.fetchBroker<PBRType>(query(tenant, rule_name), {
      body: JSON.stringify(params),
    });
  }

  async deletePBR(
    tenant: string,
    rule_name: string,
    isGlobal?: boolean
  ): Promise<any> {
    const query = isGlobal ? PBR_CRUD.GLOBAL_DELETE : PBR_CRUD.DELETE;
    return await this.fetchBroker(query(tenant, rule_name));
  }

  // PBM
  async getPBMRules(
    tenant: string,
    params?: FilterAndSort
  ): Promise<Res<Array<PBMType>>> {
    const res = await this.fetchBroker<PaginateListResponse<PBMType>>(
      PBM_CRUD.LIST(tenant)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, params?.sort) };
  }

  async addPBM(tenant: string, params: Partial<PBMType>): Promise<Res<any>> {
    return await this.fetchBroker(
      PBM_CRUD.ADD(tenant),
      {
        body: JSON.stringify(params),
      },
      undefined
    );
  }

  async getPBM(tenant: string, rule_name: string): Promise<Res<PBMType>> {
    return await this.fetchBroker<PBMType>(PBM_CRUD.GET(tenant, rule_name));
  }

  async editPBM(
    tenant: string,
    rule_name: string,
    params: Partial<PBMType>
  ): Promise<Res<PBMType>> {
    return await this.fetchBroker<PBMType>(PBM_CRUD.EDIT(tenant, rule_name), {
      body: JSON.stringify(params),
    });
  }

  async deletePBM(tenant: string, rule_name: string): Promise<any> {
    return await this.fetchBroker(PBM_CRUD.DELETE(tenant, rule_name));
  }

  // BGP
  async getBGPUnderlay(system: string): Promise<Res<BGPUnderlay>> {
    return await this.fetchBroker<BGPUnderlay>(BGP_SYSTEM.GET_UNDERLAY(system));
  }

  async updateBGPUnderlay(
    system: string,
    params: Partial<BGPUnderlay>
  ): Promise<Res<BGPUnderlay>> {
    return await this.fetchBroker<BGPUnderlay>(
      BGP_SYSTEM.EDIT_UNDERLAY(system),
      {
        method: Method.PUT,
        body: JSON.stringify(params),
      }
    );
  }

  async getBGPStatus(
    system: string,
    node_name: string
  ): Promise<Res<StatusVRFType>> {
    return await this.fetchBroker<StatusVRFType>(
      BGP_SYSTEM.GET_STATUS(system),
      { query: { node_name } }
    );
  }

  async getBGPStatusByTenant(tenant: string): Promise<Res<StatusTypeType>> {
    return await this.fetchBroker<StatusTypeType>(
      BGP_SYSTEM.GET_STATUS_BY_TENANT(tenant)
    );
  }

  // NAT RULES
  async getNatRulesList(
    tenant: string,
    sortBy?: SortType
  ): Promise<{
    result: Array<NatRuleType>;
    ok: boolean;
    error?: string | undefined;
  }> {
    const res = await this.fetchBroker<NatRulesList>(NAT_RULE.LIST(tenant));
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getNatRulesListFormatted(
    tenant: string,
    sortBy?: SortType
  ): Promise<{
    result: Array<NatRuleType & { outside_ip?: Array<string> }>;
    ok: boolean;
    error?: string | undefined;
  }> {
    const res = await this.fetchBroker<NatRulesList>(NAT_RULE.LIST(tenant));
    const data = await fetchAndAppendVIIp(tenant, res.result?.items || []);
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getNatRule(
    tenant: string,
    rule_name: string
  ): Promise<Res<NatRuleType>> {
    return await this.fetchBroker<NatRuleType>(NAT_RULE.GET(tenant, rule_name));
  }

  async addNatRule(
    tenant: string,
    params: Partial<NATRuleAdd>
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(NAT_RULE.ADD(tenant), {
      body: JSON.stringify(params),
    });
  }

  async editNatRule(
    tenant: string,
    rule_name: string,
    params: { [key: string]: string }
  ): Promise<Res<string>> {
    return await this.fetchBroker(NAT_RULE.EDIT(tenant, rule_name), {
      body: JSON.stringify(params),
    });
  }

  async deleteNatRule(tenant: string, rule_name: string): Promise<Res<string>> {
    return await this.fetchBroker(NAT_RULE.DELETE(tenant, rule_name));
  }

  // BGP SERVICES AS
  async getServiceAS(tenant: string): Promise<Res<ASType>> {
    return await this.fetchBroker<ASType>(BGP_SERVICE_AS.GET(tenant));
  }

  async addServiceAS(
    tenant: string,
    params: Partial<ASType>
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(BGP_SERVICE_AS.ADD(tenant), {
      body: JSON.stringify(params),
    });
  }

  async deleteServiceAS(tenant: string): Promise<Res<string>> {
    return await this.fetchBroker(BGP_SERVICE_AS.DELETE(tenant, ""));
  }

  async editServiceAS(tenant: string, id: string): Promise<Res<string>> {
    return await this.fetchBroker(BGP_SERVICE_AS.EDIT(tenant, id));
  }

  async getServiceBGPStatus(tenant: string): Promise<Res<ServiceBGPStatus>> {
    return await this.fetchBroker<ServiceBGPStatus>(
      BGP_SERVICE.GET_STATUS(tenant)
    );
  }

  // BGP SERVICES NEIGHBORS

  async getServiceNeighborsList(
    tenant: string
  ): Promise<Res<PaginateListResponse<ServiceBGPNeighbor>>> {
    return await this.fetchBroker<PaginateListResponse<ServiceBGPNeighbor>>(
      BGP_SERVICE_NEIGHBOR.LIST(tenant)
    );
  }

  async addServiceNeighbor(
    tenant: string,
    params: Partial<BGPNeighborAdd>
  ): Promise<Res<BGPNeighborAdd>> {
    return await this.fetchBroker<BGPNeighborAdd>(
      BGP_SERVICE_NEIGHBOR.ADD(tenant),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async editServiceNeighbor(
    tenant: string,
    remoteRouterIp: string,
    params: any
  ): Promise<Res<NeighborType>> {
    return await this.fetchBroker<NeighborType>(
      BGP_SERVICE_NEIGHBOR.EDIT(tenant, remoteRouterIp),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async getServiceNeighbor(
    tenant: string,
    router_ip: string
  ): Promise<Res<PaginateListResponse<NeighborType>>> {
    return await this.fetchBroker<PaginateListResponse<NeighborType>>(
      BGP_SERVICE_NEIGHBOR.GET(tenant, router_ip)
    );
  }

  async deleteServiceNeighbor(
    tenant: string,
    router_ip: string
  ): Promise<Res<string>> {
    return await this.fetchBroker(
      BGP_SERVICE_NEIGHBOR.DELETE(tenant, router_ip),
      {
        body: JSON.stringify({
          remote_router_ip: router_ip,
        }),
      }
    );
  }

  async getARPList(
    system: string,
    vrf: string,
    sortBy?: SortType
  ): Promise<Res<Array<VRouter_ARP>>> {
    const res = await this.fetchBroker<PaginateListResponse<VRouter_ARP>>(
      VROUTER_SYSTEM.LIST_ARP(system, vrf)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }
  async getMACList(
    system: string,
    sortBy?: SortType
  ): Promise<Res<Array<VRouter_MAC>>> {
    const res = await this.fetchBroker<PaginateListResponse<VRouter_MAC>>(
      VROUTER_SYSTEM.LIST_MAC(system)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }
  async getRoutesList(
    system: string,
    vrf: string,
    sortBy?: SortType
  ): Promise<Res<Array<any>>> {
    const res = await this.fetchBroker<PaginateListResponse<any>>(
      VROUTER_SYSTEM.LIST_ROUTES(system, vrf)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getServiceARPList(
    tenant: string,
    offset: number,
    limit: number,
    sortBy?: SortType
  ): Promise<Res<Array<any>>> {
    const res = await this.fetchBroker<PaginateListResponse<any>>(
      VROUTER_SERVICE.LIST_ARP(tenant)
    );
    const data = res.result?.items || [];
    return {
      ...res,
      result: getManualSortedData(data, sortBy),
      count: res.result?.count,
    };
  }

  async getServiceRoutesList(
    tenant: string,
    sortBy?: SortType
  ): Promise<Res<Array<any>>> {
    const res = await this.fetchBroker<PaginateListResponse<any>>(
      VROUTER_SERVICE.LIST_ROUTES(tenant)
    );
    const data = res.result?.items || [];
    return {
      ...res,
      result: getManualSortedData(data, sortBy),
      count: res.result?.count,
    };
  }

  // service - internet
  async addInternetIpsec(
    tenant: string,
    params: InternetIpsecAdd
  ): Promise<Res<string>> {
    return await this.fetchBroker(SERVICE_INTERNET.ADD_IPSEC(tenant), {
      body: JSON.stringify(params),
    });
  }

  async addInternetNat(
    tenant: string,
    params: InternetNatAdd
  ): Promise<Res<string>> {
    return await this.fetchBroker(SERVICE_INTERNET.ADD_NAT(tenant), {
      body: JSON.stringify(params),
    });
  }

  async addInternetLB(tenant: string, params: any): Promise<Res<string>> {
    return await this.fetchBroker(SERVICE_INTERNET.ADD_LOAD_BALANCER(tenant), {
      body: JSON.stringify(params),
    });
  }

  async addAwsConnection(
    params: CloudFields,
    tenant: string
  ): Promise<Res<string>> {
    return await this.fetchBroker(SERVICE_AWS.POST(tenant), {
      body: JSON.stringify(params),
    });
  }

  // config - request
  async getRequestByID(id: string): Promise<Res<any>> {
    return await this.fetchBroker(CONFIG_REQUEST.GET_BY_ID(id));
  }

  // config - endpoint
  async getEndpoints(tenantName: string): Promise<Res<Array<Endpoint>>> {
    const res = await this.fetchBroker<PaginateListResponse<Endpoint>>(
      SERVICE_ENDPOINT.GET_LIST(tenantName)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, undefined) };
  }
  async addEndpoint(tenantName: string, params: any): Promise<Res<any>> {
    return await this.fetchBroker(CONFIG_ENDPOINT.POST(tenantName), {
      body: JSON.stringify(params),
    });
  }
  async editEndpoint(tenantName: string, name: string): Promise<Res<any>> {
    return await this.fetchBroker(CONFIG_ENDPOINT.PUT(tenantName, name));
  }
  async deleteEndpoint(tenantName: string, name: string): Promise<Res<any>> {
    return await this.fetchBroker(CONFIG_ENDPOINT.DELETE(tenantName, name));
  }

  // service - connection
  async getConnectionsList(
    tenantName: string
  ): Promise<Res<Array<ConnectionApi>>> {
    const res = await this.fetchBroker<PaginateListResponse<ConnectionApi>>(
      SERVICE_CONNECTION.GET_LIST(tenantName)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, undefined) };
  }
  async addConnection(
    tenantName: string,
    params: ConnectionAdd
  ): Promise<Res<any>> {
    return await this.fetchBroker(SERVICE_CONNECTION.POST(tenantName), {
      body: JSON.stringify(params),
    });
  }
  async editConnection(
    tenantName: string,
    name: string,
    params: ConnectionEdit
  ): Promise<Res<any>> {
    return await this.fetchBroker(SERVICE_CONNECTION.PUT(tenantName, name), {
      body: JSON.stringify(params),
    });
  }
  async deleteConnection(tenantName: string, name: string): Promise<Res<any>> {
    return await this.fetchBroker(SERVICE_CONNECTION.DELETE(tenantName, name));
  }

  getPublicIpRange = async (): Promise<
    Res<PaginateListResponse<PublicIpManagementRecord>>
  > => {
    return await this.fetchBroker(IPAM.GET());
  };

  deletePublicIpRange = async (name: string): Promise<Res<any>> => {
    return await this.fetchBroker(IPAM.DELETE(name));
  };

  createPublicIpRange = async (
    ipam: CreateIpam
  ): Promise<Res<ResultStatus>> => {
    return await this.fetchBroker<ResultStatus>(IPAM.CREATE(), {
      body: JSON.stringify(ipam),
    });
  };

  getIpam = async (): Promise<Res<any>> => {
    return await this.fetchBroker(IPAM_CONF.GET());
  };

  createIpam = async (): Promise<Res<any>> => {
    return await this.fetchBroker(IPAM_CONF.GET());
  };

  updateIpam = async (name: string): Promise<Res<any>> => {
    return await this.fetchBroker(IPAM_CONF.UPDATE(name));
  };

  deleteIpam = async (name: string): Promise<Res<any>> => {
    return await this.fetchBroker(IPAM_CONF.DELETE(name));
  };

  getConfigurationByTenant = async (
    tenant: string
  ): Promise<Res<ConfigurationPreview>> => {
    return await this.fetchBroker<ConfigurationPreview>(
      CONFIG_CONNECTION.GET(tenant)
    );
  };

  async getLocationsApi(): Promise<Res<Array<LocationsListApi>>> {
    const res = await this.fetchBroker<PaginateListResponse<LocationsListApi>>(
      LOCATIONS.GET()
    );
    const data = res.result?.items || [];
    return { ...res, result: data };
  }

  // SYSTEM BGP PFX
  async getSystemBgpPfxList(): Promise<Res<Array<GlobalRouteFilters>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<GlobalRouteFilters>
    >(BGP_PFX_SYSTEM.LIST());
    const data = [...(res.result?.items || []), ALLOW_ALL_FILTER];
    return {
      ...res,
      result: data,
    };
  }

  // SERVICE BGP PFX
  async getServiceBgpPfxList(
    tenant: string
  ): Promise<Res<Array<UserRouteFilters>>> {
    const res = await this.fetchBroker<PaginateListResponse<UserRouteFilters>>(
      SERVICE_BGP_PFX.LIST(tenant)
    );
    const data = res.result?.items || [];
    return { ...res, result: data };
  }

  async addServiceBgpPfx(
    tenant: string,
    params: UserRouteFiltersAdd
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(SERVICE_BGP_PFX.ADD(tenant), {
      body: JSON.stringify(params),
    });
  }

  async getServiceBgpPfx(
    tenant: string,
    pfx_name: string
  ): Promise<Res<UserRouteFilters>> {
    return await this.fetchBroker<UserRouteFilters>(
      SERVICE_BGP_PFX.GET(tenant, pfx_name)
    );
  }

  async deleteServiceBgpPfx(
    tenant: string,
    pfx_name: string
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(SERVICE_BGP_PFX.DELETE(tenant, pfx_name));
  }

  // FDQN Web Filtering
  async getFQDNCategoriesList(): Promise<Res<Array<FQDNCategory>>> {
    const res = await this.fetchBroker<PaginateListResponse<FQDNCategory>>(
      FQDN.LIST()
    );
    const data = res.result?.items || [];
    return { ...res, result: data, count: res.result?.count };
  }

  async addFQDNCategory(params: FQDNCategoryAdd): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(FQDN.ADD(), {
      body: JSON.stringify(params),
    });
  }

  async editFQDNCategory(
    name: string,
    params: FQDNCategoryEdit
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(FQDN.EDIT(name), {
      body: JSON.stringify(params),
    });
  }

  async deleteFQDNCategory(name: string): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(FQDN.DELETE(name));
  }

  async editFQDNService(
    tenant: string,
    params: EditFQDNService
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(SERVICES_PREFERENCES.EDIT(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async getRemoteUserService(tenant: string): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(SERVICES_PREFERENCES.GET(tenant));
    return res;
  }

  async editRemoteUserService(tenant: string, params: any): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(SERVICES_PREFERENCES.EDIT(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  // Service Wire Guard
  async getWireGuardTunnel(tenant: string, user: string): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(
      WIRE_GUARD.GET_TUNNELS_LIST(tenant, user)
    );
    return res;
  }
  async getWireGuardTunnelList(
    tenant: string,
    user: string
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(
      WIRE_GUARD.GET_USER_TUNNELS_LIST(tenant, user)
    );
    return res;
  }
  async getWireGuardConf(
    tenant: string,
    user: string,
    tenant_id: string
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(
      WIRE_GUARD.GET_TUNNEL_WG_CONFIG(tenant, user, tenant_id)
    );
    return res;
  }

  async addWireGuardTunnel(
    tenant: string,
    user: string,
    system_name: string
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(
      WIRE_GUARD.CREATE_TUNNEL_WG(tenant, user, system_name),
      {
        body: JSON.stringify({ name: "remote_device" }),
      }
    );
  }

  async deleteWireGuardTunnel(
    tenant: string,
    user: string,
    tunnel_id: string
  ): Promise<Res<IdxResponse>> {
    return await this.fetchBroker(
      WIRE_GUARD.DELETE_TUNNEL_WG(tenant, user, tunnel_id)
    );
  }

  async getWireGuardStatus(tenant: string, system?: string): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(
      WIRE_GUARD_STATUS.GET_WG_TUNNEL_STATUS(tenant),
      { query: { system_name: system } }
    );
    return res;
  }

  async getWireGuardStatusPerUser(
    tenant: string,
    user: string,
    system?: string
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(
      WIRE_GUARD_STATUS.GET_WG_TUNNEL_STATUS_FOR_USER(tenant, user),
      { query: { system_name: system } }
    );
    return res;
  }
}

export const configApi = new ConfigApi();
