import React, { FC } from "react";
import styles from "./ConnectionsPlaceholder.module.scss";
import ConnectionsPlaceholderSVG from "./ConnectionsPlaceholderSVG";

const ConnectionsPlaceholder: FC = () => {
  return (
    <div className={styles.placeholder}>
      <ConnectionsPlaceholderSVG />
      <div className={styles.msg}>
        Please Select Connection, Cloud, Internet connectivity to Add or
        Enable/Disable Remote users
      </div>
    </div>
  );
};
export default ConnectionsPlaceholder;
