import { ArubaConnectCreate } from "./../../pages/WizardToolPage/types";
import Validator from "./Validator";

export default function validateSDWAN(
  data: Partial<ArubaConnectCreate>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.connection_name, "connection_name");
  validator.checkEmpty(data.interface_name, "interface_name");
  validator.checkEmpty(data.vi_ip_address, "vi_ip_address");
  return validator;
}
