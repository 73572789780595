import React, { FC } from "react";
import { ENTER_LICENSE_KEY } from "../../../../../helpers/navigation/entries";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import AwardIcon from "../../../../icons/AwardIcon";
import LicenseIcon from "../../../../icons/LicenseIcon";

import styles from "./Documentation.module.scss";

type Props = {};

const EnterKeyPageUrl = `/${ENTER_LICENSE_KEY().path}`;

const License: FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      <a href={undefined} target={"_blank"} className={styles.link}>
        <IconBlankButton
          id={"license"}
          icon={LicenseIcon}
          label={"Evaluation License"}
          className={styles.btn}
        />
      </a>
      <a href={EnterKeyPageUrl} className={styles.link}>
        <IconBlankButton
          id={"setLicenseKey"}
          icon={AwardIcon}
          label={"Set License"}
          className={styles.btn}
        />
      </a>
    </div>
  );
};

export default License;
