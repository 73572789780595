/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import {
  FilterAndSort,
  OneSystemType,
  SortType,
  SystemOverviewType,
  Version,
} from "../../helpers/api/apiTypes";
import { systemApi } from "../../helpers/api/SystemApi";
import { EMPTY_RESULT_OK } from "../../helpers/common/constantsAlias";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { PaginateListResponse, RequestStatus } from "../../helpers/types";
import AbstractCrudContextContainer, {
  createContextAndUse,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";

type IState = CrudState<OneSystemType, OneSystemType> & {
  version: Version;
  systemVersion: Version;
  systemVersionStatus: RequestStatus;
  versionStatus: RequestStatus;
  systemsOverview: PaginateListResponse<SystemOverviewType> | undefined;
  systemsOverviewStatus: RequestStatus;
};

type IFunc = {
  fetchSystemVersion: (id: string | number) => void;
  fetchVersion: () => void;
  fetchSystemsOverview: () => void;
};

const [Context, useContext] = createContextAndUse<
  IState,
  IFunc & CrudFunc<OneSystemType, string>
>();

export const useSystemsContext = useContext;

type Props = React.PropsWithChildren<{}>;

export default class SystemContextContainer extends AbstractCrudContextContainer<
  any,
  any,
  IState,
  number
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);

    this.funcs = {
      fetchSystemVersion: this.fetchSystemVersion,
      fetchVersion: this.fetchVersion,
      fetchSystemsOverview: this.fetchSystemsOverview,
    };
  }

  listReq = async (params?: FilterAndSort) => {
    return EMPTY_RESULT_OK;
  };

  selectedReq = async (systemId: string | number) => {
    return await systemApi.getSystemById(systemId as string);
  };

  addReq = async (params: Partial<OneSystemType>) => {
    return await systemApi.addSystem(params as OneSystemType);
  };

  editReq = async (
    params: Partial<OneSystemType>,
    systemName: string | number
  ) => {
    return await systemApi.updateSystem(systemName as string, {} as any);
  };

  removeReq = async (system: string | number) => {
    return await systemApi.deleteSystem(system as string);
  };

  fetchSystemVersion = async (systemId: string | number) => {
    this.setState({ systemVersionStatus: setPending("Fetching") });
    const res = await systemApi.getSystemVersion(systemId as string);
    this.setState({ systemVersion: res.result });
    this.setState({ systemVersionStatus: resToState(res) });
  };

  fetchVersion = async () => {
    this.setState({ versionStatus: setPending("Fetching") });
    const res = await systemApi.getVersion();
    this.setState({ version: res.result });
    this.setState({ versionStatus: resToState(res) });
  };

  fetchSystemsOverview = async (sortBy?: SortType) => {
    this.setState({ systemsOverviewStatus: setPending("Fetching") });
    const res = await systemApi.getSystemsOverviewList(sortBy);
    this.setState({ systemsOverview: res.result });
    this.setState({ systemsOverviewStatus: resToState(res) });
  };

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, ...this.funcs, ...this.baseFuncs }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
