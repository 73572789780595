import React, { FC, useEffect, useState } from "react";
import { Menu } from "../../../../ServicesStep/components/Menu";
import { BaseMenuItem } from "../../../../types";

type Props = {
  list: Array<BaseMenuItem>;
  onClick: (id: string) => void;
  activeType?: string;
};

const TabBlock: FC<Props> = ({ list, onClick, activeType }) => {
  const [typeList, setTypeList] = useState(list);

  useEffect(() => {
    setTypeList((prev) =>
      prev.map((type) => {
        return {
          ...type,
          active: activeType === type.id,
        };
      })
    );
  }, [activeType]);

  return <Menu list={typeList} onClick={(id) => onClick(id)} />;
};

export default TabBlock;
