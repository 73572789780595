import React from "react";
import { userApi } from "../helpers/api/UserApi";
import { RequestStatus, User } from "../helpers/types";
import { createContextAndUse } from "./common/utils";
import { resToState, setPending } from "../helpers/common/RequestStateHelpers";
import {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../components/common/table/newTable/Table";
import { tenantApi } from "../helpers/api/TenantApi";
import { configApi } from "../helpers/api/ConfigApi";

type IState = {
  currUser?: any;
  currSystem?: string;
  userStatus?: RequestStatus;
  userList: Array<User>;
  selectedUser: User | null;
  listFetchRequest?: RequestStatus;
  userRequest: RequestStatus;
};

type IFunc = {
  fetchCurrUser: (tenant: string, user: string) => Promise<void>;
  fetchCurrSystem: (tenant: string) => Promise<void>;
  fetchUserList: (
    offset: number,
    limit: number,
    tenant: string,
    system?: string
  ) => Promise<void>;
  selectUser: (userId: string) => void;
  deleteUser: (tenant: string, username: string) => Promise<boolean>;
  unselectUser: () => void;
  // addUser: (user: User, tenant?: string) => Promise<boolean>;
  addUser: (user: User, tenant?: string) => any;
  updateUser: (tenant: string, user: Partial<User>) => Promise<boolean>;
  resetUserRequest: () => RequestStatus;
  createWGTunnel: (
    tenant: string,
    username: string,
    system: string
  ) => Promise<boolean>;
};

export const GLOBAL_TENANT = "global";

const [UserListContext, useContext] = createContextAndUse<IState, IFunc>();
export const useUserListContext = useContext;

export default class UserListContextContainer extends React.Component<
  any,
  IState
> {
  funcs: IFunc;

  constructor(props: Readonly<{}>) {
    super(props);

    this.funcs = {
      fetchCurrUser: this.fetchCurrUser,
      fetchUserList: this.fetchUserList,
      fetchCurrSystem: this.fetchCurrSystem,
      selectUser: this.selectUser,
      deleteUser: this.deleteUser,
      unselectUser: this.unselectUser,
      addUser: this.addUser,
      updateUser: this.updateUser,
      resetUserRequest: this.resetUserRequest,
      createWGTunnel: this.createWGTunnel,
    };

    this.state = {
      userRequest: { state: "idle" },
      selectedUser: null,
      userList: [],
    };
  }

  fetchCurrUser = async (tenant: string, user: string): Promise<void> => {
    this.setState({ userStatus: setPending() });
    const res = await userApi.getTenantUser(tenant, user);
    if (res.ok && res.result) {
      this.setState({ currUser: res.result });
    }
    this.setState({ userStatus: resToState(res) });
  };
  resetUserRequest = () => {
    const newStatus: RequestStatus = { state: "idle" };
    this.setState({ userRequest: newStatus });

    return newStatus;
  };

  fetchCurrSystem = async (tenant: string): Promise<void> => {
    const enabledServicesRes = await tenantApi.getEnabledServices(tenant);
    const remoteUserService = enabledServicesRes.result?.services.find(
      (serviceAPI) => serviceAPI.type === "remote_users" && serviceAPI.enable
    );
    const systemName = remoteUserService?.data.wg_conf[0].system_name;
    if (!systemName) {
      this.setState({
        userRequest: {
          state: "error",
          message: "No remote user services on this tenant",
        },
      });
    } else {
      this.setState({
        currSystem: systemName,
        userRequest: { state: "ok" },
      });
    }
  };

  fetchUserList = async (
    offset: number,
    limit: number,
    tenant: string,
    system?: string
  ): Promise<void> => {
    this.setState({ listFetchRequest: setPending() });
    const res =
      tenant === GLOBAL_TENANT
        ? await userApi.getGlobalUsers()
        : await userApi.getTenantUsers(tenant);
    const resTunnel = await configApi.getWireGuardStatus(tenant, system);
    if (res?.ok && res?.result) {
      if (system) {
        //remote users enabled
        if (resTunnel?.result) {
          const newUserList = res.result.map((oneUser: any) => ({
            ...oneUser,
            tunnel_status: (
              resTunnel?.result.items.find(
                (tunnel: any) => tunnel.user_name === oneUser.username
              ) || { status: "NODATA" }
            ).status,
          }));
          this.setState({ userList: newUserList });
        } else {
          //remote users enabled but no tunnel data
          const newUserList = res.result?.map((oneUser: any) => ({
            ...oneUser,
            tunnel_status: "NODATA",
          }));
          this.setState({ userList: newUserList || [] });
        }
      } else {
        //RU disbled
        const newUserList = res.result?.map((oneUser: any) => ({
          ...oneUser,
          tunnel_status: "NODATA",
        }));
        this.setState({ userList: newUserList || [] });
      }
    }
    this.setState({ listFetchRequest: resToState(res) });
    if (window.location.href.includes("tenants/users"))
      setTimeout(() => {
        this.fetchUserList(offset, limit, tenant, system);
      }, 60000);
  };

  unselectUser = (): void => {
    this.setState({ selectedUser: null });
  };

  selectUser = (userId: string): void => {
    const selectedUser = this.state.userList.find((u) => u.id === userId);
    if (!selectedUser) {
      return;
    }
    this.setState({ selectedUser });
  };

  deleteUser = async (tenant: string, username: string): Promise<boolean> => {
    this.setState({ userRequest: { state: "pending" } });
    const { ok, error } = await userApi.deleteUser(tenant, username);

    if (!ok) {
      this.setState({ userRequest: { state: "error", message: error } });
    } else {
      this.unselectUser();
      this.setState({
        userList: this.state.userList.filter((u) => u.username !== username),
        userRequest: { state: "ok" },
      });
    }

    return ok;
  };

  addUser = async (user: User, tenant?: string) => {
    this.setState({ userRequest: { state: "pending" } });
    if (tenant && user.is_remote_user) {
      const enabledServicesRes = await tenantApi.getEnabledServices(tenant);
      const remoteServices = enabledServicesRes.result?.services.find(
        (serviceAPI) => serviceAPI.type === "remote_users" && serviceAPI.enable
      );
      if (remoteServices) {
        const res =
          tenant && tenant !== GLOBAL_TENANT
            ? await userApi.addUserToTenant(user, tenant)
            : await userApi.addUser(user);
        if (res?.ok && tenant) {
          this.fetchUserList(
            DEFAUTL_OFFSET,
            DEFAUTL_LIMIT,
            tenant,
            this.state.currSystem
          );
        }
        this.setState({ userRequest: resToState(res) });
        return res?.ok;
      } else {
        this.setState({
          userRequest: {
            state: "error",
            message: "Please enable remote users service on this tenant.",
          },
        });
      }
    } else {
      const res =
        tenant && tenant !== GLOBAL_TENANT
          ? await userApi.addUserToTenant(user, tenant)
          : await userApi.addUser(user);
      if (res?.ok && tenant) {
        this.fetchUserList(
          DEFAUTL_OFFSET,
          DEFAUTL_LIMIT,
          tenant,
          this.state.currSystem
        );
      }
      this.setState({ userRequest: resToState(res) });
      return res?.ok;
    }
  };

  updateUser = async (
    tenant: string,
    user: Partial<User>
  ): Promise<boolean> => {
    this.setState({ userRequest: { state: "pending" } });
    const { username, ...restParams } = user;
    const reqBody = { username, data: { ...restParams } };
    const res = await userApi.updateUser(tenant, reqBody);
    if (res?.ok && tenant) {
      this.fetchUserList(
        DEFAUTL_OFFSET,
        DEFAUTL_LIMIT,
        tenant,
        this.state.currSystem
      );
    }
    this.setState({ userRequest: resToState(res) });
    return res?.ok;
  };

  createWGTunnel = async (
    tenant: string,
    user: string,
    system: string
  ): Promise<boolean> => {
    const createTunnelRes = await configApi.addWireGuardTunnel(
      tenant,
      user,
      system
    );
    if (createTunnelRes.ok) {
      return createTunnelRes?.ok;
    }
    // if faild set status
    return createTunnelRes?.ok;
  };

  render(): JSX.Element {
    return (
      <UserListContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserListContext.Provider>
    );
  }
}
