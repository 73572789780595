import React, { FC } from "react";
import { ASType } from "../../../types";
import BGPAutonomousSystem from "../../../../../components/common/bgpComponents/BGPAutonomousSystem";
import AddButton from "../../../../../components/common/buttons/AddButton";
import { useHistory } from "react-router-dom";
import Button from "../../../../../components/common/buttons/Button";
import { formPathFromArray } from "../../../../../helpers/navigation";
import {
  BGP,
  CLOUD_ROUTER,
  NETWORK,
  ROUTE_FILTERS,
} from "../../../../../helpers/navigation/entries";

type HeaderProps = {
  data?: Partial<ASType>;
  className: string;
  onClick: () => void;
  onAdd: () => void;
  disabled: boolean;
};

const ServiceBGPHeader: FC<HeaderProps> = ({
  data,
  className,
  onClick,
  onAdd,
  disabled,
}) => {
  const history = useHistory();
  return (
    <div className={className}>
      <span>BGP</span>
      <BGPAutonomousSystem onClick={onClick} data={data} isIconShown={true} />
      <Button
        onClick={() => {
          history.replace(
            formPathFromArray([
              NETWORK(),
              CLOUD_ROUTER(),
              BGP(),
              ROUTE_FILTERS(),
            ])
          );
        }}
      >
        Route Filters
      </Button>
      <AddButton onClick={onAdd} label={"Add Neighbor"} disabled={disabled} />
    </div>
  );
};

export default ServiceBGPHeader;
