import { tenantApi } from "../../../helpers/api/TenantApi";
import { Tenant, TenantApi } from "../types";

export const fetchFullTenantData = async (
  list: Array<TenantApi>
): Promise<Array<Tenant>> => {
  const newList: Array<Tenant> = [];

  await Promise.all(
    list.map(
      async (tenant, i): Promise<any> => {
        const viRes = await tenantApi.getVirtualInterfaces(tenant.name);
        let interfaces: Array<string> = [];
        if (viRes.ok && viRes.result) {
          interfaces = viRes.result.map((vi) => vi.name);
        }
        newList[i] = { ...tenant, interfaces };
      }
    )
  );

  return newList;
};
