import { FC, useEffect } from "react";
import styles from "./AddressFamilyItem.module.scss";
import {
  ChangeField,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { useBGPServicesContext } from "../../../ServicesBGPContext";
import { ADDRESS_FAMILY_MAP } from "../../../consts";
import Input from "../../../../../components/common/formComponents/Input";
import { useHistory } from "react-router-dom";
import { ADD, EDIT } from "../../../../../helpers/navigation/entries";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";
import { getTimeFormMS } from "../../../../../helpers/common/dateHelpers";
import { RouteFiltersFields } from "../../../types";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  isEdit?: boolean;
  errors?: any;
  neighborBgpStatus?: any;
  routeFilters: RouteFiltersFields;
  onChangeRouteFilters: (key: string, value: string) => void;
};

const AddressFamilyItem: FC<Props> = ({
  fields,
  onChange,
  isEdit,
  neighborBgpStatus,
  routeFilters,
  onChangeRouteFilters,
}) => {
  const history = useHistory();
  const {
    routeMapList,
    fetchRouteMapList,
    removeRouteFilter,
  } = useBGPServicesContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    const tenant = localStorage.getItem("selectedTenant");
    if (tenant) {
      fetchRouteMapList(tenant);
    }
  }, []);

  const handleGoToRouteFilters = (name?: string) => {
    const specifier = name ? EDIT(name).path : ADD().path;
    history.push(`/network/cloudRouter/bgp/routeFilters/${specifier}`);
  };

  const handleRemoveRouteFilter = async (name?: string) => {
    if (!selectedTenant || !name) return;
    await removeRouteFilter(name, selectedTenant);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.firstCol}>
        <DropdownBasic
          id={"addressFamily"}
          onChange={() => undefined}
          selected={mapStringToItem(ADDRESS_FAMILY_MAP[0])}
          label="Address Family"
          placeholder=""
          itemsList={ADDRESS_FAMILY_MAP.map(mapStringToItem)}
          isMedium
          error={""}
          isNotEditable={isEdit}
        />
        {neighborBgpStatus && (
          <div className={styles.statusGroup}>
            <TextWithIcon
              icon={
                <StatusIcon
                  color={getColorFromStatus(neighborBgpStatus.state)}
                />
              }
              text={neighborBgpStatus.state}
            />
            <span>
              Up/Down: {getTimeFormMS(+neighborBgpStatus.peerUptimeMsec)}
            </span>
            <span>
              Prefixes Sent/Received: {neighborBgpStatus.pfxSnt}/
              {neighborBgpStatus.pfxRcd}
            </span>
          </div>
        )}
      </div>
      <div className={styles.secondCol}>
        <DropdownBasic
          id={"pfx_list_in"}
          onChange={(val) => onChangeRouteFilters("in", val.key)}
          selected={mapStringToItem(routeFilters.in || "")}
          label="Route Filter"
          placeholder="In"
          itemsList={routeMapList}
          isMedium
          error={""}
          createText="Route Filter"
          isPlaceholderAlwaysVisible
          onCreate={() => handleGoToRouteFilters()}
          onEditOption={handleGoToRouteFilters}
          onDeleteOption={handleRemoveRouteFilter}
          isListEditable
          isNotEditable={isEdit}
        />
        <DropdownBasic
          id={"pfx_list_out"}
          onChange={(val) => onChangeRouteFilters("out", val.key)}
          selected={mapStringToItem(routeFilters.out || "")}
          placeholder="Out"
          itemsList={routeMapList}
          isMedium
          error={""}
          createText="Route Filter"
          isPlaceholderAlwaysVisible
          onCreate={() => handleGoToRouteFilters()}
          onEditOption={handleGoToRouteFilters}
          onDeleteOption={handleRemoveRouteFilter}
          isListEditable
          isNotEditable={isEdit}
        />
        <div className={styles.row}>
          <Input
            type="number"
            value={fields.max_pfx_in}
            label="Maximum Prefix"
            onChange={(e) => {
              onChange("max_pfx_in", e.target.value);
            }}
            isPlaceholderAlwaysVisible
            medium
            error={""}
            placeholder={"In"}
          />
          <Input
            type="number"
            value={fields.max_pfx_out}
            onChange={(e) => {
              onChange("max_pfx_out", e.target.value);
            }}
            isPlaceholderAlwaysVisible
            medium
            error={""}
            placeholder={"Out"}
          />
        </div>
      </div>
    </div>
  );
};
export default AddressFamilyItem;
