import { StaticRoutesBodyType } from "../../../../../helpers/api/apiTypes";
import { Toggle } from "../../../Toggle";
import { CellFactory } from "../../newTable/cells/CellsFactory";

const cellFactory = new CellFactory<StaticRoutesBodyType>({});

export const staticTableHeader = (): Array<any> => {
  return [
    {
      Header: "Enabled",
      accessor: "enabled",
      Cell: () => (
        <Toggle
          isChecked={true}
          onChange={() => {
            return;
          }}
        />
      ),
    },
    {
      Header: "Destination",
      accessor: "destination",
      Cell: cellFactory.labelCell(),
    },
    {
      Header: "Gateway",
      accessor: "gateway_ip_address",
    },
    {
      Header: "Metric",
      accessor: "distance",
    },
    {
      Header: "Description",
      accessor: "description",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof StaticRoutesBodyType,
  }));
};
