import React, { FC } from "react";
import { withContexts } from "../../../helpers/hocs/withContexts";

import CyberThreatsContextContainer from "../CyberThreatsContext";
import Filters from "./components/Filters";
import ThreatsStatistics from "./components/ThreatsStatistics";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { Redirect, Route, Switch } from "react-router-dom";
import { addPrefix } from "../../../helpers/navigation";
import { Routes } from "../../common";
import {
  ADVANCED_THREATS,
  DNS_THREATS,
} from "../../../helpers/navigation/entries";
import { RouteEntry } from "../../types";
import DNSStatistics from "./components/DNSStatistics";

type Props = { tabs: any };

const CyberThreatsDashboard: FC<Props> = ({ tabs: Tabs }) => {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(DNS_THREATS(), path)} />
      </Route>
      <Routes
        routes={dashboardRoutes}
        tabs={Tabs as any}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default withContexts<Props>(CyberThreatsDashboard, [
  CyberThreatsContextContainer,
]);

const Dns: FC<{ tabs: any }> = ({ tabs: Tabs }) => {
  return (
    <>
      <Tabs />
      <DNSStatistics />
    </>
  );
};

const CyberThreatsStat: FC<Props> = ({ tabs: Tabs }) => {
  return (
    <>
      {Tabs && <Tabs filter={<Filters />} />}
      <ThreatsStatistics />
    </>
  );
};

const dashboardRoutes: Array<RouteEntry> = [
  { pathFunc: ADVANCED_THREATS, component: CyberThreatsStat },
  { pathFunc: DNS_THREATS, component: Dns },
];
