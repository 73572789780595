import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ConnectionIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.83464 8.7853C9.91666 8.54314 10.7253 7.57694 10.7253 6.42188C10.7253 5.08431 9.64095 4 8.30339 4C6.96582 4 5.88151 5.08431 5.88151 6.42188C5.88151 7.53956 6.63863 8.48041 7.66797 8.75954V15.5987C5.60016 15.8703 4.00391 17.6394 4.00391 19.7813C4.00391 22.1113 5.8927 24.0001 8.22266 24.0001C10.3221 24.0001 12.0634 22.4664 12.3874 20.4584H19.2815C19.5989 21.4261 20.5095 22.125 21.5833 22.125C22.9209 22.125 24.0052 21.0407 24.0052 19.7031C24.0052 18.3655 22.9209 17.2812 21.5833 17.2812C20.386 17.2812 19.3916 18.1501 19.1963 19.2917L12.4133 19.2917C12.3313 18.5822 12.0733 17.9263 11.684 17.3688L17.5595 11.4933C17.8914 11.6641 18.2677 11.7606 18.6667 11.7606C20.0042 11.7606 21.0885 10.6762 21.0885 9.33868C21.0885 8.00112 20.0042 6.91681 18.6667 6.91681C17.3291 6.91681 16.2448 8.00112 16.2448 9.33868C16.2448 9.8536 16.4055 10.331 16.6795 10.7234L10.8901 16.5127C10.3125 16.0408 9.60755 15.7189 8.83464 15.6066V8.7853Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ConnectionIcon;
