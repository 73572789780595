import { FC, useEffect } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import { withContexts } from "../../helpers/hocs/withContexts";
import { ServicesPreferencesContextContainer } from "./ServicesPreferencesContext";
import { useServicesPreferencesContext } from "./ServicesPreferencesContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import styles from "./ServicesPreferences.module.scss";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import { classNames } from "../../helpers/common/classNames";
import ServiceInfo from "./components/ServiceInfo";
import ServicesGroup from "./components/ServicesGroup";
import ActiveTenantSelector from "../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import TenantConcumersInfo from "./components/TenantConsumersInfo";

const ServicesPreferences: FC = () => {
  const {
    fetchServices,
    fetchTenantConsumersCount,
    groupedList,
    fetchStatus,
    selectedService,
  } = useServicesPreferencesContext();
  const { selectedTenant } = useGlobalFilterContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchServices(selectedTenant);
      fetchTenantConsumersCount(selectedTenant);
    }
  }, [selectedTenant]);

  return (
    <div
      className={classNames(styles.wrapper, selectedService && styles.twoCols)}
    >
      <div className={styles.tableWrapper}>
        <div className={styles.header}>
          Services
          {selectedTenant && <TenantConcumersInfo />}
          <ActiveTenantSelector />
        </div>
        {fetchStatus?.state === "pending" || !selectedTenant ? (
          <PageLoader />
        ) : (
          <div className={styles.listWrapper}>
            {Object.keys(groupedList).map((g, i) => (
              <ServicesGroup
                key={g + i}
                title={g}
                services={groupedList[g]}
                className={styles.group}
                tenant={selectedTenant}
              />
            ))}
          </div>
        )}
      </div>
      {selectedService && <ServiceInfo />}
    </div>
  );
};
export default withContexts<any>(withLayout<any>(ServicesPreferences), [
  ServicesPreferencesContextContainer,
]);
