type ListType = Array<{ [key: string]: any }>;

export const getNameById = (
  id?: number,
  list?: ListType,
  customParam?: string
): string => {
  if (id === undefined || !list) return "";
  const param = customParam || "name";
  return list.find((item) => item.id === id)?.[param] || "";
};
