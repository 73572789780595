import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { commonTableColumns } from "../table/commonTableColumns";
import { groupTableStyles } from "../helpers/consts";
import { formPathFromArray } from "../../../helpers/navigation";
import { INSIGHTS, TRAFFIC } from "../../../helpers/navigation/entries";

const DestinationIpsTable: FC = () => {
  const { data, statuses } = useSearchContext();
  return (
    <ResultTable
      title="Destination Ips"
      data={data?.destinationIps}
      columns={commonTableColumns("Destination Ip")}
      status={statuses?.destinationIpsStatus}
      style={groupTableStyles}
      goto={formPathFromArray([INSIGHTS(), TRAFFIC()])}
    />
  );
};
export default DestinationIpsTable;
