import React from "react";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { IconProps } from "../../../helpers/types";

export default function GCPIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      {...formIconAttrs(32, 32, props)}
    >
      <g fill="#59536B" clipPath="url(#clip0_16828_203975)">
        <path d="M20.405 11.815l1.97-2.016.132-.85c-3.59-3.34-9.298-2.962-12.535.791a9.393 9.393 0 00-1.921 3.684l.705-.102 3.941-.665.304-.318c1.753-1.97 4.717-2.236 6.74-.56l.664.036z"></path>
        <path d="M25.182 13.365a9.118 9.118 0 00-2.675-4.415l-2.765 2.83a5.08 5.08 0 011.804 3.99v.503c1.36 0 2.461 1.127 2.461 2.518a2.466 2.466 0 01-2.461 2.49h-4.93l-.483.539v3.02l.484.475h4.929c3.535.028 6.424-2.854 6.451-6.472.017-2.194-1.04-4.25-2.815-5.478z"></path>
        <path d="M11.697 25.314h4.922v-4.032h-4.922c-.351 0-.69-.078-1.01-.227l-.698.22-1.984 2.016-.173.686c1.113.86 2.47 1.344 3.865 1.337z"></path>
        <path d="M11.695 12.232c-3.535.022-6.384 2.973-6.363 6.591a6.606 6.606 0 002.499 5.154l2.855-2.922c-1.239-.572-1.789-2.064-1.23-3.332.56-1.268 2.018-1.831 3.257-1.258.546.252.983.7 1.23 1.258l2.855-2.922a6.344 6.344 0 00-5.102-2.569z"></path>
      </g>
      <defs>
        <clipPath id="clip0_16828_203975">
          <path
            fill="#fff"
            d="M0 0H22.667V18.667H0z"
            transform="translate(5.332 6.667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
