import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function DropDownIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M5 8L9.33013 3.5H0.669873L5 8Z" fill={color || FONT} />
    </svg>
  );
}

export default DropDownIcon;
