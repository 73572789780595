import React, { FC } from "react";
import { SystemsTabs } from "../../components/common/navigation/SystemsTabs";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import styles from "./MeshNetworkPage.module.scss";

import MeshNetworkContent from "./MeshNetworkContent";

const MeshNetworkPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <SystemsTabs showTimeRange />
      <MeshNetworkContent />
    </div>
  );
};

export default withLayout<PageProps>(MeshNetworkPage);
