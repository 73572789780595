import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CCNIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7"
        cy="4"
        r="1.5"
        fill={color || FONT}
        stroke={color || FONT}
      />
      <circle
        cx="7"
        cy="18"
        r="1.5"
        fill={color || FONT}
        stroke={color || FONT}
      />
      <path
        d="M7 4v14"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="14.1"
        cy="4"
        r="1.5"
        fill={color || FONT}
        stroke={color || FONT}
      />
      <circle
        cx="14.1"
        cy="18"
        r="1.5"
        fill={color || FONT}
        stroke={color || FONT}
      />
      <path
        d="M14 4v14"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default CCNIcon;
