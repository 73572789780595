import { FC, ReactElement } from "react";
import { RequestStatus } from "../../../../helpers/types";
import LayoutComponent from "../../../common/LayoutComponent";
import DataPlaceHolders from "../../../common/loadStates/DataPlaceHolder";

type Props = {
  status?: RequestStatus;
  tabs: ReactElement;
};

const EmtyPackages: FC<Props> = ({ status, tabs }) => {
  return (
    <LayoutComponent>
      {tabs}
      <DataPlaceHolders
        isEmpty={!status}
        status={status}
        emptyTitle="There is no data here yet"
        errorTitle="Get Data Error"
        errorSubTitle="Please refresh page or report this to InsidePacket"
      />
    </LayoutComponent>
  );
};
export default EmtyPackages;
