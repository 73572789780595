import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ExternalIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.15 3.75a1.4 1.4 0 0 0-1.4 1.4v4.7a1.4 1.4 0 0 0 1.4 1.4h7.7a1.4 1.4 0 0 0 1.4-1.4v-4.7a1.4 1.4 0 0 0-1.4-1.4h-7.7Zm.5 1.5a.4.4 0 0 0-.4.4v3.7c0 .22.18.4.4.4h6.7a.4.4 0 0 0 .4-.4v-3.7a.4.4 0 0 0-.4-.4h-6.7Z"
        fill={color || FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 13.5v-2.25h1.5v2.25H12V15H6v-1.5h2.25Z"
        fill={color || FONT}
      />
      <rect
        x="5.25"
        y="13.5"
        width="7.5"
        height="1.5"
        rx=".75"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ExternalIcon;
