import React, { FC, useCallback } from "react";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import isEmpty from "../../../helpers/common/isEmpty";
import SearchInput from "../../../components/common/SearchInput";
import AddButton from "../../../components/common/buttons/AddButton";

type Props = {
  onAdd: () => void;
  noSearch?: boolean;
};
const TableControls: FC<Props> = ({ onAdd, noSearch }) => {
  const { setFilter, filter } = useTenantContext();

  const handleSearch = useCallback(
    (value: string) => {
      setFilter(isEmpty(value) ? {} : { fields: { name: value } });
    },
    [setFilter]
  );

  return (
    <div
      style={{
        display: "grid",
        gap: "0.5rem",
        gridColumn: "auto",
        gridTemplateColumns: "auto auto",
      }}
    >
      {!noSearch && (
        <SearchInput onChange={handleSearch} value={filter?.fields?.name} />
      )}
      <AddButton onClick={onAdd} />
    </div>
  );
};

export default TableControls;
