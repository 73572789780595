import React, { FC, ReactNode } from "react";
import GrayString from "./table/GrayString";

const ValueWithPlaceholder: FC<{
  value: string | number | ReactNode | undefined;
}> = ({ value }) => {
  return <>{value || <GrayString text="—" />}</>;
};

export default ValueWithPlaceholder;
