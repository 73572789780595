import { ReactElement } from "react";
import CloudIcon from "../../../../../components/icons/CloudIcon";
import DataCenterPortIcon from "../../../../../components/icons/DataCenterPortIcon";
import LocationFillIcon from "../../../../../components/icons/LocationFillIcon";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import { IconProps } from "../../../../../helpers/types";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  DIA,
} from "../consts";
import InternetIcon from "../../../../../components/icons/interfaceTypes/InternetIcon";

type IconType = (props: IconProps) => ReactElement;

export const getMenuIcon = (id: string): IconType => {
  switch (id) {
    case CUSTOMER_LOCATIONS:
      return LocationFillIcon;
    case CLOUD_CONNECTIONS:
      return CloudIcon;
    case DATA_CENTER_PORT:
      return DataCenterPortIcon;
    case DIA:
      return InternetIcon;
    default:
      return StatusIcon;
  }
};
