import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ClockIcon({
  color,
  height = 14,
  width = 14,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 7A7 7 0 1 1 0 7a7 7 0 0 1 14 0ZM6 4a1 1 0 0 1 2 0v2h1a1 1 0 1 1 0 2H7a1 1 0 0 1-1-1V4Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ClockIcon;
