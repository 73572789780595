import { FC } from "react";
import styles from "./TenantConsumersInfo.module.scss";
import UserIconNarrow from "../../../components/icons/UserIconNarrow";
import LocationIconFilledColor from "../../../components/icons/LocationIconFilledColor";
import { useServicesPreferencesContext } from "../ServicesPreferencesContext";
import { TenantConsumersItem } from "../types";

const TenantConcumersInfo: FC = () => {
  const { tenantConsumersCount } = useServicesPreferencesContext();

  const consumersItems: Array<TenantConsumersItem> = [
    {
      title: "Users",
      icon: UserIconNarrow,
      consumersCount: tenantConsumersCount?.users,
    },
    {
      title: "Locations",
      icon: LocationIconFilledColor,
      consumersCount: tenantConsumersCount?.locations,
    },
  ];

  return (
    <div className={styles.wrapper}>
      {consumersItems.map((consumerItem, i) => {
        return (
          <TenantConcumersItem
            key={i}
            title={consumerItem.title}
            icon={consumerItem.icon}
            consumersCount={consumerItem.consumersCount || 0}
          />
        );
      })}
    </div>
  );
};

export default TenantConcumersInfo;

const TenantConcumersItem: FC<TenantConsumersItem> = ({
  title,
  icon,
  consumersCount,
}) => {
  return (
    <div className={styles.consumerInfo}>
      <div className={styles.consumerTitle}>{title}</div>
      <div className={styles.consumerDetail}>
        <div className={styles.consumerIcon}>{icon({})}</div>
        <div className={styles.consumerCount}>{consumersCount}</div>
      </div>
    </div>
  );
};
