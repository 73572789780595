import { FC, useMemo } from "react";
import ChartToppersTabs from "../../../components/common/ChartToppersTabs";
import { PageHeader } from "../../../components/common/PageHeader/PageHeader";
import TimerangOverlayComponent from "../../../components/common/timerange/TimerangeOverlayComponent";
import { withContexts } from "../../../helpers/hocs/withContexts";
import {
  NetworkContextContainer,
  useNetworkContext,
} from "../context/NetworkContext";
import {
  combineState,
  convertToGroupRows,
  removeNATVis,
} from "../helpers/table";
import { StatGroup } from "./components/StatGroup";
import { StatSection } from "./components/StatSection";

import styles from "./InterfaceStatistics.module.scss";

const InterfaceStatistics: FC = () => {
  const {
    connections,
    trafficType,
    setTrafficType,
    systems,
  } = useNetworkContext();

  const filteredConnections = useMemo(() => {
    return connections.filter(
      (connection) =>
        connection.type !== "Virtual Interface gate" &&
        connection.virtual_interface_type !== "loopback"
    );
  }, [connections]);
  const viGroups = removeNATVis(
    convertToGroupRows(combineState(filteredConnections), systems)
  );

  return (
    <div className={styles.wrapper}>
      <PageHeader
        title="Interface Statistics"
        right={[
          <ChartToppersTabs
            activeTab={trafficType}
            setTabActive={setTrafficType}
          />,
        ]}
      />
      <StatSection type="total" title={"Total"} name="Total Traffic" />
      {viGroups.map((group) => (
        <StatGroup group={group} />
      ))}
    </div>
  );
};

export default InterfaceStatistics;
