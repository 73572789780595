/* eslint-disable prettier/prettier */
import React, { FC, useEffect, useState } from "react";
import { jsPDF } from "jspdf";
import { useParams } from "react-router-dom";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { tenantApi } from "../../helpers/api/TenantApi";
import { InternetIpsec } from "../WizardToolPage/types";

export enum ConfigurationKeys {
  PHASE_1 = "phase_1",
  PHASE_2 = "phase_2",
  PHASE_2_1 = "phase2_1",
  ENABLE_ROUTING = "enable_routing",
  ADD_PSK = "add_psk",
  EDIT_ETC = "edit_etc",
  START_CONNECTION = "start_connection",
  STATUS = "status",
}

type IFunc = {
  downloadPDF: () => void;
};

type IState = {
  ipsecInterface: InternetIpsec;
};


type Props = {
  children:
  | boolean
  | React.ReactChild
  | React.ReactFragment
  | React.ReactPortal
  | null
  | undefined;
};

const [IPsecContext, useContext] = createContextAndUse<IState, IFunc>();
export const useIPsecContext = useContext;

export const IPsecContextContainer: FC<Props> = ({ children }) => {
  const [ipsecInterface, setIpsecInterface] = useState<
    InternetIpsec | Record<string, any>
  >({});

  const downloadPDF = () => {
    const guidePDF = new jsPDF('p', 'pt', 'a4');

    const guideTable = document.getElementById("guide") || "";

     guidePDF.html(guideTable, {
      callback: function (guidePDF) {
        guidePDF.save("StrongSwan Guide.pdf");
      },
    });
  }

  const {
    id,
    selectedTenant
  }: { id: string; selectedTenant: string } = useParams();

  const getIpsecInterface = async () => {
    if (!selectedTenant) {
      return;
    }

    const { result, ok } = await tenantApi.getIPsecVis(selectedTenant);

    if (!ok || !result?.items.length) {
      return;
    }

    const viId = +id;

    const ipsec = result.items.find((r) => r.id === viId);

    if (!ipsec) {
      return;
    }

    const status = await tenantApi.getIPsecGateway(selectedTenant);

    const ip = status.result?.items.find(
      (i) => i.name === ipsec.virtual_interface_ipsec_gw_name
    )?.ip_addresses[0];

    setIpsecInterface({ ...ipsec, gateway_ip: ip ? ip.split("/")[0] : ip });
  };



  useEffect(() => {
    getIpsecInterface();
  }, [selectedTenant]);

  return (
    <IPsecContext.Provider
      value={{
        ipsecInterface,
        downloadPDF
      }}
    >
      {children}
    </IPsecContext.Provider>
  );
};
