import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function LogoElementIcon({ x, y }: IconProps): ReactElement {
  return (
    <svg
      width={x ? "28" : "75"}
      height={y ? "28" : "75"}
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x ? x - 28 / 2 : "none"}
      y={y ? y - 8 : "none"}
    >
      <g clipPath="url(#clip0_2010_576)">
        <path
          d="M39.6593 14.25L59.6795 25.8763V49.1289L39.6593 60.7552L19.6391 49.1289V25.8763L39.6593 14.25Z"
          fill="#FDFCFE"
        />
        <path
          d="M24.4453 29.3713L38.0501 37.3989L51.649 29.3713L38.0501 21.3438L24.4453 29.3713Z"
          fill="#FFA400"
        />
        <path
          d="M19.332 26.3509L38.0482 15.3034L56.7644 26.3509L69.3894 18.9014L38.0482 0.398438L6.70703 18.9014L19.332 26.3509Z"
          fill="#FFA400"
        />
        <path
          d="M51.6438 29.3711L38.0449 37.3987V53.4597L51.6438 45.4262V29.3711Z"
          fill="#FF6C0E"
        />
        <path
          d="M56.7611 26.3519V48.4468L38.0449 59.5002V74.3993L69.3861 55.9023V18.9023L56.7611 26.3519Z"
          fill="#FF6C0E"
        />
        <path
          d="M24.4453 29.3711V45.4262L38.0501 53.4597V37.3987L24.4453 29.3711Z"
          fill="#59536B"
        />
        <path
          d="M19.332 48.4468V40.785V26.3519L6.70703 18.9023V55.9023L19.332 63.3518V48.4468Z"
          fill="#59536B"
        />
        <path
          d="M24.4453 51.4648V66.3698L38.0501 74.3974V59.4983L24.4453 51.4648Z"
          fill="#59536B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2010_576">
          <rect
            width="74"
            height="74"
            fill="white"
            transform="translate(0.707031 0.398438)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoElementIcon;
