import { ReactElement } from "react";
import { IconProps } from "../../../../../helpers/types";
import { FONT } from "../../../../../helpers/common/colorAliases";

function BlockSelectedIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.875 10C3.875 6.61726 6.61726 3.875 10 3.875C11.4211 3.875 12.7291 4.35895 13.7683 5.17104L5.17104 13.7683C4.35895 12.7291 3.875 11.4211 3.875 10ZM6.2317 14.829C7.27088 15.641 8.57893 16.125 10 16.125C13.3827 16.125 16.125 13.3827 16.125 10C16.125 8.57893 15.641 7.27088 14.829 6.2317L6.2317 14.829ZM10 2.375C5.78883 2.375 2.375 5.78883 2.375 10C2.375 14.2112 5.78883 17.625 10 17.625C14.2112 17.625 17.625 14.2112 17.625 10C17.625 5.78883 14.2112 2.375 10 2.375Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default BlockSelectedIcon;
