import React, { FC, useEffect } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table, {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { withContexts } from "../../helpers/hocs/withContexts";
import ARPsContextContainer, { useARPsContext } from "./ARPsContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { arpsTableHeader } from "./table/arpsTableHeader";

const ARPsTable: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchList, list, listStatus, count, totalCount } = useARPsContext();

  useEffect(() => {
    fetchWrapper();
  }, [selectedTenant]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    selectedTenant && fetchList(selectedTenant, offset, limit);
  };

  return (
    <TableWrapper
      titleProps={{
        title: `ARPs (${totalCount})`,
      }}
      dataStatus={listStatus}
      tablePlaceholder={["ARPs", "ARP"]}
      isEmpty={list.length === 0}
    >
      <Table
        fetchAction={fetchWrapper}
        data={list}
        count={count}
        totalCount={totalCount}
        columns={arpsTableHeader()}
        header={TableHeader}
        gridColumnTemplate="repeat(5, 1fr)"
      />
    </TableWrapper>
  );
};

export default withContexts(ARPsTable, [ARPsContextContainer]);
