import { FC, useEffect } from "react";
import styles from "./TenantConfigAdd.module.scss";
import InfoBlock from "../../../pages/Systems/Provisioning/components/SystemConfig/components/InfoBlock";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import { validateTenant } from "../../../helpers/validators/TenantValidator";
import { cloneDeep } from "lodash";
import LeftBlock from "./components/LeftBlock";
import ContentBlock from "./components/ContentBlock";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";
import { useLocalTenantsContext } from "../../../pages/TenantsPage/TenantsContext";
import InfoTextRight from "./components/InfoTextRight";

type Props = {
  tenant: any;
  onClose: () => void;
};

const TenantConfigEdit: FC<Props> = ({ tenant, onClose }) => {
  const {
    updateTenant,
    updateServices,
    addStatus,
    resetStatus,
  } = useTenantContext();
  const { fetchData } = useLocalTenantsContext();
  const { fetchSdrScore, currSdrScore } = useTenantContext();

  useEffect(() => {
    fetchSdrScore(tenant.full_name);
  }, [tenant.full_name]);

  const editTenant = {
    ...cloneDeep(tenant),
    sdr_service_rate: currSdrScore,
  };

  const [fields, handleFieldChange] = useFormField<any>(editTenant);
  const [errors, validate] = useValidation<any>(validateTenant, [fields]);

  const handleClose = () => {
    onClose();
    resetStatus();
  };
  const handleEdit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const systemsToAdd = fields.systems.filter(
        (item: string) => !tenant.systems.includes(item)
      );
      const systemsToRemove = tenant.systems.filter(
        (item: string) => !fields.systems.includes(item)
      );
      const tenantFields = {
        full_name: fields.full_name,
        systems_to_add: systemsToAdd,
        systems_to_remove: systemsToRemove,
        is_firewall_service: fields.is_firewall_service,
        is_nat_service: fields.is_nat_service,
        is_ids_service: fields.is_ids_service,
        is_dns_service: fields.is_dns_service,
        is_ddos_service: fields.is_ddos_service,
        is_segments_service: fields.is_segments_service,
        is_remote_user_service: fields.is_remote_user_service || false,
        is_fqdn_service: fields.is_fqdn_service,
        is_load_balancing_service: fields.is_load_balancing_service,
        is_secure_web_gw_service: fields.is_secure_web_gw_service,
        is_mesh_enabled: fields.is_mesh_enabled,
        quota_package_name: fields.quota_package_name,
      };
      const tenantRes = await updateTenant(fields.name, tenantFields);
      if (fields.sdr_service_rate) {
        const params = [
          {
            type: "sdr",
            enable: true,
            data: {
              rate: fields.sdr_service_rate,
            },
          },
        ];
        await updateServices(fields.name, params);
      }
      if (!fields.is_sdr_service) {
        const params = [
          {
            type: "sdr",
            enable: false,
            data: {},
          },
        ];
        await updateServices(fields.name, params);
      }
      if (tenantRes) {
        fetchData();
        handleClose();
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <LeftBlock className={styles.basicAppearance} fields={fields} />
      <ContentBlock
        className={styles.basicAppearance}
        fields={fields}
        onChange={handleFieldChange}
        onClose={handleClose}
        onAction={handleEdit}
        actionStatus={addStatus}
        errors={errors}
        isEdit
      />
      <InfoBlock
        className={styles.basicAppearance}
        children={<InfoTextRight />}
      />
    </div>
  );
};
export default TenantConfigEdit;
