export type DonutChartColorsType = Array<string>;

export const overviewDonutChartColors: DonutChartColorsType = [
  "var(--orange)",
  "var(--lilac)",
  "var(--grey50)",
  "var(--pink)",
  "var(--button-primary)",
  "var(--grey-30)",
];

export const systemDonutChartColors: DonutChartColorsType = [
  "var(--pink-lighter-10)",
  "var(--lilac-lighter-10)",
  "var(--green-lighter-10)",
  "var(--orange)",
  "var(--grey-30)",
  "#FCC400",
];

export const systemDonutChartIconsColors: DonutChartColorsType = [
  "var(--pink-lighter-10)",
  "var(--lilac-lighter-10)",
  "var(--green-lighter-10)",
  "var(--orange)",
  "var(--grey-30)",
  "#FCC400",
];

export const systemTrafficColors: DonutChartColorsType = [
  "var(--pink-lighter-30)",
  "var(--lilac-lighter-30)",
];

export const TenantTrafficColors = ["#00E492", "var(--out-color)"];
export const SystemTrafficColors = ["var(--in-color)", "var(--out-color)"];
export const TENANT_CONNECTION_COLOR = ["#395DDF", "var(--orange)"];

export const LiveLineChartColors = [
  "var(--button-primary)",
  "#FF8C5B",
  "#3EC1D3",
  "#CB5BFF",
  "#25D2A9",
  "#FF6BDF",
  "#7E8DEF",
  "#FCC400",
  "var(--button-primary)",
  "var(--critical)",
  "#FF8C5B",
  "#3EC1D3",
  "#CB5BFF",
  "#25D2A9",
  "#FF6BDF",
  "#7E8DEF",
  "var(--critical)",
  "#FCC400",
];

export const ONE_MINUTE_MS = 60 * 1000;
export const ONE_HOUR_MS = 60 * 60 * 1000;
export const ONE_DAY_MS = 24 * 60 * 60 * 1000;

export const IP_PREFIX_PATTERN = "X.X.X.X/X";
export const IP_PATTERN = "X.X.X.X";
