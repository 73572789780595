import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function CustomIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="8"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 5"
      />
    </svg>
  );
}

export default CustomIcon;
