import { FC, useEffect } from "react";
import Input from "../../common/formComponents/Input";
import TextWithIcon from "../../common/table/TextWithIcon";
import DialogTemplateOneColumn from "../../dialogs/common/DialogTemplateOneColumn";
import ShieldFieldIcon from "../../icons/ShieldFillIcon";

import styles from "./PasswordChangeDialog.module.scss";
import { useUserContext } from "../../../contexts/UserContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useUserEditDialogContext } from "./UserEditDialogContext";
import { useValidation } from "../../../helpers/validators/Validator";
import validateUserPassword from "../../../helpers/validators/EditPasswordUserValidator";

type Props = {
  onCancel: () => void;
};

const DEFAULT_FIELDS: any = {
  newPassword: "",
  confirmPassword: "",
};

const PasswordChangeDialog: FC<Props> = ({ onCancel }) => {
  const {
    currUser,
    userStatus,
    editPasswordCurrentUser,
  } = useUserEditDialogContext();
  const { user } = useUserContext();
  const [fields, handleFieldChange, resetFields] = useFormField<any>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<any>(validateUserPassword, [fields]);
  useEffect(() => {
    if (currUser) {
      resetFields(currUser);
    }
  }, [currUser]);
  const submitForm = async () => {
    const { isOk } = validate();
    if (isOk && user) {
      const { newPassword } = fields;
      const res = await editPasswordCurrentUser(newPassword);
      if (res) {
        onCancel();
      }
    }
  };

  return (
    <DialogTemplateOneColumn
      header={
        <TextWithIcon
          icon={<ShieldFieldIcon width={16} height={18} />}
          text="Change Password"
        />
      }
      controls={{
        okText: "Save",
        onOk: submitForm,
        cancelText: "Cancel",
        onCancel,
      }}
      status={userStatus}
    >
      <div className={styles.form}>
        <Input
          name="newPassword"
          label="New Password"
          type="password"
          placeholder="Enter the new password"
          handleFieldChange={handleFieldChange}
          value={fields.newPassword}
          medium
          togglePasswordVisibility
        />
        <Input
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          placeholder="Confirn your password"
          handleFieldChange={handleFieldChange}
          value={fields.confirmPassword}
          medium
          togglePasswordVisibility
          error={errors?.["confirmPassword"]}
        />
      </div>
    </DialogTemplateOneColumn>
  );
};
export default PasswordChangeDialog;
