import React, { FC } from "react";
import DropDownInput from "../../../../components/common/formComponents/DropDownInput";
import { DH_TABLE } from "../../const";
import { ConfigurationKeys, useIPsecContext } from "../../IPsecContext";
import { Code } from "./Code";
import styles from "./RemoteGuide.module.scss";

export const parseEncryption = (
  type: "ike" | "esp" | "none",
  encryption: string,
  auth?: string,
  dh_groups?: Array<number>
) => {
  const dh = dh_groups?.reduce(
    (acc, cur) => acc + `${DH_TABLE[cur].type}${DH_TABLE[cur].key}!`,
    ""
  );

  if (type === "none") return dh;

  return type === "ike"
    ? `${type}=${encryption}-${auth}-${dh}`
    : `${type}=${encryption}-esn`;
};
export const RemoteGuide = () => {
  const { ipsecInterface } = useIPsecContext();

  const {
    auth,
    dead_peer_detection_timeout,
    dh_groups,
    encryption,
    ike_version,
    local_id_data,
    local_networks,
    name,
    psk,
    remote_id_data,
    remote_networks,
    gateway_ip,
  } = ipsecInterface;

  return (
    <div className={styles.guide}>
      <div className={styles.header}>
        Guide
        <div className={styles.dropdown}>
          <DropDownInput
            id={"remoteGuideStringSwan"}
            itemsList={[{ key: "StrongSwan" }]}
            onChange={() => undefined}
            selected={{ key: "StrongSwan" }}
            isMedium
          />
        </div>
      </div>
      <div className={styles.viewPort} id="guide">
        <SectionTitle>Enable routing on linux:</SectionTitle>
        <Code
          children={`sysctl -w net.ipv4.ip_forward=1
sysctl -w net.ipv6.conf.all.forwarding=1
sysctl -w net.ipv4.conf.all.accept_redirects=0
sysctl -w net.ipv4.conf.all.send_redirects=0
systemctl restart strongswan-starter.service
systemctl is-enabled strongswan.service`}
        />
        <SectionTitle>Add PSK to secrets configuration file:</SectionTitle>
        <Code
          children={`echo '${local_id_data} : PSK "${psk}"'  >> /etc/ipsec.secrets`}
        />
        <SectionTitle>Edit /etc/ipsec.conf and add this content:</SectionTitle>
        <Code
          children={`# ipsec.conf - strongSwan IPsec configuration file
# basic configuration
config setup
        # strictcrlpolicy=yes
        # uniqueids=no


# Add connections here.
conn ${name}
 mobike=no
  auto=add
  type=tunnel
  keyexchange=ikev${ike_version || 2}
  ${parseEncryption("ike", encryption, auth, dh_groups)}
  ${parseEncryption("esp", encryption)}
  keyingtries=%forever
  closeaction=restart
  dpdaction=restart
  dpddelay=90s
  dpdtimeout=${dead_peer_detection_timeout}s

 
# local:
  leftauth=psk
  leftid=${remote_id_data}
  leftsourceip=WANIP
  # Replace WANIP with output of:
  # ip route get ${gateway_ip} | grep -oP 'src \K\S+'
  leftsubnet=${remote_networks}
 
 
# remote: (insidepacket)
  right=${gateway_ip}
  rightid=${local_id_data}
  rightauth=psk
  rightsubnet=${local_networks}
`}
        />
        <SectionTitle>The start the connection with:</SectionTitle>
        <Code
          children={`ipsec restart
ipsec up ${name}
`}
        />
        <SectionTitle>The status can be shown:</SectionTitle>
        <Code children={`ipsec status`} />
      </div>
    </div>
  );
};

const SectionTitle: FC = ({ children }) => {
  return <div className={styles.title}>{children}</div>;
};
