import React, { FC } from "react";

type Props = { x: number; y: number; r: number; name: string };
const ConnectionName: FC<Props> = ({ x, y, r, name }) => {
  return (
    <text
      x={x}
      y={y + r + 12}
      textAnchor="middle"
      fill="var(--font)"
      fontSize={12}
      fontWeight={600}
    >
      {name}
    </text>
  );
};
export default ConnectionName;
