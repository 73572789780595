import { FC } from "react";
import styles from "./RouteFiltersConfig.module.scss";
import RouteMapInfo from "./components/RouteMapInfo";
import RouteMapFormContainer from "./components/RouteMapFormContainer";
import RouteMapTips from "./components/RouteMapTips";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { useHistory } from "react-router-dom";
import { UserRouteFiltersAdd } from "../../types";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { useRouteFiltersContext } from "../../RouteFiltersContext";

const DEFAULT_FIELDS = {
  name: "",
  description: "",
  pfx_list: [
    {
      ip: "",
      match: true,
      seq: 0,
      le: 32,
    },
  ],
};

const RouteFiltersConfigAdd: FC = () => {
  const historyReat = useHistory();
  const { add, addStatus } = useRouteFiltersContext();

  const [fields, handleFieldChange] = useFormField<UserRouteFiltersAdd>(
    DEFAULT_FIELDS
  );

  const onClose = () => {
    history.back();
  };

  const handleAdd = async () => {
    const tenant = localStorage.getItem("selectedTenant");
    if (!tenant) return;
    const res = await add(tenant, fields);
    if (res) onClose();
  };

  return (
    <div className={styles.wrapper}>
      <RouteMapInfo className={styles.basicBlock} name={fields.name} />
      <RouteMapFormContainer
        className={styles.basicBlock}
        onClose={onClose}
        fields={fields}
        onChange={handleFieldChange}
        action={handleAdd}
        status={addStatus}
      />
      <RouteMapTips className={styles.basicBlock} />
    </div>
  );
};
export default withLayout(RouteFiltersConfigAdd);
