import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";

function OuterFrameIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.05001 2H1C0.447715 2 0 2.44772 0 3V17C0 17.5523 0.447715 18 1 18H15C15.5523 18 16 17.5523 16 17L16 9.94999C15.073 9.76181 14.3322 9.06068 14.0869 8.15575L12.4749 9.76777C11.4986 10.7441 9.91565 10.7441 8.93934 9.76777L8.23223 9.06066C7.25592 8.08435 7.25592 6.50144 8.23223 5.52513L9.84425 3.91311C8.93932 3.66779 8.23819 2.92703 8.05001 2ZM16 3.41421L11.0618 8.35242L11.0607 8.35355C10.8654 8.54882 10.5488 8.54882 10.3536 8.35355L9.64645 7.64645C9.45118 7.45118 9.45166 7.13413 9.64692 6.93886L14.5858 2H15C15.5523 2 16 2.44772 16 3L16 3.41421Z"
        fill={color || "#6B55F0"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0.5C10 0.223858 10.2239 0 10.5 0H18V7.5C18 7.77614 17.7761 8 17.5 8H16.5C16.2239 8 16 7.77614 16 7.5V3.41421L11.0607 8.35355C10.8654 8.54881 10.5488 8.54882 10.3536 8.35355L9.64645 7.64645C9.45118 7.45118 9.45118 7.1346 9.64645 6.93934L14.5858 2H10.5C10.2239 2 10 1.77614 10 1.5V0.5Z"
        fill={color || "#6B55F0"}
      />
    </svg>
  );
}

export default OuterFrameIcon;
