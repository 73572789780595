import React, { FC } from "react";
import { GLOBAL_TENANT } from "../../contexts/UserListContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import { withLayout } from "../../helpers/hocs/withLayout";
import UserGroupsContextContainer from "./UserGroupsContext";
import { PathEntry } from "../../helpers/types";
import { TenantsTab } from "../../components/common/navigation/Tenants/TenantsTab";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import ContentUserGroup from "./components/UserGroupsConfig/components/ContentUserGroups";
export type Props = {
  customRoot?: Array<PathEntry>;
};
const UserGroupsPage: FC<Props> = ({ customRoot }) => {
  const { selectedTenant } = useGlobalFilterContext();

  return (
    <>
      <TenantsTab
        defaultTenants={[{ name: GLOBAL_TENANT }]}
        remoteUsersRoot={customRoot}
      />
      {!selectedTenant ? <PageLoader /> : <ContentUserGroup />}
    </>
  );
};

export default withContexts<Props>(withLayout(UserGroupsPage), [
  UserGroupsContextContainer,
]);
