import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import UserListContextContainer from "../../contexts/UserListContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import { withLayout } from "../../helpers/hocs/withLayout";
import RemoteUsersStatusContextContainer from "../../contexts/RemoteUsersStatusContext";
import { StatusPageTable } from "./StatusPageTable";
import TimerangOverlayComponent from "../../components/common/timerange/TimerangeOverlayComponent";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StatusPageTable.module.scss";
import ActiveTenantSelector from "../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import ArrowBack from "../../components/icons/ArrowBack";

const RemoteUserStatusPage: FC = () => {
  const history = useHistory();
  return (
    <>
      <div className={styles.headerWrapper}>
        <div
          className={styles.back}
          onClick={() => {
            history.push("users");
          }}
        >
          <ArrowBack />
          Back to users
        </div>
        <div className={styles.controlsWrapper}>
          <TimerangOverlayComponent
            id={"timerangeComp"}
            className={styles.timerangeWrapper}
          />
          <ActiveTenantSelector defaultTenants={[]} />
        </div>
      </div>
      <StatusPageTable />
    </>
  );
};

export default withContexts(withLayout<any>(RemoteUserStatusPage), [
  RemoteUsersStatusContextContainer,
  UserListContextContainer,
]);
