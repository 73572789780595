import { FC } from "react";
import Dots from "./Dots";
import PortBody from "./PortBody";
import { PortTypeScheme } from "../../../../types";

type Props = {
  x: number;
  y: number;
  showDots: boolean;
  type: number;
  portIdx: number;
  data: Array<PortTypeScheme>;
};

const PortGroup: FC<Props> = ({ x, y, showDots, type, portIdx, data }) => {
  return (
    <svg
      x={x}
      y={y}
      width="60"
      height="53"
      viewBox="0 0 60 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <PortBody x={0} y={0} type={type} portIdx={portIdx} data={data} />
      {showDots && <Dots x={4} y={37} />}
    </svg>
  );
};

export default PortGroup;
