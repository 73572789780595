import React, { PropsWithChildren } from "react";
import {
  NatRuleParams,
  NatRuleType,
  SingleRuleTrafficStatistics,
  SortType,
} from "../../helpers/api/apiTypes";
import { reportApi } from "../../helpers/api/ReportApi";
import { RequestStatus, TimerangeContextType } from "../../helpers/types";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { configApi } from "../../helpers/api/ConfigApi";
import getCount from "../../helpers/getCount";
import { BPS, PPS } from "../../helpers/common/constantsAlias";
import { createContextAndUseWithExtra } from "../common/AbstractCrudContext";
import {
  ITimeRangeContext,
  withTimerangeContextProps,
} from "../TimerangeContext";

type RequestStatuses = {
  natRulesStatus?: RequestStatus;
  natRulesStatisticsStatus?: RequestStatus;
  natRuleStatus?: RequestStatus;
  natRuleBPSStatStatus?: RequestStatus;
  natRulePPSStatStatus?: RequestStatus;
};

type IState = {
  natRulesList: Array<NatRuleType> | undefined;
  natRule: NatRuleType | undefined;
  isUpdateNatRulesList: boolean;
  natRulesBPSStatistics: Array<SingleRuleTrafficStatistics> | undefined;
  natRulesPPSStatistics: Array<SingleRuleTrafficStatistics> | undefined;
} & RequestStatuses;

type IFunc = {
  fetchNatRulesList: (tenant: string, sortBy?: SortType) => void;
  fetchNatRule: (tenant: string, ruleId: string) => void;
  addNatRule: (tenant: string, params: NatRuleParams) => Promise<boolean>;
  editNatRule: (
    tenant: string,
    ruleId: string,
    params: NatRuleType
  ) => Promise<boolean>;
  deleteNatRule: (tenant: string, ruleId: string) => Promise<boolean>;
  setIsUpdateNatRulesList: (arg: boolean) => void;
  resetStatus: () => void;
  fetchNatRulesBPSStatistics: (
    tenantName: string,
    from?: string,
    to?: string,
    clearData?: boolean
  ) => void;
  fetchNatRulesPPSStatistics: (
    tenantName: string,
    from?: string,
    to?: string,
    clearData?: boolean
  ) => void;
};

const [UserContext, useContext] = createContextAndUseWithExtra<
  IState,
  IFunc,
  TimerangeContextType
>();
export const useNatContext = useContext;

type Props = ITimeRangeContext;

class NatConfigurationContextContainer extends AbstractTimeoutHandler<
  IState,
  Props
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      natRulesList: undefined,
      natRule: undefined,
      isUpdateNatRulesList: false,
      natRulesBPSStatistics: undefined,
      natRulesPPSStatistics: undefined,
    };

    this.funcs = {
      fetchNatRulesList: this.fetchNatRulesList,
      fetchNatRule: this.fetchNatRule,
      addNatRule: this.addNatRule,
      editNatRule: this.editNatRule,
      deleteNatRule: this.deleteNatRule,
      setIsUpdateNatRulesList: this.setIsUpdateNatRulesList,
      resetStatus: this.resetStatus,
      fetchNatRulesBPSStatistics: this.fetchNatRulesBPSStatistics,
      fetchNatRulesPPSStatistics: this.fetchNatRulesPPSStatistics,
    };
  }

  fetchNatRulesList = async (
    tenant: string,
    sortBy?: SortType
  ): Promise<void> => {
    this.setState({ natRulesStatus: setPending("Fetching") });
    const res = await configApi.getNatRulesListFormatted(tenant, sortBy);
    this.setState({
      natRulesList: res.result?.filter((rule) => rule.rule_type !== "NOP"),
      natRulesStatus: resToState(res),
    });
  };

  fetchNatRule = async (tenant: string, rule_name: string): Promise<void> => {
    const res = await configApi.getNatRule(tenant, rule_name);
    this.setState({
      natRule: res.result,
      natRuleStatus: resToState(res),
    });
  };

  addNatRule = async (tenant: string, params: any): Promise<boolean> => {
    const res = await configApi.addNatRule(tenant, params);
    this.setState({
      isUpdateNatRulesList: res.ok,
      natRuleStatus: resToState(res),
    });
    return res.ok;
  };

  editNatRule = async (
    tenant: string,
    rule_name: string,
    params: any
  ): Promise<boolean> => {
    const res = await configApi.editNatRule(tenant, rule_name, params);
    this.setState({
      isUpdateNatRulesList: res.ok,
      natRuleStatus: resToState(res),
    });
    return res.ok;
  };

  deleteNatRule = async (
    tenant: string,
    rule_name: string
  ): Promise<boolean> => {
    const res = await configApi.deleteNatRule(tenant, rule_name);
    this.setState({
      isUpdateNatRulesList: res.ok,
    });
    return res.ok;
  };

  setIsUpdateNatRulesList = (): void => {
    this.setState({
      isUpdateNatRulesList: false,
    });
  };

  resetStatus = async (): Promise<void> => {
    this.setState({ natRuleStatus: undefined });
  };

  // Stats
  fetchNatRulesBPSStatistics = async (
    tenantName: string,
    from?: string,
    to?: string
  ): Promise<void> => {
    // this.setState({ natRuleBPSStatStatus: setPending("Fetching") });
    const count = getCount(from, to);
    const res = await reportApi.getNatRulesTraffic(
      tenantName,
      BPS,
      from,
      to,
      count
    );
    this.setState({ natRuleBPSStatStatus: resToState(res) });
    if (res.ok && res.result?.items) {
      this.setState({
        natRulesBPSStatistics: res?.result?.items,
      });
    }
  };

  fetchNatRulesPPSStatistics = async (
    tenantName: string,
    from?: string,
    to?: string
  ): Promise<void> => {
    // this.setState({ natRuleTrafficStatStatus: setPending("Fetching") });
    const count = getCount(from, to);
    const res = await reportApi.getNatRulesTraffic(
      tenantName,
      PPS,
      from,
      to,
      count
    );
    this.setState({ natRulePPSStatStatus: resToState(res) });
    if (res.ok && res.result?.items) {
      this.setState({
        natRulesPPSStatistics: res?.result?.items,
      });
    }
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default withTimerangeContextProps<PropsWithChildren<any>>(
  NatConfigurationContextContainer
);
