import { FWRuleType } from "../../../helpers/api/apiTypes";
import { getProtocolByValue } from "../../../helpers/getProtocolByValue";
import { SegmentRule } from "../components/SegmentRulesTable/types";
import { NETWORK_APP_SEPARATOR, RULE_BODY } from "./consts";

export const createFWRule = (
  segmentRule: SegmentRule,
  srcSegment: string,
  dstSegment: string
): Partial<FWRuleType> => {
  const { name, description, priority, ip_protocol, network_app } = segmentRule;

  const networkAppValues = network_app.split(NETWORK_APP_SEPARATOR);

  const combinedName = `${srcSegment}_${dstSegment}_${networkAppValues.join(
    ""
  )}`;
  const protocols = getProtocolByValue(ip_protocol);

  const filters = [
    ...protocols,
    { filter_type: "src_segment", values: srcSegment },
    { filter_type: "dst_segment", values: dstSegment },
    { filter_type: "dst_l4_port", values: +networkAppValues[1] },
  ];

  return {
    ...RULE_BODY,
    name: name || combinedName,
    filters,
    priority,
    description,
  };
};
