import React from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { Checkbox } from "../../../../../components/common/formComponents/Checkbox";
import LoaderIcon from "../../../../../components/common/loadStates/LoaderIcon";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { useVPCContext } from "../../../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/common/VPCList/VPCContext";
import { useConnectionConfiguratorContext } from "../ConnectionConfiguratorContext";

import styles from "./CloudVpcSelector.module.scss";

const CloudVpcSelector = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();
  const { list = [], listStatus } = useVPCContext();

  if (listStatus.state === "pending") {
    return <LoaderIcon />;
  }

  return (
    <>
      {list.length > 0 ? (
        <div className={styles.row}>
          <DropdownBasic
            id={"vpc_id"}
            onChange={(val) => {
              fieldsOnChange("vpc_id", val.key);
            }}
            selected={mapStringToItem(fields.vpc_id)}
            label="VPC"
            itemsList={list.map(mapStringToItem)}
            isMedium
            error={""}
          />
          <Checkbox
            isChecked={true}
            onChange={() => undefined}
            value={""}
            label={"Add static route automatically"}
            containerClassName={styles.checkbox}
          />
        </div>
      ) : (
        <span className={styles.errorMsg}>
          Incorrect key or no VPCs found. Try again with a different Cloud
          region or key or configure IPSec manually by switching to "Advanced"
          mode.
        </span>
      )}
    </>
  );
};

export default CloudVpcSelector;
