import { FC } from "react";
import DropdownBasic from "../../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../../components/common/formComponents/Input";
import { mapStringToItem } from "../../../../../../../../helpers/mapStringToItem";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import EndpointDropdown from "../../../../common/formComponents/EndpointDropdown";
import { PHYSICAL_PORT_IDS } from "../../../../consts";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./CustomerLocationItem.module.scss";
import IPAddress from "../../../../common/formComponents/IPAddress";
import TaggedRadioGroup from "../../../../common/formComponents/TaggedRadioGroup";
import RadioGroup from "../../../../../../../../components/common/formComponents/RadioGroup";
import { getConnectionType } from "../../../../helpers/getConnectionType";
import { useUserContext } from "../../../../../../../../contexts/UserContext";
import { useConnectionConfiguratorContext } from "../../../../ConnectionConfiguratorContext";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const CustomerLocationItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
}) => {
  const { portType } = useConnectionConfiguratorContext();
  const { isDemo } = useUserContext();
  const isIpsec = fields.Connection_type === "ipsec";
  return (
    <ItemContainer
      type={type}
      title={fields?.location?.location_name || "Select Location"}
      idx={idx}
      onDelete={onDelete}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      {isDemo && portType.key === "cloudRouter" && (
        <RadioGroup
          label="Connection Type"
          isOneRow
          options={getConnectionType(isDemo)}
          value={fields.Connection_type}
          setActiveValue={(val) => onChange("Connection_type", val)}
          isNotEditable={isEdit}
        />
      )}
      {isIpsec ? (
        <>
          <>
            <BWDropdown
              field={fields["BW"]}
              onChange={handleChangeBW}
              error={errors?.[idx + "BW"]}
              idx={idx}
            />
            <Input
              label="Peer IP"
              name="peer_ip"
              placeholder="0.0.0.0/0"
              value={fields.peer_ip}
              medium
              onChange={(e) => onChange("peer_ip", e.target.value)}
              isNotEditable={isEdit}
              error={errors?.[idx + "peer_ip"]}
            />
            <Input
              label="Generated IPSEC connection details"
              name="psk"
              placeholder="PSK"
              value={fields.psk}
              medium
              onChange={(e) => onChange("psk", e.target.value)}
              isNotEditable={isEdit}
              error={errors?.[idx + "psk"]}
              isPlaceholderAlwaysVisible
            />
          </>
        </>
      ) : (
        <>
          <EndpointDropdown
            field={fields.location}
            onChange={onChange}
            type={type}
            isNotEditable={isEdit}
            error={errors?.[idx + "location"]}
          />
          <BWDropdown
            field={fields.BW}
            onChange={handleChangeBW}
            error={errors?.[idx + "BW"]}
            idx={idx}
          />
          <DropdownBasic
            id={"Phisical_Port"}
            onChange={(val) => {
              onChange("Phisical_Port", val.key);
            }}
            placeholder={"Select Physical Port #"}
            selected={mapStringToItem(fields.Phisical_Port)}
            label="Physical Port #"
            itemsList={PHYSICAL_PORT_IDS.map(mapStringToItem)}
            isMedium
            error={errors?.[idx + "Phisical_Port"]}
            isNotEditable={isEdit}
          />
          <TaggedRadioGroup
            field={fields.Tagged}
            onChange={onChange}
            isNotEditable={isEdit}
          />
          {fields.Tagged && (
            <Input
              label="VLAN ID"
              name="VLAN_ID"
              placeholder=""
              value={fields.VLAN_ID}
              medium
              onChange={(e) => onChange("VLAN_ID", e.target.value)}
              isNotEditable={isEdit}
              error={errors?.[idx + "VLAN_ID"]}
            />
          )}
          <IPAddress
            field={fields.ip_addresses}
            onChange={onChange}
            error={errors?.[idx + "ip_addresses"]}
          />
        </>
      )}
    </ItemContainer>
  );
};

export default CustomerLocationItem;
