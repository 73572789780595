import { PortParsedName, PortType, PortTypeScheme } from "../types";

export const getPortsSchemeData = (
  list: Array<PortType>
): Array<PortTypeScheme> => {
  return list.map((port) => ({
    ...port,
    parsedName: parsePortName(port.name),
  }));
};

const parsePortName = (name: string): PortParsedName => {
  let type, portIdx, subPort;

  if (name?.includes("/")) {
    const nameArr = name.split("/");
    type = nameArr[0].slice(-1);
    portIdx = nameArr[1];
    subPort = nameArr[2];
  } else {
    type = name?.slice(0, -1);
    portIdx = name?.slice(-1);
  }
  return { type, portIdx, subPort };
};
