import { PortVlanType } from "./../api/apiTypes";
import Validator from "./Validator";

export default function validateVLAN(
  data: Partial<any>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.vlan_id, "vlan_id");
  validator.checkEmpty(data.physical_port_name, "physical_port_name");
  return validator;
}
