import { SystemAPI, SystemNode } from "../pages/Systems/Provisioning/types";

export const getNodeFromSystem = (
  system?: SystemAPI
): SystemNode | undefined => {
  if (!system) return;
  const nodes = [...(system.nodes || [])];
  const activeNode = system.active_node;
  return nodes.find((node) => node.name === activeNode);
};

export const isActiveNode = (
  nodeName?: string,
  systems?: SystemAPI
): boolean => {
  if (!nodeName || !systems) return false;
  return (systems as SystemAPI).active_node === nodeName;
};

export const checkIsActive = (
  node?: SystemNode,
  systems?: Array<SystemAPI>
): boolean => {
  if (!node || !systems) return false;
  const system = systems.find((sys) => sys.name === node.ha_domain_name);
  return system?.active_node === node.name;
};
