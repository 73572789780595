import React from "react";
import Input from "../../../../../../components/common/formComponents/Input";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";

const RoutingRemoteLink = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  if (fields.connection_interface === "Equinix Fabric") {
    return (
      <Input
        medium
        label="Remote Link IP"
        value={fields.remote_link_ip}
        onChange={(e) => fieldsOnChange("remote_link_ip", e.target.value)}
      />
    );
  }
  return (
    <Input
      medium
      label="Remote AS"
      value={fields.remote_as}
      onChange={(e) => fieldsOnChange("remote_as", e.target.value)}
    />
  );
};

export default RoutingRemoteLink;
