import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { commonTableColumns } from "../table/commonTableColumns";
import { groupTableStyles } from "../helpers/consts";
import { formPathFromArray } from "../../../helpers/navigation";
import {
  APPLICATIONS,
  DNS,
  INSIGHTS,
} from "../../../helpers/navigation/entries";

const DNSDomainsTable: FC = () => {
  const { data, statuses } = useSearchContext();
  return (
    <ResultTable
      title="DNS Domains"
      data={data?.dnsDomains}
      columns={commonTableColumns("Domain")}
      status={statuses?.dnsDomainsStatus}
      style={groupTableStyles}
      goto={formPathFromArray([INSIGHTS(), APPLICATIONS(), DNS()])}
    />
  );
};
export default DNSDomainsTable;
