/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { FilterAndSort, VRFType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUse,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";

type IState = CrudState<VRFType, VRFType>;

type IFunc = {};

const [Context, useContext] = createContextAndUse<
  IState,
  IFunc & CrudFunc<VRFType, string>
>();

export const useVRFContext = useContext;

type Props = React.PropsWithChildren<{ additionParameter: string | number }>;

export default class VRFContextContainer extends AbstractCrudContextContainer<
  VRFType,
  VRFType,
  IState,
  string
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);

    this.funcs = {};
  }

  listReq = async (params?: FilterAndSort) => {
    return configApi.getVRFList(this.props.additionParameter, params?.sort);
  };

  selectedReq = async (vrfName: string | number) => {
    return await configApi.getVRF(
      this.props.additionParameter,
      vrfName as string
    );
  };

  addReq = async (params: Partial<VRFType>) => {
    return await configApi.addVRF(
      this.props.additionParameter,
      params as Partial<VRFType>
    );
  };

  editReq = async (params: Partial<VRFType>, vrfName: string | number) => {
    return await configApi.editVRF(
      this.props.additionParameter,
      vrfName as string,
      params
    );
  };

  removeReq = async (vrfName: string | number) => {
    return await configApi.deleteVRF(
      this.props.additionParameter,
      vrfName as string
    );
  };

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, ...this.funcs, ...this.baseFuncs }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
