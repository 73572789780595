import { FC } from "react";
import Label from "../../../../../components/common/table/Label";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./FilterTypeLabel.module.scss";
import { FilterType } from "../../../types";

type Props = { type: FilterType };

const FilterTypeLabel: FC<Props> = ({ type }) => {
  return <Label className={styles[type]}>{type}</Label>;
};

export default FilterTypeLabel;
