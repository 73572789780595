import React, { useCallback, useState } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./TrafficStatisticsPage.module.scss";
import { PageProps, TrafficType } from "../../../helpers/types";
import { withLayout } from "../../../helpers/hocs/withLayout";
import CCNIcon from "../../icons/CCNIcon";
import CPSIcon from "../../icons/CPSIcon";
import { OneSystemTabs } from "../../common/navigation/OneSystemTabs";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import ChartToppersTabs from "../../common/ChartToppersTabs";
import GrafanaGraph from "../../common/charts/GrafanaGraph";
import grafanaLinksFactory, {
  GRAFANA_ROOT_PATH,
  LinkFn,
} from "../../common/charts/GraphanaLinksFactory";
import ChartsHeader from "../../common/charts/ChartsHeader/ChartsHeader";
import { classNames } from "../../../helpers/common/classNames";
import { Link } from "react-router-dom";
import Button from "../../common/buttons/Button";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { useTimerangeContext } from "../../../contexts/TimerangeContext";
import { IS_DEMO } from "../../../globalFlags";

const TrafficStatisticsPage: React.FC = () => {
  const { system } = useOneSystemContext();
  const { timeRange } = useTimerangeContext();
  const [activeTraffic, setActiveTraffic] = useState<TrafficType>("bps");

  const linkWithData = useCallback(
    (func: LinkFn) => {
      const data = {
        timeRange: timeRange.general,
        system: system?.name,
        systemIp: system?.address,
      };
      return func(data);
    },
    [timeRange, system]
  );

  return (
    <>
      <OneSystemLayout>
        <div className={styles.contentWrapper}>
          <OneSystemTabs hideVrf showTimeRange />
          <div className={styles.chartsWrapper}>
            <div className={styles.topPart}>
              <div
                className={classNames(styles.graphWrap, styles.innerLeftPart)}
              >
                <ChartToppersTabs
                  activeTab={activeTraffic}
                  setTabActive={setActiveTraffic}
                />
                <GrafanaGraph
                  link={linkWithData(
                    activeTraffic === "bps"
                      ? grafanaLinksFactory.system_bps(timeRange.general)
                      : grafanaLinksFactory.system_pps(timeRange.general)
                  )}
                />
              </div>
              <div className={styles.innerRightPart}>
                <div className={styles.graphWrap}>
                  <ChartsHeader title={"Incoming traffic per tenant"} />
                  <GrafanaGraph
                    link={linkWithData(
                      grafanaLinksFactory.system_per_tenant_in
                    )}
                  />
                </div>
                <div className={styles.graphWrap}>
                  <ChartsHeader title={"Outgoing traffic per tenant"} />
                  <GrafanaGraph
                    link={linkWithData(
                      grafanaLinksFactory.system_per_tenant_out
                    )}
                  />
                </div>
              </div>
            </div>
            <div className={styles.btmPart}>
              <div className={styles.btmTop}>
                <div className={styles.percentageBox}>
                  <span>
                    <CCNIcon /> Concurrent connections
                  </span>
                  <GrafanaGraph
                    link={linkWithData(grafanaLinksFactory.system_ccn_round)}
                  />
                </div>
                <div className={styles.percentageBox}>
                  <span>
                    <CPSIcon /> Connection per second
                  </span>
                  <GrafanaGraph
                    link={linkWithData(grafanaLinksFactory.system_cps_round)}
                  />
                </div>
              </div>
              <div className={styles.bottomStats}>
                {IS_DEMO ? (
                  <img
                    src="/mock_stats.png"
                    className={styles.bottomStatsImage}
                  />
                ) : (
                  <>
                    <GrafanaGraph
                      link={linkWithData(grafanaLinksFactory.system_cpu)}
                    />
                    <GrafanaGraph
                      link={linkWithData(grafanaLinksFactory.system_fs)}
                    />
                    <GrafanaGraph
                      link={linkWithData(grafanaLinksFactory.system_ram)}
                    />
                  </>
                )}
              </div>
            </div>
            <div className={styles.footer}>
              <Link
                to={
                  GRAFANA_ROOT_PATH +
                  "/d/sS6Rmg37z/nsos-system-health?orgId=1&refresh=1m"
                }
                target={"_blank"}
                className={styles.linkBtn}
              >
                <Button onClick={() => undefined} isPrimaryBtn>
                  Advanced Health
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </OneSystemLayout>
    </>
  );
};

export default withLayout<PageProps>(withContexts(TrafficStatisticsPage, []));
