import React from "react";
import { IconProps } from "../../../helpers/types";
import { formIconAttrs } from "../../../helpers/iconsHelpers";

function AzureIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      {...formIconAttrs(32, 32, props)}
    >
      <path
        fill="#59536B"
        d="M14.907 23.651c3.029-.535 5.53-.978 5.56-.984l.052-.01-2.86-3.402a483.656 483.656 0 01-2.86-3.418c0-.017 2.953-8.148 2.97-8.177.005-.01 2.015 3.46 4.87 8.41l4.9 8.491.037.065h-9.088L9.4 24.623l5.507-.973zM4 22.614c0-.005 1.347-2.344 2.994-5.198l2.995-5.19 3.49-2.928c1.919-1.61 3.494-2.93 3.5-2.933a.607.607 0 01-.056.142l-3.789 8.127-3.722 7.982-2.706.003A210.7 210.7 0 014 22.614z"
      ></path>
    </svg>
  );
}

export default AzureIcon;
