import { FC } from "react";
import { RequestStatus } from "../../../../../helpers/types";
import DialogBtmButtons from "../../../../../components/dialogs/common/DialogBtmButtons";
import { Toggle } from "../../../../../components/common/Toggle";
import styles from "./BGPConfigFooter.module.scss";

type Props = {
  isAddMode: boolean;
  onAdd: () => void;
  onClose: () => void;
  className: string;
  status: RequestStatus | undefined;
  isAdvanced: boolean;
  onToggleAdvanced?: () => void;
  disabledSubmit?: boolean;
};
const BGPConfigFooter: FC<Props> = ({
  isAddMode,
  onAdd,
  onClose,
  className,
  status,
  isAdvanced,
  onToggleAdvanced = () => undefined,
  disabledSubmit,
}) => {
  return (
    <DialogBtmButtons
      errorDisplay={status}
      className={className}
      backButton={
        <div className={styles.backBtn}>
          <Toggle
            id={"advancedMode"}
            isChecked={isAdvanced}
            onChange={onToggleAdvanced}
            label={"Advanced"}
            // disabled={!isAddMode}
          />
        </div>
      }
      controls={{
        okText: isAddMode ? "Add" : "Save",
        onOk: onAdd,
        cancelText: "Cancel",
        onCancel: onClose,
        disableSubmit: disabledSubmit,
      }}
    />
  );
};

export default BGPConfigFooter;
