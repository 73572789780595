import React from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

function WizardsIcon({ color }: IconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5433 4.02453C13.7195 3.62928 14.2805 3.62929 14.4567 4.02453L16.9214 9.55398C16.9941 9.717 17.1482 9.82892 17.3256 9.84765L23.3461 10.4831C23.7765 10.5285 23.9498 11.062 23.6284 11.3517L19.1312 15.4046C18.9986 15.524 18.9398 15.7051 18.9768 15.8797L20.2329 21.8019C20.3227 22.2252 19.8688 22.555 19.494 22.3388L14.2498 19.3141C14.0952 19.2249 13.9048 19.2249 13.7502 19.3141L8.50603 22.3388C8.13118 22.555 7.67731 22.2252 7.7671 21.8019L9.0232 15.8797C9.06023 15.7051 9.00139 15.524 8.86881 15.4046L4.37164 11.3517C4.05018 11.062 4.22355 10.5285 4.65389 10.4831L10.6744 9.84765C10.8518 9.82892 11.0059 9.717 11.0786 9.55398L13.5433 4.02453Z"
        fill={color || FONT}
      />
      <path
        d="M5.06807 5.19642C4.83451 5.01052 4.81481 4.66273 5.02589 4.45165L5.67428 3.80326C5.88535 3.59218 6.23315 3.61188 6.41905 3.84544L8.60108 6.58697C8.75944 6.78594 8.74323 7.07208 8.56342 7.25189L8.47452 7.34079C8.29471 7.52061 8.00856 7.53681 7.8096 7.37845L5.06807 5.19642Z"
        fill={color || FONT}
      />
      <path
        d="M22.6517 5.19642C22.8852 5.01052 22.9049 4.66273 22.6938 4.45165L22.0454 3.80326C21.8344 3.59218 21.4866 3.61188 21.3007 3.84544L19.1186 6.58697C18.9603 6.78594 18.9765 7.07208 19.1563 7.25189L19.2452 7.34079C19.425 7.52061 19.7112 7.53681 19.9101 7.37845L22.6517 5.19642Z"
        fill={color || FONT}
      />
      <path
        d="M13.0447 25.26C13.011 25.5565 13.243 25.8164 13.5415 25.8164H14.4584C14.757 25.8164 14.989 25.5565 14.9552 25.26L14.5596 21.7785C14.5309 21.5258 14.3171 21.3349 14.0628 21.3349H13.9371C13.6828 21.3349 13.469 21.5258 13.4403 21.7785L13.0447 25.26Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default WizardsIcon;
