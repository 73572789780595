import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { WHITE } from "../../helpers/common/colorAliases";

function CheckboxIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.628 11.177 4.948 8.6 4 9.51 7.628 13 15 5.911 14.052 5l-6.424 6.177Z"
        fill={color || WHITE}
      />
    </svg>
  );
}

export default CheckboxIcon;
