import React, { ReactElement } from "react";
import { FONT, WHITE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CloseIcon({
  color,
  className,
  onClick,
  height = 12,
  width = 12,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.063.937.938 2.062l3.937 3.937L.937 9.937l1.125 1.125 3.939-3.938 3.938 3.938 1.125-1.125L7.126 6l3.937-3.937L9.938.937 6 4.874 2.063.937Z"
          fill={color || FONT}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={WHITE} d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CloseIcon;
