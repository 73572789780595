export type Connection = {
  name: {
    label: string;
    type: ConnectionLabelType;
  };
  type: ConnectionType;
  bandwidth: string;
  ip_address: string;
  segments: Array<string>;
  state: {
    admin: "up";
    operation: "down";
  };
  description: string;
};

export type VirtualInterfaceRow = {
  name: string;
  ip_addresses: Array<string>;
  type: string;
  is_ipsec_service?: boolean;
  is_nat_service?: boolean;
  is_snat_service?: boolean;
  labels?: { remote_type: ConnectionLabelType };
  state: { administrative_state: string; operational_state: string };
  description: string;
  network_segments: Array<string>;
  system_name: string;
  virtual_interface_type: string;
};

export type ConnectionLabelType =
  | "router"
  | "protected"
  | "internet"
  | "aws"
  | "gcp"
  | "branch"
  | "oracle"
  | "alibaba"
  | "ipsec"
  | "bareMetal"
  | "data_center"
  | "remote"
  | "remote_users"
  | "cloud"
  | "azure";

export enum ConnectionType {
  DIRECT_CONNECT = "Direct Connect",
  IPSEC = "IPSec",
  GATEWAY = "Gateway",
}

export type ConnectionBandwidthUnit = "G" | "M";

export type ConnectionRow = {
  name: {
    label: string;
    region: ConnectionRegion;
  };
  subRows: Array<VirtualInterfaceRow>;
};

export enum ConnectionRegion {
  US = "us",
  RU = "ru",
}

export type StateStatus = "up" | "down" | null;
