import { FC } from "react";
import {
  ChangeField,
  FieldsType,
} from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import Section from "../../../../../../../components/common/Section";
import Input from "../../../../../../../components/common/formComponents/Input";

type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  isEdit?: boolean;
};

const GeneralSection: FC<Props> = ({ className, fields, onChange }) => {
  return (
    <Section title={"General"} className={className} columnsCount={2}>
      <Input
        label="Name"
        name="name"
        value={fields.name}
        onChange={(e) => {
          onChange("name", e.target.value);
        }}
        medium
        error={""}
      />
      <Input
        label="Description"
        name="description"
        value={fields.description}
        onChange={(e) => {
          onChange("description", e.target.value);
        }}
        medium
        error={""}
        isOptional
      />
    </Section>
  );
};
export default GeneralSection;
