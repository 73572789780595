import Api from "./Api";
import { ARUBA_BROKER_API } from "./apiPaths";

type Res<P> = {
  ok: boolean;
  error?: string;
  result?: P;
  count?: number;
  totalCount?: number;
};

const eightDays = 8 * 24 * 60 * 60;

export class ArubaApi extends Api {
  constructor() {
    super();
  }

  addTimeToQuery(query: Record<string, string>): Record<string, string> {
    const now = Math.floor(Date.now() / 1000);
    query.startTime = (now - eightDays).toString();
    query.endTime = now.toString();
    return query;
  }

  getTenant(): string {
    return localStorage.getItem("selectedTenant") || "";
  }

  getAppliances(): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.APPLIANCE(tenant));
  }

  getGroups(): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.GROUP(tenant));
  }

  getMap(): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.MAP(tenant));
  }

  getApplication(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.APPLICATION(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getBandwidth(
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    const query: Record<string, string> = {
      granularity: "day",
    };
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.BANDWIDTH(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getDNS(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.DNS(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getFlow(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.FLOW(tenant), {
      query: query,
      body: JSON.stringify(payload),
    });
  }

  getHealth(
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.HEALTH(tenant), {
      query: this.addTimeToQuery({}),
      body: JSON.stringify(payload),
    });
  }

  getHealthLoss(query: Record<string, string>): Promise<Res<any>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.HEALTH_LOSS(tenant), {
      query,
    });
  }

  getHealthLatency(query: Record<string, string>): Promise<Res<any>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(
      ARUBA_BROKER_API.HEALTH_LATENCY(tenant),
      { query }
    );
  }

  getHealthJitter(query: Record<string, string>): Promise<Res<any>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(
      ARUBA_BROKER_API.HEALTH_JITTER(tenant),
      { query }
    );
  }

  getHealthMos(query: Record<string, string>): Promise<Res<any>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.HEALTH_MOS(tenant), {
      query,
    });
  }

  getTunnel(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.TUNNEL(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getPorts(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.PORTS(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getTopTalkers(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.TOP_TALKERS(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getUsers(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.USER(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getInterface(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(ARUBA_BROKER_API.INTERFACE(tenant), {
      query: this.addTimeToQuery(query),
      body: JSON.stringify(payload),
    });
  }

  getInterfaceOverlay(
    query: Record<string, string>,
    payload: Record<string, any> | Array<any>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(
      ARUBA_BROKER_API.INTERFACE_OVERLAY(tenant),
      {
        query: this.addTimeToQuery(query),
        body: JSON.stringify(payload),
      }
    );
  }

  getInterfaceTimeSeries(
    query: Record<string, string>
  ): Promise<Res<Array<any>>> {
    const tenant = this.getTenant();
    return this.fetchBroker<Array<any>>(
      ARUBA_BROKER_API.INTERFACE_TIME_SERIES(tenant),
      { query }
    );
  }
}

export const arubaApi = new ArubaApi();
