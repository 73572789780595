import { FC } from "react";
import styles from "./LogsHeader.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import BlankButton from "../../../common/buttons/BlankButton";
import IconBlankButton from "../../../common/buttons/IconBlankButton";
import CaretUp from "../../../icons/CaretUp";
import TimerangOverlayComponent from "../../../common/timerange/TimerangeOverlayComponent";
import { useHAHealthContext } from "../../../../contexts/systemsContext/HAHealthContext";

type Props = {
  title: string;
  isCollapsible: boolean;
  toggleCollapse: () => void;
  isCollapsed: boolean;
  className?: string;
};

const LogsHeader: FC<Props> = ({
  title,
  isCollapsible,
  toggleCollapse,
  isCollapsed,
  className,
}) => {
  const { logsArray } = useHAHealthContext();

  const handleShowAll = () => {
    console.log("click!");
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        isCollapsed && styles.fullBorder
      )}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.tableControls}>
        {isCollapsible && (
          <div className={styles.amountLabel}>{logsArray?.length || 0}</div>
        )}
        {isCollapsible ? (
          <>
            {
              <BlankButton
                className={styles.showAllBtn}
                id={"logsShowAll"}
                onClick={handleShowAll}
              >
                Show All
              </BlankButton>
            }
            <IconBlankButton
              id={"logsCollapse"}
              onClick={toggleCollapse}
              className={classNames(
                styles.collapseBtn,
                !isCollapsed && styles.rotate
              )}
              icon={CaretUp}
            />
          </>
        ) : (
          <>
            {/* <EventsSizeDropdown
              selected={size}
              onChange={setSize}
              label="Query size"
            /> */}
            <TimerangOverlayComponent
              id={"logsTimerange"}
              className={styles.timerangeWrapper}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default LogsHeader;
