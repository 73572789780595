import Validator from "./Validator";

export type CreateNatConfig = {
  inside: string;
  inside_type: string;
  name: string;
  outside: string;
  rule_type: string;
};

export function validateNATConfig(
  data: Partial<CreateNatConfig>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkEmpty(data.outside, "outside");
  validator.checkEmpty(data.rule_type, "rule_type");
  data.inside && validator.checkIPorIPWithPrefix(data.inside, "inside");

  return validator;
}
