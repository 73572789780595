import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function SearchIconSmall({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="8.5" cy="8.5" r="7" stroke="#6B55F0" strokeWidth="3" />
      <path d="M13.5 13.5L18.5 18.5" stroke="#6B55F0" strokeWidth="3" />
    </svg>
  );
}

export default SearchIconSmall;
