import { FC } from "react";
import CaretBig from "../../../../components/icons/CaretBig";
import { ACCENT, BORDER_COLOR } from "../../../../helpers/common/colorAliases";
import BlankButton from "../../../../components/common/buttons/BlankButton";
import styles from "./SelectRowComponent.module.scss";

type Props = {
  onSelect: (row: string) => void;
  selected?: string;
  currentRow: string;
  withPropaganation?: boolean;
};

const SelectRow: FC<Props> = ({
  onSelect,
  selected,
  currentRow,
  withPropaganation,
}) => {
  const isActive = selected === currentRow;
  const isAll = currentRow === "all";
  return (
    <BlankButton
      id={"selected" + currentRow}
      onClick={(e) => {
        !withPropaganation && e.stopPropagation();
        onSelect(currentRow);
      }}
      className={styles.wrapper}
    >
      {isAll && <span>All</span>}
      <CaretBig
        color={isActive ? ACCENT : BORDER_COLOR}
        className={styles.icon}
      />
    </BlankButton>
  );
};

export default SelectRow;
