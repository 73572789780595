import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./GrayString.module.scss";

type GrayStringProps = {
  text: string | number;
  title?: string;
  className?: string;
  isSmall?: boolean;
};

const GrayString: FC<GrayStringProps> = ({
  text,
  title,
  className,
  isSmall,
}) => {
  const textStyles = classNames(
    styles.text,
    className,
    isSmall && styles.smallFont
  );
  return (
    <div className={textStyles} title={title}>
      {text || ""}
    </div>
  );
};

export default GrayString;
