import { FC, useState } from "react";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import CaretDown from "../../../components/icons/CaretDown";
import { classNames } from "../../../helpers/common/classNames";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { Layer3InterfaceNew } from "../../Layer3Interface/types";
import styles from "./CloudComponent.module.scss";
import CloudDetails from "./CloudDetails";
import CloudTitle from "./CloudTitle";

type Props = {
  vpc: Array<Layer3InterfaceNew>;
  type: string;
  disabled: boolean;
};

const CloudComponent: FC<Props> = ({ vpc, type, disabled }) => {
  const [showDetails, hideDetails] = useState<boolean>(false);
  return (
    <div className={classNames(styles.wrapper, disabled && styles.disabled)}>
      <div
        className={classNames(styles.header, styles.clickable)}
        onClick={() => hideDetails((prev) => !prev)}
      >
        <CloudTitle
          className={styles.cloudTitle}
          title={type}
          count={vpc.length}
          withDetails={showDetails}
        />
        <IconBlankButton
          id={"cloudComponentCaretDown"}
          icon={CaretDown}
          className={styles.icon}
          color={GREY_FONT}
        />
      </div>
      <div
        className={classNames(
          styles.detailsBlock,
          showDetails && styles.showBlock
        )}
      >
        <CloudDetails vpc={vpc} type={type} />
      </div>
    </div>
  );
};
export default CloudComponent;
