import { FWFilters, NatOutsideType } from "./api/apiTypes";
import {
  PROTOCOLS_CODES_MAP,
  PROTOCOL_ANY,
  PROTOCOL_TCP_UDP,
} from "./common/constantsAlias";

export const getFiltersFieldValue = (
  name: string,
  filters?: Array<FWFilters>
): string => {
  if (!filters) return "";
  return (
    filters.find((item) => item.filter_type === name)?.values.toString() || ""
  );
};

export const getNatOutsideValues = (
  fields: Array<NatOutsideType>,
  field: string
): string => {
  return (
    fields.find((filter) => filter.outside_type === field)?.value.toString() ||
    ""
  );
};

export const getProtocolType = (filters?: Array<FWFilters>): string => {
  if (!filters) return "";
  const protocols = filters.filter(
    (filter) => filter.filter_type === "ip_protocol"
  );

  if (!protocols) return PROTOCOL_ANY;
  if (protocols.length === 2) return PROTOCOL_TCP_UDP;

  const protocol = protocols[0]?.values;
  let type = PROTOCOL_ANY;
  PROTOCOLS_CODES_MAP.map((item) => {
    if (item.code.toString() === protocol?.toString()) {
      type = item.type;
    }
  });

  return type;
};
