import { FC, ReactElement, useMemo } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import BasicConnection from "./BasicConnection/BasicConnection";
import FormFooter from "./FormFooter/FormFooter";
import styles from "./ConnectionFormContainer.module.scss";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../../helpers/navigation";
import {
  CONNECTIONS,
  CONNECTIVITY,
  NETWORK,
} from "../../../../../helpers/navigation/entries";
import LoopbackConnection from "./LoopbackConnection/LoopbackConnection";
import IpsecConnection from "./IpsecConnectionCloud/IpsecConnection";
import GatewayConnection from "./GatewayConnection/GatewayConnection";
import { useConnectionConfiguratorContext } from "../ConnectionConfiguratorContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import DirectConnection from "./DirectConnection/DirectConnection";
import DefaultConnectionFields from "./DefaultConnectionFields/DefaultConnectionFields";

type Props = {
  className: string;
};

const ConnectionFormContainer: FC<Props> = ({ className }) => {
  const history = useHistory();

  const {
    add,
    fieldsOnChange: onChange,
    fields,
  } = useConnectionConfiguratorContext();
  const { selectedTenant } = useGlobalFilterContext();

  const showAdvancedToggle = useMemo(() => fields.type === "ipsec", [fields]);

  const handleAdd = async () => {
    const res = await add(fields, selectedTenant);
    if (res) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    history.push(formPathFromArray([NETWORK(), CONNECTIVITY(), CONNECTIONS()]));
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      {renderFormsByType(fields.type)}
      <FormFooter
        className={styles.footerWrapper}
        isAdminUp={fields.administrative_state}
        onToggleAdminUp={() =>
          onChange("administrative_state", !fields.administrative_state)
        }
        onAdd={handleAdd}
        onCancel={handleCancel}
        isAdvanced={fields.isAdvanced}
        onToggleAdvanced={() => onChange("isAdvanced", !fields.isAdvanced)}
        showAdvancedToggle={showAdvancedToggle}
      />
    </div>
  );
};

export default ConnectionFormContainer;

const renderFormsByType = (type?: string): ReactElement => {
  switch (type) {
    case "gateway":
      return <GatewayConnection className={styles.contentWrapper} />;
    case "loopback":
      return <LoopbackConnection className={styles.contentWrapper} />;
    case "ipsec":
      return <IpsecConnection className={styles.contentWrapper} />;
    case "directConnect":
      return <DirectConnection className={styles.contentWrapper} />;
    default:
      return <DefaultConnectionFields />;
  }
};
