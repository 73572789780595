import React, { FC } from "react";
import DropdownListContainer from "./DropdownListContainer";
import styles from "./DropdownList.module.scss";
import { useClickOutside } from "../../../../../helpers/hooks/useClickOutside";
import DropdownItem from "./DropdownItem";

type Props = {
  isOpen: any;
  onClose: any;
  coords: any;
  onClick: (value: string) => void;
  list: Array<any>;
};

const DropdownList: FC<Props> = ({
  isOpen,
  coords,
  onClick,
  onClose,
  list,
}) => {
  const elementRef = useClickOutside(onClose);

  const handleClick = (value: string) => {
    onClick(value);
    onClose();
  };

  return (
    <DropdownListContainer isOpen={isOpen}>
      <div
        ref={elementRef as React.RefObject<HTMLDivElement>}
        className={styles.wrapper}
        style={{ top: coords?.y || 0, left: coords?.x || 0 }}
      >
        {list.map((el) => (
          <DropdownItem onClick={() => handleClick(el.id)} item={el.content} />
        ))}
      </div>
    </DropdownListContainer>
  );
};

export default DropdownList;
