import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SegmentsTab } from "../../../components/common/navigation/Network/SegmentsTab";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import { SEGMENTS, SEGMENTS_RULES } from "../../../helpers/navigation/entries";
import { Routes } from "../../../pages/common";
import SegmentsPage from "../../../pages/SegmentsPage";
import SegmentsRulesPage from "../../../pages/SegmentsRulesPage";
import { RouteEntry } from "../../../pages/types";

const segmentsRoutes: Array<RouteEntry> = [
  { pathFunc: SEGMENTS_RULES, component: SegmentsRulesPage },
  { pathFunc: SEGMENTS, component: SegmentsPage },
];

export default function Segments(): ReactElement {
  const path = useMatchPath();
  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(SEGMENTS_RULES(), path)} />
      </Route>
      <Routes
        routes={segmentsRoutes}
        tabs={SegmentsTab}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
}
