/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { CSSProperties, FC } from "react";
// import { useDrag, useDrop } from "react-dnd";
import { Row } from "react-table";
import { classNames } from "../../../helpers/common/classNames";
import { EXPANDER, SETTINGS } from "../../../helpers/common/constantsAlias";
import styles from "./TableRow.module.scss";

// const DND_ITEM_TYPE = "row";

type TableRowProps<D> = {
  prepareRow: (row: Row<D>) => void;
  row: Row<D>;
  isRowClickable?: boolean;
  grid: {
    gridStyle: CSSProperties | undefined;
    parentGridColumnTemplate: string;
  };
  onClick?: (id: number) => void;
  className: string;
  onHover?: (id: number | undefined) => void;
  isRowDisabled?: boolean;
  isRowHighlighted?: boolean;
  isAlignTop?: boolean;
  isRowDND?: boolean;
  moveRow?: any;
  isParentRowBasic?: boolean;
};

const TableRow: FC<TableRowProps> = ({
  prepareRow,
  row,
  isRowClickable,
  grid,
  onClick = (f) => f,
  className,
  onHover = (f) => f,
  isRowDisabled,
  isRowHighlighted,
  isAlignTop,
  isParentRowBasic,
  // isRowDND,
  // moveRow,
}) => {
  prepareRow(row);
  const dropRef = React.useRef(null);
  // const dragRef = React.useRef(null);

  // const [, drop] = useDrop({
  //   accept: DND_ITEM_TYPE,
  //   hover(item: any, monitor) {
  //     if (!dropRef.current) {
  //       return;
  //     }
  //     const dragIndex = item.row.id;
  //     const hoverIndex = row.id;
  //     if (dragIndex === hoverIndex) {
  //       return;
  //     }
  //     const hoverBoundingRect = dropRef.current.getBoundingClientRect();
  //     const hoverMiddleY =
  //       (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  //     const clientOffset = monitor.getClientOffset();
  //     const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  //     if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
  //       return;
  //     }
  //     if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
  //       return;
  //     }
  //     moveRow(item.row, row);
  //   },
  // });

  // const [, drag, preview] = useDrag({
  //   type: DND_ITEM_TYPE,
  //   item: { row },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // });

  // preview(drop(dropRef));
  // drag(dragRef);

  const isRowParent = row.isExpanded && row.depth === 0;

  const tableBodyRow = classNames(
    className,
    isRowClickable && styles.clickableRow,
    isRowDisabled && styles.disabledRow,
    isRowParent && !isParentRowBasic && styles.parentRow,
    isRowHighlighted && styles.highlightedRow
  );

  const gridValue = !isRowParent
    ? grid.gridStyle
    : { gridTemplateColumns: grid.parentGridColumnTemplate };

  return (
    <div
      ref={dropRef}
      {...row.getRowProps()}
      style={gridValue}
      className={tableBodyRow}
      onClick={() => onClick(row.original)}
      onMouseEnter={() => {
        row.setState({ isIconShown: true });
        onHover(row.original.id);
      }}
      onMouseLeave={() => {
        row.setState({ isIconShown: false });
        onHover(undefined);
      }}
    >
      {/* {isRowDND && row.id !== EXTRA_LAST_ROW_ID && row.id !== "denyAll" && (
        <div ref={dragRef}>
          <IconBlankButton
            onClick={() => undefined}
            icon={ShiftIcon}
            isOnlyOpacity
          />
        </div>
      )} */}
      {row.cells.map((cell: any) => {
        const tdStyles = classNames(
          styles.td,
          isAlignTop && !isRowParent && styles.alignedTop,
          cell.column.id === SETTINGS && styles.settingsCell,
          cell.column.id === EXPANDER && styles.expandCell
        );
        return (
          <div {...cell.getCellProps()} className={tdStyles}>
            {cell.render("Cell")}
          </div>
        );
      })}
    </div>
  );
};

export default TableRow;
