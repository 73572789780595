import { CellProps, Row } from "react-table";
import Label from "../../../../components/common/table/Label";
import { CellFactory } from "../../../../components/common/table/newTable/cells/CellsFactory";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import StatusIcon from "../../../../components/icons/StatusIcon";
import SystemIcon from "../../../../components/icons/SystemIcon";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";
import { System } from "../types";
import StatusLabel from "./StatusLabel";
import styles from "./styles.module.scss";
import SelectRow from "./SelectRowComponent";
import StatusWithCircle from "./StatusWithCircle";
import IconBlankButton from "../../../../components/common/buttons/IconBlankButton";
import PlusIcon from "../../../../components/icons/PlusIcon";
import { ACCENT, GREEN } from "../../../../helpers/common/colorAliases";
import BlankButton from "../../../../components/common/buttons/BlankButton";

const cellFactory = new CellFactory<System>({
  icon: SystemIcon,
});

export const provisioningTableHeader = (
  handleAddNode: (value: any) => void,
  onSelect: (name: string) => void,
  switchNodeToActive: (name: any) => void,
  selected?: string
): Array<any> => {
  return [
    {
      Header: "",
      accessor: "isExpanded" as keyof System,
      Cell: cellFactory.expandIcon(),
    },
    {
      Header: "Name",
      accessor: "name" as keyof System,
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        const status = row.original?.status;
        return row.depth === 0 ? (
          <TextWithIcon icon={<SystemIcon status={status} />} text={value} />
        ) : (
          <TextWithIcon
            icon={
              <StatusIcon
                color={getColorFromStatus(status)}
                className={styles.extraGap}
              />
            }
            text={value}
          />
        );
      },
    },
    {
      Header: "Model",
      accessor: "type" as keyof System,
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        return showFirstRowCell(row) ? <Label>{value || "-"}</Label> : "";
      },
    },
    {
      Header: "Location",
      accessor: "location" as keyof System,
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        return showFirstRowCell(row) ? <Label>{value || "-"}</Label> : "";
      },
    },
    {
      Header: "Host IP",
      accessor: "address" as keyof System,
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        return showFirstRowCell(row) ? value : "";
      },
    },
    {
      Header: "Version",
      accessor: "version" as keyof System,
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        return showFirstRowCell(row) ? value : "";
      },
    },
    {
      Header: "HA(status)",
      accessor: (row: any) => [row.status, row?.availability],
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        const [status, availability] = value;
        const isSyncing = status === "syncing";
        const displayText = showFirstRowCell(row)
          ? (isSyncing && status) || availability || status
          : status;
        return <StatusLabel status={displayText} />;
      },
    },
    {
      Header: "Role",
      accessor: "role" as keyof System,
      Cell: ({ row, cell: { value } }: CellProps<System>) => {
        return showFirstRowCell(row) ? <StatusWithCircle status={value} /> : "";
      },
    },
    {
      accessor: "all",
      Header: () => (
        <SelectRow onSelect={onSelect} currentRow={"all"} selected={selected} />
      ),
      Cell: ({ row }: CellProps<System>) => {
        if (row.depth > 0 && row.original.role !== "standby") return null;
        if (row.depth > 0 && row.original.role === "standby") {
          return (
            <div className={styles.controls}>
              <BlankButton
                id={row.original.id.toString()}
                onClick={(e) => {
                  e.stopPropagation();
                  switchNodeToActive(row.original);
                }}
                className={styles.switchBtn}
                hint={"Switch node to active"}
              >
                <StatusIcon color={GREEN} />
              </BlankButton>
            </div>
          );
        }
        return (
          <div className={styles.controls}>
            <BlankButton
              id={row.original.id.toString()}
              onClick={(e) => {
                e.stopPropagation();
                handleAddNode(row.original.name);
              }}
              className={styles.addBtn}
            >
              <PlusIcon color={ACCENT} />
            </BlankButton>
            <SelectRow
              onSelect={onSelect}
              currentRow={row.original.name}
              selected={selected}
            />
          </div>
        );
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof System }));
};

const showFirstRowCell = (row: Row<System>): boolean => {
  return row.depth > 0 || !row.isExpanded;
};
