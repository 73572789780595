import React, { FC } from "react";
import { PageHeader } from "../../../../../components/common/PageHeader/PageHeader";
import TimerangOverlayComponent from "../../../../../components/common/timerange/TimerangeOverlayComponent";

import styles from "./ContentHeader.module.scss";
import TotalTraffic from "./TotalTraffic";

type Props = { className: string };

const ContentHeader: FC<Props> = ({ className }) => {
  return (
    <PageHeader
      title={<TotalTraffic />}
      right={
        <TimerangOverlayComponent
          id={"reportUsageBilling"}
          className={styles.timerangeWrapper}
        />
      }
      className={className}
    />
  );
};

export default ContentHeader;
