import React, { FC, useEffect } from "react";

import DropdownBasic from "../../common/Dropdown/DropdownBasic";
import Input from "../../common/formComponents/Input";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import toNumber from "../../../helpers/toNumber";
import { useTenantVirtualInterfaceContext } from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import { VTEPType } from "../../../pages/VTEPPage/types";
import { DropdownItem } from "../../common/formComponents/DropDownInput";

type Props = {
  system: string;
  isEdit: boolean;
  fieldProps: any;
  fields: { [key: string]: any };
  onChange: (fields: string, value: string | number) => void;
};

const VniFields: FC<Props> = ({
  isEdit,
  fieldProps,
  fields,
  onChange,
  system,
}) => {
  const {
    additionalList,
    fetchAdditionalList,
  } = useTenantVirtualInterfaceContext();

  useEffect(() => {
    fetchAdditionalList("vtep_vni", system);
  }, [system]);

  const vtep = (additionalList as Array<VTEPType>)?.find(
    (vtep) => vtep.id === fields.vtep_id
  );

  const itemsList = additionalList?.map((item: any) => ({
    key: item?.id.toString(),
    value: item?.local_ip,
  }));

  const handleVniUpdate = (val: DropdownItem) => {
    onChange("vtep_id", +val.key);
    onChange("vtep_name", val.value);
  };

  return (
    <>
      <DropdownBasic
        id={"vniFieldsVTEP"}
        onChange={handleVniUpdate}
        selected={mapStringToItem(vtep?.local_ip || "")}
        label="VTEP"
        itemsList={itemsList}
        isMedium
        isNotEditable={isEdit}
      />
      <Input
        {...fieldProps("vni_id")}
        label="VNI ID"
        isNotEditable={isEdit}
        valuePreprocess={toNumber}
      />
    </>
  );
};
export default VniFields;
