export const PURPLE = "var(--accent-color)";
export const PURPLE_DARK = "var(--button-primary-hover)";
export const FONT = "var(--font)";
export const GREY_FONT = "var(--grey-font)";
export const GREY_DISABLED = "var(--grey-disabled)";
export const WHITE = "var(--item-background)";
export const GREEN = "var(--green)";
export const ORANGE = "var(--orange)";
export const BORDER_COLOR = "var(--border-color)";
export const BACKGROUND_COLOR = "var(--background-color)";
export const ORANGE_GRAIENT_FIRST = "var(--orange-gradiend-first)";
export const ORANGE_GRAIENT_SECOND = "var(--orange-gradiend-second)";
export const BUTTON_PRIMARY = "var(--button-primary)";
export const AQUA = "var(--aqua)";
export const PINK = "var(--pink)";
export const LILAC = "var(--lilac)";
export const YELLOW = "var(--yellow)";
export const CRITICAL = "var(--critical)";
export const ACCENT = "var(--accent-color)";
export const ACCENT_ORANGE = "var(--accent-orange-color)";
export const LIGHT_GREEN = "var(--green-lighter-50)";
export const LIGHT_GREEN_BORDER = "var(--green-lighter-40)";
export const LIGHT_PURPLE = "var(--purple-lighter-50)";
export const LIGHT_BLUE = "var(--light-blue)";

//
export const BUTTON_FONT = "var(--button-font)";
