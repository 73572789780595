import { IS_DEMO } from "./../../../globalFlags";
import {
  GTimeRange,
  hardcodedTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

const fromIDSCountBody = (
  vrfId: string | number,
  timeRange: GTimeRange,
  tenantName?: string
): { [key: string]: any } => {
  const { from, to } = hardcodedTimeRange;
  const isoFrom = new Date(from).toISOString();
  const isoTo = new Date(to).toISOString();

  const filterValue = IS_DEMO
    ? [
        {
          range: {
            "@timestamp": {
              format: "strict_date_optional_time",
              gte: isoFrom,
              lte: isoTo,
            },
          },
        },
      ]
    : [
        {
          bool: {
            should: [
              {
                match: {
                  vrf: "" + vrfId,
                },
              },
            ],
            minimum_should_match: 1,
          },
        },
        {
          range: {
            "@timestamp": {
              format: "strict_date_optional_time",
              gte: parseGTime(timeRange.from),
              lte: parseGTime(timeRange.to),
            },
          },
        },
      ];
  return {
    aggs: {
      "1": {
        cardinality: {
          field: "alert.signature.keyword",
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          ...filterValue,
          {
            match_phrase: { "tenant_name.keyword": tenantName },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default fromIDSCountBody;
