import React, { FC, useCallback, useMemo } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import { useValidation } from "../../../helpers/validators/Validator";
import { NewDialogProps } from "../../../helpers/types";
import Layer3InterfaceDialogContextContainer, {
  useLayer3InterfaceDialogContext,
} from "./Layer3InterfaceDialogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import { Layer3Interface, Layer3InterfaceUpdateIP } from "../types";
import FieldsGroup from "../../../components/common/FieldsGroup";
import { validateVirtInterfaceIPs } from "../../../helpers/validators/VirtInterfaceValidator";
import useFieldGroup from "../../../helpers/hooks/useFieldGroup";
import { isSame } from "../../../helpers/common/submitHelper";
import styles from "./Layer3InterfaceEditDialog.module.scss";
import { useLayer3InterfaceContext } from "../Layer3InterfaceContext";

const getDefaultFields = (data?: Layer3Interface): Layer3InterfaceUpdateIP => ({
  add_ip_addresses: data ? [...data?.ip_addresses] : [],
  delete_ip_addresses: [],
});

const Layer3InterfaceAddDialog: FC<
  NewDialogProps<Layer3Interface> & { tenant: string }
> = ({ onClose, data, tenant }) => {
  const { editIP, editStatus } = useLayer3InterfaceDialogContext();

  const { updateVi } = useLayer3InterfaceContext();

  const [fields, handleFieldChange] = useFormField<Layer3InterfaceUpdateIP>(
    getDefaultFields(data)
  );
  const [errors, validate] = useValidation<Layer3InterfaceUpdateIP>(
    validateVirtInterfaceIPs,
    [fields]
  );

  const prevIpsFields: Array<string> = useMemo(
    () => data?.ip_addresses || [],
    []
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && data) {
      const ipsFromForm = fields.add_ip_addresses.filter((ip) => ip);

      const ips_to_add = ipsFromForm.filter(
        (ip) => !prevIpsFields.includes(ip)
      );

      const ipds_to_delete = prevIpsFields.filter(
        (ip) => !ipsFromForm.includes(ip)
      );

      const params = {
        add_ip_addresses: ips_to_add,
        delete_ip_addresses: ipds_to_delete,
      };

      const success = await editIP(params, data, tenant);
      if (success) onClose();

      updateVi({ ...data, ip_addresses: ipsFromForm });
    }
  };

  const handleFieldsGroup = useCallback(
    (i: number, name: string, value: string | number) => {
      const [key, fieldGroup] = useFieldGroup(i, name, value, fields);

      handleFieldChange(key, fieldGroup);
    },
    [handleFieldChange]
  );

  const disableSubmit = isSame(data, fields);

  const controls = getDialogControls({
    dialogTitle: "save",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
    disableSubmit: disableSubmit,
  });

  const leftColumn = (
    <SummaryBlock title={"Virtual Interface"} data={data} tenant={tenant} />
  );

  return (
    <DialogTemplate
      title={"Edit Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <FieldsGroup
        name="add_ip_addresses"
        placeholder="X.X.X.X/X or X::X"
        fields={fields?.add_ip_addresses || []}
        title="IP addresses"
        onChange={handleFieldsGroup}
        errors={errors}
        isOptional
        medium
      />
    </DialogTemplate>
  );
};

export default withContexts<
  NewDialogProps<Layer3Interface> & { tenant: string }
>(Layer3InterfaceAddDialog, [Layer3InterfaceDialogContextContainer]);
