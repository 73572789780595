import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function VNIIcon({ color, indicatorColor }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {indicatorColor && <circle cx="21" cy="3" r="3" fill={indicatorColor} />}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.333 11.917c0 1.61.373 2.916.834 2.916.46 0 .833-1.306.833-2.916C21 10.306 20.627 9 20.167 9c-.46 0-.834 1.306-.834 2.917ZM18.781 9H5.167c-.46 0-.834 1.306-.834 2.917 0 1.61.374 2.916.834 2.916H18.78a14.302 14.302 0 0 1-.281-2.916c0-1.08.103-2.083.28-2.917Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default VNIIcon;
