import { FC, useState } from "react";
import LayoutWithThreeColumns from "./LayoutWithThreeColumns";

export const CollapsableThreeColumnLayout: FC<{
  InfoBlock: FC<{}>;
  Additional: FC<{}>;
}> = ({ children, InfoBlock, Additional }) => {
  const [isInfoCollapsed, setIsInfoCollapsed] = useState(false);
  return (
    <LayoutWithThreeColumns
      id={"CollapsableThreeColumnLayout"}
      InfoBlock={InfoBlock}
      AdditionalInfoBlock={Additional}
      isInfoCollapsed={isInfoCollapsed}
      setIsInfoCollapsed={setIsInfoCollapsed}
    >
      {children}
    </LayoutWithThreeColumns>
  );
};
