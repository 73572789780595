import { UserRouteFiltersAdd } from "../types";

export const getProcessedFields = (
  fields: UserRouteFiltersAdd
): UserRouteFiltersAdd => {
  const pfx_list = fields.pfx_list?.map((pfx, i) => ({
    ...pfx,
    seq: 5 * (i + 1),
  }));
  return { ...fields, pfx_list };
};
