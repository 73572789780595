import React, { FC, useEffect, useState } from "react";
import Section from "../../../components/common/Section";
import { CreatePBR } from "./PBRConfig";
import styles from "./ActionSection.module.scss";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import DropdownWithSearch from "../../../components/common/Dropdown/DropdownWithSearch";
import { DropdownItem } from "../../../helpers/types";
import { Checkbox } from "../../../components/common/formComponents/Checkbox";
import VRFDropdown from "../../../components/common/OneSystem/VRFDropdown";
import { OneSystemType, VRFType } from "../../../helpers/api/apiTypes";
import { System } from "../../Systems/Provisioning/types";
import { useTenantVirtualInterfaceContext } from "../../../contexts/tenantsContext/TenantVirtualInterfaceContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";

type Props = {
  fields: CreatePBR;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  errors?: any;
  className: string;
  system?: System;
};

export const ContinueAction = {
  key: "None",
  data: "ROUTE",
};

const ActionSection: FC<Props> = ({
  fields,
  onChange,
  className,
  errors,
  system,
}) => {
  const [activeVRF, setActiveVRF] = useState<VRFType | any | undefined>({
    value: fields?.gateway_vrf_name,
    key: fields?.gateway_vrf_name,
  });
  const { membersList } = useTenantVirtualInterfaceContext();
  const handleChange = (val: DropdownItem | string) => {
    if (typeof val === "string") {
      onChange("gateway_ip_address", val);
    } else {
      onChange("action", val.key);
    }
  };
  useEffect(() => {
    if (!fields.gateway_vrf_name && system)
      setActiveVRF({ value: fields.vrf_name, key: fields.vrf_name });
  }, [fields.vrf_name]);

  const handleSetVRF = (val?: VRFType | string): void => {
    if (!val) return;
    if (typeof val === "string") {
      onChange("gateway_vrf_name", val);
      setActiveVRF({ key: val, value: val });
    } else {
      onChange("gateway_vrf_name", val.name);
      setActiveVRF(val);
    }
  };

  return (
    <Section title={"Route Action"} className={className} columnsCount={3}>
      <div className={styles.column}>
        {system && (
          <VRFDropdown
            system={system as OneSystemType}
            selected={activeVRF}
            onSelect={(val) => handleSetVRF(val)}
            label="Target VRF"
          />
        )}
        <DropdownWithSearch
          isHideIcon
          id={"action"}
          onChange={handleChange}
          selected={mapStringToItem(fields.gateway_ip_address)}
          label={"Gateway"}
          valuesList={[ContinueAction.key]}
          isMedium
          placeholder={fields.action || "Select action or write IP"}
          error={errors && errors["action"]}
          hideEmptyListPlaceholder
          listClassName={styles.listWrapper}
          withClearIcon
        />
      </div>
      <div className={styles.column}>
        <div className={styles.checkboxWrapper}>
          <Checkbox
            isChecked={fields.tracked}
            containerClassName={
              (!fields.tracked && styles.opacity) || undefined
            }
            onChange={() => onChange("tracked", !fields.tracked)}
            label={"Track gateway"}
          />
          <div className={styles.trackedInfo}>
            Traffic matching this rule will be skipped if the Gateway is
            unavailable.
          </div>
        </div>
      </div>
    </Section>
  );
};
export default ActionSection;
