/* eslint-disable css-modules/no-unused-class */
import React, { FC, useEffect, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { RequestState } from "../../../helpers/types";
import Label from "../table/Label";
import FieldLoader from "./FieldLoader";
import styles from "./TagList.module.scss";

type Props = {
  valuesList: Array<string>;
  onAdd?: (val: Array<string>) => void;
  onToggle?: (val: string) => void;
  list?: Array<string>;
  label?: string;
  status?: RequestState;
};

type TagType = { title: string; isActive: boolean };

const TagList: FC<Props> = ({
  onAdd = () => undefined,
  onToggle = () => undefined,
  list,
  label,
  status,
  valuesList,
}) => {
  const [tags, setTags] = useState<Array<TagType>>([]);

  useEffect(() => {
    if (list) {
      const newList = list.map((el) => ({
        title: el,
        isActive: valuesList.includes(el),
      }));
      setTags(newList);
    }
  }, [list]);

  const handleToggle = (tag: TagType, i: number): void => {
    onToggle(tag.title);
    const newList = [...tags];
    newList[i] = { ...newList[i], isActive: !tag.isActive };
    setTags(newList);
  };

  const isList = tags && status === "ok";

  return (
    <div>
      <label className={styles.innerLabel}>{label}</label>
      <div className={styles.wrapper}>
        {isList ? (
          tags.map((tag, i) => {
            const tagStyles = classNames(
              styles.tag,
              tag.isActive && styles.active
            );
            return (
              <Label
                className={tagStyles}
                isOutlined={!tag.isActive}
                onClick={() => handleToggle(tag, i)}
              >
                {tag.title}
              </Label>
            );
          })
        ) : (
          <FieldLoader message="Waiting for segments" />
        )}
      </div>
    </div>
  );
};

export default TagList;
