import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function UserGuideIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.22581 0.709359H21.0323C21.2879 0.712701 21.5321 0.815733 21.7129 0.996495C21.8937 1.17727 21.9966 1.42148 22 1.6771V22.3223C21.9966 22.5779 21.8937 22.8221 21.7129 23.0029C21.5321 23.1837 21.2879 23.2866 21.0323 23.29H5.79355C4.82027 23.3213 3.8743 22.9653 3.16312 22.3002C2.45196 21.6349 2.03366 20.7148 2 19.7416V3.74162C2.02697 2.91219 2.38164 2.12728 2.98631 1.55889C3.59097 0.990508 4.3963 0.685011 5.22581 0.709359ZM5.79355 21.3545H20.0645V18.1287H5.79355C5.33341 18.0968 4.87937 18.2487 4.53104 18.551C4.18272 18.8533 3.96854 19.2816 3.93548 19.7416C3.96854 20.2017 4.18272 20.6299 4.53104 20.9322C4.87937 21.2346 5.33341 21.3865 5.79355 21.3545ZM8.944 7.40292C8.944 5.71765 10.3148 4.34689 12 4.34689C13.6853 4.34689 15.0561 5.71765 15.0561 7.40292C15.0561 8.7693 14.1552 9.92824 12.9168 10.3185V11.1248C12.9168 11.6323 12.5052 12.0384 12 12.0384C11.4949 12.0384 11.0832 11.6323 11.0832 11.1248V9.54214C11.0832 9.03579 11.4937 8.62533 12 8.62533C12.6739 8.62533 13.2224 8.07678 13.2224 7.40292C13.2224 6.72906 12.6739 6.18051 12 6.18051C11.3262 6.18051 10.7776 6.72906 10.7776 7.40292C10.7776 7.90927 10.3672 8.31973 9.86081 8.31973C9.35446 8.31973 8.944 7.90927 8.944 7.40292ZM10.905 14.464C10.905 13.8592 11.3952 13.369 12 13.369C12.6048 13.369 13.0951 13.8592 13.0951 14.464C13.0951 15.0688 12.6048 15.5591 12 15.5591C11.3952 15.5591 10.905 15.0688 10.905 14.464Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default UserGuideIcon;
