import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function RouteMapIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3586 10.3213C30.3586 12.429 28.6499 14.1377 26.5422 14.1377C24.9406 14.1377 23.5695 13.1512 23.0033 11.7527H20.8187C19.5014 11.7527 18.4335 12.8206 18.4335 14.1379V19.8625C18.4335 22.7606 16.0841 25.11 13.186 25.11H10.9997C10.4334 26.5084 9.06237 27.4948 7.46092 27.4948C5.35319 27.4948 3.64453 25.7861 3.64453 23.6784C3.64453 21.5707 5.35319 19.862 7.46092 19.862C9.06273 19.862 10.4341 20.8488 11.0001 22.2477H13.186C14.5033 22.2477 15.5712 21.1798 15.5712 19.8625V14.1379C15.5712 11.2398 17.9206 8.89036 20.8187 8.89036H23.0031C23.5692 7.49161 24.9404 6.50488 26.5422 6.50488C28.6499 6.50488 30.3586 8.21354 30.3586 10.3213Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default RouteMapIcon;
