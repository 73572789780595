import { FC } from "react";
import styles from "./EventsHeader.module.scss";
import BlankButton from "../../../components/common/buttons/BlankButton";
import { useHistory } from "react-router-dom";
import { EVENTS } from "../../../helpers/navigation/entries";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import { classNames } from "../../../helpers/common/classNames";
import CaretUp from "../../../components/icons/CaretUp";
import TimerangOverlayComponent from "../../../components/common/timerange/TimerangeOverlayComponent";
import { EventsType } from "../types";
import { useEventsContext } from "../EventsContext";
import EventsSizeDropdown from "./EventsSizeDropdown";

type Props = {
  title: string;
  isCollapsible: boolean;
  toggleCollapse: () => void;
  isCollapsed: boolean;
  className: string;
  type: EventsType;
};

const EventsHeader: FC<Props> = ({
  title,
  isCollapsible,
  toggleCollapse,
  isCollapsed,
  className,
  type,
}) => {
  const history = useHistory();
  const { events = [], size, setSize } = useEventsContext();

  const handleShowAll = () => {
    history.replace("/" + EVENTS().path);
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        isCollapsed && styles.fullBorder
      )}
    >
      <div className={styles.title}>{title}</div>
      <div className={styles.tableControls}>
        {(isCollapsible || type === "bgp") && (
          <div className={styles.amountLabel}>{events.length}</div>
        )}
        {isCollapsible ? (
          <>
            {type === "tenant" && (
              <BlankButton
                className={styles.showAllBtn}
                id={"tenantEventsShowAll"}
                onClick={handleShowAll}
              >
                Show All
              </BlankButton>
            )}
            <IconBlankButton
              id={"tenantEventsCollapse"}
              onClick={toggleCollapse}
              className={classNames(
                styles.collapseBtn,
                !isCollapsed && styles.rotate
              )}
              icon={CaretUp}
            />
          </>
        ) : (
          <>
            <EventsSizeDropdown
              selected={size}
              onChange={setSize}
              label="Query size"
            />
            <TimerangOverlayComponent
              id={"eventsTimerange"}
              className={
                type === "system"
                  ? styles.systemTimerangeWrapper
                  : styles.timerangeWrapper
              }
            />
          </>
        )}
      </div>
    </div>
  );
};
export default EventsHeader;
