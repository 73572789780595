import React, { FC, useMemo, useState } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import {
  INVALID_IP_WITH_PREFIX,
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
  useValidation,
} from "../../../helpers/validators/Validator";
import { NewDialogProps } from "../../../helpers/types";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";
import VirtualInterfaceDialogContextContainer, {
  useVirtualInterfaceDialogContext,
} from "./VirtualInterfaceDialogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import { VirtualInterfaceCreate } from "../types";
import styles from "./VirtualInterfaceDialog.module.scss";
import Input from "../../../components/common/formComponents/Input";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { capitalizeFirstLetter } from "../../../helpers/common/stringHelpers";
import {
  ADMIN_STATE,
  VIRT_INTERFACE_SYSTEM_TYPES,
} from "../../../helpers/common/constantsAlias";
import validateVirtInterface from "../../../helpers/validators/VirtInterfaceValidator";
import InputWithList from "../../../components/common/formComponents/InputWithList";
import {
  handleInput,
  handleRemoveFromList,
} from "../../../helpers/common/arrayHelpers";

const DEFAULT_FIELDS: VirtualInterfaceCreate = {
  name: "",
  virtual_interface_type: "routed",
  ipv4_addresses: [],
  ipv6_addresses: [],
  description: "",
  mtu: 9220,
  administrative_state: "up",
};

const VirtualInterfaceAddDialog: FC<
  NewDialogProps<VirtualInterfaceCreate> & { vrf: string }
> = ({ onClose, vrf }) => {
  const { add, addStatus } = useVirtualInterfaceDialogContext();

  const [fields, handleFieldChange] = useFormField<VirtualInterfaceCreate>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<VirtualInterfaceCreate>(
    validateVirtInterface,
    [fields]
  );

  const [ipv4, setIPv4] = useState<Array<string>>(fields.ipv4_addresses);
  const [ipv6, setIPv6] = useState<Array<string>>(fields.ipv6_addresses);
  const [inputErrors, setInputErrors] = useState<
    { [key: string]: string } | undefined
  >(undefined);

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const success = await add({ ...fields, vrf } as any);
      if (success) onClose();
    }
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const leftColumn = (
    <SummaryBlock title={"Virtual Interface"} data={fields} vrf={vrf} />
  );

  return (
    <DialogTemplate
      title={"Add Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <Input {...fieldProps("name")} label="Name" />
        <DropdownBasic
          id={"virtualInterfaceAddDialogAdminState"}
          onChange={(val) =>
            handleFieldChange("administrative_state", val.key.toLowerCase())
          }
          selected={mapStringToItem(
            capitalizeFirstLetter(fields.administrative_state) || ""
          )}
          label="Admin State"
          itemsList={ADMIN_STATE.map((el) =>
            mapStringToItem(capitalizeFirstLetter(el))
          )}
          isMedium
        />
        <Input
          {...fieldProps("mtu")}
          label="MTU"
          type="number"
          min={64}
          max={20000}
        />
        <Input
          {...fieldProps("description")}
          label="Description"
          isOptional
          isFullValue
        />
      </div>
      <div className={styles.column}>
        <DropdownBasic
          id={"virtualInterfaceAddDialogType"}
          onChange={(val) =>
            handleFieldChange("virtual_interface_type", val.key.toLowerCase())
          }
          selected={mapStringToItem(
            capitalizeFirstLetter(fields.virtual_interface_type)
          )}
          label="Type"
          itemsList={VIRT_INTERFACE_SYSTEM_TYPES.map((key) =>
            mapStringToItem(capitalizeFirstLetter(key))
          )}
          isMedium
        />

        <InputWithList
          name="ipv4_addresses"
          placeholder="X.X.X.X/X"
          valuesList={ipv4}
          onAdd={(val: Array<string>) => {
            handleInput(
              val,
              ipv4,
              setIPv4,
              "ipv4_addresses",
              handleFieldChange
            );
          }}
          onRemove={(val: string) =>
            handleRemoveFromList(
              val,
              ipv4,
              setIPv4,
              "ipv4_addresses",
              handleFieldChange
            )
          }
          label="IPv4"
          error={
            (errors && errors[`ipv4_addresses`]) ||
            inputErrors?.[`ipv4_addresses`]
          }
          withValidate
          validate={checkIPv4WithPrefix}
          setError={() =>
            setInputErrors({
              ["ipv4_addresses"]: INVALID_IP_WITH_PREFIX,
            })
          }
          onClear={() =>
            setInputErrors({
              ["ipv4_addresses"]: "",
            })
          }
          errorWithTooltip={true}
          isOptional
          medium
        />
        <InputWithList
          name="ipv6_addresses"
          placeholder="X::X/X"
          valuesList={ipv6}
          onAdd={(val: Array<string>) => {
            handleInput(
              val,
              ipv6,
              setIPv6,
              "ipv6_addresses",
              handleFieldChange
            );
          }}
          onBlur={validate}
          onRemove={(val: string) =>
            handleRemoveFromList(
              val,
              ipv6,
              setIPv6,
              "ipv6_addresses",
              handleFieldChange
            )
          }
          label="IPv6"
          error={
            (errors && errors[`ipv6_addresses`]) ||
            inputErrors?.[`ipv6_addresses`]
          }
          withValidate
          validate={checkIPv6WithPrefix}
          setError={() =>
            setInputErrors({
              ["ipv6_addresses"]: INVALID_IP_WITH_PREFIX,
            })
          }
          onClear={() =>
            setInputErrors({
              ["ipv6_addresses"]: "",
            })
          }
          errorWithTooltip={true}
          isOptional
          medium
          singleLabelClassname={styles.ipv6}
        />
      </div>
    </DialogTemplate>
  );
};

export default withContexts<
  NewDialogProps<VirtualInterfaceCreate> & { vrf: string }
>(VirtualInterfaceAddDialog, [VirtualInterfaceDialogContextContainer]);
