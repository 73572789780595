import { ProviderCredentialsFields } from "../../pages/SDWANProvidersPage/components/ProviderCredentials";
import Validator from "./Validator";

export default function validateSDWANCredentials(
  data: ProviderCredentialsFields,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  Object.keys(data).map((key) => {
    const value = data[key as keyof ProviderCredentialsFields];
    if (["access_key", "access_secret"].includes(key)) {
      validator.checkEmpty(value, key);
    }
    if (["tenant", "username", "end_point"].includes(key)) {
      validator.checkEmpty(value, key);
    }
  });

  return validator;
}
