import { FC } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import Section from "../../../../../../../components/common/Section";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";
import {
  PHASE1_ALGORYTHM,
  PHASE1_LOCAL_IDENTIFIER,
  PHASE1_PEER_IDENTIFIER,
} from "../../../helpers/consts";
import styles from "./Phase1Section.module.scss";

type Props = {
  className: string;
};
const Phase1Section: FC<Props> = ({ className }) => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  return (
    <Section title={"Phase 1"} columnsCount={1} className={className}>
      <div className={styles.row}>
        <div className={styles.colGroup}>
          <DropdownBasic
            label={"Local identifier"}
            id={"phase1LocalIdentifierType"}
            onChange={(val) => {
              onChange("phase1LocalIdentifierType", val.key);
            }}
            placeholder={"Type"}
            selected={mapStringToItem(fields.phase1LocalIdentifierType)}
            itemsList={PHASE1_LOCAL_IDENTIFIER.map(mapStringToItem)}
            isMedium
            error={""}
          />
          <Input
            label=""
            isPlaceholderAlwaysVisible
            name="phase1LocalIdentifierValue"
            placeholder="Value"
            value={fields.phase1LocalIdentifierValue}
            medium
            error={""}
            onChange={(e) => {
              onChange("phase1LocalIdentifierValue", e.target.value);
            }}
          />
        </div>
        <div className={styles.colGroup}>
          <DropdownBasic
            label={"Peer identifier"}
            id={"phase1PeerIdentifierType"}
            onChange={(val) => {
              onChange("phase1PeerIdentifierType", val.key);
            }}
            placeholder={"Type"}
            selected={mapStringToItem(fields.phase1PeerIdentifierType)}
            itemsList={PHASE1_PEER_IDENTIFIER.map(mapStringToItem)}
            isMedium
            error={""}
          />
          <Input
            label=""
            isPlaceholderAlwaysVisible
            name="phase1PeerIdentifierValue"
            placeholder="Value"
            value={fields.phase1PeerIdentifierValue}
            medium
            error={""}
            onChange={(e) => {
              onChange("phase1PeerIdentifierValue", e.target.value);
            }}
          />
        </div>
      </div>
      <div className={styles.row}>
        <DropdownBasic
          label={"Algorithm"}
          id={"phase1AlgorithmAESGCM"}
          onChange={(val) => {
            onChange("phase1AlgorithmAESGCM", val.key);
          }}
          placeholder={"AES-GCM"}
          selected={mapStringToItem(fields.phase1AlgorithmAESGCM)}
          itemsList={PHASE1_ALGORYTHM["AES-GSM"].map(mapStringToItem)}
          isMedium
          error={""}
        />
        <DropdownBasic
          label={""}
          id={"phase1AlgorithmPRF"}
          onChange={(val) => {
            onChange("phase1AlgorithmPRF", val.key);
          }}
          placeholder={"PRF"}
          selected={mapStringToItem(fields.phase1AlgorithmPRF)}
          itemsList={PHASE1_ALGORYTHM.PRF.map(mapStringToItem)}
          isMedium
          error={""}
        />
        <DropdownBasic
          label={""}
          id={"phase1AgorithmKeySize"}
          onChange={(val) => {
            onChange("phase1AgorithmKeySize", val.key);
          }}
          placeholder={"Key Size"}
          selected={mapStringToItem(fields.phase1AgorithmKeySize)}
          itemsList={PHASE1_ALGORYTHM.KEY_SIZE.map(mapStringToItem)}
          isMedium
          error={""}
        />
        <DropdownBasic
          label={""}
          id={"phase1AgorithmDH"}
          onChange={(val) => {
            onChange("phase1AgorithmDH", val.key);
          }}
          placeholder={"DH"}
          selected={mapStringToItem(fields.phase1AgorithmDH)}
          itemsList={PHASE1_ALGORYTHM["DH"].map(mapStringToItem)}
          isMedium
          error={""}
        />
      </div>
      <div className={styles.row}>
        <Input
          label="Pre-Shared Key"
          name="phase1PreSharedKey"
          placeholder=""
          value={fields.phase1PreSharedKey}
          medium
          error={""}
          onChange={(e) => {
            onChange("phase1PreSharedKey", e.target.value);
          }}
          type={"password"}
        />
        <Input
          label="Key lifetime (seconds)"
          name="phase1KeyLifetime"
          placeholder=""
          value={fields.phase1KeyLifetime}
          medium
          error={""}
          onChange={(e) => {
            onChange("phase1KeyLifetime", e.target.value);
          }}
          type={"number"}
        />
      </div>
    </Section>
  );
};

export default Phase1Section;
