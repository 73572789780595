import { FC, useEffect, useState } from "react";
import BarChart from "../BarChart";
import { arubaApi } from "../../../helpers/api/ArubaApi";

type Props = {
  className: string;
  list: Array<any>;
};

const FlowCounts: FC<Props> = ({ className, list }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState<Array<string>>([]);
  const [bytes, setBytes] = useState<Array<number>>([]);
  const devices = list.map((item) => item.id).join();

  useEffect(() => {
    if (!list.length) return;
    if (!isLoading) {
      setLoading(true);
    }
    const payload = {
      nePks: list.map((item) => item.id),
    };

    arubaApi.getFlow({ top: "15" }, payload).then((res) => {
      const json = res.result || [];
      const labels: Array<string> = [];
      const values: Array<number> = [];

      const data = json
        .map((item: any) => {
          const [entry] = Object.entries(item);
          const [id, data] = entry as [string, Record<string, number>];
          const {
            NON_TCP_COUNT = 0,
            TCP_ACCELERATED_COUNT = 0,
            TCP_NOT_ACCELERATED_COUNT = 0,
          } = data;

          const { hostName } = list.find((device) => device.id === id);

          return [
            hostName,
            NON_TCP_COUNT + TCP_ACCELERATED_COUNT + TCP_NOT_ACCELERATED_COUNT,
          ];
        })
        .sort((a: any, b: any) => {
          if (a[1] < b[1]) {
            return 1;
          } else if (a[1] > b[1]) {
            return -1;
          }
          return 0;
        });

      data.forEach((item: any) => {
        labels.push(item[0]);
        values.push(item[1]);
      });

      setLabels(labels);
      setBytes(values);
      setLoading(false);
    });
  }, [devices]);

  return (
    <div className={className} data-loading={isLoading}>
      <h4>Flow Counts</h4>
      <BarChart labels={labels} data={bytes} suffix=""></BarChart>
    </div>
  );
};

export default FlowCounts;
