import { useCallback, useEffect, useState } from "react";
import { RequestStatus } from "../types";
import { isOk } from "../common/RequestStateHelpers";

type ReturnType<D> = [
  tryAction: (data: D, addition?: any) => void,
  selected: D | undefined,
  sureProps: {
    onOk: () => void;
    onCancel: () => void;
    status?: RequestStatus;
    isShown: boolean;
  }
];
export default function useDeleteDialog<D>(
  action: (data: D, addition?: any) => void,
  status: RequestStatus | undefined,
  deleteCallback: (data: D) => void = () => undefined
): ReturnType<D> {
  const [showSure, setShowSure] = useState<boolean>(false);
  const [data, setData] = useState<D | undefined>();
  const [additionalParam, setAdditionalParam] = useState<any>();

  useEffect(() => {
    if (isOk(status)) {
      setShowSure(false);
      setData(undefined);
      data && deleteCallback(data);
    }
  }, [status, deleteCallback]);
  const tryAction = useCallback(
    (d, p) => {
      setData(d);
      setAdditionalParam(p);
      setShowSure(true);
    },
    [setShowSure]
  );
  const onCancel = useCallback(() => {
    setShowSure(false);
    setData(undefined);
  }, [setShowSure]);

  const onOk = useCallback(() => {
    data && action(data, additionalParam);
  }, [action, data]);

  return [tryAction, data, { onOk, onCancel, status, isShown: showSure }];
}
