import React, { CSSProperties, useMemo } from "react";
import { InterfacesGroup, PosProps, STAR_HEIGHT } from "../utils";
import styles from "./HoverTooltip.module.scss";
import ArrowDown from "../../../../../../components/icons/ArrowDown";
import ArrowUp from "../../../../../../components/icons/ArrowUp";
import PendingIcon from "../../../../../../components/icons/statuses/PendingIcon";

type Props = PosProps & {
  groupData: InterfacesGroup;
};

function getTypeAttr(
  type: "up" | "down" | "pending"
): [border: string, icon: React.ReactElement] {
  switch (type) {
    case "down":
      return [
        "#FF406E4D",
        <ArrowDown width={11} height={11} color="var(--critical)" />,
      ];
    case "pending":
      return ["#FF6C0E4D", <PendingIcon />];
    case "up":
      return [
        "#00BA774D",
        <ArrowUp width={11} height={11} color="var(--green)" />,
      ];
    default:
      return ["var(--border-color)", <div />];
  }
}
export default function HoverTooltip({
  x,
  y,
  groupData,
}: Props): React.ReactElement {
  const isAtBottom =
    y > (document.documentElement.clientHeight || STAR_HEIGHT) / 2;
  const posStyle: CSSProperties = useMemo(() => {
    return {
      top: y + "px",
      left: x + "px",
      transform: isAtBottom ? "translateY(-100%)" : "",
    };
  }, [x, y, isAtBottom]);
  return (
    <div className={styles.container} style={posStyle}>
      {groupData.data.map((item) => (
        <>
          <div key={item.name} className={styles.intName}>
            {item.name}
          </div>
          {item.vpc?.map((v, i) => {
            {
              const [color, icon] = getTypeAttr("up");
              return (
                <div
                  className={styles.vpc}
                  key={item.name + i}
                  style={{ border: `1px solid ${color}` }}
                >
                  {icon}
                  {v}
                </div>
              );
            }
          })}
        </>
      ))}
    </div>
  );
}
