import { FC } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import { labelizeBytes } from "../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

type Props = {
  labels: Array<string>;
  data: Array<number>;
  suffix?: string;
};

export const _data = {
  labels: ["1.1.1.1"],
  datasets: [
    {
      data: [1],
      backgroundColor: "#6A82FF",
      borderWidth: 0,
    },
  ],
};

const BarChart: FC<Props> = ({ labels, data, suffix }) => {
  if (!labels.length) return null;
  if (!data.length) return null;
  _data.labels = labels;
  _data.datasets[0].data = data;

  const options = {
    indexAxis: "y" as const,
    responsive: true,
    aspectRatio: 1,
    scales: {
      x: {
        ticks: {
          callback: (value: string | number) => {
            if (suffix === "") return value;
            return labelizeBytes(+value);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltip: any) => {
            return labelizeBytes(tooltip.raw);
          },
        },
      },
    },
  };

  if (typeof suffix === "string") {
    options.plugins.tooltip.callbacks.label = (tooltip) => {
      return `${tooltip.formattedValue}${suffix}`;
    };
  }
  return <Bar data={_data} options={options} />;
};

export default BarChart;
