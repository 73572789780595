import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function ElanIcon({ color, x, y, width, height }: IconProps): ReactElement {
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM5.24127 12.6312H10.342V11.3792H6.71536V8.54818H10.1138V7.31463H6.71536V4.85986H10.342V3.61397H5.24127V12.6312Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ElanIcon;
