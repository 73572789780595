import { FC } from "react";
import Button from "../../../../../../components/common/buttons/Button";
import { Toggle } from "../../../../../../components/common/Toggle";
import { classNames } from "../../../../../../helpers/common/classNames";
import styles from "./FormFooter.module.scss";

type Props = {
  className: string;
  isAdminUp: boolean;
  onToggleAdminUp: () => void;
  onAdd: () => void;
  onCancel: () => void;
  isAdvanced: boolean;
  onToggleAdvanced: () => void;
  showAdvancedToggle: boolean;
};

const FormFooter: FC<Props> = ({
  className,
  isAdminUp,
  onToggleAdminUp,
  onAdd,
  onCancel,
  isAdvanced,
  onToggleAdvanced,
  showAdvancedToggle,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.controls}>
        <Toggle
          id={"adminUp"}
          label={"Admin Up"}
          onChange={onToggleAdminUp}
          isChecked={isAdminUp}
        />
        {showAdvancedToggle && (
          <Toggle
            id={"advanced"}
            label={"Advanced"}
            onChange={onToggleAdvanced}
            isChecked={isAdvanced}
          />
        )}
      </div>
      <Button onClick={onCancel}>Cancel</Button>
      <Button onClick={onAdd} isPrimaryBtn>
        Add
      </Button>
    </div>
  );
};

export default FormFooter;
