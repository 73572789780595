import { FC } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import styles from "./Config.module.scss";
import WireGuardConfig from "../../../../../components/common/WireGuardComponents/WireGuardConfig/WireGuardConfig";
import WireGuardQRCode from "../../../../../components/common/WireGuardComponents/WireGuardQRCode/WireGuardQRCode";
import { Link } from "react-router-dom";

type Props = { className: string; user: string; tenant?: string };

const Config: FC<Props> = ({ className, user, tenant }) => {
  const wrapperClasses = classNames(styles.wrapper, className);
  return (
    <div className={wrapperClasses}>
      <WireGuardQRCode user={user} tenant={tenant} />
      <div>
        <WireGuardConfig user={user} tenant={tenant} />
        <ul className={styles.list}>
          <li>1. Download the WireGuard app.</li>
          <li>2. Download the configuration file..</li>
          <li>3. Add a tunnel.</li>
          <li>...</li>
          <li>
            <Link
              id={"viewDetails"}
              to={`/remoteUser?name=${user}&tenant=${tenant}`}
              className={styles.link}
            >
              View Detailed Instruction
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Config;
