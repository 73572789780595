import React from "react";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import BranchAgent from "./BranchAgent";
import BranchIpsec from "./BranchIpsec";

type Props = {
  className?: string;
};

const IpsecBranch = ({ className }: Props) => {
  const { showBranchAgent } = useConnectionConfiguratorContext();

  return (
    <div className={className}>
      {showBranchAgent ? <BranchAgent /> : <BranchIpsec />}
    </div>
  );
};

export default IpsecBranch;
