import React, { FC } from "react";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import StatusIcon from "../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";
import { capitalizeFirstLetter } from "../../../../helpers/common/stringHelpers";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StatusLabel.module.scss";

type Props = {
  status: string;
};

const StatusWithCircle: FC<Props> = ({ status }) => {
  const className = getClassNameFromStatus(status);
  return (
    <TextWithIcon
      icon={<StatusIcon color={getColorFromStatus(status)} />}
      text={capitalizeFirstLetter(status)}
      className={styles[className]}
    />
  );
};

export default StatusWithCircle;

const getClassNameFromStatus = (status: string): string => {
  switch (status.toLowerCase()) {
    case "failed":
      return "redText";

    case "active":
      return "greenText";
    case "maintenance":
      return "orangeText";
    case "standby":
    default:
      return "greyText";
  }
};
