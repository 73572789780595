import { SystemOverviewType } from "./api/apiTypes";
import { getHardcodeSystemBoxValuesJSON } from "./getHardcodeSystemBoxValuesJSON";
import { getSystemBoxValuesJSON } from "./getSystemBoxValuesJSON";

export function getSystemsConnectivity(
  searchVal: string
): Array<SystemOverviewType> {
  const boxValues =
    searchVal !== ""
      ? getHardcodeSystemBoxValuesJSON(searchVal)
      : getSystemBoxValuesJSON();
  return [
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 1,
      location: "AWS",
      name: "NSOS 500",
      status: "active",
      boxValue: boxValues[0],
      traffic: "18/50 Gbps",
      titleLabels: ["NSOS 500", `Tenants: 81`],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 2,
      location: "AWS",
      name: "NSOS 500",
      status: "active",
      boxValue: boxValues[1],
      traffic: "19/50 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 92"],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 3,
      location: "AWS",
      name: "NSOS 500",
      status: "critical",
      boxValue: boxValues[2],
      traffic: "49/50 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 63"],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 4,
      location: "AWS",
      name: "NSOS 500",
      status: "active",
      boxValue: boxValues[3],
      traffic: "22/50 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 85"],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 5,
      location: "AWS",
      name: "NSOS 500",
      status: "warning",
      boxValue: boxValues[4],
      traffic: "39/50 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 94"],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 6,
      location: "AWS",
      name: "NSOS 500",
      status: "warning",
      boxValue: boxValues[5],
      traffic: "39/50 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 74"],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 7,
      location: "AWS",
      name: "NSOS 500",
      status: "active",
      boxValue: boxValues[6],
      traffic: "90/120 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 88"],
    },
    {
      address: "address",
      description: "description",
      ssh_key: "key",
      hostName: "172.31.42.239",
      hostIP: "172.31.42.239",
      bandwidth: 0,
      memoryAVBL: 0,
      diskAVBL: 0,
      CPUusage: 0,
      availabilityScore: 0,
      id: 8,
      location: "AWS",
      name: "NSOS 500",
      status: "critical",
      boxValue: boxValues[7],
      traffic: "90/120 Gbps",
      titleLabels: ["NSOS 500", "Tenants: 91"],
    },
  ];
}
