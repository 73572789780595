import { FC } from "react";
import styles from "./HALogs.module.scss";
import { PageLoader } from "../../../common/loadStates/LoaderIcon";
import { useHAHealthContext } from "../../../../contexts/systemsContext/HAHealthContext";
import { classNames } from "../../../../helpers/common/classNames";
import Timestamp from "../../../../pages/EventsPage/common/Timestamp";

type Props = {
  className?: string;
};

const HALogs: FC<Props> = ({ className }) => {
  const { selectedLog } = useHAHealthContext();

  if (!selectedLog) return <PageLoader />;

  const selectedLogEntries = Object.entries(selectedLog);

  console.log(selectedLog, "selectedLog");
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.header}>
        <Timestamp value={selectedLog.timestamp} />
      </div>
      <ul className={styles.content}>
        {selectedLogEntries.map(
          ([key, value], i) =>
            value !== "undefined" &&
            value !== "no data" && (
              <li className={styles.row} key={key + i}>
                <span className={styles.key}>{key}</span>
                <span className={styles.value}>{value}</span>
              </li>
            )
        )}
      </ul>
    </div>
  );
};
export default HALogs;
