import { parseGTimeRange } from "../../../components/common/charts/GraphanaLinksFactory";

const grafanaTimeRangeBody = (
  type: string,
  tenantName?: string
): { [key: string]: any } => {
  const { from, to } = parseGTimeRange({ from: "now-10080m", to: "now" });
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (type === "FQDN") {
    return {
      aggs: {
        "2": {
          date_range: {
            field: "@timestamp",
            ranges: [
              { from: "now-7d", to: "now" },
              { from: "now-3d", to: "now" },
              { from: "now-24h", to: "now" },
              { from: "now-12h", to: "now" },
              { from: "now-6h", to: "now" },
              { from: "now-3h", to: "now" },
              { from: "now-1h", to: "now" },
              { from: "now-30m", to: "now" },
              { from: "now-15m", to: "now" },
              { from: "now-5m", to: "now" },
            ],
            time_zone: userTimeZone,
          },
        },
      },

      size: 0,
      script_fields: {},
      stored_fields: ["*"],
      runtime_mappings: {},
      query: {
        bool: {
          must: [],
          filter: [
            {
              bool: {
                filter: [
                  {
                    bool: {
                      should: [
                        { match_phrase: { "event_type.keyword": "DPI" } },
                      ],
                      minimum_should_match: 1,
                    },
                  },
                  {
                    bool: {
                      should: [
                        { match_phrase: { "FQDN_action.keyword": "ALLOW" } },
                      ],
                      minimum_should_match: 1,
                    },
                  },
                ],
              },
            },
            { match_phrase: { "tenant_name.keyword": tenantName } },
            {
              range: {
                "@timestamp": {
                  format: "strict_date_optional_time",
                  gte: from,
                  lte: to,
                },
              },
            },
          ],
          should: [],
          must_not: [],
        },
      },
    };
  }
  return {
    aggs: {
      "2": {
        date_range: {
          field: "@timestamp",
          ranges: [
            {
              from: "now-5m",
              to: "now",
            },
            {
              from: "now-15m",
              to: "now",
            },
            {
              from: "now-30m",
              to: "now",
            },
            {
              from: "now-60m",
              to: "now",
            },
            {
              from: "now-3h",
              to: "now",
            },
            {
              from: "now-6h",
              to: "now",
            },
            {
              from: "now-12h",
              to: "now",
            },
            {
              from: "now-24h",
              to: "now",
            },
            {
              from: "now-7d",
              to: "now",
            },
          ],
          time_zone: userTimeZone,
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              "event_type.keyword": type,
            },
          },
          {
            match_phrase: {
              "tenant_name.keyword": tenantName,
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: from,
                lte: to,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default grafanaTimeRangeBody;
