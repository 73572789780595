import { FC, useEffect } from "react";
import Button from "../../../components/common/buttons/Button";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { withLayout } from "../../../helpers/hocs/withLayout";
import { PageProps } from "../../../helpers/types";
import ConnectionsTable from "./components/ConnectionsTable";
import {
  ConnectionContextContainer,
  useConnectionContext,
} from "./ConnectionContext";
import styles from "./ConnectionsContent.module.scss";

const ConnectionsContent: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchConnections, fetchSystems } = useConnectionContext();

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  return (
    <>
      <div className={styles.pageWrapper}>
        <Tabs />
        <ConnectionsTable />
      </div>
    </>
  );
};

export default withContexts<PageProps>(
  withLayout<PageProps>(ConnectionsContent),
  [ConnectionContextContainer]
);
