import { CellProps } from "react-table";

type CommonPfxType = {
  [key: string]: string | number | Array<any>;
  name: string;
};
export const routeFiltersTableColumns = (): any => {
  return [
    {
      Header: "Filter Name",
      accessor: "name",
      id: ({ row }: CellProps<CommonPfxType>) => row.original.id,
      Cell: ({ cell: { value } }: CellProps<CommonPfxType>) => {
        return value || "-";
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof CommonPfxType }));
};
