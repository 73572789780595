import React, { FC } from "react";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import NATRuleInfo from "./common/NATRuleInfo";
import TipsBlock from "./common/TipsBlock";
import ConfigurationContent from "./ConfigurationContent";

import styles from "./NATRuleConfiguration.module.scss";
import NATRuleConfigurationContext from "./NATRuleConfigurationContext";

type Props = {
  onClose: () => void;
  fetchData: (tenant: string) => void;
  data?: any;
};

const NATRuleConfiguration: FC<Props> = ({ onClose, fetchData, data }) => {
  const { selectedTenant } = useGlobalFilterContext();

  return (
    <div className={styles.wrapper}>
      <NATRuleInfo tenant={selectedTenant} className={styles.basicBlock} />
      <ConfigurationContent
        className={styles.basicBlock}
        onClose={onClose}
        fetchData={fetchData}
        data={data}
      />
      <TipsBlock className={styles.basicBlock} />
    </div>
  );
};

export default withContexts<Props>(NATRuleConfiguration, [
  NATRuleConfigurationContext,
]);
