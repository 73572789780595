import { FC, useEffect } from "react";
import LeftBlock from "./components/LeftBlock";
import ContentBlock from "./components/ContentBlock/ContentBlock";
import InfoBlock from "./components/InfoBlock";
import styles from "./SystemConfig.module.scss";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import { SystemAPI } from "../../types";
import { FieldsType } from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { useSystemConfigContext } from "./SystemConfigContext";
import { useValidation } from "../../../../../helpers/validators/Validator";
import { validateSystemNode } from "../../../../../helpers/validators/SystemValidator";
import { cloneDeep } from "lodash";

type Props = {
  onClose: () => void;
  data: any;
  isNode?: boolean;
};

const SystemConfigEdit: FC<Props> = ({ onClose, data, isNode }) => {
  const [fields, handleFieldChange, resetFields] = useFormField<FieldsType>(
    cloneDeep(data)
  );
  const { edit, editStatus } = useSystemConfigContext();

  useEffect(() => {
    if (data) {
      resetFields(cloneDeep(data));
    }
  }, [data]);

  const [errors, validate] = useValidation<FieldsType>(validateSystemNode, [
    fields,
  ]);

  const handleEdit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const success = await edit(fields);
      if (success) onClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <LeftBlock
        className={styles.basicAppearance}
        fields={fields}
        isNode={isNode}
      />
      <ContentBlock
        className={styles.basicAppearance}
        fields={fields}
        onChange={handleFieldChange}
        onClose={onClose}
        onAction={handleEdit}
        actionStatus={editStatus}
        errors={errors}
        isEdit
      />
      <InfoBlock className={styles.basicAppearance} />
    </div>
  );
};
export default SystemConfigEdit;
