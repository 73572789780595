import React from "react";

export class AbstractTimeoutHandler<State, Props = {}> extends React.Component<
  Props,
  State
> {
  protected timeoutsMap: { [key: string]: number | undefined } = {}; // for timeouts
  protected intervalsMap: { [key: string]: number | undefined } = {}; // for timeouts

  constructor(props: Readonly<Props>) {
    super(props);
  }

  componentWillUnmount(): void {
    Object.values(this.timeoutsMap).map((el) => window.clearTimeout(el));
    Object.values(this.intervalsMap).map((el) => window.clearInterval(el));
  }

  setupTimeout = (key: string, func: () => void, time = 5000): void => {
    this.clearTimeout(key);
    this.timeoutsMap[key] = window.setTimeout(func, time);
  };

  clearTimeout = (key: string): void => {
    window.clearTimeout(this.timeoutsMap[key]);
  };

  setInterval = (key: string, func: () => void, time = 5000): void => {
    this.intervalsMap[key] = window.setTimeout(func, time);
  };

  clearInterval = (key: string): void => {
    window.clearInterval(this.intervalsMap[key]);
  };
}
