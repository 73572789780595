import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { CRITICAL } from "../../helpers/common/colorAliases";

function ErrorIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8 11.5c-.4 0-.8.4-.8.8 0 .5.4.8.8.8.5 0 .9-.3.8-.8 0-.4-.3-.8-.8-.8ZM8.6 4.7c-.4.1-.6.5-.6 1v.7l.2 3c0 .3.3.5.6.5.4 0 .6-.2.7-.6V6.8l.2-1.2-.1-.4a.8.8 0 0 0-1-.5Z"
        fill={color || CRITICAL}
      />
      <circle cx="9" cy="9" r="8.5" stroke={CRITICAL} />
    </svg>
  );
}

export default ErrorIcon;
