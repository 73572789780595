import Validator from "./Validator";

export default function validateUserPassword(
  data: any,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEqualPasswords(
    data.newPassword,
    data.confirmPassword,
    "confirmPassword"
  );
  return validator;
}
