import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { GREEN } from "../../helpers/common/colorAliases";

function OkCircleIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1364 15.9104L7.03409 12.7761L6 13.8209L10.1364 18L19 9.04478L17.9659 8L10.1364 15.9104Z"
        fill={color || GREEN}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5039 1.76986C6.57835 1.76986 1.77474 6.57347 1.77474 12.499C1.77474 18.4246 6.57835 23.2282 12.5039 23.2282C18.4295 23.2282 23.2331 18.4246 23.2331 12.499C23.2331 6.57347 18.4295 1.76986 12.5039 1.76986ZM0.316406 12.499C0.316406 5.76805 5.77294 0.311523 12.5039 0.311523C19.2349 0.311523 24.6914 5.76805 24.6914 12.499C24.6914 19.23 19.2349 24.6865 12.5039 24.6865C5.77294 24.6865 0.316406 19.23 0.316406 12.499Z"
        fill={color || GREEN}
      />
    </svg>
  );
}

export default OkCircleIcon;
