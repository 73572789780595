import { VTEPType } from "./../../pages/VTEPPage/types";
import Validator from "./Validator";

export default function validateVTEP(
  data: VTEPType,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.local_ip, "local_ip");
  validator.checkIP(data.local_ip, "local_ip");
  return validator;
}
