import React from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../components/common/formComponents/Input";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";

const InterfaceField = () => {
  const { fields } = useConnectionConfiguratorContext();

  if (fields.connection_interface === "Equinix Fabric") {
    return (
      <Input
        label={"Connection Token"}
        medium
        placeholder="xxxx-xxxx-xxxx-xxxx"
      />
    );
  }

  return (
    <DropdownBasic
      id="connection_interface"
      itemsList={["HQ_Fibre1"].map(mapStringToItem)}
      onChange={() => undefined}
      isMedium
      label="Connection Interface"
    />
  );
};

export default InterfaceField;
