export const PORT_WIDTH = 60;
export const PORT_HEIGHT = 53;
export const COL_GAP = 24;
export const OFFSET_X = 96;
export const OFFSET_Y = 32;
export const ARRAY_LENGTH = 10;
export const PORT_ROWS = [
  Array.from({ length: ARRAY_LENGTH }, (_, index) => index * 2),
  Array.from({ length: ARRAY_LENGTH }, (_, index) => index * 2 + 1),
  Array.from({ length: ARRAY_LENGTH }, (_, index) => index * 2 + 20),
  Array.from({ length: ARRAY_LENGTH }, (_, index) => index * 2 + 21),
];
