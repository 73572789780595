import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LocalIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.65 5.25C2.8768 5.25 2.25 5.8768 2.25 6.65V11.35C2.25 12.1232 2.8768 12.75 3.65 12.75H13.6C14.3732 12.75 15 12.1232 15 11.35V6.65C15 5.8768 14.3732 5.25 13.6 5.25H3.65ZM3.775 6.375C3.55409 6.375 3.375 6.55409 3.375 6.775V11.225C3.375 11.4459 3.55409 11.625 3.775 11.625H13.475C13.6959 11.625 13.875 11.4459 13.875 11.225V6.775C13.875 6.55409 13.6959 6.375 13.475 6.375H3.775Z"
        fill={color || FONT}
      />
      <mask
        id="mask0_231_91"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="2"
        y="6"
        width="13"
        height="6"
      >
        <rect x="2" y="6" width="13" height="6" rx="1.4" fill={color || FONT} />
      </mask>
      <g mask="url(#mask0_231_91)">
        <rect
          x="11.5327"
          y="2.38013"
          width="1.5"
          height="13.4736"
          transform="rotate(45 11.5327 2.38013)"
          fill={color || FONT}
        />
        <rect
          x="13.9548"
          y="5.26062"
          width="1.5"
          height="12.8265"
          transform="rotate(45 13.9548 5.26062)"
          fill={color || FONT}
        />
      </g>
    </svg>
  );
}

export default LocalIcon;
