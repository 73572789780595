import { RemoteUsersProps } from "../../pages/WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/components/RemoteUsersForm";
import Validator from "./Validator";

export default function validateRemoteUsers(
  data: RemoteUsersProps,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.subnet, "subnet");
  validator.checkIP(data.subnet, "subnet");
  validator.checkIP(data.dnsServer[data.dnsServer.length - 1], "dnsServer");
  return validator;
}
