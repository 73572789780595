import React, { FC } from "react";
import styles from "./PBRDescription.module.scss";
import InfoIcon from "../../../components/icons/InfoIcon";

const PBRDescription: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <InfoIcon />
        Information
      </div>
      <p>
        A Policy Based Route allows to make a routing decision based on Source
        IP (also called Source Route), Destination IP, Protocol and Ports.
        Connections matching the policy will be directed to the configured
        Gateway independent from the regular routing table.
      </p>
      <p>
        When “Track gateway” is selected, this Policy Based Route is ignored in
        case the Gateway becomes unavailable, allowing to create a redundant
        Gateway through a second rule based on priority.
      </p>
      <p>
        When “none” is chosen instead of a Gateway, connections matching this
        Policy will be routed according to the standard routing table, even if a
        Policy Based Route matching the traffic is set later (with a higher
        priority).
      </p>
    </div>
  );
};
export default PBRDescription;
