import { Dispatch, SetStateAction } from "react";

export const newFilteredArray = (
  valuesList: Array<string>,
  inputValue?: string
): Array<string> => {
  return valuesList.filter((value: any) =>
    value.toLowerCase().includes(inputValue?.toLowerCase())
  );
};

export function isNotEmpty<P>(arr?: Array<P>): arr is Array<P> {
  return Boolean(arr && arr.length > 0);
}

export const handleInput = (
  val: Array<string>,
  oldList: Array<string>,
  setter: Dispatch<SetStateAction<Array<string>>>,
  fieldName: string,
  clb: (name: string, arr: Array<string>) => void
): void => {
  const newList = oldList;
  newList.push(...val);
  setter(Array.from(new Set(newList)));
  clb(fieldName, newList);
};

export const handleRemoveFromList = (
  val: string,
  oldList: Array<string>,
  setter: Dispatch<SetStateAction<Array<string>>>,
  fieldName: string,
  clb: (name: string, arr: Array<string>) => void
): void => {
  const newList = oldList.filter((item) => item !== val);
  setter(newList);
  clb(fieldName, newList);
};
