import { FC } from "react";
import ConfirmDialog from "../../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import { useSystemProvisioningContext } from "../../ProvisioningContextContainer";

type Props = { onClose: any };

const SwitchNodeDialog: FC<Props> = ({ onClose }) => {
  const {
    selectNode,
    selectedNode,
    switchNodeStatus,
    switchNodeToActive,
  } = useSystemProvisioningContext();

  const handleSwitchNode = async () => {
    const res = await switchNodeToActive(selectedNode);
    if (res) {
      handleCancel();
    }
  };

  const handleCancel = () => {
    selectNode(undefined);
    onClose();
  };

  return (
    <ConfirmDialog
      title={"Switch node to active"}
      message={"Are you sure you want to make this node active?"}
      controls={{
        okText: "Switch",
        onOk: handleSwitchNode,
        onCancel: handleCancel,
      }}
      status={switchNodeStatus}
    />
  );
};
export default SwitchNodeDialog;
