import { FC, useEffect, useState } from "react";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import SystemConfigContext, {
  useSystemConfigContext,
} from "./SystemConfigContext";
import { useSystemProvisioningContext } from "../../ProvisioningContextContainer";
import SystemConfigEdit from "./SystemConfigEdit";
import SystemConfigAdd from "./SystemConfigAdd";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import NodeConfigEdit from "./NodeConfigEdit";

type Props = {
  onClose: () => void;
};

const SystemConfig: FC<Props> = ({ onClose }) => {
  const {
    selectedRow,
    selectRow,
    selectedNode,
    selectNode,
  } = useSystemProvisioningContext();
  const {
    fetchList,
    fetchLocations,
    getSystem,
    system,
  } = useSystemConfigContext();
  const [isEdit, setIsEdit] = useState<boolean | null>(false);

  useEffect(() => {
    fetchList();
    fetchLocations();
  }, []);

  useEffect(() => {
    if (selectedNode) {
      setIsEdit(true);
      return;
    }
    if (selectedRow) {
      setIsEdit(true);
      getSystem(selectedRow);
    }
  }, [selectedRow, selectedNode]);

  const handleClose = () => {
    selectRow(undefined);
    selectNode(undefined);
    onClose();
  };

  if (isEdit) {
    if (!system && !selectedNode) {
      return <PageLoader />;
    }
    if (selectedNode) {
      return (
        <NodeConfigEdit onClose={handleClose} data={selectedNode} isNode />
      );
    }
    return <SystemConfigEdit onClose={handleClose} data={system} />;
  }
  return <SystemConfigAdd onClose={handleClose} />;
};
export default withContexts<Props>(SystemConfig, [SystemConfigContext]);
