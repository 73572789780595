import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { PURPLE } from "../../helpers/common/colorAliases";

function UserIconNarrow({ color }: IconProps): ReactElement {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.41447 8.07241C8.24185 8.07241 9.72338 6.27279 9.72338 4.05283C9.72338 1.83282 9.23697 0.0332031 6.41447 0.0332031C3.59197 0.0332031 3.10547 1.83282 3.10547 4.05283C3.10547 6.27279 4.58699 8.07241 6.41447 8.07241Z"
        fill={color || PURPLE}
      />
      <path
        d="M12.6605 14.0476C12.5992 10.1803 12.0942 9.07839 8.22975 8.38086C8.22975 8.38086 7.68577 9.07412 6.41786 9.07412C5.14995 9.07412 4.60588 8.38086 4.60588 8.38086C0.783608 9.07078 0.247894 10.1564 0.177455 13.9219C0.171679 14.2294 0.169002 14.2456 0.167969 14.2099C0.168204 14.2768 0.168485 14.4005 0.168485 14.6162C0.168485 14.6162 1.08852 16.4711 6.41786 16.4711C11.7471 16.4711 12.6672 14.6162 12.6672 14.6162C12.6672 14.4776 12.6673 14.3812 12.6675 14.3156C12.6664 14.3377 12.6644 14.2949 12.6605 14.0476Z"
        fill={color || PURPLE}
      />
    </svg>
  );
}

export default UserIconNarrow;
