import { Res } from "../../../../helpers/api/Api";
import { configApi } from "../../../../helpers/api/ConfigApi";
import { tenantApi } from "../../../../helpers/api/TenantApi";
import {
  ServiceBGPNeighbor,
  ServiceBGPNeighborWithStatus,
  ServiceBGPStatus,
} from "../../../../helpers/api/apiTypes";
import {
  Connection,
  ConnectionApi,
  ConnectionPortDetails,
  Endpoint,
} from "../types";
import { getBGPStatus } from "./getBGStatus";

type OutputType = {
  list: Array<Connection>;
  listApi: Array<ConnectionApi>;
  portsDetails: {
    [key: string]: {
      ports: Array<ConnectionPortDetails>;
      vpc?: ConnectionPortDetails;
    };
  };
};
export const getDataFromConnections = async (
  tenant: string,
  endpoints: Array<Endpoint>
): Promise<Res<OutputType | undefined>> => {
  const res: Array<Res<any>> = await Promise.all([
    configApi.getConnectionsList(tenant),
    configApi.getServiceNeighborsList(tenant),
    configApi.getServiceBGPStatus(tenant),
    tenantApi.getVirtualInterfacesAllTypes(tenant),
  ]);

  // At least one of the requests is failed
  const failedReq = res[0];
  if (failedReq && !failedReq?.ok) {
    return { ...failedReq, result: undefined };
  }

  // Both requests are ok
  const [connections, bgpNeighbors, bgpStatuses, viList] = res;

  const list: Array<Connection> = [];
  const portsDetails: { [key: string]: any } = {};
  const bgpNeighborsList = addStatusPerNeighbor(
    bgpNeighbors?.result?.items || [],
    bgpStatuses?.result || []
  );

  connections.result
    ?.sort((a: ConnectionApi, b: ConnectionApi) => a.id - b.id)
    .forEach((connection: ConnectionApi) => {
      const bgpNeighbors = getBGPStatus(
        connection,
        bgpNeighborsList,
        viList?.result
      );
      const {
        id,
        name,
        a_endpoint_conf,
        a_endpoint_name,
        z_endpoint_conf,
        z_endpoint_name,
      } = connection;

      const {
        a_endpoint_location,
        z_endpoint_location,
        a_endpoint_type,
        z_endpoint_type,
      } = getLocationsName(endpoints || [], a_endpoint_name, z_endpoint_name);

      const formattedConnection = {
        id,
        name,
        a_endpoint_name,
        z_endpoint_name,
        a_endpoint_type,
        z_endpoint_type,
        a_endpoint_location,
        z_endpoint_location,
        a_endpoint_bw: a_endpoint_conf?.BW,
      };

      const aPort = {
        name,
        endpoint_type: a_endpoint_type,
        config: {
          endpoint_name: a_endpoint_name,
          endpoint_location: a_endpoint_location,
          ...a_endpoint_conf,
        },
      };
      if (z_endpoint_conf.ip_addresses) {
        Object.assign(aPort.config, {
          ip_addresses: z_endpoint_conf.ip_addresses,
        });
      }
      if (z_endpoint_name === "Cloud_Router") {
        Object.assign(aPort, {
          bgpNeighbors,
        });
      }

      const zPort = {
        name,
        endpoint_type: z_endpoint_type,
        config: {
          endpoint_name: z_endpoint_name,
          endpoint_location: z_endpoint_location,
          ...z_endpoint_conf,
        },
      };

      // No children
      if (!connection.labels?.parentConnection) {
        list.push(formattedConnection);
        portsDetails[name] = { ports: [aPort, zPort], vpc: undefined };
        return;
      }

      // Has childrens
      const parentConnection = connection.labels?.parentConnection;
      if (!list.find((item) => item.name === parentConnection)) {
        list.push({ ...formattedConnection, name: parentConnection });
        portsDetails[parentConnection] = { ports: [aPort], vpc: zPort };
        return;
      }
      // Add children zPort
      portsDetails[parentConnection]?.ports.push(aPort);
    });

  return {
    ...connections,
    result: { list, portsDetails, listApi: connections.result },
  };
};

const getLocationsName = (
  endpoints: Array<Endpoint>,
  a_endpoint_name: string,
  z_endpoint_name: string
): {
  a_endpoint_location: string;
  z_endpoint_location: string;
  a_endpoint_type: string;
  z_endpoint_type: string;
} => {
  let a_endpoint_location = "";
  let z_endpoint_location = "";
  let a_endpoint_type = "";
  let z_endpoint_type = "";

  endpoints.forEach((el) => {
    if (el.name === a_endpoint_name) {
      a_endpoint_location = el.location_name;
      a_endpoint_type = el.type;
    }
    if (el.name === z_endpoint_name) {
      z_endpoint_location = el.location_name;
      z_endpoint_type = el.type;
    }
  });

  return {
    a_endpoint_location,
    z_endpoint_location,
    a_endpoint_type,
    z_endpoint_type,
  };
};

const addStatusPerNeighbor = (
  neighbors: Array<ServiceBGPNeighbor>,
  status: ServiceBGPStatus
): Array<ServiceBGPNeighborWithStatus> => {
  return neighbors.map((neighbor) => ({
    ...neighbor,
    status: status?.peers?.[neighbor.remote_router_ip],
  }));
};
