import React, { FC } from "react";
import Button from "../../../../../components/common/buttons/Button";
import PlusIcon from "../../../../../components/icons/PlusIcon";
import { ACCENT } from "../../../../../helpers/common/colorAliases";
import styles from "./styles.module.scss";

export const AddRuleButton: FC<{ onClick?: () => void }> = ({
  onClick = () => undefined,
}) => {
  return (
    <Button onClick={onClick} className={styles.button}>
      <PlusIcon color={ACCENT} />
    </Button>
  );
};
