import React, { FC } from "react";
import { SystemsTabs } from "../../components/common/navigation/SystemsTabs";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import styles from "./CloudResourcesPage.module.scss";

import CloudResourcesContent from "./CloudResourcesContent";

const CloudResourcesPage: FC = () => {
  return (
    <div className={styles.wrapper}>
      <SystemsTabs />
      <CloudResourcesContent />
    </div>
  );
};

export default withLayout<PageProps>(CloudResourcesPage);
