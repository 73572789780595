import { FC } from "react";
import styles from "./LeftBlock.module.scss";
import { FieldsType } from "../../../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import TenantsIcon from "../../../icons/tabs/TenantsIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import UnifiedTextPair from "../../../common/UnifiedTextPair";

type Props = {
  className: string;
  fields: FieldsType;
  isNode?: boolean;
  onAction?: (value: any) => void;
};
const LeftBlock: FC<Props> = ({ className, fields }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <>
        <div className={styles.title}>
          <TenantsIcon color={GREY_FONT} className={styles.icon} />
          <span>{"Tenant"}</span>
        </div>
        <div className={styles.infoLines}>
          <UnifiedTextPair
            subtitle="Tenant Full Name"
            text={fields?.full_name || "-"}
          />
          <UnifiedTextPair subtitle="Tenant name" text={fields?.name || "-"} />
          <UnifiedTextPair
            subtitle="Package admin name"
            text={fields?.first_name || "-"}
          />
          <UnifiedTextPair
            subtitle="Admin username"
            text={fields?.username || "-"}
          />
          <UnifiedTextPair
            subtitle="Email address"
            text={fields?.email || "-"}
          />
        </div>
      </>
    </div>
  );
};
export default LeftBlock;
