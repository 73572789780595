import React, { FC, useMemo } from "react";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import { CONNETIONS_TYPES_MAP } from "../../utils/connectionFormsConsts";
import styles from "./FormTitle.module.scss";

const FormTitle: FC = () => {
  const { activeConnectionType } = useConnectionsContext();

  const type = useMemo(() => {
    return CONNETIONS_TYPES_MAP.find((type) => type.id === activeConnectionType)
      ?.name;
  }, [activeConnectionType]);
  return <div className={styles.title}>{`Add ${type}`}</div>;
};
export default FormTitle;
