import React, { FC } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import UnifiedTextPair from "../../../../../components/common/UnifiedTextPair";
import NATIcon from "../../../../../components/icons/NATIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import styles from "./NATRuleInfo.module.scss";

type Props = { className: string; tenant?: string };

const NATRuleInfo: FC<Props> = ({ className, tenant }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={<NATIcon color={GREY_FONT} />}
        text={"NAT Rule"}
        className={styles.header}
      />
      <div className={styles.content}>
        <UnifiedTextPair
          subtitle={"Tenant"}
          text={tenant}
          textSize={"medium"}
        />
      </div>
    </div>
  );
};

export default NATRuleInfo;
