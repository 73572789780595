import { FieldsType } from "../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import Validator from "./Validator";

export default function validateConnection(
  data: {
    name: string;
    connections: Array<FieldsType>;
    isCloudRouter: boolean;
  },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkNameFormat(data.name, "name");
  data.connections.forEach((connection, i) => {
    const connectionFields = findField(connection, data.isCloudRouter);
    Object.keys(connectionFields).forEach((field) => {
      findValidator(validator, connectionFields, field, i);
    });
  });
  return validator;
}

const findValidator = (
  validator: Validator,
  data: FieldsType,
  field: string,
  i: number
) => {
  switch (field) {
    case "location":
      return validator.checkEmpty(data.location, i + "location");
    case "BW":
      return validator.checkEmpty(data.BW, i + "BW");
    case "Phisical_Port":
      return validator.checkEmpty(data.Phisical_Port, i + "Phisical_Port");
    case "VLAN_ID":
      return data.Tagged && validator.checkEmpty(data.VLAN_ID, i + "VLAN_ID");
    case "ip_addresses":
      if (!data.ip_addresses) {
        return validator.checkEmpty(data.ip_addresses, i + "ip_addresses");
      }
      return validator.checkIPWithPrefix(data.ip_addresses, i + "ip_addresses");
    case "Customer_ID":
      if (data.Customer_ID === null) return;
      return (
        data?.Connection_type === "directConnect" &&
        validator.checkEmpty(data.Customer_ID, i + "Customer_ID")
      );
    case "Subnet":
      if (!data.Subnet) {
        return validator.checkEmpty(data.Subnet, i + "Subnet");
      }
      return validator.checkIPWithPrefix(data.Subnet, i + "Subnet");
    case "GW":
      return validator.checkEmpty(data.GW, i + "GW");
  }
};

const findField = (fields: FieldsType, isCloudRouter: boolean): FieldsType => {
  if (!isCloudRouter || fields.hasOwnProperty("ip_addresses")) return fields;
  return { ...fields, ip_addresses: undefined };
};
