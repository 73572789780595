import React, { FC, useEffect } from "react";
import StatisticsBody from "./components/StatisticsBody";
import styles from "./DNSStatistics.module.scss";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useTenantContext } from "../../../../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import ServicesRedirectPlaceholder from "../../../../../components/ServicesRedirectPlaceholder";
import CyberThreadsAndDNSButtons from "../../../../ApplicationsDashboard/components/Buttons/CyberThreadsAndDNSButtons";

type Props = { showSubmenu?: boolean };

const DNSStatistics: FC<Props> = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      {servicesStatus && !servicesStatus.dns.overall ? (
        <ServicesRedirectPlaceholder
          type="dns"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.dns.adminSide}
        />
      ) : (
        <div className={styles.wrapper}>
          <CyberThreadsAndDNSButtons />
          <StatisticsBody />
        </div>
      )}
    </>
  );
};

export default DNSStatistics;
