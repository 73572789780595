import { FC, useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { labelizeBytes } from "../utils";
import styles from "../Pie.module.scss";
import { arubaApi } from "../../../helpers/api/ArubaApi";

ChartJS.register(ArcElement, Tooltip, Legend);

type Props = {
  className: string;
  list: Array<any>;
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
      labels: {
        generateLabels: (chart: any) => {
          const original =
            ChartJS.overrides.pie.plugins.legend.labels.generateLabels;
          const labelsOriginal = original.call(this, chart);

          let datasetColors = chart.data.datasets.map((e: any) => {
            return e.backgroundColor;
          });

          datasetColors = datasetColors.flat();

          labelsOriginal.forEach((label: any) => {
            label.datasetIndex = (label.index - (label.index % 2)) / 2;
            label.hidden = !chart.isDatasetVisible(label.datasetIndex);
            label.fillStyle = datasetColors[label.index];
          });

          return labelsOriginal;
        },
      },
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          return labelizeBytes(context.raw);
        },
      },
    },
  },
};

const OverlayInterface: FC<Props> = ({ className, list }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>({});

  const devices = list.map((item) => item.id).join();

  useEffect(() => {
    if (!list.length) return;

    if (!isLoading) {
      setLoading(true);
    }

    const payload = list.map((item) => {
      return { nePk: item.id };
    });

    arubaApi
      .getInterfaceOverlay({ granularity: "day" }, payload)
      .then((res) => {
        const json = res.result || [];
        json.pop();

        const passThrough: any = {
          label: "Pass Through",
          total: 0,
          values: [],
        };
        const defaultOverlay: any = {
          label: "Default Overlay",
          total: 0,
          values: [],
        };
        const realTime: any = { label: "Real Time", total: 0, values: [] };
        const criticalApps: any = {
          label: "Critical Apps",
          total: 0,
          values: [],
        };
        const bulkApps: any = { label: "Bulk Apps", total: 0, values: [] };

        const overlays = [
          passThrough,
          realTime,
          criticalApps,
          bulkApps,
          defaultOverlay,
        ];

        json.forEach((item) => {
          const [values] = Object.values(item) as Array<Array<Array<number>>>;

          values.forEach((value) => {
            const overlay = value[13];
            const label = value[18];
            const bytes = value[21];

            if (!label || !bytes) return;

            const old = overlays[overlay]?.values.find(
              (o: any) => o.label === label
            );

            if (old) {
              old.bytes += bytes;
            } else {
              overlays[overlay]?.values.push({ label, bytes });
            }
          });
        });

        overlays.forEach((overlay) => {
          overlay.values.forEach((value: any) => {
            overlay.total += value.bytes;
          });
        });

        overlays.sort((a, b) => {
          if (a.total < b.total) {
            return 1;
          } else if (a.total > b.total) {
            return -1;
          }
          return 0;
        });

        const labels: Array<string> = [];
        const labelsInner: Array<string> = [];
        const labelsOuter: Array<string> = [];
        const datasets: Array<any> = [
          {
            label: "Outer",
            data: [],
            backgroundColor: ["#6a3d9a", "#ff7f00", "#fb9a99"],
          },
          {
            label: "Inner",
            data: [],
            backgroundColor: [
              "#33A02C",
              "#045A8D",
              "#1F78B4",
              "#B2DF8A",
              "#D95F02",
            ],
          },
        ];

        overlays.forEach((overlay) => {
          datasets[1].data.push(overlay.total);

          overlay.values.forEach((value: any) => {
            labels.push(value.label);
            datasets[0].data.push(value.bytes);

            if (!labelsOuter.includes(value.label)) {
              labelsOuter.push(value.label);
            }
          });

          if (!labelsInner.includes(overlay.label)) {
            labelsInner.push(overlay.label);
          }
        });

        overlays.forEach((overlay) => {
          labels.push(overlay.label);
        });

        setData({ datasets, labels, labelsInner, labelsOuter });
        setLoading(false);
      });
  }, [devices]);

  if (!data.datasets) return null;

  return (
    <div className={className} data-loading={isLoading}>
      <h4>Overlay-Interface</h4>
      <div className={styles.pie}>
        <div>
          <Pie data={data} options={options} />
        </div>

        <div>
          <h5>Overlays (Inner)</h5>
          <ul>
            {data.labelsInner.map((label: string, i: number) => {
              return <li key={i}>{label}</li>;
            })}
          </ul>

          <h5>Interface (Outer)</h5>
          <ul>
            {data.labelsOuter.map((label: string, i: number) => {
              return <li key={i}>{label}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OverlayInterface;
