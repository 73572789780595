import React, { ReactElement } from "react";
import { WHITE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function AddIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0H5v5H0v2h5v5h2V7h5V5H7V0Z"
        fill={color || WHITE}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0H5v5H0v2h5v5h2V7h5V5H7V0Z"
        fill={color || WHITE}
      />
    </svg>
  );
}

export default AddIcon;
