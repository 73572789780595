import React, { FC } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { classNames } from "../../../../helpers/common/classNames";
import { useApplicationsDashboardContext } from "../../ApplicationObservabilityContext";
import { getApplicationLink } from "../../common/appStatisticsHelper";
import { APPLICATION_FIELD_VALUE } from "../../consts";
import styles from "./RightPart.module.scss";

type Props = {
  className?: string;
};

const RightPart: FC<Props> = ({ className }) => {
  const { filters } = useApplicationsDashboardContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.chartWrapper}>
        <GrafanaGraph
          link={getApplicationLink(
            10,
            timeRange.general,
            selectedTenant,
            filters,
            APPLICATION_FIELD_VALUE
          )}
        />
      </div>
    </div>
  );
};

export default RightPart;
