import React from "react";
import InputWithList from "../../../../../../components/common/formComponents/InputWithList";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";

const RoutingRemoteNetworkField = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  return (
    <InputWithList
      onAdd={(v) =>
        fieldsOnChange(
          "remote_networks",
          fields.remote_networks ? [...fields.remote_networks, ...v] : [...v]
        )
      }
      onRemove={(v) =>
        fieldsOnChange(
          "remote_networks",
          fields.remote_networks
            ? (fields.remote_networks as Array<string>).filter((n) => n !== v)
            : []
        )
      }
      valuesList={fields.remote_networks || []}
      placeholder="Remote Networks"
      label={"Remote Networks"}
      medium
      searchable
    />
  );
};

export default RoutingRemoteNetworkField;
