import React, { FC, ReactNode } from "react";

import styles from "./SectionHeader.module.scss";

type Props = {
  title?: string | ReactNode;
  icon?: ReactNode;
  onClick?: () => void;
};

export const SectionHeader: FC<Props> = ({
  title,
  children,
  icon,
  onClick,
}) => {
  return (
    <div className={styles.header} onClick={onClick}>
      {icon}
      {children || title}
    </div>
  );
};
