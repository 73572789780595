import React, { FC, useState } from "react";
import TabSelector, {
  SelectorTabType,
} from "../../../../../../../components/common/selector/Selector";
import { classNames } from "../../../../../../../helpers/common/classNames";
import styles from "./ServicesCharts.module.scss";

type Props = {};

const ServicesCharts: FC<Props> = () => {
  const [currentTab, setCurrentTab] = useState<SelectorTabType>(tabs[0]);
  return (
    <div className={classNames(styles.wrapper)}>
      <TabSelector
        tabs={tabs as any}
        activeTabId={currentTab.id}
        onTabSelect={(val) => setCurrentTab(val)}
        isLongTab
        className={styles.tab}
      />
      <div className={styles.bar}>
        Bar per FW rule traffic over the selected period
      </div>
      <div className={styles.tableThreats}>
        Table Top X Threats
        <br /> per segment per location per connection <br />
        Pie of total unique threats per location and per segment
      </div>

      <div className={styles.tableApp}>
        Table Top X Application per segment per location per connection
      </div>
    </div>
  );
};

export default ServicesCharts;

const tabs: Array<SelectorTabType> = [
  { id: "incoming", name: "Incoming" },
  { id: "outcoming", name: "Outcoming" },
];
