import React, { FC } from "react";

import { useConnectionsContext } from "../../../../ConnectionsContext";
import AddCloudForm from "./AddCloudForm";
import EditCloudForm from "./EditCloudForm";

const CloudForm: FC = () => {
  const { activeConnection } = useConnectionsContext();
  return <>{activeConnection ? <EditCloudForm /> : <AddCloudForm />}</>;
};
export default CloudForm;
