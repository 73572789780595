// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CopyIcon from "../../../../components/icons/CopyIcon";
import styles from "./Code.module.scss";

export const Code = ({ children }: { children: string }) => {
  return (
    <div className={styles.code}>
      {children}
      <CopyToClipboard text={children}>
        <div className={styles.copy}>
          <CopyIcon />
        </div>
      </CopyToClipboard>
    </div>
  );
};
