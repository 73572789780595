import { createContextAndUse } from "../../../../contexts/common/AbstractCrudContext";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { RequestStatus } from "../../../../helpers/types";
import { configApi } from "../../../../helpers/api/ConfigApi";
import {
  VirtualInterface,
  VirtualInterfaceApi,
} from "../../../../pages/VirtualInterfacePage/types";
import { Res } from "../../../../helpers/api/Api";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = { list?: Array<VirtualInterfaceApi> };

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (
    vrf: { name: string; vrf_type: string },
    system?: { name: string }
  ) => void;
};

const [VIListContext, useContext] = createContextAndUse<IState, IFunc>();
export const useVIListContext = useContext;

export default class VIListContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = VIListContext;

  fetchList = async (
    vrf: { name: string; vrf_type: string },
    system?: { name: string }
  ): Promise<void> => {
    let query: Promise<Res<Array<VirtualInterface | VirtualInterfaceApi>>>;
    if (vrf.vrf_type === "tenant" || !system) {
      query = configApi.getAllVirtualInterfacesByTenant(vrf.name);
    } else if (system) {
      query = configApi.getVirtualInterfacesApiList(system.name, vrf.name);
    }
    const { ok, result } = await this._fetchWithStatus(
      () => query,
      "listStatus"
    );
    this.setState({
      list: ok ? (result as Array<VirtualInterfaceApi>) : undefined,
    });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
