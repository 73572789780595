/* eslint-disable css-modules/no-unused-class */
import React, { FC, ReactElement, useCallback, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside";
import DropDownIcon from "../../icons/DropDownIcon";
import WarningIcon from "../../icons/WarningIcon";
import BlankButton from "../buttons/BlankButton";
import styles from "./DropDownInput.module.scss";
import { OptionalLabel } from "../OptionalLabel";
import { ORANGE } from "../../../helpers/common/colorAliases";

export function mapStringToItem(val: string): DropdownItem {
  return { key: val };
}

export type DropdownItem = { value?: any; key: string };
type DropDownInputProps = {
  itemsList: Array<DropdownItem>;
  onChange: (item: DropdownItem) => void;
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  selected: DropdownItem;
  isSmaller?: boolean;
  isMedium?: boolean;
  isNotFormElement?: boolean;
  icon?: ReactElement;
  isPseudoBtnMode?: boolean;
  customDropdownIcon?: ReactElement;
  disabled?: boolean;
  onBlur?: () => void;
  id: string;
};

const DropDownInput: FC<DropDownInputProps> = (props) => {
  const {
    onChange,
    itemsList,
    className,
    label,
    error,
    selected,
    isSmaller,
    isMedium,
    isNotFormElement,
    placeholder,
    icon,
    isPseudoBtnMode,
    customDropdownIcon,
    disabled,
    onBlur,
    id,
    ...restProps
  } = props;
  const [isListHidden, setIsListHidden] = useState(true);

  const dropDownRef = useClickOutside(() => setIsListHidden(true));

  const handleSelect = useCallback((item: DropdownItem): void => {
    onChange(item);
    setIsListHidden(true);
  }, []);

  const renderValueItems = () => {
    return itemsList.map((it) => (
      <BlankButton
        id={it.key}
        className={styles.listItem}
        key={it.key}
        onClick={() => handleSelect(it)}
      >
        {it.value || it.key}
      </BlankButton>
    ));
  };

  const dropDownList = classNames(
    styles.dropDownList,
    isListHidden && styles.hiddenList,
    isSmaller && styles.smaller,
    isMedium && styles.medium,
    isNotFormElement && styles.notFormElement
  );
  const dropDownIcon = classNames(
    styles.dropDownIcon,
    !isListHidden && styles.rotateIcon
  );

  const getFieldValue = () => {
    return (
      <div
        ref={dropDownRef as React.RefObject<HTMLDivElement>}
        className={styles.dropDownWrapper}
        onBlur={onBlur}
      >
        <BlankButton
          id={id}
          onClick={() => setIsListHidden(!isListHidden)}
          className={classNames(
            styles.dropDownContainer,
            isSmaller && styles.smaller,
            isMedium && styles.medium,
            isPseudoBtnMode && styles.btnStyles
          )}
          disabled={disabled}
        >
          {icon}
          <div className={styles.text}>
            {selected.value || selected.key || placeholder}
          </div>
          {customDropdownIcon || <DropDownIcon className={dropDownIcon} />}
        </BlankButton>
        <div className={dropDownList}>{renderValueItems()}</div>
      </div>
    );
  };

  return (
    <OptionalLabel
      className={classNames(className, styles.dropDownLabel)}
      isShown={Boolean(label)}
      text={label}
      {...restProps}
    >
      <div
        data-cy="input-wrapper"
        style={{ width: "100%", position: "relative" }}
      >
        {getFieldValue()}
        {error && <Error error={error} />}
      </div>
    </OptionalLabel>
  );
};

export default DropDownInput;

const Error: FC<{ error: string }> = ({ error }) => {
  return (
    <div className={styles.error}>
      {error && <WarningIcon color={ORANGE} />}
      {error && (
        <span className={styles.errorText} title={error} data-cy="input-error">
          {error}
        </span>
      )}
    </div>
  );
};
