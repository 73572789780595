import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import FirewallIcon from "../../../components/icons/tabs/FirewallIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { useFirewallContext } from "../../../contexts/servicesContext/FirewallContext";
import { FWRuleType } from "../../../helpers/api/apiTypes";
import { formatDate } from "../../../helpers/formatDate";

type Props = {
  param: string;
};

const FirewallRuleInfo: FC<Props> = ({ param }) => {
  const { selectedFwRule } = useFirewallContext();

  return (
    <SummaryInfoBlock icon={<FirewallIcon />} title="FW">
      {selectedFwRule && (
        <RuleInfoSection rule={selectedFwRule} tenant={param} />
      )}
    </SummaryInfoBlock>
  );
};
export default FirewallRuleInfo;

type FWInfoProps = {
  rule: FWRuleType;
  tenant: string;
};

const EMPTY_SECTION_VALUE = "-";

const RuleInfoSection = ({ rule, tenant }: FWInfoProps) => {
  return (
    <>
      <UnifiedTextPair subtitle="Rule" text={rule.name} />
      <UnifiedTextPair subtitle="Tenant" text={tenant} />
      <UnifiedTextPair
        subtitle="Created by"
        text={rule.username || EMPTY_SECTION_VALUE}
      />
      <UnifiedTextPair
        subtitle="User IP"
        text={rule.user_ip_address || EMPTY_SECTION_VALUE}
      />
      <UnifiedTextPair
        subtitle="Created at"
        text={formatDate(rule.created_at)}
      />
      <UnifiedTextPair
        subtitle="Updated at"
        text={formatDate(rule.updated_at)}
      />
    </>
  );
};
