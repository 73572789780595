import { FC, useEffect, useState } from "react";
import { useNiroContext } from "../../../../../contexts/NiroContext";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import ConfirmDialog from "../../../../dialogs/common/confirmDialog/ConfirmDialog";
import UploadConfigDialog from "../../../../dialogs/UploadConfigDialog";
import ConfigRestoreIcon from "../../../../icons/configIcons/ConfigRestoreIcon";
import ConfigSaveIcon from "../../../../icons/configIcons/ConfigSaveIcon";
import RestartIcon from "../../../../icons/configIcons/RestartIcon";
import { eraseMsg } from "../../../../systems/oneSystem/SystemInfo/SystemControlsTooltip";
import styles from "./NiroConfiguration.module.scss";
import LoaderIcon from "../../../../common/loadStates/LoaderIcon";

type Props = {};

const NiroConfiguration: FC<Props> = ({}) => {
  const {
    resetStatus,
    factoryReset,
    exportConfig,
    exportStatus,
    importStatus,
  } = useNiroContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    const { state } = resetStatus;

    if (state === "ok") {
      setShowConfirm(false);
    }
  }, [resetStatus]);

  return (
    <>
      {showUpload && (
        <UploadConfigDialog onClose={() => setShowUpload(false)} />
      )}
      {showConfirm && (
        <ConfirmDialog
          className={styles.confirmDialog}
          title={"Factory reset"}
          icon={RestartIcon}
          message={eraseMsg}
          controls={{
            okText: "Continue",
            onOk: factoryReset,
            onCancel: () => setShowConfirm(false),
          }}
          status={resetStatus}
        />
      )}
      <div className={styles.wrapper}>
        <IconBlankButton
          id={"niroConfigSaveConfiguration"}
          icon={exportStatus.state === "pending" ? LoaderIcon : ConfigSaveIcon}
          label={"Save configuration"}
          onClick={() => exportConfig()}
          className={styles.btn}
          iconClassName={styles.loader}
        />
        <IconBlankButton
          id={"niroConfigRestoreConfiguration"}
          icon={
            importStatus.state === "pending" ? LoaderIcon : ConfigRestoreIcon
          }
          label={"Restore configuration"}
          onClick={() => setShowUpload(true)}
          className={styles.btn}
          iconClassName={styles.loader}
        />
        <IconBlankButton
          id={"niroConfigFactoryReset"}
          icon={RestartIcon}
          label={"Factory reset"}
          onClick={() => setShowConfirm(true)}
          className={styles.btn}
        />
      </div>
    </>
  );
};

export default NiroConfiguration;
