import React, { FC, useState } from "react";
import { CreatePBM } from "./PBMConfig";
import DropdownWithSearch from "../../../components/common/Dropdown/DropdownWithSearch";
import { DropdownItem } from "../../../helpers/types";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import styles from "./SourceBlock.module.scss";
import { getPortsRangeByBitMask } from "./getEndOfRangeByBitMask";
import { CreatePBR } from "../../PBRPage/PBRConfig/PBRConfig";
import { CreateFWRule } from "../../Firewall/config/FirewallRuleConfig";

type CommonCreateType = CreatePBR | CreatePBM | CreateFWRule;

type Props = {
  fields: CommonCreateType;
  name: string;
  errors: any;
  label?: string;
  onChange: (value: string, name: string) => void;
  className?: string;
};

const LOW_PORTS = "0/65472";
const LOW_PORTS_LABEL = "Low";

const PortDropdown: FC<Props> = ({
  fields,
  errors,
  onChange,
  name,
  label,
  className,
}) => {
  const [selected, setSelected] = useState<string>(
    (fields?.[name as keyof CommonCreateType] as string) || ""
  );
  const handleChange = (val: DropdownItem | string) => {
    if (typeof val === "string") {
      setSelected(val);
    } else {
      setSelected(val.key === LOW_PORTS_LABEL ? LOW_PORTS : val.key);
    }
    onChange(name, selected);
  };

  return (
    <DropdownWithSearch
      id={label || ""}
      placeholder={selected || "xxx"}
      label={label}
      onChange={handleChange}
      selected={
        mapStringToItem(fields?.[name as keyof CommonCreateType] as string) ||
        ""
      }
      error={errors && errors?.[name]}
      valuesList={[LOW_PORTS_LABEL]}
      isMedium
      listClassName={styles.sourceWrapper}
      isOptionalLabel
      className={className}
      withClearIcon
    />
  );
};
export default PortDropdown;
