import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LogsIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path
          d="M11 2H3C2.44772 2 2 2.44772 2 3V17C2 17.5523 2.44772 18 3 18H11"
          stroke={color || GREY_FONT}
          strokeWidth="1.4"
        />
        <path
          d="M7 10H17M17 10L13 6M17 10L13 14"
          stroke={color || GREY_FONT}
          strokeWidth="1.4"
        />
      </g>
    </svg>
  );
}

export default LogsIcon;
