import React, { FC } from "react";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import UnifiedTextPair from "../../../../components/common/UnifiedTextPair";
import IPAMIcon from "../../../../components/icons/zayo/menu/IPAMIcon";
import { classNames } from "../../../../helpers/common/classNames";
import DialogFrame from "../../common/DialogFrame/DialogFrame";
import { DialogComponentProps } from "../../common/types";
import { CreateIpam } from "../../types";
import styles from "./DialogLeft.module.scss";

type Props = {
  fields: CreateIpam;
};

const DialogLeft: FC<DialogComponentProps & Props> = ({
  className,
  fields,
}) => {
  const type = fields.type;

  const typeName = type === "location" ? "Location" : "System";

  return (
    <DialogFrame className={classNames(className, styles.frame)}>
      <TextWithIcon
        text={"IPAM"}
        icon={<IPAMIcon color="#59536B" className={styles.ipamIcon} />}
        className={styles.dialogHeader}
      />
      <div className={styles.content}>
        <UnifiedTextPair subtitle="Pool Name" text={fields.name} />
        <UnifiedTextPair subtitle={typeName} text={fields.data || ""} />
        <UnifiedTextPair subtitle="Tenants" text={"0"} />
        <UnifiedTextPair subtitle="Usage (free/total)" text={"not stated"} />
      </div>
    </DialogFrame>
  );
};

export default DialogLeft;
