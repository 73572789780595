import React, { ReactElement, useState } from "react";
import { useUserContext } from "../../../contexts/UserContext";
import { GREY_FONT, ORANGE } from "../../../helpers/common/colorAliases";
import TextWithIcon from "../../common/table/TextWithIcon";
import StatusIcon from "../../icons/StatusIcon";
import UserIcon from "../../icons/UserIcon";
import styles from "./UserLabel.module.scss";
import UserEditDialog from "../../dialogs/UserEditDialogs/UserEditDialog";
import { UserRoles, UserSecondRoles } from "../../../helpers/rolesHelpers";

const UserLabel = (): ReactElement => {
  const { user, isZayo } = useUserContext();
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const visibleName = user?.secondName
    ? `${user.secondName}@${user.name}`
    : user?.name;

  const userIcon = isZayo ? (
    <UserIcon color={GREY_FONT} className={styles.statusIcon} />
  ) : (
    <StatusIcon color={ORANGE} className={styles.statusIcon} />
  );

  const handleClick = () => setShowEditDialog(true);
  const isTenant = user?.role === UserRoles.TENANT;
  const notRemote = user?.secondRole != UserSecondRoles.REMOTE_USER;
  return (
    <>
      {showEditDialog && (
        <UserEditDialog onClose={() => setShowEditDialog(false)} />
      )}
      <TextWithIcon
        icon={userIcon}
        text={visibleName}
        className={styles.userName}
        onClick={isTenant && notRemote ? handleClick : undefined}
      />
    </>
  );
};

export default UserLabel;
