import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import CaretBig from "../../icons/CaretBig";
import BlankButton from "./BlankButton";
import styles from "./CollapsibleButton.module.scss";

type Props = {
  color?: string;
  className?: string;
  toCollapse: boolean;
  onClick: () => void;
  isSmall?: boolean;
  id: string;
};
const CollapsibleButton: FC<Props> = ({
  color,
  className,
  toCollapse,
  onClick,
  isSmall,
  id,
}) => {
  return (
    <BlankButton
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      id={id}
      className={classNames(styles.wrapper, className)}
    >
      <CaretBig
        color={color}
        className={classNames(
          styles.icon,
          toCollapse && styles.rotate,
          isSmall && styles.smallSize
        )}
      />
    </BlankButton>
  );
};
export default CollapsibleButton;
