import { FC } from "react";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import WireGuardContextContainer, {
  useWireGuardContext,
} from "../WireGuardContext";
import QRCodeGenerator from "../../QRCodeGenerator/QRCodeGenerator";

type Props = {
  user?: string;
  tenant?: string;
};

const WireGuardQRCode: FC<Props> = ({ user, tenant }) => {
  const {
    fetchDataNew,
    wireGuardSettings,
    fetchStatus,
  } = useWireGuardContext();

  const handleClick = () => {
    if (tenant && user) {
      fetchDataNew(tenant, user);
    }
  };

  return (
    <QRCodeGenerator
      value={wireGuardSettings}
      status={fetchStatus}
      onClick={handleClick}
      user={user}
    />
  );
};

export default withContexts<Props>(WireGuardQRCode, [
  WireGuardContextContainer,
]);
