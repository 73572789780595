import { configApi } from "../../../helpers/api/ConfigApi";
import { SearchResponse } from "../types";

export const fetchNatRulesByName = async (
  rules: Array<SearchResponse>,
  tenant: string
): Promise<Array<any>> => {
  const natRules: Array<any> = [];
  await Promise.all(
    rules.map(
      async (rule): Promise<any> => {
        const res = await configApi.getNatRule(tenant, rule.key);
        if (res.ok && res.result) natRules.push(res.result);
      }
    )
  );
  return natRules;
};
