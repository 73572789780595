import React, { FC } from "react";
import ZayoLogo from "../../../../../../components/icons/zayo/ZayoLogo";
import ElanIcon from "../../../../../../components/icons/ElanIcon";
import CloudRouterIcon from "../../../../../../components/icons/zayo/menu/CloudRouterIcon";
import { GREY_DISABLED } from "../../../../../../helpers/common/colorAliases";
import { useUserContext } from "../../../../../../contexts/UserContext";
import LogoElementIcon from "../../../../../../components/icons/LogoElementIcon";

type Props = { x: number; y: number; name: string; radius: number };

const MidpointConnection: FC<Props> = ({ x, y, name, radius }) => {
  const { isDemo } = useUserContext();
  const Icon = name === "Cloud_Router" ? CloudRouterIcon : ElanIcon;
  return (
    <g>
      <title>{name}</title>
      <circle cx={x / 2} cy={y / 2} r={radius} fill="#fff" stroke="#BEB8EA" />
      <Icon
        x={x / 2 - 12}
        y={y / 2 - 24}
        color={GREY_DISABLED}
        width={24}
        height={24}
      />
      {isDemo ? (
        <LogoElementIcon x={x / 2} y={y / 2 + 8} />
      ) : (
        <ZayoLogo x={x / 2} y={y / 2 + 8} />
      )}
    </g>
  );
};
export default MidpointConnection;
