export const getBGPStatusType = (status: string): string => {
  switch (status) {
    case "Absent":
      return "Absent";
    case "Established":
      return "Established";
    default:
      return "Disconnect";
  }
};
