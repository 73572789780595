import { Text } from "@mantine/core";
import React from "react";
import ReactTooltip from "react-tooltip";
import IconBlankButton from "../../components/common/buttons/IconBlankButton";
import InfoIcon from "../../components/icons/InfoIcon";
import { FWRuleType } from "../../helpers/api/apiTypes";

import styles from "./CellHoverTooltip.module.scss";

type Props = {
  rowOriginal: FWRuleType;
};

const EMPTY_VALUE = "-";

export const CellHoverTooltip = ({ rowOriginal }: Props) => {
  const { username, user_ip_address } = rowOriginal;
  const tooltipId = `fw-${rowOriginal.id}`;

  return (
    <>
      <IconBlankButton
        data-tip
        data-for={tooltipId}
        id={`hover${rowOriginal.id}`}
        icon={InfoIcon}
      />
      <ReactTooltip
        aria-haspopup="true"
        place="left"
        type="light"
        id={tooltipId}
      >
        <Text className={styles.title}>Created by</Text>
        <Text className={styles.username}>{username || EMPTY_VALUE}</Text>
        <Text className={styles.ip}>{user_ip_address || EMPTY_VALUE}</Text>
      </ReactTooltip>
    </>
  );
};
