import { FC } from "react";
import styles from "./UniqueMetricsCard.module.scss";
import { useSearchContext } from "../../SearchContext";
import MetricCard from "../../common/MetricCard/MetricCard";

const UniqueMetricsCard: FC = () => {
  const { uniqueMetrics = [], uniqueMetricsStatus } = useSearchContext();

  return (
    <div className={styles.wrapper}>
      {uniqueMetrics.map((card, i) => (
        <MetricCard
          key={i}
          card={card}
          className={styles.card}
          status={uniqueMetricsStatus}
        />
      ))}
    </div>
  );
};

export default UniqueMetricsCard;
