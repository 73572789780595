import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";
import { formIconAttrs } from "../../helpers/iconsHelpers";

function ArrowUp(props: IconProps): ReactElement {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...formIconAttrs(12, 12, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7 11.6V3.06l3.92 3.92.98-.98L6 .4.4 6l.98.98L5.3 3.06v8.54h1.4Z"
        fill={props.color || FONT}
      />
    </svg>
  );
}

export default ArrowUp;
