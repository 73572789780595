import { MembersType } from "../pages/Layer3Interface/types";

export const flatMembersList = (arr: Array<any>): Array<any> => {
  const copy: Array<any> = [];
  arr.map((el, i) => {
    copy[i] = {
      ...arr[i],
      members: getMembers(el.members),
    };
  });
  return copy;
};

const getMembers = (el: MembersType): Array<string> => {
  const interfaces = el?.map((member) => member.interfaces).flat();
  return interfaces?.map((int) => int.name);
};
