import React, { FC } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import UnifiedTextPair from "../../../../../components/common/UnifiedTextPair";
import { classNames } from "../../../../../helpers/common/classNames";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import styles from "./BGPInfo.module.scss";
import { Toggle } from "../../../../../components/common/Toggle";
import BGPIcon from "../../../../../components/icons/BGPIcon";
import ASNIcon from "../../../../../components/icons/ASNIcon";
import DeleteButton from "../../../../../components/common/buttons/DeleteButton";

type Props = {
  className: string;
  fields: {
    selectedTenant?: string;
    neighbor?: string;
    neighbors?: number;
    asn?: number;
  };
  isAsn?: boolean;
  onDelete?: () => void;
  shutDown?: boolean;
  toggleShutdown?: () => void;
};

const BGPInfo: FC<Props> = ({
  className,
  fields,
  isAsn,
  onDelete,
  shutDown,
  toggleShutdown = () => null,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={
          isAsn ? <ASNIcon color={GREY_FONT} /> : <BGPIcon color={GREY_FONT} />
        }
        text={isAsn ? "ASN" : "Neighbor"}
        className={styles.header}
      />
      <div className={styles.content}>
        <UnifiedTextPair
          subtitle={"Tenant"}
          text={localStorage.getItem("selectedTenant") || ""}
          textSize={"medium"}
        />
        {!isAsn && (
          <UnifiedTextPair
            subtitle={"Neighbor"}
            text={fields.neighbor}
            textSize={"medium"}
          />
        )}
        {isAsn && (
          <>
            <UnifiedTextPair
              subtitle={"ASN"}
              text={fields.asn}
              textSize={"medium"}
            />
            <UnifiedTextPair
              subtitle={"Neighbors"}
              text={fields.neighbors}
              textSize={"medium"}
            />
          </>
        )}
      </div>
      <div className={styles.controls}>
        {onDelete && (
          <DeleteButton id={"deleteASN"} onClick={onDelete} withLabel />
        )}
        {!isAsn && (
          <Toggle
            label={"Activate"}
            isChecked={!shutDown}
            onChange={toggleShutdown}
          />
        )}
      </div>
    </div>
  );
};

export default BGPInfo;
