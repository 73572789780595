import React from "react";
import { RemoteUserStatus, RequestStatus } from "../helpers/types";
import { createContextAndUse } from "./common/utils";
import { resToState, setPending } from "../helpers/common/RequestStateHelpers";
import { elasticApi } from "../helpers/api/ElasticApi";
import { GTimeRange } from "../components/common/charts/GraphanaLinksFactory";

type IState = {
  userStatusList: Array<RemoteUserStatus>;
  userRequest: RequestStatus;
  selectedTimeRange: GTimeRange | undefined;
};

type IFunc = {
  fetchUserStatusList: (
    tenant: string,
    timeRange?: GTimeRange
  ) => Promise<void>;
};

export const GLOBAL_TENANT = "global";

const [RemoteUsersStatusContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useRemoteUsersStatusContext = useContext;

export default class RemoteUsersStatusContextContainer extends React.Component<
  any,
  IState
> {
  funcs: IFunc;

  constructor(props: Readonly<{}>) {
    super(props);

    this.funcs = {
      fetchUserStatusList: this.fetchList,
    };

    this.state = {
      userRequest: { state: "idle" },
      userStatusList: [],
      selectedTimeRange: undefined,
    };
  }

  setSelectedTimeRange = async (timeRange: GTimeRange): Promise<void> => {
    this.setState({ selectedTimeRange: timeRange });
  };

  fetchList = async (tenant: string, timeRange?: GTimeRange): Promise<void> => {
    if (timeRange) await this.setSelectedTimeRange(timeRange);
    if (!this.state.selectedTimeRange) return;
    this.setState({ userRequest: setPending() });
    const res = await elasticApi.getRemoteUserStatus(
      tenant,
      this.state.selectedTimeRange
    );
    this.setState({
      userRequest: resToState({ ok: true }),
      userStatusList: res,
    });
  };

  render(): JSX.Element {
    return (
      <RemoteUsersStatusContext.Provider
        value={{ ...this.state, ...this.funcs }}
      >
        {this.props.children}
      </RemoteUsersStatusContext.Provider>
    );
  }
}
