import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function ArubaIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8 12.4444C5.56754 12.4444 3.5674 10.4444 3.5674 8C3.5674 5.55556 5.56754 3.55556 8 3.55556C10.4325 3.55556 12.4326 5.55556 12.4326 8C12.4326 10.4444 10.4325 12.4444 8 12.4444ZM8 0C3.56751 0 0 3.61112 0 8C0 12.4444 3.56751 16 8 16C9.83786 16 11.5136 15.3889 12.8649 14.3333C13.6758 15.7222 16 16 16 16V8C16 3.61112 12.4325 0 8 0Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default ArubaIcon;
