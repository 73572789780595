import { FC, ReactElement } from "react";
import styles from "./AddFormComponent.module.scss";
import ListContainer from "./ListComponent";
import Input from "../../../../../components/common/formComponents/Input";
import { classNames } from "../../../../../helpers/common/classNames";
import { FDQNDomain } from "../../../types";

type Props = {
  className?: string;
  btns: Array<ReactElement>;
  list: Array<FDQNDomain>;
  urlValue: string;
  onChange: (value: string) => void;
  removeItem: (item: FDQNDomain) => void;
};

const AddFormComponent: FC<Props> = ({
  className,
  btns,
  list,
  urlValue,
  onChange,
  removeItem,
}) => {
  const wrapperClasses = classNames(styles.wrapper, className);
  return (
    <div className={wrapperClasses}>
      <div className={styles.formContainer}>
        <Input
          placeholder="URL"
          value={urlValue}
          onChange={(e) => onChange(e.target.value)}
          className={styles.inputField}
        />
        {btns}
      </div>
      <ListContainer list={list} removeItem={removeItem} />
    </div>
  );
};

export default AddFormComponent;
