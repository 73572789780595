import React, { FC } from "react";
import styles from "./EnabledServiceSection.module.scss";
import { classNames } from "../../../../helpers/common/classNames";

type Props = {
  className?: string;
  title: string | number | undefined;
  subtitle?: string | number | undefined;
  columnsCount?: number;
  btmAlign?: boolean;
};

const EnabledServiceSection: FC<Props> = ({
  className,
  title,
  subtitle,
  children,
  columnsCount,
  btmAlign,
}) => {
  const childrenCount = columnsCount || React.Children.count(children);
  const gridTemplate = {
    gridTemplateColumns: `repeat(${childrenCount}, 1fr)`,
  };
  const isOneChild = childrenCount <= 1;
  return (
    <div className={classNames(styles.sectionWrapper, className)}>
      <div className={styles.title}>
        {title}
        <div className={styles.subtitle}> {subtitle} </div>
      </div>
      <div
        className={classNames(
          styles.contentWrapper,
          isOneChild && styles.oneColumn,
          btmAlign && styles.btmAlign
        )}
        style={gridTemplate}
      >
        {children}
      </div>
    </div>
  );
};

export default EnabledServiceSection;
