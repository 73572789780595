import React, { FC } from "react";
import GrafanaGraph from "../../../../../../../components/common/charts/GrafanaGraph";
import { useGlobalFilterContext } from "../../../../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../../../../contexts/TimerangeContext";
import { classNames } from "../../../../../../../helpers/common/classNames";
import { useApplicationObservabilityContext } from "../../../../../ApplicationObservabilityContext";
import { FiltersFields } from "../../../../../types";
import styles from "./StatisticsBody.module.scss";
import {
  GRAFANA_ROOT_PATH,
  GTimeRange,
  createUnifiedTenantFilter,
} from "../../../../../../../components/common/charts/GraphanaLinksFactory";
import { getMsFromDate } from "../../../../../../../components/common/charts/GraphanaTableLinks";

type Props = {
  className?: string;
};

const StatisticsBody: FC<Props> = ({ className }) => {
  const { filters } = useApplicationObservabilityContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.stat1}>
        <GrafanaGraph
          link={getApplicationLink(
            14,
            timeRange.general,
            selectedTenant,
            filters
          )}
        />
      </div>
      <div className={styles.stat2}>
        <GrafanaGraph
          link={getApplicationLink(
            10,
            timeRange.general,
            selectedTenant,
            filters
          )}
        />
      </div>
      <div className={styles.stat3}>
        <GrafanaGraph
          link={getApplicationLink(
            15,
            timeRange.general,
            selectedTenant,
            filters
          )}
        />
      </div>
      <div className={styles.stat4}>
        <GrafanaGraph
          link={getApplicationLink(
            13,
            timeRange.general,
            selectedTenant,
            filters
          )}
        />
      </div>
      <div className={styles.stat5}>
        <GrafanaGraph
          link={getApplicationLink(
            7,
            timeRange.general,
            selectedTenant,
            filters
          )}
        />
      </div>
    </div>
  );
};

export default StatisticsBody;

export const getApplicationLink = (
  panelId: number,
  timeRange: GTimeRange,
  tenant?: string,
  filters?: FiltersFields
): string => {
  const address =
    GRAFANA_ROOT_PATH + "/d-solo/CtrooqFVk/application-observability";
  const orgId = "orgId=1";
  const theme = "theme=light";
  const { from, to } = getMsFromDate(timeRange);
  const dateRange = `from=${from}&to=${to}`;
  const tenantFilter = createUnifiedTenantFilter(tenant);
  const filtersParams = filters ? getFiltersParams(FILTERS_MAP, filters) : "";
  return `${address}?${orgId}&${tenantFilter}${filtersParams}&${dateRange}&panelId=${panelId}&${theme}`;
};

const FILTERS_MAP = [
  {
    param: "locations",
    keyword: "Location",
  },
  {
    param: "connections",
    keyword: "Connection+name",
  },
  {
    param: "incomingSegments",
    keyword: "Incoming+segment",
  },
  {
    param: "outgoingSegmnets",
    keyword: "Outgoing+segment",
  },
  {
    param: "sourceIp",
    keyword: "Source_IP",
  },
  {
    param: "destinationIp",
    keyword: "Destination_IP",
  },
  {
    param: "hostname",
    keyword: "Hostname",
  },
];

export const getFiltersParams = (
  filtersMap: Array<{
    param: string;
    keyword: string;
  }>,
  filters?: FiltersFields
): string => {
  if (!filters) return "";
  const params = filtersMap.map((item) => {
    const filterValue = filters[item.param];
    const value = Array.isArray(filterValue)
      ? filterValue.join(",")
      : filterValue;

    return filterValue && filterValue?.length > 0
      ? `var-Filters=${item.keyword}.keyword%7C%3D%7C${value}`
      : undefined;
  });
  const newFilters = params.filter((p) => p !== undefined).join("&");
  return `&${newFilters}`;
};
