import {
  GRAFANA_ROOT_PATH,
  GTimeRange,
  createUnifiedTenantFilter,
} from "../../../components/common/charts/GraphanaLinksFactory";
import { getMsFromDate } from "../../../components/common/charts/GraphanaTableLinks";
import { FiltersFields } from "../types";

export const getApplicationLink = (
  panelId: number,
  timeRange: GTimeRange,
  tenant?: string,
  filters?: FiltersFields,
  filtersMap?: { [key: string]: string }
): string => {
  const address =
    GRAFANA_ROOT_PATH + "/d-solo/CtrooqFVk/application-observability";
  const orgId = "orgId=1";
  const theme = "theme=light";
  const { from, to } = getMsFromDate(timeRange);
  const dateRange = `from=${from}&to=${to}`;
  const tenantFilter = createUnifiedTenantFilter(tenant);
  const refresh = "refresh=30s";

  const filtersParams = filters ? getFiltersParams(filtersMap, filters) : "";
  return `${address}?${orgId}&${tenantFilter}&${filtersParams}&${dateRange}&panelId=${panelId}&${theme}&${refresh}`;
};

export const getFiltersParams = (
  filtersMap?: { [key: string]: string },
  filters?: FiltersFields
): string => {
  if (!filters || !filtersMap) return "";
  const params: Array<string> = [];
  Object.keys(filters).map((param) => {
    if (filters[param] && filtersMap[param]) {
      params.push(
        `var-Filters=${filtersMap[param]}.keyword%7C%3D%7C${filters[param]}`
      );
    }
  });
  return params.join("&");
};
