import React from "react";
import { LogsListType, SortType } from "../helpers/api/apiTypes";
import { hardcodedAPI } from "../helpers/api/HardcodeApi";
import { AbstractTimeoutHandler } from "../helpers/common/AbstractTimeoutHandler";
import { createContextAndUse } from "./common/AbstractCrudContext";

type IState = {
  logsList: Array<LogsListType>;
  isUpdateLogs: boolean;
};

type IFunc = {
  fetchLogsList: (arg?: SortType) => void;
  setIsUpdateLogs: (arg: boolean) => void;
};

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useNetworkContext = useContext;

export default class NetworkContextContainer extends AbstractTimeoutHandler<IState> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      logsList: [],
      isUpdateLogs: false,
    };

    this.funcs = {
      fetchLogsList: this.fetchLogsList,
      setIsUpdateLogs: this.setIsUpdateLogs,
    };
  }

  fetchLogsList = async (sortBy: SortType): Promise<void> => {
    const res = await hardcodedAPI.getLogsList(sortBy);
    this.setState({ logsList: res || [] });
  };

  setIsUpdateLogs = (value: boolean): void => {
    this.setState({ isUpdateLogs: value });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
