import React, { ReactNode } from "react";
import { classNames } from "../../../helpers/common/classNames";

import styles from "./DialogLayout.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

const DialogLayout = ({ children, className }: Props): React.ReactElement => {
  return (
    <div className={classNames(className, styles.dialogLayout)}>{children}</div>
  );
};

export default DialogLayout;
