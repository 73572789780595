import React, { FC } from "react";
import WarningCircleIcon from "../icons/WarningCircleIcon";
import styles from "./WarningMessage.module.scss";
import { classNames } from "../../helpers/common/classNames";

type Props = {
  message: string;
  className?: string;
};
const WarningMessage: FC<Props> = ({ message, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <WarningCircleIcon className={styles.icon} />
      <div className={styles.title}>Note:</div>
      <div className={styles.message}>{message}</div>
    </div>
  );
};
export default WarningMessage;
