import { FC } from "react";
import styles from "./RouteMapFormContainer.module.scss";
import { classNames } from "../../../../../../helpers/common/classNames";
import DialogBtmButtons from "../../../../../../components/dialogs/common/DialogBtmButtons";
import GeneralSection from "./components/GeneralSection";
import PrefixFilterListSection from "./components/PrefixFilterListSection";
import {
  ChangeField,
  FieldsType,
} from "../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { useRouteFiltersContext } from "../../../../RouteFiltersContext";
import { RequestStatus } from "../../../../../../helpers/types";

type Props = {
  className: string;
  onClose: () => void;
  isEdit?: boolean;
  fields: FieldsType;
  onChange: ChangeField;
  action: () => void;
  status?: RequestStatus;
};

const RouteMapFormContainer: FC<Props> = ({
  className,
  onClose,
  isEdit,
  fields,
  onChange,
  action,
  status,
}) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.contentWrapper}>
        <GeneralSection
          className={styles.sectionWrapper}
          fields={fields}
          onChange={onChange}
          isEdit={isEdit}
        />
        <PrefixFilterListSection
          className={styles.sectionWrapper}
          fields={fields.pfx_list}
          onChange={onChange}
        />
      </div>
      <DialogBtmButtons
        errorDisplay={status}
        className={styles.footerWrapper}
        controls={{
          okText: isEdit ? "Save" : "Add",
          onOk: action,
          cancelText: "Cancel",
          onCancel: onClose,
        }}
      />
    </div>
  );
};

export default RouteMapFormContainer;
