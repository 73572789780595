import { PURPLE, GREY_DISABLED } from "./colorAliases";
import { SortingTypes } from "../types";

export const getColorFromStatus = (status?: string): string => {
  switch (status) {
    // ORANGE
    case "registered":
    case "syncing":
    case "idle":
    case "notEstablished":
    case "Disconnect":
      return "var(--orange)";
    // CRITICAL
    case "invalid_cert":
    case "unavailable":
    case "critical":
    case "down":
    case "none":
    case "Absent":
      return "var(--critical)";
    // GREEN
    case "ready":
    case "active":
    case "available":
    case "up":
    case "Established":
    case "Connect":
      return "var(--green)";
    // LILAC
    case "established":
    case "warning":
      return "var(--lilac)";
    // GREY60
    case "admin_down":
      return "var(--grey60)";
    // PACKAGES
    case "essentials":
      return "var(--essential-package-color)";
    case "premium":
      return "var(--premium-package-color)";
    case "pro":
      return "var(--pro-package-color)";
    case "custom":
      return "var(--custom-package-color)";
    // GREY FONT
    default:
      return "var(--grey60)";
  }
};

export const getSortingArrowColor = (
  isSortedDesc: boolean | undefined,
  type: SortingTypes
): string => {
  switch (isSortedDesc) {
    case true:
      return type === SortingTypes.Desc ? PURPLE : GREY_DISABLED;
    case false:
      return type === SortingTypes.Asc ? PURPLE : GREY_DISABLED;
    case undefined:
    default:
      return GREY_DISABLED;
  }
};
