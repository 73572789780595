import React, { useState } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { Checkbox } from "../../../../../../components/common/formComponents/Checkbox";
import Input from "../../../../../../components/common/formComponents/Input";
import Section from "../../../../../../components/common/Section";
import EyeIcon from "../../../../../../components/icons/EyeIcon";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";

const hidePsk = (psk?: string) => {
  const symbolsNum = psk?.length || 0;

  return [...Array(symbolsNum).keys()].map(() => "*").join("");
};

const ENCRYPTION_LIST = [
  "3des",
  "aes128",
  "aes128gcm",
  "aes192",
  "aes256",
  "aes256gcm",
];

const BranchIpsec = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  const [pskIsHidden, setPskIsHidden] = useState<boolean>(true);

  const togglePsk = () => {
    setPskIsHidden((v) => !v);
  };

  return (
    <Section title={"IPSec"} columnsCount={2}>
      <Input
        label="PSK"
        name="psk"
        value={fields.psk}
        medium
        error={""}
        onChange={(e) => fieldsOnChange("psk", e.target.value)}
        rigthButtonClick={togglePsk}
        rigthButtonIcon={EyeIcon}
      />
      <DropdownBasic
        id={"encryption"}
        onChange={(val) => {
          fieldsOnChange("encryption", val.key);
        }}
        selected={mapStringToItem(fields.encryption.toUpperCase())}
        label="Encryption"
        itemsList={ENCRYPTION_LIST.map(mapStringToItem)}
        isMedium
        error={""}
      />
      <Input
        label="Remote IP"
        name="remote_ip"
        value={fields.remote_ip}
        medium
        error={""}
        placeholder="Remote IP"
        onChange={(e) => fieldsOnChange("remote_ip", e.target.value)}
      />
      <Input
        label="Remote Network"
        name="remote_network"
        value={fields.remote_network}
        medium
        error={""}
        placeholder="Networks"
        onChange={(e) => fieldsOnChange("remote_network", e.target.value)}
      />
      <Checkbox
        isChecked={fields.add_static_route}
        label={"Add static route automatically"}
        onChange={() =>
          fieldsOnChange("add_static_route", !fields.add_static_route)
        }
      />
    </Section>
  );
};

export default BranchIpsec;
