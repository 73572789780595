import { FC, ReactElement } from "react";
import UnifiedTextPair from "../../../../../components/common/UnifiedTextPair";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import styles from "./ConnectionInfoBlock.module.scss";
import { classNames } from "../../../../../helpers/common/classNames";

type Props = {
  name: string;
  ip_addresses: Array<string>;
  displayTypeText: string;
  icon: ReactElement;
  isRemote: boolean;
};

const ConnectionInfoBlock: FC<Props> = ({
  name,
  displayTypeText,
  icon,
  ip_addresses,
  isRemote,
}) => {
  const nameStyle = classNames(isRemote && styles.remote);
  return (
    <>
      <UnifiedTextPair
        textClassName={nameStyle}
        subtitle={"Name"}
        text={name}
        textSize={"medium"}
      />
      <UnifiedTextPair
        subtitle={"Remote type"}
        text={<TextWithIcon icon={icon} text={displayTypeText} />}
        textSize={"medium"}
      />
      <UnifiedTextPair
        subtitle={"IP Address"}
        text={(ip_addresses || []).join(", ")}
        textSize={"medium"}
      />
    </>
  );
};

export default ConnectionInfoBlock;
