import { FC } from "react";
import styles from "./InfoTextRight.module.scss";

const InfoTextRight: FC = () => {
  return (
    <div className={styles.mainText}>
      Add you customer as a tenant and provide the following information:
      <ul>
        <div className={styles.titleText}>General</div>
        <li>Name (The name of the customer)</li>
        <li>
          Short name (An ASCII version of the name without special characters)
        </li>
        <li>Package (optional)</li>
        <li>
          Admin username (The account to act as administrator of this customer)
        </li>
        <li>Admin name (The name of this user)</li>
        <li>
          Email address (The email of the admin for notifications and more)
        </li>
      </ul>
      <div className={styles.titleText}>Systems</div> Choose NSOS Systems this
      customer can consume services.
      <div className={styles.titleText}>Enabled Services</div>Select services
      the customer can choose to consume on these systems.
    </div>
  );
};
export default InfoTextRight;
