import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function SourceNATIcon({ color = FONT }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.31071 4.7094C4.31071 4.08327 4.8183 3.57568 5.44443 3.57568C8.60719 3.57568 11.2362 5.86114 11.7699 8.87065C11.8025 8.86783 11.8355 8.86638 11.8689 8.86638H14.0446L13.3346 8.15641C12.8919 7.71367 12.8919 6.99584 13.3346 6.55309C13.7774 6.11035 14.4952 6.11035 14.938 6.55309L17.5833 9.19844C18.0261 9.64119 18.0261 10.359 17.5833 10.8018L14.938 13.4471C14.4952 13.8899 13.7774 13.8899 13.3346 13.4471C12.8919 13.0044 12.8919 12.2865 13.3346 11.8438L14.0446 11.1338H11.8689C11.8355 11.1338 11.8025 11.1324 11.7699 11.1296C11.2362 14.1391 8.60719 16.4245 5.44443 16.4245C4.8183 16.4245 4.31071 15.9169 4.31071 15.2908C4.31071 14.6647 4.8183 14.1571 5.44443 14.1571C7.74027 14.1571 9.60141 12.2959 9.60141 10.0001C9.60141 7.70427 7.74027 5.84313 5.44443 5.84313C4.8183 5.84313 4.31071 5.33554 4.31071 4.7094ZM5.56079 11.1338C5.14747 11.9447 4.30461 12.5001 3.33203 12.5001C1.95132 12.5001 0.832031 11.3808 0.832031 10.0001C0.832031 8.61939 1.95132 7.5001 3.33203 7.5001C4.30461 7.5001 5.14747 8.05547 5.56078 8.86638H7.33397C7.96011 8.86638 8.46769 9.37397 8.46769 10.0001C8.46769 10.6262 7.96011 11.1338 7.33397 11.1338H5.56079Z"
        fill={color}
      />
    </svg>
  );
}

export default SourceNATIcon;
