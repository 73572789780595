import { FC } from "react";
import styles from "./Timestamp.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import { convertIsoDateToTableFormat } from "../../../helpers/convertIsoDateToTableFormat";

type Props = {
  value: string;
  className?: string;
};

const Timestamp: FC<Props> = ({ value, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {convertIsoDateToTableFormat(value)}
    </div>
  );
};
export default Timestamp;
