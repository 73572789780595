import { FC } from "react";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import EndpointDropdown from "../../../../common/formComponents/EndpointDropdown";

import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./DataCenterPortItem.module.scss";
import IPAddress from "../../../../common/formComponents/IPAddress";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const DataCenterPortItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
}) => {
  return (
    <ItemContainer
      type={type}
      title={fields?.location?.location_name || "Select Location"}
      idx={idx}
      onDelete={onDelete}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <EndpointDropdown
        field={fields.location}
        onChange={onChange}
        type={type}
        isNotEditable={isEdit}
        error={errors?.[idx + "location"]}
      />
      <BWDropdown
        field={fields.BW}
        onChange={handleChangeBW}
        error={errors?.[idx + "BW"]}
        idx={idx}
      />
      <IPAddress
        field={fields.ip_addresses}
        onChange={onChange}
        error={errors?.[idx + "ip_addresses"]}
      />
    </ItemContainer>
  );
};

export default DataCenterPortItem;
