import { CellProps } from "react-table";
import { ArubaGateway } from "../../WizardToolPage/types";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import AddIcon from "../../../components/icons/AddIcon";
import LabelArray from "../../../components/common/table/LabelArray";

export const arubaTableHeader = (addConnection: (row: any) => void): any => {
  const baseColums = [
    {
      Header: "Host",
      accessor: "host_name",
    },
    {
      Header: "Country",
      accessor: "device_country",
    },
    {
      Header: "City",
      accessor: "device_city",
    },
    {
      Header: "IP",
      accessor: "ip",
    },
    {
      Header: "Interfacence",
      accessor: "interface_data",
      Cell: ({ cell: { value } }: CellProps<ArubaGateway>) => {
        return <LabelArray values={Object.keys(value)} /> || "";
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: CellProps<ArubaGateway>) => {
        const handleClick = () => {
          row.toggleRowExpanded();
          addConnection(row);
        };
        return (
          <IconBlankButton
            id={`actions-${row.id}`}
            icon={AddIcon}
            label={"Add gateway"}
            onClick={handleClick}
          />
        );
      },
    },
  ];

  return baseColums.map((el) => ({
    ...el,
    accessor: el.accessor as keyof ArubaGateway,
  }));
};
