import { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function BlockedIcon({
  width = 20,
  height = 20,
  color,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.47228 5.45499C6.47228 3.50694 8.05149 1.92773 9.99954 1.92773C11.9476 1.92773 13.5268 3.50694 13.5268 5.45499V8.29133H15.3631C16.3572 8.29133 17.1631 9.09722 17.1631 10.0913V16.2731C17.1631 17.2672 16.3572 18.0731 15.3631 18.0731H4.63594C3.64182 18.0731 2.83594 17.2672 2.83594 16.2731V10.0913C2.83594 9.09722 3.64182 8.29133 4.63594 8.29133H6.47228V5.45499ZM11.9268 5.45499V8.29133H8.07228V5.45499C8.07228 4.3906 8.93514 3.52773 9.99954 3.52773C11.0639 3.52773 11.9268 4.3906 11.9268 5.45499ZM4.63594 9.89133C4.52548 9.89133 4.43594 9.98088 4.43594 10.0913V16.2731C4.43594 16.3836 4.52548 16.4731 4.63594 16.4731H15.3631C15.4736 16.4731 15.5631 16.3836 15.5631 16.2731V10.0913C15.5631 9.98087 15.4736 9.89133 15.3631 9.89133H4.63594ZM9.99954 14.5458C10.7526 14.5458 11.3632 13.9353 11.3632 13.1822C11.3632 12.4291 10.7526 11.8186 9.99954 11.8186C9.24642 11.8186 8.63591 12.4291 8.63591 13.1822C8.63591 13.9353 9.24642 14.5458 9.99954 14.5458Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default BlockedIcon;
