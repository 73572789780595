import { AbstractContextProvider } from "../../../../../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../../../../../contexts/common/utils";
import { tenantApi } from "../../../../../../../helpers/api/TenantApi";
import { AWSAccessKey } from "../../../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import {
  resToState,
  setPending,
} from "../../../../../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../../../../../helpers/types";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = {
  list?: Array<string>;
  isToken: boolean;
  token?: AWSAccessKey;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchData: (region: string, tenant: string) => Promise<void>;
  changeAWSAccessKey: (
    fields: AWSAccessKey,
    tenant: string,
    region: string
  ) => Promise<void>;
};

const [VPCContext, useVPCContext] = createContextAndUse<IState, IFunc>();
export { useVPCContext };

export default class VPCContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = VPCContext;
  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchData = async (region: string, tenant: string): Promise<void> => {
    const awsToken = await this.getAWSAccessKey(tenant);
    if (awsToken) {
      this.fetchList(region, tenant);
    }
  };

  fetchList = async (region: string, tenant: string): Promise<void> => {
    this.setState({ listStatus: setPending() });
    const res = await tenantApi.getAWSVPCListApi(tenant, region);

    if (res) {
      this.setState({
        listStatus: resToState(res),
        list: res?.result?.map((el) => el.VpcId) || [],
      });
    }
  };

  getAWSAccessKey = async (tenant: string): Promise<boolean> => {
    const { ok, result } = await tenantApi.getAWSAccessKey(tenant);
    this.setState({ token: result, isToken: ok });
    return ok;
  };

  changeAWSAccessKey = async (
    fields: AWSAccessKey,
    tenant: string,
    region: string
  ): Promise<void> => {
    if (this.state.isToken) {
      const { ok } = await tenantApi.deleteAWSAccessKey(tenant);
      if (ok) this.createAWSAccessKey(fields, tenant, region);
    }
    await this.createAWSAccessKey(fields, tenant, region);
  };

  createAWSAccessKey = async (
    fields: AWSAccessKey,
    tenant: string,
    region: string
  ): Promise<void> => {
    const res = await tenantApi.createAWSAccessKey(fields, tenant);
    if (res.ok) this.fetchData(region, tenant);
  };

  funcs = {
    fetchData: this.fetchData,
    changeAWSAccessKey: this.changeAWSAccessKey,
  };
}
