import { FC, useEffect } from "react";
import placeholder from "./common/placeholder.svg";
import { useHistory } from "react-router-dom";
import { SERVICES_PREFERENCES } from "../../helpers/navigation/entries";
import styles from "./ServicesRedirectPlaceholder.module.scss";
import { withContexts } from "../../helpers/hocs/withContexts";
import {
  ServicesPreferencesContextContainer,
  useServicesPreferencesContext,
} from "../../pages/ServicesPreferencesPage/ServicesPreferencesContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import InfoIcon from "../icons/InfoIcon";
import { GREY_DISABLED } from "../../helpers/common/colorAliases";
import { Toggle } from "../common/Toggle";
import { PageLoader } from "../common/loadStates/LoaderIcon";

type Props = {
  type:
    | "sdr"
    | "dpi"
    | "ids"
    | "fqdn"
    | "nat"
    | "segments"
    | "firewall"
    | "dns";
  adminMode: boolean;
  fetchData: (tenant: string) => void;
};

const ServicesRedirectPlaceholder: FC<Props> = ({
  type,
  adminMode,
  fetchData,
}) => {
  const history = useHistory();
  const {
    service,
    fetchService,
    editService,
    fetchStatus,
    error,
  } = useServicesPreferencesContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    if (selectedTenant && type) {
      fetchService(selectedTenant, type);
    }
  }, [selectedTenant, type]);

  const handleEditService = async () => {
    if (!service || !selectedTenant) return;
    const params = {
      type: service.type,
      enable: !service.enable,
      data: service.data,
    };
    const res = await editService(params, selectedTenant);
    if (res) {
      fetchData(selectedTenant);
    }
  };

  const title = adminMode
    ? " service was disabled by the administrator"
    : " service is disabled";

  if (fetchStatus?.state === "pending") return <PageLoader />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.placeholderWrapper}>
        <img src={placeholder} alt="" />
        <div className={styles.title}>
          {service?.title}
          {title}
        </div>
        {adminMode ? (
          <div className={styles.descr}>
            Please ask your administrator to enable this service
          </div>
        ) : (
          <div className={styles.descr}>
            <p>
              You can enable it in the{" "}
              <span
                onClick={() => {
                  history.replace("/" + SERVICES_PREFERENCES().path);
                }}
              >
                Services page
              </span>
            </p>
            <p onClick={() => handleEditService()}>
              or enable {service?.title} now{" "}
              <Toggle
                id={service?.type}
                isChecked={!!service?.enable}
                onChange={() => undefined}
              />
            </p>
            {error && <span className={styles.error}>{error}</span>}
          </div>
        )}
      </div>
      <div className={styles.infoWrapper}>
        <div className={styles.header}>
          <InfoIcon color={GREY_DISABLED} />
          Information
        </div>
        <div className={styles.info}>{service?.description}</div>
      </div>
    </div>
  );
};

export default withContexts<Props>(ServicesRedirectPlaceholder, [
  ServicesPreferencesContextContainer,
]);
