export const getUserNames = (
  login: string
): { username: string; tenantName?: string } => {
  const values = login.split("@");
  const username = values[0];
  const tenantName = values[1];
  if (username === "admin" && tenantName === "global") {
    return { username, tenantName: undefined };
  }
  return { username, tenantName };
};
