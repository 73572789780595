import React from "react";
import { SortType, TenantType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { hardcodedAPI } from "../../helpers/api/HardcodeApi";
import { tenantApi } from "../../helpers/api/TenantApi";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { createContextAndUse } from "../common/AbstractCrudContext";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { mockAttackList } from "../../pages/DDoSPage/common/attack.const";
import { Res } from "../../helpers/api/Api";

type IState = {
  tenants: Array<TenantType>;
  tenantStatistics: any;
  blockedThreatCategoryList: any;
  blockedRuleCategoryList: any;
  idsCount?: number;
  labelData?: { [key: string]: number };
};
type IFunc = {
  fetchLabelData: (tenantName: string) => void;
  fetchTenants: (arg?: SortType) => void;
  fetchBlockedThreatCategory: () => void;
  fetchBlockedRuleCategory: () => void;
  getIDSCount: (
    vrfId: string | number,
    timeRange: GTimeRange,
    tenantName?: string
  ) => void;
};

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useDashboardContext = useContext;

export default class DashboardContextContainer extends AbstractTimeoutHandler<IState> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      tenants: [],
      tenantStatistics: { interface_items: [], items: [] },
      blockedThreatCategoryList: {},
      blockedRuleCategoryList: {},
      labelData: {
        fwRules: 0,
        ddosAttack: 0,
        natRules: 0,
        routes: 0,
      },
    };

    this.funcs = {
      fetchTenants: this.fetchTenants,
      fetchBlockedThreatCategory: this.fetchBlockedThreatCategory,
      fetchBlockedRuleCategory: this.fetchBlockedRuleCategory,
      getIDSCount: this.getIDSCount,
      fetchLabelData: this.fetchLabelData,
    };
  }

  fetchTenants = async (sortBy: SortType): Promise<void> => {
    const { result } = await tenantApi.getTenantsList({ sort: sortBy });
    this.setState({ tenants: result || [] });
  };

  fetchTenantSystems = (): Array<any> => [];

  fetchLabelData = async (tenantName: string): Promise<void> => {
    const fwRules = await configApi.getFWRules(tenantName);
    const ddosAttack = mockAttackList.length;
    const natRules = await configApi.getNatRulesList(tenantName);
    const routes = await configApi.getServiceRoutesList(tenantName);

    this.setState({
      labelData: {
        fwRules: getArrayLength(fwRules),
        ddosAttack,
        natRules: getArrayLength(natRules),
        routes: getArrayLength(routes),
      },
    });
  };

  getIDSCount = async (
    vrfId: string | number,
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<void> => {
    const res = await elasticApi.getIDSCount(vrfId, timeRange, tenantName);
    if (res.ok) {
      this.setState({ idsCount: res.result || 0 });
    }
  };

  fetchBlockedThreatCategory = async (): Promise<void> => {
    const res = await hardcodedAPI.getBlockedThreatCategory();
    this.setState({ blockedThreatCategoryList: res || {} });
  };
  fetchBlockedRuleCategory = async (): Promise<void> => {
    const res = await hardcodedAPI.getBlockedRuleCategory();
    this.setState({ blockedRuleCategoryList: res || {} });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const getArrayLength = (resArray: Res<Array<any>>): number => {
  const { ok, result } = resArray;
  if (ok && result) return result.length;
  return 0;
};
