import { FC } from "react";
import Section from "../../../../../components/common/Section";
import {
  ChangeField,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import AddressFamilyItem from "./AddressFamilyItem";
import { RouteFiltersFields } from "../../../types";

type Props = {
  isEdit?: boolean;
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
  neighborBgpStatus?: any;
  routeFilters: RouteFiltersFields;
  onChangeRouteFilters: (key: string, value: string) => void;
};

const AddressFamilySection: FC<Props> = ({
  className,
  fields,
  onChange,
  isEdit,
  errors,
  neighborBgpStatus,
  routeFilters,
  onChangeRouteFilters,
}) => {
  return (
    <Section title={"AddressFamily"} className={className} columnsCount={1}>
      <AddressFamilyItem
        fields={fields}
        onChange={onChange}
        isEdit={isEdit}
        errors={errors}
        neighborBgpStatus={neighborBgpStatus}
        routeFilters={routeFilters}
        onChangeRouteFilters={onChangeRouteFilters}
      />
    </Section>
  );
};
export default AddressFamilySection;
