import React, { FC } from "react";
import { CreateIdentity } from "./IdentityDialog";
import Section from "../../../../components/common/Section";
import Input from "../../../../components/common/formComponents/Input";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./AuthSection.module.scss";

type Props = {
  fields: CreateIdentity;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
};

const AuthSection: FC<Props> = ({ fields, errors, onChange, className }) => {
  return (
    <Section title={"Authenticate"} className={className} columnsCount={2}>
      <div className={styles.column}>
        <Input
          label="Client ID"
          name="provider_client_id"
          placeholder=""
          value={fields.provider_client_id}
          medium
          error={errors && errors["name"]}
          handleFieldChange={onChange}
        />
      </div>
      <div className={styles.column}>
        <Input
          label="Client Secret Key"
          name="provider_client_secret"
          placeholder=""
          value={fields.provider_client_secret}
          type="password"
          medium
          error={errors && errors["name"]}
          handleFieldChange={onChange}
        />
      </div>
    </Section>
  );
};
export default AuthSection;
