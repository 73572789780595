import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus } from "../../helpers/types";
import { isLocalStorageSupported } from "../../helpers/isLocalStorageSupported";
import { FiltersFields } from "./types";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { APPLICATION_FIELDS_MAP, APPLICATION_FIELD_VALUE } from "./consts";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";

const LOCALSTORAGE_APPLICATION_KEY = "LOCALSTORAGE_APPLICATION_KEY";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = {
  filters?: FiltersFields | undefined;
  filtersData: { [key: string]: Array<any> };
};

type IState = State & RequestStatuses;
type IFunc = {
  setFilters: (fields?: FiltersFields) => void;
  fetchData: (tenant: string, timeRange: GTimeRange) => Promise<void>;
};

const [ApplicationsDashboardContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useApplicationsDashboardContext = useContext;

export default class ApplicationsDashboardContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = ApplicationsDashboardContext;

  // saved filters will be cleared via auth logout method
  componentWillMount() {
    const savedFilters = window.localStorage.getItem(
      LOCALSTORAGE_APPLICATION_KEY
    );
    if (savedFilters) {
      this.setFilters(JSON.parse(savedFilters));
    }
  }

  updateLoacalstorageByFilters(fields?: FiltersFields): void {
    if (!isLocalStorageSupported()) {
      return;
    }
    if (fields) {
      window.localStorage.setItem(
        LOCALSTORAGE_APPLICATION_KEY,
        JSON.stringify(fields)
      );
    } else {
      window.localStorage.removeItem(LOCALSTORAGE_APPLICATION_KEY);
    }
  }

  setFilters = (fields?: FiltersFields): void => {
    this.updateLoacalstorageByFilters(fields);
    this.setState({ filters: fields });
  };

  fetchData = async (tenant: string, timeRange: GTimeRange): Promise<void> => {
    const filtersData: { [key: string]: Array<any> } = {};
    await Promise.all(
      APPLICATION_FIELDS_MAP.map(
        async (name): Promise<any> => {
          filtersData[name] = await this.fetchElasticFields(
            APPLICATION_FIELD_VALUE[name],
            tenant,
            timeRange
          );
        }
      )
    );
    this.setState({ filtersData });
  };

  fetchElasticFields = async (
    fieldName: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> => {
    const { ok, result } = await elasticApi.getElasticFiltersValues(
      fieldName,
      tenant,
      "DPI",
      timeRange
    );
    if (ok && result) {
      return result;
    }
    return [];
  };

  funcs = {
    setFilters: this.setFilters,
    fetchData: this.fetchData,
  };
}
