import React, { ReactElement } from "react";
import { FONT } from "../../../../helpers/common/colorAliases";
import { IconProps } from "../../../../helpers/types";

function SecurityIcon({ color }: IconProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <path
        fill={color || FONT}
        fillRule="evenodd"
        d="M12 2.473a4.073 4.073 0 0 0-4.073 4.072v4.364H5.363a1 1 0 0 0-1 1v7.818a1 1 0 0 0 1 1h13.273a1 1 0 0 0 1-1V11.91a1 1 0 0 0-1-1h-2.564V6.545A4.073 4.073 0 0 0 12 2.473Zm2.472 8.436V6.545a2.473 2.473 0 0 0-4.945 0v4.364h4.945Z"
        clipRule="evenodd"
      />
      <circle cx="12" cy="15.818" r="1.636" fill="#fff" />
    </svg>
  );
}

export default SecurityIcon;
