import { ConnectionApi, ConnectionEdit } from "../../types";
import { BASIC_BODY_UPDATED } from "../consts";

export const getUpdatedConnectionBody = (
  services: {
    [key: string]: any;
  },
  port: {
    [key: string]: any;
  },
  initialConnection: ConnectionApi
): { name: string; body: ConnectionEdit } | undefined => {
  const body = { ...BASIC_BODY_UPDATED };

  if (
    port.BW === initialConnection.a_endpoint_conf.BW &&
    port.ip_addresses === initialConnection.z_endpoint_conf.ip_addresses[0]
  ) {
    return;
  }

  // Body
  body.services = services;
  body.labels = initialConnection.labels;
  body.a_endpoint_conf = { ...initialConnection.a_endpoint_conf, BW: port.BW };
  body.z_endpoint_conf = { ...initialConnection.z_endpoint_conf };
  if (body.z_endpoint_conf.hasOwnProperty("ip_addresses")) {
    body.z_endpoint_conf.ip_addresses = [port.ip_addresses];
  }
  return { name: initialConnection.name, body };
};
