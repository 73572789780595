import { FWRuleType } from "../../../helpers/api/apiTypes";
import { getPotocolName } from "../../../helpers/getProtocolByValue";
import { SegmentRule } from "../components/SegmentRulesTable/types";
import { NETWORK_APPLICATIONS, NETWORK_APP_SEPARATOR } from "./consts";

export const convertFirewallToSegmentRule = (
  fwRule: FWRuleType
): SegmentRule => {
  const ip_protocol = getPotocolName(fwRule.filters);
  const srcSegment = fwRule.filters.find(
    (filter) => filter.filter_type === "src_segment"
  );
  const dstSegment = fwRule.filters.find(
    (filter) => filter.filter_type === "dst_segment"
  );
  const port = fwRule.filters.find(
    (filter) => filter.filter_type === "src_l4_port"
  )?.values;

  const network_app =
    NETWORK_APPLICATIONS.find((el) =>
      el.includes(`${NETWORK_APP_SEPARATOR}${port}`)
    ) || "";

  return {
    id: fwRule.id,
    enabled: true,
    network_app,
    ip_protocol: ip_protocol,
    priority: fwRule.priority,
    name: fwRule.name,
    description: fwRule.description,
    srcSegment: srcSegment?.values + "",
    dstSegment: dstSegment?.values + "",
  };
};
