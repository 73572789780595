import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { commonTableColumns } from "../table/commonTableColumns";
import { groupTableStyles } from "../helpers/consts";
import { formPathFromArray } from "../../../helpers/navigation";
import {
  SEGMENTATION,
  SEGMENTS_RULES,
  SERVICES,
} from "../../../helpers/navigation/entries";

const SourceSegmentsTable: FC = () => {
  const { data, statuses } = useSearchContext();
  return (
    <ResultTable
      title="Source Segments"
      data={data?.sourceSegments}
      columns={commonTableColumns("Source Segment")}
      status={statuses?.sourceSegmentsStatus}
      style={groupTableStyles}
      goto={formPathFromArray([SERVICES(), SEGMENTATION(), SEGMENTS_RULES()])}
    />
  );
};
export default SourceSegmentsTable;
