import { FC, useEffect, useState } from "react";
import styles from "./Map.module.scss";
import { arubaApi } from "../../../helpers/api/ArubaApi";

declare global {
  interface Window {
    google: {
      maps: any;
    };
  }
}

const loadGoogleMap = () => {
  if (window.google?.maps) return Promise.resolve();

  const script = document.createElement("script");
  script.src =
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyB5_Oo5U-zg4LS96ZSqUyZTk9lfdVp_DeE";

  return new Promise((resolve) => {
    script.addEventListener("load", resolve);
    document.head.appendChild(script);
  });
};

const Map: FC = () => {
  const [list, setList] = useState<Array<any>>([]);

  useEffect(() => {
    Promise.all([arubaApi.getMap(), loadGoogleMap()]).then((data) => {
      setList(data[0].result || []);
    });
  }, []);

  useEffect(() => {
    if (!list.length) return;
    const element = document.querySelector("#map");
    const bounds = new window.google.maps.LatLngBounds();
    const map = new window.google.maps.Map(element);
    map.markers = [];
    map.coords = [];

    list.forEach((item) => {
      if (!item.appliance) return;
      if (item.latitude === 0.0 && item.longitude === 0.0) return;
      const position = {
        lat: item.latitude,
        lng: item.longitude,
      };
      const icon = new window.google.maps.MarkerImage(
        "/marker.svg",
        new window.google.maps.Size(30, 30),
        new window.google.maps.Point(0, 0),
        new window.google.maps.Point(15, 15)
      );
      const marker = new window.google.maps.Marker({ map, position, icon });
      marker.id = item.sourceId;
      map.markers.push(marker);
      map.coords.push(position);
      bounds.extend(position);
    });

    if (map.markers.length == 1) {
      map.setCenter(bounds.getCenter());
    } else {
      new window.google.maps.Polyline({
        path: map.coords,
        geodesic: true,
        strokeColor: "#00BA77",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      }).setMap(map);
      map.fitBounds(bounds);
    }
  }, [list]);

  return <div id="map" className={styles.map}></div>;
};

export default Map;
