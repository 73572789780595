import React from "react";
import { hardcodedAPI } from "../../helpers/api/HardcodeApi";
import { LoopbackType, NatpoolType } from "../../helpers/api/apiTypes";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { RequestStatus } from "../../helpers/types";
import { OK, setPending } from "../../helpers/common/RequestStateHelpers";
import { createContextAndUse } from "../common/AbstractCrudContext";

type RequestStatuses = {
  loopbackStatus?: RequestStatus;
  natpoolStatus?: RequestStatus;
};

type IState = {
  loopbackList: Array<LoopbackType>;
  natpoolList: Array<NatpoolType>;
} & RequestStatuses;

type IFunc = {
  fetchLoopbackList: () => void;
  fetchNatpoolList: () => void;
};

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useLogicalInterfacesContext = useContext;

export default class LogicalInterfacesContextContainer extends AbstractTimeoutHandler<IState> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      loopbackList: [],
      natpoolList: [],
    };

    this.funcs = {
      fetchLoopbackList: this.fetchLoopbackList,
      fetchNatpoolList: this.fetchNatpoolList,
    };
  }

  fetchLoopbackList = async (): Promise<void> => {
    this.setState({ loopbackStatus: setPending("Fetching") });
    const res = await hardcodedAPI.getLoopbackList();
    this.setState({ loopbackList: res || [] });
    // this.setState({ loopbackStatus: resToState(res) }); todo use this with real API
    this.setState({ loopbackStatus: { state: OK } });
  };

  fetchNatpoolList = async (): Promise<void> => {
    this.setState({ natpoolStatus: setPending("Fetching") });
    const res = await hardcodedAPI.getNatpoolList();
    this.setState({ natpoolList: res || [] });
    // this.setState({ natpoolStatus: resToState(res) }); todo use this with real API
    this.setState({ natpoolStatus: { state: OK } });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
