import React, { FC } from "react";
import { withLayout } from "../../../../../helpers/hocs/withLayout";
import { PageProps } from "../../../../../helpers/types";

import styles from "./UsageReportPage.module.scss";

const UsageReportPage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <div className={styles.wrapper}>
      <Tabs />
      <div className={styles.placeholderWrapper}>
        <img
          src={"/zayoGrafics/usageReport.svg"}
          className={styles.placeholderImg}
        />
      </div>
      {/* <div className={styles.content}>
        <ContentHeader className={styles.header} />
        <UsageReportTable className={styles.table} />
        <UsageReportCharts className={styles.charts} />
      </div> */}
    </div>
  );
};

export default withLayout<PageProps>(UsageReportPage);
