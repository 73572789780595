import { FC, useEffect } from "react";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useSDWANProvidersContext } from "../SDWANProvidersContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import styles from "./ProviderCredentials.module.scss";
import Button from "../../../components/common/buttons/Button";
import { useValidation } from "../../../helpers/validators/Validator";
import { classNames } from "../../../helpers/common/classNames";
import Input, { types } from "../../../components/common/formComponents/Input";
import validateSDWANCredentials from "../../../helpers/validators/SDWANCredentialsValidator";

type Props = {
  type: string;
};

export type ProviderCredentialsFields = {
  access_key?: string;
  access_secret?: string;
  tenant?: string;
  access_type: string;
  end_point?: string;
};

const ARUBA_DEFAULT_FIELDS = {
  access_key: "",
  access_secret: "",
  tenant: "",
  access_type: "user_password",
  end_point: "",
};

const fieldsData = {
  aruba: {
    secretsNames: ["access_key", "access_secret", "end_point"],
    defaults: ARUBA_DEFAULT_FIELDS,
    labels: ["Username", "Password", "URL"],
    placeholders: ["Username", "Password", "URL"],
    isSecret: [false, true, false],
  },
};

const getDataByType = (type: string) => {
  switch (type) {
    default:
      return fieldsData.aruba;
  }
};

const getSecrets = (type: string, fields: any): Array<string> => {
  switch (type) {
    default:
      return [fields.access_key, fields.access_secret, fields.end_point];
  }
};

const ProviderCredentials: FC<Props> = ({ type }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    tokens,
    createAccessKey,
    deleteAccessKey,
    requestToUpdate,
    setRequestToUpdate,
  } = useSDWANProvidersContext();

  const data = getDataByType(type);
  const [
    fields,
    handleFieldChange,
    resetFields,
  ] = useFormField<ProviderCredentialsFields>(data.defaults);
  const [errors, validate] = useValidation<ProviderCredentialsFields>(
    validateSDWANCredentials,
    [fields]
  );
  const secrets = getSecrets(type, fields);

  const connectionsPlugText =
    "Please Insert Provider Credentials to Add Gateways";

  const connectionsPlugStyle = classNames(styles.connectionsPlug);

  const handleCancelUpdate = () => {
    setRequestToUpdate(undefined);
  };

  useEffect(() => {
    if (selectedTenant) {
      resetFields(data.defaults);
      data.defaults.tenant = selectedTenant;
    }
  }, [selectedTenant]);

  useEffect(() => {
    if (tokens && type) {
      const fields = tokens?.[type];
      if (fields) {
        resetFields(fields);
      } else {
        resetFields(data.defaults);
      }
    }
  }, [tokens, type]);

  const handleApply = () => {
    if (selectedTenant) {
      const { isOk } = validate();
      if (isOk) {
        if (tokens?.[type]) {
          deleteAccessKey(selectedTenant, type, true, fields);
        } else {
          createAccessKey(fields, selectedTenant, type);
        }
        if (requestToUpdate === type) setRequestToUpdate(undefined);
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <label className={styles.label}>{"Provider Credentials"}</label>
      <div className={styles.form}>
        <div className={styles.row}>
          <div className={styles.group}>
            {secrets.map((secret, idx) => {
              return (
                <Input
                  id={`${type}_${data.secretsNames[idx]}`}
                  type={data.isSecret[idx] ? types.PASSWORD : types.INPUT}
                  value={secret}
                  label={data.labels[idx]}
                  name={data.secretsNames[idx]}
                  onChange={(e) => {
                    handleFieldChange(data.secretsNames[idx], e.target.value);
                  }}
                  isFullValue
                  medium
                  error={errors?.[data.secretsNames[idx]]}
                  placeholder={data.placeholders[idx]}
                />
              );
            })}
          </div>
          {requestToUpdate === type && (
            <div className={styles.btnWrapper}>
              <Button onClick={handleCancelUpdate} className={styles.btn}>
                Cancel
              </Button>
            </div>
          )}
          <div className={styles.btnWrapper}>
            <Button onClick={handleApply} isPrimaryBtn className={styles.btn}>
              Apply
            </Button>
          </div>
        </div>
      </div>
      {!tokens?.[type] && (
        <>
          <label className={styles.label}>{"Connections"}</label>
          <div className={connectionsPlugStyle}>
            <div className={styles.text}>{connectionsPlugText}</div>
          </div>
        </>
      )}
    </div>
  );
};
export default ProviderCredentials;
