import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import { IPortsContext, withPortsContextProps } from "../PortsContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";
import { PortType } from "../types";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<PortType>;

type IState = State & RequestStatuses;
type IFunc = DialogFunc<PortType>;

const [PortDialogContext, usePortDialogContext] = createContextUtils<
  IState,
  IFunc
>();
export { usePortDialogContext };

class PortDialogContextContainer extends AbstractDialogContextContainer<
  PortType,
  IState,
  RequestStatuses,
  IFunc,
  IPortsContext & IOneSystemContext
> {
  Context = PortDialogContext;

  _updateData = (ok: boolean) => {
    if (ok) {
      this.props.fetchList();
    }
  };

  edit = async (port: Partial<PortType>): Promise<boolean> => {
    const { system } = this.props;
    if (!system || !port.id) return false;
    const { ok } = await this._editWrap(() =>
      configApi.editAlias(system.name, port.id + "", { alias: port.alias })
    );
    this._updateData(ok);
    return ok;
  };

  // not exist
  add = async (): Promise<boolean> => {
    return false;
  };

  funcs = {
    edit: this.edit,
    add: this.add,
  };
}

export default withPortsContextProps(
  withOneSystemContextProps(PortDialogContextContainer)
);
