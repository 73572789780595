import React, { ReactElement } from "react";

function EditSquareIcon(): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 145">
        <rect
          id="Rectangle 136"
          x="0.7"
          y="0.7"
          width="22.6"
          height="22.6"
          rx="5.3"
          fill="#6B55F0"
          stroke="#6B55F0"
          strokeWidth="1.4"
        />
        <g id="edit">
          <mask
            id="mask0_140_4361"
            maskUnits="userSpaceOnUse"
            x="4"
            y="4"
            width="16"
            height="16"
          >
            <rect
              id="Rectangle 164"
              x="4"
              y="4"
              width="16"
              height="16"
              fill="#fff"
            />
          </mask>
          <g mask="url(#mask0_140_4361)">
            <path
              id="Rectangle 163"
              d="M14.55 5.37008C14.7765 5.14358 15.1437 5.14358 15.3702 5.37008L18.7022 8.70207C18.9287 8.92857 18.9287 9.29581 18.7022 9.52231L10.4587 17.7658C10.3499 17.8746 10.2024 17.9357 10.0486 17.9357H6.7166C6.39627 17.9357 6.1366 17.676 6.1366 17.3557L6.1366 14.0237C6.1366 13.8699 6.1977 13.7223 6.30648 13.6136L14.55 5.37008Z"
              fill="white"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default EditSquareIcon;
