import { Segment } from "../SegmentsRulesContext";

export const getSegmentsName = (
  segments: Array<Segment>,
  selectedSegment: { x: number; y: number; id: number }
): { srcSegment: string; dstSegment: string } | undefined => {
  const srcSegment = segments.find((el) => el.id === selectedSegment.x)?.name;
  const dstSegment = segments.find((el) => el.id === selectedSegment.y)?.name;
  return srcSegment && dstSegment ? { srcSegment, dstSegment } : undefined;
};
