import React, { FC } from "react";
import Button from "../../../../components/common/buttons/Button";
import FilterIcon from "../../../../components/icons/FilterIcon";

import styles from "./FilterButton.module.scss";

export const FilterButton: FC = () => {
  return (
    <Button onClick={() => undefined} className={styles.filterButton}>
      <FilterIcon height={12} width={12} />
    </Button>
  );
};
