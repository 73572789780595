import { FC } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import Section from "../../../../../../../components/common/Section";
import { Toggle } from "../../../../../../../components/common/Toggle";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";
import { PHASE2_PROTOCOL } from "../../../helpers/consts";
import styles from "./Phase2Section.module.scss";

type Props = {
  className: string;
};
const Phase2Section: FC<Props> = ({ className }) => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  return (
    <Section title={"Phase 2"} columnsCount={1} className={className}>
      <div className={styles.row}>
        <DropdownBasic
          label={"Protocol"}
          id={"phase2Protocol"}
          onChange={(val) => {
            onChange("phase2Protocol", val.key);
          }}
          placeholder={"Protocol"}
          selected={mapStringToItem(fields.phase2Protocol)}
          itemsList={PHASE2_PROTOCOL.map(mapStringToItem)}
          isMedium
          error={""}
        />
        <Input
          label="Key lifetime (seconds)"
          name="phase2KeyLifetime"
          placeholder=""
          value={fields.phase2KeyLifetime}
          medium
          error={""}
          onChange={(e) => {
            onChange("phase2KeyLifetime", e.target.value);
          }}
          type={"number"}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.colGroup}>
          <Input
            label="Algorythm"
            name="phase1AlgorithmAESGCM"
            isPlaceholderAlwaysVisible
            placeholder="AES-GCM"
            value={fields.phase1AlgorithmAESGCM}
            medium
            error={""}
            onChange={(e) => {
              onChange("phase1AlgorithmAESGCM", e.target.value);
            }}
            isNotEditable
          />
          <Input
            label=""
            name="phase1AgorithmDH"
            placeholder="DH"
            value={fields.phase1AgorithmDH}
            medium
            error={""}
            onChange={(e) => {
              onChange("phase1AgorithmDH", e.target.value);
            }}
            isNotEditable
            isPlaceholderAlwaysVisible
          />
        </div>
        <div className={styles.colGroupWithMargin}>
          <Toggle
            id={"phase2ReplayDetection"}
            label={"Enable Replay Detection"}
            onChange={() =>
              onChange("phase2ReplayDetection", !fields.phase2ReplayDetection)
            }
            isChecked={fields.phase2ReplayDetection}
          />
          <Toggle
            id={"pahse2Pfs"}
            label={"Perfect Forward Secrecy (PFS)"}
            onChange={() => onChange("pahse2Pfs", !fields.pahse2Pfs)}
            isChecked={fields.pahse2Pfs}
          />
        </div>
      </div>
    </Section>
  );
};

export default Phase2Section;
