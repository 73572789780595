import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";

function ApplySquareIcon({
  color,
  className,
  onClick,
}: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={className}
    >
      <rect width="28" height="28" rx="10" fill={color || BUTTON_PRIMARY} />
      <path
        d="M11.9477 16.4938L8.29238 12.9494L7 14.2025L11.9477 19L22 9.25312L20.7076 8.00001L11.9477 16.4938Z"
        fill="white"
      />
    </svg>
  );
}

export default ApplySquareIcon;
