import React, { ReactElement } from "react";

function LogoIconWhite(): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="159"
      height="43"
      viewBox="0 0 159 43"
      fill="none"
    >
      <g clip-path="url(#clip0_93_4054)">
        <path
          d="M41.9989 13.747C41.992 13.4931 42.0842 13.2468 42.2553 13.0616C42.346 12.9682 42.4551 12.8955 42.5755 12.8482C42.6959 12.8009 42.8249 12.7802 42.9538 12.7874C43.08 12.7792 43.2065 12.7979 43.3252 12.8423C43.4439 12.8867 43.5522 12.9558 43.6431 13.0452C43.7339 13.1346 43.8054 13.2423 43.8529 13.3614C43.9004 13.4805 43.9228 13.6083 43.9188 13.7368C43.9242 13.863 43.904 13.9891 43.8593 14.107C43.8146 14.225 43.7464 14.3323 43.659 14.4222C43.5658 14.5138 43.4552 14.585 43.3339 14.6316C43.2126 14.6781 43.0833 14.699 42.9538 14.693C42.8286 14.7012 42.7031 14.6827 42.5853 14.6386C42.4676 14.5945 42.3603 14.5259 42.2703 14.437C42.1804 14.3482 42.1097 14.2412 42.063 14.123C42.0162 14.0047 41.9944 13.8778 41.9989 13.7505V13.747ZM43.6421 30.2362H42.2452V17.4418H43.6421V30.2362Z"
          fill="white"
        />
        <path
          d="M47.7991 17.4421L47.8429 19.6322C48.2678 18.8869 48.8729 18.2641 49.6009 17.8226C50.319 17.4041 51.1351 17.1909 51.9628 17.2056C53.3125 17.2056 54.3181 17.5929 54.9794 18.3675C55.6407 19.1421 55.9782 20.3006 55.9917 21.8429V30.2366H54.6082V21.8292C54.6082 20.6879 54.3619 19.8344 53.8929 19.2758C53.4239 18.7171 52.6377 18.4361 51.6254 18.4361C50.7911 18.4267 49.9804 18.7181 49.3377 19.2586C48.6504 19.8413 48.1402 20.6103 47.8665 21.4762V30.2366H46.4797V17.4421H47.7991Z"
          fill="white"
        />
        <path
          d="M65.2034 26.9874C65.2116 26.6575 65.1399 26.3305 64.9946 26.0353C64.8493 25.7401 64.6348 25.4856 64.37 25.2943C63.6132 24.7853 62.7587 24.4454 61.8629 24.2969C60.9658 24.1289 60.0922 23.8504 59.2614 23.4675C58.7136 23.215 58.2403 22.8216 57.8881 22.3262C57.5783 21.8415 57.4217 21.2723 57.4393 20.6947C57.4351 20.207 57.5459 19.7253 57.7625 19.29C57.9791 18.8547 58.2953 18.4785 58.6844 18.1927C59.6071 17.5074 60.7309 17.1595 61.8731 17.2057C63.2767 17.2057 64.4071 17.5484 65.2473 18.2647C65.657 18.5924 65.9858 19.0128 66.2072 19.4925C66.4287 19.9721 66.5367 20.4976 66.5227 21.0272H65.1224C65.1257 20.6636 65.0435 20.3045 64.8827 19.9796C64.7219 19.6547 64.4871 19.3735 64.1979 19.1593C63.5336 18.6952 62.7514 18.4363 61.9455 18.4138C61.1396 18.3913 60.3445 18.6062 59.6562 19.0325C59.397 19.2107 59.1855 19.4515 59.0407 19.7332C58.8958 20.015 58.8221 20.3289 58.8261 20.6468C58.8101 20.9314 58.8633 21.2156 58.981 21.4744C59.0987 21.7332 59.2773 21.9587 59.501 22.1308C59.9632 22.4736 60.8034 22.8163 62.0283 23.0939C62.9777 23.2918 63.9 23.606 64.7749 24.0296C65.316 24.3061 65.7801 24.7162 66.1246 25.2223C66.5242 25.9171 66.659 26.7366 66.5036 27.5255C66.3481 28.3145 65.9131 29.0181 65.281 29.5031C64.4206 30.1509 63.3037 30.4731 61.9068 30.4731C60.4424 30.4731 59.2535 30.1132 58.3402 29.3935C57.9158 29.0794 57.5717 28.6665 57.3368 28.1894C57.102 27.7123 56.9832 27.1849 56.9906 26.6515H58.3841C58.3976 27.0328 58.4979 27.4058 58.677 27.7412C58.8561 28.0765 59.1093 28.3652 59.4166 28.5846C60.1599 29.0767 61.0376 29.3167 61.9237 29.2701C62.759 29.3129 63.5862 29.0848 64.2856 28.6189C64.5615 28.4474 64.7903 28.208 64.9507 27.9228C65.1112 27.6376 65.1981 27.3159 65.2034 26.9874V26.9874Z"
          fill="white"
        />
        <path
          d="M68.22 13.747C68.2157 13.6212 68.2362 13.4957 68.2802 13.378C68.3243 13.2603 68.391 13.1527 68.4765 13.0615C68.5669 12.9678 68.676 12.8949 68.7965 12.8476C68.917 12.8003 69.046 12.7797 69.175 12.7873C69.301 12.7802 69.427 12.7997 69.5453 12.8444C69.6635 12.8892 69.7714 12.9584 69.862 13.0475C69.9526 13.1367 70.0241 13.244 70.072 13.3626C70.1198 13.4812 70.143 13.6086 70.14 13.7367C70.145 13.8632 70.1242 13.9894 70.0789 14.1074C70.0336 14.2254 69.9648 14.3325 69.8768 14.4222C69.7842 14.5137 69.6742 14.5849 69.5535 14.6314C69.4328 14.678 69.3039 14.699 69.175 14.693C69.0464 14.6997 68.9179 14.6791 68.7976 14.6325C68.6773 14.5859 68.5679 14.5143 68.4765 14.4222C68.3914 14.3328 68.3249 14.227 68.2809 14.111C68.2368 13.995 68.2161 13.8713 68.22 13.747V13.747ZM69.8464 30.2362H68.463V17.4417H69.8464V30.2362Z"
          fill="white"
        />
        <path
          d="M71.6548 23.721C71.6548 21.7377 72.0912 20.1554 72.964 18.9741C73.3688 18.4059 73.9048 17.9475 74.525 17.6391C75.1451 17.3307 75.8303 17.1818 76.5204 17.2055C77.289 17.172 78.0527 17.3444 78.7349 17.7053C79.4172 18.0662 79.994 18.6031 80.4075 19.262V12.0747H81.7943V30.2399H80.4885L80.4311 28.5262C79.5313 29.8217 78.2199 30.4684 76.4968 30.4661C75.8135 30.4822 75.1367 30.3286 74.5251 30.0186C73.9135 29.7086 73.3857 29.2516 72.9876 28.6873C72.1013 27.5014 71.657 25.902 71.6548 23.8889V23.721ZM73.0618 23.9712C73.0618 25.6026 73.3846 26.889 74.0302 27.8304C74.327 28.283 74.7333 28.6506 75.2101 28.8979C75.6869 29.1451 76.2183 29.2638 76.7532 29.2425C78.4673 29.2425 79.6854 28.4748 80.4075 26.9393V20.9311C79.6854 19.27 78.4752 18.4383 76.7768 18.436C76.2411 18.4148 75.7089 18.5323 75.2302 18.7775C74.7515 19.0228 74.3421 19.3876 74.0404 19.8378C73.3903 20.7792 73.0641 22.157 73.0618 23.9712Z"
          fill="white"
        />
        <path
          d="M88.9074 30.4728C87.8844 30.4856 86.8781 30.2079 86.0022 29.6708C85.1328 29.1325 84.4309 28.3554 83.9777 27.4293C83.4864 26.4296 83.2388 25.3247 83.2556 24.2075V23.6968C83.2373 22.5495 83.4786 21.4133 83.9608 20.3757C84.3934 19.434 85.0713 18.6304 85.9212 18.0519C86.7249 17.4991 87.6736 17.2041 88.6442 17.2054C89.3293 17.1748 90.0124 17.3019 90.6423 17.5773C91.2722 17.8527 91.8326 18.2692 92.2816 18.7957C93.1814 19.8582 93.6313 21.3068 93.6313 23.1416V23.9333H84.6458V24.2075C84.6025 25.5275 85.0397 26.8173 85.874 27.8303C86.2481 28.2931 86.7205 28.664 87.2558 28.915C87.791 29.1661 88.3753 29.2909 88.9648 29.28C89.6451 29.2947 90.3199 29.1529 90.9387 28.8653C91.5524 28.5546 92.0853 28.1014 92.4942 27.5424L93.3648 28.2278C92.3435 29.7267 90.8577 30.4751 88.9074 30.4728ZM88.651 18.4118C88.1526 18.4035 87.6583 18.5038 87.2014 18.706C86.7445 18.9082 86.3356 19.2076 86.0022 19.584C85.2435 20.4621 84.7843 21.5656 84.693 22.7303H92.2479V22.5761C92.2576 21.4822 91.8912 20.4192 91.212 19.5703C90.8991 19.1931 90.5061 18.8929 90.0625 18.6923C89.6189 18.4916 89.1363 18.3957 88.651 18.4118V18.4118Z"
          fill="white"
        />
        <path
          d="M106.683 23.9576C106.683 25.9295 106.243 27.5084 105.363 28.6942C104.957 29.2645 104.419 29.7248 103.796 30.0349C103.174 30.345 102.486 30.4955 101.793 30.4731C101.214 30.4951 100.637 30.3863 100.103 30.1546C99.5701 29.9229 99.094 29.5739 98.7094 29.1329V35.1617H95.3352V17.4421H98.4564L98.5711 18.6965C98.9542 18.2152 99.4416 17.8302 99.9953 17.5716C100.549 17.313 101.154 17.1878 101.763 17.2056C102.461 17.174 103.156 17.3171 103.787 17.6226C104.418 17.928 104.965 18.3863 105.38 18.957C106.244 20.1223 106.675 21.7309 106.673 23.7828L106.683 23.9576ZM103.308 23.7108C103.308 22.5204 103.102 21.6064 102.688 20.9689C102.501 20.6577 102.235 20.4026 101.919 20.2298C101.603 20.057 101.248 19.9726 100.889 19.9852C99.8342 19.9852 99.1087 20.3954 98.7128 21.2157V26.4527C99.1244 27.2959 99.8578 27.7174 100.913 27.7174C102.517 27.7174 103.319 26.3819 103.319 23.7108H103.308Z"
          fill="white"
        />
        <path
          d="M115.112 30.2366C114.94 29.8754 114.826 29.4891 114.774 29.0918C114.377 29.5437 113.886 29.901 113.338 30.138C112.789 30.375 112.195 30.4859 111.599 30.4628C110.478 30.505 109.383 30.1171 108.532 29.3763C108.141 29.0402 107.828 28.6196 107.618 28.1452C107.407 27.6708 107.303 27.1547 107.314 26.6344C107.284 26.0298 107.405 25.4273 107.664 24.8823C107.923 24.3374 108.313 23.8673 108.798 23.5154C109.788 22.7888 111.218 22.4221 113.087 22.4153H114.636V21.6886C114.663 21.1758 114.503 20.6708 114.187 20.2697C114.005 20.08 113.784 19.9339 113.539 19.842C113.295 19.7501 113.033 19.7147 112.773 19.7385C112.289 19.707 111.81 19.8529 111.424 20.1498C111.263 20.2898 111.137 20.4651 111.053 20.6622C110.97 20.8594 110.931 21.0732 110.941 21.2876H107.567C107.567 20.5461 107.804 19.8245 108.242 19.2312C108.732 18.5683 109.393 18.0549 110.151 17.7472C111.029 17.3782 111.972 17.1951 112.922 17.2091C114.474 17.2091 115.705 17.6055 116.616 18.3984C117.527 19.1912 117.983 20.3051 117.983 21.7401V27.2821C117.983 28.4954 118.158 29.414 118.486 30.0241V30.2228L115.112 30.2366ZM112.331 27.8614C112.81 27.8648 113.282 27.747 113.704 27.5186C114.097 27.3203 114.422 27.0062 114.636 26.6172V24.4203H113.374C111.689 24.4203 110.789 25.0109 110.674 26.1922V26.3945C110.669 26.5918 110.706 26.788 110.782 26.9696C110.859 27.1512 110.973 27.3139 111.116 27.4467C111.453 27.7392 111.889 27.8879 112.331 27.8614V27.8614Z"
          fill="white"
        />
        <path
          d="M124.88 27.7171C125.431 27.7423 125.971 27.5578 126.395 27.1996C126.585 27.0274 126.737 26.8161 126.841 26.5798C126.946 26.3435 126.999 26.0875 126.999 25.8287H130.154C130.157 26.6805 129.914 27.5146 129.455 28.2278C128.994 28.9536 128.343 29.535 127.576 29.9073C126.754 30.3111 125.85 30.5152 124.937 30.5036C123.138 30.5036 121.718 29.9221 120.679 28.7591C119.64 27.596 119.119 25.9966 119.117 23.9607V23.7345C119.117 21.7649 119.633 20.1917 120.665 19.015C121.698 17.8383 123.115 17.251 124.917 17.2533C126.489 17.2533 127.755 17.7092 128.703 18.6243C129.181 19.0939 129.558 19.6601 129.807 20.2865C130.057 20.9128 130.175 21.5853 130.154 22.2607H126.999C127.008 21.661 126.792 21.0803 126.395 20.6362C126.168 20.4181 125.898 20.2505 125.604 20.1441C125.309 20.0376 124.996 19.9946 124.685 20.0178C124.373 20.0411 124.069 20.13 123.793 20.2789C123.517 20.4279 123.275 20.6336 123.081 20.8829C122.683 21.4142 122.484 22.3533 122.484 23.6488V23.9916C122.484 25.31 122.681 26.2571 123.075 26.8329C123.276 27.1243 123.548 27.3585 123.864 27.5133C124.18 27.668 124.53 27.7382 124.88 27.7171V27.7171Z"
          fill="white"
        />
        <path
          d="M135.218 25.1058L134.007 26.3362V30.2366H130.633V12.0714H134.007V22.1376L134.662 21.2877L137.884 17.4421H141.933L137.371 22.7751L142.321 30.2366H138.454L135.218 25.1058Z"
          fill="white"
        />
        <path
          d="M147.032 30.4731C145.182 30.4731 143.679 29.9019 142.52 28.7594C141.362 27.617 140.781 26.0838 140.779 24.1599V23.8171C140.759 22.6256 141.011 21.4456 141.515 20.3692C141.966 19.3995 142.688 18.5856 143.59 18.0283C144.518 17.4716 145.579 17.1869 146.657 17.2057C148.382 17.2057 149.739 17.7575 150.726 18.8611C151.714 19.9648 152.209 21.5299 152.211 23.5567V24.9516H144.19C144.266 25.722 144.616 26.4377 145.176 26.9635C145.747 27.4759 146.49 27.7459 147.251 27.7175C147.833 27.7436 148.412 27.6275 148.941 27.3788C149.469 27.13 149.931 26.7561 150.288 26.2883L151.941 28.1699C151.419 28.9148 150.713 29.506 149.893 29.8836C148.993 30.2898 148.016 30.4911 147.032 30.4731V30.4731ZM146.647 19.9613C146.344 19.9496 146.042 20.0048 145.761 20.1232C145.481 20.2415 145.23 20.4202 145.024 20.6468C144.566 21.2071 144.287 21.8957 144.224 22.621H148.904V22.3502C148.931 21.7053 148.715 21.0741 148.3 20.5851C148.083 20.3695 147.823 20.2026 147.539 20.0952C147.254 19.9877 146.95 19.9421 146.647 19.9613Z"
          fill="white"
        />
        <path
          d="M156.719 14.2992V17.4421H158.872V19.951H156.719V26.3362C156.684 26.7003 156.779 27.0651 156.986 27.3644C157.126 27.4818 157.287 27.5693 157.461 27.6217C157.635 27.6741 157.818 27.6903 157.998 27.6695C158.329 27.6729 158.66 27.6454 158.987 27.5872V30.1612C158.33 30.3627 157.648 30.4654 156.962 30.4662C154.602 30.4662 153.4 29.2563 153.355 26.8366V19.951H151.503V17.4421H153.345V14.2992H156.719Z"
          fill="white"
        />
        <path
          d="M10.1396 16.8354L17.9172 21.5L25.6914 16.8354L17.9172 12.1707L10.1396 16.8354Z"
          fill="#FFA400"
        />
        <path
          d="M7.21746 15.0805L17.9171 8.66101L28.6168 15.0805L35.8342 10.7517L17.9171 0L0 10.7517L7.21746 15.0805Z"
          fill="#FFA400"
        />
        <path
          d="M25.6914 16.8352L17.9172 21.4999V30.8327L25.6914 26.1646V16.8352Z"
          fill="#FF6C0E"
        />
        <path
          d="M28.6169 15.0803V27.9193L17.9172 34.3422V42.9998L35.8343 32.2515V10.7515L28.6169 15.0803Z"
          fill="#FF6C0E"
        />
        <path
          d="M10.1396 16.8352V26.1646L17.9172 30.8327V21.4999L10.1396 16.8352Z"
          fill="#415364"
        />
        <path
          d="M7.21746 27.9193V23.4671V15.0803L0 10.7515V32.2515L7.21746 36.5803V27.9193Z"
          fill="#415364"
        />
        <path
          d="M10.1396 29.6743V38.3353L17.9172 43V34.3424L10.1396 29.6743Z"
          fill="#415364"
        />
      </g>
      <defs>
        <clipPath id="clip0_93_4054">
          <rect width="159" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoIconWhite;
