import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import VTEPIcon from "../../../components/icons/VTEPIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { VTEPType } from "../types";

type SumProps = {
  title: string;
  data: VTEPType;
};

const SummaryBlock: FC<SumProps> = ({ title, data }) => {
  return (
    <SummaryInfoBlock icon={<VTEPIcon />} title={title}>
      <UnifiedTextPair subtitle="IP" text={data.local_ip} />
      <UnifiedTextPair subtitle="VRF" text={data.vrf?.name} />
      <UnifiedTextPair subtitle="Type" text={data.vtep_type} />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
