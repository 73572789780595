export const getUniqueIPs = (
  currentIPs: Array<string>,
  newIPs: Array<string>
): Array<string> => {
  return newIPs.filter((ip) => !currentIPs.includes(ip));
};

export const getMissedIPs = (
  currentIPs: Array<string>,
  newIPs: Array<string>
): Array<string> => {
  return currentIPs.filter((ip) => !newIPs.includes(ip));
};
