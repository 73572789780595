import React, { FC, useEffect, useMemo, useState } from "react";

import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../../components/common/table/newTable/Table";
import {
  TableColumnsAdminConfig,
  TableColumnsTenantConfig,
} from "./TableColumnsConfig";

import styles from "./Table.module.scss";
import AddButton from "../../../../components/common/buttons/AddButton";
import { FilterButton } from "./FilterButton";
import { TableGroupHeader } from "./TableGroupHeader";
import {
  combineState,
  convertToGroupRows,
  removeNATVis,
} from "../../helpers/table";
import { VirtualInterfaceApi } from "../../../VirtualInterfacePage/types";
import ConfirmDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../../helpers/getDialogControls";
import { RequestStatus } from "../../../../helpers/types";
import { useUserContext } from "../../../../contexts/UserContext";
import { UserRoles } from "../../../../helpers/rolesHelpers";
import { useConnectionContext } from "../ConnectionContext";
import { useHistory } from "react-router-dom";
import {
  NETWORK,
  ZAYO_CONNECTIONS,
} from "../../../../helpers/navigation/entries";
import Layer3InterfaceAddDialog from "../itemDialog/Layer3InterfaceAddDialog";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";

const ConnectionsTable: FC = () => {
  const { user, isZayo, isTenant } = useUserContext();
  const history = useHistory();
  const [showAdd, setShowAdd] = useState(false);
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchConnections, fetchSystems } = useConnectionContext();

  useEffect(() => {
    fetchConnections();
    fetchSystems();
  }, [selectedTenant]);

  const gotoZayoConnections = () => {
    const path = isTenant
      ? "/" + ZAYO_CONNECTIONS().path
      : "/" + NETWORK().path + "/" + ZAYO_CONNECTIONS().path;
    history.replace(path);
  };

  const {
    connections,
    systems,
    connectionFetchStatus,
    deleteConnection,
  } = useConnectionContext();

  const [
    connectionToDelete,
    setConnectionToDelete,
  ] = useState<VirtualInterfaceApi | null>(null);
  const [deleteRequestStatus, setDeleteRequestStatus] = useState<
    RequestStatus | undefined
  >();

  const data = removeNATVis(
    convertToGroupRows(combineState(connections), systems)
  ).filter((n) => n.subRows.length);

  const viCount = data.reduce((acc, cur) => acc + cur.subRows.length, 0);

  const handleConnectionDelete = async () => {
    if (!connectionToDelete) {
      return;
    }

    const { type, name } = connectionToDelete;
    const { ok } = await deleteConnection(type, name);
    if (ok) {
      setConnectionToDelete(null);
    }
  };

  const tablesParams = useMemo(() => {
    const isAdmin =
      user.role === UserRoles.ADMIN || user.role === UserRoles.ADMIN_FULL;
    const columns = isAdmin
      ? TableColumnsAdminConfig
      : TableColumnsTenantConfig;
    const grid = isAdmin
      ? "1fr 1fr 1fr 150px 1fr 1fr 1fr 200px 40px"
      : "1fr 1fr 1fr 150px 1fr 1fr 200px 40px";
    return { columns, grid };
  }, [user]);

  return (
    <div className={styles.table}>
      {connectionToDelete && (
        <ConfirmDialog
          status={deleteRequestStatus}
          icon={DeleteIcon}
          title="Delete Connection"
          message={`Are you sure you want to delete ${connectionToDelete?.name}?`}
          controls={getDeleteDialogControls({
            onDelete: handleConnectionDelete,
            onCancel: () => setConnectionToDelete(null),
          })}
        />
      )}
      {showAdd && selectedTenant && (
        <Layer3InterfaceAddDialog
          onClose={() => setShowAdd(false)}
          type={"add"}
          tenant={selectedTenant}
          onSubmit={() => {
            fetchConnections();
            fetchSystems();
          }}
        />
      )}
      <TableWrapper
        dataStatus={{ state: connectionFetchStatus }}
        isEmpty={connections.length == 0}
        titleProps={{
          title: (
            <TableTitle
              count={viCount}
              isZayo={isZayo}
              gotoZayoConnections={gotoZayoConnections}
              setShowAdd={setShowAdd}
            />
          ),
        }}
      >
        <Table
          // fetchAction={}
          header={TableHeader}
          columns={tablesParams.columns}
          groupHeader={TableGroupHeader}
          data={data}
          gridColumnTemplate={tablesParams.grid}
          rowActions={{
            onDelete: (row) => {
              isZayo
                ? gotoZayoConnections()
                : setConnectionToDelete(
                    (row as unknown) as VirtualInterfaceApi
                  );
            },
          }}
        />
      </TableWrapper>
    </div>
  );
};

export default ConnectionsTable;

type Props = {
  count: number;
  isZayo?: boolean;
  gotoZayoConnections: () => void;
  setShowAdd: (arg: boolean) => void;
};
const TableTitle: FC<Props> = ({
  count = 0,
  isZayo,
  gotoZayoConnections,
  setShowAdd,
}) => {
  return (
    <div className={styles.tableTitleWrapper}>
      <div>Connections ({count})</div>
      <div className={styles.titleButtons}>
        <FilterButton />
        <AddButton
          onClick={() => {
            isZayo ? gotoZayoConnections() : setShowAdd(true);
          }}
        />
      </div>
    </div>
  );
};
