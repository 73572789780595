import { useCallback, useEffect, useState } from "react";
import { TenantType } from "../apiTypes";
import { ERROR, OK, PENDING } from "../../common/RequestStateHelpers";

import { RequestState } from "../../types";
import { tenantApi } from "../TenantApi";

type Type = [Array<TenantType> | null, RequestState, () => void];

export const useTenantsList = (): Type => {
  const [requestStatus, setRequestStatus] = useState<RequestState>(PENDING);
  const [list, setList] = useState<Array<TenantType> | null>(null);

  const fetchList = useCallback(async () => {
    setRequestStatus(PENDING);
    const { ok, result } = await tenantApi.getTenantsList();

    const isError = !ok || !result;

    setList(isError ? null : result);
    setRequestStatus(isError ? ERROR : OK);
  }, []);

  useEffect(() => {
    fetchList();
  }, []);

  return [list, requestStatus, fetchList];
};
