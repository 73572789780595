import React, { FC, useEffect } from "react";
import { useUserContext } from "../../../../../contexts/UserContext";
import styles from "./NiroVersion.module.scss";

type Props = {};

const NiroVersion: FC<Props> = ({}) => {
  const { version, fetchVersion } = useUserContext();

  useEffect(() => {
    fetchVersion();
  }, []);

  return <div className={styles.version}>{version || ""}</div>;
};

export default NiroVersion;
