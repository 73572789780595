import React, { FC, useMemo, useState } from "react";
import BGPInfo from "../ServiceBGPConfig/common/BGPInfo";
import TipsBlock from "../ServiceBGPConfig/common/TipsBlock";
import BGPConfigFooter from "../ServiceBGPConfig/common/BGPConfigFooter";
import styles from "./ServiceBGPASNConfig.module.scss";
import { useBGPServicesContext } from "../../ServicesBGPContext";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../helpers/validators/Validator";
import { formFieldProps } from "../../../../helpers/dialogs/FieldProps";
import { ADD, EDIT } from "../../../../helpers/common/constantsAlias";
import { validateAS } from "../../../../helpers/validators/BGPServiceValidator";
import Input from "../../../../components/common/formComponents/Input";
import Section from "../../../../components/common/Section";
import { ASType } from "../../types";

type Props = {
  onClose: () => void;
  tenant: string;
  neighbors: number;
  type: string;
  data: any;
  onDelete?: () => void;
};

const DEFAULT_FIELDS: ASType = {
  asn: 0,
  keepalive: 60,
  hold: 180,
};

const ServiceBGPASNConfig: FC<Props> = ({
  onClose,
  tenant,
  neighbors,
  type,
  data,
  onDelete = () => null,
}) => {
  const { resetStatus, addStatus, addAS } = useBGPServicesContext();
  const [fields, handleFieldChange] = useFormField<any>(data || DEFAULT_FIELDS);
  const [errors, validate] = useValidation<any>(validateAS, [fields]);
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const isEdit = type === EDIT;

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const payload = isAdvanced ? fields : { asn: fields.asn };
      const success = await addAS(tenant, payload);
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  return (
    <div className={styles.content}>
      <BGPInfo
        fields={{
          selectedTenant: tenant,
          asn: fields.asn,
          neighbors: neighbors,
        }}
        className={styles.basicBlock}
        isAsn
        onDelete={onDelete}
      />
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <Section
            title={"General"}
            className={styles.sectionWrapper}
            columnsCount={2}
          >
            <Input
              {...fieldProps("asn")}
              label="ASN"
              isNotEditable={type === EDIT}
            />
          </Section>
          {isAdvanced && (
            <Section
              title={"Advanced"}
              className={styles.sectionWrapper}
              columnsCount={2}
              btmAlign
            >
              <Input
                {...fieldProps("keepalive")}
                label="Keepalive timer (sec)"
                isNotEditable={isEdit}
                type={"number"}
              />
              <Input
                {...fieldProps("hold")}
                label="Hold timer (sec)"
                isNotEditable={isEdit}
                type={"number"}
              />
            </Section>
          )}
        </div>
        <BGPConfigFooter
          isAddMode={type === ADD}
          onAdd={handleSubmit}
          onClose={handleClose}
          className={styles.footerWrapper}
          status={addStatus}
          isAdvanced={isAdvanced}
          onToggleAdvanced={() => setIsAdvanced((prev) => !prev)}
          disabledSubmit={isEdit}
        />
      </div>
      <TipsBlock className={styles.basicBlock} />
    </div>
  );
};

export default ServiceBGPASNConfig;
