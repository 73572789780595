import { FC } from "react";
import LeftBlock from "./components/LeftBlock";
import ContentBlock from "./components/ContentBlock/ContentBlock";
import InfoBlock from "./components/InfoBlock";
import styles from "./SystemConfig.module.scss";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import { SystemCreate } from "../../types";
import { useSystemConfigContext } from "./SystemConfigContext";
import { useValidation } from "../../../../../helpers/validators/Validator";
import { FieldsType } from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { validateSystemNode } from "../../../../../helpers/validators/SystemValidator";

export const DEFAULT_NODE_FIELDS = {
  name: "",
  address: "",
  location: "",
  description: "",
};

const DEFAULT_FIELDS: SystemCreate = {
  name: "",
  location: "",
  wg_conf: {},
  nodes: [
    {
      ...DEFAULT_NODE_FIELDS,
    },
  ],
};

type Props = {
  onClose: () => void;
};

const SystemConfigAdd: FC<Props> = ({ onClose }) => {
  const { add, addStatus } = useSystemConfigContext();
  const [fields, handleFieldChange] = useFormField<SystemCreate>(
    DEFAULT_FIELDS
  );

  const [errors, validate] = useValidation<FieldsType>(validateSystemNode, [
    fields,
  ]);

  const handleAdd = async () => {
    const { isOk } = validate();
    if (isOk) {
      const newFields = { ...fields, location: fields.nodes[0].location };
      const success = await add(newFields);
      if (success) onClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <LeftBlock className={styles.basicAppearance} fields={fields} />
      <ContentBlock
        className={styles.basicAppearance}
        fields={fields}
        onChange={handleFieldChange}
        onClose={onClose}
        onAction={handleAdd}
        actionStatus={addStatus}
        errors={errors}
      />
      <InfoBlock className={styles.basicAppearance} />
    </div>
  );
};
export default SystemConfigAdd;
