import { FC, useEffect, useState } from "react";
import Chart from "./Chart";
import { arubaApi } from "../../../helpers/api/ArubaApi";

type Props = {
  className: string;
  list: Array<any>;
};

const Health: FC<Props> = ({ className, list }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const devices = list.map((item) => item.id).join();

  useEffect(() => {
    if (!list.length) return;
    if (!isLoading) {
      setLoading(true);
    }
    const date = new Date();
    const sixDays = 6 * 24;

    date.setHours(date.getHours() - sixDays);
    date.setMinutes(0);
    date.setHours(0);
    date.setSeconds(0);

    const payload = {
      from: Math.floor(date.getTime() / 1000),
      to: Math.floor(Date.now() / 1000),
      nePks: list.map((item) => item.id),
      verticals: ["LOSS", "LATENCY", "JITTER", "MOS"],
      overlayId: -1,
    };

    arubaApi.getHealth(payload).then((res) => {
      const entries = Object.entries(res.result || []);
      setData(entries);
      setLoading(false);
    });
  }, [devices]);

  return (
    <div className={className} data-loading={isLoading}>
      <h4>Health Map</h4>
      <Chart data={data} list={list} />
    </div>
  );
};

export default Health;
