import { CellFactory } from "../../components/common/table/newTable/cells/CellsFactory";
import { PublicIpManagementRow } from "./types";

const cellFactory = new CellFactory<PublicIpManagementRow>({});

export const IpamTableColumns = (): Array<any> => {
  return [
    {
      accessor: "id",
      Header: "ID",
    },
    {
      accessor: "name",
      Header: "Pool Name",
    },
    {
      accessor: "from_ip",
      Header: "Start IP",
    },
    {
      accessor: "to_ip",
      Header: "End IP",
    },
    {
      accessor: "data",
      Header: "System",
    },
    {
      accessor: "vrf_name",
      Header: "VRF",
    },
    {
      accessor: "services",
      Header: "Allowed Services",
      Cell: cellFactory.labelArrayCell(""),
    },
    {
      accessor: "usage",
      Header: "Usage",
    },
    {
      accessor: "tenants",
      Header: "Tenants",
    },
  ];
};
