import React, { FC, useEffect } from "react";
import styles from "../MeshNetworkPage/MeshNetworkPage.module.scss";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import { parseQuery } from "../../helpers/common/parseQuery";
import { useAuthContext } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";

export const CheckSSOPage: FC = () => {
  const history = useHistory();
  const { getTokenSSO, setSSOError, getSSOSource, ssoError } = useAuthContext();
  const code = parseQuery("code");
  const state = parseQuery("state");
  const ssoSource = getSSOSource();

  useEffect(() => {
    if (code && state && !ssoError) {
      getTokenSSO(state, code, redirect);
    }
  }, []);

  const redirect = () => {
    history.push("/");
  };

  if (!code || !state) {
    setSSOError(
      `Invalid data to login with ${
        ssoSource || ""
      } sso: code or state are empty`
    );
    history.push("/login");
  }

  return (
    <div className={styles.wrapper}>
      <PageLoader />;
    </div>
  );
};
