import React, { useCallback } from "react";
import { PageProps, PathEntry } from "../../helpers/types";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../common";
import { RouteEntry } from "../types";
import { CONFIGURATION } from "../../helpers/navigation/entries";
import SDWANProvidersContent from "./SDWANProvidersContent";

const SDWANProvidersPage: React.FC<PageProps> = ({ tabs }) => {
  const path = useMatchPath();

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
      <Routes
        routes={providersRoutes}
        tabs={tabs}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default SDWANProvidersPage;

const ProviderConfig: React.FC<PageProps> = ({ tabs }) => {
  return <SDWANProvidersContent tabs={tabs} />;
};

const providersRoutes: Array<RouteEntry> = [
  {
    pathFunc: CONFIGURATION,
    component: ProviderConfig,
  },
];
