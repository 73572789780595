import { systemApi } from "../../../helpers/api/SystemApi";
import { ConnectionFields } from "../ConnectionStep/components/ConnectionsConfigurator/utils/formConsts";
import { IPSEC_SERVICE_BODY } from "./onboardingConsts";
import {
  VirtualInetrfaceGate,
  VirtualInetrfaceIpsecAdd,
} from "../../../helpers/api/TenantVirtualInterfaceApi/types";

export const getIpsecParametres = async (
  fields: ConnectionFields,
  gateVi?: VirtualInetrfaceGate
): Promise<Partial<VirtualInetrfaceIpsecAdd> | undefined> => {
  const { ok, result } = await systemApi.getSystemsList();
  if (!ok || !result || !gateVi) return;
  const params = IPSEC_SERVICE_BODY;
  params.name = fields.name;
  params.remote_ip = fields.ip;
  params.remote_networks = fields.remoteNetworks;
  params.psk = fields.psk;
  params.local_id_data = gateVi.ip_addresses[0].split("/")[0];
  params.remote_id_data = fields.ip;
  params.description = `ipsec_${fields.labels.remote_type}`;
  params.virtual_interface_gate_name = gateVi.name;
  if (fields.network_segments) {
    params.network_segments = fields.network_segments;
  }
  params.labels = fields.labels;
  return params;
};
