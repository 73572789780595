import React, { ReactElement } from "react";
import styles from "./LogoutButton.module.scss";
import { Redirect } from "react-router-dom";
import { useAuthContext } from "../../../contexts/AuthContext";
import IconBlankButton from "../../common/buttons/IconBlankButton";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import LogoutIcon from "../../icons/LogoutIcon";

const LogoutButton = (): ReactElement => {
  const { logout, isAuthorized } = useAuthContext();

  if (!isAuthorized) {
    return <Redirect to={"/login"} />;
  }

  const handleLogout = () => {
    logout();
  };

  return (
    <div className={styles.wrapper}>
      <IconBlankButton
        icon={LogoutIcon}
        className={styles.icon}
        color={GREY_FONT}
        onClick={handleLogout}
        hint={"Logout"}
        id={"Logout"}
      />
    </div>
  );
};

export default LogoutButton;
