import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function VeloCloudIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3.61882 9.79493C3.30406 9.11793 2.52825 8.80782 1.80341 9.12885C1.07858 9.44551 0.810368 10.2383 1.13843 10.9196L4.18406 17.4407C4.66285 18.4628 5.16824 19 6.11473 19C7.12551 19 7.56883 18.4147 8.0454 17.4407C8.0454 17.4407 10.7031 11.7408 10.7275 11.684C10.7563 11.6228 10.8406 11.4394 11.111 11.4394C11.3393 11.4416 11.5321 11.6207 11.5321 11.8609V17.4363C11.5321 18.2946 12.0154 19 12.9463 19C13.8773 19 14.3783 18.2946 14.3783 17.4363V12.8764C14.3783 11.9963 15.0189 11.4263 15.89 11.4263C16.7611 11.4263 17.3419 12.0159 17.3419 12.8764V17.4363C17.3419 18.2946 17.8273 19 18.7561 19C19.6849 19 20.1902 18.2946 20.1902 17.4363V12.8764C20.1902 11.9963 20.8286 11.4263 21.6998 11.4263C22.5709 11.4263 23.1516 12.0159 23.1516 12.8764V17.4363C23.1516 18.2946 23.6349 19 24.5658 19C25.4968 19 26 18.2946 26 17.4363V12.2452C26 10.3365 24.4439 9 22.5709 9C20.6979 9 19.5275 10.2776 19.5275 10.2776C18.9046 9.48264 18.0468 9.00218 16.5927 9.00218C15.0588 9.00218 13.7177 10.2776 13.7177 10.2776C13.0926 9.48264 12.0331 9.00218 11.1553 9.00218C9.79653 9.00218 8.71704 9.58965 8.06092 11.0747L6.11473 15.591L3.61882 9.79493Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default VeloCloudIcon;
