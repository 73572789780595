import { FC, useEffect, useState } from "react";
import QRCode from "react-qr-code";
import BlankButton from "../buttons/BlankButton";
import QRPlaceholder from "./QRPlaceholder";
import styles from "./QRCodeGenerator.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import { RequestStatus } from "../../../helpers/types";
import LoaderIcon from "../loadStates/LoaderIcon";

type Props = {
  value?: string;
  status?: RequestStatus;
  className?: string;
  user?: string;
  onClick: () => void;
};

const QRCodeGenerator: FC<Props> = ({
  value = "CONFIG TEXT",
  className,
  status,
  user,
  onClick,
}) => {
  const [showQRCode, setShowQRCode] = useState<boolean>(false);

  useEffect(() => {
    if (value && status?.state === "ok") setShowQRCode(true);
  }, [value, status]);

  useEffect(() => {
    setShowQRCode(false);
  }, [user]);

  const isLoading = status?.state === "pending";

  const wrapperClasses = classNames(
    styles.wrapper,
    showQRCode && styles.withPaddings,
    className
  );

  return (
    <div className={wrapperClasses}>
      {showQRCode ? (
        <div className={styles.clickable} onClick={() => setShowQRCode(false)}>
          <QRCode value={value} mode={"text"} />
        </div>
      ) : (
        <>
          <QRPlaceholder className={styles.placeholderImg} />
          <BlankButton
            id="qrGeneratorBtn"
            onClick={onClick}
            className={styles.btn}
          >
            {isLoading ? (
              <LoaderIcon className={styles.loader} />
            ) : (
              "Click to Show the QR"
            )}
          </BlankButton>
        </>
      )}
    </div>
  );
};

export default QRCodeGenerator;
