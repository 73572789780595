import { Text } from "@mantine/core";
import React from "react";
import ReactTooltip from "react-tooltip";

import styles from "./CellHoverTooltip.module.scss";
import { RemoteUserStatus, User } from "../../helpers/types";
import { useUserListContext } from "../../contexts/UserListContext";
import UserRoleLabel from "../../components/common/table/UserRoleLabel";

type Props = {
  rowOriginal: RemoteUserStatus;
};

const EMPTY_VALUE = "-";

export const CellHoverTooltip = ({ rowOriginal }: Props) => {
  const { username } = rowOriginal;
  const { userList } = useUserListContext();
  const tooltipId = rowOriginal.username;
  const user = userList.find((userObj) => userObj.username === username);
  const hasFullName = !!user?.full_name;
  let fullName = hasFullName ? user.full_name : `${user?.first_name} `;

  if (!hasFullName && user?.last_name) fullName += user.last_name;

  return (
    <>
      <ReactTooltip
        aria-haspopup="true"
        place="left"
        type="light"
        id={tooltipId}
        className={styles.tooltip}
      >
        <UserRoleLabel role={user?.role || EMPTY_VALUE} />
        <Text className={styles.desc}>Full name</Text>
        <Text className={styles.main}>{fullName || EMPTY_VALUE}</Text>
        <Text className={styles.desc}>Email</Text>
        <Text className={styles.main}>{user?.email || EMPTY_VALUE}</Text>
      </ReactTooltip>
    </>
  );
};
