import { FC, useEffect, useState } from "react";
import Button from "../../../components/common/buttons/Button";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";
import SearchIcon from "../../../components/icons/SearchIcon";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { WHITE } from "../../../helpers/common/colorAliases";
import styles from "./ProviderDiscovery.module.scss";
import { useSDWANProvidersContext } from "../SDWANProvidersContext";
import { arubaTableHeader } from "../common/arubaTableHeader";
import ProviderGatewayForm from "./ProviderGatewayForm";
import TableWrapper from "../../../components/common/table/newTable/layout/TableWrapper";
import { OK_STATUS } from "../../../components/const/api";
import Table from "../../../components/common/table/newTable/Table";
import TableHeader from "../../../components/common/table/newTable/rows/TableHeader";
import { ArubaInterface } from "../../WizardToolPage/types";

const fieldsData = {
  aruba: {
    columns: arubaTableHeader,
    tableGrid: `250px repeat(5, 1fr)`,
  },
};

const getDataByType = (type: string) => {
  switch (type) {
    default:
      return fieldsData.aruba;
  }
};

type Props = { type: string };

const ProviderDiscovery: FC<Props> = ({ type }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { discovery, discoverStatus, gateways } = useSDWANProvidersContext();
  const [showList, setShowList] = useState<boolean>(false);

  const specificData = getDataByType(type);

  const [selectedRow, setSelectedRow] = useState<
    | {
        body: any;
        onClose: any;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (gateways[type]) {
      setShowList(gateways[type]?.length > 0);
    }
  }, [gateways[type]]);

  const handleDiscovery = () => {
    if (selectedTenant) {
      discovery(selectedTenant, type);
    }
  };

  const handleAdd = (row: any) => {
    setSelectedRow({ body: row.original, onClose: row.toggleRowExpanded });
  };

  const getGatewayFormByType = () => {
    return (
      <ProviderGatewayForm
        onClose={() => selectedRow?.onClose()}
        host_name={selectedRow?.body?.host_name}
        interface_data={selectedRow?.body?.interface_data as ArubaInterface}
        ip={selectedRow?.body?.ip}
        ne_pk={selectedRow?.body?.ne_pk}
      />
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <div className={styles.discoverLabel}>Discover to Add Gateways</div>
        <Button onClick={handleDiscovery} isPrimaryBtn className={styles.btn}>
          {discoverStatus[type]?.state === "pending" ? (
            <LoaderIcon color={WHITE} className={styles.icon} />
          ) : (
            <SearchIcon color={WHITE} className={styles.icon} />
          )}
          Discover
        </Button>
      </div>
      {showList && (
        <TableWrapper
          dataStatus={OK_STATUS}
          tablePlaceholder={["Gateways", "Gateway"]}
          isEmpty={false}
          className={styles.listTable}
        >
          <Table
            columns={specificData.columns(handleAdd)}
            data={gateways[type]}
            header={TableHeader}
            gridColumnTemplate={specificData.tableGrid}
            renderRowSubComponent={getGatewayFormByType}
            noInitialRowExpand
            withoutPagination
          />
        </TableWrapper>
      )}
    </div>
  );
};
export default ProviderDiscovery;
