import { isEqual } from "lodash";
import React, { FC, useEffect, useMemo, useState } from "react";
import {
  HISTORY_LOG_TIME_RANGE_MAP,
  TIME_RANGE_MAP,
  TimeRangeType,
  useTimerangeContext,
} from "../../../contexts/TimerangeContext";
import { classNames } from "../../../helpers/common/classNames";
import { useDateRange } from "../../../helpers/hooks/useDateRange";
import { GTimeRange } from "../../common/charts/GraphanaLinksFactory";
import BlankButton from "../buttons/BlankButton";
import Button from "../buttons/Button";
import FromToComponent from "./FromToComponent";

import styles from "./RangeSelectForm.module.scss";

type Props = { onClose: VoidFunction; type?: TimeRangeType };

const RangeSelectForm: FC<Props> = ({ onClose, type = "general" }) => {
  const {
    timeRange,
    setTimeRange,
    isManual,
    setActiveTitle,
  } = useTimerangeContext();
  const [newTimeRange, setNewTimeRange] = useState<GTimeRange>(timeRange[type]);
  const [from, to, setRangeValue, clearDataRange] = useDateRange(
    isManual ? timeRange[type] : undefined
  );

  const timeRangeEntries = useMemo(() => {
    return Object.values(
      type === "historyLog" ? HISTORY_LOG_TIME_RANGE_MAP : TIME_RANGE_MAP
    );
  }, [type]);

  const manual = from && to;

  const handleApply = () => {
    const newValue = manual
      ? { from: from?.toISOString(), to: to?.toISOString() }
      : newTimeRange;
    setTimeRange({ ...timeRange, [type]: newValue }, isManual);
    onClose();
  };

  return (
    <>
      <div className={styles.topPart}>
        <div className={styles.title}>Time range</div>
        <div className={styles.contentPart}>
          <div className={styles.timeRange}>
            <div className={styles.subTitle}>Select relative time range</div>
            {timeRangeEntries.map(({ title, value }) => (
              <BlankButton
                id={title}
                key={title}
                onClick={() => {
                  setActiveTitle(title);
                  setNewTimeRange(value);
                  clearDataRange();
                }}
                className={classNames(
                  styles.timeRangeItem,
                  !manual &&
                    isEqual(value, newTimeRange) &&
                    styles.chosenTimeRange
                )}
              >
                {title}
              </BlankButton>
            ))}
          </div>
          <FromToComponent from={from} to={to} setField={setRangeValue} />
        </div>
      </div>
      <div className={styles.contentBtnPart}>
        <Button onClick={onClose} className={styles.btn}>
          Cancel
        </Button>
        <Button onClick={handleApply} className={styles.btn} isPrimaryBtn>
          Apply
        </Button>
      </div>
    </>
  );
};

export default RangeSelectForm;
