import { RequestStatus } from "./../types";
export const SETTINGS = "settings";
export const EXPANDER = "expander";
export const HIGHLIGHTED_ROW_ID = "highlightedRowID";
export const DEFAULT_ROW_ID = "defaultRowID";
export const DEFAULT_SUBROW_ID = "defaultSubrowID";

export const SystemsNetworkHeaderLabels = [
  { title: "Systems", values: [8] },
  {
    title: "Tenants",
    values: [560, 1000],
  },
  { title: "VNIs", values: [1782] },
  {
    title: "BW",
    values: [253, "400 Gbps"],
  },
];

export const COKE_ONE = "Coke_one";
export const PEPSI_TWO = "Pepsi_two";
export const RC_THREE = "RC_three";
export const RED_BULL_FOUR = "RedBull_four";

export const AWS = "AWS";
export const AZURE = "Azure";
export const GOOGLE_CLOUD = "Google Cloud";
export const BRANCHES = "Branches";
export const INTERNET_GWS = "Internet/GWs";
export const DC_COLO_EDGE = "DC/CoLo/EDGE";

export const PROTOCOL_ANY = "Any";
export const PROTOCOL_TCP = "TCP";
export const PROTOCOL_UDP = "UDP";
export const PROTOCOL_TCP_UDP = "TCP/UDP";
export const PROTOCOL_ICMP = "ICMP";

export const PROTOCOLS_TYPES_MAP = [
  PROTOCOL_ANY,
  PROTOCOL_TCP,
  PROTOCOL_UDP,
  PROTOCOL_ICMP,
];

export const ECT_0 = "ECT(0)";
export const ECT_1 = "ECT(1)";
export const NECT = "Not-ECT";
export const CE = "CE";

export const ECN_MAP = [ECT_0, ECT_1, NECT, CE];

export const PROTOCOLS_CODES_MAP = [
  { type: PROTOCOL_ANY, code: "" },
  { type: PROTOCOL_TCP, code: 6 },
  { type: PROTOCOL_UDP, code: 17 },
  { type: PROTOCOL_ICMP, code: 1 },
];

export const MAX_TRAFFIC_G = 50;

export const ADD = "add";
export const EDIT = "edit";
export const SAVE = "save";
export const CONFIG = "config";
export const NEXT = "next";

export const CANCEL = "cancel";
export const BACK = "back";

export const ALL = "all";

export const SOURCE_ONE_TO_ONE = "1:1 NAT";
export const SOURCE_NAT = "Source NAT";
export const DNAT = "Port forwarding";

export const NAT_TYPE_MAP: {
  [key: string]: string;
} = {
  SOURCE_ONE_TO_ONE: SOURCE_ONE_TO_ONE,
  SOURCE_NAT: SOURCE_NAT,
  DNAT: "Port forwarding",
};

export const BPS = "bps";
export const PPS = "pps";

export const FW_POLICIES: { [key: string]: string } = {
  Allow: "ALLOW",
  Deny: "DENY",
};

export const PBR_ACTIONS: { [key: string]: string } = {
  Route: "ROUTE",
};

export const AF_TYPE_CONST: { [key: string]: string } = {
  "IPv4 unicast": "IPV4_UNICAST",
  "IPv6 unicast": "IPV4_UNICAST",
};

export const AF_TYPE = ["IPv4 unicast", "IPv6 unicast"];
export const ROUTE_MAP = ["accept-all"];

export const EMPTY_RESULT = {
  ok: false,
  error: undefined,
  result: undefined,
};

export const EMPTY_RESULT_OK = {
  ok: true,
  error: undefined,
  result: undefined,
};

export const EMPTY_REQUEST_STATUS: RequestStatus = {
  state: "ok",
  message: "",
};

export const ADMIN_STATE = ["up", "down"];
export const GATEWAY_TYPES = ["VI-1"];
export const VIRT_INTERFACE_TYPES = [
  "bridged",
  "routed",
  "gateway",
  "loopback",
];
export const VIRT_INTERFACE_SYSTEM_TYPES = ["bridged", "routed", "loopback"];

export const FW_GROUPS_MAP = ["Firewall Rules", "Segmentation", "Block all"];
export const FIREWALL_RULE_GROUP = "Firewall Rules";
export const SEGMENTATION_GROUP = "Smart Policies";
export const BLOCK_ALL_GROUP = "Block all";
