import React from "react";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  ARPS,
  BGP,
  CLOUD_ROUTER,
  NETWORK,
  PBR,
  STATIC_ROUTES,
} from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";
import TimerangOverlayComponent from "../../timerange/TimerangeOverlayComponent";
import NavigationTabs from "../NavigationTabs";
import ActiveTenantSelector from "../tenantSelector/ActiveTenantSelector";

import styles from "./CloudRouterTab.module.scss";

export const CloudRouterTab: React.FC<{
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  hideControls?: boolean;
}> = ({ showTimeRange }) => {
  return (
    <div className={styles.tabsWrapper}>
      <ServiceTabs />
      <div className={styles.controlsWrapper}>
        <ActiveTenantSelector />
        {showTimeRange && (
          <TimerangOverlayComponent
            id={"cloudRouterTab"}
            className={styles.timerangeWrapper}
          />
        )}
      </div>
    </div>
  );
};
export const ServiceTabs: React.FC = () => {
  const history = useHistory();
  const handleClick = (path: PathEntry) => {
    history.replace(formPathFromArray([NETWORK(), CLOUD_ROUTER(), path]));
  };
  return (
    <NavigationTabs
      entries={servicesArray}
      disabledTabKeys={[]}
      onClick={handleClick}
    />
  );
};

const servicesArray = [STATIC_ROUTES(), BGP(), ARPS(), PBR()];
