import React, { FC, useEffect } from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../../contexts/TimerangeContext";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { TRAFFIC_FIELD_LABEL } from "../../../consts";
import { useTrafficContext } from "../../../TrafficContext";
import styles from "./FilterFields.module.scss";

type Props = {
  className?: string;
  fields: any;
  onChange: any;
};

const FilterFields: FC<Props> = ({ fields, onChange }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { filtersData, fetchData } = useTrafficContext();

  useEffect(() => {
    if (selectedTenant) fetchData(selectedTenant, timeRange.general);
  }, [selectedTenant]);

  return (
    <div className={styles.wrapper}>
      {filtersData &&
        Object.keys(filtersData).map((filter) => {
          const id = `trafficDashboardFilterFields_${filter}`;
          const label = TRAFFIC_FIELD_LABEL[filter];
          return (
            <DropdownBasic
              id={id}
              onChange={(val) => onChange(filter, val.key)}
              selected={mapStringToItem(fields[filter])}
              label={label}
              itemsList={filtersData[filter].map(mapStringToItem)}
              isMedium
            />
          );
        })}
    </div>
  );
};

export default FilterFields;
