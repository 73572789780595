/* eslint-disable css-modules/no-unused-class */
import React, { ReactElement } from "react";
import GradientalBackground from "../../../components/icons/GradientalBackground";
import LoginIconsInRow from "../../../components/icons/LoginIconsInRow";
import { useUserContext } from "../../../contexts/UserContext";
import { classNames } from "../../../helpers/common/classNames";
import styles from "../LoginPage.module.scss";

export default function RightSideImage(): ReactElement {
  const { isZayo } = useUserContext();
  return (
    <div className={classNames(styles.rightPart, isZayo && styles.zayoColors)}>
      {!isZayo && <GradientalBackground className={styles.background} />}
      <div className={styles.rightContent}>
        {isZayo ? (
          <img src="/zayoGrafics/zayoImg.png" />
        ) : (
          <LoginIconsInRow className={styles.iconsRow} />
        )}
        <span className={styles.welcome}>
          {isZayo ? "Network as a Service" : "Born in the Multi-Cloud"}
          <div className={styles.by}>by InsidePacket</div>
        </span>
      </div>
    </div>
  );
}
