import React, { FC, useEffect, useState } from "react";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import PortsLabels from "./common/PortsLabels";
import APortBlock from "./components/APort/APortBlock";
import ServicesBlock from "./components/Services/ServicesBlock";
import ZPortBlock from "./components/ZPort/ZPortBlock";
import ConnectionConfiguratorContext, {
  useConnectionConfiguratorContext,
} from "./ConnectionConfiguratorContext";
import { CLOUD_ROUTER, CONNECTIONS_DEFAULTS_FIELDS } from "./consts";
import styles from "./ConnectionConfigurator.module.scss";
import { useHistory, useParams } from "react-router-dom";
import DialogBtmButtons from "../../../../components/dialogs/common/DialogBtmButtons";
import { CANCEL, SAVE } from "../../../../helpers/common/constantsAlias";
import { useValidation } from "../../../../helpers/validators/Validator";
import validateConnection from "../../../../helpers/validators/ZayoConnectionValidator";
import { ChangeConnectionsFields, FieldsType } from "./types";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { getPortType } from "./helpers/getPortType";
import { convertDataToFields } from "./helpers/convertDataToFields";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { getDataObjectCopy } from "../../../../helpers/getDataObjectCopy";
import LeftBlock from "./components/LeftBlock/LeftBlock";
import { useConnectionsContext } from ".././ConnectionContext";
import { ServiceAPI } from "../../../ServicesPreferencesPage/types";

const ConnectionConfiguratorEdit: FC = () => {
  const history = useHistory();
  const { connection } = useParams<{ connection: string }>();
  const {
    fetchConnections,
    selectedConnections,
    listApi,
    endpoints,
    portType,
    changePortType,
    getLocation,
    edit,
    editStatus,
    services,
    fetchServices,
  } = useConnectionConfiguratorContext();
  const { fetchData } = useConnectionsContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchData(selectedTenant);
      fetchServices(selectedTenant);
    }
  }, [selectedTenant]);

  const [currServices, setCurrServices] = useState<Array<ServiceAPI>>([]);

  useEffect(() => {
    if (services) {
      setCurrServices(services);
    }
  }, [services]);

  useEffect(() => {
    if (listApi && endpoints) {
      fetchConnections(connection.slice(1));
    }
  }, [connection, listApi, endpoints]);

  // Fields
  const [fields, onChangeFields] = useFormField({
    name: "",
    connections: [],
  });

  // Reassign data to fields
  useEffect(() => {
    if (selectedConnections) {
      const firstConnection = selectedConnections[0];

      // get name
      const name =
        firstConnection?.labels?.parentConnection || firstConnection?.name;
      onChangeFields("name", name);

      // get portType
      const portType = getPortType(firstConnection);
      portType && changePortType(portType);

      // get connections
      const connections = convertDataToFields(selectedConnections, getLocation);
      onChangeFields("connections", connections);
    }
  }, [selectedConnections]);

  // Change fields
  const handleChangeConnectionsFields: ChangeConnectionsFields = (
    idx: number,
    connectionFields: FieldsType
  ): void => {
    const connections = getDataObjectCopy(fields.connections);
    connections[idx] = { ...connectionFields };
    onChangeFields("connections", connections);
  };

  // Change BW Fields
  const handleChangeBW = (idx: number, value: string): void => {
    const newConnections = fields.connections.map((el: FieldsType, i) => {
      if (portType.key !== "azPorts" && idx !== i) return el;
      return {
        ...el,
        BW: value,
      };
    });
    onChangeFields("connections", newConnections);
  };

  // Delete connection
  const handleDeleteConnection = (idx: number) => {
    if (fields.connections.length === 2 && idx === 1) {
      handleChangeConnectionsFields(
        idx,
        CONNECTIONS_DEFAULTS_FIELDS[CLOUD_ROUTER]
      );
    }
    const connections = fields.connections.filter((el, i) => i !== idx);
    onChangeFields("connections", connections);
  };

  // errors
  const [errors, validate] = useValidation(validateConnection, [
    {
      ...fields,
      isCloudRouter: portType.key === CLOUD_ROUTER,
    },
  ]);

  // Actions
  const onClose = () => {
    history.push("/");
  };

  const handleEditConnections = async () => {
    const { isOk } = validate();
    if (!selectedTenant || !isOk) return;
    const res = await edit(selectedTenant, {
      ...fields,
      services: currServices,
    });
    if (res) {
      history.push("/");
    }
  };

  if (!fields.connections.length && !services) return <PageLoader />;

  const isAZPorts = portType.key === "azPorts";

  return (
    <div className={classNames(styles.wrapper, isAZPorts && styles.twoCols)}>
      <PortsLabels className={styles.labels} />
      <LeftBlock
        connectionName={fields.name}
        onChangeConnectionName={(val) => onChangeFields("name", val)}
        selected={fields.connections[0]?.["innerType"] || ""}
        onSelect={(innerType: string) => {
          if (fields.connections.length === 0) return;
          const connection: FieldsType = fields.connections[0];
          const newFields = { ...connection, innerType };
          handleChangeConnectionsFields(0, newFields);
        }}
        error={errors?.name}
        isEdit
        className={styles.leftBlock}
      />
      <APortBlock
        className={styles.aPort}
        aPort={fields.connections[0]}
        onChange={handleChangeConnectionsFields}
        handleChangeBW={handleChangeBW}
        errors={errors}
        isEdit
      />
      <ZPortBlock
        fields={fields.connections}
        onChange={handleChangeConnectionsFields}
        onDelete={handleDeleteConnection}
        handleChangeBW={handleChangeBW}
        className={styles.zPort}
        errors={errors}
        isEdit
      />
      {!isAZPorts && (
        <ServicesBlock
          className={styles.services}
          services={currServices}
          setServices={setCurrServices}
        />
      )}
      <DialogBtmButtons
        className={styles.footer}
        controls={{
          okText: SAVE,
          onOk: handleEditConnections,
          cancelText: CANCEL,
          onCancel: onClose,
        }}
        errorDisplay={editStatus}
      />
    </div>
  );
};

export default withContexts<any>(withLayout(ConnectionConfiguratorEdit), [
  ConnectionConfiguratorContext,
]);
