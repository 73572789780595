export const FIELD_LABELS_MAP: { [key: string]: string } = {
  aPort: '"A" Port',
  zPort: '"Z" Port',

  endpoint_location: "Location",
  Connection_type: "Connection Type",
  endpoint_name: "Name",

  ip_addresses: "IP Adresses",

  BW: "BW",

  Phisical_Port: "Physical Port #",

  region: "Region",

  VLAN_ID: "VLAN ID",
  Customer_ID: "Customer ID",
  Tagged: "Tagged",
};

export const CONFIG_FIELDS_MAP = [
  "endpoint_location",
  "BW",
  "ip_addresses",
  "Connection_type",
  "Phisical_Port",
  "VLAN_ID",
  "Customer_ID",
];
