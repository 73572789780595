import React, { FC, useEffect, useState } from "react";
import ButtonsGroup, {
  Tab,
} from "../../../../components/common/buttons/ButtonsGroup";
import ChartsHeader from "../../../../components/common/charts/ChartsHeader/ChartsHeader";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getTrafficLink } from "../../common/trafficHelper";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./Flows.module.scss";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import { useTenantContext } from "../../../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";

const TABS = [
  { name: "Locations", icon: undefined },
  { name: "Connections", icon: undefined },
  { name: "Segments", icon: undefined },
  { name: "IP Addresses", icon: undefined },
];

const Flows: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters, isServerTraffic } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;
  return (
    <TrafficLayout tabs={tabs}>
      {servicesStatus && !servicesStatus.sdr.overall ? (
        <ServicesRedirectPlaceholder
          type="sdr"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.sdr.adminSide}
        />
      ) : (
        <GrafanaContainer type={GRAFANA_TYPES.SDR} className={styles.wrapper}>
          <div className={styles.chartsWrapper}>
            {" "}
            <div className={styles.srcIps}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 22 : 21,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.dstIps}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 24 : 21,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.sankey}>
              <StatisticsChart />
            </div>
          </div>
        </GrafanaContainer>
      )}
    </TrafficLayout>
  );
};

export default Flows;

const StatisticsChart: FC = () => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { filters, isServerTraffic } = useTrafficContext();

  const [activeTab, setActiveTab] = useState<string>(TABS[0].name);
  const handleTabSelect = (tab: Tab) => {
    setActiveTab(tab.name);
  };

  const getBody = () => {
    switch (activeTab) {
      case "IP Addresses":
        return (
          <GrafanaGraph
            link={getTrafficLink(
              isServerTraffic ? 49 : 69,
              timeRange.general,
              selectedTenant,
              filters
            )}
          />
        );
      case "Segments":
        return (
          <GrafanaGraph
            link={getTrafficLink(
              isServerTraffic ? 91 : 92,
              timeRange.general,
              selectedTenant,
              filters
            )}
          />
        );
      case "Connections":
        return (
          <GrafanaGraph
            link={getTrafficLink(
              isServerTraffic ? 71 : 48,
              timeRange.general,
              selectedTenant,
              filters
            )}
          />
        );
      case "Locations":
      default:
        return (
          <GrafanaGraph
            link={getTrafficLink(
              isServerTraffic ? 47 : 46,
              timeRange.general,
              selectedTenant,
              filters
            )}
          />
        );
    }
  };
  return (
    <>
      <ChartsHeader
        title={""}
        controls={
          <ButtonsGroup
            tabs={TABS}
            activeTab={activeTab}
            onChange={handleTabSelect}
            withIcon
          />
        }
      />
      {getBody()}
    </>
  );
};
