import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./LabelWithCombinedValue.module.scss";

type LabelProps = {
  title?: string;
  combinedValues?: Array<string | number>;
  className?: string;
  isOutlined?: boolean;
  isSmall?: boolean;
};

const LabelWithCombinedValue: FC<LabelProps> = ({
  className,
  isOutlined,
  title,
  combinedValues,
  isSmall,
}) => {
  const labelStyles = classNames(
    styles.wrapper,
    isSmall && styles.small,
    isOutlined && styles.outlined,
    className
  );

  const renderCombinedValues = () => {
    if (!combinedValues) return "";
    return (
      <span className={styles.value}>
        {combinedValues[0]}
        {combinedValues[1] && ` / ${combinedValues[1]}`}
      </span>
    );
  };

  return (
    <div className={labelStyles}>
      {title && (
        <span className={styles.title}>
          {title}
          {combinedValues && ": "}
        </span>
      )}
      {renderCombinedValues()}
    </div>
  );
};

export default LabelWithCombinedValue;
