import React, { ReactElement } from "react";
import {
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
  useExpanded,
  Row,
  ColumnInstance,
} from "react-table";
import IndeterminateCheckbox from "../IndeterminateCheckbox";

import Table from "./Table";
import styles from "./TableGrouped.module.scss";

export type TableColumnHeadersProps<D extends { [key: string]: any }> = {
  grid?: React.CSSProperties;
  className?: string;
  columns: Array<ColumnInstance<D>>;
  row?: Row<D>;
  onCollapse?: () => void;
};

type Props<D extends { [key: string]: any }> = {
  data: { [key: string]: Array<D> };
  header?: React.FC<TableColumnHeadersProps<D>>;
  collapsed?: Array<string>;
  gridTemplateRows?: string;
  groupRowActions?: Array<string>;
  groupClassName?: string;
  commonActions?: boolean;
  extraTitleHeader?: string;
  hideEmpty?: Array<string>;
  [key: string]: any;
};

export const CHECKBOX_ID = "selection";

export default function TableGrouped<D extends { [key: string]: any }>(
  props: Props<D>
): ReactElement {
  const {
    data,
    header,
    gridTemplateRows,
    collapsed,
    groupRowActions,
    groupClassName,
    extraTitleHeader,
    hideEmpty,
    commonActions,
    ...restProps
  } = props;

  const getData = () => {
    const firstKey: string = Object.keys(data)[0];
    return { columns: props.columns, data: data[firstKey] };
  };

  const { headers } = useTable(
    { ...getData() },
    useRowState,
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      props.withCheckbox &&
        hooks.visibleColumns.push((columns) => [
          {
            id: CHECKBOX_ID,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }: { row: Row<D> }) => (
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ]);
    }
  );

  const gridStyle = {
    gridTemplateColumns:
      (props.withCheckbox ? "40px " : "") +
      (props.gridColumnTemplate || `repeat(${props.columns.length}, 1fr)`),
  };

  const Header = header;
  const groups = Object.keys(data);

  return (
    <>
      {Header && <Header columns={headers} grid={gridStyle} />}
      <div
        className={(styles.wrapper, groupClassName)}
        style={{ gridTemplateRows }}
      >
        {groups.map((el) => {
          const needToHide = hideEmpty?.includes(el) && data[el].length === 0;
          const noCollapseBtn = data[el].length === 0;
          const isCollapsed = collapsed?.includes(el);
          const isRowActions = groupRowActions?.includes(el) || commonActions;
          if (needToHide) {
            return;
          } else if (noCollapseBtn) {
            //if number of rows is 0 remove the collaplse button
            return (
              <Table
                withoutPagination
                {...(restProps as any)}
                data={data[el]}
                header={undefined}
                extraTitle={el}
                extraTitleHeader={extraTitleHeader}
                noCollapseBtn={noCollapseBtn}
                noScroll
                collapsedExtra={isCollapsed}
                rowActions={isRowActions ? restProps.rowActions : false}
              />
            );
          } else
            return (
              <Table
                withoutPagination
                {...(restProps as any)}
                data={data[el]}
                header={undefined}
                extraTitle={el}
                noScroll
                collapsedExtra={isCollapsed}
                extraTitleHeader={extraTitleHeader}
                rowActions={isRowActions ? restProps.rowActions : false}
              />
            );
        })}
      </div>
    </>
  );
}
