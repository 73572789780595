import React, { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import styles from "./PBMPage.module.scss";
import PBRTable from "./PBMTable";
import TenantInfo from "../../components/tenants/OneTenant/TenantInfo";
import { CollapsableTwoColumnLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { OneTenantTabs } from "../../components/common/navigation/OneTenantTabs";

const PBMPage: FC = () => {
  return (
    <CollapsableTwoColumnLayout InfoBlock={() => <TenantInfo />}>
      <div className={styles.container}>
        <OneTenantTabs />
        <PBRTable />
      </div>
    </CollapsableTwoColumnLayout>
  );
};
export default withLayout<any>(PBMPage);
