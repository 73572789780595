import { FC, useEffect, useMemo, useState } from "react";
import IconBlankButton from "../../../../../../../components/common/buttons/IconBlankButton";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import SearchIcon from "../../../../../../../components/icons/SearchIcon";
import { useGlobalFilterContext } from "../../../../../../../contexts/GlobalFilterContext";
import { AWSAccessKey } from "../../../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { withContexts } from "../../../../../../../helpers/hocs/withContexts";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import VPCContextContainer, {
  useVPCContext,
} from "../../../../../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/common/VPCList/VPCContext";
import { REGIONS } from "../../../../../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/regions";
import CloudVpcSelector from "../../../common/CloudVpcSelector";
import ConnectionSection from "../../../common/ConnectionSection";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";
import styles from "./CloudSection.module.scss";

const DEFAULT_FIELDS: AWSAccessKey = {
  access_key_id: "",
  secret_access_key: "",
  session_token: "",
  description: "",
};

type Props = {
  className?: string;
};
const CloudSection: FC<Props> = ({ className }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    list = [],
    fetchList,
    token,
    isToken,
    createAWSAccessKey,
    getAWSAccessKey,
    listStatus,
  } = useVPCContext();

  const {
    fields: externalFields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  const [showList, setShowList] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTenant) getAWSAccessKey(selectedTenant);
  }, [selectedTenant]);

  // useEffect(() => {
  //   if (token) {
  //     resetFields(token);
  //   }
  // }, [token]);

  const handleFetchDiscovery = () => {
    if (externalFields.region && isToken && selectedTenant) {
      setShowList(true);
      fetchList(externalFields.region, selectedTenant);
    }
  };

  const handleCheckBlur = async () => {
    if (
      externalFields.access_key_id &&
      externalFields.secret_access_key &&
      selectedTenant
    ) {
      await createAWSAccessKey(externalFields as AWSAccessKey, selectedTenant);
    }
  };

  const regionsList = useMemo(() => {
    return REGIONS[externalFields.cloudOperator] || [];
  }, [externalFields]);

  return (
    <ConnectionSection title={"Cloud"} className={className} columnsCount={1}>
      <Input
        label="Token"
        isPlaceholderAlwaysVisible
        name="access_key_id"
        placeholder="Access key ID"
        value={externalFields.access_key_id}
        onChange={(e) => onChange("access_key_id", e.target.value)}
        medium
        error={""}
        type={"password"}
        onBlur={handleCheckBlur}
      />
      <Input
        label=""
        isPlaceholderAlwaysVisible
        name="secret_access_key"
        placeholder="Certificate"
        medium
        error={""}
        value={externalFields.secret_access_key}
        onChange={(e) => onChange("secret_access_key", e.target.value)}
        type={"password"}
        onBlur={handleCheckBlur}
      />
      <div className={styles.row}>
        <DropdownBasic
          id={"region"}
          onChange={(val) => {
            onChange("region", val.key);
          }}
          selected={mapStringToItem(externalFields.region)}
          label="Region"
          itemsList={regionsList.map(mapStringToItem)}
          isMedium
          error={""}
        />
        <IconBlankButton
          className={styles.btn}
          id={"discoverBtn"}
          onClick={handleFetchDiscovery}
          icon={SearchIcon}
          label={"Discover"}
          isOutlined
        />
      </div>
      {showList && <CloudVpcSelector />}
    </ConnectionSection>
  );
};

export default withContexts<Props>(CloudSection, [VPCContextContainer]);
