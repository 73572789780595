import React, { FC } from "react";
import {
  PACKAGES,
  PROVISIONING,
  TENANTS,
  USERS,
  USER_GROUPS,
} from "../../../../helpers/navigation/entries";
import BasicTabs from "../BasicTabs/BasicTabs";

type Props = {
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  defaultTenants?: Array<any>;
  remoteUsersRoot?: Array<any>;
};

export const TenantsTab: FC<Props> = ({
  showTimeRange,
  noConfig,
  noReports,
  defaultTenants,
  remoteUsersRoot,
}) => {
  // remoteUsersRoot is a special indicator that it is remote users page
  const tabs = remoteUsersRoot
    ? [USERS(), USER_GROUPS()]
    : [PROVISIONING(), USERS(), USER_GROUPS(), PACKAGES()];
  return (
    <BasicTabs
      showTimeRange={showTimeRange}
      noConfig={noConfig}
      noReports={noReports}
      tabs={tabs}
      root={remoteUsersRoot || [TENANTS()]}
      defaultTenants={defaultTenants}
    />
  );
};
