import React, { FC, useEffect, useMemo } from "react";
import TableHeader from "../../../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../../../components/common/table/newTable/Table";
import { useSegmentsRulesContext } from "../../../SegmentsRulesContext";
import { RulesTableHeader } from "../TableColumn";
import styles from "./PredefinedRules.module.scss";

const PredefinedRules: FC = () => {
  const {
    predefinedRules,
    setPredefinedRules,
    selectedSegment,
    getPredefinedList,
  } = useSegmentsRulesContext();

  useEffect(() => {
    if (selectedSegment) {
      getPredefinedList(selectedSegment);
    }
  }, [selectedSegment]);

  const tableItems = useMemo(() => {
    const isDeny = selectedSegment?.x !== selectedSegment?.y;
    return isDeny ? [] : predefinedRules;
  }, [selectedSegment, predefinedRules]);

  const toggleRule = (ruleId: number) => {
    const newRules = predefinedRules?.map((rule) => {
      if (rule.id === ruleId) rule.enabled = !rule.enabled;
      return rule;
    });
    setPredefinedRules(newRules);
  };

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.title}>
        Default Allow Rules ({tableItems.length})
      </div>
      <div className={styles.rulesTable}>
        <Table
          data={tableItems}
          header={TableHeader}
          columns={RulesTableHeader(toggleRule)}
          gridColumnTemplate="80px 100px 1fr 80px"
        />
      </div>
    </div>
  );
};

export default PredefinedRules;
