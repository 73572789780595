import React, { FC, useEffect, useState } from "react";
import { DialogEntity, PageProps } from "../../../helpers/types";
import { LoopbackType, NatpoolType } from "../../../helpers/api/apiTypes";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./LogicalInterfacesPage.module.scss";
import { withLayout } from "../../../helpers/hocs/withLayout";
import LogicalInterfacesContextContainer, {
  useLogicalInterfacesContext,
} from "../../../contexts/systemsContext/LogicalInterfacesContext";
import Table from "../../common/table/Table";
import LoopbackDialog from "../../dialogs/LoopbackDialog";
import NatpoolDialog from "../../dialogs/NatpoolDialog";
import AddButton from "../../common/buttons/AddButton";
import { loopbackTableHeader } from "../../common/table/TableHeaders/Systems/loopbackTableHeader";
import { natpoolTableHeader } from "../../common/table/TableHeaders/Systems/natpoolTableHeader";
import getMinMaxCellSize from "../../../helpers/getMinMaxCellSize";
import { OneSystemTabs } from "../../common/navigation/OneSystemTabs";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import { withContexts } from "../../../helpers/hocs/withContexts";

const LogicalInterfacesPage: FC = () => {
  const {
    loopbackList,
    fetchLoopbackList,
    natpoolList,
    fetchNatpoolList,
  } = useLogicalInterfacesContext();
  const [logicalInterfacesDialog, setLogicalInterfacesDialog] = useState<
    DialogEntity | undefined
  >();
  const [dialogMode, setDialogMode] = useState<string>("add");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chosenLoopbackItem, setChosenLoopbackItem] = useState<
    LoopbackType | undefined
  >();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chosenNatpoolItem, setChosenNatpoolItem] = useState<
    NatpoolType | undefined
  >();

  useEffect(() => {
    fetchLoopbackList();
    fetchNatpoolList();
  }, []);

  const handleOpenDialog = (mode: string) => (entity?: DialogEntity): void => {
    setDialogMode(mode);
    setLogicalInterfacesDialog(entity);
  };

  return (
    <>
      {logicalInterfacesDialog === DialogEntity.systemLoopback && (
        <LoopbackDialog
          onClose={() => setLogicalInterfacesDialog(undefined)}
          type={dialogMode as "add" | "edit" | undefined}
          data={chosenLoopbackItem}
        />
      )}
      {logicalInterfacesDialog === DialogEntity.systemNatpool && (
        <NatpoolDialog
          onClose={() => setLogicalInterfacesDialog(undefined)}
          type={dialogMode as "add" | "edit" | undefined}
          data={chosenNatpoolItem}
        />
      )}
      <OneSystemLayout>
        <div className={styles.contentWrapper}>
          <OneSystemTabs />
          <div className={styles.tablesWrapper}>
            {loopbackList && (
              <Table
                columns={loopbackTableHeader}
                data={loopbackList}
                header="Loopback"
                headerControls={
                  <AddButton
                    onClick={() =>
                      handleOpenDialog("add")(DialogEntity.systemLoopback)
                    }
                  />
                }
                gridColumnTemplate={`140px 180px 120px 
                ${getMinMaxCellSize("1fr")} 
                ${getMinMaxCellSize("1.5fr")}`}
              />
            )}
            {natpoolList && (
              <Table
                columns={natpoolTableHeader}
                data={natpoolList}
                header="Natpool"
                headerControls={
                  <AddButton
                    onClick={() =>
                      handleOpenDialog("add")(DialogEntity.systemNatpool)
                    }
                  />
                }
                gridColumnTemplate={`140px 180px 120px
                ${getMinMaxCellSize("1fr")} 
                ${getMinMaxCellSize("1.5fr")}`}
              />
            )}
          </div>
        </div>
      </OneSystemLayout>
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(LogicalInterfacesPage, [LogicalInterfacesContextContainer])
);
