import { CellProps } from "react-table";
import { PortType } from "../types";
import PortIcon from "../../../components/icons/PortIcon";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";
import { convertPortSpeed } from "../helpers/convertPortSpeed";
import ArrowUp from "../../../components/icons/ArrowUp";
import ArrowDown from "../../../components/icons/ArrowDown";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";

export const interfacesTableColumns = (onSelect: any, selected?: string): any =>
  [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row: { original }, cell: { value } }: CellProps<PortType>) => {
        return (
          <TextWithIcon
            icon={
              <PortIcon
                color={getColorFromStatus(original.operational_state)}
              />
            }
            text={original.alias || value}
          />
        );
      },
    },
    {
      Header: "Speed",
      accessor: "speed",
      Cell: ({ cell: { value } }: CellProps<PortType>) => {
        return <>{convertPortSpeed(value)}</>;
      },
    },
    {
      Header: "MTU",
      accessor: "mtu",
      Cell: ({ row: { original } }: CellProps<PortType>) => {
        return <>{original.mtu}</>;
      },
    },
    {
      Header: "State",
      accessor: "administrative_state",
      Cell: ({ row: { original } }: CellProps<any>) => {
        const admin = original.administrative_state;
        const oper = original.operational_state;
        const allPorts = original.nof_ports;
        const upPorts = original.up_ports;
        const greyFont = {
          color: `var(--grey60)`,
        };
        let res = "";
        let rowIcon = <LoaderIcon />;
        let portsCount = " ";
        if (upPorts && allPorts) {
          portsCount = `${upPorts}/${allPorts}`;
        }
        if (admin === "up" && oper === "up") {
          res = "Up";
          rowIcon = <ArrowUp color={getColorFromStatus("up")} />;
        } else if (admin === "up" && oper === "down") {
          res = "Down";
          rowIcon = <ArrowDown color={getColorFromStatus("idle")} />;
        } else if (admin === "down" && oper === "down") {
          res = "Admin down";
          rowIcon = <ArrowDown color={getColorFromStatus("admin_down")} />;
        } else return `non specified`;
        return (
          <>
            <TextWithIcon icon={rowIcon} text={res} />
            <span style={greyFont}> {portsCount} </span>
          </>
        );
      },
    },
    {
      Header: "LAG",
      accessor: "lag",
      Cell: ({ row: { original } }: CellProps<any>) => {
        let lagVal = "-";
        original.lag_type
          ? (lagVal = original.lag_type)
          : original.lag_name
          ? (lagVal = original.lag_name)
          : (lagVal = "-");
        return <>{lagVal}</>;
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof PortType }));
