import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function OracleIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1418_50)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.46869 9.05025C5.83614 7.7375 7.6908 7 9.62467 7H18.3747C20.3085 7 22.1632 7.7375 23.5307 9.05025C24.8981 10.363 25.6663 12.1435 25.6663 14C25.6663 15.8565 24.8981 17.637 23.5307 18.9497C22.1632 20.2625 20.3085 21 18.3747 21H9.62467C7.6908 21 5.83614 20.2625 4.46869 18.9497C3.10123 17.637 2.33301 15.8565 2.33301 14C2.33301 12.1435 3.10123 10.363 4.46869 9.05025ZM9.62467 9.8C8.46435 9.8 7.35155 10.2425 6.53108 11.0302C5.71061 11.8178 5.24967 12.8861 5.24967 14C5.24967 15.1139 5.71061 16.1822 6.53108 16.9698C7.35155 17.7575 8.46435 18.2 9.62467 18.2H18.3747C19.535 18.2 20.6478 17.7575 21.4683 16.9698C22.2887 16.1822 22.7497 15.1139 22.7497 14C22.7497 12.8861 22.2887 11.8178 21.4683 11.0302C20.6478 10.2425 19.535 9.8 18.3747 9.8H9.62467Z"
          fill={color || GREY_FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_1418_50">
          <rect
            width="23.3333"
            height="14"
            fill="white"
            transform="translate(2.33301 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OracleIcon;
