import React, { FC } from "react";
import { QuotaType } from "../../../../helpers/api/apiTypes";
import { SetField } from "../../../../helpers/dialogs/FieldProps";
import { getBooleanIcon } from "../../../../helpers/getIconFromValue";
import toNumber from "../../../../helpers/toNumber";
import { Checkbox } from "../../../common/formComponents/Checkbox";
import Input from "../../../common/formComponents/Input";

type Props = {
  isEdit: boolean;
  currentVal: any;
  realValues?: { [key: string]: string | number } & QuotaType;
  fields: { [key: string]: string } & QuotaType;
  onChange: SetField;
  fieldKey: string;
};

const SectionCell: FC<Props> = ({
  isEdit,
  realValues,
  fields,
  onChange,
  fieldKey,
  currentVal,
}) => {
  const isDisabled = !fields || fields[fieldKey] === undefined;
  const realValue = (realValues as { [key: string]: string } & QuotaType)[
    fieldKey
  ];
  const value = realValue !== undefined ? realValue : currentVal;

  if (Array.isArray(value)) {
    return isEdit ? (
      <span>
        {value.map((val: string) => (
          <Input
            handleFieldChange={onChange}
            name={fieldKey}
            value={fields[fieldKey] || val}
            medium
            disabled={isDisabled}
            valuePreprocess={toNumber}
          />
        ))}
      </span>
    ) : (
      <span>{value.join(" & ")}</span>
    );
  }

  switch (typeof value) {
    case "boolean":
      return isEdit ? (
        <Checkbox isChecked={value} disabled={isDisabled} />
      ) : (
        getBooleanIcon(value)
      );

    default:
      return isEdit ? (
        <Input
          handleFieldChange={onChange}
          name={fieldKey}
          value={fields[fieldKey] || value}
          medium
          disabled={isDisabled}
          valuePreprocess={toNumber}
        />
      ) : (
        <span>{value}</span>
      );
  }
};
export default SectionCell;
