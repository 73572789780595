import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { commonTableColumns } from "../table/commonTableColumns";
import { groupTableStyles } from "../helpers/consts";
import { formPathFromArray } from "../../../helpers/navigation";
import { INSIGHTS, TRAFFIC } from "../../../helpers/navigation/entries";

const SourceIpsTable: FC = () => {
  const { data, statuses } = useSearchContext();

  return (
    <ResultTable
      title="Source Ips"
      data={data?.sourceIps}
      columns={commonTableColumns("Source IP")}
      status={statuses?.sourceIpsStatus}
      style={{
        ...groupTableStyles,
        borderLeft: "none",
      }}
      goto={formPathFromArray([INSIGHTS(), TRAFFIC()])}
    />
  );
};
export default SourceIpsTable;
