import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { PURPLE } from "../../helpers/common/colorAliases";

function LocationIconFilledColor({ color }: IconProps): ReactElement {
  return (
    <svg
      width="14"
      height="19"
      viewBox="0 0 14 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.99951 18.1246C6.99951 18.1246 13.3037 9.78591 13.3037 6.3042C13.3037 2.82249 10.4812 0 6.99951 0C3.5178 0 0.695312 2.82249 0.695312 6.3042C0.695312 9.78591 6.99951 18.1246 6.99951 18.1246ZM6.99644 8.66853C8.30208 8.66853 9.36051 7.6101 9.36051 6.30445C9.36051 4.99881 8.30208 3.94038 6.99644 3.94038C5.69079 3.94038 4.63236 4.99881 4.63236 6.30445C4.63236 7.6101 5.69079 8.66853 6.99644 8.66853Z"
        fill={color || PURPLE}
      />
    </svg>
  );
}

export default LocationIconFilledColor;
