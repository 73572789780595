import React, { FC, useEffect, useState } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import LocationIcon from "../../../../../../components/icons/LocationIcon";
import { GREY_DISABLED } from "../../../../../../helpers/common/colorAliases";
import { DropdownItem } from "../../../../../../helpers/types";
import { Endpoint } from "../../../types";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  DIA,
} from "../../consts";
import { ChangeField } from "../../types";
import { useConnectionsContext } from "../../../ConnectionContext";

type Props = {
  field: Endpoint;
  onChange: ChangeField;
  error?: string;
  type: string;
  cloudType?: string;
  label?: string;
  isNotEditable?: boolean;
};

const EndpointDropdown: FC<Props> = ({
  field,
  onChange,
  error,
  type,
  cloudType,
  label,
  isNotEditable,
}) => {
  const { endpoints = [] } = useConnectionsContext();
  const [list, setList] = useState<Array<DropdownItem>>([]);
  useEffect(() => {
    const list: Array<DropdownItem> = [];
    switch (type) {
      case CUSTOMER_LOCATIONS:
        list.push(...endpoints.filter((el) => el.data.type === "NID"));
        break;
      case DATA_CENTER_PORT:
        list.push(...endpoints.filter((el) => el.data.type === "DC_PORT"));
        break;
      case DIA:
        list.push(...endpoints.filter((el) => el.data.type === "DIA"));
        break;
      case CLOUD_CONNECTIONS:
        list.push(
          ...endpoints.filter((el) => el.data.type === cloudType?.toUpperCase())
        );
        break;
    }
    setList([...list]);
  }, [endpoints, cloudType]);

  return (
    <DropdownBasic
      id={"location"}
      onChange={(item) => {
        item.data && onChange("location", item.data);
      }}
      placeholder={"Select"}
      selected={list.find((item) => item.key === field?.location_name)}
      label={label || "Location"}
      itemsList={list}
      isMedium
      error={error}
      icon={<LocationIcon color={GREY_DISABLED} />}
      isNotEditable={isNotEditable}
    />
  );
};
export default EndpointDropdown;
