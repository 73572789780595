import { FC, useEffect } from "react";
import styles from "./RouteFiltersConfig.module.scss";
import RouteMapInfo from "./components/RouteMapInfo";
import RouteMapFormContainer from "./components/RouteMapFormContainer";
import RouteMapTips from "./components/RouteMapTips";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { UserRouteFiltersAdd } from "../../types";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { useHistory, useParams } from "react-router-dom";
import { useRouteFiltersContext } from "../../RouteFiltersContext";

const RouteFiltersConfigEdit: FC = () => {
  const history = useHistory();
  const { route } = useParams<{ route?: string }>();
  const {
    routeFilter,
    getRouteFilter,
    routeFilterStatus,
    edit,
    editStatus,
  } = useRouteFiltersContext();
  const [fields, handleFieldChange, resetFields] = useFormField<
    UserRouteFiltersAdd | undefined
  >(undefined);

  useEffect(() => {
    const tenant = localStorage.getItem("selectedTenant");
    if (route && tenant) {
      getRouteFilter(tenant, route);
    }
  }, [route]);

  useEffect(() => {
    if (routeFilter) {
      resetFields(routeFilter);
    }
  }, [routeFilter]);

  const onClose = () => {
    history.goBack();
  };

  const handleEdit = async () => {
    onClose();
    // const tenant = localStorage.getItem("selectedTenant");
    // if (!tenant || !fields) return;
    // const res = await edit(tenant, fields);
    // if (res) onClose();
  };

  if (!fields || routeFilterStatus?.state === "pending") return <PageLoader />;

  return (
    <div className={styles.wrapper}>
      <RouteMapInfo className={styles.basicBlock} name={fields.name} />
      <RouteMapFormContainer
        className={styles.basicBlock}
        onClose={onClose}
        isEdit
        fields={fields}
        onChange={handleFieldChange}
        action={handleEdit}
        status={editStatus}
      />
      <RouteMapTips className={styles.basicBlock} />
    </div>
  );
};

export default withLayout(RouteFiltersConfigEdit);
