import { FC } from "react";
import Section from "../../../../../components/common/Section";
import Input from "../../../../../components/common/formComponents/Input";
import {
  ChangeField,
  FieldsType,
} from "../../../../ZayoPages/Connections/ConnectionConfigurator/types";

type Props = {
  isEdit?: boolean;
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
};

const GeneralSection: FC<Props> = ({
  className,
  fields,
  onChange,
  isEdit,
  errors,
}) => {
  return (
    <Section title={"General"} className={className} columnsCount={2}>
      <Input
        name={"remote_router_ip"}
        label="IP"
        value={fields.remote_router_ip}
        onChange={(e) => {
          onChange("remote_router_ip", e.target.value);
        }}
        medium
        isNotEditable={isEdit}
        error={errors?.["remote_router_ip"]}
      />
      <Input
        label="Remote AS"
        name={"remote_asn"}
        value={fields.remote_asn}
        onChange={(e) => {
          onChange("remote_asn", e.target.value);
        }}
        medium
        isNotEditable={isEdit}
        error={errors?.["remote_asn"]}
      />
      <Input
        label="Description"
        name={"description"}
        value={fields.description}
        onChange={(e) => {
          onChange("description", e.target.value);
        }}
        medium
        isOptional
        error={""}
      />
      <Input
        label="Password"
        name={"password"}
        value={fields.password}
        onChange={(e) => {
          onChange("password", e.target.value);
        }}
        medium
        isNotEditable={isEdit}
        isOptional
        error={""}
      />
    </Section>
  );
};
export default GeneralSection;
