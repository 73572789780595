import React, { FC, useEffect } from "react";
import TableWrapper from "../../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../components/common/table/newTable/Table";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../contexts/UserContext";
import { useHistoryLogPageContext } from "../context/HistoryLogPageContext";
import { HistoryLogCell } from "../domain";

import styles from "./HistoryLogTable.module.scss";
import TableColumns from "./TableColumns";
import TableTitle from "./TableTitle";
import { useTimerangeContext } from "../../../contexts/TimerangeContext";

const GRID_TEMPLATE = "1fr 100px 2fr repeat(2, 1fr) repeat(3, 0.8fr) 40px";

type Props = {
  onDialogOpen: () => void;
};

const HistoryLogTable: FC<Props> = ({ onDialogOpen }) => {
  const { timeRange } = useTimerangeContext();
  const { isTenant, user } = useUserContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchEvents,
    fetchConfiguration,
    selectEvent,
    events,
    eventsRequestStatus,
  } = useHistoryLogPageContext();

  useEffect(() => {
    const tenantName = isTenant ? user.name : selectedTenant;

    if (tenantName) {
      fetchEvents(!isTenant, tenantName, timeRange.historyLog);
    }
  }, [selectedTenant, isTenant, timeRange]);

  const selectHistoryLogCell = (event: HistoryLogCell) => {
    fetchConfiguration(event);
    selectEvent(event);
    onDialogOpen();
  };

  return (
    <div className={styles.table}>
      <TableWrapper
        isEmpty={events.length === 0}
        dataStatus={eventsRequestStatus}
        titleProps={{ title: <TableTitle /> }}
      >
        <Table
          data={events}
          header={TableHeader}
          columns={TableColumns({
            onRowExpand: selectHistoryLogCell,
            showLogsButton: !isTenant,
          })}
          gridColumnTemplate={GRID_TEMPLATE}
          rowClassName={styles.row}
        />
      </TableWrapper>
    </div>
  );
};

export default HistoryLogTable;
