import SystemInfo, {
  HAHealthInfo,
} from "../systems/oneSystem/SystemInfo/SystemInfo";
import { FC, useState } from "react";
import LayoutWithTwoColumns from "./LayoutWithTwoColumns";
import { useOneSystemContext } from "../../contexts/systemsContext/OneSystemContext";

export const OneSystemLayout: FC<{}> = ({ children }) => {
  const { isInfoCollapsed, setIsInfoCollapsed } = useOneSystemContext();

  return (
    <LayoutWithTwoColumns
      id={"OneSystemLayout"}
      InfoBlock={SystemInfo}
      isInfoCollapsed={isInfoCollapsed}
      setIsInfoCollapsed={setIsInfoCollapsed}
    >
      {children}
    </LayoutWithTwoColumns>
  );
};

export const HAHealthLayout: FC<{}> = ({ children }) => {
  const { isInfoCollapsed, setIsInfoCollapsed } = useOneSystemContext();

  return (
    <LayoutWithTwoColumns
      id={"HAHealthLayout"}
      InfoBlock={HAHealthInfo}
      isInfoCollapsed={isInfoCollapsed}
      setIsInfoCollapsed={setIsInfoCollapsed}
    >
      {children}
    </LayoutWithTwoColumns>
  );
};

export const CollapsableTwoColumnLayout: FC<{ InfoBlock: FC<{}> }> = ({
  children,
  InfoBlock,
}) => {
  const [isInfoCollapsed, setIsInfoCollapsed] = useState(false);
  return (
    <LayoutWithTwoColumns
      id={"CollapsableTwoColumnLayout"}
      InfoBlock={InfoBlock}
      isInfoCollapsed={isInfoCollapsed}
      setIsInfoCollapsed={setIsInfoCollapsed}
    >
      {children}
    </LayoutWithTwoColumns>
  );
};
