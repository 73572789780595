import React, { ReactElement } from "react";

function AddDeviceIcon(): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.7"
        y="0.7"
        width="18.6"
        height="18.6"
        rx="5.3"
        stroke="#6B55F0"
        strokeWidth="1.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8382 4.96481H9.15991V9.1607H4.96445L4.96445 10.839H9.15991V15.0347H10.8382V10.839H15.0344V9.1607H10.8382V4.96481Z"
        fill="#6B55F0"
      />
    </svg>
  );
}

export default AddDeviceIcon;
