import { FC } from "react";
import styles from "./EmptyListPlaceholder.module.scss";

type Props = { className: string };

const EmptyListPlaceholder: FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <div className={styles.title}>Seacrh for</div>
      <ul className={styles.listContainer}>
        <li className={styles.listItem}>
          navigation term (<span className={styles.example}>nat</span>)
        </li>
        <li className={styles.listItem}>
          IP (<span className={styles.example}>10.1.1.1</span>)
        </li>
        <li className={styles.listItem}>
          hostname (<span className={styles.example}>www.insidepacket.com</span>
          )
        </li>
      </ul>
    </div>
  );
};

export default EmptyListPlaceholder;
