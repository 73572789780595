import React, { FC, useEffect, useState } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import Input from "../../../components/common/formComponents/Input";
import { NewDialogProps } from "../../../helpers/types";
import { PortType } from "../types";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import PortDialogContextContainer, {
  usePortDialogContext,
} from "./PortDialogContext";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./PortDialog.module.scss";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import LabelArray from "../../../components/common/table/LabelArray";
import PortIcon from "../../../components/icons/PortIcon";
import InterfacesContextContainer, {
  useInterfacesContext,
} from "./InterfacesContext";
import { cloneDeep } from "lodash";
import { usePortsContext } from "../PortsContext";

const DEFAULT_FIELDS: Partial<any> = {
  name: "",
  min_ports: "all",
  lag_name: "LACP",
  ports: [],
  operational_state: "Up",
  mtu: 0,
};

type Props = {
  onClose: () => void;
};

const AddLagDialog: FC<Props> = ({ onClose }) => {
  const { editStatus } = usePortDialogContext();
  const { systemName } = usePortsContext();
  const {
    fetchLagList,
    free_ports_list,
    addLAG,
    addPortToLAG,
  } = useInterfacesContext();

  const [visiblePorts, setVisiblePorts] = useState<Array<string>>(
    free_ports_list || []
  );

  const handleHideUsedPort = (port: string) => {
    const ports = visiblePorts.filter((vPort) => vPort !== port);
    setVisiblePorts(ports);
  };

  useEffect(() => {
    if (systemName) fetchLagList(systemName);
  }, []);

  useEffect(() => {
    if (free_ports_list?.length) setVisiblePorts(free_ports_list);
  }, [free_ports_list]);

  const [fields, handleFieldChange] = useFormField<any>(
    cloneDeep(DEFAULT_FIELDS)
  );

  const handleAdd = async () => {
    const bodyToCreate = {
      min_ports: fields.ports.length,
      lag_type: fields.lag_name.toLowerCase(),
    };
    const portsToAssign = fields.ports;
    if (systemName && bodyToCreate) {
      addLAG(systemName, bodyToCreate);
    }
    if (systemName && portsToAssign) {
      addPortToLAG(systemName, portsToAssign);
    }
    handleClose();
  };

  const handleUpdate = async () => {
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  const removePort = (label: string) => {
    const prevPortsList = fields.ports;
    const newPortsArray = prevPortsList.filter(
      (port: string) => port !== label
    );
    handleFieldChange("ports", newPortsArray);
    setVisiblePorts(visiblePorts.concat(label));
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleAdd,
    onEdit: handleUpdate,
    onClose: onClose,
  });

  const leftColumn = <SummaryBlock title={"LAG"} data={fields} />;

  return (
    <DialogTemplate
      title={"Add LAG"}
      onClose={onClose}
      controls={controls}
      errorDisplay={editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <Input
          label="Alias Name"
          name="name"
          placeholder=""
          value={fields.name}
          medium
          onChange={(e) => handleFieldChange("name", e.target.value)}
        />
        <DropdownBasic
          id={"type"}
          onChange={(val) =>
            handleFieldChange("lag_name", val.key.toLowerCase())
          }
          selected={mapStringToItem(fields.lag_name)}
          label="Type"
          itemsList={["LACP", "STATIC"].map((key) => mapStringToItem(key))}
          isMedium
        />
        <DropdownBasic
          id={"lag_ports"}
          placeholder="Select Port to assign"
          onChange={(val) => {
            handleFieldChange("ports", [...fields.ports, val.key]);
            handleHideUsedPort(val.key);
          }}
          itemsList={visiblePorts?.map(mapStringToItem) || []}
          isMedium
        />
        {fields.ports && (
          <LabelArray
            values={fields.ports}
            isRemovable
            onClick={removePort}
            labelIcon={<PortIcon />}
            withWrap
            singleLabelClassname={styles.singleLabelClassname}
          />
        )}
      </div>
      <div className={styles.column}>
        <DropdownBasic
          id={"type"}
          onChange={(val) => handleFieldChange("operational_state", val.key)}
          selected={mapStringToItem(fields.operational_state)}
          label="Admin State"
          itemsList={["Up", "Down"].map((key) => mapStringToItem(key))}
          isMedium
        />
        <Input
          label="MTU"
          name="mtu"
          placeholder=""
          value={fields.mtu}
          medium
          onChange={(e) => handleFieldChange("mtu", e.target.value)}
        />

        <Input
          label="Minimum ports"
          name="min_ports"
          placeholder=""
          value={fields.min_ports}
          medium
          onChange={(e) => handleFieldChange("min_ports", e.target.value)}
        />
      </div>
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<PortType>>(AddLagDialog, [
  PortDialogContextContainer,
  InterfacesContextContainer,
]);
