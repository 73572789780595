import { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import SegmentsRulesContent from "./SegmentsRulesContent";
import { SEGMENTS, SEGMENTS_RULES } from "../../helpers/navigation/entries";

const SegmentsRulesPage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <>
      <Tabs noReports tabs={[SEGMENTS_RULES(), SEGMENTS()]} />
      <SegmentsRulesContent />
    </>
  );
};
export default withLayout<PageProps>(SegmentsRulesPage);
