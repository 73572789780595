import React from "react";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { IconProps } from "../../../helpers/types";

function BareMetalIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 34"
      {...formIconAttrs(34, 34, props)}
    >
      <rect
        width="20.072"
        height="4.875"
        x="6.965"
        y="6.964"
        stroke="#59536B"
        strokeWidth="1.4"
        rx="2"
      ></rect>
      <rect
        width="1.792"
        height="1.792"
        x="8.934"
        y="8.487"
        fill="#59536B"
        rx="0.896"
      ></rect>
      <rect
        width="1.792"
        height="1.792"
        x="8.934"
        y="16.283"
        fill="#59536B"
        rx="0.896"
      ></rect>
      <rect
        width="1.792"
        height="1.792"
        x="8.934"
        y="23.989"
        fill="#59536B"
        rx="0.896"
      ></rect>
      <rect
        width="20.072"
        height="4.875"
        x="6.965"
        y="14.706"
        stroke="#59536B"
        strokeWidth="1.4"
        rx="2"
      ></rect>
      <rect
        width="20.072"
        height="4.875"
        x="6.965"
        y="22.447"
        stroke="#59536B"
        strokeWidth="1.4"
        rx="2"
      ></rect>
      <path
        stroke="#59536B"
        strokeWidth="1.4"
        d="M6.965 9.258v0a2.867 2.867 0 00-2.867 2.867v2.294a2.867 2.867 0 002.867 2.867v0M27.035 17v0a2.867 2.867 0 012.867 2.867v2.294a2.867 2.867 0 01-2.867 2.868v0"
      ></path>
      <rect
        width="0.896"
        height="2.581"
        x="23.02"
        y="8.11"
        fill="#59536B"
        rx="0.448"
      ></rect>
      <rect
        width="0.896"
        height="2.581"
        x="24.742"
        y="8.11"
        fill="#59536B"
        rx="0.448"
      ></rect>
      <rect
        width="0.896"
        height="2.581"
        x="23.02"
        y="15.852"
        fill="#59536B"
        rx="0.448"
      ></rect>
      <rect
        width="0.896"
        height="2.581"
        x="24.742"
        y="15.852"
        fill="#59536B"
        rx="0.448"
      ></rect>
      <rect
        width="0.896"
        height="2.581"
        x="23.02"
        y="23.594"
        fill="#59536B"
        rx="0.448"
      ></rect>
      <rect
        width="0.896"
        height="2.581"
        x="24.742"
        y="23.594"
        fill="#59536B"
        rx="0.448"
      ></rect>
    </svg>
  );
}

export default BareMetalIcon;
