import CustomListItem from "../components/CellDropdown/CustomListItem";
import { SegmentRule } from "../components/SegmentRulesTable/types";

export const NETWORK_APP_SEPARATOR = " - ";

export const PROTOCOLS = ["Any", "TCP", "UDP", "ICMP"];

export const NETWORK_APPLICATIONS = [
  `SSH${NETWORK_APP_SEPARATOR}22`,
  `SMTP1${NETWORK_APP_SEPARATOR}25`,
  `SMTP2${NETWORK_APP_SEPARATOR}587`,
  `SMTPS${NETWORK_APP_SEPARATOR}465`,
  `DNS${NETWORK_APP_SEPARATOR}53`,
  `HTTP${NETWORK_APP_SEPARATOR}80`,
  `HTTPS${NETWORK_APP_SEPARATOR}443`,
  `HTTP3${NETWORK_APP_SEPARATOR}443`,
  `POP3${NETWORK_APP_SEPARATOR}110`,
  `POP3S${NETWORK_APP_SEPARATOR}995`,
  `IMAPS${NETWORK_APP_SEPARATOR}993`,
  `LDAP${NETWORK_APP_SEPARATOR}389`,
  `LDAPS1${NETWORK_APP_SEPARATOR}636`,
  `LDAPS2${NETWORK_APP_SEPARATOR}3269`,
];

export const ALLOW = "allow";
export const DENY = "deny";
export const CUSTOM = "custom";

export const RULE_BODY = {
  description: "",
  filters: [],
  forwarding_policy: "ACCEPT",
  name: "",
  priority: 0,
};

export const predefinedRulesList: Array<SegmentRule> = [
  {
    id: 0,
    enabled: false,
    network_app: `SSH${NETWORK_APP_SEPARATOR}22`,
    ip_protocol: PROTOCOLS[0],
    description: "Secure Shell",
    priority: 101,
    srcSegment: "",
    dstSegment: "",
  },
  {
    id: 1,
    enabled: false,
    network_app: `HTTPS${NETWORK_APP_SEPARATOR}443`,
    ip_protocol: PROTOCOLS[0],
    description: "Secure HTTP",
    priority: 102,
    srcSegment: "",
    dstSegment: "",
  },
  {
    id: 2,
    enabled: false,
    network_app: `DNS${NETWORK_APP_SEPARATOR}53`,
    ip_protocol: PROTOCOLS[0],
    description: "Domain Name System",
    priority: 103,
    srcSegment: "",
    dstSegment: "",
  },
  {
    id: 3,
    enabled: false,
    network_app: `SMTP1${NETWORK_APP_SEPARATOR}25`,
    ip_protocol: PROTOCOLS[0],
    description: "Simple Mail Transfer",
    priority: 104,
    srcSegment: "",
    dstSegment: "",
  },
];

export const CUSTOM_RULE_BODY: SegmentRule = {
  id: 0,
  enabled: true,
  network_app: NETWORK_APPLICATIONS[0],
  ip_protocol: PROTOCOLS[0],
  description: "",
  name: "",
  priority: 200,
  srcSegment: "",
  dstSegment: "",
};

export const CELL_STATUS_MAP = [
  {
    id: "allow",
    content: (
      <CustomListItem
        title="Allow"
        tip={"Add a default Allow-all for this segments"}
      />
    ),
  },
  {
    id: "custom",
    content: (
      <CustomListItem title="Custom" tip={"Add rules for this segments"} />
    ),
  },
  {
    id: "deny",
    content: (
      <CustomListItem
        title="Deny"
        tip={"Delete any rule for this segments"}
        isDeny
      />
    ),
  },
];
