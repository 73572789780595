import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LogsIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0a1 1 0 0 0-1 1v18c0 .6.4 1 1 1h18c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1H1Zm3.9 8.9c-.3 0-.5.2-.5.5v1.2c0 .3.2.5.5.5H15c.3 0 .5-.2.5-.5V9.4c0-.3-.2-.5-.5-.5H5Zm-.5-5.2c0-.2.2-.5.5-.5H15c.3 0 .5.3.5.5V5c0 .3-.2.5-.5.5H5a.5.5 0 0 1-.5-.5V3.7Zm.5 10.8c-.3 0-.5.2-.5.5v1.3c0 .2.2.4.5.4H15c.3 0 .5-.2.5-.4V15c0-.3-.2-.5-.5-.5H5Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default LogsIcon;
