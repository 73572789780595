import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { configApi } from "../../helpers/api/ConfigApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { ARPItem } from "./types";

type RequestStatuses = {
  listStatus?: RequestStatus;
};
type State = {
  list: Array<ARPItem>;
  count: number;
  totalCount: number;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (tenant: string, offset: number, limit: number) => Promise<void>;
};

const [ARPsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useARPsContext = useContext;

export default class ARPsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = ARPsContext;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = { list: [], count: 0, totalCount: 0 };
  }

  fetchList = async (
    tenant: string,
    offset: number,
    limit: number
  ): Promise<void> => {
    this.setState({ listStatus: setPending() });
    const res = await configApi.getServiceARPList(tenant, offset, limit);

    if (res.ok && res.result) {
      this.setState({
        list: res.result,
        count: res.count || 0,
        totalCount: res.total_count || 0,
      });
    }
    this.setState({ listStatus: resToState(res) });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
