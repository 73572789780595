import React, { FC } from "react";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  DIA,
} from "../../../consts";
import { FieldsType } from "../../../types";
import CloudItem from "./CloudItem";
import CustomerLocationItem from "./CustomerLocationItem";
import DataCenterPortItem from "./DataCenterPortItem";
import DIAItem from "./DIAItem";

type Props = {
  type: string;
  fields: FieldsType;
  onChange: (field: string, value: any) => void;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const FormItemRouter: FC<Props> = (props) => {
  switch (props.type) {
    case CUSTOMER_LOCATIONS:
      return <CustomerLocationItem {...props} />;
    case DATA_CENTER_PORT:
      return <DataCenterPortItem {...props} />;
    case CLOUD_CONNECTIONS:
      return <CloudItem {...props} />;
    case DIA:
      return <DIAItem {...props} />;
    default:
      return null;
  }
};
export default FormItemRouter;
