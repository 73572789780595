import { tenantApi } from "./api/TenantApi";

export const fetchAndAppendVIIp = async (
  tenant: string,
  arr: Array<any>
): Promise<Array<any & { outside_ip?: string }>> => {
  const copy: Array<any & { outside_ip?: string }> = [...arr];
  const { ok, result } = await tenantApi.getVirtualInterfacesAllTypes(tenant);
  await Promise.all(
    arr.map(
      async (el, i): Promise<any> => {
        copy[i] = {
          ...copy[i],
          outside_ip: ok
            ? result?.find((vi) => vi.name === el.outside)?.ip_addresses
            : undefined,
        };
      }
    )
  );
  return copy;
};
