import React, { FC } from "react";
import ButtonsGroup from "../../../../components/common/buttons/ButtonsGroup";
import { useTrafficContext } from "../../TrafficContext";

const TABS = [{ name: "Server Traffic" }, { name: "Client Traffic" }];

const TrafficTypeSelector: FC = () => {
  const { trafficType, setTrafficType } = useTrafficContext();
  return (
    <ButtonsGroup
      tabs={TABS}
      activeTab={trafficType}
      onChange={(val) => setTrafficType(val.name)}
      withIcon
    />
  );
};

export default TrafficTypeSelector;
