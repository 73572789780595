import React, { FC } from "react";
import ConnectionName from "./ConnectionName";
import { ConnectionPortDetails } from "../../../types";
import { getPortTypeIcon } from "../../../helpers/getPortTypeIcon";
import { GREY_FONT } from "../../../../../../helpers/common/colorAliases";
import VLANLabel from "./Labels/VLANLabel";
import { calculateCoordinatesWithPredefinedAngle } from "../../helpers/calculateCoordinates";
import BGPLabelsGroup from "./Labels/BGPLabelsGroup";
import PortLabel from "./Labels/PortLabel";

type Props = {
  x: number;
  y: number;
  connection: ConnectionPortDetails;
  angleRad?: number;
  isShift?: boolean;
};
const ConnectionItem: FC<Props> = ({ x, y, connection, angleRad, isShift }) => {
  const radius = 25;
  const svgSize = radius * 2;

  const iconSize = 32;
  const Icon = getPortTypeIcon(connection.endpoint_type);
  const iconCenter = iconSize / 2;

  const isShit =
    isShift ||
    !!(angleRad && angleRad >= Math.PI / 2 && angleRad <= (3 * Math.PI) / 2);

  const configCoords = calculateCoordinatesWithPredefinedAngle({
    angle: isShit ? Math.PI : 2 * Math.PI,
    radius,
    width: svgSize,
    height: svgSize,
  });

  const bgpCoords = calculateCoordinatesWithPredefinedAngle({
    angle: (3 * Math.PI) / 2,
    radius,
    width: svgSize,
    height: svgSize,
  });

  return (
    <svg
      x={x - radius}
      y={y - radius}
      width={svgSize}
      height={svgSize}
      style={{ overflow: "visible" }}
    >
      <circle cx={radius} cy={radius} r={radius} fill="#fff" stroke="#BEB8EA" />
      <Icon
        x={radius - iconCenter}
        y={radius - iconCenter}
        width={iconSize}
        height={iconSize}
        color={GREY_FONT}
      />
      <ConnectionName
        x={radius}
        y={radius}
        name={connection.config?.endpoint_name}
        r={radius}
      />
      {connection.config.Phisical_Port && (
        <PortLabel
          isShift={isShit}
          x={configCoords.x}
          y={configCoords.y - 10}
          port={connection.config.Phisical_Port || ""}
        />
      )}
      {connection.config.VLAN_ID && (
        <VLANLabel
          isShift={isShit}
          x={configCoords.x}
          y={configCoords.y + 10}
          vlanId={connection.config.VLAN_ID || ""}
        />
      )}
      {connection.hasOwnProperty("bgpNeighbors") && (
        <BGPLabelsGroup
          x={bgpCoords.x}
          y={bgpCoords.y}
          bgpItems={connection.bgpNeighbors}
          isShift={!isShit}
        />
      )}
    </svg>
  );
};
export default ConnectionItem;
