/* eslint-disable css-modules/no-unused-class */
import React, { FC, useEffect, useMemo, useState } from "react";
import { withWizardToolLayout } from "../../../helpers/hocs/withWizardToolLayout";
import { PageProps } from "../../../helpers/types";
import StepCounter from "../common/StepCounter";
import { Menu } from "./components/Menu";
import { WidgetLayout } from "./components/WidgetLayout";
import styles from "./styles.module.scss";
import { Service } from "./types";
import { DdosService } from "./components/Services/DdosService";
import { NatService } from "./components/Services/NatService";
import { LoadBalancerService } from "./components/Services/LoadBalancerService";
import { MenuToggleItem } from "./components/Menu/MenuToggleItem";
import { MarketPlaceService } from "./components/Services/MarketPlaceService";
import { BaseMenuItem } from "../types";
import { IS_DEMO } from "../../../globalFlags";
import { useServicesContext } from "./ServicesContext";
import SegmentsRulesContent from "../../SegmentsRulesPage/SegmentsRulesContent";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useServicesPreferencesContext } from "../../ServicesPreferencesPage/ServicesPreferencesContext";
import { PageLoader } from "../../../components/common/loadStates/LoaderIcon";
import ContentUserGroup from "../../UserGroupsPage/components/UserGroupsConfig/components/ContentUserGroups";

const SERVICES_NAV_MENU_DEMO: Array<BaseMenuItem> = [
  { title: "Segmentation", id: Service.SEGMENTATION, active: true },
  { title: "Load Balancer", id: Service.LOAD_BALANCER },
  { title: "1:1 NAT", id: Service.NAT },
  { title: "DDOS", id: Service.DDOS },
  { title: "Market Place", id: Service.MARKET },
];

const SERVICES_NAV_MENU: Array<BaseMenuItem> = [
  { title: "Segmentation", id: Service.SEGMENTATION, active: true },
  { title: "User Groups", id: Service.USERSGROUP, active: false },
];

export const SERVICE_SETTINGS = [
  { title: "Enable Cyber Threats", id: Service.IDS },
  { title: "Enable Application Observability", id: Service.DPI },
];

const ServicesStep: FC = () => {
  const [menuList, setMenuList] = useState(
    IS_DEMO ? SERVICES_NAV_MENU_DEMO : SERVICES_NAV_MENU
  );
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchServicesData } = useServicesContext();
  const {
    fetchServices,
    groupedList,
    editService,
    fetchStatus,
  } = useServicesPreferencesContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesData(selectedTenant);
      fetchServices(selectedTenant);
    }
  }, [selectedTenant]);

  const selectMenuItem = (id: string) => {
    setMenuList(
      menuList.map((menu) => ({
        ...menu,
        active: id === menu.id,
      }))
    );
  };
  const selectedServiceLocal = useMemo(() => {
    const item = menuList.find(({ active }) => active);

    switch (item?.id) {
      case Service.LOAD_BALANCER:
        return <LoadBalancerService />;
      case Service.NAT:
        return <NatService />;
      case Service.DDOS:
        return <DdosService />;
      case Service.MARKET:
        return <MarketPlaceService />;
      case Service.USERSGROUP:
        return <ContentUserGroup />;
      case Service.SEGMENTATION:
      default:
        return <SegmentsRulesContent />;
    }
  }, [menuList]);
  return (
    <div className={styles.layout}>
      <div className={styles.wrapper}>
        <StepCounter />
        <div className={styles.stepContent}>
          <WidgetLayout className={styles.menu}>
            <div className={styles.settings}>
              {fetchStatus?.state === "pending" || !selectedTenant ? (
                <PageLoader />
              ) : (
                <>
                  {Object.keys(groupedList).map((oneGroup) => (
                    <>
                      <div className={styles.title}>{oneGroup}</div>
                      <div className={styles.wrapper}>
                        <div className={styles.group}>
                          {groupedList[oneGroup].map((elem) => {
                            if (elem.type === "ids") return null;
                            return (
                              <MenuToggleItem
                                title={elem.title}
                                id={elem.type}
                                checked={elem.enable}
                                onClick={() =>
                                  editService(
                                    { ...elem, enable: !elem.enable },
                                    selectedTenant
                                  )
                                }
                                className={styles.settingItem}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </>
                  ))}

                  <Menu list={menuList} onClick={selectMenuItem} />
                </>
              )}
            </div>
          </WidgetLayout>
          <WidgetLayout>{selectedServiceLocal}</WidgetLayout>
        </div>
      </div>
    </div>
  );
};

export default withWizardToolLayout<PageProps>(ServicesStep);
