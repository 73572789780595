import React, { FC, useEffect, useMemo, useState } from "react";
import Input from "../../../../../../../components/common/formComponents/Input";
import { Toggle } from "../../../../../../../components/common/Toggle";
import { formFieldProps } from "../../../../../../../helpers/dialogs/FieldProps";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import FormFooter from "../../common/FormFooter/FormFooter";
import RadioGroup from "../../../../../../../components/common/formComponents/RadioGroup";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import { InternetFields } from "../../utils/formConsts";
import FormTitle from "../../common/FormTitle/FormTitle";
import { IS_DEMO } from "../../../../../../../globalFlags";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import validateInternet from "../../../../../../../helpers/validators/InternetValidator";
import { useUserContext } from "../../../../../../../contexts/UserContext";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./InternetForm.module.scss";

const CONNECTION_TYPES = [
  { value: "workloadsToInternet", label: "Internet Access" },
  {
    value: "internetToWorkloads",
    label: "Secure internet to workloads",
    onlyAdmin: true,
  },
];

const RULE_TYPES = [
  { value: "loadBalancer", label: "Load Balancer" },
  { value: "nat", label: "NAT", disabled: true },
];

const DEFAULT_FIELDS: any = {
  interface_name: "",
  location: "",
  cluster_name: "",
  healthcheck_type: "tcp",
  healthcheck_http_expected_statuses: [],
  healthcheck_http_path: "",
  endpoints: [],
  listener_port: 0,
};

type Props = {
  connectionType: string;
  setConnectionType: (val: string) => void;
  activeRule: string;
  setactiveRule: (val: string) => void;
};

const InternetToWorkloadsNAT: FC<Props> = ({
  connectionType,
  setConnectionType,
  activeRule,
  setactiveRule,
}) => {
  const { user } = useUserContext();
  const { activeConnectionType, addInternetLB } = useConnectionsContext();

  const [ddosProtection, setDdosProtection] = useState<boolean>(false);
  const [waf, setWAF] = useState<boolean>(false);

  const [fields, handleFieldChange, resetFields] = useFormField<any>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<any>(validateInternet, [
    fields as InternetFields,
  ]);

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields, undefined]
  );

  useEffect(() => {
    return () => {
      resetFields(DEFAULT_FIELDS);
    };
  }, []);

  const handleAddConnection = () => {
    const { isOk } = validate();
    if (isOk && activeConnectionType) {
      addInternetLB(
        { ...(fields as any), description: activeConnectionType },
        user.name
      );
    }
  };

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={fields.name}
          label="Name"
          onChange={(e) => {
            handleFieldChange("name", e.target.value);
          }}
          isFullValue
          medium
          error={errors?.name}
        />
        <RadioGroup
          label={"Connection Type"}
          options={CONNECTION_TYPES}
          value={connectionType}
          setActiveValue={(val: string) => setConnectionType(val)}
        />
        <RadioGroup
          label={"Rule"}
          isOneRow
          options={RULE_TYPES}
          value={activeRule}
          setActiveValue={(val: string) => setactiveRule(val)}
        />
        <Input
          {...fieldProps("natName")}
          placeholder={"Name"}
          isPlaceholderAlwaysVisible
          label={undefined}
        />
        <Input
          {...fieldProps("natPrivateIp")}
          placeholder={"Private IP"}
          isPlaceholderAlwaysVisible
          label={undefined}
        />
        <Input
          {...fieldProps("natDescription")}
          placeholder={"Description"}
          isPlaceholderAlwaysVisible
          label={undefined}
        />
        <div className={styles.extraConfig}>
          <Toggle
            label="DDOS Protection"
            id={"ddos"}
            isChecked={ddosProtection}
            onChange={() => {
              setDdosProtection((prev) => !prev);
            }}
            disabled={!IS_DEMO}
            className={styles.leftCol}
          />
          <Toggle
            label="WAF"
            id="waf"
            isChecked={waf}
            onChange={() => {
              setWAF((prev) => !prev);
            }}
            disabled={!IS_DEMO}
            className={styles.rightCol}
          />
          <div className={styles.text}>
            Please contact{" "}
            <a href={"mailto:support@insidepacket.com"}>
              support@insidepacket.com
            </a>{" "}
            to establish DDOS and WAF
          </div>
        </div>
      </div>
      <FormFooter onAdd={handleAddConnection} />
    </>
  );
};
export default InternetToWorkloadsNAT;
