import { AbstractContextProvider } from "../../../../../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../../../../../contexts/common/utils";
import { tenantApi } from "../../../../../../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../../../../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../../../../../helpers/types";
import { AzureAccessKey } from "../../../../../types";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = {
  list?: Array<string>;
  isToken: boolean;
  token?: AzureAccessKey;
  groups?: { [key: string]: string };
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (region: string, tenant: string) => Promise<void>;
  getAzureAccessKey: (tenant: string) => Promise<void>;
  createAzureAccessKey: (
    fields: AzureAccessKey,
    tenant: string
  ) => Promise<void>;
};

const [VnetContext, useVnetContext] = createContextAndUse<IState, IFunc>();
export { useVnetContext };

export default class VnetContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = VnetContext;
  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchList = async (region: string, tenant: string): Promise<void> => {
    this.setState({ listStatus: setPending() });
    const res = await tenantApi.getAzureVNETListApi(tenant, region);
    const groups: { [key: string]: string } = {};
    if (res) {
      res?.result?.map((el) => (groups[el.name] = el.resource_group_name));
      this.setState({
        listStatus: resToState(res),
        list: res?.result?.map((el) => el.name) || [],
        groups,
      });
    }
  };

  getAzureAccessKey = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getAzureAccessKey(tenant);
    this.setState({ token: result, isToken: ok });
  };

  createAzureAccessKey = async (
    fields: AzureAccessKey,
    tenant: string
  ): Promise<void> => {
    const { ok, result } = await tenantApi.getTenant(tenant);
    if (ok && result) {
      const newFields = { ...fields, tenant_id: result.id.toString() };
      await tenantApi.createAzureAccessKey(newFields, tenant);
    }
    this.getAzureAccessKey(tenant);
  };

  funcs = {
    fetchList: this.fetchList,
    getAzureAccessKey: this.getAzureAccessKey,
    createAzureAccessKey: this.createAzureAccessKey,
  };
}
