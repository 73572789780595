import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { configApi } from "../../../helpers/api/ConfigApi";
import { systemApi } from "../../../helpers/api/SystemApi";
import { tenantApi } from "../../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../helpers/types";
import { FAILED_STATUS, OK_STATUS } from "../../const/api";
import { generateWireGuardConfig } from "./helpers/generateWireGuardConfig";

type RequestStatuses = { fetchStatus: RequestStatus };
type State = {
  wireGuardSettings?: string;
  publicIp?: string;
  tunnelStatus?: string;
  nameDevices?: Array<string>;
  handShake?: string;
  connectedFrom?: string;
  tunnelsInfo?: any;
  currSystem?: string;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchDataNew: (tenant: string, user: string) => Promise<void>;
  clearWGSettings: () => void;
  createWGTunnel: (
    tenant: string,
    user: string,
    system: string
  ) => Promise<void>;
  deleteWGTunnel: (tenant: string, user: string) => Promise<void>;
  fetchUserDetails: (
    tenant: string,
    user: string,
    system?: string
  ) => Promise<any>;
  fetchVpnIp: () => Promise<void>;
  fetchCurrSystem: (tenant: string) => Promise<void>;
};

const [WireGuardContext, useContext] = createContextAndUse<IState, IFunc>();
export const useWireGuardContext = useContext;

export default class WireGuardContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = WireGuardContext;

  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchVpnIp = async (): Promise<void> => {
    const { result, publicIp } = await systemApi.getSystemsList();
    if (!result) {
      return;
    }
    this.setState({ fetchStatus: OK_STATUS, publicIp });
  };

  fetchUserDetails = async (
    tenant: string,
    user: string,
    system?: string
  ): Promise<any> => {
    const res = await configApi.getWireGuardStatusPerUser(tenant, user, system);
    if (res?.ok && res?.result) {
      const tunnels: Array<{
        name: string;
        endpoint_ip: string;
        latestHandshake: string;
        psk: string;
        public_key: string;
        status: string;
        system_name: string;
        transferRx: number;
        transferTx: number;
        tunnel_id: string;
        tunnel_ip: string;
        user_name: string;
      }> = res.result.items;

      const tunnelsArray = tunnels
        .filter((obj) => obj.name !== "error")
        .map((obj) => ({
          name: obj.name,
          vpnIp: obj.tunnel_ip,
          tunnelStatus: obj.status,
          handShake: obj.latestHandshake,
          connectedFrom: obj.endpoint_ip,
        }));
      const nameDevices = tunnelsArray.map((obj) => obj.name);
      this.setState({
        fetchStatus: OK_STATUS,
        tunnelsInfo: tunnelsArray,
        nameDevices,
      });
      return { tunnelsArray, nameDevices };
    }
  };

  getWGConfigs = async (
    tenant: string,
    user: string,
    tunnel_id: string
  ): Promise<any> => {
    const res = await configApi.getWireGuardConf(tenant, user, tunnel_id);
    if (res?.ok && res?.result) {
      const {
        tunnel_ip,
        tunnel_private_key,
        tunnel_DNS,
        public_key,
        psk,
        remote_ip,
        remote_port,
        allowed_ips,
      } = res.result;
      // this.setState({ publicIp: remote_ip });
      return {
        tunnel_ip,
        tunnel_private_key,
        tunnel_DNS,
        public_key,
        psk,
        remote_ip,
        remote_port,
        allowed_ips,
      };
    }
  };

  fetchDataNew = async (tenant: string, user: string): Promise<void> => {
    this.setState({ fetchStatus: setPending() });
    // fetch tunnel_id
    const tunnelIdRes = await configApi.getWireGuardTunnelList(tenant, user);

    if (tunnelIdRes.ok && tunnelIdRes.result.count > 0) {
      const tunnel_id = tunnelIdRes.result.items[0].tunnel_id;
      // fetch data for wg settings
      const rawConfig = await this.getWGConfigs(tenant, user, tunnel_id);

      // if something is missing return error
      if (!rawConfig) {
        this.setState({ fetchStatus: FAILED_STATUS });
        return;
      }
      const wireGuardSettings = generateWireGuardConfig(rawConfig);
      this.setState({ fetchStatus: OK_STATUS, wireGuardSettings });
      return;
    }
    // if faild set status
    this.setState({ fetchStatus: resToState(tunnelIdRes) });
  };

  clearWGSettings = (): void => {
    this.setState({ wireGuardSettings: "" });
  };

  createWGTunnel = async (
    tenant: string,
    user: string,
    system: string
  ): Promise<void> => {
    this.setState({ fetchStatus: setPending() });
    const createTunnelRes = await configApi.addWireGuardTunnel(
      tenant,
      user,
      system
    );
    if (createTunnelRes.ok) {
      this.setState({ fetchStatus: OK_STATUS });
    }
    // if faild set status
    this.setState({ fetchStatus: resToState(createTunnelRes) });
  };

  deleteWGTunnel = async (tenant: string, user: string): Promise<void> => {
    this.setState({ fetchStatus: setPending() });
    // fetch tunnel_id
    const tunnelIdRes = await configApi.getWireGuardTunnelList(tenant, user);
    if (tunnelIdRes.ok && tunnelIdRes.result.count > 0) {
      const tunnel_id = tunnelIdRes.result.items[0].tunnel_id;
      // delete first tunnel of the user
      // to do delete all tunnels
      if (tunnel_id) {
        const deleteTunnelRes = await configApi.deleteWireGuardTunnel(
          tenant,
          user,
          tunnel_id
        );
        if (deleteTunnelRes.ok) {
          this.setState({ fetchStatus: OK_STATUS });
        }
      }
      this.setState({ fetchStatus: resToState(tunnelIdRes) });
    }
  };

  fetchCurrSystem = async (tenant: string): Promise<void> => {
    const enabledServicesRes = await tenantApi.getEnabledServices(tenant);
    const remoteUserService = enabledServicesRes.result?.services.find(
      (serviceAPI) => serviceAPI.type === "remote_users" && serviceAPI.enable
    );
    const systemName = remoteUserService?.data.wg_conf.system_name;
    if (!systemName) {
      return;
    } else {
      this.setState({
        currSystem: systemName,
      });
    }
  };

  funcs = {
    fetchDataNew: this.fetchDataNew,
    clearWGSettings: this.clearWGSettings,
    createWGTunnel: this.createWGTunnel,
    deleteWGTunnel: this.deleteWGTunnel,
    fetchUserDetails: this.fetchUserDetails,
    fetchVpnIp: this.fetchVpnIp,
    fetchCurrSystem: this.fetchCurrSystem,
  };
}
