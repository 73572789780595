import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LicenseIcon(props: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19856_247997)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8112 2.28521C10.951 2.1426 11.2308 2 11.5105 2C11.7902 2 12.2098 2 12.3496 2.28521L14.3077 4.13904L16.965 3.71123C17.5245 3.56863 18.0839 3.99643 18.2238 4.56684L18.6434 7.13369L21.021 8.27451C21.5804 8.55972 21.7203 9.13013 21.4406 9.70053L20.3217 11.9822L21.8601 14.2638C22.1399 14.8342 22 15.4046 21.4406 15.6898L19.0629 16.8307L18.6434 19.3975C18.5035 19.9679 17.9441 20.3957 17.3846 20.2531L14.7273 19.8253L12.7692 21.6791C12.3496 22.107 11.6503 22.107 11.2308 21.6791L9.27273 19.8253L6.61538 20.2531C6.05594 20.3957 5.4965 19.9679 5.35664 19.3975L4.93706 16.8307L2.55944 15.6898C2.13986 15.4046 1.86014 14.8342 2.13986 14.2638L3.25874 11.9822L2.13986 9.70053C1.86014 9.13013 2 8.55972 2.55944 8.27451L4.93706 7.13369L5.35664 4.56684C5.4965 3.99643 5.91608 3.56863 6.61538 3.71123L8.85315 4.13904L10.8112 2.28521ZM15.4414 8L17 9.43663L9.87944 16L6 12.424L7.5586 10.9874L9.87946 13.1267L15.4414 8Z"
          fill={props.color || FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_19856_247997">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LicenseIcon;
