import { AWSVPNBody } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { AWS_VPN_BODY } from "./consts";

export const getAwsVpnBody = (fields: any): AWSVPNBody => {
  const body: AWSVPNBody = AWS_VPN_BODY;
  body.connection_name = fields.name;
  body.system_name = fields.location;
  body.gate_name = "";
  body.network_segments = fields.network_segments;
  body.labels = {
    remote_type: "aws",
    region: fields.region,
    vpc_id: fields.vpc_id,
  };
  body.region = fields.region;
  body.vpc_id = fields.vpc_id;

  return body;
};
