import React, { FC } from "react";

type Props = {
  link: string;
};

const GrafanaGraph: FC<Props> = ({ link }) => {
  // Hide Grafana Iframe for development mode
  if (window.location.host.includes("localhost")) {
    return <div>Hidden grafana iframe for dev mode.</div>;
  }

  return <iframe src={link} width="100%" height="100%" frameBorder="0" />;
};
export default GrafanaGraph;
