import React, { FC } from "react";
import ConnectionItem from "./components/ConnectionItem";
import MidpointConnection from "./components/MidpointConnection";
import ConnectionsConnector from "./components/ConnectionsConnector";
import BWLabel from "./components/Labels/BWLabel";
import { ConnectionPortDetails } from "../../types";
import IPLabel from "./components/Labels/IPLabel";

type Props = {
  connections: Array<ConnectionPortDetails>;
  midpoint: string;
};

const VPCDiagram: FC<Props> = ({ connections, midpoint }) => {
  const svgWidth = 600; // SVG width
  const svgHeight = 400; // SVG height
  const centerX = svgWidth / 2; // X coordinate for center of the graph
  const centerY = svgHeight / 2; // Y coordinate for center of the graph
  const parentRadius = 72 / 2; // Radius of the parent node

  const [midArray, firstPartStep, secondPartStep] = getSectorsParams(
    connections.length
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgHeight}
      style={{ overflow: "visible" }}
    >
      {connections.map((connection, i) => {
        const index = i + 1;

        const angle =
          index <= midArray
            ? Math.PI / 2 - index * firstPartStep
            : (3 * Math.PI) / 2 - (index - midArray) * secondPartStep;

        // coords for conections
        const connectionX = centerX + (svgWidth / 2) * Math.cos(angle);
        const connectionY = centerY + (svgHeight / 2) * Math.sin(angle);

        // coords for BW label
        const midPathX = (centerX + connectionX) / 2;
        const midPathY = (centerY + connectionY) / 2;

        // coords for IP label
        const { x: ipX, y: ipY } = getCoordsByDistance(
          centerX,
          centerY,
          connectionX,
          connectionY,
          parentRadius + 20
        );

        const ipAddres = connection.config.ip_addresses[0];

        return (
          <g key={connection.name}>
            <ConnectionsConnector
              x1={centerX}
              y1={centerY}
              x2={connectionX}
              y2={connectionY}
            />
            <BWLabel
              x={midPathX}
              y={midPathY}
              value={connection.config?.BW}
              title="BW"
            />
            <ConnectionItem
              x={connectionX}
              y={connectionY}
              angleRad={angle}
              connection={connection}
            />
            {ipAddres && <IPLabel x={ipX} y={ipY} value={ipAddres} />}
          </g>
        );
      })}
      <MidpointConnection
        x={svgWidth}
        y={svgHeight}
        name={midpoint || ""}
        radius={parentRadius}
      />
    </svg>
  );
};

export default VPCDiagram;

const getCoordsByDistance: (
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  distance: number
) => { x: number; y: number } = (x1, y1, x2, y2, distance) => {
  // Calculate the displacement vector
  const displacementX = x2 - x1;
  const displacementY = y2 - y1;
  // Calculate the distance between the two given points
  const lineDistance = Math.sqrt(
    Math.pow(displacementX, 2) + Math.pow(displacementY, 2)
  );

  // Normalize the displacement vector
  const normalizedDisplacementX = displacementX / lineDistance;
  const normalizedDisplacementY = displacementY / lineDistance;

  // Scale the normalized displacement vector by the desired distance
  const scaledDisplacementX = normalizedDisplacementX * distance;
  const scaledDisplacementY = normalizedDisplacementY * distance;

  // Calculate the coordinates of the new point
  const x = x1 + scaledDisplacementX;
  const y = y1 + scaledDisplacementY;

  return { x, y };
};

const getSectorsParams = (amount: number): [number, number, number] => {
  const firstPart = Math.ceil(amount / 2);
  const secondPart = amount - firstPart;

  const firstPartStep = Math.PI / (firstPart + 1);
  const secondPartStep = Math.PI / (secondPart + 1);

  return [firstPart, firstPartStep, secondPartStep];
};
