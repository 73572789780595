import { FC } from "react";
import WarningCircleIcon from "../../../../components/icons/WarningCircleIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { CRITICAL } from "../../../../helpers/common/colorAliases";
import styles from "./CustomListItem.module.scss";

type Props = {
  title: string;
  tip: string;
  isDeny?: boolean;
  onClick?: () => void;
};

const CustomListItem: FC<Props> = ({
  title,
  tip,
  isDeny,
  onClick = () => undefined,
}) => {
  const wrapper = classNames(styles.wrapper, isDeny && styles.isDeny);
  return (
    <div className={wrapper} onClick={onClick}>
      <div className={styles.title}>{title}</div>
      <div className={styles.tip}>
        {isDeny && (
          <WarningCircleIcon className={styles.icon} color={CRITICAL} />
        )}
        {tip}
      </div>
    </div>
  );
};

export default CustomListItem;
