import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext";
import { LOGIN } from "../../helpers/navigation/entries";

const AuthRoute = ({ children, ...routerProps }: RouteProps): JSX.Element => {
  const { isAuthorized } = useAuthContext();

  if (!isAuthorized) {
    return <Redirect to={"/" + LOGIN().path} />;
  }
  return <Route {...routerProps}>{children}</Route>;
};

export default AuthRoute;
