import React, { FC, useCallback } from "react";
import { getDialogControls } from "../../helpers/getDialogControls";
import useFieldGroup from "../../helpers/hooks/useFieldGroup";
import { useFormField } from "../../helpers/hooks/useFormField";
import { LoopbackType } from "../../helpers/api/apiTypes";
import DropDownInput, {
  mapStringToItem,
} from "../common/formComponents/DropDownInput";
import FieldsGroup from "../common/FieldsGroup";
import Textarea from "../common/formComponents/Textarea";
import SummaryInfoBlock from "../leftInfoBlock/SummaryInfoBlock";
import DialogTemplate from "./common/DialogTemplate";
import styles from "./LoopbackDialog.module.scss";
import LoopIcon from "../icons/LoopIcon";
import { NewDialogProps } from "../../helpers/types";
import DeleteButton from "../common/buttons/DeleteButton";
import UnifiedTextPair from "../common/UnifiedTextPair";
import TextWithIcon from "../common/table/TextWithIcon";
import { getColorFromStatus } from "../../helpers/common/colorHelpers";
import StatusIcon from "../icons/StatusIcon";

type LoopbackFormType = {
  vrf: string;
  description: string;
  ipv4: Array<string>;
  ipv6: Array<string>;
};

const VRF_LIST = ["vrf-1", "vrf-2", "vrf-3"].map(mapStringToItem);

const DEFAULT_FIELDS_VALUES = {
  vrf: VRF_LIST[0].key,
  description: "",
  ipv4: [],
  ipv6: [],
};

const LoopbackDialog: FC<NewDialogProps<Partial<LoopbackType>>> = ({
  onClose,
  type,
}) => {
  const isEdit = type === "edit";
  const [fields, handleFieldChange] = useFormField<LoopbackFormType>(
    DEFAULT_FIELDS_VALUES
  );

  const handleFieldGroup = useCallback(
    (i: number, name: string, value: string | number) => {
      const [key, fieldGroup] = useFieldGroup(
        i,
        name,
        value,
        fields as LoopbackFormType
      );
      handleFieldChange(key, fieldGroup);
    },
    [handleFieldChange]
  );

  const controls = getDialogControls({
    dialogTitle: isEdit ? "save" : "add",
    onAdd: () => undefined,
    onEdit: () => undefined,
    onClose,
  });

  const leftColumn = (
    <SummaryBlock
      title="Loop"
      onClick={() => undefined}
      type={type as string}
    />
  );

  return (
    <DialogTemplate
      title={isEdit ? "Edit" : "Add"}
      onClose={onClose}
      controls={controls}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <DropDownInput
          id={"loopbackDialogVRF"}
          onChange={(val) => handleFieldChange("vrf", val.key)}
          selected={mapStringToItem(fields.vrf)}
          label="VRF"
          error={""}
          itemsList={VRF_LIST}
        />
        <Textarea
          name="description"
          placeholder={fields.description}
          value={fields.description}
          label="Description"
          error={""}
          onChange={handleFieldChange}
        />
      </div>
      <div className={styles.column}>
        <FieldsGroup
          name="ipv4"
          placeholder="Enter IP"
          fields={fields.ipv4}
          title="IPv4"
          onChange={handleFieldGroup}
        />
        <FieldsGroup
          name="ipv6"
          placeholder="Enter IP"
          fields={fields.ipv6}
          title="IPv6"
          onChange={handleFieldGroup}
        />
      </div>
    </DialogTemplate>
  );
};

export default LoopbackDialog;

type SumProps = { title: string; type: string; onClick: () => void };

const SummaryBlock: FC<SumProps> = ({ title, onClick }) => {
  return (
    <SummaryInfoBlock
      icon={<LoopIcon />}
      title={title}
      footer={
        <DeleteButton id={"loopBackDialog"} onClick={onClick} withLabel />
      }
    >
      <UnifiedTextPair subtitle="Tenant" text={"Coca-Cola"} />
      <UnifiedTextPair subtitle="VRF" text={"vrf-1"} />

      <UnifiedTextPair
        subtitle="Status"
        text={
          <TextWithIcon
            icon={<StatusIcon color={getColorFromStatus("active")} />}
            text={"active".toUpperCase()}
          />
        }
      />
    </SummaryInfoBlock>
  );
};
