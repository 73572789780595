import { FC, useState } from "react";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import CaretDown from "../../../components/icons/CaretDown";
import { classNames } from "../../../helpers/common/classNames";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import styles from "./ProviderComponent.module.scss";
import ProviderDetails from "./ProviderDetails";
import ProviderTitle from "./ProviderTitle";
import { Layer3InterfaceNew } from "../../Layer3Interface/types";

type Props = {
  connectedGW: Array<Layer3InterfaceNew>;
  type: string;
  disabled: boolean;
};

const ProviderComponent: FC<Props> = ({ connectedGW, type, disabled }) => {
  const [showDetails, hideDetails] = useState<boolean>(false);
  return (
    <div className={classNames(styles.wrapper, disabled && styles.disabled)}>
      <div
        className={classNames(styles.header, styles.clickable)}
        onClick={() => hideDetails((prev) => !prev)}
      >
        <ProviderTitle
          className={styles.cloudTitle}
          title={type}
          count={connectedGW.length}
          withDetails={showDetails}
        />
        <IconBlankButton
          id={"sdwanProviderCaretDown"}
          icon={CaretDown}
          className={styles.icon}
          color={GREY_FONT}
        />
      </div>
      <div
        className={classNames(
          styles.detailsBlock,
          showDetails && styles.showBlock
        )}
      >
        <ProviderDetails type={type} connectedGW={connectedGW} />
      </div>
    </div>
  );
};
export default ProviderComponent;
