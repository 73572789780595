import React, { FC } from "react";
import { classNames } from "../../helpers/common/classNames";
import { useClickOutside } from "../../helpers/hooks/useClickOutside";
import styles from "./AdditionalMenu.module.scss";

type Props = {
  isHide: boolean;
  className: string;
  onClose: () => void;
};
const AdditionalMenu: FC<Props> = ({
  isHide,
  children,
  className,
  onClose,
}) => {
  const dropDownRef = useClickOutside(onClose);
  return (
    <div
      ref={dropDownRef as React.RefObject<HTMLDivElement>}
      className={classNames(styles.wrapper, className, isHide && styles.hide)}
    >
      {children}
    </div>
  );
};
export default AdditionalMenu;
