import { CellProps } from "react-table";
import LabelArray from "../../../components/common/table/LabelArray";
import RowControls, {
  ActionFuncs,
} from "../../../components/common/table/newTable/RowControls";
import styles from "./providerTableHeader.module.scss";
import { Layer3InterfaceNew } from "../../Layer3Interface/types";
import StatusIcon from "../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";

export const providerTableHeader = (
  actions: ActionFuncs<Layer3InterfaceNew>,
  type?: string
): any => {
  const baseColums = [
    {
      Header: "",
      accessor: "administrative_state",
      Cell: ({ row: { original } }: CellProps<Layer3InterfaceNew>) => {
        const status = original.administrative_state;
        return <StatusIcon color={getColorFromStatus(status || "")} />;
      },
    },
    {
      Header: "Connection",
      accessor: "name",
    },
    {
      Header: "Network",
      accessor: "ip_addresses",
      Cell: ({
        row: { original },
      }: CellProps<Layer3InterfaceNew & { ip_addresses?: Array<string> }>) => {
        const subnets = original.ip_addresses || [];
        return <LabelArray values={subnets} />;
      },
    },
    {
      Header: "Connection type",
      accessor: "type",
      Cell: ({ cell: { value } }: CellProps<Layer3InterfaceNew>) => {
        const connectionType = getConnectionTypeName(value);
        return (
          <span className={styles.spanValue} title={connectionType}>
            {connectionType}
          </span>
        );
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: CellProps<Layer3InterfaceNew>) => {
        return (
          <RowControls
            className={styles.rowAction}
            row={row}
            actions={{
              ...actions,
            }}
          />
        );
      },
    },
  ];

  return baseColums.map((el) => ({
    ...el,
    accessor: el.accessor as keyof Layer3InterfaceNew,
  }));
};

export const getConnectionTypeName = (value: string): string => {
  switch (true) {
    case value.includes("Virtual Interface Tenant Bridge Domain"):
      return "Direct connect";
    case value.includes("Virtual Interface IPSEC"):
      return "IPSEC";
    default:
      return "";
  }
};
