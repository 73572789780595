import React, { FC, useEffect } from "react";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { withContexts } from "../../../../../helpers/hocs/withContexts";
import TopologyButtonsContextContainer, {
  useTopologyButtonsContext,
} from "./TopologyButtonsContext";
import styles from "./TopologyButtons.module.scss";

import { useTimerangeContext } from "../../../../../contexts/TimerangeContext";
import { TOPOLOGY_CARDS_MAP } from "./topologyButtonsConsts";
import { classNames } from "../../../../../helpers/common/classNames";
import Card from "../../../../../components/common/Card/Card";

type Props = { className: string };

const TopologyButtons: FC<Props> = ({ className }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { fetchCardsData, cardsData } = useTopologyButtonsContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchCardsData(selectedTenant, timeRange.general);
    }
  }, [selectedTenant, timeRange]);

  return (
    <div className={classNames(styles.wrapper, className)}>
      {TOPOLOGY_CARDS_MAP?.map((card) => (
        <Card {...card} data={cardsData} />
      ))}
    </div>
  );
};

export default withContexts<Props>(TopologyButtons, [
  TopologyButtonsContextContainer,
]);
