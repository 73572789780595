import { PaginateListResponse } from "../../helpers/types";
import { PublicIpManagementRecord } from "./types";

export const IPAM_MOCKED_DATA: PaginateListResponse<PublicIpManagementRecord> = {
  items: [
    {
      id: "1",
      name: "publicIpRangeTest",
      from_ip: "1.1.1.1",
      to_ip: "1.1.1.255",
      type: "global",
      data: null,
      vrf_name: "internet",
      ipsec_service: true,
      nat_service: true,
      snat_service: true,
      shared: true,
    },
    {
      id: "2",
      name: "systemPublicIPRange",
      from_ip: "2.2.2.2",
      to_ip: "2.2.2.255",
      type: "system",
      data: "Real",
      vrf_name: "internet",
      ipsec_service: true,
      nat_service: true,
      snat_service: true,
      shared: false,
    },
  ],
  count: 2,
};
