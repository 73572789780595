import { configApi } from "./api/ConfigApi";

export const fetchAndAppendVtep = async <D extends { vtep_id: number }>(
  system: string,
  arr: Array<D>
): Promise<Array<D & { vtep_name?: string }>> => {
  const copy: Array<D & { vtep_name?: string }> = [...arr];
  const { ok, result } = await configApi.getVTEPList(system);
  await Promise.all(
    arr.map(
      async ({ vtep_id }, i): Promise<any> => {
        copy[i] = {
          ...copy[i],
          vtep_name: ok
            ? result?.find((vtep) => +vtep.id === +vtep_id)?.name
            : undefined,
        };
      }
    )
  );

  return copy;
};
