import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { GREEN } from "../../helpers/common/colorAliases";

function OkCircleIconFilled({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="9" fill={color || GREEN} />
      <path
        d="M4.05078 8.55039L7.65078 12.6004L14.4008 5.40039"
        stroke="white"
        strokeWidth="3"
      />
    </svg>
  );
}

export default OkCircleIconFilled;
