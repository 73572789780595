import { FC, ReactElement } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import styles from "./InfoBlock.module.scss";
import InfoIcon from "../../../../../../components/icons/InfoIcon";
import { GREY_DISABLED } from "../../../../../../helpers/common/colorAliases";

type Props = {
  className: string;
  children?: ReactElement | string;
};
const InfoBlock: FC<Props> = ({ className, children }) => {
  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.header}>
        <InfoIcon color={GREY_DISABLED} />
        Information
      </div>
      <div className={styles.info}>{children || "-"}</div>
    </div>
  );
};
export default InfoBlock;
