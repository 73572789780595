import React, { FC } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import InfoIcon from "../../../../../components/icons/InfoIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import { GREY_DISABLED } from "../../../../../helpers/common/colorAliases";
import styles from "./TipsBlock.module.scss";

type Props = { className: string };

const TipsBlock: FC<Props> = ({ className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={<InfoIcon color={GREY_DISABLED} />}
        text={"Information"}
        className={styles.header}
      />
      <div className={styles.infoContainer}>
        {Object.keys(tooltipInfo).map((key, i) => {
          return (
            <p key={key + i}>
              <span>{key}</span>: {tooltipInfo[key]}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default TipsBlock;

const tooltipInfo: { [key: string]: string } = {
  General: "Add a BGP neighbor to your network",
  IP: "The IP of the remote neighbor",
  "Remote AS": "The Autonomous System (ASN) of the remote neighbor",
  Password:
    "Securing the BGP connection, make sure to use the same password on both sides (optional)",
  "Multihop TTL":
    "Usually neighbors are directly connected (TTL=1). Increase for neighbors not directly connected. Valid for eBGP only (different remote AS).",
  "Source AS":
    "The local ASN is used to connect to the neighbor. You can change the source ASN here if required. (optional)",
  "Passive mode":
    "You should only enable passive mode, if the remote neighbor needs to initiate the BGP session. In some rare cases this can help overcoming connectivity issues (example: if a session is stuck in OpenConfirm status)",
  "Address Families":
    "Configure filtering and route distributionAddress family: Choose the address family to configure (currently only IPv4 unicast)",
  "Route filter":
    "Choose a route-map to filter incoming and outgoing routes. You can choose from existing maps or create your own",
  Buttons:
    "Enable/Disable, soft reset, hard reset. To force the neighbor to resend or accept the routes. Use this in case you changed a route map or a route is missing on either side.",
};
