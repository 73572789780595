import { ConnectionApi, ConnectionEdit } from "../../types";
import { BASIC_BODY_UPDATED } from "../consts";
import { FieldsType } from "../types";

export const getUpdatedAZConnectionBody = (
  fields: Array<FieldsType>,
  services: { [key: string]: boolean },
  initialConnection?: ConnectionApi
): ConnectionEdit | undefined => {
  const [aPort, zPort] = fields;

  if (
    aPort.BW === initialConnection?.a_endpoint_conf.BW &&
    zPort.BW === initialConnection?.z_endpoint_conf.BW
  ) {
    return undefined;
  }

  const body = { ...BASIC_BODY_UPDATED };

  body.a_endpoint_conf = {
    ...initialConnection?.a_endpoint_conf,
    BW: aPort.BW,
  };
  body.z_endpoint_conf = {
    ...initialConnection?.z_endpoint_conf,
    BW: zPort.BW,
  };

  body.services = services;

  return body;
};
