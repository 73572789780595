import React, { FC, useEffect, useState } from "react";
import TextWithIcon from "../../../../../../../../components/common/table/TextWithIcon";
import { classNames } from "../../../../../../../../helpers/common/classNames";
import { GREY_FONT } from "../../../../../../../../helpers/common/colorAliases";
import { getCloudIcon } from "../../../../helpers/getCloudIcon";
import styles from "./ItemContainer.module.scss";
import ItemControls from "./ItemControls";

type Props = {
  idx: number;
  title: string;
  onDelete: () => void;
  formClassName: string;
  isExpandInitial: boolean;
  type: string;
  isEdit?: boolean;
};

const ItemContainer: FC<Props> = ({
  children,
  title,
  idx,
  onDelete,
  formClassName,
  isExpandInitial,
  type,
  isEdit,
}) => {
  const [isExpand, setIsExpand] = useState<boolean>(true);

  useEffect(() => {
    setIsExpand(isExpandInitial);
  }, [idx, isExpandInitial]);

  return (
    <div className={classNames(styles.wrapper)}>
      <div
        className={styles.headerWrapper}
        onClick={() => setIsExpand((prev) => !prev)}
      >
        <ItemHeader title={title} type={type} />
        <ItemControls
          isExpand={isExpand}
          toggleExpand={() => setIsExpand((prev) => !prev)}
          onDelete={onDelete}
          idx={idx}
          isEdit={isEdit}
        />
      </div>
      {isExpand && <div className={formClassName}>{children}</div>}
    </div>
  );
};

export default ItemContainer;

const ItemHeader: FC<{ title: string; type: string }> = ({ title, type }) => {
  const Icon = getCloudIcon(title);
  const isCloudConnection = type === "cloudConnection";
  return (
    <>
      {isCloudConnection ? (
        <TextWithIcon
          icon={<Icon color={GREY_FONT} />}
          text={title?.toUpperCase()}
          className={styles.title}
        />
      ) : (
        <span className={styles.title}>{title}</span>
      )}
    </>
  );
};
