import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function RestartIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.54273 3.35161C7.83865 3.31854 8.0977 3.54981 8.09827 3.84757L8.1095 9.79646C8.11032 10.2288 7.59906 10.4584 7.27652 10.1704L5.65907 8.72639C4.30561 11.3791 4.74168 14.7103 6.96337 16.927C9.72184 19.6792 14.1891 19.6741 16.9414 16.9157C19.6936 14.1572 19.6885 9.6899 16.9301 6.93767C16.7987 6.80666 16.6638 6.68207 16.5256 6.56386C15.896 6.02544 15.822 5.07857 16.3604 4.44897C16.8989 3.81937 17.8457 3.74545 18.4753 4.28386C18.6719 4.45194 18.8633 4.62865 19.049 4.81395C22.9803 8.73642 22.9876 15.1032 19.0651 19.0346C15.1426 22.9659 8.77582 22.9732 4.84445 19.0507C1.4834 15.6972 0.991061 10.5595 3.36867 6.68159L1.35318 4.88222C1.03063 4.59426 1.20093 4.06035 1.63063 4.01233L7.54273 3.35161ZM12.0002 1C11.2712 1 10.6802 1.59098 10.6802 2.32L10.6802 9.58C10.6802 10.309 11.2712 10.9 12.0002 10.9C12.7292 10.9 13.3202 10.309 13.3202 9.58L13.3202 2.32C13.3202 1.59098 12.7292 1 12.0002 1Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default RestartIcon;
