import { FC } from "react";
import styles from "./PortTypeFlag.module.scss";
import { classNames } from "../../../../../../../helpers/common/classNames";

const PortTypeFlag: FC<{ flag: string; className?: string }> = ({
  flag,
  className,
}) => {
  return (
    <div className={classNames(styles.portTypeFlag, className)}>{flag}</div>
  );
};
export default PortTypeFlag;
