import { FC, useEffect } from "react";
import {
  ChangeField,
  FieldsType,
} from "../../../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import Section from "../../../common/Section";
import styles from "./SystemSection.module.scss";
import DropdownBasic from "../../../common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { useTenantContext } from "../../../../contexts/tenantsContext/TenantsContext";
import LabelArray from "../../../common/table/LabelArray";

type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
};

const SystemSection: FC<Props> = ({ className, fields, onChange, errors }) => {
  const { fetchSystems, systems } = useTenantContext();

  const removeSystem = (label: string) => {
    const prevystems = fields.systems;
    const systemRemove = label.split(" ")[0];
    const newSystems = prevystems.filter(
      (system: string) => system !== systemRemove
    );
    fields.systems = newSystems;
    fetchSystems();
  };

  const labelArrayList = systems?.reduce((acc: any, obj) => {
    if (fields.systems?.includes(obj.name)) {
      acc.push(`${obj.name} | ${obj.location}`);
    }
    return acc;
  }, []);

  useEffect(() => {
    fetchSystems();
  }, [fields.systems]);

  return (
    <Section title={"Systems"} columnsCount={1} className={className}>
      <DropdownBasic
        id={"system_name"}
        onChange={(val) => {
          const systemName = val.key.split(" ")[0];
          if (fields.systems) {
            if (!fields.systems.includes(systemName)) {
              onChange("systems", [...fields.systems, systemName]);
            }
          } else {
            onChange("systems", [systemName]);
          }
        }}
        placeholder="System name"
        itemsList={systems?.map((key: any) =>
          mapStringToItem(`${key.name} | ${key.location}`)
        )}
        listClassName={styles.dropdown}
        error={errors && errors["systems"]}
        isMedium
      />
      {labelArrayList && (
        <LabelArray
          values={labelArrayList}
          isRemovable
          onClick={removeSystem}
          withWrap
        />
      )}
    </Section>
  );
};
export default SystemSection;
