import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function CloudIcon(props: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      {...props}
    >
      <path
        fill={props.color || FONT}
        d="M15.927 9.17c-.323-2.747-2.644-4.877-5.46-4.877s-5.138 2.13-5.46 4.877c-1.955 0-3.54 1.594-3.54 3.561 0 1.967 1.585 3.562 3.54 3.562h10.92c1.955 0 3.54-1.595 3.54-3.562 0-1.967-1.585-3.561-3.54-3.561z"
      />
    </svg>
  );
}

export default CloudIcon;
