import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../../contexts/common/AbstractCrudContext";
import { TIME_RANGE_MS_MAP } from "../../../../contexts/TimerangeContext";
import { elasticApi } from "../../../../helpers/api/ElasticApi";
import {
  resToState,
  setPending,
} from "../../../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../../../helpers/types";
import {
  GTimeRange,
  parseGTime,
} from "../../../common/charts/GraphanaLinksFactory";

export const NONE = "none";

type RequestStatuses = {
  timeRangeStatus: RequestStatus;
};
type State = { availableTimeRange?: string | undefined | null };

type IState = State & RequestStatuses;
type IFunc = {
  getGrafanaTimeRange: (
    timeRange: GTimeRange,
    type: string,
    tenantName?: string
  ) => Promise<void>;
};

const [GrafanaContainerContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useGrafanaContainerContext = useContext;

export default class GrafanaContainerContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = GrafanaContainerContext;

  _convertCustomTimeRange = (timeRange: GTimeRange): number => {
    const { from, to } = timeRange;
    const fromMS = new Date(parseGTime(from)).getTime();
    const toMS = new Date(parseGTime(to)).getTime();
    return toMS - fromMS;
  };

  getGrafanaTimeRange = async (
    timeRange: GTimeRange,
    type: string,
    tenantName?: string
  ): Promise<void> => {
    this.setState({ timeRangeStatus: setPending("Fetching") });
    const res = await (type === "systems"
      ? elasticApi.getSystemsGrafanaTimeRange()
      : elasticApi.getGrafanaTimeRange(type, tenantName));

    if (res.ok && res.result) {
      const currentTimeRange = this._convertCustomTimeRange(timeRange);
      const grafanaTimeRange = res.result.find(
        (value) => value.timeRange === currentTimeRange
      );
      let availableTimeRange;

      if (grafanaTimeRange?.doc_count === 0) {
        const sortedList = res.result.sort((a, b) => a.timeRange - b.timeRange);

        if (sortedList.every((value) => value.doc_count === 0)) {
          this.setState({
            availableTimeRange: NONE,
            timeRangeStatus: resToState(res),
          });
          return;
        }

        const availableTimeRangeMS = sortedList.find(
          (value) => value.doc_count > 0
        )?.timeRange;

        availableTimeRange = availableTimeRangeMS
          ? TIME_RANGE_MS_MAP[availableTimeRangeMS]
          : null;
      }

      this.setState({
        availableTimeRange,
      });
    }
    this.setState({ timeRangeStatus: resToState(res) });
  };

  funcs = {
    getGrafanaTimeRange: this.getGrafanaTimeRange,
  };
}
