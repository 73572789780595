export const calculateDataUsage = (
  json: Array<any>,
  label: string,
  labelA = "LANRX_BYTES",
  labelB = "LANTX_BYTES",
  average = false
): Array<Array<any>> => {
  if (!json || !json.length) return [[], []];

  const { header } = json.pop();
  const [headers] = header as Array<Array<string>>;

  const ipIndex = headers.indexOf(label);
  const indexA = headers.indexOf(labelA);
  const indexB = headers.indexOf(labelB);
  const iplist: Array<Record<string, any>> = [];

  json.forEach((item) => {
    const [rows] = Object.values(item) as Array<Array<Array<number | string>>>;

    rows.forEach((row) => {
      const ip = row[ipIndex];

      if (ip === "__total__") return;

      const valueA = indexA >= 0 ? +row[indexA] : 0;
      const valueB = indexB >= 0 ? +row[indexB] : 0;
      const bytes = valueA + valueB;

      const exists = iplist.find((i) => i.ip === ip);

      if (exists) {
        if (average) {
          exists.bytes = (exists.bytes + bytes) / 2;
        } else {
          exists.bytes += bytes;
        }
      } else {
        iplist.push({ ip, bytes });
      }
    });
  });

  const toplist = iplist
    .sort((a, b) => {
      if (a.bytes < b.bytes) {
        return 1;
      } else if (a.bytes > b.bytes) {
        return -1;
      }

      return 0;
    })
    .slice(0, 15);

  return [toplist.map((i) => i.ip), toplist.map((i) => i.bytes)];
};

export const labelizeBytes = (bytes: number): string => {
  const KB = bytes / 1000;
  const MB = KB / 1000;
  const GB = MB / 1000;
  const TB = GB / 1000;

  if (TB >= 1) return TB.toFixed(1) + "T";
  if (GB >= 1) return parseInt(String(GB)) + "G";
  if (MB >= 1) return parseInt(String(MB)) + "M";
  if (KB >= 1) return parseInt(String(KB)) + "K";

  return bytes.toString();
};
