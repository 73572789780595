import React, { FC, useMemo } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { DialogControlsType, RequestStatus } from "../../../helpers/types";
import Button from "../../common/buttons/Button";
import styles from "./DialogBtmButtons.module.scss";
import LoaderIcon from "../../common/loadStates/LoaderIcon";
import { WHITE } from "../../../helpers/common/colorAliases";

type DialogBtmButtonsProps = {
  controls: DialogControlsType;
  className?: string;
  backButton?: React.ReactElement | null;
  errorDisplay?: RequestStatus;
};

const DialogBtmButtons: FC<DialogBtmButtonsProps> = ({
  controls,
  className,
  backButton,
  errorDisplay,
}) => {
  const {
    leftPart,
    okText,
    onOk,
    cancelText = "Cancel",
    onCancel,
    disableSubmit,
  } = controls;
  const wrapper = classNames(styles.btmContols, className);
  const primaryBtn = classNames(styles.dialogBtns, styles.primary);

  const isPending = useMemo(() => errorDisplay?.state === "pending", [
    errorDisplay,
  ]);
  return (
    <div className={wrapper}>
      {leftPart && <div className={styles.leftPart}>{leftPart}</div>}
      {backButton}
      {cancelText && onCancel && (
        <Button onClick={onCancel} className={styles.dialogBtns}>
          {cancelText}
        </Button>
      )}
      <Button
        onClick={onOk}
        className={primaryBtn}
        isPrimaryBtn
        disabled={disableSubmit || isPending}
      >
        {isPending ? (
          <LoaderIcon color={WHITE} className={styles.loader} />
        ) : (
          okText
        )}
      </Button>
    </div>
  );
};

export default DialogBtmButtons;
