import { FC } from "react";
import styles from "./SearchResultsList.module.scss";
import EmptyListPlaceholder from "./EmptyListPlaceholder";
import SearchIcon from "../../icons/SearchIcon";
import { ACCENT, GREY_DISABLED } from "../../../helpers/common/colorAliases";
import { NavItem } from "../helpers/types";

type Props = {
  searchValue?: string;
  navList?: Array<NavItem>;
  onClick: (value: string, goto: string) => void;
};

const SearchResultsList: FC<Props> = ({ searchValue, navList, onClick }) => {
  const handleSelectSearchValue = (value: string) => {
    onClick(value, `/search:${value}`);
  };

  const handleSelectNavValue = (value: NavItem) => {
    onClick(value.title, value.path);
  };

  return (
    <div className={styles.listWrapper}>
      {!searchValue && <EmptyListPlaceholder className={styles.basicStyles} />}
      {searchValue && (
        <div
          onClick={() => handleSelectSearchValue(searchValue)}
          className={styles.searchValue}
        >
          <SearchIcon color={GREY_DISABLED} />
          {searchValue}
        </div>
      )}
      {searchValue && navList && navList?.length > 0 && (
        <div className={styles.navListWrapper}>
          <div className={styles.navListTitle}>Navigation</div>
          <ul className={styles.navList}>
            {navList?.map((item) => {
              const Icon = item.icon;
              return (
                <li
                  className={styles.navItem}
                  onClick={() => handleSelectNavValue(item)}
                >
                  <Icon color={ACCENT} />
                  {item.title}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchResultsList;
