import React, { FC, useEffect } from "react";
import { DialogControlsType, NewDialogProps } from "../../helpers/types";
import { BGPUnderlay } from "../../helpers/api/apiTypes";
import DialogTemplate from "./common/DialogTemplate";
import { useFormField } from "../../helpers/hooks/useFormField";
import CodeEditor from "../common/CodeEditor";
import { useBGPContext } from "../../contexts/systemsContext/BGPContext";
import { EDIT } from "../../helpers/common/constantsAlias";

const DEFAULT_FIELDS: Partial<BGPUnderlay> = {
  conf: "",
};

const BGPDialog: FC<NewDialogProps<BGPUnderlay>> = ({ onClose, type }) => {
  const {
    resetStatus,
    underlay,
    fetchUnderlay,
    updateUnderlay,
    updateUnderlayStatus,
    underlayStatus,
  } = useBGPContext();

  const [fields, handleFieldChange] = useFormField<Partial<BGPUnderlay>>(
    DEFAULT_FIELDS
  );

  useEffect(() => {
    fetchUnderlay();
  }, []);

  useEffect(() => {
    if (underlay) handleFieldChange("conf", underlay.conf);
  }, [underlay]);

  const handleUpdate = async () => {
    if (fields.conf) {
      const successAdd = await updateUnderlay({ ...fields });
      if (successAdd) handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  return (
    <DialogTemplate
      title={"Config"}
      onClose={onClose}
      controls={getDialogControls({
        type,
        onAdd: handleClose,
        onEdit: handleUpdate,
        onClose: handleClose,
      })}
      errorDisplay={updateUnderlayStatus || underlayStatus}
    >
      <CodeEditor
        value={fields.conf || ""}
        isEdit={type === EDIT}
        onChange={handleFieldChange}
        status={underlayStatus}
      />
    </DialogTemplate>
  );
};

export default BGPDialog;

type ControlsType = {
  type: "add" | "edit" | undefined;
  onAdd: () => void;
  onEdit: () => void;
  onClose: () => void;
  disableSubmit?: boolean;
};
const getDialogControls = ({
  type,
  onAdd,
  onEdit,
  onClose,
  disableSubmit,
}: ControlsType): DialogControlsType => {
  const isEdit = type === EDIT;
  return {
    okText: isEdit ? "Save" : "Ok",
    onOk: isEdit ? onEdit : onAdd,
    cancelText: "Cancel",
    onCancel: isEdit ? onClose : undefined,
    disableSubmit: disableSubmit,
  };
};
