import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import { IconProps } from "../../../../helpers/types";

function IDSIPSIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.67822 2.7002L9.51119 3.35901L11.3442 2.7002L12.217 6.02895H11.6678L11.6679 6.02905H12.2161L14.3109 7.41603H12.0959C12.1184 7.59655 12.1301 7.7818 12.1301 7.97064C12.1301 9.02809 11.7629 9.9735 11.186 10.6011L14.3484 9.54204L12.8288 12.4025C14.41 13.1816 15.6905 14.5303 16.4354 16.1998H2.93555C3.60769 14.6934 4.71594 13.4481 6.08769 12.6469L4.43826 9.54204L7.95135 10.7185C7.30914 10.095 6.89304 9.09631 6.89304 7.97064C6.89304 7.7818 6.90475 7.59655 6.92727 7.41603H4.70962L6.80445 6.02905H7.35527L7.35533 6.02895H6.80537L7.67822 2.7002Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default IDSIPSIcon;
