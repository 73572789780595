import React, { FC, ReactElement, useEffect } from "react";
import DropdownBasic from "../../common/Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SegmentsDropdownContextContainer, {
  useSegmentsDropdownContext,
} from "./SegmentsDropdownContext";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";

type Props = {
  onChange: (field: string) => void;
  disabled?: boolean;
  selected?: string;
  label?: string;
  placeholder?: string;
};

export const SegmentsDropdown: FC<Props> = ({
  onChange,
  disabled,
  selected,
  label,
  placeholder,
}): ReactElement => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchList, list = [] } = useSegmentsDropdownContext();

  useEffect(() => {
    if (selectedTenant) fetchList(selectedTenant);
  }, [selectedTenant]);

  return (
    <DropdownBasic
      label={label}
      id={"segments"}
      onChange={(val) => {
        onChange(val.key);
      }}
      selected={mapStringToItem(selected || "")}
      itemsList={list.map(mapStringToItem)}
      isMedium
      error={""}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default withContexts<Props>(SegmentsDropdown, [
  SegmentsDropdownContextContainer,
]);
