import React, { FC, useEffect, useRef } from "react";
import { ConnectionPortDetails } from "../types";
import AZDiagram from "./components/AZDiagram";
import VPCDiagram from "./components/VPCDiagram";
import { useConnectionsContext } from "../ConnectionContext";
import ReactTooltip from "react-tooltip";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ConnectionDiagram.module.scss";

type Props = {
  data?: { ports: Array<ConnectionPortDetails>; vpc?: ConnectionPortDetails };
};

const ConnectionsDiagram: FC<Props> = ({ data }) => {
  const { tooltip } = useConnectionsContext();
  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      ReactTooltip.rebuild();
    }
  }, [tooltip]);

  const connections = data?.ports || [];
  const midpoint = data?.vpc?.endpoint_type;

  const numPorts = connections.length;
  return (
    <div data-tip data-for={"zayoDiagram"}>
      {numPorts === 2 && !midpoint ? (
        <AZDiagram connections={connections} />
      ) : (
        <VPCDiagram connections={connections} midpoint={midpoint as string} />
      )}
      {tooltip && (
        <ReactTooltip
          ref={tooltipRef}
          id={"zayoDiagram"}
          aria-haspopup="true"
          place="bottom"
          type="light"
          effect="float"
          arrowColor="transparent"
          isCapture={true}
          className={styles.tooltip}
        >
          {tooltip?.value || ""}
        </ReactTooltip>
      )}
    </div>
  );
};

export default ConnectionsDiagram;
