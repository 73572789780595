import { FC, useState } from "react";
import styles from "./TablesGroup.module.scss";
import IconBlankButton from "../../../../components/common/buttons/IconBlankButton";
import CaretDown from "../../../../components/icons/CaretDown";
import { FONT } from "../../../../helpers/common/colorAliases";
import { classNames } from "../../../../helpers/common/classNames";

const TablesGroup: FC = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const handleToggleCollapse = () => setIsCollapsed((prev) => !prev);

  const wrapperClasses = classNames(
    styles.wrapper,
    isCollapsed && styles.collapsed
  );
  const collapseBtnClasses = classNames(
    styles.collapseBtn,
    !isCollapsed && styles.rotate
  );

  return (
    <div className={wrapperClasses}>
      {children}
      <div className={styles.iconContainer}>
        <IconBlankButton
          id={"group_collapse"}
          icon={CaretDown}
          onClick={handleToggleCollapse}
          className={collapseBtnClasses}
          color={FONT}
          isOnlyOpacity
        />
      </div>
    </div>
  );
};
export default TablesGroup;
