import React, { FC, ReactElement, useState } from "react";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { OptionalLabel } from "../OptionalLabel";
import DropdownInput from "./components/DropdownInput";
import DropdownList from "./components/DropdownList";
import Error from "./components/Error";
import styles from "./DropdownWithSearch.module.scss";
import { DropdownItem } from "../../../helpers/types";
import { newFilteredArray } from "../../../helpers/common/arrayHelpers";
import { classNames } from "../../../helpers/common/classNames";
import { mapStringToItemWithIcon } from "../../../helpers/mapStringToItemWithGlobaIcon";

type DropdownWithSearchProps = {
  selected: DropdownItem;
  valuesList: Array<string>;
  getValuesList?: (val: string) => Array<string>;
  additionalValuesList?: Array<string>;
  onChange: (arg: Array<string> | any) => void;
  placeholder: string;
  error: string;
  isMedium?: boolean;
  label?: string;
  createText?: string;
  onCreate?: () => void;
  isHideIcon?: boolean;
  disabled?: boolean;
  withClearIcon?: boolean;
  isRightCorner?: boolean;
  id: string;
  className?: string;
  emptyListPlaceholder?: string;
  icon?: ReactElement;
  isOptionalLabel?: boolean;
  handleFieldChange?: any;
  listClassName?: string;
  additionalIcon?: ReactElement;
  valuesWithLabel?: boolean;
  valuesLabel?: string;
  hideEmptyListPlaceholder?: boolean;
};

const DropdownWithSearch: FC<DropdownWithSearchProps> = ({
  selected,
  valuesList,
  additionalValuesList,
  getValuesList,
  onChange,
  placeholder,
  error,
  isMedium,
  label,
  createText,
  onCreate,
  isHideIcon,
  disabled,
  withClearIcon,
  isRightCorner,
  id,
  className,
  emptyListPlaceholder,
  icon,
  isOptionalLabel,
  listClassName,
  valuesWithLabel,
  valuesLabel,
  additionalIcon,
  hideEmptyListPlaceholder,
}) => {
  const [isListShown, setIsListShown] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string | undefined>(
    selected.key
  );
  const [filteredValues, setFilteredValues] = useState<Array<string>>(
    valuesList
  );
  const [filteredAdditionalValues, setFilteredAdditionalValues] = useState<
    Array<string> | undefined
  >(additionalValuesList);

  const handleClick = () => {
    setFilteredValues(valuesList);
    setIsListShown((prev) => !prev);
  };

  const handleChange = (
    eOrString: React.ChangeEvent<HTMLInputElement> | string
  ) => {
    const inputValue =
      typeof eOrString == "string" ? eOrString : eOrString.currentTarget.value;
    setFilteredValues(
      newFilteredArray(getValuesList?.(inputValue) || valuesList, inputValue)
    );
    if (additionalValuesList) {
      setFilteredAdditionalValues(
        newFilteredArray(additionalValuesList, inputValue)
      );
    }
    setIsListShown(true);
    setSearchInput(inputValue);
    onChange(inputValue);
  };

  const handleSelect = (value: { key: string }) => {
    setSearchInput("");
    onChange(value);
    setIsListShown(false);
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <OptionalLabel
        className={styles.dropDownLabel}
        isShown={Boolean(label)}
        text={label}
        isOptional={isOptionalLabel}
      >
        <div data-cy="input-wrapper" style={{ width: "100%" }}>
          <DropdownInput
            id={id}
            withClearIcon={withClearIcon}
            isAutocompleteField
            isMedium={isMedium}
            selected={selected}
            isListShown={isListShown}
            onClick={handleClick}
            onChange={handleChange}
            handleSelect={handleSelect}
            value={searchInput}
            placeholder={placeholder}
            isPlaceholderAlwaysVisible
            isHideIcon={isHideIcon}
            disabled={disabled}
            icon={icon}
          />
          {error && <Error error={error} />}
        </div>
      </OptionalLabel>
      {isListShown && (
        <DropdownList
          handleSelect={handleSelect}
          isListShown={isListShown}
          valuesList={filteredValues?.map(mapStringToItem) || []}
          isMedium={!label && isMedium}
          createText={createText}
          onCreate={onCreate}
          setIsListShown={setIsListShown}
          className={
            listClassName || classNames(label && isMedium && styles.extraMrg)
          }
          isRightCorner={isRightCorner}
          emptyListPlaceholder={emptyListPlaceholder}
          additionalValuesList={
            additionalIcon
              ? filteredAdditionalValues?.map(mapStringToItemWithIcon, {
                  icon: additionalIcon,
                })
              : filteredAdditionalValues?.map(mapStringToItem)
          }
          valuesWithLabel={valuesWithLabel}
          label={valuesLabel}
          hideEmptyListPlaceholder={hideEmptyListPlaceholder}
        />
      )}
    </div>
  );
};

export default DropdownWithSearch;
