import { ConnectionFields } from "../ConnectionStep/components/ConnectionsConfigurator/utils/formConsts";
import { NAT_RULE_BODY } from "./onboardingConsts";
import { VirtualInetrfaceGate } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import { NATRuleAdd } from "../../NATRulesPage/types";

export const getNatParams = (
  fields: ConnectionFields,
  segment: string,
  gateVi: VirtualInetrfaceGate,
  i: number
): Partial<NATRuleAdd> => {
  const params = NAT_RULE_BODY;
  params.name = fields.name + "_" + i;
  params.inside = segment;
  params.outside = gateVi.name;
  return params;
};
