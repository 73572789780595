import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function SourceSymbolIcon({
  color = GREY_FONT,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g opacity="0.6">
        <circle cx="3.5" cy="4.5" r="2.5" fill="#59536B" />
        <path
          d="M11 4.5H6M11 4.5L8 1.5M11 4.5L8 7.5"
          stroke={color}
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default SourceSymbolIcon;
