import { LabelOutlineTypes } from "./types";

export default function getLabelTypeFromValue(
  value: number | string
): LabelOutlineTypes {
  if (value <= 65 || value === "ALLOW") return "green";
  if (value === "DENY") return "pink";
  if (value >= 85) return "lavender";
  return "blue";
}
