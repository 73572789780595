import { FWRuleType } from "../../../helpers/api/apiTypes";
import {
  LIMIT_FW_PRIORITY_FOR_BLOCK_SECTION_START,
  MAX_FW_PRIORITY_FOR_MANUAL_CREATION,
} from "../../Firewall/config/FirewallRuleConfig";
import { SegmentRule } from "../components/SegmentRulesTable/types";
import { SegmentsRules } from "../SegmentsRulesContext";
import { predefinedRulesList } from "./consts";
import { convertFirewallToSegmentRule } from "./convertFirewallToSegmentRule";

export const getSegmentsRulesFromFirewall = (
  fwRules: Array<FWRuleType>
): SegmentsRules => {
  const allSegmentsRules = fwRules.map(convertFirewallToSegmentRule);
  const allowAll: Array<SegmentRule> = [];
  const predefined: Array<SegmentRule> = [];
  const custom: Array<SegmentRule> = [];
  const predefinedRulesListPriorities = predefinedRulesList.map(
    (el) => el.priority
  );
  allSegmentsRules.forEach((rule) => {
    // drop all rules
    if (rule.priority >= LIMIT_FW_PRIORITY_FOR_BLOCK_SECTION_START) {
      return;
    }
    // allow all rules
    if (rule.priority >= MAX_FW_PRIORITY_FOR_MANUAL_CREATION) {
      allowAll.push(rule);
      return;
    }
    //predefined or custom rules
    if (predefinedRulesListPriorities.includes(rule.priority)) {
      predefined.push(rule);
    } else {
      custom.push(rule);
    }
  });
  return { allowAll, predefined, custom };
};
