import AWSIcon from "../components/icons/AWSIcon";
import AzureIcon from "../components/icons/AzureIcon";
import BranchesIcon from "../components/icons/BranchesIcon";
import DataCentersIcon from "../components/icons/DataCentersIcon";
import GoogleCloudIcon from "../components/icons/GoogleCloudIcon";
import InternetIcon from "../components/icons/InternetIcon";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getSystemBoxValuesJSON() {
  return [
    // System 1
    [
      {
        name: "Google Cloud",
        value: "0/10",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "1/6",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "3/10",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "9/15",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "0/0",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "5/5",
        icon: InternetIcon,
      },
    ],
    // System 2
    [
      {
        name: "Google Cloud",
        value: "1/10",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "2/6",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "4/11",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "6/10",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "5/7",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "1/3",
        icon: InternetIcon,
      },
    ],
    // System 3
    [
      {
        name: "Google Cloud",
        value: "10/10",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "5/5",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "7/10",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "12/15",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "13/15",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "2/5",
        icon: InternetIcon,
      },
    ],
    // System 4
    [
      {
        name: "Google Cloud",
        value: "2/5",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "3/7",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "4/11",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "5/10",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "5/7",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "3/10",
        icon: InternetIcon,
      },
    ],
    // System 5
    [
      {
        name: "Google Cloud",
        value: "8/10",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "4/6",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "6/10",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "10/12",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "10/10",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "1/10",
        icon: InternetIcon,
      },
    ],
    // System 6
    [
      {
        name: "Google Cloud",
        value: "8/10",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "4/6",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "6/10",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "10/12",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "10/10",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "1/10",
        icon: InternetIcon,
      },
    ],
    // System 7
    [
      {
        name: "Google Cloud",
        value: "15/20",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "15/20",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "15/20",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "15/20",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "15/20",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "15/20",
        icon: InternetIcon,
      },
    ],
    // System 8
    [
      {
        name: "Google Cloud",
        value: "15/20",
        icon: GoogleCloudIcon,
      },
      {
        name: "Azure",
        value: "15/20",
        icon: AzureIcon,
      },
      {
        name: "AWS",
        value: "15/20",
        icon: AWSIcon,
      },
      {
        name: "Branches",
        value: "15/20",
        icon: BranchesIcon,
      },
      {
        name: "DC/CoLo/EDGE",
        value: "15/20",
        icon: DataCentersIcon,
      },
      {
        name: "Internet/GWs",
        value: "15/20",
        icon: InternetIcon,
      },
    ],
  ];
}
