import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { VirtualInetrfaceAllTypes } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import { RequestStatus } from "../../../helpers/types";
import { tenantVirtualInterfacesApi } from "../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = { list?: Array<VirtualInetrfaceAllTypes> };

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (tenantName: string) => void;
};

const [GateVIDropdownContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useGateVIDropdownContext = useContext;

export default class GateVIDropdownContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = GateVIDropdownContext;

  fetchList = async (tenantName: string): Promise<void> => {
    const { ok, result } = await this._fetchWithStatus(
      () => tenantVirtualInterfacesApi.getVirtualInterfacesAllTypes(tenantName),
      "listStatus"
    );
    this.setState({ list: ok ? result : undefined });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
