import { ReactElement } from "react";

type Props = {
  height?: string;
  width?: string;
};

function GoogleIcon(props?: Props): ReactElement {
  return (
    <svg
      width={props?.width || "22"}
      height={props?.height || "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.68 11.229C20.68 10.514 20.6158 9.8265 20.4967 9.1665H11V13.0715H16.4267C16.1883 14.3273 15.4733 15.3907 14.4008 16.1057V18.6448H17.6733C19.58 16.8848 20.68 14.2998 20.68 11.229Z"
        fill="#4285F4"
      />
      <path
        d="M11 21.0831C13.7225 21.0831 16.005 20.1848 17.6733 18.6448L14.4008 16.1056C13.5025 16.7106 12.3566 17.0773 11 17.0773C8.37829 17.0773 6.15079 15.3081 5.35329 12.9248H1.99829V15.5281C3.65746 18.819 7.05829 21.0831 11 21.0831Z"
        fill="#34A853"
      />
      <path
        d="M5.35342 12.916C5.15175 12.311 5.03258 11.6693 5.03258 11.0001C5.03258 10.331 5.15175 9.68929 5.35342 9.08429V6.48096H1.99841C1.31091 7.83762 0.916748 9.36846 0.916748 11.0001C0.916748 12.6318 1.31091 14.1626 1.99841 15.5193L4.61091 13.4843L5.35342 12.916Z"
        fill="#FBBC05"
      />
      <path
        d="M11 4.9315C12.485 4.9315 13.805 5.44484 14.8591 6.43484L17.7466 3.54734C15.9958 1.91567 13.7225 0.916504 11 0.916504C7.05829 0.916504 3.65746 3.18067 1.99829 6.48067L5.35329 9.084C6.15079 6.70067 8.37829 4.9315 11 4.9315Z"
        fill="#EA4335"
      />
    </svg>
  );
}

export default GoogleIcon;
