import { FC, useState } from "react";
import { useUserContext } from "../../../../contexts/UserContext";
import { useUserListContext } from "../../../../contexts/UserListContext";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { DropdownItem, User } from "../../../../helpers/types";
import validatedUserAdd from "../../../../helpers/validators/AddUserValidator";
import { useValidation } from "../../../../helpers/validators/Validator";
import { UserEditMode } from "../../types/user";
import { PasswordChangeDialog } from "./PasswordChangeDialog";

import styles from "./UserDialog.module.scss";
import DialogTemplate from "../../../../components/dialogs/common/DialogTemplate";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import Input from "../../../../components/common/formComponents/Input";
import DropdownBasic from "../../../../components/common/Dropdown/DropdownBasic";
import SummaryInfoBlock from "../../../../components/leftInfoBlock/SummaryInfoBlock";
import UnifiedTextPair from "../../../../components/common/UnifiedTextPair";
import DeleteButton from "../../../../components/common/buttons/DeleteButton";
import { Button } from "@mantine/core";
import UserIcon from "../../../../components/icons/UserIcon";
import { Checkbox } from "../../../../components/common/formComponents/Checkbox";
import { useWireGuardContext } from "../../../../components/common/WireGuardComponents/WireGuardContext";

type AddUserFormType = Pick<User, "password_is_expired"> &
  Pick<User, "is_remote_user"> &
  Partial<User>;

type Props = {
  onClose: () => void;
  onSubmit?: (data: AddUserFormType) => void;
  onAdd: (data: User) => void;
  onEdit: (data: Partial<User>) => void;
  onDelete?: (user: User) => void;
  onChange?: (field: string, value: any) => void;
  mode?: UserEditMode;
};

const ROLES = ["Admin", "Viewer", "Editor", "Remote User"];

const DEFAULT_FIELDS = {
  role: ROLES[0],
  is_remote_user: false,
  password_is_expired: true,
};

export const UserDialog: FC<Props> = ({
  onClose,
  onSubmit,
  onDelete,
  onAdd,
  onEdit,
  mode = "edit",
}) => {
  const { selectedUser, userRequest } = useUserListContext();
  const { user } = useUserContext();
  const { fetchStatus } = useWireGuardContext();
  const [passwordIsChanging, setPasswordIsChanging] = useState(false);
  const [fields, saveField] = useFormField<AddUserFormType>({
    ...DEFAULT_FIELDS,
    ...selectedUser,
  });

  const [errors, validate] = useValidation<Partial<AddUserFormType>>(
    validatedUserAdd,
    [fields]
  );

  const isUserProfile = (selectedUser as User)?.username === user.name;
  const inputIsVisible = !isUserProfile;

  const title = mode === "add" ? "Add" : "Edit";
  const getSelectedRole = (): DropdownItem<string> => {
    switch (fields.role?.toLowerCase()) {
      case ROLES[0].toLowerCase():
        return {
          key: ROLES[0],
        };
      case ROLES[1].toLowerCase():
        return {
          key: ROLES[1],
        };
      case ROLES[2].toLowerCase():
        return {
          key: ROLES[2],
        };
      case "remote_user":
        return {
          key: ROLES[3],
        };
      default:
        return {
          key: ROLES[0],
        };
    }
  };

  const submitForm = () => {
    const { isOk } = validate();

    if (!isOk) {
      return;
    }

    if (mode === "edit") {
      onEdit(fields);
    } else {
      onAdd(fields as User);
    }

    onSubmit?.(fields);
  };

  if (passwordIsChanging) {
    return (
      <PasswordChangeDialog
        onCancel={() => setPasswordIsChanging(false)}
        onSave={() => setPasswordIsChanging(false)}
      />
    );
  }

  return (
    <DialogTemplate
      controls={{
        okText: title,
        cancelText: "Cancel",
        onOk: submitForm,
        onCancel: onClose,
      }}
      errorDisplay={userRequest}
      onClose={onClose}
      leftColumn={
        <SummaryBlock
          showDelete={mode === "edit"}
          showChangePassword={isUserProfile}
          onPasswordChange={() => setPasswordIsChanging(true)}
          onDelete={onDelete}
        />
      } // todo: connect user
      title={title}
    >
      {userRequest.state === "pending" || fetchStatus?.state === "pending" ? (
        <div className={styles.dialogPending}>
          <LoaderIcon />
        </div>
      ) : (
        <div className={styles.form}>
          <div className={styles.column}>
            <Input
              name="username"
              label="Username"
              placeholder="enter the username"
              onChange={(e) => saveField("username", e.target.value)}
              medium
              value={fields.username}
              disabled={mode === "edit"}
              error={errors?.username}
            />
            {inputIsVisible && (
              <Input
                name="password"
                label="Password"
                placeholder="enter the password"
                onChange={(e) => saveField("password", e.target.value)}
                medium
                togglePasswordVisibility
                value={fields.password}
                type="password"
                error={errors?.password}
              />
            )}
            {inputIsVisible && (
              <DropdownBasic
                id={"userDialogRole"}
                onChange={(item) => {
                  if (item.key === "Remote User") {
                    saveField("role", "remote_user");
                    saveField("is_remote_user", true);
                  } else {
                    saveField("role", item.key.toLowerCase());
                  }
                }}
                itemsList={ROLES.map(mapStringToItem)}
                label="Role"
                isMedium
                selected={getSelectedRole()}
              />
            )}
            <Input
              name="email"
              label="Email"
              onChange={(e) => saveField("email", e.target.value)}
              medium
              value={fields.email}
              error={errors?.email}
            />
            {mode === "edit" && inputIsVisible && (
              <Input
                name="remaining_failed_attempts"
                label="Remaining failed attempts"
                handleFieldChange={() =>
                  saveField(
                    "remaining_failed_attempts",
                    fields.remaining_failed_attempts
                  )
                }
                medium
                type="number"
                value={fields.remaining_failed_attempts}
              />
            )}
            {isUserProfile && (
              <Input
                name="phone"
                label="Phone"
                handleFieldChange={() => saveField("phone", fields.phone)}
                medium
                isOptional
                value={fields.phone}
                error={errors?.phone}
              />
            )}
          </div>
          <div className={styles.column}>
            <Input
              name="first_name"
              label="First Name"
              handleFieldChange={saveField}
              medium
              value={fields.first_name}
              error={errors?.first_name}
            />
            <Input
              name="last_name"
              label="Last Name"
              handleFieldChange={saveField}
              medium
              value={fields.last_name}
              error={errors?.last_name}
            />
            {!isUserProfile && (
              <Input
                name="phone"
                label="Phone"
                handleFieldChange={saveField}
                medium
                isOptional
                value={fields.phone}
                error={errors?.phone}
              />
            )}
            <Input
              name="description"
              label="Description"
              handleFieldChange={saveField}
              medium
              isOptional
              value={fields.description}
              error={errors?.description}
            />
            <Checkbox
              isChecked={fields.role === "remote_user" || fields.is_remote_user}
              label="Remote user"
              onChange={() =>
                saveField("is_remote_user", !fields.is_remote_user)
              }
            />
          </div>
        </div>
      )}
    </DialogTemplate>
  );
};

type SummaryBlockProps = {
  showDelete: boolean;
  showChangePassword?: boolean;
  onPasswordChange: () => void;
  onDelete?: (user: User) => void;
};

const SummaryBlock: FC<SummaryBlockProps> = ({
  showDelete,
  showChangePassword = false,
  onPasswordChange,
  onDelete,
}) => {
  const { selectedUser } = useUserListContext();

  const handleDelete = () => {
    selectedUser && onDelete?.(selectedUser);
  };

  const showDeleteButton = showDelete && onDelete && !showChangePassword;

  return (
    <SummaryInfoBlock icon={<UserIcon />} title={"User"}>
      <UnifiedTextPair
        subtitle="Username"
        text={selectedUser?.username || "-"}
      />
      {!showChangePassword && (
        <UnifiedTextPair subtitle="Role" text={selectedUser?.role || "-"} />
      )}

      <div className={styles.bottomButton}>
        {showDeleteButton && (
          <DeleteButton id={"userDialog"} onClick={handleDelete} withLabel />
        )}
        {showChangePassword && (
          <Button
            className={styles.changePasswordBtn}
            onClick={onPasswordChange}
          >
            Change password
          </Button>
        )}
      </div>
    </SummaryInfoBlock>
  );
};
