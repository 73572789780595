import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DIAIcon({ color, width, height, x, y }: IconProps): ReactElement {
  return (
    <svg
      width={width || "17"}
      height={height || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        d="M7.99982 16C4.14023 16 1 12.8598 1 9.00017C1 5.14024 4.13989 2 7.99982 2C11.8598 2 15 5.14024 15 9.00017C15 12.8598 11.8598 16 7.99982 16ZM7.99982 2.69436C4.52283 2.69436 1.69436 5.52283 1.69436 9.00017C1.69436 12.4772 4.52283 15.3056 7.99982 15.3056C11.4772 15.3056 14.3056 12.4772 14.3056 9.00017C14.3056 5.52283 11.4768 2.69436 7.99982 2.69436Z"
        fill={color || FONT}
      />
      <path
        d="M8.34719 2.14453H7.65283V15.5005H8.34719V2.14453Z"
        fill={color || FONT}
      />
      <path
        d="M14.7964 8.65234H1.33691V9.3467H14.7964V8.65234Z"
        fill={color || FONT}
      />
      <path
        d="M7.99205 7.40028C4.47998 7.40028 3.07946 4.48085 3.02148 4.35621L3.65057 4.0625C3.65092 4.0625 3.65092 4.06285 3.65092 4.06319C3.70647 4.18054 4.93757 6.70558 7.9924 6.70558C11.0656 6.70558 12.287 4.11944 12.2988 4.0934L12.93 4.38295C12.8734 4.5062 11.5059 7.40028 7.99205 7.40028Z"
        fill={color || FONT}
      />
      <path
        d="M3.65057 13.9386L3.02148 13.6456C3.07946 13.5213 4.47998 10.6016 7.99205 10.6016C11.5059 10.6016 12.8734 13.4953 12.93 13.6182L12.2988 13.9077C12.2495 13.8015 11.051 11.2959 7.9924 11.2959C4.91778 11.2963 3.66307 13.9126 3.65057 13.9386Z"
        fill={color || FONT}
      />
      <path
        d="M7.62281 15.9571C7.49018 15.836 4.37842 12.9426 4.37842 9.00036C4.37842 5.05155 7.5617 2.26509 7.69745 2.14844L8.15087 2.67441C8.11997 2.70115 5.07312 5.37547 5.07312 9.00071C5.07312 12.6405 8.06129 15.4176 8.0915 15.4454L7.62281 15.9571Z"
        fill={color || FONT}
      />
      <path
        d="M8.37976 15.9575L7.91072 15.4454C7.94093 15.4176 10.9294 12.6319 10.9294 9.00071C10.9294 5.36713 7.88295 2.70115 7.85205 2.67476L8.30512 2.14844C8.44087 2.26509 11.6242 5.05155 11.6242 9.00036C11.6242 12.9433 8.51239 15.8367 8.37976 15.9575Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default DIAIcon;
