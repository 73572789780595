import { VirtualInetrfaceIpsecAdd } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";

export const getIpsecBody = (fields: any): VirtualInetrfaceIpsecAdd => {
  const body: VirtualInetrfaceIpsecAdd = {
    name: fields.name,
    remote_ip: "",
    remote_networks: [""],
    local_networks: [""],
    dh_groups: [+fields.phase1AgorithmDH],
    encryption: "aes256gcm",
    auth: "prfsha256",
    psk: fields.phase1PreSharedKey,
    passive: false,
    udp_encap: false,
    nat_traversal: true,
    local_id_type: "ipv4",
    local_id_data: "",
    remote_id_type: "ipv4",
    remote_id_data: "",
    description: "",
    administrative_state: fields.administrative_state ? "up" : "down",
    virtual_interface_gate_name: "",
    network_segments: fields.network_segments,
    labels: { remote_type: getRemoteType(fields) },
  };
  return body;
};

const getRemoteType = (fields: any): string => {
  if (fields.endPoint === "cloud") {
    return fields.cloudOperator;
  }
  return fields.endPoint;
};
