import React from "react";
import { IconProps } from "../../../helpers/types";
import { formIconAttrs } from "../../../helpers/iconsHelpers";

function PendingIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      {...formIconAttrs(16, 16, props)}
    >
      <path
        stroke={props.color || "var(--accent-orange-color)"}
        strokeLinecap="round"
        strokeWidth="1.4"
        d="M4 8a4 4 0 104-4"
      ></path>
    </svg>
  );
}

export default PendingIcon;
