import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DevPortalIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_20514_265341)">
        <mask
          id="mask0_20514_265341"
          maskUnits="userSpaceOnUse"
          x="4"
          y="1"
          width="20"
          height="22"
        >
          <rect
            x="4.80078"
            y="1.91309"
            width="18.24"
            height="21.0435"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask0_20514_265341)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8679 5.00623C19.4767 4.61641 18.8424 4.61641 18.4512 5.00623L8.60645 14.8153L12.2996 18.495L22.1443 8.68598C22.5355 8.29616 22.5355 7.66415 22.1443 7.27433L19.8679 5.00623ZM10.8828 19.9067L7.18966 16.2269L6.76604 16.649C6.63361 16.781 6.54095 16.9472 6.49859 17.129L5.80995 20.0833C5.64218 20.803 6.29009 21.4485 7.01243 21.2814L9.97742 20.5952C10.1598 20.553 10.3267 20.4607 10.4592 20.3288L10.8828 19.9067Z"
            fill={color || FONT}
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9561 3.15241L17.0775 2.27697C16.8795 2.07969 16.5701 2.0427 16.3226 2.19066L14.2189 3.44834C13.8105 3.23872 13.3898 3.0661 12.9566 2.93047L12.3626 0.468546C12.2884 0.197283 12.0409 0 11.7563 0H9.28125C8.99662 0 8.74913 0.197283 8.67488 0.468546L8.08088 2.93047C7.64775 3.0661 7.227 3.23872 6.81863 3.44834L4.71488 2.19066C4.46738 2.0427 4.158 2.07969 3.96 2.27697L2.20275 4.02785C2.00475 4.22514 1.96763 4.53339 2.11613 4.77999L3.37838 6.87612C3.168 7.28302 2.99475 7.70224 2.85863 8.1338L0.47025 8.73798C0.198 8.79963 0 9.04623 0 9.32982V11.7959C0 12.0794 0.198 12.3261 0.47025 12.3877L2.85863 12.9919C2.99475 13.4234 3.168 13.8427 3.37838 14.2496L2.11613 16.3457C1.96763 16.5923 2.00475 16.9005 2.20275 17.0978L3.08138 17.9733L8.33147 12.7422C7.7715 12.1843 7.425 11.4136 7.425 10.5628C7.425 8.86128 8.811 7.4803 10.5188 7.4803C11.3726 7.4803 12.1461 7.82554 12.706 8.38348L17.9561 3.15241Z"
          fill={color || FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_20514_265341">
          <rect width="24" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default DevPortalIcon;
