import { VRFType } from "./../api/apiTypes";
import Validator from "./Validator";

export default function validateVRF(
  data: Partial<VRFType>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  data.name && validator.checkLength(data.name, [1, 20], "name");
  data.description &&
    validator.checkLength(data.description, [0, 20], "description");

  return validator;
}
