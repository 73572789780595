import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import VLANIcon from "../../../components/icons/VLANIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { PortVlan } from "../types";

type SumProps = {
  title: string;
  data: Partial<PortVlan>;
};

const SummaryBlock: FC<SumProps> = ({ title, data }) => {
  return (
    <SummaryInfoBlock icon={<VLANIcon />} title={title}>
      <UnifiedTextPair subtitle="Name" text={data?.name} />
      <UnifiedTextPair
        subtitle="Virtual Interface"
        text={data.virtual_interface_name}
      />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
