import React, { FC } from "react";
import SummaryInfoBlock from "../../../../components/leftInfoBlock/SummaryInfoBlock";
import UnifiedTextPair from "../../../../components/common/UnifiedTextPair";
import IdentityProviderIcon from "../../../../components/icons/IdentityProviderIcon";
import { CreateIdentity } from "./IdentityDialog";
import styles from "./IdentityInfo.module.scss";

const IdentityInfo: FC<CreateIdentity> = (props: CreateIdentity) => {
  return (
    <SummaryInfoBlock icon={<IdentityProviderIcon />} title="Identity Provider">
      {props.provider !== "" && (
        <ProviderSection
          name={props.provider}
          client={props.provider_client_id}
        />
      )}
    </SummaryInfoBlock>
  );
};
export default IdentityInfo;

type InfoProps = {
  name: string;
  client: string;
};

const EMPTY_SECTION_VALUE = "-";

const ProviderSection = ({ name, client }: InfoProps) => {
  return (
    <div className={styles.wrapper}>
      <UnifiedTextPair
        subtitle="Identity provider"
        text={name}
        className={styles.first}
      />
      <UnifiedTextPair
        subtitle="Client ID"
        text={client || EMPTY_SECTION_VALUE}
      />
    </div>
  );
};
