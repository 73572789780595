import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function UserIconBig({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g id="Group 2379">
        <circle id="Ellipse 403" cx="19" cy="19" r="19" fill={color || FONT} />
        <mask
          id="mask0_0_1"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="38"
          height="38"
        >
          <circle
            id="Ellipse 404"
            cx="19"
            cy="19"
            r="19"
            fill={color || FONT}
          />
        </mask>
        <g mask="url(#mask0_0_1)">
          <g id="Group">
            <path
              id="Vector"
              d="M18.7323 23.8335C22.1045 23.8335 24.8385 20.5129 24.8385 16.4168C24.8385 12.3206 23.9409 9 18.7323 9C13.5238 9 12.626 12.3206 12.626 16.4168C12.626 20.5129 15.3599 23.8335 18.7323 23.8335Z"
              fill="white"
            />
            <path
              id="Vector_2"
              d="M7.19944 35.1596C7.1984 34.9098 7.19736 35.0892 7.19944 35.1596V35.1596Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M30.2666 35.3519C30.2699 35.2835 30.2677 34.8775 30.2666 35.3519V35.3519Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M30.2535 36.0106C30.1404 28.875 29.2085 26.8417 22.0772 25.5547C22.0772 25.5547 21.0733 26.8338 18.7336 26.8338C16.3938 26.8338 15.3898 25.5547 15.3898 25.5547C8.33628 26.8277 7.34769 28.8308 7.2177 35.7788C7.20704 36.3461 7.2021 36.3759 7.2002 36.3101C7.20063 36.4335 7.20115 36.6617 7.20115 37.0597C7.20115 37.0597 8.89895 40.4824 18.7336 40.4824C28.568 40.4824 30.266 37.0597 30.266 37.0597C30.266 36.804 30.2662 36.6262 30.2664 36.5052C30.2645 36.5459 30.2607 36.467 30.2535 36.0106Z"
              fill="white"
            />
          </g>
          <circle
            id="Ellipse 405"
            cx="19"
            cy="19"
            r="17.5"
            stroke={color || FONT}
            strokeWidth="3"
          />
        </g>
      </g>
    </svg>
  );
}

export default UserIconBig;
