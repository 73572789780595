import {
  GRAFANA_ROOT_PATH,
  GTimeRange,
  createUnifiedTenantFilter,
} from "../../../components/common/charts/GraphanaLinksFactory";
import { getMsFromDate } from "../../../components/common/charts/GraphanaTableLinks";
import { getFiltersParams } from "../../ApplicationsDashboard/common/appStatisticsHelper";
import { DNS_FIELD_VALUE } from "../consts";
import { FiltersFields } from "../types";

export const getDNSLink = (
  panelId: number,
  timeRange: GTimeRange,
  filters?: FiltersFields,
  tenant?: string
): string => {
  const address = GRAFANA_ROOT_PATH + "/d-solo/OrkAaF04z/dns";
  const orgId = "orgId=1";
  const theme = "theme=light";
  const { from, to } = getMsFromDate(timeRange);
  const dateRange = `from=${from}&to=${to}`;
  const refresh = "refresh=30s";
  const tenantFilter = createUnifiedTenantFilter(tenant);
  const filtersParams = filters
    ? getFiltersParams(DNS_FIELD_VALUE, filters)
    : "";
  const link = `${address}?${orgId}&${tenantFilter}&${filtersParams}&${dateRange}&panelId=${panelId}&${theme}&${refresh}`;
  return link.replace("&&", "&");
};

export const getDNSThreatsLink = (
  panelId: number,
  timeRange: GTimeRange,
  filters?: FiltersFields,
  tenant?: string
): string => {
  const address = GRAFANA_ROOT_PATH + "/d-solo/RoU1UkmVz/dns-threats";
  const orgId = "orgId=1";
  const theme = "theme=light";
  const { from, to } = getMsFromDate(timeRange);
  const dateRange = `from=${from}&to=${to}`;
  const refresh = "refresh=30s";
  const tenantFilter = createUnifiedTenantFilter(tenant);
  const filtersParams = filters
    ? getFiltersParams(DNS_FIELD_VALUE, filters)
    : "";
  const link = `${address}?${orgId}&${tenantFilter}&${filtersParams}&${dateRange}&panelId=${panelId}&${theme}&${refresh}`;
  return link.replace("&&", "&");
};

export const getAdvancedThreatsLink = (
  panelId: number,
  timeRange: GTimeRange,
  filters?: FiltersFields,
  tenant?: string
): string => {
  const address = GRAFANA_ROOT_PATH + "/d-solo/RoU1UkmVz/ids";
  const orgId = "orgId=1";
  const theme = "theme=light";
  const { from, to } = getMsFromDate(timeRange);
  const dateRange = `from=${from}&to=${to}`;
  const refresh = "refresh=30s";
  const tenantFilter = createUnifiedTenantFilter(tenant);
  const filtersParams = filters
    ? getFiltersParams(DNS_FIELD_VALUE, filters)
    : "";
  const link = `${address}?${orgId}&${tenantFilter}&${filtersParams}&${dateRange}&panelId=${panelId}&${theme}&${refresh}`;
  return link.replace("&&", "&");
};
