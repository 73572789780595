import React, { FC, useCallback } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import { useValidation } from "../../../helpers/validators/Validator";
import { NewDialogProps } from "../../../helpers/types";
import VirtualInterfaceDialogContextContainer, {
  useVirtualInterfaceDialogContext,
} from "./VirtualInterfaceDialogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import { VirtualInterface, VirtualInterfaceUpdateIP } from "../types";
import FieldsGroup from "../../../components/common/FieldsGroup";
import { validateVirtInterfaceIPs } from "../../../helpers/validators/VirtInterfaceValidator";
import useFieldGroup from "../../../helpers/hooks/useFieldGroup";
import { isSame } from "../../../helpers/common/submitHelper";
import { getMissedIPs, getUniqueIPs } from "../utils/getUniqueIPs";
import styles from "./VirtualInterfaceEditDialog.module.scss";

const getDefaultFields = (
  data?: VirtualInterface
): VirtualInterfaceUpdateIP => ({
  add_ip_addresses: data ? [...data?.ip_addresses] : [],
  delete_ip_addresses: [],
});

const VirtualInterfaceAddDialog: FC<
  NewDialogProps<VirtualInterface> & { vrf: string }
> = ({ onClose, data, vrf }) => {
  const { editIP, editStatus } = useVirtualInterfaceDialogContext();

  const [fields, handleFieldChange] = useFormField<VirtualInterfaceUpdateIP>(
    getDefaultFields(data)
  );
  const [errors, validate] = useValidation<VirtualInterfaceUpdateIP>(
    validateVirtInterfaceIPs,
    [fields]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && data) {
      const formIps = fields.add_ip_addresses.filter((ip) => ip);
      const prevIps = data.ip_addresses;

      const params = {
        add_ip_addresses: getUniqueIPs(prevIps, formIps),
        delete_ip_addresses: getMissedIPs(prevIps, formIps),
      };
      const success = await editIP(params, data, vrf);
      if (success) onClose();
    }
  };

  const handleFieldsGroup = useCallback(
    (i: number, name: string, value: string | number) => {
      const [key, fieldGroup] = useFieldGroup(i, name, value, fields);
      handleFieldChange(key, fieldGroup);
    },
    [handleFieldChange]
  );

  const disableSubmit = isSame(data, fields);

  const controls = getDialogControls({
    dialogTitle: "save",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
    disableSubmit: disableSubmit,
  });

  const leftColumn = (
    <SummaryBlock title={"Virtual Interface"} data={data} vrf={vrf} />
  );

  return (
    <DialogTemplate
      title={"Edit Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <FieldsGroup
        name="add_ip_addresses"
        placeholder="X.X.X.X/X or X::X/X"
        fields={fields?.add_ip_addresses || []}
        title="IP addresses"
        onChange={handleFieldsGroup}
        errors={errors}
        isOptional
        medium
      />
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<VirtualInterface> & { vrf: string }>(
  VirtualInterfaceAddDialog,
  [VirtualInterfaceDialogContextContainer]
);
