import React, { FC, useEffect, useState } from "react";
import BGPContext, {
  useBGPContext,
} from "../../../contexts/systemsContext/BGPContext";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import { useVRFSelectorContext } from "../../../contexts/systemsContext/VRFSelectorContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { withLayout } from "../../../helpers/hocs/withLayout";
import useRequestInterval from "../../../helpers/hooks/useRequestInterval";
import { PageProps } from "../../../helpers/types";
import BGPAutonomousSystem from "../../common/bgpComponents/BGPAutonomousSystem";
import Button from "../../common/buttons/Button";
import { OneSystemTabs } from "../../common/navigation/OneSystemTabs";
import TableWrapper from "../../common/table/newTable/layout/TableWrapper";
import TableHeader from "../../common/table/newTable/rows/TableHeader";
import Table from "../../common/table/newTable/Table";
import { bgpRoutesTableHeader } from "../../common/table/TableHeaders/Systems/bgpRoutesTableHeader";
import BGPDialog from "../../dialogs/BGPDialog";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import styles from "./BGPPage.module.scss";

const BGPPage: FC = () => {
  const { bgpAS, bgpNeighbors, fetchStatus, statusStatus } = useBGPContext();
  const { selectedVRF } = useVRFSelectorContext();
  const { node } = useOneSystemContext();

  const [showConfig, setShowConfig] = useState<boolean>(false);
  const [updateConfig, setUpdateConfig] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const nodeName = node?.name || "";

  useEffect(() => {
    if (selectedVRF) {
      fetchStatus(selectedVRF, nodeName, isFirstLoad);
      setIsFirstLoad(false);
    }
  }, [selectedVRF]);

  useRequestInterval(() => {
    if (selectedVRF) fetchStatus(selectedVRF, nodeName);
  }, 5000);

  const header = <BGPAutonomousSystem data={bgpAS} isIconShown={false} />;

  const controls = (
    <div className={styles.btns}>
      <Button onClick={() => setUpdateConfig(true)}>Update Config</Button>
      <Button onClick={() => setShowConfig(true)} isPrimaryBtn>
        Show Config
      </Button>
    </div>
  );

  return (
    <>
      {showConfig && (
        <BGPDialog onClose={() => setShowConfig(false)} type={"add"} />
      )}
      {updateConfig && (
        <BGPDialog onClose={() => setUpdateConfig(false)} type={"edit"} />
      )}
      <OneSystemLayout>
        <div className={styles.contentWrapper}>
          <OneSystemTabs />
          <TableWrapper
            titleProps={{
              title: header,
              children: controls,
            }}
            dataStatus={statusStatus}
            tablePlaceholder={["Neighbors", "Neighbor"]}
            isEmpty={bgpNeighbors && bgpNeighbors.length === 0}
          >
            <Table
              columns={bgpRoutesTableHeader()}
              header={TableHeader}
              data={bgpNeighbors || []}
              gridColumnTemplate={`repeat(6, 0.5fr) 1fr`}
            />
          </TableWrapper>
        </div>
      </OneSystemLayout>
    </>
  );
};

export default withLayout<PageProps>(withContexts(BGPPage, [BGPContext]));
