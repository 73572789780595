import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectivityTab } from "../../../components/common/navigation/Network/ConnectivityTab";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import {
  CLOUD_CONNECTIVITY,
  CONNECTIONS,
  SDWAN,
} from "../../../helpers/navigation/entries";
import CloudsConnectivityPage from "../../../pages/CloudsConnectivityPage";
import { Routes } from "../../../pages/common";
import Connections from "../../../pages/Network/Connections";
import { RouteEntry } from "../../../pages/types";
import SDWANProvidersPage from "../../../pages/SDWANProvidersPage";

const connectivityRoutes: Array<RouteEntry> = [
  { pathFunc: CONNECTIONS, component: Connections },
  { pathFunc: CLOUD_CONNECTIVITY, component: CloudsConnectivityPage },
  { pathFunc: SDWAN, component: SDWANProvidersPage },
];

export default function Connectivity(): ReactElement {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(CONNECTIONS(), path)} />
      </Route>
      <Routes
        routes={connectivityRoutes}
        tabs={ConnectivityTab}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
}
