import UserIcon from "../../../../components/icons/UserIcon";
import DDoSIcon from "../../../../components/icons/zayo/services/DDoSIcon";
import FWaaSIcon from "../../../../components/icons/zayo/services/FWaaSIcon";
import IDSIPSIcon from "../../../../components/icons/zayo/services/IDSIPSIcon";
import LBIcon from "../../../../components/icons/zayo/services/LBIcon";
import NATIcon from "../../../../components/icons/zayo/services/NATIcon";
import SWGIcon from "../../../../components/icons/zayo/services/SWGIcon";
import { generateRandomPassword } from "../../../../helpers/generateRandomPassword";
import { DropdownItem } from "../../../../helpers/types";
import { ConnectionAdd, ConnectionEdit } from "../types";
import { FieldsType } from "./types";

export const CUSTOMER_LOCATIONS = "customerLocation";
export const CLOUD_CONNECTIONS = "cloudConnection";
export const INTERNET_CONNECTIONS = "internetConnection";
export const REMOTE_USERS = "remoteUsers";
export const ELAN = "elan";
export const CLOUD_ROUTER = "cloudRouter";
export const IPSEC_SDWAN = "ipsec_sdwan";
export const DIA = "dia";
export const DATA_CENTER_PORT = "dataCenterPort";

export const A_PORT_MENU: Array<DropdownItem> = [
  {
    key: CUSTOMER_LOCATIONS,
    value: "Customer Location",
  },
  { key: DATA_CENTER_PORT, value: "Data Center Port" },
  { key: CLOUD_CONNECTIONS, value: "Cloud Connection" },
];

export const Z_PORT_MENU: Array<DropdownItem> = [
  { key: CUSTOMER_LOCATIONS, value: "Customer Location" },
  { key: DATA_CENTER_PORT, value: "Data Center Port" },
  { key: CLOUD_CONNECTIONS, value: "Cloud Connection" },
  { key: DIA, value: "DIA" },
];

export const Z_PORT_CUST_LOCATION_MENU: Array<DropdownItem> = [
  { key: CUSTOMER_LOCATIONS, value: "Customer Location" },
  { key: CLOUD_CONNECTIONS, value: "Cloud Connection" },
  { key: ELAN, value: "ELAN" },
  { key: CLOUD_ROUTER, value: "Cloud Router" },
  { key: DIA, value: "DIA" },
];

export const Z_PORT_CLOUD_ROUTER_MENU: Array<DropdownItem> = [
  { key: CUSTOMER_LOCATIONS, value: "Customer Location" },
  { key: INTERNET_CONNECTIONS, value: "Internet Connection" },
  { key: REMOTE_USERS, value: "Remote users" },
  { key: ELAN, value: "ELAN" },
  { key: CLOUD_ROUTER, value: "Cloud Router" },
  { key: IPSEC_SDWAN, value: "IPSEC / SDWAN to branches" },
];

export const SERVICES_MAP = [
  {
    icon: <FWaaSIcon />,
    title: "FWaaS",
    id: "firewall",
    isChecked: false,
    withoutCloudRouter: true,
  },
  {
    icon: <SWGIcon />,
    title: "App observability",
    id: "app_observability",
    isChecked: false,
    withoutCloudRouter: true,
  },
  // {
  //   icon: <StatusIcon />,
  //   title: "FQDN Filtering",
  //   id: "fqdn_filtering",
  //   isChecked: false,
  //   withoutCloudRouter: true,
  // },
  // {
  //   icon: <StatusIcon />,
  //   title: "Network observability",
  //   id: "network_observability",
  //   isChecked: false,
  //   withoutCloudRouter: true,
  // },
  {
    icon: <IDSIPSIcon />,
    title: "Cyber Threats",
    id: "cyber_threats",
    isChecked: false,
    withoutCloudRouter: true,
  },
  { icon: <NATIcon />, title: "NAT 1:1", id: "nat", isChecked: false },
  {
    icon: <LBIcon />,
    title: "Load Balancing",
    id: "load_balancing",
    isChecked: false,
  },
  {
    icon: <UserIcon />,
    title: "Remote users",
    id: "remote_users",
    isChecked: false,
    isDIA: true,
  },
  {
    icon: <DDoSIcon />,
    title: "DDOS",
    id: "ddos",
    isChecked: false,
    isDIA: true,
    withoutCloudRouter: true,
  },
  // {
  //   icon: <StatusIcon />,
  //   title: "Network Segmentation",
  //   id: "network_segmentation",
  //   isChecked: false,
  // },
];

export const UNTAGGED_VLAN_GROUP = [
  { value: "untagged", label: "Untagged" },
  { value: "vlan", label: "VLAN" },
];

export const CONNECTION_TYPE_GROUP = [
  { value: "ipsec", label: "IPSEC", disabled: true },
  { value: "directConnect", label: "Direct Connect" },
];

export const CLOUD_TYPE_GROUP = [
  { value: "aws", label: "AWS" },
  { value: "azure", label: "Azure" },
  { value: "gcp", label: "GCP", disabled: true },
  { value: "salesforce", label: "Salesforce", disabled: true },
  { value: "ibmcloud", label: "IBM Cloud", disabled: true },
  { value: "oracle", label: "Oracle", disabled: true },
];

export const INTERNET_CONNECTION_TYPE_GROUP = [
  { value: "dia", label: "DIA" },
  { value: "internetGW", label: "Internet GW" },
];

export const PHYSICAL_PORT_IDS = ["1", "2", "3", "4"];

export const A_PORT_FIELDS = "aPortFields";
export const Z_PORT_FIELDS = "zPortFields";

export const A_PORT_DEFAULTS_FIELDS: {
  [key: string]: { [key: string]: any };
} = {
  [CUSTOMER_LOCATIONS]: {
    location: undefined,
    BW: undefined,
    Tagged: false,
    innerType: CUSTOMER_LOCATIONS,
    Phisical_Port: undefined,
    VLAN_ID: undefined,
  },
  [CLOUD_CONNECTIONS]: {
    cloudType: "aws",
    Connection_type: "directConnect",
    innerType: CLOUD_CONNECTIONS,
    location: undefined,
    BW: undefined,
    Customer_ID: undefined,
  },
  [DATA_CENTER_PORT]: {
    innerType: DATA_CENTER_PORT,
    location: undefined,
    BW: undefined,
  },
};

export const CONNECTIONS_DEFAULTS_FIELDS: {
  [key: string]: FieldsType;
} = {
  [CUSTOMER_LOCATIONS]: {
    Connection_type: "directConnect",
    innerType: CUSTOMER_LOCATIONS,
    location: undefined,
    BW: undefined,
    Tagged: false,
    Phisical_Port: undefined,
    VLAN_ID: undefined,
    peer_ip: undefined,
    psk: generateRandomPassword(),
  },
  [DATA_CENTER_PORT]: {
    innerType: DATA_CENTER_PORT,
    location: undefined,
    BW: undefined,
  },
  [CLOUD_CONNECTIONS]: {
    cloudType: "aws",
    Connection_type: "directConnect",
    innerType: CLOUD_CONNECTIONS,
    location: undefined,
    BW: undefined,
    Customer_ID: undefined,
  },
  [DIA]: {
    innerType: DIA,
    BW: undefined,
    Subnet: undefined,
    GW: undefined,
  },
};

export const INITIAL_CONNECTIONS_FIELDS = {
  name: "",
  connections: [
    CONNECTIONS_DEFAULTS_FIELDS[CUSTOMER_LOCATIONS],
    CONNECTIONS_DEFAULTS_FIELDS[CLOUD_CONNECTIONS],
  ],
};

export const Z_PORT_DEFAULTS_FIELDS: {
  [key: string]: FieldsType;
} = {
  [CUSTOMER_LOCATIONS]: {
    location: undefined,
    BW: undefined,
    Tagged: false,
    innerType: CUSTOMER_LOCATIONS,
    Phisical_Port: undefined,
    VLAN_ID: undefined,
  },
  [DATA_CENTER_PORT]: {
    innerType: DATA_CENTER_PORT,
    location: undefined,
    BW: undefined,
  },
  [CLOUD_CONNECTIONS]: {
    cloudType: "aws",
    Connection_type: "directConnect",
    innerType: CLOUD_CONNECTIONS,
    location: undefined,
    BW: undefined,
    Customer_ID: undefined,
  },
  [DIA]: {
    innerType: DIA,
    BW: undefined,
    Subnet: undefined,
    GW: undefined,
  },
};

export const INITIAL_Z_PORT_FIELDS = {
  connections: [Z_PORT_DEFAULTS_FIELDS[CLOUD_CONNECTIONS]],
};

export const Z_PORT_CONNECTION_TYPES_MAP = [
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  CLOUD_CONNECTIONS,
  DIA,
];

export const CONNECTION_TYPES_TITLES: { [key: string]: string } = {
  [ELAN]: "ELAN",
  [CLOUD_ROUTER]: "Cloud Router",
};

export const CONNECTION_TYPES_NAMES: { [key: string]: string } = {
  [ELAN]: "ELAN",
  [CLOUD_ROUTER]: "Cloud_Router",
};

export const BASIC_BODY: ConnectionAdd = {
  name: "",
  a_endpoint_name: "",
  a_endpoint_conf: {},
  z_endpoint_name: "",
  z_endpoint_conf: {},
  description: "",
  services: {},
  labels: {},
};

export const BASIC_BODY_UPDATED: ConnectionEdit = {
  a_endpoint_conf: {},
  z_endpoint_conf: {},
  description: "",
  services: {},
  labels: {},
};

export const PORT_TYPES_MAP: Array<DropdownItem> = [
  { value: '"A" to "Z"', key: "azPorts" },
  { value: "Cloud Router", key: "cloudRouter" },
  { value: "ELAN", key: "elan" },
];
