import { FilterAndSort } from "../api/apiTypes";

export default function filterAndSort<T extends object>(
  arr: Array<T>,
  params: FilterAndSort = {}
): Array<T> {
  // sort
  let filtered = [...arr];
  if (params.filter) {
    filtered = arr.filter((r) => {
      const index = Object.entries(params.filter?.fields || {}).findIndex(
        ([key, value]) => {
          const entryVal = (r as { [key: string]: any })[key] || "";
          return !entryVal.includes(value);
        }
      );
      return index === -1;
    });
  }
  if (params.sort) {
    const { id, desc } = params.sort;
    return filtered.sort((a, b) => {
      const a1 = (a as { [key: string]: any })[id] as string;
      const b1 = (b as { [key: string]: any })[id] as string;
      return (desc ? -1 : 1) * a1.localeCompare(b1);
    });
  }

  return filtered;
}
