import {
  ConnectionStatisticsType,
  FWRulesHitCountsStatistics,
  RulesTrafficStatistics,
  InterfaceStatProps,
  PaginateListResponse,
  StatisticsType,
  TrafficStatisticsType,
} from "./apiTypes";
import Api, { Method } from "./Api";
import { StatsOptions, StatsParams } from "../types";
import { REPORT } from "./apiPaths";

type Res<P> = { ok: boolean; error?: string; result?: P };

function formStatsParams(options: StatsOptions) {
  const { range, count } = options;
  return Api.getParamsString({
    from_timestamp: range?.from,
    to_timestamp: range?.to,
    count,
  });
}
// const paramsLast5Min = () => {
//   // 10sec shift
//   const to = new Date(Date.now() - 10 * 1000).toISOString();
//   const from = new Date(Date.now() - 15 * 1000).toISOString();
//   return `?from_timestamp=${encodeURIComponent(
//     from
//   )}&to_timestamp=${encodeURIComponent(to)}`;
// };

export class ReportApi extends Api {
  private _reportPath = `report`;
  constructor() {
    super();
  }

  private reportPath(path: string): string {
    return this.brokerApi(`${this._reportPath}/${path}`);
  }

  private systemReport(path?: string): string {
    return this.reportPath(`statistics/system/${path}`);
  }
  private tenantReport(path?: string): string {
    return this.reportPath(`statistics/tenant/${path}`);
  }

  async getSystemStatistics(
    sysID: string,
    statType: StatisticsType,
    from?: string,
    to?: string
  ): Promise<
    Res<PaginateListResponse<ConnectionStatisticsType | TrafficStatisticsType>>
  > {
    const generatedPath = `${sysID}/${statType}`;
    return await this.fetch(
      this.systemReport(
        from
          ? `${generatedPath}?from_timestamp=${from}&to_timestamp=${to}`
          : generatedPath
      ),
      {
        method: Method.GET,
      }
    );
  }

  async getInterfaceStatistics(
    { sysID, interfaceType, interfaceId, statType }: InterfaceStatProps,
    from?: string,
    to?: string,
    count?: number
  ): Promise<Res<PaginateListResponse<TrafficStatisticsType>>> {
    const generatedPath = `${sysID}/${interfaceType}/${interfaceId}/${statType}`;
    return await this.fetch(
      this.systemReport(
        from && to
          ? `${generatedPath}?from_timestamp=${from}&to_timestamp=${to}&count=${count}`
          : generatedPath
      ),
      {
        method: Method.GET,
      }
    );
  }

  async getTenantStatistics(
    tenantName: string,
    statType: StatisticsType,
    from?: string,
    to?: string,
    count?: number
  ): Promise<
    Res<PaginateListResponse<ConnectionStatisticsType | TrafficStatisticsType>>
  > {
    const generatedPath = `${tenantName}/${statType}`;

    return await this.fetch(
      this.tenantReport(
        from && to
          ? `${generatedPath}?from_timestamp=${from}&to_timestamp=${to}&count=${count}`
          : generatedPath
      ),
      {
        method: Method.GET,
      }
    );
  }

  async getFWRulesHitCounts(
    tenantName: string
  ): Promise<Res<FWRulesHitCountsStatistics>> {
    return await this.fetch(this.tenantReport(`${tenantName}/fw/hits`));
  }

  async getFWRulesTraffic(
    params: StatsParams,
    options: StatsOptions
  ): Promise<Res<RulesTrafficStatistics>> {
    const { type } = options;
    const getParams = formStatsParams(options);
    return await this.fetchBroker(
      REPORT.FW_RULES(params.tenant, type, getParams)
    );
  }

  async getFWRulesConnections(
    params: StatsParams,
    options: StatsOptions
  ): Promise<Res<RulesTrafficStatistics>> {
    const getParams = formStatsParams(options);
    return await this.fetchBroker(
      REPORT.FW_RULES(params.tenant, "connections", getParams)
    );
  }

  async getNatRulesTraffic(
    tenantName: string,
    statType: StatisticsType,
    from?: string,
    to?: string,
    count?: number
  ): Promise<Res<RulesTrafficStatistics>> {
    const generatedPath = `${tenantName}/nat/${statType}`;
    return await this.fetch(
      this.tenantReport(
        from && to
          ? `${generatedPath}?from_timestamp=${from}&to_timestamp=${to}&count=${count}`
          : generatedPath
      ),
      {
        method: Method.GET,
      }
    );
  }
}

export const reportApi = new ReportApi();
