import React, { FC } from "react";
import ElementWithTitle from "../ElementWithTitle";
import DatePickerComponent from "../formComponents/DatePickerComponent";
import styles from "./FromToComponent.module.scss";

type Props = {
  to: Date | null;
  from: Date | null;
  setField: (value: Date | null, type: "from" | "to") => void;
};
const FromToComponent: FC<Props> = ({ to, from, setField }) => {
  return (
    <div className={styles.timeRange}>
      <div className={styles.subTitle}>or use an absolute time range</div>
      <ElementWithTitle title="from" className={styles.customDate}>
        <DatePickerComponent
          className={styles.dataPicker}
          placeholderText={"dd/mm/yy"}
          dateFormat={"dd/MM/yy"}
          date={from}
          setDate={(val) => setField(val, "from")}
          isMedium
        />
        <DatePickerComponent
          className={styles.dataPicker}
          placeholderText={"00:00:00"}
          dateFormat="hh:mm:ss"
          date={from}
          setDate={(val) => setField(val, "from")}
          isMedium
          showTimeSelectOnly
          showTimeSelect
        />
      </ElementWithTitle>
      <ElementWithTitle title="to" className={styles.customDate}>
        <DatePickerComponent
          className={styles.dataPicker}
          placeholderText={"dd/mm/yy"}
          dateFormat={"dd/MM/yy"}
          date={to}
          setDate={(val) => setField(val, "to")}
          minDate={from}
          isMedium
        />
        <DatePickerComponent
          className={styles.dataPicker}
          placeholderText={"00:00:00"}
          dateFormat="hh:mm:ss"
          date={to}
          setDate={(val) => setField(val, "to")}
          isMedium
          showTimeSelectOnly
          showTimeSelect
        />
      </ElementWithTitle>
    </div>
  );
};
export default FromToComponent;
