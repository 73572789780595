import React from "react";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { IconProps } from "../../../helpers/types";
import { GREY_FONT } from "../../../helpers/common/colorAliases";

function IOTIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...formIconAttrs(24, 24, props)}
    >
      <rect
        x="3"
        y="15"
        width="6"
        height="3"
        rx="1.5"
        transform="rotate(-90 3 15)"
        fill={GREY_FONT}
      />
      <rect
        x="8"
        y="18"
        width="12"
        height="3"
        rx="1.5"
        transform="rotate(-90 8 18)"
        fill={GREY_FONT}
      />
      <rect
        x="13"
        y="21"
        width="18"
        height="3"
        rx="1.5"
        transform="rotate(-90 13 21)"
        fill={GREY_FONT}
      />
      <rect
        x="18"
        y="15"
        width="6"
        height="3"
        rx="1.5"
        transform="rotate(-90 18 15)"
        fill={GREY_FONT}
      />
    </svg>
  );
}

export default IOTIcon;
