import React, { FC } from "react";

type Props = { title: string; value: string | number; x: number; y: number };

const BWLabel: FC<Props> = ({ x, y, title, value }) => {
  return (
    <svg
      width="73"
      height="18"
      viewBox="0 0 73 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x - 73 / 2}
      y={y - 18 / 2}
    >
      <rect
        x="0.501953"
        y="0.5"
        width="71"
        height="17"
        rx="3.5"
        fill="white"
        stroke="#BEB8EA"
      />
      <text
        x={73 / 2}
        y={14}
        textAnchor="middle"
        fill="#BEB8EA"
        fontSize={12}
        fontWeight={600}
      >
        <tspan>{title} </tspan>
        <tspan fill="var(--font)">{value}</tspan>
      </text>
    </svg>
  );
};
export default BWLabel;
