import { CardElement } from "../../../../components/common/Card/cardTypes";
import LocationIcon from "../../../../components/icons/LocationIcon";
import StatusIcon from "../../../../components/icons/StatusIcon";
import DestinationIcon from "../../../../components/icons/topologyButtons/DestinationIcon";
import SegmentsIcon from "../../../../components/icons/topologyButtons/SegmentsIcon";
import SourceIcon from "../../../../components/icons/topologyButtons/SourceIcon";
import { PURPLE } from "../../../../helpers/common/colorAliases";

export const LOCATIONS_BTN = "Locations";
export const SEGMENTS_BTN = "Segments";
export const HOSTS_BTN = "Hosts";
export const DESTINATIONS_BTN = "Destinations";
export const TRAFFIC_BTN = "Traffic";

export const APP_BTN_MAP: Array<CardElement> = [
  {
    icon: <LocationIcon color={PURPLE} />,
    title: LOCATIONS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SegmentsIcon />,
    title: SEGMENTS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SourceIcon />,
    title: HOSTS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <DestinationIcon />,
    title: DESTINATIONS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <StatusIcon color={PURPLE} />,
    title: TRAFFIC_BTN,
    disabled: false,
    data: undefined,
  },
];

export const APP_BUTTONS_DATA = {
  [LOCATIONS_BTN]: [{ title: "Locations", number: 0 }],
  [SEGMENTS_BTN]: [
    { title: "In Segments", number: 0 },
    { title: "Out Segments", number: 0 },
  ],
  [HOSTS_BTN]: [
    { title: "Total Sources", number: 0 },
    { title: "Total Destinations", number: 0 },
  ],
  [DESTINATIONS_BTN]: [
    { title: "AS Names", number: 0 },
    { title: "Destination App Prots", number: 0 },
  ],
  [TRAFFIC_BTN]: [
    { title: "Total Server Traffic", number: 0 },
    { title: "Total Client Traffic", number: 0 },
  ],
};
