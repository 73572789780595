import React from "react";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import { PosProps } from "./utils";

type Props = {
  count: number;
  label: string;
  isSmall?: boolean;
} & PosProps;
export default function ActiveTunnelLabel({
  x,
  y,
  count,
  label,
  isSmall,
}: Props): React.ReactElement {
  return (
    <>
      <rect
        rx={4}
        x={x + 4}
        y={y + 4}
        width={isSmall ? 21 : 26}
        height={17}
        strokeWidth={1}
        fill={"#f0eefd"}
        stroke={"#BEB8EA"}
      />
      <text
        x={isSmall ? x + 10 : x + 8}
        y={isSmall ? y + 16 : y + 17}
        fill={GREY_FONT}
        fontSize={12}
      >
        {isSmall ? count : `${label}  ${count}`}
      </text>
    </>
  );
}
