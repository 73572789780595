import { SubList } from "../components/common/WithSubList";
import { formPathFromArray } from "./navigation";
import { PathEntry } from "./types";

export const getSublistFromArray = (
  values: Array<PathEntry>,
  onClick: (arg: string) => void,
  parent?: PathEntry,
  disabled?: Array<string>
): Array<SubList> => {
  const newArr = values.map((val) => {
    const pathArray = val.directParent ? [val.directParent, val] : [val];
    const path = parent ? formPathFromArray([parent, ...pathArray]) : val.path;
    return {
      content: val.content,
      onClick: () => {
        onClick(path);
      },
      disabled: disabled?.includes(val.path),
    };
  });

  return newArr as any;
};
