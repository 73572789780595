import { ReactElement } from "react";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import AWSLogo from "../../../../../components/icons/zayo/clouds/AWSLogo";
import AzureLogo from "../../../../../components/icons/zayo/clouds/AzureLogo";
import GCPLogo from "../../../../../components/icons/zayo/clouds/GCPLogo";
import IBMCloudLogo from "../../../../../components/icons/zayo/clouds/IBMCloudLogo";
import OracleLogo from "../../../../../components/icons/zayo/clouds/OracleLogo";
import SalesforceLogo from "../../../../../components/icons/zayo/clouds/SalesforceLogo";
import { IconProps } from "../../../../../helpers/types";

type IconType = (props: IconProps) => ReactElement;

export const getCloudIcon = (id: string): IconType => {
  switch (id) {
    case "aws":
      return AWSLogo;
    case "azure":
      return AzureLogo;
    case "gcp":
      return GCPLogo;
    case "salesforce":
      return SalesforceLogo;
    case "ibmcloud":
      return IBMCloudLogo;
    case "oracle":
      return OracleLogo;
    default:
      return StatusIcon;
  }
};
