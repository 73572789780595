import { createContextAndUse } from "../../../../contexts/common/utils";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { DropdownItem, RequestStatus } from "../../../../helpers/types";
import { configApi } from "../../../../helpers/api/ConfigApi";
import { getNewConnectionBody } from "./helpers/getNewConnectionBody";
import { getServicesParams } from "./helpers/getServicesParams";
import { PORT_TYPES_MAP } from "./consts";
import {
  resToState,
  setPending,
} from "../../../../helpers/common/RequestStateHelpers";
import { FieldsType } from "./types";
import {
  IConnectionContext,
  withConnectionContextProps,
} from "../ConnectionContext";
import { ConnectionAdd, ConnectionApi, Endpoint } from "../types";
import { getPortTypeFromEndpoint } from "./helpers/getPortTypeFormEndpoint";
import {
  INotificationsContext,
  withNotificationsContextProps,
} from "../../../../contexts/NotificationsContext";
import { getConnectionNotificationBody } from "../helpers/getConnectionNotificationBody";
import { getNewAZConnectionBody } from "./helpers/getNewAZConnectionBody";
import { getUpdatedAZConnectionBody } from "./helpers/getUpdatedAZConnectionBody";
import { getUpdatedConnectionBody } from "./helpers/getUpdatedConnectionBody";
import { tenantApi } from "../../../../helpers/api/TenantApi";
import { ServiceAPI } from "../../../ServicesPreferencesPage/types";
import { OK_STATUS } from "../../../../components/const/api";

type RequestStatuses = {
  addStatus?: RequestStatus;
  editStatus?: RequestStatus;
};

type State = {
  services?: Array<ServiceAPI>;
  portType: DropdownItem;
  isPortPortDisabled?: boolean;
  selectedConnections?: Array<
    ConnectionApi & { a_port_inner_type?: string; z_port_inner_type?: string }
  >;
  listApi?: Array<ConnectionApi>;
  endpointType?: string;
  endpoints?: Array<DropdownItem<Endpoint>>;
};

type IState = State & RequestStatuses;
type IFunc = {
  setIsPortTypeDisabled: (value: boolean) => void;
  changePortType: (value: DropdownItem) => void;
  add: (
    tenant: string,
    fields: {
      name: string;
      connections: Array<FieldsType>;
      services: Array<ServiceAPI>;
    }
  ) => Promise<boolean>;
  edit: (
    tenant: string,
    fields: {
      name: string;
      connections: Array<FieldsType>;
      services: Array<ServiceAPI>;
    }
  ) => Promise<boolean>;
  fetchConnections: (name: string) => Promise<void>;
  getLocation: (endpointName: string) => Endpoint | undefined;
  fetchServices: (tenant: string) => Promise<void>;
};
type Props = IConnectionContext & INotificationsContext;
const [
  ConnectionConfiguratorContext,
  useConnectionConfiguratorContext,
] = createContextAndUse<IState, IFunc>();
export { useConnectionConfiguratorContext };

class ConnectionConfiguratorContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  Props
> {
  Context = ConnectionConfiguratorContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = { portType: PORT_TYPES_MAP[0] };
  }

  componentDidMount(): void {
    const { listApi, endpoints } = this.props;
    this.setState({ listApi, endpoints });
  }

  componentDidUpdate(prevProps: Readonly<IConnectionContext>): void {
    const { listApi, endpoints } = this.props;

    if (listApi && listApi !== prevProps.listApi) {
      this.setState({ listApi });
    }

    if (endpoints && endpoints !== prevProps.endpoints) {
      this.setState({ endpoints });
    }

    if (
      endpoints &&
      endpoints !== prevProps.endpoints &&
      this.state.selectedConnections
    ) {
      this.updateConnections(this.state.selectedConnections, endpoints);
    }
  }

  fetchServices = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getEnabledServices(tenant);
    if (ok && result) {
      this.setState({ services: result.services });
    }
  };

  updateServices = async (
    tenant: string,
    services: Array<ServiceAPI>
  ): Promise<boolean> => {
    const res = await tenantApi.editEnabledServices(tenant, {
      services,
    });
    if (res.ok) this.props.fetchData(tenant);
    return res.ok;
  };

  fetchConnections = async (name: string): Promise<void> => {
    const selectedConnections = this.props.listApi?.filter(
      (el) => el.labels.parentConnection === name || el.name === name
    );
    this.setState({ listApi: this.props.listApi });

    if (this.props.endpoints) {
      this.updateConnections(selectedConnections, this.props.endpoints);
      return;
    }

    this.setState({ selectedConnections });
  };

  updateConnections = (
    connections: Array<ConnectionApi>,
    endpoints: Array<DropdownItem<Endpoint>>
  ) => {
    const newList = connections.map((port) =>
      getPortTypeFromEndpoint(port, endpoints)
    );
    this.setState({ selectedConnections: newList });
  };

  setIsPortTypeDisabled = (value: boolean): void => {
    this.setState({ isPortPortDisabled: value });
  };
  changePortType = (portType: DropdownItem): void => {
    if (this.state.isPortPortDisabled) return;
    this.setState({ portType });
  };

  add = async (
    tenant: string,
    fields: {
      name: string;
      connections: Array<FieldsType>;
      services: Array<ServiceAPI>;
    }
  ): Promise<boolean> => {
    this.setState({ addStatus: setPending() });
    // Show pending notification
    this.props.handleShowNotification(
      getConnectionNotificationBody("pending"),
      true
    );

    const name = fields.name;
    const servicesList = getServicesParams(fields.services);
    const params = [];

    const isAZPorts = this.state.portType.key === "azPorts";

    // Basic A Port - Z port
    if (isAZPorts) {
      params.push(
        getNewAZConnectionBody(name, fields.connections, servicesList)
      );
    }

    // With Cloud Router or ELAN or Cloud-Cloud
    if (!isAZPorts) {
      fields.connections.forEach(
        (connection: { [key: string]: any }, idx: number) => {
          const item = getNewConnectionBody(
            name,
            this.state.portType.key,
            servicesList,
            connection,
            idx
          );
          params.push(item);
        }
      );
    }

    // Creating new connections
    const finalRes: Array<any> = [];
    await Promise.all(
      params.map(
        async (item): Promise<any> => {
          const response = await configApi.addConnection(tenant, item);
          finalRes.push(response);
        }
      )
    );
    // for (const item of params) {
    //   try {
    //     const response = await configApi.addConnection(tenant, item);
    //     finalRes.push(response);
    //   } catch (error) {
    //     const errorRes = setError(error as string);
    //     this.setState({ addStatus: errorRes });
    //     return false;
    //   }
    // }
    const errorRes = finalRes.find((r) => !r.ok);
    this.setState({ addStatus: resToState(errorRes || finalRes[0]) });
    let resState;
    if (!errorRes) {
      if (!isAZPorts) {
        resState = this.updateServices(tenant, fields.services);
      } else {
        resState = finalRes[0];
        this.props.fetchData(tenant);
      }
    }

    // Show notification
    this.props.handleShowNotification(
      resState ? getConnectionNotificationBody("ok") : undefined
    );

    return Boolean(resState);
  };

  edit = async (
    tenant: string,
    fields: { name: string; connections: Array<FieldsType>; services: any }
  ): Promise<boolean> => {
    this.setState({ editStatus: setPending() });

    // Show pending notification
    this.props.handleShowNotification(
      getConnectionNotificationBody("pending"),
      true
    );

    const servicesList = getServicesParams(fields.services);
    const params = [];
    const newConnections: Array<ConnectionAdd> = [];

    const isAZPorts = this.state.portType.key === "azPorts";

    // Basic A Port - Z port
    if (isAZPorts) {
      const name = fields.name;
      const initialConnection = this.state.listApi?.find(
        (el) => el.name === name
      );
      const body = getUpdatedAZConnectionBody(
        fields.connections,
        servicesList,
        initialConnection
      );
      body && params.push({ name, body });
    }

    // With Cloud Router or ELAN or Cloud-Cloud
    if (!isAZPorts) {
      fields.connections.forEach(
        (connection: { [key: string]: any }, idx: number) => {
          const initialConnection = this.state.listApi?.find(
            (el) => el.name === connection.realName
          );
          if (!initialConnection) {
            const item = getNewConnectionBody(
              fields.name,
              this.state.portType.key,
              servicesList,
              connection,
              idx
            );
            newConnections.push(item);
          }
          if (initialConnection) {
            const item: any = getUpdatedConnectionBody(
              servicesList,
              connection,
              initialConnection
            );
            item && params.push(item);
          }
        }
      );
    }

    // Update connections
    const finalRes: Array<any> = [];
    if (params.length > 0) {
      await Promise.all(
        params.map(
          async (item): Promise<any> => {
            const response = await configApi.editConnection(
              tenant,
              item.name,
              item.body
            );
            finalRes.push(response);
          }
        )
      );
    }
    // Creating new connections
    if (newConnections.length) {
      await Promise.all(
        newConnections.map(
          async (item): Promise<any> => {
            const response = await configApi.addConnection(tenant, item);
            finalRes.push(response);
          }
        )
      );
    }
    const errorRes = finalRes.find((r) => !r.ok);
    this.setState({ editStatus: resToState(errorRes || finalRes[0]) });

    let resState;
    if (!errorRes) {
      if (!isAZPorts) {
        resState = this.updateServices(tenant, fields.services);
      } else {
        resState = finalRes[0] || OK_STATUS;
        this.props.fetchData(tenant);
      }
    }

    // Show notification
    this.props.handleShowNotification(
      resState
        ? getConnectionNotificationBody("ok", undefined, true)
        : undefined
    );
    return !!resState;
  };

  getLocation = (endpointName: string): Endpoint | undefined => {
    return this.props.endpoints.find((el) => el.data.name === endpointName)
      ?.data;
  };

  funcs = {
    fetchConnections: this.fetchConnections,
    add: this.add,
    edit: this.edit,
    changePortType: this.changePortType,
    setIsPortTypeDisabled: this.setIsPortTypeDisabled,
    getLocation: this.getLocation,
    fetchServices: this.fetchServices,
  };
}

export default withNotificationsContextProps<any>(
  withConnectionContextProps<any>(ConnectionConfiguratorContextContainer)
);
