import { TimedDataArr } from "../../../helpers/types";
import { randomNumberBounds } from "../../../helpers/common/numberHelpers";

export const getLastTime = (data: TimedDataArr): number =>
  data.length ? data[data.length - 1].time : Date.now();

export function trimData(data: TimedDataArr, timespan: number): TimedDataArr {
  const lastTime = getLastTime(data);
  return data.filter(({ time }) => time >= lastTime - timespan);
}

export const HALF_MINUTE = 30 * 1000;
export const MINUTE_5 = 5 * 60 * 1000;
export const HOUR = 60 * 60 * 1000;
export const HOURS_12 = 12 * 60 * 60 * 1000;
export const MINUTE = 60 * 1000;
export const MINUTE_15 = 15 * MINUTE;

export const INTERVAL_VALUE = 5000;

export function seedData(): TimedDataArr {
  const lineArr: TimedDataArr = [];
  const now = new Date();
  lineArr.push({
    time: now.getTime() - 500,
    y: 10 * randomNumberBounds(0, 5),
  });
  lineArr.push({
    time: now.getTime(),
    y: 10 * randomNumberBounds(0, 5),
  });

  return lineArr;
}

export function seedDashData(): TimedDataArr {
  const lineArr: TimedDataArr = [];
  const now = new Date();
  lineArr.push({
    time: now.getTime() - 500,
    ccn: 10 * randomNumberBounds(0, 5),
    cps: 10 * randomNumberBounds(0, 5),
  });
  lineArr.push({
    time: now.getTime(),
    ccn: 10 * randomNumberBounds(0, 5),
    cps: 10 * randomNumberBounds(0, 5),
  });

  return lineArr;
}

export function updateDashData(data: TimedDataArr): TimedDataArr {
  const newD = [...data];

  const lineData = {
    time: newD[newD.length - 1].time + HALF_MINUTE * 120,
    ccn:
      10 *
      Math.max(
        1,
        Math.min(5, data[data.length - 1].ccn / 10 + randomNumberBounds(-1, 1))
      ),
    cps:
      10 *
      Math.max(
        1,
        Math.min(5, data[data.length - 1].cps / 10 + randomNumberBounds(-1, 1))
      ),
  };
  newD.push(lineData);

  return newD;
}

export function updateData(data: TimedDataArr): TimedDataArr {
  const newD = [...data];

  const lineData = {
    time: newD[newD.length - 1].time + HALF_MINUTE * 120,
    y:
      10 *
      Math.max(
        1,
        Math.min(5, data[data.length - 1].y / 10 + randomNumberBounds(-1, 1))
      ),
  };
  newD.push(lineData);

  return newD;
}
