import { CardField } from "../../../../components/common/Card/cardTypes";
import { GTimeRange } from "../../../../components/common/charts/GraphanaLinksFactory";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { createContextAndUseWithExtra } from "../../../../contexts/common/AbstractCrudContext";
import { elasticApi } from "../../../../helpers/api/ElasticApi";
import {
  APP_BUTTONS_DATA,
  DESTINATIONS_BTN,
  LOCATIONS_BTN,
  SEGMENTS_BTN,
  HOSTS_BTN,
} from "./trafficButtonsConsts";

type RequestStatuses = {};
type State = { cardsData: CardField };

type IState = State & RequestStatuses;
type IFunc = {
  fetchCardsData: (timeRange: GTimeRange, tenantName?: string) => Promise<void>;
};

const [TrafficButtonsContext, useContext] = createContextAndUseWithExtra<
  IState,
  RequestStatuses,
  IFunc
>();
export const useTrafficButtonsContext = useContext;

export default class TrafficButtonsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = TrafficButtonsContext;

  fetchCardsData = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<void> => {
    const { ok, result } = await elasticApi.getApplicationsCounts(
      timeRange,
      tenantName
    );
    const newData = { ...APP_BUTTONS_DATA };
    if (ok && result) {
      newData[LOCATIONS_BTN][0].number = result["8"].value;
      newData[SEGMENTS_BTN][0].number = result["9"].value;
      newData[SEGMENTS_BTN][1].number = result["11"].value;
      newData[HOSTS_BTN][0].number = result["6"].value;
      newData[DESTINATIONS_BTN][0].number = result["7"].value;
      newData[DESTINATIONS_BTN][0].number = result["5"].value;
    }
    this.setState({
      cardsData: { ...newData },
    });
  };
  funcs = { fetchCardsData: this.fetchCardsData };
}
