import React, { FC } from "react";
import styles from "./HeaderMenuContent.module.scss";
import { ControlsType } from "../consts";

type Props = {
  control: ControlsType;
};

const HeaderMenuComponent: FC<Props> = ({ control }) => {
  return (
    <div className={styles.controlWrapper}>
      <div className={styles.controlTitle}>{control.title}</div>
      {control.component}
    </div>
  );
};

export default HeaderMenuComponent;
