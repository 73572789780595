import { Dispatch, FC } from "react";
import Group from "./Group";
import styles from "./Appliances.module.scss";

type Props = {
  groups: Array<any>;
  list: Array<any>;
  device: any;
  setDevice: Dispatch<string>;
};

const Appliances: FC<Props> = ({ groups, list, device, setDevice }) => {
  return (
    <div className={styles.appliances}>
      <h4>{list.length} Appliances</h4>
      {groups.map((group, key) => {
        const data = list.filter((item) => item.groupId === group.id);
        if (!data.length) return null;
        return (
          <Group
            key={key}
            group={group}
            appliances={data}
            device={device}
            setDevice={setDevice}
          />
        );
      })}
    </div>
  );
};

export default Appliances;
