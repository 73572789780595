import { FC } from "react";
import styles from "./DropdownItem.module.scss";

type Props = {
  item: string | React.FC<any>;
  onClick: () => void;
};

const DropdownItem: FC<Props> = ({ item, onClick }) => {
  return (
    <div onClick={onClick} className={styles.value}>
      {item}
    </div>
  );
};

export default DropdownItem;
