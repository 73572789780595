import { FC } from "react";
import BlankButton from "../../../../../components/common/buttons/BlankButton";

type Props = { onClick: () => void; isSchemeShown: boolean; className: string };

const SchemeControls: FC<Props> = ({ onClick, isSchemeShown, className }) => {
  return (
    <BlankButton id={"scheme"} onClick={onClick} className={className}>
      {isSchemeShown ? "Hide scheme" : "Show Scheme"}
    </BlankButton>
  );
};

export default SchemeControls;
