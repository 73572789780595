import React, { FC, useEffect, useMemo, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table, {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { withContexts } from "../../helpers/hocs/withContexts";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { pbmTableHeader } from "./table/pbmTableHeader";
import TableControls from "../TenantsPage/table/TableControls";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import PBMConfig from "./PBMConfig/PBMConfig";
import { ADD, EDIT } from "../../helpers/common/constantsAlias";
import FirewallContextContainer, {
  useFirewallContext,
} from "../../contexts/servicesContext/FirewallContext";
import { PBMType } from "../../helpers/api/apiTypes";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import PBMContextContainer, {
  usePBMContext,
} from "../../contexts/servicesContext/PBMContext";

const PBMTable: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchList,
    pbmList,
    pbmListStatus,
    count,
    remove,
    removeStatus,
    selectPBM,
    changePBMEnable,
  } = usePBMContext();

  const { servicesStatusRequest } = useTenantContext();

  const { fetchUserGroups } = useFirewallContext();

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState();

  useEffect(() => {
    fetchWrapper();
    selectedTenant && fetchUserGroups(selectedTenant);
  }, [selectedTenant]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    selectedTenant && fetchList(selectedTenant, offset, limit);
  };

  const handleClickRow = (pbm: any): void => {
    setSelected(pbm);
    setShowEdit(true);
    selectPBM(pbm);
  };

  const [tryDelete, selectedToDelete, sureDeleteProps] = useDeleteDialog<any>(
    remove,
    removeStatus
  );

  const rowActions: ActionFuncs<PBMType> = useMemo(
    () => ({
      onEdit: handleClickRow,
      onDelete: tryDelete,
    }),
    [handleClickRow, tryDelete]
  );

  if (showAdd) {
    return <PBMConfig type={ADD} onClose={() => setShowAdd(false)} />;
  }

  if (showEdit) {
    return (
      <PBMConfig
        type={EDIT}
        onClose={() => setShowEdit(false)}
        data={selected}
      />
    );
  }

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"PBM"}
        name={`${JSON.stringify(selectedToDelete?.name)}`}
      />
      <TableWrapper
        titleProps={{
          title: `Policy Based Meters`,
          children: <TableControls onAdd={() => setShowAdd(true)} noSearch />,
        }}
        dataStatus={pbmListStatus}
        tablePlaceholder={["PBM", "PBM"]}
        isEmpty={pbmList && pbmList.length === 0}
      >
        <Table
          fetchAction={fetchWrapper}
          data={pbmList || []}
          count={count}
          columns={pbmTableHeader()}
          header={TableHeader}
          gridColumnTemplate="120px 1fr 120px 250px 200px 80px 140px"
          rowActions={rowActions}
          clickFeatures={[
            {
              elementClass: "toggle",
              clb: changePBMEnable,
            },
          ]}
        />
      </TableWrapper>
    </>
  );
};

export default withContexts(PBMTable, [
  PBMContextContainer,
  FirewallContextContainer,
]);
