import React, { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import styles from "./ServicesStaticRoutesPage.module.scss";
import StaticRoutesServicesContextContainer from "./StaticRoutesServicesContext";
import { PageProps } from "../../helpers/types";
import { withContexts } from "../../helpers/hocs/withContexts";
import { StaticRoutePageProps } from "./types";
import TenantVIContextContainer from "../../contexts/tenantsContext/TenantVIContext";
import StaticRoutesTable from "./components/Tables/StaticRoutesTable";
import DynamicRoutesTable from "./components/Tables/DynamicRoutesTable";

type Props = PageProps & StaticRoutePageProps;
const ServicesStaticRoutesPage: FC<Props> = ({ tabs: Tabs }) => {
  return (
    <div className={styles.contentWrapper}>
      <Tabs hideControls />
      <StaticRoutesTable />
      <DynamicRoutesTable />
    </div>
  );
};

export default withLayout<Props>(
  withContexts(ServicesStaticRoutesPage, [
    StaticRoutesServicesContextContainer,
    TenantVIContextContainer,
  ])
);
