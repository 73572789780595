import { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import styles from "./ARPsPage.module.scss";
import ARPsTable from "./ARPsTable";

const ARPsPage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <div className={styles.container}>
      <Tabs showTimeRange />
      <ARPsTable />
    </div>
  );
};
export default withLayout<PageProps>(ARPsPage);
