import Api from "./Api";
import { AUTH_BROKER } from "./apiPaths";
import { AccessTokenType } from "./apiTypes";

type Res<P> = { ok: boolean; error?: string; result?: P };

export class AuthApi extends Api {
  constructor() {
    super();
  }

  async getToken(
    username: string,
    password: string,
    tenantName?: string,
    newPassword?: string
  ): Promise<Res<AccessTokenType>> {
    const body = JSON.stringify({
      username,
      password,
      tenant_name: tenantName || "global",
      new_password: newPassword || "",
    });

    const result = await this.fetchBroker<AccessTokenType>(
      AUTH_BROKER.GET_TOKEN(),
      { body },
      undefined,
      true
    );

    return result;
  }

  async validateCurrentToken(): Promise<Res<{ seconds: number }>> {
    const result = await this.fetchBroker<{ seconds: number }>(
      AUTH_BROKER.GET_TOKEN_LIFESPAN()
    );

    return result;
  }
}
export const authApi = new AuthApi();
