import React, { FC } from "react";
import LoaderIcon from "../../loadStates/LoaderIcon";
import styles from "./GrafanaLoader.module.scss";

const GrafanaLoader: FC = () => {
  return (
    <div className={styles.wrapper}>
      <LoaderIcon />
    </div>
  );
};
export default GrafanaLoader;
