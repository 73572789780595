import { PortTypeScheme } from "../../../../types";

export const getSubPorts = (
  data: Array<PortTypeScheme>,
  type: number,
  portIdx: number
): [Array<PortTypeScheme>, boolean] => {
  const subPorts = data.filter(
    (port) =>
      port.parsedName.type === type.toString() &&
      port.parsedName.portIdx === portIdx.toString() &&
      port.parsedName.subPort
  );
  const withSubPorts = subPorts.length > 0;
  return [subPorts, withSubPorts];
};
