import React, { FC, useEffect, useState } from "react";
import RadioGroup, {
  RadioOption,
} from "../../../../../../components/common/formComponents/RadioGroup";

import { CONNECTION_TYPE } from "../utils/connectionFormsConsts";

type Props = {
  type?: string;
  onChange: (value: any) => void;
  value: string;
  list?: Array<RadioOption>;
  disabled?: boolean;
};
const ConnectionTypeGroup: FC<Props> = ({
  type,
  value,
  onChange,
  list = CONNECTION_TYPE,
}) => {
  const [options, setOptions] = useState<Array<RadioOption>>(list);

  useEffect(() => {
    setOptions(CONNECTION_TYPE);
  }, [type]);

  return (
    <RadioGroup
      label={"Connection Type"}
      isOneRow
      options={options}
      value={value}
      setActiveValue={(val: string) => onChange(val)}
    />
  );
};
export default ConnectionTypeGroup;
