import { ReactElement } from "react";
import {
  ACCENT,
  GREY_DISABLED,
} from "../../../../../helpers/common/colorAliases";
import CheckboxIcon from "../../../../../components/icons/CheckboxIcon";
import EmDashIcon from "../../../../../components/icons/EmDashIcon";

export const getIconByStatus = (status: string): ReactElement => {
  switch ((status || "").toLowerCase()) {
    case "success":
      return <CheckboxIcon color={ACCENT} />;
    case "route":
    default:
      return <EmDashIcon color={GREY_DISABLED} />;
  }
};
