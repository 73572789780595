import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function CiscoIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="27"
      height="14"
      viewBox="0 0 27 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.88919 8.19202C2.88919 7.59202 2.39916 7.10547 1.79846 7.10547C1.19776 7.10547 0.707031 7.59202 0.707031 8.19202V10.4779C0.707031 11.0814 1.19776 11.5686 1.79846 11.5686C2.39916 11.5686 2.88919 11.0814 2.88919 10.4779V8.19202Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M8.88138 5.19244C8.88138 4.59243 8.39135 4.10449 7.79065 4.10449C7.18925 4.10449 6.69922 4.59243 6.69922 5.19244V10.479C6.69922 11.0825 7.18925 11.5691 7.79065 11.5691C8.39135 11.5691 8.88138 11.0825 8.88138 10.479V5.19244Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M14.8736 1.08725C14.8736 0.487245 14.3835 0 13.7828 0C13.1814 0 12.6914 0.487245 12.6914 1.08725V12.651C12.6914 13.2544 13.1814 13.7417 13.7828 13.7417C14.3835 13.7417 14.8736 13.2544 14.8736 12.651V1.08725Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M20.8658 5.19244C20.8658 4.59243 20.3757 4.10449 19.775 4.10449C19.1736 4.10449 18.6836 4.59243 18.6836 5.19244V10.479C18.6836 11.0825 19.1736 11.5691 19.775 11.5691C20.3757 11.5691 20.8658 11.0825 20.8658 10.479V5.19244Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M26.8549 8.19202C26.8549 7.59202 26.3683 7.10547 25.7704 7.10547C25.1697 7.10547 24.6797 7.59202 24.6797 8.19202V10.4779C24.6797 11.0814 25.1697 11.5686 25.7704 11.5686C26.3683 11.5686 26.8549 11.0814 26.8549 10.4779V8.19202Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default CiscoIcon;
