import React, { FC } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import UnifiedTextPair from "../../../../../components/common/UnifiedTextPair";
import ConnectionIcon from "../../../../../components/icons/ConnectionIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import { useConnectionContext } from "../../ConnectionContext";
import { CONNECTION_TYPE_IDX_MAP } from "../helpers/consts";
import styles from "./ConnectionInfo.module.scss";

type Props = { className: string; fields: any };

const ConnectionInfo: FC<Props> = ({ className, fields }) => {
  const { locations } = useConnectionContext();
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={<ConnectionIcon color={GREY_FONT} />}
        text={"Connection"}
        className={styles.header}
      />
      <div className={styles.content}>
        <UnifiedTextPair
          subtitle={"Name"}
          text={fields?.name}
          textSize={"medium"}
        />
        <UnifiedTextPair
          subtitle={"Location"}
          text={locations.find((loc) => loc?.key === fields.location)?.value}
          textSize={"medium"}
        />
        <UnifiedTextPair
          subtitle={"Type"}
          text={
            CONNECTION_TYPE_IDX_MAP.find((loc) => loc?.key === fields.type)
              ?.value
          }
          textSize={"medium"}
        />
      </div>
    </div>
  );
};

export default ConnectionInfo;
