import { FC } from "react";

const FILL = "#D8DAEB";

type Props = { x: number; y: number };

const Dots: FC<Props> = ({ x, y }) => {
  const circles = [];

  for (let i = 0; i < 16; i++) {
    const cx = 1.5 + (i % 8) * 7;
    const cy = 1.5 + Math.floor(i / 8) * 6;

    circles.push(<circle key={i} cx={cx} cy={cy} r={1.5} fill={FILL} />);
  }

  return (
    <svg
      x={x}
      y={y}
      width="52"
      height="9"
      viewBox="0 0 52 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {circles}
    </svg>
  );
};

export default Dots;
