export function getEndOfRangeByBitMask(
  start: number,
  maskLength = 8
): Array<number> {
  const binaryStart = start.toString(2).padStart(maskLength, "0");
  const lastOneIndex = binaryStart.lastIndexOf("1");

  const values: Array<number> = [];
  if (lastOneIndex == maskLength - 1) return [start];
  const mask = binaryStart.slice(0, lastOneIndex + 1);
  const count = maskLength - mask.length;
  for (let i = 0; i <= count; i++) {
    const masked = (mask + "0".repeat(count - i)).padEnd(maskLength, "1");
    values.push(parseInt(masked, 2));
  }
  values.sort((a: number, b: number) => a - b);
  return values;
}

export function getPortsRangeByBitMask(
  start: string,
  maskLength = 16
): Array<string> {
  let usedStart = start?.toString() || "0";
  if (usedStart.includes("/")) {
    const parsedStart = usedStart.split("/")[0];
    usedStart = parsedStart;
  }
  const binaryStart = parseInt(usedStart).toString(2).padStart(maskLength, "0");
  const lastOneIndex = binaryStart.lastIndexOf("1");

  const values: Array<string> = [];
  if (lastOneIndex == maskLength - 1) return [usedStart];
  const mask = binaryStart.slice(0, lastOneIndex + 1);
  const count = maskLength - mask.length;
  for (let i = 0; i <= count; i++) {
    const masked = (mask + "0".repeat(count - i)).padEnd(maskLength, "1");
    const range = parseInt(masked, 2).toString();
    if (usedStart === range) {
      values.push(usedStart);
    } else {
      values.push(`${usedStart}/${parseInt(masked, 2)}`);
    }
  }
  return values;
}
