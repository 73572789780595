import { System } from "../../Systems/Provisioning/types";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";
import {
  ConnectionRegion,
  ConnectionRow,
  VirtualInterfaceRow,
} from "../types/connections";

type GroupRowBasic = {
  name: { label: string; region: ConnectionRegion };
  subRows: Array<VirtualInterfaceRow>;
};

const NAT_TYPE = "Virtual Interface NAT";
const IPSEC_TYPE = "Virtual Interface IPSEC gateway";

export const combineState = (
  items: Array<VirtualInterfaceApi>
): Array<VirtualInterfaceRow> => {
  return (
    items?.map((i) => ({
      ...i,
      type: i.type,
      state: {
        administrative_state: i.administrative_state,
        operational_state: i.operational_state,
      },
      system_name: i.system_name || "no system",
    })) || []
  );
};

export const convertToGroupRows = (
  items: Array<VirtualInterfaceRow>,
  systems: Array<System>
): Array<ConnectionRow> => {
  const result: Array<GroupRowBasic> = [];

  const EMPTY_GROUP_LABEL = "[without system]";

  items.forEach((item) => {
    const { system_name } = item;

    const group = result.find((r) => r?.name.label === system_name);

    if (!group) {
      const group = {
        name: {
          label: item.system_name,
          region: ConnectionRegion.US,
        },
        subRows: [],
      };
      return result.push(group);
    }
  });

  result.push({
    name: { label: EMPTY_GROUP_LABEL, region: ConnectionRegion.US },
    subRows: [],
  });

  const groups = result.map((r) => {
    const location = systems.find((s) => s.name === r.name.label)?.location;

    return {
      ...r,
      name: { ...r.name, label: location || r.name.label },
      subRows: items.filter(
        (i) => i.system_name === r.name.label || i.system_name === null
      ),
    };
  });

  return groups.filter((g) => g.subRows.length);
};

export const removeNATVis = (
  list: Array<ConnectionRow>
): Array<ConnectionRow> => {
  const internetVi = (fields: VirtualInterfaceRow): VirtualInterfaceRow => ({
    ...fields,
    name: "Internet",
    type: "internet",
    description: "",
    network_segments: [],
    state: { administrative_state: "up", operational_state: "up" },
  });

  return list.map((group) => {
    const filteredVis = group.subRows.filter(
      (vi) => vi.type !== NAT_TYPE && vi.type !== IPSEC_TYPE
    );

    const viToReplace =
      group.subRows.find(
        (vi) => vi.type === NAT_TYPE && vi.state.administrative_state === "up"
      ) || group.subRows.find((vi) => vi.type === NAT_TYPE);

    const ip_addresses: Array<string> = [];
    group.subRows.forEach((vi) => {
      if (vi.type === NAT_TYPE) {
        vi.ip_addresses.forEach((ip) => {
          if (!ip_addresses.includes(ip)) ip_addresses.push(ip);
        });
      }
    });

    return {
      ...group,
      subRows: viToReplace
        ? [...filteredVis, internetVi({ ...viToReplace, ip_addresses })]
        : [...filteredVis],
    };
  });
};
