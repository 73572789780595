import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

export default function PBRIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9781 8.62962C25.9309 8.62962 26.7034 7.85721 26.7034 6.90439C26.7034 5.95158 25.9309 5.17917 24.9781 5.17917C24.0253 5.17917 23.2529 5.95158 23.2529 6.90439C23.2529 7.85721 24.0253 8.62962 24.9781 8.62962ZM24.9781 10.4963C26.9619 10.4963 28.57 8.88814 28.57 6.90439C28.57 4.92065 26.9619 3.3125 24.9781 3.3125C22.9944 3.3125 21.3862 4.92065 21.3862 6.90439C21.3862 8.88814 22.9944 10.4963 24.9781 10.4963ZM10.1251 18.6191C9.50214 19.6853 8.34551 20.4016 7.02158 20.4016C5.03783 20.4016 3.42969 18.7934 3.42969 16.8097C3.42969 14.8259 5.03783 13.2178 7.02158 13.2178C8.70012 13.2178 10.1097 14.3691 10.5038 15.9252H12.1487C12.2446 15.9252 12.3399 15.9279 12.4345 15.9333H12.6675C12.9411 15.9333 13.1987 15.8791 13.4327 15.7821L13.5793 16.1356C15.6094 16.7489 17.0876 18.6339 17.0876 20.8641V23.5852C17.0876 24.8251 18.0927 25.8302 19.3325 25.8302H21.5501C22.007 24.3715 23.3691 23.3132 24.9785 23.3132C26.9622 23.3132 28.5703 24.9213 28.5703 26.905C28.5703 28.8888 26.9622 30.4969 24.9785 30.4969C23.5772 30.4969 22.3633 29.6945 21.7713 28.5241H19.3325C16.6049 28.5241 14.3937 26.3129 14.3937 23.5852V20.8641C14.3937 19.6242 13.3886 18.6191 12.1487 18.6191H10.1251ZM19.3341 8.26693C19.0605 8.26693 18.8029 8.32114 18.5689 8.41816L17.5474 5.95489C18.0995 5.72595 18.7038 5.60026 19.3341 5.60026H20.1675V8.26693H19.3341ZM17.4854 9.50171C17.3884 9.73567 17.3341 9.99329 17.3341 10.2669V11.1835H14.6675V10.2669C14.6675 9.63662 14.7932 9.03232 15.0221 8.48023L17.4854 9.50171ZM17.3341 13.0167V13.9333C17.3341 14.5636 17.2085 15.1679 16.9795 15.72L14.5162 14.6986C14.6133 14.4646 14.6675 14.207 14.6675 13.9333V13.0167H17.3341Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}
