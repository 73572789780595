import React, { FC } from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { Checkbox } from "../../../../../components/common/formComponents/Checkbox";
import Input from "../../../../../components/common/formComponents/Input";
import Section from "../../../../../components/common/Section";
import LocationIcon from "../../../../../components/icons/LocationIcon";
import { GREY_DISABLED } from "../../../../../helpers/common/colorAliases";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  className: string;
  isEdit: boolean;
};

const OutsideSection: FC<Props> = ({ fields, onChange, className, isEdit }) => {
  return (
    <Section title={"Outside"} columnsCount={2} className={className}>
      <Checkbox
        isChecked={fields.manualOutsideIP}
        onChange={() => onChange("manualOutsideIP", !fields.manualOutsideIP)}
        label={"Manual Outside IP"}
        disabled={isEdit}
      />
      {fields.manualOutsideIP && (
        <>
          <div />
          <Input
            label="IP"
            name="otsideIP"
            placeholder="IP"
            isPlaceholderAlwaysVisible
            value={fields.otsideIP}
            onChange={(e) => onChange("otsideIP", e.target.value)}
            medium
            error={""}
            isNotEditable={isEdit}
          />
          <DropdownBasic
            id={"outsideLocation"}
            onChange={(val) => onChange("outsideLocation", val)}
            selected={mapStringToItem(fields?.outsideLocation)}
            label="Location"
            placeholder=""
            itemsList={[].map(mapStringToItem)}
            icon={<LocationIcon color={GREY_DISABLED} />}
            isMedium
            error={""}
            isNotEditable={isEdit}
          />
        </>
      )}
    </Section>
  );
};
export default OutsideSection;
