import React, { ReactElement } from "react";
import LayoutComponent from "../../components/common/LayoutComponent";
import DashboardContextContainer from "../../contexts/dashboardContext/DashboardContext";
import DashboardFilterContextContainer from "../../contexts/dashboardContext/DashboardFilterContext";
import OneSystemContextContainer from "../../contexts/systemsContext/OneSystemContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import NetworkContextContainer from "../../contexts/NetworkContext";
import styles from "./InsightsPage.module.scss";
import InsightsContent from "./InsightsContent";

function InsightsPage(): ReactElement {
  return (
    <LayoutComponent withoutPaddings withoutNavigation={true}>
      <div className={styles.container}>
        <InsightsContent />
      </div>
    </LayoutComponent>
  );
}

export default withContexts(InsightsPage, [
  OneSystemContextContainer,
  DashboardContextContainer,
  NetworkContextContainer,
  DashboardFilterContextContainer,
]);
