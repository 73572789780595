import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";
import { CreateVTEPType, VTEPType } from "../types";
import { IVTEPContext, withVTEPContextProps } from "../VTEPContext";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<VTEPType>;

type IState = State & RequestStatuses;
type IFunc = DialogFunc<VTEPType>;

const [VTEPDialogContext, useVTEPDialogContext] = createContextUtils<
  IState,
  IFunc
>();
export { useVTEPDialogContext };

class VTEPDialogContextContainer extends AbstractDialogContextContainer<
  VTEPType,
  IState,
  RequestStatuses,
  IFunc,
  IVTEPContext & IOneSystemContext
> {
  Context = VTEPDialogContext;

  _updateSystems = (ok: boolean) => {
    if (ok) {
      this.props.fetchList();
    }
  };
  add = async (vtep: Partial<VTEPType>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const params: any = { ...vtep };
    params.vrf_name = vtep.vrf?.name;
    delete params.vrf;
    const { ok } = await this._addWrap(() =>
      configApi.addVTEP(system.name, params as CreateVTEPType)
    );
    this._updateSystems(ok);
    return ok;
  };

  edit = async (vtep: Partial<VTEPType>): Promise<boolean> => {
    const { system } = this.props;
    const { name, local_ip, remote_ips, description, vtep_type } = vtep;

    if (
      !system ||
      !name ||
      !local_ip ||
      !remote_ips ||
      !description ||
      !vtep_type
    )
      return false;

    const { ok } = await this._editWrap(() =>
      configApi.editVTEP(system.name, name, {
        local_ip,
        remote_ips,
        description,
      })
    );
    this._updateSystems(ok);
    return ok;
  };

  funcs = {
    edit: this.edit,
    add: this.add,
  };
}

export default withVTEPContextProps(
  withOneSystemContextProps(VTEPDialogContextContainer)
);
