import DropdownBasic from "../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import ConnectionSection from "../itemConfigurator/common/ConnectionSection";
import { useConnectionConfiguratorContext } from "../itemConfigurator/ConnectionConfiguratorContext";

const ContractSection = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  return (
    <ConnectionSection title={"Contract"}>
      <DropdownBasic
        id={"bandwidth"}
        onChange={(val) => {
          fieldsOnChange("bandwidth", val.key);
        }}
        selected={mapStringToItem(fields.bandwidth)}
        label="Bandwidth"
        itemsList={[]}
        isMedium
        error={""}
      />
      <DropdownBasic
        id={"subscription"}
        onChange={(val) => {
          fieldsOnChange("subscription", val.key);
        }}
        selected={mapStringToItem(fields.subscription)}
        label="Subscription Period"
        itemsList={[]}
        isMedium
        error={""}
      />
    </ConnectionSection>
  );
};

export default ContractSection;
