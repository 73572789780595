import { CellProps } from "react-table";
import { CellFactory } from "../../../../components/common/table/newTable/cells/CellsFactory";
import { Toggle } from "../../../../components/common/Toggle";

import { SegmentRule } from "./types";

const cellFactory = new CellFactory<SegmentRule>({});

export const RulesTableHeader = (
  toggleRule: (id: number) => void
): Array<any> =>
  [
    {
      accessor: "enabled",
      Header: "Enabled",
      Cell: ({ row: { id, original } }: CellProps<SegmentRule>): any => {
        return (
          <Toggle
            id={id}
            isChecked={original.enabled}
            onChange={() => {
              toggleRule(original.id);
            }}
          />
        );
      },
    },
    {
      accessor: "network_app",
      Header: "NetworkApp",
      Cell: cellFactory.textCell(),
    },
    {
      accessor: "description",
      Header: "Description",
      Cell: cellFactory.textCell(),
    },
    {
      accessor: "priority",
      Header: "Priority",
      Cell: cellFactory.textCell(),
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof SegmentRule }));
