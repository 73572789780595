import React, { ReactChild, useRef, useState } from "react";
import styles from "./WithSubList.module.scss";
import BlankButton from "./buttons/BlankButton";
import { classNames } from "../../helpers/common/classNames";

export type SubList = Array<{
  content: string | ReactChild;
  onClick: () => void;
  disabled?: boolean;
}>;

interface Props {
  list: SubList;
  children: React.ReactNode;
}

export default function WithSubList(props: Props): React.ReactElement {
  const { list, children } = props;
  const [showList, setShowList] = useState(false);
  const timeout = useRef<number | undefined>();
  const setShow = (show: boolean) => {
    window.clearTimeout(timeout.current);
    if (!show) {
      timeout.current = window.setTimeout(() => setShowList(false), 300);
      return;
    }
    setShowList(true);
  };

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {showList && (
        <div className={styles.list}>
          {list.map(({ content, onClick, disabled }) => {
            const btnClasses = classNames(
              styles.button,
              disabled && styles.disabled
            );
            return (
              <BlankButton
                id={"sublistItem" + content}
                key={"sublistItem" + content}
                onClick={onClick}
                className={btnClasses}
              >
                {content}
              </BlankButton>
            );
          })}
        </div>
      )}
      {children}
    </div>
  );
}
