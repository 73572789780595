import React, { FC } from "react";
import toNumber from "../../../helpers/toNumber";
import Input from "../../common/formComponents/Input";
import PhysicalPortsDropdown from "../../common/OneSystem/PhysicalPortsDropdown";

type Props = {
  system: string;
  isEdit: boolean;
  fieldProps: any;
  fields: { [key: string]: any };
  onChange: (fields: string, value: string | number) => void;
};

const VlanFields: FC<Props> = ({
  isEdit,
  fieldProps,
  fields,
  onChange,
  system,
}) => {
  return (
    <>
      <PhysicalPortsDropdown
        id={"vlanFieldsPortName"}
        selected={fields.physical_port_name}
        onSelect={(val) => onChange("physical_port_name", val)}
        system={system}
      />
      <Input
        {...fieldProps("vlan_id")}
        label="VLAN ID"
        isNotEditable={isEdit}
        valuePreprocess={toNumber}
      />
    </>
  );
};
export default VlanFields;
