import { RouteEntry } from "./types";
import React from "react";
import { TabsProps } from "../helpers/types";
import { EntryFunc } from "../helpers/navigation/entries";
import { Redirect, Route } from "react-router-dom";

type Props = {
  routes: Array<RouteEntry>;
  tabs: React.FC<TabsProps>;
  pathFormer?: (pathFn: EntryFunc) => string;
  additionParameter?: string | number;
  defaultPath?: string;
  type?: any;
};
export const Routes: React.FC<Props> = ({
  routes,
  tabs,
  pathFormer = (fn) => fn().path,
  additionParameter,
  defaultPath,
  type,
}) => {
  return (
    <>
      {routes.map(({ pathFunc, component: Component }) => {
        return (
          <Route path={pathFormer(pathFunc)} key={pathFormer(pathFunc)}>
            <Component
              tabs={tabs}
              additionParameter={additionParameter}
              type={type}
            />
          </Route>
        );
      })}
      {defaultPath && (
        <Route path="/">
          <Redirect to={defaultPath} />
        </Route>
      )}
    </>
  );
};
