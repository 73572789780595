import React, { FC, useMemo, useState } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import {
  INVALID_IP_WITH_PREFIX,
  checkIPv4WithPrefix,
  checkIPv6WithPrefix,
  useValidation,
} from "../../../helpers/validators/Validator";
import { NewDialogProps } from "../../../helpers/types";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";
import Layer3InterfaceDialogContextContainer, {
  useLayer3InterfaceDialogContext,
} from "./Layer3InterfaceDialogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import { Layer3InterfaceCreate } from "../types";
import styles from "./Layer3InterfaceDialog.module.scss";
import Input from "../../../components/common/formComponents/Input";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { capitalizeFirstLetter } from "../../../helpers/common/stringHelpers";
import {
  ADMIN_STATE,
  VIRT_INTERFACE_TYPES,
} from "../../../helpers/common/constantsAlias";
import SystemVIDropdown from "../../../components/common/SystemVIDropdown";
import validateVirtInterface from "../../../helpers/validators/VirtInterfaceValidator";
import { VirtualInterfaceApi } from "../../VirtualInterfacePage/types";
import { useLayer3InterfaceContext } from "../Layer3InterfaceContext";
import TenantSystemDropdown from "./TenantSystemDropdown";
import { Checkbox } from "../../../components/common/formComponents/Checkbox";
import InputWithList from "../../../components/common/formComponents/InputWithList";
import {
  handleInput,
  handleRemoveFromList,
} from "../../../helpers/common/arrayHelpers";

const DEFAULT_FIELDS: Layer3InterfaceCreate = {
  name: "",
  virtual_interface_type: "routed",
  ipv4_addresses: [],
  ipv6_addresses: [],
  description: "",
  mtu: 9220,
  administrative_state: "up",
  nat_virtual_interface_name: "",
  nat_port_block_size: 0,
  is_snat_service: false,
  is_nat_service: false,
  is_ipsec_service: false,
};

type Props = NewDialogProps<Layer3InterfaceCreate> & {
  tenant: string;
  onSubmit?: () => void;
};

const Layer3InterfaceAddDialog: FC<Props> = ({ onClose, tenant, onSubmit }) => {
  const { add, addStatus } = useLayer3InterfaceDialogContext();

  const { fetchList } = useLayer3InterfaceContext();

  const [fields, handleFieldChange] = useFormField<Layer3InterfaceCreate>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<Layer3InterfaceCreate>(
    validateVirtInterface,
    [fields]
  );
  const [inputErrors, setInputErrors] = useState<
    { [key: string]: string } | undefined
  >(undefined);
  const [activeVI, setActiveVI] = useState<VirtualInterfaceApi>();

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const [ipv4, setIPv4] = useState<Array<string>>(fields.ipv4_addresses);
  const [ipv6, setIPv6] = useState<Array<string>>(fields.ipv6_addresses);

  const isGateway = fields.virtual_interface_type === "gateway";
  const isLoopback = fields.virtual_interface_type === "loopback";
  const isShowMTU = isGateway || isLoopback ? false : true;

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const {
        nat_virtual_interface_name,
        nat_port_block_size,
        ...restFields
      } = fields;
      const ips = activeVI?.ip_addresses || [];
      const newFields = isGateway
        ? { ...fields, ipv4_addresses: ips }
        : restFields;
      const success = await add({ ...newFields, tenant } as any);
      if (success) {
        onSubmit && onSubmit();
        onClose();
      }

      fetchList();
    }
  };

  const controls = getDialogControls({
    dialogTitle: "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const leftColumn = (
    <SummaryBlock title={"Virtual Interface"} data={fields} tenant={tenant} />
  );

  return (
    <DialogTemplate
      title={"Add Interface"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <Input {...fieldProps("name")} label="Name" />
        <DropdownBasic
          id={"layer3Type"}
          onChange={(val) =>
            handleFieldChange("virtual_interface_type", val.key.toLowerCase())
          }
          selected={mapStringToItem(
            capitalizeFirstLetter(fields.virtual_interface_type)
          )}
          label="Type"
          itemsList={VIRT_INTERFACE_TYPES.map((key) =>
            mapStringToItem(capitalizeFirstLetter(key))
          )}
          isMedium
        />
        {isLoopback && (
          <TenantSystemDropdown fields={fields} onChange={handleFieldChange} />
        )}
        {isGateway && (
          <>
            <TenantSystemDropdown
              fields={fields}
              onChange={handleFieldChange}
            />
            {/* <Input
              {...fieldProps("nat_port_block_size")}
              label="NAT Port Block Size"
              valuePreprocess={toNumber}
            /> */}
            <div className={styles.gatewayServices}>
              <label className={styles.gatewayLabel}>
                Enabled Gateway services
              </label>
              <div className={styles.gatewayCheckboxes}>
                <Checkbox
                  isChecked={fields.is_nat_service || false}
                  label="NAT"
                  onChange={() => {
                    handleFieldChange("is_nat_service", !fields.is_nat_service);
                    if (!fields.is_nat_service) {
                      handleFieldChange("is_ipsec_service", false);
                    }
                  }}
                />
                <Checkbox
                  isChecked={fields.is_snat_service || false}
                  label="SNAT"
                  onChange={() => {
                    handleFieldChange(
                      "is_snat_service",
                      !fields.is_snat_service
                    );
                    if (!fields.is_snat_service) {
                      handleFieldChange("is_ipsec_service", false);
                      handleFieldChange("is_nat_service", true);
                    }
                  }}
                />
                <Checkbox
                  isChecked={fields.is_ipsec_service || false}
                  label="IPSEC"
                  onChange={() => {
                    handleFieldChange(
                      "is_ipsec_service",
                      !fields.is_ipsec_service
                    );
                    if (!fields.is_ipsec_service) {
                      handleFieldChange("is_snat_service", false);
                      handleFieldChange("is_nat_service", false);
                    }
                  }}
                />
              </div>
            </div>
          </>
        )}
        {isShowMTU && (
          <Input
            {...fieldProps("mtu")}
            label="MTU"
            type="number"
            min={64}
            max={20000}
          />
        )}
      </div>
      <div className={styles.column}>
        <DropdownBasic
          id={"layer3AdminState"}
          onChange={(val) =>
            handleFieldChange("administrative_state", val.key.toLowerCase())
          }
          selected={mapStringToItem(
            capitalizeFirstLetter(fields.administrative_state) || ""
          )}
          label="Admin State"
          itemsList={ADMIN_STATE.map((el) =>
            mapStringToItem(capitalizeFirstLetter(el))
          )}
          isMedium
        />

        <Input
          {...fieldProps("description")}
          label="Description"
          isOptional
          isFullValue
        />

        {isGateway && (
          <SystemVIDropdown
            tenant={tenant}
            system={fields.system_name}
            {...fieldProps("nat_virtual_interface_name")}
            label="Parent Interface"
            onSelect={(vi) => {
              handleFieldChange("nat_virtual_interface_name", vi?.name);
              setActiveVI(vi);
            }}
            selected={activeVI}
          />
        )}
        {!isGateway && (
          <>
            <InputWithList
              name="ipv4_addresses"
              placeholder="X.X.X.X/X"
              valuesList={ipv4}
              onAdd={(val: Array<string>) => {
                handleInput(
                  val,
                  ipv4,
                  setIPv4,
                  "ipv4_addresses",
                  handleFieldChange
                );
              }}
              onRemove={(val: string) =>
                handleRemoveFromList(
                  val,
                  ipv4,
                  setIPv4,
                  "ipv4_addresses",
                  handleFieldChange
                )
              }
              label="IPv4"
              error={
                (errors && errors[`ipv4_addresses`]) ||
                inputErrors?.[`ipv4_addresses`]
              }
              withValidate
              validate={checkIPv4WithPrefix}
              setError={() =>
                setInputErrors({
                  ["ipv4_addresses"]: INVALID_IP_WITH_PREFIX,
                })
              }
              onClear={() =>
                setInputErrors({
                  ["ipv4_addresses"]: "",
                })
              }
              errorWithTooltip={true}
              isOptional
              medium
            />
            <InputWithList
              name="ipv6_addresses"
              placeholder="X::X/X"
              valuesList={ipv6}
              onAdd={(val: Array<string>) => {
                handleInput(
                  val,
                  ipv6,
                  setIPv6,
                  "ipv6_addresses",
                  handleFieldChange
                );
              }}
              onRemove={(val: string) =>
                handleRemoveFromList(
                  val,
                  ipv6,
                  setIPv6,
                  "ipv6_addresses",
                  handleFieldChange
                )
              }
              label="IPv6"
              error={
                (errors && errors[`ip6_addresses`]) ||
                inputErrors?.[`ipv6_addresses`]
              }
              withValidate
              validate={checkIPv6WithPrefix}
              setError={() =>
                setInputErrors({
                  ["ipv6_addresses"]: INVALID_IP_WITH_PREFIX,
                })
              }
              onClear={() =>
                setInputErrors({
                  ["ipv6_addresses"]: "",
                })
              }
              errorWithTooltip={true}
              isOptional
              medium
              singleLabelClassname={styles.ipv6}
            />
          </>
        )}
      </div>
    </DialogTemplate>
  );
};

export default withContexts<Props>(Layer3InterfaceAddDialog, [
  Layer3InterfaceDialogContextContainer,
]);
