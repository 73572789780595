import React from "react";
import { PosProps } from "../utils";

export default function InterfaceConnector({
  from,
  to,
}: {
  from: PosProps;
  to: PosProps;
}): React.ReactElement {
  return <path stroke="#BEB8EA" d={`M${from.x} ${from.y}L${to.x} ${to.y}`} />;
}
