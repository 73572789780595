import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CaretDoubleIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.41931 4L2 8.41931L6.41931 12.8386"
        stroke={color || GREY_FONT}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4545 4L10.0352 8.41931L14.4545 12.8386"
        stroke={color || GREY_FONT}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CaretDoubleIcon;
