export enum Service {
  SEGMENTATION = "segmentation",
  USERSGROUP = "UsersGroup",
  LOAD_BALANCER = "load_balancer",
  NAT = "nat",
  DDOS = "ddos",
  MARKET = "market_place",
  IDS = "ids",
  DPI = "dpi",
}
