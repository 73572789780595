import React, { ReactElement } from "react";
import { BORDER_COLOR, ORANGE, WHITE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CollapseInfoBlockIcon({
  className,
  onClick,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      className={className}
      onClick={onClick}
    >
      <g filter="url(#filter0_d_3525_169235)">
        <circle cx="18.5" cy="14.5" r="14" fill={WHITE} stroke={BORDER_COLOR} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0851 9.90263C17.4757 10.2932 17.4757 10.9263 17.0851 11.3168L13.902 14.5L17.0851 17.6831C17.4757 18.0736 17.4757 18.7068 17.0851 19.0973C16.6946 19.4879 16.0615 19.4879 15.6709 19.0973L11.7807 15.2071C11.3902 14.8166 11.3902 14.1834 11.7807 13.7929L15.6709 9.90263C16.0615 9.51211 16.6946 9.51211 17.0851 9.90263Z"
          fill={ORANGE}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1584 9.90263C24.5489 10.2932 24.5489 10.9263 24.1584 11.3168L20.9752 14.5L24.1584 17.6831C24.5489 18.0736 24.5489 18.7068 24.1584 19.0973C23.7679 19.4879 23.1347 19.4879 22.7442 19.0973L18.8539 15.2071C18.4634 14.8166 18.4634 14.1834 18.8539 13.7929L22.7442 9.90263C23.1347 9.51211 23.7679 9.51211 24.1584 9.90263Z"
          fill={ORANGE}
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3525_169235"
          x="0"
          y="0"
          width="37"
          height="37"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.619608 0 0 0 0 0.65098 0 0 0 0 0.682353 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3525_169235"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3525_169235"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default CollapseInfoBlockIcon;
