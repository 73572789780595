import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function PortIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {color && <circle cx="20" cy="3" r="3" fill={color} />}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 17a1 1 0 0 1-1-1v-2.75h-1.5a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h10.5a1 1 0 0 1 1 1v4.25a1 1 0 0 1-1 1h-1.5V16a1 1 0 0 1-1 1h-5.5Z"
        fill={FONT}
      />
    </svg>
  );
}

export default PortIcon;
