import { FWRuleType } from "../../../helpers/api/apiTypes";
import { RULE_BODY } from "./consts";

export const createAllowAllRule = (
  srcSegment: string,
  dstSegment: string
): Partial<FWRuleType> => {
  const name = `${srcSegment}_${dstSegment}_traffic`;
  const filters = [
    { filter_type: "src_segment", values: srcSegment },
    { filter_type: "dst_segment", values: dstSegment },
  ];
  const description = `Allow all segment traffic ${srcSegment} to${dstSegment}`;

  return {
    ...RULE_BODY,
    name,
    filters,
    priority: 10001,
    description,
    forwarding_policy: "ACCEPT",
  };
};
