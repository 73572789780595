import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import VNIIcon from "../../../components/icons/VNIIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { VtepVni } from "../types";

type SumProps = {
  title: string;
  data: VtepVni;
};

const SummaryBlock: FC<SumProps> = ({ title, data }) => {
  return (
    <SummaryInfoBlock icon={<VNIIcon />} title={title} isSmallerIcon>
      <UnifiedTextPair subtitle="Name" text={data.name} />
      <UnifiedTextPair subtitle="Alias" text={data?.alias} />
      <UnifiedTextPair subtitle="VTEP" text={data.vtep_name} />
      <UnifiedTextPair
        subtitle="Virtual Interface"
        text={data.virtual_interface_name}
      />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
