import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withContexts } from "../../helpers/hocs/withContexts";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import { APPLICATIONS_STAT, DNS } from "../../helpers/navigation/entries";
import { Routes } from "../common";
import DnsFilters from "../DnsDashboard/components/Filters";
import DnsDashboardContextContainer from "../DnsDashboard/DnsContext";
import DnsStatistics from "../DnsDashboard/DnsStatistics";
import { RouteEntry } from "../types";
import ApplicationsDashboardContextContainer from "./ApplicationObservabilityContext";
import ApplicationsStatistics from "./ApplicationsStatistics";
import AppFilters from "./components/Filters";

type Props = { tabs: any };

const ApplicationsDashboard: FC<Props> = ({ tabs: Tabs }) => {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(APPLICATIONS_STAT(), path)} />
      </Route>
      <Routes
        routes={dashboardRoutes}
        tabs={Tabs as any}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default withContexts<Props>(ApplicationsDashboard, [
  ApplicationsDashboardContextContainer,
  DnsDashboardContextContainer,
]);

const Applications: FC<{ tabs: any }> = ({ tabs: Tabs }) => {
  return (
    <>
      <Tabs filter={<AppFilters />} />
      <ApplicationsStatistics />
    </>
  );
};

const Dns: FC<{ tabs: any }> = ({ tabs: Tabs }) => {
  return (
    <>
      <Tabs filter={<DnsFilters />} />
      <DnsStatistics />
    </>
  );
};

const dashboardRoutes: Array<RouteEntry> = [
  { pathFunc: APPLICATIONS_STAT, component: Applications },
  { pathFunc: DNS, component: Dns },
];
