import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function LagIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 2.33398C0 2.05784 0.223858 1.83398 0.5 1.83398H8.66689C8.94303 1.83398 9.16689 2.05784 9.16689 2.33398V6.83412C9.16689 7.11026 8.94303 7.33412 8.66689 7.33412H6.41741V8.66833C6.41741 8.94447 6.19355 9.16833 5.91741 9.16833H3.25065C2.97451 9.16833 2.75065 8.94447 2.75065 8.66833V7.33412H0.5C0.223857 7.33412 0 7.11026 0 6.83412V2.33398ZM0.000133753 13.334C0.000133753 13.0579 0.223991 12.834 0.500134 12.834H8.66703C8.94317 12.834 9.16702 13.0579 9.16702 13.334V17.8341C9.16702 18.1103 8.94317 18.3341 8.66702 18.3341H6.41741V19.6666C6.41741 19.9428 6.19355 20.1666 5.91741 20.1666H3.25065C2.97451 20.1666 2.75065 19.9428 2.75065 19.6666V18.3341H0.500134C0.223991 18.3341 0.000133753 18.1103 0.000133753 17.8341V13.334ZM13.3335 1.83398C13.0574 1.83398 12.8335 2.05784 12.8335 2.33398V6.83412C12.8335 7.11026 13.0574 7.33412 13.3335 7.33412H15.5833V8.66833C15.5833 8.94447 15.8072 9.16833 16.0833 9.16833H18.7501C19.0262 9.16833 19.2501 8.94447 19.2501 8.66833V7.33412H21.5004C21.7765 7.33412 22.0004 7.11026 22.0004 6.83412V2.33398C22.0004 2.05784 21.7765 1.83398 21.5004 1.83398H13.3335ZM12.8336 13.334C12.8336 13.0579 13.0574 12.834 13.3336 12.834H21.5005C21.7766 12.834 22.0005 13.0579 22.0005 13.334V17.8341C22.0005 18.1103 21.7766 18.3341 21.5005 18.3341H19.2501V19.6666C19.2501 19.9428 19.0262 20.1666 18.7501 20.1666H16.0833C15.8072 20.1666 15.5833 19.9428 15.5833 19.6666V18.3341H13.3336C13.0574 18.3341 12.8336 18.1103 12.8336 17.8341V13.334Z"
          fill={color || FONT}
        />
      </g>
    </svg>
  );
}

export default LagIcon;
