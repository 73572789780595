import { createContextAndUseWithExtra } from "../../../../../contexts/common/AbstractCrudContext";
import { AbstractContextProvider } from "../../../../../contexts/common/AbstractContext";
import { tenantApi } from "../../../../../helpers/api/TenantApi";
import { Layer3InterfaceNew } from "../../../../../pages/Layer3Interface/types";
import { networkApi } from "../../../../../helpers/api/networkApi";
import { configApi } from "../../../../../helpers/api/ConfigApi";
import {
  USERS_BTN,
  CONNECTIONS_BTN,
  CYBER_THREATS_BTN,
  SEGMENTS_BTN,
} from "./topologyButtonsConsts";
import { elasticApi } from "../../../../../helpers/api/ElasticApi";
import { CardField } from "../../../../../components/common/Card/cardTypes";
import { GTimeRange } from "../../../../../components/common/charts/GraphanaLinksFactory";
import { userApi } from "../../../../../helpers/api/UserApi";
import { formPathFromArray } from "../../../../../helpers/navigation";
import {
  NETWORK,
  REMOTE_USERS,
  SEGMENTATION,
  SEGMENTS,
  SERVICES,
  USER_GROUPS,
} from "../../../../../helpers/navigation/entries";

type RequestStatuses = {};
type State = { cardsData: CardField };

type IState = State & RequestStatuses;
type IFunc = {
  fetchCardsData: (tenant: string, timeRange: GTimeRange) => Promise<void>;
};

const [TopologyButtonsContext, useContext] = createContextAndUseWithExtra<
  IState,
  RequestStatuses,
  IFunc
>();
export const useTopologyButtonsContext = useContext;

export default class TopologyButtonsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = TopologyButtonsContext;

  fetchCardsData = async (
    tenant: string,
    timeRange: GTimeRange
  ): Promise<void> => {
    const connections = await this._getConnections(tenant);
    const segments = await this._getSegments(tenant);
    const cyberThreats = await this._getCyberThreats(timeRange, tenant);
    const appObservability = await this._getAppObservability(timeRange, tenant);
    const users = await this._getUsers(tenant);
    this.setState({
      cardsData: {
        ...connections,
        ...segments,
        ...cyberThreats,
        ...appObservability,
        ...users,
      },
    });
  };

  _getConnections = async (tenant: string): Promise<CardField> => {
    const { ok, result } = await tenantApi.getVirtualInterfacesAllTypes(tenant);

    const clouds: Array<Layer3InterfaceNew> = [];
    const branches: Array<Layer3InterfaceNew> = [];
    if (ok && result) {
      result.map((vi) => {
        const remote_type = vi.labels?.remote_type || "";
        if (
          /^aws/.test(remote_type) ||
          /^azure/.test(remote_type) ||
          /^gcp/.test(remote_type)
        ) {
          clouds.push(vi);
        }
        if (
          vi.type === "Virtual Interface IPSEC gateway" ||
          /^branch/.test(remote_type) ||
          /^data_center/.test(remote_type) ||
          /^colo/.test(remote_type) ||
          /^bareMetal/.test(remote_type)
        ) {
          branches.push(vi);
        }
      });
    }
    return {
      [CONNECTIONS_BTN]: [
        { title: "VPCs/VNETs", number: clouds.length },
        { title: "Branchs/DCs", number: branches.length },
      ],
    };
  };

  _getAppObservability = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<CardField> => {
    const { ok, result } = await elasticApi.getAppObservability(
      timeRange,
      tenantName
    );
    const hostnames = ok && result ? result["1"].value : 0;
    const sourceIp = ok && result ? result["2"].value : 0;

    return {
      [USERS_BTN]: [
        { title: "Source IPs", number: sourceIp },
        { title: "Host names", number: hostnames },
      ],
    };
  };

  _getUsers = async (tenantName: string): Promise<CardField> => {
    const { result: allUsers } = await userApi.getTenantUsers(tenantName);
    const allRemoteusers = allUsers?.reduce((countUsers, oneUser) => {
      if (oneUser.is_remote_user) {
        return countUsers + 1;
      } else {
        return countUsers;
      }
    }, 0);
    const enabledServicesRes = await tenantApi.getEnabledServices(tenantName);
    const remoteUserService = enabledServicesRes.result?.services.find(
      (serviceAPI) => serviceAPI.type === "remote_users" && serviceAPI.enable
    );
    const systemName = remoteUserService?.data.wg_conf[0].system_name;
    let tunnels = 0;
    if (!systemName) {
      tunnels = 0;
    } else {
      const { result: resTunnels } = await configApi.getWireGuardStatus(
        tenantName,
        systemName
      );
      const itemsNew: Array<{
        name: string;
        endpoint_ip: string;
        latestHandshake: string;
        psk: string;
        public_key: string;
        status: string;
        system_name: string;
        transferRx: number;
        transferTx: number;
        tunnel_id: string;
        tunnel_ip: string;
        user_name: string;
      }> = resTunnels?.items;
      const rawTunnelsCount = itemsNew?.map((obj) => obj.name);
      const nameDevices = rawTunnelsCount?.filter((name) => name != "error");
      tunnels = nameDevices?.length;
    }
    return {
      [USERS_BTN]: [
        { title: "Users", number: allRemoteusers || 0 },
        { title: "Tunnels", number: tunnels || 0 },
      ],
    };
  };

  _getSegments = async (tenantName: string): Promise<CardField> => {
    const { result: segments } = await networkApi.getSegmentations(tenantName);
    const { result: groups } = await userApi.getUsersGroupsList(tenantName);
    return {
      [SEGMENTS_BTN]: [
        {
          title: "Segments",
          number: segments?.count || 0,
          link: formPathFromArray([SERVICES(), SEGMENTATION(), SEGMENTS()]),
        },
        {
          title: "Groups",
          number: groups?.count || 0,
          link: formPathFromArray([NETWORK(), REMOTE_USERS(), USER_GROUPS()]),
        },
      ],
    };
  };

  _getCyberThreats = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<CardField> => {
    const { ok, result } = await elasticApi.getCyberThreats(
      timeRange,
      tenantName
    );
    const cyberThreats = ok && result ? result["1"].value : 0;
    const hosts = ok && result ? result["2"].value : 0;

    return {
      [CYBER_THREATS_BTN]: [
        { title: "Cyber threats", number: cyberThreats },
        { title: "Host effected", number: hosts },
      ],
    };
  };

  funcs = { fetchCardsData: this.fetchCardsData };
}
