import { useCallback, useEffect, useState } from "react";
import { VRFType } from "../apiTypes";
import { configApi } from "../ConfigApi";
import { ERROR, OK, PENDING } from "../../common/RequestStateHelpers";

import { RequestState } from "../../types";

type Type = [Array<VRFType> | null, RequestState];

export const useVRFList = (systemName: string): Type => {
  const [requestStatus, setRequestStatus] = useState<RequestState>(PENDING);
  const [list, setList] = useState<Array<VRFType> | null>(null);

  const fetchList = useCallback(async () => {
    setRequestStatus("pending");
    const { ok, result } = await configApi.getVRFList(systemName);

    const isError = !ok || !result;

    setList(isError ? null : result);
    setRequestStatus(isError ? ERROR : OK);
  }, [systemName]);

  useEffect(() => {
    fetchList();
  }, [systemName]);

  return [list, requestStatus];
};
