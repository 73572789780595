import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom";

type Props = { isOpen: any };

const DropdownListContainer: FC<Props> = ({ isOpen, children }) => {
  const [isClient, setIsClient] = useState(false);

  // Set isClient to true when the component is mounted in the browser
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient) {
    return isOpen ? ReactDOM.createPortal(children, document.body) : null;
  } else {
    return null;
  }
};

export default DropdownListContainer;
