import { ONE_HOUR_MS } from "../components/graphs_d3/consts";
import { TimeRangeType } from "./types";
// todo replace with below
const getCount = (from?: string, to?: string): number | undefined => {
  if (!(from && to)) return undefined;
  const countPerHour = 360;
  const fromMs = new Date(from).getTime();
  const toMs = new Date(to).getTime();
  return (countPerHour * (toMs - fromMs)) / ONE_HOUR_MS;
};

export const newGetCount = (range?: TimeRangeType): number | undefined => {
  if (!(range?.from && range?.to)) return undefined;
  const countPerHour = 360;
  const fromMs = new Date(range.from).getTime();
  const toMs = new Date(range.to).getTime();
  return (countPerHour * (toMs - fromMs)) / ONE_HOUR_MS;
};

export default getCount;
