import React, { FC, useEffect, useState } from "react";
import { useBGPServicesContext } from "../../ServicesBGPContext";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { useValidation } from "../../../../helpers/validators/Validator";
import { validateNeighbor } from "../../../../helpers/validators/BGPServiceValidator";
import GeneralSection from "./components/GeneralSection";
import AddressFamilySection from "./components/AddressFamilySection";
import BGPConfigFooter from "./common/BGPConfigFooter";
import TipsBlock from "./common/TipsBlock";
import BGPInfo from "./common/BGPInfo";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import AdvancedSection from "./components/AdvancedSection";
import styles from "./ServiceBGPConfig.module.scss";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { useHistory, useParams } from "react-router-dom";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import { RouteFiltersFields } from "../../types";

const ServiceBGPConfigEdit: FC = () => {
  const tenant = localStorage.getItem("selectedTenant");
  const { ip } = useParams<{ ip?: string }>();
  const history = useHistory();
  const {
    editNeighbor,
    editStatus,
    resetStatus,
    neighbor,
    neighborStatus,
    getNeighbor,
    neighborBgpStatus,
  } = useBGPServicesContext();
  const { selectedTenant } = useGlobalFilterContext();

  const [routeFilters, setRouteFilters] = useState<RouteFiltersFields>({
    in: "allow-all",
    out: "allow-all",
  });
  const [fields, handleFieldChange, resetFields] = useFormField<any>(undefined);
  const [errors, validate] = useValidation<any>(validateNeighbor, [fields]);
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);

  useEffect(() => {
    if (tenant && ip) {
      getNeighbor(ip, tenant);
    }
  }, [tenant, ip]);

  useEffect(() => {
    if (neighbor) {
      resetFields(neighbor);
      setIsAdvanced(
        neighbor.keepalive >= 0 ||
          neighbor.hold >= 0 ||
          neighbor.open_delay >= 0
      );
      const routeIn = !neighbor.pfx_list_in
        ? "allow-all"
        : neighbor.pfx_list_in;
      const routeOut = !neighbor.pfx_list_out
        ? "allow-all"
        : neighbor.pfx_list_out;
      setRouteFilters({ in: routeIn, out: routeOut });
    }
  }, [neighbor]);

  const onClose = () => {
    history.goBack();
  };

  const handleChangeRouteFilters = (key: string, value: string) => {
    const newValues = { ...routeFilters, [key]: value };
    setRouteFilters(newValues);
  };

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && tenant) {
      const pfx_list_in =
        routeFilters.in === "allow-all" ? "" : routeFilters.in;
      const pfx_list_out =
        routeFilters.out === "allow-all" ? "" : routeFilters.out;
      const success = await editNeighbor(tenant, {
        ...fields,
        pfx_list_in,
        pfx_list_out,
      });
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
    resetStatus();
  };

  if (neighborStatus?.state === "pending" || !fields) return <PageLoader />;

  return (
    <div className={styles.content}>
      <BGPInfo
        fields={{ selectedTenant, neighbor: fields.remote_router_ip }}
        className={styles.basicBlock}
      />
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <GeneralSection
            className={styles.sectionWrapper}
            fields={fields}
            onChange={handleFieldChange}
            errors={errors}
            isEdit
          />
          <AddressFamilySection
            className={styles.sectionWrapper}
            fields={fields}
            onChange={handleFieldChange}
            errors={errors}
            neighborBgpStatus={neighborBgpStatus}
            routeFilters={routeFilters}
            onChangeRouteFilters={handleChangeRouteFilters}
          />
          {isAdvanced && (
            <AdvancedSection
              className={styles.sectionWrapper}
              fields={fields}
              onChange={handleFieldChange}
              errors={errors}
            />
          )}
        </div>
        <BGPConfigFooter
          isAddMode={true}
          onAdd={handleSubmit}
          onClose={handleClose}
          className={styles.footerWrapper}
          status={editStatus}
          isAdvanced={isAdvanced}
          onToggleAdvanced={() => setIsAdvanced((prev) => !prev)}
        />
      </div>
      <TipsBlock className={styles.basicBlock} />
    </div>
  );
};

export default withLayout(ServiceBGPConfigEdit);
