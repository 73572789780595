import { ReactElement } from "react";
import LocationFillIcon from "../../../../components/icons/LocationFillIcon";
import StatusIcon from "../../../../components/icons/StatusIcon";
import { IconProps } from "../../../../helpers/types";
import ElanIcon from "../../../../components/icons/ElanIcon";
import DataCenterPortIcon from "../../../../components/icons/DataCenterPortIcon";
import GCPLogo from "../../../../components/icons/zayo/clouds/GCPLogo";
import AWSLogo from "../../../../components/icons/zayo/clouds/AWSLogo";
import AzureLogo from "../../../../components/icons/zayo/clouds/AzureLogo";
import CloudRouterIcon from "../../../../components/icons/zayo/menu/CloudRouterIcon";
import SalesforceLogo from "../../../../components/icons/zayo/clouds/SalesforceLogo";
import IBMCloudLogo from "../../../../components/icons/zayo/clouds/IBMCloudLogo";
import OracleLogo from "../../../../components/icons/zayo/clouds/OracleLogo";
import DIAIcon from "../../../../components/icons/DIAIcon";

type IconType = (props: IconProps) => ReactElement;

export const getPortTypeIcon = (id: string): IconType => {
  switch (id?.toUpperCase()) {
    case "DC_PORT":
      return DataCenterPortIcon;
    case "ELAN":
      return ElanIcon;
    case "AWS":
      return AWSLogo;
    case "GCP":
      return GCPLogo;
    case "AZURE":
      return AzureLogo;
    case "SALESFORCE":
      return SalesforceLogo;
    case "IBMCLOUD":
      return IBMCloudLogo;
    case "ORACLE":
      return OracleLogo;
    case "NID":
      return LocationFillIcon;
    case "ROUTER":
    case "CLOUD_ROUTER":
      return CloudRouterIcon;
    case "DIA":
      return DIAIcon;
    default:
      return StatusIcon;
  }
};
