import React, { FC } from "react";
import { Toggle } from "../../../../../components/common/Toggle";
import DialogBtmButtons from "../../../../../components/dialogs/common/DialogBtmButtons";
import { CANCEL } from "../../../../../helpers/common/constantsAlias";
import { RequestStatus } from "../../../../../helpers/types";
import styles from "./Footer.module.scss";

type Props = {
  isAdvanced: boolean;
  onToggle: () => void;
  onAction: () => void;
  action: string;
  onClose: () => void;
  disableSubmit?: boolean;
  disableAdvanced?: boolean;
  status?: RequestStatus;
};

const Footer: FC<Props> = ({
  isAdvanced,
  onToggle,
  onAction,
  action,
  onClose,
  disableSubmit,
  status,
  disableAdvanced,
}) => {
  return (
    <div className={styles.wrapper}>
      <Toggle
        isChecked={isAdvanced}
        onChange={onToggle}
        label="Advanced"
        disabled={disableAdvanced}
      />
      <DialogBtmButtons
        controls={{
          okText: action,
          onOk: onAction,
          cancelText: CANCEL,
          onCancel: onClose,
          disableSubmit,
        }}
        errorDisplay={status}
      />
    </div>
  );
};

export default Footer;
