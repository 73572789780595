import { systemApi } from "./SystemApi";
import { flatMembersList } from "./../flatMembersList";
import {
  Layer3Interface,
  Layer3InterfaceApi,
  Layer3InterfaceApiNew,
  Layer3InterfaceCreate,
  Layer3InterfaceNew,
} from "./../../pages/Layer3Interface/types";
import {
  SortType,
  TenantType,
  PaginateListResponse,
  StaticRoutesBodyType,
  EditVirtualInterfaceType,
  QuotaType,
  ChangeQuota,
  TenantQuotaType,
  ServiceIdsType,
  FilterAndSort,
  ServiceDpiType,
} from "./apiTypes";
import Api from "./Api";
import getManualSortedData from "../getManualSortedData";
import {
  IPSEC_PATH,
  AWS_ACCESS_KEY,
  AZURE_ACCESS_KEY,
  LOCATIONS,
  QUOTA,
  SERVICE_AWS,
  SERVICE_DPI,
  SERVICE_IDS,
  TENANT,
  TENANT_STATIC_ROUTE,
  VIRTUAL_INTERFACE_TENANT,
  SERVICES_PREFERENCES,
  VROUTER_SERVICE,
  SERVICE_AZURE,
  ARUBA_ACCESS_KEY,
  ARUBA_BROKER,
} from "./apiPaths";
import filterAndSort from "../common/filterAndSorting";
import {
  InternetIpsec,
  AWSAccessKey,
  AzureAccessKey,
  LocationsList,
  LocationsListApi,
  VPC,
  AWSVPNBody,
  VNET,
  ArubaAccessKey,
  ArubaConnectCreate,
} from "../../pages/WizardToolPage/types";
import { getCombinedVirtualInterfacesNAT } from "../getCombinedVirtualInterfacesNAT";
import { getFormattedLocations } from "../getFormattedLocations";
import { LocationType } from "../../pages/WizardToolPage/ConnectionStep/ConnectionsContext";
import { VirtualInterface } from "../../pages/VirtualInterfacePage/types";
import { ServicesPreferencesAPI } from "../../pages/ServicesPreferencesPage/types";
import { TenantServices } from "../../pages/TenantsPage/types";

type Res<P> = {
  ok: boolean;
  error?: string;
  result?: P;
  count?: number;
  totalCount?: number;
};
type PaginalRes<P> = Res<PaginateListResponse<P>>;
const getListCount = (res: PaginalRes<any>): number => res.result?.count || 0;

export class TenantApi extends Api {
  constructor() {
    super();
  }

  // TENANT
  async getTenantsList(
    params?: FilterAndSort,
    offset?: number,
    limit?: number
  ): Promise<Res<Array<TenantType>>> {
    const res = await this.fetchBroker<PaginateListResponse<TenantType>>(
      TENANT.LIST(offset, limit)
    );
    const data = res.result?.items || [];
    // const data = HardcodeApi.getTenantsList();
    return {
      ...res,
      result: filterAndSort(data, params),
      count: res.result?.count,
      totalCount: res.result?.total_count,
    };
  }

  async getTenant(name: string): Promise<Res<TenantType>> {
    return await this.fetchBroker<TenantType>(TENANT.GET(name));
  }

  async getTenantsThreshold(name: string): Promise<Res<any>> {
    return await this.fetchBroker<any>(TENANT.THRESHOLD_LIST(name));
  }

  async addTenant(params: Partial<TenantType>): Promise<Res<string>> {
    return await this.fetchBroker(TENANT.ADD(), {
      body: JSON.stringify(params),
    });
  }

  async editTenant(
    tenant: string,
    params: Partial<TenantType>
  ): Promise<Res<string>> {
    return await this.fetchBroker(TENANT.EDIT(tenant), {
      body: JSON.stringify(params),
    });
  }

  async editTenantContact(name: string, params: string): Promise<Res<string>> {
    return await this.fetchBroker(TENANT.EDIT_CONTACT(name, params));
  }

  async editTenantLocations(
    tenant: string,
    locations: Array<string>
  ): Promise<Res<string>> {
    const params = {
      systems_to_add: locations,
    };
    return await this.fetchBroker(TENANT.EDIT_LOCATIONS(tenant), {
      body: JSON.stringify(params),
    });
  }

  async removeSystems(
    tenant: string,
    locations: Array<string>
  ): Promise<Res<string>> {
    const params = {
      systems_to_remove: locations,
    };
    return await this.fetchBroker(TENANT.REMOVE_SYSTEMS(tenant), {
      body: JSON.stringify(params),
    });
  }

  async editTenantQuotas(name: string, params: string): Promise<Res<string>> {
    return await this.fetchBroker(TENANT.EDIT_QUOTAS(name, params));
  }

  async editTenantServices(
    name: string,
    params: TenantServices
  ): Promise<Res<TenantType>> {
    return await this.fetchBroker(TENANT.EDIT_SERVICES(name), {
      body: JSON.stringify(params),
    });
  }

  async deleteTenant(name: string): Promise<Res<string>> {
    return await this.fetchBroker(TENANT.DELETE(name));
  }

  async getVirtualInterfaces(
    tenant: string,
    sortBy?: SortType
  ): Promise<Res<Array<Layer3Interface>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<Layer3InterfaceApi>
    >(VIRTUAL_INTERFACE_TENANT.LIST(tenant));
    const data = flatMembersList(res.result?.items || []);
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getIPsecVis(
    tenant: string
  ): Promise<Res<PaginateListResponse<InternetIpsec>>> {
    return await this.fetchBroker<PaginateListResponse<InternetIpsec>>(
      IPSEC_PATH.GET_ALL_VIS(tenant)
    );
  }

  async getIPsecGateway(
    tenant: string
  ): Promise<Res<PaginateListResponse<VirtualInterface>>> {
    return await this.fetchBroker<PaginateListResponse<VirtualInterface>>(
      IPSEC_PATH.GET_ALL_GATEWAYS(tenant)
    );
  }

  async getVirtualInterfacesAllTypes(
    tenant: string,
    sortBy?: SortType
  ): Promise<Res<Array<Layer3InterfaceNew>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<Layer3InterfaceNew>
    >(VIRTUAL_INTERFACE_TENANT.LIST_ALL(tenant));
    const data = flatMembersList(res.result?.items || []);
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getVirtualInterfacesNat(
    tenant: string,
    sortBy?: SortType
  ): Promise<Res<Array<any>>> {
    const res = await this.fetchBroker<PaginateListResponse<any>>(
      VIRTUAL_INTERFACE_TENANT.LIST_NAT(tenant)
    );
    const data = res.result?.items || [];
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getVirtualInterfacesWithInternet(
    tenant: string,
    sortBy?: SortType
  ): Promise<Res<Array<Layer3InterfaceNew>>> {
    const res = await this.fetchBroker<
      PaginateListResponse<Layer3InterfaceApiNew>
    >(VIRTUAL_INTERFACE_TENANT.LIST_ALL(tenant));
    const enabledServicesRes = await tenantApi.getEnabledServices(tenant);
    const remoteUserService = enabledServicesRes.result?.services.find(
      (serviceAPI) => serviceAPI.type === "remote_users" && serviceAPI.enable
    );
    const systemName = remoteUserService?.data.wg_conf.system_name;
    const data = await getCombinedVirtualInterfacesNAT(
      res.result?.items,
      tenant,
      systemName
    );
    return { ...res, result: getManualSortedData(data, sortBy) };
  }

  async getVirtualInterface(
    tenant_name: string,
    vi_name: string
  ): Promise<Res<any>> {
    return await this.fetchBroker<any>(
      VIRTUAL_INTERFACE_TENANT.GET(tenant_name, vi_name)
    );
  }

  async addVirtualInterfaces(
    params: Partial<Layer3InterfaceCreate>,
    name: string
  ): Promise<Res<any>> {
    return await this.fetchBroker(VIRTUAL_INTERFACE_TENANT.ADD(name), {
      body: JSON.stringify(params),
    });
  }

  async editVirtualInterfaces(
    tenant_name: string,
    vi_name: string,
    params: EditVirtualInterfaceType
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      VIRTUAL_INTERFACE_TENANT.EDIT(tenant_name, vi_name),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async deleteVirtualInterfaces(
    tenant_name: string,
    vi_name: string
  ): Promise<Res<void>> {
    return await this.fetchBroker(
      VIRTUAL_INTERFACE_TENANT.DELETE(tenant_name, vi_name)
    );
  }

  async getTenantStaticRoutes(
    tenantName: string,
    offset: number,
    skip: number,
    sortBy?: SortType
  ): Promise<PaginalRes<StaticRoutesBodyType>> {
    const res = await this.fetchBroker<
      PaginateListResponse<StaticRoutesBodyType>
    >(TENANT_STATIC_ROUTE.LIST(tenantName, offset, skip));
    const data = res.result?.items || [];
    return {
      ...res,
      result: {
        count: getListCount(res),
        items: getManualSortedData(data, sortBy),
      },
    };
  }

  async addStaticRoutes(
    tenant_name: string,
    params: Partial<StaticRoutesBodyType>
  ): Promise<Res<any>> {
    // todo old style of api
    const res = await this.fetchBroker(
      TENANT_STATIC_ROUTE.EDIT(tenant_name, ""),
      {
        body: JSON.stringify(params),
      }
    );
    return { ...res };
  }

  async deleteStaticRoutes(
    tenant_name: string,
    params: Partial<StaticRoutesBodyType>
  ): Promise<any> {
    // todo old style of api
    const res = await this.fetchBroker(
      TENANT_STATIC_ROUTE.DELETE(tenant_name, ""),
      {
        body: JSON.stringify(params),
      }
    );
    return { ...res };
  }

  async getTenantDynamicRoutes(
    tenantName: string,
    offset: number,
    skip: number,
    sortBy?: SortType
  ): Promise<PaginalRes<any>> {
    const res = await this.fetchBroker<PaginateListResponse<any>>(
      VROUTER_SERVICE.LIST_ROUTES(tenantName, offset, skip)
    );
    const data = res.result?.items || [];
    return {
      ...res,
      result: {
        count: getListCount(res),
        items: getManualSortedData(data, sortBy),
      },
    };
  }

  async getQuota(quotaPackage: string): Promise<Res<QuotaType>> {
    return await this.fetchBroker<QuotaType>(QUOTA.GET(quotaPackage));
  }
  async editQuota(
    quotaPackage: string,
    params: Partial<QuotaType>
  ): Promise<Res<QuotaType>> {
    return await this.fetchBroker<QuotaType>(QUOTA.EDIT(quotaPackage), {
      body: JSON.stringify(params),
    });
  }
  async getTenantQuota(tenant: string): Promise<Res<TenantQuotaType>> {
    return await this.fetchBroker<TenantQuotaType>(
      QUOTA.GET_TENANT_QUOTA(tenant)
    );
  }
  async editTenantQuota(
    tenant: string,
    params: ChangeQuota
  ): Promise<Res<QuotaType>> {
    return await this.fetchBroker<QuotaType>(QUOTA.EDIT_TENANT_QUOTA(tenant), {
      body: JSON.stringify(params),
    });
  }
  async editTenantQuotaValues(
    tenant: string,
    params: Partial<QuotaType>
  ): Promise<Res<QuotaType>> {
    return await this.fetchBroker<QuotaType>(
      QUOTA.EDIT_TENANT_QUOTA_VAL(tenant),
      {
        body: JSON.stringify(params),
      }
    );
  }

  async getServiceIds(tenant: string): Promise<Res<ServiceIdsType>> {
    const token = localStorage.getItem("token");
    return await this.fetchBroker<ServiceIdsType>(SERVICE_IDS.GET(tenant), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async editServiceIds(
    tenant: string,
    value: boolean
  ): Promise<Res<ServiceIdsType>> {
    const token = localStorage.getItem("token");

    return await this.fetchBroker<ServiceIdsType>(SERVICE_IDS.EDIT(tenant), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      body: new URLSearchParams({
        enable_all: value.toString(),
      }),
    });
  }

  async getServiceDpi(tenant: string): Promise<Res<ServiceDpiType>> {
    const token = localStorage.getItem("token");
    return await this.fetchBroker<ServiceDpiType>(SERVICE_DPI.GET(tenant), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
    });
  }
  async editServiceDpi(
    tenant: string,
    value: boolean
  ): Promise<Res<ServiceDpiType>> {
    const token = localStorage.getItem("token");

    return await this.fetchBroker<ServiceDpiType>(SERVICE_DPI.EDIT(tenant), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${token}`,
      },
      body: new URLSearchParams({
        enable: value?.toString(),
      }),
    });
  }

  async getLocationsApi(): Promise<Res<Array<LocationsListApi>>> {
    const res = await this.fetchBroker<PaginateListResponse<LocationsListApi>>(
      LOCATIONS.GET()
    );
    const data = res.result?.items || [];
    return { ...res, result: data };
  }

  async getLocations(): Promise<Res<Array<LocationsList>>> {
    const res = await this.fetchBroker<PaginateListResponse<LocationsListApi>>(
      LOCATIONS.GET()
    );
    const data = await getFormattedLocations(res.result?.items || []);
    return { ...res, result: data };
  }

  async getTenantLocations(tenantName: string): Promise<Array<LocationType>> {
    const locations: Array<LocationType> = [];
    const tenant = await tenantApi.getTenant(tenantName);
    const systems = await systemApi.getSystemsList();
    if (tenant.ok && tenant.result && systems.ok && systems.result) {
      const tenantSys = tenant.result.systems;
      systems.result.map((sys) => {
        if (tenantSys.includes(sys.name)) {
          locations.push({ system: sys.name, location: sys.location });
        }
      });
    }
    return locations;
  }

  async getAWSVPCListApi(
    tenant: string,
    region: string
  ): Promise<Res<Array<VPC>>> {
    const res = await this.fetchBroker<Array<VPC>>(
      SERVICE_AWS.GET(tenant, region)
    );
    return res;
  }

  async getAWSAccessKey(tenant: string): Promise<Res<AWSAccessKey>> {
    const res = await this.fetchBroker<AWSAccessKey>(
      AWS_ACCESS_KEY.GET(tenant)
    );
    return res;
  }

  async deleteAWSAccessKey(tenant: string): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(
      AWS_ACCESS_KEY.DELETE(tenant, "")
    );
    return res;
  }

  async createAWSAccessKey(
    params: AWSAccessKey,
    tenant: string
  ): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(AWS_ACCESS_KEY.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }
  // to do check this function
  async createAWSVPN(params: AWSVPNBody, tenant: string): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(SERVICE_AWS.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async getAzureAccessKey(tenant: string): Promise<Res<AzureAccessKey>> {
    const res = await this.fetchBroker<AzureAccessKey>(
      AZURE_ACCESS_KEY.GET(tenant)
    );
    return res;
  }

  async createAzureAccessKey(
    params: AzureAccessKey,
    tenant: string
  ): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(AZURE_ACCESS_KEY.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async deleteAzureAccessKey(tenant: string): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(
      AZURE_ACCESS_KEY.DELETE(tenant, "")
    );
    return res;
  }

  async getAzureVNETListApi(
    tenant: string,
    region: string
  ): Promise<Res<Array<VNET>>> {
    const res = await this.fetchBroker<Array<VNET>>(
      SERVICE_AZURE.GET(tenant, region)
    );
    return res;
  }

  async getEnabledServices(
    tenant: string
  ): Promise<Res<ServicesPreferencesAPI>> {
    const res = await this.fetchBroker<ServicesPreferencesAPI>(
      SERVICES_PREFERENCES.GET(tenant)
    );
    return res;
  }

  async editEnabledServices(
    tenant: string,
    params: ServicesPreferencesAPI
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(SERVICES_PREFERENCES.EDIT(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  // SDWAN
  async getArubaAccessKey(tenant: string): Promise<Res<ArubaAccessKey>> {
    const res = await this.fetchBroker<ArubaAccessKey>(
      ARUBA_ACCESS_KEY.GET(tenant)
    );
    return res;
  }

  async deleteArubaAccessKey(tenant: string): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(
      ARUBA_ACCESS_KEY.DELETE(tenant, "")
    );
    return res;
  }

  async createArubaAccessKey(
    params: ArubaAccessKey,
    tenant: string
  ): Promise<Res<string>> {
    const res = await this.fetchBroker<string>(ARUBA_ACCESS_KEY.POST(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async discoverAruba(tenant: string): Promise<Res<any>> {
    return await this.fetchBroker(ARUBA_BROKER.DISCOVER(tenant));
  }

  async connectAruba(
    params: ArubaConnectCreate,
    tenant: string
  ): Promise<Res<any>> {
    const res = await this.fetchBroker<any>(ARUBA_BROKER.CONNECT(tenant), {
      body: JSON.stringify(params),
    });
    return res;
  }

  async createDiaService(fields: any, tenant: string): Promise<Res<any>> {
    return await this.fetchBroker(TENANT.CREATE_DIA(tenant), {
      body: JSON.stringify(fields),
    });
  }
}

export const tenantApi = new TenantApi();
