import { VirtualInetrfaceBridgeDomainAdd } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";

const DEFAULT_BODY: Partial<VirtualInetrfaceBridgeDomainAdd> = {
  administrative_state: "up",
  labels: {},
};

export const getDirectConnectBody = (
  fields: any
): VirtualInetrfaceBridgeDomainAdd => {
  return { ...DEFAULT_BODY, ...fields };
};
