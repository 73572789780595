import React, { FC } from "react";
import { Matrix, MatrixCell } from "../../../../components/common/matrix";
import CloseIcon from "../../../../components/icons/CloseIcon";
import FilterIcon from "../../../../components/icons/FilterIcon";
import { ACCENT } from "../../../../helpers/common/colorAliases";
import { getCellStatus } from "../../helpers/getCellStatus";
import { Segment, useSegmentsRulesContext } from "../../SegmentsRulesContext";

import { MatrixCorner, MatrixHeader } from "./MatrixCells";
import { SegmentAccessCell } from "./SegmentAccessCell";
import styles from "./SegmentsMatrix.module.scss";

export const SegmentsMatrix: FC = () => {
  const { segments, allSegmentsRules } = useSegmentsRulesContext();

  const convertSegmentsToMatrix = (): Array<Array<MatrixCell>> => {
    const cornerCell = {
      data: {
        value: "corner",
      },
      render: () => <MatrixCorner />,
    };
    const leftHeadersRow: Array<MatrixCell> = segments.map((s, id) => ({
      data: {
        value: s.name,
        id,
      },
      render: ({ value }) => <MatrixHeader value={value} />,
    }));

    const matrix: Array<Array<MatrixCell>> = [];

    segments.forEach((s, id) => {
      const columns: Array<MatrixCell> = [];

      const columnHeader: MatrixCell = {
        data: {
          value: s.name,
          id,
        },
        render: ({ value }) => <MatrixHeader value={value} />,
      };

      columns.push(columnHeader);

      for (let i = 0; i < leftHeadersRow.length; i++) {
        const cell: MatrixCell = {
          data: {},
          render: () => {
            return (
              <SegmentAccessCell
                initialStatus={getCellStatus(segments, allSegmentsRules, id, i)}
                cell={{ x: id, y: i, id }}
              />
            );
          },
        };

        columns.push(cell);
      }

      matrix.push(columns);
    });
    return [[cornerCell, ...leftHeadersRow], ...matrix];
  };

  return (
    <div className={styles.table}>
      <MatrixRightHeader segments={segments} />
      <div className={styles.matrix}>
        <Matrix
          dataSource={convertSegmentsToMatrix()}
          grid="repeat(auto-fill, 1fr)"
        />
      </div>
    </div>
  );
};

type Props = {
  segments: Array<Segment>;
};
const MatrixRightHeader: FC<Props> = ({ segments }) => {
  return (
    <div className={styles.header}>
      <div className={styles.title}>Segments ({segments.length})</div>
      <div className={styles.headerActions}>
        <div className={styles.selectedCount}>
          4 Segments Selected
          <CloseIcon height={10} width={10} color={ACCENT} />
        </div>
        <FilterIcon className={styles.filterButton} color={ACCENT} />
      </div>
    </div>
  );
};
