import React from "react";
import { PosProps } from "../utils";

export default function LocationCircle({
  x,
  y,
  active,
  isLoading,
  location,
  onClick,
}: PosProps & {
  active?: boolean;
  isLoading?: boolean;
  location: string;
  onClick: () => void;
}): React.ReactElement {
  const showPlus = false;
  return (
    <>
      <text
        x={x}
        y={y + 28}
        textAnchor="middle"
        fill="var(--font)"
        fontSize="11"
      >
        {location}
      </text>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="38"
        height="38"
        fill="none"
        y={y - 19}
        x={x - 19}
        viewBox="0 0 38 38"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        {isLoading && (
          <circle
            cx="19"
            cy="19"
            r="18"
            stroke={"#6B55F0"}
            // stroke="url(#paint0_linear_17319_210700)"
            strokeDasharray="2 2"
            strokeWidth="2"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              from="0 19 19"
              to="360 19 19"
              dur="5s"
              repeatCount="indefinite"
            />
          </circle>
        )}
        <circle
          cx="19"
          cy="19"
          r="16"
          fill={active ? "#6B55F0" : "#fff"}
          stroke={active ? "#6B55F0" : "#BEB8EA"}
        />
        <path
          fill={active ? "#fff" : "#FFA400"}
          d="M15.531 17.047L19.004 19l3.471-1.953-3.471-1.952-3.473 1.952z"
        ></path>
        <path
          fill={active ? "#fff" : "#FFA400"}
          d="M14.223 16.313L19 13.626l4.777 2.687L27 14.5 19 10l-8 4.5 3.223 1.813z"
        ></path>
        <path
          fill={active ? "#fff" : "#FF6C0E"}
          d="M22.471 17.047L19 19v3.907l3.471-1.954v-3.906z"
        ></path>
        <path
          fill={active ? "#fff" : "#FF6C0E"}
          d="M23.777 16.313v5.374L19 24.376V28l8-4.5v-9l-3.223 1.813z"
        ></path>
        <path
          fill={active ? "#fff" : "#415364"}
          d="M15.531 17.047v3.906l3.473 1.954V19l-3.473-1.953zM14.223 21.687v-5.374L11 14.5v9l3.223 1.811v-3.625zM15.531 22.422v3.625L19.004 28v-3.624l-3.473-1.954z"
        ></path>
        {showPlus && (
          <>
            <path
              stroke="#fff"
              strokeWidth="5"
              d="M30 0v7m0 7V7m0 0h-7m7 0h7"
            ></path>
            <path
              stroke="#6B55F0"
              strokeWidth="2"
              d="M30 2v5m0 5V7m0 0h-5m5 0h5"
            ></path>
          </>
        )}
        <defs>
          <linearGradient
            id="paint0_linear_17319_210700"
            x1="37"
            x2="1"
            y1="26"
            y2="12"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.004" stopColor="#6B55F0"></stop>
            <stop offset="0.818" stopColor="#6B55F0" stopOpacity="0.717"></stop>
            <stop offset="1" stopColor="#6B55F0" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
