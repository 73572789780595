import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function SettingsIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m18.6 8.474-2.107-.517a6.8 6.8 0 0 0-.443-1.075l1.076-1.793a.525.525 0 0 0-.074-.644l-1.497-1.497a.525.525 0 0 0-.644-.074L13.118 3.95a6.796 6.796 0 0 0-1.075-.443L11.536 1.4A.541.541 0 0 0 11.02 1H8.91a.541.541 0 0 0-.517.4l-.506 2.107c-.369.116-.728.263-1.076.443L5.018 2.874a.525.525 0 0 0-.643.074L2.877 4.445a.525.525 0 0 0-.073.644l1.075 1.793c-.179.348-.327.706-.443 1.075l-2.035.517A.52.52 0 0 0 1 8.98v2.11a.52.52 0 0 0 .4.506l2.036.517c.116.369.264.728.443 1.076l-1.075 1.793a.525.525 0 0 0 .073.643l1.498 1.498c.169.168.432.2.643.073l1.793-1.075c.348.179.707.327 1.076.443l.506 2.035c.064.232.275.401.517.401h2.11a.541.541 0 0 0 .516-.4l.507-2.036c.369-.116.727-.264 1.075-.443l1.793 1.076a.525.525 0 0 0 .644-.074l1.497-1.498a.525.525 0 0 0 .074-.643l-1.076-1.793c.18-.348.327-.707.443-1.076l2.106-.517A.52.52 0 0 0 19 11.09V8.98a.52.52 0 0 0-.4-.506Zm-8.635 4.198a2.638 2.638 0 0 1 0-5.274 2.638 2.638 0 0 1 0 5.274Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default SettingsIcon;
