import React, { FC } from "react";
import { withLayout } from "../../../../../helpers/hocs/withLayout";
import { PageProps } from "../../../../../helpers/types";
import ContentHeader from "../../common/ContentHeader/ContentHeader";
import ServicesCharts from "./components/ServicesCharts/ServicesCharts";

import styles from "./ServicesPage.module.scss";

const ServicesPage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <div className={styles.wrapper}>
      <Tabs />
      <div className={styles.content}>
        <ContentHeader className={styles.header} />
        <ServicesCharts />
      </div>
    </div>
  );
};

export default withLayout<PageProps>(ServicesPage);
