import React, { ReactElement } from "react";

function ConnectionsPlaceholderSVG(): ReactElement {
  return (
    <svg
      width="127"
      height="132"
      viewBox="0 0 127 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2">
        <circle
          cx="60.2941"
          cy="71.0588"
          r="59.2941"
          stroke="#59536B"
          strokeWidth="2"
        />
        <path
          d="M47.4336 63.8222L60.303 71.0585L73.1668 63.8222L60.303 56.5859L47.4336 63.8222Z"
          fill="#59536B"
        />
        <path
          d="M42.591 61.0999L60.2955 51.1413L78 61.0999L89.9426 54.3846L60.2955 37.7056L30.6484 54.3846L42.591 61.0999Z"
          fill="#59536B"
        />
        <path
          d="M73.1529 63.8223L60.2891 71.0585V85.5364L73.1529 78.2948V63.8223Z"
          fill="#59536B"
        />
        <path
          d="M77.9935 61.1V81.017L60.2891 90.9809V104.411L89.9361 87.7376V54.3848L77.9935 61.1Z"
          fill="#59536B"
        />
        <path
          d="M47.4336 63.8223V78.2948L60.303 85.5364V71.0585L47.4336 63.8223Z"
          fill="#59536B"
        />
        <path
          d="M42.591 81.017V74.1104V61.1L30.6484 54.3848V87.7376L42.591 94.4528V81.017Z"
          fill="#59536B"
        />
        <path
          d="M47.4336 83.7393V97.175L60.303 104.411V90.9809L47.4336 83.7393Z"
          fill="#59536B"
        />
        <path
          d="M101.058 0.646973V26.5881M101.058 52.5293V26.5881M101.058 26.5881H75.1172M101.058 26.5881H127"
          stroke="white"
          strokeWidth="17"
        />
        <path
          d="M101.061 8.05859V26.588M101.061 45.1174V26.588M101.061 26.588H82.5312M101.061 26.588H119.59"
          stroke="#59536B"
          strokeWidth="6"
        />
      </g>
    </svg>
  );
}
export default ConnectionsPlaceholderSVG;
