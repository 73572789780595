import React, { FC, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { FONT, PURPLE } from "../../../helpers/common/colorAliases";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside";
import ClockIcon from "../../icons/ClockIcon";
import IconBlankButton from "../buttons/IconBlankButton";
import RangeSelectForm from "./RangeSelectForm";
import styles from "./TimerangeOverlayComponent.module.scss";
import {
  TimeRangeType,
  useTimerangeContext,
} from "../../../contexts/TimerangeContext";
import CaretDown from "../../icons/CaretDown";

type Props = {
  className?: string;
  id: string;
  type?: TimeRangeType;
};
const TimerangOverlayComponent: FC<Props> = ({ className, id, type }) => {
  const { activeTitle } = useTimerangeContext();
  const [expand, setExpand] = useState(false);

  const timeRangeRef = useClickOutside(() => setExpand(false));

  const timeRangeClasses = classNames(
    styles.wrapper,
    !expand && styles.collapsed,
    className
  );

  const labelClasses = classNames(styles.label, expand && styles.active);

  return (
    <div ref={timeRangeRef as React.RefObject<HTMLDivElement>}>
      <IconBlankButton
        id={`timeRangeOverlay-${id}`}
        icon={ClockIcon}
        onClick={() => setExpand((ex) => !ex)}
        color={expand ? PURPLE : FONT}
        isFill
        className={styles.btn}
        label={
          <span className={labelClasses}>
            {activeTitle} <CaretDown color={expand ? PURPLE : FONT} />
          </span>
        }
      />
      <div className={timeRangeClasses}>
        <RangeSelectForm onClose={() => setExpand(false)} type={type} />
      </div>
    </div>
  );
};

export default TimerangOverlayComponent;
