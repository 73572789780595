import { FC } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Section from "../../../../../../../components/common/Section";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";
import styles from "./InternetAccessSection.module.scss";

type Props = {
  className: string;
};
const InternetAccessSection: FC<Props> = ({ className }) => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  return (
    <Section title={"Internet Access"} columnsCount={2} className={className}>
      <DropdownBasic
        id={"usage"}
        onChange={(val) => {
          onChange("usage", val.key);
        }}
        selected={mapStringToItem(fields.usage)}
        label="Usage"
        itemsList={[]}
        isMedium
        error={""}
      />
      <DropdownBasic
        id={"acess"}
        onChange={(val) => {
          onChange("acess", val.key);
        }}
        selected={mapStringToItem(fields.acess)}
        label="Acess"
        itemsList={[]}
        isMedium
        error={""}
      />
      <div className={styles.tip}>
        <div className={styles.tipTitle}>Incoming Access</div>
        <div className={styles.tipSubtitle}>
          For incoming access, this connection will be available after clicking
          "Add" as a Source in Load balancer and NAT configurations.
        </div>
      </div>
    </Section>
  );
};

export default InternetAccessSection;
