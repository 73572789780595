import { NavItemsType } from "./types";

export const getSearchType = (
  isTenant: boolean,
  isZayo?: boolean
): NavItemsType => {
  switch (true) {
    case !isZayo && !isTenant:
      return "admin";
    case !isZayo && isTenant:
      return "tenant";
    case isZayo && !isTenant:
      return "zayoAdmin";
    case isZayo && isTenant:
      return "zayoTenant";
    default:
      return "admin";
  }
};
