import { useState } from "react";
import { ResetField, SetField } from "../dialogs/FieldProps";

export function useFormField<P>(
  initialState: P
): [fields: P, setField: SetField, resetFields: ResetField] {
  const [fields, setValues] = useState({ ...initialState });

  return [
    fields,
    (name: string, value: string | Array<string> | {} | undefined) => {
      setValues((fields: P) => ({
        ...fields,
        [name]: value,
      }));
    },
    (newFields: { [key: string]: any }) => {
      setValues(newFields as P);
    },
  ];
}
