/* eslint-disable css-modules/no-unused-class */
import React, { ChangeEvent, FC, useCallback } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./Textarea.module.scss";
import { OptionalLabel } from "../OptionalLabel";
import Error from "../Dropdown/components/Error";

type TextareaProps = {
  value: string;
  onChange: (name: string, value?: string | Array<string> | {}) => void;
  className?: string;
  label?: string;
  placeholder?: string | undefined;
  name: string;
  error?: string;
  isOptional?: boolean;
  disabled?: boolean;
  onKeyPress?: any;
};

const Textarea: FC<TextareaProps> = (props) => {
  const {
    onChange,
    className,
    label,
    error,
    value,
    placeholder,
    name,
    isOptional,
    disabled,
    onKeyPress,
    ...restProps
  } = props;

  const handleInput = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      return onChange(name, value);
    },
    [onChange]
  );

  return (
    <OptionalLabel
      className={classNames(className, styles.label)}
      isShown={Boolean(label)}
      text={label}
      isOptional={isOptional}
      {...restProps}
    >
      <div data-cy="input-wrapper" className={styles.wrapper}>
        <textarea
          value={value}
          onChange={handleInput}
          placeholder={placeholder}
          name={name}
          className={classNames(styles.textarea, error && styles.error)}
          disabled={disabled}
          onKeyPress={onKeyPress}
        />
        {error && <Error error={error} />}
      </div>
    </OptionalLabel>
  );
};

export default Textarea;
