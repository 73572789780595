import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function FileIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3618 1H2V17H16V5.27319L11.3618 1ZM2.875 16.1579V1.84211H10.2469V6.2H15.125V16.1579H2.875ZM14.8698 5.4L11.0781 1.90203V5.4H14.8698Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default FileIcon;
