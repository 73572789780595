import React from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

function NetworkIcon({ color }: IconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2917 4.37467C16.2917 4.4687 16.286 4.5614 16.275 4.65246L21.0299 7.39769C21.4483 6.94778 22.0455 6.66634 22.7083 6.66634C23.974 6.66634 25 7.69236 25 8.95801C25 10.0433 24.2455 10.9525 23.2324 11.1895V16.8099C24.2455 17.0469 25 17.956 25 19.0413C25 20.307 23.974 21.333 22.7083 21.333C22.0455 21.333 21.4483 21.0516 21.0299 20.6017L16.275 23.3469C16.286 23.4379 16.2917 23.5306 16.2917 23.6247C16.2917 24.8903 15.2657 25.9163 14 25.9163C12.7343 25.9163 11.7083 24.8903 11.7083 23.6247C11.7083 23.5306 11.714 23.4379 11.725 23.3469L6.97012 20.6017C6.55169 21.0516 5.95454 21.333 5.29167 21.333C4.02601 21.333 3 20.307 3 19.0413C3 17.956 3.75449 17.0469 4.76758 16.8099V11.1895C3.75449 10.9525 3 10.0433 3 8.95801C3 7.69236 4.02601 6.66634 5.29167 6.66634C5.95454 6.66634 6.55169 6.94778 6.97012 7.39769L11.725 4.65246C11.714 4.5614 11.7083 4.4687 11.7083 4.37467C11.7083 3.10902 12.7343 2.08301 14 2.08301C15.2657 2.08301 16.2917 3.10902 16.2917 4.37467ZM5.76758 11.2002C6.80483 10.9811 7.58333 10.0605 7.58333 8.95801C7.58333 8.71488 7.54547 8.48059 7.47532 8.26072L12.0687 5.60875C12.4759 6.24478 13.1887 6.66634 14 6.66634C14.8113 6.66634 15.5241 6.24478 15.9313 5.60875L20.5247 8.26072C20.4545 8.48059 20.4167 8.71488 20.4167 8.95801C20.4167 10.0605 21.1952 10.9811 22.2324 11.2002V16.7992C21.1952 17.0182 20.4167 17.9389 20.4167 19.0413C20.4167 19.2845 20.4545 19.5188 20.5247 19.7386L15.9313 22.3906C15.5241 21.7546 14.8113 21.333 14 21.333C13.1887 21.333 12.4759 21.7546 12.0687 22.3906L7.47532 19.7386C7.54547 19.5188 7.58333 19.2845 7.58333 19.0413C7.58333 17.9389 6.80483 17.0182 5.76758 16.7992V11.2002Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default NetworkIcon;
