import React, { ReactElement, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import ExpandArrowIcon from "../../../../../components/icons/ExpandArrowIcon";
import { useUserContext } from "../../../../../contexts/UserContext";
import { classNames } from "../../../../../helpers/common/classNames";
import { ACCENT } from "../../../../../helpers/common/colorAliases";
import { BaseMenuItem } from "../../../types";
import styles from "./styles.module.scss";
import { Toggle } from "../../../../../components/common/Toggle";
import { useConnectionsContext } from "../../../ConnectionStep/ConnectionsContext";

export type MenuItemProps<I extends BaseMenuItem> = {
  active?: boolean;
  item?: I;
};

type Props<I extends BaseMenuItem> = {
  className?: string;
  children?: ReactElement;
  menuTitle?: string | React.FC<MenuItemProps<I>>;
  list: Array<I>;
  onClick: (id: string) => void;
};

export const Menu = <I extends BaseMenuItem>({
  className,
  menuTitle,
  children,
  list,
  onClick,
}: Props<I>): any => {
  return (
    <div className={classNames(styles.menu, className)}>
      {menuTitle}
      <div className={styles.list}>
        {list.map((item, index) => (
          <MenuItem
            item={item}
            key={item.id || index}
            onClick={onClick}
            active={item.active}
            disabled={item.disabled}
          />
        ))}
      </div>
      {children}
    </div>
  );
};

const MenuItem = <I extends BaseMenuItem>({
  item,
  onClick,
  active,
  disabled,
}: {
  item: I;
  onClick: (id: string) => void;
  active?: boolean;
  disabled?: boolean;
}) => {
  const { tenantParams } = useUserContext();
  const isDisabled = useMemo(
    () => (item.onlyAdmin && !tenantParams?.isAdminRole) || disabled,
    [item, tenantParams, disabled]
  );
  const content = item.title;
  const { isSecureUsers } = useConnectionsContext();
  const changeToggleSecureUsers = (val: boolean) => {
    //todo delete NAT configure and gate VI
    const toggleValue = !val;
  };

  const Title =
    typeof content === "string"
      ? content
      : React.cloneElement(content, { active: !!item.active, item });

  return (
    <>
      <div
        className={classNames(
          styles.item,
          active && styles.selectedItem,
          isDisabled && styles.disabled
        )}
        onClick={() => {
          isDisabled ? () => undefined : onClick(item.id);
        }}
        id={item.id}
        data-tip
        data-for={item.id}
      >
        {Title}
        {item.withToggle ? (
          <Toggle
            isChecked={isSecureUsers || false}
            onChange={() => changeToggleSecureUsers(false)}
            className={classNames(styles.toggleItem)}
          />
        ) : (
          <ExpandArrowIcon
            color={ACCENT}
            height={6}
            width={12}
            opacity={1}
            className={classNames(
              styles.itemArrow,
              item.active && styles.expandedItemArrow
            )}
          />
        )}
      </div>
      {isDisabled && (
        <ReactTooltip
          id={item.id}
          aria-haspopup="true"
          place="bottom"
          type="light"
          className={styles.tooltip}
        >
          Service disabled. Please contact your operator to activate it
        </ReactTooltip>
      )}
    </>
  );
};
