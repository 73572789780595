import React, { ReactElement } from "react";
import { IconProps } from "../../../../helpers/types";

function OracleLogo({ color, x, y, width, height }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
      width={width || 48}
      height={height || 48}
      x={x}
      y={y}
    >
      <g clipPath="url(#a)">
        <path
          fill={color || "#C74634"}
          d="M20.694 25.057h3.167l-1.673-2.701-3.075 4.879h-1.399l3.74-5.854a.89.89 0 0 1 1.455-.01l3.75 5.864h-1.398l-.658-1.091h-3.205l-.698-1.087m14.531 1.087v-5.085h-1.188v5.586a.573.573 0 0 0 .176.415.6.6 0 0 0 .432.18h5.403l.704-1.09h-5.533m-19.645-.914a2.088 2.088 0 0 0 0-4.177h-5.194v6.176h1.186v-5.083h3.936a1 1 0 0 1 0 2H12.16l3.545 3.083h1.722l-2.38-2h.533ZM3.088 27.235h3.588a3.088 3.088 0 0 0 0-6.174H3.088a3.088 3.088 0 1 0 0 6.174Zm3.507-1.087H3.167a1.999 1.999 0 0 1 0-3.996h3.428a1.998 1.998 0 1 1 0 3.996Zm22.544 1.087h3.663l.694-1.087H29.22a1.999 1.999 0 1 1 0-3.996h3.48l.703-1.09h-4.264a3.088 3.088 0 1 0 0 6.173Zm14.518-1.087a1.999 1.999 0 0 1-1.924-1.455h5.074l.698-1.088h-5.772a1.997 1.997 0 0 1 1.924-1.455h3.483l.696-1.09h-4.26a3.088 3.088 0 0 0 0 6.173h3.663l.697-1.087h-4.28"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h48v48H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default OracleLogo;
