import React, { FC } from "react";
import styles from "./RouteMapTips.module.scss";
import { classNames } from "../../../../../../helpers/common/classNames";
import TextWithIcon from "../../../../../../components/common/table/TextWithIcon";
import InfoIcon from "../../../../../../components/icons/InfoIcon";
import { GREY_DISABLED } from "../../../../../../helpers/common/colorAliases";

type Props = { className: string };

const RouteMapTips: FC<Props> = ({ className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={<InfoIcon color={GREY_DISABLED} />}
        text={"Information"}
        className={styles.header}
      />
      <div className={styles.text}>
        A BGP route filter is a policy tool that can be used to filter BGP
        routes. A route filter consists of one or more entries, each with a
        priority and a match clause. The match clause specifies the criteria for
        matching prefix lists. A BGP route filter can be applied to incoming or
        outgoing routes using the neighbor configuration.
      </div>
    </div>
  );
};

export default RouteMapTips;
