/* eslint-disable css-modules/no-unused-class */
/* eslint-disable css-modules/no-undef-class */
import { FC } from "react";
import { classNames } from "../../../../helpers/common/classNames";
import { getTypeByActivity } from "./helpers/getTypeByActiviti";
import { getIconByEntity } from "./helpers/getIconByEntity";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import { ENTITY_MAP } from "./helpers/consts";
import styles from "./EventLabel.module.scss";

type Props = {
  activity: string;
  entity?: string;
};

const EventLabel: FC<Props> = ({ activity, entity }) => {
  const type = getTypeByActivity(activity);
  const Icon = entity ? getIconByEntity(entity) : undefined;

  const wrapperClassNames = classNames(
    styles.wrapper,
    styles[type],
    entity && styles.outline
  );
  return (
    <div className={wrapperClassNames}>
      {entity && Icon && <Icon color={GREY_FONT} />}
      <span>{entity ? ENTITY_MAP[entity] || entity : activity}</span>
    </div>
  );
};

export default EventLabel;
