/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { Res } from "../../helpers/api/Api";
import { configApi } from "../../helpers/api/ConfigApi";
import { RequestStatus } from "../../helpers/types";

import { CreateIpam, PublicIpManagementRecord } from "./types";

type IFunc = {
  fetchIpamData: () => void;
  createIpam: (ipam: CreateIpam) => Promise<boolean>;
  deleteIpam: (ipam: PublicIpManagementRecord) => Promise<Res<any>>;
};

type IState = {
  ipamData: Array<PublicIpManagementRecord>;
  ipamFetchStatus: RequestStatus;
  ipamCreateStatus: RequestStatus;
  ipamDeleteStatus: RequestStatus;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const [IpamContext, useContext] = createContextAndUse<IState, IFunc>();
export const useIpamContext = useContext;

export const IpamContextContainer: FC<Props> = ({ children }) => {
  const [ipamData, setIpamData] = useState<Array<PublicIpManagementRecord>>([]);
  const [ipamFetchStatus, setIpamFetchStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [ipamCreateStatus, setIpamCreateStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [ipamDeleteStatus, setIpamDeleteStatus] = useState<RequestStatus>({
    state: "idle",
  });

  const deleteIpam = async (ipam: PublicIpManagementRecord) => {
    const response = await configApi.deletePublicIpRange(ipam.name);

    if (!response.ok || response.error) {
      setIpamDeleteStatus({ state: "error", message: response.error });
      return response;
    }

    setIpamDeleteStatus({ state: "ok" });
    setIpamData((ipams) => ipams.filter((p) => p.name !== ipam.name));

    return response;
  };

  const fetchIpamData = async () => {
    setIpamFetchStatus({ state: "pending" });

    const { ok, result, error } = await configApi.getPublicIpRange();

    if (!ok || error) {
      setIpamFetchStatus({ state: "error", message: error });
      return;
    }

    setIpamData(result?.items || []);
    setIpamFetchStatus({ state: "ok" });
  };

  const createIpam = async (ipam: CreateIpam): Promise<boolean> => {
    setIpamCreateStatus({ state: "pending" });
    const { ok, error } = await configApi.createPublicIpRange(ipam);

    if (!ok || error) {
      setIpamCreateStatus({ state: "error", message: error });
      return false;
    }

    setIpamCreateStatus({ state: "ok" });
    fetchIpamData();
    return true;
  };

  return (
    <IpamContext.Provider
      value={{
        ipamData,
        ipamFetchStatus,
        ipamCreateStatus,
        ipamDeleteStatus,
        fetchIpamData,
        createIpam,
        deleteIpam,
      }}
    >
      {children}
    </IpamContext.Provider>
  );
};
