import { FC, useEffect } from "react";
import styles from "./BgpStatistics.module.scss";
import StatisticsItem from "./StatisticsItem";
import { useBGPServicesContext } from "../../../ServicesBGPContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import NoDataPlaceholder from "../../../../../components/common/loadStates/NoDataPlaceholder";

type Props = {
  handledStatus: any;
  selectedNeighbor?: string;
};

const BgpStatistics: FC<Props> = ({ handledStatus, selectedNeighbor }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    neighborsList,
    autonomous_system,
    fetchData,
  } = useBGPServicesContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchData(selectedTenant);
    }
  }, [selectedTenant]);

  return (
    <div className={styles.wrapper}>
      {!selectedTenant || handledStatus?.state === "pending" ? (
        <PageLoader />
      ) : neighborsList?.length === 0 || !autonomous_system ? (
        <NoDataPlaceholder
          title={"There are no data yet"}
          subTitle="Please configure BGP"
        />
      ) : (
        <StatisticsItem
          tenant={selectedTenant}
          peer_ip={selectedNeighbor}
          asn={autonomous_system.asn}
        />
      )}
    </div>
  );
};
export default BgpStatistics;
