/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import {
  FilterAndSort,
  SortType,
  TenantContactInfo,
  TenantQuotas,
  TenantType,
  VirtualInterfaceType,
  VRFType,
} from "../../helpers/api/apiTypes";
import { tenantApi } from "../../helpers/api/TenantApi";
import AbstractCrudContextContainer, {
  createContextAndUse,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import { getQueryParams } from "../../helpers/getQueryParams";
import { systemApi } from "../../helpers/api/SystemApi";
import { configApi } from "../../helpers/api/ConfigApi";
import { userApi } from "../../helpers/api/UserApi";
import { CreateTenantType } from "../../components/dialogs/Tenant/TenantDialog";
import { EMPTY_RESULT } from "../../helpers/common/constantsAlias";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { VTEPType } from "../../pages/VTEPPage/types";

import { SystemAPI } from "../../pages/Systems/Provisioning/types";
import { Layer3Interface } from "../../pages/Layer3Interface/types";
import { PortType } from "../../pages/PortsPage/types";
import {
  getTenantServicesStatuses,
  TenantStatuses,
} from "../../helpers/getTenantServicesStatuses";
import { OK_STATUS } from "../../components/const/api";
import { getTenantPayloadsFromParams } from "../../helpers/getTenantPayloadsFromParams";
import { ServiceAPI } from "../../pages/ServicesPreferencesPage/types";

export type SystemRecord = {
  id: number;
  system_name: string | undefined | null;
};

type IState = CrudState<TenantType, TenantType> & {
  systems?: Array<SystemAPI>;
  vrf?: VRFType;
  viList?: Array<VirtualInterfaceType>;
  viListStatus?: RequestStatus;
  activePackage?: { name: string; tenant: string };
  interfacesList?: Array<{ tenant: string; interfaces: Array<string> }>;
  thresholdList?: Array<any>;
  vtepList?: Array<VTEPType>;
  portList?: Array<PortType>;
  addStatus?: RequestStatus;
  systemsRecord?: Array<SystemRecord>;
  servicesStatus?: TenantStatuses;
  servicesStatusRequest?: RequestStatus;
  currSdrScore?: number;
};

type IFunc = {
  getVrf: (activeTenant: string) => any;
  addFullTenant: (params: CreateTenantType) => Promise<boolean | undefined>;
  addNewTenant: (params: any) => Promise<boolean | undefined>;
  createAdminUser: (
    tenant: string,
    params: any
  ) => Promise<boolean | undefined>;
  updateTenant: (tenant: string, params: any) => Promise<boolean | undefined>;
  updateServices: (tenant: string, params: any) => Promise<boolean | undefined>;
  fetchSystems: (sortBy?: SortType) => void;
  fetchSdrScore: (tenant: string) => void;
  editQuotas: (params: TenantQuotas, name: string | number) => any;
  setActivePackage: (name: string, tenant: string) => void;
  fetchInterfacesList: (list: Array<TenantType>) => void;
  fetchThresholdList: (list: Array<TenantType>) => Promise<void>;
  removeTenant: (tenant: TenantType) => void;
  fetchServicesStatus: (tenant: string) => Promise<void>;
};

const [Context, useContext] = createContextAndUse<
  IState,
  IFunc & CrudFunc<TenantType, IState>
>();

export const useTenantContext = useContext;

type Props = React.PropsWithChildren<{}>;

// SHOULD BE USED ONLY FOR TENANTS PAGE!
export default class TenantContextContainer extends AbstractCrudContextContainer<
  TenantType,
  TenantType,
  IState,
  TenantType
> {
  funcs: IFunc;
  public readonly state = {
    list: [],
    count: 0,
    interfacesList: [],
    selected: undefined,
    systems: undefined,
    sort: { id: "name", desc: false },
  };
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      setActivePackage: this.setActivePackage,
      getVrf: this.getVrf,
      addFullTenant: this.addFullTenant,
      addNewTenant: this.addNewTenant,
      createAdminUser: this.createAdminUser,
      updateTenant: this.updateTenant,
      updateServices: this.updateServices,
      fetchSystems: this.fetchSystems,
      fetchSdrScore: this.fetchSdrScore,
      editQuotas: this.editQuotas,
      fetchInterfacesList: this.fetchInterfacesList,
      fetchThresholdList: this.fetchThresholdList,
      removeTenant: this.removeTenant,
      fetchServicesStatus: this.fetchServicesStatus,
    };
  }

  fetchServicesStatus = async (tenant: string): Promise<void> => {
    this.setState({ servicesStatusRequest: setPending("Fetching") });
    const servicesStatus = await getTenantServicesStatuses(tenant);
    this.setState({ servicesStatus, servicesStatusRequest: OK_STATUS });
    return;
  };

  listReq = async (
    param?: FilterAndSort,
    extraLoad?: boolean,
    offset?: number,
    limit?: number
  ) => {
    const res = await tenantApi.getTenantsList(param, offset, limit);
    if (res.ok && res.result && extraLoad !== undefined) {
      this.fetchThresholdList(res.result);
      this.fetchInterfacesList(res.result);
    }
    return res;
  };

  fetchInterfacesList = async (list: Array<TenantType>) => {
    const tenants = list.map((tenant) => tenant.name);
    const resSelectedVI = await Promise.all(
      tenants.map(
        async (tenant): Promise<any> => ({
          tenant,
          interfaces: await tenantApi.getVirtualInterfaces(tenant),
        })
      )
    );

    const viList = resSelectedVI.map((item) => ({
      tenant: item.tenant,
      interfaces: item.interfaces.result.map(
        (el: VirtualInterfaceType) => el.name
      ),
    }));

    const interfaces: Array<Layer3Interface> = resSelectedVI
      .filter((v) => v.interfaces.result[0])
      .map((v) => v.interfaces.result[0]);

    const systemsRecord = interfaces.map((i) => ({
      id: i.id,
      system_name: i.system_name,
    }));

    this.setState({
      interfacesList: [...viList],
      systemsRecord,
    });
  };

  selectedReq = async (name: string | number) => {
    return await tenantApi.getTenant(name as string);
  };

  addReq = async () => EMPTY_RESULT;

  addNewTenant = async (
    params: Partial<CreateTenantType>
  ): Promise<boolean | undefined> => {
    this.setState({ addStatus: setPending("Fetching") });
    // create tenant
    const resTenant = await tenantApi.addTenant(params);
    // error - show error
    if (!resTenant?.ok) {
      this.setState({
        addStatus: resToState(resTenant),
      });
      return false;
    }
    // // // close dialog
    this.setState({
      addStatus: resToState(resTenant),
    });
    this.fetchList();
    return true;
  };

  createAdminUser = async (
    tenant: string,
    params: any
  ): Promise<boolean | undefined> => {
    const res = await userApi.createUser(tenant, params);
    if (res.ok && res.result) {
      this.setState({
        addStatus: resToState(res),
      });
      return true;
    }
    return false;
  };

  updateTenant = async (
    tenant: string,
    params: Partial<CreateTenantType>
  ): Promise<boolean | undefined> => {
    this.setState({ addStatus: setPending("Fetching") });
    // update tenant
    const resTenant = await tenantApi.editTenant(tenant, params);
    // error - show error
    if (!resTenant?.ok) {
      this.setState({
        addStatus: resToState(resTenant),
      });
      return false;
    }
    // // // close dialog
    this.setState({
      addStatus: resToState(resTenant),
    });
    this.fetchList();
    return true;
  };

  updateServices = async (
    tenant: string,
    services: Array<ServiceAPI>
  ): Promise<boolean> => {
    const res = await tenantApi.editEnabledServices(tenant, {
      services,
    });
    return res.ok;
  };

  addFullTenant = async (
    params: CreateTenantType
  ): Promise<boolean | undefined> => {
    const tenant = params.name;
    const [tenantPayload, viPayloads, l2Payloads] = getTenantPayloadsFromParams(
      params
    );
    this.setState({ addStatus: setPending("Fetching") });
    // create tenant
    const resTenant = await tenantApi.addTenant(tenantPayload);
    // error - show error
    if (!resTenant?.ok) {
      this.setState({
        addStatus: resToState(resTenant),
      });
      return false;
    }
    // tenant was created:
    // // create VIs and collect all results
    const resVIs = await this.createVIs(params.name, viPayloads);
    // // at least one error - show error, delete tenant
    if (!resVIs) {
      this.removeReq(tenant);
      return false;
    }
    // // VIs were created:
    // // // create L2 interfaces per VI
    const resL2s = await this.createL2Interfaces(l2Payloads);
    // // // error - show error, delete tenant
    if (!resL2s) {
      this.removeReq(tenant);
      return false;
    }
    // // // close dialog
    this.fetchList();
    return resL2s;
  };

  createVIs = async (tenant: string, list: Array<any>): Promise<boolean> => {
    const res = await Promise.all(
      list.map(
        async (vi): Promise<any> =>
          await tenantApi.addVirtualInterfaces(vi, tenant)
      )
    );
    const error = res.find((r) => !r.ok);
    if (error) {
      this.setState({ addStatus: resToState(error) });
    }
    return error?.ok || res[0].ok;
  };

  createL2Interfaces = async (list: Array<any>): Promise<boolean> => {
    const res = await Promise.all(
      list.map(
        async (l2): Promise<any> => {
          const { type, system, payload } = l2;
          if (type === "vlan") {
            return await configApi.addPortVLAN(system, payload);
          }
          if (type === "vni") {
            return await configApi.addVNI(system, payload);
          }
        }
      )
    );
    const error = res.find((r) => !r.ok);
    if (error) {
      this.setState({ addStatus: resToState(error) });
    }
    return error?.ok || res[0].ok;
  };

  editReq = async (params: Partial<TenantType>, name: string | number) => {
    const newParams: TenantContactInfo = {
      contact_email: params.contact_info?.email || "",
      contact_name: params.contact_info?.name || "",
    };
    return await tenantApi.editTenantContact(
      name as string,
      getQueryParams(newParams)
    );
  };

  editQuotas = async (params: TenantQuotas, name: string | number) => {
    return await tenantApi.editTenantQuotas(
      name as string,
      getQueryParams(params)
    );
  };

  removeReq = async (params: TenantType | string) => {
    if (typeof params === "string") {
      return await tenantApi.deleteTenant(params);
    }
    return await tenantApi.deleteTenant(params.name);
  };

  removeTenant = (tenant: TenantType) => {
    this.setState({
      list: this.state.list.filter((l: TenantType) => l.id !== tenant.id),
    });
  };

  setActivePackage = (name: string, tenant: string): void => {
    this.setState({ activePackage: { name, tenant: tenant } });
  };

  fetchSdrScore = async (tenant: string) => {
    const res = await tenantApi.getEnabledServices(tenant);
    if (res.ok && res.result) {
      const currSdrScore = res.result?.services.find(
        (service: any) => service.type === "sdr"
      )?.data.rate;
      this.setState({ currSdrScore });
    }
  };

  fetchSystems = async (sortBy?: SortType) => {
    const res = await systemApi.getSystemsAPIList(sortBy);
    if (res.ok && res.result) {
      this.setState({ systems: res.result });
    }
  };

  fetchPortsVteps = async (
    system: string,
    sortBy?: SortType
  ): Promise<void> => {
    const resPorts = await configApi.getPortsList(system, sortBy);
    const resVteps = await configApi.getVTEPList(system, sortBy);
    if (resPorts.ok) {
      this.setState({
        portList: resPorts.result || [],
      });
    }
    if (resVteps.ok) {
      this.setState({
        vtepList: resVteps.result || [],
      });
    }
  };

  getVrf = async (activeTenant: string) => {
    const resTenant = await tenantApi.getTenant(activeTenant);
    if (resTenant.ok && resTenant.result && resTenant.result.systems) {
      const resVRF = await configApi.getVRF(
        resTenant.result.systems[0],
        activeTenant
      );
      this.setState({ vrf: resVRF.result });
    }
  };

  fetchThresholdList = async (list: Array<TenantType>): Promise<void> => {
    const res = await Promise.all(
      list.map(
        async (tenant): Promise<any> => ({
          tenant: tenant.name,
          value: await tenantApi.getTenantsThreshold(tenant.name),
        })
      )
    );
    if (res.every((el) => el.value.ok && el.value.result)) {
      this.setState({
        thresholdList: res.map((el) => ({
          tenant: el.tenant,
          ...el.value.result,
        })),
      });
    }
  };

  render() {
    return (
      <Context.Provider
        value={{ ...this.state, ...this.funcs, ...this.baseFuncs }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}
