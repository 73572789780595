import React, { FC } from "react";
import { ChangeField } from "../../types";
import { UNTAGGED_VLAN_GROUP } from "../../consts";
import RadioGroup from "../../../../../../components/common/formComponents/RadioGroup";

type Props = {
  field: string;
  onChange: ChangeField;
  isNotEditable?: boolean;
};

const TaggedRadioGroup: FC<Props> = ({ field, onChange, isNotEditable }) => {
  const value = field ? "vlan" : "untagged";
  return (
    <RadioGroup
      label="Untagged/VLAN"
      isOneRow
      options={UNTAGGED_VLAN_GROUP}
      value={value}
      setActiveValue={(val) => onChange("Tagged", val === "vlan")}
      isNotEditable={isNotEditable}
    />
  );
};
export default TaggedRadioGroup;
