import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import VirtInterfaceIcon from "../../../components/icons/VirtInterfaceIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { Layer3Interface } from "../types";

type SumProps = {
  title: string;
  data?: Partial<Layer3Interface>;
  tenant?: string;
};

const SummaryBlock: FC<SumProps> = ({ title, data, tenant }) => {
  return (
    <SummaryInfoBlock icon={<VirtInterfaceIcon />} title={title} isSmallerIcon>
      <UnifiedTextPair subtitle="Name" text={data?.name} />
      <UnifiedTextPair subtitle="Tenant" text={tenant} />
      <UnifiedTextPair subtitle="Type" text={data?.virtual_interface_type} />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
