import React, { ReactElement, useMemo } from "react";
import { BUTTON_FONT } from "../../../helpers/common/colorAliases";
import styles from "./AddButton.module.scss";
import Button from "./Button";
import AddIcon from "../../icons/AddIcon";
import LoaderIcon from "../loadStates/LoaderIcon";

type AddButtonType = {
  onClick: (arg: string | number) => void;
  isIconOnly?: boolean;
  isTextOnly?: boolean;
  disabled?: boolean;
  label?: string;
  className?: string;
  isLoading?: boolean;
};

export default function AddButton({
  onClick,
  isIconOnly,
  isTextOnly,
  disabled,
  label,
  className,
  isLoading,
}: AddButtonType): ReactElement {
  const buttonContent = useMemo(() => {
    const content = [];
    if (isLoading) {
      return <LoaderIcon />;
    }

    if (!isTextOnly) {
      content.push(<AddIcon color={BUTTON_FONT} />);
    }

    if (!isIconOnly) {
      content.push(<span className={styles.btnText}>{label || "Add"}</span>);
    }

    return content;
  }, [isLoading, isTextOnly, isIconOnly, label]);

  return (
    <Button
      onClick={onClick}
      isPrimaryBtn
      isBtnSmall={isIconOnly}
      disabled={disabled}
      className={className}
    >
      {buttonContent}
    </Button>
  );
}
