import { FC, useState } from "react";
import WireGuardConfigDialog from "./WireGuardConfigDialog";
import Button from "../../buttons/Button";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import WireGuardContextContainer from "../WireGuardContext";

type Props = { className?: string; user?: string; tenant?: string };

const WireGuardConfig: FC<Props> = ({ className, user, tenant }) => {
  const [showConfig, setShowConfig] = useState<boolean>(false);
  const handleClose = () => {
    setShowConfig(false);
  };
  return (
    <>
      {showConfig && (
        <WireGuardConfigDialog
          onClose={handleClose}
          user={user}
          tenant={tenant}
        />
      )}
      <Button onClick={() => setShowConfig(true)} className={className}>
        Show Configuration
      </Button>
    </>
  );
};

export default withContexts<Props>(WireGuardConfig, [
  WireGuardContextContainer,
]);
