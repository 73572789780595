import React, { ReactElement, useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { CONFIGURATION, REPORTS } from "../../../helpers/navigation/entries";
import { addPrefix } from "../../../helpers/navigation";
import OneSystemContextContainer, {
  useOneSystemContext,
} from "../../../contexts/systemsContext/OneSystemContext";
import { PathEntry } from "../../../helpers/types";
import { isOk } from "../../../helpers/common/RequestStateHelpers";
import DataPlaceHolders from "../../common/loadStates/DataPlaceHolder";
import LayoutComponent from "../../common/LayoutComponent";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import TimerangeContextContainer from "../../../contexts/TimerangeContext";

import VRFSelectorContextContainer from "../../../contexts/systemsContext/VRFSelectorContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SystemConfigContainer from "../../../routing/SystemConfiguration";
import SystemReportingContainer from "../../../routing/SystemReporting";

function SystemContainer(): ReactElement {
  const path = useMatchPath();
  const { system: name } = useParams<{ system: string }>();
  const { oneSystemStatus, fetchSystem } = useOneSystemContext();

  useEffect(() => {
    if (name) {
      fetchSystem(name);
    }
  }, [name]);

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  if (!isOk(oneSystemStatus)) {
    return (
      <LayoutComponent>
        <DataPlaceHolders status={oneSystemStatus} />
      </LayoutComponent>
    );
  }

  return (
    <Switch>
      <Route path={fullPath(REPORTS())}>
        <SystemReportingContainer />
      </Route>
      <Route path={fullPath(CONFIGURATION())}>
        <SystemConfigContainer />
      </Route>
      <Route path="/">
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
    </Switch>
  );
}
export default withContexts(SystemContainer, [
  VRFSelectorContextContainer,
  TimerangeContextContainer,
  OneSystemContextContainer,
]);
