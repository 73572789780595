import { FC, useEffect, useState } from "react";
import styles from "./Details.module.scss";
import { Row } from "../../types";
import { colors, getHealth } from "../../utils";
import LoaderIcon from "../../../../../components/common/loadStates/LoaderIcon";

type Props = {
  name: string;
  id: string;
  data: Row;
};

const Details: FC<Props> = ({ name, id, data }) => {
  const [isLoading, setLoading] = useState(true);
  const [loss, setLoss] = useState<Record<string, any>>({});
  const [latency, setLatency] = useState<Record<string, any>>({});
  const [jitter, setJitter] = useState<Record<string, any>>({});
  const [mos, setMos] = useState<Record<string, any>>({});

  useEffect(() => {
    getHealth(id, data).then((list) => {
      const [lossData, latencyData, jitterData, mosData] = list;
      setLoss(lossData);
      setLatency(latencyData);
      setJitter(jitterData);
      setMos(mosData);
      setLoading(false);
    });
  }, [id, data.time]);

  return (
    <div className={styles.details}>
      <div>
        <p>{name}</p>
        <p>
          {new Date(data.time * 1000).toLocaleString().replace("00:00", "00")}
        </p>
      </div>

      {isLoading ? (
        <LoaderIcon />
      ) : (
        <>
          {loss && loss.healthStatus !== "HEALTHY" ? (
            <div>
              {loss.healthStatus === "CRITICAL" ? (
                <p>{loss.criticalMins} min &gt; 1%</p>
              ) : (
                <p>{loss.majorMins} min &gt; 0.1%</p>
              )}
              <h6 style={{ background: colors[loss.healthStatus] }}>Loss</h6>
            </div>
          ) : null}

          {latency && latency.healthStatus !== "HEALTHY" ? (
            <div>
              {latency.healthStatus === "CRITICAL" ? (
                <p>{latency.criticalMins} min &gt; 50ms</p>
              ) : (
                <p>{latency.majorMins} min &gt; 20ms</p>
              )}

              <h6 style={{ background: colors[latency.healthStatus] }}>
                Latency
              </h6>
            </div>
          ) : null}

          {jitter && jitter.healthStatus !== "HEALTHY" ? (
            <div>
              {jitter.healthStatus === "CRITICAL" ? (
                <p>{jitter.criticalMins} min &gt; 50ms</p>
              ) : (
                <p>{jitter.majorMins} min &gt; 20ms</p>
              )}

              <h6 style={{ background: colors[jitter.healthStatus] }}>
                Jitter
              </h6>
            </div>
          ) : null}

          {mos && mos.healthStatus !== "HEALTHY" ? (
            <div>
              <p>{mos.criticalMins || mos.majorMins} min &lt; 4</p>
              <h6 style={{ background: colors[mos.healthStatus] }}>MOS</h6>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Details;
