import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";

function EyeIcon(): ReactElement {
  return (
    <svg
      width="14"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
    >
      <path
        d="M14 6.7c-.2-.1-3.2-4.2-7-4.2s-6.8 4-7 4.2v.6c.2.1 3.2 4.2 7 4.2s6.8-4 7-4.2v-.6Zm-7 3.8C4.2 10.5 1.8 8 1 7c.8-.9 3.2-3.5 6-3.5S12.2 6 13 7c-.8.9-3.2 3.5-6 3.5Z"
        fill={GREY_FONT}
      />
      <path
        d="M7 4.2a2.8 2.8 0 1 0 0 5.6 2.8 2.8 0 0 0 0-5.6Zm0 4.6a1.8 1.8 0 1 1 0-3.7 1.8 1.8 0 0 1 0 3.7Z"
        fill={GREY_FONT}
      />
    </svg>
  );
}

export default EyeIcon;
