import React from "react";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { hardcodedAPI } from "../../helpers/api/HardcodeApi";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { FDQNServiceBody, WEB_FILTERING_ENTITIES } from "./helpers/consts";
import {
  TenantStatuses,
  getTenantServicesStatuses,
} from "../../helpers/getTenantServicesStatuses";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { OK_STATUS } from "../../components/const/api";
import {
  FQDNServiceData,
  FQDNDefaultAction,
  TenantFQDNCategory,
  FDQNDomain,
} from "./types";
import { configApi } from "../../helpers/api/ConfigApi";
import { getFQDNData } from "./helpers/getFQDNData";

type IState = {
  // service status
  servicesStatus?: TenantStatuses;
  servicesStatusRequest?: RequestStatus;

  //config
  addRequest?: RequestStatus;
  dataRequest?: RequestStatus;
  defaultAction?: FQDNDefaultAction;
  contentFilterCategories: Array<TenantFQDNCategory>;
  webThreatsFilterCategories: Array<TenantFQDNCategory>;
  allowDomains: Array<FDQNDomain>;
  denyDomains: Array<FDQNDomain>;
  domainsList: Array<FDQNDomain>;

  // reports
  blockedThreatCategoryList: any;
  cyberBlockedReasonList: any;
  blockedRuleCategoryList: any;
  webFilteringBlockedReasonList: any;
  topHitsList: Array<any>;
};

type IFunc = {
  //service
  fetchServicesStatus: (tenant: string) => Promise<void>;

  // config
  fetchData: (tenant: string) => Promise<void>;
  add: (tenant: string, defaultAction: FQDNDefaultAction) => Promise<boolean>;
  changeChecklist: (id: string, fields: Array<TenantFQDNCategory>) => void;
  addGenerslWebFilterSettings: (id: string, item: FDQNDomain) => void;
  removeGenerslWebFilterSettings: (id: string, item: FDQNDomain) => void;
  resetAll: (tenant: string) => void;

  // reports
  fetchBlockedThreatCategory: () => void;
  fetchCyberBlockedReason: () => void;
  fetchBlockedRuleCategory: () => void;
  fetchWebFilteringBlockedReason: () => void;
  fetchTopHits: () => void;
};

// type IWebFilteringContext = IState & IFunc;

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useWebFilteringsContext = useContext;

export default class WebFilteringsContextContainer extends AbstractTimeoutHandler<IState> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      // config
      contentFilterCategories: [],
      webThreatsFilterCategories: [],
      allowDomains: [],
      denyDomains: [],
      domainsList: [],

      // reports
      blockedThreatCategoryList: {},
      cyberBlockedReasonList: {},
      blockedRuleCategoryList: {},
      webFilteringBlockedReasonList: {},
      topHitsList: [],
    };

    this.funcs = {
      // config
      fetchServicesStatus: this.fetchServicesStatus,
      fetchData: this.fetchData,
      add: this.add,
      // old
      changeChecklist: this.changeChecklist,
      addGenerslWebFilterSettings: this.addGenerslWebFilterSettings,
      removeGenerslWebFilterSettings: this.removeGenerslWebFilterSettings,
      resetAll: this.resetAll,

      //reports
      fetchBlockedThreatCategory: this.fetchBlockedThreatCategory,
      fetchCyberBlockedReason: this.fetchCyberBlockedReason,
      fetchBlockedRuleCategory: this.fetchBlockedRuleCategory,
      fetchWebFilteringBlockedReason: this.fetchWebFilteringBlockedReason,
      fetchTopHits: this.fetchTopHits,
    };
  }

  // config
  fetchServicesStatus = async (tenant: string): Promise<void> => {
    this.setState({ servicesStatusRequest: setPending("Fetching") });
    const servicesStatus = await getTenantServicesStatuses(tenant);
    this.setState({ servicesStatus, servicesStatusRequest: OK_STATUS });
    return;
  };

  fetchData = async (tenant: string): Promise<void> => {
    this.setState({ dataRequest: setPending("Fetching") });
    const res = await getFQDNData(tenant);
    if (res.ok) {
      const {
        contentFilterCategories,
        webThreatsFilterCategories,
        domainsList,
        defaultAction,
      } = res.result;

      const denyDomains = domainsList.filter(
        (domain: FDQNDomain) => domain.type === "block"
      );

      this.setState({
        contentFilterCategories,
        webThreatsFilterCategories,
        defaultAction,
        domainsList,
        denyDomains,
      });

      if (defaultAction === "ALLOW") {
        this.setState({ allowDomains: domainsList });
      }
    }
    this.setState({ dataRequest: resToState(res) });
  };

  add = async (
    tenant: string,
    defaultAction: FQDNDefaultAction
  ): Promise<boolean> => {
    this.setState({ addRequest: setPending("Fetching") });
    const body = FDQNServiceBody;
    body.services[0].data = this.getFQDNData(defaultAction);
    const res = await configApi.editFQDNService(tenant, body);
    this.setState({ addRequest: resToState(res) });
    return res.ok;
  };

  getFQDNData = (default_action: FQDNDefaultAction): FQDNServiceData => {
    const {
      domainsList,
      webThreatsFilterCategories,
      contentFilterCategories,
    } = this.state;
    const isDeny = default_action === "DENY";
    const categories = isDeny
      ? []
      : [...webThreatsFilterCategories, ...contentFilterCategories].reduce(
          (result, item) => {
            if (item.isChecked) {
              result.push(item.name);
            }
            return result;
          },
          [] as Array<string>
        );
    const allowed_domains = domainsList.reduce((acc, d) => {
      if (d.type === "allow") {
        acc.push(d.domain);
      }
      return acc;
    }, [] as Array<string>);
    const denied_domains = domainsList.reduce((acc, d) => {
      if (d.type === "block") {
        acc.push(d.domain);
      }
      return acc;
    }, [] as Array<string>);
    return { default_action, categories, allowed_domains, denied_domains };
  };

  // old
  changeChecklist = (id: string, fields: Array<TenantFQDNCategory>): void => {
    if (id === WEB_FILTERING_ENTITIES.CONTENT_FILTER) {
      this.setState({ contentFilterCategories: fields });
    }
    if (id === WEB_FILTERING_ENTITIES.WEB_THREATS_FILTER) {
      this.setState({ webThreatsFilterCategories: fields });
    }
  };

  addGenerslWebFilterSettings = (id: string, item: FDQNDomain): void => {
    if (id === WEB_FILTERING_ENTITIES.BLOCK_SELECTED) {
      const list = [...this.state.allowDomains, item];
      this.setState({ allowDomains: list });
    }
    if (id === WEB_FILTERING_ENTITIES.ALLOW_SELECTED) {
      const list = [...this.state.denyDomains, item];
      this.setState({ denyDomains: list });
    }
    const domainsList = this.state.domainsList;
    domainsList.push(item);
    this.setState({ domainsList });
  };

  removeGenerslWebFilterSettings = (id: string, item: FDQNDomain): void => {
    if (id === WEB_FILTERING_ENTITIES.BLOCK_SELECTED) {
      const list = this.state.allowDomains.filter((el) => el.id !== item.id);
      this.setState({ allowDomains: list });
    }
    if (id === WEB_FILTERING_ENTITIES.ALLOW_SELECTED) {
      const list = this.state.denyDomains.filter((el) => el.id !== item.id);
      this.setState({ denyDomains: list });
    }
    const domainsList = this.state.domainsList.filter(
      (domain) => domain.domain !== item.domain
    );
    this.setState({ domainsList });
  };

  resetAll = (tenant: string): void => {
    this.fetchData(tenant);
  };

  // reports
  fetchBlockedThreatCategory = async (): Promise<void> => {
    const res = await hardcodedAPI.getBlockedThreatCategory();
    this.setState({ blockedThreatCategoryList: res || {} });
  };
  fetchCyberBlockedReason = async (): Promise<void> => {
    const res = await hardcodedAPI.getCyberBlockedReason();
    this.setState({ cyberBlockedReasonList: res || {} });
  };
  fetchBlockedRuleCategory = async (): Promise<void> => {
    const res = await hardcodedAPI.getBlockedRuleCategory();
    this.setState({ blockedRuleCategoryList: res || {} });
  };
  fetchWebFilteringBlockedReason = async (): Promise<void> => {
    const res = await hardcodedAPI.getWebFilteringBlockedReason();
    this.setState({ webFilteringBlockedReasonList: res || {} });
  };
  fetchTopHits = async (): Promise<void> => {
    const res = await hardcodedAPI.getTopHits();
    this.setState({ topHitsList: res || [] });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
