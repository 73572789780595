import React, { FC, useState } from "react";
import DropdownInput from "./components/DropdownInput";
import DropdownList from "./components/DropdownList";
import { CustomDropdownInput } from "./types";

type Props = {
  customInput?: React.FC<CustomDropdownInput>;
  value: string;
  list: Array<any>;
  onChange: (val: string) => void;
  id: string;
};

const PseudoDropdown: FC<Props> = ({
  customInput,
  value,
  onChange,
  list,
  id,
}) => {
  const [elementPosition, setElementPosition] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseDropdown = () => {
    setIsOpen(false);
  };

  return (
    <>
      <DropdownInput
        id={id}
        value={value}
        custom={customInput}
        handleToggleDropdown={handleToggleDropdown}
        setElementPosition={setElementPosition}
        isOpen={isOpen}
      />
      <DropdownList
        list={list}
        isOpen={isOpen}
        onClose={handleCloseDropdown}
        coords={elementPosition}
        onClick={onChange}
      />
    </>
  );
};

export default PseudoDropdown;
