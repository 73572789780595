/* eslint-disable css-modules/no-unused-class */
import React, { ReactElement, useCallback, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { useClickOutside } from "../../../helpers/hooks/useClickOutside";
import { DropdownItem } from "../../../helpers/types";
import { OptionalLabel } from "../OptionalLabel";
import DropdownInput from "./components/DropdownInput";
import DropdownList from "./components/DropdownList";
import Error from "./components/Error";

import styles from "./DropdownBasic.module.scss";

export type DropDownBasicProps<D> = {
  itemsList:
    | Array<DropdownItem<D>>
    | { [key: string]: Array<DropdownItem<D>> }
    | undefined;
  onChange: (item: DropdownItem<D>) => void;
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  selected?: DropdownItem<D>;
  isSmaller?: boolean;
  isMedium?: boolean;
  isNotFormElement?: boolean;
  icon?: ReactElement;
  isPseudoBtnMode?: boolean;
  selectedValues?: any;
  isListEditable?: boolean;
  createText?: string;
  onCreate?: () => void;
  disabled?: boolean;
  isPlaceholderAlwaysVisible?: boolean;
  isNotEditable?: boolean;
  isRightCorner?: boolean;
  id: string;
  onEditOption?: (key?: string) => void;
  onDeleteOption?: (key?: string) => void;
  spanClassName?: string;
  listClassName?: string;
};

function DropdownBasic<D>(props: DropDownBasicProps<D>): ReactElement {
  const {
    onChange,
    itemsList,
    className,
    label,
    error,
    selected,
    isSmaller,
    isMedium,
    isNotFormElement,
    placeholder,
    icon,
    isPseudoBtnMode,
    isListEditable,
    createText,
    onCreate,
    onEditOption,
    onDeleteOption,
    disabled,
    isPlaceholderAlwaysVisible,
    isNotEditable,
    isRightCorner,
    id,
    spanClassName,
    listClassName,
    ...restProps
  } = props;
  const [isListShown, setIsListShown] = useState(false);

  const dropDownRef = useClickOutside(() => setIsListShown(false));

  const handleSelect = useCallback(
    (item): void => {
      onChange(item);
      setIsListShown(false);
    },
    [onChange, setIsListShown]
  );

  return (
    <OptionalLabel
      className={classNames(className, styles.dropDownLabel)}
      isShown={Boolean(label)}
      text={label}
      medium={isMedium}
      {...restProps}
    >
      <div data-cy="input-wrapper" style={{ width: "100%" }}>
        <div
          ref={dropDownRef as React.RefObject<HTMLDivElement>}
          className={styles.dropDownWrapper}
        >
          <DropdownInput
            id={`dropBasic-${id}`}
            onClick={() => {
              setIsListShown((prevState) => !prevState);
            }}
            isSmaller={isSmaller}
            isMedium={isMedium}
            isPseudoBtnMode={isPseudoBtnMode}
            icon={icon}
            isListShown={isListShown}
            selected={selected}
            placeholder={placeholder}
            isError={Boolean(error)}
            disabled={disabled}
            isPlaceholderAlwaysVisible={isPlaceholderAlwaysVisible}
            isNotEditable={isNotEditable}
          />
          <DropdownList
            valuesList={itemsList || undefined}
            isListShown={isListShown}
            handleSelect={handleSelect}
            isSmaller={isSmaller}
            isMedium={isMedium}
            isNotFormElement={isNotFormElement}
            isListEditable={isListEditable}
            createText={createText}
            onCreate={onCreate}
            setIsListShown={setIsListShown}
            isRightCorner={isRightCorner}
            onEditOption={onEditOption}
            onDeleteOption={onDeleteOption}
            spanClassName={spanClassName}
            className={listClassName}
          />
        </div>
        {error && <Error error={error} />}
      </div>
    </OptionalLabel>
  );
}

export default DropdownBasic;
