/* eslint-disable css-modules/no-unused-class */
import React, { FC } from "react";
import { ORANGE } from "../../../../../helpers/common/colorAliases";
import WarningIcon from "../../../../icons/WarningIcon";
import styles from "./Error.module.scss";

const Error: FC<{ error: string }> = ({ error }) => {
  return (
    <>
      {error && (
        <div className={styles.errorContainer}>
          <WarningIcon color={ORANGE} />
          <span
            className={styles.errorText}
            title={error}
            data-cy="input-error"
          >
            {error}
          </span>
        </div>
      )}
    </>
  );
};

export default Error;
