import React, { CSSProperties, useCallback, useState } from "react";

import { Cell, CellValue, Row } from "react-table";
import { classNames } from "../../../../../helpers/common/classNames";
import {
  EXPANDER,
  SETTINGS,
} from "../../../../../helpers/common/constantsAlias";
import styles from "./TableRow.module.scss";
import RowControls, { ActionFuncs } from "../RowControls";
import CheckMarkIcon from "../../../../icons/CheckMarkIcon";
import CloseIcon from "../../../../icons/CloseIcon";
import { useValidation } from "../../../../../helpers/validators/Validator";
import IconBlankButton from "../../../buttons/IconBlankButton";

type ClickFeature = {
  elementClass: string;
  clb: (row?: any) => void;
};
export type ClickFeatures = Array<ClickFeature>;

type TableRowProps<D extends { [key: string]: any }> = {
  prepareRow: (row: Row<D>) => void;
  row: Row<D>;
  actions: ActionFuncs<D>;
  isRowClickable?: boolean;
  grid: CSSProperties | undefined;
  onClick?: (row: D) => void; // todo remove, use rowActions
  onHover?: (row?: D) => void;
  clickFeatures?: ClickFeatures;
  className?: string;
  isRowDND?: boolean;
  moveRow?: any;
  isParentRowBasic?: boolean;
  noBorder?: boolean;
  isHighlighted?: boolean;
  isFullRowExpand?: boolean;
};

function TableRow<D extends { [key: string]: any }>({
  prepareRow,
  row,
  actions,
  grid,
  onClick,
  clickFeatures,
  className,
  noBorder,
  isHighlighted,
  isFullRowExpand,
  onHover = () => undefined,
}: TableRowProps<D>): React.ReactElement {
  prepareRow(row);
  const dropRef = React.useRef(null);
  const [hovered, setHovered] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<D>(row.original);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [errors, validate] = useValidation<D>(actions.updateValidator, [
    newValue,
  ]);

  const handleUpdate = useCallback(async () => {
    const { isOk } = validate();
    if (isOk && actions.onUpdate) {
      const success = await actions.onUpdate(newValue);
      if (success) setIsEditMode(false);
    }
  }, [validate, setIsEditMode, newValue, actions.onUpdate]);

  const tableBodyRow = classNames(
    className,
    isHighlighted && styles.highlighted,
    styles.row,
    noBorder && styles.noBorders,
    (onClick || isFullRowExpand) && !isEditMode && styles.clickableRow
  );

  const handleRowClick = (row: Row<D>, e: React.MouseEvent<HTMLElement>) => {
    if (isEditMode) return;
    if (isFullRowExpand) {
      row.toggleRowExpanded();
    }
    let elementHasFeature;
    if (clickFeatures && e.target) {
      clickFeatures.forEach((clickFeature) => {
        elementHasFeature =
          e.target instanceof HTMLElement &&
          e.target.className.includes(clickFeature.elementClass);
        if (elementHasFeature) {
          if (!e.defaultPrevented) e.preventDefault();
          clickFeature.clb(row.original);
        }
      });
    }
    if (!clickFeatures || !elementHasFeature) onClick && onClick(row.original);
  };

  return (
    <div
      ref={dropRef}
      {...row.getRowProps()}
      style={grid}
      className={tableBodyRow}
      onClick={(e) => handleRowClick(row, e)}
      onMouseEnter={() => {
        onHover(row.original);
        setHovered(true);
        actions.onHover && actions.onHover(row.original);
      }}
      onMouseLeave={() => {
        onHover(undefined);
        setHovered(false);
      }}
    >
      {row.cells.map((cell: Cell<D>) => {
        const tdStyles = classNames(
          styles.td,
          isEditMode && styles.editMode,
          cell.column.id === SETTINGS && styles.settingsCell,
          cell.column.id === EXPANDER && styles.expandCell
        );
        return (
          <div {...cell.getCellProps()} className={tdStyles}>
            {cell.render(isEditMode ? "EditCell" : "Cell", {
              value: newValue[cell.column.id],
              onChange: (value: CellValue) => {
                setNewValue((v) => ({ ...v, [cell.column.id]: value }));
              },
              error: errors && errors[cell.column.id],
            })}
          </div>
        );
      })}
      {isEditMode && (
        <EditControls
          id={row.id}
          onOk={handleUpdate}
          onCancel={() => setIsEditMode(false)}
        />
      )}
      {!isEditMode && hovered && (
        <RowControls
          row={row}
          actions={{
            ...actions,
            onEdit: actions.onUpdate
              ? () => setIsEditMode(true)
              : actions.onEdit,
          }}
        />
      )}
    </div>
  );
}

function EditControls({
  id,
  onOk,
  onCancel,
}: {
  id: string;
  onOk: () => void;
  onCancel: () => void;
}) {
  return (
    <div className={styles.editControls}>
      <IconBlankButton
        id={`update-${id}`}
        icon={CheckMarkIcon}
        title="Update"
        onClick={(e) => {
          e.stopPropagation();
          onOk();
        }}
      />
      <IconBlankButton
        id={`cancel-${id}`}
        icon={CloseIcon}
        title="Cancel"
        onClick={(e) => {
          e.stopPropagation();
          onCancel();
        }}
      />
    </div>
  );
}

export default TableRow;
