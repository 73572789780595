import React, { useEffect, useState } from "react";
import DropdownAutocomplete from "../../../../../../components/common/Dropdown/DropdownAutocomplete";
import FieldLoader from "../../../../../../components/common/formComponents/FieldLoader";
import { withTokenInput } from "../../../../../../helpers/hocs/withTokenInput";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";

const VIList = ["Frankfurt project1", "Dallas - Project2"];

const CloudFields = () => {
  const [isLoaded, setLoaded] = useState(false);
  const [selectedVIs, setSelectedVI] = useState<Array<string>>([]);

  useEffect(() => {
    setTimeout(() => setLoaded(true), 1000);
  }, []);

  return (
    <div>
      {!isLoaded ? (
        <FieldLoader message={"Discovering Projects"} />
      ) : (
        <DropdownAutocomplete
          id={"cloudFieldsVi"}
          onChange={(val) => setSelectedVI(val)}
          selected={mapStringToItem("")}
          placeholder=""
          error={""}
          valuesList={VIList}
          currentValues={selectedVIs}
          isMedium
        />
      )}
    </div>
  );
};

export default withTokenInput(CloudFields);
