import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";

function EditIcon({ color, onClick }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M13.2929 1.70711C13.6834 1.31658 14.3166 1.31658 14.7071 1.70711L18.4832 5.48318C18.8737 5.8737 18.8737 6.50687 18.4832 6.89739L8.45382 16.9267C8.26629 17.1143 8.01193 17.2196 7.74672 17.2196H3.97064C3.41836 17.2196 2.97064 16.7719 2.97064 16.2196V12.4436C2.97064 12.1784 3.076 11.924 3.26354 11.7365L13.2929 1.70711Z"
        fill={color || BUTTON_PRIMARY}
      />
    </svg>
  );
}

export default EditIcon;
