import Validator from "./Validator";

export default function validateUserGroup(
  data: { name: string; ip_addresses: Array<string> },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkInputLength(data.name, "name", 24);
  return validator;
}
