import React, { FC, useEffect, useMemo, useState } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import { Toggle } from "../../../../../../../components/common/Toggle";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import { formFieldProps } from "../../../../../../../helpers/dialogs/FieldProps";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import {
  MAX_BANDWIDTH_LIST,
  SUBSCRIPTION_PERIOD_LIST,
} from "../../../../../utils/wizardToolConsts";
import ConnectionTypeGroup from "../../common/ConnectionTypeGroup";
import FormFooter from "../../common/FormFooter/FormFooter";
import SegmentsComponent from "../../common/SegmentsComponent";
import CloudFields from "../../common/CloudFields";
import styles from "./ConnectionForm.module.scss";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import { ConnectionFields } from "../../utils/formConsts";
import validateConnection from "../../../../../../../helpers/validators/ConnectionValidator";
import FormTitle from "../../common/FormTitle/FormTitle";
import { generateRandomPassword } from "../../../../../../../helpers/generateRandomPassword";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import RemoteNetworks from "../../common/RemoteNetworks";

const DEFAULT_FIELDS: ConnectionFields = {
  name: "",
  description: "",
  max_bandwidth: "100Mbps",
  subscription: "Yearly",
  network_segments: [],
  remoteNetworks: [],
  ip: "",
  psk: generateRandomPassword(),
  remote_device_type: "",
  labels: {},
};

const AddConnectionForm: FC = () => {
  const { user } = useUserContext();
  const {
    activeConnectionType,
    addIpsecService,
    activeLocation,
  } = useConnectionsContext();
  const [isAgent, setIsAgent] = useState<boolean>(false);
  const [
    fields,
    handleFieldChange,
    resetFields,
  ] = useFormField<ConnectionFields>(DEFAULT_FIELDS);
  const [errors, validate] = useValidation<ConnectionFields>(
    validateConnection,
    [fields]
  );
  const [connectioTypeField, setConnectionTypeField] = useState<string>(
    "ipsec"
  );

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, undefined),
    [handleFieldChange, fields, undefined]
  );

  useEffect(() => {
    return () => {
      resetFields(DEFAULT_FIELDS);
    };
  }, [activeConnectionType]);

  const handleAddConnection = () => {
    const { isOk } = validate();
    if (isOk && activeConnectionType && activeLocation) {
      const labels = { remote_type: activeConnectionType };
      addIpsecService({ ...fields, labels }, user.name, activeLocation);
    }
  };

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={fields.name}
          label="Name"
          onChange={(e) => {
            handleFieldChange("name", e.target.value);
          }}
          isFullValue
          medium
          error={errors?.name}
        />
        <ConnectionTypeGroup
          value={connectioTypeField}
          onChange={(val) => setConnectionTypeField(val)}
          type={activeConnectionType}
        />
        {activeConnectionType === "bareMetal" && (
          <Toggle
            isChecked={isAgent}
            onChange={() => setIsAgent((prev) => !prev)}
            label="Agent"
            disabled
          />
        )}

        {connectioTypeField === "ipsec" && (
          <IpsecConnection
            fields={fields}
            fieldProps={fieldProps}
            handleFieldChange={handleFieldChange}
            className={styles.fieldsGroup}
            showIpGroup={!isAgent}
            errors={errors}
          />
        )}

        {isAgent && <CloudFields title="Enter token" />}
        {connectioTypeField === "directConnect" && (
          <div className={styles.msg}>
            Please contact{" "}
            <a className={styles.link}>support@insidepacket.com</a> to establish
            direct connection
          </div>
        )}
      </div>
      <FormFooter
        onAdd={handleAddConnection}
        disabled={{ onAdd: connectioTypeField !== "ipsec" }}
      />
    </>
  );
};
export default AddConnectionForm;

type FormProps = {
  fields: any;
  fieldProps: any;
  handleFieldChange: any;
  className?: string;
  showIpGroup?: boolean;
  errors?: { [key: string]: string };
};

const IpsecConnection: FC<FormProps> = ({
  fieldProps,
  handleFieldChange,
  className,
  fields,
  showIpGroup = true,
  errors,
}) => {
  return (
    <>
      <DropdownBasic
        id={"connectionFormSubscriptionPeriod"}
        onChange={(val) => handleFieldChange("max_bandwidth", val.key)}
        selected={mapStringToItem(fields.max_bandwidth)}
        label="Connection Bandwidth"
        itemsList={MAX_BANDWIDTH_LIST.map((key) => mapStringToItem(key))}
        isMedium
      />
      <DropdownBasic
        id={"connectionFormConnectionBandwidth"}
        onChange={(val) => handleFieldChange("subscription", val.key)}
        selected={mapStringToItem(fields.subscription)}
        label="Subscription period"
        itemsList={SUBSCRIPTION_PERIOD_LIST.map((key) => mapStringToItem(key))}
        isMedium
      />
      <SegmentsComponent
        list={fields.network_segments || []}
        onChange={handleFieldChange}
        error={errors?.network_segments}
      />
      {/* <Input
        {...fieldProps("remoteNetworks")}
        value={fields.remoteNetworks}
        label={"Remote Network"}
        placeholder="Remote Network"
        error={errors?.remoteNetworks}
      /> */}
      <RemoteNetworks
        list={fields.remoteNetworks || []}
        onChange={handleFieldChange}
        error={errors?.remoteNetworks}
      />
      {showIpGroup && (
        <div className={className}>
          <Input
            {...fieldProps("ip")}
            value={fields.ip}
            label="Generated IPSEC connection details"
            placeholder="Peer IP"
            isPlaceholderAlwaysVisible
            error={errors?.ip}
          />
          <Input
            {...fieldProps("psk")}
            label={undefined}
            value={fields.psk}
            placeholder="PSK"
            isPlaceholderAlwaysVisible
          />
        </div>
      )}
    </>
  );
};
