import React, { FC } from "react";
import { useDashboardFilterContext } from "../../../../contexts/dashboardContext/DashboardFilterContext";
import Star from "../../../../pages/WizardToolPage/ConnectionStep/components/ConnectionsScheme/Star";

type Props = { hideMenu?: boolean };

const StarComponent: FC<Props> = () => {
  const {
    selectedInterface,
    setSelectedInterface,
    interfaces,
    interfacesStatus,
  } = useDashboardFilterContext();
  return (
    <Star
      viMap={interfaces}
      onClick={(el) => setSelectedInterface(el)}
      selectedInterface={selectedInterface}
      isLoading={interfacesStatus?.state === "pending"}
    />
  );
};

export default StarComponent;
