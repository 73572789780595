import { FC } from "react";
import { classNames } from "../../../../../../../helpers/common/classNames";
import SystemSection from "./SystemSection";
import NodesSection from "./NodesSection";
import DialogBtmButtons from "../../../../../../../components/dialogs/common/DialogBtmButtons";
import styles from "./ContentBlock.module.scss";
import {
  ChangeField,
  FieldsType,
} from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { RequestStatus } from "../../../../../../../helpers/types";
import NodeConfig from "./NodeConfig";
import Section from "../../../../../../../components/common/Section";

type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  onClose: () => void;
  onAction: () => Promise<void>;
  actionStatus?: RequestStatus;
  isEdit?: boolean;
  errors?: { [key: string]: string };
  isNode?: boolean;
};

const ContentBlock: FC<Props> = ({
  className,
  fields,
  onChange,
  onClose,
  onAction,
  actionStatus,
  isEdit,
  errors,
  isNode,
}) => {
  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.content}>
        {isNode ? (
          <Section title="Node" className={styles.section} columnsCount={1}>
            <NodeConfig
              idx={0}
              fields={fields}
              onChange={(field: string | number, value: any) =>
                onChange(field as string, value)
              }
              onDelete={() => undefined}
              errors={errors}
              isEdit
            />
          </Section>
        ) : (
          <>
            <SystemSection
              className={styles.section}
              fields={fields}
              onChange={onChange}
              errors={errors}
            />
            <NodesSection
              className={styles.section}
              fields={fields}
              onChange={onChange}
              errors={errors}
            />
          </>
        )}
      </div>
      <DialogBtmButtons
        errorDisplay={actionStatus}
        className={styles.footer}
        controls={{
          okText: isEdit ? "Save" : "Add",
          onOk: () => onAction(),
          cancelText: "Cancel",
          onCancel: () => onClose(),
        }}
      />
    </div>
  );
};
export default ContentBlock;
