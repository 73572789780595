import { FWFilters } from "./api/apiTypes";
import {
  PROTOCOL_UDP,
  PROTOCOL_ICMP,
  PROTOCOL_TCP,
  PROTOCOL_TCP_UDP,
  PROTOCOL_ANY,
} from "./common/constantsAlias";

export const PROTOCOLS_CODE_MAP: { [key: string]: string } = {
  "6": PROTOCOL_TCP,
  "17": PROTOCOL_UDP,
  "1": PROTOCOL_ICMP,
};

export const PROTOCOLS_NAMES_MAP: { [key: string]: number } = {
  [PROTOCOL_TCP]: 6,
  [PROTOCOL_UDP]: 17,
  [PROTOCOL_ICMP]: 1,
};

export const getProtocolByValue = (element: string): Array<FWFilters> => {
  if (element === PROTOCOL_ANY || !element) {
    return [];
  }
  if (!Object.keys(PROTOCOLS_NAMES_MAP).includes(element)) {
    return [{ filter_type: "ip_protocol", values: +element }];
  }
  if (element === PROTOCOL_TCP_UDP) {
    return [
      { filter_type: "ip_protocol", values: 6 },
      { filter_type: "ip_protocol", values: 17 },
    ];
  }
  return [
    {
      filter_type: "ip_protocol",
      values: PROTOCOLS_NAMES_MAP[element],
    },
  ];
};

export const getPotocolName = (protocols: Array<FWFilters>): string => {
  if (!protocols || protocols.length === 0) return PROTOCOL_ANY;
  if (protocols?.length === 2) return PROTOCOL_TCP_UDP;
  const protocol = protocols[0]?.values.toString();
  if (protocol === "" || protocol === "0") return PROTOCOL_ANY;
  if (!Object.keys(PROTOCOLS_CODE_MAP).includes(protocol)) {
    return protocol;
  }
  return PROTOCOLS_CODE_MAP[protocol];
};
