import React, { FC, ReactElement } from "react";
import DropdownBasic from "../../common/Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import LocationDropdownContextContainer, {
  useLocationDropdownContext,
} from "./LocationDropdownContext";
import LocationIcon from "../../icons/LocationIcon";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";

type Props = {
  onChange: (field: string) => void;
  disabled?: boolean;
  selected?: string;
  label?: string;
};

export const LocationDropdown: FC<Props> = ({
  onChange,
  disabled,
  selected,
  label,
}): ReactElement => {
  const { list = [] } = useLocationDropdownContext();
  return (
    <DropdownBasic
      label={label}
      id={"location"}
      onChange={(val) => {
        onChange(val.key);
      }}
      selected={list.find((loc) => loc?.key === selected)}
      itemsList={list}
      isMedium
      error={""}
      disabled={disabled}
      icon={<LocationIcon color={GREY_DISABLED} />}
    />
  );
};

export default withContexts<Props>(LocationDropdown, [
  LocationDropdownContextContainer,
]);
