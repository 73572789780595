import React, { FC } from "react";
import Button from "../../../../../../components/common/buttons/Button";
import styles from "./styles.module.scss";

type Props = {
  onClick: () => void;
};
export const SubmitButton: FC<Props> = ({ onClick }) => {
  return (
    <Button className={styles.submitButton} onClick={onClick}>
      Apply
    </Button>
  );
};
