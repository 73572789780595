import { Event } from "../pages/EventsPage/types";

export const extractLogsFromEvents = (events: any): Array<Event> => {
  const eventsList: Array<any> = events?.hits.hits.map((el: any, i: number) => {
    const fields = el.fields;
    const isRestore = fields?.entity?.[0] === "restore";
    const isRestoreOk = isRestore && fields?.log?.[0].includes("successfull");
    let status;
    if (isRestoreOk) {
      status = "Success";
    } else if (isRestore) {
      status = "Error";
    } else {
      status = fields?.status?.[0];
    }

    const event = {
      timestamp: fields?.["@timestamp"]?.[0],
      id: i,
      logs: parsingEventsToObject(
        fields?.log?.[0],
        fields?.entity?.[0],
        isRestore
      ),
      activity: fields?.action?.[0],
      type: fields?.entity?.[0],
      status: status,
      destination: fields?.["Destination IP"]?.[0],
      gateway: fields?.Gateway?.[0],
      location: fields?.location?.[0],
      neighbor_ip: fields?.neighbor_ip?.[0],
    };
    return event;
  });
  return eventsList;
};

export const parsingEventsToObject = (
  logs: string,
  entity: string,
  isRestore: boolean
): any => {
  if (!logs || !entity) return;
  if (isRestore) return [{ restore: logs }];
  const regex = /(?<![,:])\s+/; // Negative lookbehind assertion for comma
  const logsArr = logs.split(regex);
  const result: Array<any> = [];
  logsArr.forEach((el, i) => {
    if (i === 0 || i >= logsArr.length - 3) return el;
    const delimiterIndex = el.indexOf("=");
    const key = el.substring(0, delimiterIndex);
    const value = el.substring(delimiterIndex + 1);
    if (!ENTITY_FIELDS_MAP[entity] || ENTITY_FIELDS_MAP[entity].includes(key))
      result.push({ [key]: value });
  });
  return result;
};

const ENTITY_FIELDS_MAP: { [key: string]: Array<string> } = {
  end_point: ["name", "tenant_name", "type", "ip", "shared", "location_name"],
  "autonomous system": ["Created asn", "id", "tenant_name"],
  "bgp neighbor": ["tenant_name", "remote_router_ip", "remote_asn", "password"],
  connection: [
    "id",
    "name",
    "tenant_name",
    "fw",
    "ddos",
    "fqdn",
    "sdr",
    "swg",
    "ids",
    "nat",
    "lb",
    "sdwan",
    "segments",
    "a_endpoint_name",
    "a_endpoint_conf",
    "z_endpoint_name",
    "z_endpoint_conf",
    "atrinet_id",
    "description",
  ],
  tenant: [
    "is_firewall_service",
    "is_nat_service",
    "is_ids_service",
    "is_ddos_service",
    "is_load_balancing_service",
    "is_secure_web_gw_service",
    "is_mesh_enabled",
    "vrfs",
  ],
  "firewall rule": [
    "name",
    "tenant_name",
    "username",
    "created_at",
    "updated_at",
    "user_ip_address",
    "priority",
    "filters",
    "forwarding_policy",
    "description",
  ],
  "port interface": [
    "mtu",
    "physical_port_id",
    "ip_addresses",
    "virtual_interface_type",
    "vrf_id",
  ],
  "port vlan": ["mtu", "vlan_id", "physical_port_id", "ip_addresses"],
  // "southbound IDS": [
  //   "is_enable",
  //   "type",
  //   "id",
  //   "northbound_id",
  //   "vrf_id",
  //   "vrf_name",
  //   "node_name",
  //   "vrf",
  // ],
  // "southbound SDR": [
  //   "is_enable",
  //   "rate",
  //   "type",
  //   "id",
  //   "northbound_id",
  //   "vrf_id",
  //   "vrf_name",
  //   "node_name",
  //   "vrf",
  // ],
  // "southbound dpi": [
  //   "is_enable",
  //   "type",
  //   "id",
  //   "northbound_id",
  //   "vrf_id",
  //   "vrf_name",
  //   "node_name",
  //   "vrf",
  // ],
  // "southbound firewall rule": [
  //   "id",
  //   "vrf_id",
  //   "node_name",
  //   "northbound_id",
  //   "priority",
  //   "filters",
  //   "forwarding_policy",
  //   "type",
  // ],
  // "southbound port interface": [
  //   "max_bandwidth",
  //   "northbound_id",
  //   "node_name",
  //   "administrative_state",
  //   "bridge_domain_id",
  //   "alias",
  //   "mac_address",
  //   "physical_port_id",
  //   "type",
  //   "id",
  // ],
};
