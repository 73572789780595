import { FC } from "react";
import SeacrhPlaceholderImg from "./components/SeacrhPlaceholderImg";
import styles from "./SeacrhPlaceholder.module.scss";

const SeacrhPlaceholder: FC = () => {
  return (
    <div className={styles.wrapper}>
      <SeacrhPlaceholderImg />
      <p>No matching results</p>
    </div>
  );
};
export default SeacrhPlaceholder;
