import React, { FC } from "react";
import styles from "./VirtualInterfacePage.module.scss";
import { withLayout } from "../../helpers/hocs/withLayout";
import { OneSystemLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { PageProps } from "../../helpers/types";
import VirtualInterfaceContent from "./VirtualInterfaceContent";

const VirtualInterfacePage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <OneSystemLayout>
      <div className={styles.contentWrapper}>
        <Tabs />
        <VirtualInterfaceContent />
      </div>
    </OneSystemLayout>
  );
};

export default withLayout<PageProps>(VirtualInterfacePage);
