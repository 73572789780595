import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ThreeDotsIconNew({ color, onClick }: IconProps): ReactElement {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      style={{ cursor: onClick ? "pointer" : "unset" }}
    >
      <path
        d="M7.00002 7.00002H5C5 4.65688 5 7.34317 5 5.00003H7.00002V7.00002ZM7.00002 3.00001H5V1H7.00002V3.00001Z"
        fill={color || FONT}
      />
      <path d="M5 11H7.00002V9.00003H5V11Z" fill={color || FONT} />
    </svg>
  );
}

export default ThreeDotsIconNew;
