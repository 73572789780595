import { ReactElement } from "react";
import { IconProps } from "../../../../../helpers/types";
import IDSIPSIcon from "../../../../../components/icons/zayo/services/IDSIPSIcon";
import StaticRouteIcon from "../../../../../components/icons/StaticRouteIcon";
import FirewallIcon from "../../../../../components/icons/tabs/FirewallIcon";
import VirtInterfaceIcon from "../../../../../components/icons/VirtInterfaceIcon";
import TenantsIcon from "../../../../../components/icons/tabs/TenantsIcon";
import LocationFillIcon from "../../../../../components/icons/LocationFillIcon";
import ApplicationsIcon from "../../../../../components/icons/topologyButtons/ApplicationsIcon";
import CloudRouterIcon from "../../../../../components/icons/zayo/menu/CloudRouterIcon";
import StatusIconOutline from "../../../../../components/icons/StatusIconOutline";

export const getIconByEntity = (
  entity: string
): ((props: IconProps) => ReactElement) => {
  switch (entity.toLowerCase()) {
    case "ids":
      return IDSIPSIcon;
    case "dpi":
      return ApplicationsIcon;
    case "route":
      return StaticRouteIcon;
    case "firewall rule":
      return FirewallIcon;
    case "virtual interface tenant bridge domain":
    case "connection":
      return VirtInterfaceIcon;
    case "tenant":
      return TenantsIcon;
    case "end_point":
      return LocationFillIcon;
    case "fqdn":
      return CloudRouterIcon;
    default:
      return StatusIconOutline;
  }
};
