import React, { useEffect, useMemo } from "react";
import DropdownBasic from "../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../components/common/formComponents/Input";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import Section from "../../../../components/common/Section";
import LocationIcon from "../../../../components/icons/LocationIcon";
import { VRFType } from "../../../../helpers/api/apiTypes";
import { useVRFList } from "../../../../helpers/api/hooks/useVRFList";
import { useSystemsList } from "../../../../helpers/api/systems/useSystemsList";
import { classNames } from "../../../../helpers/common/classNames";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { DropdownItem } from "../../../../helpers/types";
import { System, SystemAPI } from "../../../Systems/Provisioning/types";
import DialogFrame from "../../common/DialogFrame/DialogFrame";
import { DialogComponentProps } from "../../common/types";
import { useIpamContext } from "../../IpamContext";
import { CreateIpam, IpamType } from "../../types";

import styles from "./DialogContent.module.scss";
import { DialogFooter } from "./DialogFooter";
import { useValidation } from "../../../../helpers/validators/Validator";
import validateIPAM from "../../../../helpers/validators/IPAMValidation";

const TYPES: Array<DropdownItem<string>> = [
  { key: "system", value: "System" },
  { key: "location", value: "Location" },
  { key: "global", value: "Global" },
];

const mapSystemsToDropdownList = (
  systems: Array<System | SystemAPI>
): Array<DropdownItem<string>> => {
  return systems.map((s) => ({ key: s.name, value: s.name }));
};

const mapSystemsToLocationDropdownList = (
  systems: Array<System | SystemAPI>
): Array<DropdownItem<string>> => {
  return systems
    .filter((s) => s.location)
    .map((s) => ({ key: s.name, value: s.location }));
};

const mapVrfToDropdownList = (
  vrfs: Array<VRFType>
): Array<DropdownItem<string>> => {
  return vrfs
    .filter((v) => v.vrf_type === "internet")
    .map((v) => ({ key: v.name, value: v.name }));
};

type Props = DialogComponentProps & {
  fields: CreateIpam;
  onChange: (
    name: keyof CreateIpam,
    value: string | undefined | boolean
  ) => void;
  onClose: () => void;
};

const getSelectedSystem = (
  systemList: Array<System | SystemAPI>,
  type: IpamType,
  fields: CreateIpam
) => {
  if (type === "system") {
    return systemList.find((s) => s.name === fields.data);
  }

  if (type === "location") {
    return systemList.find((s) => s.location === fields.data);
  }

  return systemList[0];
};

export const DialogContent = ({
  className,
  fields,
  onChange,
  onClose,
}: Props): JSX.Element => {
  const { createIpam } = useIpamContext();

  const [systemList, systemStatus] = useSystemsList();
  const systemIsLoading = systemStatus === "pending";
  const dropdownSystems = mapSystemsToDropdownList(systemList || []);

  const selectedSystem = getSelectedSystem(
    systemList || [],
    fields.type,
    fields
  );

  const [vrfList, vrfStatus] = useVRFList(selectedSystem?.name || "");
  const vrfIsLoading = vrfStatus === "pending";
  const dropdownVrfList = useMemo(() => mapVrfToDropdownList(vrfList || []), [
    vrfList,
  ]);

  const dropdownLocations = mapSystemsToLocationDropdownList(systemList || []);

  useEffect(() => {
    onChange("vrf_name", undefined);
  }, [fields.data, fields.type]);

  useEffect(() => {
    onChange("data", undefined);
  }, [fields.type]);

  const [errors, validate] = useValidation(validateIPAM, [fields]);

  const onAdd = async () => {
    const { isOk } = validate();
    if (isOk) {
      const res = await createIpam(fields as CreateIpam);
      if (res) {
        onClose();
      }
    }
  };

  return (
    <DialogFrame className={classNames(className, styles.dialogContent)}>
      <Section title="IPAM" columnsCount={2} className={styles.dialogForm}>
        <Input
          label="Pool Name"
          name="name"
          placeholder=""
          value={fields.name}
          onChange={(e) => onChange("name", e.target.value)}
          medium
          error={errors && errors["name"]}
        />
        <DropdownBasic
          id={"type"}
          label="Type"
          isMedium
          itemsList={TYPES}
          selected={mapStringToItem(
            TYPES.find((t) => t.key === fields.type)?.value || ""
          )}
          onChange={(item) => onChange("type", item.key)}
        />
        {fields.type === "system" && (
          <DropdownBasic
            id={"system"}
            label="System"
            isMedium
            itemsList={dropdownSystems}
            selected={mapStringToItem(fields.data || "")}
            onChange={(item) => onChange("data", item.value)}
            icon={systemIsLoading ? <LoaderIcon height={16} /> : undefined}
            error={errors && errors["data"]}
          />
        )}
        {fields.type === "location" && (
          <DropdownBasic
            id={"location"}
            label="Location"
            isMedium
            itemsList={dropdownLocations}
            selected={mapStringToItem(fields.data || "")}
            onChange={(item) => onChange("data", item.value)}
            icon={<LocationIcon />}
            error={errors && errors["data"]}
          />
        )}
        {fields.type === "global" && <div></div>}
        <DropdownBasic
          id={"vrf"}
          label="VRF"
          isMedium
          itemsList={dropdownVrfList}
          selected={mapStringToItem(fields.vrf_name || "")}
          onChange={(item) => onChange("vrf_name", item.key)}
          icon={vrfIsLoading ? <LoaderIcon height={16} /> : undefined}
          error={errors && errors["vrf_name"]}
        />
        <Input
          label="Start IP"
          name="start_ip"
          placeholder=""
          medium
          value={fields.from_ip}
          onChange={(e) => onChange("from_ip", e.target.value)}
          error={errors && errors["from_ip"]}
        />
        <Input
          label="End IP"
          name="end_ip"
          placeholder=""
          medium
          value={fields.to_ip}
          onChange={(e) => onChange("to_ip", e.target.value)}
          error={errors && errors["to_ip"]}
        />
      </Section>
      <DialogFooter
        className={styles.footer}
        onAdd={onAdd}
        onCancel={onClose}
      />
    </DialogFrame>
  );
};
