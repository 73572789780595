import React, { PropsWithChildren } from "react";

// to pass props from one context to another

type ResultComponent<C, P extends PropsWithChildren<any>> = (
  props: P & { [K in keyof C]: never }
) => React.ReactElement;

export type IContextToProps<C> = <P extends PropsWithChildren<any>>(
  WrappedComp: React.ComponentType<P & C>
) => ResultComponent<C, P>;

function withContextToProps<C>(useCont: () => C): IContextToProps<C> {
  return <P extends PropsWithChildren<any>>(
    WrappedComponent: React.ComponentType<P & C>
  ): ResultComponent<C, P> => {
    return function Component(props) {
      const cont = useCont();
      return <WrappedComponent {...cont} {...props} />;
    };
  };
}

export default withContextToProps;
