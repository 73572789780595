import React, { CSSProperties, FC } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import StatusIcon from "../../../../../../components/icons/StatusIcon";
import ReactTooltip from "react-tooltip";
import WarningCircleIcon from "../../../../../../components/icons/WarningCircleIcon";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./BGPLabel.module.scss";
import { getColorFromStatus } from "../../../../../../helpers/common/colorHelpers";
import { getBGPStatusType } from "../../../helpers/getBGPStatusType";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../../../helpers/navigation";
import {
  BGP,
  CLOUD_ROUTER,
  NETWORK,
} from "../../../../../../helpers/navigation/entries";
import { useUserContext } from "../../../../../../contexts/UserContext";

type Props = {
  id: string | number;
  className?: string;
  bgpStatus: string;
  style?: CSSProperties;
};

const BGPLabel: FC<Props> = ({ id, className, bgpStatus, style }) => {
  const history = useHistory();
  const { isDemo } = useUserContext();

  const handleClick = () => {
    history.push(formPathFromArray([NETWORK(), CLOUD_ROUTER(), BGP()]));
  };

  let bgpStatusHandled = bgpStatus;
  if (isDemo && bgpStatus !== "Absent") {
    bgpStatusHandled = "Established";
  }
  const bgpStatusType = getBGPStatusType(bgpStatusHandled);
  const isConnect = bgpStatusHandled === "Established";
  const labelStyles = classNames(
    styles.wrapper,
    styles[bgpStatusType],
    className
  );
  const labelId = bgpStatusHandled + id;
  return (
    <div
      data-tip
      data-for={isConnect ? "" : labelId}
      className={labelStyles}
      onClick={handleClick}
      style={style}
    >
      <StatusIcon
        color={getColorFromStatus(bgpStatusType)}
        className={styles.statusIcon}
      />
      <span>BGP</span>
      {!isConnect && (
        <WarningCircleIcon
          color={getColorFromStatus(bgpStatusType)}
          className={styles.configIcon}
        />
      )}
      <ReactTooltip
        className={styles.tooltip}
        id={labelId}
        aria-haspopup="true"
        place="bottom"
        type="light"
        effect="solid"
        arrowColor={"transparent"}
      >
        <div className={styles.tooltipContent}>
          <WarningCircleIcon
            color={getColorFromStatus(bgpStatusType)}
            className={styles.configIcon}
          />
          {bgpStatusHandled === "Absent" ? (
            <span>
              BGP is not configured.
              <br />
              Please configure.
            </span>
          ) : (
            <span>BGP is not established.</span>
          )}
        </div>
      </ReactTooltip>
    </div>
  );
};
export default BGPLabel;
