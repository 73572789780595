import React, { FC } from "react";
import CloseIcon from "../../../../components/icons/CloseIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import { useNetworkContext } from "../../../Network/context/NetworkContext";
import { Subnets } from "../types";

import styles from "./ArraySubnetCell.module.scss";

type Props = {
  subnets: Subnets;
  subnet_name: string;
};

export const ArraySubnetCell: FC<Props> = ({
  subnets: subnetList,
  subnet_name,
}) => {
  const { deleteSegment } = useNetworkContext();

  const deleteSubnetPrefix = (prefix_name: string) => {
    deleteSegment(subnet_name, [prefix_name]);
  };

  return (
    <div className={styles.subnets}>
      {subnetList.map((prefix_name, id) => (
        <Subnet
          prefix_name={prefix_name}
          key={id}
          onDelete={deleteSubnetPrefix}
        />
      ))}
    </div>
  );
};

type SubnetProps = {
  prefix_name: string;
  onDelete: (prefix_name: string) => void;
};
const Subnet: FC<SubnetProps> = ({ prefix_name, onDelete }) => {
  return (
    <div className={styles.subnet}>
      {prefix_name}
      <CloseIcon
        className={styles.subnetClose}
        color={GREY_FONT}
        height={10}
        onClick={() => onDelete(prefix_name)}
      />
    </div>
  );
};
