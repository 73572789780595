import React from "react";
import { useHistory } from "react-router-dom";
import NavigationTabs from "../../../../components/common/navigation/NavigationTabs";
import { formPathFromArray } from "../../../../helpers/navigation";
import { ZAYO_CONNECTIONS } from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";

import styles from "./ConnectionTab.module.scss";
import ActiveTenantSelector from "../../../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import Button from "../../../../components/common/buttons/Button";

export const ConnectionTab: React.FC<{
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  hideControls?: boolean;
}> = () => {
  const history = useHistory();

  const navToLog = () => {
    history.push(`${location.pathname}/historyLog`);
  };

  return (
    <div className={styles.tabsWrapper}>
      <TabsComponent />
      <div className={styles.tabActions}>
        <ActiveTenantSelector />
        <Button onClick={navToLog}>History Log</Button>
      </div>
    </div>
  );
};
export const TabsComponent: React.FC = () => {
  const history = useHistory();
  const handleClick = (path: PathEntry) => {
    history.replace(formPathFromArray([ZAYO_CONNECTIONS(), path]));
  };

  return (
    <NavigationTabs
      entries={servicesArray}
      disabledTabKeys={[]}
      onClick={handleClick}
    />
  );
};

const servicesArray = [ZAYO_CONNECTIONS()];
