import { FC, useEffect, useState } from "react";
import {
  COL_GAP,
  OFFSET_Y,
  PORT_HEIGHT,
  PORT_ROWS,
  PORT_WIDTH,
} from "./consts";
import PortGroup from "./components/PortGroup";
import SectionTitle from "./components/SectionTitle";
import { PortTypeScheme } from "../../../types";
import { getServerPortsOnly } from "./helpers/getServerPortsOnly";

type Props = { x: number; y: number; list: Array<PortTypeScheme> };

const UNIT_TYPE = 0;
const TITLE = "dell";
const WIDTH = PORT_WIDTH * 4 + COL_GAP * 3;

const PortsDell: FC<Props> = ({ x, y, list }) => {
  const [serverList, setServerList] = useState<Array<PortTypeScheme>>([]);

  useEffect(() => {
    if (list) {
      const serverList = getServerPortsOnly(list);
      setServerList(serverList);
    }
  }, [list]);

  return (
    <svg
      x={x}
      y={y}
      width={WIDTH}
      height={PORT_HEIGHT + OFFSET_Y}
      viewBox={`0 0 ${WIDTH} ${PORT_HEIGHT + OFFSET_Y}`}
    >
      <SectionTitle title={TITLE} />
      {PORT_ROWS.map((_, rowIndex) => {
        const data = serverList.filter(
          (port) => port.parsedName.portIdx === rowIndex.toString()
        );
        if (data.length === 0) return null;

        const x = rowIndex * (PORT_WIDTH + COL_GAP);
        const y = OFFSET_Y;
        return (
          <PortGroup
            key={rowIndex}
            x={x}
            y={y}
            showDots={false}
            type={UNIT_TYPE}
            portIdx={rowIndex}
            data={data}
          />
        );
      })}
    </svg>
  );
};

export default PortsDell;
