import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function OneByOneNatIcon({ color = FONT }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.28037 3.57568C5.65423 3.57568 5.14665 4.08327 5.14665 4.7094C5.14665 5.33554 5.65423 5.84313 6.28037 5.84313C8.5762 5.84313 10.4373 7.70427 10.4373 10.0001C10.4373 12.2959 8.5762 14.1571 6.28037 14.1571C5.65423 14.1571 5.14665 14.6647 5.14665 15.2908C5.14665 15.9169 5.65423 16.4245 6.28037 16.4245C9.44313 16.4245 12.0721 14.1391 12.6058 11.1296C12.6385 11.1324 12.6715 11.1338 12.7049 11.1338H14.8805L14.1705 11.8438C13.7278 12.2865 13.7278 13.0044 14.1705 13.4471C14.6133 13.8898 15.3311 13.8898 15.7738 13.4471L18.4192 10.8018C18.8619 10.359 18.8619 9.64118 18.4192 9.19843L15.7738 6.55308C15.3311 6.11034 14.6133 6.11034 14.1705 6.55308C13.7278 6.99583 13.7278 7.71366 14.1705 8.15641L14.8805 8.86639H12.7049C12.6715 8.86639 12.6385 8.86784 12.6058 8.87066C12.0721 5.86115 9.44313 3.57568 6.28037 3.57568ZM3.17969 10.0001C3.17969 9.37397 3.68727 8.86638 4.31341 8.86638H7.33666C7.9628 8.86638 8.47039 9.37397 8.47039 10.0001C8.47039 10.6262 7.9628 11.1338 7.33666 11.1338H4.31341C3.68727 11.1338 3.17969 10.6262 3.17969 10.0001Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.08591 6.55276C4.64316 6.11002 3.92533 6.11002 3.48259 6.55276L0.837237 9.19811C0.394492 9.64086 0.394492 10.3587 0.837237 10.8014L3.48259 13.4468C3.92533 13.8895 4.64316 13.8895 5.08591 13.4468C5.52866 13.004 5.52866 12.2862 5.08591 11.8435L3.24222 9.99977L5.08591 8.15609C5.52866 7.71334 5.52866 6.99551 5.08591 6.55276Z"
        fill={color}
      />
    </svg>
  );
}

export default OneByOneNatIcon;
