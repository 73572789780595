import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextUtils } from "../../../contexts/common/utils";
import {
  IGlobalFilterContext,
  withGlobalFilterContext,
} from "../../../contexts/GlobalFilterContext";
import {
  INotificationsContext,
  withNotificationsContextProps,
} from "../../../contexts/NotificationsContext";
import { Res } from "../../../helpers/api/Api";
import { configApi } from "../../../helpers/api/ConfigApi";

import {
  resToState,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { DropdownItem, RequestStatus } from "../../../helpers/types";
import { getDataFromConnections } from "./helpers/getDataFromConnections";
import {
  Connection,
  ConnectionApi,
  ConnectionPortDetails,
  Endpoint,
} from "./types";

type RequestStatuses = {
  listStatus?: RequestStatus;
  removeStatus?: RequestStatus;
};

type State = {
  endpoints: Array<DropdownItem>;
  list: Array<Connection>;
  listApi: Array<ConnectionApi>;
  portsDetails: {
    [key: string]: {
      ports: Array<ConnectionPortDetails>;
      vpc?: ConnectionPortDetails;
    };
  };
  tooltip?: { id: string; value: any };
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchData: (tenant: string) => Promise<void>;
  remove: (connection: Connection, tenant: string) => Promise<boolean>;
  resetRemoveStatus: () => void;
  setTooltip: (tooltip?: { id: string; value: any }) => void;
};

const [
  ConnectionsContext,
  useConnectionsContext,
  withConnectionContextProps,
] = createContextUtils<IState, IFunc>();
export { useConnectionsContext, withConnectionContextProps };
export type IConnectionContext = IState & IFunc;

let FetchTimeout: NodeJS.Timeout | undefined;
export const FAILED_TO_FETCH = "Failed to fetch";

class ConnectionsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IGlobalFilterContext & INotificationsContext
> {
  Context = ConnectionsContext;
  constructor(props: Readonly<any>) {
    super(props);
  }

  componentWillUnmount(): void {
    this.clearTimeout();
  }

  clearTimeout = () => {
    if (FetchTimeout) {
      clearTimeout(FetchTimeout);
    }
    FetchTimeout = undefined;
  };

  clearData = () => {
    this.setState({ list: [] });
  };

  fetchData = async (tenant: string): Promise<void> => {
    if (!tenant) return;
    // Fetch endpoints
    const endpointsRes = await this.fetchEndpoints(tenant);
    if (endpointsRes.ok && endpointsRes.result) {
      // Fetch connections list
      this.fetchConnections(tenant, endpointsRes.result);
    }
    if (endpointsRes.error?.includes(FAILED_TO_FETCH)) {
      FetchTimeout = setTimeout(() => {
        this.fetchData(tenant);
      }, 5000);
    }
    // Set status by endpoints
    this.setState({ listStatus: resToState(endpointsRes) });
  };

  fetchEndpoints = async (tenant: string): Promise<Res<any>> => {
    const res = await configApi.getEndpoints(tenant);
    if (res.ok && res.result) {
      const endpoints = res.result.map((el) => ({
        key: el.location_name,
        data: { ...el },
      }));
      this.setState({ endpoints });
    }
    return res;
  };

  fetchConnections = async (tenant: string, endpoints: Array<Endpoint>) => {
    const res = await getDataFromConnections(tenant, endpoints);
    this.setState({ listStatus: resToState(res) });
    if (res.result) {
      const { list, portsDetails, listApi } = res.result;
      this.setState({ list, portsDetails, listApi });
    } else {
      this.clearData();
    }
    if (res.error?.includes(FAILED_TO_FETCH)) {
      FetchTimeout = setTimeout(() => {
        this.fetchData(tenant);
      }, 5000);
    }
  };

  remove = async (connection: Connection, tenant: string): Promise<boolean> => {
    if (!tenant) return false;
    this.setState({ removeStatus: setPending() });
    // Show pending notification
    this.props.handleShowNotification(
      {
        state: "pending",
        title: "In Progress",
        details: "This might take up to a few minutes",
      },
      true
    );
    const name = connection.name;
    const connections = this.state.listApi.filter(
      (item) => item.name === name || item.labels?.parentConnection === name
    );

    const finalRes: Array<any> = [];
    await Promise.all(
      connections.map(
        async (connection): Promise<any> => {
          const response = await configApi.deleteConnection(
            tenant,
            connection.name
          );
          finalRes.push(response);
        }
      )
    );

    // for (const connection of connections) {
    //   try {
    //     const response = await configApi.deleteConnection(
    //       tenant,
    //       connection.name
    //     );
    //     finalRes.push(response);
    //   } catch (error) {
    //     const errorRes = setError(error as string);
    //     errorMsg = errorRes.message;
    //     this.setState({ removeStatus: errorRes });
    //     return false;
    //   }
    // }
    const errorRes = finalRes.find((r) => !r.ok);

    this.setState({ removeStatus: resToState(errorRes || finalRes[0]) });
    this.fetchData(tenant);
    // Show notification
    const resState = errorRes?.ok || finalRes[0]?.ok;
    this.props.handleShowNotification({
      state: errorRes ? "error" : "ok",
      title: resState
        ? "Connection(s) were deleted successfuly"
        : "Failed to delete connection(s)",
      details: resState ? "" : errorRes?.message || "",
    });
    return finalRes[0]?.ok;
  };

  resetRemoveStatus = () => {
    this.setState({ removeStatus: undefined });
  };

  setTooltip = (tooltip?: { id: string; value: any }) => {
    this.setState({ tooltip });
  };

  funcs = {
    fetchData: this.fetchData,
    remove: this.remove,
    resetRemoveStatus: this.resetRemoveStatus,
    setTooltip: this.setTooltip,
  };
}

export default withNotificationsContextProps<any>(
  withGlobalFilterContext<any>(ConnectionsContextContainer)
);
