import React, { FC } from "react";
import { classNames } from "../../helpers/common/classNames";
import { isOk } from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import CopyTextBtn from "./buttons/CopyTextBtn";
import styles from "./CodeEditor.module.scss";
import Textarea from "./formComponents/Textarea";
import DataPlaceHolders from "./loadStates/DataPlaceHolder";

type Props = {
  value: string;
  isCopyDisabled?: boolean;
  isEdit?: boolean;
  onChange?: any;
  status?: RequestStatus;
};

const CodeEditor: FC<Props> = ({
  value,
  isCopyDisabled,
  isEdit,
  onChange = () => undefined,
  status,
}) => {
  return (
    <div className={classNames(styles.wrapper)}>
      {status && !isOk(status) && !value ? (
        <DataPlaceHolders
          isEmpty={!status}
          status={status}
          emptyTitle="There is no data here yet"
          errorTitle="Get Data Error"
          errorSubTitle="Please refresh page or report this to InsidePacket"
        />
      ) : (
        <>
          <Textarea
            name="conf"
            value={value}
            error={""}
            onChange={onChange}
            disabled={!isEdit}
          />
          <CopyTextBtn
            className={styles.copyIcon}
            value={value}
            disabled={isCopyDisabled}
          />
        </>
      )}
    </div>
  );
};

export default CodeEditor;
