import React, { ChangeEvent, ReactElement, useState } from "react";
import { classNames } from "../../../helpers/common/classNames";
import CheckboxSomeSelectIcon from "../../icons/CheckboxSomeSelectIcon";
import CheckboxIcon from "../../icons/CheckboxIcon";
import styles from "./Checkbox.module.scss";
import ReactTooltip from "react-tooltip";

type CheckboxProps = {
  isChecked: boolean;
  onChange?: (e: ChangeEvent<Element>) => void;
  value?: string;
  label?: string | React.ReactElement;
  indeterminate?: boolean;
  disabled?: boolean;
  resolvedRef?: React.ForwardedRef<HTMLInputElement>;
  className?: string;
  containerClassName?: string;
  indeterminateIcon?: React.ReactElement;
  tooltip?: string;
  id?: string;
};

export function Checkbox({
  isChecked,
  onChange,
  value,
  label,
  indeterminate,
  disabled,
  resolvedRef,
  className,
  containerClassName,
  indeterminateIcon,
  tooltip,
  id,
}: CheckboxProps): ReactElement {
  const checkoxStyles = classNames(
    styles.checkbox,
    (indeterminate || isChecked) && styles.checkboxActive,
    disabled && styles.checkboxDisabled,
    !label && styles.singleCheckbox,
    className
  );

  const renderToolTip = () => {
    if (!tooltip) return null;
    return (
      <ReactTooltip
        id={id}
        aria-haspopup="true"
        place="bottom"
        type="light"
        className={styles.tooltip}
      >
        <div
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: tooltip }}
        />
      </ReactTooltip>
    );
  };

  const renderIcon: () => ReactElement | undefined = () => {
    if (indeterminate) {
      return (
        indeterminateIcon || (
          <CheckboxSomeSelectIcon color="var(--button-font)" />
        )
      );
    }
    if (isChecked) {
      return <CheckboxIcon color="var(--button-font)" />;
    }
  };
  return (
    <label
      className={classNames(containerClassName, styles.container)}
      data-tip
      data-for={id}
    >
      <input
        ref={resolvedRef}
        type="checkbox"
        onChange={onChange}
        disabled={disabled}
        checked={isChecked}
        value={value}
      />
      <span className={checkoxStyles} aria-hidden="true">
        {renderIcon()}
      </span>
      {renderToolTip()}
      {label}
    </label>
  );
}
