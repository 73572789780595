import { RequestedGateVIAdd } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { REQUESTED_GATE_VI_BODY } from "./consts";

export const getRequestedGateVIBody = (fields: any): RequestedGateVIAdd => {
  const body: RequestedGateVIAdd = REQUESTED_GATE_VI_BODY;
  body.name = fields.name;
  body.system_name = fields.location;
  body.type = fields.defaultRoute;
  if (body.type === "global") delete body.data;
  return body;
};
