import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function TutorialsIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19818_247989)">
        <g clipPath="url(#clip1_19818_247989)">
          <path
            d="M23.7111 8.45662L13.0905 3.56784C12.7461 3.40916 12.3791 3.3291 12 3.3291C11.6209 3.3291 11.254 3.40916 10.9095 3.56784L0.288891 8.45662C0.113344 8.53776 0 8.71462 0 8.90788C0 9.10115 0.113344 9.27801 0.288891 9.35915L10.9095 14.2479C11.2539 14.4066 11.6209 14.487 12 14.487C12.3791 14.487 12.7461 14.4066 13.0906 14.2479L21.4725 10.3898V14.2749C21.1398 14.3124 20.8804 14.5955 20.8804 14.9376C20.8804 15.2357 21.0777 15.4937 21.3588 15.5759L20.792 19.3161H23.4018L22.8351 15.5759C23.1162 15.4937 23.3134 15.2357 23.3134 14.9376C23.3134 14.5955 23.054 14.3124 22.7214 14.2749V9.81482L23.7112 9.35915C23.8867 9.27801 24.0001 9.10115 24.0001 8.90788C24.0001 8.71462 23.8867 8.53776 23.7111 8.45662Z"
            fill={color || FONT}
          />
          <path
            d="M11.9999 16.2576C11.4728 16.2576 10.962 16.1455 10.4818 15.9246L5.28711 13.5332V18.2975C5.28711 19.3435 8.4477 20.6703 11.9999 20.6703C15.5522 20.6703 18.7129 19.3434 18.7129 18.2975V13.5332L13.5178 15.9246C13.038 16.1455 12.5273 16.2576 11.9999 16.2576Z"
            fill={color || FONT}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_19818_247989">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_19818_247989">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TutorialsIcon;
