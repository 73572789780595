import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_FONT } from "../../helpers/common/colorAliases";

function CheckboxSomeSelectIcon({ color }: IconProps): ReactElement {
  return (
    <svg width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill={color || BUTTON_FONT} d="M5 8h8v2H5z" />
    </svg>
  );
}

export default CheckboxSomeSelectIcon;
