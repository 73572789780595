import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function StatusIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="6"
      height="6"
      fill="none"
      viewBox="0 0 6 6"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="3" cy="3" r="3" fill={color || FONT} />
    </svg>
  );
}

export default StatusIcon;
