import React, { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  SERVICES,
  USAGE_REPORT,
  ZAYO_REPORT_USAGE_BILLING,
} from "../../../helpers/navigation/entries";
import ServicesPage from "./components/ServicesPage";
import UsageReportPage from "./components/UsageReportPage";
import { ReportUsageBillingTab } from "./ReportUsageBillingTab/ReportUsageBillingTab";

const ReportUsageBilling: FC = () => {
  return (
    <Switch>
      <Route path={"/" + ZAYO_REPORT_USAGE_BILLING().path} exact>
        <Redirect
          to={
            "/" + ZAYO_REPORT_USAGE_BILLING().path + "/" + USAGE_REPORT().path
          }
        />
      </Route>
      <Route
        path={
          "/" + ZAYO_REPORT_USAGE_BILLING().path + "/" + USAGE_REPORT().path
        }
      >
        <UsageReportPage tabs={ReportUsageBillingTab} />
      </Route>
      <Route
        path={"/" + ZAYO_REPORT_USAGE_BILLING().path + "/" + SERVICES().path}
      >
        <ServicesPage tabs={ReportUsageBillingTab} />
      </Route>
      <Redirect
        to={"/" + ZAYO_REPORT_USAGE_BILLING().path + "/" + USAGE_REPORT().path}
      />
    </Switch>
  );
};

export default ReportUsageBilling;
