import { createContextAndUse } from "../../../../contexts/common/AbstractCrudContext";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { RequestStatus } from "../../../../helpers/types";
import { configApi } from "../../../../helpers/api/ConfigApi";
import { VTEPType } from "../../../../pages/VTEPPage/types";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = { list?: Array<VTEPType> };

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (system: { name: string }) => void;
};

const [VTEPListContext, useContext] = createContextAndUse<IState, IFunc>();
export const useVTEPListContext = useContext;

export default class VTEPListContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = VTEPListContext;

  fetchList = async (system: { name: string }): Promise<void> => {
    const { ok, result } = await this._fetchWithStatus(
      () => configApi.getVTEPList(system.name),
      "listStatus"
    );
    this.setState({ list: ok ? result : undefined });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
