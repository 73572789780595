export const SDWAN_PROVIDERS_TYPES_MAP = [
  "aruba",
  "fortinet",
  "cisco",
  "velocloud",
];

const getGroupsFromTypes = (): { [key: string]: Array<any> } => {
  const groups: { [key: string]: Array<any> } = {};
  SDWAN_PROVIDERS_TYPES_MAP.forEach((element) => {
    groups[element] = [];
  });
  return groups;
};

export const SDWAN_TYPES_GROUPS = getGroupsFromTypes();
