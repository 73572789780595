import { FC } from "react";
import styles from "./EventStatus.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import { getIconByStatus } from "./EventLabel/helpers/getIconByStatus";

type Props = {
  status: string;
  className?: string;
};

const EventStatus: FC<Props> = ({ status, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {getIconByStatus(status)}
      {status}
    </div>
  );
};
export default EventStatus;
