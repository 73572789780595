/* eslint-disable css-modules/no-unused-class */
import React, { ChangeEvent, FC, useState } from "react";
import LabelArray from "../table/LabelArray";

import Input, { InputProps } from "./Input";

type Props = {
  valuesList: Array<string>;
  onAdd: (val: Array<string>) => void;
  onRemove: (val: string) => void;
  wrapperClassName?: string;
  labelClassName?: string;
  singleLabelClassname?: string;
  validate?: (val: string) => boolean;
  withValidate?: boolean;
  setError?: () => void;
  onClear?: () => void;
} & InputProps;

// TODO Find a rename as a proper type of input
const InputWithList: FC<Props> = ({
  valuesList,
  onAdd,
  onRemove,
  wrapperClassName,
  labelClassName,
  singleLabelClassname,
  errorWithTooltip,
  validate,
  withValidate,
  setError,
  onClear,
  ...restProps
}) => {
  const [currentValue, setCurrentValue] = useState<string>("");

  const handleValidate = (val: string): boolean => {
    let validated = true;
    if (withValidate && validate) {
      validated = validate(val.toLowerCase());
    }
    return validated;
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement> | undefined,
    fromBlur = false
  ) => {
    if (fromBlur || e?.code === "Enter" || e?.code === "Space") {
      const value = fromBlur
        ? currentValue
        : ((e as unknown) as ChangeEvent<HTMLInputElement>).target.value;
      if (!value) {
        setCurrentValue("");
        onClear?.();
        return;
      }
      const validated = handleValidate(value);
      if (validated) {
        onAdd([value.toLowerCase()]);
        setCurrentValue("");
        onClear?.();
      } else {
        setError?.();
      }
    }
  };

  const handleCheckBlur = () => {
    if (validate) {
      handleKeyDown(undefined, true);
    } else {
      if (currentValue) {
        const vals = currentValue.split(" ").filter((el) => el.length > 0);
        onAdd(vals);
        setCurrentValue("");
      }
    }
  };

  return (
    <div className={wrapperClassName}>
      <Input
        {...restProps}
        value={currentValue}
        onChange={(e) => setCurrentValue(e.target.value.toLowerCase())}
        onKeyDown={handleKeyDown}
        onBlur={handleCheckBlur}
        errorWithTooltip={errorWithTooltip}
      />
      {valuesList && valuesList.length > 0 && (
        <LabelArray
          values={valuesList}
          isRemovable
          onClick={onRemove}
          withWrap
          className={labelClassName}
          singleLabelClassname={singleLabelClassname}
        />
      )}
    </div>
  );
};

export default InputWithList;
