import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import styles from "./PBMInfo.module.scss";
import PBMIcon from "../../../components/icons/tabs/PBMIcon";
import { PBMType } from "../../../helpers/api/apiTypes";
import { usePBMContext } from "../../../contexts/servicesContext/PBMContext";

type Props = {
  tenant?: string;
};

const PBMInfo: FC<Props> = ({ tenant }) => {
  const { selectedPBM } = usePBMContext();

  return (
    <SummaryInfoBlock
      icon={<PBMIcon />}
      title="PBM"
      className={styles.bodyContainer}
    >
      {selectedPBM && <RuleInfoSection rule={selectedPBM} tenant={tenant} />}
    </SummaryInfoBlock>
  );
};
export default PBMInfo;

type InfoProps = {
  rule: PBMType;
  tenant?: string;
};

const RuleInfoSection = ({ tenant }: InfoProps) => {
  return (
    <>
      <UnifiedTextPair subtitle="Tenant" text={tenant} />
    </>
  );
};
