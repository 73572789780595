import React, { FC } from "react";
import ConnectionItem from "./components/ConnectionItem";
import ConnectionsConnector from "./components/ConnectionsConnector";
import BWLabel from "./components/Labels/BWLabel";
import { ConnectionPortDetails } from "../../types";
import ZayoLogo from "../../../../../components/icons/zayo/ZayoLogo";
import { calculateCoordinates } from "../helpers/calculateCoordinates";
import LogoIcon from "../../../../../components/icons/LogoIcon";
import { useUserContext } from "../../../../../contexts/UserContext";

type Props = {
  connections: Array<ConnectionPortDetails>;
};

const AZDiagram: FC<Props> = ({ connections }) => {
  const { isDemo } = useUserContext();
  const elAmount = connections.length;

  const width = 600;
  const height = 240;

  const radius = 180;
  const rVlan = 200;

  const [startCoordinates, endCoordinates] = connections.map((el, index) =>
    calculateCoordinates({
      index,
      elAmount,
      radiuses: [radius, rVlan],
      width: width,
      height: height,
    })
  );

  const x1 = startCoordinates[radius].x;
  const x2 = endCoordinates[radius].x;
  const y1 = startCoordinates[radius].y;
  const y2 = endCoordinates[radius].y;

  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      {isDemo ? (
        <LogoIcon x={centerX} y={centerY - 24} />
      ) : (
        <ZayoLogo x={centerX} y={centerY - 24} />
      )}
      <ConnectionsConnector x1={x1} y1={y1} x2={x2} y2={y2} />
      <BWLabel
        x={centerX}
        y={centerY}
        value={connections[0].config?.BW}
        title="BW"
      />
      <ConnectionItem x={x1} y={y2} connection={connections[0]} />
      <ConnectionItem x={x2} y={y2} connection={connections[1]} isShift />
      <ConnectionsConnector
        x1={centerX}
        y1={centerY}
        x2={width / 2}
        y2={height / 2}
      />
    </svg>
  );
};

export default AZDiagram;
