import DNATIcon from "../components/icons/nat/DNATIcon";
import OneByOneNatIcon from "../components/icons/nat/OneByOneNatIcon";
import SourceNATIcon from "../components/icons/nat/SourceNATIcon";
import { GREY_FONT } from "./common/colorAliases";
import { NAT_TYPE_MAP } from "./common/constantsAlias";

export const getNatIcon = (type: string) => {
  switch (type) {
    case "DNAT":
      return <DNATIcon color={GREY_FONT} />;
    case "SNAT":
      return <SourceNATIcon color={GREY_FONT} />;
    default:
      return <OneByOneNatIcon color={GREY_FONT} />;
  }
};

export const getNatIconByFullName = (type: string) => {
  switch (type) {
    case NAT_TYPE_MAP.DNAT:
      return <DNATIcon color={GREY_FONT} />;
    case NAT_TYPE_MAP.SOURCE_NAT:
      return <SourceNATIcon color={GREY_FONT} />;
    default:
      return <OneByOneNatIcon color={GREY_FONT} />;
  }
};

export const getNatTypeName = (val: string) => {
  switch (val) {
    case "DNAT":
      return "Port forwarding";
    case "SOURCE_NAT":
      return "Source NAT";
    case "NOP":
      return "NOP";
    case "SOURCE_ONE_TO_ONE":
    default:
      return "1:1 NAT";
  }
};

export const renderNatTypeWithIcon = (type: string) => {
  return { key: type, icon: getNatIconByFullName(type) };
};
