import { FC } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";
import { PageProps } from "../../helpers/types";
import { withContexts } from "../../helpers/hocs/withContexts";
import { SearchContextContainer } from "./SearchContext";
import styles from "./SearchPage.module.scss";
import FWRulesTable from "./components/FwRulesTable";
import NatRulesTable from "./components/NatRulesTable";
import SourceIpsTable from "./components/SourceIpsTable";
import DestinationIpsTable from "./components/DestinationIpsTable";
import SourceSegmentsTable from "./components/SourceSegmentsTable";
import DestinationSegmentsTable from "./components/DestinationSegmentsTable";
import CyberThreatsTable from "./components/CyberThreatsTable";
import DNSServersTable from "./components/DNSServersTable";
import DNSDomainsTable from "./components/DNSDomainsTable";
import HTTPSHostnamesTable from "./components/HTTPSHostnamesTable";
import SearchControls from "./components/SearchControls/SearchControls";
import UniqueMetricsCard from "./components/UniqueMetricCard/UniqueMetricsCard";
import TablesGroup from "./common/TablesGroup/TablesGroup";

const SearchPage: FC<PageProps> = () => {
  return (
    <div className={styles.wrapper}>
      <SearchControls />
      <UniqueMetricsCard />
      <FWRulesTable />
      <NatRulesTable />
      <TablesGroup>
        <SourceIpsTable />
        <DestinationIpsTable />
        <SourceSegmentsTable />
        <DestinationSegmentsTable />
      </TablesGroup>
      <TablesGroup>
        <CyberThreatsTable />
        <DNSServersTable />
        <DNSDomainsTable />
        <HTTPSHostnamesTable />
      </TablesGroup>
    </div>
  );
};
export default withContexts<PageProps>(withLayout<PageProps>(SearchPage), [
  SearchContextContainer,
]);
