import { IS_DEMO } from "../../../globalFlags";
import {
  GRAFANA_ROOT_PATH,
  GTimeRange,
  createUnifiedTenantFilter,
  parseGTimeRange,
} from "./GraphanaLinksFactory";

type ParamsType = {
  type: "tenant_events" | "system_events";
  timeRange: GTimeRange;
  filterValue?: string;
  selectedTenant?: string;
  filterType?: "vrf" | "system_id" | "tenant_name";
};

const grafanaTablesLinks = (params: ParamsType): string => {
  const { type, filterValue, selectedTenant, filterType, timeRange } = params;
  const { from, to } = getMsFromDate(timeRange);

  const panel: { [key: string]: number } = {
    tenant_events: 5,
    system_events: 6,
  };
  let filter =
    filterValue && !IS_DEMO
      ? `&var-Filters=${filterType}%7C%3D%7C${filterValue}`
      : "";

  const tenantFilter = createUnifiedTenantFilter(selectedTenant);

  if (filter && tenantFilter) {
    console.error(
      `GrafanaTableLink filter duplication filter${filter} was overwrited to ${tenantFilter}`
    );
  }
  if (tenantFilter) {
    filter = tenantFilter;
  }

  return `${GRAFANA_ROOT_PATH}/d-solo/UF-o3uGVz/system-events?orgId=1${filter}&theme=light&from=${from}&to=${to}&panelId=${panel[type]}`;
};

export default grafanaTablesLinks;

export const getMsFromDate = (range: GTimeRange) => {
  const { from, to } = parseGTimeRange(range);
  return { from: new Date(from).getTime(), to: new Date(to).getTime() };
};
