import { FC } from "react";
import styles from "./PrefixesLabel.module.scss";
import Label from "../../../../../components/common/table/Label";

type Props = {
  pfxSnt?: number;
  pfxRcd?: number;
};
const PrefixesLabel: FC<Props> = ({ pfxSnt, pfxRcd }) => {
  return (
    <Label isOutlined className={styles.wrapper}>
      <span className={styles.title}>Received/Sent: </span>
      <span>
        {pfxRcd || "-"}/{pfxSnt || "-"}
      </span>
    </Label>
  );
};
export default PrefixesLabel;
