import { CellProps, Renderer } from "react-table";
import { IconProps } from "../../../../../helpers/types";
import TextWithIcon from "../../TextWithIcon";
import {
  FONT,
  GREY_DISABLED,
} from "../../../../../helpers/common/colorAliases";
import LabelArray from "../../LabelArray";
import Label from "../../Label";
import {
  getBooleanIcon,
  getOpStateIcon,
} from "../../../../../helpers/getIconFromValue";
import { capitalizeFirstLetter } from "../../../../../helpers/common/stringHelpers";
import LabelOutline from "../../LabelOutline";
import { Checkbox } from "../../../formComponents/Checkbox";
import StatusIcon from "../../../../icons/StatusIcon";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";
import { Toggle } from "../../../Toggle";
import CollapsibleButton from "../../../buttons/CollapsibleButton";

type Cell<P extends object> = Renderer<CellProps<P>>;

export function withCellValue<P extends object>(
  render: (value: any) => Cell<P>
): Cell<P> {
  return ({ cell: { value } }: CellProps<P>) => {
    return render(value);
  };
}

export type InitOptions<P> = {
  icon?: React.FC<IconProps>;
  idFormer?: (name: string) => string;
  statusSelector?: (original: P) => string | undefined;
};

type Options<P> = {
  icon?: React.FC<IconProps>;
  idFormer: (name: string) => string;
  statusSelector: (original: P) => string | undefined;
};
export class CellFactory<P extends object> {
  private options: Options<P>;
  constructor(options: InitOptions<P>) {
    this.options = {
      idFormer: (f) => f,
      statusSelector: (orig: P) => ({ status: undefined, ...orig }?.status),
      ...options,
    };
  }

  idCell(): Cell<P> {
    return ({ cell }: CellProps<P>) =>
      this.options.icon ? (
        <TextWithIcon
          icon={
            <this.options.icon
              color={FONT}
              status={this.options.statusSelector(cell.row.original)}
            />
          }
          text={this.options.idFormer(cell.value)}
        />
      ) : (
        this.options.idFormer(cell.value)
      );
  }

  emptyCell(): Cell<P> {
    return () => "";
  }

  labelArrayCell(
    title: string,
    disableTooltip = false,
    className?: string,
    singleLabelClassname?: string,
    withWrap?: boolean
  ): Cell<P> {
    return withCellValue<P>((value) =>
      value ? (
        <LabelArray
          values={value}
          title={title}
          disableTooltip={disableTooltip}
          className={className}
          singleLabelClassname={singleLabelClassname}
          withWrap={withWrap}
        />
      ) : (
        this.emptyCell()
      )
    );
  }

  textCell(text?: string): Cell<P> {
    return withCellValue<P>((value) => <span>{text || value || ""}</span>);
  }

  labelCell(): Cell<P> {
    return withCellValue<P>((value) =>
      value ? <Label>{value}</Label> : this.emptyCell()
    );
  }

  outlineLabelCell(): Cell<P> {
    return withCellValue<P>((value) =>
      value ? (
        <LabelOutline type="font">{value}</LabelOutline>
      ) : (
        this.emptyCell()
      )
    );
  }

  opStateCell(): Cell<P> {
    return withCellValue<P>((value) => {
      if (!value) return "";
      return (
        <TextWithIcon
          icon={getOpStateIcon(value)}
          text={capitalizeFirstLetter(value)}
        />
      );
    });
  }

  capitalTextCell(): Cell<P> {
    return withCellValue<P>((value) =>
      value ? capitalizeFirstLetter(value.toLowerCase()) : this.emptyCell()
    );
  }

  checkboxCell(): Cell<P> {
    return withCellValue<P>((value) => (
      <Checkbox isChecked={value} onChange={() => undefined} disabled />
    ));
  }

  booleanIconCell(): Cell<P> {
    return withCellValue<P>((value) => getBooleanIcon(value));
  }

  statusWithIconCell(): Cell<P> {
    return withCellValue<P>((value) => (
      <TextWithIcon
        text={value}
        icon={<StatusIcon color={getColorFromStatus(value?.toLowerCase())} />}
      />
    ));
  }

  toggleCell(): Cell<P> {
    return withCellValue<P>((value) => (
      <Toggle isChecked={Boolean(value)} onChange={() => undefined} />
    ));
  }

  expandIcon(): Cell<P> {
    return ({ row }: CellProps<P>) => {
      return row.canExpand && row.subRows.length ? (
        <CollapsibleButton
          id={row.id}
          toCollapse={row.isExpanded}
          onClick={row.toggleRowExpanded}
          color={GREY_DISABLED}
          isSmall
        />
      ) : (
        ""
      );
    };
  }
}
