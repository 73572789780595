import React from "react";
import styles from "./LayoutComponent.module.scss";
import Header from "../Header/Header";
import NavigationBar from "../NavigationBar";
import NavigationHeader from "./navigation/NavigationHeader";
import { PathEntry } from "../../helpers/types";
import { classNames } from "../../helpers/common/classNames";
import InfoMessages from "./InfoMessages";

type Props = {
  alternatives?: Array<PathEntry>;
  withoutPaddings?: boolean;
  withoutNavigation?: boolean;
};
const LayoutComponent: React.FC<Props> = (props) => {
  const { children, alternatives, withoutPaddings, withoutNavigation } = props;

  return (
    <div className={styles.container}>
      <Header />
      {!withoutNavigation && <NavigationHeader alternatives={alternatives} />}
      <InfoMessages />
      <div
        className={classNames(
          styles.content,
          withoutPaddings && styles.withoutPaddings,
          withoutNavigation && styles.withoutNavigation
        )}
      >
        {children}
      </div>
      <NavigationBar />
    </div>
  );
};

export default LayoutComponent;
