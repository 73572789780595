import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function GlobeWithNATIcon(props: IconProps): ReactElement {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01917 5.03113C8.65792 3.75363 9.40159 3.24902 10 3.24902C10.5984 3.24902 11.3421 3.75363 11.9808 5.03113C12.5181 6.1057 12.8932 7.57752 12.9805 9.24902H7.01948C7.10683 7.57752 7.48189 6.1057 8.01917 5.03113ZM5.51765 9.24902C5.60611 7.37797 6.02313 5.66911 6.67753 4.36031C6.7344 4.24657 6.79386 4.13447 6.85587 4.02447C4.92132 5.04463 3.54237 6.97688 3.2912 9.24902H5.51765ZM3.2912 10.749H5.51765C5.60611 12.6201 6.02313 14.3289 6.67753 15.6377C6.7344 15.7515 6.79386 15.8636 6.85587 15.9736C4.92132 14.9534 3.54237 13.0212 3.2912 10.749ZM12.9805 10.749L12.9798 10.7618C13.0962 10.7537 13.2136 10.7496 13.332 10.7496H14.1654C14.2717 10.7496 14.3772 10.7529 14.4819 10.7595L14.4824 10.749H16.7088C16.685 10.9644 16.6511 11.1767 16.6075 11.3855C17.0904 11.6564 17.5238 12.0051 17.8907 12.4146C18.1243 11.6506 18.25 10.8395 18.25 9.99902C18.25 5.44267 14.5563 1.74902 10 1.74902C5.44365 1.74902 1.75 5.44267 1.75 9.99902C1.75 13.6085 4.06793 16.6765 7.29645 17.7958C7.28811 17.6938 7.28385 17.5905 7.28385 17.4863C7.28385 16.5982 7.59262 15.7821 8.10868 15.1397C8.07863 15.0837 8.04879 15.0262 8.01917 14.9669C7.48189 13.8923 7.10683 12.4205 7.01948 10.749H12.9805ZM16.7088 9.24902C16.4576 6.97688 15.0787 5.04463 13.1441 4.02446C13.2061 4.13446 13.2656 4.24657 13.3225 4.36031C13.9769 5.66911 14.3939 7.37797 14.4824 9.24902H16.7088Z"
        fill={props.color || FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6499 12.6338C11.6499 12.0993 12.0658 11.666 12.579 11.666C15.1721 11.666 17.3274 13.6189 17.7634 16.1897C17.8028 16.1848 17.8429 16.1823 17.8837 16.1823H19.6261L19.0447 15.5767C18.6819 15.1987 18.6819 14.5859 19.0447 14.208C19.4075 13.83 19.9958 13.83 20.3586 14.208L22.5265 16.4662C22.8894 16.8442 22.8894 17.4569 22.5265 17.8349L20.3586 20.0931C19.9958 20.4711 19.4075 20.4711 19.0447 20.0931C18.6819 19.7152 18.6819 19.1024 19.0447 18.7244L19.6269 18.1179H17.8837C17.843 18.1179 17.8028 18.1154 17.7634 18.1105C17.3275 20.6816 15.1722 22.6345 12.579 22.6345C12.0658 22.6345 11.6499 22.2012 11.6499 21.6667C11.6499 21.1322 12.0658 20.6989 12.579 20.6989C14.4604 20.6989 15.9857 19.1101 15.9857 17.1503C15.9857 15.1904 14.4604 13.6016 12.579 13.6016C12.0658 13.6016 11.6499 13.1683 11.6499 12.6338ZM12.676 18.1179C12.3373 18.8101 11.6466 19.2842 10.8496 19.2842C9.71805 19.2842 8.80078 18.3287 8.80078 17.15C8.80078 15.9714 9.71805 15.0159 10.8496 15.0159C11.6466 15.0159 12.3374 15.49 12.6761 16.1823H14.0895C14.624 16.1823 15.0573 16.6156 15.0573 17.1501C15.0573 17.6846 14.624 18.1179 14.0895 18.1179H12.676Z"
        fill={props.color || FONT}
      />
    </svg>
  );
}

export default GlobeWithNATIcon;
