import React, { FC } from "react";
import Input from "../../../../../components/common/formComponents/Input";
import SegmentsDropdown from "../../../../../components/common/SegmentsDropdown/SegmentsDropdown";
import { DESTINATION_NAT, SOURCE_NAT } from "../../../const";

import styles from "./InsideBlock.module.scss";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  isEdit: boolean;
  errors?: { [key: string]: string };
  validate: () => {
    isOk: boolean;
  };
};

const InsideBlock: FC<Props> = ({
  fields,
  onChange,
  isEdit,
  errors,
  validate,
}) => {
  const handleChange = (
    field: string,
    value: string,
    type: string,
    typeValue: string
  ) => {
    if (!fields[type]) onChange(type, typeValue);
    onChange(field, value);
  };

  const isDNAT = fields?.rule_type === DESTINATION_NAT;
  const isSNAT = fields?.rule_type === SOURCE_NAT;
  const isSegmentType = fields.inside_type === "segment";

  return (
    <div className={styles.colGroup}>
      <Input
        name={"inside"}
        label="Inside IP"
        placeholder={"X.X.X.X"}
        onChange={(e) => {
          handleChange("inside", e.target.value, "inside_type", "ip");
        }}
        onBlur={validate}
        isFullValue
        medium
        error={errors && errors["inside"]}
        value={isSegmentType ? "" : fields.inside}
        isPlaceholderAlwaysVisible
        isNotEditable={isEdit}
        errorWithTooltip={true}
      />
      {isSNAT && (
        <SegmentsDropdown
          label=""
          placeholder="Segments"
          onChange={(val) =>
            handleChange("inside", val, "inside_type", "segment")
          }
          selected={isSegmentType ? fields.inside : ""}
          disabled={isEdit}
        />
      )}
      {isDNAT && (
        <Input
          type={"number"}
          label=""
          name="inside_port"
          placeholder="Port"
          isPlaceholderAlwaysVisible
          value={fields.inside_port}
          onChange={(e) => onChange("inside_port", +e.target.value)}
          medium
          error={""}
          isNotEditable={isEdit}
        />
      )}
    </div>
  );
};
export default InsideBlock;
