import { CellProps } from "react-table";
import LabelArray from "../../../../components/common/table/LabelArray";
import { CellFactory } from "../../../../components/common/table/newTable/cells/CellsFactory";
import { Segmentation } from "../../../Network/context/NetworkContext";
import { SegmentsRow, Subnets } from "../types";
import { ArraySubnetCell } from "./ArraySubnetCell";

const cellFactory = new CellFactory<SegmentsRow>({});

export const segmentsTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Subnets",
      accessor: "",
      Cell: ({ row: { original } }: CellProps<Segmentation>) => {
        const subnets = original.prefixes.map((el) => el.subnet);
        return (
          <ArraySubnetCell subnets={subnets} subnet_name={original.name} />
        );
      },
    },
    {
      Header: "Connections",
      accessor: "",
      Cell: ({ row: { original } }: CellProps<Segmentation>) => {
        const connections = original.prefixes.map((el) => el.interface_name);

        return <LabelArray values={connections} />;
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof any }));
};
