import { FC } from "react";

const SeacrhPlaceholderImg: FC = () => {
  return (
    <svg
      width="65"
      height="64"
      viewBox="0 0 65 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M0.999638 25.9025C0.999638 39.6558 12.1489 50.805 25.9022 50.805C39.6554 50.805 50.8047 39.6558 50.8047 25.9025C50.8047 12.1492 39.6554 1 25.9022 1C12.1489 1 0.999638 12.1492 0.999638 25.9025Z"
          stroke="#59536B"
        />
        <path
          d="M7.03648 25.9027C7.03648 36.3218 15.4829 44.7682 25.902 44.7682C36.3212 44.7682 44.7676 36.3218 44.7676 25.9027C44.7676 15.4835 36.3212 7.03711 25.902 7.03711C15.4829 7.03711 7.03648 15.4835 7.03648 25.9027Z"
          stroke="#59536B"
        />
        <path
          d="M20.1484 20.1504L31.933 31.9349M31.933 20.1504L20.1484 31.9349"
          stroke="#59536B"
        />
        <path
          d="M45.1446 41.75L63.6328 60.2382L61.3689 62.5021L42.8807 44.0139"
          stroke="#59536B"
        />
      </g>
    </svg>
  );
};

export default SeacrhPlaceholderImg;
