import { CellProps } from "react-table";
import Label from "../../../components/common/table/Label";
import LabelArray from "../../../components/common/table/LabelArray";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import VirtInterfaceIcon from "../../../components/icons/VirtInterfaceIcon";
import { VirtualInterface } from "../types";
import {
  checkIP,
  checkIPv4WithPrefix,
  checkIPv6,
  checkIPv6WithPrefix,
} from "../../../helpers/validators/Validator";
import styles from "./virtualInterfaceTableHeader.module.scss";

export const virtualInterfaceTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }: CellProps<VirtualInterface>) => (
        <TextWithIcon icon={<VirtInterfaceIcon />} text={value} />
      ),
    },
    {
      Header: "Type",
      accessor: "virtual_interface_type",
      Cell: ({ cell: { value } }: CellProps<VirtualInterface>) => value || "",
    },
    {
      Header: "IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        const ipv4 = value.filter(
          (val: string) => checkIPv4WithPrefix(val) || checkIP(val)
        );
        const ipv6 = value.filter(
          (val: string) => checkIPv6WithPrefix(val) || checkIPv6(val)
        );
        return (
          <div>
            <LabelArray values={ipv4} withWrap={true} />
            <LabelArray
              values={ipv6}
              singleLabelClassname={styles.ips}
              withWrap={true}
            />
          </div>
        );
      },
    },
    {
      Header: "L2 Members",
      accessor: "members",
      Cell: ({ cell: { value } }: CellProps<VirtualInterface>) => {
        if (value === undefined) {
          return <Label>—</Label>;
        } else if (typeof value[0] === "object" && value.length > 0) {
          for (const obj of value) {
            if (
              obj.interface_type === "port_vlan" &&
              obj.interfaces.length > 0
            ) {
              for (const oneInterface of obj.interfaces)
                return <Label>{oneInterface.name}</Label>;
            }
          }
        } else {
          return <Label>{value}</Label>;
        }
      },
    },
    {
      Header: "Description",
      accessor: "description",
      Cell: ({ cell: { value } }: CellProps<VirtualInterface>) => value || "",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInterface,
  }));
};
