import { SegmentRule } from "../components/SegmentRulesTable/types";

export const isAllowAllExist = (
  src: string,
  dst: string,
  rules?: Array<SegmentRule>
): boolean => {
  if (!rules) return false;
  return rules.some((el) => el.srcSegment === src && el.dstSegment === dst);
};

export const isCustomExist = (
  src: string,
  dst: string,
  rules?: Array<SegmentRule>
): boolean => {
  if (!rules) return false;
  return rules.some((el) => el.srcSegment === src && el.dstSegment === dst);
};
