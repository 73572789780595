import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CloudRouterTab } from "../../../components/common/navigation/Network/CloudRouterTab";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../../helpers/navigation";
import {
  ARPS,
  BGP,
  PBR,
  STATIC_ROUTES,
} from "../../../helpers/navigation/entries";
import ServiceBGP from "../../../pages/ServiceBGP";
import ServicesStaticRoutesPage from "../../../pages/StaticRoutes/ServicesStaticRoutesPage";
import ARPsPage from "../../../pages/ARPsPage";
import PBRPage from "../../../pages/PBRPage";

export default function CloudRouter(): ReactElement {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={path}>
        <Redirect to={addPrefix(STATIC_ROUTES(), path)} />
      </Route>
      <Route exact path={path + "/" + STATIC_ROUTES().path}>
        <ServicesStaticRoutesPage tabs={CloudRouterTab} />
      </Route>
      <Route path={path + "/" + ARPS().path}>
        <ARPsPage tabs={CloudRouterTab} />
      </Route>
      <Route path={path + "/" + PBR().path}>
        <PBRPage tabs={CloudRouterTab} />
      </Route>
      <Route path={path + "/" + BGP().path}>
        <ServiceBGP tabs={CloudRouterTab} />
      </Route>
    </Switch>
  );
}
