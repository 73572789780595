import { FC } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Section from "../../../../../../../components/common/Section";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";

type Props = {
  className: string;
};
const ContractSection: FC<Props> = ({ className }) => {
  const {
    fields,
    fieldsOnChange: onChange,
  } = useConnectionConfiguratorContext();

  return (
    <Section title={"Contract"} columnsCount={2} className={className}>
      <DropdownBasic
        id={"bandwidth"}
        onChange={(val) => {
          onChange("bandwidth", val.key);
        }}
        selected={mapStringToItem(fields.bandwidth)}
        label="Bandwidth"
        itemsList={[]}
        isMedium
        error={""}
      />
      <DropdownBasic
        id={"subscription"}
        onChange={(val) => {
          onChange("subscription", val.key);
        }}
        selected={mapStringToItem(fields.subscription)}
        label="Subscription Period"
        itemsList={[]}
        isMedium
        error={""}
      />
    </Section>
  );
};

export default ContractSection;
