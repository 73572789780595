import { CreateIpam } from "../../pages/IPAMPage/types";
import Validator from "./Validator";

export default function validateIPAM(
  data: CreateIpam,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");

  validator.checkEmpty(data.from_ip, "from_ip");
  validator.checkIP(data.from_ip, "from_ip");

  validator.checkEmpty(data.to_ip, "to_ip");
  validator.checkIP(data.to_ip, "to_ip");

  validator.checkEmpty(data.vrf_name, "vrf_name");

  if (data.type !== "global") {
    validator.checkEmpty(data.data, "data");
  }

  return validator;
}
