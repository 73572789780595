import React, { FC, ReactElement, useState } from "react";
import {
  GREY_FONT,
  PURPLE,
  PURPLE_DARK,
} from "../../../helpers/common/colorAliases";
import { classNames } from "../../../helpers/common/classNames";
import { IconProps } from "../../../helpers/types";
import BlankButton from "./BlankButton";
import styles from "./IconBlankButton.module.scss";

type IconBlankButtonType = {
  icon: (props: IconProps) => ReactElement;
  onClick?: (arg?: any) => void;
  color?: string;
  hoverColor?: string;
  label?: string | ReactElement;
  disabled?: boolean;
  isOutlined?: boolean;
  isFill?: boolean;
  isOnlyOpacity?: boolean;
  className?: string;
  btnClassName?: string;
  iconClassName?: string;
  title?: string;
  hint?: string;
  id: string;
};

const IconBlankButton: FC<IconBlankButtonType> = ({
  icon: Icon,
  label,
  color,
  hoverColor,
  disabled,
  onClick = () => undefined,
  isOutlined,
  isFill,
  isOnlyOpacity,
  className,
  btnClassName,
  iconClassName,
  title,
  id,
  hint,
  ...rest
}) => {
  const [isHover, setIsHover] = useState<boolean>(true);

  const iconColor = color || getIconColor(isHover, disabled);
  const btnStyles = classNames(
    styles.btn,
    isOutlined && styles.outlined,
    isFill && styles.fillBtn,
    (isOnlyOpacity || color) && styles.opacity,
    btnClassName && styles.fireWallBtn,
    className
  );
  const labelStyles = classNames(
    styles.label,
    disabled && styles.disabled,
    btnClassName && styles.fireWallBtnLabel
  );
  const iconStyles = classNames(btnClassName, iconClassName);

  return (
    <BlankButton
      onClick={onClick}
      className={btnStyles}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      disabled={disabled}
      title={title}
      data-tip
      data-for={id}
      id={id}
      hint={hint}
      {...rest}
    >
      {
        <Icon
          className={iconStyles}
          color={isHover ? hoverColor || PURPLE : iconColor}
        />
      }
      {label && <span className={labelStyles}>{label}</span>}
    </BlankButton>
  );
};

export default IconBlankButton;

const getIconColor = (isHover: boolean, disabled?: boolean): string => {
  if (disabled) return GREY_FONT;
  return isHover ? PURPLE_DARK : PURPLE;
};
