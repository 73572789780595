import { useTimerangeContext } from "../../contexts/TimerangeContext";

// to provide time range params to another contexts

export default function withTimerangeContext(
  WrappedComponent: React.ComponentType<any>
) {
  return function Component(props: any): React.ReactElement {
    const { timeRange, isManual, setTimeRange } = useTimerangeContext();
    return (
      <WrappedComponent
        timerangeContext={{
          timeRange,
          isManual,
          setTimeRange,
        }}
        {...props}
      />
    );
  };
}
