import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import {
  IVirtualInterfaceContext,
  withVirtualInterfaceContextProps,
} from "../VirtualInterfaceContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { configApi } from "../../../helpers/api/ConfigApi";
import { VirtualInterface, VirtualInterfaceCreate } from "../types";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<VirtualInterfaceCreate>;

type IState = State & RequestStatuses;
type IFunc = DialogFunc<VirtualInterfaceCreate> & {
  editIP: (
    params: any,
    vi: VirtualInterface,
    tenant: string
  ) => Promise<boolean>;
};

const [
  VirtualInterfaceDialogContext,
  useVirtualInterfaceDialogContext,
] = createContextUtils<IState, IFunc>();
export { useVirtualInterfaceDialogContext };

class VirtualInterfaceDialogContextContainer extends AbstractDialogContextContainer<
  VirtualInterfaceCreate,
  IState,
  RequestStatuses,
  IFunc,
  IVirtualInterfaceContext & IOneSystemContext
> {
  Context = VirtualInterfaceDialogContext;

  _updateSystems = (ok: boolean, vrf: string) => {
    if (ok) {
      this.props.fetchList(vrf);
    }
  };

  add = async (vi: any): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { vrf, ...restFields } = vi;
    const { ok } = await this._addWrap(() =>
      configApi.addVirtualInterface(system.name, vrf, restFields)
    );
    this._updateSystems(ok, vi.vrf);

    return ok;
  };

  editIP = async (
    params: any,
    vi: VirtualInterface,
    vrf: string
  ): Promise<boolean> => {
    const { system } = this.props;
    if (!system || !vrf) return false;
    const { ok } = await this._editWrap(() =>
      configApi.editVirtualInterface(system.name, vi.name, vrf, params)
    );
    this._updateSystems(ok, vrf);
    return ok;
  };

  // todo not implemented
  edit = async (vi: Partial<any>): Promise<boolean> => {
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());
    this._updateSystems(ok, vi.vrf);
    return ok;
  };

  funcs = {
    editIP: this.editIP,
    edit: this.edit,
    add: this.add,
  };
}

export default withOneSystemContextProps(
  withVirtualInterfaceContextProps(VirtualInterfaceDialogContextContainer)
);
