import { CellProps } from "react-table";
import { CellFactory } from "../../../components/common/table/newTable/cells/CellsFactory";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import PortIcon from "../../../components/icons/PortIcon";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";
import { PortInterface } from "../types";

const cellFactory = new CellFactory<PortInterface>({});

export const portInterfaceTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row, cell: { value } }: CellProps<PortInterface>) => (
        <TextWithIcon
          icon={
            <PortIcon
              indicatorColor={getColorFromStatus(
                row.original.administrative_state
              )}
            />
          }
          text={value}
        />
      ),
    },
    {
      Header: "Alias",
      accessor: "alias",
    },
    {
      Header: "Virt. Interface",
      accessor: "virtual_interface_name",
    },
    {
      Header: "Admin. State",
      accessor: "administrative_state",
      Cell: cellFactory.opStateCell(),
    },
    {
      Header: "Op. State",
      accessor: "operational_state",
      Cell: cellFactory.opStateCell(),
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof PortInterface }));
};
