import { DropdownItem } from "../../../../../helpers/types";
import { ConnectionApi, Endpoint } from "../../types";
import {
  CLOUD_CONNECTIONS,
  CLOUD_ROUTER,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  DIA,
  ELAN,
} from "../consts";

export const getPortTypeFromEndpoint = (
  port: ConnectionApi,
  endpoints: Array<DropdownItem<Endpoint>>
): ConnectionApi & {
  a_port_inner_type?: string;
  z_port_inner_type?: string;
} => {
  const a_port_inner_type =
    endpoints.find((el) => el?.data?.name === port?.a_endpoint_name)?.data
      ?.type || "";
  const z_port_inner_type =
    endpoints.find((el) => el?.data?.name === port?.z_endpoint_name)?.data
      ?.type || port?.z_endpoint_name;
  return {
    ...port,
    a_port_inner_type: getType(a_port_inner_type),
    z_port_inner_type: getType(z_port_inner_type),
  };
};

const getType = (value: string): string | undefined => {
  if (!value || !value.length) return undefined;
  switch (value) {
    case "NID":
      return CUSTOMER_LOCATIONS;
    case "DIA":
      return DIA;
    case "DC_PORT":
      return DATA_CENTER_PORT;
    case "ELAN":
      return ELAN;
    case "Cloud_Router":
      return CLOUD_ROUTER;
    default:
      return CLOUD_CONNECTIONS;
  }
};
