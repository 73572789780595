import React, { FC, useEffect } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { DialogProps, SummaryBlockProps } from "../../../helpers/types";
import {
  ServiceStaticRouteFields,
  StaticRoutesBodyType,
  StaticRoutesType,
} from "../../../helpers/api/apiTypes";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import Input from "../../../components/common/formComponents/Input";
import StaticRouteIcon from "../../../components/icons/StaticRouteIcon";
import { isEditMode } from "../../../helpers/isEditMode";
import DeleteButton from "../../../components/common/buttons/DeleteButton";
import { useStaticRoutesServicesContext } from "../StaticRoutesServicesContext";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";

import styles from "./ServicesStaticRouteDialog.module.scss";
import { GatewayInput } from "./GatewayInput";
import { useValidation } from "../../../helpers/validators/Validator";
import validateServiceStaticRoutes from "../../../helpers/validators/ServiceStaticRoutesValidator";
import { useUserContext } from "../../../contexts/UserContext";

const DEFAULT_ROUTE_FIELDS: ServiceStaticRouteFields = {
  destination: "",
  gateway_ip_address: "",
  gateway_interface: "",
  description: "",
  distance: 1,
  type: StaticRoutesType.STATIC,
};

const ServicesStaticRouteDialog: FC<DialogProps<StaticRoutesBodyType>> = ({
  tenant,
  onClose,
  dialogTitle,
}) => {
  const {
    addStaticRoutes,
    addStaticRouteStatus,
    resetAddStaticRouteStatus,
  } = useStaticRoutesServicesContext();
  const { user } = useUserContext();

  useEffect(() => {
    return () => {
      resetAddStaticRouteStatus();
    };
  }, []);

  const [fields, handleFieldChange] = useFormField<ServiceStaticRouteFields>(
    DEFAULT_ROUTE_FIELDS
  );

  const [errors, validate] = useValidation<ServiceStaticRouteFields>(
    validateServiceStaticRoutes,
    [fields]
  );

  const isInterface = fields.type === StaticRoutesType.INTERFACE ? true : false;

  const handleAddRoute = async () => {
    const { isOk } = validate();

    if (tenant && isOk) {
      fields.distance = +fields.distance;
      const { gateway_interface, gateway_ip_address, type, ...rest } = fields;

      const payload =
        type === StaticRoutesType.INTERFACE
          ? { ...rest, gateway_interface }
          : { ...rest, gateway_ip_address };

      const success = await addStaticRoutes(tenant, payload, user.role);
      if (success) onClose();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const controls = getDialogControls({
    dialogTitle,
    onAdd: handleAddRoute,
    onEdit: () => undefined,
    onClose: handleClose,
  });

  const leftColumn = (
    <SummaryBlock
      title="Static Route"
      onClick={() => undefined}
      dialogTitle={dialogTitle}
      tenant={tenant}
    />
  );

  return (
    <DialogTemplate
      title={isInterface ? "Add Interface" : "Add Static Route"}
      onClose={onClose}
      controls={controls}
      className={styles.formWrapper}
      leftColumn={leftColumn}
      errorDisplay={addStaticRouteStatus}
    >
      <DropdownBasic
        label="Type"
        isMedium
        itemsList={Object.values(StaticRoutesType).map(mapStringToItem)}
        onChange={(item) => handleFieldChange("type", item.key)}
        selected={{ key: fields.type || "" }}
        className={styles.type}
        id="gateway_type"
        error={errors && errors["type"]}
      />
      <Input
        name="destination"
        placeholder={"X.X.X.X/X or X::X/X"}
        value={fields.destination}
        label="Destination"
        handleFieldChange={handleFieldChange}
        onBlur={validate}
        medium
        className={styles.destination}
        error={errors && errors["destination"]}
      />
      <GatewayInput
        fields={fields}
        handleFieldChange={handleFieldChange}
        isInterface={isInterface}
        errors={errors || {}}
        validate={validate}
      />
      <Input
        name="distance"
        placeholder={""}
        value={fields.distance}
        label="Distance"
        handleFieldChange={handleFieldChange}
        medium
        className={styles.distance}
        error={errors && errors["distance"]}
      />
      <Input
        name="description"
        placeholder={fields.description}
        value={fields.description}
        label="Description"
        isOptional
        handleFieldChange={handleFieldChange}
        medium
        isFullValue
        className={styles.description}
        error={errors && errors["description"]}
      />
    </DialogTemplate>
  );
};

export default ServicesStaticRouteDialog;

type SumProps = SummaryBlockProps & {
  tenant?: string;
};

const SummaryBlock: FC<SumProps> = ({
  title,
  dialogTitle,
  onClick,
  tenant,
}) => {
  return (
    <SummaryInfoBlock
      icon={<StaticRouteIcon />}
      isSmallerIcon
      title={title}
      withFooter={isEditMode(dialogTitle)}
      footer={
        <DeleteButton
          id={"serviceStaticRouteDialog"}
          onClick={onClick}
          withLabel
        />
      }
    >
      <UnifiedTextPair subtitle="Tenant" text={tenant} />
    </SummaryInfoBlock>
  );
};
