import React, { FC } from "react";

type Props = { port: string | number; x: number; y: number; isShift: boolean };

const PortLabel: FC<Props> = ({ x, y, port, isShift }) => {
  const value = port.toString().length + 1;
  const width = 4 * 8 + value * 8;
  return (
    <svg
      width={width}
      height="18"
      viewBox={`0 0 ${width} 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={isShift ? x - width + 4 : x - 4}
      y={y - 18 / 2}
    >
      <rect
        x="0.501953"
        y="0.5"
        width={width - 2}
        height="16"
        rx="3.5"
        fill="white"
        stroke="#BEB8EA"
      />
      <text
        x={4}
        y={13}
        textAnchor="start"
        fill="var(--font)"
        fontSize={12}
        fontWeight={600}
      >
        <tspan fill={"var(--grey60)"}>Port </tspan>
        {port}
      </text>
    </svg>
  );
};
export default PortLabel;
