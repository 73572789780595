import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";

function RadioBtnIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="4" fill={color || BUTTON_PRIMARY} />
    </svg>
  );
}

export default RadioBtnIcon;
