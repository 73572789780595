import React from "react";
import { SortType, VRouter_MAC } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { systemApi } from "../../helpers/api/SystemApi";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { VirtualInterfaceApi } from "../../pages/VirtualInterfacePage/types";

import { createContextAndUse } from "../common/AbstractCrudContext";

type IState = {
  vrouterList?: {
    [key: string]: any;
    arps: Array<any>;
    macs: Array<VRouter_MAC>;
    routes: Array<any>;
  };
  vrouterStatus?: {
    [key: string]: any;
    arps?: RequestStatus;
    macs?: RequestStatus;
    routes?: RequestStatus;
  };
  virtInterfacesList: Array<VirtualInterfaceApi>;
};

type IFunc = {
  fetchVRouterList: (vrf: string, sortBy?: SortType) => Promise<void>;
  fetchVrtInterfacesList: (vrf: string, sortBy?: SortType) => Promise<void>;
};

const [Context, useContext] = createContextAndUse<IState, IFunc>();

export const useVRouterContext = useContext;

type Props = React.PropsWithChildren<{
  additionParameter: string;
}>;

class VRouterContextContainer extends AbstractTimeoutHandler<any, Props> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);

    this.funcs = {
      fetchVRouterList: this.fetchVRouterList,
      fetchVrtInterfacesList: this.fetchVrtInterfacesList,
    };
  }

  fetchVRouterList = async (vrf: string): Promise<void> => {
    this.setState({
      vrouterStatus: {
        arps: setPending("Fetching"),
        macs: setPending("Fetching"),
        routes: setPending("Fetching"),
      },
    });
    const system = await systemApi.getSystemById(this.props.additionParameter);
    if (system.ok && system.result) {
      const activeNode = system.result.active_node;
      const resArp = await configApi.getARPList(activeNode, vrf);
      // const resMac = await configApi.getMACList(activeNode);
      const resRoutes = await configApi.getRoutesList(activeNode, vrf);
      this.setState({
        vrouterStatus: {
          arps: resToState(resArp),
          // macs: resToState(resMac),
          routes: resToState(resRoutes),
        },
        vrouterList: {
          arps: resArp.result,
          // macs: resMac.result,
          routes: resRoutes.result,
        },
      });
    }
  };

  fetchVrtInterfacesList = async (vrf: string): Promise<void> => {
    const res = await configApi.getVirtualInterfacesApiList(
      this.props.additionParameter,
      vrf
    );
    if (res.ok && res.result) {
      this.setState({ viList: res.result });
    }
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.funcs,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default VRouterContextContainer;
