import React, { FC } from "react";
import { ChangeField } from "../../types";
import Input from "../../../../../../components/common/formComponents/Input";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import { CLOUD_ROUTER } from "../../consts";

type Props = {
  field: string;
  onChange: ChangeField;
  error?: string;
};

const IPAddress: FC<Props> = ({ field, onChange, error }) => {
  const { portType } = useConnectionConfiguratorContext();
  const isCloudRouter = portType.key === CLOUD_ROUTER;
  if (!isCloudRouter) return null;
  return (
    <Input
      label="Cloud Router IP Address"
      name="ip_addresses"
      placeholder="0.0.0.0/0"
      value={field}
      medium
      error={error}
      onChange={(e) => onChange("ip_addresses", e.target.value)}
    />
  );
};
export default IPAddress;
