import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { networkApi } from "../../../helpers/api/networkApi";

type RequestStatuses = {};
type State = { list?: Array<string> };

type IState = State & RequestStatuses;
type IFunc = { fetchList: (tenantName: string) => Promise<void> };

const [SegmentsDropdownContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useSegmentsDropdownContext = useContext;

export default class SegmentsDropdownContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = SegmentsDropdownContext;

  fetchList = async (tenantName: string): Promise<void> => {
    const { ok, result } = await networkApi.getSegmentations(tenantName);
    if (ok && result) {
      const data = result.items.map((seg) => seg.name) || [];
      this.setState({ list: data });
    }
  };

  funcs = { fetchList: this.fetchList };
}
