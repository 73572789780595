import React, { ReactElement } from "react";
import { BORDER_COLOR, CRITICAL } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function NoGraphData({
  isError,
}: IconProps & { isError?: boolean }): ReactElement {
  const mainColor = isError ? CRITICAL : BORDER_COLOR;
  return (
    <svg
      width="212"
      height="85"
      viewBox="0 0 212 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m2 65.6 19.6 9.5a4 4 0 0 0 3.7 0l16.6-9a4 4 0 0 1 2-.5h13.7c1 0 2-.4 2.8-1.1l14-13.7a4 4 0 0 1 3.9-1L88.9 53a4 4 0 0 0 3.4-.6l13.2-9a4 4 0 0 1 4.2-.3l12 6.5a4 4 0 0 0 5.2-1.1l10.8-15.2a4 4 0 0 1 3.8-1.6l22.4 3a4 4 0 0 0 3.7-1.6l9-12a4 4 0 0 1 1.9-1.3L210 9"
        stroke={mainColor}
        strokeOpacity=".5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="210"
        height="83"
        rx="3"
        stroke={BORDER_COLOR}
        strokeWidth="2"
      />
    </svg>
  );
}

export default NoGraphData;
