import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

export const elasticRemoteUsersStatus = (
  tenant: string,
  timeRange?: GTimeRange,
  timeRangeMS?: { from: number; to: number }
): { [key: string]: any } => {
  let gte, lte;
  if (timeRange) {
    gte = parseGTime(timeRange.from);
    lte = parseGTime(timeRange.to);
  }
  if (timeRangeMS) {
    gte = new Date(timeRangeMS.from).toISOString();
    lte = new Date(timeRangeMS.to).toISOString();
  }
  return {
    aggs: {
      2: {
        terms: {
          field: "Username.keyword",
          order: {
            _count: "desc",
          },
          size: 500,
        },
        aggs: {
          4: {
            terms: {
              field: "action.keyword",
              order: {
                _count: "desc",
              },
              size: 5,
            },
            aggs: {
              5: {
                terms: {
                  field: "Remote Tunnel IP.keyword",
                  order: {
                    _count: "desc",
                  },
                  size: 20,
                },
                aggs: {
                  6: {
                    terms: {
                      field: "Remote Tunnel Country.keyword",
                      order: {
                        _count: "desc",
                      },
                      size: 5,
                    },
                    aggs: {
                      3: {
                        date_histogram: {
                          field: "@timestamp",
                          fixed_interval: "10m",
                          time_zone: "Asia/Jerusalem",
                          min_doc_count: 1,
                        },
                        aggs: {
                          7: {
                            terms: {
                              field: "action.keyword",
                            },
                            aggs: {
                              9: {
                                terms: {
                                  field: "Remote Tunnel City.keyword",
                                  order: {
                                    _count: "desc",
                                  },
                                  size: 20,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              minimum_should_match: 1,
              should: [
                {
                  match_phrase: {
                    action: "Sending handshake response",
                  },
                },
                {
                  match_phrase: {
                    action: "Retrying handshake",
                  },
                },
              ],
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte,
                lte,
              },
            },
          },
          {
            match_phrase: {
              "tenant_name.keyword": tenant,
            },
          },
          {
            match_phrase: {
              "event_type.keyword": "WireGuard",
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};
