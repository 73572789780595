import { CloudCredentialsFields } from "../../pages/CloudsConnectivityPage/components/CloudCredentials";
import Validator from "./Validator";

export default function validateCloudCredentials(
  data: CloudCredentialsFields,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  Object.keys(data).map((key) => {
    const value = data[key as keyof CloudCredentialsFields];
    if (["access_key_id", "secret_access_key"].includes(key)) {
      validator.checkEmpty(value, key);
    }
    if (["tenant_id", "client_id", "subscription_id"].includes(key)) {
      validator.checkEmpty(value, key);
      value && validator.checkAzureTokenFormat(value, key);
    }
    if (key === "client_secret")
      validator.checkLength(value || "", [24, 88], key);
  });

  return validator;
}
