import { configApi } from "../../helpers/api/ConfigApi";
import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { VTEPType } from "./types";
import { PortType } from "../PortsPage/types";

type RequestsState = CrudRequestStatuses;

type ValueState = CrudValueState<VTEPType> & {
  portList?: Array<PortType>;
};

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<VTEPType> & {};

export type IVTEPContext = IState & IFunc;
const [Context, useVTEPContext, withVTEPContextProps] = createContextUtils<
  IState,
  IFunc
>();

export { useVTEPContext, withVTEPContextProps };

type Props = IOneSystemContext;

class VTEPContextContainer extends NewAbstractCrudContext<
  VTEPType,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;
    await this._fetchListWrap(() => configApi.getVTEPList(system.name));
  };

  remove = async (vtep: VTEPType): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._removeWrap(() =>
      configApi.deleteVTEP(system.name, vtep.name)
    );
    return ok;
  };

  // todo not implemented
  edit = async (vtep: VTEPType): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());

    return ok;
  };

  funcs = {
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
  };
}

export default withOneSystemContextProps(VTEPContextContainer);
