import { PortVlan } from "../types";

export const getGroupedList = (
  vlan: Array<PortVlan>
): { [key: string]: Array<PortVlan> } => {
  return vlan.reduce(
    (acc: { [key: string]: Array<PortVlan> }, vlan: PortVlan) => {
      const { physical_port_name } = vlan;
      if (!acc[physical_port_name]) {
        acc[physical_port_name] = [];
      }
      acc[physical_port_name].push(vlan);
      return acc;
    },
    {}
  );
};
