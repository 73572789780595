import { ReactElement } from "react";
import CloudSection from "../components/IpsecConnectionCloud/components/CloudSection";

export const renderSectionByCloudOperator = (
  cloudOperator: string,
  className?: string
): ReactElement | undefined => {
  switch (cloudOperator) {
    case "aws":
      return <CloudSection className={className} />;
    default:
      null;
  }
};
