import React, { FC } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { PORT_TYPES_MAP } from "../../consts";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";

const ConnectionTypeDropdown: FC<{ isNotEditable?: boolean }> = ({
  isNotEditable,
}) => {
  const { portType, changePortType } = useConnectionConfiguratorContext();
  return (
    <DropdownBasic
      id={"portType"}
      onChange={(val) => {
        changePortType(val);
      }}
      selected={portType}
      label="Port Type"
      itemsList={PORT_TYPES_MAP}
      isMedium
      error={""}
      isNotEditable={isNotEditable}
    />
  );
};
export default ConnectionTypeDropdown;
