import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { userApi } from "../../helpers/api/UserApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { UpdateUserGroup, User, UserGroup } from "../../helpers/types";

type RequestStatuses = {
  state: "pending" | "ok" | "error" | "idle";
  message?: string;
};
type State = {
  users: Array<User>;
  groups: any;
  groupStatus: RequestStatuses;
  selectedIp: Array<string>;
  groupsItems: Array<any> | undefined;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchUsers: (tenant: string) => Promise<void>;
  fetchOneGroup: (tenantName: string, groupName: string) => Promise<void>;
  fetchGroups: (tenant: string) => Promise<void>;
  setSelectedIp: (subnets: Array<string>) => void;
  createUsersGroup: (
    tenantName: string,
    bodyParams: UserGroup
  ) => Promise<boolean>;
  updateUsersGroup: (
    tenantName: string,
    groupName: string,
    bodyParams: UpdateUserGroup
  ) => Promise<void>;
};

const [UserGroupsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useUserGroupsContext = useContext;

export default class UserGroupsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = UserGroupsContext;

  constructor(props: Readonly<any>) {
    super(props);
  }
  fetchOneGroup = async (
    tenantName: string,
    groupName: string
  ): Promise<void> => {
    this.setState({ groupStatus: setPending() });
    await userApi.getOneUsersGroup(tenantName, groupName);
    this.setState({ groupStatus: { state: "ok" } });
  };

  fetchGroups = async (tenantName: string): Promise<void> => {
    this.setState({ groupStatus: setPending() });
    const { ok, result } = await userApi.getUsersGroupsList(tenantName);
    if (ok && result) {
      this.setState({
        groups: result,
        groupsItems: result.items,
        groupStatus: { state: "ok" },
      });
    }
  };

  fetchUsers = async (tenantName: string): Promise<void> => {
    const { ok, result } = await userApi.getTenantUsers(tenantName);
    if (ok && result) {
      this.setState({
        users: result,
      });
    }
  };

  setSelectedIp = (subnets: Array<string>): void => {
    this.setState({ selectedIp: subnets });
  };

  createUsersGroup = async (tenantName: string, bodyParams: UserGroup) => {
    this.setState({ groupStatus: setPending() });
    const res = await userApi.createUsersGroup(tenantName, bodyParams);
    if (res?.ok && res?.result) {
      await this.fetchGroups(tenantName);
    }
    this.setState({ groupStatus: resToState(res) });
    return res?.ok;
  };

  updateUsersGroup = async (
    tenantName: string,
    groupName: string,
    bodyParams: UpdateUserGroup
  ): Promise<void> => {
    this.setState({ groupStatus: setPending() });
    const { ok, result } = await userApi.editUsersGroup(
      tenantName,
      groupName,
      bodyParams
    );
    if (ok && result) {
      this.setState({ groupStatus: { state: "ok" } });
      await this.fetchGroups(tenantName);
    }
    this.setState({ groupStatus: { state: "error" } });
  };

  funcs = {
    fetchUsers: this.fetchUsers,
    fetchOneGroup: this.fetchOneGroup,
    fetchGroups: this.fetchGroups,
    setSelectedIp: this.setSelectedIp,
    updateUsersGroup: this.updateUsersGroup,
    createUsersGroup: this.createUsersGroup,
  };
}
