import React, { ReactElement } from "react";
import { GREY_DISABLED, WHITE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ReportsIcon({ color }: IconProps): ReactElement {
  return (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.33.63c0-.35.28-.63.63-.63h4.08c.35 0 .63.28.63.63v1.41c0 .35-.28.63-.63.63H7.96a.63.63 0 0 1-.63-.63V.62Zm-2.66.7H4.5A2.5 2.5 0 0 0 2 3.83V17.5A2.5 2.5 0 0 0 4.5 20h11a2.5 2.5 0 0 0 2.5-2.5V3.83a2.5 2.5 0 0 0-2.5-2.5h-.17v1.5a2.5 2.5 0 0 1-2.5 2.5H7.17a2.5 2.5 0 0 1-2.5-2.5v-1.5ZM6.37 15a1.25 1.25 0 1 0 0 2.5h7.5a1.25 1.25 0 0 0 0-2.5h-7.5Z"
          fill={GREY_DISABLED || color}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill={WHITE} d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ReportsIcon;
