import React, { FC } from "react";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import ActiveTenantSelector from "../../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import TimerangOverlayComponent from "../../../components/common/timerange/TimerangeOverlayComponent";
import FilterIcon from "../../../components/icons/FilterIcon";
import { FONT } from "../../../helpers/common/colorAliases";

import styles from "./TableTitle.module.scss";

const TableTitle: FC = () => {
  return (
    <div className={styles.tableTitleWrapper}>
      <div className={styles.title}>History Log</div>
      <div className={styles.actions}>
        <ActiveTenantSelector />
        <IconBlankButton
          icon={FilterIcon}
          id="historyLogsFilter"
          isFill
          color={FONT}
        />
        <TimerangOverlayComponent
          id={"historyLogsTimerange"}
          className={styles.timerangeWrapper}
          type="historyLog"
        />
      </div>
    </div>
  );
};

export default TableTitle;
