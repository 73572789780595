import { useEffect, useRef } from "react";
import { INTERVAL_VALUE } from "../../components/graphs_d3/common/liveLinechartHelpers";

export default function useRequestInterval(
  requestFn: () => void,
  timeout: number = INTERVAL_VALUE
): void {
  const i = useRef<number | undefined>(undefined);
  useEffect(() => {
    requestFn;
    i.current = window.setInterval(requestFn, timeout);
    return () => clearInterval(i.current);
  }, [requestFn]);
}
