import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function SystemEditIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_13240_136957"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_13240_136957)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0234 2.73039C17.6329 2.33987 16.9997 2.33987 16.6092 2.73039L14.7181 4.62142L20.9465 10.8498L22.8375 8.95873C23.228 8.56821 23.228 7.93504 22.8375 7.54452L18.0234 2.73039ZM19.2494 12.5468L13.0211 6.31847L4.37395 14.9656C4.18641 15.1532 4.08105 15.4075 4.08105 15.6727V20.4869C4.08105 21.0391 4.52877 21.4869 5.08105 21.4869H9.89518C10.1604 21.4869 10.4148 21.3815 10.6023 21.194L19.2494 12.5468Z"
          fill={color || FONT}
        />
      </g>
    </svg>
  );
}

export default SystemEditIcon;
