import React, { FC } from "react";
import { CreateFWRule } from "./FirewallRuleConfig";

import DropdownWithSearch from "../../../components/common/Dropdown/DropdownWithSearch";
import { DropdownItem } from "../../../helpers/types";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import styles from "./SourceBlock.module.scss";
import { UserGroups } from "../../../contexts/servicesContext/FirewallContext";
import GlobeIconLessLines from "../../../components/icons/GlobeIconLessLines";

type Props = {
  fields: CreateFWRule;
  errors: any;
  onChange: (value: string, name: string) => void;
  groups: UserGroups | undefined;
};

const DestinationBlock: FC<Props> = ({ fields, errors, onChange, groups }) => {
  const label = "Destination";
  let selected: string;

  const handleChange = (val: DropdownItem | string) => {
    if (typeof val === "string") {
      selected = val;
      onChange("dst_network", selected);
      onChange("dst_group", "");
    } else {
      selected = val.key;
      onChange("dst_group", selected);
      onChange("dst_network", "");
    }
  };

  return (
    <DropdownWithSearch
      id={label}
      placeholder={fields.dst_group || fields.dst_network || ""}
      label={label}
      onChange={handleChange}
      selected={mapStringToItem(fields.dst_group) || fields.dst_network || ""}
      error={errors && errors["dst_network"]}
      valuesList={groups?.tenant || []}
      isMedium
      isHideIcon={!!fields.dst_group || !!fields.dst_network}
      listClassName={styles.sourceWrapper}
      isOptionalLabel
      additionalValuesList={groups?.global}
      valuesWithLabel
      valuesLabel="Groups"
      additionalIcon={GlobeIconLessLines({})}
      withClearIcon
      hideEmptyListPlaceholder
    />
  );
};
export default DestinationBlock;
