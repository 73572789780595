import React, { useCallback, useMemo } from "react";

import { OneSystemType, VRFType } from "../../../helpers/api/apiTypes";
import DropdownBasic from "../Dropdown/DropdownBasic";
import { useVRFList } from "../../../helpers/api/hooks/useVRFList";

type Props = {
  system: OneSystemType;
  selected?: VRFType | any;
  onSelect: (vrf?: VRFType) => void;
  disabled?: boolean;
  className?: string;
  label?: string;
};
const VRFDropdown: React.FC<Props> = ({
  selected,
  onSelect,
  system,
  disabled,
  className,
  label,
}) => {
  const [list] = useVRFList(system.name);

  const mapToItem = useCallback(
    (vrf: VRFType) => ({
      value: vrf.name,
      key: vrf.name,
      data: vrf,
    }),
    []
  );
  const selectedIem = useMemo(() => {
    const isItem = selected?.key !== undefined;
    return selected ? (isItem ? selected : mapToItem(selected)) : undefined;
  }, [selected]);
  const items = useMemo(() => (list || []).map(mapToItem), [list]);
  return (
    <DropdownBasic
      id={"VRF"}
      onChange={(val) => onSelect(val.data)}
      selected={selectedIem}
      label={label || "VRF"}
      itemsList={items}
      isMedium
      disabled={disabled}
      className={className}
    />
  );
};

export default VRFDropdown;
