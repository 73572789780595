import React, { FC } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import {
  ACCENT,
  GREY_DISABLED,
} from "../../../../../helpers/common/colorAliases";
import { DropdownItem } from "../../../../../helpers/types";
import { getMenuIcon } from "../helpers/getMenuIcon";
import styles from "./Menu.module.scss";

type Props = {
  list: Array<DropdownItem>;
  selected: string;
  onSelect: (item: string) => void;
  isNotEditable?: boolean;
};

const Menu: FC<Props> = ({ list, selected, onSelect, isNotEditable }) => {
  return (
    <ul className={styles.wrapper}>
      {list.map((item) => {
        const isActive = item.key === selected;
        const Icon = getMenuIcon(item.key);
        return (
          <li
            onClick={() => !isNotEditable && onSelect(item.key)}
            className={classNames(
              styles.liItem,
              isActive && styles.active,
              isNotEditable && styles.disabled
            )}
          >
            <TextWithIcon
              text={item.value}
              icon={<Icon color={isActive ? ACCENT : GREY_DISABLED} />}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
