import React, { FC } from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../../components/common/formComponents/RadioGroup";
import Section from "../../../../../components/common/Section";
import NATIcon from "../../../../../components/icons/NATIcon";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import { DESTINATION_NAT, NAT_TYPES_MAP, PROTOCOL_TYPES } from "../../../const";
import InsideBlock from "./InsideBlock";
import OutsideBlock from "./OutsideBlock";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  className: string;
  isEdit: boolean;
  errors?: { [key: string]: string };
  validate: () => {
    isOk: boolean;
  };
};

const GeneralSection: FC<Props> = ({
  fields,
  onChange,
  className,
  isEdit,
  errors,
  validate,
}) => {
  return (
    <Section title={"General"} columnsCount={2} className={className}>
      <Input
        label="Name"
        name="name"
        placeholder=""
        value={fields.name}
        onChange={(e) => onChange("name", e.target.value)}
        medium
        error={errors && errors["name"]}
      />
      <DropdownBasic
        id={"rule_type"}
        onChange={(val) => onChange("rule_type", val.key)}
        selected={NAT_TYPES_MAP.find((el) => el.key === fields.rule_type)}
        label="Type"
        placeholder=""
        itemsList={NAT_TYPES_MAP}
        icon={<NATIcon color={GREY_FONT} />}
        isMedium
        isNotEditable={isEdit}
        error={errors && errors["rule_type"]}
      />
      <InsideBlock
        fields={fields}
        onChange={onChange}
        isEdit={isEdit}
        errors={errors}
        validate={validate}
      />
      <OutsideBlock fields={fields} onChange={onChange} isEdit={isEdit} />
      <Input
        label="Description"
        isOptional
        name="description"
        placeholder=""
        value={fields.description}
        onChange={(e) => onChange("description", e.target.value)}
        medium
        error={""}
        isFullValue
      />
      {fields?.rule_type === DESTINATION_NAT && (
        <RadioGroup
          label={"Protocol"}
          isOneRow
          options={PROTOCOL_TYPES}
          value={fields.generalProtocol}
          setActiveValue={(val) => onChange("generalProtocol", val)}
          isNotEditable={isEdit}
        />
      )}
    </Section>
  );
};
export default GeneralSection;
