import React, { ReactChild, ReactElement } from "react";
import { classNames } from "../../helpers/common/classNames";
import styles from "./OptionalLabel.module.scss";
import BlankButton from "./buttons/BlankButton";

type OptionalLabelProps = {
  className?: string;
  isShown: boolean;
  text?: string | ReactChild | undefined;
  children: Array<React.ReactElement> | React.ReactElement;
  isOptional?: boolean;
  medium?: boolean;
  tipMessage?: string;
  withDelete?: boolean;
  onDelete?: () => void;
};
export function OptionalLabel({
  className,
  isShown,
  text,
  children,
  isOptional,
  medium,
  tipMessage,
  withDelete,
  onDelete,
  ...restProps
}: OptionalLabelProps): ReactElement {
  if (!isShown) {
    return <>{children}</>;
  }
  return (
    <label className={className} {...restProps}>
      {text && (
        <div className={classNames(styles.label, medium && styles.medium)}>
          <div className={styles.textWrapper}>
            {text}
            {(isOptional || tipMessage) && (
              <div className={styles.optional}>
                — {tipMessage || "optional"}
              </div>
            )}
          </div>
          {withDelete && (
            <BlankButton
              className={styles.delete}
              id={"label-delete"}
              key={"label-delete"}
              onClick={onDelete}
            >
              {"Delete"}
            </BlankButton>
          )}
        </div>
      )}
      {children}
    </label>
  );
}
