import { arubaApi } from "./api/ArubaApi";

const CACHED_DEVICES: Record<string, any> = {};
const CACHED_GROUPS: Record<string, any> = {};

export const fetchDevices = (): Promise<Array<any>> => {
  const tenant = localStorage.getItem("selectedTenant") || "";

  if (CACHED_DEVICES[tenant]) {
    return Promise.resolve(CACHED_DEVICES[tenant]);
  }

  return arubaApi.getAppliances().then((res) => {
    CACHED_DEVICES[tenant] = res.result?.map((item: any) => {
      return {
        id: item.id,
        groupId: item.groupId,
        hostName: item.hostName,
      };
    });
    return CACHED_DEVICES[tenant];
  });
};

export const fetchGroups = (): Promise<Array<any>> => {
  const tenant = localStorage.getItem("selectedTenant") || "";

  if (CACHED_GROUPS[tenant]) {
    return Promise.resolve(CACHED_GROUPS[tenant]);
  }

  return arubaApi.getGroups().then((res) => {
    CACHED_GROUPS[tenant] = res.result?.map((item: any) => {
      return {
        id: item.id,
        name: item.name,
      };
    });
    return CACHED_GROUPS[tenant];
  });
};
