import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";
import { getColorFromStatus } from "../../helpers/common/colorHelpers";

function BGPIcon({ color, status }: IconProps): ReactElement {
  const statusColor = getColorFromStatus(status);
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {status && <circle cx="21" cy="3" r="3" fill={statusColor} />}
      <rect
        width="7.3"
        height="7.3"
        rx="1.4"
        transform="matrix(-1 0 0 1 9 2)"
        fill={color || FONT}
      />
      <rect
        width="7.3"
        height="7.3"
        rx="1.4"
        transform="matrix(-1 0 0 1 18 11)"
        fill={color || FONT}
      />
    </svg>
  );
}

export default BGPIcon;
