import React, { FC } from "react";
import styles from "./DatePickerComponent.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { classNames } from "../../../helpers/common/classNames";

type DatePickerProps = {
  className?: string;
  date: Date | null;
  setDate: (date: Date | null) => void;
  minDate?: Date | null;
  setMode?: () => void;
  placeholderText: string;
  dateFormat?: string;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  isMedium?: boolean;
};

const DatePickerComponent: FC<DatePickerProps> = ({
  className,
  setDate,
  date,
  minDate,
  setMode,
  placeholderText,
  dateFormat,
  showTimeSelect,
  showTimeSelectOnly,
  timeCaption,
  timeIntervals,
  isMedium,
}) => {
  return (
    <DatePicker
      className={classNames(
        styles.datePicker,
        className,
        isMedium && styles.medium
      )}
      showPopperArrow={false}
      selected={date}
      onChange={(d) => {
        setDate(d);
        setMode?.();
      }}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      minDate={minDate}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      timeCaption={timeCaption}
      timeIntervals={timeIntervals}
    />
  );
};

export default DatePickerComponent;
