import { ReactElement } from "react";

function GoogleCloudIcon(): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_88_31175)">
        <path
          d="M17.6925 9.39681L19.883 7.17871L20.029 6.24474C16.0373 2.56938 9.6919 2.98612 6.09352 7.11432C5.09398 8.2609 4.35245 9.69036 3.95703 11.1665L4.74158 11.0545L9.12254 10.323L9.46077 9.97274C11.4095 7.80558 14.7045 7.51402 16.9546 9.35794L17.6925 9.39681Z"
          fill="#EA4335"
        />
        <path
          d="M23.0035 11.1012C22.5 9.22373 21.4663 7.53591 20.029 6.2448L16.9546 9.3579C18.2528 10.432 18.9924 12.0502 18.9607 13.7473V14.3C20.4718 14.3 21.6969 15.5404 21.6969 17.0706C21.6969 18.6008 20.4718 19.8102 18.9607 19.8102H13.4806L12.9425 20.4016V23.7249L13.4806 24.2463H18.9607C22.8913 24.2773 26.1025 21.1071 26.1331 17.127C26.1516 14.7145 24.977 12.4528 23.0035 11.1012Z"
          fill="#4285F4"
        />
        <path
          d="M8.00811 24.2463H13.4805V19.8101H8.00811C7.6182 19.81 7.24059 19.7252 6.88602 19.5611L6.10964 19.8023L3.90376 22.0204L3.71161 22.7754C4.94867 23.7212 6.45801 24.2531 8.00811 24.2463Z"
          fill="#34A853"
        />
        <path
          d="M8.00808 9.85601C4.07738 9.87973 0.910051 13.1256 0.933479 17.1057C0.946571 19.3283 1.9717 21.4205 3.71158 22.7754L6.88599 19.5611C5.50876 18.931 4.89677 17.2899 5.51899 15.8954C6.14112 14.5009 7.76199 13.8812 9.13903 14.5112C9.7459 14.7888 10.2319 15.281 10.506 15.8954L13.6804 12.6811C12.3298 10.8932 10.2311 9.84794 8.00808 9.85601Z"
          fill="#FBBC05"
        />
      </g>
      <defs>
        <clipPath id="clip0_88_31175">
          <rect
            width="25.2"
            height="20.5333"
            fill="white"
            transform="translate(0.93335 3.73334)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GoogleCloudIcon;
