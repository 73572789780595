import { FC, useMemo } from "react";
import { TrafficType } from "../../../../../../helpers/types";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import grafanaLinksFactory from "../../../../../../components/common/charts/GraphanaLinksFactory";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";

type Props = {
  interfaceName: string;
  traffic: TrafficType;
  tenantName?: string;
  className?: string;
};

const InterfaceTraffic: FC<Props> = ({
  interfaceName,
  traffic = "bps",
  tenantName,
  className,
}) => {
  const { timeRange } = useTimerangeContext();

  const graphLink = useMemo(() => {
    const data = {
      interfaceName,
      timeRange: timeRange.general,
      tenantName,
    };
    return traffic === "bps"
      ? grafanaLinksFactory.dashboard_interface_bps(timeRange.general)(data)
      : grafanaLinksFactory.dashboard_interface_pps(timeRange.general)(data);
  }, [traffic, timeRange, tenantName, interfaceName]);

  return (
    <div className={className}>
      <GrafanaGraph link={graphLink} />
    </div>
  );
};

export default InterfaceTraffic;
