import {
  ServiceBGPNeighbor,
  ServiceBGPNeighborWithStatus,
  ServiceBGPStatus,
} from "../../../helpers/api/apiTypes";

export const addStatusToNeighbor = (
  neighbors: Array<ServiceBGPNeighbor>,
  status: ServiceBGPStatus
): Array<ServiceBGPNeighborWithStatus> => {
  return neighbors?.map((n) => ({
    ...n,
    status: status?.peers?.[n?.remote_router_ip],
  }));
};
