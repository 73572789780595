import { FC, useMemo, useState } from "react";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";
import AlibabaIcon from "../../../components/icons/cloudConnections/AlibabaIcon";
import AWSIcon from "../../../components/icons/cloudConnections/AWSIcon";
import AzureIcon from "../../../components/icons/cloudConnections/AzureIcon";
import GCPIcon from "../../../components/icons/cloudConnections/GCPIcon";
import OracleIcon from "../../../components/icons/cloudConnections/OracleIcon";
import StatusIcon from "../../../components/icons/StatusIcon";
import { classNames } from "../../../helpers/common/classNames";
import {
  GREEN,
  GREY_DISABLED,
  GREY_FONT,
  ORANGE,
} from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";
import { useCloudsConnectivityContext } from "../CloudsConnectivityContext";
import styles from "./CloudTitle.module.scss";
import KeyIcon from "../../../components/icons/KeyIcon";
import CheckMarkIcon from "../../../components/icons/CheckMarkIcon";
import Button from "../../../components/common/buttons/Button";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import UpdateIcon from "../../../components/icons/UpdateIcon";

type Props = {
  className: string;
  title: string;
  count: number;
  withDetails: boolean;
};

const CloudTitle: FC<Props> = ({ className, title, count, withDetails }) => {
  const { countStatus } = useCloudsConnectivityContext();
  const {
    tokens,
    correctTokens,
    setRequestToRemove,
    setRequestToUpdate,
    requestToUpdate,
  } = useCloudsConnectivityContext();
  const [tooltipShown, setTooltipShown] = useState<boolean>(false);
  const type = useMemo(() => (title === "azure" ? "VNET" : "VPC"), [title]);
  const Icon = useMemo(() => getIconFromType(title), [title]);
  const keyColor =
    tokens?.[title] && correctTokens?.[title]
      ? GREEN
      : correctTokens?.[title] === false
      ? ORANGE
      : GREY_DISABLED;
  const countVal =
    countStatus?.state === "pending" ? (
      <LoaderIcon className={styles.loader} color={GREY_FONT} />
    ) : (
      count
    );

  const handleTooltip = () => {
    setTooltipShown(!tooltipShown);
  };

  const getUpdateTokensBlock = () => {
    return (
      <div className={styles.logic}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setRequestToRemove(title);
          }}
        >
          <DeleteIcon />
          Delete
        </Button>
        <Button
          onClick={(e) => {
            withDetails && e.stopPropagation();
            requestToUpdate ? setRequestToUpdate() : setRequestToUpdate(title);
          }}
        >
          <UpdateIcon />
          Update
        </Button>
      </div>
    );
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.cloudName}>
        <Icon color={GREY_FONT} className={styles.cloudIcon} />
        {TYPE_TITLE_MAP[title]}
      </div>
      <div className={styles.right}>
        <div onMouseEnter={handleTooltip} onMouseLeave={handleTooltip}>
          <KeyIcon color={keyColor} />
          {tooltipShown && keyColor === GREEN && (
            <div className={styles.tooltip}>
              <div className={styles.text}>
                <CheckMarkIcon color={GREEN} />
                Token stored
              </div>
              {getUpdateTokensBlock()}
            </div>
          )}
          {tooltipShown && keyColor === ORANGE && (
            <div className={styles.tooltipExpired}>
              <div className={styles.text}>The key is expired/incorrect</div>
              {getUpdateTokensBlock()}
            </div>
          )}
        </div>
        <div className={styles.counter}>
          {countVal} {type} connected
        </div>
      </div>
    </div>
  );
};
export default CloudTitle;

const getIconFromType = (type: string): FC<IconProps> => {
  switch (type) {
    case "aws":
      return AWSIcon;
    case "azure":
      return AzureIcon;
    case "gcp":
      return GCPIcon;
    case "oracle":
      return OracleIcon;
    case "alibaba":
      return AlibabaIcon;
    default:
      return StatusIcon;
  }
};
const TYPE_TITLE_MAP: { [key: string]: string } = {
  aws: "AWS",
  azure: "Azure",
  gcp: "GCP",
  oracle: "Oracle",
  alibaba: "Alibaba",
};
