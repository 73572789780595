import React, { FC, useState } from "react";
import { useConnectionsContext } from "../../../../ConnectionContext";
import { ServiceBGPNeighborWithStatus } from "../../../../../../../helpers/api/apiTypes";
import { getColorFromStatus } from "../../../../../../../helpers/common/colorHelpers";
import BGPTooltip from "../BGPTooltip";
import { getBGPStatusType } from "../../../../helpers/getBGPStatusType";
import { useHistory } from "react-router-dom";
import { formPathFromArray } from "../../../../../../../helpers/navigation";
import {
  BGP,
  CLOUD_ROUTER,
  NETWORK,
} from "../../../../../../../helpers/navigation/entries";
import { useUserContext } from "../../../../../../../contexts/UserContext";

type Props = {
  bgpItems: Array<ServiceBGPNeighborWithStatus> | undefined;
  x: number;
  y: number;
  isShift: boolean;
};
//
const BGPLabelsGroup: FC<Props> = ({ x, y, bgpItems, isShift }) => {
  const { isDemo } = useUserContext();

  const labelWidth = 41;
  const spacing = 12;
  const totalWidth =
    bgpItems === undefined || bgpItems.length === 0
      ? labelWidth
      : bgpItems.length * (labelWidth + spacing) - spacing;

  return (
    <svg
      width={totalWidth}
      height="18"
      viewBox={`0 0 41 18`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={isShift ? x - totalWidth + spacing : x}
      y={y - 18 / 2}
      style={{ cursor: "help", userSelect: "none" }}
    >
      <g x={0} y={0}>
        {!bgpItems || bgpItems.length === 0 ? (
          <BGPItem
            key={"zeroBGP"}
            bgp={"Absent"}
            idx={0}
            labelWidth={labelWidth}
            spacing={spacing}
            isLastItem={true}
          />
        ) : (
          bgpItems.map((item, i) => (
            <BGPItem
              key={item.id}
              bgp={isDemo ? "Established" : item.status?.state}
              idx={i}
              labelWidth={labelWidth}
              spacing={spacing}
              isLastItem={i === bgpItems.length - 1}
              neighbor={item}
            />
          ))
        )}
      </g>
    </svg>
  );
};
export default BGPLabelsGroup;

type ItemProps = {
  labelWidth: number;
  spacing: number;
  bgp: string;
  idx: number;
  isLastItem: boolean;
  neighbor?: ServiceBGPNeighborWithStatus;
};

const BGPItem: FC<ItemProps> = ({
  bgp,
  idx,
  labelWidth,
  spacing,
  isLastItem,
  neighbor,
}) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(formPathFromArray([NETWORK(), CLOUD_ROUTER(), BGP()]));
  };
  const { setTooltip } = useConnectionsContext();
  const [zIndex, setZIndex] = useState(idx);
  const statusVal = getBGPStatusType(bgp);
  const x = idx * spacing;

  let value = neighbor ? (
    <BGPTooltip neighbor={neighbor} />
  ) : bgp === "Absent" ? (
    "BGP is not configured"
  ) : (
    "BGP is not established."
  );

  if (bgp === "Established") value = "BGP is established.";

  return (
    <g
      onClick={handleClick}
      onMouseEnter={() => {
        setTooltip({
          id: bgp + idx,
          value,
        });
        setZIndex(999);
      }}
      onMouseLeave={() => {
        setTooltip(undefined);
        setZIndex(idx);
      }}
      style={{ zIndex, cursor: "pointer" }}
    >
      <rect
        width={labelWidth}
        height="18"
        rx="6"
        fill={bgpColor[statusVal]}
        stroke={getColorFromStatus(statusVal)}
        strokeOpacity="0.3"
        x={x}
      />
      <circle
        cx={x + 2.5 + 2}
        cy="8.5"
        r="2.5"
        fill={getColorFromStatus(statusVal)}
      />
      {isLastItem && (
        <text
          x={x + 10}
          y={14}
          textAnchor="start"
          fill="var(--font)"
          fontSize={12}
          fontWeight={600}
        >
          BGP
        </text>
      )}
    </g>
  );
};

const bgpColor: { [key: string]: string } = {
  Absent: "#FDE4EC",
  Disconnect: "#FDE8DE",
  Established: "#E4F6F1",
};
