import React, { FC } from "react";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import SystemContextContainer from "../../../contexts/systemsContext/SystemsContext";
import { useTimerangeContext } from "../../../contexts/TimerangeContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { withLayout } from "../../../helpers/hocs/withLayout";
import { PageProps } from "../../../helpers/types";
import { OneSystemTabs } from "../../common/navigation/OneSystemTabs";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import styles from "./SystemEventsPage.module.scss";
import EventsTable from "../../../pages/EventsPage/EventsTable";

const SystemEventsPage: FC = () => {
  const { system } = useOneSystemContext();

  return (
    <OneSystemLayout>
      <div className={styles.wrapper}>
        <OneSystemTabs />
        <EventsTable type="system" matchParam={system?.name} />
      </div>
    </OneSystemLayout>
  );
};

export default withLayout<PageProps>(
  withContexts(SystemEventsPage, [SystemContextContainer])
);
