import React, { FC } from "react";
import { Column } from "react-table";
import TableWrapper from "../../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../components/common/table/newTable/Table";
import { SubnetFirewallRule } from "./api.types";
import SubnetTableHeader from "./SubnetTableHeader";

export const list: Array<SubnetFirewallRule> = [
  {
    subnet: "10.107.24.0/24",
    rule_actions: { destination: true, source: true },
  },
  {
    subnet: "10.206.6.0/24",
    rule_actions: { destination: true, source: true },
  },
];

const SubnetTable: FC = () => {
  return (
    <TableWrapper
      dataStatus={{ state: "ok" }}
      titleProps={{
        title: "Specific subnets in your network without Firewall rules",
      }}
    >
      <Table
        data={list}
        header={TableHeader}
        columns={
          (SubnetTableHeader() as unknown) as Array<Column<SubnetFirewallRule>>
        }
        gridColumnTemplate={`150px 1fr`}
      />
    </TableWrapper>
  );
};

export default SubnetTable;
