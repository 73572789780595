import { ReactElement } from "react";

function StandbyNodeIcon(): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.2" cx="12" cy="12" r="12" fill="#6B55F0" />
      <g clip-path="url(#clip0_30572_97735)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.16434 9.11686C8.39618 8.80749 8.33332 8.36875 8.02395 8.13692C7.71457 7.90508 7.27584 7.96794 7.044 8.27731C5.23839 10.6868 5.43277 14.1185 7.62583 16.3066C10.0281 18.7034 13.9185 18.699 16.3153 16.2968C18.5001 14.1071 18.6895 10.6822 16.8873 8.27732C16.6555 7.96794 16.2168 7.90508 15.9074 8.13692C15.598 8.36875 15.5352 8.80749 15.767 9.11686C17.1578 10.9728 17.0105 13.6179 15.3243 15.3079C13.4736 17.1628 10.4696 17.1662 8.61466 15.3155C6.92203 13.6267 6.77084 10.9764 8.16434 9.11686ZM11.9997 5C11.5358 5 11.1597 5.37608 11.1597 5.84L11.1597 10.46C11.1597 10.9239 11.5358 11.3 11.9997 11.3C12.4636 11.3 12.8397 10.9239 12.8397 10.46L12.8397 5.84C12.8397 5.37608 12.4636 5 11.9997 5Z"
          fill="#6B55F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_30572_97735">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="matrix(-4.37114e-08 1 1 4.37114e-08 5 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default StandbyNodeIcon;
