import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { firewallTableHeader } from "../../../components/common/table/TableHeaders/Services/firewallTableHeader";
import { formPathFromArray } from "../../../helpers/navigation";
import { SERVICES, SERVICES_FW } from "../../../helpers/navigation/entries";

const FWRulesTable: FC = () => {
  const { data, statuses } = useSearchContext();
  return (
    <ResultTable
      title="FW Rules"
      isCollapsible
      count={data?.fwRules.length}
      columns={firewallTableHeader()}
      data={data?.fwRules}
      grid={`120px 1fr 120px repeat(4, 1fr) 100px 80px`}
      status={statuses?.fwRulesStatus}
      goto={formPathFromArray([SERVICES(), SERVICES_FW()])}
    />
  );
};
export default FWRulesTable;
