import React, { FC, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import styles from "./WizardNavigationBar.module.scss";

import {
  WIZARDS,
  LOCATIONS,
  INSIGHTS,
} from "../../../helpers/navigation/entries";
import { classNames } from "../../../helpers/common/classNames";
import Button from "../../../components/common/buttons/Button";
import { formPathFromArray, isActivePath } from "../../../helpers/navigation";
import BlankButton from "../../../components/common/buttons/BlankButton";
import { STEPS_LINKS } from "../utils/onboardingConsts";
import { useOnboardingContext } from "../OnboardingContext";
import { IS_DEMO } from "../../../globalFlags";
import { useUserContext } from "../../../contexts/UserContext";
import { useLocationContext } from "../LocationStep/LocationContext";

const WizardNavigationBar: FC = () => {
  const { user, setIsTenantEmpty } = useUserContext();
  const { stepNumber, setStepNumber } = useOnboardingContext();
  const { addLocations } = useLocationContext();

  const location = useLocation();
  const history = useHistory();

  const isLastStep = useMemo(() => stepNumber === STEPS_LINKS.length - 1, [
    stepNumber,
  ]);

  const isLocationsStep = useMemo(
    () => location.pathname.includes(LOCATIONS().path),
    [location]
  );

  const handleContinue = async () => {
    if (isLastStep) {
      setIsTenantEmpty(false);
      return history.push("/" + INSIGHTS().path);
    }

    if (isLocationsStep && !IS_DEMO) {
      const res = await addLocations(user.name);
      if (!res) return;
    }

    setStepNumber(stepNumber + 1);
    const nextStep = STEPS_LINKS[stepNumber + 1];
    history.push(formPathFromArray([WIZARDS(), nextStep]));
  };

  return (
    <div className={styles.container}>
      {STEPS_LINKS.map((el, i) => {
        return (
          <>
            <BlankButton
              id={`wizardNav-${el.path}`}
              key={el.path}
              className={classNames(
                styles.tabButton,
                isActivePath(location, el.path) && styles.active
              )}
              onClick={() => {
                history.push(formPathFromArray([WIZARDS(), el]));
                setStepNumber(i);
              }}
            >
              <div className={styles.number}>{i + 1}</div>
              {el.content}
            </BlankButton>
            {i !== STEPS_LINKS.length - 1 && (
              <hr className={styles.separator} />
            )}
          </>
        );
      })}
      <Button onClick={handleContinue} className={styles.btn} isPrimaryBtn>
        {isLastStep ? "Done" : "Continue"}
      </Button>
    </div>
  );
};

export default WizardNavigationBar;
