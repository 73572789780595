import { FC, useMemo } from "react";
import styles from "./ListComponent.module.scss";
import Table from "../../../../../components/common/table/newTable/Table";
import { urlListTableColumns } from "../../../helpers/urlListTableColumns";
import { ActionFuncs } from "../../../../../components/common/table/newTable/RowControls";
import { FDQNDomain } from "../../../types";

type Props = {
  list: Array<FDQNDomain>;
  removeItem: (item: FDQNDomain) => void;
};

const ListComponent: FC<Props> = ({ list, removeItem }) => {
  const rowActions: ActionFuncs<FDQNDomain> = useMemo(
    () => ({
      onDelete: (row) => removeItem(row),
    }),
    []
  );
  return (
    <div className={styles.wrapper}>
      <Table
        columns={urlListTableColumns()}
        data={list}
        gridColumnTemplate={`88px 1fr 0`}
        rowActions={rowActions}
        withoutPagination
      />
    </div>
  );
};

export default ListComponent;
