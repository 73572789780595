import React, { ReactElement } from "react";
import { BUTTON_PRIMARY } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function UpdateIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3368 6.82769C14.6346 6.82713 14.8659 6.56807 14.8328 6.27216L14.3409 1.87087C14.2929 1.44116 13.759 1.27087 13.471 1.59341L12.1388 3.08562C9.62741 1.7622 6.44854 2.15812 4.33849 4.27296C1.74662 6.8707 1.75139 11.0777 4.34913 13.6696C6.94687 16.2614 11.1539 16.2567 13.7457 13.6589C13.8681 13.5363 13.9848 13.4099 14.0959 13.28C14.5266 12.7764 14.4675 12.0189 13.9638 11.5881C13.4601 11.1574 12.7026 11.2165 12.2719 11.7202C12.2007 11.8034 12.1257 11.8847 12.0468 11.9638C10.3911 13.6232 7.70369 13.6263 6.04426 11.9706C4.38484 10.3149 4.3818 7.62751 6.03747 5.96809C7.23234 4.77051 8.96587 4.43532 10.4614 4.96449L9.53421 6.00307C9.24625 6.32561 9.47576 6.83687 9.90814 6.83606L14.3368 6.82769Z"
        fill={color || BUTTON_PRIMARY}
      />
    </svg>
  );
}

export default UpdateIcon;
