import React, { ReactElement } from "react";
import styles from "./LoaderIcon.module.scss";
import { PURPLE } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";
import { classNames } from "../../../helpers/common/classNames";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function LoaderIcon({
  color = PURPLE,
  height = 42,
  width = 42,
  className,
}: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 42 42"
      fill="none"
      className={classNames(styles.circularLoader, className)}
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 21c0 11.046 8.954 20 20 20s20-8.954 20-20S32.046 1 21 1"
      />
    </svg>
  );
}

export default LoaderIcon;

export function PageLoader(): React.ReactElement {
  return (
    <div className={styles.container}>
      <LoaderIcon />
    </div>
  );
}
