import { getPanelIdByTimerange } from "../../../helpers/getPanelIdByTimerange";

/* eslint-disable @typescript-eslint/no-unused-vars */
export type GTimeRange = { from: string; to: string };

export const GRAFANA_ROOT_PATH = "/broker/grafana";

export const hardcodedTimeRange = {
  from: Date.parse("2023-07-18T14:58:11.319Z"),
  to: Date.parse("2023-07-19T08:32:11.505Z"),
};

export function formGTimeRange(timeSpanMin = 15): GTimeRange {
  return {
    from: `now-${timeSpanMin}m`,
    to: "now",
  };
}

// to ISO string
export function parseGTime(time: string): string {
  if (time === "now") {
    return new Date().toISOString();
  }

  if (time && time.startsWith("now-") && time.endsWith("m")) {
    const reg = /(?<=now-)(.*)(?=m)/g;
    const arr = reg.exec(time);
    if (arr && arr.length > 0) {
      const min = parseInt(arr[0]);
      const date = new Date();
      date.setMinutes(date.getMinutes() - min);
      return date.toISOString();
    }
  }

  if (time && time.startsWith("now-") && time.endsWith("s")) {
    const reg = /(?<=now-)(.*)(?=s)/g;
    const arr = reg.exec(time);
    if (arr && arr.length > 0) {
      const sec = parseInt(arr[0]);
      const date = new Date();
      date.setSeconds(date.getSeconds() - sec);
      return date.toISOString();
    }
  }

  return time;
}

export function parseGTimeRange(range: GTimeRange): GTimeRange {
  return {
    from: parseGTime(range.from),
    to: parseGTime(range.to),
  };
}

function tenantRawQuery(tenantName?: string): string | undefined {
  return tenantName ? `var-tenant_name=${tenantName}` : undefined;
}

function tenantRawNameQuery(tenantName?: string): string | undefined {
  return tenantName ? `tenant_name.keyword%7C%3D%7C${tenantName}` : undefined;
}

export function createUnifiedTenantFilter(tenantName?: string): string {
  return tenantName ? `var-Filters=${tenantRawNameQuery(tenantName)}` : "";
}

function composeFilters(
  tenantName?: string,
  vrf?: string | number
): string | undefined {
  if (!tenantName && !vrf) return;
  const tenantQuery = tenantRawQuery(tenantName);
  const vfrQuery = vrf ? `vrf%7C%3D%7C${vrf}` : undefined;
  if (tenantQuery && vfrQuery) {
    console.error(
      `GrafanaLinksFactory filter duplication filter${vfrQuery} was overwrited to ${tenantQuery}`
    );
  }
  return tenantQuery || vfrQuery;
}
const panels = {
  dashboard: {
    bps: 58,
    pps: 59,
    ccn: 63,
    cps: 61,
    fw_rules_hits: 110,
    ids: 4,
    interface: {
      bps: 129,
      pps: 131,
    },
    interfaceInternet: {
      bps: 169,
      pps: 168,
    },
  },
  system: {
    ccn: 73,
    cps: 69,
    bps: 67,
    pps: 65,
    ccn_round: 127,
    cps_round: 125,
    bps_round: 123,
    pps_round: 124,
    cpu: 20,
    ram: 16,
    fs: 154,
    per_tenant_in: 139,
    per_tenant_out: 137,
  },
  firewall_bps: 163,
  firewall_bps_accept: 167,
  firewall_pps: 79,
  firewall_pps_accept: 81,
  firewall_cps: 85,
  firewall_cps_accept: 89,
  firewall_ccn: 87,
  firewall_ccn_accept: 91,

  nat_pps_rx: 95,
  nat_pps_tx: 150,

  nat_bps_rx: 93,
  nat_bps_tx: 152,

  nat_pie_rx: 109,
  nat_pie_tx: 151,

  cyberTreads: {
    graph: 2,
    pie: 4,
    uniqueAlerts: 6,
    multiGraph: 3,
    protocols1: 5,
    protocols2: 7,
    table: 9,
  },
  tenant_events: 5,
  system_events: 6,
};

const grafana_common = {
  path: GRAFANA_ROOT_PATH,
  boards: {
    insidePacket: "d-solo/gui_graphs/gui-graphs",
    ids: "d-solo/RoU1UkmVz/ids",
    dns: "d-solo/RoU1UkmVz/dns-threats",
    nsosSystemHealth: "d-solo/sS6Rmg37z/nsos-system-health",
    events: "d-solo/UF-o3uGVz/system-events",
  },
  panels,
};

export type LinkFn = (options: Partial<GrafanaOptions>) => string;
export type GrafanaOptions = {
  interfaceName?: number | string;
  timeRange?: GTimeRange;
  panelId: number;
  system?: string;
  systemIp?: string;
  tenantName?: string;
  filter?: { vrf?: string | number; tenant?: string | number };
  noTime?: boolean;
  filtersList?: string;
};
class GraphanaLinksFactory {
  gMap = grafana_common;

  formArgs(options: GrafanaOptions) {
    const timeRange = options.noTime
      ? { ...hardcodedTimeRange }
      : options.timeRange || formGTimeRange(15);

    return {
      orgId: 1,
      panelId: options.panelId,
      "var-system": options.system || undefined,
      "var-node": options.systemIp || undefined,
      "var-virtual_interface": options.interfaceName || undefined,
      "var-Filters":
        composeFilters(undefined, options.filter?.vrf) || undefined,
      "var-tenant_name": options.tenantName || undefined,
      // "var-fw_rule": undefined,
      // "var-nat_rule": "",
      refresh: "30s",
      theme: "light",
      ...timeRange,
      extraFilters: options.filtersList,
    };
  }

  formLink(panelId: number, board: string, args: Partial<GrafanaOptions>) {
    return (
      [this.gMap.path, board].join("/") +
      formParamsString(this.formArgs({ ...args, panelId }))
    );
  }

  withOptions = (panelId: number, board?: string): LinkFn => {
    return (options: Partial<GrafanaOptions>) => {
      return this.formLink(
        panelId,
        board || this.gMap.boards.insidePacket,
        options
      );
    };
  };

  withOptionsNoTime = (panelId: number, board?: string): LinkFn => {
    return (options: Partial<GrafanaOptions>) => {
      return this.formLink(panelId, board || this.gMap.boards.insidePacket, {
        ...options,
        noTime: true,
      });
    };
  };

  dashboard_bps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "dashboardBps");
    return this.withOptions(panel);
  }
  dashboard_pps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "dashboardPps");
    return this.withOptions(panel);
  }
  dashboard_fw_rules_hits = this.withOptions(
    this.gMap.panels.dashboard.fw_rules_hits
  );
  dashboard_ids = this.withOptions(
    this.gMap.panels.dashboard.ids,
    this.gMap.boards.ids
  );
  dashboard_interface_bps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "interfaceBps");
    return this.withOptions(panel);
  }
  dashboard_interface_pps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "interfacePps");
    return this.withOptions(panel);
  }
  dashboard_interface_internet_bps = this.withOptions(
    this.gMap.panels.dashboard.interfaceInternet.bps
  );
  dashboard_interface_internet_pps = this.withOptions(
    this.gMap.panels.dashboard.interfaceInternet.pps
  );
  dashboard_ccn = this.withOptions(this.gMap.panels.dashboard.ccn);
  dashboard_cps = this.withOptions(this.gMap.panels.dashboard.cps);

  // system
  system_ccn(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "systemCcn");
    return this.withOptions(panel);
  }
  system_cps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "systemCps");
    return this.withOptions(panel);
  }
  system_bps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "systemBps");
    return this.withOptions(panel);
  }
  system_pps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "systemPps");
    return this.withOptions(panel);
  }
  system_ccn_round = this.withOptions(this.gMap.panels.system.ccn_round);
  system_cps_round = this.withOptions(this.gMap.panels.system.cps_round);
  system_bps_round = this.withOptions(this.gMap.panels.system.bps_round);
  system_pps_round = this.withOptions(this.gMap.panels.system.pps_round);
  system_cpu = this.withOptions(
    this.gMap.panels.system.cpu,
    this.gMap.boards.nsosSystemHealth
  );
  system_ram = this.withOptions(
    this.gMap.panels.system.ram,
    this.gMap.boards.nsosSystemHealth
  );
  system_fs = this.withOptions(
    this.gMap.panels.system.fs,
    this.gMap.boards.nsosSystemHealth
  );
  system_per_tenant_in = this.withOptions(
    this.gMap.panels.system.per_tenant_in
  );
  system_per_tenant_out = this.withOptions(
    this.gMap.panels.system.per_tenant_out
  );

  firewall_bps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallBps");
    return this.withOptions(panel);
  }
  firewall_bps_accept(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallBpsAccept");
    return this.withOptions(panel);
  }
  firewall_pps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallPps");
    return this.withOptions(panel);
  }
  firewall_pps_accept(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallPpsAccept");
    return this.withOptions(panel);
  }
  firewall_cps(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallCps");
    return this.withOptions(panel);
  }
  firewall_cps_accept(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallCpsAccept");
    return this.withOptions(panel);
  }
  firewall_ccn(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallCcn");
    return this.withOptions(panel);
  }
  firewall_ccn_accept(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "firewallCcnAccept");
    return this.withOptions(panel);
  }
  nat_pps_rx(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "natPpsRx");
    return this.withOptions(panel);
  }
  nat_pps_tx(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "natPpsTx");
    return this.withOptions(panel);
  }

  nat_bps_rx(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "natBpsRx");
    return this.withOptions(panel);
  }
  nat_bps_tx(timeRange: GTimeRange) {
    const panel = getPanelIdByTimerange(timeRange, "natBpsTx");
    return this.withOptions(panel);
  }

  nat_pie_rx = this.withOptions(this.gMap.panels.nat_pie_rx);
  nat_pie_tx = this.withOptions(this.gMap.panels.nat_pie_tx);

  cyber_threats_graph = this.withOptions(
    this.gMap.panels.cyberTreads.graph,
    this.gMap.boards.ids
  );
  dns_threats_graph = this.withOptions(
    this.gMap.panels.cyberTreads.graph,
    this.gMap.boards.dns
  );
  cyber_threats_pie = this.withOptions(
    this.gMap.panels.cyberTreads.pie,
    this.gMap.boards.ids
  );
  dns_threats_pie = this.withOptions(
    this.gMap.panels.cyberTreads.pie,
    this.gMap.boards.dns
  );
  cyber_threats_uniqueAlerts = this.withOptions(
    this.gMap.panels.cyberTreads.uniqueAlerts,
    this.gMap.boards.ids
  );
  cyber_threats_multiGraph = this.withOptions(
    this.gMap.panels.cyberTreads.multiGraph,
    this.gMap.boards.ids
  );
  dns_threats_multiGraph = this.withOptions(
    this.gMap.panels.cyberTreads.multiGraph,
    this.gMap.boards.dns
  );
  cyber_threats_protocols1 = this.withOptions(
    this.gMap.panels.cyberTreads.protocols1,
    this.gMap.boards.ids
  );
  cyber_threats_protocols2 = this.withOptions(
    this.gMap.panels.cyberTreads.protocols2,
    this.gMap.boards.ids
  );
  cyber_threats_sankey = this.withOptions(
    this.gMap.panels.cyberTreads.pie,
    this.gMap.boards.ids
  );

  tenant_events = this.withOptions(
    this.gMap.panels.tenant_events,
    this.gMap.boards.events
  );
  system_events = this.withOptions(
    this.gMap.panels.system_events,
    this.gMap.boards.events
  );
  cyber_threats_table = this.withOptions(
    this.gMap.panels.cyberTreads.table,
    this.gMap.boards.ids
  );
  dns_threats_table = this.withOptions(
    this.gMap.panels.cyberTreads.table,
    this.gMap.boards.dns
  );
}

function formParamsString(params: {
  [key: string]: string | number | undefined;
}): string {
  const extraFilters = params.extraFilters;
  delete params.extraFilters;
  const paramsArray = Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((param) => `${param}=${params[param]}`);

  const filtersField = extraFilters ? "&" + extraFilters : "";
  return `?${paramsArray.join("&")}${filtersField}`;
}

const grafanaLinksFactory = new GraphanaLinksFactory();

export default grafanaLinksFactory;
