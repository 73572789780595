import { configApi } from "../../helpers/api/ConfigApi";

import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { RequestStatus } from "../../helpers/types";
import { Layer3Interface } from "./types";
import { tenantApi } from "../../helpers/api/TenantApi";
import { tenantVirtualInterfacesApi } from "../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";

type RequestsState = CrudRequestStatuses & {
  portListStatus: RequestStatus;
};

type ValueState = CrudValueState<Layer3Interface>;

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<Layer3Interface> & {
  remove: (vi: Layer3Interface, tenant: string) => Promise<boolean>;
  updateVi: (vi: Partial<Layer3Interface>) => void;
};

export type ILayer3InterfaceContext = IState & IFunc;
const [
  Context,
  useLayer3InterfaceContext,
  withLayer3InterfaceContextProps,
] = createContextUtils<IState, IFunc>();

export { useLayer3InterfaceContext, withLayer3InterfaceContextProps };

type Props = IOneSystemContext;

class Layer3InterfaceContextContainer extends NewAbstractCrudContext<
  Layer3Interface,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (tenant?: string): Promise<void> => {
    if (!tenant) return;
    await this._fetchListWrap(
      () => tenantApi.getVirtualInterfacesAllTypes(tenant) as any
    );
  };

  remove = async (vi: any, tenant?: string): Promise<boolean> => {
    const isGate = vi.type === "Virtual Interface gate";
    if (!tenant) return false;
    const { ok } = await this._removeWrap(() =>
      isGate
        ? tenantVirtualInterfacesApi.deleteVirtualInterfaceGate(tenant, vi.name)
        : tenantApi.deleteVirtualInterfaces(tenant, vi.name)
    );
    return ok;
  };

  // todo not implemented
  edit = async (vlan: any): Promise<boolean> => {
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());

    return ok;
  };

  updateVi = (vi: Partial<Layer3Interface>) => {
    const viList = this.state.list;

    const updatedList = viList?.map((i) =>
      i.id === vi.id ? { ...i, ...vi } : i
    );

    this.setState({ list: updatedList });
  };

  funcs = {
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
    updateVi: this.updateVi,
  };
}

export default withOneSystemContextProps(Layer3InterfaceContextContainer);
