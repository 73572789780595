import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import PortIcon from "../../../components/icons/PortIcon";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { PortInterface } from "../types";

type SumProps = {
  title: string;
  data: Partial<PortInterface>;
};

const SummaryBlock: FC<SumProps> = ({ title, data }) => {
  return (
    <SummaryInfoBlock icon={<PortIcon />} title={title}>
      <UnifiedTextPair subtitle="Alias" text={data?.alias} />
      <UnifiedTextPair
        subtitle="Virtual Interface"
        text={data?.virtual_interface_name}
      />
    </SummaryInfoBlock>
  );
};

export default SummaryBlock;
