import React, { FC, useEffect, useMemo, useState } from "react";
import styles from "./StaticRoutesPage.module.scss";
import { withLayout } from "../../../helpers/hocs/withLayout";
import StaticRouteDialog from "../../dialogs/StaticRouteDialog";
import { staticTableHeader } from "../../common/table/TableHeaders/Systems/staticTableHeader";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import StaticRouteContext, {
  StRouteDeleteArgs,
  useStaticRouteContext,
} from "../../../contexts/systemsContext/StaticRouteContext";
import TableWrapper from "../../common/table/newTable/layout/TableWrapper";
import Table from "../../common/table/newTable/Table";
import TableHeader from "../../common/table/newTable/rows/TableHeader";
import useDeleteDialog from "../../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../dialogs/common/confirmDialog/ConfirmDeleteDialog";
import AddButton from "../../common/buttons/AddButton";
import { ActionFuncs } from "../../common/table/newTable/RowControls";
import { StaticRouteType } from "../../../helpers/api/apiTypes";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { PageProps } from "../../../helpers/types";
import { useVRFSelectorContext } from "../../../contexts/systemsContext/VRFSelectorContext";

const StaticRoutesPage: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    list,
    fetchList,
    listStatus,
    remove,
    removeStatus,
  } = useStaticRouteContext();
  const { selectedVRF } = useVRFSelectorContext();

  const [showAdd, setShowAdd] = useState(false);

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<StRouteDeleteArgs>(remove, removeStatus);

  useEffect(() => {
    if (selectedVRF) {
      fetchList(selectedVRF);
    }
  }, [selectedVRF]);

  const rowActions: ActionFuncs<StaticRouteType> = useMemo(
    () => ({
      onDelete: (rowOriginal) => {
        tryDelete(
          {
            ...rowOriginal,
          },
          selectedVRF
        );
      },
    }),
    [selectedVRF, tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Static route"}
        name={selectedToDelete?.destination}
      />
      {showAdd && (
        <StaticRouteDialog onClose={() => setShowAdd(false)} type={"add"} />
      )}
      <OneSystemLayout>
        <div className={styles.contentWrapper}>
          <Tabs />
          <TableWrapper
            titleProps={{
              title: "Static",
              children: <AddButton onClick={() => setShowAdd(true)} />,
            }}
            dataStatus={listStatus}
            tablePlaceholder={["Static Routes", "Static Route"]}
            isEmpty={list && list.length === 0}
          >
            <Table
              columns={staticTableHeader()}
              header={TableHeader}
              data={list || []}
              gridColumnTemplate={`repeat(6, 1fr) 0px`}
              rowActions={rowActions}
            />
          </TableWrapper>
        </div>
      </OneSystemLayout>
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(StaticRoutesPage, [StaticRouteContext])
);
