import React, { ReactElement } from "react";
import { PathEntry } from "../types";
import LayoutComponent from "../../components/common/LayoutComponent";

// to wrap component into basic page layout

type Props = {
  alternatives?: Array<PathEntry>;
};

export function withLayout<T>(
  WrappedComponent: React.ComponentType<Omit<T, "alternatives">>
) {
  return (props: T & Props): ReactElement => {
    const { alternatives, ...restProps } = props;

    return (
      <LayoutComponent alternatives={alternatives}>
        <WrappedComponent {...restProps} />
      </LayoutComponent>
    );
  };
}
