import React, { ReactElement, FC } from "react";
import { DialogControlsType, RequestStatus } from "../../../helpers/types";
import DialogBtmButtons from "./DialogBtmButtons";
import styles from "./DialogTemplate.module.scss";
import { classNames } from "../../../helpers/common/classNames";
import WarningMessage from "../../common/WarningMessage";

type DialogTemplateProps = {
  title?: string | ReactElement;
  leftColumn?: ReactElement;
  controls?: DialogControlsType;
  backButton?: React.ReactElement | null;
  errorDisplay?: RequestStatus;
  className?: string;
  onClose: () => void;
  warningMessage?: string;
  containerClassName?: string;
  withoutLine?: boolean;
};

const DialogTemplate: FC<DialogTemplateProps> = ({
  title,
  leftColumn,
  children,
  controls,
  backButton,
  className,
  errorDisplay,
  onClose = () => undefined,
  warningMessage,
  containerClassName,
  withoutLine,
}) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.dialogContainer,
          !controls && styles.dialogContainerFull,
          containerClassName
        )}
      >
        <div className={styles.leftColumn}>{leftColumn}</div>
        <div className={styles.content}>
          {title && (
            <div
              className={classNames(
                styles.title,
                withoutLine && styles.withoutLine
              )}
            >
              {title}
            </div>
          )}
          <div className={classNames(styles.contentWrapper, className)}>
            {children}
          </div>
          {warningMessage && <WarningMessage message={warningMessage} />}
        </div>
        {controls && (
          <DialogBtmButtons
            className={styles.dialogFooter}
            controls={controls}
            backButton={backButton}
            errorDisplay={errorDisplay}
          />
        )}
      </div>
      <div className={styles.overlay} onClick={() => onClose()} />
    </div>
  );
};

export default DialogTemplate;
