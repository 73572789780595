import { ReactElement } from "react";
import ArrowDown from "../components/icons/ArrowDown";
import ArrowUp from "../components/icons/ArrowUp";
import BooleanFalseIcon from "../components/icons/BooleanFalseIcon";
import BooleanTrueIcon from "../components/icons/BooleanTrueIcon";
import ErrDisableIcon from "../components/icons/ErrDisableIcon";
import ExternalIcon from "../components/icons/ExternalIcon";
import LocalIcon from "../components/icons/LocalIcon";
import CustomIcon from "../components/icons/packages/CustomIcon";
import EssentialsIcon from "../components/icons/packages/EssentialsIcon";
import PremiumIcon from "../components/icons/packages/PremiumIcon";
import ProIcon from "../components/icons/packages/ProIcon";
import StatusIcon from "../components/icons/StatusIcon";
import { OperationalStateType } from "./api/apiTypes";
import {
  BORDER_COLOR,
  GREEN,
  GREY_DISABLED,
  GREY_FONT,
  ORANGE,
  PINK,
} from "./common/colorAliases";
import ServicesIcon from "../components/icons/tabs/ServicesIcon";
import { SERVICES_PREFERENCES } from "./navigation/entries";
import OkCircleIcon from "../components/icons/OkCircleIcon";
import WarningCircleIcon from "../components/icons/WarningCircleIcon";

const getIconFromValue = (value?: string, color?: string): ReactElement => {
  switch (value) {
    case SERVICES_PREFERENCES().path:
      return <ServicesIcon color={color || GREY_DISABLED} />;
    case "up":
    case "ready":
      return <ArrowUp color={color || GREEN} />;
    case "link_down":
    case "degared":
      return <ArrowDown color={color || ORANGE} />;
    case "not_present":
      return <ErrDisableIcon color={color || GREY_DISABLED} />;
    case "err_disable":
    case "failed":
      return <ErrDisableIcon color={color || PINK} />;
    case "admin_down":
      return <ArrowDown color={color || GREY_DISABLED} />;
    case "local":
      return <LocalIcon />;
    case "external":
      return <ExternalIcon />;
    case "essentials":
      return <EssentialsIcon color={color || GREY_FONT} />;
    case "premium":
      return <PremiumIcon color={color || GREY_FONT} />;
    case "pro":
      return <ProIcon color={color || GREY_FONT} />;
    case "custom":
      return <CustomIcon color={color || GREY_FONT} />;
    default:
      return <StatusIcon color={color || GREY_FONT} />;
  }
};

export default getIconFromValue;

export const getHAIconFromValue = (value?: string): ReactElement => {
  switch (value) {
    case "ok":
    case "true":
      return <OkCircleIcon />;
    case "warning":
    case "degraded":
      return <WarningCircleIcon color={ORANGE} />;
    case "critical":
    case "down":
      return <WarningCircleIcon color={PINK} />;
    case "none":
      return <OkCircleIcon color={BORDER_COLOR} />;
    default:
      return <OkCircleIcon color={BORDER_COLOR} />;
  }
};

export const getOpStateIcon = (value?: OperationalStateType): ReactElement => {
  switch (value) {
    case "up":
      return <ArrowUp color={GREEN} />;
    case "link_down":
    case "down":
      return <ArrowDown color={ORANGE} />;
    case "not_present":
      return <ErrDisableIcon color={GREY_DISABLED} />;
    case "err_disable":
      return <ErrDisableIcon color={PINK} />;
    case "admin_down":
      return <ArrowDown color={GREY_DISABLED} />;
    default:
      return <StatusIcon color={GREY_FONT} />;
  }
};

export const getBooleanIcon = (value: boolean): ReactElement => {
  if (value) {
    return <BooleanTrueIcon />;
  }
  return <BooleanFalseIcon />;
};
