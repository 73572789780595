import { FC } from "react";
import Section from "../../../../../../../components/common/Section";
import Input from "../../../../../../../components/common/formComponents/Input";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import {
  ChangeField,
  FieldsType,
} from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { useSystemConfigContext } from "../../SystemConfigContext";
import { DropdownItem } from "../../../../../../../helpers/types";
import { useSystemProvisioningContext } from "../../../../ProvisioningContextContainer";

type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
};

const NEW_SYSTEM = "New System";

const SystemSection: FC<Props> = ({ className, fields, onChange, errors }) => {
  const { list = [] } = useSystemConfigContext();
  const { selectRow, selectedRow } = useSystemProvisioningContext();

  const handleChangeSystem = (value: DropdownItem) => {
    if (value.key !== NEW_SYSTEM) {
      selectRow(value.key);
      onChange("name", value.key);
    }
  };

  return (
    <Section title={"System"} columnsCount={2} className={className}>
      <DropdownBasic
        id={"configGeneralType"}
        onChange={(value) => handleChangeSystem(value)}
        selected={mapStringToItem(selectedRow ? fields.name : NEW_SYSTEM)}
        label="Type"
        placeholder=""
        itemsList={["New System", ...list].map((el) => ({
          key: el,
          value: el,
        }))}
        isMedium
        error={selectedRow && errors && errors["name"]}
      />
      {!selectedRow && (
        <Input
          label={"System Name"}
          name="name"
          placeholder=""
          value={fields.name}
          medium
          onChange={(e) => onChange("name", e.target.value)}
          error={errors && errors["name"]}
        />
      )}
    </Section>
  );
};
export default SystemSection;
