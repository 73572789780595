import React, { FC } from "react";
import InputWithList from "../../../../../../components/common/formComponents/InputWithList";

type Props = {
  list: Array<string>;
  onChange: (field: string, val: Array<string>) => void;
  error?: string;
};
const RemoteNetworks: FC<Props> = ({ list, onChange, error }) => {
  const handleAdd = (val: Array<string>) => {
    onChange("remoteNetworks", [...list, ...val]);
  };

  const handleRemove = (val: string) => {
    onChange(
      "remoteNetworks",
      list.filter((el) => el !== val)
    );
  };

  return (
    <InputWithList
      name={"remoteNetworks"}
      label="Remote Networks"
      placeholder="enter one value"
      onAdd={handleAdd}
      onRemove={handleRemove}
      isFullValue
      medium
      error={error}
      valuesList={list}
    />
  );
};

export default RemoteNetworks;
