import { ReactElement } from "react";
import { IconProps } from "../../../../../helpers/types";
import { FONT } from "../../../../../helpers/common/colorAliases";

function AllowSelectedIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2615 10C17.2615 14.0104 14.0104 17.2615 10 17.2615C5.98956 17.2615 2.73846 14.0104 2.73846 10C2.73846 5.98956 5.98956 2.73846 10 2.73846C11.7685 2.73846 13.3894 3.37067 14.6489 4.42143L15.1599 3.88626C13.767 2.70941 11.9663 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 9.37374 17.928 8.7643 17.7919 8.17947L17.1682 8.83264C17.2296 9.21269 17.2615 9.60261 17.2615 10Z"
        fill={color || FONT}
      />
      <path
        d="M14.6489 4.42143L14.4887 4.61339L14.6682 4.76315L14.8297 4.59408L14.6489 4.42143ZM15.1599 3.88626L15.3407 4.05892L15.5242 3.86676L15.3213 3.69529L15.1599 3.88626ZM17.7919 8.17947L18.0354 8.12281L17.9306 7.6723L17.6111 8.00682L17.7919 8.17947ZM17.1682 8.83264L16.9874 8.65998L16.9016 8.74985L16.9214 8.87252L17.1682 8.83264ZM10 17.5115C14.1485 17.5115 17.5115 14.1485 17.5115 10H17.0115C17.0115 13.8724 13.8724 17.0115 10 17.0115V17.5115ZM2.48846 10C2.48846 14.1485 5.85149 17.5115 10 17.5115V17.0115C6.12763 17.0115 2.98846 13.8724 2.98846 10H2.48846ZM10 2.48846C5.85149 2.48846 2.48846 5.85149 2.48846 10H2.98846C2.98846 6.12763 6.12763 2.98846 10 2.98846V2.48846ZM14.809 4.22946C13.5062 3.14261 11.8292 2.48846 10 2.48846V2.98846C11.7078 2.98846 13.2725 3.59874 14.4887 4.61339L14.809 4.22946ZM14.8297 4.59408L15.3407 4.05892L14.9791 3.7136L14.4681 4.24877L14.8297 4.59408ZM15.3213 3.69529C13.8849 2.48176 12.0276 1.75 10 1.75V2.25C11.9051 2.25 13.6491 2.93707 14.9986 4.07723L15.3213 3.69529ZM10 1.75C5.44365 1.75 1.75 5.44365 1.75 10H2.25C2.25 5.71979 5.71979 2.25 10 2.25V1.75ZM1.75 10C1.75 14.5563 5.44365 18.25 10 18.25V17.75C5.71979 17.75 2.25 14.2802 2.25 10H1.75ZM10 18.25C14.5563 18.25 18.25 14.5563 18.25 10H17.75C17.75 14.2802 14.2802 17.75 10 17.75V18.25ZM18.25 10C18.25 9.35453 18.1758 8.72608 18.0354 8.12281L17.5484 8.23614C17.6803 8.80252 17.75 9.39296 17.75 10H18.25ZM17.6111 8.00682L16.9874 8.65998L17.349 9.00529L17.9727 8.35213L17.6111 8.00682ZM17.5115 10C17.5115 9.58921 17.4785 9.18594 17.415 8.79275L16.9214 8.87252C16.9807 9.23943 17.0115 9.61602 17.0115 10H17.5115Z"
        fill={color || FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6426 4.80651C17.7916 4.94575 17.7995 5.1794 17.6602 5.32838L9.91357 13.617L6.04381 9.47653C5.90457 9.32755 5.91247 9.0939 6.06145 8.95466C6.21043 8.81542 6.44408 8.82331 6.58332 8.97229L9.91357 12.5355L17.1207 4.82415C17.26 4.67516 17.4936 4.66727 17.6426 4.80651Z"
        fill={color || FONT}
        stroke={color || FONT}
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default AllowSelectedIcon;
