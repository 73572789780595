import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function NetworkObservabilityIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Union"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.431714 5.26694C0.321102 5.34677 0.321102 5.51147 0.431714 5.5913L5.38464 9.16576C5.51691 9.26122 5.70168 9.16671 5.70168 9.00359V6.72765H14.5866C15.1572 6.72765 15.6198 6.26507 15.6198 5.69446C15.6198 5.12385 15.1572 4.66127 14.5866 4.66127H5.70168V1.85465C5.70168 1.69153 5.51691 1.59702 5.38464 1.69248L0.431714 5.26694Z"
        fill={color || FONT}
      />
      <path
        id="Union_2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5658 13.18C17.6764 13.2599 17.6764 13.4246 17.5658 13.5044L12.6128 17.0788C12.4806 17.1743 12.2958 17.0798 12.2958 16.9167V14.6177H3.42027C2.84966 14.6177 2.38708 14.1552 2.38708 13.5845C2.38708 13.0139 2.84966 12.5514 3.42027 12.5514H12.2958V9.76774C12.2958 9.60462 12.4806 9.5101 12.6128 9.60556L17.5658 13.18Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default NetworkObservabilityIcon;
