import React, { ReactElement } from "react";
import { FONT } from "../../../../helpers/common/colorAliases";
import { IconProps } from "../../../../helpers/types";

function CloudRouterIcon({
  color,
  x,
  y,
  width,
  height,
}: IconProps): ReactElement {
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        d="M2.57746 11.7535C2.6093 9.28244 4.45786 7.28315 6.72544 7.28315C7.237 7.28315 7.74447 7.38746 8.21917 7.5878C8.58449 6.37665 9.26869 5.28969 10.1836 4.48099C11.2641 3.52597 12.6257 3 14.0177 3C17.2945 3 19.9765 5.83826 20.115 9.38417C21.1579 9.72195 22.0967 10.4045 22.788 11.3348C23.5696 12.3865 24 13.7133 24 15.0707C24 18.3402 21.5672 21 18.5767 21H4.43179C1.98811 21 6.2076e-05 18.8264 0 16.1547C0 14.2626 1.02283 12.5382 2.57746 11.7535Z"
        fill={color || FONT}
      />
      <path
        d="M15.8484 10.125C15.8309 10.082 15.79 10.0539 15.7446 10.0539H14.5134V8.11497C14.5134 8.05151 14.463 8 14.401 8H12.6033C12.5412 8 12.4909 8.05151 12.4909 8.11497V10.0539H11.2549C11.2096 10.0539 11.1686 10.082 11.1511 10.1248C11.1338 10.1678 11.1433 10.2172 11.1754 10.2501L13.4158 12.1569C13.4369 12.1785 13.4655 12.1907 13.4953 12.1907C13.5252 12.1907 13.5538 12.1785 13.5749 12.1571L15.8242 10.2503C15.8563 10.2174 15.866 10.168 15.8484 10.125Z"
        fill="white"
      />
      <path
        d="M11.1516 16.8347C11.1691 16.8769 11.21 16.9044 11.2554 16.9044H12.4866V18.8873C12.4866 18.9495 12.537 19 12.599 19H14.3967C14.4588 19 14.5091 18.9495 14.5091 18.8873V16.9044H15.7451C15.7904 16.9044 15.8314 16.8769 15.8489 16.8349C15.8662 16.7928 15.8567 16.7443 15.8246 16.7121L13.5752 14.8425C13.554 14.8213 13.5255 14.8093 13.4956 14.8093C13.4657 14.8093 13.4372 14.8213 13.4161 14.8422L11.1758 16.7119C11.1437 16.7441 11.134 16.7926 11.1516 16.8347Z"
        fill="white"
      />
      <path
        d="M10.044 15.8504C10.0859 15.8329 10.1132 15.792 10.1132 15.7466V14.5153H12.0785C12.1403 14.5153 12.1904 14.465 12.1904 14.403V12.6052C12.1904 12.5432 12.1403 12.4929 12.0785 12.4929H10.1132V11.2569C10.1132 11.2115 10.0858 11.1706 10.0442 11.1531C10.0023 11.1358 9.9542 11.1452 9.92218 11.1773L8.03267 13.3422C8.01163 13.3633 7.99976 13.3919 7.99976 13.4218C7.99976 13.4516 8.01163 13.4802 8.03245 13.5013L9.92196 15.8261C9.95399 15.8583 10.0021 15.8679 10.044 15.8504Z"
        fill="white"
      />
      <path
        d="M16.9543 11.1535C16.9125 11.171 16.8851 11.2119 16.8851 11.2573V12.4886H14.9216C14.8598 12.4886 14.8096 12.5389 14.8096 12.6009V14.3987C14.8096 14.4607 14.8598 14.5111 14.9216 14.5111H16.8851V15.747C16.8851 15.7924 16.9125 15.8333 16.9541 15.8508C16.996 15.8681 17.0442 15.8587 17.0762 15.8266L18.9673 13.6608C18.9884 13.6396 19.0002 13.6111 19.0002 13.5812C19.0002 13.5513 18.9884 13.5228 18.9675 13.5017L17.0764 11.1778C17.0444 11.1456 16.9962 11.136 16.9543 11.1535Z"
        fill="white"
      />
    </svg>
  );
}

export default CloudRouterIcon;
