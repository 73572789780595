import styles from "./ConfigValue.module.scss";

type Props = {
  name: string;
  value: any;
};

export default function ConfigValue({ name, value }: Props) {
  return (
    <div className={styles.field} key={name}>
      <span className={styles.name}>{name}:</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
}
