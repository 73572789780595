import { FC, useMemo } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import { renderSectionByCloudOperator } from "../../common/cloudOperator";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import { CONNECTION_END_POINT_IDX } from "../../helpers/consts";
import DefaultConnectionFields from "../DefaultConnectionFields/DefaultConnectionFields";
import IpsecBranch from "../IpsecConnectionBranch/IpsecBranch";
import ContractSection from "./components/ContractSection";
import Phase1Section from "./components/Phase1Section";
import Phase2Section from "./components/Phase2Section";
import styles from "./IpsecConnection.module.scss";
import IpsecConnectionExtraCloudFields from "./IpsecConnectionExtraCloudFields";

type Props = {
  className: string;
};

const IpsecConnection: FC<Props> = ({ className }) => {
  const { fields } = useConnectionConfiguratorContext();

  const showCloudSection = useMemo(() => fields.endPoint === "cloud", [fields]);
  const showContractSection = useMemo(
    () => CONNECTION_END_POINT_IDX.includes(fields.endPoint),
    [fields]
  );

  const showBranchSettings = fields.endPoint === "branch";

  const sectionClassNames = classNames(className, styles.basicSection);
  return (
    <div className={styles.wrapper}>
      <DefaultConnectionFields>
        <IpsecConnectionExtraCloudFields />
      </DefaultConnectionFields>
      {showCloudSection && (
        <>
          {fields.isAdvanced ? (
            <>
              <Phase1Section className={sectionClassNames} />
              <Phase2Section className={sectionClassNames} />
            </>
          ) : (
            renderSectionByCloudOperator(
              fields.cloudOperator,
              sectionClassNames
            )
          )}
        </>
      )}
      {showBranchSettings && <IpsecBranch className={sectionClassNames} />}
      {showContractSection && <ContractSection className={sectionClassNames} />}
    </div>
  );
};

export default IpsecConnection;
