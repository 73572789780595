import { NotificationMessage } from "../../../../contexts/NotificationsContext";

export const getConnectionNotificationBody = (
  type: "pending" | "ok" | "error",
  errorMsg?: string,
  isEdit?: boolean
): NotificationMessage | undefined => {
  switch (type) {
    case "pending":
      return {
        state: "pending",
        title: "In Progress",
        details: "This might take up to a few minutes",
      };
    case "ok":
      return {
        state: "ok",
        title: isEdit
          ? "Connection was updated successfully"
          : "Connections creation was successful",
        details: "",
      };
    case "error":
      return {
        state: "error",
        title: isEdit
          ? "Failed to update connections"
          : "Failed to create connections",
        details: errorMsg || "",
      };
    default:
      return undefined;
  }
};
