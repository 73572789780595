import { CellProps } from "react-table";
import LabelArray from "../../../components/common/table/LabelArray";
import { VNET } from "../../WizardToolPage/types";
import IconBlankButton from "../../../components/common/buttons/IconBlankButton";
import AddIcon from "../../../components/icons/AddIcon";
import LocationIcon from "../../../components/icons/LocationIcon";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";

export const vnetTableHeader = (addConnection: (row: any) => void): any => {
  return [
    {
      Header: "Region",
      accessor: "region",
      Cell: ({ row: { original } }: any) => {
        return (
          <>
            <LocationIcon color={GREY_DISABLED} />
            {original.region}
          </>
        );
      },
    },
    {
      Header: "VNET name",
      accessor: "name",
    },
    {
      Header: "Subnets",
      accessor: "subnets",
      Cell: ({ row: { original } }: CellProps<VNET>) => {
        const subnets = original.address_space.address_prefixes;
        return <LabelArray values={subnets} />;
      },
    },
    {
      Header: "",
      accessor: "empty",
    },
    {
      Header: "Resource Group",
      accessor: "resource_group_name",
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }: CellProps<VNET>) => {
        const handleClick = () => {
          row.toggleRowExpanded();
          addConnection(row);
        };
        return (
          <IconBlankButton
            id={`actions-${row.id}`}
            icon={AddIcon}
            label={"Add connection"}
            onClick={handleClick}
          />
        );
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VNET,
  }));
};
