import React, { FC } from "react";
import {
  PACKAGES,
  PROVISIONING,
  TENANTS,
} from "../../../../helpers/navigation/entries";
import BasicTabs from "../BasicTabs/BasicTabs";

type Props = {
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
  defaultTenants?: Array<any>;
};

export const PackagesTab: FC<Props> = ({
  showTimeRange,
  noConfig,
  noReports,
  defaultTenants,
}) => {
  return (
    <BasicTabs
      showTimeRange={showTimeRange}
      noConfig={noConfig}
      noReports={noReports}
      tabs={[PACKAGES()]}
      root={[TENANTS()]}
      defaultTenants={defaultTenants}
    />
  );
};
