import AWSIcon from "../components/icons/AWSIcon";
import AzureIcon from "../components/icons/AzureIcon";
import BranchesIcon from "../components/icons/BranchesIcon";
import DataCentersIcon from "../components/icons/DataCentersIcon";
import GoogleCloudIcon from "../components/icons/GoogleCloudIcon";
import InternetIcon from "../components/icons/InternetIcon";
import {
  AWS,
  AZURE,
  BRANCHES,
  COKE_ONE,
  DC_COLO_EDGE,
  GOOGLE_CLOUD,
  INTERNET_GWS,
  PEPSI_TWO,
  RC_THREE,
  RED_BULL_FOUR,
} from "./common/constantsAlias";
import { getSystemBoxValuesJSON } from "./getSystemBoxValuesJSON";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getHardcodeSystemBoxValuesJSON(searchVal) {
  if (searchVal === COKE_ONE)
    return [
      // System 1
      [
        {
          name: GOOGLE_CLOUD,
          value: "1/2",
          icon: GoogleCloudIcon,
        },
      ],
      // System 2
      [
        {
          name: AWS,
          value: "0.5/1",
          icon: AWSIcon,
        },
      ],
      // System 3
      [
        {
          name: BRANCHES,
          value: "1/10",
          icon: BranchesIcon,
        },
      ],
      // System 5
      [
        {
          name: INTERNET_GWS,
          value: "3/10",
          icon: InternetIcon,
        },
      ],
    ];

  if (searchVal === PEPSI_TWO) {
    return [
      // System 1
      [
        {
          name: AWS,
          value: "2/2",
          icon: AWSIcon,
        },
      ],
      // System 3
      [
        {
          name: BRANCHES,
          value: "2/2",
          icon: BranchesIcon,
        },
      ],
      // System 6
      [
        {
          name: DC_COLO_EDGE,
          value: "2/5",
          icon: DataCentersIcon,
        },
      ],
    ];
  }

  if (searchVal === RC_THREE) {
    return [
      // System 1
      [
        {
          name: DC_COLO_EDGE,
          value: "1/1",
          icon: DataCentersIcon,
        },
        {
          name: AWS,
          value: "2/2",
          icon: AWSIcon,
        },
      ],
      // System 2
      [
        {
          name: GOOGLE_CLOUD,
          value: "1/2",
          icon: GoogleCloudIcon,
        },
      ],
      // System 3
      [
        {
          name: INTERNET_GWS,
          value: "1/1",
          icon: InternetIcon,
        },
      ],
      // System 4
      [
        {
          name: BRANCHES,
          value: "1/2",
          icon: BranchesIcon,
        },
      ],
      // System 5
      [
        {
          name: AZURE,
          value: "0.5/1",
          icon: AzureIcon,
        },
      ],
      // System 6
      [
        {
          name: AZURE,
          value: "0/1",
          icon: AzureIcon,
        },
      ],
    ];
  }

  if (searchVal === RED_BULL_FOUR) {
    return [
      // System 3
      [
        {
          name: AWS,
          value: "1/1",
          icon: AWSIcon,
        },
      ],
      // System 5
      [
        {
          name: BRANCHES,
          value: "1/1",
          icon: BranchesIcon,
        },
      ],
    ];
  }

  if (searchVal === AWS) {
    return [
      // System 1
      [
        {
          name: AWS,
          value: "3/10",
          icon: AWSIcon,
        },
      ],
      // System 2
      [
        {
          name: AWS,
          value: "4/11",
          icon: AWSIcon,
        },
      ],
      // System 3
      [
        {
          name: AWS,
          value: "7/10",
          icon: AWSIcon,
        },
      ],
      // System 4
      [
        {
          name: AWS,
          value: "4/11",
          icon: AWSIcon,
        },
      ],
      // System 5
      [
        {
          name: AWS,
          value: "6/10",
          icon: AWSIcon,
        },
      ],
      // System 6
      [
        {
          name: AWS,
          value: "6/10",
          icon: AWSIcon,
        },
      ],
      // System 7
      [
        {
          name: AWS,
          value: "15/20",
          icon: AWSIcon,
        },
      ],
      // System 8
      [
        {
          name: AWS,
          value: "15/20",
          icon: AWSIcon,
        },
      ],
    ];
  }

  if (searchVal === AZURE) {
    return [
      // System 1
      [
        {
          name: AZURE,
          value: "1/6",
          icon: AzureIcon,
        },
      ],
      // System 2
      [
        {
          name: AZURE,
          value: "2/6",
          icon: AzureIcon,
        },
      ],
      // System 3
      [
        {
          name: AZURE,
          value: "5/5",
          icon: AzureIcon,
        },
      ],
      // System 4
      [
        {
          name: AZURE,
          value: "3/7",
          icon: AzureIcon,
        },
      ],
      // System 5
      [
        {
          name: AZURE,
          value: "4/6",
          icon: AzureIcon,
        },
      ],
      // System 6
      [
        {
          name: AZURE,
          value: "4/6",
          icon: AzureIcon,
        },
      ],
      // System 7
      [
        {
          name: AZURE,
          value: "15/20",
          icon: AzureIcon,
        },
      ],
      // System 8
      [
        {
          name: AZURE,
          value: "15/20",
          icon: AzureIcon,
        },
      ],
    ];
  }

  if (searchVal === GOOGLE_CLOUD) {
    return [
      // System 1
      [
        {
          name: GOOGLE_CLOUD,
          value: "0/10",
          icon: GoogleCloudIcon,
        },
      ],
      // System 2
      [
        {
          name: GOOGLE_CLOUD,
          value: "1/10",
          icon: GoogleCloudIcon,
        },
      ],
      // System 3
      [
        {
          name: GOOGLE_CLOUD,
          value: "10/10",
          icon: GoogleCloudIcon,
        },
      ],
      // System 4
      [
        {
          name: GOOGLE_CLOUD,
          value: "2/5",
          icon: GoogleCloudIcon,
        },
      ],
      // System 5
      [
        {
          name: GOOGLE_CLOUD,
          value: "8/10",
          icon: GoogleCloudIcon,
        },
      ],
      // System 6
      [
        {
          name: GOOGLE_CLOUD,
          value: "8/10",
          icon: GoogleCloudIcon,
        },
      ],
      // System 7
      [
        {
          name: GOOGLE_CLOUD,
          value: "15/20",
          icon: GoogleCloudIcon,
        },
      ],
      // System 8
      [
        {
          name: GOOGLE_CLOUD,
          value: "15/20",
          icon: GoogleCloudIcon,
        },
      ],
    ];
  }
  return getSystemBoxValuesJSON();
}
