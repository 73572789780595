import React, { FC } from "react";
import DestinationSymbolIcon from "../../../../components/icons/DestinationSymbolIcon";
import SourceSymbolIcon from "../../../../components/icons/SourceSymbolIcon";
import styles from "./MatrixCells.module.scss";

type HeaderProps = {
  value?: string;
};

export const MatrixHeader: FC<HeaderProps> = ({ value }) => {
  return <div className={styles.matrixCellHeader}>{value}</div>;
};

type CellProps = {
  isActive: boolean;
};

export const MatrixCellTemplate: FC<CellProps> = ({ isActive = false }) => {
  return <div>{isActive}</div>;
};

export const MatrixCorner: FC = () => {
  return (
    <div className={styles.corner}>
      <div className={styles.cornerDST}>
        <DestinationSymbolIcon /> DST
      </div>
      <div className={styles.cornerSRC}>
        <SourceSymbolIcon />
        SRC
      </div>
      <div className={styles.cornerLine}></div>
    </div>
  );
};
