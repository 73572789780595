import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ReleaseNotesIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9503 0.799805L16.6676 4.49169L11.9503 8.18339V5.92738C7.99061 5.92738 4.77178 9.14621 4.77178 13.1059C4.77178 15.0268 5.52337 16.8322 6.8801 18.1897L4.85577 20.2226C2.94917 18.3218 1.90039 15.7949 1.90039 13.1059C1.90039 7.56479 6.4092 3.05599 11.9503 3.05599V0.799805ZM17.0205 8.02181L19.0448 5.98886C20.9514 7.88972 22.0002 10.4165 22.0002 13.1056C22.0002 18.6467 17.4914 23.1555 11.9503 23.1555V25.4116L7.24941 21.7198L11.9503 18.028V20.2841C15.9099 20.2841 19.1288 17.0653 19.1288 13.1056C19.1288 11.1846 18.3772 9.37926 17.0205 8.02181Z"
        fill={color || FONT}
      />
      <rect x="9" y="10" width="6" height="2" fill={color || FONT} />
      <rect x="9" y="14" width="6" height="2" fill={color || FONT} />
    </svg>
  );
}

export default ReleaseNotesIcon;
