import { QuotaNames } from "../../../../helpers/api/apiTypes";

export const SECTIONS_MAP = [
  {
    key: "networkingFields",
    title: "Networking services",
  },
  {
    key: "securityFields",
    title: "Security",
  },
  {
    key: "managmentFields",
    title: "Management",
  },
];

export const EXISTED_VALUES_MAP: { [key: string]: string } = {
  fw_rules: "FWaaS",
  nat_rules: "Public network access",
  maximum_bgp_neighbors: "Cloud router",
};

export const TOOLTIPS_MAP: { [key: string]: string } = {
  "Site to Site connectivity": "Same tenant multi box",
  "Private DNS": "DNS server per tenant",
  maximum_bgp_neighbors: "Number of BGP neighbors. Routes per BGP neighbor",
  vRouter: "Total # of routes",
  nat_rules: "NAT rules",
  "Load Balancing service": "Load Balancing service",
  "Load Balancer front-end IPs": "Virtual IPs",
  "Load Balancer back-end IPs": "Servers",
  fw_rules: "FW",
  "Secure Web Gateway": "SWG Malicious sites",
  "Web Based Filtering": "SWG Filtering",
  "Threat Visibility": "IDS",
  "Threat Blocking": "IPS",
  "DNS Filtering": "DNS Malicious + filtering",
  "DDoS Protection": "Denial of service prevention",
  "User Portal": "NIRO Web GUI",
  "API Access": "REST API",
  "SIEM integration": "Statistics feed",
};

export const QUOTAS_NAMES_MAP: Array<Partial<QuotaNames>> = [
  "Essentials",
  "Premium",
  "Pro",
];

export const VI_IPSEC_GATEWAY = "Virtual Interface IPSEC gateway";
export const VI_IPSEC = "Virtual Interface IPSEC";
export const VI_NAT = "Virtual Interface NAT";

export const VI_GATE = "Virtual Interface gate";
