import React from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

const AwardIcon = ({
  width = 24,
  height = 24,
  color = GREY_FONT,
}: IconProps): React.ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_21604_281851)">
        <path
          d="M11.7264 0.5C11.5075 0.5 11.2886 0.609524 11.1791 0.719048L9.64677 2.14286L7.89552 1.81429C7.34826 1.70476 7.0199 2.03333 6.91045 2.47143L6.58209 4.44286L4.72139 5.31905C4.28358 5.5381 4.17413 5.97619 4.39303 6.41429L5.26866 8.16667L4.39303 9.91905C4.17413 10.3571 4.39303 10.7952 4.72139 11.0143L6.58209 11.8905L6.91045 13.8619C7.0199 14.3 7.45771 14.6286 7.89552 14.519L9.97512 14.1905L11.5075 15.6143C11.8358 15.9429 12.3831 15.9429 12.7114 15.6143L14.2438 14.1905L16.3234 14.519C16.7612 14.6286 17.199 14.3 17.3085 13.8619L17.6368 11.8905L19.4975 11.0143C19.9353 10.7952 20.0448 10.3571 19.8259 9.91905L18.6219 8.16667L19.4975 6.41429C19.7164 5.97619 19.607 5.5381 19.1692 5.31905L17.3085 4.44286L16.9801 2.47143C16.8706 2.03333 16.4328 1.70476 15.995 1.81429L13.9154 2.14286L12.3831 0.719048C12.2736 0.5 11.9453 0.5 11.7264 0.5ZM4.39303 13.7524L1 20.5429L4.9403 19.9952L6.91045 23.5L9.75622 17.6952L9.53731 17.4762L8.44279 16.4905L6.91045 16.7095C5.92537 16.819 4.9403 16.1619 4.72139 15.1762L4.39303 13.7524ZM19.4975 13.7524L19.1692 15.1762C18.9502 16.0524 18.1841 16.7095 17.3085 16.7095H17.0896L15.5572 16.4905L14.4627 17.4762L14.2438 17.6952L17.0896 23.5L19.0597 20.1048L23 20.6524L19.4975 13.7524Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_21604_281851">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AwardIcon;
