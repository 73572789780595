import * as pathEntries from "./entries";
import { Location } from "history";
import { PathEntry } from "../types";
import { CONFIGURATION, SERVICES } from "./entries";

export function isActivePath(location: Location, path: string): boolean {
  if (!location) {
    return false;
  }
  return location.pathname.includes(path.split("?")[0]);
}

export function formAlternatives(
  arr: Array<PathEntry>,
  path: string
): Array<PathEntry> {
  return arr.map((entry) => ({ ...entry, path: addPrefix(entry, path) }));
}

// create full pathname from array of PathEntries
export function formPathFromArray(arr: Array<PathEntry>): string {
  const path = "/" + arr.map((entry) => entry.path).join("/");
  return path.replace("//", "/");
}

export function addPrefix(entry: PathEntry, prefix: string): string {
  return prefix + "/" + entry.path;
}

// split into segments
export function getSegments(url: string): Array<string> {
  return url.split("/").filter((f) => f);
}

// replace last element with alternatives
export function addAlternatives(
  arr: Array<PathEntry>,
  alternatives?: Array<PathEntry>
): Array<PathEntry | Array<PathEntry>> {
  const copy: Array<PathEntry | Array<PathEntry>> = [...arr];
  if (alternatives) {
    copy.pop();
    copy.push(alternatives);
  }
  return copy;
}

// parse pathname into PathEntry array
export function getPathArrayFromPath(path: string): Array<PathEntry> {
  const segments = getSegments(path);
  const arr: Array<PathEntry> = [];
  segments.reduce((prefix, seg) => {
    const [name, id] = parseURLSegment(seg);
    const fullPath = prefix + "/" + seg;
    if (segmentsMap[name]) {
      const entry = segmentsMap[name](id);
      entry.path = fullPath;
      arr.push(entry);
    }

    return fullPath;
  }, "");

  return arr;
}

// extract id from url if exists
export function parseURLSegment(seg: string): Array<string> {
  return seg.split("_");
}

// form segments map for parsing URL
type SegmentsMap = { [key: string]: (id?: any) => PathEntry };
const segmentsMap: SegmentsMap = (() => {
  const map: SegmentsMap = {};
  Object.values(pathEntries).forEach((entry: (id?: any) => PathEntry) => {
    map[entry().path] = entry;
  });

  return map;
})();

export const servicesConfiguration = (entry: PathEntry): string => {
  return formPathFromArray([SERVICES(), CONFIGURATION(), entry]);
};

export const servicesReporting = (entry: PathEntry): string => {
  return formPathFromArray([SERVICES(), pathEntries.REPORTS(), entry]);
};

export const systemReporting = (entry: PathEntry, sysId: string): string => {
  return formPathFromArray([
    pathEntries.SYSTEMS(),
    pathEntries.SYSTEM(sysId),
    pathEntries.REPORTS(),
    entry,
  ]);
};

export const ACTIVE_NAT_QUERY_PARAM = "activeNat";
export const ACTIVE_FW_QUERY_PARAM = "activeFwRule";
