import { createContextAndUse } from "../../contexts/common/utils";
import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { IUserContext, withUserContextProps } from "../../contexts/UserContext";

type RequestStatuses = {};

type State = {
  stepNumber: number;
};

type IState = State & RequestStatuses;
type IFunc = {
  setStepNumber: (val: number) => void;
};

const [OnboardingListContext, useOnboardingContext] = createContextAndUse<
  IState,
  IFunc
>();
export { useOnboardingContext };

class OnboardingContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = OnboardingListContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      stepNumber: 0,
    };
  }

  setStepNumber = (stepNumber: number): void => {
    this.setState({ stepNumber });
  };

  funcs = {
    setStepNumber: this.setStepNumber,
  };
}

export default withUserContextProps<any>(OnboardingContextContainer);
