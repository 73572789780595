import React, { ReactNode } from "react";
import { CloseTutorialButton } from "./CloseTutorialButton";

import styles from "./Tutorial.module.scss";
import { useTutorialContext } from "../../contexts/TutorialContext";

type Props = {
  children: ReactNode;
  visible?: boolean;
};

const Tutorial = () => {
  const { link } = useTutorialContext();
  return (
    <div className={styles.tutorial}>
      <div className={styles.frame}>
        <CloseTutorialButton />
        <iframe src={link} className={styles.preview} />
      </div>
    </div>
  );
};

const TutorialWrapper = ({ children, visible }: Props) => {
  if (!visible) {
    return <>{children}</>;
  }

  return (
    <div className={styles.tutorialFrame}>
      <Tutorial />
      {children}
    </div>
  );
};

export default TutorialWrapper;
