/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Res } from "../../helpers/api/Api";
import {
  FilterAndSort,
  VirtualInterfaceType,
} from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { tenantApi } from "../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { Layer3Interface } from "../../pages/Layer3Interface/types";
import { VTEPType } from "../../pages/VTEPPage/types";
import AbstractCrudContextContainer, {
  createContextAndUse,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import { PortType } from "../../pages/PortsPage/types";

type IState = CrudState<Layer3Interface, Layer3Interface> & {
  membersList: Array<any>;
  membersStatus?: RequestStatus;
  domainsList: Array<string>;
  editThresholdStatus?: RequestStatus;
  additionalList?: Array<PortType | VTEPType>;
  viListPerTenant: Array<Layer3Interface>;
  addInterfaceStatus?: RequestStatus;
};

type IFunc = {
  fetchMembersList: (tenant?: string) => void;
  editThreshold: (
    type: string,
    system: string,
    fields: any
  ) => Promise<Res<boolean>>;
  addInterface: (type: string, system: string, fields: any) => Promise<boolean>;
  fetchAdditionalList: (l2Type: string, system: string) => Promise<void>;
  fetchViListPerTenant: (tenant: string) => Promise<void>;
};

const [Context, useContext] = createContextAndUse<
  IState,
  IFunc & CrudFunc<Layer3Interface, Layer3Interface>
>();

export const useTenantVirtualInterfaceContext = useContext;

type Props = React.PropsWithChildren<{ tenant: string }>;

class TenantVirtualInterfaceContextContainer extends AbstractCrudContextContainer<
  Layer3Interface,
  Layer3Interface,
  IState,
  Layer3Interface,
  Props
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      fetchMembersList: this.fetchMembersList,
      editThreshold: this.editThreshold,
      addInterface: this.addInterface,
      fetchAdditionalList: this.fetchAdditionalList,
      fetchViListPerTenant: this.fetchViListPerTenant,
    };
  }

  listReq = async (params?: FilterAndSort) => {
    return tenantApi.getVirtualInterfaces(
      this.props.tenant,
      params?.sort
    ) as any;
  };

  selectedReq = async (vi_name: string | number) => {
    return tenantApi.getVirtualInterface(this.props.tenant, vi_name as string);
  };

  addReq = async (params: any) => {
    return tenantApi.addVirtualInterfaces(params, this.props.tenant);
  };

  editReq = async (params: any, vi_name: string | number) => {
    return tenantApi.editVirtualInterfaces(
      this.props.tenant,
      vi_name as string,
      params
    );
  };

  removeReq = async (data: Layer3Interface) => {
    return tenantApi.deleteVirtualInterfaces(this.props.tenant, data?.name);
  };

  fetchViListPerTenant = async (tenant: string) => {
    const { ok, result } = await tenantApi.getVirtualInterfaces(tenant);

    if (ok) {
      this.setState({ viListPerTenant: result || [] });
    }
  };

  fetchVirtInterfaces = async (domains: Array<string>, tenant: string) => {
    const { result: res } = await configApi.getAllVirtualInterfacesByTenant(
      tenant
    );

    const output = domains.map((system) => ({
      domain: system,
      result: res?.filter((r) => r.system_name === system && r.members) || [],
      ok: true,
    }));

    return output as Array<any>;
  };

  getMembersList = (
    viList: Array<VirtualInterfaceType>,
    domain: string
  ): Array<any> => {
    const members: any = [];

    viList?.map((vi: VirtualInterfaceType) => {
      return vi.members.map((member) => {
        return member.interfaces.map((int: any) => {
          const body = {
            ...int,
            domain,
            virtual_interface_name: int.virtual_interface_name || vi.name,
            int_type: member.interface_type,
          };
          members.push(body);
        });
      });
    });

    return members;
  };

  fetchMembersList = async (tenant?: string) => {
    this.setState({ membersStatus: setPending("Fetching") });
    const resTenant = await tenantApi.getTenant(tenant || this.props.tenant);
    if (resTenant.ok && resTenant.result) {
      const { systems, name } = resTenant.result;
      const resVi = await this.fetchVirtInterfaces(systems, name);

      if (resVi.every((item) => item.ok)) {
        const list: Array<any> = [];
        resVi.map((res) => {
          const domain = res.domain;
          const viList = res.result;

          list.push(this.getMembersList(viList, domain));
        });
        this.setState({ membersList: list.flat(), domainsList: systems });
      }
      const endRes = resVi.find((item) => item.ok) || resVi[0];
      this.setState({ membersStatus: resToState(endRes) });
    }
    this.setState({ membersStatus: resToState(resTenant) });
  };

  fetchAdditionalList = async (
    l2Type: string,
    system: string
  ): Promise<void> => {
    let res;
    switch (l2Type) {
      case "vtep_vni":
        res = await configApi.getVTEPList(system);
        break;
      case "port_interface":
      case "port_vlan":
      default:
        res = await configApi.getPortsList(system);
        break;
    }
    if (res?.ok && res?.result) this.setState({ additionalList: res.result });
  };

  editThreshold = async (
    l2type: string,
    system: string,
    fields: any
  ): Promise<Res<boolean>> => {
    this.setState({ editThresholdStatus: setPending("Fetching") });
    let res;
    switch (l2type) {
      case "port_vlan":
        res = await configApi.editPortVLANThreshold(system, fields.alias, {
          max_bandwidth: fields.max_bandwidth,
        });
        break;
      case "vtep_vni":
        res = await configApi.editVNIThreshold(system, fields.alias, {
          max_bandwidth: fields.max_bandwidth,
        });
        break;
      case "port_interface":
      default:
        res = await configApi.editPortInterfaceThreshold(system, fields.alias, {
          max_bandwidth: fields.max_bandwidth,
        });
        break;
    }

    if (res) {
      this.setState({ editThresholdStatus: resToState(res) });
      this.fetchMembersList();
    }
    return res.ok;
  };

  addInterface = async (
    l2type: string,
    system: string,
    fields: any
  ): Promise<boolean> => {
    this.setState({ addInterfaceStatus: setPending("Fetching") });
    let res;
    switch (l2type) {
      case "port_vlan":
        res = await configApi.addPortVLAN(system, fields);
        break;
      case "vtep_vni":
        res = await configApi.addVNI(system, fields);
        break;
      case "port_interface":
      default:
        res = await configApi.addPortInterface(system, fields);
        break;
    }

    if (res) {
      this.setState({ addInterfaceStatus: resToState(res) });
      this.fetchMembersList();
    }
    return !!res?.ok;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.funcs,
          ...this.baseFuncs,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default TenantVirtualInterfaceContextContainer;
