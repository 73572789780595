import { FC } from "react";

const UnavailableBg: FC = () => {
  return (
    <svg
      x={0}
      y={0}
      width="60"
      height="30"
      viewBox="0 0 60 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1833_152"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="30"
      >
        <rect
          x="0.5"
          y="1.13672"
          width="59"
          height="28"
          rx="1.5"
          fill="#EFE9FB"
          stroke="#E2E2E8"
        />
      </mask>
      <g mask="url(#mask0_1833_152)">
        <path d="M33.8447 -30.9336L-5.66883 25.8525" stroke="#E2E2E8" />
        <path d="M37.1377 -26.1992L-2.37586 30.5869" stroke="#E2E2E8" />
        <path d="M40.4307 -21.4688L0.917112 35.3174" stroke="#E2E2E8" />
        <path d="M43.7236 -16.7383L4.21008 40.0478" stroke="#E2E2E8" />
        <path d="M47.0166 -12.0039L7.50305 44.7822" stroke="#E2E2E8" />
        <path d="M50.3086 -7.27344L10.795 49.5127" stroke="#E2E2E8" />
        <path d="M53.6016 -2.53906L14.088 54.2471" stroke="#E2E2E8" />
        <path d="M58.917 -0.367188L19.4034 56.4189" stroke="#E2E2E8" />
        <path d="M60.1875 6.92578L20.6739 63.7119" stroke="#E2E2E8" />
        <path d="M65.9023 9.76172L26.3888 66.5478" stroke="#E2E2E8" />
        <path d="M30.5518 -35.6641L-8.9618 21.1221" stroke="#E2E2E8" />
      </g>
    </svg>
  );
};

export default UnavailableBg;
