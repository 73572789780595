import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function ProIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.82248L3.16438 14.2778H17.8356L19 4.82248L14.1678 8.77395L10.5 2L6.83219 8.77395L2 4.82248Z"
        stroke={color || FONT}
        strokeWidth="1.8"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="16.168"
        width="17"
        height="1.88889"
        rx="0.944444"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ProIcon;
