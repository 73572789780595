import React, { FC } from "react";
import Button from "../../../../../../../components/common/buttons/Button";
import { isPending } from "../../../../../../../helpers/common/RequestStateHelpers";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import styles from "./FormFooter.module.scss";

type Props = {
  onAdd: () => void;
  disabled?: { onAdd?: boolean; onCancel?: boolean };
};

const FormFooter: FC<Props> = ({ onAdd, disabled }) => {
  const {
    setActiveConnectionType,
    addConnectionStatus,
    activeConnection,
  } = useConnectionsContext();

  const handleCancel = () => {
    setActiveConnectionType(undefined);
  };

  return (
    <div className={styles.wrapper}>
      <Button onClick={handleCancel} disabled={disabled?.onCancel}>
        Cancel
      </Button>
      <Button
        onClick={onAdd}
        isPrimaryBtn
        disabled={
          isPending(addConnectionStatus) ||
          disabled?.onAdd ||
          Boolean(activeConnection)
        }
      >
        {activeConnection ? "Edit" : "Add"}
      </Button>
    </div>
  );
};
export default FormFooter;
