import React from "react";
import styles from "./NoDataPlaceholder.module.scss";
import TablePlaceholder from "../../icons/TablePlaceholder";
import { classNames } from "../../../helpers/common/classNames";
import NoGraphData from "../../icons/NoGraphData";
import { capitalizeFirstLetter } from "../../../helpers/common/stringHelpers";

export default function NoDataPlaceholder({
  isError,
  isGraph,
  title,
  subTitle,
}: {
  isGraph?: boolean;
  isError?: boolean;
  title?: string;
  subTitle?: string;
}): React.ReactElement {
  return (
    <div className={classNames(styles.container, isError && styles.error)}>
      {isGraph ? (
        <NoGraphData isError={isError} />
      ) : (
        <TablePlaceholder isError={isError} />
      )}
      {title && (
        <div className={styles.title}>{capitalizeFirstLetter(title)}</div>
      )}
      {subTitle && (
        <div className={styles.subTitle}>{capitalizeFirstLetter(subTitle)}</div>
      )}
    </div>
  );
}
