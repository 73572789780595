import React, { FC, useEffect, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table, {
  DEFAUTL_OFFSET,
  DEFAUTL_LIMIT,
} from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { withContexts } from "../../helpers/hocs/withContexts";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import TableControls from "../TenantsPage/table/TableControls";
import { diaTableHeader } from "./table/diaTableHeader";
import ConnectionsContextContainer, {
  useConnectionsContext,
} from "../WizardToolPage/ConnectionStep/ConnectionsContext";
import { DIADialog } from "./DIADialog";

const DIATable: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchDIAList, diaList, diaListStatus } = useConnectionsContext();
  const [showAdd, setShowAdd] = useState(false);
  useEffect(() => {
    selectedTenant && fetchDIAList(selectedTenant);
  }, [selectedTenant]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    selectedTenant && fetchDIAList(selectedTenant, offset, limit);
  };

  if (showAdd) {
    return (
      <DIADialog
        onClose={() => setShowAdd(false)}
        onAdd={() => setShowAdd(false)}
        onEdit={() => setShowAdd(false)}
      />
    );
  }

  return (
    <>
      <TableWrapper
        titleProps={{
          title: `Connections`,
          children: <TableControls onAdd={() => setShowAdd(true)} noSearch />,
        }}
        dataStatus={diaListStatus}
        tablePlaceholder={["Connection", "Connections"]}
        isEmpty={diaList && diaList.length === 0}
      >
        <Table
          fetchAction={fetchWrapper}
          data={diaList || []}
          count={diaList?.length || 0}
          columns={diaTableHeader()}
          header={TableHeader}
          gridColumnTemplate="150px 150px 200px 200px 200px"
        />
      </TableWrapper>
    </>
  );
};

export default withContexts<any>(DIATable, [ConnectionsContextContainer]);
