import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function GlobeIcon(props: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      {...props}
    >
      <path
        fill={props.color || FONT}
        d="M10.666 2.093c-4.46 0-8.107 3.648-8.107 8.107s3.648 8.107 8.107 8.107c4.458 0 8.107-3.648 8.107-8.107 0-4.46-3.649-8.107-8.107-8.107zm5.593 4.864h-2.35c-.244-1.054-.65-1.946-1.136-2.919 1.46.568 2.757 1.54 3.486 2.919zm-5.593-3.243c.648.973 1.216 2.027 1.54 3.243h-3.08c.324-1.135.891-2.27 1.54-3.243zm-6.243 8.107A5.227 5.227 0 014.18 10.2c0-.568.081-1.135.243-1.622H7.18c-.082.568-.082 1.054-.082 1.622 0 .567.082 1.054.082 1.621H4.423zm.649 1.622h2.35c.244 1.053.65 1.945 1.136 2.918-1.46-.567-2.757-1.54-3.486-2.918zm2.35-6.486h-2.35c.81-1.378 2.026-2.351 3.486-2.919-.487.973-.892 1.865-1.135 2.919zm3.244 9.728c-.649-.972-1.216-2.026-1.54-3.242h3.08c-.324 1.135-.892 2.27-1.54 3.242zm1.864-4.864H8.801c-.081-.567-.162-1.054-.162-1.621 0-.568.08-1.054.162-1.622h3.81c.081.568.162 1.054.162 1.622 0 .567-.162 1.054-.243 1.621zm.243 4.54c.487-.892.892-1.865 1.135-2.918h2.351c-.73 1.378-2.026 2.35-3.486 2.918zm1.46-4.54c.08-.567.08-1.054.08-1.621 0-.568-.08-1.054-.08-1.622h2.756c.162.487.243 1.054.243 1.622 0 .567-.08 1.135-.243 1.621h-2.756z"
      />
    </svg>
  );
}

export default GlobeIcon;
