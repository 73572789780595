import { FC } from "react";
import BgpStatistics from "./components/BgpStatistics";
import EventsTable from "../../../EventsPage/EventsTable";
import styles from "./ServicesBGPStat.module.scss";

type Props = {
  handledStatus: any;
  selectedNeighbor?: string;
};

const ServiceBGPStat: FC<Props> = ({ handledStatus, selectedNeighbor }) => {
  return (
    <div className={styles.contentWrapper}>
      <BgpStatistics
        handledStatus={handledStatus}
        selectedNeighbor={selectedNeighbor}
      />
      <EventsTable isCollapsible type="bgp" className={styles.eventsTable} />
    </div>
  );
};
export default ServiceBGPStat;
