import { CloudFields } from "../../pages/WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/formConsts";
import Validator from "./Validator";

export default function validateCloud(
  data: CloudFields,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.connection_name, "name");
  validator.checkEmpty(data.vpc_id, "vpc_id");
  return validator;
}
