import React, { FC, ReactElement, useEffect, useRef, useState } from "react";
import { select, zoom } from "d3";
import LocationPoint from "./common/LocationPoint";
import { LocationsList } from "../types";
import ReactTooltip from "react-tooltip";

const HEIGHT = 500;
const WIDTH = 1000;

type Props = {
  locations: Array<any>;
  toggleLocation: (location: LocationsList) => void;
  selectedLocations: Array<LocationsList>;
  setTooltip?: (loc: LocationsList | undefined) => void;
  tooltipContent?: ReactElement;
  tooltipClassName?: string;
};

const MapView: FC<Props> = ({
  locations,
  toggleLocation,
  selectedLocations,
  setTooltip,
  tooltipContent,
  tooltipClassName,
}) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [scale, setScale] = useState<number>(1);

  useEffect(() => {
    if (!svgRef.current) return;
    const svg = select(svgRef.current);
    const zoomBehaviour = zoom<SVGSVGElement, unknown>().on("zoom", (e) => {
      setScale(e.transform.k);
      svg.select("g").attr("transform", e.transform.toString());
    });
    svg?.call(zoomBehaviour);
  }, [setScale]);

  // TOOLTIP
  const tooltipRef = useRef(null);
  useEffect(() => {
    if (tooltipRef.current) {
      ReactTooltip.rebuild();
    }
  }, [tooltipContent]);

  return (
    <>
      <svg
        viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
        width="100%"
        height="100%"
        ref={svgRef}
        data-tip
        data-for="locationInfo"
      >
        <g>
          <image href="/assets/worldMap_x4.png" height={HEIGHT} width={WIDTH} />
          {locations.map((loc) => {
            return (
              <LocationPoint
                key={loc.name}
                location={loc}
                scale={scale}
                toggleLocation={toggleLocation}
                selectedLocations={selectedLocations}
              />
            );
          })}
        </g>
      </svg>
      {tooltipContent && (
        <ReactTooltip
          ref={tooltipRef}
          id={"locationInfo"}
          aria-haspopup="true"
          place="bottom"
          type="light"
          effect="float"
          arrowColor="transparent"
          isCapture={true}
          globalEventOff="click"
          clickable={true}
          className={tooltipClassName}
        >
          {tooltipContent}
        </ReactTooltip>
      )}
    </>
  );
};

export default MapView;
