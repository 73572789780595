import React from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { IconProps } from "../../../helpers/types";

export default function IpsecIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...formIconAttrs(34, 34, props)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 9.26438V13.9565V14H22.9919V13.9565V9.26087V9.21955L22.9949 9.18296L22.995 9.1818L22.9952 9.17896C22.9956 9.1735 22.9962 9.16254 22.9969 9.14646C22.9983 9.11424 23 9.06173 23 8.99185C23 8.85167 22.9933 8.64416 22.9655 8.3923C22.9094 7.88361 22.7702 7.22303 22.4485 6.57523C22.131 5.93596 21.6364 5.30843 20.8514 4.83421C20.0641 4.35866 18.9229 4 17.2551 4C13.9831 4 12.4996 5.37621 11.7721 6.63176C11.3895 7.29225 11.1938 7.96176 11.0952 8.47224C11.0463 8.72553 11.0224 8.93391 11.0108 9.07434C11.005 9.14437 11.0023 9.19696 11.001 9.22915C11.0004 9.24524 11.0001 9.25617 11 9.2616L11 9.26438ZM9 13.9565V14H7C5.34315 14 4 15.3431 4 17V29C4 30.6569 5.34315 32 7 32H27C28.6569 32 30 30.6569 30 29V17C30 15.3431 28.6569 14 27 14H24.9919V13.9565V9.29423C24.9929 9.27793 24.9939 9.25801 24.995 9.23466C24.9976 9.17516 25 9.09316 25 8.99185C25 8.78963 24.9905 8.50802 24.9535 8.17291C24.8801 7.5077 24.6954 6.60306 24.2398 5.68564C23.7799 4.75969 23.0438 3.82201 21.8855 3.12231C20.7294 2.42394 19.2147 2 17.2551 2C13.272 2 11.1279 3.75422 10.0416 5.62911C9.51743 6.53384 9.25964 7.42954 9.1315 8.09298C9.06706 8.42664 9.0343 8.70739 9.01758 8.90936C9.0092 9.01052 9.00482 9.09244 9.00252 9.15196C9.00137 9.18173 9.00074 9.20595 9.0004 9.22422L9.00007 9.24714L9.00001 9.25517L9 9.25831L9 9.25966C9 9.26028 9 9.26087 10 9.26087H9V13.9565ZM7 16H9H10H23.9919H24.9919H27C27.5523 16 28 16.4477 28 17V29C28 29.5523 27.5523 30 27 30H7C6.44772 30 6 29.5523 6 29V17C6 16.4477 6.44772 16 7 16ZM14 23C14 21.3431 15.3431 20 17 20C18.6569 20 20 21.3431 20 23C20 24.6569 18.6569 26 17 26C15.3431 26 14 24.6569 14 23ZM17 18C14.2386 18 12 20.2386 12 23C12 25.7614 14.2386 28 17 28C19.7614 28 22 25.7614 22 23C22 20.2386 19.7614 18 17 18Z"
        fill={FONT}
      />
    </svg>
  );
}
