import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

const fromAppObservabilityCountBody = (
  timeRange: GTimeRange,
  tenantName?: string
): { [key: string]: any } => {
  return {
    aggs: {
      "1": {
        cardinality: {
          field: "Hostname.keyword",
        },
      },
      "2": {
        cardinality: {
          field: "Source_IP.keyword",
        },
      },
    },
    size: 0,
    query: {
      bool: {
        filter: [
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: parseGTime(timeRange.from),
                lte: parseGTime(timeRange.to),
              },
            },
          },
          {
            match_phrase: { "tenant_name.keyword": tenantName },
          },
        ],
      },
    },
  };
};

export default fromAppObservabilityCountBody;
