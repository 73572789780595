/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { FilterAndSort, PBRType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import AbstractCrudContextContainer, {
  createContextAndUseWithExtra,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";
import { EMPTY_RESULT } from "../../helpers/common/constantsAlias";
import withTimerangeContext from "../../helpers/hocs/withTimerangeContext";
import { RequestStatus, TimerangeContextType } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";

type IState = CrudState<PBRType, PBRType> & {
  pbrList: Array<PBRType>;
  pbrListStatus: RequestStatus;
  lastFetchedTenant: string;
  selectedPBR: PBRType | undefined;
};

type IFunc = {
  fetchList: (tenant?: string, offset?: number, limit?: number) => void;
  selectPBR: (pbr: PBRType | undefined) => void;
  changePBREnable: (pbr?: any | undefined) => void;
  fetchGlobalList: () => void;
  globalAdd: (pbr: PBRType, tenant?: string) => Promise<boolean>;
  globalEdit: (
    params: Partial<PBRType>,
    rule_name: string | number,
    tenant?: string
  ) => Promise<boolean>;
  globalDelete: (rule: PBRType, tenant?: string) => Promise<boolean>;
  changePBREnableGlobal: (pbr?: any | undefined) => void;
  useTenant: (tenant: string) => void;
  removeDeletedRule: (name: string) => void;
};

export type PBRDeleteArgs = PBRType & {
  tenant: string;
};

const [Context, useContext] = createContextAndUseWithExtra<
  IState,
  IFunc & CrudFunc<PBRType, PBRType>,
  TimerangeContextType
>();

export const usePBRContext = useContext;

type Props = React.PropsWithChildren<{
  timerangeContext: TimerangeContextType;
}>;

class PBRContextContainer extends AbstractCrudContextContainer<
  PBRType,
  PBRType,
  IState,
  PBRType
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.funcs = {
      fetchList: this.fetchList,
      selectPBR: this.selectPBR,
      changePBREnable: this.changePBREnable,
      fetchGlobalList: this.fetchGlobalList,
      globalAdd: this.globalAdd,
      globalEdit: this.globalEdit,
      changePBREnableGlobal: this.changePBREnableGlobal,
      globalDelete: this.globalDelete,
      useTenant: this.useTenant,
      removeDeletedRule: this.removeDeletedRule,
    };
  }

  changePBREnable = async (pbr?: PBRType | undefined, isGlobal?: boolean) => {
    if (pbr) {
      this.setState({ editStatus: setPending("Fetching") });
      const res = await this.editReq(
        { enable: !pbr.enable, priority: pbr.priority },
        pbr.name,
        isGlobal ? pbr.tenant_name : this.state.lastFetchedTenant,
        isGlobal
      );
      this.setState({ editStatus: resToState(res) });
    }
  };

  removeDeletedRule = (name: string) => {
    const list = this.state.list.filter((r) => r.name !== name);
    this.setState({ list });
  };

  changePBREnableGlobal = async (pbr?: PBRType | undefined) => {
    return await this.changePBREnable(pbr, true);
  };

  selectPBR = async (pbr: PBRType | undefined) => {
    this.setState({ selectedPBR: pbr });
  };

  listReq = async (params?: FilterAndSort, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.getPBRRules(tenant);
    // TEMP SOLUTION FOR SORTING
    if (res.ok) {
      const sortedResult = res.result?.sort(
        (prev, next) => prev.priority - next.priority
      );
      return { ...res, result: sortedResult };
    }
    return res;
  };

  fetchList = async (
    tenant?: string,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    if (!tenant) return;
    this.setState({ pbrListStatus: setPending("Fetching") });
    const res = await this.listReq(undefined, tenant);

    if (res.ok && res.result) {
      this.setState({ pbrList: res.result, lastFetchedTenant: tenant });
    }
    this.setState({
      pbrListStatus: resToState(res),
      lastFetchedTenant: tenant,
    });
  };

  fetchGlobalList = async (): Promise<void> => {
    this.setState({ pbrListStatus: setPending("Fetching") });
    const res = await configApi.getPBRGlobalRules();

    if (res.ok && res.result) {
      const sortedResult = res.result?.sort(
        (prev, next) => prev.priority - next.priority || prev.id - next.id
      );
      const tenantToUse = sortedResult[0].tenant_name;
      if (tenantToUse) {
        this.setState({
          pbrList: sortedResult,
          lastFetchedTenant: tenantToUse,
        });
      } else {
        this.setState({ pbrList: sortedResult });
      }
    }
    this.setState({ pbrListStatus: resToState(res) });
  };

  selectedReq = async (rule_name: string | number, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    return await configApi.getPBR(tenant, rule_name as string);
  };

  addReq = async (params: any, tenant?: string) => {
    if (!tenant) return EMPTY_RESULT;
    const res = await configApi.addPBR(tenant, params as any);
    if (res.ok) {
      await this.fetchList(this.state.lastFetchedTenant);
    }
    return res;
  };

  globalAdd = async (pbr: PBRType, tenant?: string) => {
    this.setState({ addStatus: setPending("Fetching") });
    pbr.tenant_name = this.state.lastFetchedTenant;
    const res = await configApi.addPBRGlobal(pbr);
    if (res.ok) {
      await this.fetchGlobalList();
    }
    this.setState({ addStatus: resToState(res) });
    return res.ok;
  };

  globalEdit = async (
    params: Partial<PBRType>,
    rule_name: string | number,
    tenant?: string
  ) => {
    this.setState({ editStatus: setPending("Fetching") });
    const usedTenant = params.tenant_name || this.state.lastFetchedTenant;
    const res = await this.editReq(params, rule_name, usedTenant, true);
    this.setState({ editStatus: resToState(res) });
    return res.ok;
  };

  globalDelete = async (rule: PBRType, tenant?: string) => {
    this.setState({ removeStatus: setPending("Fetching") });
    const res = await this.removeReq(rule, rule.tenant_name, true);
    this.setState({ removeStatus: resToState(res) });
    return res.ok;
  };

  removeReq = async (rule: PBRType, tenant?: string, isGlobal = false) => {
    const selectedTenant = tenant || this.state.lastFetchedTenant;
    if (!selectedTenant) return EMPTY_RESULT;
    const res = await configApi.deletePBR(
      selectedTenant,
      rule.name.toString(),
      isGlobal
    );
    if (res.ok) {
      await ((isGlobal && this.fetchGlobalList()) ||
        this.fetchList(this.state.lastFetchedTenant));
    }
    return res;
  };

  useTenant = async (tenant: string) => {
    this.setState({ lastFetchedTenant: tenant });
  };

  editReq = async (
    params: Partial<PBRType>,
    rule_name: string | number,
    tenant?: string,
    isGlobal?: boolean
  ) => {
    if (!tenant || !params.priority) return EMPTY_RESULT;
    const res = await configApi.editPBR(
      tenant,
      rule_name as string,
      params,
      isGlobal
    );
    if (res && res.ok) {
      await ((isGlobal && this.fetchGlobalList()) ||
        this.fetchList(this.state.lastFetchedTenant));
    }
    return res;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.baseFuncs,
          ...this.funcs,
          ...this.props.timerangeContext,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default withTimerangeContext(PBRContextContainer);
