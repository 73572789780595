import { NatRuleType } from "../../helpers/api/apiTypes";

import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { RequestStatus } from "../../helpers/types";

import { configApi } from "../../helpers/api/ConfigApi";
import { tenantApi } from "../../helpers/api/TenantApi";
import { Layer3Interface } from "../../pages/Layer3Interface/types";

type RequestsState = CrudRequestStatuses & {
  natListStatus: RequestStatus;
};

type IState = RequestsState & {
  viList: Array<Layer3Interface> | undefined;
  selectedInterface: Layer3Interface | undefined;
};

type IFunc = CrudFunc<NatRuleType> & {
  fetchNatInterfaces: (tenantName: string) => Promise<void>;
  fetchVIList: (tenantName: string) => Promise<void>;
  selectInterface: (selectedInterface: Layer3Interface) => Promise<void>;
};

export type ITenantVIContext = IState & IFunc;
const [
  Context,
  useTenantVIContext,
  withTenantVIContextProps,
] = createContextUtils<IState, IFunc>();

export { useTenantVIContext, withTenantVIContextProps };

type Props = IOneSystemContext;

// TODO Combine with NAT Rule Context and remove
class TenantVIContextContainer extends NewAbstractCrudContext<
  NatRuleType,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (): Promise<void> => {
    return;
  };

  fetchNatInterfaces = async (tenantName: string): Promise<void> => {
    const { result: natList } = await configApi.getNatRulesList(tenantName);
    const { result: viList } = await tenantApi.getVirtualInterfaces(tenantName);
  };

  fetchVIList = async (tenantName: string): Promise<void> => {
    const { result: viList } = await tenantApi.getVirtualInterfacesAllTypes(
      tenantName
    );
    this.setState({ viList: viList as any });
  };

  selectInterface = async (
    selectedInterface: Layer3Interface
  ): Promise<void> => {
    this.setState({ selectedInterface });
  };

  remove = async (): Promise<boolean> => {
    return false;
  };
  edit = async (): Promise<boolean> => {
    return false;
  };

  funcs = {
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
    fetchNatInterfaces: this.fetchNatInterfaces,
    fetchVIList: this.fetchVIList,
    selectInterface: this.selectInterface,
  };
}

export default withOneSystemContextProps(TenantVIContextContainer);
