import React, { FC } from "react";
import styles from "./VTEPPage.module.scss";
import { withLayout } from "../../helpers/hocs/withLayout";
import { OneSystemTabs } from "../../components/common/navigation/OneSystemTabs";
import { OneSystemLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { PageProps } from "../../helpers/types";
import VTEPPageContent from "./VTEPPageContent";

const VTEPPage: FC = () => {
  return (
    <OneSystemLayout>
      <div className={styles.contentWrapper}>
        <OneSystemTabs disabledVrf />
        <VTEPPageContent />
      </div>
    </OneSystemLayout>
  );
};

export default withLayout<PageProps>(VTEPPage);
