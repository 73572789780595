import React, { FC } from "react";
import styles from "./ResultTableHeader.module.scss";
import CaretDown from "../../../../../components/icons/CaretDown";
import IconBlankButton from "../../../../../components/common/buttons/IconBlankButton";
import { classNames } from "../../../../../helpers/common/classNames";
import { FONT } from "../../../../../helpers/common/colorAliases";
import { Link } from "react-router-dom";

type Props = {
  title: string;
  goto?: string;
  count?: number;
  isCollapsed?: boolean;
  toggleCollapse?: () => void;
  isCollapsible?: boolean;
};

const ResultTableHeader: FC<Props> = ({
  title,
  goto,
  count,
  isCollapsed,
  toggleCollapse,
  isCollapsible,
}) => {
  const collapseBtnClasses = classNames(
    styles.collapseBtn,
    !isCollapsed && styles.rotate
  );

  return (
    <div className={styles.wrapper}>
      <Link to={goto || ""} className={styles.title}>
        {title}
      </Link>
      {count !== undefined && (
        <span className={styles.count}>
          {count === 1 ? `Found 1 rule` : `Found ${count} rules`}
        </span>
      )}
      {isCollapsible && (
        <IconBlankButton
          id={title + "collapse"}
          icon={CaretDown}
          onClick={toggleCollapse}
          className={collapseBtnClasses}
          color={FONT}
          isOnlyOpacity
        />
      )}
    </div>
  );
};
export default ResultTableHeader;
