import { ServiceAPI } from "../../../../ServicesPreferencesPage/types";

export const getServicesParams = (
  userList: Array<ServiceAPI>
): { [key: string]: boolean } => {
  return SERVICES;
};

const SERVICES: { [key: string]: boolean } = {
  firewall: false,
  ddos: false,
  fqdn_filtering: true,
  app_observability: false,
  network_observability: true,
  cyber_threats: false,
  nat: false,
  load_balancing: false,
  remote_users: false,
  network_segmentation: true,
};
