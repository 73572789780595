import React from "react";

import NavigationTabs from "./NavigationTabs";
import { PACKAGES, TENANT_USAGE } from "../../../helpers/navigation/entries";
import styles from "./PackagesTabs.module.scss";

export const PackagesTabs: React.FC = () => {
  return (
    <div className={styles.tabsWrapper}>
      <NavigationTabs
        entries={[PACKAGES(), TENANT_USAGE()]}
        disabledTabKeys={[]}
      />
    </div>
  );
};
