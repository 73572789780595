import { configApi } from "../../helpers/api/ConfigApi";

import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { RequestStatus } from "../../helpers/types";
import { VTEPType } from "../VTEPPage/types";
import { VtepVni } from "./types";

type RequestsState = CrudRequestStatuses & {
  vtepListStatus: RequestStatus;
};

type ValueState = CrudValueState<VtepVni> & {
  vtepList?: Array<VTEPType>;
};

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<VtepVni> & {
  fetchVtepList: () => Promise<void>;
};

export type IVniContext = IState & IFunc;
const [Context, useVNIContext, withVNIContextProps] = createContextUtils<
  IState,
  IFunc
>();

export { useVNIContext, withVNIContextProps };

type Props = IOneSystemContext;

class VNIContextContainer extends NewAbstractCrudContext<
  VtepVni,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;

    const { ok } = await this._fetchListWrap(() =>
      configApi.getVNIList(system.name)
    );
    if (ok) {
      this.fetchVtepList();
    }
  };

  remove = async (vlan: VtepVni): Promise<boolean> => {
    const { system } = this.props;
    const { vtep_id, vtep_name, vni_id, alias } = vlan;
    if (!system || !vtep_name) return false;

    const { ok } = await this._removeWrap(() =>
      configApi.deleteVNI(system.name, {
        vtep_id,
        vtep_name,
        vni_id,
        alias,
      })
    );

    return ok;
  };

  // todo not implemented
  edit = async (vni: VtepVni): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());

    return ok;
  };

  fetchVtepList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;
    const { ok, result } = await this._fetchWithStatus(
      () => configApi.getVTEPList(system.name),
      "vtepListStatus"
    );
    this.setState({ vtepList: ok ? result : undefined });
  };

  funcs = {
    fetchVtepList: this.fetchVtepList,
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
  };
}

export default withOneSystemContextProps(VNIContextContainer);
