import React, { FC } from "react";
import { useConnectionsContext } from "../../../../../ConnectionsContext";

import AddWorkloadsToInternet from "./AddWorkloadsToInternet";
import EditWorkloadsToInternet from "./EditWorkloadsToInternet";

type Props = {
  connectionType: string;
  setConnectionType: (val: string) => void;
};

const WorkloadsToInternet: FC<Props> = (props) => {
  const { activeConnection } = useConnectionsContext();
  return (
    <>
      {activeConnection ? (
        <EditWorkloadsToInternet {...props} />
      ) : (
        <AddWorkloadsToInternet {...props} />
      )}
    </>
  );
};
export default WorkloadsToInternet;
