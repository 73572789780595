import React, { FC } from "react";
import AddButton from "../../../../components/common/buttons/AddButton";
import { TableColumnHeadersProps } from "../../../../components/common/table/newTable/Table";
import { ConnectionRow } from "../../types/connections";

import styles from "./TableGroupHeader.module.scss";
import { useUserContext } from "../../../../contexts/UserContext";
import { useHistory } from "react-router-dom";
import {
  NETWORK,
  REPORTS,
  ZAYO_CONNECTIONS,
} from "../../../../helpers/navigation/entries";
import IconBlankButton from "../../../../components/common/buttons/IconBlankButton";
import SearchIcon from "../../../../components/icons/SearchIcon";

export const TableGroupHeader: FC<TableColumnHeadersProps<ConnectionRow>> = ({
  row,
  onCollapse,
}) => {
  const { isZayo, isTenant } = useUserContext();
  const history = useHistory();

  const gotoZayoConnections = () => {
    const path = isTenant
      ? "/" + ZAYO_CONNECTIONS().path
      : "/" + NETWORK().path + "/" + ZAYO_CONNECTIONS().path;
    history.replace(path);
  };
  const name = row?.original.name.label;

  return (
    <div className={styles.groupWrapper} onClick={onCollapse}>
      <div className={styles.groupTitle}>
        {/* <CountryUSIcon /> */}
        {name}
      </div>
      <IconBlankButton
        icon={SearchIcon}
        id={""}
        onClick={() => history.push(REPORTS().path)}
      />
      {/* <AddButton
        onClick={() => (isZayo ? gotoZayoConnections() : undefined)}
        isIconOnly
      /> */}
    </div>
  );
};
