import { configApi } from "../../../helpers/api/ConfigApi";
import { SearchResponse } from "../types";

export const fetchFwRulesByName = async (
  rules: Array<SearchResponse>,
  tenant: string
): Promise<Array<any>> => {
  const fwRules: Array<any> = [];
  await Promise.all(
    rules.map(
      async (rule): Promise<any> => {
        const res = await configApi.getFWRule(tenant, rule.key);
        if (res.ok && res.result) fwRules.push(res.result);
      }
    )
  );
  return fwRules;
};
