import { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { ORANGE } from "../../../helpers/common/colorAliases";
import LoaderIcon from "../loadStates/LoaderIcon";
import TextWithIcon from "../table/TextWithIcon";
import styles from "./FieldLoader.module.scss";

type Props = {
  className?: string;
  message: string;
};

const FieldLoader: FC<Props> = ({ className, message }) => {
  return (
    <TextWithIcon
      icon={<LoaderIcon color={ORANGE} />}
      text={message}
      className={classNames(styles.loader, className)}
    />
  );
};

export default FieldLoader;
