/* eslint-disable css-modules/no-unused-class */
import { FC, useState } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import styles from "./UserGroupComponent.module.scss";
import IconBlankButton from "../../../../../components/common/buttons/IconBlankButton";
import CaretDown from "../../../../../components/icons/CaretDown";
import FirewallIcon from "../../../../../components/icons/tabs/FirewallIcon";
import { GREY_FONT, PURPLE } from "../../../../../helpers/common/colorAliases";
import GroupIcon from "../../../../../components/icons/GroupIcon";
import { UserGroup } from "../../../../../helpers/types";
import { UserThumbnail } from "../../../../UsersPage/components/shared/UserThumbnail";
import Label from "../../../../../components/common/table/Label";
import EditSquareIcon from "../../../../../components/icons/editSquareIcon";
import { Checkbox } from "../../../../../components/common/formComponents/Checkbox";
import { useUserGroupsContext } from "../../../UserGroupsContext";
import _ from "lodash";
import InputWithList from "../../../../../components/common/formComponents/InputWithList";
import Button from "../../../../../components/common/buttons/Button";

type Props = {
  group: UserGroup;
  selectedTenant: string;
  type?: string;
};

const UserGroupComponent: FC<Props> = ({ group, selectedTenant }) => {
  const { users, updateUsersGroup } = useUserGroupsContext();
  const [newGroup, setNewGroup] = useState<UserGroup>(_.cloneDeep(group));
  const [isShowDetails, setIsShowDetails] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const filterRemoteUsers = users?.filter((oneUser) => oneUser.is_remote_user);
  const addUsersArray = filterRemoteUsers?.map((oneUser) => {
    const userCheck = newGroup.users.includes(oneUser.username);
    return {
      username: oneUser.username,
      userCheck,
      userFullName: oneUser.first_name + " " + oneUser.last_name,
    };
  });

  const handleUpdateGroup = async () => {
    if (!selectedTenant) return;
    setIsEditMode(false);
    const updateGroupRes = await updateUsersGroup(
      selectedTenant,
      newGroup.name,
      {
        users: newGroup.users,
        subnets: newGroup.subnets,
      }
    );
  };

  const handleUserChange = (userName: string, isCheck: boolean) => {
    let newUsers = [""];
    if (!isCheck) {
      newUsers = [...newGroup.users, userName];
    } else {
      newUsers = newGroup.users.filter((user) => user !== userName);
    }
    const params = {
      subnets: [...newGroup.subnets],
      users: newUsers,
    };
    setNewGroup({ ...newGroup, users: [...newUsers] });
  };

  const handleIPChange = (iP: string, isCheck: boolean) => {
    let newipSubnets = [""];
    if (!isCheck) {
      newipSubnets = [...newGroup.subnets, iP];
    } else {
      newipSubnets = newGroup.subnets.filter((subnet) => subnet !== iP);
    }
    const params = {
      subnets: newipSubnets,
      users: [...newGroup.users],
    };
    setNewGroup({ ...newGroup, subnets: [...newipSubnets] });
  };

  const addSegment = (segments: Array<string>) => {
    const isOk = true;
    // const { isOk } = validate();
    if (isOk) {
      const prevSegments = newGroup.subnets;
      const newSegments = [...prevSegments, ...segments];
      setNewGroup({ ...newGroup, subnets: newSegments });
    }
  };

  const removeSegment = (ip: string) => {
    const prevSegments = newGroup.subnets;
    const newSegments = prevSegments.filter((subnet) => subnet !== ip);
    setNewGroup({ ...newGroup, subnets: newSegments });
  };

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.header, styles.clickable)}>
        {isShowDetails ? (
          <div className={styles.inlineBox}>
            <div className={styles.groupTitle}>
              <div className={styles.groupName}>
                <GroupIcon />
                <div>{newGroup.name}</div>
              </div>
              <div className={styles.groupSubTitle}>
                <div>
                  <span className={styles.groupFirstSpan}>
                    {newGroup.users.length}
                  </span>
                  <span className={styles.groupSecondSpan}> Users</span>
                </div>
                <div>
                  <span className={styles.groupFirstSpan}>
                    {newGroup.subnets.length}
                  </span>
                  <span className={styles.groupSecondSpan}> IP Subnets</span>
                </div>
              </div>
            </div>
            <div>
              <div className={styles.inputDiv}>
                <div className={styles.groupRow}>
                  <span className={styles.groupColumn}>Users</span>
                  <div className={styles.list}>
                    {newGroup.users.map((userMap) => (
                      <Label
                        isRemovable
                        onClick={() => handleUserChange(userMap, true)}
                        className={styles.labelClassName}
                      >
                        {<UserThumbnail username={userMap} />}
                      </Label>
                    ))}
                    {!newGroup.users.length && (
                      <div className={styles.groupNoLabels}>
                        Add using table below
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.groupRow}>
                  <span className={styles.groupColumn}>IP Subnets</span>
                  <div className={styles.list}>
                    {newGroup.subnets.map((subnetMap) => (
                      <Label
                        isRemovable
                        onClick={() => handleIPChange(subnetMap, true)}
                        className={styles.labelSubnetClassName}
                      >
                        {subnetMap}
                      </Label>
                    ))}
                    {!newGroup.subnets.length && (
                      <div className={styles.groupNoLabels}>
                        Add using field below
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isEditMode && (
                <>
                  <div className={styles.edit}>
                    <div className={styles.editLabel}>Add IP Subnet</div>
                    <InputWithList
                      onAdd={addSegment}
                      onRemove={removeSegment}
                      valuesList={newGroup.subnets}
                      medium
                    />
                    <Button
                      className={styles.addGroupBtn}
                      onClick={handleUpdateGroup}
                    >
                      Apply
                    </Button>
                  </div>
                  {addUsersArray ? (
                    <>
                      {addUsersArray.map((oneUser) => (
                        <div className={styles.userBox}>
                          <div className={styles.userThumbnail}>
                            <UserThumbnail username={oneUser.userFullName} />
                          </div>
                          <div className={styles.userNameBox}>
                            {oneUser.username}
                          </div>
                          <Checkbox
                            onChange={() => {
                              handleUserChange(
                                oneUser.username,
                                oneUser.userCheck
                              );
                            }}
                            isChecked={oneUser.userCheck}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <div>No Remote users on tenant. Please add some.</div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className={styles.collapsedGroup}>
            <div className={styles.groupTitle}>
              <div className={styles.groupName}>
                <GroupIcon />
                <div>{newGroup.name}</div>
              </div>
            </div>
            <div className={styles.members}>
              {newGroup.users.length} Users <br /> {newGroup.subnets.length} IP
              Subnets
            </div>
          </div>
        )}
        <IconBlankButton
          id={"fireWallPolicy"}
          icon={FirewallIcon}
          label={"Policy"}
          btnClassName={styles.fireWallBtn}
          color={PURPLE}
        />
        <IconBlankButton
          id={"editBtn"}
          onClick={() => {
            setIsEditMode((prev) => !prev);
          }}
          icon={EditSquareIcon}
          className={styles.editBtn}
        />
        <IconBlankButton
          id={"userGroupCaretDown"}
          icon={CaretDown}
          className={styles.showBtn}
          color={GREY_FONT}
          onClick={() => setIsShowDetails((prev) => !prev)}
        />
      </div>
    </div>
  );
};
export default UserGroupComponent;
