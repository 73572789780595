import getManualSortedData from "../getManualSortedData";

import {
  LoopbackType,
  NatpoolType,
  SortType,
  LAGType,
  LogsListType,
  StatusVRFType,
  OneSystemType,
} from "./apiTypes";
import { PaginalRes, Res } from "./Api";
import { PaginateListResponse } from "../types";
import { PublicIpManagementRecord } from "../../pages/IPAMPage/types";
import { IPAM_MOCKED_DATA } from "../../pages/IPAMPage/mocked.data";

export class HardcodeApi {
  getPackagesData(): {
    networkingFields: Array<string>;
    securityFields: Array<string>;
    managmentFields: Array<string>;
    list: Array<{
      name: string;
      max_bw: string;
      [key: string]: any;
    }>;
  } {
    const networkingFields = [
      "Site to Site connectivity",
      "Private DNS",
      "maximum_bgp_neighbors",
      "vRouter",
      "nat_rules",
      "Load Balancing service",
      "Load Balancer front-end IPs",
      "Load Balancer back-end IPs",
    ];
    const securityFields = [
      "fw_rules",
      "Secure Web Gateway",
      "Web Based Filtering",
      "Threat Visibility",
      "Threat Blocking",
      "DNS Filtering",
      "DDoS Protection",
    ];
    const managmentFields = ["User Portal", "API Access", "SIEM integration"];
    const list = [
      {
        name: "Essentials",
        max_bw: "1Gbps",
        "Site to Site connectivity": false,
        "Private DNS": true,
        maximum_bgp_neighbors: ["6 neighbors", "15 routes/neighbor"],
        vRouter: "20 routes",
        nat_rules: "10 rules",
        "Load Balancing service": false,
        "Load Balancer front-end IPs": 0,
        "Load Balancer back-end IPs": 0,
        fw_rules: "10 policies",
        "Secure Web Gateway": false,
        "Web Based Filtering": 0,
        "Threat Visibility": false,
        "Threat Blocking": false,
        "DNS Filtering": false,
        "DDoS Protection": false,

        "User Portal": true,
        "API Access": false,
        "SIEM integration": false,
      },
      {
        name: "Premium",
        max_bw: "5Gbps",
        "Site to Site connectivity": true,
        "Private DNS": true,
        maximum_bgp_neighbors: ["20 neighbors", "150 routes/neighbor"],
        vRouter: "200 routes",
        nat_rules: "50 rules",
        "Load Balancing service": false,
        "Load Balancer front-end IPs": 128,
        "Load Balancer back-end IPs": 256,
        fw_rules: "20 policies",
        "Secure Web Gateway": false,
        "Web Based Filtering": "20 Categories",
        "Threat Visibility": true,
        "Threat Blocking": false,
        "DNS Filtering": true,
        "DDoS Protection": false,
        "User Portal": true,
        "API Access": true,
        "SIEM integration": false,
      },
      {
        name: "Pro",
        max_bw: "10Gbps",
        "Site to Site connectivity": true,
        "Private DNS": true,
        maximum_bgp_neighbors: ["200 neighbors", "800 routes/neighbor"],
        vRouter: "1000 routes",
        nat_rules: "100 rules",
        "Load Balancing service": false,
        "Load Balancer front-end IPs": 1024,
        "Load Balancer back-end IPs": 2048,
        fw_rules: "100 policies",
        "Secure Web Gateway": true,
        "Web Based Filtering": "100 Categories",
        "Threat Visibility": true,
        "Threat Blocking": true,
        "DNS Filtering": true,
        "DDoS Protection": true,
        "User Portal": true,
        "API Access": true,
        "SIEM integration": true,
      },
      {
        name: "Custom",
        max_bw: "10Gbps",
        "Site to Site connectivity": true,
        "Private DNS": true,
        "Cloud router": ["200 neighbors", "800 routes/neighbor"],
        vRouter: "1000 routes",
        "Public network access": "100 rules",
        "Load Balancing service": false,
        "Load Balancer front-end IPs": 1024,
        "Load Balancer back-end IPs": 2048,
        FWaaS: "100 policies",
        "Secure Web Gateway": true,
        "Web Based Filtering": "100 Categories",
        "Threat Visibility": true,
        "Threat Blocking": true,
        "DNS Filtering": true,
        "DDoS Protection": true,
        "User Portal": true,
        "API Access": true,
        "SIEM integration": true,
      },
    ];
    return { networkingFields, securityFields, managmentFields, list };
  }

  async getSystemsList(sortBy: SortType): Promise<PaginalRes<OneSystemType>> {
    return {
      ok: true,
      error: "error",
      result: {
        total_count: 1,
        count: 1,
        items: getManualSortedData(
          [
            {
              id: 1,
              name: "mock",
              address: "122.13.73.80",
              type: "NSOS-500",
              version: "1.1.1",
              status: "READY",
              location: "Ashburn",
              description: "Sagittis aliquet dolor amet arcu amet",
              secure_type: "OPEN",
            },
          ],
          sortBy
        ),
      },
    };
  }

  async getSystemById(
    id: number
  ): Promise<{
    ok: boolean;
    error?: string | undefined;
    result?: OneSystemType;
  }> {
    return {
      ok: true,
      error: "error",
      result: {
        id: id,
        name: "mock",
        address: "122.13.73.80",
        type: "NSOS-500",
        version: "1.1.1",
        status: "READY",
        location: "Ashburn",
        description: "Sagittis aliquet dolor amet arcu amet",
        secure_type: "OPEN",
      },
    };
  }

  async getLoopbackList(): Promise<Array<LoopbackType>> {
    return [
      {
        id: 0,
        status: "active",
        name: "lo1",
        interfaceDescription: "Scelerisque amet",
        vrfMapping: "vrf-1",
        ipv4: ["192.168.72.12/24", "192.168.72.13/24"],
        ipv6: [
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
        ],
      },
      {
        id: 1,
        status: "active",
        name: "lo2",
        interfaceDescription: "Scelerisque amet",
        vrfMapping: "vrf-1",
        ipv4: ["192.168.72.12/24", "192.168.72.13/24"],
        ipv6: [
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
        ],
      },
      {
        id: 2,
        status: "active",
        name: "lo3",
        interfaceDescription: "Scelerisque amet",
        vrfMapping: "vrf-1",
        ipv4: ["192.168.72.12/24", "192.168.72.13/24"],
        ipv6: [
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
        ],
      },
    ];
  }

  async getNatpoolList(): Promise<Array<NatpoolType>> {
    return [
      {
        id: 0,
        status: "active",
        name: "npool1",
        interfaceDescription: "Scelerisque amet",
        vrfMapping: "vrf-1",
        ipv4: ["192.168.72.12/24", "192.168.72.13/24"],
        ipv6: [
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
        ],
      },
      {
        id: 1,
        status: "active",
        name: "npool2",
        interfaceDescription: "Scelerisque amet",
        vrfMapping: "vrf-1",
        ipv4: ["192.168.72.12/24", "192.168.72.13/24"],
        ipv6: [
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
        ],
      },
      {
        id: 2,
        status: "active",
        name: "npool3",
        interfaceDescription: "Scelerisque amet",
        vrfMapping: "vrf-1",
        ipv4: ["192.168.72.12/24", "192.168.72.13/24"],
        ipv6: [
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
          "2001:0db8:0000:0000:0000:ff00:0042:7879",
        ],
      },
    ];
  }

  async getLAGList(): Promise<Array<LAGType>> {
    return [
      {
        id: 0,
        status: "active",
        name: "LAG0",
        type: "static",
        links: {
          configured: 5,
          active: {
            curr: 3,
            min: 1,
          },
        },
        members: [1, 2, 3, 4, 5],
        vlans: {
          untagged: 32,
          tagged: [10, 11, 12],
        },
        speed: 233,
        adminState: "up",
      },
      {
        id: 1,
        status: "inactive",
        name: "LAG1",
        type: "LACP",
        links: {
          configured: 5,
          active: {
            curr: 3,
            min: 1,
          },
        },
        members: [9, 10, 11, 12],
        vlans: {
          untagged: 32,
          tagged: [10, 12, 13, 22, 27],
        },
        speed: 233,
        adminState: "down",
      },
      {
        id: 2,
        status: "active",
        name: "LAG2",
        type: "static",
        links: {
          configured: 5,
          active: {
            curr: 3,
            min: 1,
          },
        },
        members: [1, 2, 3],
        vlans: {
          untagged: 32,
          tagged: [10],
        },
        speed: 233,
        adminState: "up",
      },
      {
        id: 3,
        status: "inactive",
        name: "LAG3",
        type: "static",
        links: {
          configured: 5,
          active: {
            curr: 3,
            min: 1,
          },
        },
        members: [1, 2],
        vlans: {
          untagged: 32,
          tagged: [10, 11],
        },
        speed: 233,
        adminState: "down",
      },
    ];
  }

  async getLogsList(sortBy: SortType): Promise<Array<LogsListType>> {
    const data: Array<LogsListType> = [
      {
        status: "critical",
        time: new Date(0, 0, 0, 11, 20, 0, 0),
        state: "Power down",
        ip: "11.33.22.22",
      },
      {
        status: "warning",
        time: new Date(0, 0, 0, 11, 20, 0, 0),
        state: "Power down",
        ip: "11.33.22.22",
      },
      {
        status: "critical",
        time: new Date(0, 0, 0, 11, 20, 0, 0),
        state: "Fan Broken",
        ip: "11.33.22.22",
      },
      {
        status: "critical",
        time: new Date(0, 0, 0, 11, 20, 0, 0),
        state: "Fan Broken",
        ip: "11.33.22.22",
      },
    ];

    return getManualSortedData(data, sortBy);
  }

  async getBGPStatus(): Promise<{
    ok: boolean;
    error?: string | undefined;
    result?: StatusVRFType;
  }> {
    return {
      ok: true,
      error: "error",
      result: {
        underlay: {
          ipv4Unicast: {
            routerId: "100.0.11.2",
            as: 65000,
            vrfId: 44,
            vrfName: "underlay",
            tableVersion: 9,
            ribCount: 17,
            ribMemory: 3128,
            peerCount: 1,
            peerMemory: 740296,
            peers: {
              "100.0.11.3": {
                remoteAs: 65000,
                localAs: 65000,
                version: 4,
                msgRcvd: 368,
                msgSent: 28,
                tableVersion: 0,
                outq: 0,
                inq: 0,
                peerUptime: "00:21:52",
                peerUptimeMsec: 1312000,
                peerUptimeEstablishedEpoch: 1680446788,
                pfxRcd: 9,
                pfxSnt: 0,
                state: "Established",
                peerState: "OK",
                connectionsEstablished: 1,
                connectionsDropped: 0,
                idType: "ipv4",
              },
            },
            failedPeers: 1,
            displayedPeers: 1,
            totalPeers: 1,
            dynamicPeers: 0,
            bestPath: { multiPathRelax: "false" },
          },
          l2VpnEvpn: {
            routerId: "100.0.11.2",
            as: 65000,
            vrfId: 44,
            vrfName: "underlay",
            tableVersion: 0,
            ribCount: 675,
            ribMemory: 124200,
            peerCount: 1,
            peerMemory: 740296,
            peers: {
              "100.0.11.3": {
                remoteAs: 65000,
                localAs: 65000,
                version: 4,
                msgRcvd: 368,
                msgSent: 28,
                tableVersion: 0,
                outq: 0,
                inq: 0,
                peerUptime: "00:21:52",
                peerUptimeMsec: 1312000,
                peerUptimeEstablishedEpoch: 1680446788,
                pfxRcd: 344,
                pfxSnt: 0,
                state: "Established",
                peerState: "OK",
                connectionsEstablished: 1,
                connectionsDropped: 0,
                idType: "ipv4",
              },
            },
            failedPeers: 1,
            displayedPeers: 1,
            totalPeers: 1,
            dynamicPeers: 0,
            bestPath: { multiPathRelax: "false" },
          },
        },
      },
    };
  }

  async getBlockedThreatCategory(): Promise<{}> {
    return {
      Coke_one: [
        { name: "Hacking", percentage: "0%", amount: 65 },
        {
          name: "Newly Observed Domain",
          percentage: "0%",
          amount: 160,
        },
        {
          name: "Newly Registered Domain",
          percentage: "1%",
          amount: 205,
        },
        { name: "Unrated", percentage: "1%", amount: 210 },
        { name: "Dynamic DNS", percentage: "3%", amount: 1060 },
        { name: "Spam URLs", percentage: "4%", amount: 1273 },
        { name: "Phishing", percentage: "29%", amount: 9742 },
        {
          name: "Malicious Websites",
          percentage: "63%",
          amount: 21307,
        },
      ],
      Pepsi_two: [
        { name: "Hacking", percentage: "0%", amount: 103 },
        {
          name: "Newly Observed Domain",
          percentage: "1%",
          amount: 588,
        },
        {
          name: "Newly Registered Domain",
          percentage: "1%",
          amount: 309,
        },
        { name: "Unrated", percentage: "0%", amount: 40 },
        { name: "Dynamic DNS", percentage: "8%", amount: 4355 },
        { name: "Spam URLs", percentage: "2%", amount: 1232 },
        { name: "Phishing", percentage: "25%", amount: 12874 },
        {
          name: "Malicious Websites",
          percentage: "62%",
          amount: 31874,
        },
      ],

      RC_three: [
        { name: "Hacking", percentage: "0%", amount: 7 },
        {
          name: "Newly Observed Domain",
          percentage: "0%",
          amount: 16,
        },
        {
          name: "Newly Registered Domain",
          percentage: "3%",
          amount: 127,
        },
        { name: "Unrated", percentage: "1%", amount: 21 },
        { name: "Dynamic DNS", percentage: "8%", amount: 334 },
        { name: "Spam URLs", percentage: "0%", amount: 5 },
        { name: "Phishing", percentage: "35%", amount: 1400 },
        {
          name: "Malicious Websites",
          percentage: "53%",
          amount: 2131,
        },
      ],

      RedBull_four: [
        { name: "Hacking", percentage: "0%", amount: 86 },
        {
          name: "Newly Observed Domain",
          percentage: "0%",
          amount: 212,
        },
        {
          name: "Newly Registered Domain",
          percentage: "1%",
          amount: 272,
        },
        { name: "Unrated", percentage: "1%", amount: 644 },
        { name: "Dynamic DNS", percentage: "3%", amount: 1204 },
        { name: "Spam URLs", percentage: "1%", amount: 288 },
        { name: "Phishing", percentage: "22%", amount: 9844 },
        {
          name: "Malicious Websites",
          percentage: "72%",
          amount: 32987,
        },
      ],
      fifth_tenant: [
        { name: "Hacking", percentage: "0%", amount: 450 },
        {
          name: "Newly Observed Domain",
          percentage: "0%",
          amount: 32,
        },
        {
          name: "Newly Registered Domain",
          percentage: "2%",
          amount: 2003,
        },
        { name: "Unrated", percentage: "0%", amount: 433 },
        { name: "Dynamic DNS", percentage: "1%", amount: 1237 },
        { name: "Spam URLs", percentage: "7%", amount: 7021 },
        { name: "Phishing", percentage: "25%", amount: 26005 },
        {
          name: "Malicious Websites",
          percentage: "64%",
          amount: 66332,
        },
      ],
    };
  }
  async getCyberBlockedReason(): Promise<{}> {
    return {
      Coke_one: [
        { name: "URL blocked by malicious-url-list", amount: 2 },
        { name: "A rating error occurs", amount: 5 },
        { name: "Domain was blocked by dns botnet C&C", amount: 7 },
        {
          name: "URL was blocked because it is in the URL filter list",
          amount: 10,
        },
        { name: "backdoor: Cybergate.Botnet,", amount: 29 },
        { name: "backdoor: Quasar.Botnet,", amount: 29 },
        {
          name: "The HTTP request contained an invalid domain name.",
          amount: 5651,
        },
        { name: "Domain is monitored", amount: 28289 },
      ],
      Pepsi_two: [
        { name: "URL blocked by malicious-url-list", amount: 7 },
        { name: "A rating error occurs", amount: 33 },
        { name: "Domain was blocked by dns botnet C&C", amount: 4 },
        {
          name: "URL was blocked because it is in the URL filter list",
          amount: 89,
        },
        { name: "backdoor: Quasar.Botnet,", amount: 20 },
        {
          name: "The HTTP request contained an invalid domain name.",
          amount: 9844,
        },
        { name: "Domain is monitored", amount: 41378 },
      ],

      RC_three: [
        {
          name: "URL was blocked because it is in the URL filter list",
          amount: 4,
        },
        { name: "backdoor: Cybergate.Botnet,", amount: 2 },
        { name: "backdoor: Quasar.Botnet,", amount: 10 },
        {
          name: "The HTTP request contained an invalid domain name.",
          amount: 1045,
        },
        { name: "Domain is monitored", amount: 2980 },
      ],

      RedBull_four: [
        { name: "URL blocked by malicious-url-list", amount: 33 },
        { name: "A rating error occurs", amount: 6 },
        { name: "Domain was blocked by dns botnet C&C", amount: 10 },
        { name: "backdoor: Cybergate.Botnet,", amount: 37 },
        {
          name: "The HTTP request contained an invalid domain name.",
          amount: 8332,
        },
        { name: "Domain is monitored", amount: 37119 },
      ],
      fifth_tenant: [
        { name: "URL blocked by malicious-url-list", amount: 10 },
        { name: "A rating error occurs", amount: 228 },
        { name: "Domain was blocked by dns botnet C&C", amount: 33 },
        {
          name: "URL was blocked because it is in the URL filter list",
          amount: 90,
        },
        { name: "backdoor: Cybergate.Botnet,", amount: 55 },
        { name: "backdoor: Quasar.Botnet,", amount: 99 },
        {
          name: "The HTTP request contained an invalid domain name.",
          amount: 23556,
        },
        { name: "Domain is monitored", amount: 79642 },
      ],
    };
  }

  async getBlockedRuleCategory(): Promise<{}> {
    return {
      Coke_one: [
        {
          name: "Explicit Violence",
          percentage: "0.01%",
          amount: 1,
        },
        { name: "Tobacco", percentage: "0.02%", amount: 2 },
        { name: "Marijuana", percentage: "0.10%", amount: 9 },
        {
          name: "Alternative Beliefs",
          percentage: "0.22%",
          amount: 20,
        },
        {
          name: "Weapons (Sales)",
          percentage: "0.23%",
          amount: 21,
        },
        {
          name: "Lingerie and Swimsuit",
          percentage: "0.39%",
          amount: 35,
        },
        { name: "Gambling", percentage: "31.39%", amount: 2853 },
        {
          name: "Other Adult Materials",
          percentage: "31.71%",
          amount: 2882,
        },
        { name: "Pornography", percentage: "35.92%", amount: 3264 },
      ],
      Pepsi_two: [
        { name: "Tobacco", percentage: "0.01%", amount: 5 },
        {
          name: "Alternative Beliefs",
          percentage: "0.18%",
          amount: 30,
        },
        {
          name: "Lingerie and Swimsuit",
          percentage: "0.16%",
          amount: 60,
        },
        {
          name: "Weapons (Sales)",
          percentage: "0.16%",
          amount: 60,
        },
        {
          name: "Explicit Violence",
          percentage: "1.13%",
          amount: 425,
        },
        {
          name: "Other Adult Materials",
          percentage: "23.59%",
          amount: 8845,
        },
        { name: "Gambling", percentage: "34.24%", amount: 12840 },
        { name: "Pornography", percentage: "40.53%", amount: 15195 },
      ],
      RC_three: [
        { name: "Tobacco", percentage: "0.02%", amount: 5 },
        { name: "Marijuana", percentage: "0.02%", amount: 5 },
        {
          name: "Alternative Beliefs",
          percentage: "0.30%",
          amount: 75,
        },
        {
          name: "Lingerie and Swimsuit",
          percentage: "0.58%",
          amount: 145,
        },
        {
          name: "Weapons (Sales)",
          percentage: "0.26%",
          amount: 65,
        },
        {
          name: "Explicit Violence",
          percentage: "0.02%",
          amount: 5,
        },
        {
          name: "Other Adult Materials",
          percentage: "8.30%",
          amount: 2075,
        },
        { name: "Gambling", percentage: "33.56%", amount: 8390 },
        { name: "Pornography", percentage: "56.94%", amount: 14235 },
      ],

      RedBull_four: [
        {
          name: "Explicit Violence",
          percentage: "2.52%",
          amount: 330,
        },
        { name: "Tobacco", percentage: "0.08%", amount: 10 },
        { name: "Marijuana", percentage: "0.19%", amount: 25 },
        {
          name: "Alternative Beliefs",
          percentage: "0.61%",
          amount: 80,
        },
        {
          name: "Weapons (Sales)",
          percentage: "0.57%",
          amount: 75,
        },
        {
          name: "Lingerie and Swimsuit",
          percentage: "0.69%",
          amount: 90,
        },
        { name: "Gambling", percentage: "10.86%", amount: 1425 },
        {
          name: "Other Adult Materials",
          percentage: "58.16%",
          amount: 7630,
        },
        { name: "Pornography", percentage: "26.33%", amount: 3455 },
      ],
      fifth_tenant: [
        {
          name: "Explicit Violence",
          percentage: "15.62%",
          amount: 3304,
        },
        { name: "Tobacco", percentage: "5.04%", amount: 1066 },
        { name: "Marijuana", percentage: "12.02%", amount: 2543 },
        {
          name: "Alternative Beliefs",
          percentage: "0.61%",
          amount: 80,
        },
        {
          name: "Weapons (Sales)",
          percentage: "3.56%",
          amount: 753,
        },
        {
          name: "Lingerie and Swimsuit",
          percentage: "4.26%",
          amount: 901,
        },
        { name: "Gambling", percentage: "6.74%", amount: 1425 },
        {
          name: "Other Adult Materials",
          percentage: "36.06%",
          amount: 7630,
        },
        { name: "Pornography", percentage: "16.33%", amount: 3455 },
      ],
    };
  }

  async getWebFilteringBlockedReason(): Promise<{}> {
    return {
      Coke_one: [
        { name: "Domain is monitored", amount: 593 },
        { name: "URL belongs to a denied category in policy", amount: 1170 },
        { name: "Domain belongs to a denied category in policy", amount: 7325 },
      ],
      Pepsi_two: [
        { name: "Domain is monitored", amount: 3409 },
        { name: "URL belongs to a denied category in policy", amount: 12489 },
        {
          name: "Domain belongs to a denied category in policy",
          amount: 21588,
        },
      ],
      RC_three: [
        { name: "Domain is monitored", amount: 2044 },
        { name: "URL belongs to a denied category in policy", amount: 10452 },
        {
          name: "Domain belongs to a denied category in policy",
          amount: 12504,
        },
      ],

      RedBull_four: [
        { name: "Domain is monitored", amount: 533 },
        { name: "URL belongs to a denied category in policy", amount: 3998 },
        {
          name: "Domain belongs to a denied category in policy",
          amount: 8589,
        },
      ],
      fifth_tenant: [
        { name: "Domain is monitored", amount: 1298 },
        { name: "URL belongs to a denied category in policy", amount: 5032 },
        {
          name: "Domain belongs to a denied category in policy",
          amount: 14827,
        },
      ],
    };
  }

  async getTopHits(): Promise<Array<any>> {
    return [
      {
        url: "betika.com",
        category: "Malicious Websites",
        hits: "2235",
        dest_ip: "137.175.27.8",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "toranoana.jp",
        category: "Other Adult Materials",
        hits: "2234",
        dest_ip: "77.137.105.193",
        message: "Domain belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "notification.tubecup.net",
        category: "Phishing",
        hits: "568",
        dest_ip: "157.90.88.166",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "sex.com",
        category: "Malicious Websites",
        hits: "366",
        dest_ip: "100.200.1.1",
        message: "Domain is monitored",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "sexxx.com",
        category: "Malicious Websites",
        hits: "366",
        dest_ip: "100.200.1.1",
        message: "Domain is monitored",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "xxx.com",
        category: "Pornography",
        hits: "365",
        dest_ip: "213.57.0.255",
        message: "Domain belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "notification.tubecup.net",
        category: "Phishing",
        hits: "288",
        dest_ip: "157.90.88.166",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "l.gotube.pro",
        category: "Phishing",
        hits: "115",
        dest_ip: "157.90.88.166",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "td.jucydate.com",
        category: "Phishing",
        hits: "92",
        dest_ip: "168.119.67.98",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "tracker.city9x.com",
        category: "Gambling",
        hits: "82",
        dest_ip: "77.137.105.29",
        message: "Domain belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "us.urbeautyshooting.com",
        category: "Malicious Websites",
        hits: "49",
        dest_ip: "157.90.88.168",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "wggullplkcq1tgprh6r0sp7g.people-wet.com",
        category: "Malicious Websites",
        hits: "47",
        dest_ip: "157.90.88.168",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "osn.oscam.tv",
        category: "Malicious Websites",
        hits: "45",
        dest_ip: "100.200.1.1",
        message: "Domain is monitored",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "controlcam.push2u.com",
        category: "Malicious Websites",
        hits: "40",
        dest_ip: "137.175.27.8",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "api.whatsflirt.com",
        category: "Malicious Websites",
        hits: "37",
        dest_ip: "137.175.27.8",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "b.minichat.com",
        category: "Malicious Websites",
        hits: "36",
        dest_ip: "100.200.1.1",
        message: "Domain is monitored",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "onlyfans.com",
        category: "Phishing",
        hits: "29",
        dest_ip: "168.119.67.98",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "mg.jucydate.com",
        category: "Phishing",
        hits: "21",
        dest_ip: "157.90.88.166",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "www.eis.de",
        category: "Malicious Websites",
        hits: "21",
        dest_ip: "100.200.1.1",
        message: "Domain is monitored",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
      {
        url: "love-wet.com",
        category: "Phishing",
        hits: "19",
        "Destination ip": "157.90.88.166",
        message: "URL belongs to a denied category in policy",
        dest_continent: "North America",
        dest_ico_code: "US",
      },
    ];
  }

  getSystemsData = () => {
    return [
      {
        name: "string",
        active_node: "string",
        status: "string",
        nodes: [
          {
            id: 0,
            name: "string",
            address: "string",
            ha_domain_name: "string",
            role: "active",
            type: "nsos-500",
            version: "string",
            status: "registered",
            location: "string",
            description: "string",
            secure_type: "auto",
            vendor: "string",
            model: "string",
            serial: "string",
            virtual: "string",
            cpu_type: "string",
            cpu_socket_count: "string",
            cpu_core_per_socket: "string",
            cpu_cores_total: "string",
            mem_total: "string",
            disk_total: "string",
          },
        ],
      },
    ];
  };

  getPublicIpRange = async (): Promise<
    Res<PaginateListResponse<PublicIpManagementRecord>>
  > => {
    return { ok: true, result: IPAM_MOCKED_DATA };
  };
}

export const hardcodedAPI = new HardcodeApi();
