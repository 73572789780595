import { UniqueMetric } from "../types";

export const METRICS_MAP: Array<UniqueMetric> = [
  { metric: "Hostname", value: 0 },
  { metric: "DNS.rrname", value: 0 },
  { metric: "Alert.signature", value: 0 },
  { metric: "Client traffic", value: 0 },
  { metric: "Server traffic", value: 0 },
  { metric: "Source Segments", value: 0 },
  { metric: "Destination Segments", value: 0 },
];

type ResponseType = { [key: string]: { value: number } };

export const parsingSearchUniqueMetricsResponse = (
  response: ResponseType
): Array<UniqueMetric> => {
  const result = METRICS_MAP.map((metric, i) => {
    const value = response[i + 1]?.value;
    return { ...metric, value };
  }, [] as Array<UniqueMetric>);
  return result;
};
