export enum CYBER_THREATS_FILTER_FIELD {
  LOCATION = "locations",
  DST_CONNECTIONS = "dstConnections",
  DST_COUNTRY = "dstCountry",
  SRC_IP = "srcIPs",
  DST_IP = "dstIP",
  THREAT_NAME = "threatName",
  THREAT_CATEGORY = "threatCategory",
  DST_ASN = "dstASN",
}

export const CYBER_THREATS_FIELDS_MAP: Array<string> = [
  CYBER_THREATS_FILTER_FIELD.LOCATION,
  CYBER_THREATS_FILTER_FIELD.DST_CONNECTIONS,
  CYBER_THREATS_FILTER_FIELD.DST_COUNTRY,
  CYBER_THREATS_FILTER_FIELD.SRC_IP,
  CYBER_THREATS_FILTER_FIELD.DST_IP,
  CYBER_THREATS_FILTER_FIELD.THREAT_NAME,
  CYBER_THREATS_FILTER_FIELD.THREAT_CATEGORY,
  CYBER_THREATS_FILTER_FIELD.DST_ASN,
];

export const CYBER_THREATS_FIELD_VALUE: { [key: string]: string } = {
  [CYBER_THREATS_FILTER_FIELD.LOCATION]: "Location",
  [CYBER_THREATS_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connection",
  [CYBER_THREATS_FILTER_FIELD.DST_COUNTRY]: "Destination Country",
  [CYBER_THREATS_FILTER_FIELD.SRC_IP]: "src_ip",
  [CYBER_THREATS_FILTER_FIELD.DST_IP]: "dest_ip",
  [CYBER_THREATS_FILTER_FIELD.THREAT_NAME]: "alert.signature",
  [CYBER_THREATS_FILTER_FIELD.THREAT_CATEGORY]: "alert.category",
  [CYBER_THREATS_FILTER_FIELD.DST_ASN]: "Destination AS Name",
};

export const CYBER_THREATS_FIELD_LABEL: { [key: string]: string } = {
  [CYBER_THREATS_FILTER_FIELD.LOCATION]: "Locations",
  [CYBER_THREATS_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connection",
  [CYBER_THREATS_FILTER_FIELD.DST_COUNTRY]: "Destination Country",
  [CYBER_THREATS_FILTER_FIELD.SRC_IP]: "Source IPs",
  [CYBER_THREATS_FILTER_FIELD.DST_IP]: "Destination IPs",
  [CYBER_THREATS_FILTER_FIELD.THREAT_NAME]: "Therat name",
  [CYBER_THREATS_FILTER_FIELD.THREAT_CATEGORY]: "Threat Category",
  [CYBER_THREATS_FILTER_FIELD.DST_ASN]: "Destination ASN",
};
