import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function VLANIcon({ color, indicatorColor }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {indicatorColor && <circle cx="21" cy="3" r="3" fill={indicatorColor} />}
      <rect
        x="8.666"
        y="4.5"
        width="6.667"
        height="6.667"
        rx="1.4"
        fill={color || FONT}
      />
      <rect
        x="2.833"
        y="13.667"
        width="5"
        height="5"
        rx="1.4"
        fill={color || FONT}
      />
      <rect
        x="16.166"
        y="13.667"
        width="5"
        height="5"
        rx="1.4"
        fill={color || FONT}
      />
      <rect
        x="9.5"
        y="13.667"
        width="5"
        height="5"
        rx="1.4"
        fill={color || FONT}
      />
    </svg>
  );
}

export default VLANIcon;
