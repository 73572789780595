import { FC } from "react";

import InfoIcon from "../../../components/icons/InfoIcon";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";
import styles from "./ServiceInfo.module.scss";
import { useServicesPreferencesContext } from "../ServicesPreferencesContext";

const ServiceInfo: FC = () => {
  const { selectedService } = useServicesPreferencesContext();
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <InfoIcon color={GREY_DISABLED} />
        Information
      </div>
      <div className={styles.info}>{selectedService?.description}</div>
    </div>
  );
};

export default ServiceInfo;
