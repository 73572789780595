export const PORTS_SPEED_MAP: { [key: number]: number } = {
  0: 0,
  1: 10,
  2: 25,
  3: 100,
  4: 400,
};

export const convertPortSpeed = (value: number): string => {
  const speed = PORTS_SPEED_MAP[value];
  return speed > 0 ? speed + "G" : "-";
};
