import React from "react";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { IconProps } from "../../../helpers/types";

export default function BranchIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 34"
      {...formIconAttrs(34, 34, props)}
    >
      <path
        fill="#59536B"
        fillRule="evenodd"
        d="M12.517 3.074a1.7 1.7 0 00-1.7 1.7V6c0 .102.01.203.027.3h-1.23a1.7 1.7 0 00-1.7 1.7v20.258a1.7 1.7 0 001.7 1.7h15.097a1.7 1.7 0 001.7-1.7V8a1.7 1.7 0 00-1.7-1.7h-1.23c.018-.097.027-.198.027-.3V4.774a1.7 1.7 0 00-1.7-1.7h-9.29zm9.29 3.226a.3.3 0 00.3-.3V4.774a.3.3 0 00-.3-.3h-9.29a.3.3 0 00-.3.3V6a.3.3 0 00.3.3h9.29zm-9.29 1.4H9.614a.3.3 0 00-.3.3v20.258a.3.3 0 00.3.3h6.097v-2.816a1 1 0 011-1h1.226a1 1 0 011 1v2.816h5.774a.3.3 0 00.3-.3V8a.3.3 0 00-.3-.3H12.518zm.934 2.203a.645.645 0 00-.645.645v1.29c0 .357.288.646.645.646h1.29a.645.645 0 00.645-.645v-1.29a.645.645 0 00-.645-.646h-1.29zm0 4.516a.645.645 0 00-.645.646v1.29c0 .356.288.645.645.645h1.29a.645.645 0 00.645-.645v-1.29a.645.645 0 00-.645-.646h-1.29zm0 4.517a.645.645 0 00-.645.645v1.29c0 .356.288.645.645.645h1.29a.645.645 0 00.645-.645v-1.29a.645.645 0 00-.645-.645h-1.29zm6.13-9.033a.645.645 0 00-.644.645v1.29c0 .357.288.646.645.646h1.29a.645.645 0 00.645-.645v-1.29a.645.645 0 00-.645-.646h-1.29zm-.644 5.162c0-.357.288-.646.645-.646h1.29c.356 0 .645.29.645.646v1.29a.645.645 0 01-.645.645h-1.29a.645.645 0 01-.645-.645v-1.29zm.645 3.87a.645.645 0 00-.645.646v1.29c0 .356.288.645.645.645h1.29a.645.645 0 00.645-.645v-1.29a.645.645 0 00-.645-.645h-1.29z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
