import * as d3 from "d3";
import { System } from "../../Systems/Provisioning/types";

export function calcCoordinates(
  long: number,
  lat: number
): null | [number, number] {
  const projection = d3.geoMercator();
  const xS = d3.scaleLinear().domain([0, 1000]).range([105, 868]);
  const yS = d3.scaleLinear().domain([0, 1000]).range([154, 910]);
  const pr = projection([long, lat]);
  return pr && [xS(pr[0]), yS(pr[1])];
}

export function simplifyLocation(name: string): string {
  return name?.replace(" ", "").toLowerCase();
}

export function getSystemIndex(systems: Array<System>, system: System): number {
  return systems.findIndex((s) => s.name === system.name);
}

export function getSystemByLoc(
  systems: Array<System>,
  location: string
): System | undefined {
  return systems.find((sys) => {
    return simplifyLocation(sys.location)?.includes(simplifyLocation(location));
  });
}

export function getSystemsListByLoc(
  systems: Array<System> | undefined,
  locations: Array<string>
): Array<System> {
  if (!systems || !locations) return [];
  return systems.filter((system) => {
    return locations.includes(system.location);
  });
}
