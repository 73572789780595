import { configApi } from "../../helpers/api/ConfigApi";
import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { PortType, PortTypeScheme } from "./types";
import { SystemAPI } from "../Systems/Provisioning/types";
import { getPortsSchemeData } from "./helpers/getPortsSchemeData";
import { getFilteredLists } from "./helpers/getFilteredLists";

export const ALLOWED_SYSTEM_TYPE_1 = "NSOS-6000";
export const ALLOWED_SYSTEM_TYPE_2 = "NSOS-500";
export const ALLOWED_SYSTEM_TYPE_3 = "NSOS-v";

type RequestsState = CrudRequestStatuses;

type ValueState = CrudValueState<PortType> & {
  showScheme: boolean;
  schemePortsList?: Array<PortTypeScheme>;
  tooltip?: { id: string; value: any };
  hoveredPort?: PortType;
  systemType?: string;
  systemName?: string;
  filteredLists?: {
    basic: Array<PortTypeScheme>;
    data: Array<PortTypeScheme>;
    lag: Array<PortTypeScheme>;
    backplane: Array<PortTypeScheme>;
  };
  selectedPort?: PortTypeScheme;
};

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<PortType> & {
  setTooltip: (tooltip?: { id: string; value: any }) => void;
  hoverPort: (port?: PortType) => void;
  selectPort: (id?: number) => void;
};

export type IPortsContext = IState & IFunc;
const [Context, usePortsContext, withPortsContextProps] = createContextUtils<
  IState,
  IFunc
>();

export { usePortsContext, withPortsContextProps };

type Props = IOneSystemContext;

class PortsContextContainer extends NewAbstractCrudContext<
  PortType,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;

    const { ok, result } = await this._fetchListWrap(() =>
      configApi.getPortsList(system.name)
    );
    if (ok && result) {
      const showScheme = ((system as unknown) as SystemAPI).nodes?.find((n) => {
        return (
          n?.type?.toLowerCase() === ALLOWED_SYSTEM_TYPE_1.toLowerCase() ||
          n?.type?.toLowerCase() === ALLOWED_SYSTEM_TYPE_2.toLowerCase() ||
          n?.type?.toLowerCase() === ALLOWED_SYSTEM_TYPE_3.toLowerCase()
        );
      });
      const schemePortsList = getPortsSchemeData(result);
      const filteredLists = getFilteredLists(schemePortsList);
      this.setState({
        showScheme: !!showScheme,
        systemType: showScheme?.type,
        systemName: system.name,
        schemePortsList,
        filteredLists,
      });
    }
  };

  edit = async (port: PortType): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() =>
      configApi.editAlias(system.name, port.id + "", { alias: port.alias })
    );
    return ok;
  };

  setTooltip = (tooltip?: { id: string; value: any }) => {
    this.setState({ tooltip });
  };

  hoverPort = (hoveredPort?: PortType): void => {
    this.setState({ hoveredPort });
  };

  selectPort = (id?: number): void => {
    const selectedPort = this.state.schemePortsList?.find(
      (port) => port.id === id
    );
    this.setState({ selectedPort });
  };

  // not exist
  remove = async (): Promise<boolean> => {
    return false;
  };

  funcs = {
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
    setTooltip: this.setTooltip,
    hoverPort: this.hoverPort,
    selectPort: this.selectPort,
  };
}

export default withOneSystemContextProps(PortsContextContainer);
