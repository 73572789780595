import React, { FC } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import TextWithIcon from "../../../../../../components/common/table/TextWithIcon";
import RouteMapIcon from "../../../../../../components/icons/RouteMapIcon";
import { GREY_FONT } from "../../../../../../helpers/common/colorAliases";
import UnifiedTextPair from "../../../../../../components/common/UnifiedTextPair";
import styles from "./RouteMapInfo.module.scss";

type Props = { className: string; name: string };

const RouteMapInfo: FC<Props> = ({ className, name }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={<RouteMapIcon color={GREY_FONT} />}
        text={"Route Filter"}
        className={styles.header}
      />
      <div className={styles.content}>
        <UnifiedTextPair subtitle={"Name"} text={name} textSize={"medium"} />
      </div>
    </div>
  );
};

export default RouteMapInfo;
