import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { CRITICAL } from "../../helpers/common/colorAliases";

function CrossIcon({
  color,
  height = 20,
  width = 20,
  strokeWidth = 2,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="9"
        stroke={color || CRITICAL}
        strokeWidth={strokeWidth}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89461 5.4843C6.50408 5.09378 5.87092 5.09378 5.48039 5.4843C5.08987 5.87482 5.08987 6.50799 5.48039 6.89851L8.58281 10.0009L5.48039 13.1033C5.08987 13.4939 5.08987 14.127 5.48039 14.5176C5.87092 14.9081 6.50408 14.9081 6.89461 14.5176L9.99702 11.4151L13.0994 14.5176C13.49 14.9081 14.1231 14.9081 14.5137 14.5176C14.9042 14.127 14.9042 13.4939 14.5137 13.1033L11.4112 10.0009L14.5137 6.89851C14.9042 6.50799 14.9042 5.87482 14.5137 5.4843C14.1231 5.09378 13.49 5.09378 13.0994 5.4843L9.99702 8.58672L6.89461 5.4843Z"
        fill={color || CRITICAL}
      />
    </svg>
  );
}

export default CrossIcon;
