import React, { FC } from "react";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import styles from "./Search.module.scss";
import { useHistory } from "react-router-dom";
import { SEARCH } from "../../../../../helpers/navigation/entries";
import SearchIcon from "../../../../icons/SearchIcon";

type Props = {};

const Search: FC<Props> = () => {
  const history = useHistory();
  return (
    <IconBlankButton
      id={"search"}
      icon={SearchIcon}
      label={"Search"}
      onClick={() => history.replace("/" + SEARCH().path)}
      className={styles.btn}
    />
  );
};

export default Search;
