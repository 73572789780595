import { ReactElement } from "react";
import styles from "./NavigationTabs.module.scss";
import { PathEntry } from "../../../helpers/types";
import { useHistory, useLocation } from "react-router-dom";
import { isActivePath } from "../../../helpers/navigation";
import { classNames } from "../../../helpers/common/classNames";
import BlankButton from "../buttons/BlankButton";
import ReactTooltip from "react-tooltip";

export default function NavigationTabs({
  entries,
  disabledTabKeys,
  separatorBefore,
  onClick,
  tabsHint,
  semiDisabled,
  onStringClick,
  active,
  className,
}: {
  entries: Array<PathEntry | string>;
  disabledTabKeys?: Array<string>;
  separatorBefore?: string;
  onClick?: (arg: PathEntry) => any;
  onStringClick?: (arg: string) => any;
  tabsHint?: { [key: string]: string };
  semiDisabled?: boolean;
  active?: string;
  className?: string;
}): ReactElement {
  const location = useLocation();
  const history = useHistory();

  const handleClick = (entry: PathEntry | string) => {
    if (typeof entry === "string" && onStringClick) {
      onStringClick(entry);
      return;
    } else if (onClick) {
      onClick(entry as PathEntry);
    } else history.push((entry as PathEntry).path);
  };

  return (
    <div className={classNames(styles.tabs, className)}>
      {entries.map((entry) => {
        const path = typeof entry === "string" ? entry : entry.path;
        const content = typeof entry === "string" ? entry : entry.content;

        const disabled = disabledTabKeys?.includes(path);
        const isSeparated = separatorBefore === path;
        return (
          <>
            {isSeparated && <div className={styles.separator} />}
            <div
              data-tip
              data-for={path}
              className={classNames(
                styles.tab,
                disabled && styles.disabled,
                semiDisabled && styles.semiDisabled,
                (active === path || isActivePath(location, path)) &&
                  styles.active
              )}
            >
              <BlankButton
                className={styles.btn}
                id={path}
                key={path}
                onClick={disabled ? (f) => f : () => handleClick(entry)}
              >
                {content}
              </BlankButton>
            </div>
            {tabsHint && tabsHint[path] && (
              <ReactTooltip
                id={path}
                aria-haspopup="true"
                place="bottom"
                type="light"
                className={styles.tooltip}
              >
                {tabsHint[path]}
              </ReactTooltip>
            )}
          </>
        );
      })}
    </div>
  );
}
