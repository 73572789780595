import React from "react";
import { CellProps } from "react-table";
import { UserRow } from "../../types/table";
import { UserThumbnail } from "../shared/UserThumbnail";
import { User } from "../../../../helpers/types";
import { CellFactory } from "../../../../components/common/table/newTable/cells/CellsFactory";
import LabelArray from "../../../../components/common/table/LabelArray";
import { Checkbox } from "../../../../components/common/formComponents/Checkbox";
import SelectRow from "../../../Systems/Provisioning/table/SelectRowComponent";
import UserRoleLabel from "../../../../components/common/table/UserRoleLabel";
import { Toggle } from "../../../../components/common/Toggle";
import StatusIcon from "../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";
import Label from "../../../../components/common/table/Label";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./UserTable.module.scss";

const cellFactory = new CellFactory<UserRow>({});

export const UserTableColumns = (
  onClick: (row: User) => void,
  onEdit: (field: string, value: boolean, username: string, user: User) => void,
  isGlobalAdmin: boolean,
  selectedUser?: string
): Array<any> => [
  {
    accessor: "enabled",
    Header: "Enabled",
    Cell: ({ row: { original }, cell: { value } }: CellProps<User>) => (
      <Toggle
        isChecked={value}
        onChange={() => {
          onEdit("enabled", !value, original.username, original);
        }}
      />
    ),
  },
  {
    accessor: "username",
    Header: "Username",
    Cell: ({ cell: { value } }: { cell: { value: string } }) => {
      return <UserThumbnail username={value} />;
    },
  },
  {
    accessor: "email",
    Header: "Email",
    Cell: cellFactory.textCell(),
  },
  {
    accessor: "role",
    Header: "Role",
    Cell: ({ cell: { value } }: CellProps<User>) => {
      return <UserRoleLabel role={value} />;
    },
  },
  {
    accessor: "full_name",
    Header: "Full Name",
    Cell: ({ cell }: CellProps<User>) => {
      const { first_name, last_name } = cell.row.original;
      const fullnameIsExisted = first_name && last_name;
      const full_name = `${first_name} ${last_name}`;

      return fullnameIsExisted ? <UserThumbnail username={full_name} /> : <></>;
    },
  },
  {
    accessor: "user_groups",
    Header: "User Groups",
    Cell: ({ cell }: CellProps<User>) => {
      const { user_groups } = cell.row.original;
      return <LabelArray values={user_groups} />;
    },
  },
  {
    accessor: "is_remote_user",
    Header: "Remote User",
    Cell: ({ row: { original }, cell: { value } }: CellProps<User>) => {
      if (isGlobalAdmin) return "";
      return (
        <Checkbox
          isChecked={value}
          disabled={original.role.toLowerCase() === "remote_user"}
          onChange={() =>
            onEdit("is_remote_user", !value, original.username, original)
          }
        />
      );
    },
  },
  {
    accessor: "connection_status",
    Header: "Remote Connection",
    Cell: ({ cell }: CellProps<User>) => {
      const { tunnel_status } = cell.row.original;
      const tunnelStatusesMap: any = {
        DOWN: "Disconnected",
        UP: "Connected",
        IDLE: "Idle",
        NODATA: "",
      };
      if (isGlobalAdmin || tunnel_status != ("UP" || "IDLE")) return "";
      return (
        <Label>
          <div className={styles.labels}>
            <span className={styles.iconWrapper}>
              <StatusIcon
                color={getColorFromStatus(tunnel_status?.toLowerCase() || "")}
              />
            </span>
            <span className={styles.value}>
              {tunnel_status != undefined
                ? tunnelStatusesMap[tunnel_status]
                : ""}
            </span>
          </div>
        </Label>
      );
    },
  },
  {
    accessor: "selected",
    Header: "",
    Cell: ({ row: { original } }: CellProps<User>) => {
      if (!original.is_remote_user) return "";
      return (
        <SelectRow
          onSelect={() => onClick(original)}
          currentRow={original.username}
          selected={selectedUser}
          withPropaganation
        />
      );
    },
  },
];
