import React, { FC, useEffect } from "react";
import withUserRole from "../../../../helpers/hocs/withUserRole";
import { mapStringToItem } from "../../../../helpers/mapStringToItem";
import { UserRoles } from "../../../../helpers/rolesHelpers";
import DropdownWithSearch from "../../Dropdown/DropdownWithSearch";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTenantsList } from "../../../../helpers/api/hooks/useTenantsList";

type Props = {
  defaultTenants?: Array<{
    name: string;
  }>;
};

const ActiveTenantSelector: FC<Props> = ({ defaultTenants = [] }) => {
  const { setSelectedTenant, getSelectedTenant } = useGlobalFilterContext();
  const [list] = useTenantsList();

  const tenantsFromApi = list || [];

  const tenantList = [...defaultTenants, ...tenantsFromApi];

  const selectedTenant = getSelectedTenant();

  useEffect(() => {
    setSelectedTenant(selectedTenant);
  }, []);

  useEffect(() => {
    if (!isSelectedTenantStillInlist() && tenantList.length > 0 && list) {
      setSelectedTenant(tenantList?.[0].name);
    }
  }, [tenantList]);

  const isSelectedTenantStillInlist = () => {
    for (let i = 0; i < tenantList.length; i++) {
      if (tenantList[i].name === selectedTenant) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (tenantList?.length && !selectedTenant) {
      setSelectedTenant(tenantList[0]?.name);
    }
  }, [list]);

  return (
    <DropdownWithSearch
      id={"activeTenantSelector"}
      onChange={(val) => {
        setSelectedTenant(val.key);
      }}
      selected={mapStringToItem(selectedTenant || "")}
      placeholder={selectedTenant || ""}
      error={""}
      valuesList={(tenantList || []).map((tenant) => tenant.name)}
      isMedium
    />
  );
};

export default withUserRole(ActiveTenantSelector, {
  roles: [UserRoles.ADMIN_FULL, UserRoles.ADMIN],
});
