import { System, SystemAPI } from "../pages/Systems/Provisioning/types";

export const appendActiveNodeData = async (
  systems?: Array<SystemAPI>
): Promise<Array<System>> => {
  if (!systems || systems.length === 0) return [];
  const copy: Array<System> = [];
  systems.map((system) => {
    const activeNodeData = system?.nodes?.find(
      (node) => node.name === system.active_node
    );
    if (activeNodeData) {
      const nodes = system.nodes || [];
      const subRows = [
        ...nodes.map((node) => ({ ...node, system: system.name })),
      ];
      const { name, active_node, status, max_nodes } = system;
      copy.push({
        ...activeNodeData,
        subRows,
        name,
        active_node,
        status,
        max_nodes,
      });
    }
  });
  return copy;
};

export const configAccessor = async (
  systems?: Array<SystemAPI>
): Promise<any> => {
  if (!systems || systems.length === 0) return "";

  const systemsWithConf = systems.filter((system) => system.wg_conf);
  if (systemsWithConf.length === 0) return "";

  return systemsWithConf[0].wg_conf.public_ip;
};

export const configSystemAccessor = async (
  systems?: Array<SystemAPI>
): Promise<any> => {
  if (!systems || systems.length === 0) return "";

  return systems[0].name;
};
