import React, { FC } from "react";
import { ConnectionPortDetails } from "../../../types";
import ConfigFields from "./components/ConfigFields";
import { classNames } from "../../../../../../helpers/common/classNames";
import PortTitle from "./components/PortTitle";
import RowControls from "./components/RowControls";
import PortTypeFlag from "./components/PortTypeFlag";

import styles from "./ConnectionTable.module.scss";

const PORTS_TITLES = ['"A" Port', '"Z" Port'];

type Props = {
  ports: Array<ConnectionPortDetails>;
  vpc?: ConnectionPortDetails;
  onDelete?: (row: any) => void;
  allFields: Array<string>;
  showDiagram: boolean;
  toggleDiagram: () => void;
  className?: string;
};
const ConnectionTable: FC<Props> = ({
  ports,
  vpc,
  onDelete,
  allFields,
  showDiagram,
  toggleDiagram,
  className,
}) => {
  const wrapperClassNames = classNames(styles.wrapper, className);
  const rowClassNames = classNames(styles.row, !vpc && styles.withPortFlag);

  return (
    <div className={wrapperClassNames}>
      {ports.map((port, i) => (
        <div className={rowClassNames} key={port.name + i}>
          <PortTitle data={port} className={styles.rowTitle} />
          <ConfigFields
            data={port}
            className={styles.rowContent}
            onDelete={onDelete}
            allFields={allFields}
            isVPCType={!!vpc}
            isShortcuted={showDiagram}
          />
          {!vpc && (
            <PortTypeFlag flag={PORTS_TITLES[i]} className={styles.portFlag} />
          )}
        </div>
      ))}
      <RowControls
        showDiagram={showDiagram}
        toggleDiagram={toggleDiagram}
        isCloudRouter={vpc?.endpoint_type === "Cloud_Router"}
        className={classNames(vpc && styles.rowControls)}
      />
      {vpc && (
        <PortTypeFlag flag={vpc.endpoint_type} className={styles.portFlag} />
      )}
    </div>
  );
};

export default ConnectionTable;
