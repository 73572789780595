import React, { FC } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";

type Props = {
  field: string;
  onChange: (idx: number, value: string) => void;
  error?: string;
  idx: number;
};

const BWDropdown: FC<Props> = ({ field, onChange, error, idx }) => {
  return (
    <DropdownBasic
      id={"BW"}
      onChange={(val) => {
        onChange(idx, val.key);
      }}
      placeholder={"Select BW"}
      selected={mapStringToItem(field)}
      label="BW (Mbps)"
      itemsList={["100", "200", "300", "400", "500"].map(mapStringToItem)}
      isMedium
      error={error}
    />
  );
};
export default BWDropdown;
