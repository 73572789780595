import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../../contexts/common/AbstractCrudContext";
import { elasticApi } from "../../../../helpers/api/ElasticApi";
import { RequestStatus } from "../../../../helpers/types";
import { EVENT_START_TIME } from "../../../../contexts/AuthContext";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = { eventsAmount: number };

type IState = State & RequestStatuses;
type IFunc = { fetchList: (tenant?: string) => void };

const [EventsIconContext, useContext] = createContextAndUse<IState, IFunc>();
export const useEventsIconContext = useContext;

export default class EventsIconContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = EventsIconContext;

  fetchList = async (tenant?: string): Promise<void> => {
    const to = new Date().getTime();
    const from = +(localStorage.getItem(EVENT_START_TIME) || to - 30_000);
    const type = tenant ? "tenant" : "systems";
    const { result } = await elasticApi.getEventsByType(
      type,
      "20",
      undefined,
      undefined,
      { from, to }
    );
    this.setState({ eventsAmount: result?.length });
  };

  funcs = {
    fetchList: this.fetchList,
  };
}
