import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function VRFIcon({ color, indicatorColor }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {indicatorColor && <circle cx="21" cy="3" r="3" fill={indicatorColor} />}
      <path
        d="M13 5h-2V3H9v2H7v1l3 2 3-2V5ZM7 15h2v3h2v-3h2v-1l-3-2-3 2v1ZM5 13v-2h3V9H5V7l-2 3 2 3ZM15 7v2h-3v2h3v2l2-3-2-3Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default VRFIcon;
