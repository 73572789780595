import React, { FC } from "react";
import styles from "./Layer3InterfacePage.module.scss";
import { withLayout } from "../../helpers/hocs/withLayout";
import { CollapsableTwoColumnLayout } from "../../components/leftInfoBlock/LayoutTwoColumnFactory";
import { PageProps } from "../../helpers/types";
import TenantInfo from "../../components/tenants/OneTenant/TenantInfo";
import Layer3InterfaceContent from "./Layer3InterfaceContent";

const Layer3InterfacePage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <CollapsableTwoColumnLayout InfoBlock={() => <TenantInfo />}>
      <div className={styles.contentWrapper}>
        <Tabs />
        <Layer3InterfaceContent />
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default withLayout<PageProps>(Layer3InterfacePage);
