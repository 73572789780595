import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { configApi } from "../../helpers/api/ConfigApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { getProcessedFields } from "./helpers/getProcessedFields";
import {
  GlobalRouteFilters,
  UserRouteFilters,
  UserRouteFiltersAdd,
} from "./types";

type RequestStatuses = {
  listStatus?: RequestStatus;
  addStatus?: RequestStatus;
  removeStatus?: RequestStatus;
  routeFilterStatus?: RequestStatus;
  editStatus?: RequestStatus;
};
type State = {
  list: { global: Array<GlobalRouteFilters>; user: Array<UserRouteFilters> };
  amount: number;
  routeFilter?: UserRouteFilters;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (tenant: string) => Promise<void>;
  add: (tenant: string, fields: UserRouteFiltersAdd) => Promise<boolean>;
  remove: (name: string, tenant?: string) => Promise<boolean>;
  getRouteFilter: (tenant: string, name: string) => Promise<void>;
  edit: (tenant: string, fields: UserRouteFiltersAdd) => Promise<boolean>;
};

const [RouteFiltersContext, useContext] = createContextAndUse<IState, IFunc>();
export const useRouteFiltersContext = useContext;

export default class RouteFiltersContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = RouteFiltersContext;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = { list: { global: [], user: [] }, amount: 0 };
  }

  fetchList = async (tenantName: string): Promise<void> => {
    this.setState({ listStatus: setPending() });
    const resGlobal = await configApi.getSystemBgpPfxList();
    const resUser = await configApi.getServiceBgpPfxList(tenantName);

    if (resGlobal.ok && resGlobal.result) {
      this.setState({
        list: { ...this.state.list, global: resGlobal.result },
        amount: this.state.amount + (resGlobal.count || 0),
      });
    }
    if (resUser.ok && resUser.result) {
      this.setState({
        list: { ...this.state.list, user: resUser.result },
        amount: this.state.amount + (resUser.count || 0),
      });
    }
    this.setState({ listStatus: resToState(resUser) });
  };

  add = async (
    tenant: string,
    fields: UserRouteFiltersAdd
  ): Promise<boolean> => {
    const payload = getProcessedFields(fields);
    this.setState({ addStatus: setPending() });
    const res = await configApi.addServiceBgpPfx(tenant, payload);
    if (res.ok) {
      this.fetchList(tenant);
    }
    this.setState({ addStatus: resToState(res), editStatus: resToState(res) });
    return res.ok;
  };

  remove = async (name: string, tenant?: string): Promise<boolean> => {
    if (!tenant) return false;

    this.setState({ removeStatus: setPending() });
    const res = await configApi.deleteServiceBgpPfx(tenant, name);
    if (res.ok) {
      this.fetchList(tenant);
    }
    this.setState({
      removeStatus: resToState(res),
      editStatus: resToState(res),
    });
    return res.ok;
  };

  getRouteFilter = async (tenant: string, name: string): Promise<void> => {
    this.setState({ routeFilterStatus: setPending() });
    const res = await configApi.getServiceBgpPfx(tenant, name);
    if (res.ok && res.result) {
      this.setState({ routeFilter: res.result });
    }
    this.setState({ routeFilterStatus: resToState(res) });
  };

  edit = async (
    tenant: string,
    fields: UserRouteFiltersAdd
  ): Promise<boolean> => {
    this.setState({ editStatus: setPending() });
    const removeRes = await this.remove(fields.name, tenant);
    if (!removeRes) return removeRes;

    const addRes = await this.add(tenant, fields);
    return addRes;
  };

  funcs = {
    fetchList: this.fetchList,
    add: this.add,
    remove: this.remove,
    getRouteFilter: this.getRouteFilter,
    edit: this.edit,
  };
}
