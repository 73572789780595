export const getUpdatedFields = <T extends { [key: string]: any }>(
  fields: T,
  name: keyof T,
  value: string | Array<string> | {} | undefined
) => {
  return {
    ...fields,
    [name]: value,
  };
};
