import React, { FC } from "react";
import { CreatePBR } from "./PBRConfig";
import DropdownWithSearch from "../../../components/common/Dropdown/DropdownWithSearch";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { DropdownItem } from "../../../helpers/types";
import styles from "./SourceBlock.module.scss";
import { UserGroups } from "../../../contexts/servicesContext/FirewallContext";
import GlobeIconLessLines from "../../../components/icons/GlobeIconLessLines";

type Props = {
  fields: CreatePBR;
  errors: any;
  onChange: (value: string, name: string) => void;
  groups: UserGroups | undefined;
};

const SourceBlock: FC<Props> = ({ fields, errors, onChange, groups }) => {
  const label = "Source";
  let selected;

  const handleChange = (val: DropdownItem | string) => {
    if (typeof val === "string") {
      selected = val;
      onChange("src_network", selected);
      onChange("src_group", "");
    } else {
      selected = val.key;
      onChange("src_group", selected);
      onChange("src_network", "");
    }
  };

  return (
    <DropdownWithSearch
      id={label}
      placeholder={fields.src_group || fields.src_network || "IP, Group"}
      label={label}
      onChange={handleChange}
      handleFieldChange={onChange}
      selected={mapStringToItem(fields.src_group || fields.src_network || "")}
      error={errors && errors["src_network"]}
      valuesList={groups?.tenant || []}
      isMedium
      isHideIcon={!!fields.src_group || !!fields.src_network}
      isOptionalLabel
      listClassName={styles.sourceWrapper}
      additionalValuesList={groups?.global}
      valuesWithLabel
      valuesLabel="Groups"
      additionalIcon={GlobeIconLessLines({})}
      withClearIcon
      hideEmptyListPlaceholder
    />
  );
};
export default SourceBlock;
