import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ThreeDotsIcon({ color }: IconProps): ReactElement {
  return (
    <svg width="15" height="3" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="1.5"
        cy="1.5"
        r="1.5"
        transform="rotate(-90 1.5 1.5)"
        fill={color || FONT}
      />
      <circle
        cx="7.5"
        cy="1.5"
        r="1.5"
        transform="rotate(-90 7.5 1.5)"
        fill={color || FONT}
      />
      <circle
        cx="13.5"
        cy="1.5"
        r="1.5"
        transform="rotate(-90 13.5 1.5)"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ThreeDotsIcon;
