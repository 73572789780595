import { FC, useState } from "react";
import ChecklistComponent from "../common/ChecklistComponent";
import AddFormComponent from "../common/AddFormComponent";
import GlobeIcon from "../../../../../components/icons/GlobeIcon";
import styles from "./BlockSelectedContainer.module.scss";
import { FONT } from "../../../../../helpers/common/colorAliases";
import {
  BLOCK_SELECTED_CHECKLISTS_MAP,
  WEB_FILTERING_ENTITIES,
} from "../../../helpers/consts";
import BlankButton from "../../../../../components/common/buttons/BlankButton";
import { useWebFilteringsContext } from "../../../WebFilteringContext";
import { generateListItemId } from "../../../helpers/generateListItemId";
import { FDQNDomain, FilterType } from "../../../types";

type Props = {};

const BlockSelectedContainer: FC<Props> = () => {
  const {
    allowDomains,
    domainsList,
    addGenerslWebFilterSettings,
    removeGenerslWebFilterSettings,
  } = useWebFilteringsContext();
  const [url, setUrl] = useState<string>("");
  const id = WEB_FILTERING_ENTITIES.BLOCK_SELECTED;

  const handleRemoveItem = (item: FDQNDomain) => {
    removeGenerslWebFilterSettings(id, item);
  };

  const handleAddItem = (type: FilterType) => {
    const itemId = generateListItemId(allowDomains);
    const newItem = { type, domain: url, id: itemId };
    addGenerslWebFilterSettings(id, newItem);
    setUrl("");
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.listsContainer}>
        {BLOCK_SELECTED_CHECKLISTS_MAP.map((item) => (
          <ChecklistComponent data={item} key={item.id} />
        ))}
      </div>
      <div className={styles.sectionHeader}>
        <GlobeIcon color={FONT} />
        <span>General Web Filter Settings</span>
      </div>
      <AddFormComponent
        removeItem={handleRemoveItem}
        urlValue={url}
        onChange={setUrl}
        className={styles.formContainer}
        btns={[
          <BlankButton
            id={"allowBtn"}
            onClick={() => handleAddItem("allow")}
            className={styles.allowBtn}
          >
            Allow
          </BlankButton>,
          <BlankButton
            id={"blockBtn"}
            onClick={() => handleAddItem("block")}
            className={styles.blockBtn}
          >
            Block
          </BlankButton>,
        ]}
        list={domainsList}
      />
    </div>
  );
};

export default BlockSelectedContainer;
