import React, { FC } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./RowControls.module.scss";

type RowControlsProps = {
  isExtraGap?: boolean;
};

const RowControls: FC<RowControlsProps> = ({ children, isExtraGap }) => {
  const rowStyles = classNames(
    styles.container,
    isExtraGap && styles.lastChildExtraGap
  );
  return <div className={rowStyles}>{children}</div>;
};

export default RowControls;
