import React, { FC } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import Input from "../../../../../../components/common/formComponents/Input";
import ConnectionTypeDropdown from "../../common/formComponents/ConnectionTypeDropdown";
import Menu from "../../common/Menu";
import { A_PORT_MENU } from "../../consts";
import styles from "./LeftBlock.module.scss";

type Props = {
  connectionName: string;
  onChangeConnectionName: (value: string) => void;
  selected: string;
  onSelect: (item: string) => void;
  error?: string;
  isEdit?: boolean;
  className?: string;
};

const LeftBlock: FC<Props> = ({
  connectionName,
  onChangeConnectionName,
  selected,
  onSelect,
  error,
  isEdit,
  className,
}) => {
  const wrapperClasses = classNames(styles.wrapper, className);
  return (
    <div className={wrapperClasses}>
      <div className={styles.title}>
        <Input
          label="Connection Name"
          name="connectionName"
          placeholder=""
          value={connectionName}
          medium
          error={error}
          onChange={(e) => onChangeConnectionName(e.target.value)}
          isNotEditable={isEdit}
        />
        <ConnectionTypeDropdown isNotEditable={isEdit} />
      </div>
      <Menu
        list={A_PORT_MENU}
        selected={selected}
        onSelect={onSelect}
        isNotEditable={isEdit}
      />
    </div>
  );
};

export default LeftBlock;
