import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { LILAC } from "../../helpers/common/colorAliases";

function WarningIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      className={className}
    >
      <path
        fill={color || LILAC}
        fillRule="evenodd"
        d="M9.128 1.328a1 1 0 011.744 0l8.356 14.897a1 1 0 01-.872 1.49H1.644a1 1 0 01-.872-1.49L9.128 1.328zM8.562 14.23a1 1 0 011-1h.876a1 1 0 011 1v.99a1 1 0 01-1 1h-.876a1 1 0 01-1-1v-.99zm1-8.476a1 1 0 00-1 1v3.98a1 1 0 001 1h.876a1 1 0 001-1v-3.98a1 1 0 00-1-1h-.876z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default WarningIcon;
