import { FC, useEffect } from "react";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import DialogTemplate from "../../../dialogs/common/DialogTemplate";
import CodeEditor from "../../CodeEditor";
import SummaryInfoBlock from "../../../leftInfoBlock/SummaryInfoBlock";
import SettingsIcon from "../../../icons/SettingsIcon";
import UnifiedTextPair from "../../UnifiedTextPair";
import { useWireGuardContext } from "../WireGuardContext";

type Props = {
  onClose: () => void;
  user?: string;
  tenant?: string;
};

const WireGuardConfigDialog: FC<Props> = ({ onClose, user, tenant }) => {
  const {
    fetchDataNew,
    fetchStatus,
    wireGuardSettings,
    clearWGSettings,
  } = useWireGuardContext();
  const [fields, handleFieldChange, resetFields] = useFormField<any>({
    conf: "",
  });

  useEffect(() => {
    if (user && tenant) {
      fetchDataNew(tenant, user);
    }
  }, [user, tenant]);

  useEffect(() => {
    if (wireGuardSettings) {
      resetFields({ conf: wireGuardSettings });
    }
  }, [wireGuardSettings]);

  useEffect(() => {
    return () => {
      clearWGSettings();
    };
  }, []);
  const controls = {
    okText: "Ok",
    onOk: onClose,
  };
  return (
    <DialogTemplate
      title={"Config"}
      onClose={onClose}
      controls={controls}
      withoutLine
      leftColumn={<LeftColumn />}
    >
      <CodeEditor
        value={fields.conf || ""}
        onChange={handleFieldChange}
        status={fetchStatus}
      />
    </DialogTemplate>
  );
};

export default WireGuardConfigDialog;

const LeftColumn: FC = () => {
  const { fetchVpnIp, publicIp } = useWireGuardContext();
  useEffect(() => {
    fetchVpnIp();
  }, []);
  return (
    <SummaryInfoBlock
      icon={<SettingsIcon />}
      title={"Configuration"}
      // footer={<BlankButton id="downloadFile">Download file</BlankButton>}
      isSmallerIcon
    >
      <UnifiedTextPair subtitle="VPN IP" text={publicIp} />
    </SummaryInfoBlock>
  );
};
