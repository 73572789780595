import { AbstractContextProvider } from "../common/AbstractContext";
import { createContextAndUse } from "../common/utils";
import {
  GTimeRange,
  formGTimeRange,
} from "../../components/common/charts/GraphanaLinksFactory";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import {
  HAFullLog,
  HALog,
  HANode,
} from "../../components/systems/oneSystem/HAHealth/types";
import { SystemAPI, SystemNode } from "../../pages/Systems/Provisioning/types";
import { systemApi } from "../../helpers/api/SystemApi";
import { getTimefromSec } from "../../helpers/getTimefromSec";
import { getDateShortMonth } from "../../helpers/common/dateHelpers";

type RequestStatuses = {
  state: "pending" | "ok" | "error" | "idle";
  message?: string;
};
type State = {
  systemFromHA: SystemAPI | undefined;
  list_of_node?: Array<string>;
  node?: SystemNode;
  nodeInfo?: HANode;
  logsArray?: Array<HALog>;
  selectedLog?: HALog | HAFullLog;
  lastFailover?: string;
  getNodeStatus: RequestStatus;
  selectedTimeRange: GTimeRange | undefined;
  logRequestStatus?: RequestStatus;
  trafficPorts?: number;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchNodeInfo: (node: string, timeRange?: GTimeRange) => Promise<void>;
  fetchLogsInfo: (node: string, timeRange?: GTimeRange) => Promise<void>;
  fetchSystem: (system: string) => Promise<void>;
  fetchLastFailover: (
    activeNode: string,
    standbyNode: string,
    timeRange: GTimeRange
  ) => Promise<void>;
  fetchTrafficPorts: (system: string) => Promise<void>;
  updateTrafficPorts: (system: string, portsCount: number) => Promise<void>;
  manualFailover: (system: string, standbyNode: string) => Promise<boolean>;
  selectLog: (id?: number) => void;
};

const [HAHealthContext, useContext] = createContextAndUse<IState, IFunc>();
export const useHAHealthContext = useContext;

export default class HAHealthContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = HAHealthContext;

  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchSystem = async (system: string): Promise<void> => {
    this.setState({ getNodeStatus: setPending("Fetching") });
    const res = await systemApi.getSystemById(system);

    if (res?.result) {
      const activeNode = res?.result?.nodes?.find(
        (node: any) => node.role === "active"
      );
      const standbyNode = res?.result?.nodes?.find(
        (node: any) => node.role === "standby"
      );

      if (activeNode && standbyNode) {
        this.fetchLastFailover(activeNode.name, standbyNode.name, {
          from: "now-10080m",
          to: "now",
        });
      }
    }

    this.setState({
      systemFromHA: res.result,
      getNodeStatus: resToState(res),
    });
  };

  fetchTrafficPorts = async (system: string): Promise<void> => {
    this.setState({ getNodeStatus: setPending("Fetching") });
    const res = await systemApi.getHaConf(system);
    this.setState({
      getNodeStatus: resToState(res),
      trafficPorts: res.result.min_ports_up,
    });
  };

  updateTrafficPorts = async (
    system: string,
    portsCount: number
  ): Promise<void> => {
    this.setState({ getNodeStatus: setPending("Fetching") });
    const res = await systemApi.updateHaConf(system, {
      min_ports_up: portsCount,
    });
    this.setState({
      getNodeStatus: resToState(res),
    });
  };

  fetchNodeInfo = async (node: string): Promise<void> => {
    this.setState({ logRequestStatus: setPending("Fetching") });
    const timeRangeMy: GTimeRange = formGTimeRange(60 * 48 * 4);
    const res = await elasticApi.getHAHealthInfo(node, timeRangeMy);
    if (res.result.length === 0) return;
    const fieldsRes = res.result[0].fields;
    const neededFields: any = {
      "score_values.backplane_port_down": "backplane_ports_down",
      "score_values.backplane_ports": "backplane_ports_up",
      "score_values.cpu_max": "cpu_load",
      "score_values.data_ports_down": "traffic_ports_down",
      "score_values.dataplane_ports": "traffic_ports_up",
      "score_values.ipkt_log_diskfree": "disk_free",
      "score_values.mgmnt_port_status": "oob_link",
      "score_values.niro_heartbeat": "niro_heartbeat",
      "score_values.nos_service": "dataplane_health",
      "score_values.nsos_status": "nsos_software",
      "score_values.nv_diskfree": "disk_free",
      "score_values.wb_status": "white_box",
      "score_values.fan_redundancy": "fan_redundancy",
      "score_values.temp_in": "temp_in",
      "score_values.temp_out": "temp_out",
      "score_values.power_redundancy": "power_supply",
      "score_values.raid_status": "raid",
    };

    const nodeInfo: any = {};

    for (const key in fieldsRes) {
      if (key in neededFields) {
        nodeInfo[neededFields[key]] = fieldsRes[key][0];
      }
    }
    nodeInfo["node_name"] = node;

    let diskFreeText = "loading";
    nodeInfo["disk_free"] === true
      ? (diskFreeText = "ok")
      : (diskFreeText = "warning");
    nodeInfo["disk_free"] = diskFreeText;

    let cpuMaxText = "loading";
    nodeInfo["cpu_load"] === true
      ? (cpuMaxText = "ok")
      : (cpuMaxText = "warning");
    nodeInfo["cpu_load"] = cpuMaxText;

    let whiteboxText = "loading";
    nodeInfo["white_box"] === "available" || "none"
      ? (whiteboxText = "ok")
      : (whiteboxText = "down");
    nodeInfo["white_box"] = whiteboxText;

    nodeInfo["niroHeartbeatIcon"] = "green";

    if (nodeInfo["niro_heartbeat"]) {
      nodeInfo["niro_heartbeat"] < 12
        ? (nodeInfo["niroHeartbeatIcon"] = "green")
        : (nodeInfo["niroHeartbeatIcon"] = "orange");
    }

    const niroHeartbeatText = getTimefromSec(nodeInfo["niro_heartbeat"]);
    nodeInfo["niro_heartbeat"] = niroHeartbeatText;

    const nodeInfoData = {
      allTrafficPorts: 0,
      allBackplanePorts: 0,
    };
    if (
      nodeInfo?.traffic_ports_up !== undefined &&
      nodeInfo?.traffic_ports_down !== undefined
    ) {
      nodeInfoData.allTrafficPorts =
        (nodeInfo.traffic_ports_up || 0) + (nodeInfo.traffic_ports_down || 0);
    }

    if (
      nodeInfo?.backplane_ports_up !== undefined &&
      nodeInfo?.backplane_ports_down !== undefined
    ) {
      nodeInfoData.allBackplanePorts =
        (nodeInfo.backplane_ports_up || 0) +
        (nodeInfo.backplane_ports_down || 0);
    }

    this.setState({
      nodeInfo: nodeInfo,
      getNodeStatus: resToState({ ok: true }),
      logRequestStatus: resToState(res),
    });

    if (window.location.href.includes("configuration/hahealth"))
      setTimeout(() => {
        this.fetchNodeInfo(node);
      }, 60000);
  };

  fetchLogsInfo = async (
    node: string,
    timeRange?: GTimeRange
  ): Promise<void> => {
    this.setState({ logRequestStatus: setPending("Fetching") });
    const res = await elasticApi.getHAHealthLogs(node, timeRange);
    if (res.length === 0) return;
    const nodeRolesMap: any = {
      STANDBY: "Standby node",
      ACTIVE: "Active node",
      undefined: "",
    };

    const transformedArray = res.map((item: any) => {
      const itemRoleField = String(item.fields["role"]);
      const roleParts = itemRoleField?.split(".");
      const nodeRole =
        roleParts && roleParts.length >= 2
          ? nodeRolesMap[roleParts[1]]
          : undefined;
      return {
        id: String(item["_id"]),
        timestamp: String(item.fields["@timestamp"]),
        event_type: String(item.fields["event_type"]),
        node_name: String(item.fields["node_name"]),
        curr_score: String(item.fields["current_score"]),
        prev_score: String(item.fields["previous_score"]),
        first_reason_field: String(item.fields["reasons.0.name"]),
        second_reason_field: String(item.fields["reasons.1.name"]),
        third_reason_field: String(item.fields["reasons.2.name"]),
        forth_reason_field: String(item.fields["reasons.3.name"]),
        node_role: nodeRole,
      };
    });
    this.setState({
      logsArray: transformedArray,
      logRequestStatus: resToState({ ok: true }),
    });
  };

  selectLog = (id?: number): void => {
    const select = this.state.logsArray?.find((el) => el.id === id);
    this.setState({ selectedLog: select });
  };

  manualFailover = async (
    system: string,
    standbyNode: string
  ): Promise<boolean> => {
    const res = await systemApi.systemFailover(system, {
      active_node: standbyNode,
    });
    if (res.ok) {
      this.fetchNodeInfo(standbyNode);
    }
    return res.ok;
  };

  fetchLastFailover = async (
    activeNode: string,
    standbyNode: string,
    timeRange: GTimeRange
  ): Promise<void> => {
    this.setState({ logRequestStatus: setPending("Fetching") });
    const res = await elasticApi.getHALastFailover(
      activeNode,
      standbyNode,
      timeRange
    );
    if (res.length === 0)
      this.setState({
        lastFailover: "More than 7 days ago",
        logRequestStatus: resToState({ ok: true }),
      });

    const lastFailover = getDateShortMonth(res.result[0].fields["@timestamp"]);

    this.setState({
      lastFailover: lastFailover,
      logRequestStatus: resToState({ ok: true }),
    });
  };

  funcs = {
    fetchNodeInfo: this.fetchNodeInfo,
    fetchLogsInfo: this.fetchLogsInfo,
    fetchLastFailover: this.fetchLastFailover,
    fetchSystem: this.fetchSystem,
    fetchTrafficPorts: this.fetchTrafficPorts,
    updateTrafficPorts: this.updateTrafficPorts,
    manualFailover: this.manualFailover,
    selectLog: this.selectLog,
  };
}
