import React, { FC } from "react";
import Input from "../../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../../components/common/formComponents/RadioGroup";
import Section from "../../../../../components/common/Section";
import { PROTOCOL_TYPES } from "../../../const";

type Props = {
  fields: { [key: string]: any };
  onChange: (field: string, value: any) => void;
  className: string;
  isEdit: boolean;
};

const FilterSection: FC<Props> = ({ fields, onChange, className, isEdit }) => {
  return (
    <Section title={"Filter"} columnsCount={2} className={className}>
      <Input
        label="Port"
        name="filterPort"
        placeholder=""
        value={fields.filterPort}
        onChange={(e) => onChange("filterPort", e.target.value)}
        medium
        error={""}
        isNotEditable={isEdit}
      />
      <RadioGroup
        label={"Protocol"}
        isOneRow
        options={PROTOCOL_TYPES}
        value={fields.filterProtocol}
        setActiveValue={(val) => onChange("filterProtocol", val)}
        isNotEditable={isEdit}
      />
    </Section>
  );
};
export default FilterSection;
