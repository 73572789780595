import React, { FC, useState } from "react";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import TabsComponent from "../../components/common/navigation/TabsComponent";
import styles from "./CloudResourcesContent.module.scss";
import { OK_STATUS } from "../../components/const/api";
import regionsImg from "./img/regions.svg";
import nniImage from "./img/nni.svg";
import awsImage from "./img/aws.svg";
import azureImage from "./img/azure.svg";
import googleImage from "./img/google.svg";

const CLOUD_RESOURCES_TABS = ["Regions", "NNIs"];

const CloudResourcesContent: FC = () => {
  const [activeTab, setActiveTab] = useState<string>(CLOUD_RESOURCES_TABS[0]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.cloudsWrapper}>
        <img src={awsImage} />
        <img src={azureImage} />
        <img src={googleImage} />
      </div>
      <TableWrapper
        titleProps={{
          title: (
            <TabsComponent
              tabs={CLOUD_RESOURCES_TABS}
              activeTab={activeTab}
              setTabActive={(val) => setActiveTab(val)}
              isBtnMode
            />
          ),
        }}
        dataStatus={OK_STATUS}
        tablePlaceholder={["", ""]}
        isEmpty={false}
        className={styles.table}
      >
        <img
          src={activeTab === CLOUD_RESOURCES_TABS[0] ? regionsImg : nniImage}
        />
      </TableWrapper>
    </div>
  );
};

export default CloudResourcesContent;
