import Validator from "./Validator";

export default function validateUserEmail(
  data: any,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmail(data.email, "email");
  return validator;
}
