import { configApi } from "../../helpers/api/ConfigApi";
import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { VirtualInterface } from "./types";
import { PortType } from "../PortsPage/types";

type RequestsState = CrudRequestStatuses;

type ValueState = CrudValueState<VirtualInterface> & {
  portList?: Array<PortType>;
};

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<VirtualInterface> & {};

export type IVirtualInterfaceContext = IState & IFunc;
const [
  Context,
  useVirtualInterfaceContext,
  withVirtualInterfaceContextProps,
] = createContextUtils<IState, IFunc>();

export { useVirtualInterfaceContext, withVirtualInterfaceContextProps };

type Props = IOneSystemContext;

class VirtualInterfaceContextContainer extends NewAbstractCrudContext<
  VirtualInterface,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (vrf?: string): Promise<void> => {
    const { system } = this.props;
    if (!system || !vrf) return;
    if (vrf === "service") {
      await this._fetchListWrap(() =>
        configApi.getVirtualInterfacesList(system.name, vrf)
      );
    } else {
      await this._fetchListWrap(() =>
        configApi.getAllVirtualInterfacesByTenant(vrf)
      );
    }
  };

  remove = async (vi: VirtualInterface, vrf?: string): Promise<boolean> => {
    const { system } = this.props;
    if (!system || !vrf) return false;
    const { ok } = await this._removeWrap(() =>
      configApi.deleteVirtualInterface(system.name, vi.name, vrf)
    );
    return ok;
  };

  // todo not implemented
  edit = async (vtep: any): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());
    return ok;
  };

  funcs = {
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
  };
}

export default withOneSystemContextProps(VirtualInterfaceContextContainer);
