/* eslint-disable prettier/prettier */
import React, { FC, ReactNode, useState } from "react";

import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus } from "../../helpers/types";
import { tenantApi } from "../../helpers/api/TenantApi";
import { userApi } from "../../helpers/api/UserApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import {
  SERVICES_MAP,
  getFullServiceList,
  getModifiedServicesList,
} from "./helpers/getModifiedServicesList";
import { Service, ServiceAPI, ServicesPreferencesAPI, TenantConsumersCount } from "./types";

type IFunc = {
  fetchServices: (tenant: string) => Promise<void>;
  editService: (service: ServiceAPI, tenant: string) => Promise<boolean>;
  selectService: (service?: Service) => void;
  fetchService: (tenant: string, service: string) => Promise<void>;
  fetchTenantConsumersCount: (tenant: string) => Promise<void>;
};

type IState = {
  list?: Array<Service>;
  service?: Service;
  groupedList: { [key: string]: Array<Service> };
  fetchStatus?: RequestStatus;
  editStatus?: RequestStatus;
  selectedService?: Service;
  error?: string;
  tenantConsumersCount: TenantConsumersCount; 
};

type Props = {
  children: ReactNode;
};

const [ServicesPreferencesContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useServicesPreferencesContext = useContext;

export const ServicesPreferencesContextContainer: FC<Props> = ({
  children,
}) => {
  const [groupedList, setGroupedList] = useState<{
    [key: string]: Array<Service>;
  }>({});
  const [list, setList] = useState<Array<Service>>([]);
  const [service, setService] = useState<Service | undefined>(undefined);
  const [fetchStatus, setFetchStatus] = useState<RequestStatus | undefined>();
  const [selectedService, setSelectedService] = useState<Service | undefined>();
  const [error, setError] = useState<string | undefined>(undefined);
  const [tenantConsumersCount, setTenantConsumersCount] = useState<TenantConsumersCount | undefined>(undefined);

  const fetchService = async (
    tenant: string,
    service: string
  ): Promise<void> => {
    setFetchStatus(setPending("Fetching"));
    const res = await tenantApi.getEnabledServices(tenant);
    if (res.ok && res.result) {
      const serviceApi = res.result.services.find((s) => s.type === service);
      if (serviceApi) {
        setService({ ...serviceApi, ...SERVICES_MAP[service] });
      }
    }
    setFetchStatus(resToState(res));
  };

  const fetchServices = async (tenant: string): Promise<void> => {
    setFetchStatus(setPending("Fetching"));
    const res = await tenantApi.getEnabledServices(tenant);
    setFetchStatus(resToState(res));
    if (res.ok && res.result) {
      setList(getFullServiceList(res.result.services));
      setGroupedList(getModifiedServicesList(res.result.services));
    }
  };

  const editService = async (
    service: ServiceAPI,
    tenant: string
  ): Promise<boolean> => {
    if (service.type === "ids") {
      setError("Advanced Threats service is not part of your current License");
      return false;
    }
    setFetchStatus(setPending("Fetching"));
    const payload: ServicesPreferencesAPI = { services: [service] };
    const res = await tenantApi.editEnabledServices(tenant, payload);

    if (res.ok) {
      fetchServices(tenant);
    }

    setFetchStatus(resToState(res));
    return res.ok;
  };

  const selectService = (service?: Service): void =>
    setSelectedService(service);

  const fetchTenantConsumersCount = async (tenant: string) => {
    const users = await fetchTenantUsersCount(tenant);
    const locations = await fetchTenantLocationsCount(tenant);
    setTenantConsumersCount({ users, locations });
  };

  const fetchTenantUsersCount = async (tenant: string) => {
    const res = await userApi.getTenantUsers(tenant);
    if (res.ok && res.result?.length) {
      return res.result.length;
    };
    return 0;
  };

  const fetchTenantLocationsCount = async (tenant:string) => {
    const res = await tenantApi.getTenantLocations(tenant);
    if (res && res.length) {
        return res.length;
    };
    return 0;
  };

  return (
    <ServicesPreferencesContext.Provider
      value={{
        fetchServices,
        groupedList,
        fetchStatus,
        editService,
        selectService,
        selectedService,
        list,
        service,
        fetchService,
        error,
        fetchTenantConsumersCount,
        tenantConsumersCount,
      }}
    >
      {children}
    </ServicesPreferencesContext.Provider>
  );
};
