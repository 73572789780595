type IncomingValues = {
  index: number;
  elAmount: number;
  radiuses: Array<number>;
  width: number;
  height: number;
};

export const calculateCoordinates = (
  arg: IncomingValues
): { [key: number]: { x: number; y: number; angleRad: number } } => {
  const { index, elAmount, radiuses, width, height } = arg;
  const angleRad = index * ((2 * Math.PI) / elAmount);

  const coordsPerRadius: {
    [key: number]: { x: number; y: number; angleRad: number };
  } = {};

  radiuses.forEach((radius: number) => {
    const x = width / 2 + radius * Math.cos(angleRad);
    const y = height / 2 + radius * Math.sin(angleRad);
    coordsPerRadius[radius] = { x, y, angleRad };
  });

  return coordsPerRadius;
};

export const calculateCoordinatesWithPredefinedAngle = (arg: {
  angle: number;
  radius: number;
  width: number;
  height: number;
}): { x: number; y: number } => {
  const { angle, radius, width, height } = arg;

  const x = width / 2 + radius * Math.cos(angle);
  const y = height / 2 + radius * Math.sin(angle);

  return { x, y };
};
