import { useEffect, useState } from "react";

export default function useCurrentDate(): Date {
  const [currentDate, setCurrentDate] = useState(() => new Date());
  useEffect(() => {
    let timer: NodeJS.Timeout;
    const firstUpdate = setTimeout(() => {
      setCurrentDate(new Date());
      timer = setInterval(() => {
        setCurrentDate(new Date());
      }, 60000);
    }, 60000 - currentDate.getSeconds() * 1000);
    return () => {
      clearTimeout(firstUpdate);
      timer && clearInterval(timer);
    };
  }, [setCurrentDate]);

  return currentDate;
}
