import { FC } from "react";
import { getColorFromStatus } from "../../../../../../helpers/common/colorHelpers";

type Props = {
  cx: string;
  cy: string;
  r: string;
  status: string;
};

const PortStatus: FC<Props> = ({ cx, cy, r, status }) => {
  const fillColor = getColorFromStatus(status);
  return <circle cx={cx} cy={cy} r={r} fill={fillColor} />;
};

export default PortStatus;
