export const handlingErrorDetails = (errorDetails: any): string => {
  if (!errorDetails) return "Request was failed";
  if (
    errorDetails.hasOwnProperty("error") &&
    errorDetails.hasOwnProperty("message")
  ) {
    return `${errorDetails.message}. ${errorDetails.error}`;
  }
  return JSON.stringify(errorDetails);
};
