/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { createContextAndUse } from "../common/AbstractCrudContext";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { QuotaType, TenantQuotaType } from "../../helpers/api/apiTypes";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { tenantApi } from "../../helpers/api/TenantApi";
import { hardcodedAPI } from "../../helpers/api/HardcodeApi";
import { PackageListType } from "../../components/tenants/TenantPackages/TenantPackagesPage";
import { QUOTAS_NAMES_MAP } from "../../components/tenants/TenantPackages/components/constAlias";

type IState = {
  predefinedList?: Array<QuotaType>;
  list?: Array<QuotaType>;
  listStatus?: RequestStatus;
  editTenantQuotaStatus?: RequestStatus;
  hardcodedList?: Array<PackageListType>;
  hardcodedListFull?: Array<PackageListType>;
  hardcodedFields?: {
    [key: string]: Array<string>;
    networkingFields: Array<string>;
    securityFields: Array<string>;
    managmentFields: Array<string>;
  };
  selectedTenant?: TenantQuotaType;
  selectedTenantList?: Array<QuotaType>;
  selectedHardcodedTenantList?: Array<PackageListType>;
};

type IFunc = {
  fetchSelectedTenant: () => void;
  fetchPredefinedList: () => void;
  fetchHardcodedData: () => void;
  fetchList: () => Promise<void>;
  updateDefaultPackage: (params: QuotaType) => Promise<boolean>;
  changeTenantQuota: (newQuota: string) => Promise<boolean>;
  updateCustomQuota: (fields: Partial<QuotaType>) => Promise<boolean>;
};

const [Context, useContext] = createContextAndUse<IState, IFunc>();

export const useQuotaContext = useContext;

type Props = React.PropsWithChildren<{
  tenant: string;
}>;

class QuotaContextContainer extends AbstractTimeoutHandler<IState, Props> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {};
    this.funcs = {
      updateDefaultPackage: this.updateDefaultPackage,
      fetchSelectedTenant: this.fetchSelectedTenant,
      fetchPredefinedList: this.fetchPredefinedList,
      fetchList: this.fetchList,
      changeTenantQuota: this.changeTenantQuota,
      updateCustomQuota: this.updateCustomQuota,
      fetchHardcodedData: this.fetchHardcodedData,
    };
  }

  fetchHardcodedData = () => {
    const {
      networkingFields,
      securityFields,
      managmentFields,
      list,
    } = hardcodedAPI.getPackagesData();
    this.setState({
      hardcodedFields: { networkingFields, securityFields, managmentFields },
      hardcodedListFull: list,
      hardcodedList: list.filter((type) => type.name !== "Custom"),
    });
  };

  fetchList = async (): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const predefinedList = await this.fetchPredefinedList();
    const tenantQuota = await (
      await tenantApi.getTenantQuota(this.props.tenant)
    ).result;
    const customQuota =
      tenantQuota?.name === "Custom"
        ? { name: "Custom", ...tenantQuota.quota }
        : {
            name: "Custom",
            fw_rules: "0",
            nat_rules: "0",
            maximum_bgp_neighbors: "0",
          };
    const quotas = customQuota ? [...predefinedList, customQuota] : [];
    this.setState({ list: quotas });
  };

  fetchPredefinedList = async (): Promise<Array<any>> => {
    this.setState({ listStatus: setPending("Fetching") });
    const res = await Promise.all(
      QUOTAS_NAMES_MAP.map(
        async (name): Promise<any> => await tenantApi.getQuota(name)
      )
    );
    const failed = res.find((el) => !el.ok);
    if (!failed) {
      this.setState({ predefinedList: res.map((el) => el.result) });
    }
    this.setState({ listStatus: resToState(failed || res[0]) });

    return !failed ? res.map((el) => el.result) : [];
  };

  fetchSelectedTenant = async (): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const res = await tenantApi.getTenantQuota(this.props.tenant);
    if (res.ok && res.result) {
      const hardcodedQuota = hardcodedAPI
        .getPackagesData()
        .list.find((el) => el.name === res.result?.name);
      const currentState = {
        name: "Current",
        ...res.result.current_state,
      };
      const defaultState = { ...res.result.quota, name: res.result.name };
      this.setState({
        selectedTenant: res.result,
        selectedTenantList: [
          currentState as QuotaType,
          defaultState as QuotaType,
        ],
        selectedHardcodedTenantList: [
          { ...hardcodedQuota, name: "Current" } as PackageListType,
          { ...hardcodedQuota } as PackageListType,
        ],
      });
    }
    this.setState({ listStatus: resToState(res) });
  };

  updateDefaultPackage = async (params: QuotaType): Promise<boolean> => {
    const { name, fw_rules, nat_rules, maximum_bgp_neighbors } = params;
    const body: Partial<QuotaType> = {
      fw_rules,
      nat_rules,
      maximum_bgp_neighbors,
    };
    const res = await tenantApi.editQuota(name, body);
    if (res.ok) {
      this.fetchPredefinedList();
      this.fetchHardcodedData();
    }
    return res.ok;
  };

  changeTenantQuota = async (newQuota: string): Promise<boolean> => {
    const res = await tenantApi.editTenantQuota(this.props.tenant, {
      quota_package_name: newQuota,
    });
    this.setState({ editTenantQuotaStatus: resToState(res) });
    return res.ok;
  };

  updateCustomQuota = async (params: Partial<QuotaType>): Promise<boolean> => {
    const { fw_rules, nat_rules, maximum_bgp_neighbors } = params;
    const body: Partial<QuotaType> = {
      fw_rules,
      nat_rules,
      maximum_bgp_neighbors,
    };
    const res = await tenantApi.editTenantQuotaValues(this.props.tenant, body);
    return res.ok;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.funcs,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default QuotaContextContainer;
