import React, { useState } from "react";
import { systemApi } from "../helpers/api/SystemApi";
import { createContextAndUse } from "./common/AbstractCrudContext";
import downloadFile from "../helpers/downloadFileHelper";
import { Res } from "../helpers/api/Api";
import { UUID } from "../helpers/api/types";
import { RequestStatus } from "../helpers/types";
import { elasticApi } from "../helpers/api/ElasticApi";
import { formGTimeRange } from "../components/common/charts/GraphanaLinksFactory";

type IState = {
  resetStatus: RequestStatus;
};

type IFunc = {
  factoryReset: () => Promise<Res<void>>;
  exportConfig: () => Promise<void>;
  importConfig: (file: HTMLFormElement, type: string) => Promise<any>;
  exportStatus: RequestStatus;
  importStatus: RequestStatus;
};

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useNiroContext = useContext;

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

const FETCH_RESTORE_ATTEMPTS = 25;
const FETCH_RESTORE_INTERVAL = 30000;
const FETCH_INTERVAL = 5000;

export default function NiroContextContainer(props: Props): React.ReactElement {
  const [exportStatus, setExportStatus] = useState<RequestStatus>({
    state: "ok",
  });
  const [resetStatus, setResetStatus] = useState<RequestStatus>({
    state: "idle",
  });
  const [importStatus, setImportStatus] = useState<RequestStatus>({
    state: "ok",
  });

  const factoryReset = async (): Promise<void> => {
    setResetStatus({ state: "pending" });
    await systemApi.factoryResetNiro();
    const { ok, error: message } = await systemApi.factoryResetNiro();

    if (ok) {
      setResetStatus({ state: "ok" });
    } else {
      setResetStatus({ state: "error", message });
    }
  };

  const exportConfig = async (): Promise<void> => {
    setExportStatus({ state: "pending" });
    const { ok, result, error } = await systemApi.niroExport();

    if (!ok || !result?.uuid) {
      setExportStatus({ state: "error", message: error });
      return;
    }

    const interval = setInterval(async () => {
      const cleanup = () => clearInterval(interval);
      const [status, response] = await downloadConfigByUUID(result.uuid);

      if (["failed", "not found"].includes(status)) {
        cleanup();
        return setExportStatus({
          state: "error",
          message: "Something bad happened",
        });
      }

      if (status === "ok") {
        cleanup();
        response && (await downloadFile(response));
        setExportStatus({ state: "ok" });
      }
    }, FETCH_INTERVAL);
  };

  const downloadConfigByUUID = async (
    uuid: UUID
  ): Promise<[string, Response?]> => {
    const response = await systemApi.downloadConfigByUUID(uuid);

    if (!response) {
      return ["failed"];
    }

    if (response.status === 202) {
      return ["file is being prepared"];
    }

    if (response.status === 404) {
      return ["not found"];
    }

    try {
      return ["ok", response];
    } catch (error) {
      return ["failed"];
    }
  };

  const importConfig = async (file: string, type: string): Promise<void> => {
    setImportStatus({ state: "pending" });

    const { ok, result, error } = await systemApi.niroImport(file, type);
    if (!ok || !result) {
      setImportStatus({ state: "error", message: error });
      return;
    }

    const timeRange = formGTimeRange();
    let attempts = 0;

    const interval = setInterval(async () => {
      const cleanup = () => clearInterval(interval);
      const [status, response] = await elasticApi.searchRestoreConfigInfo(
        timeRange
      );

      console.log(status, response);
      attempts++;

      if (attempts >= FETCH_RESTORE_ATTEMPTS) {
        cleanup();
        setImportStatus({ state: "ok" });
      }
    }, FETCH_RESTORE_INTERVAL);
  };

  return (
    <UserContext.Provider
      value={{
        factoryReset,
        exportConfig,
        importConfig,
        exportStatus,
        resetStatus,
        importStatus,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
