import { FC, useMemo } from "react";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import {
  ChangeField,
  FieldsType,
} from "../../ZayoPages/Connections/ConnectionConfigurator/types";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useTenantContext } from "../../../contexts/tenantsContext/TenantsContext";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
};

const TenantSystemDropdown: FC<Props> = ({ fields, onChange }) => {
  const { selected } = useTenantContext();
  const systems = useMemo(() => selected?.systems || [], [selected]);

  return (
    <DropdownBasic
      id={"system_name"}
      onChange={(val) => onChange("system_name", val.key)}
      selected={mapStringToItem(fields.system_name)}
      label="System name"
      itemsList={systems.map((key) => mapStringToItem(key))}
      isMedium
    />
  );
};

export default TenantSystemDropdown;
