import React from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

function ServicesIcon({ color }: IconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2181 11.143C19.9911 13.2682 17.2737 13.9963 15.1485 12.7693C13.0234 11.5424 12.2953 8.82496 13.5222 6.69981C14.7492 4.57466 17.4666 3.84653 19.5917 5.07348C21.7169 6.30044 22.445 9.01786 21.2181 11.143ZM9.55806 16.5555C8.5868 18.2378 6.43569 18.8142 4.75341 17.8429C3.07114 16.8716 2.49475 14.7205 3.46601 13.0382C4.43727 11.356 6.58839 10.7796 8.27066 11.7508C9.95293 12.7221 10.5293 14.8732 9.55806 16.5555ZM15.3008 23.9064C16.8335 24.7912 18.7932 24.2661 19.6781 22.7335C20.563 21.2008 20.0378 19.2411 18.5052 18.3562C16.9726 17.4713 15.0128 17.9964 14.1279 19.5291C13.2431 21.0617 13.7682 23.0215 15.3008 23.9064Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ServicesIcon;
