import React, { FC, useEffect, useState } from "react";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./IdentityDialog.module.scss";
import { DialogType } from "../../../../helpers/types";
import { ADD, EDIT } from "../../../../helpers/common/constantsAlias";
import { useFormField } from "../../../../helpers/hooks/useFormField";
import { CollapsableTwoColumnLayout } from "../../../../components/leftInfoBlock/LayoutTwoColumnFactory";
import DialogBtmButtons from "../../../../components/dialogs/common/DialogBtmButtons";
import { isEditMode } from "../../../../helpers/isEditMode";
import IdentityInfo from "./IdentityInfoBlock";
import AuthSection from "./AuthSection";
import SAMLSection from "./SAMLSection";
import ProviderSection from "./ProviderSection";

type Props = {
  onClose: () => void;
  type: DialogType;
};

export type CreateIdentity = {
  provider: string;
  provider_client_id: string;
  provider_client_secret: string;
  entity_id: string;
  use_descriptor: boolean;
  metadata: string;
};

const DEFAULT_FIELDS: CreateIdentity = {
  provider: "",
  provider_client_id: "",
  provider_client_secret: "",
  entity_id: "",
  use_descriptor: false,
  metadata: "",
};

const IdentityDialog: FC<Props> = ({ onClose, type }) => {
  const [fields, handleFieldChange] = useFormField<CreateIdentity>(
    DEFAULT_FIELDS
  );

  const [hasProvider, setHasProvider] = useState<boolean>(false);
  const [isSAML, setIsSAML] = useState<boolean>(false);

  const handleAdd = async () => {
    return;
  };

  const handleEdit = async () => {
    return;
  };

  const handleHasProvider = (hasProvider: boolean) => {
    setHasProvider(hasProvider);
  };

  useEffect(() => {
    setIsSAML(fields.provider === "SAML 2.0 Identity Providers");
  }, [fields.provider]);

  return (
    <CollapsableTwoColumnLayout InfoBlock={() => <IdentityInfo {...fields} />}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <ProviderSection
            className={styles.contentWrapper}
            fields={fields}
            onChange={handleFieldChange}
            handleProvider={handleHasProvider}
          />
          {hasProvider && (
            <>
              <AuthSection
                className={styles.contentWrapper}
                fields={fields}
                errors={[]}
                onChange={handleFieldChange}
              />
              {isSAML && (
                <SAMLSection
                  className={styles.contentWrapper}
                  fields={fields}
                  errors={[]}
                  onChange={handleFieldChange}
                />
              )}
            </>
          )}
        </div>
        <div className={styles.footer}>
          <DialogBtmButtons
            controls={{
              okText: isEditMode(type) ? EDIT : ADD,
              onOk: isEditMode(type) ? handleEdit : handleAdd,
              cancelText: "Cancel",
              onCancel: onClose,
            }}
          />
        </div>
      </div>
    </CollapsableTwoColumnLayout>
  );
};

export default IdentityDialog;
