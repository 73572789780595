import { FC } from "react";
import ConfirmDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import CrossIcon from "../../../../components/icons/CrossIcon";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { CRITICAL } from "../../../../helpers/common/colorAliases";
import { useSegmentsRulesContext } from "../../SegmentsRulesContext";

type Props = {
  onClose: () => void;
  cell: { x: number; y: number; id: number };
};
const DenyAllDialog: FC<Props> = ({ onClose, cell }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { deleteAllRules } = useSegmentsRulesContext();
  const handleContinue = async () => {
    const res = await deleteAllRules(cell, selectedTenant);
    if (res) {
      onClose();
    }
  };
  return (
    <ConfirmDialog
      title={"Set “Deny” for segments"}
      icon={CrossIcon}
      color={CRITICAL}
      message={
        "Are you sure you want to set 'Deny'? All rules for these segments will be deleted."
      }
      controls={{
        okText: "Continue",
        onOk: handleContinue,
        onCancel: onClose,
      }}
    />
  );
};

export default DenyAllDialog;
