import { FC, useEffect } from "react";
import styles from "./DIADialog.module.scss";
import {
  VirtualInetrfaceDIA,
  VirtualInetrfaceDIACreate,
} from "../../helpers/api/TenantVirtualInterfaceApi/types";
import { useFormField } from "../../helpers/hooks/useFormField";
import DialogTemplate from "../../components/dialogs/common/DialogTemplate";
import SummaryInfoBlock from "../../components/leftInfoBlock/SummaryInfoBlock";
import DIAConnectionIcon from "../../components/icons/DIAConnectionIcon";
import UnifiedTextPair from "../../components/common/UnifiedTextPair";
import Input from "../../components/common/formComponents/Input";
import { Checkbox } from "../../components/common/formComponents/Checkbox";
import DropdownBasic from "../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../helpers/mapStringToItem";
import { FieldsType } from "../ZayoPages/Connections/ConnectionConfigurator/types";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useConnectionsContext } from "../WizardToolPage/ConnectionStep/ConnectionsContext";

type Props = {
  onClose: () => void;
  onAdd: (data: VirtualInetrfaceDIA) => void;
  onEdit: (data: Partial<VirtualInetrfaceDIA>) => void;
  onDelete?: (dia: VirtualInetrfaceDIA) => void;
  onChange?: (field: string, value: any) => void;
};

const DEFAULT_FIELDS: any = {
  name: "",
  ip_pool_size: "",
  is_nat: true,
  is_snat: true,
  is_ipsec: false,
  tenant: "",
  system: { name: "", location: "" },
};

export const DIADialog: FC<Props> = ({ onClose }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchPoolList,
    fetchSystems,
    createDiaService,
    systemsList,
    poolList,
  } = useConnectionsContext();
  const [fields, handleFieldChange] = useFormField<VirtualInetrfaceDIACreate>(
    DEFAULT_FIELDS
  );
  useEffect(() => {
    if (selectedTenant) {
      handleFieldChange("tenant", selectedTenant);
      fetchPoolList();
      fetchSystems();
    }
  }, [selectedTenant]);

  //TO DO validation
  const submitForm = () => {
    selectedTenant && createDiaService(fields, selectedTenant);
  };

  return (
    <DialogTemplate
      controls={{
        okText: "Add",
        cancelText: "Cancel",
        onOk: submitForm,
        onCancel: onClose,
      }}
      onClose={onClose}
      leftColumn={<SummaryBlock fields={fields} />}
      title={"Add DIA Connection"}
    >
      <div className={styles.form}>
        <div className={styles.column}>
          <Input
            label="Name"
            name="name"
            placeholder=""
            value={fields.name}
            onChange={(e) => handleFieldChange("name", e.target.value)}
            medium
          />
          <Checkbox
            onChange={() => handleFieldChange("is_nat", !fields.is_nat)}
            isChecked={fields.is_nat}
            label={"NAT"}
          />
          <Checkbox
            onChange={() => handleFieldChange("is_snat", !fields.is_snat)}
            isChecked={fields.is_snat}
            label={"SNAT"}
          />
          <Checkbox
            onChange={() => handleFieldChange("is_ipsec", !fields.is_ipsec)}
            isChecked={fields.is_ipsec}
            label={"IPSEC"}
          />
        </div>
        <div className={styles.column}>
          <DropdownBasic
            id={"system_location"}
            label="System location"
            onChange={(val) => {
              const oneSystemApi = systemsList?.find(
                (system) => system.location === val.key
              );
              if (oneSystemApi) {
                const systemField = {
                  name: oneSystemApi.name,
                  location: oneSystemApi.location,
                };
                handleFieldChange("system", systemField);
              }
            }}
            itemsList={systemsList?.map((key: any) =>
              mapStringToItem(`${key.location}`)
            )}
            selected={mapStringToItem(fields.system?.location || "")}
            isMedium
          />
          <DropdownBasic
            id={"ipPoolSize"}
            label="IP pool Size"
            isMedium
            itemsList={poolList?.map((key: any) => mapStringToItem(key))}
            onChange={(item) => handleFieldChange("ip_pool_size", item.key)}
            selected={mapStringToItem(fields.ip_pool_size)}
          />
        </div>
      </div>
    </DialogTemplate>
  );
};

type SummaryBlockProps = {
  fields: FieldsType;
  className?: string;
};

const SummaryBlock: FC<SummaryBlockProps> = ({ fields }) => {
  return (
    <SummaryInfoBlock
      icon={<DIAConnectionIcon color={"var(--dialog-background)"} />}
      title={"DIA"}
    >
      <UnifiedTextPair subtitle="Name" text={fields.name} />
      <UnifiedTextPair subtitle="Tenant" text={fields.tenant} />
    </SummaryInfoBlock>
  );
};
