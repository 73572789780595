import React, { FC } from "react";
import { useConnectionsContext } from "../../../../ConnectionsContext";
import styles from "./RemoteUsers.module.scss";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import { useFormField } from "../../../../../../../helpers/hooks/useFormField";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import { mapStringToItemSubitem } from "../../../../../../../helpers/mapStringToItemSubitem";
import { REMOTE_USERS_LIST } from "../../../../../utils/wizardToolConsts";
import { Toggle } from "../../../../../../../components/common/Toggle";
import Input from "../../../../../../../components/common/formComponents/Input";
import InputWithList from "../../../../../../../components/common/formComponents/InputWithList";
import Button from "../../../../../../../components/common/buttons/Button";
import { useValidation } from "../../../../../../../helpers/validators/Validator";
import validateRemoteUsers from "../../../../../../../helpers/validators/RemoteUsersValidator";
import { PageLoader } from "../../../../../../../components/common/loadStates/LoaderIcon";
type Props = {};

export type RemoteUsersProps = {
  subnet: string;
  subnetPort: string;
  dnsServer: Array<string>;
  secureAccess: boolean;
};

const DEFAULT_FIELDS: RemoteUsersProps = {
  subnet: "10.9.0.0",
  subnetPort: "/21",
  dnsServer: [],
  secureAccess: true,
};

const RemoteUserForm: FC<Props> = () => {
  const {
    isSecureUsers,
    activeLocation,
    addRemoteUsersService,
    setActiveConnectionType,
    addConnectionStatus,
  } = useConnectionsContext();
  const [fields, handleFieldChange] = useFormField<RemoteUsersProps>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<RemoteUsersProps>(
    validateRemoteUsers,
    [fields]
  );

  const { user } = useUserContext();
  const handleAdd = (val: Array<string>) => {
    handleFieldChange("dnsServer", [...fields.dnsServer, ...val]);
  };

  const handleRemove = (val: string) => {
    handleFieldChange(
      "dnsServer",
      fields.dnsServer.filter((el: string) => el !== val)
    );
  };
  const handleCancel = () => {
    setActiveConnectionType(undefined);
  };
  const handleApply = async () => {
    const { isOk } = validate();
    if (isOk && activeLocation) {
      const subnet = fields.subnet + fields.subnetPort;
      const dnsServer = fields.dnsServer.join(",");
      if (dnsServer) {
        addRemoteUsersService(
          subnet,
          user.name,
          fields.secureAccess,
          activeLocation,
          dnsServer
        );
      } else {
        addRemoteUsersService(
          subnet,
          user.name,
          fields.secureAccess,
          activeLocation
        );
      }
    }
  };

  const changeToggle = (val: boolean) => {
    handleFieldChange("secureAccess", !val);
  };
  if (addConnectionStatus?.state === "pending") return <PageLoader />;
  return (
    <>
      <div className={styles.title}>Remote Users</div>
      <div className={styles.formContainer}>
        <div className={styles.msg}>
          Choose a network to assign IPs from to the tunnels. Every connection
          will get a fixed IP. Make sure this network doesn't overlap with any
          other IPs in your setup.
        </div>
        <div className={styles.prefixForTunnel}>
          <Input
            value={fields.subnet}
            label="Prefix for Tunnel IPs"
            onChange={(e) => {
              handleFieldChange("subnet", e.target.value);
            }}
            isFullValue
            medium
            error={errors?.subnet}
          />
          <DropdownBasic
            id={"prefixForTunnelIPs"}
            onChange={(val) => handleFieldChange("subnetPort", val.key)}
            selected={mapStringToItem(fields.subnetPort)}
            itemsList={REMOTE_USERS_LIST.map((key) =>
              mapStringToItemSubitem(key.title, key.subtitle)
            )}
            spanClassName={"spanSubtitle"}
            isMedium
          />
        </div>
        <div className={styles.msg}>
          Set an optional list of DNS server to use while connected to the
          tunnel, e.g. internal DNS servers.
        </div>
        <InputWithList
          name={"dnsServer"}
          label="DNS servers (optional)"
          placeholder="Servers` IPs"
          onAdd={handleAdd}
          onRemove={handleRemove}
          isFullValue
          medium
          valuesList={[...fields.dnsServer]}
          error={errors?.dnsServer}
        />
        <Toggle
          isChecked={fields.secureAccess}
          id="secureAccess"
          onChange={() => changeToggle(fields.secureAccess)}
          label="Secure Internet Access"
        />
        {!isSecureUsers && (
          <div className={styles.wrapper}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button onClick={handleApply} isPrimaryBtn>
              Apply
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
export default RemoteUserForm;
