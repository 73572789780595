export const CONNECTION_TYPE = [
  { value: "ipsec", label: "IPSEC", disabled: false },
  { value: "directConnect", label: "Direct Connect", disabled: false },
  // { value: "cloud", label: "Agent", disabled: false },
];

export const CONNETIONS_TYPES_MAP = [
  { name: "Branch", id: "branch" },
  {
    name: "Colo / Data Center",
    id: "data_center",
  },
  { name: "Bare Metal", id: "bareMetal" },
  {
    name: "AWS",
    id: "aws",
  },
  {
    name: "GCP",
    id: "gcp",
  },
  { name: "Azure", id: "azure" },
  {
    name: "Internet access",
    id: "internet",
  },
  {
    name: "Remote Users",
    id: "secure_users",
  },
];

export const VPC_LIST: {
  [key: string]: Array<string>;
  aws: Array<string>;
  gcp: Array<string>;
  azure: Array<string>;
} = {
  aws: [
    "vpc-us-east-2-d-api",
    "vpc-us-east-1-t-webapp",
    "vpc-us-west-1-s-redis",
    "vpc-us-west-2-p-postgres_sql",
    "vpc-af-south-1-d-storage",
    "vpc-ap-east-1-t-api",
    "vpc-ap-south-2-s-webapp",
    "vpc-ap-southeast-3-p-redis",
    "vpc-ap-south-1-d-postgres_sql",
    "vpc-ap-northeast-3-t-storage",
    "vpc-ap-northeast-2-s-api",
    "vpc-ap-southeast-1-p-webapp",
    "vpc-ap-southeast-2-d-redis",
    "vpc-ap-northeast-1-t-postgres_sql",
    "vpc-ca-central-1-s-storage",
    "vpc-eu-central-1-p-api",
    "vpc-eu-west-1-d-webapp",
    "vpc-eu-west-2-t-redis",
    "vpc-eu-south-1-s-postgres_sql",
    "vpc-eu-west-3-p-storage",
    "vpc-eu-south-2-d-api",
    "vpc-eu-north-1-t-webapp",
    "vpc-eu-central-2-s-redis",
    "vpc-me-south-1-p-postgres_sql",
    "vpc-me-central-1--storage",
  ],
  gcp: [
    "vpc-us-east4-b-d-api",
    "vpc-us-east4-c-t-webapp",
    "vpc-us-east5-a-s-redis",
    "vpc-us-east5-b-p-postgres_sql",
    "vpc-southamerica-east1-b-d-storage",
    "vpc-southamerica-east1-c-t-api",
    "vpc-us-south1-b-s-webapp",
    "vpc-us-south1-c-p-redis",
    "vpc-us-west1-a-d-postgres_sql",
    "vpc-us-west1-b-t-storage",
    "vpc-us-west1-c-s-api",
    "vpc-us-west2-a-p-webapp",
    "vpc-us-west2-b-d-redis",
    "vpc-us-west2-c-t-postgres_sql",
    "vpc-us-west3-a-s-storage",
    "vpc-asia-south1-a-p-api",
    "vpc-asia-south1-b-d-webapp",
    "vpc-asia-south1-c-t-redis",
    "vpc-asia-south2-a-s-postgres_sql",
    "vpc-asia-south2-b-p-storage",
    "vpc-asia-south2-c-d-api",
    "vpc-europe-central2-c-t-webapp",
    "vpc-europe-north1-a-s-redis",
    "vpc-europe-north1-b-p-postgres_sql",
    "vpc-europe-north1-c--storage",
  ],
  azure: [
    "vnet-webapp-d-eastus",
    "vnet-redis-t-eastus2",
    "vnet-postgres_sql-s-southcentralus",
    "vnet-storage-p-westus2",
    "vnet-api-d-westus3",
    "vnet-webapp-t-australiaeast",
    "vnet-redis-s-southeastasia",
    "vnet-postgres_sql-p-northeurope",
    "vnet-storage-d-swedencentral",
    "vnet-api-t-uksouth",
    "vnet-webapp-s-westeurope",
    "vnet-redis-p-centralus",
    "vnet-postgres_sql-d-southafricanorth",
    "vnet-storage-t-centralindia",
    "vnet-api-s-eastasia",
    "vnet-webapp-p-japaneast",
    "vnet-redis-d-koreacentral",
    "vnet-postgres_sql-t-canadacentral",
    "vnet-storage-s-francecentral",
    "vnet-api-p-germanywestcentral",
    "vnet-webapp-d-norwayeast",
    "vnet-redis-t-switzerlandnorth",
    "vnet-postgres_sql-s-uaenorth",
    "vnet-storage-p-brazilsouth",
  ],
};
