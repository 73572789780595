import React, { FC, useState } from "react";
import InternetToWorkloadsLB from "./InternetToWorkloadsLB";
import InternetToWorkloadsNAT from "./InternetToWorkloadsNAT";
import WorkloadsToInternet from "./WorkloadsToInternet";

type Props = {};

const InternetForm: FC<Props> = () => {
  const [activeRule, setactiveRule] = useState<string>("loadBalancer");
  const [connectionType, setConnectionType] = useState<string>(
    "workloadsToInternet"
  );

  if (connectionType === "workloadsToInternet")
    return (
      <WorkloadsToInternet
        connectionType={connectionType}
        setConnectionType={setConnectionType}
      />
    );
  if (activeRule === "loadBalancer")
    return (
      <InternetToWorkloadsLB
        connectionType={connectionType}
        setConnectionType={setConnectionType}
        activeRule={activeRule}
        setactiveRule={setactiveRule}
      />
    );
  return (
    <InternetToWorkloadsNAT
      connectionType={connectionType}
      setConnectionType={setConnectionType}
      activeRule={activeRule}
      setactiveRule={setactiveRule}
    />
  );
};
export default InternetForm;
