import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function DNATIcon({ color = FONT }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.21191 4.7094C5.21191 4.08327 5.7195 3.57568 6.34564 3.57568C9.5069 3.57568 12.1349 5.85899 12.6703 8.86638H13.6033C14.0166 8.05547 14.8594 7.50009 15.832 7.50009C17.2127 7.50009 18.332 8.61938 18.332 10.0001C18.332 11.3808 17.2127 12.5001 15.832 12.5001C14.8595 12.5001 14.0166 11.9447 13.6033 11.1338H12.6703C12.1349 14.1412 9.5069 16.4245 6.34564 16.4245C5.7195 16.4245 5.21191 15.9169 5.21191 15.2908C5.21191 14.6647 5.7195 14.1571 6.34564 14.1571C8.64147 14.1571 10.5026 12.2959 10.5026 10.0001C10.5026 7.70427 8.64147 5.84313 6.34564 5.84313C5.7195 5.84313 5.21191 5.33554 5.21191 4.7094ZM5.41289 6.55308C4.97015 6.11034 4.25232 6.11034 3.80957 6.55308L1.16422 9.19843C0.721476 9.64118 0.721476 10.359 1.16422 10.8018L3.80957 13.4471C4.25232 13.8898 4.97015 13.8898 5.41289 13.4471C5.85564 13.0044 5.85564 12.2865 5.41289 11.8438L4.70294 11.1338H8.54515C9.17129 11.1338 9.67887 10.6262 9.67887 10.0001C9.67887 9.37397 9.17129 8.86639 8.54515 8.86639H4.70291L5.41289 8.15641C5.85564 7.71366 5.85564 6.99583 5.41289 6.55308Z"
        fill={color}
      />
    </svg>
  );
}

export default DNATIcon;
