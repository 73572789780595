import { DragEvent, FC, useEffect, useState } from "react";
import {
  ChangeField,
  FieldsType,
} from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import Section from "../../../../../../../components/common/Section";
import { AddItemButton } from "./AddItemButton";
import PrefixFilterItem from "./PrefixFilterItem";
import { getRouteFiltersPrefixBody } from "../../../../../helpers/getRouteFiltersPrefixBody";

type Props = {
  className: string;
  fields: Array<FieldsType>;
  onChange: ChangeField;
};

const PrefixFilterListSection: FC<Props> = ({
  className,
  fields,
  onChange,
}) => {
  const [items, setItems] = useState<Array<FieldsType>>(fields);

  // update fields
  useEffect(() => {
    onChange("pfx_list", items);
  }, [items]);

  // change list actions
  const handleChangeItem = (idx: number, newValues: FieldsType) => {
    const newItems = items.map((el, i) => {
      if (i === idx) return newValues;
      return el;
    });
    setItems(newItems);
  };
  const handleAddItem = () => {
    const newItems = [...items, { ...getRouteFiltersPrefixBody() }];
    setItems(newItems);
  };
  const handleDeleteItem = (idx: number) => {
    const newItems = fields.filter((el, i) => i !== idx);
    if (!newItems.length) {
      newItems.push({ ...getRouteFiltersPrefixBody() });
    }
    setItems(newItems);
  };

  // drag-n-drop actions

  const [isDragging, setIsDragging] = useState(false);
  const [dropIndex, setDropIndex] = useState<number | null>(null);
  const [dragIndex, setDragIndex] = useState<number | null>(null);

  const handleDragStart = (event: DragEvent<HTMLDivElement>, index: number) => {
    event.dataTransfer.setData("text/plain", index + "");
    setIsDragging(true);
    setDragIndex(index);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>, index: number) => {
    event.preventDefault();
    setDropIndex(index);
  };

  const handleDrop = (event: DragEvent<HTMLDivElement>, dropIndex: number) => {
    event.preventDefault();
    const dragIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
    const newItems = [...items];
    const draggedItem = newItems[dragIndex];
    newItems.splice(dragIndex, 1);
    newItems.splice(dropIndex, 0, draggedItem);
    setItems(newItems);
    setIsDragging(false);
    setDropIndex(null);
    setDragIndex(null);
  };

  return (
    <Section
      title={"Prefix Filter List"}
      className={className}
      columnsCount={1}
    >
      {fields?.map((item, index) => {
        return (
          <PrefixFilterItem
            fields={item}
            idx={index}
            onChange={handleChangeItem}
            onDelete={() => handleDeleteItem(index)}
            key={item.id}
            draggable
            onDragStart={(event) => handleDragStart(event, index)}
            onDragOver={(event) => handleDragOver(event, index)}
            onDrop={(event) => handleDrop(event, index)}
            isDroppable={isDragging && dropIndex === index}
            isDragging={isDragging && dragIndex === index}
          />
        );
      })}
      <AddItemButton onClick={handleAddItem} />
    </Section>
  );
};
export default PrefixFilterListSection;
