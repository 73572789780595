import React, { useContext } from "react";
import { RequestStatus } from "../../helpers/types";
import {
  FilterAndSort,
  FilterType,
  SortType,
} from "../../helpers/api/apiTypes";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { Res } from "../../helpers/api/Api";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";

type CrudRequestStatuses = {
  listStatus?: RequestStatus;
  selectedStatus?: RequestStatus;
  addStatus?: RequestStatus;
  removeStatus?: RequestStatus;
  editStatus?: RequestStatus;
};

export type CrudState<InList, One> = {
  list: Array<InList>;
  selected: One | undefined;
  count?: number;
} & CrudRequestStatuses &
  FilterAndSort;

export type CrudFunc<One, DeleteArgs> = {
  fetchList: (addition?: string, offset?: number, limit?: number) => void; // todo make addition part of filer
  fetchSelected: (id: number | string, addition?: string) => void;
  add: (params: Partial<One>, addition?: string) => Promise<boolean>;
  remove: (params: DeleteArgs) => Promise<boolean>;
  edit: (
    params: Partial<One>,
    id: number | string,
    addition?: string
  ) => Promise<boolean>;
  resetStatus: () => void;
  setFilter: (filter: FilterType) => void;
  setSort: (sort: SortType) => void;
};

// TODO with additional vrf context
export function createContextAndUse<IS, IF>(): [
  React.Context<(IS & IF) | any>,
  () => IS & IF
] {
  const Context = React.createContext<(IS & IF) | any>(null);
  return [Context, (): IS & IF => useContext<IS & IF>(Context)];
}

export function createContextAndUseWithExtra<IS, IF, AC>(): [
  React.Context<(IS & IF & AC) | any>,
  () => IS & IF & AC
] {
  const Context = React.createContext<(IS & IF & AC) | any>(null);
  return [Context, (): IS & IF & AC => useContext<IS & IF & AC>(Context)];
}

type Props = React.PropsWithChildren<{}>;

abstract class AbstractCrudContextContainer<
  InListType,
  One,
  CState extends CrudState<InListType, One>,
  DeleteArgs,
  Props = any
> extends AbstractTimeoutHandler<CState, Props> {
  baseFuncs: CrudFunc<One, DeleteArgs>;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public readonly state: CState = {
    list: [],
  };
  constructor(props: Readonly<Props>) {
    super(props);

    this.baseFuncs = {
      fetchList: this.fetchList,
      fetchSelected: this.fetchSelected,
      add: this.add,
      remove: this.remove,
      edit: this.edit,
      resetStatus: this.resetStatus,
      setFilter: this.setFilter,
      setSort: this.setSort,
    };
  }

  abstract listReq: (
    param?: FilterAndSort,
    addition?: any,
    offset?: number,
    limit?: number
  ) => Promise<Res<Array<InListType>>>;
  abstract selectedReq: (
    id: string | number,
    addition?: any
  ) => Promise<Res<One>>;
  abstract addReq: (params: Partial<One>, addition?: any) => Promise<Res<any>>;
  abstract removeReq: (args: DeleteArgs, addition?: any) => Promise<Res<any>>;
  abstract editReq: (
    params: Partial<One>,
    id: string | number,
    addition?: any
  ) => Promise<Res<any>>;

  setFilter = (filter: FilterType): void => {
    this.setState({ filter }, this.fetchList);
  };
  setSort = (sort: SortType): void => {
    this.setState({ sort }, this.fetchList);
  };

  fetchList = async (
    addition?: any,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const { filter, sort } = this.state;
    const res = await this.listReq({ filter, sort }, addition, offset, limit);
    this.setState({ list: res.result || [], count: res.count });
    this.setState({ listStatus: resToState(res) });
  };

  fetchSelected = async (
    id: number | string,
    addition?: any
  ): Promise<boolean> => {
    this.setState({ selectedStatus: setPending("Fetching") });
    const res = await this.selectedReq(id, addition);
    if (res.ok) {
      this.setState({ selected: res.result });
    }
    this.setState({ selectedStatus: resToState(res) });
    return res.ok;
  };

  add = async (params: Partial<One>, addition?: any): Promise<boolean> => {
    this.setState({ addStatus: setPending("Fetching") });
    const res = await this.addReq(params, addition);
    if (res.ok) {
      this.fetchList(addition);
    }
    this.setState({ addStatus: resToState(res) });
    return res.ok;
  };

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  remove = async (params: DeleteArgs, addition?: any): Promise<boolean> => {
    this.setState({ removeStatus: setPending("Fetching") });
    const res = await this.removeReq(params, addition);

    if (res.ok) {
      this.fetchList(addition);
    }

    this.setState({ removeStatus: resToState(res) });
    this.setupTimeout(
      "removeStatus",
      () => this.setState({ removeStatus: undefined }),
      2000
    );
    return res.ok;
  };

  edit = async (
    params: Partial<One>,
    id: number | string,
    addition?: any
  ): Promise<boolean> => {
    this.setState({ editStatus: setPending("Fetching") });
    const res = await this.editReq(params, id, addition);
    this.setState({ editStatus: resToState(res) });
    if (res.ok) {
      await this.fetchList(addition);
    }
    this.setupTimeout(
      "editStatus",
      () => this.setState({ editStatus: undefined }),
      2000
    );
    return res.ok;
  };

  resetStatus = async (): Promise<void> => {
    this.setState({ editStatus: undefined });
  };

  abstract render(): JSX.Element;
}

export default AbstractCrudContextContainer;
