import React, { ReactNode } from "react";
import Section from "../../../../../components/common/Section";
import { classNames } from "../../../../../helpers/common/classNames";

import styles from "./ConnectionSection.module.scss";

type Props = {
  children: ReactNode;
  title: string;
  className?: string;
  columnsCount?: number;
};
const ConnectionSection = ({
  children,
  title,
  className,
  columnsCount = 2,
}: Props) => {
  return (
    <Section
      title={title}
      className={classNames(styles.wrapper, className)}
      columnsCount={columnsCount}
    >
      {children}
    </Section>
  );
};

export default ConnectionSection;
