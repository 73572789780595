import React, { FC } from "react";
import { PageProps } from "../../helpers/types";
import { withContexts } from "../../helpers/hocs/withContexts";
import ServicesBGPContextContainer from "./ServicesBGPContext";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { ADD, EDIT, ROUTE_FILTERS } from "../../helpers/navigation/entries";
import ServiceBGPConfigAdd from "./components/ServiceBGPConfig/ServiceBGPConfigAdd";
import RouteFiltersPage from "../RouteFiltersPage";
import ServiceBGPContent from "./ServiceBGPContent";
import ServiceBGPConfigEdit from "./components/ServiceBGPConfig/ServiceBGPConfigEdit";

const ServiceBGP: FC<PageProps> = ({ tabs }) => {
  const path = useMatchPath();
  return (
    <Switch>
      <Route path={path} exact>
        <ServiceBGPContent tabs={tabs} />
      </Route>
      <Route path={path + "/" + ADD().path} exact>
        <ServiceBGPConfigAdd />
      </Route>
      <Route path={path + "/" + EDIT().path + "_:ip"} exact>
        <ServiceBGPConfigEdit />
      </Route>
      <Route path={path + "/" + ROUTE_FILTERS().path}>
        <RouteFiltersPage />
      </Route>
      <Route path={path + "/*"}>
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};

export default withContexts<PageProps>(ServiceBGP, [
  ServicesBGPContextContainer,
]);
