/* eslint-disable css-modules/no-unused-class */
import { FC, useEffect, useState } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import styles from "./UserGroupComponent.module.scss";
import GroupIcon from "../../../../../components/icons/GroupIcon";
import { UserGroup } from "../../../../../helpers/types";
import { UserThumbnail } from "../../../../UsersPage/components/shared/UserThumbnail";
import Label from "../../../../../components/common/table/Label";
import { Checkbox } from "../../../../../components/common/formComponents/Checkbox";
import { useUserGroupsContext } from "../../../UserGroupsContext";
import Input from "../../../../../components/common/formComponents/Input";
import Button from "../../../../../components/common/buttons/Button";
import InputWithList from "../../../../../components/common/formComponents/InputWithList";
import { useValidation } from "../../../../../helpers/validators/Validator";
import validateUserGroup from "../../../../../helpers/validators/UserGroupValidator";

type Props = {
  selectedTenant: string;
  onAdd: (data: UserGroup) => void;
  onClose: () => void;
};

const UserGroupComponentAdd: FC<Props> = ({
  selectedTenant,
  onAdd,
  onClose,
}) => {
  const { users } = useUserGroupsContext();

  const [newGroup, setNewGroup] = useState<UserGroup>({
    name: "",
    users: [],
    subnets: [],
  });

  useEffect(() => {
    console.log(newGroup, "newGroup");
  }, [newGroup]);

  const filterRemoteUsers = users?.filter((oneUser) => oneUser.is_remote_user);
  const addUsersArray = filterRemoteUsers?.map((oneUser) => {
    const userCheck = newGroup.users.includes(oneUser.username);
    return {
      username: oneUser.username,
      userCheck,
      userFullName: oneUser.first_name + " " + oneUser.last_name,
    };
  });

  const [errors, validate] = useValidation<any>(validateUserGroup, [
    { name: newGroup.name, ip_addresses: newGroup.ip_addresses },
  ]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    const { isOk } = validate();
    if (e.key === "Enter") {
      if (selectedTenant && isOk) {
        onAdd(newGroup);
      }
    }
  };

  const handleUserChange = (userName: string, isCheck: boolean) => {
    let newUsers = [""];
    if (!isCheck) {
      newUsers = [...newGroup.users, userName];
    } else {
      newUsers = newGroup.users.filter((user) => user !== userName);
    }
    setNewGroup({ ...newGroup, users: [...newUsers] });
  };

  const handleIPChange = (iP: string, isCheck: boolean) => {
    let newipSubnets = [""];
    if (!isCheck) {
      newipSubnets = [...newGroup.subnets, iP];
    } else {
      newipSubnets = newGroup.subnets.filter((subnet) => subnet !== iP);
    }
    setNewGroup({ ...newGroup, subnets: [...newipSubnets] });
  };

  const addSegment = (segments: Array<string>) => {
    const isOk = true;
    if (isOk) {
      const prevSegments = newGroup.subnets;
      const newSegments = [...prevSegments, ...segments];
      setNewGroup({ ...newGroup, subnets: newSegments });
    }
  };

  const removeSegment = (ip: string) => {
    const prevSegments = newGroup.subnets;
    const newSegments = prevSegments.filter((subnet) => subnet !== ip);
    setNewGroup({ ...newGroup, subnets: newSegments });
  };

  const submitAddGroup = () => {
    const { isOk } = validate();
    if (!isOk) {
      return;
    }
    onAdd(newGroup);
  };

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={classNames(styles.header, styles.clickable)}>
        <>
          <div className={styles.groupInput}>
            <GroupIcon />
            <Input
              value={newGroup.name}
              onChange={(e) =>
                setNewGroup({ ...newGroup, name: e.target.value })
              }
              isFullValue
              medium
              error={errors?.name}
              placeholder="Group name"
              onKeyDown={handleKeyDown}
              className={styles.groupInputName}
            />
          </div>
          <div className={styles.inlineBoxAddGroup}>
            <div className={styles.flexRow}>
              <div className={styles.addDiv}>
                <div className={styles.groupRow}>
                  <span className={styles.groupColumn}>Users</span>
                  <div className={styles.list}>
                    {newGroup.users.map((userMap) => (
                      <Label
                        isRemovable
                        onClick={() => handleUserChange(userMap, true)}
                        className={styles.labelClassName}
                      >
                        {<UserThumbnail username={userMap} />}
                      </Label>
                    ))}
                    {!newGroup.users.length && (
                      <div className={styles.groupNoLabels}>
                        Add using table below
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.groupRow}>
                  <span className={styles.groupColumn}>IP Subnets</span>
                  <div className={styles.list}>
                    {newGroup.subnets.map((subnetMap) => (
                      <Label
                        isRemovable
                        onClick={() => handleIPChange(subnetMap, true)}
                        className={styles.labelSubnetClassName}
                      >
                        {subnetMap}
                      </Label>
                    ))}
                    {!newGroup.subnets.length && (
                      <div className={styles.groupNoLabels}>
                        Add using field below
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Button onClick={onClose}>Cancel</Button>
              <Button className={styles.addGroupBtn} onClick={submitAddGroup}>
                Add
              </Button>
            </div>
            <div className={styles.edit}>
              <div className={styles.editLabel}>Add IP Subnet</div>
              <InputWithList
                onAdd={addSegment}
                onRemove={removeSegment}
                valuesList={newGroup.subnets}
                medium
              />
            </div>
            {addUsersArray ? (
              <>
                {addUsersArray.map((oneUser) => (
                  <div className={styles.userBox}>
                    <div className={styles.userThumbnail}>
                      <UserThumbnail username={oneUser.userFullName} />
                    </div>
                    <div className={styles.userNameBox}>{oneUser.username}</div>
                    <Checkbox
                      onChange={() => {
                        handleUserChange(oneUser.username, oneUser.userCheck);
                      }}
                      isChecked={oneUser.userCheck}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div>No Remote users on this tenant. Please add some.</div>
            )}
          </div>
        </>
      </div>
    </div>
  );
};
export default UserGroupComponentAdd;
