import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
  DIA,
} from "../consts";
import { FieldsType } from "../types";

export const getConfigFromType = (fields: FieldsType): FieldsType => {
  switch (fields.innerType) {
    case CUSTOMER_LOCATIONS:
      return {
        BW: fields["BW"],
        Phisical_Port: +fields.Phisical_Port,
        Tagged: fields.Tagged,
        VLAN_ID: fields.Tagged ? +fields.VLAN_ID : undefined,
      };
    case DATA_CENTER_PORT:
      return {
        BW: fields["BW"],
      };
    case DIA:
      return {
        BW: fields["BW"],
        Subnet: fields.Subnet,
        GW: fields.GW,
      };
    case CLOUD_CONNECTIONS:
      const isIpsec = fields.Connection_type === "ipsec";
      const base = {
        Connection_type: fields.Connection_type,
        BW: fields["BW"],
        Customer_ID: +fields.Customer_ID,
      };
      return isIpsec
        ? {
            ...base,
            VPC_ID: fields.VPC_ID,
            Token_part1: fields.Token_part1,
            Token_part2: fields.Token_part2,
          }
        : base;
    default:
      return {};
  }
};
