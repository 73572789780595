export const renderCustomerIdLabel = (cloudType: string): string => {
  switch (cloudType) {
    case "aws":
      return "AWS Customer ID";
    case "azure":
      return "Azure Circuit ID";
    default:
      return "Customer ID";
  }
};
