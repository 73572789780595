import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

const fromThreadsSevirityBody = (
  timeRange: GTimeRange,
  tenantName?: string
): { [key: string]: any } => {
  return {
    aggs: {
      "1": {
        terms: {
          field: "Alert.severity",
          order: {
            _count: "desc",
          },
          size: 5,
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              filter: [
                {
                  bool: {
                    should: [
                      {
                        match_phrase: { "event_type.keyword": "IDS" },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        exists: {
                          field: "Alert.string.keyword",
                        },
                      },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
            },
          },
          { match_phrase: { "tenant_name.keyword": tenantName } },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: parseGTime(timeRange.from),
                lte: parseGTime(timeRange.to),
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default fromThreadsSevirityBody;
