import React, { ReactElement } from "react";
import { IconProps } from "../../../../helpers/types";

function GCPLogo({ color, x, y, width, height }: IconProps): ReactElement {
  return (
    <svg
      width={width || 48}
      height={height || 48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        fill={color || "#EA4335"}
        d="M28.805 18.266h1l2.85-2.85.14-1.21a12.81 12.81 0 0 0-20.84 6.25 1.55 1.55 0 0 1 1-.06l5.7-.94s.29-.48.44-.45a7.11 7.11 0 0 1 9.73-.74h-.02Z"
      />
      <path
        fill={color || "#4285F4"}
        d="M36.715 20.455a12.84 12.84 0 0 0-3.87-6.24l-4 4a7.11 7.11 0 0 1 2.61 5.64v.71a3.56 3.56 0 0 1 0 7.12h-7.12l-.71.72v4.27l.71.71h7.12a9.26 9.26 0 0 0 5.26-16.93Z"
      />
      <path
        fill={color || "#34A853"}
        d="M17.205 37.344h7.12v-5.7h-7.12a3.54 3.54 0 0 1-1.47-.32l-1 .31-2.87 2.85-.25 1a9.21 9.21 0 0 0 5.59 1.86Z"
      />
      <path
        fill={color || "#FBBC05"}
        d="M17.205 18.855a9.26 9.26 0 0 0-5.59 16.6l4.13-4.13a3.56 3.56 0 1 1 4.71-4.71l4.13-4.13a9.25 9.25 0 0 0-7.38-3.63Z"
      />
    </svg>
  );
}

export default GCPLogo;
