import React, { FC, useState } from "react";
import { CreateIdentity } from "./IdentityDialog";
import Section from "../../../../components/common/Section";
import Input from "../../../../components/common/formComponents/Input";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./AuthSection.module.scss";
import BlankButton from "../../../../components/common/buttons/BlankButton";
import { Checkbox } from "../../../../components/common/formComponents/Checkbox";

type Props = {
  fields: CreateIdentity;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
};

const SAMLSection: FC<Props> = ({ fields, errors, onChange, className }) => {
  const [metadataShow, setMetadataShow] = useState<boolean>(!!fields.metadata);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  return (
    <Section title={"SAML settings"} className={className} columnsCount={2}>
      <div className={styles.column}>
        <Input
          label="Service provider entity ID"
          name="entity_id"
          placeholder=""
          value={fields.entity_id}
          medium
          error={errors && errors["name"]}
          handleFieldChange={onChange}
        />
        {!metadataShow && (
          <BlankButton
            id={"metadata"}
            key={"metadata"}
            className={styles.descriptionButton}
            onClick={() => console.log("show metadata")}
          >
            {"Show Metadata"}
          </BlankButton>
        )}
      </div>
      <div className={styles.column}>
        <div className={styles.checkboxWrapper}>
          <div className={styles.chexboxTitle}>Use entity descriptor</div>
          <Checkbox
            containerClassName={styles.checkbox}
            isChecked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            label={"Enable"}
          />
        </div>
      </div>
    </Section>
  );
};
export default SAMLSection;
