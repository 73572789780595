import React from "react";
import Input from "../../../../../../components/common/formComponents/Input";
import ConnectionSection from "../../common/ConnectionSection";
import InterfaceField from "./InterfaceField";

const InterfaceSection = () => {
  return (
    <ConnectionSection title="Interface">
      <InterfaceField />
      <Input medium label="Local Link IP" />
    </ConnectionSection>
  );
};

export default InterfaceSection;
