import React, { ReactElement } from "react";
import { GREY_FONT, WHITE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ShieldFieldIcon({ width = 35, height = 40 }: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.8248 8.21339C34.8248 35.0936 17.4124 39.5736 17.4124 39.5736C17.4124 39.5736 0 35.8402 0 8.21339L17.4124 0L34.8248 8.21339Z"
        fill={GREY_FONT}
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4124 0V39.5736C17.4124 39.5736 0 35.8402 0 8.21339L17.4124 0Z"
        fill={WHITE}
      />
    </svg>
  );
}

export default ShieldFieldIcon;
