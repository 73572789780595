import React, { FC, useMemo, useState } from "react";
import ChartsHeader from "../../../components/common/charts/ChartsHeader/ChartsHeader";
import GrafanaGraph from "../../../components/common/charts/GrafanaGraph";
import ChartToppersTabs from "../../../components/common/ChartToppersTabs";
import grafanaLinksFactory from "../../../components/common/charts/GraphanaLinksFactory";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import FWStatsContextContainer, {
  useFirewallContext,
} from "../../../contexts/servicesContext/FirewallContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./RulesTrafficCharts.module.scss";
import { useTimerangeContext } from "../../../contexts/TimerangeContext";

const BPS = "Bytes per Second";
const PPS = "Packets per Second";
const CPS = "Connection per Second";
const CCN = "Concurrent Connections";

const RulesTrafficCharts: FC<{}> = () => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const [activeTraffic, setActiveTraffic] = useState<string>(BPS);
  const graphLinks: {
    [key: string]: [string, string];
  } = useMemo(() => {
    const data = { timeRange: timeRange.general, tenantName: selectedTenant };

    return {
      [BPS]: [
        grafanaLinksFactory.firewall_bps(timeRange.general)(data),
        grafanaLinksFactory.firewall_bps_accept(timeRange.general)(data),
      ],
      [PPS]: [
        grafanaLinksFactory.firewall_pps(timeRange.general)(data),
        grafanaLinksFactory.firewall_pps_accept(timeRange.general)(data),
      ],
      [CPS]: [
        grafanaLinksFactory.firewall_cps(timeRange.general)(data),
        grafanaLinksFactory.firewall_cps_accept(timeRange.general)(data),
      ],
      [CCN]: [
        grafanaLinksFactory.firewall_ccn(timeRange.general)(data),
        grafanaLinksFactory.firewall_ccn_accept(timeRange.general)(data),
      ],
    };
  }, [timeRange, selectedTenant]);

  const fwHitGraphLink: string = useMemo(() => {
    const data = { timeRange: timeRange.general, tenantName: selectedTenant };
    return grafanaLinksFactory.dashboard_fw_rules_hits(data);
  }, [timeRange, selectedTenant]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabs}>
        <ChartToppersTabs
          tabs={[BPS, PPS, CPS, CCN] as any}
          activeTab={activeTraffic}
          setTabActive={setActiveTraffic}
          isLongTab
        />
      </div>
      <ChartWrapper
        className={styles.leftChart}
        title="Traffic"
        link={graphLinks[activeTraffic][1]}
      />
      <ChartWrapper
        className={styles.midChart}
        title="Rules Traffic"
        link={graphLinks[activeTraffic][0]}
      />
      <ChartWrapper
        className={styles.rightChart}
        title="FW Rules hits"
        link={fwHitGraphLink}
      />
    </div>
  );
};

export default withContexts(RulesTrafficCharts, [FWStatsContextContainer]);

type ChartProps = {
  className: string;
  title: string;
  link: string;
};
const ChartWrapper: FC<ChartProps> = ({ className, title, link }) => {
  return (
    <div className={className}>
      <ChartsHeader title={title} />
      <div className={styles.chartsWrapper}>
        <GrafanaGraph link={link} />
      </div>
    </div>
  );
};
