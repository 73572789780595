import React, { FC, ReactElement, useMemo } from "react";
import Label from "./Label";
import styles from "./LabelArray.module.scss";
import ReactTooltip from "react-tooltip";
import { classNames } from "../../../helpers/common/classNames";
import LabelWithCombinedValue from "./LabelWithCombinedValue";

type LabelProps = {
  values?: Array<string | number>;
  combinedValues?: Array<{
    title: string;
    values?: Array<number | string>;
  }>;
  title?: string;
  isOutlined?: boolean;
  className?: string;
  isRemovable?: boolean;
  onClick?: (arg: any) => void;
  tooltipId?: string;
  withWrap?: boolean;
  singleLabelClassname?: string;
  isSmall?: boolean;
  disableTooltip?: boolean;
  labelIcon?: ReactElement;
};

const LabelArray: FC<LabelProps> = ({
  values,
  title,
  isOutlined,
  isRemovable,
  isSmall,
  onClick,
  tooltipId,
  withWrap,
  singleLabelClassname,
  className,
  combinedValues,
  disableTooltip,
  labelIcon,
  ...restProps
}) => {
  const singleElement = {
    gridTemplateColumns: `fit-content(100%)`,
  };
  const multiElements =
    values && values?.length > 2
      ? {
          gridTemplateColumns: `repeat(${values?.length}, fit-content(100%)) 15px`,
        }
      : {
          gridTemplateColumns: `repeat(2, fit-content(50%))`,
        };

  const key = useMemo(() => tooltipId || "labelArray" + Date.now(), [
    tooltipId,
  ]);
  const singleLabelClass = classNames(styles.labels, singleLabelClassname);

  return (
    <div
      className={classNames(
        !withWrap && styles.wrapper,
        withWrap && styles.withWrap,
        className
      )}
      style={values && values?.length > 1 ? multiElements : singleElement}
      {...restProps}
    >
      {!withWrap &&
        values &&
        convertValuesToString(values)?.map(
          (value, i) =>
            i < 2 && (
              <Label
                key={value + i}
                className={singleLabelClass}
                isOutlined={isOutlined}
                isRemovable={isRemovable}
                onClick={() => onClick?.(value)}
              >
                {labelIcon} {value}
              </Label>
            )
        )}
      {withWrap &&
        values &&
        convertValuesToString(values)?.map((value, i) => (
          <Label
            key={value + i}
            className={classNames(
              styles.labels,
              withWrap && styles.wrap,
              singleLabelClassname
            )}
            isOutlined={isOutlined}
            isRemovable={isRemovable}
            onClick={() => onClick?.(value)}
          >
            {labelIcon} {value}
          </Label>
        ))}
      {combinedValues &&
        combinedValues?.map((item, i) => (
          <LabelWithCombinedValue
            title={item.title}
            combinedValues={item.values}
            key={item.title + i}
            className={classNames(styles.labels, withWrap && styles.wrap)}
            isOutlined={isOutlined}
            isSmall={isSmall}
          />
        ))}
      {values && values.length > 2 && !withWrap && (
        <div data-tip data-for={key}>
          ...
        </div>
      )}
      <ReactTooltip
        id={key}
        aria-haspopup="true"
        place="bottom"
        type="light"
        className={styles.tooltip}
        disable={disableTooltip}
      >
        <div className={styles.tooltipTitle}>{title}</div>
        {values &&
          convertValuesToString(values).map((value, i) => (
            <div key={value + i} className={styles.tooltipValue}>
              {value}
            </div>
          ))}
      </ReactTooltip>
    </div>
  );
};

export default LabelArray;

const convertValuesToString = (
  values: Array<string | number>
): Array<string> => {
  return values?.map((value) => value?.toString());
};
