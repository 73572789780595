import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function StatisticsIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0a1 1 0 0 0-1 1v18c0 .5.4 1 1 1h3c.6 0 1-.5 1-1V1c0-.6-.4-1-1-1h-3ZM7.5 7c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v12c0 .5-.4 1-1 1h-3a1 1 0 0 1-1-1V7ZM0 14c0-.6.4-1 1-1h3c.6 0 1 .4 1 1v5c0 .5-.4 1-1 1H1a1 1 0 0 1-1-1v-5Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default StatisticsIcon;
