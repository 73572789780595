import { DropdownItem } from "./../../helpers/types";
export const PROTOCOL_TYPES = [
  { value: "tcp", label: "TCP" },
  { value: "udp", label: "UDP" },
];

export const NEW_PUBLIC_IP = "Request a new public IP address";

export const SOURCE_ONE_TO_ONE = "SOURCE_ONE_TO_ONE";
export const SOURCE_NAT = "SOURCE_NAT";
export const DESTINATION_NAT = "DESTINATION_NAT";

export const NAT_TYPES_MAP: Array<DropdownItem> = [
  { key: "SOURCE_ONE_TO_ONE", value: "1:1 NAT" },
  { key: "SOURCE_NAT", value: "Source NAT" },
  // { key: "DESTINATION_NAT", value: "Port forwarding" },
];
