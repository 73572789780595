import React, { ReactElement } from "react";

function FlagUSA(): ReactElement {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.333 0H0v1.773h17.333V0ZM17.333 3.56H0v1.773h17.333V3.56ZM17.333 10.666H0v1.774h17.333v-1.774ZM17.333 14.227H0V16h17.333v-1.773ZM17.333 7.106H0V8.88h17.333V7.106Z"
        fill="#DC4437"
      />
      <path
        d="M17.333 1.773H0v1.774h17.333V1.773ZM17.333 5.333H0v1.774h17.333V5.333ZM17.333 12.44H0v1.773h17.333V12.44ZM17.333 8.894H0v1.773h17.333V8.894Z"
        fill="#FFFFFF"
      />
      <path d="M9.333 0H0v8.893h9.333V0Z" fill="#2A66B7" />
      <path
        d="m1.8.533.307.654.693.106-.507.52.12.72-.613-.346-.613.346.12-.72-.507-.52.693-.106L1.8.533ZM4.533.533l.307.654.693.106-.493.52.107.72-.614-.346-.613.346.107-.72-.494-.52.694-.106.306-.654ZM7.267.533l.306.654.694.106-.507.52.12.72-.613-.346-.614.346.12-.72-.506-.52.693-.106.307-.654ZM3.2 3.2l.307.654.693.106-.493.52.106.72-.613-.346-.613.346.106-.72-.493-.52.693-.106L3.2 3.2ZM5.933 3.2l.307.654.693.106-.506.52.12.72-.614-.346-.613.346.12-.72-.507-.52.694-.106.306-.654ZM1.8 6.027l.307.653.693.107-.507.507.12.733L1.8 7.68l-.613.347.12-.733L.8 6.787l.693-.107.307-.653ZM4.533 6.027l.307.653.693.107-.493.507.107.733-.614-.347-.613.347.107-.733-.494-.507.694-.107.306-.653ZM7.267 6.027l.306.653.694.107-.507.507.12.733-.613-.347-.614.347.12-.733-.506-.507.693-.107.307-.653Z"
        fill="#FFFFFF"
      />
    </svg>
  );
}

export default FlagUSA;
