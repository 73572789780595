import React, { ReactElement, useCallback } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import {
  CONNECTIONS,
  LOCATIONS,
  SERVICES,
} from "../../helpers/navigation/entries";
import { PathEntry } from "../../helpers/types";
import { Routes } from "../common";
import { RouteEntry } from "../types";
import ConnectionStep from "./ConnectionStep";
import LocationStep from "./LocationStep";
import ServicesStep from "./ServicesStep";
import OnboardingContextContainer from "./OnboardingContext";
import { withContexts } from "../../helpers/hocs/withContexts";
import LocationContextContainer from "./LocationStep/LocationContext";
import ConnectionsContextContainer from "./ConnectionStep/ConnectionsContext";
import ServicesContext from "./ServicesStep/ServicesContext";
import { ServicesPreferencesContextContainer } from "../ServicesPreferencesPage/ServicesPreferencesContext";

const stepsRoutes: Array<RouteEntry> = [
  { pathFunc: LOCATIONS, component: LocationStep },
  { pathFunc: CONNECTIONS, component: ConnectionStep },
  { pathFunc: SERVICES, component: ServicesStep },
];

function WizardToolPage(): ReactElement {
  const path = useMatchPath();

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <OnboardingContextContainer>
      <Switch>
        <Route exact path={path}>
          <Redirect to={fullPath(LOCATIONS())} />
        </Route>
        <Routes
          routes={stepsRoutes}
          tabs={(<div></div>) as any} // TODO tabs should be not required
          pathFormer={(fn) => fullPath(fn())}
        />
      </Switch>
    </OnboardingContextContainer>
  );
}
export default withContexts(WizardToolPage, [
  OnboardingContextContainer,
  LocationContextContainer,
  ConnectionsContextContainer,
  ServicesContext,
  ServicesPreferencesContextContainer,
]);
