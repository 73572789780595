import {
  GTimeRange,
  parseGTime,
} from "../../../components/common/charts/GraphanaLinksFactory";

const fromElasticFilterBody = (
  fieldName: string,
  tenant: string,
  eventType: string,
  timeRange: GTimeRange
): { [key: string]: any } => {
  const { from, to } = timeRange;
  const gte = parseGTime(from);
  const lte = parseGTime(to);
  const field = `${fieldName}.keyword`;
  return {
    aggs: {
      "2": {
        terms: {
          field,
          order: { _count: "desc" },
          size: 50,
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          { match_phrase: { "event_type.keyword": eventType } },
          { match_phrase: { "tenant_name.keyword": tenant } },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte,
                lte,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default fromElasticFilterBody;
