import { FC } from "react";
import ResultTable from "../common/ResultTable";
import { useSearchContext } from "../SearchContext";
import { commonTableColumns } from "../table/commonTableColumns";
import { groupTableStyles } from "../helpers/consts";
import { formPathFromArray } from "../../../helpers/navigation";
import { CYBER_THREATS, INSIGHTS } from "../../../helpers/navigation/entries";

const CyberThreatsTable: FC = () => {
  const { data, statuses } = useSearchContext();

  return (
    <ResultTable
      title="Cyber Threats"
      data={data?.cyberThreats}
      columns={commonTableColumns("Cyber Threat")}
      status={statuses?.cyberThreatsStatus}
      style={{ ...groupTableStyles, borderLeft: "none" }}
      goto={formPathFromArray([INSIGHTS(), CYBER_THREATS()])}
    />
  );
};
export default CyberThreatsTable;
