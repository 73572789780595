import Validator from "./Validator";

export default function validatePortInterface(
  data: Partial<any>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.physical_port_name, "physical_port_name");
  return validator;
}
