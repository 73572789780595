import React from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../components/common/formComponents/Input";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import ConnectionSection from "../../common/ConnectionSection";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import RoutingRemoteLink from "./RoutingRemoteLink";
import RoutingRemoteNetworkField from "./RoutingRemoteNetworkField";

const RoutingSection = () => {
  const { fields } = useConnectionConfiguratorContext();

  const showBgpPassword = fields.connection_interface !== "Equinix Fabric";

  return (
    <ConnectionSection title="Routing">
      <DropdownBasic
        id="connection_interface"
        itemsList={["BGP"].map(mapStringToItem)}
        onChange={() => undefined}
        isMedium
        selected={mapStringToItem(fields.routing)}
        label="Routing"
      />
      <RoutingRemoteLink />
      {showBgpPassword ? (
        <Input medium label="BGP neighbor IP" />
      ) : (
        <RoutingRemoteNetworkField />
      )}
      {showBgpPassword && <Input medium label="BGP Password" isOptional />}
    </ConnectionSection>
  );
};

export default RoutingSection;
