import React from "react";
import { GREY_FONT } from "../../../../../helpers/common/colorAliases";
import { PosProps } from "./utils";

type Props = {
  count: number;
  label: string;
  isSmall?: boolean;
} & PosProps;
export default function VPCLabel({
  x,
  y,
  count,
  label,
  isSmall,
}: Props): React.ReactElement {
  const LABEL_H = isSmall ? 14 : 17 + 4;
  return (
    <>
      <rect
        rx={4}
        x={x + 2}
        y={y + 2}
        width={isSmall ? 10 : 55 - 4}
        height={LABEL_H - 4}
        strokeWidth={1}
        fill={"#f0eefd"}
        stroke={"#BEB8EA"}
      />
      <text
        x={x + 4}
        y={y + LABEL_H / 2 + 4}
        fill={GREY_FONT}
        fontSize={isSmall ? 8 : 12}
      >
        {isSmall ? count : `${label}  ${count}`}
      </text>
    </>
  );
}
