import { FC, useMemo } from "react";
import styles from "./ChecklistComponent.module.scss";
import { WHITE } from "../../../../../helpers/common/colorAliases";
import { Checkbox } from "../../../../../components/common/formComponents/Checkbox";
import { useWebFilteringsContext } from "../../../WebFilteringContext";
import { WEB_FILTERING_ENTITIES } from "../../../helpers/consts";
import { BlockSelectedChecklist } from "../../../types";

type Props = { data: BlockSelectedChecklist };

const ChecklistComponent: FC<Props> = ({ data }) => {
  const {
    webThreatsFilterCategories,
    contentFilterCategories,
    changeChecklist,
  } = useWebFilteringsContext();
  const { icon, color, title, listTitle, id } = data;
  const Icon = icon;

  const list = useMemo(() => {
    if (id === WEB_FILTERING_ENTITIES.CONTENT_FILTER)
      return contentFilterCategories;
    if (id === WEB_FILTERING_ENTITIES.WEB_THREATS_FILTER)
      return webThreatsFilterCategories;
    return [];
  }, [data, contentFilterCategories, webThreatsFilterCategories]);

  const handleChange = (i: number) => {
    const newList = [...list];
    newList[i] = { ...newList[i], isChecked: !newList[i].isChecked };
    changeChecklist(id, newList);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.line} style={{ backgroundColor: color }} />
        <span className={styles.svgWrapper} style={{ backgroundColor: color }}>
          <Icon color={WHITE} />
        </span>
        <span className={styles.title}>{title}</span>
      </div>
      <div className={styles.content}>
        <div className={styles.row}>
          <div className={styles.label}>{listTitle}</div>
          <div className={styles.threatsBlock}>
            {list?.map((item, i) => (
              <Checkbox
                key={item.id}
                isChecked={item.isChecked}
                onChange={() => handleChange(i)}
                label={item.name}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistComponent;
