import React, { ReactNode } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../components/common/formComponents/Input";
import { useConnectionContext } from "../../../ConnectionContext";
import ConnectionSection from "../../common/ConnectionSection";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import { CONNECTION_TYPE_IDX_MAP } from "../../helpers/consts";

type Props = {
  className?: string;
  children?: ReactNode;
};

const DefaultConnectionFields = ({ className, children }: Props) => {
  const { locations } = useConnectionContext();
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  return (
    <ConnectionSection className={className} title={"Connection"}>
      <Input
        label="Name"
        name="name"
        placeholder=""
        value={fields.name}
        medium
        error={""}
        onChange={(e) => fieldsOnChange("name", e.target.value)}
      />
      <DropdownBasic
        id={"location"}
        onChange={(val) => {
          fieldsOnChange("location", val.key);
        }}
        selected={locations.find((loc) => loc?.key === fields.location)}
        label="Location"
        itemsList={locations}
        isMedium
        error={""}
      />
      <DropdownBasic
        id={"type"}
        onChange={(val) => {
          fieldsOnChange("type", val.key);
        }}
        selected={CONNECTION_TYPE_IDX_MAP.find(
          (idx) => idx?.key === fields.type
        )}
        label="Type"
        placeholder="Choose Connection Type"
        itemsList={CONNECTION_TYPE_IDX_MAP}
        isMedium
      />
      {children}
    </ConnectionSection>
  );
};

export default DefaultConnectionFields;
