import React, { FC, ReactElement } from "react";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../../../contexts/UserContext";
import { IPAM } from "../../../../../helpers/navigation/entries";
import { UserRoles } from "../../../../../helpers/rolesHelpers";
import { IconProps } from "../../../../../helpers/types";
import IconBlankButton from "../../../../common/buttons/IconBlankButton";
import CloudRouterIcon from "../../../../icons/zayo/menu/CloudRouterIcon";
import IPAMIcon from "../../../../icons/zayo/menu/IPAMIcon";
import SecurityIcon from "../../../../icons/zayo/menu/SecurityIcon";
import styles from "./ZayoMenu.module.scss";

type Props = {};

const ZayoMenu: FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      {getDocumentations().map((doc) => {
        if (doc.onClick) {
          return (
            <div className={styles.link} onClick={doc.onClick}>
              <IconBlankButton
                id={"documentation"}
                icon={doc.icon}
                label={doc.label}
                className={styles.btn}
              />
            </div>
          );
        } else {
          return (
            <Link to={doc.link || ""} className={styles.link}>
              <IconBlankButton
                id={"documentation"}
                icon={doc.icon}
                label={doc.label}
                className={styles.btn}
              />
            </Link>
          );
        }
      })}
    </div>
  );
};

export const AdminIpamMenu: FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      {getIpam().map((doc) => {
        return (
          <Link to={doc.link || ""} className={styles.link}>
            <IconBlankButton
              id={"documentation"}
              icon={doc.icon}
              label={doc.label}
              className={styles.btn}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default ZayoMenu;

const getIpam = (): Array<{
  link?: string;
  icon: (props: IconProps) => ReactElement;
  label: string;
  onClick?: () => void;
}> => {
  return [
    {
      link: "/" + IPAM().path,
      icon: IPAMIcon,
      label: "IPAM",
    },
  ];
};

const getDocumentations = (): Array<{
  link?: string;
  icon: (props: IconProps) => ReactElement;
  label: string;
  onClick?: () => void;
}> => {
  const { user } = useUserContext();
  const isTenant = React.useMemo(() => user?.role === UserRoles.TENANT, [user]);

  return isTenant
    ? [
        {
          link: "",
          icon: CloudRouterIcon,
          label: "Cloud Router",
        },
        {
          link: "",
          icon: SecurityIcon,
          label: "Security services",
        },
      ]
    : [
        {
          link: "",
          icon: CloudRouterIcon,
          label: "Cloud Router",
        },
        {
          link: "",
          icon: SecurityIcon,
          label: "Security services",
        },
        {
          link: "/" + IPAM().path,
          icon: IPAMIcon,
          label: "IPAM",
        },
      ];
};
