import React, { FC, ReactElement } from "react";
import DialogTemplateOneColumn from "../DialogTemplateOneColumn";
import {
  DialogControlsType,
  IconProps,
  RequestStatus,
} from "../../../../helpers/types";
import TitleWithIcon from "../../../common/TitleWithIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";

type ConfirmDialogProps = {
  icon?: (props: IconProps) => ReactElement;
  color?: string;
  title: string;
  message: string | ReactElement;
  controls: DialogControlsType;
  className?: string;
  status?: RequestStatus;
};

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  icon,
  title,
  message,
  controls,
  className,
  status,
  color,
}) => {
  return (
    <DialogTemplateOneColumn
      header={<DialogTitle icon={icon} title={title} color={color} />}
      controls={controls}
      isSmall={true}
      className={className}
      status={status}
    >
      <div style={{ wordBreak: "break-word" }}>{message}</div>
    </DialogTemplateOneColumn>
  );
};

export default ConfirmDialog;

const DialogTitle: FC<{
  icon?: (props: IconProps) => ReactElement;
  color?: string;
  title: string;
}> = ({ icon: Icon, title, color }) => {
  return Icon ? (
    <TitleWithIcon
      isSmaller
      icon={<Icon color={color || GREY_FONT} />}
      title={title}
    />
  ) : (
    <>{title}</>
  );
};
