import React from "react";
import { IconProps } from "../../../helpers/types";
import { formIconAttrs } from "../../../helpers/iconsHelpers";
import { GREY_FONT } from "../../../helpers/common/colorAliases";

function InternetIcon(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 30 30"
      {...formIconAttrs(30, 30, props)}
    >
      <path
        fill={props.color || GREY_FONT}
        d="M15.134 5C9.56 5 5 9.56 5 15.134c0 5.573 4.56 10.133 10.134 10.133 5.573 0 10.133-4.56 10.133-10.133C25.267 9.56 20.707 5 15.134 5zm6.992 6.08h-2.939c-.304-1.317-.81-2.432-1.419-3.648 1.825.71 3.446 1.925 4.358 3.648zm-6.992-4.053c.81 1.216 1.52 2.533 1.925 4.053h-3.85c.405-1.418 1.114-2.837 1.925-4.053zM7.33 17.16c-.203-.608-.304-1.317-.304-2.026 0-.71.101-1.419.304-2.027h3.445c-.101.71-.101 1.317-.101 2.027 0 .71.101 1.317.101 2.026H7.331zm.81 2.027h2.94c.303 1.318.81 2.432 1.418 3.648-1.824-.709-3.446-1.925-4.358-3.648zm2.94-8.107H8.14c1.014-1.723 2.534-2.939 4.358-3.648-.608 1.216-1.115 2.33-1.419 3.648zm4.053 12.16c-.811-1.215-1.52-2.533-1.926-4.053h3.851c-.405 1.419-1.115 2.838-1.925 4.054zm2.33-6.08h-4.661c-.101-.709-.203-1.317-.203-2.026 0-.71.102-1.318.203-2.027h4.763c.101.71.203 1.317.203 2.027 0 .71-.203 1.317-.304 2.026zm.305 5.675c.608-1.114 1.114-2.33 1.418-3.648h2.939c-.912 1.723-2.533 2.939-4.358 3.648zm1.824-5.675c.1-.709.1-1.317.1-2.026 0-.71-.1-1.318-.1-2.027h3.445c.203.608.304 1.317.304 2.027 0 .71-.101 1.418-.304 2.026h-3.445z"
      ></path>
    </svg>
  );
}

export default InternetIcon;
