import { CardElement } from "../../../../../components/common/Card/cardTypes";
import ConnectionIcon from "../../../../../components/icons/topologyButtons/ConnectionIcon";
import CyberThreatsIcon from "../../../../../components/icons/topologyButtons/CyberThreatsIcon";
import SegmentsIcon from "../../../../../components/icons/topologyButtons/SegmentsIcon";
import UsersIcon from "../../../../../components/icons/topologyButtons/UsersIcon";
import { formPathFromArray } from "../../../../../helpers/navigation";
import {
  NETWORK,
  CONNECTIONS,
  INSIGHTS,
  CYBER_THREATS,
  CONNECTIVITY,
  REMOTE_USERS,
  PROVISIONING,
  USER_GROUPS,
} from "../../../../../helpers/navigation/entries";

export const CONNECTIONS_BTN = "Connections";
export const USERS_BTN = "Remote users";
export const SEGMENTS_BTN = "Network Segmentation";
export const CYBER_THREATS_BTN = "Cyber threats";

export const TOPOLOGY_CARDS_MAP: Array<CardElement> = [
  {
    icon: <ConnectionIcon />,
    link: formPathFromArray([NETWORK(), CONNECTIVITY(), CONNECTIONS()]),
    title: CONNECTIONS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SegmentsIcon />,
    // link: formPathFromArray([SERVICES(), SEGMENTATION(), SEGMENTS()]),
    link: formPathFromArray([NETWORK(), REMOTE_USERS(), USER_GROUPS()]),
    title: SEGMENTS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <CyberThreatsIcon />,
    link: formPathFromArray([INSIGHTS(), CYBER_THREATS()]),
    title: CYBER_THREATS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <UsersIcon />,
    link: formPathFromArray([NETWORK(), REMOTE_USERS(), PROVISIONING()]),
    title: USERS_BTN,
    disabled: false,
    data: undefined,
  },
];
