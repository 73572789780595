import {
  CreateInterfacesType,
  CreateTenantType,
} from "../../components/dialogs/Tenant/TenantDialog";
import Validator, { errorKey } from "./Validator";

export function validateTenant(
  data: Partial<CreateTenantType> & { systems: any },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.systems, "systems");
  validator.checkEmpty(data.full_name, "full_name");
  data.email && validator.checkEmail(data.email, "email");
  return validator;
}

export function validateTenantInterfaces(
  data: Array<CreateInterfacesType>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  data.map((el, i) => {
    validator.checkEmpty(
      el.virtual_interface_name,
      errorKey(i, "virtual_interface_name")
    );
    validator.checkEmpty(el.system, errorKey(i, "system"));
    validator.checkEmpty(el.interface_id, errorKey(i, "interface_id"));
    validator.checkIsNumeric(+el.interface_id, errorKey(i, "interface_id"));
  });

  return validator;
}
