import React, { ReactElement } from "react";
import { PathEntry } from "../types";

// allowed to wrap any component to a specific contexts

type Props = {
  alternatives?: Array<PathEntry>;
  additionParameter?: string | number;
};

export function withContexts<T>(
  WrappedComponent: React.ComponentType<Omit<T, "alternatives">>,
  contexts: Array<React.ComponentType<any>>
) {
  return (props: T & Props): ReactElement => {
    const { additionParameter } = props;
    return contexts.reduce(
      (Comp, Provider) => (
        <Provider additionParameter={additionParameter}>{Comp}</Provider>
      ),
      <WrappedComponent {...props} />
    );
  };
}
