import React from "react";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./StatusPageTable.module.scss";
import { UserThumbnail } from "../UsersPage/components/shared/UserThumbnail";
import { RemoteUserStatus } from "../../helpers/types";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import Label from "../../components/common/table/Label";
import StatusIcon from "../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../helpers/common/colorHelpers";
import LocationFillIcon from "../../components/icons/LocationFillIcon";
import { classNames } from "../../helpers/common/classNames";
import {
  US,
  RU,
  IL,
  PK,
  SG,
  IN,
  IE,
  CN,
  CA,
} from "country-flag-icons/react/3x2";

const FLAGS: { [key: string]: JSX.Element } = {
  Pakistan: <PK className={styles.flag} />,
  Israel: <IL className={styles.flag} />,
  Russia: <RU className={styles.flag} />,
  "United States": <US className={styles.flag} />,
  Singapore: <SG className={styles.flag} />,
  India: <IN className={styles.flag} />,
  Ireland: <IE className={styles.flag} />,
  China: <CN className={styles.flag} />,
  Canada: <CA className={styles.flag} />,
};

export const remoteUserStatusTableColumns = (): Array<any> => {
  return [
    {
      accessor: "handshake",
      Header: "Latest handshake",
      Cell: ({ cell: { value } }: { cell: { value: number } }) => {
        const date = new Date(value);
        return (
          <div className={styles.greyText}>
            <span className={styles.date}>
              {date.toLocaleDateString("en-US")}
            </span>
            {date.toLocaleTimeString()}
          </div>
        );
      },
    },
    {
      accessor: "username",
      Header: "User",
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        return (
          <div data-for={value} data-tip className={styles.user}>
            <UserThumbnail username={value} />
          </div>
        );
      },
    },
    {
      accessor: "status",
      Header: "Activity",
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        const isActive = !value.includes("Retrying");
        const status = (isActive && "Active") || "Disconnected";
        const labelStyles = classNames(
          styles.labelWrapper,
          isActive && styles.active
        );
        return (
          <Label className={labelStyles}>
            <div className={styles.labels}>
              <span className={styles.iconWrapper}>
                <StatusIcon
                  color={getColorFromStatus(status.toLowerCase() || "")}
                />
              </span>
              <span className={styles.value}>{status}</span>
            </div>
          </Label>
        );
      },
    },
    {
      accessor: "city",
      Header: "Location",
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        return (
          <div className={styles.connectedWrapper}>
            {value && <LocationFillIcon color={GREY_FONT} />}
            {value}
          </div>
        );
      },
    },
    {
      accessor: "location",
      Header: "Country",
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        const flag = FLAGS?.[value];
        return (
          <div className={styles.connectedWrapper}>
            {flag}
            {value}
          </div>
        );
      },
    },
    {
      accessor: "ip",
      Header: "Connected from",
      Cell: ({ cell: { value } }: { cell: { value: string } }) => {
        return <div className={styles.greyText}>{value}</div>;
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof RemoteUserStatus,
  }));
};
