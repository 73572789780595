import { LicenseKey } from "../../pages/EnterLicenseKeyPage/domain";
import Api from "./Api";
import { LICENSE } from "./apiPaths";

export class LiceseApi extends Api {
  constructor() {
    super();
  }

  async setLicense(key: LicenseKey) {
    return await this.fetchBroker<null>(LICENSE.SET(key));
  }
}

export const licenseApi = new LiceseApi();
