import React, { ReactElement } from "react";

function LoginIconsInRow(props: { className?: string }): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="420"
      height="120"
      viewBox="0 0 420 120"
      fill="none"
      className={props.className}
    >
      <path
        fill="#FFFFFF"
        fillRule="evenodd"
        d="M60 120c33.137 0 60-26.863 60-60S93.137 0 60 0 0 26.863 0 60s26.863 60 60 60zm15.535-69.457a1.006 1.006 0 00-.57-.916l-14.51-6.845a1 1 0 00-.854 0L45.09 49.627c-.35.165-.575.53-.57.917.25 22.313 13.413 26.72 15.287 27.234a.75.75 0 00.44-.008c1.864-.589 15.032-5.51 15.288-27.226zM210 120c33.137 0 60-26.863 60-60S243.137 0 210 0s-60 26.863-60 60 26.863 60 60 60zm15.23-63.619a9.493 9.493 0 01-6.483 4.593l-.392 9.398a6.522 6.522 0 013.35 9.367 6.522 6.522 0 11-7.346-9.56l.388-9.307a9.515 9.515 0 01-2.513-1.009 9.5 9.5 0 01-2.556-2.15l-7.799 4.261a7.16 7.16 0 11-1.95-3.492l7.895-4.313a9.486 9.486 0 01.928-7.301 9.514 9.514 0 1116.478 9.513zM360 120c33.137 0 60-26.863 60-60S393.137 0 360 0s-60 26.863-60 60 26.863 60 60 60zm-12.936-80.427a1 1 0 00-1.337.941v35.483a1 1 0 001 1h26.546a1 1 0 001-1V49.995a1 1 0 00-.664-.942l-26.545-9.48zm7.319 14.995a.5.5 0 00-.5.5v5.117a.5.5 0 00.5.5h3.078a.5.5 0 00.5-.5v-5.117a.5.5 0 00-.5-.5h-3.078zm-.5 10.695a.5.5 0 01.5-.5h3.078a.5.5 0 01.5.5v5.117a.5.5 0 01-.5.5h-3.078a.5.5 0 01-.5-.5v-5.117zm8.656-10.695a.5.5 0 00-.5.5v5.117a.5.5 0 00.5.5h3.078a.5.5 0 00.5-.5v-5.117a.5.5 0 00-.5-.5h-3.078zm-.5 10.695a.5.5 0 01.5-.5h3.078a.5.5 0 01.5.5v5.117a.5.5 0 01-.5.5h-3.078a.5.5 0 01-.5-.5v-5.117z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default LoginIconsInRow;
