import { FC, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import TableControls from "./table/TableControls";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table, {
  DEFAUTL_LIMIT,
  DEFAUTL_OFFSET,
} from "../../components/common/table/newTable/Table";
import { tenantTableHeader } from "./table/tenantTableHeader";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { Tenant } from "./types";
import { PACKAGES, TENANT, TENANT_L3 } from "../../helpers/navigation/entries";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import { useLocalTenantsContext } from "./TenantsContext";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import TenantConfigAdd from "../../components/dialogs/Tenant/TenantConfigAdd";
import TenantConfigEdit from "../../components/dialogs/Tenant/TenantConfigEdit";

const TenantsPageContent: FC = () => {
  const history = useHistory();
  const {
    listStatus,
    fetchData,
    list = [],
    count,
    totalCount,
    remove,
    removeStatus,
    editServices,
  } = useLocalTenantsContext();
  const { setActivePackage } = useTenantContext();

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState();

  const [tryDelete, selectedToDelete, sureDeleteProps] = useDeleteDialog<any>(
    remove,
    removeStatus
  );

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    fetchData(undefined, undefined, offset, limit);
  };

  const handleChangeService = (tenant: Tenant, service: string): void => {
    const {
      is_ddos_service,
      is_firewall_service,
      is_fqdn_service,
      is_ids_service,
      is_mesh_enabled,
      is_remote_users_service,
      is_secure_web_gw_service,
      is_segments_service,
    } = tenant;
    const params: any = {
      is_ddos_service,
      is_firewall_service,
      is_fqdn_service,
      is_ids_service,
      is_mesh_enabled,
      is_remote_users_service,
      is_secure_web_gw_service,
      is_segments_service,
      [service]: !tenant[service as keyof Tenant],
    };
    editServices(params, tenant.name);
  };

  const handleClickPackage = (value: string, tenant: string) => {
    setActivePackage(value, tenant);
    const path = TENANT(tenant).path + "/" + PACKAGES().path;
    history.push(path);
  };

  const handleClickRow = (tenant: any): void => {
    setSelectedTenant(tenant);
    setShowEdit(true);
  };

  const handleClickInterfaces = ({ name }: any): void => {
    const path = TENANT(name).path + "/" + TENANT_L3().path;
    history.push(path);
  };

  const rowActions: ActionFuncs<Tenant> = useMemo(
    () => ({
      onEdit: handleClickRow,
      onDelete: tryDelete,
      onEditInterfaces: handleClickInterfaces,
    }),
    [handleClickRow, tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Tenant"}
        name={`${JSON.stringify(selectedToDelete?.name)}`}
      />

      {showAdd ? (
        <TenantConfigAdd onClose={() => setShowAdd(false)} />
      ) : (
        <>
          {showEdit ? (
            <TenantConfigEdit
              tenant={selectedTenant}
              onClose={() => setShowEdit(false)}
            />
          ) : (
            <TableWrapper
              titleProps={{
                title: `Tenants (${totalCount || 0})`,
                children: <TableControls onAdd={() => setShowAdd(true)} />,
              }}
              dataStatus={listStatus}
              tablePlaceholder={["Tenants", "Tenant"]}
              // isEmpty={list && list.length === 0}
            >
              <Table
                fetchAction={fetchWrapper}
                data={list}
                count={count}
                totalCount={totalCount}
                columns={tenantTableHeader(
                  handleChangeService,
                  handleClickPackage
                )}
                header={TableHeader}
                gridColumnTemplate="160px 120px 160px repeat(2, 1fr) repeat(8, 80px) 70px"
                rowActions={rowActions}
              />
            </TableWrapper>
          )}
        </>
      )}
    </>
  );
};
export default TenantsPageContent;
