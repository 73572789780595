import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function NetworkTopologyIcon({
  color = FONT,
  height = 20,
  width = 20,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83226 4.78437C5.91221 4.54068 6.71875 3.57546 6.71875 2.42188C6.71875 1.08431 5.63444 0 4.29688 0C2.95931 0 1.875 1.08431 1.875 2.42188C1.875 3.54106 2.63415 4.48293 3.6656 4.76065V11.5984C1.59704 11.8693 0 13.6388 0 15.7812C0 18.1112 1.8888 20 4.21875 20C6.31819 20 8.05946 18.4664 8.38344 16.4584H15.2763C15.5937 17.4261 16.5043 18.125 17.5781 18.125C18.9157 18.125 20 17.0407 20 15.7031C20 14.3656 18.9157 13.2812 17.5781 13.2812C16.3808 13.2812 15.3864 14.1501 15.191 15.2917L8.40941 15.2917C8.32728 14.581 8.06851 13.9241 7.67813 13.366L13.5521 7.49198C13.8845 7.66361 14.2618 7.76053 14.6617 7.76053C15.9993 7.76053 17.0836 6.67622 17.0836 5.33866C17.0836 4.00109 15.9993 2.91678 14.6617 2.91678C13.3241 2.91678 12.2398 4.00109 12.2398 5.33866C12.2398 5.85262 12.3999 6.32919 12.673 6.72122L6.88362 12.5106C6.3069 12.0401 5.60341 11.7191 4.83226 11.6068V4.78437Z"
        fill={color}
      />
    </svg>
  );
}

export default NetworkTopologyIcon;
