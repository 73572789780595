import { CellProps } from "react-table";
import { CellFactory } from "../../../components/common/table/newTable/cells/CellsFactory";
import VTEPIcon from "../../../components/icons/VTEPIcon";
import { VTEPType } from "../types";

const cellFactory = new CellFactory<VTEPType>({
  icon: VTEPIcon,
});

export const vtepTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: cellFactory.idCell(),
    },
    {
      Header: "Local IP",
      accessor: "local_ip",
    },
    {
      Header: "VRF",
      accessor: "vrf",
      Cell: ({ cell: { value } }: CellProps<VTEPType>) => {
        return value?.name || "";
      },
    },
    {
      Header: "Type",
      accessor: "vtep_type",
    },
    {
      Header: "Remote",
      accessor: "remote_ips",
      Cell: cellFactory.labelArrayCell("Remote"),
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof VTEPType }));
};
