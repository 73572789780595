import { DragEvent, FC, useState } from "react";
import { FieldsType } from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import Input from "../../../../../../../components/common/formComponents/Input";
import styles from "./PrefixFilterItem.module.scss";
import CloseIcon from "../../../../../../../components/icons/CloseIcon";
import IconBlankButton from "../../../../../../../components/common/buttons/IconBlankButton";
import { Checkbox } from "../../../../../../../components/common/formComponents/Checkbox";
import { OptionalLabel } from "../../../../../../../components/common/OptionalLabel";
import {
  ACCENT,
  GREY_FONT,
} from "../../../../../../../helpers/common/colorAliases";
import RadioGroup from "../../../../../../../components/common/formComponents/RadioGroup";
import DragDropIcon from "../../../../../../../components/icons/DragDropIcon";
import { classNames } from "../../../../../../../helpers/common/classNames";

type Props = {
  fields: FieldsType;
  idx: number;
  onChange: (idx: number, fields: FieldsType) => void;
  onDelete: () => void;
  draggable: boolean;
  onDragStart: (event: DragEvent<HTMLDivElement>) => void;
  onDragOver: (event: DragEvent<HTMLDivElement>) => void;
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  isDroppable: boolean;
  isDragging: boolean;
};

const PrefixFilterItem: FC<Props> = ({
  fields,
  idx,
  onChange,
  onDelete,
  draggable,
  onDragStart,
  onDragOver,
  onDrop,
  isDroppable,
  isDragging,
}) => {
  const [isExact, setIsExact] = useState<boolean>(!fields.le);

  const handleChange = (field: string, value: any): void => {
    const newFields = { ...fields, [field]: value };
    onChange(idx, newFields);
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        isDroppable && styles.droppable,
        isDragging && styles.dragging
      )}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <Input
        label="IP Prefix"
        name="ip"
        value={fields.ip}
        placeholder="x.x.x.x/x"
        onChange={(e) => handleChange("ip", e.target.value)}
        medium
        error={""}
      />
      <OptionalLabel
        isShown={true}
        text={"Subnet  Mask Range"}
        medium={true}
        className={styles.customLabel}
      >
        <div className={styles.row}>
          <Checkbox
            isChecked={isExact}
            onChange={() => setIsExact((prev) => !prev)}
            label="Exact"
            containerClassName={styles.checkbox}
          />
          <Input
            name="le"
            value={fields.le}
            onChange={(e) => handleChange("le", +e.target.value)}
            medium
            error={""}
            type={"number"}
            placeholder="Max"
            isPlaceholderAlwaysVisible
            disabled={fields.exactSubnetMaskRange}
            isNotEditable={!isExact}
          />
        </div>
      </OptionalLabel>
      <RadioGroup
        isOneRow
        label={"Action"}
        options={[
          { value: "filter", label: "Filter" },
          { value: "notFilter", label: "Not filter" },
        ]}
        value={fields.match ? "notFilter" : "filter"}
        setActiveValue={(val: string) =>
          handleChange("match", val === "notFilter")
        }
      />
      <IconBlankButton
        className={styles.deleteBtn}
        id={idx + "delete"}
        icon={CloseIcon}
        onClick={onDelete}
        color={GREY_FONT}
      />
      <div className={styles.dragDropBtn} id={idx + "dragDrop"}>
        <span>Priority</span>
        <DragDropIcon color={ACCENT} />
      </div>
    </div>
  );
};
export default PrefixFilterItem;
