import React, { FC } from "react";
import { Link } from "react-router-dom";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import APIIcon from "../../icons/APIIcon";
import LogsIcon from "../../icons/LogsIcon";
import StatisticsIcon from "../../icons/StatisticsIcon";
import IconBlankButton from "../../common/buttons/IconBlankButton";
import styles from "./ExternalApi.module.scss";
import { useUserContext } from "../../../contexts/UserContext";
import { GRAFANA_ROOT_PATH } from "../../common/charts/GraphanaLinksFactory";

export const KIBANA_ROOT_PATH = "/broker/kibana";

const ExternalApi: FC = () => {
  const { isTenant } = useUserContext();
  const API_LINK = `/broker/docs`;

  return (
    <div className={styles.links}>
      <Link to={API_LINK} target={"_blank"}>
        <IconBlankButton
          id={"adminControlsAPI"}
          icon={APIIcon}
          hint={"API"}
          color={GREY_FONT}
        />
      </Link>
      {!isTenant && (
        <>
          <Link to={KIBANA_ROOT_PATH} target={"_blank"}>
            <IconBlankButton
              id={"adminControlsKibana"}
              icon={LogsIcon}
              hint={"Kibana"}
              color={GREY_FONT}
            />
          </Link>
          <Link to={GRAFANA_ROOT_PATH} target={"_blank"}>
            <IconBlankButton
              id={"adminControlsGrafana"}
              icon={StatisticsIcon}
              hint={"Grafana"}
              color={GREY_FONT}
            />
          </Link>
        </>
      )}
    </div>
  );
};
export default ExternalApi;
