import React, { FC, useState } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import { Toggle } from "../../../../../../../components/common/Toggle";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import {
  MAX_BANDWIDTH_LIST,
  SUBSCRIPTION_PERIOD_LIST,
} from "../../../../../utils/wizardToolConsts";
import ConnectionTypeGroup from "../../common/ConnectionTypeGroup";
import FormFooter from "../../common/FormFooter/FormFooter";
import SegmentsComponent from "../../common/SegmentsComponent";
import CloudFields from "../../common/CloudFields";
import styles from "./ConnectionForm.module.scss";
import FormTitle from "../../common/FormTitle/FormTitle";
import RemoteNetworks from "../../common/RemoteNetworks";
import { useConnectionsContext } from "../../../../ConnectionsContext";

const EditConnectionForm: FC = () => {
  const { activeConnectionType, activeConnection } = useConnectionsContext();

  const [isAgent, setIsAgent] = useState<boolean>(false);
  const [connectioTypeField] = useState<string>("ipsec");

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={activeConnection?.name}
          label="Name"
          onChange={() => undefined}
          isFullValue
          medium
          error={""}
        />
        <ConnectionTypeGroup
          value={connectioTypeField}
          onChange={() => undefined}
          type={activeConnectionType}
        />
        {activeConnectionType === "bareMetal" && (
          <Toggle
            isChecked={isAgent}
            onChange={() => setIsAgent((prev) => !prev)}
            label="Agent"
          />
        )}

        {connectioTypeField === "ipsec" && (
          <IpsecConnection
            activeConnection={activeConnection}
            className={styles.fieldsGroup}
            showIpGroup={!isAgent}
          />
        )}

        {isAgent && <CloudFields title="Enter token" />}
        {connectioTypeField === "directConnect" && (
          <div className={styles.msg}>
            Please contact{" "}
            <a className={styles.link}>support@insidepacket.com</a> to establish
            direct connection
          </div>
        )}
      </div>
      <FormFooter onAdd={() => undefined} />
    </>
  );
};
export default EditConnectionForm;

type FormProps = {
  activeConnection?: any;
  className?: string;
  showIpGroup?: boolean;
};

const IpsecConnection: FC<FormProps> = ({
  className,
  activeConnection,
  showIpGroup = true,
}) => {
  return (
    <>
      <DropdownBasic
        id={"connectionFormEditSubscriptionPeriod"}
        onChange={() => undefined}
        selected={mapStringToItem(MAX_BANDWIDTH_LIST[0])}
        label="Connection Bandwidth"
        itemsList={MAX_BANDWIDTH_LIST.map((key) => mapStringToItem(key))}
        isMedium
      />
      <DropdownBasic
        id={"connectionFormEditConnectionBandwidth"}
        onChange={() => undefined}
        selected={mapStringToItem(SUBSCRIPTION_PERIOD_LIST[0])}
        label="Subscription period"
        itemsList={SUBSCRIPTION_PERIOD_LIST.map((key) => mapStringToItem(key))}
        isMedium
      />
      <SegmentsComponent
        list={activeConnection?.network_segments || []}
        onChange={() => undefined}
        error={""}
      />
      <RemoteNetworks
        list={activeConnection?.remote_networks}
        onChange={() => undefined}
        error={""}
      />
      {showIpGroup && (
        <div className={className}>
          <Input
            value={activeConnection?.remote_ip}
            label="Generated IPSEC connection details"
            placeholder="Peer IP"
            isPlaceholderAlwaysVisible
            error={""}
          />
          <Input
            label={undefined}
            value={activeConnection?.psk}
            placeholder="PSK"
            isPlaceholderAlwaysVisible
          />
        </div>
      )}
    </>
  );
};
