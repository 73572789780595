import React, { FC, useEffect, useState } from "react";

import styles from "./FirewallReports.module.scss";
import { PageProps } from "../../helpers/types";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import FirewallContextContainer, {
  useFirewallContext,
} from "../../contexts/servicesContext/FirewallContext";
import { withLayout } from "../../helpers/hocs/withLayout";
import { withContexts } from "../../helpers/hocs/withContexts";
import RulesTrafficCharts from "./graphs/RulesTrafficCharts";
import AutoSortTable, {
  filedAccessor,
  defaultDateColumn,
} from "../../components/common/table/autoSortTable/autosortTable";
import { FWRuleType } from "../../helpers/api/apiTypes";
import { elasticApi } from "../../helpers/api/ElasticApi";
import DropdownWithSearch from "../../components/common/Dropdown/DropdownWithSearch";
import { mapStringToItem } from "../../helpers/mapStringToItem";
import { parseQuery } from "../../helpers/common/parseQuery";
import { ACTIVE_FW_QUERY_PARAM } from "../../helpers/navigation";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import { SERVICES_FW } from "../../helpers/navigation/entries";

const Source_IP = "Source IP";
const Source_Port = "Source Port";
const Destination_IP = "Destination IP";
const Destination_Port = "Destination Port";
const Location_field = "Location";
const Source_Segments = "Source Segments";
const Destination_Segments = "Destination Segments";
const Client_traffic_count = "Client traffic count";
const Server_traffic_count = "Server traffic count";

const columns = [
  defaultDateColumn,
  {
    accessorKey: "Source_IP", //access nested data with dot notation
    header: Source_IP,
  },
  {
    accessorKey: "Source_Port",
    header: Source_Port,
  },
  {
    accessorKey: "Destination_IP",
    header: Destination_IP,
  },
  {
    accessorKey: "Destination_Port",
    header: Destination_Port,
  },
  {
    accessorKey: "Location_field",
    header: Location_field,
  },
  {
    accessorKey: "Source_Segments",
    header: Source_Segments,
  },
  {
    accessorKey: "Destination_Segments",
    header: Destination_Segments,
  },
  {
    accessorKey: "Client_traffic_count",
    header: Client_traffic_count + "[Bytes]",
  },
  {
    accessorKey: "Server_traffic_count",
    header: Server_traffic_count + "[Bytes]",
  },
];
const formatData = (resultArr: any) => {
  const formattedArr: any = [];
  resultArr.forEach((element: any) => {
    const fields = element.fields;
    formattedArr.push({
      date: new Date(filedAccessor(fields, "@timestamp")),
      Source_IP: filedAccessor(fields, Source_IP),
      Source_Port: filedAccessor(fields, Source_Port),
      Destination_IP: filedAccessor(fields, Destination_IP),
      Destination_Port: filedAccessor(fields, Destination_Port),
      Location_field: filedAccessor(fields, Location_field),
      Source_Segments: filedAccessor(fields, Source_Segments),
      Destination_Segments: filedAccessor(fields, Destination_Segments),
      Client_traffic_count: filedAccessor(fields, Client_traffic_count),
      Server_traffic_count: filedAccessor(fields, Server_traffic_count),
    });
  });
  return formattedArr;
};
const FirewallReports: FC<PageProps> = ({ tabs: Tabs }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { list, fetchList } = useFirewallContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();

  const [fwListData, setFwListData] = useState<Array<FWRuleType> | undefined>();
  const [activeFWRule, setActiveFWRule] = useState<string>(
    parseQuery(ACTIVE_FW_QUERY_PARAM)
  );

  useEffect(() => {
    if (list && list.length > 0) {
      setFwListData(list);
      if (!Boolean(activeFWRule) && list[0]?.name) {
        setActiveFWRule(list[0].name);
      }
    }
  }, [list]);

  useEffect(() => {
    if (selectedTenant) {
      fetchList(selectedTenant);
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  const renderFwDropDownFilter = () => {
    return (
      <DropdownWithSearch
        withClearIcon
        id={"NatRuleTableFilter"}
        onChange={(val) => {
          setActiveFWRule(val.key);
        }}
        selected={mapStringToItem(String(activeFWRule))}
        placeholder={String(activeFWRule) || ""}
        error={""}
        valuesList={(fwListData || []).map((fwRule) => fwRule.name)}
        isMedium
        disabled={false}
      />
    );
  };

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      <Tabs showTimeRange tabs={[SERVICES_FW()]} />
      {servicesStatus && !servicesStatus.firewall.overall ? (
        <ServicesRedirectPlaceholder
          type="firewall"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.firewall.adminSide}
        />
      ) : (
        <>
          <div className={styles.wrapper}>
            <RulesTrafficCharts />
          </div>
          {selectedTenant && (
            <AutoSortTable
              csvTitle={"FW Rules Statistics"}
              dropDownFilterContent={renderFwDropDownFilter()}
              tableRequest={() => {
                return elasticApi.getFWRules(selectedTenant, activeFWRule);
              }}
              formatter={formatData}
              columns={columns}
              syntheticRefreshParam={activeFWRule}
            />
          )}
        </>
      )}
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(FirewallReports, [FirewallContextContainer])
);
