import { FC, useEffect, useState } from "react";
import {
  ARRAY_LENGTH,
  COL_GAP,
  OFFSET_Y,
  PORT_HEIGHT,
  PORT_ROWS,
  PORT_WIDTH,
} from "./consts";
import PortGroup from "./components/PortGroup";
import SectionTitle from "./components/SectionTitle";
import { PortTypeScheme } from "../../../types";
import { getSwitchPortsOnly } from "./helpers/getSwitchPortsOnly";

type Props = { x: number; y: number; list: Array<PortTypeScheme> };

const UNIT_TYPE = 1;
const WIDTH = PORT_WIDTH * (ARRAY_LENGTH + 1) + COL_GAP * (ARRAY_LENGTH - 1);
const HEIGHT = PORT_HEIGHT * PORT_ROWS.length + OFFSET_Y * 2;

const PortsUnitOne: FC<Props> = ({ x, y, list }) => {
  const [switchList, setSwitchList] = useState<Array<PortTypeScheme>>([]);

  useEffect(() => {
    if (list) {
      const serverList = getSwitchPortsOnly(list);
      setSwitchList(serverList);
    }
  }, [list]);

  return (
    <svg
      x={x}
      y={y}
      width={WIDTH}
      height={HEIGHT}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
    >
      <SectionTitle title="Switch Ports hge1" />
      {PORT_ROWS.map((row, rowIndex) => {
        return row.map((number, colIndex) => {
          const data = switchList.filter(
            (port) => port.parsedName.portIdx === number.toString()
          );

          const x = colIndex * (PORT_WIDTH + COL_GAP);
          const y = rowIndex * PORT_HEIGHT + OFFSET_Y * 2;
          return (
            <PortGroup
              key={rowIndex + colIndex}
              x={x}
              y={y}
              showDots={rowIndex % 2 === 0}
              type={UNIT_TYPE}
              portIdx={number}
              data={data}
            />
          );
        });
      })}
    </svg>
  );
};

export default PortsUnitOne;
