import { FC } from "react";

type Props = {
  className: string;
};

const QRPlaceholder: FC<Props> = ({ className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="1155px"
      height="1155px"
      viewBox="0 0 1155 1155"
      enable-background="new 0 0 1155 1155"
      xmlSpace="preserve"
      className={className}
    >
      <rect x="0" y="0" width="1155" height="1155" fill="none" />
      <g transform="translate(70,70)">
        <g transform="translate(280,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,0) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,35) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,70) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,70) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,70) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,70) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,70) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,105) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,105) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,105) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,105) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,140) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,140) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,140) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,140) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,140) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,140) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,175) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,175) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,175) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,175) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,175) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,175) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,210) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,245) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(245,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,280) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(245,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,315) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(245,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,350) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,385) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,420) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(245,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,455) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,490) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,525) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,560) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(245,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,595) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(140,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(245,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,630) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,665) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(35,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(105,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(175,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(210,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,700) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,735) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,770) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,805) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,840) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(385,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,875) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(350,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(455,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(630,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(910,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(980,910) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(420,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(490,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(560,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(770,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(805,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,945) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(280,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(315,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(525,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(595,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(665,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(700,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(735,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(875,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(945,980) scale(0.35,0.35)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(0,0) scale(2.45, 2.45)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <g>
              <rect
                x="15"
                y="15"
                style={{ fill: "none" }}
                width="70"
                height="70"
              />
              <path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z" />
            </g>
          </g>
        </g>
        <g transform="translate(770,0) scale(2.45, 2.45)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <g>
              <rect
                x="15"
                y="15"
                style={{ fill: "none" }}
                width="70"
                height="70"
              />
              <path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z" />
            </g>
          </g>
        </g>
        <g transform="translate(0,770) scale(2.45, 2.45)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <g>
              <rect
                x="15"
                y="15"
                style={{ fill: "none" }}
                width="70"
                height="70"
              />
              <path d="M85,0H15H0v15v70v15h15h70h15V85V15V0H85z M85,85H15V15h70V85z" />
            </g>
          </g>
        </g>
        <g transform="translate(70,70) scale(1.05, 1.05)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(840,70) scale(1.05, 1.05)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
        <g transform="translate(70,840) scale(1.05, 1.05)">
          <g transform="" style={{ fill: "rgb(0, 0, 0)" }}>
            <rect width="100" height="100" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default QRPlaceholder;
