import { FieldsType } from "../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import Validator, { errorKey } from "./Validator";

export function validateSystemNode(
  data: FieldsType,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  data.name && validator.checkLength(data.name, [1, 20], "name");

  data.nodes.map((node: FieldsType, i: number) => {
    validator.checkEmpty(node.name, errorKey(i, "name"));
    validator.checkIP(node.address, errorKey(i, "address"));
  });

  return validator;
}
