import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus } from "../../helpers/types";
import { isLocalStorageSupported } from "../../helpers/isLocalStorageSupported";
import { FiltersFields } from "./types";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { TRAFFIC_FIELDS_MAP, TRAFFIC_FIELD_VALUE } from "./consts";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";

const LOCALSTORAGE_TRAFFIC_KEY = "LOCALSTORAGE_TRAFFIC_KEY";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = {
  filters?: FiltersFields | undefined;
  filtersData: { [key: string]: Array<any> };
  trafficType: string;
  isServerTraffic: boolean;
};

type IState = State & RequestStatuses;
type IFunc = {
  setFilters: (fields?: FiltersFields) => void;
  fetchData: (tenant: string, timeRange: GTimeRange) => Promise<void>;
  setTrafficType: (val: string) => void;
};

const [TrafficContext, useContext] = createContextAndUse<IState, IFunc>();
export const useTrafficContext = useContext;

export default class TrafficContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = TrafficContext;

  // saved filters will be cleared via auth logout method
  componentWillMount(): void {
    const savedFilters = window.localStorage.getItem(LOCALSTORAGE_TRAFFIC_KEY);
    if (savedFilters) {
      this.setFilters(JSON.parse(savedFilters));
    }
    this.setTrafficType("Server Traffic");
  }
  setTrafficType = (val: string): void => {
    const isServerTraffic = val === "Server Traffic";
    this.setState({ trafficType: val, isServerTraffic });
  };

  updateLoacalstorageByFilters(fields?: FiltersFields): void {
    if (!isLocalStorageSupported()) {
      return;
    }
    if (fields) {
      window.localStorage.setItem(
        LOCALSTORAGE_TRAFFIC_KEY,
        JSON.stringify(fields)
      );
    } else {
      window.localStorage.removeItem(LOCALSTORAGE_TRAFFIC_KEY);
    }
  }

  setFilters = (fields?: FiltersFields): void => {
    this.updateLoacalstorageByFilters(fields);
    this.setState({ filters: fields });
  };

  fetchData = async (tenant: string, timeRange: GTimeRange): Promise<void> => {
    const filtersData: { [key: string]: Array<any> } = {};
    await Promise.all(
      TRAFFIC_FIELDS_MAP.map(
        async (name: string): Promise<any> => {
          filtersData[name] = await this.fetchElasticFields(
            TRAFFIC_FIELD_VALUE[name],
            tenant,
            timeRange
          );
        }
      )
    );
    this.setState({ filtersData });
  };

  fetchElasticFields = async (
    fieldName: string,
    tenant: string,
    timeRange: GTimeRange
  ): Promise<any> => {
    const { ok, result } = await elasticApi.getElasticFiltersValues(
      fieldName,
      tenant,
      "SDR",
      timeRange
    );
    if (ok && result) {
      return result;
    }
    return [];
  };

  funcs = {
    setFilters: this.setFilters,
    fetchData: this.fetchData,
    setTrafficType: this.setTrafficType,
  };
}
