import { CardField } from "../../../../components/common/Card/cardTypes";
import { GTimeRange } from "../../../../components/common/charts/GraphanaLinksFactory";
import { AbstractContextProvider } from "../../../../contexts/common/AbstractContext";
import { createContextAndUseWithExtra } from "../../../../contexts/common/AbstractCrudContext";
import { elasticApi } from "../../../../helpers/api/ElasticApi";
import {
  APP_BUTTONS_DATA,
  DESTINATIONS_BTN,
  LOCATIONS_BTN,
  SEGMENTS_BTN,
  SOURCES_BTN,
} from "./appButtonsConsts";
import {
  DNS_LOCATIONS_BTN,
  CYBER_THREAD_AND_DNS_BUTTONS_DATA,
  DNS_DESTINATIONS_BTN,
} from "./cyberThreadsAndDNSButtonsConsts";

type RequestStatuses = {};
type State = { cardsData: CardField };

type IState = State & RequestStatuses;
type IFunc = {
  fetchCardsData: (timeRange: GTimeRange, tenantName?: string) => Promise<void>;
  fetchCyberThreadsAndDNSCardsData: (
    timeRange: GTimeRange,
    tenantName?: string
  ) => Promise<void>;
};

const [AppButtonsContext, useContext] = createContextAndUseWithExtra<
  IState,
  RequestStatuses,
  IFunc
>();
export const useAppButtonsContext = useContext;

export default class AppButtonsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = AppButtonsContext;

  fetchBlockedApplications = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<number> => {
    const { ok, result } = await elasticApi.getBlockedApplicationsCounts(
      timeRange,
      tenantName
    );
    if (ok && result) {
      return result["1"].value;
    }
    return 0;
  };

  fetchThreadsSevirity = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<Array<any>> => {
    const sevirity = [
      { title: "High", number: 0 },
      { title: "Medium", number: 0 },
      { title: "Low", number: 0 },
    ];
    const { ok, result } = await elasticApi.getThreadsSevirity(
      timeRange,
      tenantName
    );
    if (ok && result) {
      result["1"].buckets.forEach((bucket) => {
        switch (bucket.key) {
          case 3:
            sevirity[2].number = bucket.doc_count;
            break;
          case 2:
            sevirity[1].number = bucket.doc_count;
            break;
          default:
            sevirity[0].number = bucket.doc_count;
            break;
        }
      });
    }
    return sevirity;
  };

  parseSegmentsAndSource = (
    newData: any,
    result: { [key: string]: { value: number } }
  ) => {
    newData[SEGMENTS_BTN][0].number = result["1"].value;
    newData[SEGMENTS_BTN][1].number = result["2"].value;
    newData[SOURCES_BTN][0].number = result["3"].value;
    newData[SOURCES_BTN][1].number = result["4"].value;
  };

  fetchCyberThreadsAndDNSCardsData = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<void> => {
    const { ok, result } = await elasticApi.getApplicationsCounts(
      timeRange,
      tenantName
    );
    const newData = { ...CYBER_THREAD_AND_DNS_BUTTONS_DATA };
    if (ok && result) {
      this.parseSegmentsAndSource(newData, result);
      newData[DNS_LOCATIONS_BTN][0].number = result["6"].value;
      newData[DNS_DESTINATIONS_BTN] = await this.fetchThreadsSevirity(
        timeRange,
        tenantName
      );
    }
    this.setState({
      cardsData: { ...newData },
    });
  };

  fetchCardsData = async (
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<void> => {
    const { ok, result } = await elasticApi.getApplicationsCounts(
      timeRange,
      tenantName
    );
    const newData = { ...APP_BUTTONS_DATA };
    if (ok && result) {
      this.parseSegmentsAndSource(newData, result);
      newData[LOCATIONS_BTN][0].number = result["6"].value;
      newData[DESTINATIONS_BTN][0].number = result["5"].value;
      newData[DESTINATIONS_BTN][1].number = await this.fetchBlockedApplications(
        timeRange,
        tenantName
      );
    }
    this.setState({
      cardsData: { ...newData },
    });
  };
  funcs = {
    fetchCardsData: this.fetchCardsData,
    fetchCyberThreadsAndDNSCardsData: this.fetchCyberThreadsAndDNSCardsData,
  };
}
