import React, { FC } from "react";
import BlankButton from "../../../../../../../components/common/buttons/BlankButton";
import BGPConfig from "./BGPConfig";
import { classNames } from "../../../../../../../helpers/common/classNames";
import { ACCENT } from "../../../../../../../helpers/common/colorAliases";
import CaretDoubleIcon from "../../../../../../../components/icons/CaretDoubleIcon";

import styles from "./RowControls.module.scss";

type Props = {
  showDiagram: boolean;
  toggleDiagram: () => void;
  isCloudRouter?: boolean;
  className?: string;
};

const RowControls: FC<Props> = ({
  showDiagram,
  toggleDiagram,
  isCloudRouter,
  className,
}) => {
  return (
    <div
      className={classNames(
        styles.wrapper,
        className,
        !isCloudRouter && styles.rightAlign
      )}
    >
      {isCloudRouter && <BGPConfig />}
      <BlankButton
        onClick={toggleDiagram}
        id={"toggleDiagram"}
        className={styles.toggleBtn}
      >
        {showDiagram ? "Hide diagram" : "Show diagram"}
        <CaretDoubleIcon
          color={ACCENT}
          className={classNames(
            styles.toggleIcon,
            !showDiagram && styles.reverse
          )}
        />
      </BlankButton>
    </div>
  );
};
export default RowControls;
