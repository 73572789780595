import React from "react";
import { withContexts } from "../../helpers/hocs/withContexts";
import { RemoteConfig } from "./components/Config/RemoteConfig";
import { RemoteGuide } from "./components/Guide/RemoteGuide";
import { RemoteConfigHeader } from "./components/Header/RemoteConfigHeader";
import { IPsecContextContainer } from "./IPsecContext";

import styles from "./styles.module.scss";

function IPsecRemoteConfigPage() {
  return (
    <div className={styles.wrapper}>
      <RemoteConfigHeader />
      <div className={styles.layout}>
        <RemoteConfig />
        <RemoteGuide />
      </div>
    </div>
  );
}

export default withContexts(IPsecRemoteConfigPage, [IPsecContextContainer]);
