import React from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

function SystemsIcon({ color, className }: IconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 7C5.89543 7 5 7.89543 5 9V19C5 20.1046 5.89543 21 7 21H21.6667C22.7712 21 23.6667 20.1046 23.6667 19V9C23.6667 7.89543 22.7712 7 21.6667 7H7ZM12.1667 12.6C11.8905 12.6 11.6667 12.8239 11.6667 13.1V14.9C11.6667 15.1761 11.8905 15.4 12.1667 15.4H16.5C16.7762 15.4 17 15.1761 17 14.9V13.1C17 12.8239 16.7762 12.6 16.5 12.6H12.1667Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default SystemsIcon;
