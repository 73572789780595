import { FC, useEffect } from "react";
import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../../contexts/UserContext";
import { useStaticRoutesServicesContext } from "../../StaticRoutesServicesContext";

import Table, {
  DEFAUTL_LIMIT,
  DEFAUTL_OFFSET,
} from "../../../../components/common/table/newTable/Table";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { routesTableHeader } from "../../../../components/common/table/TableHeaders/common/routesTableHeader";

const DynamicRoutesTable: FC = () => {
  const { user } = useUserContext();
  const {
    fetchDynamicRoutesList,
    dynamicRoutesList,
    dynamicRoutesStatus,
    dynamicRoutesCount,
  } = useStaticRoutesServicesContext();
  const { selectedTenant } = useGlobalFilterContext();

  useEffect(() => {
    selectedTenant && fetchWrapper();
  }, [selectedTenant]);

  const fetchWrapper = (offset = DEFAUTL_OFFSET, limit = DEFAUTL_LIMIT) => {
    if (selectedTenant) {
      fetchDynamicRoutesList(selectedTenant, user.role, offset, limit);
      return;
    }
  };

  return (
    <TableWrapper
      titleProps={{
        title: `Dynamic Routes`,
      }}
      dataStatus={dynamicRoutesStatus}
      tablePlaceholder={["Dynamic Routes", "Dynamic Route"]}
      isEmpty={!dynamicRoutesList?.length}
    >
      <Table
        fetchAction={fetchWrapper}
        data={dynamicRoutesList}
        count={dynamicRoutesCount}
        columns={routesTableHeader()}
        header={TableHeader}
        gridColumnTemplate="repeat(4, 200px)"
      />
    </TableWrapper>
  );
};
export default DynamicRoutesTable;
