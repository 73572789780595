import React, { useCallback } from "react";
import { PageProps, PathEntry } from "../../helpers/types";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { addPrefix } from "../../helpers/navigation";
import { Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "../common";
import { RouteEntry } from "../types";
import { CONFIGURATION, REPORTS } from "../../helpers/navigation/entries";
import FirewallReports from "./FirewallReports";
import FirewallContent from "./FirewallContent";

const Firewall: React.FC<PageProps> = ({ tabs: Tabs }) => {
  const path = useMatchPath();
  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Route path={path} exact>
        <Redirect to={fullPath(CONFIGURATION())} />
      </Route>
      <Routes
        routes={cyberThreatsRoutes}
        tabs={Tabs}
        pathFormer={(fn) => addPrefix(fn(), path)}
      />
    </Switch>
  );
};

export default Firewall;

const FwReports: React.FC<PageProps> = ({ tabs: Tabs }) => {
  return <FirewallReports tabs={Tabs} />;
};
const FwConfig: React.FC<PageProps> = ({ tabs: Tabs }) => {
  return <FirewallContent tabs={Tabs} />;
};

const cyberThreatsRoutes: Array<RouteEntry> = [
  {
    pathFunc: REPORTS,
    component: FwReports,
  },
  {
    pathFunc: CONFIGURATION,
    component: FwConfig,
  },
];
