import React, { FC } from "react";
import styles from "./RadioGroup.module.scss";
import { RadioBtn } from "./RadioBtn";
import { classNames } from "../../../helpers/common/classNames";
import { useUserContext } from "../../../contexts/UserContext";

export type RadioOption = {
  value: string;
  label: string | React.ReactElement;
  disabled?: boolean;
  onlyAdmin?: boolean;
  description?: string;
};

export type RadioGroupProps = {
  options: Array<RadioOption>;
  value: string;
  setActiveValue: (val: string) => void;
  label?: string;
  isOneRow?: boolean;
  isNotEditable?: boolean;
  className?: string;
  radioContainerClassName?: string;
};

const RadioGroup: FC<RadioGroupProps> = ({
  value,
  setActiveValue,
  options,
  label,
  isOneRow,
  isNotEditable,
  className,
  radioContainerClassName,
}) => {
  const { tenantParams } = useUserContext();
  return (
    <div className={classNames(styles.wrapper, className)}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={classNames(
          radioContainerClassName,
          styles.radioContainer,
          isOneRow && styles.oneRow
        )}
      >
        {options.map((item) => (
          <div>
            <RadioBtn
              key={item.value}
              isChecked={item.value === value}
              toggleRadioBtn={() => setActiveValue(item.value)}
              value={item.value}
              label={item.label}
              isNotEditable={isNotEditable}
              onlyAdmin={item?.onlyAdmin && !tenantParams?.isAdminRole}
              disabled={
                item.disabled || (item?.onlyAdmin && !tenantParams?.isAdminRole)
              }
            />
            {item.description && (
              <span className={styles.desc} aria-hidden="true">
                {item.description}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
