import React, { FC } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import { ChangeConnectionsFields, FieldsType } from "../../types";
import FormRouter from "./components/FormRouter";
import styles from "./APortBlock.module.scss";

type Props = {
  className: string;
  aPort: FieldsType;
  onChange: ChangeConnectionsFields;
  errors?: { [key: string]: any };
  isEdit?: boolean;
  handleChangeBW: (idx: number, value: string) => void;
};

const APortBlock: FC<Props> = ({
  className,
  aPort,
  onChange,
  errors,
  isEdit,
  handleChangeBW,
}) => {
  const containerClasses = classNames(className, styles.wrapper);
  return (
    <div className={containerClasses}>
      <FormRouter
        type={aPort.innerType}
        fields={aPort}
        onChange={onChange}
        className={styles.basicForm}
        isEdit={isEdit}
        errors={errors}
        handleChangeBW={handleChangeBW}
      />
    </div>
  );
};

export default APortBlock;
