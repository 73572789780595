import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../../helpers/hooks/navigationHooks";
import IPsecRemoteConfigPage from "../../../pages/IPsecRemoteConfigPage";

const IPsecRemoteConfig: FC = () => {
  const path = useMatchPath();

  return (
    <Switch>
      <Route exact path={`${path}/:selectedTenant/:id`}>
        <IPsecRemoteConfigPage />
      </Route>
    </Switch>
  );
};

export default IPsecRemoteConfig;
