import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

export default function TenantsIcon(props: IconProps): ReactElement {
  const { color, ...restProps } = props;
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 6.00169C6 4.61863 7.37018 3.65303 8.67267 4.11821L21.006 8.52297C21.802 8.80725 22.3333 9.56123 22.3333 10.4065V22.1637C22.3333 23.2682 21.4379 24.1637 20.3333 24.1637H8C6.89543 24.1637 6 23.2683 6 22.1637V6.00169ZM10.6667 12.497C10.6667 11.8527 11.189 11.3303 11.8333 11.3303C12.4777 11.3303 13 11.8527 13 12.497V13.6637C13 14.308 12.4777 14.8303 11.8333 14.8303C11.189 14.8303 10.6667 14.308 10.6667 13.6637V12.497ZM11.8333 17.1637C11.189 17.1637 10.6667 17.686 10.6667 18.3303V19.497C10.6667 20.1413 11.189 20.6637 11.8333 20.6637C12.4777 20.6637 13 20.1413 13 19.497V18.3303C13 17.686 12.4777 17.1637 11.8333 17.1637ZM15.3333 12.497C15.3333 11.8527 15.8557 11.3303 16.5 11.3303C17.1443 11.3303 17.6667 11.8527 17.6667 12.497V13.6637C17.6667 14.308 17.1443 14.8303 16.5 14.8303C15.8557 14.8303 15.3333 14.308 15.3333 13.6637V12.497ZM16.5 17.1637C15.8557 17.1637 15.3333 17.686 15.3333 18.3303V19.497C15.3333 20.1413 15.8557 20.6637 16.5 20.6637C17.1443 20.6637 17.6667 20.1413 17.6667 19.497V18.3303C17.6667 17.686 17.1443 17.1637 16.5 17.1637Z"
        fill={color || FONT}
      />
    </svg>
  );
}
