import React, { FC } from "react";
import { DialogContent } from "./sub/DialogContent";
import DialogLeft from "./sub/DialogLeft";
import DialogRight from "./sub/DialogRight";

import styles from "./IpamAddDialog.module.scss";
import VRFContextContainer from "../../../contexts/systemsContext/VRFContext";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { CreateIpam } from "../types";

const INIT_FORM: CreateIpam = {
  from_ip: "",
  name: "",
  to_ip: "",
  type: "system",
  vrf_name: "",
  ipsec_service: false,
  nat_service: true,
  shared: false,
  snat_service: true,
  data: null,
};

type Props = { onClose: () => void };

const IpamAddDialog: FC<Props> = ({ onClose }) => {
  const [fields, onChange] = useFormField<CreateIpam>(INIT_FORM);

  return (
    <VRFContextContainer>
      <div className={styles.dialog}>
        <DialogLeft className={styles.dialogInfo} fields={fields} />
        <DialogContent
          className={styles.dialogForm}
          fields={fields}
          onChange={onChange}
          onClose={onClose}
        />
        <DialogRight className={styles.dialogInstruction} />
      </div>
    </VRFContextContainer>
  );
};

export default IpamAddDialog;
