import React from "react";
import { Res } from "../../helpers/api/Api";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";

export abstract class AbstractContextProvider<
  State extends RequestsState,
  RequestsState,
  Funcs extends { [key: string]: any },
  Props = {}
> extends AbstractTimeoutHandler<State, Props> {
  protected abstract funcs: Funcs;
  abstract readonly Context: React.Context<any>;

  protected _fetchWithStatus = async <D,>(
    fn: (specificator?: string) => Promise<Res<D>>,
    status: keyof RequestsState
  ): Promise<Res<D>> => {
    this.setState((s) => ({ ...s, [status]: setPending() }));
    const res = await fn();
    this.setState((s) => ({ ...s, [status]: resToState(res) }));
    return res;
  };

  protected _resetStatusInTimeout = (
    status: keyof RequestsState,
    timeout = 2000
  ): void => {
    this.setupTimeout(
      status.toString(),
      () => this.setState((s) => ({ ...s, [status]: undefined })),
      timeout
    );
  };

  render(): React.ReactNode {
    return (
      <this.Context.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </this.Context.Provider>
    );
  }
}
