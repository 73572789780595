import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DataCenterPortIcon({
  color,
  x,
  y,
  width,
  height,
}: IconProps): ReactElement {
  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x={x}
      y={y}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.1792 4.57031C1.1792 3.46574 2.07463 2.57031 3.1792 2.57031H9.6792C10.7838 2.57031 11.6792 3.46574 11.6792 4.57031V13.4275C11.6792 14.532 10.7838 15.4275 9.6792 15.4275H3.1792C2.07463 15.4275 1.1792 14.532 1.1792 13.4275V4.57031ZM4.17878 6.56161C4.17878 6.1474 4.51457 5.81161 4.92878 5.81161C5.34299 5.81161 5.67878 6.1474 5.67878 6.56161V7.33052C5.67878 7.74474 5.34299 8.08052 4.92878 8.08052C4.51457 8.08052 4.17878 7.74474 4.17878 7.33052V6.56161ZM4.92878 9.59324C4.51457 9.59324 4.17878 9.92902 4.17878 10.3432V11.1121C4.17878 11.5264 4.51457 11.8621 4.92878 11.8621C5.34299 11.8621 5.67878 11.5264 5.67878 11.1121V10.3432C5.67878 9.92902 5.34299 9.59324 4.92878 9.59324ZM7.17962 6.56161C7.17962 6.1474 7.5154 5.81161 7.92962 5.81161C8.34383 5.81161 8.67962 6.1474 8.67962 6.56161V7.33052C8.67962 7.74474 8.34383 8.08052 7.92962 8.08052C7.5154 8.08052 7.17962 7.74474 7.17962 7.33052V6.56161ZM7.92962 9.59324C7.5154 9.59324 7.17962 9.92902 7.17962 10.3432V11.1121C7.17962 11.5264 7.5154 11.8621 7.92962 11.8621C8.34383 11.8621 8.67962 11.5264 8.67962 11.1121V10.3432C8.67962 9.92902 8.34383 9.59324 7.92962 9.59324Z"
        fill={color || FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64307 9.07031C9.64307 7.96574 10.5385 7.07031 11.6431 7.07031H14.7145C15.8191 7.07031 16.7145 7.96574 16.7145 9.07031V13.4275C16.7145 14.532 15.8191 15.4275 14.7145 15.4275H11.6431C10.5385 15.4275 9.64307 14.532 9.64307 13.4275V9.07031ZM11.6629 9.68228C11.6629 9.40332 11.8891 9.17718 12.168 9.17718C12.447 9.17718 12.6731 9.40332 12.6731 9.68228V10.1469C12.6731 10.4258 12.447 10.652 12.168 10.652C11.8891 10.652 11.6629 10.4258 11.6629 10.1469V9.68228ZM12.168 11.6353C11.8891 11.6353 11.6629 11.8614 11.6629 12.1404V12.605C11.6629 12.8839 11.8891 13.1101 12.168 13.1101C12.447 13.1101 12.6731 12.8839 12.6731 12.605V12.1404C12.6731 11.8614 12.447 11.6353 12.168 11.6353ZM13.6841 9.68228C13.6841 9.40332 13.9102 9.17718 14.1892 9.17718C14.4681 9.17718 14.6943 9.40332 14.6943 9.68228V10.1469C14.6943 10.4258 14.4681 10.652 14.1892 10.652C13.9102 10.652 13.6841 10.4258 13.6841 10.1469V9.68228ZM14.1892 11.6353C13.9102 11.6353 13.6841 11.8614 13.6841 12.1404V12.605C13.6841 12.8839 13.9102 13.1101 14.1892 13.1101C14.4681 13.1101 14.6943 12.8839 14.6943 12.605V12.1404C14.6943 11.8614 14.4681 11.6353 14.1892 11.6353Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default DataCenterPortIcon;
