import { InternetFields } from "../../pages/WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/formConsts";
import Validator from "./Validator";

export default function validateInternet(
  data: InternetFields,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  return validator;
}
