import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function StaticRouteIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 2a3 3 0 0 1 2.962 3.477l5.572 3.039a3.5 3.5 0 1 1 0 4.968l-4.654 2.539a4 4 0 1 1-.959-1.755l4.655-2.54a3.519 3.519 0 0 1 0-1.457L8.504 7.232A3 3 0 1 1 6.5 2Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default StaticRouteIcon;
