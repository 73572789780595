import { systemApi } from "./../../helpers/api/SystemApi";
import { tenantApi } from "./../../helpers/api/TenantApi";
import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { RequestStatus } from "../../helpers/types";
import { isLocalStorageSupported } from "../../helpers/isLocalStorageSupported";
import { FiltersData, FiltersFields } from "./types";
import { networkApi } from "../../helpers/api/networkApi";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { FiltersFieldType } from "../../helpers/api/data/applicationsFiltersBody";
import { getSystemByLoc } from "../WizardToolPage/LocationStep/mapUtils";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";

const LOCALSTORAGE_APPLICATION_KEY = "LOCALSTORAGE_APPLICATION_KEY";

type RequestStatuses = {
  listStatus: RequestStatus;
};
type State = { filters?: FiltersFields | undefined; filtersData: FiltersData };

type IState = State & RequestStatuses;
type IFunc = {
  setFilters: (fields?: FiltersFields) => void;
  fetchData: (tenant: string, timeRange: GTimeRange) => Promise<void>;
};

const [ApplicationObservabilityContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useApplicationObservabilityContext = useContext;

export default class ApplicationObservabilityContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc
> {
  Context = ApplicationObservabilityContext;

  // saved filters will be cleared via auth logout method
  componentWillMount() {
    const savedFilters = window.localStorage.getItem(
      LOCALSTORAGE_APPLICATION_KEY
    );
    if (savedFilters) {
      this.setFilters(JSON.parse(savedFilters));
    }
  }

  updateLoacalstorageByFilters(fields?: FiltersFields): void {
    if (!isLocalStorageSupported()) {
      return;
    }
    if (fields) {
      window.localStorage.setItem(
        LOCALSTORAGE_APPLICATION_KEY,
        JSON.stringify(fields)
      );
    } else {
      window.localStorage.removeItem(LOCALSTORAGE_APPLICATION_KEY);
    }
  }

  setFilters = (fields?: FiltersFields): void => {
    this.updateLoacalstorageByFilters(fields);
    this.setState({ filters: fields });
  };

  fetchData = async (tenant: string, timeRange: GTimeRange): Promise<void> => {
    const locations = await this.fetchLocations(tenant);
    const connections = await this.fetchConnections(tenant);
    const segments = await this.fetchSegments(tenant);
    const hostname = await this.fetchElasticFields("hostname", timeRange);
    const sourceIp = await this.fetchElasticFields("source", timeRange);
    const destinationIp = await this.fetchElasticFields(
      "destination",
      timeRange,
      tenant
    );
    this.setState({
      filtersData: {
        locations,
        connections,
        segments,
        hostname,
        sourceIp,
        destinationIp,
      },
    });
  };

  fetchLocations = async (tenant: string): Promise<Array<string>> => {
    const { ok, result } = await tenantApi.getTenant(tenant);
    const { result: systems } = await systemApi.getSystemsList();
    if (ok && result && systems) {
      const tenantSystems = result.systems as any;
      return tenantSystems.map(
        (sys: string) => systems.find((el) => el.name === sys)?.location
      );
    }
    return [];
  };

  fetchConnections = async (tenant: string): Promise<Array<string>> => {
    const { ok, result } = await tenantApi.getVirtualInterfacesAllTypes(tenant);
    if (ok && result) {
      return result.map((vi) => vi.name);
    }
    return [];
  };

  fetchSegments = async (tenant: string): Promise<Array<string>> => {
    const { ok, result } = await networkApi.getSegmentations(tenant);
    if (ok && result) {
      return result.items.map((seg) => seg.name);
    }
    return [];
  };

  fetchElasticFields = async (
    type: FiltersFieldType,
    timeRange: GTimeRange,
    tenantName?: string
  ): Promise<any> => {
    const { ok, result } = await elasticApi.getAppFiltersValues(
      type,
      timeRange,
      tenantName
    );
    if (ok && result) {
      return [];
    }
    return [];
  };

  funcs = {
    setFilters: this.setFilters,
    fetchData: this.fetchData,
  };
}
