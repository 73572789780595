import React, { FC } from "react";
import Button from "../../../components/common/buttons/Button";
import styles from "./ProviderGatewayForm.module.scss";
import Input from "../../../components/common/formComponents/Input";
import { ArubaConnectCreate, ArubaInterface } from "../../WizardToolPage/types";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useSDWANProvidersContext } from "../SDWANProvidersContext";
import { useValidation } from "../../../helpers/validators/Validator";
import validateSDWAN from "../../../helpers/validators/sdwanValidator";

type Props = {
  host_name: string;
  ip: string;
  ne_pk: string;
  interface_data: ArubaInterface;
  onClose?: () => void;
};

const DEFAULT_FIELDS = {
  interface_name: "",
  gw_ip: "",
  ne_pk: "",
  gw_name: "",
  vi_ip_address: "",
  connection_name: "",
};

const ProviderGatewayForm: FC<Props> = ({
  host_name,
  ip,
  interface_data,
  ne_pk,
  onClose = () => undefined,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { connect } = useSDWANProvidersContext();
  const interfaces = Object.keys(interface_data);
  const [fields, handleFieldChange] = useFormField<ArubaConnectCreate>(
    DEFAULT_FIELDS
  );
  const [errors, validate] = useValidation<Partial<ArubaConnectCreate>>(
    validateSDWAN,
    [fields]
  );
  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk && selectedTenant) {
      const params: ArubaConnectCreate = getParamsByType();
      const success = await connect(params, selectedTenant);
      if (success) onClose();
    }
  };

  function getInterfaceOptions(): Array<string> {
    const selected = interface_data?.[fields.interface_name];
    return selected?.options;
  }

  const getParamsByType = (): ArubaConnectCreate => {
    const labels = { remote_type: "sdwan", sdwan_provider: "aruba" };
    return {
      ...fields,
      labels,
      ne_pk: ne_pk,
      gw_ip: ip,
      gw_name: host_name,
    };
  };

  return (
    <div className={styles.wrapper}>
      <Input
        value={fields.connection_name}
        label="Connection name"
        name={"connection_name"}
        handleFieldChange={handleFieldChange}
        isFullValue
        medium
        error={errors && errors["connection_name"]}
        placeholder={""}
      />
      <Input
        value={fields.interface_name}
        label="Interface"
        name={"interface_name"}
        handleFieldChange={handleFieldChange}
        isFullValue
        valuesList={interfaces}
        medium
        error={errors && errors["interface_name"]}
        placeholder={""}
      />
      <Input
        value={fields.vi_ip_address}
        label="Virtual IP"
        name={"vi_ip_address"}
        error={errors && errors["vi_ip_address"]}
        handleFieldChange={handleFieldChange}
        isFullValue
        valuesList={getInterfaceOptions()}
        medium
        placeholder={""}
      />
      <Button onClick={onClose} className={styles.btns}>
        Cancel
      </Button>
      <Button onClick={handleSubmit} isPrimaryBtn className={styles.btns}>
        Add
      </Button>
    </div>
  );
};
export default ProviderGatewayForm;
