import { createContextAndUse } from "../../../contexts/common/utils";
import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { tenantApi } from "../../../helpers/api/TenantApi";
import {
  IUserContext,
  withUserContextProps,
} from "../../../contexts/UserContext";
import { LocationsList } from "../types";
import { RequestStatus } from "../../../helpers/types";
import {
  resToState,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { System } from "../../Systems/Provisioning/types";

type RequestStatuses = {
  locationsStatus?: RequestStatus;
};

type State = {
  locations?: Array<LocationsList>;
  systems?: Array<System>;
  selectedLocations: Array<LocationsList>;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchLocations: (tenantName: string) => void;
  toggleLocation: (location: LocationsList) => void;
  addLocations: (tenant: string) => Promise<boolean>;
};

const [LocationContext, useLocationContext] = createContextAndUse<
  IState,
  IFunc
>();
export { useLocationContext };

class LocationContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = LocationContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = { selectedLocations: [] };
  }

  fetchLocations = async (tenantName: string) => {
    const { ok, result } = await this._fetchWithStatus(
      () => tenantApi.getLocations(),
      "locationsStatus"
    );
    if (ok && result) {
      this._getSelectedLocations(tenantName, result);
    }
    this.setState({ locations: ok ? result : undefined });
  };

  _getSelectedLocations = async (
    tenantName: string,
    locations: Array<LocationsList>
  ) => {
    const { ok, result } = await tenantApi.getTenant(tenantName);
    if (ok && result) {
      const systems = result.systems;
      const selectedLocations = locations.filter(
        (loc) => loc.system && systems.includes(loc.system)
      );
      this.setState({ selectedLocations });
    }
  };

  toggleLocation = async (location: LocationsList): Promise<void> => {
    const { selectedLocations } = this.state;
    const locCopy = [...(selectedLocations || [])];
    const locI = locCopy.findIndex((l) => l.name === location.name);
    if (locI > -1) {
      locCopy.splice(locI, 1);
    } else {
      locCopy.push(location);
    }
    this.setState({ selectedLocations: locCopy });
  };

  addLocations = async (tenant: string): Promise<boolean> => {
    this.setState({ locationsStatus: setPending("Fetching") });
    const { result } = await tenantApi.getTenant(tenant);
    const systems = result?.systems || [];

    const sysList = await this._getSysList(
      systems,
      this.state.selectedLocations
    );
    const sysListToRemove = await this._getSysListToRemove(
      systems,
      this.state.selectedLocations
    );
    if (sysListToRemove.length)
      await tenantApi.removeSystems(tenant, sysListToRemove);

    if (sysList.length === 0) return true;

    const res = await tenantApi.editTenantLocations(tenant, sysList);
    this.setState({
      locationsStatus: resToState(res),
    });
    return res?.ok;
  };

  _getSysListToRemove = async (
    systems: Array<string>,
    selectedLocs: Array<LocationsList>
  ) => {
    const sysList: Array<string> = [];
    const locationsSystems = selectedLocs.map((loc) => loc.system);
    systems.map((system) => {
      if (!locationsSystems.includes(system)) sysList.push(system);
    });
    return sysList;
  };

  _getSysList = async (
    systems: Array<string>,
    selectedLocs: Array<LocationsList>
  ): Promise<Array<string>> => {
    const sysList: Array<string> = [];
    selectedLocs.map((loc) => {
      if (!systems.includes(loc.system)) {
        sysList.push(loc.system);
      }
    });
    return sysList;
  };

  funcs = {
    fetchLocations: this.fetchLocations,
    toggleLocation: this.toggleLocation,
    addLocations: this.addLocations,
  };
}

export default withUserContextProps<any>(LocationContextContainer);
