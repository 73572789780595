import React, { FC } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import InfoIcon from "../../../../../components/icons/InfoIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import { GREY_DISABLED } from "../../../../../helpers/common/colorAliases";
import styles from "./TipsBlock.module.scss";

type Props = { className: string };

const TipsBlock: FC<Props> = ({ className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        icon={<InfoIcon color={GREY_DISABLED} />}
        text={"Information"}
        className={styles.header}
      />
    </div>
  );
};

export default TipsBlock;
