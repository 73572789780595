import React, { FC, useState } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../components/common/formComponents/Input";
import InputWithList from "../../../../../../components/common/formComponents/InputWithList";
import { AddRuleButton } from "../../AddRuleButton";
import { RuleLayout } from "../../WidgetLayout/RuleLayout";
import { SubmitButton } from "../Submit";
import styles from "./styles.module.scss";

export const HEALTH_CHECK_LIST = [
  { key: "http", value: "HTTP" },
  { key: "tcp", value: "TCP" },
  // { key: "ping", value: "Ping" },
];

export const LoadBalancerService: FC = () => {
  const [dropdownItem, setDropdownItem] = useState<{
    key: string;
    value: string;
  }>(HEALTH_CHECK_LIST[0]);
  const [ipsList, setIpsList] = useState<Array<string>>([]);

  const handleItemSelect = (key: string) => {
    const value = HEALTH_CHECK_LIST.find((item) => item.key === key);
    if (value) setDropdownItem(value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <RuleLayout>
          <div className={styles.form}>
            <Input
              placeholder="Load Balancer name"
              name="name"
              onChange={() => undefined}
              label="Name"
              className={styles.input}
            />
            <Input
              placeholder="11.11.11.11"
              name="frontend_ip"
              onChange={() => undefined}
              label="Frontend IP"
            />
            <DropdownBasic
              id={"loadBalancerSeviceHealtCheck"}
              error=""
              selected={dropdownItem}
              placeholder="HTTP"
              onChange={({ key }) => handleItemSelect(key)}
              label="Helth check"
              itemsList={HEALTH_CHECK_LIST}
              className={styles.dropdownList}
            />
            <InputWithList
              onAdd={(val: Array<string>) =>
                setIpsList((ips) => [...ips, ...val])
              }
              onRemove={(val: string) =>
                setIpsList((ips) => ips.filter((ip) => ip !== val))
              }
              valuesList={ipsList}
              name="server_ips"
              label="Server IPs"
            />
          </div>
        </RuleLayout>
        <AddRuleButton />
      </div>

      <div className={styles.submit}>
        <SubmitButton onClick={() => undefined} />
      </div>
    </div>
  );
};
