import { FC, useEffect, useState } from "react";
import { OFFSET_Y, PORT_HEIGHT, PORT_ROWS, PORT_WIDTH } from "./consts";
import PortGroup from "./components/PortGroup";
import SectionTitle from "./components/SectionTitle";
import { PortTypeScheme } from "../../../types";
import { getServerPortsOnly } from "./helpers/getServerPortsOnly";

type Props = { x: number; y: number; list: Array<PortTypeScheme> };

const UNIT_TYPE = 0;
const HEIGHT = PORT_HEIGHT * PORT_ROWS.length + OFFSET_Y * 2;

const PortsUnitZero: FC<Props> = ({ x, y, list }) => {
  const [serverList, setServerList] = useState<Array<PortTypeScheme>>([]);

  useEffect(() => {
    if (list) {
      const serverList = getServerPortsOnly(list);
      setServerList(serverList);
    }
  }, [list]);

  return (
    <svg
      x={x}
      y={y}
      width={PORT_WIDTH + 120}
      height={HEIGHT}
      viewBox={`0 0 ${PORT_WIDTH} ${HEIGHT}`}
    >
      <SectionTitle title={"Server Ports hge0"} />
      {PORT_ROWS.map((_, rowIndex) => {
        const data = serverList.filter(
          (port) => port.parsedName.portIdx === rowIndex.toString()
        );
        // if (data.length === 0) return null;

        const x = 0;
        const y = OFFSET_Y * 2 + rowIndex * PORT_HEIGHT;
        return (
          <PortGroup
            key={rowIndex}
            x={x}
            y={y}
            showDots={false}
            type={UNIT_TYPE}
            portIdx={rowIndex}
            data={data || []}
          />
        );
      })}
    </svg>
  );
};

export default PortsUnitZero;
