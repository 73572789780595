import React, { FC } from "react";
import UnifiedTextPair from "../../../components/common/UnifiedTextPair";
import SummaryInfoBlock from "../../../components/leftInfoBlock/SummaryInfoBlock";
import { PBRType } from "../../../helpers/api/apiTypes";
import PBRIcon from "../../../components/icons/tabs/PBRIcon";
import { usePBRContext } from "../../../contexts/servicesContext/PBRContext";
import styles from "./PBRInfo.module.scss";

const PBRInfo: FC = () => {
  const { selectedPBR } = usePBRContext();

  return (
    <SummaryInfoBlock
      icon={<PBRIcon />}
      title="Policy Based Route"
      className={styles.bodyContainer}
    >
      {selectedPBR && <RuleInfoSection rule={selectedPBR} />}
    </SummaryInfoBlock>
  );
};
export default PBRInfo;

type InfoProps = {
  rule: PBRType;
};

const RuleInfoSection = ({ rule }: InfoProps) => {
  return (
    <>
      <UnifiedTextPair subtitle="Tenant" text={rule.name} />
    </>
  );
};
