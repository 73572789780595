import React, { FC, useState } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../components/common/formComponents/DropDownInput";
import styles from "./MarketPlaceService.module.scss";

const LIST = [
  "Cisco CSR",
  "Fortinet Secure",
  "Juniper vSRX",
  "Palo Alto CloudGenix Virtual ION",
  "Versa FlexVNFTM",
  "VMware Edge",
];

export const MarketPlaceService: FC = () => {
  const [currentValue, setCurrentValue] = useState<string>("");
  return (
    <div className={styles.wrapper}>
      <div className={styles.form}>
        <DropdownBasic
          id={"marketService"}
          className={styles.dropdownButton}
          onChange={(v) => setCurrentValue(v.key)}
          error=""
          selected={mapStringToItem(currentValue)}
          itemsList={LIST.map(mapStringToItem)}
          isMedium
        />
      </div>
    </div>
  );
};
