import React, { FC, useEffect } from "react";
import AppButtons from "./components/Buttons/AppButtons";
import styles from "./ApplicationsStatistics.module.scss";
import LeftPart from "./components/LeftPart/LeftPart";
import BtmPart from "./components/BtmPart/BtmPart";
import RightPart from "./components/RightPart/RightPart";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../components/common/charts/GrafanaContainer/GrafanaContainer";

const ApplicationsStatistics: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      {servicesStatus && !servicesStatus.dpi.overall ? (
        <ServicesRedirectPlaceholder
          type="dpi"
          adminMode={servicesStatus.dpi.adminSide}
          fetchData={fetchServicesStatus}
        />
      ) : (
        <GrafanaContainer type={GRAFANA_TYPES.DPI} className={styles.wrapper}>
          <div className={styles.container}>
            <AppButtons className={styles.buttons} />
            <LeftPart className={styles.appCharts} />
            <RightPart className={styles.topAppDonut} />
            <BtmPart className={styles.topAppTable} />
          </div>
        </GrafanaContainer>
      )}
    </>
  );
};

export default ApplicationsStatistics;
