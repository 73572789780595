import React, { useEffect, useState } from "react";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../components/common/formComponents/Input";

import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useTenantVIContext } from "../../../contexts/tenantsContext/TenantVIContext";
import { ServiceStaticRouteFields } from "../../../helpers/api/apiTypes";
import { mapStringToItem } from "../../../helpers/mapStringToItem";

import styles from "./GatewayInput.module.scss";

type Props = {
  isInterface: boolean;
  fields: ServiceStaticRouteFields;
  handleFieldChange: (key: string, value: string) => void;
  errors: {
    [key: string]: string;
  };
  validate: () => void;
};

export const GatewayInput = ({
  isInterface,
  fields,
  handleFieldChange,
  errors,
  validate,
}: Props) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { fetchVIList, viList } = useTenantVIContext();
  const [selectedVI, setSelectedVI] = useState<string>("");

  useEffect(() => {
    if (!selectedTenant) {
      return;
    }

    fetchVIList(selectedTenant);
  }, [selectedTenant]);

  const gatewayField = {
    name: isInterface ? "gateway_interface" : "gateway_ip_address",
    placeholder: isInterface ? "interface name" : "X.X.X.X or X::X",
    value: isInterface ? fields.gateway_interface : fields.gateway_ip_address,
    label: isInterface ? "Gateway interface" : "Gateway",
    error: isInterface
      ? errors["gateway_interface"]
      : errors["gateway_ip_address"],
  };

  const filteredVIList =
    viList?.filter((viItem) => viItem.type === "Virtual Interface IPSEC") || [];
  const viItems = filteredVIList?.map((v) => v.name) || [];

  if (isInterface) {
    return (
      <div className={styles.gateway}>
        <DropdownBasic
          id="gateway"
          itemsList={viItems.map(mapStringToItem)}
          onChange={(item) => {
            setSelectedVI(item.key);
            handleFieldChange("gateway_interface", item.key);
          }}
          isMedium
          selected={mapStringToItem(selectedVI)}
          {...gatewayField}
        />
      </div>
    );
  }

  return (
    <Input
      handleFieldChange={handleFieldChange}
      medium
      className={styles.gateway}
      {...gatewayField}
      onBlur={validate}
    />
  );
};
