import { configApi } from "../../helpers/api/ConfigApi";

import NewAbstractCrudContext, {
  CrudFunc,
  CrudRequestStatuses,
  CrudValueState,
} from "../../contexts/common/NewAbstractCrudContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../contexts/systemsContext/OneSystemContext";
import { PortVlan } from "./types";
import { RequestStatus } from "../../helpers/types";
import { PortType } from "../PortsPage/types";
import { getGroupedList } from "./utils/apiUtils";

type RequestsState = CrudRequestStatuses & {
  portListStatus: RequestStatus;
};

type ValueState = CrudValueState<PortVlan> & {
  groupedList: { [key: string]: Array<PortVlan> };
  portList?: Array<PortType>;
};

type IState = RequestsState & ValueState;

type IFunc = CrudFunc<PortVlan> & {
  fetchPortList: () => Promise<void>;
};

export type IVlanContext = IState & IFunc;
const [Context, useVLANContext, withVlanContextProps] = createContextUtils<
  IState,
  IFunc
>();

export { useVLANContext, withVlanContextProps };

type Props = IOneSystemContext;

class VLANContextContainer extends NewAbstractCrudContext<
  PortVlan,
  IState,
  RequestsState,
  IFunc,
  Props
> {
  Context = Context;

  fetchList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;

    const { ok, result } = await this._fetchListWrap(() =>
      configApi.getPortVLANList(system.name)
    );
    if (ok && result) {
      const groupedList = getGroupedList(result);
      this.setState({ groupedList });
      this.fetchPortList();
    }
  };

  // addReq = async (params: any) => {
  //   return await configApi.addPortVLAN(
  //     this.props.additionParameter,
  //     params as any
  //   );
  // };

  remove = async (vlan: PortVlan): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { alias, physical_port_name, vlan_id } = vlan;
    const { ok } = await this._removeWrap(() =>
      configApi.deletePortVLAN(system.name, {
        alias,
        physical_port_name,
        vlan_id,
      })
    );

    return ok;
  };

  // todo not implemented
  edit = async (vlan: PortVlan): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());

    return ok;
  };

  fetchPortList = async (): Promise<void> => {
    const { system } = this.props;
    if (!system) return;
    const { ok, result } = await this._fetchWithStatus(
      () => configApi.getPortsList(system.name),
      "portListStatus"
    );
    this.setState({ portList: ok ? result : undefined });
  };

  funcs = {
    fetchPortList: this.fetchPortList,
    fetchList: this.fetchList,
    remove: this.remove,
    edit: this.edit,
  };
}

export default withOneSystemContextProps(VLANContextContainer);
