import { createContextUtils } from "./common/utils";
import { AbstractContextProvider } from "./common/AbstractContext";
import { IUserContext, withUserContextProps } from "./UserContext";
import { UserRoles } from "../helpers/rolesHelpers";
import { PropsWithChildren } from "react";

type IState = {
  selectedTenant?: string;
  selectedVI?: string | undefined;
};
type IFunc = {
  setSelectedTenant: (tenantName: string | undefined) => void;
  setSelectedVI: (vi: string) => void;
  getSelectedTenant: () => string | undefined;
};

export type IGlobalFilterContext = IState & IFunc;

const [
  GlobalFilterContext,
  useGlobalFilterContext,
  withGlobalFilterContext,
] = createContextUtils<IState, IFunc>();

export { useGlobalFilterContext, withGlobalFilterContext };

type Props = PropsWithChildren<IUserContext>;
class GlobalFilterContextContainer extends AbstractContextProvider<
  IState,
  {},
  IFunc,
  Props
> {
  Context = GlobalFilterContext;

  componentDidMount() {
    this._setTenantFromUser();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { user } = this.props;
    if (user && prevProps.user !== user) {
      this._setTenantFromUser();
    }
  }

  _setTenantFromUser = () => {
    const { user } = this.props;
    if (user?.role === UserRoles.TENANT) {
      this.setSelectedTenant(user?.name);
    }
  };

  setSelectedTenant = (name: string | undefined): void => {
    this.setState({ selectedTenant: name });
    if (name) {
      localStorage.setItem("selectedTenant", name);
    }
  };

  getSelectedTenant = () => {
    const fromStorage = localStorage.getItem("selectedTenant");
    return fromStorage || this.state?.selectedTenant;
  };

  setSelectedVI = (vi: string): void => {
    this.setState({ selectedVI: vi });
  };

  funcs = {
    setSelectedTenant: this.setSelectedTenant,
    setSelectedVI: this.setSelectedVI,
    getSelectedTenant: this.getSelectedTenant,
  };
}

export default withUserContextProps<PropsWithChildren<any>>(
  GlobalFilterContextContainer
);
