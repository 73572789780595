import { SortType } from "./api/apiTypes";

export default function getManualSortedData<D>(
  data: Array<D>,
  sortBy: SortType
): Array<D> {
  if (!sortBy) return data;

  const { id: key, desc } = sortBy;

  if (!desc) {
    // A-Z
    return data.sort((prev: any, next: any) => {
      const a = prev[key].toString().toLowerCase();
      const b = next[key].toString().toLowerCase();
      if (a === b) return 0;
      return a > b ? 1 : -1;
    });
  } else {
    // Z-A
    return data.sort((prev: any, next: any) => {
      const a = prev[key].toString().toLowerCase();
      const b = next[key].toString().toLowerCase();
      if (a === b) return 0;
      return a < b ? 1 : -1;
    });
  }
}
