import { CardElement } from "../../../../components/common/Card/cardTypes";
import LocationIcon from "../../../../components/icons/LocationIcon";
import DestinationIcon from "../../../../components/icons/topologyButtons/DestinationIcon";
import SegmentsIcon from "../../../../components/icons/topologyButtons/SegmentsIcon";
import SourceIcon from "../../../../components/icons/topologyButtons/SourceIcon";
import { PURPLE } from "../../../../helpers/common/colorAliases";

export const DNS_LOCATIONS_BTN = "Affected Locations";
export const SEGMENTS_BTN = "Unique Group and Segments";
export const SOURCES_BTN = "Users and Source IP";
export const DNS_DESTINATIONS_BTN = "Threats Sevirity";

export const APP_BTN_MAP: Array<CardElement> = [
  {
    icon: <LocationIcon color={PURPLE} />,
    title: DNS_LOCATIONS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SegmentsIcon />,
    title: SEGMENTS_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <SourceIcon />,
    title: SOURCES_BTN,
    disabled: false,
    data: undefined,
  },
  {
    icon: <DestinationIcon />,
    title: DNS_DESTINATIONS_BTN,
    disabled: false,
    data: undefined,
  },
];

export const CYBER_THREAD_AND_DNS_BUTTONS_DATA = {
  [DNS_LOCATIONS_BTN]: [{ title: "Locations", number: 0 }],
  [SEGMENTS_BTN]: [
    { title: "Segments", number: 0 },
    { title: "Unique Groups", number: 0 },
  ],
  [SOURCES_BTN]: [
    { title: "Source IP", number: 0 },
    { title: "Users", number: 0 },
  ],
  [DNS_DESTINATIONS_BTN]: [
    { title: "High", number: 0 },
    { title: "Medium", number: 0 },
    { title: "Low", number: 0 },
  ],
};
