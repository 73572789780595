import { FC, useMemo } from "react";
import DropdownBasic from "../../../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../../../components/common/formComponents/Input";
import { mapStringToItem } from "../../../../../../../helpers/mapStringToItem";
import BWDropdown from "../../../common/formComponents/BWDropdown";
import EndpointDropdown from "../../../common/formComponents/EndpointDropdown";
import { PHYSICAL_PORT_IDS } from "../../../consts";
import {
  ChangeConnectionsFields,
  ChangeField,
  FieldsType,
} from "../../../types";
import IPAddress from "../../../common/formComponents/IPAddress";
import TaggedRadioGroup from "../../../common/formComponents/TaggedRadioGroup";
import { useConnectionConfiguratorContext } from "../../../ConnectionConfiguratorContext";
import { useUserContext } from "../../../../../../../contexts/UserContext";
import RadioGroup from "../../../../../../../components/common/formComponents/RadioGroup";
import { getConnectionType } from "../../../helpers/getConnectionType";

type Props = {
  fields: FieldsType;
  onChange: ChangeConnectionsFields;
  className: string;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const CustomerLocationForm: FC<Props> = ({
  fields,
  onChange,
  className,
  type,
  isEdit,
  errors,
  handleChangeBW,
}) => {
  const { portType } = useConnectionConfiguratorContext();
  const { isDemo } = useUserContext();

  const portsList = useMemo(
    () => fields.location?.labels?.Ports || PHYSICAL_PORT_IDS,
    [fields.location]
  );
  const handleChange: ChangeField = (field: string, value: any): void => {
    const newFields = { ...fields, [field]: value };
    onChange(0, newFields);
  };
  const idx = 0;
  const isIpsec = fields.Connection_type === "ipsec";
  return (
    <div className={className}>
      {isDemo && portType.key === "cloudRouter" && (
        <RadioGroup
          label="Connection Type"
          isOneRow
          options={getConnectionType(isDemo)}
          value={fields.Connection_type}
          setActiveValue={(val) => handleChange("Connection_type", val)}
          isNotEditable={isEdit}
        />
      )}
      {isIpsec ? (
        <>
          <BWDropdown
            field={fields["BW"]}
            onChange={handleChangeBW}
            error={errors?.[idx + "BW"]}
            idx={idx}
          />
          <Input
            label="Peer IP"
            name="peer_ip"
            placeholder="0.0.0.0/0"
            value={fields.peer_ip}
            medium
            onChange={(e) => handleChange("peer_ip", e.target.value)}
            isNotEditable={isEdit}
            error={errors?.[idx + "peer_ip"]}
          />
          <Input
            label="Generated IPSEC connection details"
            name="psk"
            placeholder="PSK"
            value={fields.psk}
            medium
            onChange={(e) => handleChange("psk", e.target.value)}
            isNotEditable={isEdit}
            error={errors?.[idx + "psk"]}
            isPlaceholderAlwaysVisible
          />
        </>
      ) : (
        <>
          <EndpointDropdown
            field={fields.location}
            onChange={handleChange}
            type={type}
            isNotEditable={isEdit}
            error={errors?.[idx + "location"]}
          />
          <BWDropdown
            field={fields["BW"]}
            onChange={handleChangeBW}
            error={errors?.[idx + "BW"]}
            idx={idx}
          />
          <DropdownBasic
            id={"physicalPort"}
            onChange={(val) => {
              handleChange("Phisical_Port", val.key);
            }}
            placeholder={"Select Physical Port #"}
            selected={mapStringToItem(fields.Phisical_Port)}
            label="Physical Port #"
            itemsList={portsList.map(mapStringToItem)}
            isMedium
            error={errors?.[idx + "Phisical_Port"]}
            isNotEditable={isEdit}
          />
          <TaggedRadioGroup
            field={fields.Tagged}
            onChange={handleChange}
            isNotEditable={isEdit}
          />
          {fields.Tagged && (
            <Input
              label="VLAN ID"
              name="VLAN_ID"
              placeholder=""
              value={fields.VLAN_ID}
              medium
              onChange={(e) => handleChange("VLAN_ID", e.target.value)}
              isNotEditable={isEdit}
              error={errors?.[idx + "VLAN_ID"]}
            />
          )}
          <IPAddress
            field={fields.ip_addresses}
            onChange={handleChange}
            error={errors?.[idx + "ip_addresses"]}
          />
        </>
      )}
    </div>
  );
};

export default CustomerLocationForm;
