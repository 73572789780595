import { FC } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import RadioGroup from "../../../../../../../../components/common/formComponents/RadioGroup";
import CloudSelector from "../../../../common/CloudSelector";
import BWDropdown from "../../../../common/formComponents/BWDropdown";
import EndpointDropdown from "../../../../common/formComponents/EndpointDropdown";
import { ChangeField, FieldsType } from "../../../../types";
import ItemContainer from "../common/ItemContainer";
import styles from "./CloudItem.module.scss";
import VPCList from "../../../../common/formComponents/VPCList/VPCList";
import IPAddress from "../../../../common/formComponents/IPAddress";
import { renderCustomerIdLabel } from "../../../../helpers/renderCustomerIdLabel";
import { getConnectionType } from "../../../../helpers/getConnectionType";
import { useUserContext } from "../../../../../../../../contexts/UserContext";

type Props = {
  fields: FieldsType;
  onChange: ChangeField;
  listLength: number;
  idx: number;
  isExpandInitial: boolean;
  onDelete: () => void;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const CloudItem: FC<Props> = ({
  fields,
  onChange,
  idx,
  isExpandInitial,
  onDelete,
  type,
  isEdit,
  errors,
  handleChangeBW,
}) => {
  const { isDemo } = useUserContext();
  return (
    <ItemContainer
      type={type}
      title={fields.cloudType}
      idx={idx}
      onDelete={onDelete}
      formClassName={styles.formWrapper}
      isExpandInitial={isExpandInitial}
      isEdit={isEdit}
    >
      <CloudSelector
        selected={fields.cloudType}
        onSelect={(val) => onChange("cloudType", val)}
        isOneRow
        disabled={isEdit}
      />
      <div className={styles.fields}>
        <RadioGroup
          label="Connection Type"
          isOneRow
          options={getConnectionType(isDemo)}
          value={fields.Connection_type}
          setActiveValue={(val) => onChange("Connection_type", val)}
          isNotEditable={isEdit}
        />
        <EndpointDropdown
          label={"Region"}
          field={fields.location}
          onChange={onChange}
          type={type}
          cloudType={fields.cloudType}
          isNotEditable={isEdit}
          error={errors?.[idx + "location"]}
        />
        <BWDropdown
          field={fields.BW}
          onChange={handleChangeBW}
          error={errors?.[idx + "BW"]}
          idx={idx}
        />
        {fields.Connection_type === "ipsec" && fields.cloudType === "aws" && (
          <VPCList
            onChange={onChange}
            externalFields={fields}
            error={errors?.[idx + "VPC_ID"]}
          />
        )}
        {fields.Connection_type === "directConnect" && (
          <>
            <Input
              label={renderCustomerIdLabel(fields.cloudType)}
              name="Customer_ID"
              placeholder=""
              value={fields.Customer_ID}
              medium
              onChange={(e) => onChange("Customer_ID", e.target.value)}
              isNotEditable={isEdit}
              error={errors?.[idx + "Customer_ID"]}
            />
          </>
        )}
        <IPAddress
          field={fields.ip_addresses}
          onChange={onChange}
          error={errors?.[idx + "ip_addresses"]}
        />
      </div>
    </ItemContainer>
  );
};

export default CloudItem;
