import React, { FC } from "react";
import DeleteIcon from "../../../icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../../helpers/getDialogControls";
import ConfirmDialog from "./ConfirmDialog";
import { RequestStatus } from "../../../../helpers/types";

type ConfirmDialogProps = {
  deviceType?: string;
  name?: string;
  onOk: () => void;
  onCancel: () => void;
  status?: RequestStatus;
  isShown: boolean;
};

const ConfirmDeleteDialog: FC<ConfirmDialogProps> = ({
  deviceType,
  name,
  onOk,
  onCancel,
  status,
  isShown,
}) => {
  if (!isShown) {
    return <></>;
  }
  return (
    <ConfirmDialog
      icon={DeleteIcon}
      title={`Delete ${deviceType}`}
      message={`Are you sure you want to delete ${name || "current item"}?`}
      controls={getDeleteDialogControls({
        onDelete: onOk,
        onCancel,
      })}
      status={status}
    />
  );
};

export default ConfirmDeleteDialog;
