const downloadFile = async (response: Response): Promise<void> => {
  const { type, name } = getParamsFromHeaders(response.headers);
  const file = await response.blob();

  const blob = new Blob([file], { type });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");

  link.download = name;
  link.href = url;
  link.click();
};

export default downloadFile;

const getParamsFromHeaders = (headers: any): { type: string; name: string } => {
  const res: any = {};
  headers.forEach((val: string, key: string) => {
    res[key] = val;
  });
  const type = (res as ResHeadersType)["content-type"];
  const name = (res as ResHeadersType)["content-disposition"].split(
    "attachment;filename="
  )[1];
  return { type, name };
};

type ResHeadersType = {
  connection: "string";
  "content-disposition": string;
  "content-type": "application/zip";
  date: string;
  server: string;
  "transfer-encoding": string;
  "x-powered-by": string;
};
