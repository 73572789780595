import { CellProps } from "react-table";
import { getColorFromStatus } from "../../../helpers/common/colorHelpers";
import VNIIcon from "../../../components/icons/VNIIcon";
import { CellFactory } from "../../../components/common/table/newTable/cells/CellsFactory";
import TextWithIcon from "../../../components/common/table/TextWithIcon";
import { VtepVni } from "../types";

const cellFactory = new CellFactory<VtepVni>({});

export const vniTableHeader = (): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row, cell: { value } }: CellProps<VtepVni>) => (
        <TextWithIcon
          icon={
            <VNIIcon
              indicatorColor={getColorFromStatus(
                row.original.administrative_state
              )}
            />
          }
          text={value}
        />
      ),
    },
    {
      Header: "Alias",
      accessor: "alias",
    },
    {
      Header: "Virt. Interface",
      accessor: "virtual_interface_name",
    },
    {
      Header: "Op-state",
      accessor: "operational_state",
      Cell: cellFactory.opStateCell(),
    },
    {
      Header: "MTU",
      accessor: "mtu",
    },
    // {
    //   Header: "Endpoint IP",
    //   accessor: "Endpoint IP",
    //   Cell: ({ row }: CellProps<VtepVni>) => {
    //     return (
    //       <LabelArray
    //         values={row.original.tunnels.map((el) => el.remote_ip)}
    //         title={"Endpoint IP"}
    //       />
    //     );
    //   },
    // },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof VtepVni }));
};
