import React, { FC, ReactElement, useState } from "react";
import { useOneSystemContext } from "../../../../contexts/systemsContext/OneSystemContext";
import { classNames } from "../../../../helpers/common/classNames";
import { DialogControlsType, IconProps } from "../../../../helpers/types";
import AdditionalMenu from "../../../common/AdditionalMenu";
import IconBlankButton from "../../../common/buttons/IconBlankButton";
import ConfirmDialog from "../../../dialogs/common/confirmDialog/ConfirmDialog";
import ConfigEraseIcon from "../../../icons/configIcons/ConfigEraseIcon";
import RestartIcon from "../../../icons/configIcons/RestartIcon";
import styles from "./SystemControlsTooltip.module.scss";

type ConfirmDialogType = "restart" | "erase" | undefined;

type Props = {
  isHide: boolean;
  className: string;
  onClose: () => void;
};

const SystemControlsTooltip: FC<Props> = ({ isHide, className, onClose }) => {
  const { system, resetSystem, factoryResetSystem } = useOneSystemContext();
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogType>();
  return (
    <>
      {confirmDialog && system && (
        <ConfirmDialog
          {...getDialogData(
            confirmDialog,
            system?.name,
            resetSystem,
            factoryResetSystem,
            () => setConfirmDialog(undefined)
          )}
        />
      )}
      <AdditionalMenu
        isHide={isHide}
        className={classNames(styles.wrapper, className)}
        onClose={onClose}
      >
        <IconBlankButton
          id={"systemControlsRestart"}
          icon={RestartIcon}
          onClick={() => setConfirmDialog("restart")}
          label={"Restart"}
          disabled
        />
        <IconBlankButton
          id={"systemControlsEraseConfiguration"}
          icon={ConfigEraseIcon}
          onClick={() => setConfirmDialog("erase")}
          label={"Erase configuration"}
        />
      </AdditionalMenu>
    </>
  );
};
export default SystemControlsTooltip;

type DialogDataType = {
  className: string;
  icon: (props: IconProps) => ReactElement;
  title: string;
  message: React.ReactElement;
  controls: DialogControlsType;
};
const getDialogData = (
  type: ConfirmDialogType,
  system: string,
  resetSystem: (name?: string | undefined) => void,
  factoryResetSystem: (name?: string | undefined) => void,
  onCancel: () => void
): DialogDataType => {
  const isRestart = type === "restart";
  return {
    className: styles.confirmDialog,
    icon: isRestart ? RestartIcon : ConfigEraseIcon,
    title: isRestart ? "Restart" : "Erase configuration",
    message: isRestart ? restartMsg : eraseMsg,
    controls: {
      okText: "Continue",
      onOk: isRestart
        ? () => resetSystem(system)
        : () => factoryResetSystem(system),
      cancelText: "Cancel",
      onCancel,
    },
  };
};

const restartMsg = (
  <>
    Are you sure you want to restart the system?
    <br />
    <br />
    This will cause some traffic drop.
  </>
);

export const eraseMsg = (
  <>
    Are you sure you want to erase the configuration?
    <br />
    <br />
    Please make sure you backed-up the configuration before continuing.
  </>
);
