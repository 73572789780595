import React, { FC, ReactNode } from "react";
import { classNames } from "../../../helpers/common/classNames";
import { LabelOutlineTypes } from "../../../helpers/types";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./LabelOutline.module.scss";

type LabelOutlineProps = {
  type: LabelOutlineTypes;
  className?: string;
  onClick?: () => void;
  isMedium?: boolean;
  icon?: ReactNode;
};

const LabelOutline: FC<LabelOutlineProps> = ({
  children,
  type,
  className,
  onClick,
  isMedium,
  icon,
}) => {
  const labelStyle = classNames(
    styles.wrapper,
    isMedium && styles.medium,
    type && styles[type],
    className
  );
  return children ? (
    <div className={labelStyle} onClick={onClick}>
      {icon}
      {children}
    </div>
  ) : (
    <></>
  );
};

export default LabelOutline;
