import React, { FC, ReactElement } from "react";
import styles from "./SummaryInfoBlock.module.scss";
import { classNames } from "../../helpers/common/classNames";
import TitleWithIcon from "../common/TitleWithIcon";

type SummaryInfoBlockType = {
  icon: ReactElement;
  title: string;
  isSmallerIcon?: boolean;
  withFooter?: boolean; // todo remove withFooter is footer is defined then show it
  footer?: ReactElement;
  className?: string;
};

const SummaryInfoBlock: FC<SummaryInfoBlockType> = ({
  icon,
  title,
  children,
  footer,
  isSmallerIcon,
  className,
}) => {
  const blockStyle = classNames(
    styles.container,
    className,
    !footer && styles.withoutFooter
  );

  return (
    <div className={blockStyle}>
      <TitleWithIcon icon={icon} title={title} isSmaller={isSmallerIcon} />
      <div className={styles.bodyContainer}>{children}</div>
      {footer}
    </div>
  );
};

export default SummaryInfoBlock;
