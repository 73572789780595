import { FC } from "react";
import { GREEN } from "../../../../../../helpers/common/colorAliases";
import KeyIcon from "../../../../../../components/icons/KeyIcon";
import styles from "./TokenPlug.module.scss";

export const TokenPlug: FC = () => {
  return (
    <div className={styles.wrapper}>
      <KeyIcon color={GREEN} />
      <span>Token stored</span>
    </div>
  );
};
