import { CellProps } from "react-table";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./diaTableHeader.module.scss";
import Label from "../../../components/common/table/Label";
import { VirtualInetrfaceDIA } from "../../../helpers/api/TenantVirtualInterfaceApi/types";
import DIAConnectionIcon from "../../../components/icons/DIAConnectionIcon";
import LabelArray from "../../../components/common/table/LabelArray";

export const diaTableHeader = (): Array<any> => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return (
          <div className={styles.labelName}>
            <DIAConnectionIcon />
            <span>{value}</span>
          </div>
        );
      },
    },
    {
      Header: "IP pool assigned",
      accessor: "ip_address",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return <Label>{value}</Label>;
      },
    },

    {
      Header: "Available IPs",
      accessor: "availableIPs",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return <div> {value} </div>;
      },
    },
    {
      Header: "GW IP",
      accessor: "ip_addresses",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return <LabelArray values={value} isOutlined withWrap />;
      },
    },
    {
      Header: "Allowed Services",
      accessor: "allowed_services",
      Cell: ({ cell: { value } }: CellProps<VirtualInetrfaceDIA>) => {
        return (
          <div className={styles.labelName}>
            {value.is_nat && <Label isOutlined>NAT</Label>}
            {value.is_snat && <Label isOutlined>SNAT</Label>}
            {value.is_ipsec && <Label isOutlined>IPSEC</Label>}
          </div>
        );
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInetrfaceDIA,
  }));
};
