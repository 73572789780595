import React, { FC } from "react";
import styles from "./BGPTooltip.module.scss";
import { ServiceBGPNeighborWithStatus } from "../../../../../../helpers/api/apiTypes";
import StatusIcon from "../../../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../../../helpers/common/colorHelpers";
import { getBGPStatusType } from "../../../helpers/getBGPStatusType";

type Props = { neighbor: ServiceBGPNeighborWithStatus };
const BGPTooltip: FC<Props> = ({ neighbor }) => {
  const statusVal = getBGPStatusType(neighbor.status.state);
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Status</span>
      <span className={styles.value}>
        <span className={styles.status}>
          <StatusIcon color={getColorFromStatus(statusVal)} />
          {neighbor.status.state}
        </span>
      </span>
      <span className={styles.title}>Remote Neighbor(s)</span>
      <span className={styles.value}>{neighbor.remote_router_ip}</span>
      <span className={styles.title}>Remote ASN</span>
      <span className={styles.value}>{neighbor.remote_asn}</span>
      <span className={styles.title}>Routes Sent/Received</span>
      <span className={styles.value}>
        {neighbor.status.pfxSnt}/{neighbor.status.pfxRcd}
      </span>
    </div>
  );
};
export default BGPTooltip;
