import React, { FC, useEffect, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import Table from "../../components/common/table/newTable/Table";
import { withLayout } from "../../helpers/hocs/withLayout";
import { IpamContextContainer, useIpamContext } from "./IpamContext";
import { IpamTableColumns } from "./Table.columns";
import { TableGroupHeader } from "./Table.group";
import { IpamRow, IPAM_SERVICES, PublicIpManagementRecord } from "./types";

import styles from "./IPAM.module.scss";
import { IpamDeleteDialog } from "./IpamDeleteDialog/IpamDeleteDialog";
import { withContexts } from "../../helpers/hocs/withContexts";
import { PageProps } from "../../helpers/types";
import IpamAddDialog from "./IpamAddDialog/IpamAddDialog";

const gridTemplate = "60px 1fr 100px 100px 100px 100px 1fr 0.5fr 1fr 40px";

const convertToTableRow = (
  apiData: Array<PublicIpManagementRecord>
): Array<IpamRow> => {
  const types = [...new Set(apiData.map((d) => d.type))];

  const rows = apiData.map(
    ({ ipsec_service, nat_service, snat_service, data, ...restRecord }) => {
      const serviceRecord = {
        ipsec_service,
        nat_service,
        snat_service,
      };

      const services = Object.entries(serviceRecord)
        .filter(([, isActive]) => isActive)
        .map(([key]) => IPAM_SERVICES[key]);

      return {
        ...restRecord,
        data: data || "-",
        services,
      };
    }
  );

  const result: Array<IpamRow> = [];

  types.forEach((type) => {
    const subRows = rows.filter((r) => r.type === type);

    if (type !== "location") {
      result.push({
        name: type,
        subRows,
      });
      return;
    }

    const locations = [...new Set(subRows.map((r) => r.data))];

    locations.forEach((location) => {
      const locationRows = rows.filter((r) => r.data === location);

      result.push({
        name: location,
        subRows: locationRows,
      });
    });
  });

  return result;
};

const IPAMPage: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    ipamData,
    ipamFetchStatus,
    fetchIpamData,
    deleteIpam,
  } = useIpamContext();
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const [
    ipamToDelete,
    setIpamToDelete,
  ] = useState<PublicIpManagementRecord | null>(null);

  useEffect(() => {
    fetchIpamData();
  }, []);

  const handleIpamDelete = async () => {
    if (!ipamToDelete) {
      return;
    }
    const { ok } = await deleteIpam(ipamToDelete);

    if (ok) {
      setIpamToDelete(null);
    }
  };

  const closeDeleteDialog = () => {
    setIpamToDelete(null);
  };

  if (showDialog) {
    return <IpamAddDialog onClose={() => setShowDialog(false)} />;
  }

  return (
    <div>
      {Tabs && <Tabs />}
      {ipamToDelete && (
        <IpamDeleteDialog
          ipamToDelete={ipamToDelete}
          onDelete={handleIpamDelete}
          onCancel={closeDeleteDialog}
        />
      )}
      <TableWrapper
        titleProps={{
          title: <TableTitle setShowDialog={setShowDialog} />,
        }}
        dataStatus={ipamFetchStatus}
        isEmpty={ipamData.length === 0}
      >
        <Table
          header={TableHeader}
          columns={IpamTableColumns()}
          data={convertToTableRow(ipamData)}
          gridColumnTemplate={gridTemplate}
          groupHeader={TableGroupHeader}
          rowActions={{
            onDelete: (row) =>
              setIpamToDelete((row as unknown) as PublicIpManagementRecord),
          }}
        />
      </TableWrapper>
    </div>
  );
};

const TableTitle: FC<any> = ({ setShowDialog }) => {
  const { ipamData } = useIpamContext();

  return (
    <div className={styles.tableTitle}>
      <div>IPAM ({ipamData.length})</div>
      <AddButton onClick={() => setShowDialog(true)} />
    </div>
  );
};

export default withContexts<PageProps>(withLayout<PageProps>(IPAMPage), [
  IpamContextContainer,
]);
