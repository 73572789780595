import React, { FC, useEffect, useMemo, useState } from "react";
import { useVRFSelectorContext } from "../../../contexts/systemsContext/VRFSelectorContext";
import VRouterContext, {
  useVRouterContext,
} from "../../../contexts/systemsContext/VRouterContext";
import { VirtualInterfaceType } from "../../../helpers/api/apiTypes";
import { withContexts } from "../../../helpers/hocs/withContexts";
import { withLayout } from "../../../helpers/hocs/withLayout";
import { PageProps } from "../../../helpers/types";
import TabsComponent from "../../common/navigation/TabsComponent";
import TableWrapper from "../../common/table/newTable/layout/TableWrapper";
import TableHeader from "../../common/table/newTable/rows/TableHeader";
import Table from "../../common/table/newTable/Table";
import { arpsTableHeader } from "../../common/table/TableHeaders/common/arpsTableHeader";
import { macsTableHeader } from "../../common/table/TableHeaders/common/macsTableHeader";
import { routesTableHeader } from "../../common/table/TableHeaders/common/routesTableHeader";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import styles from "./VRoute.module.scss";

const VROUTER_TABS = ["Routes", "ARPs"];

type TableParams = {
  title: string;
  number: number;
  getColumns: any;
  grid: string;
  list: Array<any>;
};

const getTableParams = (data: {
  [key: string]: Array<any>;
}): { [key: string]: TableParams } => {
  return {
    routes: {
      title: "Routes",
      number: data.routes.length,
      grid: "repeat(4, 200px)",
      getColumns() {
        return routesTableHeader();
      },
      list: data.routes,
    },
    // macs: {
    //   title: "MACs",
    //   number: data.macs.length,
    //   grid: "repeat(5, 200px)",
    //   getColumns() {
    //     return macsTableHeader();
    //   },
    //   list: data.macs,
    // },
    arps: {
      title: "ARPs",
      number: data.arps.length,
      grid: "repeat(5, 200px)",
      getColumns(viList: Array<VirtualInterfaceType>) {
        return arpsTableHeader(viList);
      },
      list: data.arps,
    },
  };
};

const VRouter: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    vrouterList,
    fetchVRouterList,
    vrouterStatus,
    fetchVrtInterfacesList,
    virtInterfacesList,
  } = useVRouterContext();
  const { selectedVRF } = useVRFSelectorContext();

  const [activeTab, setActiveTab] = useState<string>(VROUTER_TABS[0]);
  const [tableParams, setTableParams] = useState<TableParams>();

  useEffect(() => {
    if (selectedVRF) {
      fetchVRouterList(selectedVRF);
      fetchVrtInterfacesList(selectedVRF);
    }
  }, [selectedVRF]);

  useEffect(() => {
    if (activeTab && vrouterList) {
      setTableParams(undefined);
      const data = getTableParams(vrouterList);
      setTableParams(data[activeTab.toLowerCase()]);
    }
  }, [activeTab, vrouterList]);

  const activeKey = useMemo(() => activeTab.toLowerCase(), [activeTab]);

  return (
    <OneSystemLayout>
      <div className={styles.contentWrapper}>
        <Tabs />
        <TableWrapper
          titleProps={{
            title: (
              <TabsComponent
                tabs={VROUTER_TABS}
                activeTab={activeTab}
                setTabActive={(val) => setActiveTab(val)}
                isBtnMode
              />
            ),
          }}
          dataStatus={vrouterStatus && vrouterStatus[activeKey]}
          tablePlaceholder={[activeTab, activeTab]}
          isEmpty={
            vrouterList &&
            vrouterList[activeKey] &&
            vrouterList[activeKey].length === 0
          }
        >
          {tableParams && (
            <Table
              columns={tableParams.getColumns(virtInterfacesList)}
              header={TableHeader}
              data={[...tableParams.list]}
              gridColumnTemplate={tableParams.grid}
            />
          )}
        </TableWrapper>
      </div>
    </OneSystemLayout>
  );
};

export default withLayout<PageProps>(withContexts(VRouter, [VRouterContext]));
