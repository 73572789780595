import React, { ReactElement, useCallback, useMemo } from "react";
import styles from "./NavigationHeader.module.scss";
import BlankButton from "../buttons/BlankButton";
import { PathEntry } from "../../../helpers/types";
import { useHistory, useLocation } from "react-router-dom";
import DropDownInput, { DropdownItem } from "../formComponents/DropDownInput";
import {
  addAlternatives,
  getPathArrayFromPath,
  isActivePath,
} from "../../../helpers/navigation";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { SERVICES_PREFERENCES } from "../../../helpers/navigation/entries";
import getIconFromValue from "../../../helpers/getIconFromValue";

type Props = {
  alternatives?: Array<PathEntry>;
};

const NavigationHeader: React.FC<Props> = (props) => {
  const { children, alternatives } = props;
  const location = useLocation();

  const pathArray: Array<PathEntry | Array<PathEntry>> = useMemo(() => {
    const arr: Array<PathEntry> = getPathArrayFromPath(location.pathname);
    return addAlternatives(arr, alternatives);
  }, [location, alternatives]);

  return (
    <div className={styles.navHeader}>
      <div className={styles.path}>
        {pathArray.flatMap((el, i) => {
          const addSlash = i > 0;
          const arr = addSlash
            ? [
                <div key={i + "_slash"} className={styles.slash}>
                  /
                </div>,
              ]
            : [];
          if (Array.isArray(el)) {
            return [
              ...arr,
              <PathDropdown pathEntries={el} key={i + "_dropdown"} />,
            ];
          }
          return [...arr, <PathEntryComponent pathEntry={el} key={el.path} />];
        })}
      </div>
      {children}
    </div>
  );
};

type PathDropdownProps = {
  pathEntries: Array<PathEntry>;
};

const mapPathToDropdownEntry = (el?: PathEntry): DropdownItem => ({
  value: el?.content,
  key: el?.path || "",
});

function PathDropdown({ pathEntries }: PathDropdownProps): ReactElement {
  const history = useHistory();
  const location = useLocation();
  const selected = pathEntries.find((el) => isActivePath(location, el.path));

  const list: Array<DropdownItem> = useMemo(
    () => pathEntries.map(mapPathToDropdownEntry),
    [pathEntries]
  );
  const handleSelect = useCallback(
    (item: DropdownItem) => {
      if (item.key) {
        history.push(item.key);
      }
    },
    [pathEntries]
  );
  return (
    <div className={styles.dropdownWrapper}>
      <DropDownInput
        id={"navHeader"}
        isSmaller
        onChange={handleSelect}
        className={styles.dropDownPath}
        itemsList={list}
        selected={mapPathToDropdownEntry(selected)}
        label={""}
        error={""}
        isNotFormElement
      />
    </div>
  );
}

type PathEntryProps = {
  pathEntry: PathEntry;
};
function PathEntryComponent({ pathEntry }: PathEntryProps): ReactElement {
  const history = useHistory();
  const { selectedTenant } = useGlobalFilterContext();

  const value = useMemo(() => {
    const pathEntries = pathEntry.path.split("/");
    const lastEntry = pathEntries[pathEntries.length - 1];
    const withPrefix =
      selectedTenant && ENTRIES_WITH_TENANT_PREFIX.includes(lastEntry);
    const icon = getIconFromValue(lastEntry);
    return withPrefix ? (
      <span className={styles.complexElement}>
        {icon} {selectedTenant} {pathEntry.content}
      </span>
    ) : (
      pathEntry.content
    );
  }, [pathEntry, selectedTenant]);

  return (
    <BlankButton
      id={"pathEntry"}
      className={styles.pathEntry}
      onClick={() => history.push(pathEntry.path)}
    >
      {value}
    </BlankButton>
  );
}

export default NavigationHeader;

const ENTRIES_WITH_TENANT_PREFIX = [SERVICES_PREFERENCES().path];
