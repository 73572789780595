import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function GlobeWithLockIcon(props: IconProps): ReactElement {
  return (
    <svg
      width="25"
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2594 4.41523C11.5413 4.41523 10.6489 5.02076 9.88238 6.55377C9.23764 7.84325 8.78758 9.60943 8.68275 11.6152H13.4406C12.9881 12.1436 12.626 12.7516 12.3779 13.4152H8.68275C8.78758 15.421 9.23764 17.1872 9.88238 18.4767C10.6489 20.0097 11.5413 20.6152 12.2594 20.6152C12.2977 20.6152 12.3364 20.6135 12.3757 20.61C12.6138 21.2493 12.9578 21.837 13.3864 22.3518C13.0165 22.3937 12.6404 22.4152 12.2594 22.4152C6.79176 22.4152 2.35938 17.9829 2.35938 12.5152C2.35938 7.04762 6.79176 2.61523 12.2594 2.61523C16.9522 2.61523 20.8824 5.88042 21.902 10.2629C21.2656 9.91051 20.5584 9.67045 19.8071 9.56944C19.0961 7.74931 17.7459 6.24938 16.0323 5.34576C16.1067 5.47776 16.1781 5.61229 16.2463 5.74878C16.7821 6.82033 17.1853 8.11535 17.4211 9.54319C16.8052 9.60216 16.2164 9.75426 15.669 9.98524C15.4627 8.65382 15.0998 7.48064 14.6364 6.55377C13.8699 5.02076 12.9775 4.41523 12.2594 4.41523ZM8.27241 5.74878C7.48713 7.31934 6.98671 9.36997 6.88055 11.6152H4.20881C4.51022 8.88866 6.16496 6.56997 8.48642 5.34576C8.41201 5.47777 8.34066 5.61229 8.27241 5.74878ZM6.88055 13.4152H4.20881C4.51022 16.1418 6.16496 18.4605 8.48642 19.6847C8.41201 19.5527 8.34066 19.4182 8.27241 19.2817C7.48713 17.7111 6.98671 15.6605 6.88055 13.4152Z"
        fill={props.color || FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7616 25.6782C24.7616 26.0416 24.3964 26.3362 23.9461 26.3362H13.0733C12.6229 26.3362 12.2578 26.0416 12.2578 25.6782V18.2209C12.2578 17.8575 12.6229 17.5629 13.0733 17.5629H14.2334V15.2443C14.2334 13.1883 15.9617 11.5156 18.5097 11.5156C21.0577 11.5156 22.786 13.1883 22.786 15.2443V17.5629H23.9461C24.3964 17.5629 24.7616 17.8575 24.7616 18.2209V25.6782ZM18.5097 12.8316C16.861 12.8316 15.8643 13.9139 15.8643 15.2443V17.5629H21.155V15.2443C21.155 13.9139 20.1584 12.8316 18.5097 12.8316ZM18.5105 23.2344C19.2719 23.2344 19.8891 22.6171 19.8891 21.8557C19.8891 21.0943 19.2719 20.4771 18.5105 20.4771C17.7491 20.4771 17.1318 21.0943 17.1318 21.8557C17.1318 22.6171 17.7491 23.2344 18.5105 23.2344Z"
        fill={props.color || FONT}
      />
    </svg>
  );
}

export default GlobeWithLockIcon;
