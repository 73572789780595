import { FC } from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import DefaultConnectionFields from "../DefaultConnectionFields/DefaultConnectionFields";
import ContractSection from "./components/ContractSection";
import InternetAccessSection from "./components/InternetAccessSection";
import RoutingSection from "./components/RoutingSection";
import styles from "./GatewayConnection.module.scss";

type Props = {
  className: string;
};
const GatewayConnection: FC<Props> = ({ className }) => {
  const sectionClassNames = classNames(className, styles.basicSection);

  return (
    <div className={classNames(styles.wrapper)}>
      <DefaultConnectionFields />
      <InternetAccessSection className={sectionClassNames} />
      <RoutingSection className={sectionClassNames} />
      <ContractSection className={sectionClassNames} />
    </div>
  );
};

export default GatewayConnection;
