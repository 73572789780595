import { ConnectionAdd } from "../../types";
import {
  BASIC_BODY,
  CLOUD_CONNECTIONS,
  CLOUD_ROUTER,
  CONNECTION_TYPES_NAMES,
} from "../consts";
import { getConfigFromType } from "./getConfigFromType";

export const getNewConnectionBody = (
  name: string,
  zEndpointType: string,
  services: {
    [key: string]: any;
  },
  connection: {
    [key: string]: any;
  },
  idx: number
): ConnectionAdd => {
  const body = { ...BASIC_BODY };

  // Constant values
  const aType =
    connection.innerType === CLOUD_CONNECTIONS
      ? connection.cloudType
      : connection.innerType;
  body.name = name + "_" + aType + "_" + idx;
  body.labels = {
    parentConnection: name,
  };
  body.services = services;

  // A Port values
  body.a_endpoint_name = connection.location?.name;
  body.a_endpoint_conf = getConfigFromType(connection);

  // Z Port values
  body.z_endpoint_name = CONNECTION_TYPES_NAMES[zEndpointType];
  body.z_endpoint_conf =
    zEndpointType === CLOUD_ROUTER
      ? {
          ip_addresses: [connection.ip_addresses],
          network_segments: [name],
          virtual_interface_name: body.name,
        }
      : {};

  return body;
};
