/* eslint-disable prettier/prettier */
import React, { FC, useState } from "react";
import {
  VI_IPSEC,
  VI_IPSEC_GATEWAY,
} from "../../../components/tenants/TenantPackages/components/constAlias";
import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { useGlobalFilterContext } from "../../../contexts/GlobalFilterContext";
import { useUserContext } from "../../../contexts/UserContext";
import { Res, ResultStatus } from "../../../helpers/api/Api";
import { networkApi } from "../../../helpers/api/networkApi";
import { systemApi } from "../../../helpers/api/SystemApi";
import { flatMembersList } from "../../../helpers/flatMembersList";
import { RequestStatus, TrafficType } from "../../../helpers/types";
import { System, SystemAPI } from "../../Systems/Provisioning/types";

type Status = "ok" | "pending" | "error";

import {
  VirtualInterface,
  VirtualInterfaceApi,
} from "../../VirtualInterfacePage/types";

type IFunc = {
  fetchSegments: () => void;
  fetchConnections: () => void;
  fetchSegmentations: () => void;
  deleteSegment: (
    segment_name: string,
    prefixes_to_delete: Array<string>
  ) => void;
  fetchSystems: () => void;
  setTrafficType: (type: TrafficType) => void;
  deleteConnection: (type: string, name: string) => Promise<Res<ResultStatus>>;
};

type IState = {
  connections: Array<VirtualInterfaceApi>;
  segmentations: Array<Segmentation>;
  segmentRequestStatus: RequestStatus;
  systems: Array<System>;
  trafficType: TrafficType;
  connectionFetchStatus: Status;
};

type Props = {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

export type Connection = VirtualInterface & {
  state?: {
    operational_state: string | null;
    administrative_state: string | null;
  };
};

export type Segmentation = {
  name: string;
  prefixes: Array<SegmentationPrefix>;
};

export type SegmentationPrefix = {
  subnet: string;
  interface_type?: string;
  interface_name: string;
};

const [NetworkContext, useContext] = createContextAndUse<IState, IFunc>();
export const useNetworkContext = useContext;

export const NetworkContextContainer: FC<Props> = ({ children }) => {
  const { user } = useUserContext();
  const { selectedTenant } = useGlobalFilterContext();
  const [connections, setConnections] = useState<Array<VirtualInterfaceApi>>(
    []
  );
  const [segmentations, setSegmentations] = useState<Array<Segmentation>>([]);
  const [
    segmentRequestStatus,
    setSegmentRequestStatus,
  ] = useState<RequestStatus>({ state: "pending" });
  const [systems, setSystems] = useState<Array<System | SystemAPI>>([]);
  const [trafficType, setTrafficType] = useState<TrafficType>("bps");
  const [connectionFetchStatus, setConnectionFetchStatus] = useState<Status>(
    "pending"
  );

  const tenant = selectedTenant || user.name;

  const fetchConnections = async () => {
    setConnectionFetchStatus("pending");
    const { result, ok } = await networkApi.getVirtualInterfaces(tenant);

    if (!ok || !result) {
      setConnectionFetchStatus("error");
      return;
    }

    setConnections(flatMembersList(result.items));
    setConnectionFetchStatus("ok");
  };

  const fetchSegmentations = async () => {
    setSegmentRequestStatus({ state: "pending" });
    const { result, ok } = await networkApi.getSegmentations(tenant);

    if (!ok) {
      setSegmentRequestStatus({ state: "error" });
    }

    result?.items && setSegmentations(result.items);
    setSegmentRequestStatus({ state: "ok" });
  };

  const deleteConnection = async (
    type: string,
    name: string
  ): Promise<Res<ResultStatus>> => {
    if (!selectedTenant) {
      return { ok: false };
    }

    const { ok, error, result } = await deleteViByType(
      type,
      name,
      selectedTenant
    );

    if (!ok) {
      return { ok: false, error };
    }

    const updatedConnections = connections.filter((c) => c.name !== name);
    setConnections(updatedConnections);

    return { ok, result };
  };

  const deleteViByType = async (
    type: string,
    vi_name: string,
    tenant: string
  ) => {
    switch (type) {
      case VI_IPSEC:
        return await networkApi.deleteIpsecInterface(tenant, vi_name);
      case VI_IPSEC_GATEWAY:
        return await networkApi.deleteIpsecGatewayInterface(tenant, vi_name);
      default:
        return await networkApi.deleteBridgedInterface(tenant, vi_name);
    }
  };

  const deleteSegment = async (
    segment_name: string,
    prefixes_to_delete: Array<SegmentationPrefix>
  ) => {
    setSegmentRequestStatus({ state: "pending" });
    await networkApi.deleteSegments(tenant, segment_name, prefixes_to_delete);

    setSegmentations((segments) => {
      const result = segments.map((s) =>
        s.name === segment_name
          ? {
              ...s,
              prefixes: s.prefixes.filter(
                (p) => !prefixes_to_delete.includes(p)
              ),
            }
          : s
      );

      result.filter((s) => s.prefixes.length > 0);

      return result;
    });
    setSegmentRequestStatus({ state: "ok" });
  };

  const fetchSystems = async () => {
    const { ok, result } = await systemApi.getSystemsList();

    if (!result) {
      return;
    }

    setSystems(result);
  };

  return (
    <NetworkContext.Provider
      value={{
        connections,
        segmentations,
        segmentRequestStatus,
        systems,
        trafficType,
        connectionFetchStatus,
        fetchConnections,
        fetchSegmentations,
        deleteSegment,
        fetchSystems,
        setTrafficType,
        deleteConnection,
      }}
    >
      {children}
    </NetworkContext.Provider>
  );
};
