import React, { FC, useMemo } from "react";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import ErrorIcon from "../../../../../components/icons/ErrorIcon";
import OkCircleIcon from "../../../../../components/icons/OkCircleIcon";
import { classNames } from "../../../../../helpers/common/classNames";
import { useSegmentsRulesContext } from "../../../SegmentsRulesContext";
import styles from "./StatusMessage.module.scss";

const StatusMessage: FC<{ className: string }> = ({ className }) => {
  const { rulesStatus } = useSegmentsRulesContext();
  const elements = useMemo(() => {
    switch (rulesStatus?.state) {
      case "ok":
        return {
          icon: <OkCircleIcon />,
          text: "Configuration saved!",
          styles: styles.okText,
        };
      case "error":
        return {
          icon: <ErrorIcon />,
          text: rulesStatus.message,
          styles: styles.errorText,
        };
      default:
        return null;
    }
  }, [rulesStatus]);

  if (!elements) return <div />;

  return (
    <TextWithIcon
      icon={elements.icon}
      text={elements.text}
      className={classNames(className, elements.styles)}
    />
  );
};
export default StatusMessage;
