import { useCallback, useState } from "react";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";

export function useDateRange(
  initialState?: GTimeRange
): [
  Date | null,
  Date | null,
  (value: Date | null, type: "from" | "to") => void,
  () => void
] {
  const [dateFrom, setDateFrom] = useState<Date | null>(
    initialState ? new Date(initialState.from) : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    initialState ? new Date(initialState.from) : null
  );

  const setDate = useCallback((value: Date | null, type: "from" | "to") => {
    type === "from" ? setDateFrom(value) : setDateTo(value);
  }, []);

  const clear = useCallback(() => {
    setDateFrom(null);
    setDateTo(null);
  }, []);
  return [dateFrom, dateTo, setDate, clear];
}
