import { ChangeEvent, FC, KeyboardEvent } from "react";
import SearchIcon from "../../icons/SearchIcon";
import { GREY_DISABLED } from "../../../helpers/common/colorAliases";

import styles from "./SearchField.module.scss";

type Props = {
  searchValue: string;
  onChange: (value: string) => void;
  onEnter: (value: string, goto: string) => void;
  onClick: () => void;
};

const SearchField: FC<Props> = ({
  searchValue,
  onChange,
  onEnter,
  onClick,
}) => {
  const handleChangeFiled = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onEnter(searchValue, `/search:${searchValue}`);
    }
  };

  return (
    <label
      className={styles.inputField}
      htmlFor="searchField"
      onClick={onClick}
    >
      <SearchIcon className={styles.searchIcon} color={GREY_DISABLED} />
      <input
        value={searchValue}
        type="text"
        id="searchField"
        onChange={handleChangeFiled}
        onKeyDown={handleKeyDown}
      />
    </label>
  );
};

export default SearchField;
