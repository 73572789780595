import React, { useMemo } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { classNames } from "../../../../helpers/common/classNames";
import { FONT, GREY_DISABLED } from "../../../../helpers/common/colorAliases";
import { formPathFromArray } from "../../../../helpers/navigation";
import {
  CONFIGURATION,
  NETWORK,
  REPORTS,
  SEGMENTATION,
  SEGMENTS,
  SEGMENTS_RULES,
  SERVICES,
} from "../../../../helpers/navigation/entries";
import { PathEntry } from "../../../../helpers/types";
import PieChartIcon from "../../../icons/PieChartIcon";
import SettingsIcon from "../../../icons/SettingsIcon";
import BlankButton from "../../buttons/BlankButton";
import TimerangOverlayComponent from "../../timerange/TimerangeOverlayComponent";
import NavigationTabs from "../NavigationTabs";
import ActiveTenantSelector from "../tenantSelector/ActiveTenantSelector";

import styles from "./SegmentsTab.module.scss";

export const SegmentsTab: React.FC<{
  showTimeRange?: boolean;
  noConfig?: boolean;
  noReports?: boolean;
}> = ({ showTimeRange, noConfig, noReports }) => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const isReportingActive = useMemo(() => path.includes(REPORTS().path), [
    path,
  ]);

  return (
    <div className={styles.tabsWrapper}>
      <TabsComponent />
      <div className={styles.controlsWrapper}>
        <ActiveTenantSelector />
        {showTimeRange && (
          <TimerangOverlayComponent
            id={"segmentsTab"}
            className={styles.timerangeWrapper}
          />
        )}
        {false && (
          <div className={styles.selectView}>
            <BlankButton
              id={"SegmentsTabConfig"}
              className={classNames(noConfig && styles.disabled)}
              disabled={noConfig}
              onClick={() => {
                history.push(path + "/" + CONFIGURATION().path);
              }}
            >
              <SettingsIcon
                color={!noConfig && !isReportingActive ? FONT : GREY_DISABLED}
              />
            </BlankButton>
            <BlankButton
              id={"SegmentsTabReports"}
              className={classNames(noReports && styles.disabled)}
              disabled={noReports}
              onClick={() => {
                history.push(path + "/" + REPORTS().path);
              }}
            >
              <PieChartIcon
                color={!noReports && isReportingActive ? FONT : GREY_DISABLED}
              />
            </BlankButton>
          </div>
        )}
      </div>
    </div>
  );
};
export const TabsComponent: React.FC = () => {
  const history = useHistory();
  const handleClick = (path: PathEntry) => {
    history.replace(formPathFromArray([SERVICES(), SEGMENTATION(), path]));
  };

  return (
    <NavigationTabs
      entries={servicesArray}
      disabledTabKeys={[]}
      onClick={handleClick}
    />
  );
};

const servicesArray = [SEGMENTS_RULES(), SEGMENTS()];
