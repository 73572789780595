import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function ConfigRestoreIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1019_2350)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.7359 8.77274L22.4038 9.42438C22.6978 9.49087 22.9116 9.75685 22.9116 10.0627V11.2387C22.0274 10.9554 21.0846 10.8024 20.1058 10.8024C19.0369 10.8024 18.0109 10.9849 17.0574 11.3203C17.0094 8.34533 14.5714 5.94841 11.5709 5.94841C8.54047 5.94841 6.08379 8.3935 6.08379 11.4097C6.08379 14.3959 8.49199 16.8224 11.481 16.8702C11.1439 17.8193 10.9605 18.8405 10.9605 19.9045C10.9605 20.8784 11.1142 21.8167 11.3988 22.6965H10.1288C9.82145 22.6965 9.55422 22.4837 9.47405 22.1912L8.83268 19.6245C8.36502 19.4782 7.91072 19.292 7.46978 19.066L5.19827 20.4224C4.93103 20.582 4.59699 20.5421 4.3832 20.3293L2.48582 18.4409C2.27203 18.2281 2.23195 17.8957 2.39229 17.6297L3.7552 15.3689C3.52804 14.9301 3.34098 14.4779 3.194 14.0124L0.615171 13.3608C0.321211 13.2943 0.107422 13.0283 0.107422 12.7225V10.0627C0.107422 9.75685 0.321211 9.49087 0.615171 9.42438L3.194 8.77274C3.34098 8.30728 3.52804 7.85512 3.7552 7.41627L2.39229 5.15548C2.23195 4.8895 2.27203 4.55704 2.48582 4.34426L4.3832 2.45583C4.59699 2.24305 4.93103 2.20316 5.19827 2.36274L7.46978 3.71922C7.91072 3.49314 8.36502 3.30695 8.83268 3.16067L9.47405 0.505352C9.55422 0.21278 9.82145 0 10.1288 0H12.8011C13.1085 0 13.3757 0.21278 13.4559 0.505352L14.0972 3.16067C14.5649 3.30695 15.0192 3.49314 15.4601 3.71922L17.7316 2.36274C17.9989 2.20316 18.3329 2.24305 18.5467 2.45583L20.4441 4.34426C20.6579 4.55704 20.698 4.8895 20.5376 5.15548L19.1747 7.41627C19.4019 7.85512 19.5889 8.30728 19.7359 8.77274ZM11.5719 13.8381C12.9188 13.8381 14.0106 12.7514 14.0106 11.4109C14.0106 10.0704 12.9188 8.98365 11.5719 8.98365C10.225 8.98365 9.13315 10.0704 9.13315 11.4109C9.13315 12.7514 10.225 13.8381 11.5719 13.8381Z"
          fill={color || FONT}
        />
        <g clipPath="url(#clip1_1019_2350)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.2399 16.9904L13.7964 17.2787L13.9199 12.8623L15.2729 14.1553C17.175 12.7984 19.8338 12.9734 21.5407 14.6803C23.4426 16.5823 23.4426 19.6659 21.5407 21.5679C19.6388 23.4698 16.5551 23.4698 14.6532 21.5679C14.1263 21.041 13.744 20.4211 13.5087 19.7599L15.3929 19.0893C15.5308 19.4767 15.7548 19.8411 16.0674 20.1537C17.1883 21.2746 19.0056 21.2746 20.1265 20.1537C21.2474 19.0328 21.2474 17.2155 20.1265 16.0946C19.2165 15.1846 17.8475 15.0134 16.7648 15.5809L18.2399 16.9904Z"
            fill={color || FONT}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1019_2350">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_1019_2350">
          <rect
            width="11.52"
            height="11.52"
            fill="white"
            transform="translate(24 12.4805) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ConfigRestoreIcon;
