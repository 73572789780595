import { PropsWithChildren } from "react";
import { RequestStatus } from "../../helpers/types";
import { PRes } from "../../helpers/api/Api";
import { AbstractContextProvider } from "./AbstractContext";

export type DialogRequestStatuses = {
  addStatus?: RequestStatus;
  editStatus?: RequestStatus;
};

export type DialogValueState<One> = {};

export type DialogState<One> = DialogValueState<One> & DialogRequestStatuses;

export type DialogFunc<One> = {
  add: (item: Partial<One>, parent?: string) => Promise<boolean>;
  edit: (modifiedItem: Partial<One>, parent?: string) => Promise<boolean>;
};

abstract class AbstractDialogContextContainer<
  One,
  State extends DialogState<One> & RequestsState,
  RequestsState extends DialogRequestStatuses,
  Funcs extends DialogFunc<any>,
  Props = {}
> extends AbstractContextProvider<State, RequestsState, Funcs, Props> {
  constructor(props: Readonly<PropsWithChildren<Props>>) {
    super(props);
  }

  _addWrap = async (fn: () => PRes<void>): PRes<void> => {
    return this._doActionWrap(fn, "addStatus");
  };

  _editWrap = async (fn: () => PRes<void>): PRes<void> => {
    return this._doActionWrap(fn, "editStatus");
  };

  _doActionWrap = async <D,>(
    fn: () => PRes<D>,
    status: keyof RequestsState
  ): PRes<D> => {
    const { ok, result, error } = await this._fetchWithStatus(fn, status);
    this._resetStatusInTimeout(status);
    return { ok, result, error };
  };
}

export default AbstractDialogContextContainer;
