import React, { FC, ReactElement } from "react";
import { DialogControlsType, RequestStatus } from "../../../helpers/types";
import DialogBtmButtons from "./DialogBtmButtons";
import styles from "./DialogTemplateOneColumn.module.scss";
import { classNames } from "../../../helpers/common/classNames";

type DialogTemplateOneColumnProps = {
  header: ReactElement | string;
  controls: DialogControlsType;
  onClose?: () => void;
  isSmall?: boolean;
  className?: string;
  status?: RequestStatus;
};

const DialogTemplateOneColumn: FC<DialogTemplateOneColumnProps> = ({
  header,
  controls,
  onClose,
  children,
  isSmall,
  className,
  status,
}) => {
  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(
          styles.dialogContainer,
          isSmall && styles.small,
          className
        )}
      >
        <div className={styles.header}>{header}</div>
        <div className={styles.content}>{children}</div>
        <DialogBtmButtons controls={controls} errorDisplay={status} />
      </div>
      <div className={styles.overlay} onClick={onClose || controls.onCancel} />
    </div>
  );
};

export default DialogTemplateOneColumn;
