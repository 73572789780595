import React, { PropsWithChildren } from "react";
import { FilterAndSort, VRFType } from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { createContextAndUse } from "../common/AbstractCrudContext";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "./OneSystemContext";

type IState = { selectedVRF?: string; list: Array<VRFType> };
type IFunc = {
  setSelectedVRF: (vrf: string) => void;
  fetchList: () => Promise<void>;
};

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useVRFSelectorContext = useContext;

type Props = IOneSystemContext;
class VRFSelectorContextContainer extends AbstractTimeoutHandler<
  IState,
  Props
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);

    this.funcs = {
      fetchList: this.fetchList,
      setSelectedVRF: this.setSelectedVRF,
    };
  }

  fetchList = async (params?: FilterAndSort): Promise<void> => {
    const { system } = this.props;
    if (!system) return;
    const res = await configApi.getVRFList(system.name, params?.sort);
    if (res.ok && res.result) {
      this.setState({ list: res.result });
    }
  };

  setSelectedVRF = (vrf: string): void => {
    this.setState({ selectedVRF: vrf });
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default withOneSystemContextProps<PropsWithChildren<any>>(
  VRFSelectorContextContainer
);
