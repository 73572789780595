import React, { FC, ReactElement } from "react";
import { classNames } from "../../../../helpers/common/classNames";
import styles from "./InterfaceInfoCart.module.scss";
import { Layer3Interface } from "../../../../pages/Layer3Interface/types";
import { getIconAndText } from "../../../Network/Connections/helpers/getConnectionType";
import ConnectionInfoBlock from "./components/ConnectionInfoBlock";

type Props = {
  interfaceInfo: Layer3Interface;
  outBand: number;
  inBand: number;
  className?: string;
};

const InterfaceInfoCart: FC<Props> = ({ interfaceInfo, className }) => {
  const isRemote = interfaceInfo?.labels?.remote_type === "remote_users";
  return (
    <div
      className={classNames(
        styles.elementsBox,
        isRemote && styles.twoColumns,
        className
      )}
    >
      {getBlockColumns(interfaceInfo, isRemote)}
    </div>
  );
};

export default InterfaceInfoCart;

const getBlockColumns = (
  interfaceInfo: any,
  isRemote: boolean
): ReactElement | null => {
  const [icon, displayText] = getIconAndText(
    interfaceInfo.type,
    interfaceInfo?.virtual_interface_type,
    interfaceInfo?.labels?.remote_type,
    interfaceInfo?.is_ipsec_service,
    interfaceInfo?.is_nat_service,
    interfaceInfo?.is_snat_service
  );
  return (
    <ConnectionInfoBlock
      name={(isRemote && "Remote users") || interfaceInfo.name}
      displayTypeText={displayText}
      icon={icon}
      ip_addresses={interfaceInfo.ip_addresses}
      isRemote={isRemote}
    />
  );
};
