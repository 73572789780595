import { Row } from "./types";
import { arubaApi } from "../../../helpers/api/ArubaApi";

export const colors: Record<string, string> = {
  HEALTHY: "#66bb6a",
  WARNING: "#00bcd4",
  MAJOR: "#ffa726",
  CRITICAL: "#ef5350",
};

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getLastSevenDays = (): Array<string> => {
  const daySeven = new Date();
  const day = daySeven.getDate();

  const dayOne = new Date();
  dayOne.setDate(day - 6);

  const dayTwo = new Date();
  dayTwo.setDate(day - 5);

  const dayThree = new Date();
  dayThree.setDate(day - 4);

  const dayFour = new Date();
  dayFour.setDate(day - 3);

  const dayFive = new Date();
  dayFive.setDate(day - 2);

  const daySix = new Date();
  daySix.setDate(day - 1);

  return [dayOne, dayTwo, dayThree, dayFour, dayFive, daySix, daySeven].map(
    (date) => {
      return months[date.getMonth()] + " " + date.getDate();
    }
  );
};

export const getHealth = (
  id: string,
  data: Row
): Promise<[any, any, any, any]> => {
  const params = {
    time: data.time.toString(),
    applianceId: id,
    overlayId: "-1",
  };

  const loss = arubaApi.getHealthLoss(params).then((res) => res.result);
  const latency = arubaApi.getHealthLatency(params).then((res) => res.result);
  const jitter = arubaApi.getHealthJitter(params).then((res) => res.result);
  const mos = arubaApi.getHealthMos(params).then((res) => res.result);

  return Promise.all([loss, latency, jitter, mos]);
};
