import React, { FC, useMemo, useState } from "react";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import { useGlobalFilterContext } from "../../../../../../contexts/GlobalFilterContext";
import { TrafficType } from "../../../../../../helpers/types";
import grafanaLinksFactory from "../../../../../../components/common/charts/GraphanaLinksFactory";
import ChartsHeader from "../../../../../../components/common/charts/ChartsHeader/ChartsHeader";
import ChartToppersTabs from "../../../../../../components/common/ChartToppersTabs";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";

type Props = { className: string };

const NewTenantTraffic: FC<Props> = ({ className }) => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();

  const [activeTraffic, setActiveTraffic] = useState<TrafficType>("bps");

  const graphData = useMemo(
    () => ({
      timeRange: timeRange.general,
      tenantName: selectedTenant,
    }),
    [timeRange, selectedTenant]
  );
  const graphLink = useMemo(
    () =>
      activeTraffic === "bps"
        ? grafanaLinksFactory.dashboard_bps(timeRange.general)(graphData)
        : grafanaLinksFactory.dashboard_pps(timeRange.general)(graphData),
    [activeTraffic, graphData]
  );

  return (
    <div className={className}>
      <ChartsHeader
        controls={
          <ChartToppersTabs
            activeTab={activeTraffic}
            setTabActive={setActiveTraffic}
          />
        }
      />
      <GrafanaGraph link={graphLink} />
    </div>
  );
};
export default NewTenantTraffic;
