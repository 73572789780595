import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function ConnectionIcon({}: IconProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path
        fill="#6B55F0"
        d="M1.915 13.053a1.368 1.368 0 1 0 0-2.737 1.368 1.368 0 0 0 0 2.737Z"
      />
      <path
        fill="#6B55F0"
        fillRule="evenodd"
        d="M8 10.398a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        clipRule="evenodd"
      />
      <path
        fill="#6B55F0"
        stroke="#6B55F0"
        strokeWidth=".2"
        d="m2.707 5.842-.048.088.088.048 3.65 1.99.087.048.048-.088.249-.456.048-.088-.088-.048-3.65-1.99-.087-.048-.048.088-.249.456ZM6.532 8.87l-.048-.088-.088.048-3.649 1.99-.088.048.048.088.25.457.047.087.088-.047 3.649-1.99.088-.049-.048-.088-.25-.456Z"
      />
      <path
        fill="#6B55F0"
        d="M1.915 6.483a1.368 1.368 0 1 0 0-2.737 1.368 1.368 0 0 0 0 2.737Z"
      />
      <path
        fill="#6B55F0"
        fillRule="evenodd"
        d="M14.085 13.053a1.368 1.368 0 1 1 0-2.736 1.368 1.368 0 0 1 0 2.736Z"
        clipRule="evenodd"
      />
      <path
        fill="#6B55F0"
        stroke="#6B55F0"
        strokeWidth=".2"
        d="m13.293 5.842.048.088-.088.048-3.65 1.99-.087.048-.048-.088-.249-.456-.048-.088.088-.048 3.65-1.99.087-.048.048.088.249.456ZM9.468 8.87l.048-.088.088.048 3.649 1.99.088.048-.048.088-.25.457-.047.087-.088-.047-3.649-1.99-.088-.049.048-.088.25-.456Z"
      />
      <path
        fill="#6B55F0"
        fillRule="evenodd"
        d="M14.085 6.483a1.368 1.368 0 1 1 0-2.737 1.368 1.368 0 0 1 0 2.737Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default ConnectionIcon;
