import React, { FC } from "react";
import styles from "./InstructionPage.module.scss";

type Props = {
  activeInstruction: string;
};
const instructions: any = {
  Windows: `<li>
  <span>1. </span>
  Download the WireGuard app from the official <a
    href="https://www.wireguard.com/install/"
    target="_blank"
  >
  WireGuard website
  </a>
</li>
<li>
  <span>2. </span> Download the configuration file here
</li>
<li>
  <span>3. </span>
  Launch the WireGuard app and click "Add Tunnel" on the bottom left
</li>
<li>
  <span>4. </span>
  Select "Import tunnel(s) from file" from the menu and choose the configuration file downloaded above
</li>
<li>
  <span>5. </span>
  Click “Activate” to connect to the network
</li>`,
  MacOS: `<li>
  <span>1. </span>
  Download the WireGuard app from the <a
    href="https://apps.apple.com/us/app/wireguard/id1451685025?ls=1&mt=12"
    target="_blank"
  > App Store</a>
</li>
<li>
  <span>2. </span> Download the configuration file here
</li>
<li>
  <span>3. </span>
  Open the app and click the + button to add a tunnel
</li>
<li>
  <span>4. </span>
  Choose “Create from file or archive” and select the configuration
  file downloaded above
</li>
<li>
  <span>5. </span>
  Click “Activate” to connect to the network
</li>
<li>
  <span>Tipp </span>
  It’s also possible to scan the QR code on the left with your laptop
  camera. To do so, open this page on another device, reveal to code
  on the left and choose “Create from QR code” in step 4 above.
</li>`,
  Ubuntu: `<li>
  <span>1. </span>Download the configuration file here</li>
<li>
  <span>2. </span>Open the Ubuntu Settings and navigate to Network. Add a new VPN connection by clicking “+”. Make sure the option “WireGuard” is in the list, otherwise please update Ubuntu first or see the tipp below.
</li>
<li>
  <span>3. </span>
  Choose “Import from file” to add the connection.
</li>
<li>
  <span>4. </span>
  Select the configuration file downloaded above
</li>
<li>
  <span>5. </span>
  Enable the VPN connection in the network menu on the top right corner of the screen
</li>
<li>
  <span>Tipp </span>
  If the Ubuntu Settings tool doesn’t support Wireguard or if it has trouble importing the configuration, run this command instead:
<tt>nmcli connection import type wireguard file ~/ipkt.conf</tt><br>
Make sure the path to the file ipkt.conf is correct.
</li>`,
  IOS: `<li>
<span>1. </span>
Search for "WireGuard" in the App Store and install the official app
</li>
<li>
<span>2. </span> Open the WireGuard app on your iOS device and tap the "+" icon
</li>
<li>
<span>3. </span>
Reveal the QR code on this screen by clicking on it
</li>
<li>
<span>4. </span>
Choose "Import from QR code" and scan the QR code revealed above
</li>
<li>
<span>5. </span>
Allow the VPN to be added to your device (requires authentication)
</li>
<li>
<span>6. </span>
Toggle the switch next to the new configuration to connect
</li>`,
  Android: `<li>
<span>1. </span>
Visit the Google Play Store and search for "WireGuard" and install the official app. Please don’t use 3rd party App stores!
</li>
<li>
<span>2. </span> Open the WireGuard app on your Android device and tap the "+" icon</li>
<li>
<span>3. </span>
Reveal the QR code on this screen by clicking on it</li>
<li>
<span>4. </span>
Choose "Create from QR code" and scan the QR code revealed above
</li>
<li>
<span>5. </span>
Allow the VPN to be added to your device (might require authentication)
</li>
<li>
<span>6. </span>
Tap the toggle switch to connect.</li>`,
  Linux: `<li>
<span>1. </span>
Visit the  <a
href="https://www.wireguard.com/install/"
target="_blank"
>
WireGuard website
</a> to understand how to install WireGuard for your OS
</li>
<li>
<span>2. </span> Download the configuration file here
</li>
<li>
<span>3. </span>
Copy the configuration file to the configuration directory for wireguard (usually <tt>/etc/wireguard/ </tt>). Comment out the lines “Address=” and “DNS=” with a hash sign (#) using your preferred editor.</li>
<li>
<span>4. </span>
Create a wireguard interface<br>
<tt>sudo ip link add wg0 type wireguard</tt><br>
<tt>sudo ip link set dev wg0 up</tt>
</li>
<li>
<span>5. </span>
Add the IP address to the wg0 interface
<tt>sudo ip addr add <b>[IP]</b>/32 dev wg0</tt>
</li>
<li>
<span>6. </span>
Activate the connection
<tt>sudo wg setconf wg0 /etc/wireguard/<b>[config]</b>.conf/tt>
</li>
<li>
<span>7. </span>
See connection status
<tt>sudo wg show</tt>
</li>`,
};

const InstructionPage: FC<Props> = ({ activeInstruction }) => {
  return (
    <>
      <div className={styles.instructionLogo}>
        <ul>
          Instructions
          <div
            dangerouslySetInnerHTML={{
              __html: instructions[activeInstruction],
            }}
          />
        </ul>
      </div>
    </>
  );
};

export default InstructionPage;
