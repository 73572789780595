import { CellFactory } from "../../../components/common/table/newTable/cells/CellsFactory";
import {
  SubnetFirewallRule,
  ActivatedRules,
  FirewallRuleDirection,
} from "./api.types";
import { FirewallRule } from "./FirewallRule";

import styles from "./SubnetTableHeader.module.scss";

const cellFactory = new CellFactory<SubnetFirewallRule>({});

const SubnetTableHeader = (): Array<any> => [
  {
    Header: "Subnet",
    accessor: "subnet",
    Cell: cellFactory.textCell(),
  },
  {
    Header: "Create a firewall rule",
    accessor: "rule_actions",
    Cell: ({
      cell: { value },
    }: {
      cell: { value: ActivatedRules };
    }): JSX.Element => {
      const rules = Object.entries(value);

      return (
        <div className={styles.rulesWrapper}>
          {rules.map(([key, isActive]) => (
            <FirewallRule
              key={key}
              isActive={isActive}
              name={key as FirewallRuleDirection}
            />
          ))}
        </div>
      );
    },
  },
];

export default SubnetTableHeader;
