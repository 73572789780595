import React, { FC, useState } from "react";
import IconBlankButton from "../../../../components/common/buttons/IconBlankButton";
import FilterIcon from "../../../../components/icons/FilterIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { FONT, PURPLE } from "../../../../helpers/common/colorAliases";
import { useClickOutside } from "../../../../helpers/hooks/useClickOutside";
import FilterBody from "./components/FilterBody";
import styles from "./Filters.module.scss";

const DnsFilters: FC<{ className?: string }> = ({ className }) => {
  const [expand, setExpand] = useState(false);

  const timeRangeRef = useClickOutside(() => setExpand(false));

  const filterBodyClasses = classNames(
    styles.wrapper,
    !expand && styles.collapsed,
    className
  );

  return (
    <div ref={timeRangeRef as React.RefObject<HTMLDivElement>}>
      <IconBlankButton
        id={"appDashboardFilter"}
        icon={FilterIcon}
        onClick={() => setExpand((ex) => !ex)}
        color={expand ? PURPLE : FONT}
        isFill
      />
      <div className={filterBodyClasses}>
        <FilterBody onClose={() => setExpand(false)} />
      </div>
    </div>
  );
};

export default DnsFilters;
