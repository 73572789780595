import React, { FC } from "react";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import InfoIcon from "../../../../components/icons/InfoIcon";
import DialogFrame from "../../common/DialogFrame/DialogFrame";
import { DialogText } from "../../common/DialogText/DialogText";
import { DialogComponentProps } from "../../common/types";

const DialogRight: FC<DialogComponentProps> = ({ className }) => {
  return (
    <DialogFrame className={className}>
      <TextWithIcon icon={<InfoIcon />} text="Information" />
      <DialogText>
        Define a range of IP addresses to be assigned to tenants for internet
        access or IPSec and set a Name, Location and VRF to use. Define services
        allowed (e.g. to use different addresses for NAT and IPSec) and whether
        these IPs can be shared amongst different tenants (best for IPSec) or
        are assigned to only one tenant each. When a tenant requests an address,
        a loopback device in the above VRF will be added to the system in the
        location where the tenant is operational. Make sure to configure BGP to
        announce the IP. If no BGP is available, assign the range to a specific
        System and add a static route on the next hop for this range. Global IPs
        can be assigned to any system by the tenant. Please make sure that your
        routing supports that.
      </DialogText>
    </DialogFrame>
  );
};

export default DialogRight;
