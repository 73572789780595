import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import {
  IPortInterfaceContext,
  withPortInterfaceContextProps,
} from "../PortInterfaceContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { PortInterface, PortInterfaceCreate } from "../types";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";
import { PortType } from "../../PortsPage/types";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<PortInterface>;

type IState = State & RequestStatuses & { ports?: PortType };
type IFunc = DialogFunc<PortInterface>;

const [
  PortInterfaceDialogContext,
  usePortInterfaceDialogContext,
] = createContextUtils<IState, IFunc>();
export { usePortInterfaceDialogContext };

class PortInterfaceDialogContextContainer extends AbstractDialogContextContainer<
  PortInterface,
  IState,
  RequestStatuses,
  IFunc,
  IPortInterfaceContext & IOneSystemContext
> {
  Context = PortInterfaceDialogContext;

  _updateSystems = (ok: boolean) => {
    if (ok) {
      this.props.fetchList();
    }
  };
  add = async (params: Partial<PortInterface>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._addWrap(() =>
      configApi.addPortInterface(system.name, params as PortInterfaceCreate)
    );
    this._updateSystems(ok);

    return ok;
  };

  // todo not implemented
  edit = async (vlan: Partial<PortInterface>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());
    this._updateSystems(ok);
    return ok;
  };

  funcs = {
    edit: this.edit,
    add: this.add,
  };
}

export default withPortInterfaceContextProps(
  withOneSystemContextProps(PortInterfaceDialogContextContainer)
);
