/* eslint-disable prettier/prettier */
import React, { FC, ReactNode, useState } from "react";

import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { elasticApi } from "../../helpers/api/ElasticApi";
import { RequestStatus } from "../../helpers/types";
import { GTimeRange } from "../../components/common/charts/GraphanaLinksFactory";
import { Event, EventsType } from "./types";

type IFunc = {
  fetchEvents: (
    type: EventsType,
    timeRange: GTimeRange,
    size: string,
    matchParam?: string
  ) => Promise<void>;
  selectEvent: (id?: number) => void;
  setSize: (size: string) => void;
};

type IState = {
  events: Array<Event>;
  size: string;
  selectedEvent?: Event;
  eventsRequestStatus?: RequestStatus;
};

type Props = {
  children: ReactNode;
};

const [EventsContext, useContext] = createContextAndUse<IState, IFunc>();
export const useEventsContext = useContext;

export const EventsContextContainer: FC<Props> = ({ children }) => {
  const [events, setEvents] = useState<Array<any>>([]);
  const [size, setSize] = useState<string>("20");
  const [selectedEvent, setSelectedEvent] = useState<any | undefined>();
  const [eventsRequestStatus, setEventsRequestStatus] = useState<RequestStatus>(
    { state: "idle" }
  );

  const fetchEvents = async (
    type: EventsType,
    timeRange: GTimeRange,
    size: string,
    matchParam?: string
  ) => {
    setEventsRequestStatus({ state: "pending" });

    const { ok, result, error } = await elasticApi.getEventsByType(
      type,
      size,
      timeRange,
      matchParam
    );

    if (!ok || !result) {
      setEventsRequestStatus({ state: "error", message: error });
      return;
    }
    setEventsRequestStatus({ state: "ok" });
    setEvents(result);
  };

  const selectEvent = (id?: number) => {
    const select = events.find((el) => el.id === id);
    setSelectedEvent(select);
  };

  return (
    <EventsContext.Provider
      value={{
        fetchEvents,
        selectEvent,
        events,
        eventsRequestStatus,
        selectedEvent,
        size,
        setSize,
      }}
    >
      {children}
    </EventsContext.Provider>
  );
};
