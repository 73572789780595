import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function DragDropIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="2" y="7" width="12" height="2" rx="1" fill={color || FONT} />
      <path
        d="M13.1172 4H2.88278C2.37005 4 2.18954 3.32026 2.63471 3.06588L7.50386 0.283508C7.81129 0.107832 8.18871 0.107832 8.49614 0.283508L13.3653 3.06588C13.8105 3.32026 13.6299 4 13.1172 4Z"
        fill={color || FONT}
      />
      <path
        d="M13.1172 12H2.88278C2.37005 12 2.18954 12.6797 2.63471 12.9341L7.50386 15.7165C7.81129 15.8922 8.18871 15.8922 8.49614 15.7165L13.3653 12.9341C13.8105 12.6797 13.6299 12 13.1172 12Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default DragDropIcon;
