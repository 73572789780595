import { ValidatorResponse } from "../validators/Validator";
import { capitalizeFirstLetter } from "../common/stringHelpers";

export type SetField = (
  name: string,
  value: string | Array<string> | {} | undefined
) => void;

export type ResetField = (arg: { [key: string]: any }) => void;

export type PropsFormer<P> = (
  name: keyof P
) => {
  name: keyof P;
  value: any;
  error?: string;
  handleFieldChange: SetField;
  medium: boolean;
  label: string;
};

export function formFieldProps<P extends { [key: string]: any }>(
  fields: P,
  onChange: SetField,
  errors: ValidatorResponse["errors"] | undefined
): PropsFormer<P> {
  return (name: keyof P) => {
    return {
      name,
      value: fields[name],
      error: errors && errors[name as string],
      handleFieldChange: onChange,
      label: capitalizeFirstLetter(name as string),
      medium: true,
    };
  };
}
