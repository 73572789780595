import { FC } from "react";
import { DropdownItem } from "../../../../../../helpers/types";
import styles from "./ListContainer.module.scss";
import ListItem from "./ListItem";
import { capitalizeFirstLetter } from "../../../../../../helpers/common/stringHelpers";
import { classNames } from "../../../../../../helpers/common/classNames";

type Props = {
  valuesList:
    | Array<DropdownItem>
    | { [key: string]: Array<DropdownItem> }
    | undefined;
  isListEditable?: boolean;
  emptyListPlaceholder?: string;
  onEditOption?: (key?: string) => void;
  onDeleteOption?: (key?: string) => void;
  withCheckbox?: boolean;
  handleSelect: (arg: any) => void;
  spanClassName?: string;
  additionalValuesList?:
    | Array<DropdownItem>
    | { [key: string]: Array<DropdownItem> }
    | undefined;
};

const ListContainer: FC<Props> = ({
  valuesList,
  isListEditable,
  emptyListPlaceholder,
  onEditOption,
  onDeleteOption,
  withCheckbox,
  handleSelect,
  spanClassName,
  additionalValuesList,
}) => {
  const isEmpty =
    (!valuesList && !additionalValuesList) ||
    (Array.isArray(valuesList) &&
      valuesList.length === 0 &&
      !additionalValuesList);

  if (isEmpty) {
    return (
      <div className={styles.noValue}>{emptyListPlaceholder || "No value"}</div>
    );
  }

  const getItem = (
    value: DropdownItem,
    withControls: string | boolean | undefined,
    i: number
  ) => {
    return (
      <ListItem
        key={value?.key + i}
        value={value}
        onEditOption={onEditOption}
        onDeleteOption={onDeleteOption}
        onSelectOption={handleSelect}
        withCheckbox={!!withCheckbox}
        withControls={!!withControls}
        spanClassName={spanClassName}
      />
    );
  };

  if (Array.isArray(valuesList)) {
    return (
      <>
        <div className={styles.values}>
          {valuesList.map((value, i) => {
            const withControls =
              isListEditable && value?.key && value?.isEditable;

            return getItem(value, withControls, i);
          })}
        </div>
        {additionalValuesList && (
          <div
            className={classNames(
              !!valuesList.length && styles.additional,
              !additionalValuesList?.length && styles.noVisible
            )}
          >
            {Array.isArray(additionalValuesList) &&
              additionalValuesList?.map((value, i) => {
                const withControls =
                  isListEditable && value?.key && value?.isEditable;

                return getItem(value, withControls, i);
              })}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {valuesList &&
        Object.keys(valuesList).map((key) => (
          <>
            <span className={styles.subTitle}>
              {capitalizeFirstLetter(key)}
            </span>
            {valuesList[key].map((value, i) => {
              const withControls =
                isListEditable && value?.key && value?.isEditable;

              return getItem(value, withControls, i);
            })}
          </>
        ))}
    </>
  );
};

export default ListContainer;
