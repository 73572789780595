import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

export function TableIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className={className}
    >
      <rect width="14" height="2" fill={color || FONT} rx="1" />
      <rect width="14" height="2" y="4" fill={color || FONT} rx="1" />
      <rect width="14" height="2" y="8" fill={color || FONT} rx="1" />
      <rect width="14" height="2" y="12" fill={color || FONT} rx="1" />
    </svg>
  );
}
