import { CellProps } from "react-table";
import { ARPItem } from "../types";
import { getBooleanIcon } from "../../../helpers/getIconFromValue";

export const arpsTableHeader = (): Array<any> => {
  return [
    {
      Header: "IP",
      accessor: "ip",
      Cell: ({ cell: { value } }: CellProps<ARPItem>) => {
        return value || "";
      },
    },
    {
      Header: "MAC",
      accessor: "mac",
      Cell: ({ cell: { value } }: CellProps<ARPItem>) => {
        return value || "";
      },
    },
    {
      Header: "Virtual Interface",
      accessor: "virtual_interface_name",
      Cell: ({ cell: { value } }: CellProps<ARPItem>) => {
        return value || "";
      },
    },
    {
      Header: "Age(sec)",
      accessor: "age_sec",
      Cell: ({ cell: { value } }: CellProps<ARPItem>) => {
        return value || "";
      },
    },
    {
      Header: "Static",
      accessor: "is_static",
      Cell: ({ cell: { value } }: CellProps<ARPItem>) => {
        return getBooleanIcon(value);
      },
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof ARPItem,
  }));
};
