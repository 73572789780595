import React, { FC } from "react";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import { PageProps } from "../../../../helpers/types";
import ConnectionsContent from "./ConnectionsContent";
import styles from "./ConnectionsPage.module.scss";

const ConnectionsPage: FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <div className={styles.wrapper}>
      <Tabs />
      <ConnectionsContent />
    </div>
  );
};

export default withLayout<PageProps>(ConnectionsPage);
