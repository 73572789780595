import React, { FC } from "react";
import BlankButton from "../../../../../../../../components/common/buttons/BlankButton";
import TextWithIcon from "../../../../../../../../components/common/table/TextWithIcon";
import PlusIcon from "../../../../../../../../components/icons/PlusIcon";
import { ACCENT } from "../../../../../../../../helpers/common/colorAliases";
import styles from "./ConnectionControls.module.scss";

type Props = {
  type?: string;
  onChange: (type: string) => void;
};

const ConnectionControls: FC<Props> = ({ type, onChange }) => {
  const list = type ? BUTTONS_MAP.filter((el) => el.id === type) : BUTTONS_MAP;
  return (
    <div className={styles.wrapper}>
      {list.map((btn) => (
        <BlankButton
          key={btn.id}
          onClick={() => onChange(btn.id)}
          id={btn.id}
          className={styles.btn}
        >
          <TextWithIcon icon={<PlusIcon color={ACCENT} />} text={btn.text} />
        </BlankButton>
      ))}
    </div>
  );
};

export default ConnectionControls;

const BUTTONS_MAP = [
  { id: "cloudRouter", text: "Add port with Cloud Router" },
  { id: "elan", text: "Add port with ELAN" },
];
