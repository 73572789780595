import React from "react";
import { SETTINGS } from "./common/constantsAlias";
import RowControls from "../components/common/table/RowControls";
import EditButton from "../components/common/buttons/EditButton";
import IconBlankButton from "../components/common/buttons/IconBlankButton";
import DuplicateIcon from "../components/icons/DuplicateIcon";
import DeleteButton from "../components/common/buttons/DeleteButton";
import SettingsIcon from "../components/icons/SettingsIcon";
import SearchIconSmall from "../components/icons/SearchIconSmall";
import PieChartIcon from "../components/icons/PieChartIcon";

type RowControls = {
  onEdit?: (rowOriginal?: any) => void;
  onDelete?: (rowOriginal?: any) => void;
  onDuplicate?: (rowOriginal?: any) => void;
  onSetting?: (rowOriginal?: any) => void;
  onPieChart?: (rowOriginal?: any) => void;
  onDiveIn?: (rowOriginal?: any) => void;
  disable?: {
    onEdit?: boolean;
    onDiveIn?: boolean;
    onDelete?: boolean;
    onDuplicate?: boolean;
    onSetting?: boolean;
    onPieChart?: boolean;
  };
};

export default function getRowControls({
  onEdit,
  onDiveIn,
  onDelete,
  onDuplicate,
  onSetting,
  onPieChart,
  disable,
}: RowControls): {
  id: string;
  Header: string;
  accessor: string;
  Cell: ({ row }: any) => JSX.Element | "";
} {
  return {
    id: SETTINGS,
    Header: "",
    accessor: SETTINGS,
    Cell: ({ row }: any) => {
      if (!row.state.isIconShown) {
        return "";
      }
      return (
        <RowControls>
          {onEdit && (
            <EditButton
              id={`rowControls-${row.id}`}
              title={"Edit"}
              onClick={(e) => {
                e.stopPropagation();
                onEdit(row.original);
              }}
              disabled={disable?.onEdit}
            />
          )}
          {onDuplicate && (
            <IconBlankButton
              id={`rowControls-Duplicate-${row.id}`}
              title={"Duplicate"}
              onClick={(e) => {
                e.stopPropagation();
                onDuplicate();
              }}
              icon={DuplicateIcon}
              disabled={disable?.onDuplicate}
            />
          )}
          {onSetting && (
            <IconBlankButton
              id={`rowControls-Settings-${row.id}`}
              title={"Settings"}
              onClick={(e) => {
                e.stopPropagation();
                onSetting(row.original);
              }}
              icon={SettingsIcon}
              disabled={disable?.onSetting}
            />
          )}
          {onDiveIn && (
            <IconBlankButton
              id={`rowControls-DiveIn-${row.id}`}
              title={"Dive in"}
              onClick={(e) => {
                e.stopPropagation();
                onDiveIn(row.original);
              }}
              icon={SearchIconSmall}
              disabled={disable?.onDiveIn}
            />
          )}
          {onDelete && (
            <DeleteButton
              id={`rowControls-Delete-${row.id}`}
              title={"Delete"}
              onClick={(e) => {
                e.stopPropagation();
                onDelete(row.original);
              }}
              disabled={disable?.onDelete}
            />
          )}
          {onPieChart && (
            <IconBlankButton
              id={`rowControls-onPie-${row.id}`}
              onClick={(e) => {
                e.stopPropagation();
                onPieChart(row.original);
              }}
              icon={PieChartIcon}
              disabled={disable?.onPieChart}
            />
          )}
        </RowControls>
      );
    },
  };
}
