import React, { FC, useEffect } from "react";
import Input from "../../../../../../../../components/common/formComponents/Input";
import FormFooter from "../../../common/FormFooter/FormFooter";
import RadioGroup from "../../../../../../../../components/common/formComponents/RadioGroup";
import FormTitle from "../../../common/FormTitle/FormTitle";
import { useConnectionsContext } from "../../../../../ConnectionsContext";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "../InternetForm.module.scss";
import SegmentsTabsComponent from "../../../common/SegmentsTabsComponent";
import { useGlobalFilterContext } from "../../../../../../../../contexts/GlobalFilterContext";

const CONNECTION_TYPES = [
  { value: "workloadsToInternet", label: "Secure workloads to internet" },
  { value: "internetToWorkloads", label: "Secure internet to workloads" },
];

type Props = {
  connectionType: string;
  setConnectionType: (val: string) => void;
};

const EditWorkloadsToInternet: FC<Props> = ({
  connectionType,
  setConnectionType,
}) => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    activeConnection,
    fetchInternetConnection,
    internetSegments,
  } = useConnectionsContext();

  useEffect(() => {
    if (selectedTenant) fetchInternetConnection(selectedTenant);
  }, [activeConnection, selectedTenant]);

  return (
    <>
      <FormTitle />
      <div className={styles.formContainer}>
        <Input
          value={activeConnection?.name}
          label="Name"
          onChange={() => undefined}
          isFullValue
          medium
        />
        <RadioGroup
          label={"Connection Type"}
          options={CONNECTION_TYPES}
          value={connectionType}
          setActiveValue={(val: string) => setConnectionType(val)}
        />
        {internetSegments && (
          <SegmentsTabsComponent
            list={internetSegments}
            onChange={() => undefined}
          />
        )}
      </div>
      <FormFooter onAdd={() => undefined} disabled={{ onAdd: true }} />
    </>
  );
};
export default EditWorkloadsToInternet;
