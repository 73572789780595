import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

export default function PBMIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.1565 9.84108L18.2522 22.0189C17.7348 23.2717 16.8217 24 15.8174 24C14.9957 24 14.2957 23.5921 13.9 22.9512C13.4739 22.1937 13.5043 21.2614 14.0217 20.4748L21.7522 8.61746C22.1478 8.03479 22.9391 7.83086 23.5783 8.15133C24.2174 8.4718 24.4609 9.20014 24.1565 9.84108ZM26.2261 13.3662C25.7696 12.9001 25.0087 12.871 24.4913 13.308C24.0043 13.745 23.9739 14.4733 24.4304 14.9686C26.4391 17.0662 27.5652 19.8048 27.5652 22.689C27.5652 23.3299 28.113 23.8543 28.7826 23.8543C29.4522 23.8543 30 23.3299 30 22.689C30 19.2221 28.6609 15.9009 26.2261 13.3662ZM16.9739 11.4434C17.6435 11.5017 18.2217 11.0356 18.2826 10.3946C18.3435 9.75367 17.8565 9.20014 17.187 9.14187C16.7913 9.11274 16.3957 9.0836 16 9.0836C8.26957 9.0836 2 15.2017 2 22.7181C2 23.3591 2.54783 23.8835 3.21739 23.8835C3.88696 23.8835 4.43478 23.3591 4.43478 22.7181C4.43478 16.4835 9.6087 11.4143 16 11.4143C16.3348 11.3852 16.6696 11.4143 16.9739 11.4434Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}
