import { VtepVni } from "./../../pages/VNIPage/types";
import Validator from "./Validator";

export default function validateVNI(
  data: Partial<VtepVni>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.vni_id, "vni_id");
  validator.checkEmpty(data.vtep_id, "vtep_id");

  return validator;
}
