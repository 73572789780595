import React, { FC, useEffect, useRef } from "react";
import PortsUnitZero from "./PortsUnitZero";
import PortsUnitOne from "./PortsUnitOne";
import {
  ARRAY_LENGTH,
  COL_GAP,
  OFFSET_X,
  OFFSET_Y,
  PORT_HEIGHT,
  PORT_ROWS,
  PORT_WIDTH,
} from "./consts";
import { ALLOWED_SYSTEM_TYPE_2, usePortsContext } from "../../../PortsContext";
import { PageLoader } from "../../../../../components/common/loadStates/LoaderIcon";
import ReactTooltip from "react-tooltip";
import styles from "./Scheme.module.scss";
import PortsDell from "./PortsDell";

const WIDTH =
  PORT_WIDTH * (ARRAY_LENGTH + 1) + COL_GAP * (ARRAY_LENGTH - 1) + 120;
const HEIGHT = PORT_HEIGHT * PORT_ROWS.length + OFFSET_Y * 2;
const DELL_HEIGHT = PORT_HEIGHT + OFFSET_Y;

const Scheme: FC = () => {
  const { schemePortsList, tooltip, systemType } = usePortsContext();
  if (!schemePortsList) return <PageLoader />;

  const isDellType =
    systemType?.toLowerCase() === ALLOWED_SYSTEM_TYPE_2.toLowerCase();

  const tooltipRef = useRef(null);

  useEffect(() => {
    if (tooltipRef.current) {
      ReactTooltip.rebuild();
    }
  }, [tooltip]);

  return (
    <>
      <svg
        width={WIDTH}
        height={isDellType ? DELL_HEIGHT : HEIGHT}
        viewBox={`0 0 ${WIDTH} ${isDellType ? DELL_HEIGHT : HEIGHT}`}
        data-tip
        data-for={"portScheme"}
      >
        {isDellType ? (
          <PortsDell x={0} y={0} list={schemePortsList} />
        ) : (
          <>
            <PortsUnitZero
              x={WIDTH - (PORT_WIDTH + 120)}
              y={0}
              list={schemePortsList}
            />
            <PortsUnitOne x={0} y={0} list={schemePortsList} />
          </>
        )}
      </svg>
      {tooltip && (
        <ReactTooltip
          ref={tooltipRef}
          id={"portScheme"}
          aria-haspopup="true"
          place="bottom"
          type="light"
          effect="float"
          arrowColor="transparent"
          isCapture={true}
          className={styles.tooltip}
        >
          {tooltip.value}
        </ReactTooltip>
      )}
    </>
  );
};

export default Scheme;
