import { FC } from "react";
import Section from "../../../../../../../components/common/Section";
import Button from "../../../../../../../components/common/buttons/Button";
import PlusIcon from "../../../../../../../components/icons/PlusIcon";
import { ACCENT } from "../../../../../../../helpers/common/colorAliases";
import NodeConfig from "./NodeConfig";
import styles from "./NodeSection.module.scss";
import {
  ChangeField,
  FieldsType,
} from "../../../../../../ZayoPages/Connections/ConnectionConfigurator/types";
import { DEFAULT_NODE_FIELDS } from "../../SystemConfigAdd";

type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  errors?: { [key: string]: string };
};

const NodesSection: FC<Props> = ({ className, fields, onChange, errors }) => {
  const handleChangeFields = (idx: number) => (
    field: keyof FieldsType,
    value: any
  ) => {
    const nodes: Array<FieldsType> = [...fields.nodes];
    nodes[idx][field] = value;
    onChange("nodes", nodes);
  };
  const handleAddNode = () => {
    const nodes = [...fields.nodes, { ...DEFAULT_NODE_FIELDS }];
    onChange("nodes", nodes);
  };
  const handleDeleteNode = (idx: number) => {
    const nodes = fields.nodes.filter((n: FieldsType, i: number) => i !== idx);
    onChange("nodes", nodes);
  };
  return (
    <Section title={"Nodes"} columnsCount={1} className={className}>
      {fields.nodes.map((node: FieldsType, i: number) => (
        <NodeConfig
          idx={i}
          fields={node}
          onChange={handleChangeFields(i)}
          onDelete={() => handleDeleteNode(i)}
          errors={errors}
        />
      ))}

      <Button
        onClick={() => handleAddNode()}
        className={styles.button}
        disabled={fields.nodes.length > 1}
      >
        <PlusIcon color={ACCENT} />
        <span>HA Node</span>
      </Button>
    </Section>
  );
};
export default NodesSection;
