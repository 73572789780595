import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LockIcon({ indicatorColor }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79943 5.06636C5.79943 2.76718 7.66329 0.90332 9.96247 0.90332C12.2617 0.90332 14.1255 2.76718 14.1255 5.06636V8.28375H16.3429C17.4475 8.28375 18.3429 9.17918 18.3429 10.2838V17.7729C18.3429 18.8775 17.4475 19.7729 16.3429 19.7729H3.58203C2.47746 19.7729 1.58203 18.8775 1.58203 17.7729V10.2838C1.58203 9.17918 2.47746 8.28375 3.58203 8.28375H5.79943V5.06636ZM12.1255 5.06636V8.28375H7.79943V5.06636C7.79943 3.87175 8.76786 2.90332 9.96247 2.90332C11.1571 2.90332 12.1255 3.87175 12.1255 5.06636ZM16.3429 10.2838H3.58203V17.7729H16.3429V10.2838ZM9.96251 15.6098C10.836 15.6098 11.544 14.9018 11.544 14.0283C11.544 13.1549 10.836 12.4468 9.96251 12.4468C9.08906 12.4468 8.38099 13.1549 8.38099 14.0283C8.38099 14.9018 9.08906 15.6098 9.96251 15.6098Z"
        fill={indicatorColor || FONT}
      />
    </svg>
  );
}

export default LockIcon;
