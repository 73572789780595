import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { FONT } from "../../helpers/common/colorAliases";

function EmDashIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3"
        y1="10"
        x2="15"
        y2="10"
        stroke={color || FONT}
        strokeWidth="2"
      />
    </svg>
  );
}

export default EmDashIcon;
