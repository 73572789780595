import { IconProps } from "./types";
import { SVGAttributes } from "react";

export function formIconAttrs(
  defW: number,
  defH: number,
  { x, y, width, height }: IconProps
): SVGAttributes<any> {
  const w = width || defW;
  const h = height || defH;
  return {
    width: w,
    height: h,
    x: x ? x - w / 2 : undefined,
    y: y ? y - h / 2 : undefined,
  };
}
