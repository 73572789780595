export const getPortBodyColor = (
  status?: string,
  withSubPorts?: boolean
): [string, string] => {
  if (!status || withSubPorts) return ["rgba(239, 233, 251, 0.40)", "#D8DAEB"];
  switch (status.toLocaleLowerCase()) {
    case "up":
      return ["rgba(239, 233, 251, 0.40)", "#D8DAEB"];
    case "down":
      return ["#fde4ec", "#ffc6d4"];
    default:
      return ["#fde8de", "#ffcfb3"];
  }
};
