import Validator from "./Validator";

export default function validateLogin(
  data: {
    username: string;
    password: string;
    newPassword?: string;
    confirmPassword?: string;
  },
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.username, "username");
  validator.checkLength(data.username, [1, 40], "username");
  validator.checkEmpty(data.password, "password");
  validator.checkLength(data.password, [1, 40], "password");
  if (data.newPassword && data.confirmPassword) {
    validator.checkEqualPasswords(
      data.newPassword,
      data.confirmPassword,
      "newPassword"
    );
  }
  return validator;
}
