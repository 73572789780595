import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AddButton from "../../components/common/buttons/AddButton";
import Input from "../../components/common/formComponents/Input";
import TextWithIcon from "../../components/common/table/TextWithIcon";
import AwardIcon from "../../components/icons/AwardIcon";
import CloseIcon from "../../components/icons/CloseIcon";
import { withDialogLayout } from "../../helpers/hocs/withDialogLayout";
import { useEnterLicenseKeyContext } from "./context/EnterLicenseKeyPageContext";
import { LicenseKey } from "./domain";

import styles from "./EnterLicenseKeyPage.module.scss";

const EnterLicenseKeyPage = () => {
  const { enterLicenseKey, licenseKeyState } = useEnterLicenseKeyContext();

  const history = useHistory();

  const [licenseKey, setLicenseKey] = useState<LicenseKey>("");

  const sendLicenseKey = async () => {
    const isWithNsos = licenseKey.startsWith("nsos-");
    const value = isWithNsos ? licenseKey : "nsos-" + licenseKey;

    const res = await enterLicenseKey(value);

    if (res.ok) {
      return exitPage();
    }
  };

  const exitPage = () => {
    history.push("/");
  };

  return (
    <div className={styles.dialog}>
      <CloseIcon
        height={18}
        width={16}
        className={styles.dialogCloseBtn}
        color="currentColor"
        onClick={exitPage}
      />
      <TextWithIcon
        className={styles.dialogHeader}
        icon={<AwardIcon />}
        text={"Enter License Key"}
      />
      <Input
        value={licenseKey}
        onChange={(e) => setLicenseKey(e.target.value)}
        name="licenseKey"
        isPlaceholderAlwaysVisible
        placeholder="nsos-"
        inputExternalClass={styles.inputKeyField}
        error={licenseKeyState.error}
      />
      <div className={styles.dialogFooter}>
        <p className={styles.helpText}>
          Need help? Please contact{" "}
          <a href="mailto:support@insidepacket.com" target={"_blank"}>
            support@insidepacket.com
          </a>
        </p>
        <AddButton
          label="Set License"
          isTextOnly
          onClick={sendLicenseKey}
          className={styles.setKeyBtn}
        />
      </div>
    </div>
  );
};

export default withDialogLayout(EnterLicenseKeyPage, styles.dialogFrame);
