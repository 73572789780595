import React from "react";
import OuterFrameIcon from "../../../../components/icons/OuterFrameIcon";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { VirtualInterfaceApi } from "../../../VirtualInterfacePage/types";
import styles from "./RemoteConfigButton.module.scss";

type Props = {
  vi: VirtualInterfaceApi;
};

export const RemoteConfigButton = ({ vi }: Props) => {
  const { selectedTenant } = useGlobalFilterContext();

  const isVisible = vi.type === "Virtual Interface IPSEC";
  return isVisible ? (
    <a
      className={styles.button}
      href={`../../remote_config/${selectedTenant}/${vi.id}`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <OuterFrameIcon /> <span>Show remote config</span>
    </a>
  ) : (
    <></>
  );
};
