/* eslint-disable prettier/prettier */
import React, { FC, ReactNode, useState } from "react";

import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { configApi } from "../../../helpers/api/ConfigApi";
import { elasticApi } from "../../../helpers/api/ElasticApi";
import { RequestStatus } from "../../../helpers/types";
import {
  ConfigurationPreview,
  convertElasticToTable,
  HistoryLogCell,
} from "../domain";
import { GTimeRange } from "../../../components/common/charts/GraphanaLinksFactory";

type IFunc = {
  fetchEvents: (
    isAdmin: boolean,
    tenant: string,
    timeRange: GTimeRange
  ) => void;
  setConfigurationPreview: (preview: ConfigurationPreview | null) => void;
  fetchConfiguration: (event: HistoryLogCell) => void;
  selectEvent: (event: HistoryLogCell) => void;
};

type IState = {
  events: any;
  eventsRequestStatus: RequestStatus;
  configurationPreview: ConfigurationPreview;
  configurationRequestStatus: RequestStatus;
  selectedEvent: HistoryLogCell;
};

type Props = {
  children: ReactNode;
};

const [HistoryLogPageContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useHistoryLogPageContext = useContext;

export const HistoryLogPageContextContainer: FC<Props> = ({ children }) => {
  const [events, setEvents] = useState<Array<HistoryLogCell>>([]);
  const [
    configurationPreview,
    setConfigurationPreview,
  ] = useState<ConfigurationPreview | null>();
  const [selectedEvent, setSelectedEvent] = useState<HistoryLogCell | null>();
  const [eventsRequestStatus, setEventsRequestStatus] = useState<RequestStatus>(
    { state: "idle" }
  );
  const [
    configurationRequestStatus,
    setConfigurationRequestStatus,
  ] = useState<RequestStatus>({ state: "idle" });

  const fetchEvents = async (
    isAdmin: boolean,
    tenant: string,
    timeRange: GTimeRange
  ) => {
    setEventsRequestStatus({ state: "pending" });

    const { ok, result, error } = await elasticApi.getHistoryLog(
      tenant,
      timeRange
    );

    if (!ok || !result) {
      setEventsRequestStatus({ state: "error", message: error });
      return;
    }

    setEventsRequestStatus({ state: "ok" });
    const hits = result.hits.hits;

    setEvents(convertElasticToTable(hits));
  };

  const fetchConfiguration = async (event: HistoryLogCell) => {
    setConfigurationPreview(undefined);
    setConfigurationRequestStatus({ state: "pending" });
    const { ok, result, error } = await configApi.getConfigurationByTenant(
      event.name
    );

    if (!ok) {
      setConfigurationRequestStatus({ state: "error", message: error });
      return;
    }
    setConfigurationPreview(result);

    setConfigurationRequestStatus({ state: "ok" });
  };

  const selectEvent = (event: HistoryLogCell) => {
    setSelectedEvent(event);
  };

  return (
    <HistoryLogPageContext.Provider
      value={{
        fetchEvents,
        setConfigurationPreview,
        fetchConfiguration,
        selectEvent,
        events,
        eventsRequestStatus,
        configurationPreview,
        configurationRequestStatus,
        selectedEvent,
      }}
    >
      {children}
    </HistoryLogPageContext.Provider>
  );
};
