import React, {
  FC,
  useRef,
  useState,
  useEffect,
  useMemo,
  Children,
} from "react";
import { classNames } from "../../../helpers/common/classNames";
import CloseIcon from "../../icons/CloseIcon";
import styles from "./Label.module.scss";
import ReactTooltip from "react-tooltip";

type LabelProps = {
  className?: string;
  isOutlined?: boolean;
  onClick?: (arg: string) => void;
  isRemovable?: boolean;
  tooltipId?: string;
};

const Label: FC<LabelProps> = ({
  children,
  className,
  isOutlined,
  onClick = (f) => f,
  isRemovable,
  tooltipId,
}) => {
  const isOneChild = Children.toArray(children).length === 1;
  const labelStyles = classNames(
    className,
    styles.wrapper,
    isOneChild && styles.centered,
    isOutlined && styles.outlined,
    isRemovable && styles.clickable
  );
  const key = useMemo(() => tooltipId || "" + Date.now(), [tooltipId]);

  const ref = useRef<HTMLDivElement>(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current;
    setIsOverflown((element?.scrollWidth || 0) > (element?.clientWidth || 0));
  }, [ref?.current?.clientWidth]);

  return (
    <div
      data-tip
      data-for={key}
      className={labelStyles}
      onClick={() => children && onClick(children.toString())}
    >
      <span className={classNames(isRemovable && styles.content)} ref={ref}>
        {children}
      </span>
      {isRemovable && <CloseIcon className={styles.closeIcon} />}
      <ReactTooltip
        className={styles.tooltip}
        disable={!isOverflown}
        id={key}
        aria-haspopup="true"
        place="bottom"
        type="light"
      >
        {children}
      </ReactTooltip>
    </div>
  );
};

export default Label;
