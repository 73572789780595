import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../../contexts/common/AbstractDialogContext";

import { createContextUtils } from "../../../../contexts/common/utils";
import { configApi } from "../../../../helpers/api/ConfigApi";
import { tenantApi } from "../../../../helpers/api/TenantApi";
import { tenantVirtualInterfacesApi } from "../../../../helpers/api/TenantVirtualInterfaceApi/TenantVirtualInterfacesApi";
import {
  Layer3Interface,
  Layer3InterfaceCreate,
} from "../../../Layer3Interface/types";
import { TenantApi } from "../../../TenantsPage/types";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<Layer3InterfaceCreate>;

type IState = State & RequestStatuses & { tenant: TenantApi };
type IFunc = DialogFunc<Layer3InterfaceCreate> & {
  editIP: (
    params: any,
    vi: Layer3Interface,
    tenant: string
  ) => Promise<boolean>;
  fetchTenant: (tenant: string) => Promise<void>;
};

const [
  Layer3InterfaceDialogContext,
  useLayer3InterfaceDialogContext,
] = createContextUtils<IState, IFunc>();
export { useLayer3InterfaceDialogContext };

class Layer3InterfaceDialogContextContainer extends AbstractDialogContextContainer<
  Layer3InterfaceCreate,
  IState,
  RequestStatuses,
  IFunc
> {
  Context = Layer3InterfaceDialogContext;

  _updateSystems = (ok: boolean) => {
    if (ok) {
      // this.props.fetchList();
    }
  };

  add = async (vi: Partial<any>): Promise<boolean> => {
    const { tenant, ...rest } = vi;

    if (vi.virtual_interface_type === "gateway") {
      return this._addGateway(vi);
    } else {
      const { ok } = await this._addWrap(() =>
        tenantApi.addVirtualInterfaces(rest, tenant)
      );
      this._updateSystems(ok);

      return ok;
    }
  };

  _addGateway = async (vi: Partial<any>): Promise<boolean> => {
    const gatewayBody = {
      name: vi.name,
      description: vi.description,
      virtual_interface_bridge_domain_name: vi.nat_virtual_interface_name,
      is_snat_service: true,
      is_nat_service: true,
      is_ipsec_service: false,
      is_lb_service: false,
    };
    const { ok } = await this._addWrap(() =>
      tenantVirtualInterfacesApi.addVirtualInterfaceGate(vi.tenant, gatewayBody)
    );
    this._updateSystems(ok);

    return ok;
  };

  editIP = async (
    params: any,
    vi: Layer3Interface,
    tenant: string
  ): Promise<boolean> => {
    const { ok } = await this._editWrap(() =>
      tenantApi.editVirtualInterfaces(tenant, vi.name, params)
    );
    this._updateSystems(ok);
    return ok;
  };

  fetchTenant = async (tenant: string): Promise<void> => {
    const res = await tenantApi.getTenant(tenant);
    if (res?.ok && res?.result) {
      this.setState({ tenant: res?.result });
    }
  };

  // todo not implemented
  edit = async (vi: Partial<Layer3Interface>): Promise<boolean> => {
    const { ok } = await this._editWrap(() => configApi.editPortVLAN());
    this._updateSystems(ok);
    return ok;
  };

  funcs = {
    editIP: this.editIP,
    edit: this.edit,
    add: this.add,
    fetchTenant: this.fetchTenant,
  };
}

export default Layer3InterfaceDialogContextContainer;
