import React, { FC, ReactElement } from "react";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";
import ArrowUp from "../../../../../components/icons/ArrowUp";
import ArrowDown from "../../../../../components/icons/ArrowDown";
import { classNames } from "../../../../../helpers/common/classNames";

// eslint-disable-next-line css-modules/no-unused-class
import styles from "./TotalTraffic.module.scss";

const TotalTraffic: FC = () => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>Total Traffic</span>
      <TrafficItem
        status={"up"}
        value={{ type: "Incoming", number: "10.5GB" }}
        stat={{ type: "up", number: "12", message: "Higher than last week" }}
      />
      <TrafficItem
        status={"down"}
        value={{ type: "Outgoing", number: "10.5GB" }}
        stat={{ type: "down", number: "12", message: "Lower than last week" }}
      />
    </div>
  );
};
export default TotalTraffic;

const TrafficItem: FC<{
  status: string;
  value: { type: string; number: string };
  stat: { type: string; number: string; message: string };
}> = ({ status, value, stat }) => {
  return (
    <div className={styles.itemWrapper}>
      <div className={styles.value}>
        <span className={styles.icon}>
          <StatusIcon color={getColorFromStatus(status)} />
        </span>
        <span className={styles.valueNumber}>{value.number}</span>
        <span className={styles.msg}>{value.type}</span>
      </div>
      <div className={classNames(styles.stat, styles[stat.type])}>
        <span className={styles.icon}>{getIcomFromType(stat.type)}</span>
        <span className={styles.statNumber}>{stat.number}</span>
        <span className={styles.statMessage}>{stat.message}</span>
      </div>
    </div>
  );
};

const getIcomFromType = (type: string): ReactElement => {
  switch (type) {
    case "up":
      return <ArrowUp color={getColorFromStatus(type)} />;
    case "down":
    default:
      return <ArrowDown color={getColorFromStatus(type)} />;
  }
};
