import { FC } from "react";
import styles from "./Footer.module.scss";
import { useWebFilteringsContext } from "../../../WebFilteringContext";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";
import { FQDNDefaultAction, WebFilteringTab } from "../../../types";
import DialogBtmButtons from "../../../../../components/dialogs/common/DialogBtmButtons";

type Props = { activeTab: WebFilteringTab };

const Footer: FC<Props> = ({ activeTab }) => {
  const { selectedTenant } = useGlobalFilterContext();
  const { resetAll, add, addRequest } = useWebFilteringsContext();

  const handleReset = () => {
    if (selectedTenant) {
      resetAll(selectedTenant);
    }
  };

  const handleAdd = () => {
    if (selectedTenant) {
      add(selectedTenant, activeTab.id as FQDNDefaultAction);
    }
  };

  return (
    <div className={styles.footer}>
      <DialogBtmButtons
        className={styles.controls}
        controls={{
          okText: "Apply",
          onOk: handleAdd,
          cancelText: "Cancel",
          onCancel: handleReset,
        }}
        errorDisplay={addRequest}
      />
    </div>
  );
};

export default Footer;
