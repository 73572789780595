import React, { FC } from "react";
import styles from "./SummaryInformation.module.scss";
import { classNames } from "../../helpers/common/classNames";

type SummaryInfoBlockType = {
  className: string;
};

const SummaryInformation: FC<SummaryInfoBlockType> = ({
  className,
  children,
}) => {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
};

export default SummaryInformation;
