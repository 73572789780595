import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";

function GeoTagIcon(): ReactElement {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99843 13.9141C6.09913 11.1333 3.99988 7.60761 3.99988 5.60885C3.99988 3.75429 4.80902 2.07815 6.11219 0.87793C3.75016 1.29736 1.95654 3.36093 1.95654 5.84342C1.95654 8.62879 6.9999 15.2997 6.9999 15.2997C6.9999 15.2997 7.41099 14.756 7.99843 13.9141Z"
        fill={GREY_FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9999 15.2995C10.9999 15.2995 16.0433 8.62853 16.0433 5.84316C16.0433 3.05779 13.7853 0.799805 10.9999 0.799805C8.21453 0.799805 5.95654 3.05779 5.95654 5.84316C5.95654 8.62853 10.9999 15.2995 10.9999 15.2995ZM10.9998 7.73467C12.0443 7.73467 12.891 6.88792 12.891 5.84341C12.891 4.79889 12.0443 3.95215 10.9998 3.95215C9.95527 3.95215 9.10852 4.79889 9.10852 5.84341C9.10852 6.88792 9.95527 7.73467 10.9998 7.73467Z"
        fill={GREY_FONT}
      />
    </svg>
  );
}

export default GeoTagIcon;
