import React from "react";
import Button from "../../../../components/common/buttons/Button";
import LoaderIcon from "../../../../components/common/loadStates/LoaderIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { useIpamContext } from "../../IpamContext";

import styles from "./DialogFooter.module.scss";

type Props = {
  className?: string;
  onAdd: () => void;
  onCancel: () => void;
};

export const DialogFooter = ({ className, onAdd, onCancel }: Props) => {
  const { ipamCreateStatus } = useIpamContext();

  const ipamIsAdding = ipamCreateStatus.state === "pending";

  const addBtnContent = ipamIsAdding ? <LoaderIcon height={16} /> : "Add";

  const error =
    ipamCreateStatus.state === "error" ? ipamCreateStatus.message : undefined;

  return (
    <div className={classNames(styles.dialogFooter, className)}>
      {error}
      <Button onClick={onCancel} className={styles.btn}>
        Cancel
      </Button>
      <Button
        className={styles.btn}
        isPrimaryBtn={!ipamIsAdding}
        onClick={onAdd}
        disabled={ipamIsAdding}
      >
        {addBtnContent}
      </Button>
    </div>
  );
};
