import { Res } from "../../../helpers/api/Api";
import { configApi } from "../../../helpers/api/ConfigApi";
import { tenantApi } from "../../../helpers/api/TenantApi";
import { FDQNDomain, FQDNServiceData, TenantFQDNCategory } from "../types";

export const getFQDNData = async (tenant: string): Promise<Res<any>> => {
  const res = await tenantApi.getEnabledServices(tenant);
  if (!res.ok) return res;
  const fqdn: FQDNServiceData | undefined = res.result?.services.find(
    (s) => s.type === "fqdn"
  )?.data as FQDNServiceData;
  const categories = await getCategories(fqdn?.categories);
  const domainsList = getDomains(fqdn);
  const defaultAction = fqdn.default_action;
  return { ...res, result: { ...categories, domainsList, defaultAction } };
};

const getCategories = async (
  currentList: Array<string>
): Promise<
  | {
      contentFilterCategories?: Array<TenantFQDNCategory>;
      webThreatsFilterCategories?: Array<TenantFQDNCategory>;
    }
  | undefined
> => {
  const { ok, result } = await configApi.getFQDNCategoriesList();
  if (!ok) return;

  const res = result?.reduce(
    (acc, el) => {
      const isChecked = currentList.includes(el.name);
      if (el.category_type === "content_filter") {
        acc.contentFilterCategories.push({ ...el, isChecked });
        return acc;
      }
      acc.webThreatsFilterCategories.push({ ...el, isChecked });
      return acc;
    },
    {
      contentFilterCategories: [] as Array<TenantFQDNCategory>,
      webThreatsFilterCategories: [] as Array<TenantFQDNCategory>,
    }
  );
  return res;
};

const getDomains = (data?: FQDNServiceData): Array<FDQNDomain> => {
  if (!data) return [];
  const allowList: Array<FDQNDomain> = data.allowed_domains.map((d, i) => ({
    domain: d,
    type: "allow",
    id: i,
  }));
  const blockList: Array<FDQNDomain> = data.denied_domains.map((d, i) => ({
    domain: d,
    type: "block",
    id: i,
  }));
  return [...allowList, ...blockList];
};
