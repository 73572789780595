import React from "react";
import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../../contexts/common/utils";

import { PortTypeScheme } from "../types";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  resToState,
  setPending,
} from "../../../helpers/common/RequestStateHelpers";
import { OK_STATUS } from "../../../components/const/api";
import { RequestStatus } from "../../../helpers/types";

type State = {
  list_of_lag?: Array<string>;
  list_of_ports?: Array<string>;
  free_ports_list?: Array<string>;
  unassignedPorts?: Array<string>;
  filteredLists?: {
    basic: Array<PortTypeScheme>;
    data: Array<PortTypeScheme>;
    lag: Array<PortTypeScheme>;
    backplane: Array<PortTypeScheme>;
  };
  tableStatus?: RequestStatus;
};

type IState = State;
type IFunc = {
  fetchLagList: (system: string, lag_name?: string) => Promise<void>;
  addLAG: (system: string, body: any) => Promise<void>;
  addPortToLAG: (
    system: string,
    ports: Array<string>,
    lag_name?: string
  ) => Promise<void>;
  removePortFromLAG: (system: string, ports: Array<string>) => Promise<void>;
};

const [InterfacesContext, useContext] = createContextAndUse<IState, IFunc>();
export const useInterfacesContext = useContext;

export default class InterfacesContextContainer extends AbstractContextProvider<
  IState,
  State,
  IFunc
> {
  Context = InterfacesContext;

  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchLagList = async (system: string, lagName?: string): Promise<void> => {
    const { ok, result } = await configApi.getPortsList(system);
    const listOfLag = result?.filter(
      (el) => el.role === "data" && el.name.startsWith("lag")
    );
    const listOfPortsLag = result?.filter(
      (el) => el.role === "lag" && el.lag_name === lagName
    );
    const freePortsToAssign = result?.filter(
      (el) => el.role === "data" && el.name.startsWith("hge") && !el.lag_name
    );

    if (listOfLag) {
      const listOfLagNames = listOfLag.map((lag) => lag.name);
      this.setState({ list_of_lag: listOfLagNames });
    }
    if (listOfPortsLag) {
      const listOfPortLagNames = listOfPortsLag.map((port) => port.name);
      this.setState({ list_of_ports: listOfPortLagNames });
    }

    if (freePortsToAssign) {
      const freePortsToAssignNames = freePortsToAssign.map((port) => port.name);
      this.setState({ free_ports_list: freePortsToAssignNames });
    }
  };

  addLAG = async (
    system: string,
    body: { min_ports: number; lag_type: string }
  ): Promise<void> => {
    this.setState({ tableStatus: setPending() });

    if (!system) return;
    const createLAGRes = await configApi.createLAG(system, body);
    this.setState({ tableStatus: resToState(createLAGRes) });
  };

  addPortToLAG = async (
    system: string,
    ports: Array<string>,
    lag_name?: string
  ): Promise<void> => {
    if (!system || !ports) return;
    this.setState({ tableStatus: setPending() });
    if (!lag_name) {
      lag_name = `lag${(this.state.list_of_lag?.length || 0) + 1}`;
    }
    const assignPortsToLag = async (lagName: string) => {
      for (const port of ports) {
        await configApi.assignPortToLag(system, port, lagName);
      }
    };
    await assignPortsToLag(lag_name);
    this.setState({ tableStatus: OK_STATUS });
    this.fetchLagList(system, lag_name);
  };

  removePortFromLAG = async (
    system: string,
    ports: Array<string>
  ): Promise<void> => {
    if (!system) return;
    if (ports) {
      this.setState({ tableStatus: setPending() });
      for (const port of ports) {
        await configApi.removePortFromLag(system, port);
      }
    }
    this.setState({ tableStatus: OK_STATUS });
    this.fetchLagList(system);
  };

  funcs = {
    fetchLagList: this.fetchLagList,
    addLAG: this.addLAG,
    addPortToLAG: this.addPortToLAG,
    removePortFromLAG: this.removePortFromLAG,
  };
}
