import React, { FC, ReactElement } from "react";
import { useOneSystemContext } from "../../../../contexts/systemsContext/OneSystemContext";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";
import StatusIcon from "../../../icons/StatusIcon";
import SystemIcon from "../../../icons/SystemIcon";
import UnifiedTextPair from "../../../common/UnifiedTextPair";
import TextWithIcon from "../../../common/table/TextWithIcon";
import SummaryInformation from "../../../leftInfoBlock/SummaryInformation";
import ParametersLines from "../../../common/ParametersLines";
import TitleWithIcon from "../../../common/TitleWithIcon";
import SystemControls from "./SystemControls";
import styles from "./SystemInfo.module.scss";
import { useHAHealthContext } from "../../../../contexts/systemsContext/HAHealthContext";

const SystemInfo: FC = () => {
  const { system } = useOneSystemContext();

  return (
    <>
      <SummaryInformation className={styles.summaryLayout}>
        <TitleWithIcon
          icon={<SystemIcon status={system?.status} />}
          title={system?.name}
        />
        <SystemControls onEdit={() => undefined} />
        <ParametersLines params={getParameters()} />
      </SummaryInformation>
    </>
  );
};
export default SystemInfo;

export const HAHealthInfo: FC<{}> = () => {
  const { system } = useOneSystemContext();
  const { systemFromHA } = useHAHealthContext();
  const activeNode = systemFromHA?.nodes?.find(
    (node: any) => node.role === "active"
  );
  const standbyNode = systemFromHA?.nodes?.find(
    (node: any) => node.role === "standby"
  );
  return (
    <>
      <SummaryInformation className={styles.haLayout}>
        <TitleWithIcon
          icon={<SystemIcon status={system?.status} />}
          title={system?.name}
        />
        <div className={styles.location}> {system?.location} </div>
        <UnifiedTextPair subtitle="Type" text={activeNode?.type} />
        <UnifiedTextPair subtitle="Version" text={activeNode?.version} />
        <UnifiedTextPair
          subtitle="Status"
          text={
            <TextWithIcon
              icon={
                <StatusIcon color={getColorFromStatus(activeNode?.status)} />
              }
              text={activeNode?.status?.toUpperCase()}
            />
          }
        />
        <UnifiedTextPair subtitle="Active node" text={activeNode?.name} />
        <div className={styles.subtitle}>
          {getHealthStatus(activeNode?.health_score)}
        </div>
        {standbyNode?.name && (
          <>
            <UnifiedTextPair
              subtitle="Standby node"
              text={standbyNode?.name || "There is no standby node"}
            />
            <div className={styles.subtitle}>
              {getHealthStatus(standbyNode?.health_score)}
            </div>
          </>
        )}
      </SummaryInformation>
    </>
  );
};

const getHealthStatus = (health_score?: number) => {
  if (health_score && health_score > 90) {
    return (
      <span className={styles.healthyColor}>{`Healthy: ${health_score}`}</span>
    );
  } else {
    return (
      <span
        className={styles.unHealthyColor}
      >{`Unhealthy: ${health_score}`}</span>
    );
  }
};

const getParameters = (): ReactElement => {
  const { node } = useOneSystemContext();
  return (
    <>
      <UnifiedTextPair subtitle="Host IP" text={node?.address} />
      <UnifiedTextPair subtitle="Location" text={node?.location} />
      <UnifiedTextPair subtitle="Type" text={node?.type} />
      <UnifiedTextPair subtitle="Version" text={node?.version} />
      <UnifiedTextPair
        subtitle="Status"
        text={
          <TextWithIcon
            icon={<StatusIcon color={getColorFromStatus(node?.status)} />}
            text={node?.status?.toUpperCase()}
          />
        }
      />
    </>
  );
};
