import { FC } from "react";
import Label from "./Label";
import styles from "./UserRoleLabel.module.scss";

type Props = {
  role: string;
};
const UserRoleLabel: FC<Props> = ({ role }) => {
  const [title, className] = getParamsByRole(role);
  return <Label className={className}>{title}</Label>;
};

export default UserRoleLabel;

const getParamsByRole = (role: string): [string, string] => {
  switch (role.toLowerCase()) {
    case "admin":
      return ["Admin", styles.admin];
    case "tenant_admin":
      return ["Tenant Admin", styles.tenantAdmin];
    case "editor":
      return ["Editor", styles.editor];
    case "remote_user":
      return ["Remote User", styles.remoteUser];
    case "viewer":
    default:
      return ["Viewer", styles.viewer];
  }
};
