import React, { FC, ReactElement } from "react";
import { classNames } from "../../../helpers/common/classNames";
import styles from "./TextWithIcon.module.scss";

type TextWithIconProps = {
  icon: ReactElement;
  text: string | number | null | undefined;
  className?: string;
  iconPosition?: "left" | "right";
  onClick?: () => void;
};

const TextWithIcon: FC<TextWithIconProps> = ({
  icon,
  text,
  className,
  iconPosition = "left",
  onClick,
}) => {
  const wrapperStyles = classNames(
    styles.wrapper,
    className,
    onClick && styles.clickable
  );
  return (
    <div className={wrapperStyles} onClick={() => onClick && onClick()}>
      {iconPosition === "left" ? (
        <>
          {icon}
          {text || "—"}
        </>
      ) : (
        <>
          {text || "—"}
          {icon}
        </>
      )}
    </div>
  );
};

export default TextWithIcon;
