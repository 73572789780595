import React, { ReactElement } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import {
  EVENTS,
  CLOUD_RESOURCES,
  SYSTEM,
  SYSTEMS,
  IPAM,
  MESH_NETWORK,
} from "../../helpers/navigation/entries";
import SystemProvisioningContextContainer from "../../pages/Systems/Provisioning/ProvisioningContextContainer";
import Provisioning from "../../pages/Systems/Provisioning";
import { SystemsTabs } from "../../components/common/navigation/SystemsTabs";
import EventsPage from "../../pages/EventsPage";
import CloudResourcesPage from "../../pages/CloudResourcesPage";
import SystemContainer from "../../components/systems/oneSystem/SystemContainer";
import { useUserContext } from "../../contexts/UserContext";
import MeshNetworkPage from "../../pages/MeshNetworkPage";
import IPAMPage from "../../pages/IPAMPage";

export default function Systems(): ReactElement {
  const { isDemo } = useUserContext();
  const path = useMatchPath();
  return (
    <Switch>
      <SystemProvisioningContextContainer>
        <Route path={path + "/" + SYSTEMS().path}>
          <Provisioning tabs={SystemsTabs} />
        </Route>
        <Route path={path + "/" + IPAM().path}>
          <IPAMPage tabs={SystemsTabs} />
        </Route>
        <Route path={path + "/" + EVENTS().path}>
          <EventsPage tabs={SystemsTabs} type="systems" />
        </Route>
        <Route path={path + "/" + CLOUD_RESOURCES().path}>
          <CloudResourcesPage tabs={SystemsTabs} />
        </Route>
        {isDemo && (
          <Route path={path + "/" + MESH_NETWORK().path}>
            <MeshNetworkPage tabs={SystemsTabs} />
          </Route>
        )}
        <Route path={path + "/" + SYSTEM().path + "_:system"}>
          <SystemContainer />
        </Route>
        <Route exact path={path}>
          <Redirect to={path + "/" + SYSTEMS().path} />
        </Route>
      </SystemProvisioningContextContainer>
    </Switch>
  );
}
