import React, { FC, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AddButton from "../../../../components/common/buttons/AddButton";
import TableWrapper from "../../../../components/common/table/newTable/layout/TableWrapper";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import Table from "../../../../components/common/table/newTable/Table";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import {
  ZAYO_EDIT_CONNECTION,
  NEW_CONNECTION,
} from "../../../../helpers/navigation/entries";
import { useConnectionsContext } from "../ConnectionContext";
import ConnectionDetails from "./components/ConnectionDetails";
import { connectionsTableColumns } from "./table/connectionsTableColumns";
import { Connection } from "../types";
import useDeleteDialog from "../../../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { ActionFuncs } from "../../../../components/common/table/newTable/RowControls";
import { isPending } from "../../../../helpers/common/RequestStateHelpers";

const REFETCH_INTERVAL = 30 * 1000;
let FetchInterval: NodeJS.Timeout | undefined;

let listStatusBeforePending: any = undefined;

const ConnectionsContent: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const history = useHistory();

  const {
    listStatus,
    fetchData,
    list,
    portsDetails,
    remove,
    removeStatus,
    resetRemoveStatus,
  } = useConnectionsContext();

  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<Connection>(remove, removeStatus);

  useEffect(() => {
    fetchWrapper(selectedTenant);
    restartFetchInterval(selectedTenant);
  }, [selectedTenant]);

  if (!isPending(listStatus)) {
    listStatusBeforePending = listStatus;
  }

  let handledStatus = listStatus;
  if (isPending(listStatus) && listStatusBeforePending) {
    handledStatus = listStatusBeforePending;
  }

  const restartFetchInterval = (providedTenant: string | undefined) => {
    FetchInterval && clearInterval(FetchInterval);
    FetchInterval = setInterval(() => {
      fetchWrapper(providedTenant);
    }, REFETCH_INTERVAL);
  };

  useEffect(() => {
    fetchWrapper(selectedTenant);
    restartFetchInterval(selectedTenant);
    return () => {
      FetchInterval && clearInterval(FetchInterval);
    };
  }, []);

  const fetchWrapper = (providedTenant: string | undefined) => {
    if (providedTenant) {
      fetchData(providedTenant);
    }
  };

  const handleDelete = (row: any): void => {
    resetRemoveStatus();
    tryDelete(row, selectedTenant);
  };

  const rowActions: ActionFuncs<Connection> = useMemo(
    () => ({
      onEdit: (row: Connection) => {
        history.push(
          `${location.pathname}/${ZAYO_EDIT_CONNECTION(row.name).path}`
        );
      },
      onDelete: (row: Connection) => {
        handleDelete(row);
      },
    }),
    [tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Connection"}
        name={selectedToDelete?.name}
      />
      <TableWrapper
        titleProps={{
          title: `Connections (${list?.length || 0})`,
          children: (
            <AddButton
              onClick={() => {
                history.push(`${location.pathname}/${NEW_CONNECTION().path}`);
              }}
            />
          ),
        }}
        dataStatus={handledStatus}
        tablePlaceholder={["Connections", "Connection"]}
        isEmpty={!list || list.length === 0}
      >
        <Table
          columns={connectionsTableColumns()}
          data={list}
          header={TableHeader}
          gridColumnTemplate={`40px repeat(6, 1fr) 0`}
          renderRowSubComponent={(row) => (
            <ConnectionDetails
              data={portsDetails[row.name]}
              onDelete={handleDelete}
            />
          )}
          rowActions={rowActions}
          noInitialRowExpand
          isFullRowExpand
          // getRowsIdsFromData
          // keepExpandedRowsAfterRefresh
        />
      </TableWrapper>
    </>
  );
};

export default ConnectionsContent;
