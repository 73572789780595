import React, { FC } from "react";

import styles from "./TrafficLayout.module.scss";
import Filters from "./Filters";
import TrafficTypeSelector from "./TrafficTypeSelector/TrafficTypeSelector";

const TrafficLayout: FC<{ tabs: any }> = ({ tabs: Tabs, children }) => {
  return (
    <>
      <Tabs filter={<Filters />} typeSelector={<TrafficTypeSelector />} />
      <div className={styles.content}>
        {/* <TrafficButtons /> */}
        {children}
      </div>
    </>
  );
};

export default TrafficLayout;
