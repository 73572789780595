import React, { FC, ReactElement } from "react";
import { classNames } from "../../../helpers/common/classNames";
import BlankButton from "../buttons/BlankButton";

import styles from "./ButtonsGroup.module.scss";

export type Tab = {
  name: string;
  icon?: ReactElement;
  disabled?: boolean;
  className?: string;
  activeStyle?: string;
  disableStyle?: string;
  value?: string;
};

type TabsProps<T extends Tab> = {
  className?: string;
  tabs: Array<T>;
  activeTab: string;
  onChange: (tab: T) => void;
  withIcon?: boolean;
  icon?: ReactElement;
  selectedTabClassName?: string;
  withBorder?: boolean;
};

const ButtonsGroup: FC<TabsProps<Tab>> = ({
  tabs,
  activeTab,
  onChange,
  className,
  withIcon,
  selectedTabClassName,
  withBorder,
}) => {
  return (
    <div
      className={classNames(
        withBorder ? styles.container : styles.containerNoBorder,
        className
      )}
    >
      {tabs.map((tab) => {
        return (
          <BlankButton
            id={tab.name}
            key={tab.name}
            className={classNames(
              tab?.className || styles.tab,
              activeTab === tab.name && (tab?.activeStyle || styles.active),
              activeTab === tab.name && selectedTabClassName,
              tab?.disabled && (tab?.disableStyle || styles.disabled)
            )}
            onClick={() => onChange(tab)}
          >
            {withIcon && tab.icon}
            {tab.name}
          </BlankButton>
        );
      })}
    </div>
  );
};

export default ButtonsGroup;
