import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function CyberThreatsIcon({}: IconProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-2 0 16 16">
      <path
        fill="#6B55F0"
        d="M14.3 4.138c0 9.197-6.333 10.73-6.333 10.73s-6.333-1.277-6.333-10.73l6.333-2.81 6.333 2.81Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.971 1.329v13.539H7.97s-6.333-1.277-6.333-10.73l6.333-2.81h.001Z"
        clipRule="evenodd"
        opacity=".4"
      />
    </svg>
  );
}

export default CyberThreatsIcon;
