import React, { FC, useEffect } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getTrafficLink } from "../../common/trafficHelper";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./Overview.module.scss";
import { useTenantContext } from "../../../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";
import { getPanelIdByTimerange } from "../../../../helpers/getPanelIdByTimerange";

const Overview: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters, isServerTraffic } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <TrafficLayout tabs={tabs}>
      {servicesStatus && !servicesStatus.sdr.overall ? (
        <ServicesRedirectPlaceholder
          type="sdr"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.sdr.adminSide}
        />
      ) : (
        <GrafanaContainer type={GRAFANA_TYPES.SDR} className={styles.wrapper}>
          <div className={styles.chartsWrapper}>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic
                    ? getPanelIdByTimerange(
                        timeRange.general,
                        "trafficOverview77"
                      )
                    : 76,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 81 : 80,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 79 : 78,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 22 : 21,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 24 : 23,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 33 : 32,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 27 : 28,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 26 : 25,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
            <div className={styles.basicChart}>
              <GrafanaGraph
                link={getTrafficLink(
                  isServerTraffic ? 18 : 17,
                  timeRange.general,
                  selectedTenant,
                  filters
                )}
              />
            </div>
          </div>
        </GrafanaContainer>
      )}
    </TrafficLayout>
  );
};

export default Overview;
