import { CellProps } from "react-table";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";
import VLANIcon from "../../../../icons/VLANIcon";
import { CellFactory } from "../../newTable/cells/CellsFactory";
import TextWithIcon from "../../TextWithIcon";
import { PortVlan } from "../../../../../pages/VLANPage/types";

const cellFactory = new CellFactory<PortVlan>({});

export const vlanTableHeader = (port: string): any => {
  return [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row, cell: { value } }: CellProps<PortVlan>) => (
        <TextWithIcon
          icon={
            <VLANIcon
              indicatorColor={getColorFromStatus(
                row.original.administrative_state
              )}
            />
          }
          text={`${port} - ${value}`}
        />
      ),
    },
    {
      Header: "Alias",
      accessor: "alias",
    },
    {
      Header: "Virt. Interface",
      accessor: "virtual_interface_name",
    },
    {
      Header: "Op-state",
      accessor: "administrative_state",
      Cell: cellFactory.opStateCell(),
    },
    {
      Header: "MTU",
      accessor: "mtu",
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof PortVlan }));
};
