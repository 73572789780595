import React, { FC, ReactNode } from "react";
import { classNames } from "../../../../helpers/common/classNames";

import styles from "./DialogFrame.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
};

const DialogFrame: FC<Props> = ({ children, className }) => {
  return (
    <div className={classNames(styles.dialogFrame, className)}>{children}</div>
  );
};

export default DialogFrame;
