/* eslint-disable prettier/prettier */
import React, { FC } from "react";
import Button from "../../../../components/common/buttons/Button";
import { useSegmentsRulesContext } from "../../SegmentsRulesContext";
import CustomRules from "./components/CustomRules";
import PredefinedRules from "./components/PredefinedRules";
import StatusMessage from "./components/StatusMessage";

import styles from "./SegmentRulesTable.module.scss";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";

export const SegmentRulesTable: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    selectedSegment,
    segments,
    createFirewallRules,
    setSegment,
  } = useSegmentsRulesContext();

  const dstSegment =
    typeof selectedSegment?.y === "number"
      ? segments[selectedSegment.y].name
      : "-";

  const srcSegment =
    typeof selectedSegment?.x === "number"
      ? segments[selectedSegment.x].name
      : "-";

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <div className={styles.headerSource}>
          <span>Source:</span>
          <b>{srcSegment}</b>
        </div>
        <div className={styles.headerDestination}>
          <span>Destination:</span>
          <b>{dstSegment}</b>
        </div>
      </header>
      <main className={styles.mainContent}>
        <PredefinedRules />
        <CustomRules />
      </main>
      <footer className={styles.footer}>
        <StatusMessage className={styles.msg} />
        <Button className={styles.btn} onClick={() => setSegment(undefined)}>
          Cancel
        </Button>
        <Button
          className={styles.btn}
          isPrimaryBtn
          onClick={() =>
            selectedTenant &&
            createFirewallRules(srcSegment, dstSegment, selectedTenant)
          }
        >
          Save
        </Button>
      </footer>
    </div>
  );
};
