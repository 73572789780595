import React, { FC } from "react";
import { classNames } from "../../../../helpers/common/classNames";
import { FONT } from "../../../../helpers/common/colorAliases";
import { getColorFromStatus } from "../../../../helpers/common/colorHelpers";
import getIconFromValue from "../../../../helpers/getIconFromValue";
import Button from "../../../common/buttons/Button";
import IconBlankButton from "../../../common/buttons/IconBlankButton";
import ReloadIcon from "../../../icons/ReloadIcon";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./SectionHeader.module.scss";

type Props = {
  title?: string;
  activePackage?: string;
  onSave?: (title: string) => void;
  onSelect: (title: string) => void;
  isDefaultPackages?: boolean;
};

const SectionHeader: FC<Props> = ({
  title,
  activePackage,
  onSave,
  onSelect,
  isDefaultPackages,
}) => {
  if (!title) return <></>;

  const type = title.toLowerCase();
  const isActive = activePackage && type === activePackage.toLowerCase();
  const btnBasic = isDefaultPackages ? "Edit" : "Select Plan";
  const btnActive = isDefaultPackages ? "Apply" : "Selected";

  const handleClick = (val: string) => {
    isActive && onSave ? onSave(val) : onSelect(val);
  };

  return (
    <div className={styles.headerSection}>
      <div className={classNames(styles.colorLine, styles[type])} />
      <div
        className={classNames(
          styles.headerSectionContent,
          styles[type],
          isActive && styles.active
        )}
      >
        <div className={styles.title}>
          {getIconFromValue(type, FONT)}
          {title}
        </div>
        <div className={styles.btns}>
          <Button
            onClick={() => handleClick(title)}
            className={classNames(styles.btn, isActive && styles.activeBtn)}
          >
            {isActive ? btnActive : btnBasic}
          </Button>
          {isDefaultPackages && isActive && (
            <IconBlankButton
              id={`sectionHEader${title}`}
              icon={ReloadIcon}
              className={classNames(styles.btn, styles.iconBtn)}
              color={getColorFromStatus(type)}
              onClick={() => onSelect("")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionHeader;
