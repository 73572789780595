import { createContextAndUse } from "../../../contexts/common/utils";
import { AbstractContextProvider } from "../../../contexts/common/AbstractContext";
import { tenantApi } from "../../../helpers/api/TenantApi";
import { Service } from "../ServicesStep/types";
import {
  IUserContext,
  withUserContextProps,
} from "../../../contexts/UserContext";
import { networkApi } from "../../../helpers/api/networkApi";
import { RequestStatus } from "../../../helpers/types";
import { setPending } from "../../../helpers/common/RequestStateHelpers";
import { SegmentRule } from "../../SegmentsRulesPage/components/SegmentRulesTable/types";

export type Segment = {
  id: number;
  isProtected: boolean;
  isAllowed: boolean;
  name: string;
};
type RequestStatuses = { segmentsStatus?: RequestStatus };

export type SegmentsRules = {
  allowAll: Array<SegmentRule>;
  predefined: Array<SegmentRule>;
  custom: Array<SegmentRule>;
};

type State = {
  segments: Array<Segment>;
  selectedSegment?: { x: number; y: number; id: number };
  ids: boolean;
  dpi: boolean;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchSegments: (tenant: string) => Promise<void>;
  updateSegment: (
    id: number,
    propertyName: keyof Segment,
    newValue: any
  ) => void;
  setSegment: (arg: { x: number; y: number; id: number } | undefined) => void;
  toggleServices: (value: boolean, id: string, tenant: string) => Promise<void>;
  fetchServicesData: (tenant: string) => Promise<void>;
};

const [ServicesContext, useServicesContext] = createContextAndUse<
  IState,
  IFunc
>();
export { useServicesContext };

class ServicesContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = ServicesContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      segments: [],
      ids: false,
      dpi: false,
    };
  }

  fetchServicesData = async (tenant: string): Promise<void> => {
    this.getIds(tenant);
    this.getDpi(tenant);
    this.fetchSegments(tenant);
  };

  // Segments
  fetchSegments = async (tenant: string): Promise<void> => {
    this.setState({ segmentsStatus: setPending() });
    const { ok, result } = await networkApi.getSegmentations(tenant);
    if (ok && result) {
      this.setState({
        segments: result.items.map((el, id) => ({
          isProtected: false,
          name: el.name,
          isAllowed: false,
          id,
        })),
      });
    }
  };

  updateSegment = (id: number, property: keyof Segment, value: any): void => {
    this.setState({
      segments: this.state.segments.map((s) => ({
        ...s,
        [property]: s.id === id ? value : s[property],
      })),
    });
  };

  setSegment = (
    segment: { x: number; y: number; id: number } | undefined
  ): void => {
    this.setState({
      selectedSegment:
        this.state.selectedSegment?.x === segment?.x &&
        this.state.selectedSegment?.y === segment?.y
          ? undefined
          : segment,
    });
  };

  // SERVICES
  getIds = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getServiceIds(tenant);
    if (ok && result) {
      this.setState({ ids: result.all });
    }
  };

  getDpi = async (tenant: string): Promise<void> => {
    const { ok, result } = await tenantApi.getServiceDpi(tenant);
    if (ok && result) {
      this.setState({ dpi: result.enabled });
    }
  };

  toggleServices = async (
    value: boolean,
    id: string,
    tenant: string
  ): Promise<void> => {
    if (id === Service.IDS) {
      const res = await tenantApi.editServiceIds(tenant, value);
      if (res.ok && res.result) {
        this.setState({ ids: res.result.all });
      }
    }
    if (id === Service.DPI) {
      const res = await tenantApi.editServiceDpi(tenant, value);
      if (res.ok && res.result) {
        this.setState({ dpi: res.result.enabled });
      }
    }
  };

  funcs = {
    fetchSegments: this.fetchSegments,
    updateSegment: this.updateSegment,
    setSegment: this.setSegment,
    toggleServices: this.toggleServices,
    fetchServicesData: this.fetchServicesData,
  };
}

export default withUserContextProps<any>(ServicesContextContainer);
