import { FC, memo, useEffect, useState } from "react";
import styles from "./OverviewPage.module.scss";

import Appliances from "./Appliances";
import Map from "./Map";
import Bandwidth from "./Bandwidth";
import TopTalkers from "./TopTalkers";
import Domains from "./Domains";
import Users from "./Users";
import Applications from "./Applications";
import Countries from "./Countries";
import Ports from "./Ports";
import Loss from "./Loss";
import Jitter from "./Jitter";
import Latency from "./Latency";
import FlowCounts from "./FlowCounts";
import Health from "./Health";
import OverlayInterface from "./OverlayInterface";
import { fetchDevices, fetchGroups } from "../../helpers/insights";
import OverlayTransport from "./OverlayTransport";

const OverviewPage: FC<{ tabs: any }> = memo(({ tabs: Tabs }) => {
  const [groups, setGroups] = useState<Array<any>>([]);
  const [devices, setDevices] = useState<Array<any>>([]);
  const [device, setDevice] = useState<string>("");

  useEffect(() => {
    fetchDevices().then((data) => {
      if (data) setDevices(data);
    });

    fetchGroups().then((data) => {
      if (data) setGroups(data);
    });
  }, []);

  const list = device ? devices.filter((item) => item.id === device) : devices;

  return (
    <>
      <Tabs />
      <div className={styles.twoColumns}>
        <Appliances
          groups={groups}
          list={devices}
          device={device}
          setDevice={setDevice}
        />

        <div>
          <Map />
          <div className={styles.charts}>
            <Bandwidth className={styles.chart} list={list} />
            <TopTalkers className={styles.chart} list={list} />
            <Domains className={styles.chart} list={list} />
            <Applications className={styles.chart} list={list} />
            <Users className={styles.chart} list={list} />
            <Countries className={styles.chart} list={list} />
          </div>

          <Health className={styles.chart} list={list} />

          <div className={styles.charts}>
            <Ports className={styles.chart} list={list} />
            <Loss className={styles.chart} list={list} />
            <Jitter className={styles.chart} list={list} />
            <Latency className={styles.chart} list={list} />
            <FlowCounts className={styles.chart} list={list} />
            <OverlayTransport className={styles.chart} list={list} />
            <OverlayInterface className={styles.chart} list={list} />
          </div>
        </div>
      </div>
    </>
  );
});

export default OverviewPage;
