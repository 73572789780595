import React, { useContext } from "react";
import withContextToProps, {
  IContextToProps,
} from "../../helpers/hocs/withContextToProps";

export function createContextAndUse<IS, IF>(): [
  React.Context<(IS & IF) | any>,
  () => IS & IF
] {
  const Context = React.createContext<(IS & IF) | any>(null);
  return [Context, (): IS & IF => useContext<IS & IF>(Context)];
}

export function createContextUtils<IS, IF>(): [
  Context: React.Context<(IS & IF) | any>,
  useContext: () => IS & IF,
  withContextProps: IContextToProps<IS & IF>
] {
  const Context = React.createContext<(IS & IF) | any>(null);
  const useCont = () => useContext<IS & IF>(Context);
  const withContProps = withContextToProps<IS & IF>(useCont);
  return [Context, useCont, withContProps];
}
