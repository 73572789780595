import { CellProps } from "react-table";
import { VirtualInterfaceType } from "../../../../../helpers/api/apiTypes";

import { CellFactory } from "../../newTable/cells/CellsFactory";

const cellFactory = new CellFactory<VirtualInterfaceType>({});

export const l2InterfacesTableHeader = (): any => {
  return [
    {
      Header: "Alias",
      accessor: "alias",
      Cell: ({ row, cell: { value } }: CellProps<any>) => {
        return value || row.original?.name;
      },
    },
    {
      Header: "VI (Virtual Interface) name",
      accessor: "virtual_interface_name",
    },
    {
      Header: "Max Bandwidth (Mbps)",
      accessor: "max_bandwidth",
      Cell: ({ cell: { value } }: CellProps<any>) => {
        return value ? `${value}Mbps` : "Unlimited";
      },
    },
    {
      Header: "Op-state",
      accessor: "operational_state",
      Cell: () => cellFactory.opStateCell(),
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof VirtualInterfaceType,
  }));
};
