import React, { FC, useEffect } from "react";
import { withContexts } from "../../../../helpers/hocs/withContexts";
import { withLayout } from "../../../../helpers/hocs/withLayout";
import {
  ConnectionContextContainer,
  useConnectionContext,
} from "../ConnectionContext";

import ConnectionInfo from "./common/ConnectionInfo";
import ConnectionTips from "./common/ConnectionTips";
import ConnectionFormContainer from "./components";
import styles from "./ConnectionConfigurator.module.scss";
import ConnectionConfiguratorContextContainer, {
  useConnectionConfiguratorContext,
} from "./ConnectionConfiguratorContext";

const ConnectionCofigurator: FC = () => {
  const { fetchLocations } = useConnectionContext();
  const { fields } = useConnectionConfiguratorContext();

  useEffect(() => {
    fetchLocations();
  }, []);

  return (
    <div className={styles.wrapper}>
      <ConnectionInfo className={styles.basicBlock} fields={fields} />
      <ConnectionFormContainer className={styles.basicBlock} />
      <ConnectionTips className={styles.basicBlock} />
    </div>
  );
};

export default withContexts(withLayout<any>(ConnectionCofigurator), [
  ConnectionContextContainer,
  ConnectionConfiguratorContextContainer,
]);
