import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextUtils } from "../../contexts/common/utils";
import {
  IGlobalFilterContext,
  withGlobalFilterContext,
} from "../../contexts/GlobalFilterContext";
import { FilterAndSort } from "../../helpers/api/apiTypes";
import { tenantApi } from "../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { fetchFullTenantData } from "./helpers/fetchFullTenantData";
import { Tenant, TenantServices } from "./types";

type RequestStatuses = {
  listStatus?: RequestStatus;
  removeStatus?: RequestStatus;
};

type State = {
  list: Array<Tenant>;
  count: number;
  totalCount: number;
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchData: (
    param?: FilterAndSort,
    extraLoad?: boolean,
    offset?: number,
    limit?: number
  ) => Promise<void>;
  remove: (tenant: Tenant) => Promise<boolean>;
  editServices: (
    params: TenantServices,
    name: string | number
  ) => Promise<boolean>;
};

const [
  TenantsContext,
  useLocalTenantsContext,
  withTenantsContextProps,
] = createContextUtils<IState, IFunc>();
export { useLocalTenantsContext, withTenantsContextProps };
export type ITenantsContext = IState & IFunc;

export const FAILED_TO_FETCH = "Failed to fetch";

class ConnectionsContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IGlobalFilterContext
> {
  Context = TenantsContext;
  constructor(props: Readonly<any>) {
    super(props);
  }

  fetchData = async (
    param?: FilterAndSort,
    extraLoad?: boolean,
    offset?: number,
    limit?: number
  ): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const res = await tenantApi.getTenantsList(param, offset, limit);
    if (res.ok && res.result) {
      const list = await fetchFullTenantData(res.result);
      this.setState({
        list,
        count: res.count || 0,
        totalCount: res.totalCount || 0,
      });
    }
    this.setState({ listStatus: resToState(res) });
    return;
  };

  remove = async (params: Tenant): Promise<any> => {
    this.setState({ removeStatus: setPending("Fetching") });
    const res = await tenantApi.deleteTenant(params.name);
    this.setState({ removeStatus: resToState(res) });
    if (this.state.removeStatus?.state === "ok") {
      window.location.reload();
    }
    return res.ok;
  };

  editServices = async (
    params: TenantServices,
    name: string | number
  ): Promise<boolean> => {
    const res = await tenantApi.editTenantServices(name as string, params);
    return res.ok;
  };

  funcs = {
    fetchData: this.fetchData,
    remove: this.remove,
    editServices: this.editServices,
  };
}

export default withGlobalFilterContext<any>(ConnectionsContextContainer);
