import React, { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function IdentityProviderIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9951 13.7165C13.7492 13.7165 15.1712 11.9891 15.1712 9.85827C15.1712 7.72738 14.7043 6 11.9951 6C9.28594 6 8.81897 7.72738 8.81897 9.85827C8.81897 11.9891 10.241 13.7165 11.9951 13.7165Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M6.00123 19.607C6.00016 19.5708 6.00069 19.4786 6.00123 19.607V19.607Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M17.9988 19.7079C17.9994 19.4611 18.0005 19.6723 17.9988 19.7079V19.7079Z"
        fill={color || GREY_FONT}
      />
      <path
        d="M17.9916 19.451C17.9328 15.739 17.4481 14.6812 13.7388 14.0117C13.7388 14.0117 13.2166 14.6771 11.9996 14.6771C10.7826 14.6771 10.2604 14.0117 10.2604 14.0117C6.59154 14.6739 6.07733 15.716 6.00972 19.3304C6.00417 19.6255 6.0016 19.641 6.00061 19.6067C6.00084 19.6709 6.00111 19.7897 6.00111 19.9967C6.00111 19.9967 6.8842 23.7772 11.9996 23.7772C17.1149 23.7772 17.9981 19.9967 17.9981 19.9967C17.9981 19.8637 17.9982 19.7712 17.9983 19.7083C17.9974 19.7295 17.9954 19.6884 17.9916 19.451Z"
        fill={color || GREY_FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0006 0.900391L23.0006 5.92303V6.56574C23.0006 14.286 20.4067 18.9048 17.7064 21.5949C16.3676 22.9286 15.0251 23.766 14.009 24.2722C13.5007 24.5253 13.0727 24.6963 12.7655 24.8056C12.6118 24.8603 12.4881 24.8997 12.3993 24.9262C12.3549 24.9395 12.3192 24.9496 12.2928 24.9568L12.2603 24.9654L12.2495 24.9682L12.2454 24.9693L12.2437 24.9697C12.243 24.9699 12.2423 24.9701 12.0006 23.9997C11.7974 24.9788 11.7966 24.9787 11.7959 24.9785L11.7941 24.9782L11.7899 24.9773L11.7787 24.9748L11.7456 24.9673C11.7188 24.9611 11.6827 24.9524 11.6378 24.9408C11.5481 24.9175 11.4233 24.8828 11.2685 24.8337C10.9591 24.7355 10.5286 24.5796 10.0176 24.3427C8.99571 23.8691 7.64674 23.0694 6.30234 21.758C3.58923 19.1114 1.00061 14.485 1.00061 6.56574V5.92303L12.0006 0.900391ZM12.0006 23.9997L11.7974 24.9788L12.0208 25.0252L12.2423 24.9701L12.0006 23.9997ZM11.9816 22.9606C12.0159 22.9491 12.0538 22.9361 12.0951 22.9214C12.3347 22.8361 12.688 22.6958 13.1172 22.482C13.9761 22.0541 15.1336 21.3349 16.2948 20.1781C18.5258 17.9555 20.8568 14.0324 20.9942 7.20553L12.0006 3.09901L3.00665 7.20569C3.14062 14.2411 5.47843 18.1603 7.69888 20.3263C8.85448 21.4536 10.0055 22.1327 10.8587 22.5282C11.2851 22.7258 11.6359 22.852 11.8733 22.9273C11.9126 22.9398 11.9487 22.9509 11.9816 22.9606ZM12.2077 23.0214C12.2082 23.0215 12.2084 23.0215 12.2077 23.0214V23.0214Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default IdentityProviderIcon;
