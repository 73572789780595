import { capitalizeFirstLetter } from "./common/stringHelpers";
import { DialogControlsType, DialogTitle, DialogType } from "./types";

type DialogControls = {
  dialogTitle: DialogTitle | "save" | "delete";
  onAdd: () => void;
  onEdit: () => void;
  onClose: () => void;
  disableSubmit?: boolean;
};

export const okButtonText = (type: DialogType | undefined): string => {
  return capitalizeFirstLetter(type);
};
export const getDialogControls = ({
  dialogTitle,
  onAdd,
  onEdit,
  onClose,
  disableSubmit,
}: DialogControls): DialogControlsType => {
  return {
    okText: capitalizeFirstLetter(dialogTitle),
    onOk: dialogTitle === "add" ? onAdd : onEdit,
    cancelText: "Cancel",
    onCancel: onClose,
    disableSubmit: disableSubmit,
  };
};

type DeleteDialogControls = {
  onDelete: () => void;
  onCancel: () => void;
};
export const getDeleteDialogControls = ({
  onDelete,
  onCancel,
}: DeleteDialogControls): DialogControlsType => {
  return {
    okText: "Delete",
    onOk: onDelete,
    cancelText: "Cancel",
    onCancel,
  };
};
