export default function useFieldGroup<D>(
  i: number,
  name: string,
  value: string | number,
  fields: D
): [key: string, fieldGroup: Array<string>] {
  const temp: any = { ...fields };
  const key = name as keyof any;
  const fieldGroup = temp[key] as Array<string>;
  fieldGroup?.splice(i, 1, value.toString());
  return [key as string, fieldGroup];
}
