import React, { FC } from "react";

import styles from "./PortsScheme.module.scss";
import { DataForScheme } from "../types";
import { classNames } from "../../../helpers/common/classNames";
import SchemeControls from "./components/SchemeControls";
import Scheme from "./components/Scheme";

type SchemeProps = {
  isSchemeShown: boolean;
  onClick: () => void;
};

const PortsScheme: FC<SchemeProps> = ({ isSchemeShown, onClick }) => {
  const schemeWrapperStyle = classNames(
    styles.schemeWrapper,
    isSchemeShown && styles.showScheme
  );

  return (
    <div className={schemeWrapperStyle}>
      <SchemeControls
        onClick={onClick}
        className={styles.toggleBtn}
        isSchemeShown={isSchemeShown}
      />
      {isSchemeShown && (
        <div className={styles.schemeContainer}>
          <Scheme />
        </div>
      )}
    </div>
  );
};

export default PortsScheme;
