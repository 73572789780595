import React, { FC, useEffect } from "react";

import styles from "./DnsStatistics.module.scss";

import GrafanaGraph from "../../components/common/charts/GrafanaGraph";
import { getDNSLink } from "./common/dnsStatisticsHelper";
import { useTimerangeContext } from "../../contexts/TimerangeContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useDnsDashboardContext } from "./DnsContext";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useUserContext } from "../../contexts/UserContext";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";

const DnsStatistics: FC = () => {
  const { isDemo } = useUserContext();

  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { filters } = useDnsDashboardContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  const trValue = isDemo
    ? { from: "2023-07-18T14:58:11.319Z", to: "2023-07-19T08:32:11.505Z" }
    : timeRange.general;

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      {servicesStatus && !servicesStatus.dns.overall ? (
        <ServicesRedirectPlaceholder
          type="dns"
          adminMode={servicesStatus.dns.adminSide}
          fetchData={fetchServicesStatus}
        />
      ) : (
        <GrafanaContainer
          type={GRAFANA_TYPES.DPI}
          className={styles.wrapper}
          ignorePlaceholder={isDemo}
        >
          <div className={styles.container}>
            <div className={styles.top}>
              <GrafanaGraph
                link={getDNSLink(3, trValue, filters, selectedTenant)}
              />
            </div>
            <div className={styles.middle}>
              <GrafanaGraph
                link={getDNSLink(2, trValue, filters, selectedTenant)}
              />
            </div>
            <div className={styles.btmLeft}>
              <GrafanaGraph
                link={getDNSLink(5, trValue, filters, selectedTenant)}
              />
            </div>
            <div className={styles.btmRight}>
              <GrafanaGraph
                link={getDNSLink(8, trValue, filters, selectedTenant)}
              />
            </div>
          </div>
        </GrafanaContainer>
      )}
    </>
  );
};

export default DnsStatistics;
