import React, { FC, useEffect, useMemo, useState } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import PortInterfaceDialog from "./itemDialog/PortInterfaceDialog";
import useDeleteDialog from "../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../components/dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { ActionFuncs } from "../../components/common/table/newTable/RowControls";
import { withContexts } from "../../helpers/hocs/withContexts";
import PortInterfaceContextContainer, {
  usePortInterfaceContext,
} from "./PortInterfaceContext";
import { PortInterface } from "./types";
import { portInterfaceTableHeader } from "./table/portInterfaceTableHeader";

const PortInterfacePageContent: FC = () => {
  const {
    list = [],
    fetchList,
    listStatus,
    remove,
    removeStatus,
  } = usePortInterfaceContext();

  const [, setViewItem] = useState<PortInterface | undefined>();
  const [showAdd, setShowAdd] = useState(false);
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<PortInterface>(remove, removeStatus, () => {
    setViewItem(undefined);
  });

  useEffect(() => {
    fetchList();
  }, []);

  const rowActions: ActionFuncs<PortInterface> = useMemo(
    () => ({
      // onEdit: setViewItem,
      onDelete: tryDelete,
    }),
    [tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"Port Interface"}
        name={selectedToDelete?.name}
      />
      {showAdd && (
        <PortInterfaceDialog onClose={() => setShowAdd(false)} type={"add"} />
      )}
      <TableWrapper
        titleProps={{
          title: `${(list || []).length} Port Interfaces`,
          children: <AddButton onClick={() => setShowAdd(true)} />,
        }}
        dataStatus={listStatus}
        tablePlaceholder={["Port Interfaces", "Port Interface"]}
        isEmpty={list && list.length === 0}
      >
        <Table
          columns={portInterfaceTableHeader()}
          header={TableHeader}
          data={list}
          gridColumnTemplate={`1.5fr repeat(4, 1fr) 0`}
          rowActions={rowActions}
        />
      </TableWrapper>
    </>
  );
};

export default withContexts(PortInterfacePageContent, [
  PortInterfaceContextContainer,
]);
