import React, { FC } from "react";
import styles from "./LayoutWithThreeColumns.module.scss";
import CollapseInfoBlockIcon from "../icons/CollapseInfoBlockIcon";
import { classNames } from "../../helpers/common/classNames";
import BlankButton from "../common/buttons/BlankButton";

type Props = {
  InfoBlock: FC<{}>;
  AdditionalInfoBlock: FC<{}>;
  isInfoCollapsed?: boolean;
  setIsInfoCollapsed?: (v: boolean) => void;
  id: string;
};

const LayoutWithThreeColumns: React.FC<Props> = ({
  children,
  InfoBlock,
  AdditionalInfoBlock,
  isInfoCollapsed,
  setIsInfoCollapsed,
  id,
}) => {
  const withInfoBlock = !!InfoBlock;
  const withAdditional = !!AdditionalInfoBlock;
  return (
    <div
      className={classNames(
        styles.wrapper,
        isInfoCollapsed && styles.collapsed
      )}
    >
      <div className={styles.infoWrapper}>{withInfoBlock && <InfoBlock />}</div>
      {children}
      {setIsInfoCollapsed && (
        <BlankButton
          id={id}
          onClick={() => setIsInfoCollapsed(!isInfoCollapsed)}
          className={classNames(
            styles.collapsedIcon,
            isInfoCollapsed && styles.expandedIcon
          )}
        >
          <CollapseInfoBlockIcon />
        </BlankButton>
      )}
      <div className={styles.additionalWrapper}>
        {withAdditional && <AdditionalInfoBlock />}
      </div>
    </div>
  );
};

export default LayoutWithThreeColumns;
