import React from "react";
import { RequestStatus } from "../../../helpers/types";
import {
  isError,
  isOk,
  isPending,
} from "../../../helpers/common/RequestStateHelpers";
import { PageLoader } from "./LoaderIcon";
import NoDataPlaceholder from "./NoDataPlaceholder";

export default function DataPlaceHolders({
  status,
  errorTitle,
  errorSubTitle,
  emptyTitle,
  emptySubTitle,
  isEmpty,
  isGraph,
}: {
  isGraph?: boolean;
  status?: RequestStatus;
  errorTitle?: string;
  errorSubTitle?: string;
  emptyTitle?: string;
  emptySubTitle?: string;
  isEmpty?: boolean;
}): React.ReactElement {
  if (isPending(status)) {
    return <PageLoader />;
  }
  if (isError(status)) {
    return (
      <NoDataPlaceholder
        isError
        isGraph={isGraph}
        title={errorTitle || "Get Data Error"}
        subTitle={errorSubTitle || status?.message}
      />
    );
  }
  if (isOk(status) && isEmpty) {
    return (
      <NoDataPlaceholder
        isGraph={isGraph}
        title={emptyTitle || "No data"}
        subTitle={emptySubTitle}
      />
    );
  }
  return <></>;
}
