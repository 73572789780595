import React from "react";
import { PageProps } from "../../helpers/types";
import styles from "./SegmentsPage.module.scss";
import { withLayout } from "../../helpers/hocs/withLayout";
import SegmentsPageContent from "./SegmentsPageContent";
import { NetworkContextContainer } from "../Network/context/NetworkContext";

const SegmentsPage: React.FC<PageProps> = ({ tabs: Tabs }) => {
  return (
    <NetworkContextContainer>
      <div className={styles.pageWrapper}>
        <Tabs noReports />
        <SegmentsPageContent />
      </div>
    </NetworkContextContainer>
  );
};

export default withLayout<PageProps>(SegmentsPage);
