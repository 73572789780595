import React, { FC } from "react";
import styles from "./InputsGroup.module.scss";
import Input from "./formComponents/Input";

type Props = {
  name: string;
  placeholder: string;
  fields: Array<string>;
  title: string;
  onChange: (val: Array<string>) => void;
  errors?: { [key: string]: string };
  isOptional?: boolean;
  medium?: boolean;
  isNotEditable?: boolean;
};

const InputsGroup: FC<Props> = ({
  name,
  placeholder,
  fields,
  title,
  onChange,
  errors,
  isOptional,
  medium,
  isNotEditable,
}) => {
  const handleChange = (val: string, i: number) => {
    const newFields = [...fields];
    newFields[i] = val;
    if (!newFields[i]) newFields.splice(i, 1);
    onChange(newFields);
  };
  const renderInputsWithExtraField = () => {
    return [
      ...fields.filter((field) => field.length > 0),
      "",
    ].map((field: string, i: number) => (
      <Input
        key={i}
        onChange={(e) => handleChange(e.target.value, i)}
        name={name}
        placeholder={placeholder}
        value={field}
        label={i === 0 ? title : ""}
        error={errors && errors[`${i}_${name}`]}
        isOptional={isOptional}
        medium={medium}
        isNotEditable={isNotEditable}
      />
    ));
  };

  return (
    <div className={styles.inputGroups}>{renderInputsWithExtraField()}</div>
  );
};

export default InputsGroup;
