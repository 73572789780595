import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { CRITICAL } from "../../helpers/common/colorAliases";

function ErrDisableIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="5" width="10" height="2" fill={color || CRITICAL} />
    </svg>
  );
}

export default ErrDisableIcon;
