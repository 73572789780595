import React, { FC } from "react";
import {
  CLOUD_CONNECTIONS,
  CUSTOMER_LOCATIONS,
  DATA_CENTER_PORT,
} from "../../../consts";
import { ChangeConnectionsFields, FieldsType } from "../../../types";
import CloudConnectionForm from "./CloudConnectionForm";
import CustomerLocationForm from "./CustomerLocationForm";
import DataCenterPortForm from "./DataCenterPortForm";

type Props = {
  type: string;
  fields: FieldsType;
  onChange: ChangeConnectionsFields;
  className: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};
const FormRouter: FC<Props> = ({ type, ...restProps }) => {
  switch (type) {
    case CUSTOMER_LOCATIONS:
      return <CustomerLocationForm type={type} {...restProps} />;
    case DATA_CENTER_PORT:
      return <DataCenterPortForm type={type} {...restProps} />;
    case CLOUD_CONNECTIONS:
      return <CloudConnectionForm type={type} {...restProps} />;
    default:
      return null;
  }
};
export default FormRouter;
