import React, { ReactElement } from "react";
import { PURPLE } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function BooleanTrueIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 12.1111L10.2222 18.3333L20 5"
        stroke={color || PURPLE}
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BooleanTrueIcon;
