import moment from "moment";
import { GTimeRange } from "../../../components/common/charts/GraphanaLinksFactory";

export const getHistoryLogQuery = (tenant: string, timeRange: GTimeRange) => {
  const { from, to } = timeRange;
  return {
    track_total_hits: false,
    sort: [{ "@timestamp": { order: "desc", unmapped_type: "boolean" } }],
    fields: [
      { field: "*", include_unmapped: "true" },
      { field: "@timestamp", format: "strict_date_optional_time" },
      { field: "syslog5424_ts", format: "strict_date_optional_time" },
      { field: "timestamp", format: "strict_date_optional_time" },
    ],
    size: 500,
    version: true,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    _source: false,
    query: {
      bool: {
        must: [],
        filter: [
          {
            bool: {
              should: [
                {
                  bool: {
                    should: [
                      { match_phrase: { "entity.keyword": "bgp neighbor" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
                {
                  bool: {
                    should: [
                      { match_phrase: { "entity.keyword": "connection" } },
                    ],
                    minimum_should_match: 1,
                  },
                },
              ],
              minimum_should_match: 1,
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: from,
                lte: to,
              },
            },
          },
          { match_phrase: { "tenant_name.keyword": tenant } },
        ],
        should: [],
        must_not: [],
      },
    },
    highlight: {
      pre_tags: ["@kibana-highlighted-field@"],
      post_tags: ["@/kibana-highlighted-field@"],
      fields: { "*": {} },
      fragment_size: 2147483647,
    },
  };
};
