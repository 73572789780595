import React, { FC, useMemo } from "react";
import { getDialogControls } from "../../../helpers/getDialogControls";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { OneSystemType, VRFType } from "../../../helpers/api/apiTypes";
import DialogTemplate from "../../../components/dialogs/common/DialogTemplate";
import Input from "../../../components/common/formComponents/Input";
import DropdownBasic from "../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../helpers/mapStringToItem";
import { useValidation } from "../../../helpers/validators/Validator";
import { NewDialogProps } from "../../../helpers/types";
import { formFieldProps } from "../../../helpers/dialogs/FieldProps";

import styles from "./VTEPDialog.module.scss";
import VRFDropdown from "../../../components/common/OneSystem/VRFDropdown";

import { VTEPType } from "../types";
import VTEPDialogContextContainer, {
  useVTEPDialogContext,
} from "./VTEPDIalogContext";
import { withContexts } from "../../../helpers/hocs/withContexts";
import SummaryBlock from "./SummaryBlock";
import validateVTEP from "../../../helpers/validators/VTEPValidator";
import { useOneSystemContext } from "../../../contexts/systemsContext/OneSystemContext";
import InputsGroup from "../../../components/common/InputsGroup";

const VTEP_TYPE = ["bgp", "static"];

const DEFAULT_FIELDS: VTEPType = {
  id: 0,
  name: "",
  vrf: undefined,
  vtep_type: "",
  local_ip: "",
  remote_ips: [],
  description: "",
};

const VTEPDialog: FC<NewDialogProps<VTEPType>> = ({ type, onClose }) => {
  const isEdit = type === "edit";

  const { add, addStatus, edit, editStatus } = useVTEPDialogContext();
  const { system } = useOneSystemContext();

  const [fields, handleFieldChange] = useFormField<VTEPType>(DEFAULT_FIELDS);
  const [errors, validate] = useValidation<VTEPType>(validateVTEP, [fields]);

  const fieldProps = useMemo(
    () => formFieldProps(fields, handleFieldChange, errors),
    [handleFieldChange, fields, errors]
  );

  const handleSubmit = async () => {
    const { isOk } = validate();
    if (isOk) {
      const action = isEdit ? edit(fields) : add(fields);
      const success = await action;
      if (success) onClose();
    }
  };

  const handleSetVrf = (vrf?: VRFType) => {
    handleFieldChange("vrf", vrf);
  };

  const controls = getDialogControls({
    dialogTitle: isEdit ? "edit" : "add",
    onAdd: handleSubmit,
    onEdit: handleSubmit,
    onClose: onClose,
  });

  const leftColumn = <SummaryBlock title={"VTEP"} data={fields} />;

  return (
    <DialogTemplate
      title={"Add VTEP"}
      onClose={onClose}
      controls={controls}
      errorDisplay={addStatus || editStatus}
      className={styles.formWrapper}
      leftColumn={leftColumn}
    >
      <div className={styles.column}>
        <Input {...fieldProps("name")} label="Name" disabled={isEdit} />
        <Input
          {...fieldProps("local_ip")}
          label="Local VTEP IP"
          placeholder="X.X.X.X"
        />
        <VRFDropdown
          system={system as OneSystemType}
          selected={fields.vrf}
          onSelect={handleSetVrf}
          disabled={isEdit}
        />
        <Input {...fieldProps("description")} label="Description" />
      </div>
      <div className={styles.column}>
        <InputsGroup
          name="remote_ips"
          placeholder="X.X.X.X"
          fields={fields?.remote_ips || []}
          title="Remote VTEP IP"
          onChange={(val) => handleFieldChange("remote_ips", val)}
          errors={errors}
          medium
          isOptional
        />
        <DropdownBasic
          id={"VTEPDialogType"}
          onChange={(val) => handleFieldChange("vtep_type", val.key)}
          selected={mapStringToItem(fields.vtep_type)}
          label="Type"
          itemsList={VTEP_TYPE.map((key) => mapStringToItem(key))}
          isMedium
          isNotEditable={isEdit}
          disabled={isEdit}
        />
      </div>
    </DialogTemplate>
  );
};

export default withContexts<NewDialogProps<VTEPType>>(VTEPDialog, [
  VTEPDialogContextContainer,
]);
