import { FC, useEffect, useMemo } from "react";
import { withContexts } from "../../helpers/hocs/withContexts";
import SegmentsRulesContext, {
  useSegmentsRulesContext,
} from "./SegmentsRulesContext";
import styles from "./SegmentsRulesContent.module.scss";
import { SegmentsMatrix } from "./components/SegmentsMatrix/SegmentsMatrix";
import { SegmentRulesTable } from "./components/SegmentRulesTable";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";

const SegmentsRulesContent: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const { selectedSegment, fetchSegmentsData } = useSegmentsRulesContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchSegmentsData(selectedTenant);
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  const isCustomStatus = useMemo(() => selectedSegment?.status === "custom", [
    selectedSegment,
  ]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      {servicesStatus && !servicesStatus.segments.overall ? (
        <ServicesRedirectPlaceholder
          type="segments"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.segments.adminSide}
        />
      ) : (
        <div className={styles.wrapper}>
          <SegmentsMatrix />
          {isCustomStatus && <SegmentRulesTable />}
        </div>
      )}
    </>
  );
};

export default withContexts(SegmentsRulesContent, [SegmentsRulesContext]);
