import { PfxItem } from "../types";

export const PREFIX_FILTER_ITEM: PfxItem = {
  ip: "",
  match: true,
  seq: 0,
  le: 32,
};

export const getRouteFiltersPrefixBody = (): PfxItem => {
  return { ...PREFIX_FILTER_ITEM };
};
