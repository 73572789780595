import { getSublistFromArray } from "../../../helpers/getSublistFromArray";
import {
  APPLICATIONS,
  CYBER_THREATS,
  INSIGHTS,
  SERVICES,
  TOPOLOGY,
  TRAFFIC,
  NETWORK,
  CONNECTIVITY,
  SEGMENTATION,
  CLOUD_ROUTER,
  LOCATIONS,
  ZAYO_CONNECTIONS,
  SERVICES_FW,
  SERVICES_NAT,
  SERVICES_PREFERENCES,
  WEB_FILTERING,
  CONNECTIONS,
  WIZARDS,
  REMOTE_USERS,
  REMOTE_USERS_USER_GROUPS,
} from "../../../helpers/navigation/entries";
import ConnectionIcon from "../../icons/ConnectionIcon";
import InsightsIcon from "../../icons/tabs/InsightsIcon";
import NetworkIcon from "../../icons/tabs/NetworkIcon";
import ServicesIcon from "../../icons/tabs/ServicesIcon";
import WizardsIcon from "../../icons/tabs/WizardsIcon";
import { ButtonInfo } from "../types";

export const zayoTenantFormButtons = (
  goTo: () => void,
  isDemo?: boolean,
  tenantParams?: any
): Array<ButtonInfo> => {
  if (isDemo) {
    return [
      {
        ...INSIGHTS(),
        Icon: InsightsIcon,
        disabled: tenantParams?.isEmptyConfig,
        subList: getSublistFromArray(
          [TOPOLOGY(), APPLICATIONS(), TRAFFIC(), CYBER_THREATS()],
          goTo,
          INSIGHTS(),
          []
        ) as any,
      },
      {
        ...WIZARDS(),
        Icon: WizardsIcon,
        subList: getSublistFromArray(
          [LOCATIONS(), CONNECTIONS(), SERVICES()],
          goTo,
          WIZARDS()
        ) as any,
      },
      {
        ...NETWORK(),
        Icon: NetworkIcon,
        disabled: tenantParams?.isEmptyConfig,
        subList: getSublistFromArray(
          [
            CONNECTIVITY(),
            CLOUD_ROUTER(),
            REMOTE_USERS(),
            REMOTE_USERS_USER_GROUPS(),
          ],
          goTo,
          NETWORK(),
          []
        ) as any,
      },
      {
        ...SERVICES(),
        Icon: ServicesIcon,
        disabled: tenantParams?.isEmptyConfig,
        subList: getSublistFromArray(
          [
            SERVICES_FW(),
            WEB_FILTERING(),
            SERVICES_NAT(),
            SEGMENTATION(),
            SERVICES_PREFERENCES(),
          ],
          goTo,
          SERVICES()
        ) as any,
      },
    ];
  }
  return [
    {
      ...ZAYO_CONNECTIONS(),
      Icon: ConnectionIcon,
      withSeparator: true,
    },
    {
      ...NETWORK(),
      Icon: NetworkIcon,
      subList: getSublistFromArray(
        [SEGMENTATION(), CLOUD_ROUTER(), WEB_FILTERING()],
        goTo,
        NETWORK(),
        []
      ) as any,
    },
  ];
};
