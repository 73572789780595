import React from "react";
import { IconProps } from "../../../helpers/types";
import { formIconAttrs } from "../../../helpers/iconsHelpers";

function NSPBranch(props: IconProps): React.ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 34 34"
      {...formIconAttrs(34, 34, props)}
    >
      <path
        fill="#59536B"
        fillRule="evenodd"
        d="M12.074 4.646a1.7 1.7 0 00-1.327.638L8.05 8.653a1.703 1.703 0 00-.985 1.231l-.02.026h.016a1.71 1.71 0 00-.027.3v16.548a1.7 1.7 0 001.7 1.7h16.549a1.7 1.7 0 001.7-1.7V10.21c0-.103-.01-.203-.027-.3h.005l-.006-.007a1.703 1.703 0 00-1.006-1.258l-2.69-3.361a1.7 1.7 0 00-1.327-.638h-9.859zm-.234 1.512a.3.3 0 01.234-.112h9.859a.3.3 0 01.234.112L24.05 8.51H9.959l1.881-2.352zM8.435 10.21a.3.3 0 01.3-.3h16.549a.3.3 0 01.3.3v4.483H8.435V10.21zm0 5.883v4.782h17.149v-4.782H8.435zm0 10.665v-4.483h17.149v4.483a.3.3 0 01-.3.3H8.735a.3.3 0 01-.3-.3zm11.253-14.373c0-.42.34-.761.761-.761h2.957a.762.762 0 010 1.523H20.45a.762.762 0 01-.761-.762zm-8.96-1.03a.896.896 0 100 1.792.896.896 0 000-1.792z"
        clipRule="evenodd"
      ></path>
      <rect
        width="4.48"
        height="1.523"
        x="19.688"
        y="17.627"
        fill="#59536B"
        rx="0.762"
      ></rect>
      <rect
        width="4.48"
        height="1.523"
        x="19.688"
        y="24.169"
        fill="#59536B"
        rx="0.762"
      ></rect>
      <rect
        width="1.792"
        height="1.792"
        x="9.832"
        y="17.627"
        fill="#59536B"
        rx="0.896"
      ></rect>
      <rect
        width="1.792"
        height="1.792"
        x="9.832"
        y="23.721"
        fill="#59536B"
        rx="0.896"
      ></rect>
    </svg>
  );
}

export default NSPBranch;
