import { AbstractContextProvider } from "../../contexts/common/AbstractContext";
import { createContextAndUse } from "../../contexts/common/utils";
import { IUserContext, withUserContextProps } from "../../contexts/UserContext";
import { networkApi } from "../../helpers/api/networkApi";
import { tenantApi } from "../../helpers/api/TenantApi";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { RequestStatus } from "../../helpers/types";
import { Layer3InterfaceNew } from "../Layer3Interface/types";
import {
  ArubaAccessKey,
  ArubaConnectCreate,
  ArubaGateway,
} from "../WizardToolPage/types";
import { ProviderCredentialsFields } from "./components/ProviderCredentials";
import { SDWAN_TYPES_GROUPS } from "./const";

type RequestStatuses = {
  vpcStatus: { [key: string]: RequestStatus | undefined };
  removeStatus?: RequestStatus;
  countStatus?: RequestStatus;
  addConnectionStatus?: RequestStatus;
};

type State = {
  groupedList: { [key: string]: Array<Layer3InterfaceNew> };
  isToken: boolean;
  tokens?: { [key: string]: { [key: string]: string } | undefined };
  correctTokens?: { [key: string]: boolean };
  requestToRemove: string | undefined;
  requestToUpdate: string | undefined;
  usedTenant: string;
  discoverStatus: { [key: string]: RequestStatus | undefined };
  gateways: { [key: string]: Array<ArubaGateway> };
};

type IState = State & RequestStatuses;
type IFunc = {
  fetchList: (tenant: string) => Promise<void>;
  discovery: (tenant: string, type: string) => Promise<void>;
  connect: (fields: ArubaConnectCreate, tenant: string) => Promise<boolean>;
  getAccessKeys: (tenant: string, type: string) => Promise<void>;
  remove: (item: Layer3InterfaceNew) => Promise<boolean>;
  useTenant: (tenant: string) => Promise<void>;
  createAccessKey: (
    fields: ProviderCredentialsFields,
    tenant: string,
    type: string
  ) => Promise<any>;
  deleteAccessKey: (
    tenant: string,
    type: string,
    withCreate?: boolean,
    fields?: any
  ) => Promise<any>;
  resetTokens: () => Promise<void>;
  setRequestToRemove: (type?: string) => Promise<void>;
  setRequestToUpdate: (type?: string) => Promise<void>;
};

const [SDWANProvidersContext, useSDWANProvidersContext] = createContextAndUse<
  IState,
  IFunc
>();
export { useSDWANProvidersContext };

class SDWANContextContainer extends AbstractContextProvider<
  IState,
  RequestStatuses,
  IFunc,
  IUserContext
> {
  Context = SDWANProvidersContext;
  constructor(props: Readonly<any>) {
    super(props);
    this.state = {
      isToken: false,
      groupedList: SDWAN_TYPES_GROUPS,
      vpcStatus: {},
      requestToRemove: undefined,
      requestToUpdate: undefined,
      usedTenant: "",
      discoverStatus: {},
      gateways: {},
    };
  }

  useTenant = async (tenant: string): Promise<void> => {
    this.setState({ usedTenant: tenant });
  };

  setRequestToRemove = async (type?: string): Promise<void> => {
    this.setState({ requestToRemove: type });
  };

  setRequestToUpdate = async (type?: string): Promise<void> => {
    this.setState({ requestToUpdate: type });
  };

  fetchList = async (tenant: string) => {
    this.setState({ countStatus: setPending() });
    const res = await tenantApi.getVirtualInterfacesAllTypes(tenant);
    const grouppedList = { ...SDWAN_TYPES_GROUPS };
    const types = Object.keys(grouppedList);
    types.forEach((type) => {
      const connections = (res.result || [])?.filter(
        (el) =>
          el.labels?.remote_type === "sdwan" &&
          el?.labels?.sdwan_provider === type
      );
      grouppedList[type] = [...connections];
    });
    this.setState({ groupedList: grouppedList, countStatus: resToState(res) });
  };

  getAccessKeys = async (tenant: string, type: string): Promise<void> => {
    let query;
    switch (type) {
      case "aruba":
        query = tenantApi.getArubaAccessKey(tenant);
        break;
      default:
        return;
    }
    const { ok, result } = query && (await query);
    if (ok && result) {
      const newTokens = { ...this.state.tokens };
      newTokens[type] = result;
      this.setState({ tokens: newTokens, isToken: ok });
    }
  };

  resetTokens = async (): Promise<void> => {
    this.setState({ tokens: undefined, correctTokens: undefined });
  };

  createAccessKey = async (
    fields: any,
    tenant: string,
    type: string
  ): Promise<boolean> => {
    let res;
    switch (type) {
      case "aruba":
        res = await this.createArubaAccessKey(fields, tenant, type);
        return res;
      default:
        return false;
    }
  };

  deleteAccessKey = async (
    tenant: string,
    type: string,
    withCreate?: boolean,
    fields?: any
  ): Promise<any> => {
    let query;
    switch (type) {
      case "aruba":
        query = tenantApi.deleteArubaAccessKey(tenant);
        break;
      default:
        return;
    }
    const { ok } = await query;
    if (ok) {
      const correctTokens = this.state.correctTokens;
      if (correctTokens) delete correctTokens[type];

      this.setState({ correctTokens });

      if (withCreate && fields) {
        await this.createAccessKey(fields, tenant, type);
      }
    }
  };

  createArubaAccessKey = async (
    fields: ArubaAccessKey,
    tenant: string,
    type: string
  ): Promise<boolean> => {
    const res = await tenantApi.createArubaAccessKey(fields, tenant);
    await this.getAccessKeys(tenant, type);
    return res.ok;
  };

  connect = async (
    fields: ArubaConnectCreate,
    tenant: string
  ): Promise<boolean> => {
    const res = await tenantApi.connectAruba(fields, tenant);
    return res.ok;
  };

  remove = async (item: Layer3InterfaceNew): Promise<boolean> => {
    this.setState({ removeStatus: setPending() });
    const res = await networkApi.deleteIpsecInterface(
      this.state.usedTenant,
      item.name
    );
    this.setState({ removeStatus: resToState(res) });

    if (res.ok) {
      await this.fetchList(this.state.usedTenant);
    }

    return res.ok;
  };

  discovery = async (tenant: string, type: string) => {
    this.setState({
      discoverStatus: { ...this.state.discoverStatus, [type]: setPending() },
    });
    let query;
    switch (type) {
      case "aruba":
        query = tenantApi.discoverAruba(tenant);
        break;
      default:
        return;
    }
    const res = await query;
    if (res) {
      this.setState({
        discoverStatus: {
          ...this.state.discoverStatus,
          [type]: resToState(res),
        },
        gateways: {
          ...this.state.gateways,
          [type]: res?.result?.gateways || [],
        },
      });
    }
  };

  funcs = {
    fetchList: this.fetchList,
    getAccessKeys: this.getAccessKeys,
    remove: this.remove,
    createAccessKey: this.createAccessKey,
    deleteAccessKey: this.deleteAccessKey,
    resetTokens: this.resetTokens,
    setRequestToRemove: this.setRequestToRemove,
    setRequestToUpdate: this.setRequestToUpdate,
    useTenant: this.useTenant,
    discovery: this.discovery,
    connect: this.connect,
  };
}

export default withUserContextProps<any>(SDWANContextContainer);
