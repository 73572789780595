import { AWSVPNBody } from "./../../pages/WizardToolPage/types";
import Validator from "./Validator";

export default function validateAWSVPN(
  data: Partial<AWSVPNBody>,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.connection_name, "connection_name");
  validator.checkEmpty(data.system_name, "system_name");
  return validator;
}
