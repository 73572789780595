import React, { ReactElement } from "react";
import { BORDER_COLOR, CRITICAL } from "../../helpers/common/colorAliases";

function TablePlaceholder({ isError }: { isError?: boolean }): ReactElement {
  const mainColor = isError ? CRITICAL : BORDER_COLOR;
  return (
    <svg width="212" height="85" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1"
        y="1"
        width="210"
        height="83"
        rx="3"
        stroke={BORDER_COLOR}
        strokeWidth="2"
      />
      <path d="M0 4a4 4 0 0 1 4-4h204a4 4 0 0 1 4 4v5H0V4Z" fill={mainColor} />
      {isError ? <ErrorSign /> : <Lines />}
    </svg>
  );
}

function Lines() {
  return (
    <path
      d="M51 25h110v2H51zM51 39h110v2H51zM51 53h110v2H51zM51 67h110v2H51z"
      fill={BORDER_COLOR}
    />
  );
}
function ErrorSign() {
  return (
    <>
      <path
        d="M0 4a4 4 0 0 1 4-4h204a4 4 0 0 1 4 4v5H0V4Z"
        fill="var(--background-second-color)"
        fillOpacity=".5"
      />
      <path
        d="M105.7 51.7c-.9 0-1.6.7-1.6 1.6 0 .8.7 1.5 1.6 1.5.8 0 1.5-.7 1.5-1.5 0-.9-.7-1.6-1.5-1.6ZM105.3 39c-.7.1-1.2.8-1.2 1.6l.1 1.5.3 5.6c0 .6.6 1 1.2 1s1.1-.4 1.2-1v-1.2l.2-3.6.1-2.3v-.8c-.4-.7-1.2-1-1.9-.9Z"
        fill={CRITICAL}
      />
      <circle cx="106" cy="47" r="17" stroke={CRITICAL} strokeWidth="1.4" />
    </>
  );
}

export default TablePlaceholder;
