import React, { FC, useMemo } from "react";
import { TrafficType } from "../../../../../../helpers/types";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import grafanaLinksFactory from "../../../../../../components/common/charts/GraphanaLinksFactory";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";

type Props = {
  traffic: TrafficType;
  className?: string;
  tenantName?: string;
};

const TotalTraffic: FC<Props> = ({
  traffic = "bps",
  className,
  tenantName,
}) => {
  const { timeRange } = useTimerangeContext();

  const graphData = useMemo(
    () => ({
      timeRange: timeRange.general,
      tenantName,
    }),
    [timeRange, tenantName]
  );
  const graphLink = useMemo(
    () =>
      traffic === "bps"
        ? grafanaLinksFactory.dashboard_bps(timeRange.general)(graphData)
        : grafanaLinksFactory.dashboard_pps(timeRange.general)(graphData),
    [traffic, graphData]
  );

  return (
    <div className={className}>
      <GrafanaGraph link={graphLink} />
    </div>
  );
};
export default TotalTraffic;
