import React from "react";
import {
  ASType,
  PeerStatus,
  ServiceBGPNeighborWithStatus,
  SortType,
} from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { DropdownItem, RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { EMPTY_RESULT_OK } from "../../helpers/common/constantsAlias";
import { createContextAndUse } from "../../contexts/common/AbstractCrudContext";
import { ASTypeAdd, BGPNeighborAdd } from "./types";
import { addStatusToNeighbor } from "./helpers/addStatusToNeighbor";
import { mapStringToItem } from "../../helpers/mapStringToItem";

type RequestStatuses = {
  listStatus?: RequestStatus;
  addStatus?: RequestStatus;
  editStatus?: RequestStatus;
  removeStatus?: RequestStatus;
  neighborStatus?: RequestStatus;
};

type IState = {
  autonomous_system?: ASType;
  neighborsList: Array<ServiceBGPNeighborWithStatus>;
  bgpStatuses: Array<PeerStatus>;
  routeMapList: {
    global: Array<DropdownItem>;
    user: Array<DropdownItem>;
  };
  selectedNeighbor?: ServiceBGPNeighborWithStatus;
  neighbor?: any;
  neighborBgpStatus?: any;
} & RequestStatuses;

type IFunc = {
  addAS: (tenant: string, params: ASTypeAdd) => Promise<boolean>;
  addNeighbor: (
    tenant: string,
    params: Partial<BGPNeighborAdd>
  ) => Promise<boolean>;
  editNeighbor: (
    tenant: string,
    params: ServiceBGPNeighborWithStatus
  ) => Promise<boolean>;
  removeAS: (tenant: string) => Promise<boolean>;
  removeNeighbor: (remote_ip: string, tenant: string) => Promise<boolean>;
  fetchData: (tenant: string, sortBy?: SortType) => Promise<void>;
  resetStatus: () => void;
  selectNeighbor: (id?: number) => void;
  fetchRouteMapList: (tenant: string, sortBy?: SortType) => Promise<void>;
  removeRouteFilter: (name: string, tenant: string) => Promise<boolean>;
  getNeighbor: (ip: string, tenant: string) => Promise<void>;
};

const [UserContext, useContext] = createContextAndUse<IState, IFunc>();
export const useBGPServicesContext = useContext;

export default class ServicesBGPContextContainer extends AbstractTimeoutHandler<IState> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      autonomous_system: undefined,
      neighborsList: [],
      bgpStatuses: [],
      routeMapList: { global: [], user: [] },
    };
    this.funcs = {
      fetchData: this.fetchData,
      resetStatus: this.resetStatus,
      addAS: this.addAS,
      removeAS: this.removeAS,
      removeNeighbor: this.removeNeighbor,
      addNeighbor: this.addNeighbor,
      editNeighbor: this.editNeighbor,
      selectNeighbor: this.selectNeighbor,
      fetchRouteMapList: this.fetchRouteMapList,
      removeRouteFilter: this.removeRouteFilter,
      getNeighbor: this.getNeighbor,
    };
  }

  fetchData = async (tenant: string): Promise<void> => {
    this.setState({ listStatus: setPending("Fetching") });
    const resAS = await configApi.getServiceAS(tenant);
    const resStatus = await configApi.getServiceBGPStatus(tenant);
    if (resAS.ok && resStatus.ok && resStatus.result) {
      const newState: any = { autonomous_system: resAS.result };
      const resNeighbor = await configApi.getServiceNeighborsList(tenant);
      if (resNeighbor.ok && resNeighbor.result) {
        const neighborsList = addStatusToNeighbor(
          resNeighbor.result.items,
          resStatus.result || ({} as any)
        );
        newState.neighborsList = neighborsList;
      }
      newState.listStatus = resToState(resNeighbor);
      this.setState({ ...newState });
      return;
    }
    this.setState({
      listStatus: resToState(EMPTY_RESULT_OK),
      autonomous_system: undefined,
      neighborsList: [],
    });
  };

  addAS = async (tenant: string, params: ASTypeAdd): Promise<boolean> => {
    this.setState({ addStatus: setPending("Fetching") });
    const res = await configApi.addServiceAS(tenant, params);
    if (res.ok) {
      this.fetchData(tenant);
    }
    this.setState({ addStatus: resToState(res) });
    return res.ok;
  };

  removeAS = async (tenant: string): Promise<boolean> => {
    this.setState({ removeStatus: setPending("Fetching") });
    const res = await configApi.deleteServiceAS(tenant);
    if (res.ok) {
      this.fetchData(tenant);
    }
    this.setState({ removeStatus: resToState(res) });
    this.setupTimeout(
      "removeStatus",
      () => this.setState({ removeStatus: undefined }),
      2000
    );
    return res.ok;
  };

  addNeighbor = async (
    tenant: string,
    params: Partial<BGPNeighborAdd>
  ): Promise<boolean> => {
    this.setState({ addStatus: setPending("Fetching") });
    const res = await configApi.addServiceNeighbor(tenant, params);
    if (res.ok) {
      this.fetchData(tenant);
    }
    this.setState({ addStatus: resToState(res) });
    return res.ok;
  };

  editNeighbor = async (tenant: string, params: any): Promise<boolean> => {
    this.setState({ editStatus: setPending("Fetching") });
    const {
      pfx_list_in,
      pfx_list_out,
      passive,
      max_pfx_in,
      max_pfx_out,
      keepalive,
      hold,
      open_delay,
      shutdown,
      description,
    } = params;
    const res = await configApi.editServiceNeighbor(
      tenant,
      params.remote_router_ip,
      {
        pfx_list_in,
        pfx_list_out,
        passive,
        max_pfx_in,
        max_pfx_out,
        keepalive,
        hold,
        open_delay,
        shutdown,
        description,
      }
    );
    if (res.ok) {
      this.fetchData(tenant);
    }
    this.setState({ editStatus: resToState(res) });
    return res.ok;
  };

  removeNeighbor = async (
    router_ip: string,
    tenant: string
  ): Promise<boolean> => {
    this.setState({ removeStatus: setPending("Fetching") });
    const res = await configApi.deleteServiceNeighbor(tenant, router_ip);
    if (res.ok) {
      this.fetchData(tenant);
    }
    this.setState({ removeStatus: resToState(res) });
    this.setupTimeout(
      "removeStatus",
      () => this.setState({ removeStatus: undefined }),
      2000
    );
    return res.ok;
  };

  resetStatus = async (): Promise<void> => {
    this.setState({ addStatus: undefined });
  };

  selectNeighbor = (neighborId?: number): void => {
    const selectedNeighbor = this.state.neighborsList.find(
      (n) => n.id === neighborId
    );
    this.setState({ selectedNeighbor });
  };

  fetchRouteMapList = async (tenantName: string): Promise<void> => {
    const resGlobal = await configApi.getSystemBgpPfxList();
    const resUser = await configApi.getServiceBgpPfxList(tenantName);

    if (resGlobal.ok && resGlobal.result) {
      this.setState({
        routeMapList: {
          ...this.state.routeMapList,
          global: resGlobal.result.map((el) => mapStringToItem(el.name)),
        },
      });
    }
    if (resUser.ok && resUser.result) {
      this.setState({
        routeMapList: {
          ...this.state.routeMapList,
          user: resUser.result.map((el) => ({
            ...mapStringToItem(el.name),
            isEditable: true,
          })),
        },
      });
    }
    this.setState({ listStatus: resToState(resUser) });
  };

  removeRouteFilter = async (
    name: string,
    tenant?: string
  ): Promise<boolean> => {
    if (!tenant) return false;
    const res = await configApi.deleteServiceBgpPfx(tenant, name);
    if (res.ok) {
      this.fetchRouteMapList(tenant);
    }
    return res.ok;
  };

  getNeighbor = async (ip: string, tenant: string): Promise<void> => {
    this.setState({ neighborStatus: setPending() });
    this.getNeighborBGPStatus(ip, tenant);
    const res = await configApi.getServiceNeighbor(tenant, ip);
    if (res.ok && res.result) {
      this.setState({ neighbor: res.result });
    }
    this.setState({ neighborStatus: resToState(res) });
  };

  getNeighborBGPStatus = async (ip: string, tenant: string): Promise<void> => {
    const res = await configApi.getServiceBGPStatus(tenant);
    if (res.ok && res.result) {
      const neighborBgpStatus = res.result.peers[ip];
      this.setState({ neighborBgpStatus });
    }
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
