import { FC } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import RouteFiltersOverview from "./components/RouteFiltersOverview/RouteFiltersOverview";
import { ADD, EDIT } from "../../helpers/navigation/entries";
import RouteFiltersConfigAdd from "./components/RouteFiltersConfig/RouteFiltersConfigAdd";
import RouteFiltersConfigEdit from "./components/RouteFiltersConfig/RouteFiltersConfigEdit";
import { withContexts } from "../../helpers/hocs/withContexts";
import RouteFiltersContextContainer from "./RouteFiltersContext";

const RouteFiltersPage: FC = () => {
  const path = useMatchPath();

  return (
    <Switch>
      <Route path={path} exact>
        <RouteFiltersOverview />
      </Route>
      <Route path={path + "/" + ADD().path} exact>
        <RouteFiltersConfigAdd />
      </Route>
      <Route path={path + "/" + EDIT().path + "_:route"} exact>
        <RouteFiltersConfigEdit />
      </Route>
      <Route path={path + "/*"}>
        <Redirect to={path} />
      </Route>
    </Switch>
  );
};
export default withContexts(RouteFiltersPage, [RouteFiltersContextContainer]);
