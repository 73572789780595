import React, { FC, useEffect } from "react";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { useGlobalFilterContext } from "../../../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { CYBER_THREATS_FIELD_LABEL } from "../../../../consts";
import { useCyberThreatsContext } from "../../../../CyberThreatsContext";
import styles from "./FilterFields.module.scss";
import { useUserContext } from "../../../../../../contexts/UserContext";

type Props = {
  className?: string;
  fields: any;
  onChange: any;
};

const FilterFields: FC<Props> = ({ fields, onChange }) => {
  const { isDemo } = useUserContext();
  const { selectedTenant } = useGlobalFilterContext();
  const { timeRange } = useTimerangeContext();
  const { filtersData, fetchData } = useCyberThreatsContext();

  useEffect(() => {
    if (selectedTenant) {
      const trValue = isDemo
        ? { from: "2023-07-18T14:58:11.319Z", to: "2023-07-19T08:32:11.505Z" }
        : timeRange.general;
      fetchData(selectedTenant, trValue);
    }
  }, [selectedTenant]);

  return (
    <div className={styles.wrapper}>
      {filtersData &&
        Object.keys(filtersData).map((filter) => {
          const id = `filterFieldCyberThreatsDashboard_${filter}`;
          const label = CYBER_THREATS_FIELD_LABEL[filter];
          return (
            <DropdownBasic
              id={id}
              onChange={(val) => onChange(filter, val.key)}
              selected={mapStringToItem(fields[filter])}
              label={label}
              itemsList={filtersData[filter].map(mapStringToItem)}
              isMedium
            />
          );
        })}
    </div>
  );
};

export default FilterFields;
