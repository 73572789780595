import React, { FC } from "react";
import styles from "./AddItemButton.module.scss";
import Button from "../../../../../../../components/common/buttons/Button";
import PlusIcon from "../../../../../../../components/icons/PlusIcon";
import { ACCENT } from "../../../../../../../helpers/common/colorAliases";

export const AddItemButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button onClick={onClick} className={styles.button}>
      <PlusIcon color={ACCENT} />
    </Button>
  );
};
