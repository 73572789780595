import { Layer3Interface } from "./../pages/Layer3Interface/types";
import { ReactElement } from "react";
import { EndpointType, IconProps } from "./types";
import BranchIcon from "../components/icons/BranchIcon";
import CloudIcon from "../components/icons/CloudIcon";
import GlobeIcon from "../components/icons/GlobeIcon";
import IPsecIcon from "../components/icons/IPsecIcon";
import RemoteIcon from "../components/icons/RemoteIcon";
import { System, SystemAPI } from "../pages/Systems/Provisioning/types";
import { VIMapType } from "../pages/WizardToolPage/types";
import BareMetalIcon from "../components/icons/connections/BareMetalIcon";
import ColoIcon from "../components/icons/connections/ColoIcon";
import AWSIcon from "../components/icons/connections/AWSIcon";
import AzureIcon from "../components/icons/connections/AzureIcon";
import GCPIcon from "../components/icons/connections/GCPIcon";

export function prepareInterfaces(
  interfaces: Array<any>
): Array<Layer3Interface> {
  return interfaces.map((item) => updateInterfaceType(item)); // TEMP Change vni type to ipsec/remote based on name (Lior's temp decision)
}

export function updateInterfaceType(item: Layer3Interface): Layer3Interface {
  const itemName = item?.name.toLowerCase();
  if (itemName === "wfh") {
    item.virtual_interface_type = "remote";
    return item;
  }
  if (/wan$/.test(itemName)) {
    item.virtual_interface_type = "ipsec";
    return item;
  }
  return item;
}

export const getIconByType = (
  type: EndpointType | string
): ((props: IconProps) => ReactElement) => {
  switch (type) {
    case "branch":
    case "Virtual Interface Tenant Bridge Domain":
      return BranchIcon;
    case "bareMetal":
      return BareMetalIcon;
    case "data_center":
      return ColoIcon;

    case "aws":
      return AWSIcon;
    case "azure":
      return AzureIcon;
    case "gcp":
      return GCPIcon;

    case "remote":
      return RemoteIcon;
    case "ipsec":
    case "Virtual Interface IPSEC":
      return IPsecIcon;

    case "cloud":
      return CloudIcon;

    case "internet":
    case "Virtual Interface NAT":
    default:
      return GlobeIcon;
  }
};

export function groupedInterfacesByLoc(
  interfaces: Array<any>,
  allSystems?: Array<System | SystemAPI>,
  tenantSystems?: Array<string>
): VIMapType {
  if (!allSystems || !tenantSystems) return {};
  const result: VIMapType = {};
  tenantSystems.map((tenantSystem) => {
    const system = allSystems.find((sys) => sys.name === tenantSystem);
    const viList =
      interfaces.filter((vi) => vi.system_name === tenantSystem) || [];
    if (system) {
      result[system.location] = { system: system.name, viList: [...viList] };
    }
  });

  return result;
}
