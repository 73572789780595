import React, { FC } from "react";
import { ServiceBGPNeighborWithStatus } from "../../../../../../helpers/api/apiTypes";
import BGPLabel from "./BGPLabel";
import styles from "./BGPLabelContainer.module.scss";
import { classNames } from "../../../../../../helpers/common/classNames";

type Props = {
  className: string;
  bgpNeighbors: Array<ServiceBGPNeighborWithStatus> | undefined;
};
const BGPLabelContainer: FC<Props> = ({ className, bgpNeighbors }) => {
  if (!bgpNeighbors || !bgpNeighbors.length) {
    return <BGPLabel id={"zeroBGP"} bgpStatus="Absent" />;
  }

  return (
    <div className={classNames(styles.wrapper, className)}>
      {bgpNeighbors?.map((n, i) => (
        <BGPLabel
          key={n.id + i}
          id={"zeroBGP"}
          bgpStatus={n.status?.state}
          className={styles.child}
          style={{
            zIndex: bgpNeighbors.length - i,
            marginRight: 22 * i,
          }}
        />
      ))}
    </div>
  );
};
export default BGPLabelContainer;
