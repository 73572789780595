import { CellProps } from "react-table";
import Timestamp from "../../../../pages/EventsPage/common/Timestamp";
import { HALog } from "./types";
import Label from "../../../common/table/Label";
import styles from "./HALogsColumns.module.scss";
import CaretBig from "../../../icons/CaretBig";
import { ACCENT } from "../../../../helpers/common/colorAliases";

export const haLogsColumns = (
  hoveredRow?: number,
  selectedRow?: number
): Array<any> => {
  return [
    {
      Header: "Timestamp",
      accessor: "timestamp" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        return <Timestamp value={value} />;
      },
    },
    {
      Header: "Event type",
      accessor: "event_type" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        if (!value) return null;
        return value === "NSOS_Health" ? (
          <Label isOutlined className={styles.nsosLabel}>
            {value}
          </Label>
        ) : (
          <Label isOutlined className={styles.roleChangeLabel}>
            {value}
          </Label>
        );
      },
    },

    {
      Header: "Node name",
      accessor: "node_name" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        if (!value) return null;
        return <div>{value}</div>;
      },
    },
    {
      Header: "Current score",
      accessor: "curr_score" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        if (value > 90) {
          return <Label className={styles.currScoreGreenLabel}>{value}</Label>;
        } else if (value > 75) {
          return <Label className={styles.currScoreOrangeLabel}>{value}</Label>;
        } else if (value < 75) {
          return <Label className={styles.currScoreRedLabel}>{value}</Label>;
        } else {
          return <Label>{"-"}</Label>;
        }
      },
    },
    {
      Header: "Previous score",
      accessor: "prev_score" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        if (value > 90) {
          return (
            <Label isOutlined className={styles.prevScoreGreenLabel}>
              {value}
            </Label>
          );
        } else if (value > 75) {
          return (
            <Label isOutlined className={styles.prevScoreOrangeLabel}>
              {value}
            </Label>
          );
        } else if (value < 75) {
          return (
            <Label isOutlined className={styles.prevScoreRedLabel}>
              {value}
            </Label>
          );
        } else {
          return <Label isOutlined>{"-"}</Label>;
        }
      },
    },

    {
      Header: "Reasons",
      accessor: "first_reason_field" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        if (value === "undefined") return <div>{"-"}</div>;
        return <div>{value}</div>;
      },
    },
    {
      Header: "New Node Role",
      accessor: "node_role" as keyof any,
      Cell: ({ cell: { value } }: CellProps<HALog>) => {
        if (!value) return null;
        return <div>{value}</div>;
      },
    },
    {
      Header: "",
      accessor: "id" as keyof any,
      Cell: ({ row: { original } }: CellProps<HALog>) => {
        const isHovered = original.id === hoveredRow;
        const isActive = original.id === selectedRow;

        return isHovered || isActive ? (
          <CaretBig color={ACCENT} className={styles.caret} />
        ) : (
          ""
        );
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof any }));
};
