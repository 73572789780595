import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ReloadIcon({ color, onClick }: IconProps): ReactElement {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path d="M1.875 7.5 3.75 5.25H0L1.875 7.5Z" fill={color || FONT} />
      <path
        d="M1.8 6a4.2 4.2 0 1 1 2.4 3.796"
        stroke={color || FONT}
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ReloadIcon;
