import React, { ReactElement, useCallback } from "react";
import { Switch } from "react-router-dom";
import { RouteEntry } from "../pages/types";
import {
  TRAFFIC_STATISTIC,
  EVENTS,
  VROUTER,
} from "../helpers/navigation/entries";
import { useMatchPath } from "../helpers/hooks/navigationHooks";
import { PathEntry } from "../helpers/types";
import { addPrefix } from "../helpers/navigation";
import { Routes } from "../pages/common";
import { OneSystemTabs } from "../components/common/navigation/OneSystemTabs";
import TrafficStatisticsPage from "../components/systems/oneSystem/TrafficStatisticsPage";
import SystemEventsPage from "../components/systems/oneSystem/SystemEventsPage";
import VRoute from "../components/systems/oneSystem/VRouter";
import { useOneSystemContext } from "../contexts/systemsContext/OneSystemContext";

const reportRoutes: Array<RouteEntry> = [
  { pathFunc: TRAFFIC_STATISTIC, component: TrafficStatisticsPage },
  { pathFunc: EVENTS, component: SystemEventsPage },
  { pathFunc: VROUTER, component: VRoute },
];

function SystemReportingContainer(): ReactElement {
  const path = useMatchPath();

  // todo remove from here (retrieve directly, where it is needed)
  const { system } = useOneSystemContext();

  const fullPath = useCallback((entry: PathEntry) => addPrefix(entry, path), [
    path,
  ]);

  return (
    <Switch>
      <Routes
        routes={reportRoutes}
        tabs={OneSystemTabs}
        pathFormer={(fn) => fullPath(fn())}
        additionParameter={system?.name}
        defaultPath={fullPath(TRAFFIC_STATISTIC())}
      />
    </Switch>
  );
}
export default SystemReportingContainer;
