import React, { FC, useState } from "react";
import meshStatus from "./img/meshStatus.svg";
import sysBasic from "./img/sysBasic.svg";
import sysActive from "./img/sysActive.svg";

import styles from "./MeshNetworkContent.module.scss";

const MeshNetworkContent: FC = () => {
  const [isClicked, setIsClicked] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <img src={meshStatus} alt="" />
      {isClicked ? (
        <img src={sysActive} alt="" onClick={() => setIsClicked(false)} />
      ) : (
        <img src={sysBasic} alt="" onClick={() => setIsClicked(true)} />
      )}
    </div>
  );
};

export default MeshNetworkContent;
