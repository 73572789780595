import { ReactElement } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import {
  PROVISIONING,
  USER_GROUPS,
  IP_GROUPS,
  NETWORK,
  REMOTE_USERS,
  REMOTE_USERS_STATUS,
} from "../../../helpers/navigation/entries";
import UsersPage from "../../../pages/UsersPage";
import UserGroupsPage from "../../../pages/UserGroupsPage";
import IPGroupsPage from "../../../pages/IPGroupsPage";
import RemoteUserStatusPage from "../../../pages/RemoteUserStatusPage";

function TenantRemoteUsers(): ReactElement {
  const { path } = useRouteMatch();
  const customRoot = [NETWORK(), REMOTE_USERS()];
  return (
    <Switch>
      <Route path={path + "/" + PROVISIONING().path}>
        <UsersPage customRoot={customRoot} />
      </Route>
      <Route path={path + "/" + USER_GROUPS().path}>
        <UserGroupsPage customRoot={customRoot} />
      </Route>
      <Route path={path + "/" + REMOTE_USERS_STATUS().path}>
        <RemoteUserStatusPage />
      </Route>
      <Route path={path + "/" + IP_GROUPS().path}>
        <IPGroupsPage customRoot={customRoot} />
      </Route>
      <Route path={path}>
        <Redirect to={path + "/" + PROVISIONING().path} />
      </Route>
    </Switch>
  );
}

export default TenantRemoteUsers;
