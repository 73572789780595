import React, { FC } from "react";
import { SectionHeader } from "../../../../components/common/SectionHeader/SectionHeader";
import { useNetworkContext } from "../../context/NetworkContext";
import { ConnectionRow } from "../../types/connections";
import { StatSection } from "./StatSection";

type Props = {
  group: ConnectionRow;
};
export const StatGroup: FC<Props> = ({ group }) => {
  const { connections } = useNetworkContext();

  return (
    <>
      <SectionHeader title={group.name.label} />
      {group.subRows.map((vi, index) => {
        const connection = connections.find((c) => c.name === vi.name);
        return (
          <StatSection
            connection={connection}
            key={index}
            type="interface"
            name={vi.name}
            remote_type={vi.labels?.remote_type}
          />
        );
      })}
    </>
  );
};
