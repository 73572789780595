import { CSSProperties, FC, useEffect, useState } from "react";
import styles from "./ResultTable.module.scss";
import Table from "../../../../components/common/table/newTable/Table";
import SeacrhPlaceholder from "../SeacrhPlaceholder";
import ResultTableHeader from "./components/ResultTableHeader";
import TableHeader from "../../../../components/common/table/newTable/rows/TableHeader";
import { RequestStatus } from "../../../../helpers/types";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";

type Props = {
  title: string;
  count?: number;
  isCollapsible?: boolean;
  data?: Array<any>;
  columns: Array<any>;
  grid?: string;
  status?: RequestStatus;
  style?: CSSProperties;
  goto: string;
};

const ResultTable: FC<Props> = ({
  title,
  count,
  isCollapsible,
  data,
  columns,
  grid,
  status,
  style,
  goto,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const handleToggleCollapse = () => setIsCollapsed((prev) => !prev);

  const isPending = status?.state === "pending";
  const isEmpty = (!data || data?.length === 0) && !isPending;

  useEffect(() => {
    if (data && data?.length === 0 && isCollapsible) {
      setIsCollapsed(true);
    }
  }, [data, isCollapsible]);

  return (
    <div className={styles.tableContainer} style={style}>
      <ResultTableHeader
        title={title}
        isCollapsible={isCollapsible}
        isCollapsed={isCollapsed}
        toggleCollapse={handleToggleCollapse}
        count={count}
        goto={goto}
      />
      {!isCollapsed && (
        <>
          {isPending && <PageLoader />}
          {isEmpty ? (
            <SeacrhPlaceholder />
          ) : (
            <Table
              columns={columns}
              header={TableHeader}
              data={data || []}
              gridColumnTemplate={grid || "1fr 1fr"}
              count={data?.length || 0}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ResultTable;
