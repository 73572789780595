import { VRFType } from "./api/apiTypes";
import { configApi } from "./api/ConfigApi";

export const fetchAndAppendVRF = async <D extends { vrf_id: number }>(
  system: string,
  arr: Array<D>
): Promise<Array<D & { vrf?: VRFType }>> => {
  const copy: Array<D & { vrf?: VRFType }> = [...arr];
  const { ok, result } = await configApi.getVRFList(system);
  await Promise.all(
    arr.map(
      async ({ vrf_id }, i): Promise<any> => {
        copy[i] = {
          ...copy[i],
          vrf: ok ? result?.find((vrf) => +vrf.id === +vrf_id) : undefined,
        };
      }
    )
  );

  return copy;
};
