import React, { FC, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useMatchPath } from "../../helpers/hooks/navigationHooks";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useUserContext } from "../../contexts/UserContext";
import { useDashboardFilterContext } from "../../contexts/dashboardContext/DashboardFilterContext";
import { UserRoles } from "../../helpers/rolesHelpers";
import DashboardPlaceholder from "./components/DashboardPlaceholder";
import OverviewPage from "../OverviewPage";
import TrendsPage from "../Trends";
import TopologyPage from "../TopologyPage";
import {
  APPLICATIONS,
  CYBER_THREATS,
  INSIGHTS_OVERVIEW,
  INSIGHTS_TRENDS,
  TOPOLOGY,
  TRAFFIC,
} from "../../helpers/navigation/entries";
import ApplicationsDashboard from "../ApplicationsDashboard";
import TrafficDashboard from "../TrafficDashboard";
import CyberThreatsStat from "../CyberThreatsPage/CyberThreatsStat/CyberThreatsDashboard";
import DashboardHeader from "./components/header/DashboardHeader";

type Props = {};

const InsightsContent: FC<Props> = () => {
  const path = useMatchPath();
  const { selectedTenant } = useGlobalFilterContext();
  const { user } = useUserContext();
  const {
    fetchSystems,
    isSystemsExist,
    systemsStatus,
    fetchTenants,
    isTenantsExist,
    tenantsStatus,
    fetchData,
  } = useDashboardFilterContext();

  React.useEffect(() => {
    fetchSystems();
    fetchTenants();
    if (selectedTenant) fetchData(selectedTenant);
  }, [selectedTenant]);

  const isItTenant = useMemo(() => user?.role === UserRoles?.TENANT, [user]);
  if ((!isSystemsExist || !isTenantsExist) && !isItTenant) {
    return (
      <DashboardPlaceholder
        status={!isSystemsExist ? systemsStatus : tenantsStatus}
        type={!isSystemsExist ? "system" : "tenant"}
      />
    );
  }

  return (
    <Switch>
      <Route path={path + "/" + INSIGHTS_OVERVIEW().path} exact>
        <OverviewPage tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/" + INSIGHTS_TRENDS().path} exact>
        <TrendsPage tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/" + TOPOLOGY().path} exact>
        <TopologyPage tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/" + APPLICATIONS().path}>
        <ApplicationsDashboard tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/" + TRAFFIC().path}>
        <TrafficDashboard tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/" + CYBER_THREATS().path}>
        <CyberThreatsStat tabs={DashboardHeader} />
      </Route>
      <Route path={path + "/"} exact>
        <Redirect to={path + "/" + TOPOLOGY().path} />
      </Route>
    </Switch>
  );
};

export default InsightsContent;
