import { ReactElement } from "react";
import TextWithIcon from "../../../../components/common/table/TextWithIcon";
import { GREY_FONT } from "../../../../helpers/common/colorAliases";
import { maskedStr } from "../../../../helpers/getMaskedStr";
import { getPortTypeIcon } from "./getPortTypeIcon";
import LabelArray from "../../../../components/common/table/LabelArray";
import { CONNECTION_TYPE_GROUP } from "../ConnectionConfigurator/consts";

export const renderFieldValue = (
  title: string,
  value: string | Array<string>,
  endpoint_type: string
): ReactElement | string => {
  switch (title) {
    // case "endpoint_location":
    //   return (
    //     <TextWithIcon
    //       text={value as string}
    //       icon={<LocationFillIcon color={GREY_FONT} />}
    //     />
    //   );
    case "Connection_type":
      const name =
        CONNECTION_TYPE_GROUP.find((item) => item.value === value)?.label ||
        "-";
      const Icon = getPortTypeIcon(endpoint_type);
      return <TextWithIcon text={name} icon={<Icon color={GREY_FONT} />} />;
    case "Customer_ID":
      return maskedStr(value as string);
    case "ip_addresses":
      return <LabelArray values={value as Array<string>} />;
    default:
      return value?.toString() || "-";
  }
};
