import React, { FC } from "react";
import InputWithList from "../../../../../../components/common/formComponents/InputWithList";
import { useSegmentsList } from "../../../../../../helpers/api/onboarding/useSegmentsList";

type Props = {
  list?: Array<string>;
  onChange: (field: string, val: Array<string>) => void;
  error?: string;
};

const SegmentsComponent: FC<Props> = ({ list = [], onChange, error }) => {
  const [segments] = useSegmentsList();

  const handleAdd = (val: Array<string>) => {
    if (segments) onChange("network_segments", [...list, ...val]);
  };

  const handleRemove = (val: string) => {
    onChange(
      "network_segments",
      list.filter((el) => el !== val)
    );
  };

  return (
    <InputWithList
      name={"network_segments"}
      label="Segments for connection"
      placeholder="Separate with space"
      onAdd={handleAdd}
      onRemove={handleRemove}
      isFullValue
      medium
      error={error}
      valuesList={list}
    />
  );
};

export default SegmentsComponent;
