import { ReactElement } from "react";
import { DropdownItem } from "./types";

type Props = {
  icon: ReactElement;
};

export function mapStringToItemWithIcon(
  this: Props,
  val: string
): DropdownItem {
  return { key: val, icon: this.icon };
}
