import React, { FC } from "react";
import styles from "./PortTitle.module.scss";
import { ConnectionPortDetails } from "../../../../types";
import TextWithIcon from "../../../../../../../components/common/table/TextWithIcon";
import { GREY_FONT } from "../../../../../../../helpers/common/colorAliases";
import { getPortTypeIcon } from "../../../../helpers/getPortTypeIcon";
import BGPLabelContainer from "../../BGPLabel/BGPLabelContainer";
import { classNames } from "../../../../../../../helpers/common/classNames";

type Props = {
  data: ConnectionPortDetails;
  className: string;
};
const PortTitle: FC<Props> = ({ data, className }) => {
  const Icon = getPortTypeIcon(data.endpoint_type);

  return (
    <div className={classNames(styles.wrapper, className)}>
      <TextWithIcon
        className={styles.title}
        icon={<Icon color={GREY_FONT} />}
        text={data.config.endpoint_name}
      />
      {data.hasOwnProperty("bgpNeighbors") && (
        <BGPLabelContainer
          className={styles.bgpContainer}
          bgpNeighbors={data.bgpNeighbors}
        />
      )}
    </div>
  );
};
export default PortTitle;
