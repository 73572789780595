import React, { FC, useState } from "react";
import IconBlankButton from "../../../common/buttons/IconBlankButton";
import SystemEditIcon from "../../../icons/SystemEditIcon";
import ThreeDotsIcon from "../../../icons/ThreeDotsIcon";
import styles from "./SystemControls.module.scss";
import SystemControlsTooltip from "./SystemControlsTooltip";
import { classNames } from "../../../../helpers/common/classNames";

type SystemControlsProps = {
  onEdit: () => void;
  className?: string;
};

const SystemControls: FC<SystemControlsProps> = ({ onEdit, className }) => {
  const [hideTooltip, setHideTooltip] = useState(true);

  return (
    <div className={classNames(className, styles.controlsWrapper)}>
      <IconBlankButton
        id={"systemControlsEditInfo"}
        icon={SystemEditIcon}
        onClick={onEdit}
        label="Edit info"
        disabled
      />
      <IconBlankButton
        id={"systemControlsThreeDots"}
        icon={ThreeDotsIcon}
        onClick={() => setHideTooltip(!hideTooltip)}
        className={styles.showMoreIcon}
      />
      <SystemControlsTooltip
        isHide={hideTooltip}
        className={styles.tooltip}
        onClose={() => setHideTooltip(true)}
      />
    </div>
  );
};

export default SystemControls;
