import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function CPSIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="12" r="8" stroke={color || FONT} strokeWidth="2" />
      <path fill={color || FONT} d="M10 1h2v3h-2zM10 8h2v4h-2zM10 14v-2h4v2z" />
    </svg>
  );
}

export default CPSIcon;
