import React, { FC, useEffect, useRef, useState } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import DuplicateIcon from "../../icons/DuplicateIcon";
import IconBlankButton from "./IconBlankButton";
import styles from "./CopyTextBtn.module.scss";

type Props = {
  className?: string;
  value: string;
  disabled?: boolean;
};

const CopyTextBtn: FC<Props> = ({ className, value, disabled }) => {
  const [isMsgShown, setIsMsgShown] = useState<boolean>(false);

  const i = useRef<number | undefined>(undefined);
  useEffect(() => {
    if (isMsgShown) {
      i.current = window.setInterval(() => {
        setIsMsgShown(false);
      }, 600);
    }
    return () => clearInterval(i.current);
  }, [isMsgShown]);

  const copyToClipboard = (str: string): void => {
    const el = document.createElement("textarea");
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setIsMsgShown(true);
  };
  return (
    <div className={className}>
      {isMsgShown && <div className={styles.tooltip}>Copied</div>}
      <IconBlankButton
        id={"copyText"}
        color={GREY_FONT}
        icon={DuplicateIcon}
        onClick={() => copyToClipboard(value)}
        disabled={disabled}
      />
    </div>
  );
};

export default CopyTextBtn;
