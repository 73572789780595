import React, { FC } from "react";
import { classNames } from "../../helpers/common/classNames";
import styles from "./Section.module.scss";

type Props = {
  className?: string;
  title: string | number | undefined;
  subtitle?: string | number | undefined;
  columnsCount?: number;
  btmAlign?: boolean;
  gridTemplateString?: string;
};

const Section: FC<Props> = ({
  className,
  title,
  subtitle,
  children,
  columnsCount,
  btmAlign,
  gridTemplateString,
}) => {
  const childrenCount = columnsCount || React.Children.count(children);
  const gridTemplate = {
    gridTemplateColumns: gridTemplateString || `repeat(${childrenCount}, 1fr)`,
  };
  const isOneChild = childrenCount <= 1;
  return (
    <div className={classNames(styles.sectionWrapper, className)}>
      <div className={styles.title}>
        {title}
        <div className={styles.subtitle}> {subtitle} </div>
      </div>
      <div
        className={classNames(
          styles.contentWrapper,
          isOneChild && styles.oneColumn,
          btmAlign && styles.btmAlign
        )}
        style={gridTemplate}
      >
        {children}
      </div>
    </div>
  );
};

export default Section;
