import { Attack, AttackType, DDoSAttackStatus } from "./api.types";

export const mockAttackList: Array<Attack> = [
  {
    id: 1,
    type: AttackType.ACTIVE,
    subRows: [
      {
        id: 1,
        event: DDoSAttackStatus.ATTACKED,
        mitigationPattern: ["TCP SYN"],
        target: ["10.112.52.7"],
        mitigation: "Filter 80%",
        rule: "SYN protection",
        protectedScope: ["Office Net"],
        thresholdRatio: "250/50",
        timestamp: "16:21",
      },
      {
        id: 2,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["TCP SYN 443"],
        target: ["10.112.52.7"],
        mitigation: "Filter 90%",
        rule: "SYN Flood",
        protectedScope: ["Office Net"],
        thresholdRatio: "280/50",
        timestamp: "16:23",
      },
      {
        id: 3,
        event: DDoSAttackStatus.WATERMARK_REACHED,
        mitigationPattern: ["TCP SYN"],
        target: ["10.112.52.7"],
        mitigation: "Filter 80%",
        rule: "YN protection",
        protectedScope: ["Office Net"],
        thresholdRatio: "3/50",
        timestamp: "17:13",
      },
      {
        id: 4,
        event: DDoSAttackStatus.THRESHOLD_BREACHED,
        mitigationPattern: ["TCP SYN"],
        target: ["10.112.52.7"],
        rule: "SYN Flood",
        mitigation: "Filter 80%",
        protectedScope: ["Office Net"],
        thresholdRatio: "430/50",
        timestamp: "17:19",
      },
      {
        id: 5,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["TCP SYN 80"],
        target: ["10.112.52.7"],
        mitigation: "Filter 90%",
        rule: "SYN Flood",
        protectedScope: ["Office Net"],
        thresholdRatio: "425/50",
        timestamp: "17:21",
      },
      {
        id: 6,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["TCP SYN 80 TTL"],
        target: ["10.112.52.7"],
        mitigation: "Filter 100%",
        rule: "SYN Flood",
        protectedScope: ["Office Net"],
        thresholdRatio: "480/50",
        timestamp: "17:40",
      },
      {
        id: 7,
        event: DDoSAttackStatus.WATERMARK_REACHED,
        mitigationPattern: ["TCP SYN"],
        target: ["10.112.52.7"],
        mitigation: "Filter 80%",
        rule: "SYN Flood",
        protectedScope: ["Office Net"],
        thresholdRatio: "4/50",
        timestamp: "17:50",
      },
      {
        id: 8,
        event: DDoSAttackStatus.CLOSED,
        mitigationPattern: ["TCP SYN"],
        target: ["10.112.52.7"],
        mitigation: "None",
        rule: "SYN Flood",
        protectedScope: ["Office Net"],
        thresholdRatio: "3/50",
        timestamp: "17:55",
      },
    ],
  },
  {
    id: 2,
    type: AttackType.ACTIVE,
    subRows: [
      {
        id: 9,
        event: DDoSAttackStatus.ATTACKED,
        mitigationPattern: ["UDP"],
        target: ["10.114.132.22"],
        mitigation: "Filter 80%",
        rule: "UDP reflection",
        protectedScope: ["Servers"],
        thresholdRatio: "980/250",
        timestamp: "22:20",
      },
      {
        id: 10,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["UDP Src 53"],
        target: ["10.114.132.22"],
        mitigation: "Filter 90%",
        rule: "UDP reflection",
        protectedScope: ["Servers"],
        thresholdRatio: "1123/250",
        timestamp: "22:22",
      },
      {
        id: 11,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["UDP Src 53 TOS"],
        target: ["10.114.132.22"],
        mitigation: "Filter 100%",
        rule: "UDP reflection",
        protectedScope: ["Servers"],
        thresholdRatio: "1310/250",
        timestamp: "23:57",
      },
      {
        id: 12,
        event: DDoSAttackStatus.WATERMARK_REACHED,
        mitigationPattern: ["UDP"],
        target: ["10.114.132.22"],
        mitigation: "None",
        rule: "UDP reflection",
        protectedScope: ["Servers"],
        thresholdRatio: "20/250",
        timestamp: "1:21",
      },
      {
        id: 13,
        event: DDoSAttackStatus.CLOSED,
        mitigationPattern: ["UDP"],
        target: ["10.114.132.22"],
        mitigation: "None",
        rule: "UDP reflection",
        protectedScope: ["Servers"],
        thresholdRatio: "21/250",
        timestamp: "1:26",
      },
    ],
  },
  {
    id: 3,
    type: AttackType.ACTIVE,
    subRows: [
      {
        id: 14,
        event: DDoSAttackStatus.ATTACKED,
        mitigationPattern: ["UDP"],
        target: ["10.114.132.138"],
        mitigation: "Filter 80%",
        rule: "Frag",
        protectedScope: ["Servers"],
        thresholdRatio: "20/5",
        timestamp: "1:01",
      },
      {
        id: 15,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["UDP TTL 20"],
        target: ["10.114.132.138"],
        mitigation: "Filter 90%",
        rule: "Frag",
        protectedScope: ["Servers"],
        thresholdRatio: "1400/5",
        timestamp: "1:03",
      },
      {
        id: 16,
        event: DDoSAttackStatus.WATERMARK_REACHED,
        mitigationPattern: ["UDP"],
        target: ["10.114.132.138"],
        mitigation: "None",
        rule: "Frag",
        protectedScope: ["Servers"],
        thresholdRatio: "20/5",
        timestamp: "1:50",
      },
      {
        id: 17,
        event: DDoSAttackStatus.ATTACKED,
        mitigationPattern: ["UDP"],
        target: ["10.114.132.138"],
        mitigation: "None",
        rule: "Frag",
        protectedScope: ["Servers"],
        thresholdRatio: "20/5",
        timestamp: "1:55",
      },
    ],
  },
  {
    id: 4,
    type: AttackType.CLOSED,
    subRows: [
      {
        id: 18,
        event: DDoSAttackStatus.ATTACKED,
        mitigationPattern: ["IP"],
        target: ["10.124.12.2"],
        mitigation: "Filter 70%",
        rule: "IP general",
        protectedScope: ["Cloud"],
        thresholdRatio: "1900/400",
        timestamp: "14:50",
      },
      {
        id: 19,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["IP ICMP"],
        target: ["10.124.12.2"],
        mitigation: "Filter 80%",
        rule: "IP general",
        protectedScope: ["Cloud"],
        thresholdRatio: "1820/400",
        timestamp: "14:53",
      },
      {
        id: 20,
        event: DDoSAttackStatus.UPDATED,
        mitigationPattern: ["IP ICMP TOS"],
        target: ["10.124.12.2"],
        mitigation: "Filter 90%",
        rule: "IP general",
        protectedScope: ["Cloud"],
        thresholdRatio: "1910/400",
        timestamp: "16:40",
      },
      {
        id: 21,
        event: DDoSAttackStatus.WATERMARK_REACHED,
        mitigationPattern: ["IP"],
        target: ["10.124.12.2"],
        mitigation: "None",
        rule: "IP general",
        protectedScope: ["Cloud"],
        thresholdRatio: "240/400",
        timestamp: "17:01",
      },
      {
        id: 22,
        event: DDoSAttackStatus.CLOSED,
        mitigationPattern: ["IP"],
        target: ["10.124.12.2"],
        mitigation: "None",
        rule: "IP general",
        protectedScope: ["Cloud"],
        thresholdRatio: "230/400",
        timestamp: "17:05",
      },
    ],
  },
];
