import React, { FC } from "react";
import TagList from "../../../../../../components/common/formComponents/TagList";
import { useSegmentsList } from "../../../../../../helpers/api/onboarding/useSegmentsList";

type Props = {
  list?: Array<string>;
  onChange: (field: string, val: Array<string>) => void;
};
const SegmentsTabsComponent: FC<Props> = ({ list, onChange }) => {
  const [segments, requestStatus] = useSegmentsList();

  const handleToggle = (val: string) => {
    if (!list) return;
    const isValIncluded = list.includes(val);
    const newList = isValIncluded
      ? list.filter((el) => el !== val)
      : [...list, val];
    onChange("network_segments", newList);
  };

  return (
    <>
      {list && (
        <TagList
          onAdd={(val) => onChange("network_segments", val)}
          onToggle={handleToggle}
          valuesList={list}
          list={segments || []}
          label="Segments for connection"
          status={requestStatus}
        />
      )}
    </>
  );
};

export default SegmentsTabsComponent;
