import AbstractDialogContextContainer, {
  DialogFunc,
  DialogRequestStatuses,
  DialogValueState,
} from "../../../contexts/common/AbstractDialogContext";
import { createContextUtils } from "../../../contexts/common/utils";
import { VtepVni } from "../types";
import { configApi } from "../../../helpers/api/ConfigApi";
import {
  IOneSystemContext,
  withOneSystemContextProps,
} from "../../../contexts/systemsContext/OneSystemContext";
import { IVniContext, withVNIContextProps } from "../VNIContext";
import { getCreateParams } from "../apiUtils/getCreateParams";

type RequestStatuses = DialogRequestStatuses;
type State = DialogValueState<VtepVni>;

type IState = State & RequestStatuses;
type IFunc = DialogFunc<VtepVni>;

const [VNIDialogContext, useVNIDialogContext] = createContextUtils<
  IState,
  IFunc
>();
export { useVNIDialogContext };

class VNIDialogContextContainer extends AbstractDialogContextContainer<
  VtepVni,
  IState,
  RequestStatuses,
  IFunc,
  IVniContext & IOneSystemContext
> {
  Context = VNIDialogContext;

  _updateSystems = (ok: boolean) => {
    if (ok) {
      this.props.fetchList();
    }
  };
  add = async (vni: Partial<VtepVni>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._addWrap(() =>
      configApi.addVNI(system.name, getCreateParams(vni as VtepVni))
    );
    this._updateSystems(ok);

    return ok;
  };
  // not implemented
  edit = async (vni: Partial<VtepVni>): Promise<boolean> => {
    const { system } = this.props;
    if (!system) return false;
    const { ok } = await this._editWrap(() => configApi.editVNI());
    this._updateSystems(ok);
    return ok;
  };

  funcs = {
    edit: this.edit,
    add: this.add,
  };
}

export default withVNIContextProps(
  withOneSystemContextProps(VNIDialogContextContainer)
);
