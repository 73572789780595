import { DropdownItem } from "../../../../../helpers/types";
import { ConnectionApiExtended } from "../../types";
import { PORT_TYPES_MAP } from "../consts";

export const getPortType = (
  connection?: ConnectionApiExtended
): DropdownItem | undefined => {
  const portTypeKey = (): string => {
    switch (connection?.z_endpoint_name) {
      case "Cloud_Router":
        return "cloudRouter";
      case "ELAN":
        return "elan";
      default:
        return "azPorts";
    }
  };
  return PORT_TYPES_MAP.find((port) => port.key === portTypeKey());
};
