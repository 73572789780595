import React, { FC, useEffect } from "react";
import GrafanaGraph from "../../../../components/common/charts/GrafanaGraph";
import GrafanaContainer, {
  GRAFANA_TYPES,
} from "../../../../components/common/charts/GrafanaContainer/GrafanaContainer";
import { useGlobalFilterContext } from "../../../../contexts/GlobalFilterContext";
import { useTimerangeContext } from "../../../../contexts/TimerangeContext";
import { getTrafficLink } from "../../common/trafficHelper";
import TrafficLayout from "../../common/TrafficLayout";
import { useTrafficContext } from "../../TrafficContext";
import styles from "./DetailedRecords.module.scss";
import { useTenantContext } from "../../../../contexts/tenantsContext/TenantsContext";
import { PageLoader } from "../../../../components/common/loadStates/LoaderIcon";
import ServicesRedirectPlaceholder from "../../../../components/ServicesRedirectPlaceholder";

const DetailedRecords: FC<{ tabs: any }> = ({ tabs }) => {
  const { filters } = useTrafficContext();
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();
  useEffect(() => {
    if (selectedTenant) {
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <TrafficLayout tabs={tabs}>
      {servicesStatus && !servicesStatus.sdr.overall ? (
        <ServicesRedirectPlaceholder
          type="sdr"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.sdr.adminSide}
        />
      ) : (
        <GrafanaContainer
          type={GRAFANA_TYPES.SDR}
          className={styles.basicChart}
        >
          <GrafanaGraph
            link={getTrafficLink(
              10,
              timeRange.general,
              selectedTenant,
              filters
            )}
          />
        </GrafanaContainer>
      )}
    </TrafficLayout>
  );
};

export default DetailedRecords;
