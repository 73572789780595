import React, { FC } from "react";

type Props = { vlanId: string; x: number; y: number; isShift?: boolean };

const VLANLabel: FC<Props> = ({ vlanId, x, y, isShift }) => {
  const textWidth = 4 * 8 + vlanId.toString().length * 8 + 8;
  return (
    <svg
      width={textWidth + 2}
      height="20"
      viewBox={`0 0 ${textWidth} 20`}
      xmlns="http://www.w3.org/2000/svg"
      x={isShift ? x - textWidth + 4 : x - 4}
      y={y - 20 / 2}
    >
      <rect
        x="0.501953"
        y="0.5"
        width={textWidth}
        height="17"
        rx="3.5"
        fill="#F1EEFE"
        stroke="#BEB8EA"
      />
      <text
        x={4}
        y={14}
        textAnchor="start"
        fill="var(--grey60)"
        fontSize={12}
        fontWeight={600}
      >
        <tspan>VLAN </tspan>
        <tspan fill="var(--font)">{vlanId}</tspan>
      </text>
    </svg>
  );
};

export default VLANLabel;
