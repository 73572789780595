import { FC } from "react";
import {
  ChangeField,
  FieldsType,
} from "../../../../pages/ZayoPages/Connections/ConnectionConfigurator/types";
import Section from "../../../common/Section";
import DropdownBasic from "../../../common/Dropdown/DropdownBasic";
import Input from "../../../common/formComponents/Input";
import styles from "./GeneralSection.module.scss";
type Props = {
  className: string;
  fields: FieldsType;
  onChange: ChangeField;
  isEdit?: boolean;
  errors?: { [key: string]: string };
};
const GeneralSection: FC<Props> = ({
  className,
  fields,
  onChange,
  isEdit,
  errors,
}) => {
  return (
    <Section title={"General"} columnsCount={2} className={className}>
      <Input
        label={"Tenant Full Name"}
        name="full_name"
        placeholder=""
        value={fields.full_name}
        medium
        onChange={(e) => onChange("full_name", e.target.value)}
        error={errors && errors["full_name"]}
      />

      <Input
        label={"Tenant Name"}
        name="short_name"
        placeholder=""
        value={fields.name || fields.full_name}
        medium
        disabled={isEdit}
        onChange={(e) => onChange("name", e.target.value)}
        error={errors && errors["name"]}
      />

      <DropdownBasic
        id={"quotaPackageName"}
        onChange={(value) => onChange("quota_package_name", value.key)}
        selected={{
          key: fields.quota_package_name,
          value: fields.quota_package_name,
        }}
        label="Package"
        placeholder=""
        itemsList={["Essentials", "Premium", "Pro"].map((el) => ({
          key: el,
          value: el,
        }))}
        isMedium
        error={errors && errors["quota_package_name"]}
      />

      {!isEdit && (
        <>
          <Input
            label={
              <div>
                Admin name
                <span className={styles.optionalSpan}> - optional </span>
              </div>
            }
            name="adminName"
            placeholder=""
            value={fields.first_name}
            medium
            onChange={(e) => onChange("first_name", e.target.value)}
            error={errors && errors["first_name"]}
          />

          <div className={styles.adminUser}>
            <Input
              label={"Admin user"}
              name="adminUsername"
              placeholder="Username"
              value={fields.username}
              medium
              onChange={(e) => onChange("username", e.target.value)}
              error={errors && errors["username"]}
            />

            <Input
              label={<div className={styles.transparentLabel}> password</div>}
              name="adminPass"
              placeholder="Password"
              value={fields.password}
              isPlaceholderAlwaysVisible
              medium
              onChange={(e) => onChange("password", e.target.value)}
              error={errors && errors["password"]}
            />
          </div>
          <Input
            label={"Email address"}
            name="email"
            placeholder=""
            value={fields.email}
            medium
            onChange={(e) => onChange("email", e.target.value)}
            error={errors && errors["email"]}
          />
        </>
      )}
    </Section>
  );
};
export default GeneralSection;
