import React, { FC, ReactNode, useState } from "react";

import { createContextAndUse } from "../../../contexts/common/AbstractCrudContext";
import { Res } from "../../../helpers/api/Api";
import { licenseApi } from "../../../helpers/api/LicenseApi";

import { EnterLicenseKeyFn, LicenseKey } from "../domain";

type IFunc = {
  enterLicenseKey: EnterLicenseKeyFn;
};

type IState = {
  licenseKey: LicenseKey;
  licenseKeyState: Res<null>;
};

type Props = {
  children: ReactNode;
};

const [EnterLicenseKeyContext, useContext] = createContextAndUse<
  IState,
  IFunc
>();
export const useEnterLicenseKeyContext = useContext;

export const EnterLicenseKeyContextContainer: FC<Props> = ({ children }) => {
  const [licenseKey, setLicenseKey] = useState<LicenseKey | null>(null);
  const [licenseKeyState, setLicenseKeyState] = useState<Res<null>>({
    ok: true,
  });

  const enterLicenseKey = async (key: LicenseKey) => {
    if (!key) {
      const state = { ok: false, error: "License key is missing" };

      setLicenseKeyState(state);

      return state;
    }
    setLicenseKey(key);
    const response = await licenseApi.setLicense(key);

    if (response.ok) {
      setLicenseKey(key);
    }

    setLicenseKeyState(response);

    return response;
  };

  return (
    <EnterLicenseKeyContext.Provider
      value={{ enterLicenseKey, licenseKey, licenseKeyState }}
    >
      {children}
    </EnterLicenseKeyContext.Provider>
  );
};
