import { CellProps } from "react-table";
import { CellFactory } from "../../../../../components/common/table/newTable/cells/CellsFactory";
import BGPIcon from "../../../../../components/icons/BGPIcon";
import { ServiceBGPNeighborWithStatus } from "../../../../../helpers/api/apiTypes";
import { getTimeFormMS } from "../../../../../helpers/common/dateHelpers";
import PrefixesLabel from "./PrefixesLabel";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import StatusIcon from "../../../../../components/icons/StatusIcon";
import { getColorFromStatus } from "../../../../../helpers/common/colorHelpers";
import SelectRow from "../../../../Systems/Provisioning/table/SelectRowComponent";

const cellFactory = new CellFactory<ServiceBGPNeighborWithStatus>({
  icon: BGPIcon,
});

export const serviceBGPTableHeader = (
  resetSelect: (row: undefined) => void,
  selected?: string,
  isDemo?: boolean
): Array<any> => {
  const commonEelements = [
    {
      Header: "Neighbor",
      accessor: "remote_router_ip",
      Cell: cellFactory.idCell(),
    },
    {
      Header: "Remote AS",
      accessor: "remote_asn",
    },
    {
      Header: "Status",
      accessor: "state",
      Cell: ({
        row: { original },
      }: CellProps<ServiceBGPNeighborWithStatus>) => {
        const value = isDemo ? "Established" : original?.status?.state;
        return (
          <TextWithIcon
            icon={<StatusIcon color={getColorFromStatus(value)} />}
            text={value}
          />
        );
      },
    },
    {
      Header: "Prefixes",
      accessor: "prefixes",
      Cell: ({
        row: { original },
      }: CellProps<ServiceBGPNeighborWithStatus>) => {
        return (
          <PrefixesLabel
            pfxSnt={original.status?.pfxSnt}
            pfxRcd={original.status?.pfxRcd}
          />
        );
      },
    },
  ];
  const upDownElement = {
    Header: "Up/Down",
    accessor: "peerUptimeMsec",
    Cell: ({ row: { original } }: CellProps<ServiceBGPNeighborWithStatus>) => {
      const value = original.status?.peerUptimeMsec;
      return <>{value ? getTimeFormMS(+value) : "-"}</>;
    },
  };
  const description = {
    Header: "Description",
    accessor: "description",
  };
  const chartSelector = {
    Header: () => (
      <SelectRow
        onSelect={() => resetSelect(undefined)}
        currentRow={"all"}
        selected={selected}
        withPropaganation
      />
    ),
    accessor: "chartSelector",
    Cell: ({ row: { original } }: CellProps<ServiceBGPNeighborWithStatus>) => {
      return (
        <SelectRow
          onSelect={() => null}
          currentRow={original.remote_router_ip}
          selected={selected}
          withPropaganation
        />
      );
    },
  };
  const resultArr = [...commonEelements];
  if (!isDemo) {
    resultArr.push(upDownElement);
  }
  resultArr.push(description);
  resultArr.push(chartSelector as any);

  return resultArr.map((el) => ({
    ...el,
    accessor: el.accessor as keyof ServiceBGPNeighborWithStatus,
  }));
};
