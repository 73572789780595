import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function SegmentsIcon({ color }: IconProps): ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
      <path
        fill={color || "#6B55F0"}
        fillRule="evenodd"
        d="M4.383 4.35a1.696 1.696 0 1 0-.817-.018V9.12a2.954 2.954 0 1 0 3.302 3.402h4.825a1.696 1.696 0 1 0-.06-.817H6.888a2.936 2.936 0 0 0-.512-1.348l4.111-4.112a1.695 1.695 0 1 0-.615-.54L5.818 9.758a2.94 2.94 0 0 0-1.435-.632V4.349Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SegmentsIcon;
