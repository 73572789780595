import React, { FC, useEffect } from "react";
import Input from "../../../components/common/formComponents/Input";
import Section from "../../../components/common/Section";
import { CreateFWRule } from "./FirewallRuleConfig";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./RuleSection.module.scss";
import { UserGroups } from "../../../contexts/servicesContext/FirewallContext";
import SourceBlock from "./SourceBlock";
import DestinationBlock from "./DestinationBlock";
import {
  PROTOCOLS_TYPES_MAP,
  PROTOCOL_TCP,
  PROTOCOL_ANY,
} from "../../../helpers/common/constantsAlias";

type Props = {
  fields: CreateFWRule;
  errors: any;
  onChange: (
    name: string,
    value: string | {} | Array<string> | undefined
  ) => void;
  className: string;
  groups: UserGroups | undefined;
  isSNI: boolean;
  isFQDN: boolean;
};

const RuleSection: FC<Props> = ({
  fields,
  errors,
  onChange,
  className,
  groups,
  isSNI,
  isFQDN,
}) => {
  const setPort = () => {
    isSNI && isFQDN && onChange("dst_l4_port", "443");
  };

  const setProtocol = () => {
    isSNI && isFQDN && onChange("ip_protocol", PROTOCOL_TCP);
  };

  useEffect(() => {
    setPort();
    setProtocol();
  }, [isSNI, isFQDN]);

  return (
    <Section title={"Rule"} className={className} columnsCount={3}>
      <div className={styles.column}>
        <SourceBlock
          fields={fields}
          errors={errors}
          onChange={onChange}
          groups={groups}
        />
        <DestinationBlock
          fields={fields}
          errors={errors}
          onChange={onChange}
          groups={groups}
        />
      </div>
      <div className={styles.column}>
        <Input
          label="Protocol"
          name={"ip_protocol"}
          className={styles.inputWrapperCenter}
          handleFieldChange={onChange}
          value={fields.ip_protocol}
          placeholder={fields.ip_protocol}
          error={errors && errors["ip_protocol"]}
          valuesList={PROTOCOLS_TYPES_MAP}
          medium
          isNotEditable={isSNI && isFQDN}
        />
      </div>
      <div className={styles.column}>
        <Input
          label="Source Port"
          name="src_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.src_l4_port}
          medium
          error={errors && errors["src_l4_port"]}
          isOptional
        />
        <Input
          label="Destination Port"
          name="dst_l4_port"
          className={styles.portWrapper}
          placeholder="xxx"
          handleFieldChange={onChange}
          value={fields.dst_l4_port}
          medium
          error={errors && errors["dst_l4_port"]}
          isOptional
          isNotEditable={isSNI && isFQDN}
        />
      </div>
    </Section>
  );
};
export default RuleSection;
