import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";
import { getColorFromStatus } from "../../helpers/common/colorHelpers";

function SystemIcon({ color, status, className }: IconProps): ReactElement {
  const statusColor = getColorFromStatus(status);
  return (
    <svg
      width="24"
      height="20"
      fill="none"
      viewBox="0 0 24 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33 5a2 2 0 0 0-2 2v6c0 1.1.9 2 2 2h9.34a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2H5.33ZM8.6 9a.5.5 0 0 0-.5.5v1c0 .28.22.5.5.5h2.8a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H8.6Z"
        fill={color || FONT}
      />
      {status && <circle cx="21" cy="3" r="3" fill={statusColor} />}
    </svg>
  );
}

export default SystemIcon;
