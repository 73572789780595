import React, { FC } from "react";
import Button from "../../../../../components/common/buttons/Button";
import { useFormField } from "../../../../../helpers/hooks/useFormField";
import { useDnsDashboardContext } from "../../../DnsContext";
import styles from "./FilterBody.module.scss";
import FilterFields from "./FilterFields";

const DEFAULT_FIELDS = {};

type Props = { onClose: VoidFunction };

const FilterBody: FC<Props> = ({ onClose }) => {
  const { setFilters, filters } = useDnsDashboardContext();
  const [fields, handleFieldChange, resetFields] = useFormField<any>(
    filters || DEFAULT_FIELDS
  );

  const resetFilter = () => {
    setFilters(undefined);
    const curr: { [key: string]: string } = {};
    Object.keys(fields).forEach((key) => (curr[key] = ""));
    resetFields(curr);
  };

  const handleAdd = () => {
    setFilters({ ...fields });
    onClose();
  };
  return (
    <>
      <div className={styles.filterHeader}>Filters</div>
      <FilterFields fields={fields} onChange={handleFieldChange} />
      <div className={styles.filterControls}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button onClick={handleAdd} isPrimaryBtn>
          Apply
        </Button>
      </div>
    </>
  );
};

export default FilterBody;
