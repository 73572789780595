import React from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

function InsightsIcon({ color }: IconProps): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 18L12.3876 11.7281C12.9439 10.6983 14.2616 10.3641 15.2414 11.0043L16.7586 11.9957C17.7384 12.6359 19.0561 12.3017 19.6124 11.2719L23 5"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 23L4.5 23L4.5 5"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InsightsIcon;
