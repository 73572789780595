import { CellProps } from "react-table";
import FilterTypeLabel from "../components/WebFilteringConfig/components/FilterTypeLabel";
import GrayString from "../../../components/common/table/GrayString";
import { FDQNDomain } from "../types";

export const urlListTableColumns = (): any => {
  return [
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ cell: { value } }: CellProps<FDQNDomain>) => {
        return <FilterTypeLabel type={value} />;
      },
    },
    {
      Header: "URL",
      accessor: "domain",
      Cell: ({ cell: { value } }: CellProps<FDQNDomain>) => {
        return <GrayString text={value} />;
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof FDQNDomain }));
};
