import React, { useMemo } from "react";
import { useSystemVIList } from "../../helpers/api/hooks/useSystemVIList";
import { VirtualInterfaceApi } from "../../pages/VirtualInterfacePage/types";
import DropdownBasic from "./Dropdown/DropdownBasic";

type Props = {
  tenant: string;
  selected?: VirtualInterfaceApi;
  onSelect: (virtInterface?: VirtualInterfaceApi) => void;
  label: string;
  system?: string;
};
const SystemVIDropdown: React.FC<Props> = ({
  selected,
  onSelect,
  tenant,
  label,
  system,
}) => {
  const [list] = useSystemVIList(system);
  const selectedIem = useMemo(
    () =>
      selected
        ? { value: selected.name, key: selected.name, data: selected }
        : undefined,
    [selected]
  );
  const items = useMemo(
    () =>
      (list || []).map((item) => ({
        value: item.name,
        key: item.name,
        data: item,
      })),
    [list]
  );
  return (
    <DropdownBasic
      id={label}
      onChange={(val) => onSelect(val.data)}
      selected={selectedIem}
      label={label}
      itemsList={items}
      isMedium
      disabled={!system}
    />
  );
};

export default SystemVIDropdown;
