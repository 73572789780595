import React, { FC } from "react";
import EditIcon from "../../icons/EditIcon";
import IconBlankButton from "./IconBlankButton";

type Props = {
  onClick: (arg?: any) => void;
  withLabel?: boolean;
  isOnlyOpacity?: boolean;
  disabled?: boolean;
  title?: string;
  id: string;
  className?: string;
};

const EditButton: FC<Props> = ({
  onClick,
  withLabel,
  title,
  id,
  ...restProps
}) => {
  return (
    <IconBlankButton
      id={`editBtn-${id}`}
      title={title}
      onClick={onClick}
      icon={EditIcon}
      label={withLabel ? "Edit" : undefined}
      {...restProps}
    />
  );
};
export default EditButton;
