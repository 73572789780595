import React, { ReactElement } from "react";
import styles from "./ChartsHeader.module.scss";
import { classNames } from "../../../../helpers/common/classNames";

type Props = {
  title?: string;
  controls?: ReactElement;
  className?: string;
};

const ChartsHeader: React.FC<Props> = ({ title, controls, className }) => {
  return (
    <div className={classNames(styles.header, className)}>
      <div className={styles.title}>{title}</div>
      {controls}
    </div>
  );
};

export default ChartsHeader;
