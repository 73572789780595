export const MAX_BANDWIDTH_LIST = ["100Mbps", "500Mbps", "1Gbps", "10Gbps"];
export const SUBSCRIPTION_PERIOD_LIST = [
  "Yearly",
  "Monthly",
  "Daily",
  "Hourly",
];

export const REMOTE_DEVICES_MAP = [
  { value: "strongSwan", disabled: false },
  { value: "PFsense", disabled: false },
  { value: "FortiGate", disabled: true },
  { value: "Checkpoint", disabled: true },
  { value: "PaloAlto", disabled: true },
];

export const REMOTE_USERS_LIST = [
  { title: "/21", subtitle: "for up to 2043 devices" },
  { title: "/22", subtitle: "for up to 1019 devices" },
  { title: "/23", subtitle: "for up to 507 devices" },
];
