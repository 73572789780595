import React, { FC } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import { CLOUD_TYPE_GROUP } from "../consts";
import { getCloudIcon } from "../helpers/getCloudIcon";
import styles from "./CloudSelector.module.scss";

type Props = {
  selected: string;
  onSelect: (val: string) => void;
  isOneRow?: boolean;
  disabled?: boolean;
};

const CloudSelector: FC<Props> = ({
  selected,
  onSelect,
  isOneRow,
  disabled,
}) => {
  const wrapperClasses = classNames(styles.wrapper, isOneRow && styles.oneRow);
  return (
    <div className={wrapperClasses}>
      {CLOUD_TYPE_GROUP.map((cloud) => {
        const isActive: boolean = cloud.value === selected;
        const Icon = getCloudIcon(cloud.value);
        return (
          <div
            onClick={() => !disabled && onSelect(cloud.value)}
            className={classNames(
              styles.item,
              isActive && styles.active,
              (disabled || cloud?.disabled) && styles.disabled
            )}
          >
            <Icon />
          </div>
        );
      })}
    </div>
  );
};

export default CloudSelector;
