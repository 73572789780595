import React, { FC } from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import Input from "../../../../../components/common/formComponents/Input";
import { mapStringToItem } from "../../../../../helpers/mapStringToItem";
import { RuleLayout } from "../../../../WizardToolPage/ServicesStep/components/WidgetLayout/RuleLayout";
import { NETWORK_APPLICATIONS, PROTOCOLS } from "../../../helpers/consts";
import { useSegmentsRulesContext } from "../../../SegmentsRulesContext";
import { SegmentRule } from "../types";
import styles from "./RuleBody.module.scss";

const RuleBody: FC<{ rule: SegmentRule; id: number }> = ({ rule, id }) => {
  const { changeCustomRule } = useSegmentsRulesContext();

  const onChange = (field: string, value: string) => {
    changeCustomRule(id, field, value);
  };

  return (
    <RuleLayout title={`Rule ${id + 1}`} className={styles.ruleLayout}>
      <div className={styles.addRuleForm}>
        <Input
          label="Name"
          onChange={({ target: { value } }) => onChange("name", value)}
          value={rule.name}
        />
        <Input
          label="Rule priority"
          onChange={({ target: { value } }) => onChange("priority", value)}
          value={rule.priority}
          placeholder={"200"}
        />
        <DropdownBasic
          id={""}
          label="Protocol"
          itemsList={PROTOCOLS.map(mapStringToItem)}
          onChange={(val) => onChange("ip_protocol", val.key)}
          selected={mapStringToItem(rule.ip_protocol)}
        />
        <DropdownBasic
          id={""}
          label="Network Application"
          itemsList={NETWORK_APPLICATIONS.map(mapStringToItem)}
          onChange={(val) => onChange("network_app", val.key)}
          selected={mapStringToItem(rule.network_app)}
        />
        <Input
          label="Description"
          isOptional
          value={rule.description}
          onChange={({ target: { value } }) => onChange("description", value)}
          isFullValue
        />
      </div>
    </RuleLayout>
  );
};
export default RuleBody;
