import { ReactElement } from "react";
import AWSIcon from "../../../../components/icons/cloudConnections/AWSIcon";
import AzureIcon from "../../../../components/icons/cloudConnections/AzureIcon";
import GCPIcon from "../../../../components/icons/cloudConnections/GCPIcon";
import ColoIcon from "../../../../components/icons/connections/ColoIcon";
import BranchIcon from "../../../../components/icons/connections/BranchIcon";
import GlobeWithNATIcon from "../../../../components/icons/GlobeWithNATIcon";
import GlobeIconLessLines from "../../../../components/icons/GlobeIconLessLines";
import NATIcon from "../../../../components/icons/NATIcon";
import LockIcon from "../../../../components/icons/LockIcon";
import ThreeDotsIconNew from "../../../../components/icons/ThreeDotsIconNew";
import RemoteUsersIcon from "../../../../components/icons/interfaceTypes/RemoteUsersIcon";
import LoopWithBGPIcon from "../../../../components/icons/BGPIconNew";
import GlobeWithLockIcon from "../../../../components/icons/GlobeWithLockIcon";
import LoopbackIconNew from "../../../../components/icons/LoopbackIconNew";
import IOTIcon from "../../../../components/icons/interfaceTypes/IOTIcon";
import SDWANIcon from "../../../../components/icons/interfaceTypes/SDWANIcon";

const DIRECTLY_CONNECTED = "Directly connected";
const IPSEC_TUNNEL = "IPSec tunnel";

const getIPSECTextAndIcon = (label: string): [ReactElement, string] => {
  switch (label) {
    case "aws":
      return [<AWSIcon />, "AWS IPSec"];
    case "azure":
      return [<AzureIcon />, "Azure IPSec"];
    case "gcp":
      return [<GCPIcon />, "GCP IPsec"];
    case "data_center":
      return [<ColoIcon />, IPSEC_TUNNEL];
    case "branch":
      return [<BranchIcon />, IPSEC_TUNNEL];
    default:
      return [<LockIcon />, IPSEC_TUNNEL];
  }
};

// for virtual_interface_type = routed && Virtual Interface Tenant Bridge Domain type
const getRoutedAndOrIcon = (label = ""): [ReactElement, string] => {
  switch (label) {
    case "aws":
      return [<AWSIcon />, "AWS Direct Connect"];
    case "azure":
      return [<AzureIcon />, "Azure Express Route"];
    case "gcp":
      return [<GCPIcon />, "GCP Interconnect"];
    case "iot":
      return [<IOTIcon />, "QoS"];
    case "sdwan":
      return [<SDWANIcon />, "SDWAN"];
    case "remote_users":
      return [<RemoteUsersIcon />, "Remote Users"];
    case "data_center":
      return [<ColoIcon />, DIRECTLY_CONNECTED];
    case "branch":
      return [<BranchIcon />, DIRECTLY_CONNECTED];
    default:
      return [<ThreeDotsIconNew />, DIRECTLY_CONNECTED];
  }
};

export const getGateTextAndIcon = (
  is_ipsec_service?: boolean,
  is_nat_service?: boolean,
  is_snat_service?: boolean
): [ReactElement, string] => {
  if (is_snat_service && !is_nat_service && !is_ipsec_service) {
    return [<GlobeWithNATIcon />, "Internet Access"];
  }
  if ((is_nat_service || is_snat_service) && is_ipsec_service)
    return [<GlobeIconLessLines />, "Internet Gate"];
  if (is_ipsec_service) return [<GlobeWithLockIcon />, "IPSec Gate"];
  return [<NATIcon />, "NAT Gate"];
};

export const getTextAndIconByVirtualInterfaceType = (
  type: string,
  label?: string
): [ReactElement, string] => {
  switch (type) {
    case "loopback":
      return label === "bgp"
        ? [<LoopWithBGPIcon />, "BGP source"]
        : [<LoopbackIconNew />, "Loopback Interface"];
    case "bridged":
      return [<ThreeDotsIconNew />, "Ethernet Link"];
    case "routed":
      return getRoutedAndOrIcon(label);
    default:
      return [<ThreeDotsIconNew />, ""];
  }
};

export const getIconAndText = (
  type: string,
  vit?: string,
  remote_type?: string,
  is_ipsec_service?: boolean,
  is_nat_service?: boolean,
  is_snat_service?: boolean
): [ReactElement, string] => {
  if (vit) {
    return getTextAndIconByVirtualInterfaceType(vit, remote_type);
  } else {
    if (type === "Virtual Interface gate") {
      return getGateTextAndIcon(
        is_ipsec_service,
        is_nat_service,
        is_snat_service
      );
    } else {
      return getIPSECTextAndIcon(remote_type || "");
    }
  }
};
