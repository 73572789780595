import React, { FC, useEffect } from "react";
import AddButton from "../../components/common/buttons/AddButton";
import TableWrapper from "../../components/common/table/newTable/layout/TableWrapper";
import Table from "../../components/common/table/newTable/Table";
import TableHeader from "../../components/common/table/newTable/rows/TableHeader";
import { segmentsTableHeader } from "./common/table/segmentsTableHeader";

import styles from "./SegmentsPageContent.module.scss";
import { useNetworkContext } from "../Network/context/NetworkContext";
import { useGlobalFilterContext } from "../../contexts/GlobalFilterContext";
import { useTenantContext } from "../../contexts/tenantsContext/TenantsContext";
import ServicesRedirectPlaceholder from "../../components/ServicesRedirectPlaceholder";
import { PageLoader } from "../../components/common/loadStates/LoaderIcon";

const SegmentsPageContent: FC = () => {
  const {
    segmentations,
    segmentRequestStatus,
    fetchSegmentations,
  } = useNetworkContext();

  const { selectedTenant } = useGlobalFilterContext();
  const {
    fetchServicesStatus,
    servicesStatus,
    servicesStatusRequest,
  } = useTenantContext();

  useEffect(() => {
    if (selectedTenant) {
      fetchSegmentations();
      fetchServicesStatus(selectedTenant);
    }
  }, [selectedTenant]);

  if (servicesStatusRequest?.state === "pending") return <PageLoader />;

  return (
    <>
      {servicesStatus && !servicesStatus.segments.overall ? (
        <ServicesRedirectPlaceholder
          type="segments"
          fetchData={fetchServicesStatus}
          adminMode={servicesStatus.segments.adminSide}
        />
      ) : (
        <TableWrapper
          titleProps={{
            title: `Segments (${segmentations.length})`,
          }}
          dataStatus={segmentRequestStatus}
          tablePlaceholder={["Segments", "Segment"]}
        >
          <Table
            columns={segmentsTableHeader()}
            header={TableHeader}
            data={segmentations}
            gridColumnTemplate={`150px minmax(240px, 1fr) 1fr`}
            rowClassName={styles.rowClassName}
          />
        </TableWrapper>
      )}
    </>
  );
};

export default SegmentsPageContent;
