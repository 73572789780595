import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function QuestionIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M7.7 9.80078H6.29999V11.2008H7.7V9.80078Z"
          fill={color || FONT}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00024 1.06621H7C3.7233 1.06621 1.067 3.72251 1.067 6.9992C1.067 10.2759 3.7233 12.9322 7 12.9322C10.2767 12.9322 12.933 10.2759 12.933 6.9992L12.933 6.99896C12.929 3.72403 10.2751 1.07018 7.00024 1.06621ZM0.200012 6.9992C0.200012 3.24369 3.24449 0.199219 7 0.199219C10.7555 0.199219 13.8 3.24369 13.8 6.9992C13.8 10.7547 10.7555 13.7992 7 13.7992C3.24449 13.7992 0.200012 10.7547 0.200012 6.9992Z"
          fill={color || FONT}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.00149 2.78516C6.78589 2.78522 6.56688 2.81036 6.34801 2.86276C6.01508 2.94248 5.70814 3.07977 5.43668 3.26254C5.70821 3.07982 6.01523 2.94255 6.34824 2.86282C6.56703 2.81043 6.78597 2.78527 7.00149 2.78516ZM4.20004 5.5725C4.2 5.58126 4.2 5.59003 4.20004 5.5988H5.60005C5.66578 4.82561 6.34585 4.25208 7.11904 4.3178C7.85131 4.38005 8.40449 4.99333 8.40517 5.71488C8.40494 4.99295 7.85161 4.3792 7.11904 4.31693C6.34585 4.2512 5.66578 4.82473 5.60005 5.59793H4.20004L4.20004 5.5725ZM6.30004 9.0979V9.09878H7.70005V9.0979H6.30004ZM7.70005 8.30078C8.96238 7.97526 9.80308 6.83769 9.80151 5.58811C9.80269 6.83735 8.96209 7.97446 7.70005 8.2999V8.30078ZM6.39458 3.05639C7.79106 2.72203 9.19417 3.58301 9.52854 4.97949C9.86101 6.35975 9.02486 7.75173 7.65011 8.10624L7.50005 8.14493V8.8979H6.50004V7.62091C6.50004 7.38832 6.68779 7.19955 6.92 7.19791C7.72572 7.22418 8.43357 6.66622 8.59594 5.87618L8.59832 5.86462L8.59932 5.85285C8.6744 4.9696 8.01923 4.19272 7.13598 4.11764C6.31493 4.04785 5.58581 4.60907 5.42687 5.39793H4.40668C4.48702 4.27556 5.28556 3.32193 6.39458 3.05639Z"
          fill={color || FONT}
        />
      </g>
    </svg>
  );
}

export default QuestionIcon;
