import React, { FC, useEffect, useRef, useState } from "react";
import { CustomDropdownInput } from "../../types";

type Props = {
  custom?: React.FC<CustomDropdownInput>;
  value: string;
  handleToggleDropdown: () => void;
  setElementPosition: (val: { x: number; y: number }) => void;
  isOpen: boolean;
  id: string;
};

const DropdownInput: FC<Props> = ({
  custom: Custom,
  value,
  handleToggleDropdown,
  setElementPosition,
  isOpen,
  id,
}) => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      setElementPosition({ x: rect.left, y: rect.top });
    }
  }, [elementRef.current, screenSize]);

  return (
    <div ref={elementRef}>
      {Custom ? (
        <Custom onClick={handleToggleDropdown} value={value} isOpen={isOpen} />
      ) : (
        <button onClick={handleToggleDropdown} id={id}>
          Open dropdown
        </button>
      )}
    </div>
  );
};

export default DropdownInput;
