import { FC } from "react";
import BWDropdown from "../../../common/formComponents/BWDropdown";
import EndpointDropdown from "../../../common/formComponents/EndpointDropdown";
import {
  ChangeConnectionsFields,
  ChangeField,
  FieldsType,
} from "../../../types";
import IPAddress from "../../../common/formComponents/IPAddress";

type Props = {
  fields: FieldsType;
  onChange: ChangeConnectionsFields;
  className: string;
  type: string;
  isEdit?: boolean;
  errors?: { [key: string]: any };
  handleChangeBW: (idx: number, value: string) => void;
};

const DataCenterPortForm: FC<Props> = ({
  fields,
  onChange,
  className,
  type,
  isEdit,
  errors,
  handleChangeBW,
}) => {
  const handleChange: ChangeField = (field: string, value: any): void => {
    const newFields = { ...fields, [field]: value };
    onChange(0, newFields);
  };
  const idx = 0;
  return (
    <div className={className}>
      <EndpointDropdown
        field={fields.location}
        onChange={handleChange}
        type={type}
        isNotEditable={isEdit}
        error={errors?.[idx + "location"]}
      />
      <BWDropdown
        field={fields.BW}
        onChange={handleChangeBW}
        error={errors?.[idx + "BW"]}
        idx={idx}
      />
      <IPAddress
        field={fields.ip_addresses}
        onChange={handleChange}
        error={errors?.[idx + "ip_addresses"]}
      />
    </div>
  );
};

export default DataCenterPortForm;
