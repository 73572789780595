import { FC } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import { WEB_FILTERING_TABS } from "../../../helpers/consts";
import styles from "./TabsComponent.module.scss";
import { FONT, GREY_FONT } from "../../../../../helpers/common/colorAliases";
import { WebFilteringTab } from "../../../types";

type Props = {
  activeTab: WebFilteringTab;
  onClick: (value: WebFilteringTab) => void;
};

const TabsComponent: FC<Props> = ({ activeTab, onClick }) => {
  return (
    <div className={styles.wrapper}>
      {WEB_FILTERING_TABS.map((tab) => {
        const isActive = activeTab.id === tab.id;
        const Icon = tab.icon;
        const tabClasses = classNames(styles.tab, isActive && styles.activeTab);
        return (
          <div className={tabClasses} onClick={() => onClick(tab)} key={tab.id}>
            <Icon color={isActive ? FONT : GREY_FONT} />
            <span className={styles.title}>{tab.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default TabsComponent;
