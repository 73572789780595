import { VirtualInetrfaceBridgeDomainAdd } from "../../../../../helpers/api/TenantVirtualInterfaceApi/types";
import { LOOPBACK_BODY } from "./consts";

export const getLoopbackBody = (
  fields: any
): VirtualInetrfaceBridgeDomainAdd => {
  const body: VirtualInetrfaceBridgeDomainAdd = LOOPBACK_BODY;
  body.name = fields.name;
  body.ipv4_addresses = [fields.ip];
  body.system_name = fields.location;
  body.network_segments = fields.network_segments;
  body.administrative_state = fields.administrative_state ? "up" : "down";
  return body;
};
