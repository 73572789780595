import { ServiceStaticRouteFields, StaticRoutesType } from "../api/apiTypes";
import Validator from "./Validator";

export default function validateServiceStaticRoutes(
  data: ServiceStaticRouteFields,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();

  validator.checkEmpty(data.destination, "destination");
  validator.checkIPWithPrefixAll(data.destination, "destination");

  validator.checkEmpty(data.distance, "distance");
  validator.checkIsNumeric(+data.distance, "distance");

  validator.checkEmpty(data.type, "type");

  if (data?.type === StaticRoutesType.STATIC) {
    validator.checkEmpty(data.gateway_ip_address, "gateway_ip_address");
    validator.checkIPAll(data.gateway_ip_address, "gateway_ip_address");
  }

  if (data?.type === StaticRoutesType.INTERFACE) {
    validator.checkEmpty(data.gateway_interface, "gateway_interface");
  }

  return validator;
}
