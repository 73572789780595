import { FC } from "react";
import { Service } from "../types";
import { classNames } from "../../../helpers/common/classNames";
import { Toggle } from "../../../components/common/Toggle";
import InfoIcon from "../../../components/icons/InfoIcon";
import {
  ACCENT,
  GREY_DISABLED,
  GREY_FONT,
} from "../../../helpers/common/colorAliases";
import styles from "./ServiceItem.module.scss";
import { useServicesPreferencesContext } from "../ServicesPreferencesContext";
import BlankButton from "../../../components/common/buttons/BlankButton";

type Props = { service: Service; className: string; tenant: string };

const ServicesItem: FC<Props> = ({ service, className, tenant }) => {
  const {
    editService,
    selectService,
    selectedService,
  } = useServicesPreferencesContext();
  const isSelected = service.type === selectedService?.type;
  return (
    <div className={classNames(styles.wrapper, className)}>
      <Toggle
        label={<ServiceTitle service={service} />}
        id={service.type}
        isChecked={service.enable}
        onChange={() =>
          editService({ ...service, enable: !service.enable }, tenant)
        }
      />
      <BlankButton
        id={service.type + "_info"}
        onClick={() => selectService(isSelected ? undefined : service)}
      >
        <InfoIcon color={isSelected ? ACCENT : GREY_DISABLED} />
      </BlankButton>
    </div>
  );
};

export default ServicesItem;

const ServiceTitle: FC<{
  service: Service;
}> = ({ service }) => {
  const Icon = service.icon;
  return (
    <span className={styles.title}>
      <Icon color={GREY_FONT} />
      {service.title}
    </span>
  );
};
