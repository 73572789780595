import { FC } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import styles from "./Header.module.scss";
import { User } from "../../../../../helpers/types";
import UserIconBig from "../../../../../components/icons/UserIconBig";
import { ACCENT } from "../../../../../helpers/common/colorAliases";

type Props = {
  user: User | null | undefined;
  className: string;
  device: string;
  onChange: (value: string) => void;
};

const UserInfo: FC<Props> = ({ className, user }) => {
  const wrapperClasses = classNames(styles.wrapper, className);
  return (
    <div className={wrapperClasses}>
      <UserIconBig className={styles.icon} color={ACCENT} />
      <div className={styles.name}>{user?.username}</div>
      <div className={styles.info}>
        <span>
          {user?.first_name} {user?.last_name}
        </span>{" "}
        <span>{user?.email}</span>
      </div>
    </div>
  );
};

export default UserInfo;
