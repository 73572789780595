import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function KeyIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_28223_137189)">
        <path
          d="M11.4657 8.46575C11.8057 7.713 12 6.88 12 6C12 2.6865 9.3135 0 6 0C2.6865 0 0 2.6865 0 6C0 9.3135 2.6865 12 6 12C6.88 12 7.713 11.8057 8.46575 11.4657L9 12H11V14H13V16H16V13L11.4657 8.46575ZM6 8C4.8945 8 4 7.1055 4 6C4 4.8945 4.8945 4 6 4C7.1055 4 8 4.8945 8 6C8 7.1055 7.1055 8 6 8Z"
          fill={color || FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_28223_137189">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default KeyIcon;
