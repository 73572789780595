import React, { FC, useEffect, useState } from "react";
import LayoutComponent from "../../common/LayoutComponent";
import styles from "./PackagesPage.module.scss";
import { useQuotaContext } from "../../../contexts/tenantsContext/QuotaContext";
import PackagesHeader from "./components/PackagesHeader";
import PackagesSections from "./components/PackagesSections";
import { SECTIONS_MAP } from "./components/constAlias";
import EmtyPackages from "./components/EmtyPackages";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { QuotaType } from "../../../helpers/api/apiTypes";
import { PackagesTab } from "../../common/navigation/Tenants/PackagesTab";
import { TenantsTab } from "../../common/navigation/Tenants/TenantsTab";

export type PackageListType = {
  name: string;
  max_bw: string;
  [key: string]: any;
};

const DEFAULT_FIELDS = {
  name: "",
  fw_rules: 0,
  nat_rules: 0,
  maximum_bgp_neighbors: 0,
};

const PackagesPage: FC = () => {
  const {
    fetchPredefinedList,
    predefinedList,
    listStatus,
    fetchHardcodedData,
    hardcodedList,
    updateDefaultPackage,
  } = useQuotaContext();

  const [activePackage, setActivePackage] = useState("");

  const [fields, handleFieldChange, resetFields] = useFormField<QuotaType>(
    DEFAULT_FIELDS
  );

  useEffect(() => {
    fetchPredefinedList();
    fetchHardcodedData();
  }, []);

  useEffect(() => {
    if (activePackage) {
      const currentQuota = predefinedList?.find(
        (el) => el.name === activePackage
      );
      resetFields(currentQuota as QuotaType);
    }
  }, [activePackage]);

  const handleSubmit = async () => {
    const success = await updateDefaultPackage(fields);
    if (success) setActivePackage("");
  };

  if (!hardcodedList || !predefinedList) {
    return <EmtyPackages status={listStatus} tabs={<PackagesTab />} />;
  }

  return (
    <>
      <LayoutComponent>
        <TenantsTab />
        <div className={styles.container}>
          <div className={styles.tableWrapper}>
            <PackagesHeader
              list={predefinedList}
              activePackage={activePackage}
              onSelect={setActivePackage}
              onSave={handleSubmit}
              isDefaultPackages
            />
            {SECTIONS_MAP.map((item, i) => (
              <PackagesSections
                activePackage={activePackage}
                section={item}
                list={predefinedList}
                isDefaultPackages
                isLastRow={i === SECTIONS_MAP.length - 1}
                fields={fields}
                onChange={handleFieldChange}
                hardcodedList={hardcodedList}
                key={i}
              />
            ))}
          </div>
        </div>
      </LayoutComponent>
    </>
  );
};
export default PackagesPage;
