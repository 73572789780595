/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import {
  FilterAndSort,
  StaticRouteType,
  VRFType,
} from "../../helpers/api/apiTypes";
import { configApi } from "../../helpers/api/ConfigApi";
import { EMPTY_RESULT } from "../../helpers/common/constantsAlias";
import AbstractCrudContextContainer, {
  createContextAndUse,
  CrudFunc,
  CrudState,
} from "../common/AbstractCrudContext";

type IState = CrudState<StaticRouteType, StaticRouteType> & {
  vrfList: Array<VRFType>;
};

type IFunc = { fetchVRFList: () => Promise<void> };

export type StRouteDeleteArgs = {
  destination: string;
  gateway_ip_address: string;
};
const [Context, useContext] = createContextAndUse<
  IState,
  IFunc & CrudFunc<StaticRouteType, StRouteDeleteArgs>
>();

export const useStaticRouteContext = useContext;

type Props = React.PropsWithChildren<{
  additionParameter: string;
}>;

class StaticRouteContextContainer extends AbstractCrudContextContainer<
  StaticRouteType,
  StaticRouteType,
  IState,
  StRouteDeleteArgs
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);

    this.funcs = {
      fetchVRFList: this.fetchVRFList,
    };
  }

  listReq = async (params?: FilterAndSort, vrf?: string) => {
    if (!vrf) return EMPTY_RESULT;
    return configApi.getStaticRoutesList(
      this.props.additionParameter,
      vrf,
      params?.sort
    );
  };

  addReq = async (params: any, vrf?: string) => {
    if (!vrf) return EMPTY_RESULT;
    return await configApi.addStaticRoute(
      this.props.additionParameter,
      vrf,
      params as any
    );
  };

  removeReq = async (args: StRouteDeleteArgs, vrf?: string) => {
    if (!vrf) return EMPTY_RESULT;
    const params = {
      destination: args.destination,
      gateway_ip_address: args.gateway_ip_address,
    };
    return await configApi.deleteStaticRoute(
      this.props.additionParameter,
      vrf,
      params
    );
  };

  selectedReq = async () => {
    return await configApi.getStaticRoute();
  };
  editReq = async () => {
    return await configApi.editStaticRoute();
  };

  fetchVRFList = async (params?: FilterAndSort) => {
    const res = await configApi.getVRFList(
      this.props.additionParameter,
      params?.sort
    );
    if (res.ok && res.result) {
      this.setState({ vrfList: res.result });
    }
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          ...this.funcs,
          ...this.baseFuncs,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export default StaticRouteContextContainer;
