import { CellProps } from "react-table";
import CollapsibleButton from "../../../../../components/common/buttons/CollapsibleButton";
import Label from "../../../../../components/common/table/Label";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import {
  GREY_DISABLED,
  GREY_FONT,
} from "../../../../../helpers/common/colorAliases";
import { Connection } from "../../types";
import { getPortTypeIcon } from "../../helpers/getPortTypeIcon";
import styles from "./connectionsTableColumns.module.scss";

export const connectionsTableColumns = (): any => {
  return [
    {
      Header: "",
      accessor: "toggle",
      id: ({ row }: CellProps<Connection>) => row.original.id,
      Cell: ({ row }: CellProps<Connection>) => {
        return (
          <CollapsibleButton
            isSmall
            toCollapse={!row.isExpanded}
            color={GREY_DISABLED}
            id={"toggle" + row.original.id}
            onClick={() => row.toggleRowExpanded()}
          />
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }: CellProps<Connection>) => {
        return value || "";
      },
    },
    {
      Header: '"A" Port',
      accessor: "a_endpoint_name",
      Cell: ({ row, cell: { value } }: CellProps<Connection>) => {
        const Icon = getPortTypeIcon(row.original.a_endpoint_type);
        return (
          <Label>
            <TextWithIcon
              className={styles.label}
              icon={<Icon color={GREY_FONT} />}
              text={value}
            />
          </Label>
        );
      },
    },
    {
      Header: '"A" Location / region',
      accessor: "a_endpoint_location",
      Cell: ({ cell: { value } }: CellProps<Connection>) => {
        return value || "";
      },
    },
    {
      Header: '"Z" Port',
      accessor: "z_endpoint_name",
      Cell: ({ row, cell: { value } }: CellProps<Connection>) => {
        const Icon = getPortTypeIcon(row.original.z_endpoint_type);
        return (
          <Label>
            <TextWithIcon
              className={styles.label}
              icon={<Icon color={GREY_FONT} />}
              text={value}
            />
          </Label>
        );
      },
    },

    {
      Header: '"Z" Location / Region',
      accessor: "z_endpoint_location",
      Cell: ({ cell: { value } }: CellProps<Connection>) => {
        return value || "";
      },
    },
    {
      Header: "Connection BW",
      accessor: "a_endpoint_bw",
      Cell: ({ cell: { value } }: CellProps<Connection>) => {
        return value || " - ";
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof Connection }));
};
