import React, { ReactElement } from "react";
import { IconProps } from "../../helpers/types";
import { ORANGE } from "../../helpers/common/colorAliases";

function WarningCircleIcon({
  color,
  className,
  height = 26,
  width = 26,
}: IconProps): ReactElement {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 24.4833C19.3421 24.4833 24.4833 19.3421 24.4833 13C24.4833 6.65793 19.3421 1.51667 13 1.51667C6.65793 1.51667 1.51667 6.65793 1.51667 13C1.51667 19.3421 6.65793 24.4833 13 24.4833ZM13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
        fill={color || ORANGE}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4038 7.88389C11.4038 7.14292 11.8434 6.53204 12.5503 6.33973C13.2573 6.18135 13.9642 6.50376 14.2672 7.13726C14.3682 7.36351 14.4038 7.58976 14.4038 7.8443C14.386 8.19781 14.3607 8.55133 14.3355 8.90485C14.3102 9.25837 14.285 9.61189 14.2672 9.9654C14.2345 10.5112 14.2003 11.0571 14.1662 11.6029C14.132 12.1487 14.0979 12.6946 14.0652 13.2404C14.0652 13.4362 14.0559 13.6092 14.0468 13.7783C14.0381 13.9401 14.0296 14.0984 14.0296 14.2698C13.9998 14.8524 13.5246 15.2993 12.9187 15.2993C12.3127 15.2993 11.8434 14.8807 11.8078 14.3038C11.7573 13.4525 11.7068 12.6097 11.6563 11.7669C11.6058 10.9242 11.5553 10.0814 11.5048 9.23009C11.487 9.00666 11.4706 8.78183 11.4543 8.55699C11.438 8.33215 11.4216 8.10731 11.4038 7.88389ZM11.4405 18.785C11.4405 17.9607 12.1177 17.2697 12.9256 17.2697C13.7395 17.2697 14.4108 17.9546 14.3751 18.8213C14.4108 19.6093 13.7038 20.3002 12.9256 20.3002C12.1177 20.3002 11.4405 19.6093 11.4405 18.785Z"
        fill={color || ORANGE}
      />
    </svg>
  );
}

export default WarningCircleIcon;
