import { parseGTimeRange } from "../../../components/common/charts/GraphanaLinksFactory";

const grafanaSystemsTimeRangeBody = (): { [key: string]: any } => {
  const { from, to } = parseGTimeRange({ from: "now-10080m", to: "now" });
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    aggs: {
      "9": {
        date_range: {
          field: "@timestamp",
          ranges: [
            {
              from: "now-5m",
              to: "now",
            },
            {
              from: "now-15m",
              to: "now",
            },
            {
              from: "now-30m",
              to: "now",
            },
            {
              from: "now-60m",
              to: "now",
            },
            {
              from: "now-3h",
              to: "now",
            },
            {
              from: "now-6h",
              to: "now",
            },
            {
              from: "now-12h",
              to: "now",
            },
            {
              from: "now-24h",
              to: "now",
            },
            {
              from: "now-7d",
              to: "now",
            },
          ],
          time_zone: userTimeZone,
        },
        aggs: {
          "10": {
            max: {
              field: "rx_pps",
            },
          },
          "11": {
            max: {
              field: "rx_pps",
            },
          },
        },
      },
    },
    size: 0,
    script_fields: {},
    stored_fields: ["*"],
    runtime_mappings: {},
    query: {
      bool: {
        must: [],
        filter: [
          {
            match_phrase: {
              entity: "all_systems",
            },
          },
          {
            range: {
              "@timestamp": {
                format: "strict_date_optional_time",
                gte: from,
                lte: to,
              },
            },
          },
        ],
        should: [],
        must_not: [],
      },
    },
  };
};

export default grafanaSystemsTimeRangeBody;
