import { FC } from "react";
import EditButton from "../../../../buttons/EditButton";
import DeleteButton from "../../../../buttons/DeleteButton";

type Props = {
  onEdit: () => void;
  onDelete: () => void;
  id?: string;
  className: string;
};

const ItemControls: FC<Props> = ({ onEdit, onDelete, id, className }) => {
  return (
    <>
      <EditButton id={`edit${id}`} onClick={onEdit} className={className} />
      <DeleteButton id={`del${id}`} onClick={onDelete} className={className} />
    </>
  );
};

export default ItemControls;
