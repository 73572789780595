import { FC } from "react";
import ActiveTenantSelector from "../../../../components/common/navigation/tenantSelector/ActiveTenantSelector";
import TimerangOverlayComponent from "../../../../components/common/timerange/TimerangeOverlayComponent";
import styles from "./SearchControls.module.scss";
import SearchField from "../../common/SearchField";

const SearchControls: FC = () => {
  return (
    <div className={styles.wrapper}>
      <SearchField className={styles.inputField} />
      <ActiveTenantSelector />
      <TimerangOverlayComponent
        id={"searchTimerange"}
        className={styles.timerangeWrapper}
      />
    </div>
  );
};

export default SearchControls;
