import React, { FC, useState } from "react";
import { ConnectionPortDetails } from "../../types";
// eslint-disable-next-line css-modules/no-unused-class
import styles from "./ConnectionDetails.module.scss";
import ConnectionTable from "./ConnectionTable/ConnectionTable";
import { getAllPortsFields } from "../../helpers/getAllPortsFields";
import ConnectionsDiagram from "../../ConnectionsDiagram/ConnectionsDiagram";
import { classNames } from "../../../../../helpers/common/classNames";

type Props = {
  data: {
    ports: Array<ConnectionPortDetails>;
    vpc?: ConnectionPortDetails;
  };
  onDelete: (row: any) => void;
};

const ConnectionDetails: FC<Props> = ({ data, onDelete }) => {
  const { ports, vpc } = data;
  const [showDiagram, setShowDiagram] = useState(true);
  const allFields = getAllPortsFields(ports);

  return (
    <div
      className={classNames(styles.wrapper, !showDiagram && styles.collapsed)}
    >
      <ConnectionTable
        ports={ports}
        vpc={vpc}
        onDelete={onDelete}
        allFields={allFields}
        showDiagram={showDiagram}
        toggleDiagram={() => setShowDiagram((prev) => !prev)}
      />
      {showDiagram && (
        <div className={styles.diagram}>
          <ConnectionsDiagram data={data} />
        </div>
      )}
    </div>
  );
};
export default ConnectionDetails;
