import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function PremiumIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99994 2L12.6556 7.34486L18.5594 8.21885L14.2968 12.3961L15.29 18.2812L9.99994 15.518L4.70987 18.2812L5.70306 12.3961L1.44043 8.21885L7.34432 7.34486L9.99994 2Z"
        stroke={color || FONT}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default PremiumIcon;
