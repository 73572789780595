import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function APIIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="26"
      height="20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 26 20"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1c0-.6.4-1 1-1h24c.6 0 1 .4 1 1v15.1H0V1Zm0 16.7V19c0 .6.4 1 1 1h24c.6 0 1-.4 1-1v-1.3H0Zm4.2-15c-1 0-2 1-2 2v8.7h2v-4h2v4H8V4.7c0-1-1-2-2-2H4.2Zm0 4.6V4.7h2v2.6h-2Zm5.9-2.6c0-1 1-2 2-2h1.8c1 0 2 1 2 2v2.7c0 1-1 2-2 2H12v4h-2V4.7Zm1.9 0v2.6h2V4.7h-2Zm7.8 0v6.8h-2v2h6v-2h-2V4.7h2v-2h-6v2h2Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default APIIcon;
