import BlockedIcon from "../../../components/icons/BlockedIcon";
import { PINK, YELLOW } from "../../../helpers/common/colorAliases";
import AllowSelectedIcon from "../components/WebFilteringConfig/common/AllowSelectedIcon";
import BlockSelectedIcon from "../components/WebFilteringConfig/common/BlockSelectedIcon";
import {
  BlockSelectedChecklist,
  EditFQDNService,
  FQDNDefaultAction,
  WebFilteringTab,
} from "../types";

export const WEB_FILTERING_ENTITIES = {
  BLOCK_SELECTED: "ALLOW",
  ALLOW_SELECTED: "DENY",
  CONTENT_FILTER: "contentFilter",
  WEB_THREATS_FILTER: "webThreatsFilter",
};

export const WEB_FILTERING_TABS: Array<WebFilteringTab> = [
  {
    icon: BlockSelectedIcon,
    title: "Block selected categories and URLs",
    id: WEB_FILTERING_ENTITIES.BLOCK_SELECTED as FQDNDefaultAction,
  },
  {
    icon: AllowSelectedIcon,
    title: "Allow only selected",
    id: WEB_FILTERING_ENTITIES.ALLOW_SELECTED as FQDNDefaultAction,
  },
];

export const BLOCK_SELECTED_CHECKLISTS_MAP: Array<BlockSelectedChecklist> = [
  {
    id: WEB_FILTERING_ENTITIES.CONTENT_FILTER,
    icon: BlockSelectedIcon,
    color: YELLOW,
    title: "Content Filter",
    listTitle: "URL Block list categories",
  },
  {
    id: WEB_FILTERING_ENTITIES.WEB_THREATS_FILTER,
    icon: BlockedIcon,
    color: PINK,
    title: "Web Threats Filter",
    listTitle: "URL Block Threats",
  },
];

export const FDQNServiceBody: EditFQDNService = {
  services: [
    {
      type: "fqdn",
      enable: true,
      data: {
        default_action: "ALLOW",
        categories: [],
        allowed_domains: [],
        denied_domains: [],
      },
    },
  ],
};
