import React, { FC, PropsWithChildren } from "react";
import { classNames } from "../../../../../helpers/common/classNames";
import styles from "./RuleLayout.module.scss";

export const RuleLayout: FC<
  PropsWithChildren<{ title?: string; className?: string }>
> = ({ children, title = "Rule 1", className }) => {
  return (
    <div className={classNames(styles.layout, className)}>
      <h3 className={styles.title}>{title}</h3>
      {children}
    </div>
  );
};
