import { ReactElement } from "react";
import { GREY_FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function DataCentersIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="28" height="28" fill="white" />
      <path
        d="M4 8H24M4 13.5H24M4 19.5H24M6 11H7M8.5 11H9.5M18.5 11H22M6 16.75H7M8.5 16.75H9.5M18.5 16.75H22M6 22.25H7M8.5 22.25H9.5M18.5 22.25H22M23.7071 6.70711L20.2929 3.29289C20.1054 3.10536 19.851 3 19.5858 3H8.41421C8.149 3 7.89464 3.10536 7.70711 3.29289L4.29289 6.70711C4.10536 6.89464 4 7.149 4 7.41421V24C4 24.5523 4.44772 25 5 25H23C23.5523 25 24 24.5523 24 24V7.41421C24 7.149 23.8946 6.89464 23.7071 6.70711Z"
        stroke={color || GREY_FONT}
        strokeWidth="0.6"
      />
    </svg>
  );
}

export default DataCentersIcon;
