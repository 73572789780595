import { FC, useEffect, useState } from "react";
import BarChart from "../BarChart";
import { calculateDataUsage } from "../utils";
import { arubaApi } from "../../../helpers/api/ArubaApi";

type Props = {
  className: string;
  list: Array<any>;
};

const Countries: FC<Props> = ({ className, list }) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [labels, setLabels] = useState<Array<string>>([]);
  const [bytes, setBytes] = useState<Array<number>>([]);
  const devices = list.map((item) => item.id).join();

  useEffect(() => {
    if (!list.length) return;
    if (!isLoading) {
      setLoading(true);
    }
    const payload = list.map((item) => {
      return { nePk: item.id };
    });

    const params = {
      groupBy: "COUNTRY",
      top: "15",
      granularity: "day",
    };

    arubaApi.getDNS(params, payload).then(async (res) => {
      const data = res.result || [];
      const [labels, bytes] = calculateDataUsage(data, "COUNTRY");
      setLabels(labels);
      setBytes(bytes);
      setLoading(false);
    });
  }, [devices]);

  return (
    <div className={className} data-loading={isLoading}>
      <h4>Countries</h4>
      <BarChart labels={labels} data={bytes}></BarChart>
    </div>
  );
};

export default Countries;
