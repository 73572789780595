import { ConnectionAdd } from "../../types";
import { BASIC_BODY, DIA } from "../consts";
import { FieldsType } from "../types";
import { getConfigFromType } from "./getConfigFromType";

export const getNewAZConnectionBody = (
  name: string,
  fields: Array<FieldsType>,
  services: { [key: string]: boolean }
): ConnectionAdd => {
  const [aPort, zPort] = fields;
  const body = { ...BASIC_BODY };

  body.name = name;

  body.a_endpoint_name = aPort.location?.name;
  body.a_endpoint_conf = getConfigFromType(aPort);

  body.z_endpoint_name = zPort.innerType === DIA ? "DIA" : zPort.location?.name;
  body.z_endpoint_conf = getConfigFromType(zPort);

  body.services = services;

  return body;
};
