import React, { FC, ReactElement } from "react";
import styles from "./ParametersLines.module.scss";

type Props = {
  params: ReactElement;
};
const ParametersLines: FC<Props> = ({ params }) => {
  return <div className={styles.wrapper}>{params}</div>;
};
export default ParametersLines;
