import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { IpamContextContainer } from "./IpamContext";
import Ipam from "./index";

const IpamRoutes: FC = () => {
  return (
    <IpamContextContainer>
      <Switch>
        <Route path={"/"} exact>
          <Ipam tabs={undefined as any} />
        </Route>
        <Route path={"*"}>
          <Ipam tabs={undefined as any} />
        </Route>
      </Switch>
    </IpamContextContainer>
  );
};

export default IpamRoutes;
