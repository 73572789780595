import { FC } from "react";
import Button from "../../../../components/common/buttons/Button";
import BlockedIcon from "../../../../components/icons/BlockedIcon";
import FlagUSA from "../../../../components/icons/TenantsScheme/FlagUSA";
import { useIPsecContext } from "../../IPsecContext";
import styles from "./Header.module.scss";

type Props = {};
export const RemoteConfigHeader: FC<Props> = () => {
  const { ipsecInterface, downloadPDF } = useIPsecContext();

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <div className={styles.title}>IPSec remote config</div>
          <div className={styles.region}>
            <BlockedIcon /> {ipsecInterface.name}
          </div>
          <div className={styles.location}>
            <FlagUSA />
            New York
          </div>
        </div>
        <div className={styles.headerRight}>
          <Button isPrimaryBtn onClick={downloadPDF}>
            Download PDF
          </Button>
        </div>
      </div>
    </div>
  );
};
