import React, { ReactElement } from "react";
import { FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function ColoIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1201_1520)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.11421 0C6.59778 0 6.10935 0.234752 5.78674 0.638018L3.03763 4.0744C2.51731 4.29473 2.12949 4.76679 2.02686 5.33786L2.02557 5.33948H2.02657C2.00911 5.43719 2 5.5378 2 5.64053V22.55C2 23.4889 2.76112 24.25 3.7 24.25H20.6095C21.5484 24.25 22.3095 23.4889 22.3095 22.55V5.64053C22.3095 4.92983 21.8734 4.32099 21.2542 4.06703L18.511 0.638018C18.1884 0.234752 17.6999 0 17.1835 0H7.11421ZM6.87995 1.51259C6.93688 1.44143 7.02308 1.4 7.11421 1.4H17.1835C17.2746 1.4 17.3608 1.44143 17.4178 1.51259L19.3593 3.93948H4.93844L6.87995 1.51259ZM3.4 5.64053C3.4 5.47484 3.53432 5.34053 3.7 5.34053H20.6095C20.7752 5.34053 20.9095 5.47484 20.9095 5.64053V10.2447H3.4V5.64053ZM3.4 11.6447V16.5457H20.9095V11.6447H3.4ZM3.4 22.55V17.9457H20.9095V22.55C20.9095 22.7157 20.7752 22.85 20.6095 22.85H3.7C3.53431 22.85 3.4 22.7157 3.4 22.55ZM14.8853 7.87702C14.8853 7.44819 15.2329 7.10055 15.6618 7.10055H18.6763C19.1051 7.10055 19.4528 7.44819 19.4528 7.87702C19.4528 8.30585 19.1051 8.65349 18.6763 8.65349H15.6618C15.2329 8.65349 14.8853 8.30585 14.8853 7.87702ZM5.74996 6.82757C5.24545 6.82757 4.83646 7.23656 4.83646 7.74107C4.83646 8.24558 5.24545 8.65456 5.74996 8.65456C6.25447 8.65456 6.66345 8.24558 6.66345 7.74107C6.66345 7.23656 6.25447 6.82757 5.74996 6.82757Z"
          fill={color || FONT}
        />
        <rect
          x="14.8848"
          y="13.2227"
          width="4.56749"
          height="1.55295"
          rx="0.776473"
          fill={color || FONT}
        />
        <rect
          x="14.8848"
          y="19.8906"
          width="4.56749"
          height="1.55295"
          rx="0.776473"
          fill={color || FONT}
        />
        <rect
          x="4.83594"
          y="13.2227"
          width="1.82699"
          height="1.82699"
          rx="0.913497"
          fill={color || FONT}
        />
        <rect
          x="4.83594"
          y="19.4336"
          width="1.82699"
          height="1.82699"
          rx="0.913497"
          fill={color || FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_1201_1520">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ColoIcon;
