import { CellProps } from "react-table";
import ReactTooltip from "react-tooltip";
import LabelArray from "../../../../../components/common/table/LabelArray";
import { CellFactory } from "../../../../../components/common/table/newTable/cells/CellsFactory";
import TextWithIcon from "../../../../../components/common/table/TextWithIcon";
import { Toggle } from "../../../../../components/common/Toggle";
import { NatRuleType } from "../../../../../helpers/api/apiTypes";
import { getNatIcon, getNatTypeName } from "../../../../../helpers/natHelpers";
import styles from "./natRulesTableColumns.module.scss";

const cellFactory = new CellFactory<NatRuleType>({});

export const natRulesTableColumns = [
  {
    Header: "Enabled",
    accessor: "id",
    Cell: ({ cell: { value } }: CellProps<NatRuleType>) => (
      <div className={styles.labels}>
        <div data-tip data-for={value} className={styles.id}>
          ID
        </div>
        <Toggle
          isChecked={true}
          onChange={() => {
            return;
          }}
        />
        <ReactTooltip
          id={value.toString()}
          aria-haspopup="true"
          place="bottom"
          type="light"
          className={styles.tooltip}
        >
          {value}
        </ReactTooltip>
      </div>
    ),
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ cell: { value } }: CellProps<NatRuleType>) => {
      return (
        <span className={styles.name} title={value}>
          {value}
        </span>
      );
    },
  },
  {
    Header: "Type",
    accessor: "rule_type",
    Cell: ({ cell: { value } }: CellProps<NatRuleType>) => {
      return (
        <TextWithIcon
          icon={getNatIcon(value)}
          text={getNatTypeName(value)}
          className={styles.type}
        />
      );
    },
  },
  {
    Header: "Inside",
    accessor: "inside",
    Cell: cellFactory.outlineLabelCell(),
  },
  {
    Header: "Outside",
    accessor: "outside",
    Cell: ({
      row: { original },
      cell: { value },
    }: CellProps<NatRuleType & { outside_ip?: Array<string> }>) => {
      const ips = original.outside_ip;
      const isIpsExist = ips && ips.length > 0;
      const values = isIpsExist ? [value, ...ips] : [value];
      return <LabelArray values={values} withWrap />;
    },
  },
  {
    Header: "Description",
    accessor: "description",
  },
].map((el) => ({ ...el, accessor: el.accessor as keyof NatRuleType }));
