import React, { FC } from "react";
import styles from "./UserThumbnail.module.scss";

type Props = {
  username: string;
};

export const UserThumbnail: FC<Props> = ({ username = "Tenant1" }) => {
  return (
    <div className={styles.thumbnail}>
      <div className={styles.letter}>{username.substring(0, 1)}</div>
      <div>{username}</div>
    </div>
  );
};
