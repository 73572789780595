const units = ["", "K", "M", "B", "T"];

export const formatNumbers = (value: number | string): string => {
  let number = +value;

  let unitIndex = 0;
  while (number >= 1000 && unitIndex < units.length - 1) {
    number /= 1000;
    unitIndex++;
  }

  number = Math.round(number * 10) / 10;

  return number.toString() + units[unitIndex];
};
