import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function FortinetIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.8173 20.9976C22.9255 20.6283 23.788 19.3763 23.9952 17.8021V17.3472H18.3136V20.998H21.8165L21.8173 20.9976ZM23.996 10.6532V10.2416C23.7782 8.59893 22.8516 7.3045 21.6703 7.00039H18.3144V10.6528L23.996 10.6532ZM4 17.3476V17.7986C4.20574 19.3743 5.06806 20.6285 6.17948 20.998H9.66473V17.3472L4 17.3476ZM6.32483 7.00315C5.14515 7.30742 4.21602 8.60248 4 10.2475V10.6562H9.66473V7.00378L6.32483 7.00315ZM24 12.1761H18.3184V15.8286H24V12.1761ZM11.2154 21H16.7733V17.3491H11.2154V21ZM16.7733 7H11.2154V10.6524H16.7733V7ZM4.00482 15.8239H9.66954V12.1714H4.00482V15.8239Z"
        fill={color || GREY_FONT}
      />
    </svg>
  );
}

export default FortinetIcon;
