import { SearchResponse } from "../types";

export const commonTableColumns = (keyHeader: string): any => {
  return [
    {
      Header: keyHeader,
      accessor: "key",
    },
    {
      Header: "Count",
      accessor: "doc_count",
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof SearchResponse }));
};
