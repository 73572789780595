import { ConnectionApiExtended, Endpoint } from "../../types";
import { Z_PORT_DEFAULTS_FIELDS } from "../consts";
import { FieldsType } from "../types";

export const convertDataToFields = (
  list: Array<ConnectionApiExtended>,
  getLocation: (name: string) => Endpoint | undefined
): Array<FieldsType> => {
  const connections: Array<FieldsType> = [];
  if (
    list.length === 1 &&
    list[0].z_port_inner_type !== "cloudRouter" &&
    list[0].z_port_inner_type !== "elan"
  ) {
    return getAZPorts(list[0], getLocation);
  }
  list.forEach((el) => {
    const newConnection = getAPort(el, getLocation);
    connections.push(newConnection);
  });
  return connections;
};

const getAZPorts = (
  connection: ConnectionApiExtended,
  getLocation: (name: string) => Endpoint | undefined
): Array<FieldsType> => {
  const aPort = getAPort(connection, getLocation);
  const zPort = getZPort(connection, getLocation);
  return [aPort, zPort];
};

const getAPort = (
  connection: ConnectionApiExtended,
  getLocation: (name: string) => Endpoint | undefined
): FieldsType => {
  const realName = connection.name;
  const location = getLocation(connection.a_endpoint_name);
  const innerType = connection.a_port_inner_type;
  const cloudType = location?.type.toLowerCase();
  const resultFileds = {
    ...connection.a_endpoint_conf,
    location,
    innerType,
    cloudType,
    realName,
  };
  const ip_addresses = connection.z_endpoint_conf?.ip_addresses?.[0];
  if (ip_addresses) {
    Object.assign(resultFileds, { ip_addresses });
  }
  return resultFileds;
};

const getZPort = (
  connection: ConnectionApiExtended,
  getLocation: (name: string) => Endpoint | undefined
): FieldsType => {
  const innerType = connection.z_port_inner_type;
  const newConnection = innerType
    ? Z_PORT_DEFAULTS_FIELDS[innerType]
    : undefined;
  const location = getLocation(connection.z_endpoint_name);
  const cloudType = location?.type.toLowerCase();
  return {
    ...newConnection,
    ...connection.z_endpoint_conf,
    location,
    cloudType,
  };
};
