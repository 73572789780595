import React, { FC } from "react";
import { Checkbox } from "../../../../../../components/common/formComponents/Checkbox";
import { useServicesContext } from "../../../ServicesContext";
import { SubmitButton } from "../Submit";
import styles from "./styles.module.scss";

export const DdosService: FC = () => {
  const { segments, updateSegment } = useServicesContext();

  const toggleSegmentStatus = (id: number) => {
    const segment = segments.find((s) => s.id === id);

    if (!segment) {
      return;
    }
    updateSegment(segment.id, "isProtected", !segment.isProtected);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <div className={styles.title}>Select the segments to protect </div>
        {segments.map(({ id, name, isProtected }) => (
          <Checkbox
            isChecked={isProtected}
            onChange={() => toggleSegmentStatus(id)}
            label={name}
            className={styles.checkbox}
            key={id}
          />
        ))}
      </div>
      <div className={styles.submit}>
        <SubmitButton onClick={() => undefined} />
      </div>
    </div>
  );
};
