import React, { FC, ReactElement } from "react";
import ReactDOM from "react-dom";
import { useNotificationsContext } from "../../contexts/NotificationsContext";

import styles from "./NotificationPopup.module.scss";
import OkCircleIconFilled from "../icons/OkCircleIconFilled";
import ErrorCircleIconFilled from "../icons/ErrorCircleIconFilled";
import { classNames } from "../../helpers/common/classNames";
import ClockIcon from "../icons/ClockIcon";

const NotificationPopup: FC = () => {
  const { message } = useNotificationsContext();
  return ReactDOM.createPortal(
    <div
      className={classNames(
        styles.wrapper,
        message?.state === "ok" && styles.okState,
        message?.state === "pending" && styles.pendingState
      )}
    >
      <div className={styles.icon}>{getIcon(message?.state)}</div>
      <div className={styles.title}>{message?.title}</div>
      <div className={styles.details}>{message?.details}</div>
    </div>,
    document.body
  );
};
export default NotificationPopup;

const getIcon = (
  state: "ok" | "error" | "pending" | undefined
): ReactElement => {
  switch (state) {
    case "ok":
      return <OkCircleIconFilled />;
    case "error":
      return <ErrorCircleIconFilled />;
    case "pending":
    default:
      return <ClockIcon color={"var(--extra-dusty-yellow)"} />;
  }
};
