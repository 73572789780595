import React, { FC, useEffect } from "react";
import styles from "./ConnectionsScheme.module.scss";
import Star from "./Star";
import { isPending } from "../../../../../helpers/common/RequestStateHelpers";
import { useConnectionsContext } from "../../ConnectionsContext";
import { useUserContext } from "../../../../../contexts/UserContext";

const ConnectionsScheme: FC = () => {
  const {
    connections,
    activeLocation,
    setActiveLocation,
    activeConnection,
    setActiveConnection,
    addConnectionStatus,
    setActiveConnectionType,
    getSecureUsers,
  } = useConnectionsContext();

  const { user } = useUserContext();

  useEffect(() => {
    if (activeLocation?.system) {
      getSecureUsers(user.name, activeLocation.system);
    }
  }, [user.name, activeLocation?.system]);

  const handleClick = (el: any, type?: string, loc?: string) => {
    if (type === "loc")
      setActiveLocation({ location: el.location, system: el.system });
    if (type === "vi" && loc) {
      setActiveConnection(el, loc);
      setActiveConnectionType(el?.labels?.remote_type || "branch");
    }
  };

  return (
    <div className={styles.wrapper}>
      <Star
        onClick={handleClick}
        viMap={connections}
        activeLocation={activeLocation}
        isLocationLoading={isPending(addConnectionStatus)}
        selectedInterface={activeConnection}
      />
    </div>
  );
};

export default ConnectionsScheme;
