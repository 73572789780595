import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function SearchIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="m19.878 18.7-5.81-5.81a7.876 7.876 0 0 0 1.765-4.973C15.833 3.55 12.282 0 7.917 0 3.55 0 0 3.551 0 7.917c0 4.365 3.551 7.916 7.917 7.916a7.876 7.876 0 0 0 4.973-1.765l5.81 5.81a.417.417 0 0 0 .589 0l.589-.59a.417.417 0 0 0 0-.588ZM7.917 14.167a6.257 6.257 0 0 1-6.25-6.25 6.257 6.257 0 0 1 6.25-6.25 6.257 6.257 0 0 1 6.25 6.25 6.257 6.257 0 0 1-6.25 6.25Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default SearchIcon;
