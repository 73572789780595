import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LoopWithBGPIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.86029 5.92021C8.63862 5.72141 8.63875 5.37413 8.86057 5.1755L13.2279 1.26454C13.55 0.976092 14.0616 1.20482 14.0615 1.63721L14.0603 4.8955C17.593 5.70085 20.3143 8.76309 20.5238 12.5614C20.7771 17.1561 17.2578 21.0862 12.6631 21.3395C8.06848 21.5929 4.13838 18.0736 3.88502 13.4789C3.87304 13.2617 3.8695 13.0458 3.87415 12.8315C3.88613 12.2794 4.34346 11.8415 4.89561 11.8535C5.44777 11.8654 5.88566 12.3228 5.87368 12.8749C5.87014 13.0381 5.87283 13.2028 5.88198 13.3688C6.07453 16.8606 9.06125 19.5351 12.553 19.3426C16.0448 19.15 18.7193 16.1633 18.5268 12.6715C18.3766 9.94719 16.5247 7.71928 14.0595 6.96392L14.0586 9.46238C14.0584 9.89476 13.5466 10.1231 13.2247 9.83442L8.86029 5.92021Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default LoopWithBGPIcon;
