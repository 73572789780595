import React, { useState } from "react";
import { withLayout } from "../../helpers/hocs/withLayout";

import styles from "./HistoryLogPage.module.scss";
import HistoryLogTable from "./components/HistoryLogTable";
import { withContexts } from "../../helpers/hocs/withContexts";
import TimerangeContextContainer from "../../contexts/TimerangeContext";
import Button from "../../components/common/buttons/Button";
import { useHistory } from "react-router-dom";
import {
  HistoryLogPageContextContainer,
  useHistoryLogPageContext,
} from "./context/HistoryLogPageContext";
import ConfigurationPreviewDialog from "./components/ConfigurationPreviewDialog";

const HistoryLogPage = () => {
  const {
    configurationPreview,
    configurationRequestStatus,
    selectedEvent,
  } = useHistoryLogPageContext();

  const [previewDialogIsVisible, setPreviewDialogIsVisible] = useState(false);
  const history = useHistory();

  const redirectToConnections = () => {
    history.goBack();
  };

  const closeDialog = () => {
    setPreviewDialogIsVisible(false);
  };

  const openDialog = () => {
    setPreviewDialogIsVisible(true);
  };

  return (
    <div className={styles.historyLogPageWrapper}>
      {previewDialogIsVisible && (
        <ConfigurationPreviewDialog
          configuration={configurationPreview}
          onClose={closeDialog}
          isLoading={configurationRequestStatus.state === "pending"}
          event={selectedEvent}
        />
      )}
      <HistoryLogTable onDialogOpen={openDialog} />
      <Button onClick={redirectToConnections} className={styles.redirectBtn}>
        Back to Connections
      </Button>
    </div>
  );
};

export default withContexts<any>(withLayout(HistoryLogPage), [
  TimerangeContextContainer,
  HistoryLogPageContextContainer,
]);
