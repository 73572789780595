import { DropdownItem } from "./types";

export function mapStringToItem(val: string): DropdownItem {
  return { key: val };
}

export function mapObjToItem(val: {
  value: string;
  disabled: boolean;
}): DropdownItem {
  return { key: val.value, disabled: Boolean(val.disabled) };
}
