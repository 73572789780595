import {
  Segmentation,
  SegmentationPrefix,
} from "../../pages/Network/context/NetworkContext";
import { VirtualInterfaceApi } from "../../pages/VirtualInterfacePage/types";
import { PaginateListResponse } from "../types";
import Api, { Res, ResultStatus } from "./Api";
import {
  SEGMENTATIONS,
  TENANT_VIRTUAL_INTERFACES,
  CONNECTIONS_INTERFACES,
} from "./apiPaths";

export class NetworkApi extends Api {
  constructor() {
    super();
  }

  async getVirtualInterfaces(
    tenant: string
  ): Promise<Res<PaginateListResponse<VirtualInterfaceApi>>> {
    return await this.fetchBroker(TENANT_VIRTUAL_INTERFACES.GET(tenant));
  }

  async getSegmentations(
    tenant: string
  ): Promise<Res<PaginateListResponse<Segmentation>>> {
    return await this.fetchBroker(SEGMENTATIONS.GET(tenant));
  }

  async deleteSegments(
    tenant: string,
    segment: string,
    prefixes_to_delete: Array<SegmentationPrefix>
  ): Promise<Res<Array<string>>> {
    return await this.fetchBroker(SEGMENTATIONS.DELETE(tenant, segment), {
      body: JSON.stringify({ delete_prefixes: prefixes_to_delete }),
    });
  }

  async deleteIpsecGatewayInterface(
    tenant: string,
    name: string
  ): Promise<Res<ResultStatus>> {
    return await this.fetchBroker(
      CONNECTIONS_INTERFACES.DELETE_IPSEC_GATEWAY(tenant, name)
    );
  }
  async deleteIpsecInterface(
    tenant: string,
    name: string
  ): Promise<Res<ResultStatus>> {
    return await this.fetchBroker(
      CONNECTIONS_INTERFACES.DELETE_IPSEC(tenant, name)
    );
  }
  async deleteBridgedInterface(
    tenant: string,
    name: string
  ): Promise<Res<ResultStatus>> {
    return await this.fetchBroker(
      CONNECTIONS_INTERFACES.DELETE_BRIDGE(tenant, name)
    );
  }
}

export const networkApi = new NetworkApi();
