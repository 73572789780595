import { FC } from "react";
import { SystemsViewMode } from "../types";
import LocationFillIcon from "../../../../components/icons/LocationFillIcon";
import { TableIcon } from "../../../../components/icons/tabs/TableIcon";
import styles from "./ViewTabs.module.scss";
import { classNames } from "../../../../helpers/common/classNames";
import { ACCENT, GREY_FONT } from "../../../../helpers/common/colorAliases";
import { capitalizeFirstLetter } from "../../../../helpers/common/stringHelpers";

const TABS = [
  { name: SystemsViewMode.map, icon: LocationFillIcon },
  { name: SystemsViewMode.table, icon: TableIcon },
];

type Props = {
  viewMode: SystemsViewMode;
  onChange: (mode: SystemsViewMode) => void;
  className?: string;
};
const ViewTabs: FC<Props> = ({ viewMode, onChange, className }) => {
  return (
    <div className={classNames(styles.wrapper, className)}>
      {TABS.map((el) => {
        const Icon = el.icon;
        const isActive = el.name === viewMode;
        return (
          <div
            className={classNames(styles.item, isActive && styles.active)}
            onClick={() => onChange(el.name)}
          >
            <Icon color={isActive ? ACCENT : GREY_FONT} />
            {capitalizeFirstLetter(el.name)}
          </div>
        );
      })}
    </div>
  );
};

export default ViewTabs;
