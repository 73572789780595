import React, { FC } from "react";
import { Link } from "react-router-dom";
import { isPending } from "../../../helpers/common/RequestStateHelpers";
import {
  NETWORK_RESOURCES,
  SYSTEMS,
  TENANTS,
} from "../../../helpers/navigation/entries";
import { RequestStatus } from "../../../helpers/types";
import styles from "./DashboardPlaceholder.module.scss";
import { PageLoader } from "../../../components/common/loadStates/LoaderIcon";
import NoGraphData from "../../../components/icons/NoGraphData";
import Button from "../../../components/common/buttons/Button";
import AddIcon from "../../../components/icons/AddIcon";

type Props = {
  status?: RequestStatus;
  type: "system" | "tenant";
};
const DashboardPlaceholder: FC<Props> = ({ status, type }) => {
  if (isPending(status)) {
    return <PageLoader />;
  }
  const isSystem = type === "system";
  return (
    <div className={styles.container}>
      <NoGraphData />
      <div className={styles.message}>
        There are no {isSystem ? "Systems" : "Tenants"} yet, please add
      </div>
      <Link
        to={
          isSystem
            ? "/" + NETWORK_RESOURCES().path + "/" + SYSTEMS().path
            : "/" + TENANTS().path
        }
        className={styles.link}
      >
        <Button isPrimaryBtn onClick={() => undefined}>
          <AddIcon className={styles.icon} />
          Add {isSystem ? "System" : "Tenant"}
        </Button>
      </Link>
    </div>
  );
};
export default DashboardPlaceholder;
