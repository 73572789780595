import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ASNIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.1499 15.2091C20.1158 15.0869 20.0363 15.0071 19.948 15.0071H17.554V9.49344C17.554 9.31298 17.4562 9.1665 17.3356 9.1665H13.8401C13.7195 9.1665 13.6216 9.31298 13.6216 9.49344V15.0071H11.2185C11.1302 15.0071 11.0507 15.0869 11.0166 15.2085C10.983 15.3308 11.0013 15.4714 11.0638 15.5649L15.4199 20.9871C15.461 21.0485 15.5165 21.0832 15.5746 21.0832C15.6327 21.0832 15.6882 21.0485 15.7293 20.9877L20.1027 15.5655C20.1652 15.472 20.1839 15.3314 20.1499 15.2091Z"
        fill={color || FONT}
      />
      <path
        d="M1.85013 6.67583C1.88421 6.7957 1.96374 6.87392 2.052 6.87392H4.44596L4.44596 12.5125C4.44596 12.6894 4.54384 12.833 4.66443 12.833H8.15992C8.28051 12.833 8.37839 12.6894 8.37839 12.5125V6.87389H10.7815C10.8698 6.87389 10.9493 6.79567 10.9834 6.67643C11.017 6.55656 10.9987 6.41872 10.9362 6.32704L6.56249 1.01058C6.52143 0.950328 6.46592 0.916342 6.40782 0.916342C6.34971 0.916342 6.29421 0.950327 6.25314 1.00995L1.89732 6.32641C1.83483 6.41812 1.81605 6.55593 1.85013 6.67583Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ASNIcon;
