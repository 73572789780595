import { BaseMenuItem } from "../../../../../types";

const SECURITY_LIST: Array<BaseMenuItem> = [
  {
    title: "Secure users",
    id: "secure_users",
    active: false,
    withToggle: true,
  },
];

const CONNECTIONS_LIST: Array<BaseMenuItem> = [
  {
    title: "Branch",
    id: "branch",
    active: true,
  },
  {
    title: "Colo / Data Center",
    id: "data_center",
  },
  { title: "Bare Metal", id: "bareMetal" },
];

const CLOUDS_LIST: Array<BaseMenuItem> = [
  {
    title: "AWS",
    id: "aws",
  },
  {
    title: "GCP",
    id: "gcp",
    onlyAdmin: true,
  },
  { title: "Azure", id: "azure", onlyAdmin: true },
];

const INTERNET_LIST: Array<BaseMenuItem> = [
  {
    title: "Internet",
    id: "internet",
  },
];

export const SELECTORS_LIST = [
  { title: "Enable remote users", list: SECURITY_LIST },
  { title: "Add connection", list: CONNECTIONS_LIST },
  { title: "Add clouds VPCs / vNets", list: CLOUDS_LIST },
  { title: "Add Internet connectivity", list: INTERNET_LIST },
];

export const ALL_CONNECTIONS_MAP = [
  ...SECURITY_LIST,
  ...CONNECTIONS_LIST,
  ...CLOUDS_LIST,
  ...INTERNET_LIST,
];
