import React from "react";
import { classNames } from "../../../../../../helpers/common/classNames";
import SegmentsComponent from "../../../../../WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/common/SegmentsComponent";
import EndpointDropdown from "../../common/EndpointDropdown";
import { useConnectionConfiguratorContext } from "../../ConnectionConfiguratorContext";
import DefaultConnectionFields from "../DefaultConnectionFields/DefaultConnectionFields";
import InterfaceSection from "./InterfaceSection";

import styles from "./DirectConnection.module.scss";
import RoutingSection from "./RoutingSection";
import ContractSection from "../../../common/ContractSection";
import CloudOperatorField from "../../common/CloudOperatorField";
import DropdownBasic from "../../../../../../components/common/Dropdown/DropdownBasic";
import { mapStringToItem } from "../../../../../../helpers/mapStringToItem";
import { renderSectionByCloudOperator } from "../../common/cloudOperator";

type Props = {
  className?: string;
};

const DirectConnection = ({ className }: Props) => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  return (
    <div className={classNames(styles.wrapper)}>
      <DefaultConnectionFields className={className}>
        <EndpointDropdown />
        <SegmentsComponent
          list={fields.network_segments || []}
          onChange={fieldsOnChange}
          error={""}
        />
        <DropdownBasic
          id={"connection_interface"}
          onChange={(val) => {
            fieldsOnChange("connection_interface", val.key);
          }}
          selected={mapStringToItem(fields.connection_interface)}
          label="Connection Interface"
          itemsList={["Existing", "Equinix Fabric"].map(mapStringToItem)}
          isMedium
        />
        {fields.endPoint === "cloud" && <CloudOperatorField />}
      </DefaultConnectionFields>
      <InterfaceSection />
      <RoutingSection />
      <ContractSection />
      {fields.endPoint === "cloud" &&
        renderSectionByCloudOperator(fields.cloudOperator, styles.cloudSection)}
    </div>
  );
};

export default DirectConnection;
