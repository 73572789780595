import { CellProps } from "react-table";
import { StaticRoutesBodyType } from "../../../helpers/api/apiTypes";
import { Toggle } from "../../../components/common/Toggle";
import Label from "../../../components/common/table/Label";

export const staticTableHeader = (): Array<any> => {
  return [
    {
      Header: "Enabled",
      accessor: "enabled",
      Cell: () => (
        <Toggle
          isChecked={true}
          onChange={() => {
            return;
          }}
        />
      ),
    },
    {
      Header: "Destination",
      accessor: "destination",
      Cell: ({ cell: { value } }: CellProps<StaticRoutesBodyType>) => (
        <Label>{value}</Label>
      ),
    },
    {
      Header: "Gateway",
      accessor: (row: any) => [row?.gateway_ip_address, row?.gateway_interface],
      Cell: ({ cell: { value } }: CellProps<any>) => {
        const [gateway_ip_address, gateway_interface] = value;
        return gateway_ip_address || gateway_interface;
      },
    },
    {
      Header: "Distance",
      accessor: "distance",
    },
    {
      Header: "Description",
      accessor: "description",
    },
  ].map((el) => ({
    ...el,
    accessor: el.accessor as keyof StaticRoutesBodyType,
  }));
};
