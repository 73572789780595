import { FC, useMemo, useState } from "react";
import LoaderIcon from "../../../components/common/loadStates/LoaderIcon";
import StatusIcon from "../../../components/icons/StatusIcon";
import { classNames } from "../../../helpers/common/classNames";
import {
  GREEN,
  GREY_DISABLED,
  GREY_FONT,
  ORANGE,
} from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";
import { useSDWANProvidersContext } from "../SDWANProvidersContext";
import styles from "./ProviderTitle.module.scss";
import KeyIcon from "../../../components/icons/KeyIcon";
import CheckMarkIcon from "../../../components/icons/CheckMarkIcon";
import Button from "../../../components/common/buttons/Button";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import UpdateIcon from "../../../components/icons/UpdateIcon";
import ArubaIcon from "../../../components/icons/cloudConnections/ArubaIcon";
import VeloCloudIcon from "../../../components/icons/cloudConnections/VeloCloudIcon";
import CiscoIcon from "../../../components/icons/cloudConnections/CiscoIcon";
import FortinetIcon from "../../../components/icons/cloudConnections/FortinetIcon";

type Props = {
  className: string;
  title: string;
  count: number;
  withDetails: boolean;
};

const ProviderTitle: FC<Props> = ({ className, title, count, withDetails }) => {
  const { countStatus } = useSDWANProvidersContext();
  const {
    tokens,
    setRequestToRemove,
    setRequestToUpdate,
    requestToUpdate,
  } = useSDWANProvidersContext();
  const [tooltipShown, setTooltipShown] = useState<boolean>(false);
  const Icon = useMemo(() => getIconFromType(title), [title]);
  const keyColor = tokens?.[title] ? GREEN : GREY_DISABLED;
  const countVal =
    countStatus?.state === "pending" ? (
      <LoaderIcon className={styles.loader} color={GREY_FONT} />
    ) : (
      count
    );

  const handleTooltip = () => {
    setTooltipShown(!tooltipShown);
  };

  const getUpdateTokensBlock = () => {
    return (
      <div className={styles.logic}>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            setRequestToRemove(title);
          }}
        >
          <DeleteIcon />
          Delete
        </Button>
        <Button
          onClick={(e) => {
            withDetails && e.stopPropagation();
            requestToUpdate ? setRequestToUpdate() : setRequestToUpdate(title);
          }}
        >
          <UpdateIcon />
          Update
        </Button>
      </div>
    );
  };

  return (
    <div className={classNames(styles.wrapper, className)}>
      <div className={styles.cloudName}>
        <Icon color={GREY_FONT} className={styles.cloudIcon} />
        {TYPE_TITLE_MAP[title]}
      </div>
      <div className={styles.right}>
        <div onMouseEnter={handleTooltip} onMouseLeave={handleTooltip}>
          <KeyIcon color={keyColor} />
          {tooltipShown && keyColor === GREEN && (
            <div className={styles.tooltip}>
              <div className={styles.text}>
                <CheckMarkIcon color={GREEN} />
                Token stored
              </div>
              {getUpdateTokensBlock()}
            </div>
          )}
        </div>
        <div className={styles.counter}>{countVal} Connected Gateways</div>
      </div>
    </div>
  );
};
export default ProviderTitle;

const getIconFromType = (type: string): FC<IconProps> => {
  switch (type) {
    case "aruba":
      return ArubaIcon;
    case "cisco":
      return CiscoIcon;
    case "fortinet":
      return FortinetIcon;
    case "velocloud":
      return VeloCloudIcon;
    default:
      return StatusIcon;
  }
};
const TYPE_TITLE_MAP: { [key: string]: string } = {
  aruba: "HP-Aruba",
  fortinet: "Fortinet",
  cisco: "Cisco",
  velocloud: "VeloCloud",
};
