import { CellProps } from "react-table";
import { Event } from "../types";
import CaretBig from "../../../components/icons/CaretBig";
import styles from "./styles.module.scss";
import Timestamp from "../common/Timestamp";
import EventLabel from "../common/EventLabel/EventLabel";
import { ACCENT } from "../../../helpers/common/colorAliases";
import EventStatus from "../common/EventStatus";

export const eventsColumns = (
  hoveredRow?: number,
  selectedRow?: number
): Array<any> => {
  return [
    {
      Header: "Timestamp",
      accessor: "timestamp" as keyof any,
      Cell: ({ cell: { value } }: CellProps<Event>) => {
        return <Timestamp value={value} />;
      },
    },
    {
      Header: "Activity",
      accessor: "activity" as keyof any,
      Cell: ({ cell: { value } }: CellProps<Event>) => {
        if (!value) return null;
        return <EventLabel activity={value} />;
      },
    },
    {
      Header: "Type",
      accessor: "type" as keyof any,
      Cell: ({ row: { original }, cell: { value } }: CellProps<Event>) => {
        return <EventLabel activity={original.activity} entity={value} />;
      },
    },
    {
      Header: "Status",
      accessor: "status" as keyof any,
      Cell: ({ cell: { value } }: CellProps<Event>) => {
        return <EventStatus status={value} />;
      },
    },
    {
      Header: "",
      accessor: "id" as keyof any,
      Cell: ({ row: { original } }: CellProps<Event>) => {
        const isHovered = original.id === hoveredRow;
        const isActive = original.id === selectedRow;

        return isHovered || isActive ? (
          <CaretBig color={ACCENT} className={styles.caret} />
        ) : (
          ""
        );
      },
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof any }));
};

export const bgpEventsColumns = (
  hoveredRow?: number,
  selectedRow?: number
): Array<any> => {
  return [
    {
      Header: "Timestamp",
      accessor: "timestamp" as keyof any,
      Cell: ({ cell: { value } }: CellProps<Event>) => {
        return <Timestamp value={value} />;
      },
    },
    {
      Header: "Activity",
      accessor: "activity" as keyof any,
      Cell: ({ cell: { value } }: CellProps<Event>) => {
        if (!value) return null;
        return <EventLabel activity={value} />;
      },
    },
    {
      Header: "Type",
      accessor: "type" as keyof any,
      Cell: ({ row: { original }, cell: { value } }: CellProps<Event>) => {
        return <EventLabel activity={original.activity} entity={value} />;
      },
    },
    {
      Header: "Location",
      accessor: "location" as keyof any,
    },
    {
      Header: "Neighbor",
      accessor: "neighbor_ip" as keyof any,
    },
  ].map((el) => ({ ...el, accessor: el.accessor as keyof any }));
};
