import { useCallback, useEffect, useState } from "react";
import { RequestState } from "../../types";
import { systemApi } from "../SystemApi";
import { ERROR, OK, PENDING } from "../../common/RequestStateHelpers";
import { System, SystemAPI } from "../../../pages/Systems/Provisioning/types";

type Type = [Array<System | SystemAPI> | null, RequestState];

export const useSystemsList = (): Type => {
  const [requestStatus, setRequestStatus] = useState<RequestState>(PENDING);
  const [list, setList] = useState<Array<System | SystemAPI> | null>(null);

  const fetchList = useCallback(async () => {
    const { ok, result } = await systemApi.getSystemsList();

    const isError = !ok || !result;

    setList(isError ? null : result);
    setRequestStatus(isError ? ERROR : OK);
  }, []);

  useEffect(() => {
    fetchList();
  }, []);

  return [list, requestStatus];
};
