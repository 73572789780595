import React from "react";
import CrossIcon from "../../components/icons/CrossIcon";
import { useTutorialContext } from "../../contexts/TutorialContext";

import styles from "./CloseTutorialButton.module.scss";

export const CloseTutorialButton = (): React.ReactElement => {
  const { hideTutorial } = useTutorialContext();

  return (
    <div className={styles.closeTutorial} onClick={hideTutorial}>
      <CrossIcon height={40} width={40} strokeWidth={1} />
    </div>
  );
};
