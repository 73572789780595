export enum UserRoles {
  ADMIN_FULL = "admin_full",
  TENANT = "tenant",
  SUPER_ADMIN = "super_admin",
  ADMIN = "admin",
  TENANT_ADMIN = "tenant_admin",
  REMOTE_USER = "remote_user",
}

export enum UserSecondRoles {
  EDITOR = "editor",
  VIEWER = "viewer",
  ADMIN = "admin",
  REMOTE_USER = "remote_user",
  // TODO add remote user role
}
