/* eslint-disable css-modules/no-unused-class */
import { ReactElement, useMemo } from "react";
import Input, { types } from "../../../components/common/formComponents/Input";
import styles from "../LoginPage.module.scss";
import BlankButton from "../../../components/common/buttons/BlankButton";
import { useFormField } from "../../../helpers/hooks/useFormField";
import { useValidation } from "../../../helpers/validators/Validator";
import validateLogin from "../../../helpers/validators/LoginValidator";
import { classNames } from "../../../helpers/common/classNames";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useUserContext } from "../../../contexts/UserContext";
import { SSOProvidersComponent } from "./SSOProvidersComponent";

const DEFAULT_FIELDS = {
  username: "",
  password: "",
  newPassword: "",
  confirmPassword: "",
};

export default function LoginForm(): ReactElement {
  const {
    isPasswordExpired,
    loginRes,
    login,
    resetIsPasswordExpired,
    ssoProviders,
    ssoError,
    ssoErrorMessage,
  } = useAuthContext();
  const { isZayo } = useUserContext();
  const [fields, handleFieldChange] = useFormField(DEFAULT_FIELDS);
  const [errors, validate] = useValidation(validateLogin, [fields]);

  const handleLogin = async () => {
    const { isOk } = validate();
    if (isOk) {
      await login(fields.username, fields.password, fields.newPassword);
    }
  };

  const handleEnter = (k: React.KeyboardEvent<HTMLInputElement>) => {
    if (k.code === "Enter") handleLogin();
  };

  const isDisabled = useMemo(() => {
    if (!errors) return false;
    const keys = Object.keys(errors).length;
    return keys > 0;
  }, [errors]);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.title}>
          {isPasswordExpired ? "Please update your password" : "Log in"}
        </div>
        <Input
          onChange={(e) => handleFieldChange("username", e.target.value)}
          placeholder={"username"}
          value={fields.username}
          label="User Name"
          error={errors && errors["username"]}
          inputInternalClass={classNames(
            !loginRes && styles.inputInternalClass
          )}
          onKeyDown={(k) => handleEnter(k)}
        />
        <Input
          onChange={(e) => handleFieldChange("password", e.target.value)}
          placeholder={isPasswordExpired ? "current password" : "password"}
          value={fields.password}
          className={styles.password}
          type={types.PASSWORD}
          label={isPasswordExpired ? "Current Password" : "Password"}
          error={errors && errors["password"]}
          inputInternalClass={classNames(
            !loginRes && styles.inputInternalClass
          )}
          togglePasswordVisibility
          onKeyDown={(k) => handleEnter(k)}
        />
        {isPasswordExpired && (
          <>
            <Input
              value={fields.newPassword}
              onChange={(e) => handleFieldChange("newPassword", e.target.value)}
              placeholder={"Enter your new password"}
              className={styles.password}
              type={types.PASSWORD}
              label="New Password"
              error={errors?.newPassword}
              inputInternalClass={classNames(
                !loginRes && styles.inputInternalClass
              )}
              togglePasswordVisibility
              onKeyDown={(k) => handleEnter(k)}
            />
            <Input
              value={fields.confirmPassword}
              onChange={(e) =>
                handleFieldChange("confirmPassword", e.target.value)
              }
              placeholder={"Please confirm Password"}
              className={styles.password}
              type={types.PASSWORD}
              label="Confirm password"
              error={errors?.newPassword}
              inputInternalClass={classNames(
                !loginRes && styles.inputInternalClass
              )}
              togglePasswordVisibility
              onKeyDown={(k) => handleEnter(k)}
            />
          </>
        )}

        <div className={styles.buttonBox}>
          {isPasswordExpired && (
            <BlankButton
              id={"cancelButton"}
              className={classNames(
                styles.cancellButton,
                isZayo && styles.zayoColors
              )}
              onClick={resetIsPasswordExpired}
            >
              Cancel
            </BlankButton>
          )}
          <BlankButton
            id={"actionButton"}
            className={classNames(
              styles.loginButton,
              isZayo && styles.zayoColors
            )}
            onClick={handleLogin}
            disabled={!isPasswordExpired && isDisabled}
          >
            {isPasswordExpired ? "Update Password" : "Login"}
          </BlankButton>
        </div>
        {ssoProviders && <SSOProvidersComponent />}
      </div>
      <div className={classNames(styles.errorText)}>{loginRes.message}</div>
      {ssoError && (
        <div className={classNames(styles.errorText)}>{ssoErrorMessage}</div>
      )}
    </>
  );
}
