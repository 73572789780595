export enum DNS_FILTER_FIELD {
  LOCATION = "locations",
  DST_CONNECTIONS = "dstConnections",
  DNS_IP = "dnsIP",
  RR_TYPE = "rrType",
}

export const DNS_FIELDS_MAP: Array<string> = [
  DNS_FILTER_FIELD.LOCATION,
  DNS_FILTER_FIELD.DST_CONNECTIONS,
  DNS_FILTER_FIELD.DNS_IP,
  DNS_FILTER_FIELD.RR_TYPE,
];

export const DNS_FIELD_VALUE: { [key: string]: string } = {
  [DNS_FILTER_FIELD.LOCATION]: "Location",
  [DNS_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connection",
  [DNS_FILTER_FIELD.DNS_IP]: "dest_ip",
  [DNS_FILTER_FIELD.RR_TYPE]: "dns.rrtype",
};

export const DNS_FIELD_LABEL: { [key: string]: string } = {
  [DNS_FILTER_FIELD.LOCATION]: "Locations",
  [DNS_FILTER_FIELD.DST_CONNECTIONS]: "Destination Connection",
  [DNS_FILTER_FIELD.DNS_IP]: "DNS IPs",
  [DNS_FILTER_FIELD.RR_TYPE]: "RR_TYPE",
};
