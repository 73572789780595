import React, { FC, useMemo, useState } from "react";
import { Layer3Interface } from "../../../../../Layer3Interface/types";
import { useTimerangeContext } from "../../../../../../contexts/TimerangeContext";
import { useGlobalFilterContext } from "../../../../../../contexts/GlobalFilterContext";
import { TrafficType } from "../../../../../../helpers/types";
import ChartsHeader from "../../../../../../components/common/charts/ChartsHeader/ChartsHeader";
import ChartToppersTabs from "../../../../../../components/common/ChartToppersTabs";
import GrafanaGraph from "../../../../../../components/common/charts/GrafanaGraph";
import grafanaLinksFactory from "../../../../../../components/common/charts/GraphanaLinksFactory";

type Props = {
  interfaceInfo: Layer3Interface;
};

const BranchTraffic: FC<Props> = ({ interfaceInfo }) => {
  const { timeRange } = useTimerangeContext();
  const { selectedTenant } = useGlobalFilterContext();
  const [activeTraffic, setActiveTraffic] = useState<TrafficType>("bps");

  const graphLink = useMemo(() => {
    if (interfaceInfo.description === "internet") {
      const data = {
        timeRange: timeRange.general,
        tenantName: selectedTenant,
        system: interfaceInfo.system_name || "",
      };
      return activeTraffic === "bps"
        ? grafanaLinksFactory.dashboard_interface_internet_bps(data)
        : grafanaLinksFactory.dashboard_interface_internet_pps(data);
    }
    const data = {
      interfaceName: interfaceInfo.name,
      timeRange: timeRange.general,
      tenantName: selectedTenant,
    };
    return activeTraffic === "bps"
      ? grafanaLinksFactory.dashboard_interface_bps(timeRange.general)(data)
      : grafanaLinksFactory.dashboard_interface_pps(timeRange.general)(data);
  }, [activeTraffic, timeRange, selectedTenant, interfaceInfo]);

  return (
    <>
      <ChartsHeader
        controls={
          <ChartToppersTabs
            activeTab={activeTraffic}
            setTabActive={setActiveTraffic}
          />
        }
        title="Traffic"
      />
      <GrafanaGraph link={graphLink} />
    </>
  );
};

export default BranchTraffic;
