import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function LoopIcon({ indicatorColor }: IconProps): ReactElement {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {indicatorColor && <circle cx="21" cy="3" r="3" fill={indicatorColor} />}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 14a6 6 0 1 1 8-8l4 5 3 3a1 1 0 1 1-2 2l-4-3-3-5a3 3 0 1 0-4 4l5 3 3 4a2 2 0 0 1-2 2l-3-3-5-4Z"
        fill={FONT}
      />
      <path d="m12 18 6-6 2 2-6 6z" fill={FONT} />
    </svg>
  );
}

export default LoopIcon;
