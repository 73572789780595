import React from "react";
import ConfirmDialog from "../../../components/dialogs/common/confirmDialog/ConfirmDialog";
import DeleteIcon from "../../../components/icons/DeleteIcon";
import { getDeleteDialogControls } from "../../../helpers/getDialogControls";
import { useIpamContext } from "../IpamContext";
import { PublicIpManagementRecord } from "../types";

type Props = {
  ipamToDelete: PublicIpManagementRecord;
  onDelete: () => void;
  onCancel: () => void;
};
export const IpamDeleteDialog = ({
  onDelete,
  onCancel,
  ipamToDelete,
}: Props) => {
  const { ipamDeleteStatus } = useIpamContext();

  return (
    <ConfirmDialog
      status={ipamDeleteStatus}
      icon={DeleteIcon}
      title="Delete Connection"
      message={`Are you sure you want to delete ${ipamToDelete?.name}?`}
      controls={getDeleteDialogControls({
        onDelete,
        onCancel,
      })}
    />
  );
};
