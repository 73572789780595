import React, { useEffect, useMemo } from "react";

import { OneSystemType } from "../../../helpers/api/apiTypes";
import DropdownBasic from "../Dropdown/DropdownBasic";
import { withContexts } from "../../../helpers/hocs/withContexts";
import VTEPListContextContainer, {
  useVTEPListContext,
} from "./contexts/VTEPListContext";

type Props = {
  system: OneSystemType;
  selected?: { name: string; id: string | number };
  onSelect: (vrf?: { name: string; id: string | number }) => void;
  disabled?: boolean;
  hideLabel?: boolean;
};
const VTEPDropdown: React.FC<Props> = ({
  selected,
  onSelect,
  system,
  disabled,
  hideLabel,
}) => {
  const { fetchList, list } = useVTEPListContext();

  useEffect(() => {
    if (system && system.name) {
      fetchList(system);
    }
  }, [fetchList, system?.name]);

  const selectedIem = useMemo(
    () =>
      selected
        ? { value: selected.name, key: selected.name, data: selected }
        : undefined,
    [selected]
  );
  const items = useMemo(
    () =>
      (list || []).map((item) => ({
        value: item.name,
        key: item.name,
        data: item,
      })),
    [list]
  );
  return (
    <DropdownBasic
      id={"VTEP"}
      onChange={(val) => onSelect(val.data)}
      selected={selectedIem}
      label={hideLabel ? undefined : "VTEP"}
      placeholder="VTEP"
      itemsList={items}
      isMedium
      disabled={disabled}
    />
  );
};

export default withContexts<Props>(VTEPDropdown, [VTEPListContextContainer]);
