import React from "react";

import LoaderIcon from "../loadStates/LoaderIcon";

import styles from "./styles.module.scss";

type Props = {
  label?: string;
};

const Loader = ({ label = "Loading..." }: Props): React.ReactElement => {
  return (
    <div className={styles.loader}>
      <LoaderIcon /> {label}
    </div>
  );
};

export default Loader;
