import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";
import { FONT } from "../../../helpers/common/colorAliases";

function DNSIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3578 6.50781C11.1922 4.83908 12.1783 4.14961 13 4.14961C13.8216 4.14961 14.8078 4.83908 15.6421 6.50781C16.0592 7.34195 16.401 8.35727 16.6341 9.5H18.4672C18.2076 8.07531 17.7921 6.78285 17.2521 5.70283C17.1404 5.47941 17.0212 5.26129 16.8946 5.05042C18.7843 5.978 20.2948 7.55957 21.1311 9.5H22.9999C23.0207 9.5 23.0413 9.50032 23.062 9.50095C21.6145 5.33772 17.6562 2.34961 13 2.34961C8.34374 2.34961 4.38542 5.33772 2.93799 9.50094C2.95854 9.50032 2.97918 9.5 2.99988 9.5H4.86885C5.70512 7.55957 7.21566 5.978 9.10535 5.05042C8.97878 5.26129 8.85955 5.47941 8.74785 5.70283C8.20784 6.78285 7.79235 8.07531 7.5328 9.5H9.3659C9.59893 8.35727 9.94075 7.34195 10.3578 6.50781ZM9.60637 17.5H7.74593C7.99873 18.5314 8.3373 19.4753 8.74785 20.2964C8.85955 20.5198 8.97879 20.7379 9.10536 20.9488C7.55216 20.1864 6.2551 18.9822 5.37806 17.5H3.34477C5.04081 21.1325 8.72644 23.6496 13 23.6496C17.2735 23.6496 20.9591 21.1325 22.6552 17.5H20.6219C19.7449 18.9822 18.4478 20.1864 16.8946 20.9488C17.0212 20.7379 17.1404 20.5198 17.2521 20.2964C17.6627 19.4753 18.0012 18.5314 18.254 17.5H16.3936C16.1873 18.2398 15.933 18.9097 15.6421 19.4914C14.8078 21.1601 13.8216 21.8496 13 21.8496C12.1783 21.8496 11.1922 21.1601 10.3578 19.4914C10.0669 18.9097 9.81266 18.2398 9.60637 17.5Z"
        fill={color || FONT}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9.5C1.89543 9.5 1 10.3954 1 11.5V15.5C1 16.6046 1.89543 17.5 3 17.5H23C24.1046 17.5 25 16.6046 25 15.5V11.5C25 10.3954 24.1046 9.5 23 9.5H3ZM20.4344 15.6208C20.8115 15.3679 21 15.022 21 14.5828C21 14.2657 20.8998 13.9984 20.6994 13.7811C20.499 13.5615 20.1285 13.3431 19.5879 13.1257C19.1792 12.9616 18.9194 12.8485 18.8087 12.7864C18.7005 12.7221 18.6214 12.6567 18.5713 12.5902C18.5212 12.5214 18.4962 12.4416 18.4962 12.3506C18.4962 12.2043 18.5581 12.0856 18.6821 11.9947C18.806 11.9037 18.984 11.8583 19.2161 11.8583C19.4112 11.8583 19.609 11.8794 19.8094 11.9215C20.0124 11.9636 20.2682 12.0379 20.5768 12.1444L20.9723 11.3426C20.6743 11.234 20.3882 11.1497 20.114 11.0898C19.8424 11.0299 19.5562 11 19.2556 11C18.6438 11 18.1639 11.1231 17.8158 11.3693C17.4704 11.6154 17.2977 11.9536 17.2977 12.3839C17.2977 12.6123 17.3504 12.8119 17.4559 12.9827C17.5614 13.1535 17.7024 13.3032 17.8791 13.4318C18.0558 13.5604 18.3208 13.6946 18.6742 13.8343C19.0513 13.9851 19.3005 14.0949 19.4218 14.1637C19.5457 14.2324 19.6393 14.3045 19.7026 14.3799C19.7659 14.4531 19.7975 14.5374 19.7975 14.6327C19.7975 14.8035 19.725 14.9321 19.58 15.0186C19.4376 15.1051 19.2319 15.1484 18.9629 15.1484C18.7388 15.1484 18.4909 15.1184 18.2193 15.0585C17.9503 14.9987 17.6207 14.8955 17.2304 14.7492V15.7073C17.7051 15.9024 18.2509 16 18.868 16C19.5378 16 20.0599 15.8736 20.4344 15.6208ZM9.79802 13.4551C9.79802 14.2557 9.52641 14.8689 8.98319 15.2947C8.4426 15.7206 7.66073 15.9335 6.63758 15.9335H5V11.0699H6.81557C7.75962 11.0699 8.49271 11.2794 9.01483 11.6986C9.53696 12.1178 9.79802 12.7033 9.79802 13.4551ZM8.52435 13.4817C8.52435 12.4371 7.97585 11.9148 6.87886 11.9148H6.22621V15.0818H6.75229C7.93366 15.0818 8.52435 14.5485 8.52435 13.4817ZM16.1268 15.9335H14.5684L12.0527 12.2542H12.0171C12.0672 12.904 12.0922 13.3675 12.0922 13.6447V15.9335H10.9965V11.0699H12.5431L15.0549 14.7126H15.0826C15.043 14.0805 15.0232 13.6336 15.0232 13.3719V11.0699H16.1268V15.9335Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default DNSIcon;
