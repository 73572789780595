import React, { PropsWithChildren } from "react";
import styles from "./TitleBlock.module.scss";

export type TitleProps = PropsWithChildren<{
  title: string | React.ReactElement;
}>;
export default function TitleBlock({
  title,
  children,
}: TitleProps): React.ReactElement {
  return (
    <div className={styles.tableHeader}>
      {title}
      {children}
    </div>
  );
}
