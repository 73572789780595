import React from "react";
import DropdownBasic from "../../../../../components/common/Dropdown/DropdownBasic";
import { useConnectionConfiguratorContext } from "../ConnectionConfiguratorContext";
import { CONNECTION_CLOUD_OPERATOR_MAP } from "../helpers/consts";

const CloudOperatorField = () => {
  const { fields, fieldsOnChange } = useConnectionConfiguratorContext();

  return (
    <DropdownBasic
      id={"cloudOperator"}
      onChange={(val) => {
        fieldsOnChange("cloudOperator", val.key);
      }}
      selected={CONNECTION_CLOUD_OPERATOR_MAP.find(
        (idx) => idx?.key === fields.cloudOperator
      )}
      label="Cloud Operator"
      placeholder="Choose Cloud Operator"
      itemsList={CONNECTION_CLOUD_OPERATOR_MAP}
      isMedium
    />
  );
};

export default CloudOperatorField;
