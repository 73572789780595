import React, { ReactElement } from "react";
import { FONT } from "../../helpers/common/colorAliases";
import { IconProps } from "../../helpers/types";

function ConfigIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 2a.5.5 0 0 0-.5.5v14a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-14a.5.5 0 0 0-.5-.5h-13ZM14 5H6v2h8V5Z"
        fill={color || FONT}
      />
    </svg>
  );
}

export default ConfigIcon;
