import React, { PropsWithChildren } from "react";
import {
  formGTimeRange,
  GTimeRange,
} from "../components/common/charts/GraphanaLinksFactory";
import { AbstractTimeoutHandler } from "../helpers/common/AbstractTimeoutHandler";
import { createContextUtils } from "./common/utils";
import { isLocalStorageSupported } from "../helpers/isLocalStorageSupported";

const LOCALSTORAGE_TIMERANGE_KEY = "LOCALSTORAGE_TIMERANGE_KEY";

export type TimeRange = { general: GTimeRange; historyLog: GTimeRange };
export type TimeRangeType = "general" | "historyLog";

type IState = {
  isManual: boolean;
  timeRange: TimeRange;
  activeTitle: string;
};

type IFunc = {
  setTimeRange: (timeRangeValue: TimeRange, isManual?: boolean) => void;
  setActiveTitle: (title: string) => void;
  getTitleByTimerange: (timeRange: TimeRange) => string;
};

export type ITimeRangeContext = IState & IFunc;
const [
  UserContext,
  useTimerangeContext,
  withTimerangeContextProps,
] = createContextUtils<IState, IFunc>();

export { useTimerangeContext, withTimerangeContextProps };

export default class TimerangeContextContainer extends AbstractTimeoutHandler<
  IState,
  PropsWithChildren<any>
> {
  funcs: IFunc;
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = {
      isManual: false,
      timeRange: {
        general: TIME_RANGE_MAP.fifteenMin.value,
        historyLog: HISTORY_LOG_TIME_RANGE_MAP.sevenDays.value,
      },
      activeTitle: TIME_RANGE_MAP.fifteenMin.title,
    };

    this.funcs = {
      setTimeRange: this.setTimeRange,
      setActiveTitle: this.setActiveTitle,
      getTitleByTimerange: this.getTitleByTimerange,
    };
  }

  componentWillMount(): void {
    const savedFilters = window.localStorage.getItem(
      LOCALSTORAGE_TIMERANGE_KEY
    );
    if (savedFilters) {
      const { timeRange, isManual } = JSON.parse(savedFilters);
      this.setTimeRange(timeRange, isManual);
      this.setActiveTitle(this.getTitleByTimerange(timeRange));
    }
  }

  updateLocalstorageByFilters(timeRange: TimeRange, isManual = false): void {
    if (!isLocalStorageSupported()) {
      return;
    }
    window.localStorage.setItem(
      LOCALSTORAGE_TIMERANGE_KEY,
      JSON.stringify({ timeRange, isManual })
    );
  }

  setTimeRange = (timeRange: TimeRange, isManual = false): void => {
    this.updateLocalstorageByFilters(timeRange, isManual);
    this.setState({ timeRange, isManual });
  };

  setActiveTitle = (title: string): void => {
    this.setState({ activeTitle: title });
  };

  getTitleByTimerange = (timeRange: TimeRange): string => {
    for (const timeRangeKey in TIME_RANGE_MAP) {
      if (TIME_RANGE_MAP?.[timeRangeKey].value.from === timeRange.general.from)
        return TIME_RANGE_MAP?.[timeRangeKey].title || "";
    }
    return "";
  };

  render(): JSX.Element {
    return (
      <UserContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

type RangeValuesType = { [key: string]: { title: string; value: GTimeRange } };

export const TIME_RANGE_MAP: RangeValuesType = {
  fiveMin: { title: "Last 5 minutes", value: formGTimeRange(5) },
  fifteenMin: { title: "Last 15 minutes", value: formGTimeRange(15) },
  thirtyMin: { title: "Last 30 minutes", value: formGTimeRange(30) },
  oneHour: { title: "Last 1 hour", value: formGTimeRange(60) },
  threeHours: { title: "Last 3 hours", value: formGTimeRange(3 * 60) },
  sixHours: { title: "Last 6 hours", value: formGTimeRange(6 * 60) },
  twelveHours: { title: "Last 12 hours", value: formGTimeRange(12 * 60) },
  oneDay: { title: "Last 24 hours", value: formGTimeRange(60 * 24) },
  threeDays: { title: "Last 3 days", value: formGTimeRange(3 * 60 * 24) },
  sevenDays: { title: "Last 7 days", value: formGTimeRange(7 * 60 * 24) },
};

export const HISTORY_LOG_TIME_RANGE_MAP: RangeValuesType = {
  threeHours: { title: "Last 3 hours", value: formGTimeRange(3 * 60) },
  oneDay: { title: "Last 24 hours", value: formGTimeRange(60 * 24) },
  sevenDays: { title: "Last 7 days", value: formGTimeRange(7 * 60 * 24) },
  thirtyDays: { title: "Last 30 days", value: formGTimeRange(30 * 60 * 24) },
  ninetyDays: { title: "Last 90 days", value: formGTimeRange(90 * 60 * 24) },
};

const ONE_MINUTE = 60 * 1000;

export const TIME_RANGE_MS_MAP = {
  [ONE_MINUTE * 5]: "Last 5 minutes",
  [ONE_MINUTE * 15]: "Last 15 minutes",
  [ONE_MINUTE * 30]: "Last 30 minutes",
  [ONE_MINUTE * 60]: "Last 1 hour",
  [ONE_MINUTE * 3 * 60]: "Last 3 hours",
  [ONE_MINUTE * 6 * 60]: "Last 6 hours",
  [ONE_MINUTE * 12 * 60]: "Last 12 hours",
  [ONE_MINUTE * 60 * 24]: "Last 24 hours",
  [ONE_MINUTE * 3 * 60 * 24]: "Last 3 days",
  [ONE_MINUTE * 7 * 60 * 24]: "Last 7 days",
};

export const getTimeRangeByDiff = (diff: number): Array<string> => {
  const days = Math.floor(diff / 86400000);
  if (days > 1) return ["now-7d", "Last 7 days"];

  const minutes = Math.round(((diff % 86400000) % 3600000) / 60000);
  if (minutes < 5) return ["now-5m", "Last 5 minutes"];
  if (minutes < 15) return ["now-15m", "Last 15 minutes"];
  if (minutes < 30) return ["now-30m", "Last 30 minutes"];
  if (minutes < 60) return ["now-60m", "Last 1 hour"];

  const hours = Math.floor((diff % 86400000) / 3600000);
  if (hours < 3) return ["now-3h", "Last 3 hours"];
  if (hours < 6) return ["now-6h", "Last 6 hours"];
  if (hours < 12) return ["now-12h", "Last 12 hours"];

  return ["now-24h", "Last 24 hours"];
};
