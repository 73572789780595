import { ConnectionFields } from "../../pages/WizardToolPage/ConnectionStep/components/ConnectionsConfigurator/utils/formConsts";
import Validator from "./Validator";

export default function validateConnection(
  data: ConnectionFields,
  initValidator?: Validator
): Validator {
  const validator = initValidator || new Validator();
  validator.checkEmpty(data.name, "name");
  validator.checkIP(data.ip || "", "ip");
  validator.checkRemoteNetworkIPWithPrefix(
    data.remoteNetworks[0] || "",
    "remoteNetworks"
  );
  return validator;
}
