import React, { ReactElement } from "react";
import { IconProps } from "../../../helpers/types";

function DestinationIcon({ color }: IconProps): ReactElement {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11.3333" cy="6.00033" r="3.33333" fill={color || "#6B55F0"} />
      <path
        d="M8 6H1.33333M8 6L4 2M8 6L4 10"
        stroke={color || "#6B55F0"}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DestinationIcon;
