import React from "react";
import { AbstractTimeoutHandler } from "../../helpers/common/AbstractTimeoutHandler";
import { VRFType } from "../../helpers/api/apiTypes";
import { groupedInterfacesByLoc } from "../../helpers/interfacesHelpers";
import { tenantApi } from "../../helpers/api/TenantApi";
import { configApi } from "../../helpers/api/ConfigApi";
import { RequestStatus } from "../../helpers/types";
import {
  resToState,
  setPending,
} from "../../helpers/common/RequestStateHelpers";
import { systemApi } from "../../helpers/api/SystemApi";
import { ITimeRangeContext } from "../TimerangeContext";
import { createContextUtils } from "../common/utils";
import { VIMapType } from "../../pages/WizardToolPage/types";
import { Layer3Interface } from "../../pages/Layer3Interface/types";
import {
  IGlobalFilterContext,
  withGlobalFilterContext,
} from "../GlobalFilterContext";

type IState = {
  systemsStatus?: RequestStatus;
  tenantsStatus?: RequestStatus;
  interfacesStatus?: RequestStatus;
  isSystemsExist?: boolean;
  isTenantsExist?: boolean;
  activeVRF?: VRFType;
  interfaces: any;
  selectedInterface: Layer3Interface | undefined;
  viMap?: VIMapType;
};
type IFunc = {
  fetchData: (tenant: string) => void;
  fetchSystems: () => Promise<void>;
  fetchTenants: () => Promise<void>;
  setSelectedInterface: (
    selectedInterface: Layer3Interface | undefined
  ) => void;
};

const [DashboardFilterContext, useContext] = createContextUtils<
  IState,
  IFunc
>();
export const useDashboardFilterContext = useContext;

type Props = ITimeRangeContext & IGlobalFilterContext;
class DashboardFilterContextContainer extends AbstractTimeoutHandler<
  IState,
  Props
> {
  funcs: IFunc;
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      selectedInterface: undefined,
      interfaces: [],
    };

    this.funcs = {
      fetchData: this.fetchData,
      fetchSystems: this.fetchSystems,
      fetchTenants: this.fetchTenants,
      setSelectedInterface: this.setSelectedInterface,
    };
  }

  fetchSystems = async (): Promise<void> => {
    this.setState({ systemsStatus: setPending("Fetching") });
    const res = await systemApi.getSystemsList();
    this.setState({
      systemsStatus: resToState(res),
      isSystemsExist: !!(res.ok && res.result && res.result.length > 0),
    });
  };

  fetchTenants = async (): Promise<void> => {
    this.setState({ tenantsStatus: setPending("Fetching") });
    const res = await tenantApi.getTenantsList();
    this.setState({
      tenantsStatus: resToState(res),
      isTenantsExist: !!(res.ok && res.result && res.result.length > 0),
    });
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { selectedTenant } = this.props;
    if (selectedTenant && prevProps.selectedTenant !== selectedTenant) {
      this._getVRF(selectedTenant);
    }
  }

  fetchData = async (tenant: string) => {
    this._getInterfaces(tenant);
    this._getVRF(tenant);
  };

  _getVRF = async (activeTenant: string): Promise<void> => {
    const resTenant = await tenantApi.getTenant(activeTenant);
    if (resTenant.ok && resTenant.result) {
      const resVRF = await configApi.getVRF(
        resTenant.result.systems && resTenant.result.systems[0],
        activeTenant
      );
      this.setState({ activeVRF: resVRF.result });
    }
  };

  setSelectedInterface = (
    selectedInterface: Layer3Interface | undefined
  ): void => {
    const currInterface = this.state.selectedInterface;
    const isNewInterface = currInterface?.id !== selectedInterface?.id;
    this.setState({
      selectedInterface: isNewInterface ? selectedInterface : undefined,
    });
  };

  _getInterfaces = async (tenant_name: string): Promise<any> => {
    this.setState({ interfaces: [], interfacesStatus: setPending() });
    const { result: systems } = await systemApi.getSystemsList();
    const { result: tenant } = await tenantApi.getTenant(tenant_name);
    const res = await tenantApi.getVirtualInterfacesWithInternet(tenant_name);

    if (res.ok && res.result) {
      this.setState({
        interfaces: groupedInterfacesByLoc(
          res.result,
          systems,
          tenant?.systems
        ),
      });
    }
    this.setState({ interfacesStatus: resToState(res) });
  };

  render(): JSX.Element {
    return (
      <DashboardFilterContext.Provider value={{ ...this.state, ...this.funcs }}>
        {this.props.children}
      </DashboardFilterContext.Provider>
    );
  }
}

export default withGlobalFilterContext(DashboardFilterContextContainer);
