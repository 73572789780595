import React, { FC, useEffect, useMemo, useState } from "react";
import VRFContextContainer, {
  useVRFContext,
} from "../../../contexts/systemsContext/VRFContext";
import { VRFType } from "../../../helpers/api/apiTypes";
import Table from "../../common/table/newTable/Table";
import VRFDialog from "../../dialogs/VRFDialog";
import styles from "./VRFPage.module.scss";
import { withLayout } from "../../../helpers/hocs/withLayout";
import { vrfTableHeader } from "../../common/table/TableHeaders/Systems/vrfTableHeader";
import { OneSystemLayout } from "../../leftInfoBlock/LayoutTwoColumnFactory";
import TableWrapper from "../../common/table/newTable/layout/TableWrapper";
import AddButton from "../../common/buttons/AddButton";
import TableHeader from "../../common/table/newTable/rows/TableHeader";
import useDeleteDialog from "../../../helpers/hooks/useDeleteDialog";
import ConfirmDeleteDialog from "../../dialogs/common/confirmDialog/ConfirmDeleteDialog";
import { ActionFuncs } from "../../common/table/newTable/RowControls";
import { PageProps } from "../../../helpers/types";
import { withContexts } from "../../../helpers/hocs/withContexts";

const VRFPage: FC<PageProps> = ({ tabs: Tabs }) => {
  const {
    list = [],
    fetchList,
    remove,
    removeStatus,
    listStatus,
  } = useVRFContext();
  const [viewItem, setViewItem] = useState<VRFType | undefined>();
  const [showAdd, setShowAdd] = useState(false);
  const [
    tryDelete,
    selectedToDelete,
    sureDeleteProps,
  ] = useDeleteDialog<string>(remove, removeStatus, () => {
    setViewItem(undefined);
  });

  useEffect(() => {
    fetchList();
  }, []);

  const rowActions: ActionFuncs<VRFType> = useMemo(
    () => ({
      onEdit: setViewItem,
      onDelete: (rowOriginal) => {
        tryDelete(rowOriginal.name);
      },
      // onUpdate: (rowOriginal) => edit(rowOriginal, rowOriginal.name),
      // updateValidator: validateVRF,
    }),
    [setViewItem, tryDelete]
  );

  return (
    <>
      <ConfirmDeleteDialog
        {...sureDeleteProps}
        deviceType={"VRF"}
        name={selectedToDelete}
      />
      {showAdd && <VRFDialog onClose={() => setShowAdd(false)} type={"add"} />}
      {viewItem && (
        <VRFDialog
          onClose={() => setViewItem(undefined)}
          type="edit"
          data={viewItem}
          onDelete={viewItem && (() => tryDelete(viewItem?.name))}
        />
      )}
      <OneSystemLayout>
        <div className={styles.contentWrapper}>
          <Tabs disabledVrf />
          <TableWrapper
            titleProps={{
              title: `${(list || []).length} VRF`,
              children: <AddButton onClick={() => setShowAdd(true)} />,
            }}
            dataStatus={listStatus}
            tablePlaceholder={["VRFs", "VRF"]}
            isEmpty={list && list.length === 0}
          >
            <Table
              columns={vrfTableHeader}
              header={TableHeader}
              data={list}
              gridColumnTemplate={`1fr 1fr 3fr`}
              rowActions={rowActions}
              onClick={setViewItem}
            />
          </TableWrapper>
        </div>
      </OneSystemLayout>
    </>
  );
};

export default withLayout<PageProps>(
  withContexts(VRFPage, [VRFContextContainer])
);
