import React, { FC, useMemo, useState } from "react";
import { IconProps } from "../../../../helpers/types";
import { LocationsList } from "../../types";

type Props = {
  scale?: number;
  onClick?: (arg: any) => void;
  location: LocationsList;
  toggleLocation: (location: LocationsList) => void;
  selectedLocations: Array<LocationsList>;
  setTooltip?: (loc: LocationsList | undefined) => void;
} & IconProps;

const LocationPoint: FC<Props> = ({
  width,
  height,
  scale = 1,
  location,
  toggleLocation,
  selectedLocations,
  setTooltip = () => undefined,
}) => {
  const isSelected = useMemo(() => {
    return (
      selectedLocations?.findIndex(
        (l) => l.name.toLowerCase() === location.name.toLowerCase()
      ) > -1
    );
    // return system && getSystemIndex(systems, system) > -1
  }, [selectedLocations, location]);

  const isEnabled = location.status === "ready"; //Boolean(system);

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const h = (height || 24) / scale;
  const w = (width || 18) / scale;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={w}
        height={h}
        fill="none"
        viewBox="0 -1 18 24"
        x={location.x - w / 2}
        y={location.y - h}
        onClick={() => {
          if (isEnabled) toggleLocation(location);
        }}
        opacity={isEnabled ? 1 : 0.6}
        onMouseEnter={() => {
          setIsHovered(true);
          setTooltip(location);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
          setTooltip(undefined);
        }}
        style={{ cursor: isEnabled ? "pointer" : "default" }}
      >
        <rect x="0" y="0" width={18} height={24} fill="transparent" />

        <path
          fill={isSelected ? "#FF6C0E" : "#211D2E"}
          stroke={isEnabled && isHovered ? "#FF6C0E" : "none"}
          strokeWidth={1.6}
          d="M17.2 8.495c0 4.527-8.196 15.367-8.196 15.367S.809 13.022.809 8.495a8.195 8.195 0 0116.39 0z"
        ></path>
        <circle cx="9" cy="8" r="6" fill="#fff"></circle>
        <path
          fill="#FFA400"
          d="M7.266 6.915L9.002 8l1.736-1.085-1.736-1.084-1.736 1.084z"
        ></path>
        <path
          fill="#FFA400"
          d="M6.611 6.507L9 5.014l2.389 1.493L13 5.5 9 3 5 5.5l1.611 1.007z"
        ></path>
        <path
          fill="#FF6C0E"
          d="M10.736 6.915L9 8v2.17l1.736-1.085v-2.17z"
        ></path>
        <path
          fill="#FF6C0E"
          d="M11.389 6.507v2.986L9 10.987V13l4-2.5v-5l-1.611 1.007z"
        ></path>
        <path
          fill="#211D2E"
          d="M7.266 6.915v2.17l1.736 1.085V8L7.266 6.915zM6.611 9.493V6.508L5 5.5v5l1.611 1.007V9.493zM7.266 9.9v2.015L9.002 13v-2.014L7.266 9.901z"
        ></path>
      </svg>
      {isHovered && (
        <ToolTip
          location={location.name}
          x={location.x}
          y={location.y + w / 4}
          scale={scale}
        />
      )}
    </>
  );
};

const ToolTip = ({
  x,
  y,
  location,
  scale,
}: {
  x: number;
  y: number;
  location: string;
  scale: number;
}): React.ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={80 / scale}
      height={25 / scale}
      fill="none"
      viewBox="0 0 80 25"
      x={x}
      y={y}
    >
      <rect width="80" height="25" x="0" y="0" fill="#211D2E" rx="4"></rect>
      <text x={5} y={15} fill="#FFFFFF" fontSize={12}>
        {location}
      </text>
    </svg>
  );
};

export default LocationPoint;
