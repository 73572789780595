import { FC } from "react";
import CaretDown from "../../../../components/icons/CaretDown";
import EditIcon from "../../../../components/icons/EditIcon";
import { classNames } from "../../../../helpers/common/classNames";
import { GREY_DISABLED, WHITE } from "../../../../helpers/common/colorAliases";
import { capitalizeFirstLetter } from "../../../../helpers/common/stringHelpers";

import styles from "./CustomInput.module.scss";
import { CustomDropdownInput } from "../../../../components/common/PseudoDropdown/types";

const CustomInput: FC<CustomDropdownInput> = ({ onClick, value, isOpen }) => {
  const isCustom = value === "custom";
  const isDeny = value === "deny";

  return (
    <button onClick={onClick} className={styles.wrapper}>
      <span className={classNames(styles.value, isDeny && styles.isDeny)}>
        {capitalizeFirstLetter(value)}
      </span>
      <CaretDown
        color={GREY_DISABLED}
        className={classNames(styles.caret, isOpen && styles.rotate)}
      />
      {isCustom && (
        <div className={styles.customIcon}>
          <EditIcon color={WHITE} />
        </div>
      )}
    </button>
  );
};

export default CustomInput;
