import React, { FC, useEffect } from "react";
import { AddRuleButton } from "../../../../WizardToolPage/ServicesStep/components/AddRuleButton";
import { useSegmentsRulesContext } from "../../../SegmentsRulesContext";

import styles from "./CustomRules.module.scss";
import RuleBody from "./RuleBody";
import { useGlobalFilterContext } from "../../../../../contexts/GlobalFilterContext";

const CustomRules: FC = () => {
  const { selectedTenant } = useGlobalFilterContext();
  const {
    customRules,
    addCustomRule,
    getCustomRules,
    selectedSegment,
  } = useSegmentsRulesContext();

  useEffect(() => {
    if (selectedTenant && selectedSegment) {
      getCustomRules(selectedSegment, selectedTenant);
    }
  }, [selectedTenant, selectedSegment]);

  return (
    <div className={styles.addRuleWrapper}>
      <div className={styles.title}>
        Additional Rules ({customRules.length})
      </div>
      {customRules.map((rule, id) => (
        <RuleBody key={id} rule={rule} id={id} />
      ))}
      <AddRuleButton onClick={addCustomRule} />
    </div>
  );
};
export default CustomRules;
