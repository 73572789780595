import React, { ReactElement } from "react";
import { GREY_FONT } from "../../../helpers/common/colorAliases";
import { IconProps } from "../../../helpers/types";

function AlibabaIcon({ color, className }: IconProps): ReactElement {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1418_51)">
        <path
          d="M6.35534 7.55111C5.3982 7.74798 4.37019 8.51251 3.94297 9.35055C3.51575 10.1886 3.5 10.3946 3.5 13.9223C3.5 15.8048 3.5315 17.3552 3.57875 17.5973C3.85962 19.0332 4.98247 20.156 6.41834 20.4369C6.85344 20.5157 12.5165 20.5557 12.5165 20.4684C12.5165 20.4051 12.0342 18.5069 12.0103 18.4832C12.0024 18.4675 10.9977 18.246 9.77966 17.9845C6.94006 17.3676 7.02341 17.3912 6.78978 17.1064L6.58405 16.8613V11.1191L6.78978 10.8698C7.02702 10.585 6.94006 10.6089 9.77966 9.9917L12.0103 9.49295C12.0345 9.47655 12.5169 7.57801 12.5169 7.5183C12.5169 7.44283 6.73531 7.47892 6.35534 7.55767V7.55111ZM15.5225 7.56292C15.5304 7.70533 15.9733 9.44537 16.0213 9.49295C16.037 9.51658 17.1205 9.76201 18.4179 10.0386C19.7153 10.3152 20.87 10.5964 20.9727 10.6555C21.1118 10.7362 21.2286 10.8504 21.3129 10.9876C21.4396 11.209 21.4553 11.4463 21.4553 13.9932C21.4553 16.5401 21.4396 16.7773 21.3129 16.9988C21.2267 17.1343 21.1102 17.2479 20.9727 17.3309C20.87 17.3867 19.7258 17.6629 18.4179 17.9477L16.0213 18.4974C15.9737 18.5449 15.5307 20.285 15.5225 20.4274C15.5225 20.5143 16.0921 20.5301 18.5915 20.5061C22.0368 20.4746 22.0794 20.4668 22.9575 19.8735C23.5881 19.4394 24.0646 18.8163 24.3179 18.0938C24.4997 17.5638 24.4997 17.5163 24.4997 13.9965C24.4997 10.4767 24.4997 10.4291 24.3179 9.89917C24.0645 9.17676 23.5881 8.55354 22.9575 8.11942C22.0794 7.52683 22.04 7.5183 18.5915 7.48712C16.0921 7.4635 15.5225 7.47531 15.5225 7.56587V7.56292ZM11.9919 13.8308C11.9665 13.9153 11.9611 14.0046 11.9761 14.0916C11.9998 14.2229 12.1422 14.234 14.0007 14.2577L15.994 14.2734V13.7199H14.0197C12.3666 13.7199 12.0263 13.7356 11.9952 13.8304L11.9919 13.8308Z"
          fill={color || GREY_FONT}
        />
      </g>
      <defs>
        <clipPath id="clip0_1418_51">
          <rect
            width="21"
            height="21"
            fill="white"
            transform="translate(3.5 3.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default AlibabaIcon;
