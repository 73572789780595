import React, { FC } from "react";
import ArrowDown from "../../../../components/icons/ArrowDown";
import ArrowUp from "../../../../components/icons/ArrowUp";
import { CRITICAL, GREEN } from "../../../../helpers/common/colorAliases";

import styles from "./StatHealthcheck.module.scss";

type Props = {
  admin?: string;
  oper?: string;
};
export const StatHealthcheck: FC<Props> = ({ admin, oper }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.check}>
        <div className={styles.checkName}>Admin</div>
        {admin === "Up" ? <ArrowGreen /> : <ArrowRed />}
      </div>
      <div className={styles.check}>
        <div className={styles.checkName}>Oper</div>
        {oper === "Up" ? <ArrowGreen /> : <ArrowRed />}
      </div>
    </div>
  );
};

const ArrowGreen = () => {
  return (
    <div className={styles.checkValue}>
      <ArrowUp color={GREEN} /> Up
    </div>
  );
};

const ArrowRed = () => {
  return (
    <div className={styles.checkValue}>
      <ArrowDown color={CRITICAL} /> Down
    </div>
  );
};
